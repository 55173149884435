@use '../0_base' as *;

/*------------------------------------------------------------
side(共通)
------------------------------------------------------------*/

.c-side__bnr {
	margin-top: 20px;

	a {
		margin: 0 auto 10px auto;
		display: block;
		&:last-child {
			margin: 0 auto;
		}
	}
}



/*
------------------------------------------------------------*/
