@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　斎場情報（検索）
------------------------------------------------------------*/
.p-sosaiHall {
	background-color: #ffffff;
	.c-breadcrumb1{
		padding: 16px 0 65px 0;
	}
}

//葬儀場を探す 
.p-sosaiHall01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//地域から探す
.p-sosaiHall02 {
	margin-bottom: 100px;
}

//地域リスト
.p-sosaiHall03 {
	margin-bottom: 140px;
	&__content01 {
		padding: 80px 0 70px;
		background-color: $subColor10;
		.c-sosaiAreaList01 img{
			padding-top: 21px;
			margin-top: 0;
			margin-bottom: 60px;
		}
	}
	&__content02 {
		padding: 80px 0 71px;
		.c-sosaiAreaList01{
			img{
				margin-top: 0;
				margin-bottom: 61px;
				margin-left: 229px;
			}
			ul{
				li{
					a{
						padding: 12px 30px 12px 30px;
					}
				}
			}
		}
	}
	&__content03 {
		padding: 77px 0 70px;
		background-color: $subColor10;
		.c-sosaiAreaList01 img{
			padding-top: 5px;
			margin-top: 0;
			margin-bottom: 60px;
			margin-left: 179px;
		}
	}
	&__content01 , &__content02, &__content03{
		.u-inner01{
			position: relative;
		}
		.c-sosaiTitle04 {
		    height: 400px;
		    margin: 0;
		    text-align: left;
		    position: absolute;
		    top: 0;
		    right: 0;
		    -webkit-writing-mode: vertical-rl;
		    -ms-writing-mode: tb-rl;
		    writing-mode: vertical-rl;
		    margin-bottom: 14px;
		    line-height: 1;
			img{
				margin: 3px 15px 4px 0;
			}
		}
	}
}

@include sp {
	.p-sosaiHall {
		.c-sosaiTitle04 img{
			width: 20%;
			margin: 9px auto 0;
		}
	}

	//葬儀場を探す sp
	.p-sosaiHall01 {
		min-width: 100%;
		margin-bottom: 37px;
		padding: 32px 0 33px;

	}

	//地域から探す
	.p-sosaiHall02 {
		margin-bottom: 51px;
	}

	//地域リスト
	.p-sosaiHall03 {
		margin-bottom: 0;
		&__content01{
			padding: 50px 15px 45px;
			.c-sosaiAreaList01{
				img{
					padding-top: 0;
					margin: 0 auto 38px;
				}
				ul{
					li{
						a{
							line-height: 1.5rem;
						}
						&:nth-child(2),
						&:nth-child(6),
						&:nth-child(7){
							a{
								padding: 9px 12px 9px 17px;
							}
						}
						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5){
							a{
								padding: 9px 10px 9px 18px;
							}
						}
					}
				}
			}
		}
		&__content02{
			padding: 50px 15px 45px;
			.c-sosaiAreaList01{
				img{
					padding: 0 35px;
					margin: 0 auto 40px;
				}
				ul{
					li{
						a{
							padding: 11px 12px 7px;
						}
					}
				}
			}
		}
		&__content03{
			padding: 51px 15px 19px;
			.c-sosaiAreaList01{
				img{
					padding: 0 10px;
					margin: 0 auto 39px;
				}
				ul{
					li{
						a{
							padding: 9px 5px 9px 10px;
						}
						&:nth-child(3){
							a{
								padding: 9px 5px;
							}
						}
					}
				}
			}
		}
		&__content01, &__content02, &__content03 {
			
			.c-sosaiTitle04{
				height: auto;
				margin-bottom: 29px;
				font-weight: normal;
			    color: #2f2933;
			    text-align: center;
			    writing-mode: unset;
			    position: unset;
			}
		}
	}
}

/*
------------------------------------------------------------*/
