@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　ご危篤・ご逝去でお急ぎの方
------------------------------------------------------------*/
.p-sosaiUrgency {
	background-color: #ffffff;
	font-size: 1.8rem;
	line-height: 1.77;
	color: $gray08;
}

//ご危篤・ご逝去でお急ぎの方
.p-sosaiUrgency01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//焦らなくても大丈夫です~~
.p-sosaiUrgency02 {
	margin-bottom: 120px;
	> p {
		margin: 22px 0 71px;
		text-align: center;
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 3.2rem;
	}
}

//こんなご要望に迅速にお応えします
.p-sosaiUrgency03 {
	margin-bottom: 106px;
	padding: 107px 0 120px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b {
	    margin-bottom: 60px;
	    span{
	    	&:after{
	    		margin: 5px auto 0 auto;
	    	}
	    }
	}
	ol {
		border: 1px solid #c8c8c8;
		li {
			width: calc(100%/3);
			background-color: #ffffff;
			position: relative;
			+ li{
				border-left: 1px solid #c8c8c8;
			}
			&:before{
				content: "";
				width: 10px;
				height: 10px;
				background: url("../img/sosai/urgency_before.png") no-repeat;
				background-size: cover;
				position: absolute;
				top: 2px;
				left: 2px;
			}
			p {
				text-align: left;
				font-size: 1.6rem;
				line-height: 2.8rem;
				font-weight: bold;
				padding: 34px 40px 32px;
			}
		}
	}
}

//ご危篤を告げられた時
.p-sosaiUrgency04 {
	margin-bottom: 118px;
	> p {
		font-size: 1.7rem;
		line-height: 3.0rem;
	}
	.c-sosaiTitle02b{
		padding-right: 7px;
		margin-bottom: 52px;
		span{
	    	&:after{
	    		margin: 5px auto 0 auto;
	    	}
	    }
	}
	&__text01{
		text-align: center;
		margin-bottom: 72px;
	}
	&__text02{
		text-align: left;
	}
	&__box {
		margin-bottom: 71px;
		border: solid 2px $sosaiNaviColor02;
		ul{
			display: flex;
			li{
				width: 25%;
				display: flex;
				align-items: center;
				padding: 30px 10px 30px 30px;
				&:nth-child(2n){
					background: #f6f6f6;
				}
				img {
					width: 34px;
					height: 34px;
				}
				p{
					font-size: 1.6rem;
					line-height: 1.8rem;
					color: #111;
					font-weight: bold;
					padding-left: 18px;
				}
			}
		}
	}
}

//ご逝去時の流れとサポート
.p-sosaiUrgency05 {
	margin-bottom: 106px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
	    padding-left: 4px;
		span{
	    	&:after{
	    		margin: 5px auto 0 auto;
	    	}
	    }
	}
	&__content01 {
		padding: 101px 0 83px 0;
		background-color: #f8f8f8;
		position: relative;
		&:after {
			width: 30px;
			height: 48px;
			margin: 0 auto;
			content: '';
			background-image: url("../img/component/3arrow02.png");
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			bottom: -24px;
			left: 0;
			right: 0;
		}
		.p-sosaiUrgency05__text{
			h3{
				align-items: center;
				img{
					margin-top: 0;
				}
			}
		}
	}
	&__content02 {
		padding: 96px 0 92px;
		position: relative;
		&:after {
			width: 30px;
			height: 48px;
			margin: 0 auto;
			content: '';
			background-image: url("../img/component/3arrow02.png");
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			bottom: -24px;
			left: 0;
			right: 0;
		}
		.p-sosaiUrgency05__text{
			h3{
				margin-bottom: 15px;
			}
		}
		.p-sosaiUrgency05__textImg{
			padding-top: 5px;
		}
	}
	&__content03 {
		padding: 100px 0;
		background-color: #f8f8f8;
		.p-sosaiUrgency05__text{
			h3{
				padding-top: 3px;
				margin-bottom: 15px;
				img{
					margin-top: -3px;
				}
			}
		}
	}
	&__text{
		width: 660px;
		h3 {
			display: flex;
			align-items: flex-start;
			font-size: 3.4rem;
			line-height: 4.4rem;
			font-weight: normal;
			margin-bottom: 22px;
			@include mincyo;
			letter-spacing: -0.1px;
			img{
				margin-right: 15px;
				margin-top: 5px;
				width: 50px;
				height: 50px;
			}
		}
		p{
			font-size: 1.7rem;
			line-height: 3.0rem;
			color: #111;
		}
	}
	&__text02 {
		margin: 33px 0 16px;
		padding: 26px 40px 32px;
		background-color: #FFF;
		border: solid 1px #c8c8c8;
		.c-sosaiTitle10{
			margin-bottom: 8px;
			font-size: 2rem;
			line-height: 4.8rem;
			color: #111;
			font-weight: bold;
			@include gosick;
			&:before{
				margin: -1px 12px 0 -30px;
			}
		}
		p {
			font-size: 1.7rem;
			line-height: 3.0rem;
			color: #111;
		}
	}
	&__textImg{
		width: 400px;
		margin-left: 60px;
		p{
			font-size: 1.5rem;
			line-height: 2.4rem;
			color: #111;
			margin-top: 24px;
			padding-left: 16px;
			position: relative;
			span{
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
	&__box {
		min-height: 240px;
		display: flex;
		position: relative;
	}
	&__note{
		margin-top: 16px;
		p{
			font-size: 1.4rem;
			line-height: 2.4rem;
			color: #111;
			padding-left: 15px;
			position: relative;
			span{
				position: absolute;
				left: 0;
				top: 0;
			}
			+ p{
				margin-top: 9px;
			}
		}
	}
}

//葬儀を行う上で必要なもの
.p-sosaiUrgency06 {
	margin-bottom: 72px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
	    padding-left: 4px;
		span{
	    	&:after{
	    		margin: 5px auto 0 auto;
	    	}
	    }
	}
	> p{
		font-size: 1.8rem;
		line-height: 3.2rem;
		color: #111;
		font-weight: bold;
		text-align: center;
		margin-top: 93px;
	}
	ul {
		li {
			width: 353px;
			margin-right: 30px;
			border-bottom: 1px solid #c8c8c8;
			position: relative;
			&:nth-child(3) {
				margin-right: 0;
			}
			&:before,
			&:after{
				content: "";
				width: 1px;
				height: calc(100% - 200px);
				background: #c8c8c8;
				position: absolute;
				bottom: 0;
			}
			&:before{
				left: 0;
			}
			&:after{
				right: 0;
			}
		}
	}
	&__text{
		padding: 33px 40px 32px;
		h3{
			font-size: 2.0rem;
			line-height: 3.2rem;
			color: #2e95a3;
			text-align: center;
			font-weight: bold;
			margin-bottom: 17px;
		}
		&--01{
			font-size: 1.6rem;
			line-height: 2.8rem;
			color: #111;
		}
		&--02{
			font-size: 1.6rem;
			line-height: 2.8rem;
			color: #111;
			position: relative;
		}
	}
}

//ご質問やご相談だけでも構いません。~~
.p-sosaiUrgency07 {
	padding: 0 0 140px;
}

.p-sosaiUrgencyBox{
	background: url("../img/sosai/bg_urgency.png") no-repeat top right;
	background-size: cover;
	position: relative;
	@include sp{
		padding-top: 10px;
		background: url("../img/sosai/bg_urgency_sp.png") no-repeat top 10px right;
		background-size: cover;
	}
	> img{
		position: absolute;
		bottom: 0;
		right: 0;
		@include sp{
			top: 0;
		}
	}
	&__title{
		padding: 24px 38px 22px;
		background: #f6738b;
		font-size: 3.4rem;
		line-height: 1;
		color: #FFF;
		font-weight: normal;
		@include mincyo;
		display: flex;
    	align-items: center;
    	@include sp{
    		display: block;
    		font-size: 2.3rem;
    		line-height: 2.5rem;
    		padding: 12px 98px 13px 10px;
    		text-align: center;
    		height: 115px;
    	}
		span{
		    display: inline-block;
		    font-size: 1.8rem;
		    font-weight: bold;
		    color: #f2385a;
		    background: #FFF;
		    padding: 7px 28px 6px 31px;
		    margin-top: -3px;
		    margin-left: 16px;
		    letter-spacing: 0.7px;
		    border-radius: 15px;
		    font-family: YuGothic,'Yu Gothic',sans-serif;
		    @include sp{
				display: block;
			    font-size: 1.1rem;
			    line-height: 1.3rem;
			    margin: 5px auto 0;
			    padding: 5px 10px 4px;
			    max-width: 130px;
			    letter-spacing: 0;
			    border-radius: 20px;
		    }
		}
	}
	&__Area{
		border-left: 2px solid #c8c8c8;
		border-bottom: 2px solid #c8c8c8;
		border-right: 2px solid #c8c8c8;
		padding: 42px 0;
		display: flex;
		@include sp{
			display: block;
			border-left: 1px solid #c8c8c8;
			border-bottom: 1px solid #c8c8c8;
			border-right: 1px solid #c8c8c8;
			padding: 18px 0 22px;
		}
		ul{
			width: 418px;
			padding: 0 38px 0 51px;
			background: url("../img/sosai/urgency_border02.png") repeat-y top right;
			@include sp{
				width: 100%;
				background: none;
				padding: 0 20px;
			}
			&:not(:first-of-type) {
				padding: 0 30px;
				background: none;
				@include sp{
    				padding: 18px 20px 0;
				}
				li{
					&:last-child{
						background: none;
						padding-bottom: 0;
					}
					a{
						> div{
							@include sp{
								width: 210px;
								padding-left: 51px;
							}
							&:before{
								left: 12px;
							}
						}
					}
				}
			}
			li{
				@include mincyo;
				padding-bottom: 23px;
				background: url("../img/sosai/urgency_border01.png") repeat-x bottom left;
				margin-bottom: 29px;
				@include sp{
					padding-bottom: 24px;
					margin-bottom: 19px;
				}
				&:last-child{
					background: none;
					margin-bottom: 0;
					padding-bottom: 0;
					@include sp{
						background: url("../img/sosai/urgency_border01.png") repeat-x bottom left;
						padding-bottom: 24px;
					}
				}
				h3{
					font-size: 2.6rem;
					line-height: 3.4rem;
					font-weight: normal;
					margin-bottom: 14px;
					@include sp{
						font-size: 1.9rem;
						line-height: 2.5rem;
						margin-bottom: 5px;
					}
					img{
						width: 30px;
						height: 30px;
						margin-right: 10px;
						margin-top: 1px;
						@include sp{
							width: 21px;
							height: 21px;
							margin-right: 5px;
							margin-top: 2px;
						}
					}
					span{
						display: inline-block;
						font-size: 1.8rem;
						@include sp{
							font-size: 1.3rem;
						}
					}
				}
				a{
					display: block;
					font-size: 4.0rem;
					line-height: 1;
					color: #111;
					letter-spacing: 3px;
					@include sp{
						background: #2e95a3;
						font-size: 1.8rem;
						line-height: 2rem;
						color: #FFF;
						letter-spacing: 0.2px;
						padding: 10px 10px 5px 10px;
						font-family: YuGothic,'Yu Gothic',sans-serif;
						font-weight: bold;
						box-shadow: 0px 3px 0px #20656e;
    					border-radius: 5px;
					}
					> div{
						@include sp{
							width: 185px;
							margin: 0 auto;
							position: relative;
							padding-left: 40px;
						}
						&:before{
							content: "";
							width: 25px;
							height: 25px;
							background: url("../img/common/icon_tel07.png") no-repeat;
							background-size: cover;
							display: none;
							position: absolute;
							top: calc(50% - 13px);
							left: 0;
							@include sp{
								display: block;
							}
						}
					}

					span{
						display: block;
						color: #333;
						@include sp{
							display: block;
							font-size: 1rem;
							line-height: 1.2;
							color: #FFF;
							margin-bottom: 1px;
						}
						&.p-sosaiUrgencyBox__name {
							font-size: 1.5rem;
							line-height: 1.2;
							font-weight: bold;
							font-family: YuGothic,'Yu Gothic',sans-serif;
							letter-spacing: 0;
							margin-bottom: 10px;
							@include sp {
								font-size: 1.0rem;
								line-height: 1.2;
								margin-bottom: 0;
							}
						}
					}
					img{
						margin-right: 9px;
    					margin-top: 3px;
					}
					+ a{
						margin-top: 18px;
						@include sp{
							margin-top: 8px;
						}
					}
					&.mb10 {
						margin-bottom: 10px;
					}
				}
				.p-sosaiUrgencyBox__area {
					font-weight: normal;
					margin-bottom: 0;
					font-size: 1.5rem;
					line-height: 1.2;
					margin-bottom: 10px;
					display: block;
					@include sp {
						font-size: 1.3rem;
					}
				}
				.p-sosaiUrgencyBox__tel {
					white-space: nowrap;
					@include sp {
						font-size: 1.8rem;
					}
				}
				.p-sosaiUrgencyBox__note {
					font-size: 1.3rem;
					line-height: 1.3;
					font-weight: normal;
					font-family: YuGothic,'Yu Gothic',sans-serif;
					padding-top: 5px;
					small {
						margin-bottom: 7px;
						display: inline-block;
					}
					@include sp {
						padding-top: 15px;
					}
					&:first-of-type {
						margin-bottom: 10px;
					}
 				}
			}
		}
	}
}

@include sp {
	.p-sosaiUrgency {
		font-size: 1.4rem;
	}
	//ご危篤・ご逝去でお急ぎの方 sp
	.p-sosaiUrgency01 {
		min-width: 100%;
		margin-bottom: 48px;
		padding: 32px 0 33px;
	}

	//焦らなくても大丈夫です~~ sp
	.p-sosaiUrgency02 {
		margin-bottom: 50px;
		padding: 0 20px;
		> p {
			margin-bottom: 16px;
			font-size: 1.4rem;
			line-height: 2rem;
		}
	}
	//こんなご要望に迅速にお応えします sp
	.p-sosaiUrgency03 {
		margin-bottom: 50px;
		padding: 47px 20px 50px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
			 	&:after {
		    		margin: 11px auto 0 auto;
		    	}
		    }
		}
		ol {
			li {
				width: 100%;
				height: auto;
				border-bottom: 1px solid #c8c8c8;
				&:last-child {
					border-bottom: 0;
				}
				+ li{
					border-left: 0;
				}
				p {
					text-align: left;
					font-size: 1.3rem;
					line-height: 2rem;
					padding: 17px 20px 15px;
				}
			}
		}
	}

	//ご危篤を告げられた時 sp
	.p-sosaiUrgency04 {
		margin-bottom: 44px;
		padding: 0 15px;
		> p {
			font-size: 1.4rem;
			line-height: 1.7;
			padding: 0 5px;
		}
		.c-sosaiTitle02b{
			margin-bottom: 27px;
			padding-right: 2px;
			span{
			 	&:after {
		    		margin: 11px auto 0 auto;
		    	}
		    }
		}
		&__text01{
			text-align: left;
			margin-bottom: 34px;
		}
		&__box {
			margin-bottom: 33px;
			ul{
				display: block;
				li{
					width: 100%;
					padding: 15px 10px 15px 15px;
					img{
						width: 30px;
						height: 30px;
					}
					p{
						font-size: 1.3rem;
						line-height: 2rem;
						padding-left: 9px;
					}
				}
			}
		}
	}

	//ご逝去時の流れとサポート sp
	.p-sosaiUrgency05 {
		margin-bottom: 47px;
		&__content01 {
			padding: 51px 20px 49px;
		}
		&__content02 {
			padding: 47px 20px 49px;
			.p-sosaiUrgency05__text{
				h3{
					margin-bottom: 10px;
				}
			}
			.p-sosaiUrgency05__note{
				margin-top: 12px;
			}
		}
		&__content03 {
			padding: 49px 20px 45px;
			.p-sosaiUrgency05__text{
				h3{
					margin-bottom: 15px;
					img{
						margin-right: 9px;
					}
				}
			}
		}
		&__text{
			width: 100%;
			h3{
				font-size: 2.1rem;
				line-height: 2.6rem;
				margin-bottom: 19px;
				img{
					width: 30px;
					height: 30px;
					margin-right: 6px;
					margin-top: 2px;
				}
			}
			p{
				font-size: 1.4rem;
				line-height: 1.7;
			}
		}
		&__text02{
			margin: 14px 0 0;
			padding: 15px 18px 14px 20px;
			.c-sosaiTitle10{
				font-size: 1.5rem;
				line-height: 2.4rem;
				padding-left: 16px;
				margin-bottom: 4px;
				position: relative;
				&:before{
					margin: 0;
					position: absolute;
					left: 0;
					top: 7px;
					width: 11px;
					height: 11px;
				}
			}
		}
		&__textImg{
			width: 100%;
			margin-left: 0;
			margin-bottom: 15px;
			img{
				width: 100%;
			}
		}
		&__box {
			min-height: auto;
			display: block;
			position: relative;
		}
		&__note{
			margin-top: 20px;
			p{
				font-size: 1rem;
				line-height: 1.2rem;
				padding-left: 10px;
    			padding-right: 10px;
    			+ p{
    				margin-top: 7px;
    			}
			}
		}
	}

	//葬儀を行う上で必要なもの sp
	.p-sosaiUrgency06 {
		margin-bottom: 0;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
			 	&:after {
		    		margin: 11px auto 0 auto;
		    	}
		    }
		}
		> p{
			font-size: 1.4rem;
			line-height: 2rem;
			margin-top: 46px;
		}
		&__text{
			padding: 18px 21px 8px;
			border-bottom: 1px solid #c8c8c8;
			border-top: 1px solid #c8c8c8;
			border-right: 1px solid #c8c8c8;
			width: calc(100% - 90px);
			h3{
				font-size: 1.5rem;
				line-height: 1.6rem;
				text-align: left;
				margin-bottom: 5px;
			}
			&--01{
				font-size: 1.3rem;
				line-height: 2rem;
			}
			&--02{
				font-size: 1.1rem;
				line-height: 1.5rem;
				font-weight: bold;
				position: relative;
				padding-left: 10px;
				margin-top: 2px;
				span{
					position: absolute;
					left: -1px;
					top: 1px;
				}
			}
		}
		&__img{
			width: 33%;
			border-bottom: 1px solid #c8c8c8;
			border-top: 1px solid #c8c8c8;
			border-left: 1px solid #c8c8c8;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;'; /*IE対策*/
			}
		}
		ul {
			li {
				width: 100%;
				margin: 0 0 10px 0;
				border-bottom: 0;
				display: flex;
				&:before,
				&:after{
					display: none;
				}
				&:nth-child(2){
					.p-sosaiUrgency06__text--02{
						margin-top: 11px;
					}
				}
				&:nth-child(3){
					.p-sosaiUrgency06__text--01{
						margin-top: 10px;
					}
				}
			}
		}
	}

	//ご質問やご相談だけでも構いません。~~ sp
	.p-sosaiUrgency07 {
		margin-bottom: 40px;
		padding: 17px 20px 20px;
		.u-inner01 {
			> p {
				margin-bottom: 30px;
				font-size: 1em;
			}
		}
	}
}

/*
------------------------------------------------------------*/
