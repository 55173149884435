@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム メンテナンススケジュール
------------------------------------------------------------*/
.p-reformCalendar {
	background-color: #ffffff;
}

//メンテナンススケジュール
.p-reformCalendar01 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior05.jpg");
		}
	}
}

//住まいのお手入れ
.p-reformCalendar02 {
	margin-bottom: 54px;
	color: $gray01;
	font-size: 1.5rem;
	line-height: 1.75;
	&__content01 {
		.c-refomeTitle03 {
			margin: -10px 0 22px;
			padding-left: 23px;
			&:after{
				display: none;
			}
		}
	}
}

//タブコンテンツ
.p-reformCalendar03 {
	margin-bottom: 80px;
	color: $gray01;
	font-size: 1.5rem;
	line-height: 1.73;
	//タブ
	&__tab {
		&__btn {
			width: 32%;
			color: #247404;
			padding: 12px 20px;
			font-size: 1.8rem;
			font-weight: bold;
			text-align: center;
			cursor: pointer;
			border: 2px solid #247404;
			border-right: none;
			transition: 0.3s ease-in-out;
			&:last-child{
				border-right: 2px solid #247404;
			}
			&.is-open, &:hover {
				color: #ffffff !important;
				background: #247404;
			}
			&.is-open {
				cursor: default;
				position: relative;
				&:before {
					width: 0;
					height: 0;
					margin-left: -4px;
					content: '';
					border: solid transparent;
					border-width: 6px;
					border-color: #247404;
					pointer-events: none;
					position: absolute;
					left: 50%;
					@include sp{
						margin: 0 0 3px -4px;
						border-width: 4px;
					}
				}
			}
		}
		&--top {
			margin-bottom: 45px;
			.p-reformCalendar03__tab__btn {
				&.is-open {
					&:before {
						bottom: -8px;
						transform: rotate(45deg);
					}
				}
			}
		}
		&--bottom {
			margin-top: 83px;
			.p-reformCalendar03__tab__btn {
				&.is-open {
					&:before {
						top: -7px;
						bottom: 100%;
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	//コンテンツ
	&__content {
		display: none;
		&.is-open {
			display: block;
		}
		> h2 {
			margin-bottom: 15px;
			font-size: 2.0rem;
			font-weight: bold;
			color: $reformBtnColor01;
			span{
				padding: 0 10px 0 5px;
				letter-spacing: 0;
			}
		}
		&__img {
			margin-bottom: 72px;
			img {
				margin: 0 auto;
			}
		}
		&__place {
			margin-bottom: 30px;
			padding-bottom: 10px;
			.c-refomeTitle03b{
				margin-bottom: 37px;
			}
			&:last-of-type {
				padding-bottom: 0 !important;
				border-bottom: none;
			}
			.c-refomeTitle04{
				width: 100%;
				padding: 9px 0 6px;
				margin-bottom: 32px;
				@include sp{
					margin-bottom: 15px;
				}
			}
			&__price {
				width: 190px;
				margin-bottom: 20px;
				font-size: 1.6rem;
				font-weight: bold;
				line-height: 32px;
				text-align: right;
				color: $reformBtnColor06;
				border-bottom: dotted 1px $gray07;
				i {
					display: inline-block;
					vertical-align: middle;
					transform: translate(0,-5px);
				}
				em {
					margin: 0 2px;
					font-size: 3.2rem;
					font-style: normal;
				}
			}
			&__point {
				margin-bottom: 42px;
				padding: 32px 40px;
				background-color: $subColor15;
				dt {
					margin-bottom: 8px;
					font-size: 1.8rem;
					font-weight: bold;
				}
				dd {
					ul {
						li {
							padding-left: 20px;
							&:before {
								width: 12px;
								height: 9px;
								margin: -3px 8px 0 -20px;
								content: '';
								background-image: url("../img/common/icon_check_orange01.png");
								-webkit-background-size: cover;
								background-size: cover;
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
				}
			}
		}
		.place01{
			margin-bottom: 10px;
			.c-refomeTitle03b{
				margin-bottom: 26px;
			}
		}
	}
	.p-reformCalendar__box__title{
		display: none;
	}

}

//関連ページ
.p-reformCalendar04 {
	margin-bottom: 105px;
	&__content01 {
		.c-refomeTitle04 {
			margin-bottom: 30px;
		}
	}
}


@include sp {
	//メンテナンススケジュール sp
	.p-reformCalendar01 {
		margin-bottom: 30px;
		&__content01 {
			.c-refomeTitle02 {
				padding: 27px 20px;
				background-image: url("../img/reform/title_obi_interior05_sp.jpg");
			}
		}
	}

	//住まいのお手入れ sp
	.p-reformCalendar02 {
		margin-bottom: 32px;
		padding: 0 20px;
		font-size: 1.4rem;
		&__content01 {
			.c-refomeTitle04 {
				margin-bottom: 12px;
			}
			.c-refomeTitle03 {
				padding-left: 12px;
				margin: 0 0 5px;
			}
		}
	}

	//タブコンテンツ sp
	.p-reformCalendar03 {
		margin-bottom: 30px;
		//タブ
		&__tab {
			padding: 0 20px;
			&__btn {
				padding: 10px 3px 8px;
				font-size: 1.4rem;
				span {
					display: block;
				}
			}
			&--top {
				margin-bottom: 33px;
			}
			&--bottom {
				margin-top: 30px;
			}
		}
		//コンテンツ
		&__content {
			> h2 {
				margin-bottom: 10px;
				padding: 0 20px;
				font-size: 1.6rem;
			}
			&__img {
				margin-bottom: 30px;
				padding: 0 20px;
			}
			&__place, .place01 {
				margin-bottom: 0;
				padding-bottom: 1px;
				&__item {
					margin-bottom: 10px;
					font-size: 1.8rem;
				}
				&__price {
					margin-bottom: 20px;
					font-size: 1.4rem;
					em {
						font-size: 2.6rem;
					}
				}
				&__point {
					margin-bottom: 20px;
					padding: 15px 20px;
					dt {
						font-size: 1.3rem;
						margin-bottom: 3px;
					}
					dd {
						font-size: 1.2rem;
					}
				}
			}
		}

		.p-reformCalendar__box {
			margin-bottom: 0;
			&__title {
				font-size: 1.5rem;
				color: #fff;
				line-height: 1.6;
				background: #48a3a4;
				padding: 14px 25px 14px 20px;
				cursor: pointer;
				position: relative;
				display: table;
				width: 100%;
				span {
					display: table-cell;
					width: 27px;
					margin-right: 5px;
				}
				&:after, &:before {
					content: '';
					background: #fff;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					transition: transform 0.25s ease-out;
				}
				&:after {
					width: 10px;
					height: 1px;
					right: 15px;
				}
				&:before {
					width: 1px;
					height: 10px;
					right: 19px;
				}
			}
			&__content{
				padding: 27px 20px 15px;
			}
			&.is-open02 {
				.p-reformCalendar__box__title::before {
					transform: translateY(-50%) rotate(-90deg);
				}
			}
		}


	}

	//関連ページ sp
	.p-reformCalendar04 {
		margin-bottom: 40px;
		padding: 0 20px;
	}
}

/*
------------------------------------------------------------*/
