@use '../0_base' as *;

/*------------------------------------------------------------
header05（リフォーム）
------------------------------------------------------------*/

.c-head05Pc {
	width: 95%;
	min-width: $baseWidth01;
	height: 50px;
	margin: 0 auto 70px;
	padding: 10px 1% 0 2%;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	@include radius(0 0 25px 25px);
	@include clear;
	.c-head05Logo {
		width: 189px;
	}
}
.c-head05GnaviPc {
	ul {
		@include dib;
		li {
			margin-right: 15px;
			a {
				color: $gray01;
				font-size: 1.4rem;
				line-height: 2.14;
				font-weight: 600;
				&:before {
					margin: -3px 5px 0 0;
					content: '';
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
				&:hover {
					color: $keycolor01;
				}
			}
			&:nth-child(1) {
				a {
					&:before {
						width: 14px;
						height: 19px;
						background-image: url("../img/common/icon_biginer01.png");
					}
				}
			}
			&:nth-child(2) {
				a {
					&:before {
						width: 17px;
						height: 18px;
						background-image: url("../img/common/icon_reaf01.png");
					}
				}
			}
		}
	}
}

.c-head05CateTitle {
	height: 70px;
	padding-top: 20px;
	font-size: 2.4rem;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	z-index: 9998;
}

.c-head05BtnPc {
	height: 0;
	font-size: 1.6rem;
	font-weight: bold;
	text-align: center;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	z-index: 9998;
	.c-head05BtnPc__btn {
		position: absolute;
		top: 0;
		&.c-head05BtnPc__btn--1 {
			height: 70px;
			padding-top: 20px;
			text-align: center;
			background-color: #fff;
			right: 360px;
			span {
				padding-top: 1px;
				display: block;
				font-size: 1.2rem;
				b {
					margin: 0 0 0 0.25em;
					font-size: 1.4rem;
					font-weight: bold;
				}
			}
		}
		&.c-head05BtnPc__btn--2 {
			width: 345px;
			height: 80px;
			background-color: $reformBtnColor01;
			right: 0;
			a {
				width: 100%;
				height: 100%;
				display: block;
				color: #ffffff;
				display: flex;
    			align-items: center;
    			justify-content: center;
				&:before {
					width: 18px;
					height: 20px;
					margin: -3px 10px 0 0;
					content: '';
					background-image: url("../img/common/icon_file01.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}


@include sp {
	.c-head05Sp {
		width: 100%;
		height: 50px;
		padding:0;
		background-color: #ffffff;
		border-bottom: solid 1px $glaylight04;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		@include clear;
		.c-head05Logo {
			width: 200px;
			max-width: 35%;
			height: 50px;
			position: absolute;
			top: 0;
			left: 15px;
			img {
				width: 100%;
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
			}
		}
		.c-spMenu {
			background-color: #ffffff;
		}
		.c-headCateTitleSp {
			padding: 14px 15px 0 11px;
			background-color: $reformColor01;
			letter-spacing: -0.1em;
			strong {
				white-space: nowrap;
			}
			.c-cateIcon01{
				margin: -5px 7px 0 0;
			}
		}
	}
	.c-head05LinkSp {
		dl {
			width: 100%;
			dt {
				width: 100%;
				background-color: $keycolor02;
				a {
					padding: 11px 35px 8px 20px;
					font-size: 1.4rem;
					font-weight: bold;
					line-height: 1.5;
					display: block;
					color: #ffffff;
					background-image: url("../img/common/icon_arrow-white02.png");
					background-repeat: no-repeat;
					-webkit-background-size: 7px;
					background-size: 7px;
					background-position: 95% center;
				}
			}
			dd {
				padding: 15px 20px 5px;
				background-color: #ffffff;
				ul {
					li {
						width: 49%;
						height: 45px;
						margin: 0 2% 2% 0;
						border: solid 1px $glaylight01;
						background-color: #ffffff;
						@include radius(4px);
						a {
							width: 100%;
							height: 100%;
							padding: 2px 0 2px 52px;
							font-size: 1.4rem;
							font-weight: bold;
							line-height: 2.928;
							display: block;
							position: relative;
							&:before {
								width: 30px;
								height: 30px;
								content: '';
								background-repeat: no-repeat;
								-webkit-background-size: 65%;
								background-size: 65%;
								background-position: center center;
								position: absolute;
								top: 6px;
								left: 14px;
								@include radius(50%);
							}
						}
						&:nth-child(1) {
							a {
								color: $ticketColor02;
								&:before {
									background-color: $ticketColor01;
									background-image: url("../img/common/icon_ticket01.png");
								}
							}
						}
						&:nth-child(2) {
							margin-right: 0;
							a {
								color: $hokenColor02;
								&:before {
									background-color: $hokenColor01;
									background-image: url("../img/common/icon_hoken01.png");
								}
							}
						}
						&:nth-child(3) {
							a {
								line-height: 1.2;
								color: $kurashiColor02;
								padding-top: 7px;
								&:before {
									background-color: $kurashiColor01;
									background-image: url("../img/common/icon_kurashi01.png");
								}
							}
						}
						&:nth-child(4) {
							margin-right: 0;
							a {
								color: $sosaiColor02;
								&:before {
									background-color: $sosaiColor01;
									background-image: url("../img/common/icon_sosai01.png");
								}
							}
						}
					}
				}
			}
		}
		div {
			width: 100%;
			padding: 0 20px 23px;
			background-color: #ffffff;
			a {
				padding: 0 0 0 9px;
				font-size: 1.4rem;
				line-height: 1.2;
				display: block;
				color: $keycolor01;
				background-image: url("../img/common/icon_arrow-green06.png");
				background-repeat: no-repeat;
				-webkit-background-size: 8px;
				background-size: 8px;
				background-position: 0 1px;
			}
		}
	}
	.c-head05GnaviSp {
		margin-bottom: 20px;
		border-bottom: dotted 1px $gray07;
		.c-head05GnaviSp__navi {
			width: 100%;
			a, em {
				padding: 15px 35px 12px 18px;
				font-size: 1.6rem;
				font-weight: bold;
				font-style: normal;
				line-height: 1.5;
				color: #ffffff;
				display: block;
			}
			&.c-head05GnaviSp__navi--1 {
				background-color: $reformColor01;
				background-image: url("../img/common/icon_arrow-white01.png");
				background-repeat: no-repeat;
				-webkit-background-size: 6px;
				background-size: 6px;
				background-position: 95.5% center;
			}
			&.c-head05GnaviSp__navi--2 {
				background-color: #ffffff;
				background-image: url("../img/common/icon_arrow-green05.png");
				background-repeat: no-repeat;
				-webkit-background-size: 6px;
				background-size: 6px;
				background-position: 95.5% center;
				border-bottom: dotted 1px $gray07;
				&:nth-child(even) {
					background-color: $reformNaviColor03;
				}
				&:last-child {
					border-bottom: none;
				}
				a {
					color: $reformNaviColor02;
					padding: 14px 35px 13px 19px;
					font-size: 1.2rem;
				}
			}
			&.c-head05GnaviSp__navi--3 {
				background-color: #ffffff;
				dt {
					background-color: $reformNaviColor01;
					position: relative;
					a, em {
						width: calc(100% - 60px);
						font-size: 1.5rem;
						background: none;
						padding: 11px 35px 11px 18px;
					}
				}
				dd {
					color: $reformNaviColor02;
					background-image: url("../img/common/icon_arrow-green05.png");
					background-repeat: no-repeat;
					-webkit-background-size: 6px;
					background-size: 6px;
					background-position: 95.5% center;
					border-bottom: dotted 1px $gray07;

					&:nth-child(odd) {
						background-color: $reformNaviColor03;
					}
					&:last-child {
						border-bottom: none;
					}
					a {
						color: $reformNaviColor02;
						font-size: 1.2rem;
					}
					&.c-head05GnaviSp__navi__sub {
						padding: 0;
						background-color: #ffffff;
						background-image: none;
						border: none;
						dl {
							dt {
								background-color: $gray01;
								border-bottom: solid 1px $gray06;
								a {
									color: #ffffff;
									padding: 11px 35px 9.5px 20px;
									font-size: 1.3rem;
								}
								.c-spMenuAccordionBtn2 {
									width: 40px;
									height: 100%;
									-webkit-background-size: 20px;
									background-size: 20px;
									background-position: center center;
									background-repeat: no-repeat;
									position: absolute;
									top: 0;
									right: 0;
									z-index: 100;
									&:before {
										width: 12px;
										height: 1px;
										margin: auto;
										content: '';
										background-color: #ffffff;
										display: block;
										position: absolute;
										top: 0;
										bottom: 0;
										left: 0;
										right: 0;
										transition: transform 0.3s;
										@include tr3d0;
									}
									&:after {
										width: 1px;
										height: 12px;
										margin: auto;
										content: '';
										background-color: #ffffff;
										display: block;
										position: absolute;
										top: 0;
										bottom: 0;
										left: 0;
										right: 0;
										transition: transform 0.3s;
										@include tr3d0;
									}
								}
							}
							dd {
								display: none;
								padding: 0;
								&:first-of-type {
									//padding-top: 5px;
								}
								&:nth-child(odd) {
									background-color: $reformNaviColor03;
								}
								&:last-child {
									//padding-bottom: 5px;
								}
								a {
									padding: 8px 20px 7.5px 18px;
									color: $reformNaviColor02;
									font-size: 1.2rem;
								}
							}
							&.is-open {
								.c-spMenuAccordionBtn2 {
									&:before {
										-webkit-transform: rotate(180deg);
										transform: rotate(180deg);
									}
									&:after {
										-webkit-transform: rotate(90deg);
										transform: rotate(90deg);
									}
								}
								dd {
									display: block;
								}
							}
						}
						.c-head05GnaviSp__navi__sub__icon_reform {
							width: 29px;
							height: 29px;
							margin: 0 13px 0 0;
							display: inline-block;
							vertical-align: middle;
							-webkit-background-size: cover;
							background-size: cover;
							&.c-head05GnaviSp__navi__sub__icon_reform--1 {
								background-image: url("../img/reform/icon_reform01.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--2 {
								background-image: url("../img/reform/icon_reform02.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--3 {
								background-image: url("../img/reform/icon_reform03.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--4 {
								background-image: url("../img/reform/icon_reform04.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--5 {
								background-image: url("../img/reform/icon_reform05.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--6 {
								background-image: url("../img/reform/icon_reform06.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--7 {
								background-image: url("../img/reform/icon_reform07.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--8 {
								background-image: url("../img/reform/icon_reform08.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--9 {
								background-image: url("../img/reform/icon_reform09.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--10 {
								background-image: url("../img/reform/icon_reform10.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--11 {
								background-image: url("../img/reform/icon_reform11.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--12 {
								background-image: url("../img/reform/icon_reform12.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--13 {
								background-image: url("../img/reform/icon_reform13.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--14 {
								background-image: url("../img/reform/icon_reform14.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--15 {
								background-image: url("../img/reform/icon_reform15.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--16 {
								background-image: url("../img/reform/icon_reform16.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--17 {
								background-image: url("../img/reform/icon_reform17.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--18 {
								background-image: url("../img/reform/icon_reform18.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--19 {
								background-image: url("../img/reform/icon_reform19.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--20 {
								background-image: url("../img/reform/icon_reform20.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--21 {
								background-image: url("../img/reform/icon_reform21.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--22 {
								background-image: url("../img/reform/icon_reform22.png");
							}
							&.c-head05GnaviSp__navi__sub__icon_reform--23 {
								background-image: url("../img/reform/icon_water.png");
							}
						}
					}
				}
			}
		}
		.c-head05GnaviSp__navi__menuTitle {
			padding: 13px 18px 10px;
			font-size: 1.5rem;
			font-weight: bold;
			line-height: 1.5;
			color: #ffffff;
			background-image: none;
			background-color: $reformNaviColor04;
		}
	}
	.c-head05BtnSp {
		margin-bottom: 30px;
		padding: 10px 20px 0;
		background-color: #ffffff;
		a {
			width: 100%;
			height: 50px;
			margin-bottom: 8px;
			text-align: center;
			font-size: 1.4rem;
			font-weight: bold;
			color: #ffffff;
			display: block;
			&:before {
				margin: -3px 5px 0 0;
				content: '';
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:nth-child(1) {
				padding-top: 10px;
				letter-spacing: -1px;
				background-color: $reformBtnColor01;
				border: solid 3px $reformBtnColor02;
				&:before {
					width: 12px;
					height: 13px;
					background-image: url("../img/common/icon_file01.png");
				}
			}
			&:nth-child(2) {
				height: 45px;
				background-color: $reformBtnColor03;
				letter-spacing: -1px;
				padding-top: 11px;
				&:before {
					width: 12px;
					height: 12px;
					background-image: url("../img/common/icon_tel02.png");
				}
			}
		}
	}
}


/*
------------------------------------------------------------*/
