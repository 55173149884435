@use '../0_base' as *;

/*------------------------------------------------------------
form
------------------------------------------------------------*/

.c-form1 {
    color: #46483C;
    text-align: left;
    select,
    input,
    textarea {
        box-sizing: border-box;
        background-color: #fff;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        margin: 0;
        box-shadow: none;
        outline: none;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    select {
        width: 100%;
        &::-ms-expand {
            display: none;
        }
    }
    input {
        width: 100%;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }
        &::-webkit-input-placeholder {
            color: #989898;
        }
        &:-ms-input-placeholder {
            color: #989898;
        }
        &::placeholder {
            color: #989898;
        }
        &::-ms-clear {
            display: none;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 100px white inset !important;
        }
        &:hover,
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    // .c-form1 textarea .
    textarea {
        padding: 15px 20px;
        resize: none;
        width: 100%;
        height: 200px;
        font-size: inherit;
        line-height: inherit;
        font-weight: 500;
        color: #46483C;
        border: 1px solid #D5D5D5;
        border-radius: 2px;
        @include sp {
            height: 150px;
        }
        &::-webkit-input-placeholder {
            color: #989898;
        }
        &:-ms-input-placeholder {
            color: #989898;
        }
        &::placeholder {
            color: #989898;
        }
    }
    .u-style02 {
        th,
        input,
        select,
        textarea {
            background-color: rgba(242, 39, 39, 0.2);
        }
    }
    .error {
        margin-top: 6px;
        font-size: 1.4rem;
        font-weight: bold;
        color: #f22727;
        display: block;
        width: 100%;
        @include sp {
            font-size: 1.1rem;
        }
        &+.c-form1__title.is-style1 {
            margin-top: 22px;
            @include sp {
                margin-top: 23px;
            }
        }
        &+.c-form1__title.is-style2 {
            margin-top: 34px;
            @include sp {
                margin-top: 14px;
            }
        }
        &+.c-form1__text2 {
            margin-top: 20px;
            @include sp {
                margin-top: 23px;
            }
        }
    }
    &__grerror {
        display: flex;
        flex-wrap: wrap;
        @include pc {
            padding-left: 140px;
        }
        &+.c-form1__title.is-style2 {
            margin-top: 38px;
            @include sp {
                margin-top: 19px;
            }
        }
        .error {
            @include pc {
                width: 50%;
                max-width: 288px;
            }
        }
    }
    &__selects {
        @include pc {
            flex: 1;
            display: flex;
        }
    }
    &__select {
        position: relative;
        flex: 1;
        @include pc {
            max-width: 200px;
        }
        // .c-form1__select:before .
        &:before {
            left: 28px;
            top: 0;
            position: absolute;
            pointer-events: none;
            content: '';
            width: 8px;
            height: 100%;
            background: url(../img/hoken/common/icon_selectbox.svg) no-repeat center / 9px auto;
            transform: rotate(90deg);
            @include sp {
                left: 17px;
            }
        }
        // .c-form1__select__inner .
        &__inner {
            padding: 0 10px 0 47px;
            height: 40px;
            border: 1px solid #D5D5D5;
            border-radius: 2px;
            width: 100%;
            @include yu_gosick;
            @include sp {
                padding-left: 32px;
                border-radius: 1px;
            }
        }
    }
    // .c-form1__selectbox .
    &__selectbox {
        display: flex;
        @include pc {
            max-width: 288px;
            width: 50%;
        }
        // .c-form1__selectbox + .c-form1__selectbox .
        &+.c-form1__selectbox {
            @include sp {
                margin-top: 10px;
            }
        }
        // .c-form1__selectbox__title .
        &__title {
            padding-top: 9px;
            padding-right: 10px;
            width: max-content;
            min-width: 58px;
            @include sp {
                padding-top: 10px;
                padding-right: 5px;
                min-width: 62px;
                font-size: 1.3rem;
            }
        }
        .c-form1__input {
            @include sp {
                flex: 1;
            }
        }
    }
    &__selectgr {
        font-size: 1.6rem;
        line-height: 1.5;
        @include pc {
            display: flex;
        }
        @include sp {
            font-size: 1.4rem;
        }
        // .c-form1__selectgr__title .
        &__title {
            @include pc {
                padding-top: 9px;
                padding-right: 10px;
                width: 140px;
            }
            @include sp {
                margin-bottom: 6px;
            }
        }
        // .c-form1__selectgr + .c-form1__title.is-style2 .
        &+.c-form1__title.is-style2 {
            margin-top: 38px;
            @include sp {
                margin-top: 19px;
            }
        }
        // .c-form1__selectgr + .c-form1__selectgr .
        &+.c-form1__selectgr {
            margin-top: 20px;
            @include sp {
                margin-top: 12px;
            }
        }
    }
    // .c-form1__text2 .
    &__text2 {
        margin: 20px 0 10px;
        font-size: 1.6rem;
        line-height: 3rem;
        font-weight: 700;
        @include sp {
            margin: 23px 0 14px;
            font-size: 1.4rem;
            line-height: 2.1rem;
        }
    }
    // .c-form1__row .
    &__row {
        margin-bottom: 33px;
        @include sp {
            margin-bottom: 30px;
        }
        &.is-hide {
            display: none;
        }
    }
    // .c-form1__label .
    &__label {
        padding: 6px 3px 5px;
        font-size: 1.2rem;
        line-height: 1;
        font-weight: 700;
        color: #fff;
        min-width: 34px;
        text-align: center;
        border-radius: 2px;
        background-color: $hokenTextColor01;
        display: inline-block;
        // .c-form1__label.is-style1 .
        &.is-style1 {
            background-color: #989898;
            @include pc {
                padding: 4px 3px 3px;
            }
        }
        @include sp {
            padding: 5px 3px 4px;
            border-radius: 1px;
            font-size: 1rem;
            min-width: 28px;
        }
    }
    // .c-form1__grcontrol .
    &__grcontrol {
        padding-left: 30px;
        padding-right: 30px;
        @include sp {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    // .c-form1__textarea .
    &__textarea {
        font-size: 1.6rem;
        line-height: 1.875;
        display: flex;
        width: 100%;
        &:last-child {
            margin-bottom: 40px;
            @include sp {
                margin-bottom: 30px;
            }
        }
        @include sp {
            font-size: 1.4rem;
        }
        textarea {
            @include sp {
                padding: 10px 10px;
            }
        }
    }
    &__radio {
        display: block;
        // .c-form1__radio + .c-form1__radio .
        &+.c-form1__radio {
            margin-top: 4px;
            @include sp {
                margin-top: 13px;
            }
        }
        // .c-form1__radio + .c-form1__title.is-style2 .
        &+.c-form1__title.is-style2 {
            margin-top: 34px;
            @include sp {
                margin-top: 14px;
            }
        }
        // .c-form1__radio__label .
        &__label {
            cursor: pointer;
            position: relative;
            display: flex;
            width: max-content;
            max-width: 100%;
        }
        // .c-form1__radio__text .
        &__text {
            padding-left: 11px;
            font-size: 1.6rem;
            line-height: 1.5;
            font-weight: 500;
            flex: 1;
            @include sp {
                padding-left: 10px;
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
            // .c-form1__radio__text .is-small .
            .is-small {
                margin-top: -4px;
                font-size: 1.4rem;
                display: block;
                text-indent: -12px;
                padding-left: 11px;
                @include sp {
                    margin-top: 3px;
                    font-size: 1.2rem;
                    line-height: 1.5;
                }
            }
        }
        // .c-form1__radio input .
        input {
            min-width: 18px;
            max-width: 18px;
            width: auto !important;
            height: 18px;
            border-radius: 50%;
            border: 1px solid #707070 !important;
            cursor: pointer;
            @include sp {
                top: -1px;
                position: relative;
                min-width: 20px;
                max-width: 20px;
                height: 20px;
            }
            &:checked {
                border: 5px solid #82CDF3 !important;
            }
        }
        // .c-form1__radio + .c-form1__title.is-style1 .
        &+.c-form1__title.is-style1 {
            margin-top: 22px;
            @include sp {
                margin-top: 23px;
            }
        }
    }
    // .c-form1__title .
    &__title {
        display: flex;
        align-items: flex-start;
        // .c-form1__title:not(.is-style1) .
        &:not(.is-style1) {
            padding: 31px 30px 26px;
            margin-bottom: 20px;
            background-color: #F3F3F3;
            @include sp {
                padding: 15px 15px 9px;
                margin-bottom: 19px;
            }
        }
        // .c-form1__title.is-style1 .
        &.is-style1 {
            margin-bottom: 14px;
            @include sp {
                margin-bottom: 9px;
            }
            // .c-form1__title.is-style1 .c-form1__title__text .
            .c-form1__title__text {
                @include pc {
                    padding-left: 15px;
                }
            }
            // .c-form1__title.is-style1 .c-form1__label .
            .c-form1__label {
                position: relative;
                top: 2px;
                @include sp {
                    top: 0;
                }
            }
        }
        // .c-form1__title.is-style2 .
        &.is-style2 {
            padding: 0;
            background-color: transparent;
            margin-bottom: 14px;
            @include sp {
                margin-bottom: 11px;
            }
            // .c-form1__title.is-style2 .c-form1__title__text .
            .c-form1__title__text {
                padding-left: 10px;
                @include sp {
                    padding-left: 8px;
                }
            }
            &+.c-form1__note {
                margin-top: -10px;
                margin-bottom: 15px;
                @include pc {
                    margin-left: 15px;
                }
                @include sp {
                    margin-bottom: 15px;
                }
            }
        }
        // .c-form1__title__text .
        &__text {
            padding-left: 16px;
            flex: 1;
            display: flex;
            font-size: 1.8rem;
            line-height: 2.7rem;
            font-weight: 700;
            @include sp {
                padding-left: 8px;
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }
        // .c-form1__title__num .
        &__num {
            margin-right: 9px;
            @include gosick;
            font-size: 2rem;
            font-weight: 500;
            line-height: 1;
            min-width: max-content;
            position: relative;
            top: 1px;
            @include sp {
                margin-right: 5px;
                top: 1px;
                font-size: 1.5rem;
            }
        }
        // .c-form1__title__icon .
        &__icon {
            display: inline-flex;
            align-items: center;
            margin-left: 25px;

            @include sp {
                margin-left: 0;
            }
        }

        // .c-form1__title .c-form1__label .
        .c-form1__label {
            position: relative;
            top: 1px;
            @include sp {
                top: -1px;
            }
        }
        &.u-style02 {
            background-color: rgba(242, 39, 39, 0.2);
        }
    }
    // .c-form1__text1 .
    &__text1 {
        margin-bottom: 34px;
        padding-left: 40px;
        position: relative;
        font-size: 1.6rem;
        line-height: 1.75;
        font-weight: 700;
        color: $hokenTextColor01;
        @include sp {
            margin-bottom: 24px;
            padding-left: 33px;
            font-size: 1.4rem;
            line-height: 2.45rem;
        }
        .c-form1 {
            // .c-form1__text1 .c-form1__label .
            &__label {
                top: 2px;
                left: 0;
                position: absolute;
                @include sp {}
            }
        }
    }
    // .c-form1__table .
    &__table {
        margin-bottom: 70px;
        border-collapse: collapse;
        width: 100%;
        @include sp {
            margin-bottom: 41px;
        }
        // .c-form1__table__th .
        &__th {
            background-color: #F3F3F3;
            font-size: 1.8rem;
            line-height: 2.7rem;
            font-weight: 700;
            vertical-align: middle;
            text-align: left;
            @include pc {
                border-top: 1px solid #fff;
                width: 27.85%;
            }
            @include sp {
                margin-bottom: 13px;
                padding: 15px 15px 11px;
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }
        &__td {
            @include pc {
                border-top: 1px solid #f3f3f3;
            }
        }
        // .c-form1__table__th, .c-form1__table__td .
        &__th,
        &__td {
            @include pc {
                padding: 9px 20px 10.4px;
            }
            @include sp {
                display: block;
            }
        }
        &__tr {
            @include sp {
                margin-bottom: 20px;
                display: block;
            }
            &:first-child {
                th {
                    @include pc {
                        border-top-color: #F3F3F3;
                    }
                }
            }
            &:last-child {
                @include sp {
                    margin-bottom: 0;
                }
                th,
                td {
                    @include pc {
                        border-bottom: 1px solid #F3F3F3;
                    }
                }
            }
        }
        // .c-form1__table .c-form1__label .
        .c-form1__label {
            margin-right: 15px;
            top: -1px;
            position: relative;
            @include sp {
                margin-right: 6px;
                top: -3px;
            }
        }
    }
    &__grinput {
        @include pc {
            display: flex;
            align-items: flex-start;
        }
        .c-form1__input {
            @include pc {
                width: 50%;
                max-width: max-content;
            }
            &:not(:last-child) {
                @include pc {
                    padding-right: 30px;
                }
            }
            &+.c-form1__input {
                @include sp {
                    margin-top: 10px;
                }
            }
            input {
                @include pc {
                    flex: 1;
                    max-width: 200px;
                    width: auto;
                }
            }
        }
    }
    &__title2 {
        padding-bottom: 10px;
        margin-bottom: 30px;
        font-size: 3rem;
        line-height: 1.5;
        font-weight: 700;
        border-bottom: 2px solid blue;
        @include sp {
            padding-bottom: 10px;
            margin-bottom: 10px;
            font-size: 1.6rem;
            line-height: 1.5;
            border-bottom-width: 1px;
        }
    }
    // .c-form1__notes .
    &__notes {
        margin-top: 80px;
        padding-top: 37px;
        margin-bottom: 46px;
        border-top: 1px solid #82CDF3;
        @include sp {
            margin-top: 35px;
            padding-top: 17px;
            margin-bottom: 20px;
        }
    }
    &__note {
        padding-left: 15px;
        position: relative;
        font-size: 1.6rem;
        line-height: 1.75;
        font-weight: 500;
        text-align: left;
        @include sp {
            font-size: 1.4rem;
        }
        &:before {
            content: '※';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    // .c-form1__input .
    &__input {
        font-size: 1.6rem;
        line-height: 1;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include sp {
            font-size: 1.4rem;
        }
        &.no-wrap {
            flex-wrap: nowrap;
        }
        // .c-form1__input__note .
        &__note {
            margin-top: 5px;
            font-size: 1.4rem;
            line-height: 1.3;
            display: block;
            text-align: left;
            @include sp {
                margin-top: 10px;
                margin-bottom: -3px;
                font-size: 1.2rem;
            }
        }
        // .c-form1__input__text .
        &__text {
            padding-right: 10px;
            width: 42px;
            font-size: 1.6rem;
            line-height: 1;
            min-width: max-content;
            @include sp {
                padding-right: 10px;
                width: 40px;
                font-size: 1.3rem;
            }
            &.is-style1 {
                @include sp {
                    display: block;
                }
                &+.c-form1__input {
                    margin-top: 10px;
                    @include sp {
                        margin-top: 5px;
                    }
                }
                .c-form1__input {
                    &__right {
                        @include pc {
                            flex: 1;
                        }
                    }
                    &__text {
                        width: 140px;
                        @include sp {
                            padding-right: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
        // .c-form1__input__subtext .
        &__subtext {
            padding-right: 10px;
            width: 62px;
            font-size: 1.6rem;
            line-height: 1;
            min-width: max-content;
            @include sp {
                padding-right: 10px;
                font-size: 1.3rem;
            }
        }
        // .c-form1__input input .
        input {
            padding: 5px 9px;
            border: 1px solid #D5D5D5;
            border-radius: 2px;
            height: 40px;
            border-radius: 2px;
            @include sp {
                padding: 2px 13px;
                border-radius: 1px;
                flex: 1;
            }
            &.mw242-pc {
                @include pc {
                    max-width: 242px;
                }
            }
            &.mw258-pc {
                @include pc {
                    max-width: 258px;
                }
            }
            &.mw200-pc {
                @include pc {
                    max-width: 200px;
                }
            }
        }
        // .c-form1__input.is-style1 .
        &.is-style1 {
            align-items: flex-start;
            @include sp {
                display: block;
            }
            .c-form1__input {
                // .c-form1__input.is-style1 .c-form1__input__text .
                &__text {
                    @include pc {
                        width: 140px;
                        padding-top: 13px;
                    }
                    @include sp {
                        margin-bottom: 6px;
                        padding-right: 0;
                        font-size: 1.4rem;
                        display: block;
                    }
                }
                // .c-form1__input.is-style1 .c-form1__input__right .
                &__right {
                    @include pc {
                        flex: 1;
                        text-align: left;
                    }
                }
            }
            // .c-form1__input.is-style1 + .c-form1__input .
            &+.c-form1__input {
                margin-top: 20px;
                @include sp {
                    margin-top: 14px;
                }
            }
        }
        .c-form1__grinput {
            @include pc {
                flex: 1;
            }
        }
        // .c-form1__inputconfirm
        &confirm {
            line-height: 1.875;
        }
    }
    &__privacy {
        margin-bottom: 30px;
        padding: 20px 20px;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: 2px;
        border: 1px solid #f3f3f3;
        height: auto;
        max-height: 400px;
        @include sp {
            margin-bottom: 20px;
            padding: 15px 15px;
            max-height: 300px;
            border-radius: 1px;
        }
        &__title {
            font-size: 2rem;
            line-height: 1.5;
            font-weight: 700;
            @include sp {
                font-size: 1.6rem;
            }
        }
        &__text {
            font-size: 1.6rem;
            line-height: 1.5;
        }
    }
    &__list {
        counter-reset: li;
        &__text {
            margin-bottom: 2px;
            display: flex;
            &:before {
                content: counter(li);
                counter-increment: li;
                margin-right: 3px;
                min-width: max-content;
            }
        }
    }
    // .c-form1__text3 .
    &__text3 {
        margin-bottom: 30px;
        font-size: 1.8rem;
        line-height: 3rem;
        font-weight: 700;
        letter-spacing: 0.18px;
        @include pc {
            text-align: center;
        }
        @include sp {
            margin-bottom: 20px;
            font-size: 1.4rem;
            line-height: 2.1rem;
        }
    }
    &__text4 {
        margin-top: 5px;
        font-size: 1.4rem;
        line-height: 1.5;
        display: block;
        font-weight: 500;
        @include sp {
            margin-top: 5px;
            font-size: 1.2rem;
            color: #989898;
        }
    }
    &__textconfirm {
        @include sp {
            font-size: 1.3rem;
        }
    }
    &.is-confirm {
        .c-form1 {
            // .c-form1.is-confirm .c-form1__table .
            &__table {
                margin-bottom: 75px;
                @include sp {
                    margin-bottom: 29px;
                }
                // .c-form1.is-confirm .c-form1__table__tr .
                &__tr {
                    @include sp {
                        margin-bottom: 15px;
                    }
                }
                // .c-form1.is-confirm .c-form1__table__th, .c-form1.is-confirm .c-form1__table__td .
                &__th,
                &__td {
                    padding-top: 9.5px;
                    @include sp {
                        padding-top: 14px;
                    }
                }
                th {
                    @include sp {
                        margin-bottom: 11px;
                    }
                }
                &__td {
                    @include sp {
                        padding-top: 0;
                    }
                }
                input {
                    @include sp {
                        height: max-content;
                    }
                }
            }
            &__grinput {
                // .c-form1.is-confirm .c-form1__grinput .c-form1__input .
                .c-form1__input {
                    @include pc {
                        min-width: 273px;
                    }
                    // .c-form1.is-confirm .c-form1__grinput .c-form1__input + .c-form1__input .
                    &+.c-form1__input {
                        @include sp {
                            margin-top: 8px;
                        }
                    }
                }
            }
            // .c-form1.is-confirm .c-form1__text3 .
            &__text3 {
                margin-bottom: 33px;
                @include sp {
                    margin-bottom: 14px;
                }
            }
            // .c-form1.is-confirm .c-form1__text2 .
            &__text2 {
                margin: 16px 0 20px;
                @include sp {
                    margin: 13px 0 14px;
                }
            }
            &__title {
                // .c-form1.is-confirm .c-form1__title:not(.is-style1) .
                &:not(.is-style1) {
                    margin-bottom: 17px;
                    @include sp {
                        margin-bottom: 14px;
                    }
                }
                // .c-form1.is-confirm .c-form1__title.is-style1 .
                &.is-style1 {
                    margin-bottom: 10px;
                    @include sp {
                        margin-bottom: 5px;
                    }
                    // .c-form1.is-confirm .c-form1__title.is-style1:last-child .
                    &:last-child {
                        margin-bottom: 34px;
                        @include sp {
                            margin-bottom: 24px;
                        }
                    }
                }
                // .c-form1.is-confirm .c-form1__title.is-style2 .
                &.is-style2 {
                    margin-top: 28px;
                    margin-bottom: 9px;
                    @include sp {
                        margin-top: 19px;
                        margin-bottom: 11px;
                    }
                }
            }
            // .c-form1.is-confirm .c-form1__row .
            &__row {
                margin-bottom: 30px;
                @include sp {
                    margin-bottom: 24px;
                }
            }
            // .c-form1.is-confirm .c-form1__textarea .
            &__textarea {
                @include sp {
                    padding-left: 0;
                }
                // .c-form1.is-confirm .c-form1__textarea:last-child .
                &:last-child {
                    margin-bottom: 0;
                }
                textarea {
                    padding: 0;
                    border: 0;
                    height: max-content;
                    max-height: unset;
                    border-radius: 0;
                    background-color: transparent;
                }
            }
            // .c-form1.is-confirm .c-form1__notes .
            &__notes {
                margin-top: 76px;
                padding-top: 55px;
                margin-bottom: 33px;
                @include sp {
                    margin-top: 37px;
                    padding-top: 39px;
                    margin-bottom: 14px;
                }
            }
            // // .c-form1.is-confirm .c-form1__input .
            &__input {
                // .c-form1.is-confirm .c-form1__input__text .
                &__text {
                    width: 54px;
                    min-width: max-content;
                    @include sp {
                        width: 36px;
                    }
                }
                .c-form1__textconfirm {
                    font-weight: 500;
                }
                input {
                    padding: 0;
                    border-radius: 0;
                    border: none;
                    font-weight: 500;
                }
                &.is-style1 {
                    // .c-form1.is-confirm .c-form1__input.is-style1 + .c-form1__input .
                    &+.c-form1__input {
                        margin-top: 20px;
                        @include sp {
                            margin-top: 12px;
                        }
                    }
                    .c-form1__input {
                        // .c-form1.is-confirm .c-form1__input.is-style1 .c-form1__input__text .
                        &__text {
                            @include pc {
                                padding-top: 0;
                                width: 140px;
                                font-weight: 700;
                            }
                            @include sp {
                                width: 100%;
                                margin-bottom: 12.5px;
                            }
                        }
                        &__right {
                            input {
                                height: max-content;
                            }
                        }
                    }
                }
            }
            // .c-form1.is-confirm .c-form1__radio .
            &__radio {
                position: relative;
                @include sp {
                    padding-left: 5px;
                }
                &__label {
                    cursor: text;
                }
                &__text {
                    padding-left: 0;
                    @include pc {
                        line-height: 1.875;
                    }
                }
                input {
                    position: absolute;
                    opacity: 0;
                    z-index: -1;
                }
                // .c-form1.is-confirm .c-form1__radio + .c-form1__title .
                &+.c-form1__title {
                    margin-top: 18px;
                    @include sp {
                        margin-top: 13px;
                    }
                    // .c-form1.is-confirm .c-form1__radio + .c-form1__title.is-style2 .
                    &.is-style2 {
                        margin-top: 28px;
                        margin-bottom: 10px;
                        @include sp {
                            margin-top: 13px;
                            margin-bottom: 9px;
                        }
                    }
                }
            }
            // .c-form1.is-confirm .c-form1__selects .
            &__selects {
                @include pc {
                    padding-top: 4px;
                }
                @include sp {
                    display: flex;
                }
            }
            &__selectgr {
                // .c-form1.is-confirm .c-form1__selectgr__title .
                &__title {
                    padding-top: 0;
                    @include pc {
                        font-weight: 700;
                    }
                    @include sp {
                        margin-bottom: 2px;
                        font-size: 1.4rem;
                        line-height: 2.1rem;
                    }
                }
                // .c-form1.is-confirm .c-form1__selectgr + .c-form1__selectgr .
                &+.c-form1__selectgr {
                    margin-top: 12px;
                    @include sp {
                        margin-top: 9px;
                    }
                }
                &+.c-form1__title {
                    // .c-form1.is-confirm .c-form1__selectgr + .c-form1__title.is-style2 .
                    &.is-style2 {
                        margin-top: 31px;
                        margin-bottom: 16px;
                        @include sp {
                            margin-top: 15px;
                            margin-bottom: 13px;
                        }
                    }
                }
            }
            // .c-form1.is-confirm .c-form1__selectbox .
            &__selectbox {
                @include pc {
                    width: max-content;
                    min-width: 128px;
                }
                @include sp {
                    min-width: 101px;
                    margin-top: 0 !important;
                }
            }
            &__select {
                &__text {
                    font-size: 1.6rem;
                    line-height: 1;
                    font-weight: 500;
                    @include sp {
                        font-size: 1.4rem;
                        line-height: 2.1rem;
                    }
                }
            }
            &__grcontrol {
                // .c-form1.is-confirm .c-form1__grcontrol .c-form1__title.is-style1 .
                .c-form1__title.is-style1 {
                    @include sp {
                        padding-left: 0;
                    }
                }
            }
        }
        input {
            // .c-form1.is-confirm input:not([class^=c-btn02]) .
            &:not([class^=c-btn02]) {
                cursor: text;
                pointer-events: none;
                @include sp {
                    font-size: 1.3rem;
                }
            }
        }
        .c-checkbox {
            position: relative;
            &__text {
                cursor: text;
                font-size: 1.6rem;
                line-height: 3rem;
                @include sp {
                    font-size: 1.4rem;
                    line-height: 2.1rem;
                }
            }
            input {
                opacity: 0;
                position: absolute;
                z-index: -1;
            }
        }
    }
    &__checkbox {
        // .c-form1__checkbox__label .
        &__label {
            padding-left: 29px;
            position: relative;
            display: inline-block;
            cursor: pointer;
            @include sp {
                padding-left: 30px;
            }
        }
        // .c-form1__checkbox__text .
        &__text {
            font-size: 1.6rem;
            line-height: 3rem;
            font-weight: 500;
            @include sp {
                font-size: 1.4rem;
                line-height: 2.1rem;
            }
        }
        // .c-form1__checkbox input .
        input {
            top: 3px;
            width: 18px;
            height: 18px;
            left: 0;
            border-radius: 2px;
            border: 1px solid #707070;
            position: absolute;
            @include sp {
                top: 1px;
                border-radius: 1px;
                width: 20px;
                height: 20px;
            }
            &:checked {
                background: #82CDF3 url(../img/hoken/common/icon_checked.svg) no-repeat center / 11px auto;
                border-color: #82CDF3;
                @include sp {
                    background-size: 12px auto;
                }
            }
        }
        // .c-form1__checkbox.is-term .
        &.is-term {
            display: flex;
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            text-align: center;
            .c-form1__checkbox {
                &__label {
                    padding-left: 26px;
                    @include sp {}
                }
                &__text {
                    font-size: 1.8rem;
                    line-height: 1.8;
                    vertical-align: middle;
                    @include sp {
                        font-size: 1.3rem;
                        line-height: 1.5;
                    }
                }
            }
            input {
                width: 16px;
                height: 16px;
                border-color: #c7c7c7;
                top: 50%;
                transform: translateY(-50%);
                &:checked {
                    border-color: #82CDF3;
                }
            }
        }
        // .c-form1__checkbox.is-pink .
        &.is-pink {
           input {
            &:checked {
                border-color: $ticketColor01;
                background-color: $ticketColor01;
            }
           }
        }
        // .c-form1__checkbox.is-coopGreen .
        &.is-coopGreen {
            input {
             &:checked {
                 border-color: #8CB340;
                 background-color: #8CB340;
             }
            }
         }
        // .c-form1__checkbox + .c-form1__checkbox .
        &+.c-form1__checkbox {
            margin-top: -2px;
            @include sp {
                margin-top: 11px;
            }
        }
        &+.c-btn02 {
            margin-top: 40px;
            @include sp {
                margin-top: 40px;
            }
        }
    }
    .c-table2__radio {
        margin: 0;
        font-size: 1.6rem;
        @include sp {
            font-size: 1.4rem;
        }
        &+.c-table2__radio {
            margin-top: 4px;
            @include sp {
                margin-top: 13px;
            }
        }
        &.is-pink {
            input {
                &:checked {
                    &+label:before {
                        border-color: #FC819B !important;
                        background-color: #FC819B;
                    }
                }
            }
        }
        &.is-coopGreen {
            input {
                &:checked {
                    &+label:before {
                        border-color: #8CB340 !important;
                        background-color: #8CB340;
                    }
                }
            }
        }
        li {
            width: 100%;
            margin-right: 0;
            text-align: left;
            .is-small {
                margin-top: -4px;
                font-size: 1.4rem;
                display: block;
                text-indent: -12px;
                padding-left: 11px;
                @include sp {
                    margin-top: 3px;
                    font-size: 1.2rem;
                    line-height: 1.5;
                }
            }
        }
        input {
            &+label {
                &:before {
                    border-color: #707070 !important;
                    width: 18px;
                    height: 18px;
                    @include sp {
                        width: 20px;
                        height: 20px;
                    }
                }
                &:after {
                    left: 5px;
                    width: 8px;
                    height: 8px;
                    @include sp {
                        left: 6px;
                    }
                }
            }
            &:checked {
                &+label:before {
                    border-color: #82cdf3 !important;
                    background-color: #82cdf3;
                }
            }
        }
        // .c-form1 .c-table2__radio + .c-form1__title.is-style1 .
        &+.c-form1__title.is-style1 {
            margin-top: 22px;
            @include sp {
                margin-top: 23px;
            }
        }
        // .c-form1 .c-table2__radio + .c-form1__title.is-style2 .
        &+.c-form1__title.is-style2 {
            margin-top: 34px;
            @include sp {
                margin-top: 14px;
            }
        }
    }
    .c-table2__checkbox {
        margin: 0;
        font-size: 1.6rem;
        @include sp {
            font-size: 1.4rem;
        }
        &.is-coopGreen {
            input {
                &:checked {
                    &+label:before {
                        border-color: #8CB340 !important;
                        background-color: #8CB340;
                    }
                }
            }
        }
        li {
            width: 100%;
            margin-right: 0;
            text-align: left;

            + li {
                margin-top: 7px;
            }
        }
        input {
            display: none;
            + label {
                position: relative;
                display: flex;
                column-gap: 3px;
                cursor: pointer;
                &:before {
                    content: '';
                    display: inline-block;
                    border-radius: 2px;
                    min-width: 14px;
                    width: 14px;
                    height: 14px;
                    border: 1px solid #58595B;
                    border-color: #707070 !important;
                    width: 18px;
                    height: 18px;
                    @include sp {
                        min-width: 20px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            &:checked {
                &+label:before {
                    background: url(../img/hoken/common/icon_checked.svg) no-repeat center;
                    border-color: #8CB340 !important;
                    background-color: #8CB340;
                }
            }
        }
    }
    .c-table2__rating {
        + .c-table2__rating {
            margin-top: 15px;
        }

        &__text {
            margin-bottom: 5px;
            font-size: 1.6rem;
            @include sp {
                font-size: 1.4rem;
            }
        }

        &__input {
            height: 25px;
            display: flex;
            column-gap: 5px;
            position: relative;
    
            &__status {
                width: fit-content;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: flex;
                column-gap: 5px;
                pointer-events: none;
    
                div {
                    width: 25px;
                    height: 25px;
                    mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50" viewBox="0 0 50 50"><path d="M50 19.0395V19.2655L34.6861 30.8475L40.6926 49.887L40.5303 50L25 38.1921L9.4697 50L9.25325 49.887L15.2597 30.8475L0 19.2655V19.0395H18.9394L24.8918 0H25.0541L31.0065 19.0395H50Z" /></svg>');
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: 100% 100%;
                    background-color: #D5D5D5;
    
                    &.is-active {
                        background-color: #8CB340;
                        opacity: 1;
                    }
                }
            }
    
            input {
                display: none;
            }
    
            label {
                cursor: pointer;
                display: block;
                width: 25px;
                height: 25px;
                text-indent: -9999px;
                overflow: hidden;
            }
        }
    }
    .c-table2__checkbox01 {
        li {
            float: none;
            display: block;
            margin-right: 0;
            text-align: left;
        }
    }
    .c-checkbox {
        &.is-disable {
            pointer-events: none;
            input {}
            .c-checkbox {
                &__text {
                    color: #D5D5D5 !important;
                    &:before {
                        border-color: #D5D5D5 !important;
                        background-color: #F3F3F3 !important;
                    }
                }
            }
        }
    }
    .c-formPrivacyText__txt {
        text-align: left;
    }
}


/*
------------------------------------------------------------*/

textarea,
input {
    @include yu_gosick();
}
