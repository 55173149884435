@use '../0_base' as *;

/*------------------------------------------------------------
side03（チケット）
------------------------------------------------------------*/
.c-side03 {
	.c-sideNavi03__btn01 {
		width: 240px;
		height: 74px;
		margin-bottom: 25px;
		color: #ffffff;
		background-color: $ticketBtnColor03;
		position: relative;
		a {
			width: 100%;
			height: 100%;
			padding-left: 20px;
			display: block;
			font-size: 1.8rem;
			font-weight: 500;
			line-height: 3.8;
			color: #ffffff;
			border: solid 3px $ticketBtnColor04;
			position: relative;
			z-index: 10;
		}
		span {
			width: 31px;
			height: 31px;
			font-size: 1.3rem;
			font-weight: normal;
			text-align: center;
			background-image: url("../img/common/icon_cart01.png");
			-webkit-background-size: 28px;
			background-size: 28px;
			background-repeat: no-repeat;
			background-position: 0 2px;
			position: absolute;
			top: 20px;
			right: 20px;
		}
	}
	.c-sideNavi03__btn02 {
		width: 240px;
		height: 110px;
		margin-bottom: 6px;
		text-align: center;
		background-color: $ticketBtnColor01;
		a {
			width: 100%;
			height: 100%;
			padding-top: 15px;
			display: block;
			color: $gray03;
			font-size: 1.4rem;
			font-weight: 500;
			line-height: 1.6;
			border: solid 3px $ticketBtnColor02;
			b {
				font-size: 1.8rem;
				font-weight: 500;
				display: block;
			}
			span {
				margin-top: 5px;
				color: $ticketBtnColor03;
				display: block;
				&:before {
					width: 16px;
					height: 20px;
					margin: -3px 10px 0 0;
					content: '';
					background-image: url("../img/common/icon_door01.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}

	.c-sideNavi03__link01 {
		margin-bottom: 13px;
		font-size: 1.5rem;
		font-weight: 500;
		&:before {
			width: 6px;
			height: 10px;
			margin: -3px 10px 0 0;
			content: '';
			background-image: url("../img/common/icon_arrow-pink01.png");
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
		a {
			color: $linkColor01;
			text-decoration: underline;
			&:hover{
				opacity: 1;
				text-decoration: none;
			}
		}
	}

	.c-side03Navi {
		.c-side03Navi__title {
			margin-bottom: 7px;
			font-size: 1.6rem;
			font-weight: 600;
			color: $ticketNaviColor02;
			&:before {
				margin: -3px 10px 0 0;
				content: '';
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&.c-side03Navi__title--1 {
				&:before {
					width: 20px;
					height: 20px;
					background-image: url("../img/common/icon_search03.png");
				}
			}
			&.c-side03Navi__title--2 {
				&:before {
					width: 24px;
					height: 24px;
					background-image: url("../img/common/icon_ticket03.png");
				}
			}
		}
		.l-right {
			margin-bottom: 17px;
		}
	}
}
/*
------------------------------------------------------------*/
