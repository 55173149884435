@use '../../0_base' as *;

$coop_dantai-color01: #82CDF4;
$coop_dantai-color02: #253B73;
$coop_dantai-color03: #46483C;
$coop_dantai-color04: #E9F4F8;
$coop_dantai-color05: #256DAA;
$coop_dantai-color06: #FFF58A;

.c-list10{
	display: flex;
	flex-wrap: wrap;

	@include sp{
		display: block;
	}

	&__item{
		width: 50%;
		margin-bottom: 38px;

		@include sp{
			width: 100%;
			margin-bottom: 40px;

			&:not(:last-child){
				margin-bottom: 12px;
			}
		}

		&:nth-child(2n){
			padding-left: 30px;

			@include sp{
				padding-left: 0;
			}
		}

		&:nth-child(2n + 1){
			padding-right: 30px;

			@include sp{
				padding-right: 0;
			}
		}
	}
}

.c-card04{
	border: 1px solid #DDDDDD;
	border-radius: 20px;
	position: relative;
	padding: 60px;

	@include sp{
		border-radius: 10px;
		padding: 30px;
	}


	&::before{
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100% - 120px);
		height: 9px;
		background: $coop_dantai-color01;
		border-radius: 0 0 6px 6px;

		@include sp{
			height: 5px;
			border-radius: 0 0 5px 5px;
			width: calc(100% - 60px);
		}
	}

	&__content{
		padding: 28px 0 21px;

		@include sp{
			padding: 19px 0 15px;
		}
	}

	&__title{
		font-size: 2.4rem;
		letter-spacing: 0.05em;
		line-height: 36px;
		font-weight: bold;
		color: $coop_dantai-color02;
		margin-bottom: 14px;

		@include sp{
			font-size: 2rem;
			line-height: 30px;
			margin-bottom: 10px;
		}
	}

	&__text{
		font-size: 1.6rem;
		line-height: 28px;
		font-weight: 500;

		@include sp{
			font-size: 1.4rem;
			line-height: 25px;
		}
	}

	&__txt{
		font-size: 1.4rem;
		line-height: 24.5px;
		font-weight: 500;
		margin-top: 9px;

		@include sp{
			font-size: 1.2rem;
			line-height: 21px;
		}
	}

	&__tag{
		display: flex;
		margin-top: 15px;

		@include sp{
			margin-top: 14px;
		}

		&Label{
			font-size: 1.4rem;
			font-weight: bold;
			color: $coop_dantai-color02;
			margin-right: 10px;
			min-width: 56px;
			position: relative;
			top: 1px;

			@include sp{
				font-size: 1.2rem;
				min-width: 50px;
				margin-right: 7px;
				top: 0;
			}
		}
	}

	&__lsttag{
		display: flex;
		flex-wrap: wrap;

		&Item{
			background: $coop_dantai-color04;
			padding: 4px 6px;
			border-radius: 4px;
			color: $coop_dantai-color02;
			font-size: 1.2rem;
			font-weight: 500;
			margin: 0 10px 8px 0;
			max-height: 26px;

			@include sp{
				font-size: 1rem;
				padding: 2px 4px 0;
				border-radius: 2px;
				margin: 0 5px 5px 0;
			}
		}
	}

	&__bt{

		&Inner{
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 -5px;

			@include max-w(374px){
				display: block;
				margin: 0;
			}
		}
	}

	&__btn01{
		padding: 0 5px;
		width: 50%;

		@include max-w(374px){
			width: 100%;
			padding: 0;
		}

		& + .c-card04__btn01{
			@include max-w(374px){
				margin-top: 10px;
			}
		}

		a{
			display: block;
			text-align: center;
			background: #fff;
			color: $coop_dantai-color05;
			font-size: 1.6rem;
			font-weight: bold;
			letter-spacing: 0.05em;
			border: 1px solid $coop_dantai-color05;
			position: relative;
			padding: 12px 0 10px;
			border-radius: 70px;
			text-indent: 12px;

			@include sp{
				font-size: 1.2rem;
				letter-spacing: 0.025em;
				text-indent: 9px;
				padding:  9px 0;
			}

			@include hover{
				opacity: 0.4;
			}

			&::before{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 19px;
				background: $coop_dantai-color05 url('../img/lp/coop_dantai-hoken/icon-copy.svg') no-repeat center;
				background-size: 13px 15px;
				height: 30px;
				width: 30px;
				border-radius: 50%;

				@include sp{
					height: 23px;
					width: 23px;
					background-size: 10px 11px;
					left: 10px;
				}
			}
		}

		&.is-style01{
			a{
				border-color: $coop_dantai-color02;
				color: $coop_dantai-color02;
				text-indent: 40px;

				@include sp{
					text-indent: 28px;
				}

				&::before{
					background: $coop_dantai-color02 url('../img/lp/coop_dantai-hoken/icon-mail.svg') no-repeat center;
					background-size: 13px 10px;

					@include sp{
						background-size: 10px 8px;
					}
				}
			}
		}
	}

	&__btn02{
		margin-top: 20px;

		@include sp{
			margin-top: 15px;
		}
		a{
			display: block;
			text-align: center;
			background: $coop_dantai-color06 url('../img/lp/coop_dantai-hoken/icon-arrow.svg') no-repeat;
			background-size: 8px 14px;
			background-position: center right 45px;
			color: $coop_dantai-color02;
			font-size: 2rem;
			font-weight: bold;
			letter-spacing: 0.05em;
			padding: 25px 20px 23px;
			border-radius: 60px;
			text-indent: -26px;

			@include hover{
				opacity: 0.4;
			}

			@include sp{
				font-size: 1.4rem;
				background-size: 6px 12px;
				border-radius: 35px;
				padding: 14px 0;
				background-position: center right 30px;
			}

			@include max-w(374px){
				background-position: center right 15px;
				font-size: 1.2rem;
				text-indent: -18px;
			}
		}
	}
}

#p-coop_dantai-hoken{
	@include yu_gosick;
	color: $coop_dantai-color03;

	.l-container{
		width: 100%;
		max-width: 1120px;
	}

	.c-hokenNote {
	    margin-top: 100px;

	    @include sp {
	    	margin-top: 30px;
	    }
	}

	.c-CoopDantaiHeader{
		background: $coop_dantai-color01;
		border-radius: 0 0 60px 60px;
		margin: 0 40px;
		text-align: center;
		padding: 51px 0 41px;

		@include sp{
			margin: 0 20px;
			border-radius: 0 0 30px 30px;
			padding: 40px 0 32px;
			min-width: auto;
		}

		.c-cpHeader{

			&__title{
				@include sp{
					width: 67%;
					margin: 0 auto;
					max-width: 450px;
				}
			}

			&__text{
				font-size: 2.4rem;
				line-height: 36px;
				color: #fff;
				font-weight: bold;
				margin-top: 29px;

				@include sp{
					font-size: 1.4rem;
					line-height: 25px;
					padding: 0 30px;
					text-align: left;
					margin-top: 26px;
				}
			}
		}
	}

	.c-CoopDantaiFooter{
		&__inner{
			margin: 0 40px;

			@include sp{
				margin: 0;
			}
		}
	}

	.c-cpContact{
		background: url('../img/lp/coop_dantai-hoken/bg-contact.jpg') no-repeat;
		background-size: cover !important;
		padding: 10px;
		border-radius: 20px;
		margin: 10px 0 80px;

		@include sp{
			border-radius: 10px;
			background: url('../img/lp/coop_dantai-hoken/bg-contact-sp.jpg') no-repeat;
			margin: 0 20px 40px;
		}

		&__inner{
			background: #fff;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 55px 80px 54px;
			white-space: nowrap;

			@include sp{
				display: block;
				white-space: normal;
				padding: 25px 30px 27px;
				border-radius: 5px;
				text-align: center;
			}

			@include max-w(374px){
				padding: 20px 15px;
			}
		}

		&__title{
			font-size: 3.4rem;
			line-height: 51px;
			font-weight: bold;
			letter-spacing: 0.05em;
			color: $coop_dantai-color02;
			padding-right: 60px;

			@include sp{
				font-size: 2.2rem;
				line-height: 33px;
				letter-spacing: 0.025em;
				padding-right: 0;
			}
		}

		&__content{
			padding-left: 60px;
			position: relative;

			@include sp{
				padding-left: 0;
				margin-top: 13px;
				padding-top: 21px;
			}

			&::before{
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
				height: 152px;
				border-left: 1px solid #707070;

				@include sp{
					left: 50%;
					top: 0;
					transform: translateX(-50%);
					height: 1px;
					width: 100%;
					border-left: 0;
					border-top: 1px solid #707070;
				}
			}
		}

		&__phone{
			position: relative;
			padding-left: 100px;

			@include sp{
				padding-left: 0;
			}

			&::before{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				background: $coop_dantai-color02 url('../img/lp/coop_dantai-hoken/icon-phone.svg') no-repeat center;
				background-size: 34px 34px;
				height: 86px;
				width: 86px;
				border-radius: 50%;

				@include sp{
					position: relative;
					display: block;
					top: 0;
					left: 0;
					transform: none;
					width: 43px;
					height: 43px;
					background-size: 17px 17px;
					margin: 0 auto 8px;
				}
			}

			&Num{
				font-family: 'Roboto', sans-serif;
				font-size: 6.7rem;
				font-weight: bold;
				color: $coop_dantai-color02;
				line-height: 1;

				a{
					color: $coop_dantai-color02;
					pointer-events: none;

					@include sp{
						pointer-events: inherit;
					}
				}

				@include sp{
					font-size: 3.95rem;
					white-space: nowrap;
				}

				@include max-w(374px){
					font-size: 3rem;
				}
			}

			&Text{
				font-size: 1.6rem;
				line-height: 28px;
				font-weight: 500;
				margin-top: 1px;
				text-indent: -11px;

				@include sp{
					font-size: 1.2rem;
					text-indent: 0;
					line-height: 21px;
					margin-top: 5px;
				}

				span{
					font-weight: bold;
					font-family: 'Roboto', sans-serif;
				}
			}
		}

		&__tit{
			font-size: 2.4rem;
			line-height: 36px;
			font-weight: bold;
			letter-spacing: 0.01em;
			color: $coop_dantai-color02;
			margin: 6px 0 -2px;

			@include sp{
				font-size: 1.4rem;
				line-height: 18px;
				margin: 14px 0 6px;
			}
		}

		&__text{
			font-size: 1.6rem;
			line-height: 28px;
			font-weight: 500;

			@include sp{
				font-size: 1rem;
				line-height: 14px;
			}
		}
	}

	.c-cpCopyright{
		background: #F8F8F8;
		font-size: 2rem;
		font-family: 'Roboto', sans-serif;
		text-align: center;
		padding: 28px 0;

		@include sp{
			font-size: 0.9rem;
			line-height: 10px;
			padding: 15px 0;
		}
	}

	.c-cpGotop{
		position: fixed;
		bottom: 40px;
		right: 40px;

		@include sp{
			width: 90px;
			bottom: 20px;
			right: 20px;
		}
	}
}

.p-coop_dantai-hoken{

	&01{
		padding: 132px 40px 0;

		@include sp{
			padding: 42px 20px 0;
		}
	}
}
