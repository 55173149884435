@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　お葬式の流れ
------------------------------------------------------------*/
.p-sosaiFlow {
	background-color: #ffffff;
	font-size: 1.8rem;
	line-height: 1.77;
	color: $gray08;
	.c-breadcrumb1{
		padding: 7px 0 85px;
	}
}
//お葬式の流れ
.p-sosaiFlow01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//お葬式の流れ 本文
.p-sosaiFlow02 {
	margin-bottom: 40px;
	&__content01 {
		margin-bottom: 120px;
		> p {
			margin-bottom: 52px;
		}
		.c-list04{
			border: 3px solid #29806c;
			margin-bottom: 35px;
			&__item{
				padding: 27px 0 64px 0;
			}
			&__icon {
				position: relative;
				margin-top: -8px;
				&:before {
					width: 12px;
					height: 22px;
					margin: 0 auto;
					content: '';
					background-image: url("../img/common/icon_arrow-blue04.png");
					-webkit-background-size: cover;
					background-size: cover;
					opacity: 0.6;
					transform: rotate(90deg);
					position: absolute;
					top: 131%;
					left: 0;
					right: 0;
				}
			}
		}
		.c-supplement {
			text-align: center;
		}
	}
	&__content02 {
		.c-sosaiTitle10 {
			margin-bottom: 16px;
		}
	}
}

//流れ詳細リスト
.p-sosaiFlowList {
	font-size: 1.7rem;
	line-height: 1.77;
	b {
		font-weight: 700;
	}
	> li {
		padding: 101px 0;
		position: relative;
		&:after {
			width: 30px;
			height: 48px;
			margin: 0 auto;
			content: '';
			background-image: url("../img/component/3arrow02.png");
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			bottom: -24px;
			left: 0;
			right: 0;
			z-index: 10;
		}
		&:nth-child(odd) {
			background-color: $glaylight02;
		}&:last-child {
			&:after {
				content: none;
			}
		}
		.u-inner01 {
			position: relative;
		}
		&:nth-child(1){
			.p-sosaiFlowList__title{
				transform: translateY(-5px);
				img{
					margin: -5px 15px 0 0;
				}
			}
			.c-sosaiUketsuke02{
				h2{
					padding: 10px 35px 9px;
					font-weight: normal;
					span{
						margin: -12px 0 0 17px;
						padding: 1px 40px 0px 30px
					}
				}
				&__boxArea{
					padding: 37px 25px 37px 50px;
				}
				&__box{
					h3{
						margin-bottom: 9px;
						span {
							margin-left: 9px;
						}
					}
					a {
						span {
							display: block;
							color: #333333;
							&:nth-child(1) {
								font-size: 1.5rem;
								font-weight: bold;
								margin-bottom: 2px;
							}
							&:nth-child(2) {
								font-size: 4rem;
								padding-left: 38px;
								position: relative;
								line-height: 1;
								@include mincyo();
								&:before {
									content: '';
									width: 28px;
									height: 28px;
									background: url("../img/common/icon_tel06.png");
									position: absolute;
									top: 3px;
									left: 1px;
								}
							}
						}
					}
					&__tel{
						.tel-number{
							margin-top: -2px;
	    					letter-spacing: 3px;
	    					&:before{
	    						margin: -14px 9px 0 0;
	    					}
	    				}
					}
					+ .c-sosaiUketsuke02__box{
						margin-top: 22px;
						padding-top: 23px;
					}
				}
				ul {
					li {
						margin-bottom: 17px;
					}
				}
			}
		}
		&:nth-child(2){
			.p-sosaiFlowList__title{
				transform: translateY(-5px);
				margin-bottom: 14px;
				img{
					margin: -5px 15px 0 0;
				}
			}
			.p-sosaiFlowList__txt03 p{
				margin-bottom: 17px;
			}
			.p-sosaiFlowList__txt03__annotation{
				li{
					margin-bottom: 9px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
		&:nth-child(3){
			.p-sosaiFlowList__title{
				transform: translateY(-5px);
				margin-bottom: 11px;
				img{
					margin: -5px 15px 0 0;
				}
			}
			.p-sosaiFlowList__txt03 p{
				margin-bottom: 46px;
			}
			.c-sosaiTitle10{
				font-size: 2.0rem;
				margin-bottom: 20px;
			}
			.p-sosaiFlowList__txt03{
				.p-sosaiFlowList__box01{
					margin-top: -5px;
				}
			}
			.p-sosaiFlowList__txt04{
				padding-top: 56px;
				ul{
					margin-top: -3px;
					li{
						h3{
							margin: 33px 0 15px;
						}
						p{
							padding: 0 40px 32px 40px;
						}
					}
				}
			}
		}
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(7)
		{
			padding: 101px 0 79px;
			.p-sosaiFlowList__title{
				margin-bottom: 13px;
			}
		}
		&:nth-child(8){
			padding: 101px 0 61px;
		}
	}
	&__txt01 {
		width: 660px;
	}
	&__txt02 {
		padding: 70px 0 30px 0;
		clear: both;
		p {
			font-size: 2.0rem;
		}
	}
	&__txt03 {
		min-height: 240px;
		padding-right: 460px;
		position: relative;
		p {
			margin-bottom: 20px;
			img {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		&__annotation {
			font-size: 1.4rem;
			li {
				padding-left: 1em;
				text-indent: -1em;
			}
		}
		.p-sosaiFlowList__box01 {
			ul {
				@include dib;
				li {
					width: 50%;
					font-weight: 700;
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 10px 0 0;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
	}
	&__txt04 {
		padding-top: 60px;
		clear: both;
		ul {
			li {
				width: 353px;
				margin-right: 30px;
				background-color: #ffffff;
				&:last-child {
					margin-right: 0;
				}
				h3 {
					margin: 20px 0;
					font-size: 2.0rem;
					font-weight: 700;
					text-align: center;
					color: $sosaiNaviColor02;
				}
				p {
					padding: 0 40px 40px 40px;
					font-size: 1.6rem;
				}
			}
		}
	}
	&__img01 {
		width: 400px;
		img {
			margin-bottom: 25px;
		}
		&__annotation {
			padding-left: 1em;
			font-size: 1.5rem;
			text-indent: -1em;
			line-height: 1.6;
		}
	}
	&__title {
		margin-bottom: 20px;
		font-size: 3.4rem;
		font-weight: 500;
		color: $gray08;
		transform: translateY(-5px);
		@include mincyo;
		img {
			width: 50px;
			margin: -5px 15px 0 0;
			vertical-align: middle;
		}
	}
	&__box01 {
		padding: 35px 40px 32px;
		background-color: #ffffff;
		border: solid 1px $glaylight13;
	}
}

//関連コンテンツ
.p-sosaiFlow03 {
	padding: 105px 0 140px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
}

@include sp {
	.p-sosaiFlow {
		font-size: 1.6rem;
		padding-bottom: 59px;
	}
	//お葬式の流れ　sp
	.p-sosaiFlow01 {
		min-width: 100%;
		margin-bottom: 45px;
		padding: 32px 0 33px;
	}

	//お葬式の流れ 本文 sp
	.p-sosaiFlow02 {
		margin-bottom: 20px;
		&__content01 {
			margin-bottom: 50px;
			padding: 0 20px;
			> p {
				margin-bottom: 24px;
				font-size: 1.4rem;
				line-height: 1.5;
			}
			.c-list04{
				width: calc(100% + 10px);
				margin: 0 0 15px -5px;
				border: 2px solid #29806c;
				&__item{
					padding: 14px 15px 13px 14px;
				}
				&__number{
				}
				&__icon {
					position: static;
					width: 15%;
					img{
						vertical-align: middle;
					}
					&:before {
						content: none;
					}
				}
			}
		}
		&__content02 {
			.c-sosaiTitle10 {
				font-size: 1.5rem;
				margin-bottom: 1px;
			}
		}
	}

	//流れ詳細リスト sp
	.p-sosaiFlowList {
		font-size: 1.6rem;
		> li {
			padding: 52px 20px;
			&:after {
				width: 30px;
				height: 48px;
			}
			&:nth-child(1){
				.c-sosaiUketsuke02 {
					border: none;
					padding-top: 10px;
					background: url(../img/sosai/sosai_bg_urgency_sp.png) no-repeat top 10px right;
					background-size: cover;
					> img {
						top: 0;
					}
					h2 {
						display: block;
						font-size: 2.3rem;
						line-height: 2.5rem;
						padding: 12px 98px 13px 10px;
						text-align: center;
						height: 115px;
						span {
							display: block;
							font-size: 1.1rem;
							line-height: 1.3rem;
							margin: 5px auto 0;
							padding: 5px 10px 4px;
							max-width: 130px;
							letter-spacing: 0;
							border-radius: 20px;
						}
					}
					&__boxArea {
						border: 1px solid #c8c8c8;
						border-top: none;
						padding: 14px 20px 7px;
						.u-flr--pc {
							&:nth-child(2) {
								.c-sosaiUketsuke02__box {
									margin-bottom: 0;
									padding-bottom: 0;
								}
							}
						}
					}
					&__box{
						margin-bottom: -2px;
						padding-bottom: 15px;
						text-align: left;
						h3{
							font-size: 1.9rem;
							margin-bottom: 4px;
							&:before {
								margin: -5px 6px 0 0;
							}
							span {
								margin-left: 4px;
								font-size: 1.3rem;
							}
						}
						a {
							display: block;
							background: #2e95a3;
							text-align: center;
							position: relative;
							border-radius: 5px;
							box-shadow: 0px 3px 0px #1f6267;
							padding: 8px 0;
							div {
								width: 185px;
								margin: 0 auto;
								position: relative;
								padding-left: 39px;
								&:before {
									content: '';
									width: 25px;
									height: 25px;
									background: url("../img/common/icon_tel07.png");
									background-size: contain;
									position: absolute;
									top: 9px;
									left: 0;
								}
							}
							span {
								display: block;
								color: #fff;
								font-weight: bold;
								text-align: left;
								line-height: 1.3;
								&:nth-child(1) {
									font-size: 1rem;
									margin-bottom: 0;
								}
								&:nth-child(2) {
									font-size: 1.8rem;
									padding-left: 0;
									font-family: 'Roboto',"游ゴシック", YuGothic,"Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
									&:before {
										content: none;
									}
								}
							}
						}
						&__tel{
							.tel-number{
								margin-top: -2px;
								letter-spacing: 3px;
								&:before{
									margin: -14px 9px 0 0;
								}
							}
						}
						+ .c-sosaiUketsuke02__box{
							margin-top: 1px;
							padding-top: 14px;
						}
					}
					ul {
						li {
							margin-bottom: 8px;
						}
					}
				}
				.p-sosaiFlowList__title img {
					margin: -6px 6px 0 0;
				}
			}
			&:nth-child(2) {
				padding-bottom: 46px;
				.p-sosaiFlowList {
					&__title {
						margin-bottom: 11px;
						img {
							margin: -7px 6px 0 0;
						}
					}
					&__txt03 {
						p {
							font-size: 1.4rem;
							line-height: 1.5;
							margin-bottom: 12px;
							img {
								margin-bottom: 14px;
							}
						}
						&__annotation {
							li {
								font-size: 1rem;
								font-weight: bold;
								line-height: 1.3;
								margin-bottom: 5px;
							}
						}
					}
				}
			}
			&:nth-child(3) {
				padding-bottom: 42px;
				.p-sosaiFlowList {
					&__title {
						img {
							margin: -7px 9px 0 0;
						}
					}
					&__txt03 {
						p {
							font-size: 1.5rem;
							line-height: 1.5;
							margin-bottom: 19px;
							img {
								margin-bottom: 15px;
							}
						}
					}
					&__txt04 {
						padding-top: 24px;
						ul {
							li {
								display: flex;
								padding: 0;
								margin-bottom: 10px;
								h3 {
									font-size: 1.5rem;
									margin: 0 0 0;
									padding: 16px 20px 1px;
									text-align: left;
									line-height: 1.5;
								}
								p {
									font-size: 1.3rem;
									padding: 0 20px 0;
									line-height: 1.5;
									span {
										font-size: 1.1rem;
										font-weight: bold;
										line-height: 1.3;
										display: block;
										margin-top: 6px;
										text-indent: -10px;
										padding-left: 10px;
									}
								}
							}
						}
						&__img {
							width: 90px;
						}
						&__text {
							width: calc(100% - 90px);
						}
					}
					&__box01 {
						padding: 15px 19px 13px;
						ul {
							li {
								font-size: 1.4rem;
								line-height: 1.88;
								&:before {
									margin-right: 4px;
								}
							}
						}
					}
				}
				.c-sosaiTitle10 {
					font-size: 1.5rem;
					margin-bottom: 12px;
				}
			}
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8){
				padding: 52px 20px 25px;
				.p-sosaiFlowList {
					&__title {
						margin-bottom: 10px;
						img {
							margin: -7px 10px 0 0;
						}
					}
					&__txt03 {
						p {
							font-size: 1.4rem;
							line-height: 1.5;
							img {
								margin-bottom: 16px;
							}
						}
					}
				}
			}
		}
		&__txt01 {
			width: 100%;
		}
		&__txt02 {
			padding: 23px 0 17px;
			p {
				font-size: 1.4rem;
				line-height: 1.5;
			}
			.u-txtCenter {
				text-align: left;
			}
		}
		&__txt03 {
			min-height: auto;
			padding-right: 0;
			position: static;
			p {
				margin-bottom: 20px;
				img {
					margin: 10px auto;
					display: block;
					position: static;
				}
			}
			&__annotation {
				font-size: 1.2rem;
			}
			.p-sosaiFlowList__box01 {
				ul {
					li {
						width: 100%;
						display: block;
					}
				}
			}
		}
		&__txt04 {
			padding-top: 30px;
			clear: both;
			ul {
				li {
					width: 100%;
					margin: 0 0 10px 0;
					padding: 15px;
					h3 {
						margin: 10px 0;
						font-size: 1.8rem;
					}
					p {
						padding: 0;
						font-size: 1.4rem;
					}
				}
			}
		}
		&__img01 {
			width: 100%;
			margin: 0;
			text-align: center;
			img {
				margin: 0;
			}
			&__annotation {
				margin-top: 19px;
				font-size: 1rem;
				font-weight: bold;
				text-align: left;
				line-height: 1.2;
			}
		}
		&__title {
			margin-bottom: 11px;
			font-size: 2.1rem;
			img {
				width: 30px;
				margin: -6px 6px 0 0;
			}
		}
		&__box01 {
			padding: 15px;
			margin-top: 20px;
			p {
				font-size: 1.4rem;
				line-height: 1.5;
			}
		}
	}


	//関連コンテンツ sp
	.p-sosaiFlow03 {
		padding: 50px 15px 39px;
		.c-sosaiTitle02b {
			margin-bottom: 40px;
		}
		.c-sosaiKanrenLink01 {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 48%;
				padding-bottom: 9px;
				margin-bottom: 11px;
				&:after {
					bottom: 3px;
				}
				img {
					margin-bottom: 5px;
				}
				a {
					font-size: 1.2rem;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
