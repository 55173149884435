@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス お申込み方法
------------------------------------------------------------*/
.p-kurashiApplication {
	background-color: #ffffff;
}

//お申し込み前のご確認事項
.p-kurashiApplication01 {
	margin: -6px 0 41px 0;
	&__content01 {
		font-size: 1.6rem;
		line-height: 1.75;
		color: $kurashiTextColor01;
		dl {
			dt {
				margin-bottom: 2px;
				font-weight: bold;
				&:before {
					width: 12px;
					height: 12px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $kurashiColor01;
					display: inline-block;
					vertical-align: middle;
					@include radius(4px);
				}
			}
			dd {
				margin-bottom: 20px;
			}
			a {
				color: $linkColor01;
				text-decoration: underline;
				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
		}
	}
}

//お申し込み方法
.p-kurashiApplication02 {
	&__content01 {
		margin-bottom: 18px;
		font-size: 1.6rem;
		line-height: 1.75;
		color: $kurashiTextColor01;
	}
	&__content02 {
		font-size: 1.6rem;
		line-height: 1.75;
		color: $kurashiTextColor01;
		ol {
			li {
				margin-bottom: 28px;
				position: relative;
				border-bottom: dashed 1px rgba($kurashiTextColor03,0.3);
				img {
					position: absolute;
				}
				&:nth-child(1) {
					min-height: 213px;
					padding: 0 300px 30px 0;
					img {
						top: 1px;
						right: 0;
					}
				}
				&:nth-child(2) {
					min-height: 180px;
					padding: 0 300px 30px 0;
					img {
						top: 35px;
						right: 0;
					}
				}
				&:nth-child(3) {
					margin: 0;
					border: none;
				}
				h3 {
					margin-bottom: 5px;
					color: $kurashiTextColor04;
					font-size: 1.8rem;
					font-weight: bold;
					&:before {
						width: 25px;
						height: 25px;
						margin: -5px 10px 0 0;
						content: counter(count01);
						line-height: 1.38;
						color: #ffffff;
						background-color: $kurashiColor01;
						text-align: center;
						display: inline-block;
						vertical-align: middle;
						@include radius(50%);
					}
				}
			}
		}
		&__contact {
			width: 410px;
			padding: 22px 25px 13px;
			margin-top: 4px;
			border: solid 5px $subColor14;
			dt {
				margin-bottom: 5px;
				font-weight: bold;
				&:before {
					width: 12px;
					height: 12px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $kurashiColor01;
					display: inline-block;
					vertical-align: middle;
					@include radius(4px);
				}
			}
			dd {
				p {
					margin-bottom: 5px;
					font-size: 1.4rem;
					line-height: 1.5;
					color: $subColor05;
				}
			}
			&__tel {
				font-size: 1.4rem;
				font-weight: bold;
				.pc-only {
					color: $subColor05;
					span {
						font-size: 2.2rem;
						&:before {
							width: 21px;
							height: 18px;
							margin: -5px 5px 0 5px;
							content: '';
							background-image: url("../img/common/icon_tel03.png");
							-webkit-background-size: cover;
							background-size: cover;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
}

@include sp {
	.p-kurashiApplication {
		padding: 30px 20px;
	}

	//お申し込み前のご確認事項 sp
	.p-kurashiApplication01 {
		margin-bottom: 20px;
		&__content01 {
			dl {
				font-size: 1.4rem;
				dd {
					margin-bottom: 15px;
				}
			}
		}
	}

	//お申し込み方法 sp
	.p-kurashiApplication02 {
		&__content01 {
			font-size: 1.4rem;
		}
		&__content02 {
			font-size: 1.4rem;
			ol {
				li {
					min-height: auto !important;
					margin-bottom: 15px;
					padding: 0 0 15px 0 !important;
					position: static;
					img {
						margin: 0 auto 10px auto;
						display: block;
						position: static;
					}
					&:nth-child(3) {
						margin: 0 !important;
					}
					h3 {
						font-size: 1.6rem;
						&:before {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
			&__contact {
				width: 100%;
				margin-bottom: 10px;
				dd {
					p {
						font-size: 1.2rem;
					}
				}
				&__tel {
					font-size: 1.2rem;
					a {
						display: block;
						text-align: center;
						background-color: #4b5870;
						display: block;
						border: solid 3px #939ba9;
						padding: 10px 5px 8px;
						color: #ffffff;
						span {
							display: block;
							&:nth-child(1) {
								font-size: 1.8rem;
								font-weight: bold;
								margin-bottom: 2px;
								line-height: 1;
								&:before {
									width: 21px;
									height: 16px;
									margin: -3px 5px 0 0;
									content: '';
									background-image: url("../img/common/icon_tel01.png");
									background-size: cover;
									display: inline-block;
									vertical-align: middle;
								}
							}
							&:nth-child(2) {
								font-size: 1.1rem;
								font-weight: normal;
							}
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
