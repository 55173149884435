@use '../../0_base' as *;

/*------------------------------------------------------------
チケットご利用者アンケート 確認画面
------------------------------------------------------------*/
.p-ticketEnqueteConfirm {
	padding: 41px 0 133px;

	@include sp {
		padding: 19px 20px 35px;
	}

	&1 {

		.c-step3 {
			margin-top: 43px;
			margin-bottom: 80px;

			@include sp {
				margin-top: 20px;
				margin-bottom: 40px;
			}
		}
	}

  &2 {

    .c-form1 {
      &__row {
        .c-form1__title {
          &.mt22 {
            margin-top: 22px;
          }
        }
        &:last-child {
          margin-bottom: 70px;

          @include sp {
            margin-bottom: 41px;
          }
        }
      }

      &__title {
        &.is-block {
          display: block;
        }
      }

      &__text3 {
        margin: 75px 0 50px!important;

        @include sp {
          margin: 29px 0 40px!important;
        }
      }

      .c-ticketText01 {
        font-size: 1.6rem;
        font-weight: 500;
        margin-top: 5px;
        display: inline-block;

        @include sp {
          padding-left: 23.08px;
          font-size: 1.4rem;
        }
      }
    }
  }
}
