@use '../0_base' as *;

/*------------------------------------------------------------
footer08
------------------------------------------------------------*/
.c-footer08 {
	&.is-fixed {
		width: 100%;
		left: 0;
		bottom: 0;
		position: fixed;
	}
}
