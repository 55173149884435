@use '../../0_base' as *;

/*------------------------------------------------------------
かんたん資金シミュレーション
------------------------------------------------------------*/
.p-hokenSimulation {
	.c-breadcrumb1 {
		padding: 19px 0 56px;
	}
	.c-hokenTitle03 {
		background-image: url("../img/hoken/title_obi_simulation.jpg");
		padding: 30px 0 28px 34px;

		@include sp {
			background-image: url("../img/hoken/title_obi_simulation_sp.jpg");
		}
	}

	&__text1 {
		font-size: 1.6rem;
		line-height: 2.8rem;
		color: #46483c;

		@include sp {
			font-size: 1.4rem;
      line-height: 1.75;
		}
	}

	&__box {
		width: 590px;
		margin: 40px auto 0;
		text-align: center;

		@include sp {
			width: 100%;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&__img {
		width: 100%;
		height: auto;
	}

	.c-hokenBtn02 {
		width: 500px;
		margin: 22px auto 0;

		@include sp {
			width: auto;
      margin: 10px 20px 0;
		}

		a {
			padding: 11px 12px 10px 0;
			font-size: 1.4rem;

			@include sp {
				font-size: 1.3rem;
			}
		}
	}

	.c-hokenBtn02__icon {
    width: auto;
    height: auto;
		transform: translateY(3px);
		margin-left: 4px;
	}

	&__note1 {
		font-size: 1.4rem;
		line-height: 2.8rem;
		color: #46483c;
		margin-top: 10px;

		@include sp {
			font-size: 1.1rem;
		}
	}

	&01 {
		@include sp {
			padding: 0 20px;
		}

		.c-hokenTitle02 {
			margin: 33px 0 24px;
			padding: 10px 10px 10px 26px;

			@include sp {
				margin: 17px 0 15px;
				padding: 5px 5px 5px 17px;
			}
		}

		.c-hokenTitle05 {
			margin-bottom: 13px;

			@include sp {
				margin-bottom: 7px;
			}
		}

		&__list {
			margin-top: 16px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;

			@include sp {
				margin-top: 0;
				display: block;
			}

			li {
				width: 410px;
				margin-left: 20px;

				@include sp {
					display: block;
					width: 100%;
					margin-left: 0;
					margin-top: 15px;
				}

				&::first-child {
					margin-left: 0;
				}

				img {
					@include sp {
						width: 100%;
					}
				}
			}
		}
	}

	&02 {
		padding-top: 41px;

		@include sp {
			padding: 20px 20px 0;
		}

		.c-hokenTitle02 {
			margin-bottom: 24px;

			@include sp {
				margin-bottom: 15px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
