@use '../../0_base' as *;

/*------------------------------------------------------------
チケット ログイン
------------------------------------------------------------*/
.p-ticketLogin {
	padding: 30px 0 135px 0;
	background-color: #ffffff;
}

//ログイン
.p-ticketLogin01 {
	font-size: 1.6rem;
	line-height: 1.75;
	color: $ticketNaviColor02;
	&__content01 {
		margin-bottom: 28px;
		&__annotation {
			margin: 7px 0 27px 0;
			font-size: 1.4rem;
		}
		&__annotation2 {
			font-size: 1.4rem;
			color: $gray02;
		}
		.c-ticketTitle04 {
			padding-top: 24px;
			margin-bottom: 11px;
		}
	}

	&__content02 {
		margin-bottom: 54px;
		&__bg {
			padding: 30px;
			background-color: $glaylight10;
		}
		&__box01 {
			width: 400px;
			margin-right: 40px;
			ul {
				li {
					margin-bottom: 16px;
					label {
						font-weight: bold;
						display: block;
						margin-bottom: 2px;
					}
					input {
						width: 100%;
						padding: 12px 10px;
						border: solid 1px $ticketBtnColor05;
						border-radius: 0px;
					}
					select {
						width: 100%;
						padding: 10px;
						border: solid 1px $ticketBtnColor05;
						border-radius: 0px;
					}
				}
			}
			.c-cartBtn {
				margin-top: 31px;
				text-align: center;
				&__btn02b {
					width: 200px;
					height: 50px;
				}
			}
		}
		&__box02 {
			width: 400px;
			.c-ticketBtn02{
				width: 220px;
				margin: 29px auto 35px;
				a {
					padding: 30px 0;
				}
			}
			&__annotation {
				border-top: dashed 1px $gray02;
				padding-top: 30px;
				a {
					color: $linkColor01;
					text-decoration: underline;
				}
			}
		}
	}
	&__content03 {
		.c-formComplete {
			p {
				text-align: center;
				color: $ticketNaviColor02;
			}
			&__inquiry {
				width: 840px;
				margin: 12px auto 30px auto;
				border: solid 5px $ticketSubColor01;
				h3 {
					color: $ticketNaviColor02;
				}
				p {
					color: $ticketNaviColor02;
				}
			}
		}
	}

}

@include sp {
	.p-ticketLogin {
		padding: 30px 20px 25px 20px;
	}

	//ログイン
	.p-ticketLogin01 {
		font-size: 1.5rem;
		&__content01 {
			margin-bottom: 24px;
			&__txt {
				font-size: 1.4rem;
				line-height: 1.6;
			}
			&__annotation {
				margin: 1px 0 9px;
				font-size: 1.1rem;
				font-weight: bold;
			}
			&__annotation2 {
				font-size: 1.1rem;
				font-weight: bold;
				line-height: 1.6;
			}
			.c-ticketTitle04 {
				padding-top: 23px;
				margin-bottom: 6px;
				font-size: 1.8rem;
			}

		}
		&__content02 {
			margin-bottom: 28px;
			&__bg {
				padding: 24px 30px 20px;
				background-color: $glaylight10;
			}
			&__txt {
				font-size: 1.4rem;
			}
			&__box01 {
				width: 100%;
				margin: 0 0 17px 0;
				ul {
					li {
						margin-bottom: 15px;
						label {
							font-size: 1.4rem;
							margin-bottom: 4px;
						}
						select {
							font-size: 1.4rem;
						}
					}
				}
				.c-cartBtn {
					margin-top: 32px;
					&__btn02b {
						width: 100%;
						max-width: 160px;
						height: 40px;
						-webkit-appearance: none;
						-webkit-border-radius:0;
						border-radius:0;
					}
				}
				.c-ticketTitle02b {
					font-size: 1.4rem;
					background-size: 4px;
					margin-bottom: 16px;
					em:after {
						height: 2px;
					}
				}
			}
			&__box02 {
				width: 100%;
				.c-ticketBtn02{
					width: 100%;
					max-width: 160px;
					margin: 11px auto 22px;
					a {
						padding: 20px 0;
					}
				}
				&__annotation {
					padding: 15px 0 9px;
					font-size: 1.3rem;
					line-height: 1.6;
				}
				.c-ticketTitle02b {
					font-size: 1.4rem;
					background-size: 4px;
					margin-bottom: 16px;
					em:after {
						height: 2px;
					}
				}
			}
		}
		&__content03 {
			.c-formComplete {
				font-size: 1.4rem;
				&__inquiry {
					width: 100%;
					margin: 17px auto 42px auto;
					padding: 13px 15px 15px;
				}
			}
			.c-ticketBtn03{
				max-width: 210px;
				margin: 15px auto;
			}
		}

	}
}

/*
------------------------------------------------------------*/
