@use '../../0_base' as *;

/*------------------------------------------------------------
保険 保険見直しのタイミング
------------------------------------------------------------*/
.p-hokenTimingEvent {
    padding: 20px;
    border: 1.5px solid #D9D9D9;
    border-radius: 5px;
    position: relative;

    .c-hokenAnchor {
        position: absolute;
    }

    @include pc {
        padding: 40px;
        border-width: 3px;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 400px 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 30px;
    }

    &__title {
        font-size: 1.9rem;
        font-weight: 700;
        @include pc {
            font-size: 2.4rem;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }

    &__image {
        margin-top: 15px;

        @include pc {
            margin-top: 0;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        img {
            width: 100%;
            border-radius: 5px;
            @include pc {
                border-radius: 10px;
            }
        }
    }

    &__text {
        margin-top: 15px;
        color: #222;
        font-size: 1.4rem;
        @include pc {
            flex: 1;
            font-size: 1.6rem;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }
}

/*
------------------------------------------------------------*/
