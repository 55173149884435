@use '../0_base' as *;

/*------------------------------------------------------------
breadcrumb
------------------------------------------------------------*/
.c-breadcrumb1 {
	padding: 11px 0 60px 0;
	color: $gray01;
	ul {
		@include dib;
		li {
			font-size: 1.2rem;
			font-weight: 500;
			&:after {
				width: 6px;
				height: 6px;
				margin: 0 0.5em;
				content: '';
				border-top: 1px solid $gray01;
				border-right: 1px solid $gray01;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				display: inline-block;
				vertical-align: middle;
			}
			&:last-child {
				&:after {
					content: none;
				}
			}
			a {
				color: $gray01;
				text-decoration: none;
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
		}
	}
	&--service {
		padding: 15px 0 40px 0;
		ul {
			li {
				a {
					color: $linkColor01;
				}
			}
		}
	}
	&--ticket {
		ul {
			li {
				a {
					color: $linkColor01;
				}
			}
		}
	}
	&--hoken {
		ul {
			li {
				a {
					color: $linkColor02;
				}
			}
		}
	}
	&--reform {
	}
	&--kurashi{
		padding: 42px 0 55px 0;
		color: $kurashiTextColor01;
		ul {
			li {
				&:after {
					border-top: 1px solid $gray02;
					border-right: 1px solid $gray02;
				}
				a {
					color: $gray02;
				}
			}
		}
	}
	&--sosai {
		color: $gray09;
		ul {
			li {
				&:after {
					border-top: 1px solid $gray09;
					border-right: 1px solid $gray09;
				}
				a {
					color: $gray09;
				}
			}
		}
	}
}

.c-breadcrumb2 {
    margin-bottom: 30px;
	color: #41B4E6;

    @include pc {
        margin-bottom: 80px;
    }

	ul {
        font-size: 1.2rem;
        @include pc {
            font-size: 1.6rem;
        }
        :not(:last-child) {
            &::after {
                content: '>';
            }
        }

		li {
            display: inline;
			a {
				color: inherit;
				text-decoration: none;
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
		}
	}
	&--service {
		padding: 15px 0 40px 0;
		ul {
			li {
				a {
					color: $linkColor01;
				}
			}
		}
	}
	&--ticket {
		ul {
			li {
				a {
					color: $linkColor01;
				}
			}
		}
	}
	&--hoken {
		ul {
			li {
				a {
					color: $linkColor02;
				}
			}
		}
	}
	&--reform {
	}
	&--kurashi{
		padding: 42px 0 55px 0;
		color: $kurashiTextColor01;
		ul {
			li {
				&:after {
					border-top: 1px solid $gray02;
					border-right: 1px solid $gray02;
				}
				a {
					color: $gray02;
				}
			}
		}
	}
	&--sosai {
		color: $gray09;
		ul {
			li {
				&:after {
					border-top: 1px solid $gray09;
					border-right: 1px solid $gray09;
				}
				a {
					color: $gray09;
				}
			}
		}
	}
}




/*
------------------------------------------------------------*/
