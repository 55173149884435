@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　樹木葬
------------------------------------------------------------*/
.p-sosaiTimber {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//樹木葬
.p-sosaiTimber01 {
	min-width: $baseWidth01;
	padding: 43px 0 46px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		margin: 0 0 2px 0;
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//樹木葬 本文
.p-sosaiTimber02 {
	margin-bottom: 130px;
	padding-top: 25px;
	&__content01 {
		min-height: 240px;
		padding-right: 445px;
		position: relative;
		> img {
			position: absolute;
			top: 8px;
			right: 0;
		}
		> p {
			margin-bottom: 30px;
			line-height: 3rem;
		}
	}
}

//樹木葬のメリット
.p-sosaiTimber03 {
	margin-bottom: 107px;
	padding: 106px 0 120px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		padding-left: 10px;
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	&__content01 {
		.c-sosaiTextBox01 {
			background-color: #ffffff;
			> div{
				padding: 36px 40px 32px;
				.c-sosaiTitle10{
					margin-bottom: 15px;
					&:before{
						margin: -5px 10px 0 -30px;
					}
				}
				p{
					line-height: 3rem;
					padding-left: 2px;
				}
			}
		}
		dl {
			margin-top: -1px;
			background-color: $glaylight02;
			border: solid 1px $glaylight13;
			dt {
			    width: 146px;
			    padding: 34px 20px 30px 0;
			    font-size: 1.6rem;
			    font-weight: 700;
			    text-align: center;
			}
			dd {
				width: calc(100% - 146px);
				padding: 32px 0 30px;
				font-size: 1.4rem;
				line-height: 2.9rem;
			}
		}
	}
}

//樹木葬の流れ
.p-sosaiTimber04 {
	margin-bottom: 128px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
}

//お問い合わせ
.p-sosaiTimber05 {
	margin-bottom: 130px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiAfterServiceContact >div a span{
		margin-left: -50px;
	}
}

//葬儀後のサービス
.p-sosaiTimber06 {
	padding: 107px 0 118px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiAfterServiceLink{
		padding-top: 0;
	}
}


@include sp {
	.p-sosaiTimber {
		font-size: 1.6rem;
	}
	//樹木葬sp
	.p-sosaiTimber01 {
		min-width: 100%;
		margin-bottom: 35px;
		padding: 15px 0;
		> span {
			font-size: 1.2rem;
			margin: 0 0 7px 0;
		}
		.c-sosaiPagetitleCate{
			span{
				width: 37px;
			    height: 16px;
			    font-size: 1rem;
			    line-height: 1.6rem;
			}
			&__Shizuoka{
				width: 35px;
				margin-right: 0;
			}
		}
	}

	//樹木葬 本文 sp
	.p-sosaiTimber02 {
		margin-bottom: 45px;
		padding: 0 20px;
		&__content01 {
			padding-right: 0;
			position:static;
			.sp-only {
				display: block;
			}
			> img {
				margin: 0 auto 8px;
				position: static;
			}
			> p{
				font-size: 1.4rem;
				line-height: 1.5;
			}
		}
	}

	//樹木葬のメリット sp
	.p-sosaiTimber03 {
		margin-bottom: 47px;
		padding: 47px 20px 49px;
		.c-sosaiTitle02b {
		    padding-left: 5px;
		    margin-bottom: 40px;
		    span{
		    	&:after{
		    		margin: 10px auto 0;
		    	}
		    }
		}
		&__content01 {
			.c-sosaiTextBox01{
				> div{
					padding: 15px 20px 13px;
					.c-sosaiTitle10 {
					    margin-bottom: 4px;
					    &:before{
					    	margin: -3px 5px 0 -26px;
					    }
					}
					p{
						font-size: 1.4rem;
						line-height: 1.7;
						padding-left: 0;
					}
				}
			}
			dl {
				dt {
					width: 100%;
					padding: 16px 19px 5px;
					font-size: 1.4rem;
					text-align: left;
				}
				dd {
					width: 100%;
					padding: 4px 15px 15px 19px;
					p{
						font-size: 1.4rem;
						line-height: 1.7;
						padding-left: 13px;
						position: relative;
						span{
							position: absolute;
							top: 0;
							left: 0;
						}
						+ p{
							margin-top: 6px;
						}
					}
				}
			}
		}
	}


	//樹木葬の流れ sp
	.p-sosaiTimber04 {
		margin-bottom: 78px;
		padding: 0 15px;
		.c-sosaiTitle02b {
		    margin-bottom: 40px;
		    span{
		    	&:after{
		    		margin: 10px auto 0;
		    	}
		    }
		}
		.c-sosaiFlow01 > li h3 {
			font-size: 1.8rem;
		}
	}

	//お問い合わせ sp
	.p-sosaiTimber05 {
		margin-bottom: 50px;
		padding: 0 15px;
		.c-sosaiTitle02b {
		    margin-bottom: 31px;
		    span{
		    	&:after{
		    		margin: 10px auto 0;
		    	}
		    }
		}
	}

	//葬儀後のサービス sp
	.p-sosaiTimber06 {
		padding: 47px 20px 56px;
		margin-bottom: 60px;
		.c-sosaiTitle02b {
		    margin-bottom: 40px;
		    span{
		    	&:after{
		    		margin: 10px auto 0;
		    	}
		    }
		}

	}

}

/*
------------------------------------------------------------*/
