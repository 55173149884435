@use '../../0_base' as *;

.p-anniversaryEnqueteService {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding: 80px 0 40px;
	background-image: url("../img/service/bg03.jpg");
	position: relative;

	&::before {
		width: 100%;
		height: 4px;
		content: '';
		background-image: url("../img/service/line_colorful01_pc.jpg");
		background-repeat: repeat-x;
		background-position: center 0;
		position: absolute;
		top: 0;
		left: 0;
	}

	.c-serviceTitle01 {
		margin-bottom: 55px;
	}
	.p-serviceTop04__contents {
		margin-bottom: 60px;
	}

	@include sp {
		min-width: 100%;
		padding: 40px 20px 20px;
		.c-serviceTitle01 {
			margin-bottom: 14px;
		}
		.p-serviceTop04__contents {
			margin-bottom: 30px;
		}
	}
}