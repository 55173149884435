@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　斎場情報一覧（神奈川）
------------------------------------------------------------*/
.sosaiHallKanagawa {
	background-color: #ffffff;
}

//葬儀場を探す
.sosaiHallKanagawa01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//神奈川県の葬儀場
.sosaiHallKanagawa02 {
	margin-bottom: 90px;
	&__content01 {
		position: relative;
	}
}
@media (min-width: $breakpoint01 + 1) {
	.sosaiHallKanagawa02 {
		&__content01 {
			.c-sosaiTitle04 {
				height: 400px;
				margin: 0;
				text-align: left;
				position: absolute;
				top: 0;
				right: -14px;
				-webkit-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				img {
					margin: 0 6px 0 0;
				}
				margin-bottom: 14px;
			}
		}
	}
}

//神奈川県の葬儀場一覧
.sosaiHallKanagawa03 {
	padding: 70px 0 110px 0;
	background-color: $subColor10;
	&__content01 {
		//コンポーネント　c-sosaiHallList
		.c-sosaiHallList {
			margin-bottom: 58px;
			dl{
				dd{
					padding: 50px 50px 53px;
					@include sp{
						padding: 15px 15px 20px;
					}
					table{
						td{
							padding: 24px 20px 20px 10px;
							@include sp{
								padding: 13px 0 12px 0;
							}
						}
						th{
							padding: 28px 10px 23px;
							@include sp{
								padding: 10px 0 10px 5px
							}
						}
					}
				}
			}
			&__detail{

			}
		}
		//コンポーネント　c-sosaiPager01
	}
	.c-sosaiTitle02 span{
		&:after{
			margin: 10px auto 0 auto;
		}

	}
}


@include sp {
	//葬儀場を探す
	.sosaiHallKanagawa01 {
		min-width: 100%;
		margin-bottom: 40px;
		padding: 32px 0 33px;

	}
	//神奈川県の葬儀場
	.sosaiHallKanagawa02 {
		margin-bottom: 50px;
		&__content01 {
			padding: 0 15px;
		}
		.c-sosaiTitle04{
			margin-bottom: 28px;
			img{
				width: 20%;
				margin: 9px auto 0;
			}
		}
		.c-sosaiAreaList01 img {
   			 margin: 0 auto 38px auto;
		}
	}
	//神奈川県の葬儀場一覧
	.sosaiHallKanagawa03 {
		margin-bottom: -65px;
		padding: 48px 0 90px 0;
		background-color: #faf9f6;
		.c-sosaiTitle02{
			margin-bottom: 40px;
			span:after {
			    margin: 7px auto 0 auto;
			}
		}

		&__content01 {
			padding: 0 15px;
			//コンポーネント　c-sosaiHallList
			.c-sosaiHallList {
				margin-bottom: 20px;
				dt{
					padding: 16px 10px 14px 57px;
				}
			}
			//コンポーネント　c-sosaiPager01
		}
	}
}

/*
------------------------------------------------------------*/
