@use '../0_base' as *;

/*------------------------------------------------------------
side04（保険）
------------------------------------------------------------*/

.c-side04 {
	.c-side04__box01 {
		width: 240px;
		margin-bottom: 28px;
		font-size: 1.6rem;
		font-weight: bold;
		text-align: center;
		background-color: #ffffff;
		border: solid 1px $hokenColor01;
		overflow: hidden;
		@include radius(4px);
		dt {
			padding: 11px 0;
			color: $hokenNaviColor02;
			background-color: $hokenNaviColor01;
			border-bottom: solid 1px $glaylight01;
			span {
				width: 65px;
				margin-right: 5px;
				font-size: 2.4rem;
				color: $hokenTextColor01;
				display: inline-block;
				text-align: right;
			}
		}
		dd {
			padding: 21px 19px;
			a {
				width: 100%;
				padding: 16px 0 15px;
				display: block;
				font-size: 1.8rem;
				color: #ffffff;
				background-image: url("../img/hoken/bg01.jpg");
				@include radius(6px);
				&:before {
					width: 40px;
					height: 40px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: #ffffff;
					background-image: url("../img/common/icon_file02.png");
					-webkit-background-size: 18px;
					background-size: 18px;
					background-repeat: no-repeat;
					background-position: center center;
					display: inline-block;
					vertical-align: middle;
					@include radius(50%);
				}
			}
		}
	}
	.c-side04Navi {
		.l-right {
			margin-bottom: 20px;
		}
	}
}

/*
------------------------------------------------------------*/
