@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　葬儀後の手続き
------------------------------------------------------------*/
.p-sosaiAfter {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//葬儀後の手続き
.p-sosaiAfter01 {
	min-width: $baseWidth01;
	padding: 66px 0 67px;
	padding: 63px 0 69px;;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//葬儀後の手続き2
.p-sosaiAfter02 {
	margin-bottom: 120px;
	padding-top: 16px;
	&__content01 {
		margin: 69px 0 78px;
	}
	&__content02 {
		margin-bottom: 60px;
	}
}

//葬儀後の手続き リスト
.p-sosaiAfterProcedureList {
	ul {
		+ ul {
			margin-top: -1px;
		}
		li {
			padding: 39px 40px 33px;
			border-left: solid 1px $glaylight13;
			border-bottom: solid 1px $glaylight13;
			h4 {
				margin-bottom: 18px;
				line-height: 1.4;
				font-weight: 700;
				color: $sosaiNaviColor02;
			}
			dl {
				padding-bottom: 17px;
				margin-bottom: 24px;
				font-size: 1.6rem;
				border-bottom: dotted 1px $gray07;
				dt {
					font-weight: 700;
					padding-bottom: 4px
				}
				dd {
					max-width: 72%;
				}
			}
			p {
				font-size: 1.6rem;
				line-height: 1.75;
			}
		}
	}
	&__w1-2 {
		width: 50%;
		&:nth-child(even), &:last-child {
			border-right: solid 1px $glaylight13;
		}
		&:nth-child(1), &:nth-child(2) {
			border-top: solid 1px $glaylight13;
		}
		h4 {
			font-size: 2.0rem;
		}
	}
	&__w1-3 {
		width: 33.3333333%;
		&:nth-child(3n), &:last-child {
			border-right: solid 1px $glaylight13;
		}
		&:nth-child(1), &:nth-child(2), &:nth-child(3) {
			border-top: solid 1px $glaylight13;
		}
		padding: 39px 40px 32px !important;
		h4 {
			font-size: 1.8rem;
			margin-bottom: 22px !important;
		}
	}
	&__label {
		margin: 0 0 0.2rem 0;
		padding: 0 10px;
		font-size: 1.2rem;
		font-weight: 700;
		text-align: center;
		color: #ffffff;
		display: block;
		transform: translate(0,0.2rem);
		&--red {
			background-color: $sosaiSizuokaColor01;
		}
		&--blue {
			background-color: $sosaiYamanashiColor01;
		}
		&--green {
			background-color: $sosaiKanagawaColor01;
		}
	}
}

//葬儀後のサービス
.p-sosaiAfter03 {
	margin-bottom: 105px;
	padding: 105px 0 118px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
}

//関連サービス
.p-sosaiAfter04 {
	margin-bottom: 140px;
	ul {
		padding-top: 6px;
		li {
			width: 33.3333333%;
			padding: 35px 40px 33px;
			text-align: center;
			position: relative;
			border-top: solid 1px $glaylight13;
			border-bottom: solid 1px $glaylight13;
			border-left: solid 1px $glaylight13;
			&:last-child {
				border-right: solid 1px $glaylight13;
			}
			&:after {
				width: 10px;
				height: 10px;
				content: '';
				background-image: url("../img/common/icon_button_sankaku02_blue.png");
				-webkit-background-size: cover;
				background-size: cover;
				position: absolute;
				bottom: 3px;
				right: 2px;
			}
			a {
				margin-bottom: 15px;
				font-size: 2.0rem;
				font-weight: 700;
				color: $sosaiNaviColor02;
				display: block;
				img {
					margin: -4px 16px 0 -16px;
					display: inline-block;
					vertical-align: middle;
				}
			}
			p {
				font-size: 1.5rem;
				line-height: 1.75;
				text-align: left;
			}
		}
	}
}

@include sp {
	.p-sosaiAfter {
		font-size: 1.4rem;
	}
	//葬儀後の手続き sp
	.p-sosaiAfter01 {
		min-width: 100%;
		margin-bottom: 42px;
		padding: 32px 0 33px;;
	}

	//葬儀後の手続き2 sp
	.p-sosaiAfter02 {
		margin-bottom: 40px;
		padding: 0 20px;
		&__content01 {
			margin: 30px 0;
		}
		&__content02 {
			margin-bottom: 30px;
		}
	}

	//葬儀後の手続き リスト sp
	.p-sosaiAfterProcedureList {
		ul {
			li {
				margin-top: -1px;
				padding: 15px;
				&:first-child {
					margin-top: 0;
				}
				h4 {
					margin-bottom: 10px;
					font-size: 1.6rem !important;
				}
				dl {
					padding-bottom: 10px;
					margin-bottom: 10px;
					dd {
						max-width: 67%;
					}
				}
				p {
					font-size: 1.4rem;
				}
			}
		}
		&__w1-2, &__w1-3 {
			width: 100%;
			padding: 15px !important;
			border: none !important;
			border: solid 1px $glaylight13 !important;
			h4 {
				font-size: 1.6rem;
				margin-bottom: 10px !important;
			}
		}
		&__label {
			font-size: 1rem;
			padding: 0 5px;
		}
	}

	//葬儀後のサービス sp
	.p-sosaiAfter03 {
		margin-bottom: 40px;
		padding: 40px 20px;
	}
	//関連サービス sp
	.p-sosaiAfter04 {
		margin-bottom: 40px;
		padding: 0 20px;
		ul {
			li {
				width: 100%;
				margin-bottom: 15px;
				padding: 15px;
				border: solid 1px $glaylight13;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					margin-bottom: 15px;
					font-size: 1.7rem;
				}
				p {
					font-size: 1.4rem;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
