//baseWidth
//$baseWidth1:1000px;
$baseWidth01:1120px;

// Breakpoint
//$breakpoint1:767px;
//$breakpoint2:480px;
$breakpoint01:640px;

// Common Colors
//$color1:#;
//$color2:#;
//$color3:#;

// link color
//$link:#000;
//$hover:#000;
//$active:#000;
//$visited:#000;

// キーカラー
$keycolor01: #649333;
$keycolor02: #8cb340;

//サブカラー
$subColor01: #fcfbf5;
$subColor02: #fafcf7;
$subColor03: #f0662b;
$subColor04: #e0dccc;
$subColor05: #445161;
$subColor06: #fdfacf;
$subColor07: #fefce2;
$subColor08: #9ac985;
$subColor09: #fffdeb;
$subColor10: #faf9f6;
$subColor11: #fef4f4;
$subColor12: #4b5870;
$subColor13: #939ba9;
$subColor14: #fef3e6;
$subColor15: #fbf7f1;
$subColor16: #ea3d3d;
$subColor17: #f5f3ed;
$subColor18: #edeef0;
$subColor19: #f6738b;
$subColor20: #f7f5f1;

//リンク
$linkColor01: #308bbb;
$linkColor02: #253b73;


//強調
$strongColor01: #ef004f;
$strongColor02: #f22727;
$strongColor03: #d33f10;
$strongColor04: #f60b26;


// グレー
$gray01: #46483c;
$gray02: #999999;
$gray03: #666666;
$gray04: #333333;
$gray05: #9ca69c;
$gray06: #7e7f77;
$gray07: #4c4c4c;
$gray08: #111111;
$gray09: #646464;
$gray10: #777777;

$glaylight01: #dddddd;
$glaylight02: #f8f8f8;
$glaylight03: #f9f9f9;
$glaylight04: #eeeeee;
$glaylight05: #ced5e0;
$glaylight06: #dfdfdf;
$glaylight07: #e3e3e3;
$glaylight08: #fafafa;
$glaylight09: #f5f6f7;
$glaylight10: #efefef;
$glaylight11: #d2d2d2;
$glaylight12: #f6f6f6;
$glaylight13: #c8c8c8;
$glaylight14: #f5f5f5;
$glaylight15: #cccccc;
$glaylight16: #faf9f6;
$glaylight17: #e6e6e6;
$glaylight18: #f3f3f3;

// カテゴリカラー

//チケット
$ticketColor01:#fc819b;
$ticketColor02:#783146;
$ticketNaviColor01: #fef2f5;
$ticketNaviColor02: #232934;
$ticketBoderColor01: #a5abb7;
$ticketBtnColor01: #ffff9a;
$ticketBtnColor02: #ffea61;
$ticketBtnColor03: #ff6e03;
$ticketBtnColor04: #ffa257;
$ticketBtnColor05: #4b5870;
$ticketBtnColor06: #939ba9;
$ticketBtnColor07: #7fc55c;
$ticketSubColor01: #fff2f5;
$ticketSubColor02: #f0bfca;


//保険
$hokenColor01:#82cdf3;
$hokenColor02:#253b73;
$hokenNaviColor01: #f6fbfe;
$hokenNaviColor02: #2579be;
$hokenTextColor01: #ff4433;
$hokenTextColor02: #ff5b4c;
$hokenBtnColor01: #82b169;
$hokenBtnColor02: #007a50;
$hokenBtnColor03: #f99f2b;
$hokenBtnColor04: #f76aa3;
$hokenBtnColor05: #fd5724;
$hokenBtnColor06: #f03f4e;
$hokenBtnColor07: #5076d1;


//リフォーム
$reformColor01:#68ba93;
$reformColor02:#154827;
$reformNaviColor01: #4daaab;
$reformNaviColor02: #04756f;
$reformNaviColor03: #f8f8f5;
$reformNaviColor04: #3a4452;
$reformBtnColor01: #fa5b0f;
$reformBtnColor02: #fdad87;
$reformBtnColor03: #264028;
$reformBtnColor04: #a60303;
$reformBtnColor05: #333745;
$reformBtnColor06: #f2385a;
$reformBtnColor07: #a7a37e;
$reformBtnColor08: #247404;
$reformTextColor01: #342603;
$reformTextColor02: #141414;
$reformSubColor01: #254503;
$reformSubColor02: #2b2803;
$reformSubColor03: #433112;
$reformSubColor04: #726344;
$reformSubColor05: #99936f;



//くらしのサービス
$kurashiColor01:#faad5a;
$kurashiColor02:#7c4b12;
$kurashiTextColor01: #594242;
$kurashiTextColor02: #f0662b;
$kurashiTextColor03: #733e01;
$kurashiTextColor04: #483825;
$kurashiTextColor05: #6c727c;
$kurashiTextColor06: #445161;
$kurashiNaviColor01: #fef7ee;
$kurashiNaviColor02:#fdfbf9;
$kurashiNaviColor03:#f9f5ef;
$kurashiBtnColor01:#76a93b;
$kurashiBtnColor02:#b3d378;
$kurashiBtnColor03:#fa9932;
$kurashiBtnColor04:#696056;
$kurashiBtnColor05:#f77b68;
$kurashiBtnColor06:#a8845b;
$kurashiBtnColor07:#fddab5;



//葬祭
$sosaiColor01:#af8ebe;
$sosaiColor02:#503f69;
$sosaiNaviColor01: #333745;
$sosaiNaviColor02: #2e95a3;
$sosaiNaviColor03: #f2385a;
$sosaiNaviColor04: #3b424c;
$sosaiBtnColor01: #0074d9;
$sosaiTextColor01: #2f2933;
$sosaiKanagawaColor01: #00a384;
$sosaiSizuokaColor01: #931719;
$sosaiYamanashiColor01: #006da7;
$sosaiSeminarColor01: #2e95a3;
$sosaiReportColor01: #eb5902;
$sosaiSubColor01:#007399;
$sosaiSubColor02:#ad76af;