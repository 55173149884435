@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　無料見積もり・お問い合わせフォーム（カート）
------------------------------------------------------------*/
.p-reformContact {
	padding: 49px 0 140px 0;
	background-color: #ffffff;
}

//見出し
.p-reformContact01 {
	margin-bottom: 24px;
}

//カートの中
.p-reformContact02 {
	margin-bottom: 52px;
	.p-reformCart{
		padding-top: 5px;
	}
	&__txt {
		font-size: 1.6rem;
		color: $gray01;
		&:first-of-type {
			margin-bottom: 20px;
			@include sp {
				margin-bottom: 10px;
			}
		}
	}
	&__annotation {
		color: $gray01;
		font-size: 1.4rem;
		margin-top: 3px;
		margin-bottom: 20px;
		display: block;
		@include sp {
			font-size: 1.1rem;
			margin-bottom: 10px;
		}
	}

	&_2 {
		margin-bottom: 0;
	}
	//コンポーネント　c-tbl02
}

//お申し込み前のご確認事項
.p-reformContact03 {
	.p-reformContact03__content01 {
		margin-bottom: 55px;
		dl {
			dt {
				margin-bottom: 10px;
				font-size: 2.0rem;
				font-weight: bold;
				color: $gray01;
				&:before {
					width: 10px;
					height: 10px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $reformBtnColor08;
					display: inline-block;
					vertical-align: middle;
					@include radius(50%);
				}

			}
			dd {
				margin-bottom: 20px;
				font-size: 1.6rem;
				line-height: 1.75;
				color: $gray01;
				a {
					color: $reformNaviColor02;
					text-decoration: underline;
					&:hover {
						opacity: 1;
						text-decoration: none;
					}
				}
				p {
					width: 800px;
				}
			}
		}
	}
	.p-reformContact03__content02 {
		//コンポーネント　c-cartBtn
	}
}


@include sp {
	.p-reformContact {
		padding: 30px 20px 28px 20px;
	}

	//見出し sp
	.p-reformContact01 {
		margin-bottom: 16px;
		.c-formTitle {
			margin-bottom: 27px;
		}
	}

	//カートの中 sp
	.p-reformContact02 {
		margin-bottom: 24px;
		&__txt {
			margin-bottom: 8px;
			font-size: 1.4rem;
			line-height: 1.4;
		}
		.p-reformCart {
			padding-top: 0;
		}
		//コンポーネント　c-tbl02
	}

	//お申し込み前のご確認事項 sp
	.p-reformContact03 {
		.p-reformContact03__content01 {
			margin-bottom: 30px;
			dl {
				dt {
					margin-bottom: 2px;
					font-size: 1.4rem;
					&:before {
						width: 8px;
						height: 8px;
						margin: -3px 4px 0 0;
					}
				}
				dd {
					margin-bottom: 15px;
					font-size: 1.4rem;
					line-height: 1.4;
					p {
						width: 100%;
						margin-bottom: 10px;
					}
					img {
						margin: 0 auto;
						display: block;
					}
				}
			}
			.c-refomeTitle05 {
				font-size: 1.6rem;
				margin-bottom: 10px;
				padding-bottom: 10px;
			}
		}
		.p-reformContact03__content02 {
			//コンポーネント　c-cartBtn
			.c-cartBtn {
				margin-top: 37px;
			}
		}
	}
}
/*
------------------------------------------------------------*/
