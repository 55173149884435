@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　葬儀後のマナー
------------------------------------------------------------*/
.p-sosaiKnowledgeHostManner {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
	//注釈
	&__annotation01 {
		margin: 1em 0;
		font-size: 1.2rem;
	}
	.c-sosaiTitle09{
		margin-bottom: 18px;
	}
}

//葬儀後のマナー
.p-sosaiKnowledgeHostManner01 {
	min-width: $baseWidth01;
	padding: 50px 0 53px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		margin: 0 0 1px 0;
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//あいさつ回りとお礼
.p-sosaiKnowledgeHostManner02 {
	margin: 15px 0 125px;
	&__txt01{
		text-align: center;
	}
	&__content01 {
		margin: 111px 0 98px 0;
		padding-right: 440px;
		position: relative;
		> img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 33px;
		}
		.c-sosaiTextBox01 {
			>div{
				padding: 35px 40px 32px;
			}
			.c-sosaiTitle10{
				margin-bottom: 18px;
			}
		}
	}
	&__content02 {
		dl {
			dt {
				margin: 28px 0 4px;
				color: $sosaiNaviColor02;
				font-size: 2.0rem;
				font-weight: 700;
			}
			dd {
				margin-bottom: 54px;
				p {
					margin-bottom: 22px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.c-sosaiTextBox01 {
			>div{
				padding: 35px 40px 11px;
			}
			.c-sosaiTitle10{
				margin-bottom: 18px;
			}
		}
	}
}

//香典返し
.p-sosaiKnowledgeHostManner03 {
	margin-bottom: 113px;
	&__txt01{
		text-align: center;
	}
	&__content01 {
		min-height: 240px;
		margin: 112px 0 100px 0;
		padding-right: 440px;
		position: relative;
		> img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 30px;
		}
	}
	&__content02 {
		margin-bottom: 90px;
		p{
			line-height: 1.8;
		}
		.c-sosaiTitle09{
			margin-bottom: 16px;
		}
	}
	&__content03{
		&--list{
			margin: 33px 0;
			li{
				margin-bottom: 2px;
			}
		}
	}
}


//関連コンテンツ
.p-sosaiKnowledgeHostManner04 {
	padding: 106px 0 140px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
}


@include sp {
	.p-sosaiKnowledgeHostManner {
		font-size: 1.4rem;
		line-height: 1.45;
		//注釈
		&__annotation01 {
			font-size: 1.0rem;
		}
		.c-sosaiTitle09{
			margin-bottom: 14px;
		}
	}
	//葬儀後のマナー sp
	.p-sosaiKnowledgeHostManner01 {
		min-width: 100%;
		margin-bottom: 50px;
		padding: 22px 0 23px;
		> span {
			font-size: 1rem;
			margin-bottom: 6px;
		}
	}
	//あいさつ回りとお礼 sp
	.p-sosaiKnowledgeHostManner02 {
		margin-bottom: 78px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 26px;
		}
		&__txt01{
			text-align: left;
			padding: 0 6px;
		}
		.c-sosaiTextBox01{
			> div {
			    padding: 17px 20px 12px;
			}
			p{
				font-size: 1.3rem;
				line-height: 1.6;
			}
			.c-sosaiTitle10{
				margin-bottom: 7px;
			}
		}
		&__content01 {
			margin: 42px 0 47px 0;
			padding-right: 0;
			position:static;
			> img {
				margin: 0 auto 18px;
				display: block;
				position: static;
			}
			>p{
				padding: 0 5px;
				margin-bottom: 13px;
			}
			.c-sosaiTextBox01{
				margin: 0 5px;
			}
			.c-sosaiTitle02b{
				margin-bottom: 25px;
			}
		}
		&__content02 {
			dl {
				dt {
					margin: 19px 5px 8px;
					color: $sosaiNaviColor02;
					font-size: 1.5rem;
					font-weight: 700;
				}
				dd {
					margin: 0 5px 38px;
					p {
						font-size: 1.4rem;
						line-height: 1.45;
						margin-bottom: 14px;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.c-sosaiTextBox01{
				p{
					margin: 0;
				}
			}
		}
	}

	//香典返し sp
	.p-sosaiKnowledgeHostManner03 {
		margin-bottom: 44px;
		padding: 0 15px;
		.c-sosaiTitle02b {
		    margin-bottom: 26px;
		}
		&__txt01{
			text-align: left;
			padding: 0 5px;
		}
		&__content01 {
			min-height: auto;
			margin: 42px 0 34px 0;
			padding-right: 0;
			position:static;
			> img {
				margin: 17px auto;
				display: block;
				position: static;
			}
			p{
				padding: 0 5px;
			}
		}
		&__content02, &__content03{
			margin-bottom: 34px;
			p{
				line-height: 1.45;
    			padding: 0 5px;
			}
		}
		 &__content03{
		 	&--list{
		 		padding: 0 5px;
				margin: 21px 0;
				li{
					margin-bottom: 10px;
				}
			}
		}
	}

	//関連コンテンツ sp
	.p-sosaiKnowledgeHostManner04 {
		margin-bottom: 62px;
		padding: 49px 15px 30px;
		overflow: hidden;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
		}
		.c-sosaiKanrenLink01{
			margin: 0 auto;
			display: flex;
			li{
				width: calc(50% -  5px);
				margin-right: 10px;
				float: left;
				&:nth-child(2n), &:last-child{
					margin-right: 0;
				}
				a{
					font-size: 1.2rem;
					line-height: 1.3;
					padding: 0 15px;
					display: block;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
