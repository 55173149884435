@use '../0_base' as *;

/*------------------------------------------------------------
header07(葬祭)
------------------------------------------------------------*/
.c-head07Pc {
	width: 95%;
	min-width: $baseWidth01;
	height: 50px;
	margin: 0 auto;
	padding: 10px 1% 0 2%;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	@include radius(0 0 25px 25px);
	@include clear;
	&--2 {
		position: fixed;
	}
	.c-head07Logo {
		width: 189px;
	}
}
.c-head07GnaviPc {
	ul {
		@include dib;
		li {
			margin-right: 15px;
			a {
				color: $gray01;
				font-size: 1.4rem;
				line-height: 2.14;
				font-weight: 600;
				&:before {
					margin: -3px 5px 0 0;
					content: '';
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
				&:hover {
					color: $keycolor01;
				}
			}
			&:nth-child(1) {
				a {
					&:before {
						width: 14px;
						height: 19px;
						background-image: url("../img/common/icon_biginer01.png");
					}
				}
			}
			&:nth-child(2) {
				a {
					&:before {
						width: 17px;
						height: 18px;
						background-image: url("../img/common/icon_reaf01.png");
					}
				}
			}
		}
	}
}

.c-head07CateTitle {
	height: 70px;
	padding-top: 20px;
	margin-bottom: 76px;
}

.c-sosaiNaviPc {
	width: 100%;
	min-width: $baseWidth01;
	height: 75px;
	margin: 0 auto;
	background-color: #ffffff;
	border-top: solid 1px $glaylight07;
	position: absolute;
	top: 120px;
	left: 0;
	right: 0;
	z-index: 9998;
	ul {
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		@include dtbl;
		li {
			margin: 0;
			height: 75px;
			font-size: 1.7rem;
			font-weight: bold;
			@include mincyo;
			> span {
				width: 100%;
				height: 100%;
				padding: 20px 36px 0;
				display: block;
				text-align: center;
				color: $sosaiNaviColor01;
				white-space: nowrap;
				position: relative;
				@media screen and (max-width: 1199px) {
					padding: 20px 30px 0;
				}
				&:before {
					width: 1px;
					height: 10px;
					content: '';
					background-color: $glaylight06;
					position: absolute;
					top: calc(50% - 5px);
					right: 0;
				}
				&:after {
					width: 7px;
					height: 15px;
					margin: 0 auto;
					content: '';
					background-image: url("../img/common/icon_arrow-blue04.png");
					-webkit-background-size: cover;
					background-size: cover;
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
					position: absolute;
					top: 45px;
					left: 0;
					right: 0;
				}
			}
			&:last-child {
				> span {
					&:before {
						content: none;
					}
				}
			}
			&:hover {
				> span {
					&:after {
						width: 28px;
						height: 14px;
						background-image: url("../img/common/icon_sankaku_bage.png");
						top: auto;
						bottom: 0;
						-webkit-transform: none;
						transform: none;
					}
				}
				.c-sosaiNaviPc__hoverMenu {
					display: block;
				}
			}
			&.is-current {
				> span {
					border-bottom: 3px solid $sosaiNaviColor02;
				}
			}
		}
	}
}
.c-sosaiNaviPc__hoverMenu {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding: 30px 0 60px 0;
	display: none;
	background-color: $subColor04;
	position: absolute;
	top: calc(100% - 3px);
	left: 0;
	right: 0;
	z-index: 9999;
	.c-sosaiNaviPc__hoverMenu__bnr {
		width: 352px;
		background-color: #ffffff;
		a {
			width: 100%;
			padding: 10px 30px;
			color: $sosaiNaviColor02;
			display: block;
			background-image: url("../img/common/icon_arrow-blue04.png");
			-webkit-background-size: 7px;
			background-size: 7px;
			background-repeat: no-repeat;
			background-position: 94% center;
		}
		&.c-sosaiNaviPc__hoverMenu__bnr--2 {
			width: 545px;
			margin-bottom: 30px;
		}
		&.c-sosaiNaviPc__hoverMenu__bnr--3 {
			width: 545px;
			height: 120px;
			margin-bottom: 8px;
			&:nth-child(2) {
				margin: 0;
			}
			img {
				width: 220px;
			}
			a {
				width: 325px;
				padding: 0 30px;
				line-height: 7;
			}
		}
		&.c-sosaiNaviPc__hoverMenu__bnr--4 {
			width: 258px;
		}
		&.c-sosaiNaviPc__hoverMenu__bnr--5 {
			margin-bottom: 30px;
		}
		&.c-sosaiNaviPc__hoverMenu__bnr--6 {
			width: 545px;
			margin: 0 auto;
			img {
				width: 273px;
			}
			a {
				width: 272px;
				padding: 0 30px;
				line-height: 7;
			}
		}
	}
}


@include sp {
	.c-head07Sp {
		width: 100%;
		height: 50px;
		padding:0;
		background-color: #ffffff;
		border-bottom: solid 1px $glaylight04;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		@include clear;
		.c-head07Logo {
			width: 200px;
			max-width: 35%;
			height: 50px;
			position: absolute;
			top: 0;
			left: 15px;
			img {
				width: 100%;
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
			}
		}
		.c-spMenu {
			background-color: #ffffff;
		}
		.c-headCateTitleSp {
			padding: 14px 15px 0 12px;
			background-color: $sosaiColor01;
		}
	}
	.c-head07LinkSp {
		dl {
			width: 100%;
			dt {
				width: 100%;
				background-color: $keycolor02;
				a {
					padding: 9px 35px 9px 20px;
					font-size: 1.4rem;
					font-weight: bold;
					line-height: 1.5;
					display: block;
					color: #ffffff;
					background-image: url("../img/common/icon_arrow-white02.png");
					background-repeat: no-repeat;
					-webkit-background-size: 7px;
					background-size: 7px;
					background-position: 95% center;
				}
			}
			dd {
				padding: 15px 20px 5px;
				background-color: #ffffff;
				ul {
					li {
						width: 49%;
						height: 45px;
						margin: 0 2% 2% 0;
						border: solid 1px $glaylight01;
						background-color: #ffffff;
						@include radius(8px);
						a {
							width: 100%;
							height: 100%;
							padding: 2px 0 2px 55px;
							font-size: 1.4rem;
							font-weight: bold;
							line-height: 2.928;
							display: block;
							position: relative;
							&:before {
								width: 30px;
								height: 30px;
								content: '';
								background-repeat: no-repeat;
								-webkit-background-size: 80%;
								background-size: 80%;
								background-position: center center;
								position: absolute;
								top: 7px;
								left: 16px;
								@include radius(50%);
							}
						}
						&:nth-child(1) {
							a {
								color: $ticketColor02;
								&:before {
									background-color: $ticketColor01;
									background-image: url("../img/common/icon_ticket01.png");
								}
							}
						}
						&:nth-child(2) {
							margin-right: 0;
							a {
								color: $hokenColor02;
								&:before {
									background-color: $hokenColor01;
									background-image: url("../img/common/icon_hoken01.png");
								}
							}
						}
						&:nth-child(3) {
							a {
								color: $reformColor02;
								&:before {
									background-color: $reformColor01;
									background-image: url("../img/common/icon_reform01.png");
								}
							}
						}
						&:nth-child(4) {
							margin-right: 0;
							a {
								line-height: 1.2;
								color: $kurashiColor02;
								padding-top: 7px;
								&:before {
									background-color: $kurashiColor01;
									background-image: url("../img/common/icon_kurashi01.png");
								}
							}
						}
					}
				}
			}
		}
		div {
			width: 100%;
			padding: 0 20px 12px;
			background-color: #ffffff;
			a {
				padding: 2px 0 0 11px;
				font-size: 1.4rem;
				line-height: 1.2;
				display: block;
				color: $keycolor01;
				background-image: url("../img/common/icon_arrow-green06.png");
				background-repeat: no-repeat;
				-webkit-background-size: 8px;
				background-size: 8px;
				background-position: 0 5px;
			}
		}
	}
	.c-head07GnaviSp {
		margin-bottom: 20px;
		.c-head07GnaviSp__navi {
			width: 100%;
			@include mincyo;
			a {
				padding: 10px 35px 9px 15px;
				font-size: 1.4rem;
				font-weight: bold;
				line-height: 1.5;
				display: block;
				color: #ffffff;
				background-image: url("../img/common/icon_arrow-white03.png");
				background-repeat: no-repeat;
				-webkit-background-size: 7px;
				background-size: 7px;
				background-position: 96% center;
			}
			&.c-head07GnaviSp__navi--1 {
				background-color: $sosaiColor01;
				a {
					font-size: 1.7rem;
					padding-top: 14px;
					padding-bottom: 12px;
				}
			}
			&.c-head07GnaviSp__navi--2 {
				background-color: $sosaiNaviColor03;
				a {
					font-size: 1.7rem;
					padding: 18px 35px 16px 36px;
					&:before {
						width: 20px;
						height: 20px;
						margin: -3px 5px 0 -20px;
						content: '';
						background-image: url("../img/common/icon_point01.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
			&.c-head07GnaviSp__navi--3 {
				border-bottom: solid 1px $glaylight01;
				&:last-of-type {
					border: none;
				}
				dt {
					position: relative;
					a {
						width: 100%;
						color: $sosaiNaviColor01;
						background: none;
						font-size: 1.7rem;
						padding-top: 18px;
						padding-bottom: 16px;
					}
				}
				dd {
					background-color: $sosaiNaviColor02;
					border-bottom: solid 1px rgba(255,255,255,0.3);
					padding-left: 10px;
				}
			}
		}
	}
	.c-head07BtnSp {
		margin-bottom: 38px;
		padding: 0 15px;
		background-color: #ffffff;
		a {
			width: 100%;
			margin: 0 auto;
			padding: 20px 0;
			font-size: 1.4rem;
			font-weight: bold;
			text-align: center;
			display: block;
			color: #ffffff;
			background-color: $sosaiBtnColor01;
			&:before {
				width: 18px;
				height: 20px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_file01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}


}


/*
------------------------------------------------------------*/
