@use '../../0_base' as *;

/*------------------------------------------------------------
サービスサイトTOP
------------------------------------------------------------*/
.p-serviceTop {
}

//サービストップ　スライド
.p-serviceTop01 {
	width: 100%;
	min-width: $baseWidth01;
	height: 100vh;
	min-height: 640px;
	margin: 0 auto 57px auto;
	position: relative;
	.p-serviceTop01__contents01 {
		width: 90%;
		max-width: $baseWidth01;
		margin: 0 auto;
		position: absolute;
		top: 22%;
		left: 0;
		right: 0;
		z-index: 2000;
		h1 {
			width: 100%;
			height: 12.05vw;
			max-height: 150px;
			min-height: 135px;
			margin-bottom: 15px;
			background-image: url("../img/service/top_mvTxtPC.png");
			-webkit-background-size: cover;
			background-size: cover;
			@include ct;
		}
		ul {
			li {
				width: 18.57%;
				height: 16.47vw;
				max-height: 205px;
				min-height: 184px;
				margin-right: 1.7875%;
				background-image: url("../img/service/bg01.jpg");
				box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
				overflow: hidden;
				position: relative;
				@include radius(6px);
				&:after {
					width: 25px;
					height: 25px;
					content: '';
					background-image: url("../img/common/icon_btn-green01.png");
					position: absolute;
					bottom: 0;
					right: 0;
					transition: 0.3s ease-in-out;
				}
				&:before {
					content: '';
					width: 100%;
					height: 100%;
					border: solid 4px;
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
					transition: 0.3s ease-in-out;
				}
				&:hover {
					&:after {
						opacity: 0;
					}
					&:before {
						opacity: 1;
					}
				}
				&:nth-child(1) {
					&:before {
						border-color: $ticketColor01;
					}
				}
				&:nth-child(2) {
					&:before {
						border-color: $hokenColor01;
					}
				}
				&:nth-child(3) {
					&:before {
						border-color: $reformColor01;
					}
				}
				&:nth-child(4) {
					&:before {
						border-color: $kurashiColor01;
					}
				}
				&:nth-child(5) {
					margin: 0;
					&:before {
						border-color: $sosaiColor01;
					}
				}
				.c-cateIcon01 {
					width: 80px;
					height: 80px;
					margin: 0 auto;
					-webkit-background-size: 50%;
					background-size: 50%;
					position: absolute;
					top: 13%;
					left: 0;
					right: 0;
				}
				.c-cateIcon01--kurashi + a {
					margin-top: -0.5em;
				}
				a {
					width: 100%;
					display: block;
					text-align: center;
					font-size: 2.2rem;
					font-weight: bold;
					line-height: 1.2;
					position: absolute;
					top: 68%;
					left: 0;
				}
			}
		}
	}
	.p-serviceTop01__slide {
		width: 99%;
		margin: 0 auto;
		li {
			width: 100%;
			height: 100vh;
			min-height: 640px;
			overflow: hidden;
			@include radius(60px);
			span {
				width: 100%;
				height: 100%;
				display: block;
				-webkit-background-size: cover;
				background-size: cover;
				background-position: center top;
				-webkit-transition: transform 6s ease-out;
				transition: transform 6s ease-out;
				&.p-serviceTop01__slide__img01 {
					background-image: url("../img/service/top_mv01.jpg");
				}
				&.p-serviceTop01__slide__img02 {
					background-image: url("../img/service/top_mv02.jpg");
				}
				&.p-serviceTop01__slide__img03 {
					background-image: url("../img/service/top_mv03.jpg");
				}
			}
			&.u-moving {
				span {
					-webkit-transform: scale(1.1);
					transform: scale(1.1);
				}
			}
		}
		.sp-only {
			display: none !important;
		}
	}
	.p-serviceTop01__scroll {
		width: 100px;
		margin: 0 auto;
		position: absolute;
		top: 92%;
		left: 0;
		right: 0;
		z-index: 2000;
	}
	.p-serviceTop01__bird {
		width: 100%;
		max-width: 1360px;
		min-width: $baseWidth01;
		margin: 0 auto;
		text-align: right;
		position: absolute;
		top: 12%;
		left: 0;
		right: 0;
		z-index: 1000;
			img {
				width: 15.67vw;
				max-width: 195px;
				min-width: 175px;
			}
	}
	.p-serviceTop01__slideFrame {
		width: 100%;
		min-width: $baseWidth01;
		height: 100%;
		margin: 0 auto;
		border: solid 20px #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		@include radius(60px);
		&:before {
			width: calc(100% + 40px);
			height: 220px;
			content: '';
			background-image: url("../img/service/top_topSlidePC.png");
			background-position: center bottom;
			position: absolute;
			top: -20px;
			left: -20px;
		}
		&:after {
			width: calc(100% + 40px);
			height: 467px;
			content: '';
			background-image: url("../img/service/top_bottomSlidePC.png");
			background-position: center top;
			position: absolute;
			bottom: -20px;
			left: -20px;
		}
	}
}

//はじめてご利用される方へ
.p-serviceTop02 {
	margin-bottom: 27px;
	.p-serviceTop02__contents01 {

		.p-serviceTop02__contents01__img {
			width: 440px;
		}
		.p-serviceTop02__contents01__txt {
			width: 640px;
			padding-top: 52px;
			.pc-only {
				width: 100%;
				height: 196px;
				padding: 28px 105px;
				text-align: center;
				background-image: url("../img/common/icon_arrow-green04.png");
				-webkit-background-size: 13px;
				background-size: 13px;
				background-position: 590px center;
				background-repeat: no-repeat;
				border: solid 6px $keycolor02;
				@include radius(98px);
				transition: 0.3s ease-in-out;
				&:hover {
					background-color: $keycolor02;
					background-image: url("../img/common/icon_arrow-white02.png");
					h2 {
						&:before {
							background-image: url("../img/common/icon_biginer02.png");
						}
						a {
							color: #ffffff;
						}
					}
					p {
						color: #ffffff;
					}
				}
			}
			h2 {
				margin-bottom: 7px;
				font-size: 2.4rem;
				font-weight: bold;
				&:before {
					width: 19px;
					height: 25px;
					margin: -3px 15px 0 0;
					content: '';
					background-image: url("../img/common/icon_biginer01.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
					transition: 0.3s ease-in-out;
				}
				a {
					color: $keycolor02;
				}
			}
			p {
				font-size: 1.6rem;
				line-height: 1.75;
				color: $gray01;
				transition: 0.3s ease-in-out;
				font-weight: 500;
				em {
					font-style: normal;
				}
			}
		}
	}
}

//お知らせ
.p-serviceTop03 {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding: 88px 0 85px;
	background-image: url("../img/service/bg02.jpg");
	overflow: hidden;
	position: relative;
	&:before {
		width: 100%;
		height: 20px;
		content: '';
		background-image: url("../img/service/line_topBg02.png");
		background-repeat: repeat-x;
		background-position: center 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	&:after {
		width: 100%;
		height: 4px;
		content: '';
		background-image: url("../img/service/line_colorful01_pc.jpg");
		background-repeat: repeat-x;
		background-position: center 0;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.p-serviceTop03__contents01 {
		padding: 40px;
		background-color: #ffffff;
		position: relative;
		z-index: 100;
		h2 {
			margin-bottom: 11px;
			font-size: 2.4rem;
			font-weight: bold;
			color: $gray01;
			&:before {
				width: 31px;
				height: 26px;
				margin: -5px 9px 0 0;
				content: '';
				background-image: url("../img/common/icon_news01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			span {
				margin: -0.25em 0 0 12px;
    			letter-spacing: 1px;
				font-size: 1.4rem;
				font-weight: normal;
				color: $gray02;
				display: inline-block;
				vertical-align: middle;
			}
		}
		.p-serviceTop03__contents01__newsList {
			border-top: dashed 1px $glaylight01;
			li {
				padding: 20px 0 16px;
				border-bottom: dashed 1px $glaylight01;
				p {
					width: 785px;
					color: $gray01;
					font-size: 1.6rem;
					font-weight: 500;
					line-height: 1.5;
				}
				a {
					width: 780px;
					color: $gray01;
					font-size: 1.6rem;
					font-weight: 500;
					line-height: 1.5;
					&:hover {
						opacity: 1;
						text-decoration: underline;
					}
				}
			}
			.p-serviceTop03__contents01__newsList_data {
				width: 128px;
				font-size: 1.6rem;
				color: $gray02;
				&:before {
					width: 5px;
					height: 12px;
					margin: -3px 10px 0 0;
					content: '';
					background-image: url("../img/common/icon_arrow-green01.png");
					display: inline-block;
					vertical-align: middle;
				}
			}
			.c-newsCate01 {
				margin-right: 30px;
			}
		}
		.c-serviceBtn01 {
			position: absolute;
			top: 42px;
			right: 40px;
		}
	}
	.p-serviceTop03__img {
		width: $baseWidth01;
		height: 100%;
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		.p-serviceTop03__img__wood {
			position: absolute;
			left: -231px;
    		bottom: -6px;
		}
		.p-serviceTop03__img__bird {
			position: absolute;
			top: 110px;
			right: -100px;
		}
	}
}

//コープライフサービスの5つのサービス
.p-serviceTop04 {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding: 80px 0 100px;
	background-image: url("../img/service/bg03.jpg");
	.c-serviceTitle01 {
		margin-bottom: 55px;
	}
	.p-serviceTop04__contents {
		margin-bottom: 60px;
	}
}


@include sp {
	.p-serviceTop {
	}

	//サービストップTOP　スライド　SP
	.p-serviceTop01 {
		min-width: 100%;
		height: 143.75vw;
		min-height: auto;
		margin-bottom: 35px;
		.p-serviceTop01__contents01 {
			width: 100%;
			padding: 0 20px;
			top: 14%;
			h1 {
				width: 198px;
				height: 132px;
				margin: -3px auto 13px auto;
				max-height: 240px;
				min-height: auto;
				background-image: url("../img/service/top_mvTxtSP.png");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
			ul {
				li {
					width: 30%;
					height: 37.5vw;
					max-height: 240px;
					min-height: auto;
					margin: 0 4.5% 2.9% 0;
					border-radius: 3px;
					&:after {
						width: 15px;
						height: 15px;
					}
					&:first-child{
						margin-left: 13px;
					}
					.c-cateIcon01 {
						width: 16vw;
						height: 16vw;
						-webkit-background-size: 60%;
						background-size: 60%;
						top: 12%;
						left: 0;
						right: 0;
					}
					a {
						font-size: 1.4rem;
						top: 69%;
					}
				}
			}
		}

		.p-serviceTop01__slide {
			width: 100%;
			li {
				height: auto;
				min-height: auto;
				@include radius(0);
				img {
					-webkit-transition: transform 6s ease-out;
					transition: transform 6s ease-out;
				}
				&.u-moving {
					img {
						-webkit-transform: scale(1.1);
						transform: scale(1.1);
					}
				}
			}
			.pc-only {
				display: none !important;
			}
			.sp-only {
				display: block !important;
			}
		}
		.p-serviceTop01__bird {
			min-width: 100%;
			top: 5%;
				img {
					width: 30.78vw;
					max-width: 197px;
					min-width: auto;
				}
		}
		.p-serviceTop01__slideFrame {
			min-width: 100%;
			height: 87%;
			background-image: url("../img/service/top_bgSlideSP.png");
			-webkit-background-size: cover;
			background-size: cover;
			background-position: center center;
			border: none;
			@include radius(0);
			&:before, &:after {
				content: none;
			}
		}
	}

	//はじめてご利用される方へ　SP
	.p-serviceTop02 {
		margin-bottom: 36px;
		padding: 0 20px;
		.p-serviceTop02__contents01 {
			.p-serviceTop02__contents01__img {
				width: 100%;
				margin-bottom: 18px;
			}
			.p-serviceTop02__contents01__txt {
				width: 100%;
				padding: 0;
				h2 {
					padding: 0;
					font-size: 1.6rem;
					text-align: center;
					background-image: url("../img/common/icon_arrow-green04.png");
					-webkit-background-size: 6px;
					background-size: 6px;
					background-position: 96% center;
					background-repeat: no-repeat;
					border: solid 3px $keycolor02;
					margin-bottom: 15px;
					@include radius(98px);
					&:before {
						content: none;
					}
					a {
						padding: 13px 10px;
						display: block;
						&:before {
							width: 14px;
							height: 18px;
							margin: -3px 9px 0 0;
							content: '';
							background-image: url("../img/common/icon_biginer01.png");
							-webkit-background-size: cover;
							background-size: cover;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
				p {
					font-size: 1.4rem;
					line-height: 1.7;
				}
			}
		}
	}

	//お知らせ　SP
	.p-serviceTop03 {
		min-width: 100%;
		padding: 35px 0 45px;
		&:before {
			content: none;
		}
		&:after {
			height: 5px;
			background-image: url("../img/service/line_colorful01_sp.jpg");
		}
		.p-serviceTop03__contents01 {
			padding: 0 20px;
			background: none;
			h2 {
				margin-bottom: 16px;
				font-size: 1.6rem;
				&:before {
					width: 20px;
					height: 17px;
					margin: -3px 10px 0 0;
				}
				span {
					margin: -0.25em 0 0 10px;
					font-size: 1.2rem;
					letter-spacing: 0px;
				}
			}
			.p-serviceTop03__contents01__newsList {
				margin-bottom: 18px;
				padding: 4px 20px;
				background-color: #ffffff;
				border: none;
				@include radius(8px);
				li {
					padding: 15px 0 12px;
					align-items: flex-end;
					&:last-child {
						border: none;
					}
					a {
						width: 100%;
						padding-left: 20px;
						line-height: 1.4;
						font-size: 1.4rem;
						&:before {
							width: 5px;
							height: 12px;
							margin: -3px 15px 0 -20px;
							content: '';
							background-image: url("../img/common/icon_arrow-green01.png");
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
				.p-serviceTop03__contents01__newsList_data {
					width: auto;
					margin: 0 11px 11px 0;
					font-size: 1.2rem;
					letter-spacing: -0.5px;
					&:before {
						content: none;
					}
				}
				.c-newsCate01 {
					margin: 0 0 13px 0;
				}
			}
			.c-serviceBtn01 {
				position: static;
				background-position: 88% center;
				a {
					padding: 6px 20px;
				}
			}
			.p-serviceTop03__contents01__bird {
				width: 30px;
				position: absolute;
				top: 30px;
				right: 30px;
			}
		}
	}

	//コープライフサービスの5つのサービス　SP
	.p-serviceTop04 {
		min-width: 100%;
		padding: 40px 20px 59px;
		.c-serviceTitle01 {
			margin-bottom: 14px;
		}
		.p-serviceTop04__contents {
			margin-bottom: 30px;
		}
	}

}

/*
------------------------------------------------------------*/
