@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　参列者のマナー
------------------------------------------------------------*/
.p-sosaiKnowledgeAttendee {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
	//注釈
	&__annotation01 {
		margin: 13px 0;
		font-size: 1.2rem;
	}
}

//参列者のマナー
.p-sosaiKnowledgeAttendee01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//ボタンエリア
.p-sosaiKnowledgeAttendee02 {
	margin-bottom: 110px;
	padding-top: 4px;
	p {
		margin-bottom: 52px;
		line-height: 3rem;
	}
	ul {
		li {
			width: 352px;
			padding: 21px 20px 18px;
		}
	}
}

//香典について
.p-sosaiKnowledgeAttendee03 {
	margin-bottom: 119px;
	.c-sosaiTitle02b{
		margin-bottom: 61px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		padding-left: 28px;
		margin-bottom: 22px;
	}
	&__content01 {
		margin-bottom: 70px;
		padding-right: 440px;
		position: relative;
		> img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p{
			line-height: 3rem;
			padding-right: 5px;
		}
		&__table {
			width: 100%;
			border-collapse: collapse;
			position: relative;
			margin-top: 32px;
			thead {
				tr {
					position: relative;
					td {
						width: 50%;
						padding: 20px 30px 18px;
					}
				}
			}
			tbody {
				tr {
					position: relative;
					th {
						width: 50%;
						padding: 20px 20px 19px 48px;
						font-size: 1.6rem;
						font-weight: 500;
						text-align: left;
						background: $glaylight03 url("../img/sosai/urgency_border01.png") repeat-x bottom left;
						&:before {
							width: 8px;
							height: 8px;
							margin: -4px 10px 0 -18px;
							content: '';
							background-color: $sosaiBtnColor01;
							display: inline-block;
							vertical-align: middle;
						}
	
					}
					td {
						width: 50%;
						padding: 20px 28px 19px;
						font-size: 1.6rem;
						font-weight: 700;
						text-align: right;
						background: $glaylight03 url("../img/sosai/urgency_border01.png") repeat-x bottom left;
					}
					&:nth-child(odd) {
						th {
							background-color: #ffffff;
						}
						td {
							background-color: #ffffff;
						}
					}
				}
			}
			&__title {
				font-size: 1.6rem;
				font-weight: 700;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: right;
				&--01 {
					text-align: left;
					position: relative;
					&:before {
						width: 1px;
						height: 100%;
						content: '';
						background-color: #ffffff;
						position: absolute;
						top: 0;
						right: 0;
					}
				}
			}
		}
	}
	&__content02 {
		margin-bottom: 72px;
		> p{
			margin-bottom: 45px;
			line-height: 3rem;
		}
		.c-sosaiTextBox01 {
			margin: 0 0 32px 0;
			> div{
				padding: 35px 20px 32px 40px;
				h4{
					margin-bottom: 17px;
					letter-spacing: 2.5px;
				}
			}
		}
		.c-sosaiTitle13 {
		    margin-bottom: 20px;
		}
	}
	&__content03 {
		> p{
			line-height: 3rem;
		}
	}
}

//通夜・告別式の服装
.p-sosaiKnowledgeAttendee04 {
	margin-bottom: 127px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		padding-left: 28px;
		margin-bottom: 22px;
	}
	.c-sosaiTitle13{
		margin-bottom: 20px;
		&:before{
			margin: -5px 12px 0 0;
		}
	}
	.c-sosaiTextBox01{
		> div{
			padding: 35px 40px 32px;
			h4{
				margin-bottom: 16px;
				letter-spacing: 2.5px;
			}
			p{
				line-height: 3rem;
			}
		}
	}
	&__content01 {
		margin-bottom: 100px;
		padding-right: 372px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 45px;
			line-height: 3rem;
		}		
	}
	&__content02 {
		margin-bottom: 100px;
		padding-right: 372px;
		position: relative;
		img {
			position: absolute;
			top: 102px;
			right: 0;
		}
		> p {
			margin-bottom: 45px;
			line-height: 3rem;
		}
	}
	&__content03 {
		padding-right: 372px;
		position: relative;
		img {
			position: absolute;
			top: 76px;
			right: 0;
		}
		> p {
			margin-bottom: 45px;
			line-height: 3rem;
		}
	}
}

//焼香の手順
.p-sosaiKnowledgeAttendee05 {
	margin-bottom: 126px;
	.c-sosaiTitle02b{
		margin-bottom: 53px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		padding-left: 28px;
		margin-bottom: 22px;
	}
	&__content01 {
		min-height: 240px;
		margin-bottom: 68px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 7px;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
	&__content02 {
		margin-bottom: 80px;
		> p {
			margin-bottom: 23px;
			line-height: 3rem;
		}
		ol {
			li {
				width: 257px;
				margin-right: 30px;
				&:nth-child(4) {
					margin-right: 0;
				}
				border: solid 2px $sosaiNaviColor02;
				p {
					padding: 16px 18px 20px 30px;
					font-size: 1.6rem;
					line-height: 2.6rem;
				}
			}
		}
	}
	&__content03 {
		margin-bottom: 80px;
		ol {
			padding-top: 8px;
			li {
				width: 257px;
				margin-right: 30px;
				&:nth-child(4) {
					margin-right: 0;
				}
				border: solid 2px $sosaiNaviColor02;
				p {
					padding: 16px 18px 23px 29px;
					font-size: 1.6rem;
					line-height: 2.6rem;
				}
			}
		}
	}
	&__content04 {
		margin-bottom: 80px;
		> p {
			margin-bottom: 23px;
			line-height: 3rem;
		}
		ol {
			li {
				width: 257px;
				margin-right: 30px;
				&:nth-child(4) {
					margin-right: 0;
				}
				border: solid 2px $sosaiNaviColor02;
				p {
					padding: 15px 18px 20px 29px;
					font-size: 1.6rem;
					line-height: 2.6rem;
				}
			}
		}
	}
	&__content05 {
		> p {
			margin-bottom: 23px;
			line-height: 3rem;
		}
		ol {
			li {
				width: 257px;
				margin-right: 30px;
				&:nth-child(4) {
					margin-right: 0;
				}
				border: solid 2px $sosaiNaviColor02;
				p {
					padding: 16px 18px 20px 29px;
					font-size: 1.6rem;
					line-height: 2.6rem;
				}
			}
		}
	}
}

//参列を遠慮してほしい旨の連絡があったら
.p-sosaiKnowledgeAttendee06 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		margin-bottom: 52px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	> p {
		margin-bottom: 52px;
		line-height: 3rem;
	}
	.c-sosaiTextBox01 {
		margin: 0 auto;
		> div{
			background-color: $glaylight12;
			padding: 35px 40px 34px 41px;
		}
	}
	h4 {
		margin-bottom: 19px;
		font-size: 1.8rem;
		font-weight: 700;
	}
	ul {
		li {
			margin-bottom: 10px;
			padding-left: 18px;
			font-size: 1.6rem;
			line-height: 2.4rem;
			&:last-child {
				margin-bottom: 0;
			}
			&:before {
				width: 8px;
				height: 8px;
				margin: -3px 10px 0 -17px;
				content: '';
				background-color: $sosaiBtnColor01;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}




//関連コンテンツ
.p-sosaiKnowledgeAttendee07 {
	padding: 108px 0 120px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	overflow: hidden;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiKanrenLink01{
		li{
			img{
				margin-bottom: 17px;
			}
			&:after{
				bottom: 3px;
			}
		}
	}
}


@include sp {
	.p-sosaiKnowledgeAttendee {
		font-size: 1.6rem;
		//注釈
		&__annotation01 {
			font-size: 1.2rem !important;
			padding-left: 3px;
			margin: 8px 0;
		}
	}
	//参列者のマナー sp
	.p-sosaiKnowledgeAttendee01 {
		min-width: 100%;
		margin-bottom: 47px;
		padding: 32px 0 33px;
		img{
			margin: 8px auto 0;
			width: 50px;
			transform: translateX(-4px);
		}
	}
	
	//ボタンエリア sp
	.p-sosaiKnowledgeAttendee02 {
		margin-bottom: 57px;
		padding: 0 20px;
		p {
			margin-bottom: 35px;
			font-size: 1.4rem;
			line-height: 1.7;
			text-align: left;
		}
		ul {
			margin: 0 -5px;
			li {
				width: 100%;
				margin-bottom: 13px;
				font-size: 1.5rem;
				padding: 12px 20px 11px;
				&:last-child {
					margin-bottom: 0;
				}
				a{
					&:after{
						margin: -3px 0 0 10px;
					}
				}
			}
		}
	}
	
	//香典について sp
	.p-sosaiKnowledgeAttendee03 {
		margin-bottom: 71px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 38px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTitle09{
			padding-left: 14px;
			margin-bottom: 19px;
			margin-left: -5px;
		}
		&__content01 {
			margin-bottom: 44px;
			padding-right: 0;
			position:static;
			> img {
				margin: 0 auto 16px;
				display: block;
				position: static;
			}
			> p{
				font-size: 1.4rem;
				line-height: 1.7;
				padding-right: 0;
			}
			&__table {
				margin: 34px -5px 0;
    			width: calc(100% + 10px);
				thead {
					tr {
						td {
							padding: 17px 10px 14px;
							text-align: center;
						}
					}
				}
				tbody {
					tr {
						th {
							padding: 17px 10px 12px 24px;
							font-size: 1.3rem;
							&:before {
								margin: -3px 5px 0 -13px;
							}
						}
						td {
							padding: 11px 8px 12px;
							font-size: 1.3rem;
						}
					}
				}
				&__title {
					font-size: 1.3rem;
				}
			}
		}
		&__content02 {
			margin-bottom: 34px;
			> p{
				font-size: 1.4rem;
				line-height: 1.7;
				margin-bottom: 20px;
			}
			.c-sosaiTitle09{
				margin-bottom: 15px;
			}
			.c-sosaiTextBox01 {
				margin: 14px 0 16px;
				> div{
					padding: 15px 20px 14px 19px;
					h4{
						margin-bottom: 4px;
						padding-left: 17px;
						letter-spacing: 0;
						&:before{
							margin: -5px 5px 0 -17px;
						}
					}
					p{
						font-size: 1.4rem;
						line-height: 1.7;
					}
				}
			}
			.c-sosaiTitle13{
				margin-bottom: 13px;
			}
		}
		&__content03 {
			> p{
				font-size: 1.4rem;
				line-height: 1.7;
			}
			.c-sosaiTitle09{
				margin-bottom: 15px;
			}
		}
	}

	//通夜・告別式の服装 sp
	.p-sosaiKnowledgeAttendee04 {
		margin-bottom: 76px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 39px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTitle09{
			padding-left: 14px;
			margin-bottom: 19px;
			margin-left: -5px;
		}
		.c-sosaiTitle13{
			margin-bottom: 13px;
			&:before{
				width: 12px;
				height: 12px;
				margin: -3px 8px 0 0;
			}
		}
		&__content01,
		&__content02,
		&__content03 {
			margin-bottom: 40px;
			padding-right: 0;
			position: static;
			img {
				width: 146px;
				display: block;
				margin: 0 auto 16px;
				position: static;
			}
			> p {
				margin-bottom: 20px;
				font-size: 1.4rem;
				line-height: 1.7;
			}
			.c-sosaiTextBox01 {
				> div{
					padding: 15px 20px 13px 19px;
					h4{
						margin-bottom: 4px;
						padding-left: 17px;
						letter-spacing: 0;
						&:before{
							margin: -5px 5px 0 -17px;
						}
					}
					p{
						font-size: 1.4rem;
						line-height: 1.7;
					}
				}
			}
		}
		&__content02 {
			img {
				transform: translateX(-12px);
			}
		}
		&__content03 {
			margin-bottom: 0;
			.c-sosaiTitle09{
				margin-bottom: 18px;
			}
			img{
				margin: 0 auto 15px;
			}
			.c-sosaiTextBox01 {
				> div{
					padding: 16px 20px 14px 19px;
				}
			}
		}
	}
	
	//焼香の手順 sp
	.p-sosaiKnowledgeAttendee05 {
		margin-bottom: 66px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 29px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTitle09{
			padding-left: 14px;
			margin-bottom: 16px;
			margin-left: -5px;
		}
		.c-sosaiTitle13{
			margin-bottom: 13px;
			&:before{
				width: 12px;
				height: 12px;
				margin: -3px 8px 0 0;
			}
		}
		&__content01 {
			min-height: auto;
			margin-bottom: 43px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 17px;
				position: static;
			}
			> p {
				margin-bottom: 10px;
				font-size: 1.4rem;
				line-height: 1.7;
			}
		}
		&__content02 {
			margin-bottom: 29px;
			> p {
				margin-bottom: 23px;
				font-size: 1.4rem;
				line-height: 1.7;
				span{
					display: block;
					font-size: 1.2rem;
					margin-top: 5px;
					padding-left: 3px;
				}
			}
			ol {
				li {
					width: calc(50% - 5px);
					margin-right: 10px;
					margin-bottom: 10px;
					border: solid 1px #2e95a3;
					&:nth-child(even) {
						margin-right: 0;
					}
					p {
						padding: 12px 15px 12px;
					    font-size: 1.4rem;
					    line-height: 1.6;
					}
				}
			}
		}
		&__content03 {
			margin-bottom: 29px;
			ol {
				padding-top: 2px;
				li {
					width: calc(50% - 5px);
					margin-right: 10px;
					margin-bottom: 10px;
					border: solid 1px #2e95a3;
					&:nth-child(even) {
						margin-right: 0;
					}
					p {
						padding: 13px 15px 11px;
					    font-size: 1.4rem;
					    line-height: 1.6;
					}
				}
			}
		}
		&__content04 {
			margin-bottom: 30px;
			> p {
				margin-bottom: 15px;
				font-size: 1.4rem;
				line-height: 1.7;
			}
			ol {
				li {
					width: calc(50% - 5px);
					margin-right: 10px;
					margin-bottom: 10px;
					border: solid 1px #2e95a3;
					&:nth-child(even) {
						margin-right: 0;
					}
					p {
						padding: 13px 15px 11px;
					    font-size: 1.4rem;
					    line-height: 1.6;
					}
				}
			}
		}
		&__content05 {
			> p {
				margin-bottom: 14px;
				font-size: 1.4rem;
				line-height: 1.7;
			}
			ol {
				li {
					width: calc(50% - 5px);
					margin-right: 10px;
					margin-bottom: 10px;
					border: solid 1px #2e95a3;
					&:nth-child(even) {
						margin-right: 0;
					}
					p {
						padding: 13px 15px 11px;
					    font-size: 1.4rem;
					    line-height: 1.6;
					}
				}
			}
		}
	}
	
	//参列を遠慮してほしい旨の連絡があったら sp
	.p-sosaiKnowledgeAttendee06 {
		margin-bottom: 50px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 24px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		> p {
			margin-bottom: 35px;
		    font-size: 1.4rem;
		    line-height: 1.7;
		    text-align: left;
		}
		.c-sosaiTextBox01{
			> div{
				padding: 15px 15px 13px 19px;
			}
		}
		h4 {
			margin-bottom: 8px;
			font-size: 1.4rem;
		}
		ul {
			li {
				margin-bottom: 7px;
				padding-left: 13px;
				font-size: 1.4rem;
				line-height: 1.7;
				&:before {
					margin: -3px 7px 0 -13px;
				}
			}
		}
	}
	
	
	//関連コンテンツ sp
	.p-sosaiKnowledgeAttendee07 {
		padding: 49px 20px 47px;
	    margin-bottom: 60px;
		.c-sosaiTitle02b{
			margin-bottom: 29px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiKanrenLink01{
			display: flex;
			li{
				width: 50%;
				margin: 0 auto;
				img{
					margin-bottom: 15px;
				}
				a{
					font-size: 1.2rem;
					line-height: 1.5rem;
					text-align: center;
					padding: 0 15px;
					display: block;
				}
			}
		}
	}
	
}

/*
------------------------------------------------------------*/
