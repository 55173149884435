@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　遺言・相続に関するご相談
------------------------------------------------------------*/
.p-sosaiInheritance {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}
//遺言・相続に関するご相談
.p-sosaiInheritance01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//遺言・相続に関する無料相談
.p-sosaiInheritance02 {
	margin-bottom: 106px;
	padding-top: 21px;
	.c-sosaiTitle02b{
		margin-bottom: 52px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	&__txt{
		width: 720px;
		line-height: 1.77;
		float: left;
		b {
			font-weight: 700;
		}
		p{
			padding-bottom: 15px;
		}
	}
	&__img {
		margin: 5px 0 50px;
		transform: translateY(5px);
	}
}

//対応内容例のご紹介
.p-sosaiInheritance03 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	ul {
		padding-top: 5px;
		li {
			width: 33.333333%;
			height: 124px;
			position: relative;
			font-size: 1.6rem;
			font-weight: 700;
			display: table;
			&:after {
				width: 10px;
				height: 10px;
				content: '';
				background-image: url("../img/common/icon_sankaku_left_top_brown01.png");
				-webkit-background-size: cover;
				background-size: cover;
				position: absolute;
				top: 2px;
				left: 2px;
			}
			&:nth-child(1) {
				border: solid 1px $glaylight13;
			}
			&:nth-child(2), &:nth-child(3) {
				border-top: solid 1px $glaylight13;
				border-bottom: solid 1px $glaylight13;
				border-right: solid 1px $glaylight13;
			}
			&:nth-child(4) {
				border-bottom: solid 1px $glaylight13;
				border-left: solid 1px $glaylight13;
				border-right: solid 1px $glaylight13;
			}
			&:nth-child(5) {
				width: 66.666666%;
				border-bottom: solid 1px $glaylight13;
				border-right: solid 1px $glaylight13;
			}
			p {
				padding: 35px 40px 32px;
				display: table-cell;
				vertical-align: middle;
			}
		}
	}
}

//ご相談先
.p-sosaiInheritance04 {
	padding: 107px 0 140px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 52px;
	}
	&__list {
		margin-top: 71px;
		&__btn {
			width: 372px;
			margin-right: 1px;
			padding: 18px 16px 14px;
			color: $gray10;
			background-color: $glaylight17;
			font-size: 1.6rem;
			font-weight: 700;
			text-align: center;
			cursor: pointer;
			transition: opacity 0.3s ease-in-out;
			&:last-child {
				margin-right: 0;
			}
			&--yamanashi {
				border-top: solid 2px $sosaiYamanashiColor01;
			}
			&--kanagawa {
				border-top: solid 2px $sosaiKanagawaColor01;
			}
			&--shizuoka {
				border-top: solid 2px $sosaiSizuokaColor01;
			}
			&.is-open, {
				color: $gray08;
				background-color: #ffffff;
				opacity: 1 !important;
				cursor: default;
			}
			&:hover {
				opacity: 0.70;
			}
		}
	}
	&__content {
		padding: 54px 60px 59px;
		background-color: #ffffff;
		display: none;
		&.is-open {
			display: block;
		}
		&__adviser {
			margin-bottom: 53px;
			padding-bottom: 49px;
			border-bottom: dotted 1px $gray07;
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: none;
			}
			h3 {
				margin-bottom: 3px;
				padding-left: 45px;
				font-size: 2.6rem;
				font-weight: 700;
				@include mincyo;
				&:before {
					width: 30px;
					height: 30px;
					margin: -8px 10px 0 -45px;
					content: '';
					background-image: url("../img/sosai/icon_sosai01.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
			}
			p {
				font-size: 1.5rem;
				font-weight: 700;
				color: $gray04;
			}
			&--01 {
				padding-right: 490px;
				position: relative;
				.adviser-img {
					position: absolute;
					top: 6px;
					right: 0;
				}
			}
			&--02 {
				&__left {
					width: 500px;
					h3{
						margin-bottom: 1px;
					}
				}
				&__right {
					width: 480px;
				}
			}
			&__tel {
				margin: 10px 0 13px;
				.tel-number{
					margin-right: 10px;
					font-size: 4.0rem;
					line-height: 1;
					letter-spacing: 3px;
					color: $gray08;
					display: inline-block;
					vertical-align: middle;
					@include mincyo;
					&:before {
						width: 28px;
						height: 28px;
						margin: -5px 10px 0 0;
						content: '';
						background-image: url("../img/common/icon_tel06.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
					span {
						font-size: 1.3rem;
						font-weight: 700;
						display: inline-block;
						vertical-align: middle;
						@include yu_gosick;
						letter-spacing: 0;
						margin-left: 10px;
						margin-top: -8px;
					}
					a{
						display: block;
					}
				}				
				span {
					font-size: 1.3rem;
					font-weight: 700;
					display: inline-block;
					vertical-align: middle;
				}
			}
			&__tel2 {
				width: 100%;
				margin-top: 8px;
				li{
					padding: 12px 15px 14px 40px;
					background-color: $subColor20;
					&:nth-child(2n) {
						background-color: #FFF;
					}
					a{
						color: $gray08;
						@include mincyo;
						
						font-size: 2.4rem;
						line-height: 1;
						div{
							display: flex;
							align-items: center;
							p{
								font-size: 1.4rem;
								font-weight: 700;
								text-align: left;
								font-family: YuGothic,'Yu Gothic',sans-serif;
								width: 234px;
								&:before {
									width: 8px;
									height: 8px;
									margin: -4px 10px 0 -20px;
									content: '';
									background-color: $sosaiNaviColor02;
									display: inline-block;
									vertical-align: middle;
								}
							}
							span{
								font-size: 1.6rem;
								margin-right: 7px;
	    						margin-top: 7px;
							}
						}
					}
				}
			}
			&__time {
				font-size: 1.6rem;
				font-weight: 700;
				span {
					font-weight: 500;
					@include mincyo;
					em {
						margin: 0 0 0 12px;
						font-size: 3.2rem;
						letter-spacing: 3px;
						font-style: normal;
					}
				}
			}
			&__link {
				font-size: 1.5rem;
				font-weight: 700;
				a {
					color: $sosaiNaviColor02;
					&:after {
						width: 12px;
						height: 12px;
						margin: -5px 0 0 10px;
						content: '';
						background-image: url("../img/common/icon_link05.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
					&:hover {
						text-decoration: underline;
					}
				}
			}
			&__address {
				font-size: 1.5rem;
				font-weight: 700;
				.c-sosaiBtn04 {
					margin-left: 10px;
				}
			}
			&__annotation {
				margin-top: 9px;
				li {
					font-size: 1.2rem;
					color: $gray03;
					padding-left: 1em;
					text-indent: -1em;
					b {
						font-weight: 700;
					}
				}
			}
		}
	}
}


@include sp {
	.p-sosaiInheritance {
		font-size: 1.4rem;
	}
	//遺言・相続に関するご相談 sp
	.p-sosaiInheritance01 {
		min-width: 100%;
		margin-bottom: 47px;
		padding: 32px 0 33px;
	}

	//遺言・相続に関する無料相談 sp
	.p-sosaiInheritance02 {
		margin-bottom: 74px;
		padding: 0 20px;
		.c-sosaiTitle02b {
		    margin-bottom: 25px;
		    padding-left: 4px;
		    span{
		    	&:after{
		    		height: 2px;
		    		margin: 13px auto 0;
		    	}
		    }
		}
		&__txt{
			width: 100%;
			margin-top: 15px;
			float: none;
			p{
				padding-bottom: 10px;
			}
		}
		&__img {
			margin: 22px auto 0;
			display: block;
			transform: translateY(0);
			width: 93%;
		}
	}
	//対応内容例のご紹介 sp
	.p-sosaiInheritance03 {
		margin-bottom: 50px;
		padding: 0 20px;
		.c-sosaiTitle02b {
		    margin-bottom: 40px;
		    span{
		    	&:after{
		    		margin: 12px auto 0;
		    	}
		    }
		}
		ul {
			border: solid 1px $glaylight13;
			padding: 0;
			li {
				width: 100% !important;
				height: auto;
				font-size: 1.3rem;
				line-height: 2rem;
				display: block;
				border-top: solid 1px $glaylight13;
				&:nth-child(1) {
				    border: 0;
				}
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5){
					border-bottom: 0;
					border-right: 0;
					border-left: 0;
				}
				p {
					padding: 17px 20px 15px;
					display: inherit;
				}
			}
		}
	}

	//ご相談先 sp
	.p-sosaiInheritance04 {
		padding: 50px 15px;
		margin-bottom: 57px;
		.c-sosaiTitle02b {
		    margin-bottom: 25px;
		}
		.u-inner01{
			> p{
				text-align: left;
				padding: 0 5px;
			}			
		}
		&__list {
			margin-top: 32px;
			&__btn {
				width: 33.3333%;
				font-size: 1.3rem;
				padding: 10px 5px 8px;
			}
		}
		&__content {
			padding: 20px;
			&__adviser {
				margin-bottom: 20px;
				padding-bottom: 18px;
				h3 {
					padding-left: 29px;
					font-size: 1.8rem;
					line-height: 2.5rem;
					margin-bottom: 8px;
					&:before {
						width: 21px;
						height: 21px;
						margin: -5px 8px 0 -29px;
					}
				}
				p {
					font-size: 1.3rem;
					line-height: 1.5rem;
				}
				&--01 {
					padding-right: 0;
					position: static;
					.adviser-img {
						margin: 0 auto 20px auto;
						display: block;
						position: static;
					}
				}
				&--02 {
					&__left {
						width: 100%;
						margin-bottom: 20px;
						.p-sosaiInheritance04__content__adviser__link{
							margin-top: 6px;
						}
					}
					&__right {
						width: 100%;
					}
				}
				&__tel {
					margin: 12px 0 13px;
					.tel-number{
						display: none;
					}
					a{
						display: block;
						background-color: #2e95a3;
						padding: 15px 0;
						font-family: YuGothic,'Yu Gothic',sans-serif;
						font-weight: bold;
						box-shadow: 0px 3px 0px #20656e;
    					border-radius: 5px;
						p{
							width: 190px;
							margin: 0 auto;
							position: relative;
							padding-left: 40px;
							font-size: 1.8rem;
							color: #FFF;
							line-height: 1;
							text-align: left;
							&:before{
								content: "";
								width: 25px;
								height: 25px;
								background: url("../img/common/icon_tel07.png") no-repeat;
								background-size: cover;
								display: block;
								position: absolute;
								top: calc(50% - 13px);
								left: 0;
							}
							span{
								font-size: 1rem;
								line-height: 1;
								display: block;
								margin-top: 2px;
							}
						}
					}
				}
				&__tel2 {
					margin-top: 0;
					li{
						padding: 0;
						background-color: #FFF;
						margin-bottom: 7px;
						a{
							background-color: #2e95a3;
							padding: 13px 0;
							font-family: YuGothic,'Yu Gothic',sans-serif;
							font-weight: bold;
							box-shadow: 0px 3px 0px #20656e;
	    					border-radius: 5px;
	    					color: #FFF;
    						display: block;
    						font-size: 1.8rem;
    						text-align: left;
							div{
								width: 170px;
								margin: 0 auto;
								padding-left: 40px;
								display: block;
								position: relative;
								&:before{
									content: "";
									width: 25px;
									height: 25px;
									background: url("../img/common/icon_tel07.png") no-repeat;
									background-size: cover;
									display: block;
									position: absolute;
									top: calc(50% - 13px);
									left: 0;
								}
								p{
									font-size: 1rem;
									width: 100%;
									color: #FFF;
									margin-bottom: 1px;
									&:before {
										display: none;
									}
								}
								span{
									font-size: 1.6rem;
									margin-right: 7px;
		    						margin-top: 7px;
								}
							}
						}
					}
				}
				&__time {
					font-size: 1.2rem;
					margin-top: 6px;
					span {
						margin-left: -4px;
						em {
							font-size: 2.4rem;
							letter-spacing: 2px;
							line-height: 1;
						}
					}
				}
				&__link {
					font-size: 1.2rem;
					a {
						color: $sosaiNaviColor02;
						&:after {
							width: 10px;
							height: 10px;
						}
					}
				}
				&__address {
					font-size: 1.3rem;
					.c-sosaiBtn04 {
						margin-left: 5px;
					}
				}
				&__annotation {
					margin-top: 10px;
					li {
						font-size: 1rem;
						line-height: 1.2rem;
						font-weight: bold;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
