@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　葬儀のご挨拶
------------------------------------------------------------*/
.p-sosaiKnowledgeHostGreeting {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
	//注釈
	&__annotation01 {
		margin: 1em 0;
		font-size: 1.2rem;
	}
}

//葬儀のご挨拶
.p-sosaiKnowledgeHostGreeting01 {
	min-width: $baseWidth01;
	padding: 49px 0 50px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		margin: 0 0 5px 0;
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//親族やご友人、会社の方へ
.p-sosaiKnowledgeHostGreeting02 {
	margin-bottom: 121px;
	padding-top: 21px;
	.c-sosaiTitle02b{
		margin-bottom: 100px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		margin-bottom: 30px;
		padding-left: 28px;
	}
	&__content01 {
		padding-right: 440px;
		position: relative;
		> img {
			position: absolute;
			top: 0;
			right: 0;
		}
		.c-sosaiTextBox01{
			> div{
				padding: 35px 40px 33px;
				> p{
					line-height: 3.0rem;
				}
			}
			.c-sosaiTitle10{
				margin-bottom: 16px;
				&:before{
					margin: -5px 10px 0 -30px;
				}
			}
		}
	}
}

//葬儀での喪主ご挨拶
.p-sosaiKnowledgeHostGreeting03 {
	margin-bottom: 117px;
	.c-sosaiTitle02b{
		margin-bottom: 101px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		margin-bottom: 22px;
		padding-left: 28px;
	}
	&__content01 {
		margin-bottom: 101px;
		&__01 {
			min-height: 240px;
			margin-bottom: 34px;
			padding-right: 445px;
			position: relative;
			> img {
				position: absolute;
				top: 0;
				right: 0;
			}
			> p{
				line-height: 3.0rem;
			}
		}
		.c-sosaiTextBox01{
			padding: 35px 40px 32px;
			border: solid 1px #c8c8c8;
			margin-top: -1px;
			.c-sosaiTitle10{
				margin-bottom: 16px;
				&:before{
					margin: -5px 10px 0 -30px;
				}
			}
			> p{
				line-height: 3rem;
			}
		}
	}
	&__content02 {
		margin-bottom: 100px;
		&__01 {
			min-height: 240px;
			margin-bottom: 33px;
			padding-right: 445px;
			position: relative;
			> img {
				position: absolute;
				top: 0;
				right: 0;
			}
			> p{
				line-height: 3rem;
			}
		}
		.c-sosaiTextBox01{
			> div{
				padding: 35px 40px 31px;
				> p{
					line-height: 3rem;
				}
			}
			.c-sosaiTitle10{
				margin-bottom: 16px;
				&:before{
					margin: -5px 10px 0 -30px;
				}
			}
		}
	}
	&__content03 {
		> p{
			line-height: 3rem;
		}
		.p-sosaiKnowledgeHostGreeting__annotation01{
			margin-top: 3px;
		}
	}
}

//寺院・僧侶への挨拶
.p-sosaiKnowledgeHostGreeting04 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		margin-bottom: 100px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	.c-sosaiTextBox01{
		> div{
			padding: 35px 40px 32px;
			> p{
				line-height: 3rem;
			}
		}
		.c-sosaiTitle10{
			margin-bottom: 16px;
			&:before{
				margin: -5px 10px 0 -30px;
			}
		}
	}
}




//関連コンテンツ
.p-sosaiKnowledgeHostGreeting05 {
	padding: 106px 0 140px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
	.c-sosaiKanrenLink01{
		li{
			margin-right: 30px;
			&:last-child{
				margin-right: 0;
			}
			&:after{
				bottom: 2px;
				right: 2px;
			}
		}
	}
}

@include sp {
	.p-sosaiKnowledgeHostGreeting {
		font-size: 1.4rem;
		//注釈
		&__annotation01 {
			font-size: 1.0rem;
		}
	}
	//葬儀のご挨拶 sp
	.p-sosaiKnowledgeHostGreeting01 {
		min-width: 100%;
		margin-bottom: 50px;
		padding: 20px 0 22px;
		> span {
			font-size: 1rem;
			margin: 0 0 6px 0;
		}
	}

	//親族やご友人、会社の方へ
	.p-sosaiKnowledgeHostGreeting02 {
		margin-bottom: 73px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTitle09 {
		    margin: 0 -5px 18px;
		    padding-left: 14px;
		}
		&__content01 {
			padding-right: 0;
			position:static;
			> img {
				margin: 0 auto 20px;
				display: block;
				position: static;
			}
			.c-sosaiTextBox01{
				> div{
					padding: 15px 20px 13px;
					> p{
						line-height: 1.7;
					}
				}
				.c-sosaiTitle10{
					padding-left: 15px;
					margin-bottom: 2px;
					&:before{
						margin: -4px 4px 0 -15px;
						width: 11px;
						height: 11px;	
					}
				}
			}
			.p-sosaiKnowledgeHostGreeting__annotation01{
				margin-top: 12px;
				font-size: 1.2rem;
				line-height: 1.8rem;
				padding-left: 12px;
				position: relative;
				span{
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
	}
	
	//葬儀での喪主ご挨拶
	.p-sosaiKnowledgeHostGreeting03 {
		margin-bottom: 72px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 38px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTitle09 {
		    margin: 0 -5px 18px;
		    padding-left: 14px;
		    line-height: 2.1rem;
		}
		&__content01 {
			margin-bottom: 49px;
			&__01 {
				min-height: auto;
				margin-bottom: 25px;
				padding-right: 0;
				position:static;
				> img {
					margin: 0 auto 14px;
					display: block;
					position: static;
				}
				> p{
					line-height: 1.7;
					padding-left: 2px;
				}
			}
			.c-sosaiTextBox01{
				padding: 15px 20px 12px;
				> p{
					line-height: 1.7;
				}
				.c-sosaiTitle10{
					padding-left: 15px;
					margin-bottom: 2px;
					&:before{
						margin: -4px 4px 0 -15px;
						width: 11px;
						height: 11px;	
					}
				}
			}
		}
		&__content02 {
			margin-bottom: 49px;
			&__01 {
				min-height: auto;
				padding-right: 0;
				position:static;
				margin-bottom: 25px;
				> img {
					margin: 0 auto 14px;
					display: block;
					position: static;
				}
				> p{
					line-height: 1.7;
					padding-left: 2px;
				}
			}
			.c-sosaiTextBox01{
				> div {
					padding: 15px 20px 12px;
					> p{
						line-height: 1.7;
					}
				}
				.c-sosaiTitle10{
					padding-left: 15px;
					margin-bottom: 2px;
					&:before{
						margin: -4px 4px 0 -15px;
						width: 11px;
						height: 11px;	
					}
				}
			}
		}
		&__content03 {
			>p{
				line-height: 1.7;
				padding-left: 2px;
			}
			.c-sosaiTitle09 {
			    margin: 0 -5px 12px;
			}
			.p-sosaiKnowledgeHostGreeting__annotation01{
				margin-top: 11px;
				font-size: 1.2rem;
				line-height: 1.8rem;
				padding-left: 12px;
				position: relative;
				span{
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
	}
	
	//寺院・僧侶への挨拶
	.p-sosaiKnowledgeHostGreeting04 {
		margin-bottom: 50px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTextBox01{
			> div {
				padding: 15px 20px 12px;
				> p{
					line-height: 1.7;
				}
			}
			.c-sosaiTitle10{
				padding-left: 15px;
				margin-bottom: 2px;
				&:before{
					margin: -4px 4px 0 -15px;
					width: 11px;
					height: 11px;	
				}
			}
		}
	}
	
	
	
	//関連コンテンツ sp
	.p-sosaiKnowledgeHostGreeting05 {
		padding: 47px 15px 40px;
    	margin-bottom: 59px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
				&:after{
					margin: 13px auto 0;
				}
			}
		}
		.c-sosaiKanrenLink01{
			flex-wrap: wrap;
			li{
				width: calc(50% - 5px);
				margin-right: 10px;
				padding-bottom: 5px;
				margin-bottom: 10px;
				&:nth-child(2n){
					margin-right: 0;
				}
				&:after{
					bottom: 2px;
					right: 2px;
				}
				img{
					margin-bottom: 8px;
				}
				a{
					font-size: 1.2rem;
					line-height: 3rem;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
