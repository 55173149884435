@use '../../0_base' as *;

.page-kurashi_enquete_index,
.page-kurashi_enquete_confirm
.page-kurashi_enquete_complete{
	.c-header{
		&__logo{
			@include sp{
				margin-top: 0;
			}
		}
	}
}


.c-form1{

	&Privacy__wrap{

		.c-formPrivacyText{
			border: solid 1px #efefef;
			height: 300px;
			overflow: hidden;
			padding: 28px 31px;
			position: relative;

			@include sp{
				padding: 18px 12px 18px 19px;
			}
		}

		.c-formPrivacyText__txt{
			font-size: 1.5rem;
			line-height: 30px;
			border: none;
			padding: 0;
			overflow: auto;
			height: calc(300px - 56px);
			margin-bottom: 0;
			font-weight: 500;
			padding-right: 15px;

			a[href^="tel:"] {
				color: #232934;
				pointer-events: none;

				@include sp{
					pointer-events: inherit;
				}
			}

			@include sp{
				font-size: 1.2rem;
				line-height: 22.5px;
				height: calc(300px - 26px);
				padding-right: 10px;
			}

			span{
				display: block;
				font-size: 1.7rem;
				line-height: 30px;
				font-weight: bold;

				@include sp{
					font-size: 1.3rem;
					line-height: 22.5px;
				}
			}
		}
	}
}

.p-kurashi-enquete{
	color: #232934;
	font-family: YuGothic,'Yu Gothic',sans-serif;
	.l-container{
		padding: 0 20px;
		width: 1160px;

		@include sp{
			width: 100%;
		}
	}

	.c-step3{
		@include sp{
			margin-top: 20px !important;
		}

		&__text{
			font-weight: 500;
			
			@include sp{
				white-space: nowrap;
			}
		}
	}

	.c-kurashi-enqueteText1{
		font-size: 1.6rem;
		line-height: 28px;
		font-weight: 500;

		@include sp{
			text-align: left;
			font-size: 1.4rem;
			line-height: 24.5px;
		}
	}

	.c-form1{
		color: #232934;
		.is-error{
			
			.c-form1__title,
			select{
				background-color: #FCD4D4;
			}

			input,
			textarea{
				background-color: rgba(242, 39, 39, 0.2);
			}

			.error{
				font-size: 1.6rem;

				@include sp{
					font-size: 1.2rem;
				}
			}
		}

		&__text{
			color: #F22727;
		}

		&__label{
			top: 2px;
			&:not(.is-style1){
				background-color: #F22727;
			}

			@include sp{
				top: 0;
			}

			&.is-style1{
				top: 4px;
				@include sp{
					top: 0;
				}
			}
		}

		&__select{
			max-width: 280px;

			@include sp{
				max-width: 100%;
			}

			&__inner{
				padding: 0 20px;
				text-indent: 25px;
				@include sp{
					text-indent: 15px;
				}

				@-moz-document url-prefix() {
					text-indent: 15px;
				}
			}
		}

		&__input{

			&__text{
				font-size: 1.8rem;
				width: 101px;
				font-weight: bold;

				@include sp{
					font-size: 1.4rem;
					width: 100%;
				}
			}

			input{
				&.mw258-pc{
					max-width: 289px;

					@include sp{
						max-width: 100%;
					}
				}

				&.is-full{
					max-width: 100% !important;
				}
			}
		}

		&__text4{
			color: #999999;
		}

		&Privacy{
			padding-top: 16px;

			@include sp{
				padding-top: 28px;
			}

			.c-hokenTitle04{
				padding-bottom: 9px;
				border-color: #F0AF68;
				margin-bottom: 31px;
				color: #232934;

				@include sp{
					font-size: 1.6rem;
					margin-bottom: 13px;
					border-width: 1px;
				}
			}

			.c-formPrivacyText{
				border: solid 1px #efefef;
				height: 300px;
				overflow: hidden;
				padding: 28px 31px;
				position: relative;

				@include sp{
					padding: 18px 12px 18px 19px;
				}

				// &::before{
				// 	content: "";
				// 	position: absolute;
				// 	width: 18px;
				// 	height: calc(100% - 40px);
				// 	top: 50%;
				// 	transform: translateY(-50%);
				// 	right: 26px;
				// 	border: 1px solid #989898;
				// 	border-radius: 10px;

				// 	@include sp{
				// 		display: none;
				// 	}

				// 	@-moz-document url-prefix() {
				// 		display: none;
				// 	}
				// }
			}

			.c-formPrivacyText__txt{
				font-size: 1.5rem;
				line-height: 30px;
				border: none;
				padding: 0;
				overflow: auto;
				height: calc(300px - 56px);
				margin-bottom: 0;
				font-weight: 500;
				padding-right: 15px;

				a[href^="tel:"] {
					color: #232934;
					pointer-events: none;

					@include sp{
						pointer-events: inherit;
					}
				}

				// /* width */
				// &::-webkit-scrollbar {
				// 	width: 8px;
				// 	border-radius: 10px;

				// 	@include sp{
				// 		width: 3px;
				// 		border-radius: 0;
				// 	}
				// }
				
				// /* Handle */
				// &::-webkit-scrollbar-thumb {
				// 	background: #989898; 
				// 	border-radius: 10px;
				// }

				@include sp{
					font-size: 1.2rem;
					line-height: 22.5px;
					height: calc(300px - 26px);
					padding-right: 10px;
				}

				span{
					display: block;
					font-size: 1.7rem;
					line-height: 30px;
					font-weight: bold;

					@include sp{
						font-size: 1.3rem;
						line-height: 22.5px;
					}
				}
			}
		}

		&__checkbox{
			&.is-term{
				margin-top: 37px;

				@include sp{
					margin-top: 17px;
				}

				.c-form1__checkbox__label{
					@include sp{
						text-align: left;
					}
				}

				input:checked{
					border-color: #FC819B;
				}

				input{
					@include sp{
						top: 3px;
						transform: translateY(0);
					}
				}
			}

			input:checked {
				background: #FC819B url(../img/hoken/common/icon_checked.svg) no-repeat center/11px auto;
			}
		}

		&__title{
			position: relative;

			&.is-info{
				@include sp{
					padding-right: 45px;
				}
			}

			&__text{
				.is-select-change{
					display: none;

					&:not(.is-washing){
						display: block;
					}
				}
			}

			&__num{
				line-height: 2.7rem;
				top: 0;

				@include sp{
					line-height: 2.1rem;
				}
			}
		}

		&__info{
			position: relative;
			left: 12px;

			@include sp{
				position: absolute;
				top: 14px;
				left: inherit;
				right: 15px;
				width: 20px;
				height: 20px;
			}
		}

		.c-table2__radio{
			margin: -2px 0 -4px;
			color: #232934;

			@include sp{
				margin: -3px 0 0;
			}
		}

		.c-table2__radio li{
			margin-bottom: 4px;

			@include sp{
				margin-bottom: 14px;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}

		.c-table2__radio input + label{
			padding-left: 29px;
			max-width: fit-content;
			font-weight: 500;
			white-space: nowrap;
		}

		.c-table2__radio input:checked + label:before{
			background-color: #FC819B;
			border-color: #FC819B !important;
		}

		.c-table2__radio input + label:before,
		.c-table2__radio input + label::after{
			@include sp{
				top: 10px;
			}
		}

		.c-cartBtn{
			@include sp{
				margin: 36px auto 0;
			}

			input.c-cartBtn__btn02,
			.c-cartBtn__btn01{
				width: 300px;
				font-size: 1.8rem;
				text-align: center;
				pointer-events: inherit;
				cursor: pointer;

				@include sp{
					width: 100%;
					height: 44px;
					font-size: 1.4rem;
				}
			}

			.c-cartBtn__btn01{
				border: 2px solid #b2b2b2;
			}

			.c-cartBtn__btn01 + input.c-cartBtn__btn02{
				margin-left: 20px;

				@include sp{
					margin-left: 0;
					margin-bottom: 10px;
				}
			}

			input.c-cartBtn__btn02{
				color: #ffffff;
				border: 2px solid #FFA257;
				line-height: 1;

				@include sp{
					width: 100%;
					height: 44px;
				}

				&:hover{
					color: #ff6e03;
				}
			}

			a{
				&::before,
				&::after{
					width: 12px;
					height: 12px;
					transition: all 0.3s ease-in-out;
				}

				&::before{
					left: 24px;
				}

				&::after{
					right: 24px;
				}
			}

			
		}

		&.is-confirm{
			.c-form1{
				&__notes{
					padding-top: 0;
					border: none;
					margin-bottom: 0;
					margin-top: 75px;

					@include sp{
						margin-top: 48px;
					}
				}
			}

			.c-cartBtn{
				margin-top: 30px;

				@include sp{
					margin-top: 15px;
				}
			}
		}
	}

	&-complete{
		padding: 42px 0 199px;
	
		@include sp{
			padding: 19px 20px;
		}

		.c-step3{
			margin-top: 40px;

			@include sp{
				margin-top: 20px;
			}

			&.is-orange{
				.c-step3__item{
					&:last-child{
						&.is-active{
							background-image: url(../img/kurashi/enquete/step3-bg2-orange-active.png) !important;
	
							@include sp{
								background-image: none !important;
			
								&::after{
									background-color: #F0AF68 !important;
								}
							}
			
							.c-step3__text{
								font-weight: bold;
							}
			
							&::before{
								@include sp{
									background: url(../img/kurashi/enquete/step3-orange-sp-active1.svg) no-repeat center left/27px auto !important;
								}
							}
						}
					}
				}
			}			
		}

		
	
		&__text{
			margin-top: 134px;
			font-size: 2.8rem;
			line-height: 42px;
			letter-spacing: 0.01em;
			font-weight: bold;
			text-align: center;
	
			@include sp{
				margin-top: 57px;
				font-size: 1.6rem;
				line-height: 21px;
			}
		}
	}

	&-input{
		padding: 42px 0 134px;

		@include sp{
			padding: 21px 20px;
		}

		.c-kurashi-enqueteTitle1{
			margin-bottom: 44px;

			@include sp{
				margin-bottom: 25px;
			}
		}

		.c-step3{
			margin-top: 86px;

			@include sp{
				margin-top: 24px !important;
			}

			&__item.is-active{
				background-image: url(../img/kurashi/enquete/step3-bg-orange-active.png) !important;

				@include sp{
					background: #F0AF68;

					&::after{
						background-color: #F0AF68 !important;
					}
				}

				.c-step3__text{
					font-weight: bold;
				}

				&:nth-child(1){
					& + .c-step3__item:after{
						@include sp{
							background: url(../img/kurashi/enquete/step3-orange-sp-active.svg) no-repeat center left/27px auto;
						}
					}
				}
			}
		}

		.c-form1{
			margin-top: 117px;

			@include sp{
				margin-top: 37px;
			}
		}
	}

	&-confirm{
		padding: 42px 0 134px;

		@include sp{
			padding: 19px 20px;
		}

		.c-kurashi-enqueteTitle1{
			margin-bottom: 40px;

			@include sp{
				margin-bottom: 20px;
			}
		}

		.c-step3{
			&.is-orange{
				.c-step3__item{
					&.is-active{
						background-image: url(../img/kurashi/enquete/step3-bg1-orange-active.png) !important;

						@include sp{
							background-image: none !important;
		
							&::after{
								background-color: #F0AF68 !important;
							}
						}
		
						.c-step3__text{
							font-weight: bold;
						}
		
						&::before{
							@include sp{
								background: url(../img/kurashi/enquete/step3-orange-sp-active1.svg) no-repeat center left/27px auto;
							}
						}

						& + .c-step3__item{
							&::before{
								@include sp{
									background: url(../img/kurashi/enquete/step3-orange-sp-active.svg) no-repeat center left/27px auto;
								}
							}
						}
					}
				}
			}
		}

		.c-form1{
			margin-top: 80px;

			@include sp{
				margin-top: 40px;
			}

			&__radio__text{
				white-space: nowrap;
			}

			&__grcontrol{
				&.is-style1{
					padding-top: 6px;

					@include sp{
						padding-top: 3px;
					}

					.c-form1__input{
						& + .c-form1__input{
							margin-top: 26px;

							@include sp{
								margin-top: 22px;
							}
						}
					}

					.c-form1__input__text{
						width: 122px;
					}

					.c-form1__input__right{
						top: 3px;
						position: relative;

						@include sp{
							top: -1px;
						}
					}
				}
			}
		}

		.c-cartBtn{
			@include sp{
				display: flex;
				flex-wrap: wrap;

				a{
					height: 44px;
				}

				.c-cartBtn__btn01{
					order: 2;
					margin: 0;
				}
			}
		}
	}
}

