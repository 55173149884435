@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭TOP
------------------------------------------------------------*/
.p-sosaiTop {
	background-color: #ffffff;
}

//葬祭MV
.p-sosaiTop01 {
	min-width: $baseWidth01;
	margin: 0 auto;
	padding-bottom: 116px;
	background-color: #ffffff;
	background-image: url("../img/sosai/index_mv_bg.jpg");
	-webkit-background-size: 100%;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center 0;
	.p-sosaiTop01__mv {
		max-width: 1600px;
		width: 100%;
		margin: 0 auto;
		padding: 70px 24px 0;
		&--pc {
			display: flex;
			li {
				width: 16.2%;
				margin-right: 1.6%;
				list-style: none;
				&:nth-last-of-type(2) {
					margin-right: 0;
				}
				&:nth-child(2n) {
					margin-top: 31px;
				}
				&:last-of-type {
					width: 460px;
					min-width: 460px;
					height: 420px;
					margin-right: 0;
					margin-top: 80px;
					margin-left: 62px;
				}
				&:not(:last-of-type) {
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}

//葬祭TOPリンク
.p-sosaiTop02 {
	//コンポーネント　c-list01
	&__txt {
		font-size: 1.4rem;
		font-weight: 500;
		color: $gray08;
		text-align: center;
		@include sp {
			padding: 0 18px;
			font-size: 1.1rem;
			line-height: 1.5;
		}
	}

}

//終活応援セミナー開催情報
.p-sosaiTop03 {
	padding: 89px 0 100px;
	background-color: #ffffff;
	.c-sosaiTitle01 {
		margin-bottom: 53px;
	}
	.p-sosaiTop03__contents01 {
		.p-sosaiTop03__contents01__newsList {
			li {
				padding: 20px 0;
				border-bottom: solid 1px $glaylight01;
				&:last-child {
					border: none;
				}
				p {
					width: 910px;
					color: $gray01;
					font-size: 1.7rem;
					font-weight: 500;
					line-height: 1.5;
				}
				a {
					color: $gray01;
					font-size: 1.7rem;
					font-weight: 500;
					line-height: 1.5;
					text-decoration: underline;
					&:hover {
						opacity: 1;
						text-decoration: none;
					}
				}
			}

			.p-sosaiTop03__contents01__newsList_data {
				width: 120px;
				font-size: 1.7rem;
				color: $gray03;
				@include mincyo;
			}
			.c-newsCate02 {
				width: 60px;
				margin: 4px 5px 0 0;
				line-height: 1;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.c-newsCate03 {
				min-width: 70px;
				width: inherit;
				padding: 0 5px;
				margin: 4px 34px 0 0;
				line-height: 1;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.c-sosaiBtn01 {
			width: 200px;
			margin-top: 30px;
			font-weight: bold;
		}
	}
}

//家族葬
.p-sosaiTop04 {
	min-width: $baseWidth01;
	margin: 0 auto;

	//コンポーネント　c-block02
	.c-block02__btn {
		width: 277px;
		@include sp{
			width: 232px !important;
			text-align: left;
			padding: 10px 29px 10px;
		}
	}
	.c-block02__left img {
		min-height: 760px;
		@include sp {
			min-height: auto;
		}
	}
}

//葬祭TOPリンク2
.p-sosaiTop05 {
	padding: 108px 0 119px;
	background-color: #ffffff;
	.c-sosaiTitle01 {
		margin-bottom: 60px;
	}
	.p-sosaiTop05__contents01 {
		//コンポーネント　c-list02
		.c-list02{
			&--txt{
				background: #FFF;
				top: 50%;
				left: 50%;
				right: auto;
				transform: translate(-50%, -50%);
				writing-mode: unset;
				padding: 10px 27px;
				border: 3px solid #fff;
				overflow: hidden;
				@include sp{
					writing-mode: vertical-lr;
					padding: 20px 7px;
				}
				span{
					font-size: 1.6rem;
					line-height: 4.8rem;
					color: #2f2933;
					font-weight: bold;
					@include sp{
						font-size: 1.2rem;
						line-height: 1.5rem;
					}
				}
				&:after {
				    content: '';
				    position: absolute;
				    width: 15px;
				    height: 30px;
				    background: #2e95a3;
				    right: -6px;
				    bottom: -18px;
				    -webkit-transform: rotate(45deg);
				    -ms-transform: rotate(45deg);
				    transform: rotate(45deg);
				}
			}
		}
	}
}

//お葬式の流れ
.p-sosaiTop06 {
	padding: 110px 0 121px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	margin-bottom: 110px;

	.p-sosaiTop06__contents01 {
		.p-sosaiTop06__contents01__txt01 {
			margin-bottom: 51px;
			font-size: 1.8rem;
			font-weight: 500;
			line-height: 1.8;
			color: $gray08;
			text-align: center;
			@include sp {
				padding: 0 18px;
				font-size: 1.4rem;
				line-height: 1.6;
				margin-bottom: 24px;
			}
		}

		.p-sosaiTop06__contents01__txt02 {
			margin: 36px 0 34px;
			font-size: 1.4rem;
			font-weight: 500;
			color: $gray08;
			text-align: center;
			@include sp {
				padding: 0 18px;
				font-size: 1.1rem;
				margin: 16px 0 20px;
    			line-height: 1.5;
			}
		}

	//コンポーネント　c-list04
		.c-list04 {
			background-color: #ffffff;
		}
	}
	.c-sosaiBtn01 {
		width: 281px;
		font-weight: bold;
		@include sp{
			width: 73%;
			font-size: 1.3rem;
			line-height: 4.5rem;
		}
	}
}

//事前相談
.p-sosaiTop07 {
	padding: 90px 0 97px;
	.p-sosaiTop07__contents01 {
		.p-sosaiTop07__contents01__txt01 {
			margin-bottom: 55px;
			font-size: 1.8rem;
			font-weight: 500;
			line-height: 1.8;
			color: $gray08;
			text-align: center;
			@include sp {
				padding: 0 15px;
				font-size: 1.4rem;
				text-align: left;
				line-height: 1.6;
				margin-bottom: 24px;
			}
			b {
				font-weight: bold;
			}
		}
		//コンポーネント　c-list03
	}
}

//お葬式の流れ
.p-sosaiTop08 {
	padding: 0 0 87px 0;
	.p-sosaiTop08__contents01 {
		.p-sosaiTop08__contents01__txt01 {
			margin-bottom: 51px;
			font-size: 1.8rem;
			font-weight: 500;
			line-height: 1.8;
			color: $gray08;
			text-align: center;
			@include sp {
				margin-bottom: 36px;
				font-size: 1.4rem;
				padding: 0 20px;
				line-height: 1.6;
				text-align: left;
			}
			b {
				font-weight: bold;
			}
		}
		//コンポーネント　c-list03

		.c-list03{
			&__item{
				@include sp{
					width: 100%;
					margin: 0 0 10px;
				}
				&:nth-child(1){
					.c-list03__lable{
						@include sp{
							min-width: 170px;
						}
					}
				}
				&:nth-child(3){
					.c-list03__lable{
						@include sp{
							min-width: 170px;
						}
					}
				}
			}
			&__lable{
				@include sp{
					width: auto;
					left: 50%;
					font-size: 1.2rem;
					text-align: center;
					padding: 12px 16px;
			    	line-height: 1.5rem;
				}
			}
		}
	}
	&__list{
		&Item{
			display: block;
			width: 262px;
			float: left;
			position: relative;
			@include sp{
				width: calc(50% - 5px);
				padding-top: 5px;
			}
			&:last-child{
				margin-left: 21px;
				@include sp{
					margin-left: 10px;
				}
				.c-list03__lable{
	    			width: 156px;
	    			@include sp{
	    				width: auto;
	    				min-width: 100px;
	    			}
				}
			}
			.c-list03__lable{
				padding: 21px 22px 21px 28px;
    			width: 140px;
    			@include sp{
    				padding: 12px 10px !important;
    				width: auto;
    				min-width: 90px;
    			}
			}
		}
	}
}

//大切なペットの火葬・霊園
.p-sosaiTop09 {
	min-width: $baseWidth01;
	margin: 0 auto 140px auto;
	//コンポーネント　c-block02
	.c-block02__btn {
		width: 328px;
		padding: 15px 7px 13px 0;
		text-align: center;
		@include sp{
			width: 210px !important;
    		padding: 5px 16px 4px 0;
    		line-height: 1.3;
		}
	}
	.c-block02__left img {
		min-height: 556px;
		@include sp {
			min-height: auto;
		}
	}
}

@include sp {
	//葬祭MV sp
	.p-sosaiTop01 {
		min-width: 100%;
		background-image: none;
		padding-bottom: 0;

		.p-sosaiTop01__mv {
			width: 100%;
			padding: 0;
			&--sp {
				ul {
					display: flex;
				}
				li{
					list-style: none;
				}
				img {
					width: 100%;
					height: auto;
				}
			}
			&--logo {
				padding: 33px 60px 31px;
				background-image: url("../img/sosai/mv_bg-sp.png");
				-webkit-background-size: 100%;
				background-size: 100%;
			}
		}
	}

	//終活応援セミナー開催情報 sp
	.p-sosaiTop03 {
		padding: 40px 20px 50px;
		.p-sosaiTop03__contents01 {
			.p-sosaiTop03__contents01__newsList {
				li {
					padding: 19px 0 15px;
					a {
						width: 100%;
						font-size: 1.4rem;
						padding: 4px 0 0;
					}
				}
				.p-sosaiTop03__contents01__newsList_data {
					width: auto;
					margin: 0 11px 0 0;
					font-size: 1.3rem;
				}
				.c-newsCate02 {
					width: 40px;
					height: 15px;
					margin: 2px 3px 2px 0;
					font-size: 1rem;
					padding-top: 2px;
				}
				.c-newsCate03 {
					min-width: 50px;
					width: inherit;
					height: 15px;
					margin: 2px 0;
					font-size: 1rem;
					padding-top: 3px;
				}
			}
			.c-sosaiBtn01 {
				width: 61%;
				margin-top: 11px;
				font-size: 1.3rem;
			}
		}
	}
	//家族葬 sp
	.p-sosaiTop04 {
		min-width: 100%;
		//コンポーネント　c-block02
	}
	//葬祭TOPリンク2 sp
	.p-sosaiTop05 {
		padding: 43px 0 50px;
		.c-sosaiTitle01 {
			margin-bottom: 39px !important;
		}
	}
	.p-sosaiTop06 {
		padding: 43px 0 50px;
		margin-bottom: 0;
		.c-sosaiTitle01 {
			margin-bottom: 37px !important;
		}
	}
	.p-sosaiTop07 {
    	padding: 43px 0 31px;
    	.c-sosaiTitle01 {
			margin-bottom: 37px !important;
		}
	}
	.p-sosaiTop08 {
    	padding: 44px 0 40px;
    	.c-sosaiTitle01 {
			margin-bottom: 36px !important;
		}
	}
	//大切なペットの火葬・霊園
	.p-sosaiTop09 {
		min-width: 100%;
		margin-bottom: 25px;
		//コンポーネント　c-block02
	}
}

// aniamtion
//-----------------------------------------------------------------
.js-fade02 {
	opacity: 0;
	transform: translate(0, 130px);
	&.is-fade02--1 {
		transition-duration: 3s;
	}
	&.is-fade02--2 {
		transition-delay: 1s;
		transition-duration: 3s;
	}
	&.is-fade02--3 {
		transition-delay: 2s;
		transition-duration: 3s;
	}
	&.is-fade02--4 {
		transition-delay: 3s;
		transition-duration: 3s;
	}
	&.is-fade02 {
		opacity: 1;
		transform: translate(0, 0);
	}
}

.js-fade03 {
	opacity: 0;
	@keyframes fadeIn02 {
		0% {
			filter: opacity(0%) blur(10px);
			opacity: 0;
		}
		50% { filter: opacity(100%) blur(10px);}
		100% {
			filter: opacity(100%) blur(0px);
			opacity: 1;
		}
	}
	&.is-fade03 {
		//animation-delay: 5s;
		animation-name: fadeIn02;
		animation-timing-function: linear;
		animation-duration: 1.5s;
		animation-fill-mode: both;
		filter: blur(0px);
	}
}
