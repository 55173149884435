@use '../../0_base' as *;


.p-hokenService {
	.c-hokenItemSerect__item__request {
		margin-top: 35px;
		text-align: center;
		a {
			width: auto;
			display: inline-block;
			padding: 0 10px;
			height: auto;
			margin: 0 auto;
			border-radius: 100px;
			line-height: 1.6;
			padding: 11px 44px 10px;
			&::before {
				margin: -5px 3px 0 -3px;
			}
		}
	}

	.c-box01{
		@include sp {
			margin: 0 -20px;
		}

		&__banner{
			background: url(/img/hoken/detail/c-box01_banner.jpg) no-repeat bottom left;
			background-size: cover !important;
			padding: 62px 20px 54px;

			@include sp {
				background: url(/img/hoken/detail/c-box01_bannerSP.jpg) no-repeat bottom right;
				padding: 20px 20px 40px;
			}

			img{
				@include sp {
					width: 190px;
				}
			}
		}

		&__inner{
			background: #FDF6AE;
			padding: 30px 30px 10px;
			margin-top: -35px;
			position: relative;
			z-index: 1;

			@include sp {
				padding: 44px 20px 10px;
				position: relative;
				margin-top: -10px;
			}
		}

		&__wrap {
			display: flex;
			align-items: flex-end;

			@include sp {
				justify-content: center;
			}

			@include max-w(374px) {
				flex-wrap: wrap;
			}
		}

		&__title{
			position: relative;
			top: -19px;

			@include sp {
				position: absolute;
				top: -20px;
				left: 0;
				width: 100%;
				text-align: center;
				padding: 0 20px;
			}
		}

		&__content{
			display: flex;
			margin-left: 36px;

			@include sp {
				margin-left: 0;
			}

			@include max-w(374px) {
				width: 100%;
			}
		}

		&__item{
			border: 2px solid #fff;
			border-radius: 4px;
			padding: 26px 18px 9px;

			@include sp {
				padding: 30px 12px 20px;
			}

			@include max-w(374px) {
				width: 50%;
			}

			&:not(:last-child){
				margin-right: 15px;

				@include sp {
					margin-right: 5px;
				}
			}

			.c-box01__itemTitle{
				background: #FFFFFF;
				border-radius: 13px;
				text-align: center;
				padding: 5px 0;
				max-width: 110px;
				margin: -41px auto 12px;
				line-height: initial;
			}
		}

		&__select{
			width: 120px;
			position: relative;
			border-radius: 4px;
			cursor: pointer;
			background: #F6FBFE;
			border: 1px solid #DDDDDD;

			@include sp {
				width: 80px;
				position: relative;
				top: 10px;
			}

			@include max-w(374px) {
				width: 100%;
			}

			&:after{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 12px;
				width: 0;
				height: 0;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 7px solid #BABABA;

				@include sp {
					right: 8px;
				    border-left: 3px solid transparent;
				    border-right: 3px solid transparent;
				    border-top: 5px solid #BABABA;
				}
			}

			.c-box01__selectLabel{
				position: absolute;
				width: 100%;
				z-index: 1;
				margin-top: 0;
				font-size: 1.6rem;
				font-weight: bold;
				color: #253B73;
				height: 50px;
				display: flex;
				align-items: center;
				padding: 0 15px;

				@include sp {
					font-size: 1.2rem;
					line-height: 22px;
					height: 34px;
					padding: 0 10px;
				}
			}

			select{
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				display: block;
				cursor: pointer;
				width: 100%;
				border: none;
				height: 50px;
				opacity: 0;
				position: relative;
				z-index: 2;
				padding: 0 7px;
				font-size: 1.6rem;

				@include sp {
					height: 34px;
					font-size: 1.3rem;
				}
			}
		}

		&__radio{
			display: flex;
			align-items: center;
		}

		&__radioLabel{
			font-size: 1.4rem !important;
			line-height: 20px !important;
			font-weight: bold;
			color: #2579BE;
			margin-top: 0 !important;
			white-space: nowrap;

			@include sp {
				font-size: 1.3rem !important;
				line-height: 18px !important;
				letter-spacing: 0.05em;
			}
		}

		&__radioContent{
			margin-left: 15px;

			@include sp {
				margin-left: 10px;
			}
		}

		&__radioItem{
			display: flex;
			align-items: center;

			&:not(:last-child){
				margin-bottom: 10px;

				@include sp {
					margin-bottom: 5px;
				}
			}

			input{
				display: none;
			}

			.checkmark{
				font-size: 1.6rem;
				line-height: 24px;
				font-weight: bold;
				color: #253B73;
				background: url(/img/hoken/detail/c-box01_radio_off.png) no-repeat center left;
				background-size: 22px 22px !important;
				padding-left: 27px;
				cursor: pointer;
				transition: 0.3s;
				white-space: nowrap;

				@include sp {
					font-size: 1.4rem;
					background-size: 18px 18px !important;
					padding-left: 23px;
				}
			}

			input[type="radio"]:checked+label{
				background: url(/img/hoken/detail/c-box01_radio_on.png) no-repeat center left;
			}
		}

		&__btn{
			width: 110px;
			height: 100px;
			background: #2579BE;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 15px;
			cursor: pointer;
			position: relative;

			@include sp {
				width: 66px;
				margin-left: 10px;
			}

			@include max-w(374px) {
				width: 100%;
				margin-left: 0;
				margin-top: 20px;
				height: 50px;
			}

			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #fff;
				opacity: 0;
				transition: 0.3s;
			}

			&:hover::before{
				opacity: 0.3;
			}

			img{
				@include sp {
					width: 40px;
				}
			}
		}

		&__note {
			font-size: 1.2rem;
			color: #9E9E9E;
			margin-top: 0;

			@include sp {
				margin-top: 15px;
			}
		}
	}

	.c-popup{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(70,72,60,0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10000;
		opacity: 0;
		visibility: hidden;
		transition: 0.3s;

		@include sp {
			padding: 20px;
		}

		&.show{
			opacity: 1;
			visibility: visible;
		}

		&__wrap{
			width: 670px;
		}

		&__close{
			text-align: right;
			margin-bottom: 20px;
			cursor: pointer;

			@include sp {
				margin-bottom: 10px;
			}
		}

		&__title{
			background: #2579BE;
			font-size: 2.5rem;
			font-weight: bold;
			color: #fff;
			text-align: center;
			padding: 10px;
			border-radius: 4px 4px 0 0;

			@include sp {
				font-size: 1.8rem;
				line-height: 26px;
				padding: 8px 10px;
			}
		}

		&__content{
			background: #fff;
			border-radius: 0 0 4px 4px;
			padding: 60px 60px;

			@include sp {
				padding: 30px;
			}
		}

		&__item{
			display: flex;

			&:not(:last-child){
				margin-bottom: 37px;
			}
		}

		&__label{
			min-width: 110px;

			@include sp {
				min-width: 82px;
			}

			span{
				font-size: 1.8rem;
				font-weight: bold;
				color: #fff;
				background: #2579BE;
				border-radius: 4px;
				padding: 4px 6px;

				@include sp {
					font-size: 1.4rem;
					line-height: 22px;
				}
			}
		}

		&__text{
			flex: 1;
			font-size: 2rem;
			font-weight: bold;
			color: #46483C;
			line-height: 24px;
			padding-left: 30px;

			@include sp {
				padding-left: 20px;
				font-size: 1.8rem;
				line-height: 22px;
			}

			&.cl-blue{
				color: #2579BE;
			}

			.price{
				font-size: 3rem;
				margin-right: 5px;

				@include sp {
					font-size: 3rem;
				}
			}

			.txt{
				display: block;
				font-size: 1.8rem;
				margin-top: 10px;

				@include sp {
					font-size: 1.8rem;
					line-height: 22px;
				}
			}
		}

		.c-hokenItemSerect__item__btn{
			margin-top: 37px;
			padding-top: 40px;
			border-top: 1px solid #DDDDDD;

			@include sp {
				margin-top: 24px;
				padding-top: 30px;
			}
		}

		.c-hokenItemSerect__item__btn .c-hokenItemSerect__item__request{
			margin-top: 0;

			a {
				@include sp {
					width: 100%;
				}
			}

			&:first-child{
				@include sp {
					margin-top: 0 !important;
				}
			}
		}
	}
}
.p-hokenService-02 {
    @include pc {
        display: flex;
        column-gap: 10px;
        align-items: baseline;
    }
    dt {
        color: #333;
        font-size: 1.4rem;
        @include pc {
            font-size: 1.6rem;
        }
    }
    dd {
        margin-top: 5px;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        @include pc {
            gap: 10px;
        }
    }
}
.p-hokenService-03 {
	&__box {
        margin-left: auto;
        margin-right: auto;
		display: block;
		max-width: 840px;
		height: 100%;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

/*コンテンツ
---------------------------------------------------------------------------*/
#contents {
	max-width: 840px;
	font-size: 16px;
	line-height: 1.7;
	color: #46483d;
}

/*リンク
---------------------------------------------------------------------------*/
#contents a {
	color: #336699;	/*リンクテキストの色*/
}
#contents a:visited {
	color: #7B517B;
}
#contents a:hover {
	text-decoration: none;
}

/*見出し
---------------------------------------------------------------------------*/
#contents h2 {
	margin-top: 40px;
	margin-bottom: 15px;
	color: #fff;
	padding: 8px 20px;
	background: #82cdf4;
	border-radius: 4px;
	font-size: 20px;
	@include sp {
		font-size: 1.8rem;
	}
}


/*汎用
---------------------------------------------------------------------------*/
.clearfix:after {
    content:" ";
    display:block;
    clear:both;
}
.center{
	text-align:center;
}
.left{
	text-align:left;
}
.right{
	text-align:right;
}
.f-left {
	float: left;
}
.f-right {
	float: right;
}
.txtbld {
    font-weight: bold;
}
.txt-attention {
	color: #F00;
}
.m-top-5 {margin-top: 5px;}
.m-top-10 {margin-top: 10px;}
.m-top-15 {margin-top: 15px;}
.m-top-20 {margin-top: 20px;}
.m-top-30 {margin-top: 30px;}
.m-top-40 {margin-top: 40px;}
.m-bottom-5 {margin-bottom: 5px;}
.m-bottom-6 {margin-bottom: 6px;}
.m-bottom-10 {margin-bottom: 10px;}
.m-bottom-15 {margin-bottom: 15px;}
.m-bottom-20 {margin-bottom: 20px;}
.m-bottom-30 {margin-bottom: 30px;}
.notes01 {
	padding-left: 1em;
	text-indent: -1em;
	font-size: 14px;
	line-height: 1.5;
	@include sp {
		font-size: 1.2rem;
		line-height: 1.5;
	}
}
.notes02 {
	font-size: 14px;
	padding-left: 25px;
	background: url(../img/hoken/ico_caution.gif) no-repeat left top;
	margin-top: 5px;
	@include sp {
		font-size: 1.2rem;
	}
}
.caution {
	font-weight: bold;
	color: #D8347B;
}
#contents sup {
	font-style: normal;
	font-size: 70%;
	height: 0;
	line-height: 1;
	vertical-align: top;
	_vertical-align: top;
	position: relative;
	top: 0.5em;
}


/*商品ページ簡易版テンプレート
---------------------------------------------------------------------------*/
.ribbon {
    display: inline-block;
    position: relative;
    height: 60px;/*高さ*/
    line-height: 60px;/*高さ*/
    vertical-align: middle;
    text-align: center;
    padding: 0 40px 0 18px;/*文字の左右の余白*/
    font-size: 18px;/*文字サイズ*/
    background: #DD0729;/*背景色*/
    color: #FFF;/*文字色*/
    box-sizing: border-box;
	font-weight: bold;
	@include sp {
		font-size: 1.6rem;
	}
}

.ribbon:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    z-index: 1;
}

.ribbon:after {
    top: 0;
    right: 0;
    border-width: 30px 15px 30px 0px;
    border-color: transparent #fff transparent transparent;
    border-style: solid;
}
.frame {
	background-color: #FFF;
	border-radius: 6px;
	color: #DD0729;
	padding: 0.5em;
	margin-right: 1em;
}
.figure {
	font-size: 38px;
	vertical-align: middle;
}
.shudan-wrap {
	border: 2px solid #E83743;
	border-radius: 10px;
	margin-bottom: 30px;
}
.shudan-wrap .shudan-ttl,
.shudan-wrap .txt {
	display: table-cell;
	vertical-align: middle;
}
.shudan-wrap .txt {
	padding: 4px 15px 4px 24px;
}
.shudan-ttl {
	position: relative;
	display: inline-block;
	width: 110px;
	height: 140px;
	background: #E83743;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}
.shudan-ttl:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 100%;
	margin-top: -15px;
	border: 15px solid transparent;
	border-left: 15px solid #E83743;
}
.shudan-ttl img {
	margin: 0 auto;
	display: block;
}
/*.shudan02-wrap {
	position: relative;
 	margin: -15px auto 30px;
 	padding: 2.5em 1em 1em;
 	background-color: #FFEBE9;
}
.shudan02-ttl {
 	position: absolute;
	top: 8px;
 	left: 15px;
 	background-color: #FFEBE9;
 	color: #E83743;
 	font-size: 1.1em;
 	font-weight: bold;
}*/
.point-wrap {
	overflow: hidden;
	_zoom: 1;
	border: 2px solid #ddd;
	padding: 10px;
	margin-top: 13px;
}
.point-img {
	padding-right: 15px;
	float: left;
}
.point-txt {
	overflow: hidden;
	_zoom: 1;
}
.cv-area {
	text-align: center;
	margin-top: 30px;
	font-size: 14px;
	@include sp {
		font-size: 1.2rem;
	}
}
.linearrow {
	position: relative;
}
.linearrow img {
	position: relative;
	z-index: 2;
	display: inline-block;
	padding-top: 24px;
}
.linearrow:before {
	position: absolute;
	top: 50%;
	z-index: 1;
	content: '';
	display: block;
	width: 100%;
	height: 1px;
	background-color: #644141;
}
.cv-txt {
	text-align: center;
	margin: 30px 0 0;
}
.point-sp,
.shudan-ttl-sp {display: none;}

.c-hoken_txt01 {
	font-size: 2.0rem;
	@include sp {
		font-size: 1.8rem;
	}
}
.c-hoken_txt02 {
	font-size: 1.6rem;
	@include sp {
		font-size: 1.4rem;
	}
}
.c-hoken_txt03 {
	font-size: 1.4rem;
	@include sp {
		font-size: 1.2rem;
	}
}

@include sp {
	.point-img,
	.shudan-wrap .shudan-ttl {display: none;}
	.point-sp {
		display: block;
		padding: .3em .75em;
		border-radius: 6px;
		text-align: center;
		margin-bottom: 5px;
		color: #fff;
		font-weight: bold;
	}
	.shudan-ttl-sp {
		display: block;
		color: #E83743;
		font-weight: bold;
		text-align: center;
	}
	.ribbon {line-height: 1.2;}
	#contents {
		font-size: 1.4rem;
		line-height: 1.65;
		input {
			width: 100%;
		}
		label {
			width: 100%;
		}
	}

}

.p-hokenService-04 {
	margin-top: 40px;
	&__table {
		width: 100%;
		border-collapse: collapse;
		th, td {
			padding: 15px 30px 13px;
			vertical-align: middle;
			border-top: 1px solid #dddddd;
			border-bottom: 1px solid #dddddd;
			font-size: 1.6rem;
			line-height: 1.6;
		}
		th {
			width: 240px;
			text-align: left;
			font-weight: bold;
			color: #253b73;
			background-color: #e6f5fd;
		}
		td {
			.p-hokenService-04__table__midashi {
				font-size: 1.8rem;
				font-weight: bold;
				color: #000;
			}
			.p-hokenService-04__table__name {
				font-weight: bold;
				color: #000;
			}
			span {
				color: #999999;
			}
			dl {
				dt {
					margin-top: 4px;
					padding-left: 12px;
					font-size: 1.6rem;
					font-weight: bold;
					background: url('../img/hoken/service_dot.png') no-repeat left center;
					span {
						color: #999999;
					}
				}
				dd {
					padding-left: 15px;
					span {
						color: #999999;
					}
				}
			}
		}
	}
	.p-hokenService-04__number {
		margin-top: 16px;
		font-size: 1.2rem;
		color: #999999;
	}
}

@include sp {
	.page-hoken__service {
		.c-hokenItemSerect__item__request {
			margin-top: 16px;
			a {
				display: block;
				position: relative;
				width: 75%;
				height: auto;
				padding: 10px 34px 8px;
				font-weight: bold;
				line-height: 1.2;
				&::before {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 12px;
					margin: 0;
				}
			}
		}
	}
	.p-hokenService-03 {
		.c-hokenTitle02 {
			margin-bottom: 21px;
			font-size: 1.8rem;
		}
	}
	.p-hokenService-04 {
		&__table {
			border: 1px solid #dddddd;
			border-top: none;
			tr, th, td {
				display: block;
				width: 100%;
			}
			th, td {
				font-size: 1.4rem;
			}
			th {
				border-bottom: none;
				padding: 12px 15px 9px;
			}
			td {
				padding: 8px 15px 11px;
				border: none;
				.p-hokenService-04__table__midashi {
					font-size: 1.5rem;
				}
				dl {
					dt {
						margin-top: 3px;
						font-size: 1.4rem;
					}
					dd {
						padding-left: 15px;
					}
				}
			}
		}
		.p-hokenService-04__number {
			margin-top: 12px;
			font-size: 0.9rem;
		}
	}
}

/*
------------------------------------------------------------*/
