@use '../../0_base' as *;

/*------------------------------------------------------------
リフォームTOP
------------------------------------------------------------*/
.p-serviceTop {
}

//リフォームTOP　MV
.p-reformTop01 {
	width: 100%;
	min-width: $baseWidth01;
	height: 675px;
	margin: 0 auto;
	background-color: #ffffff;
	background-image: url("../img/reform/index_mv.jpg");
	background-repeat: no-repeat;
	background-position: center 0;
	overflow: hidden;
	.p-reformTop01__in {
		height: 560px;
		padding-top: 420px;
		position: relative;
	}
	.p-reformTop01__txt01 {
		width: 470px;
		position: absolute;
		top: 175px;
		left: 0;
		&--color1{
			font-size: 2.7rem;
			color:#043b18;
			letter-spacing: -4px;
			padding-top: 8px;
    		padding-bottom: 16px;
    		padding-left: 1px;
		}
		&--color2{
			font-size: 3.8rem;
			color:#342603;
			letter-spacing: -5px;
			//display: none;
			span{
				color: red;
			}
		}
		.u-redColor{
			color: #d23600;
		}
	}
	.p-reformTop01__bnr {
		width: 100%;
		li {
			width: 301px;
			height: 234px;
			margin: 8px -24px;
			padding: 0 18px 0 18px;
			position: relative;
			background-size: 100% 100%;
			background-image: url("../img/reform/mv_bnr_bg01.png");
			@include sp{
				padding: 1% 3% 0;
				margin: 0;
				height: auto;
			}
			.bnr-img{
				margin: 8px 7px;
				height: 122px;
				overflow: hidden;
				img{
					height: 122px;
					-webkit-transition: .3s ease-in-out;
					transition: .3s ease-in-out;
				}
				@include sp{
					margin: -6px 3px;
					height: auto;
					img{
						width: 100%;
						height: auto;
					}
				}
			}

			.bnr-txt{
				text-align: center;
				color: #342603;
				font-weight: bold;
				padding-top: 10px;
				&__txt01{
					font-size: 2rem;
					line-height: 1;
				}
				&__txt02{
					font-size: 1.3rem;
				}
				@include sp{
					padding-top: 19px;
					padding-bottom: 30px;
					&__txt01{
						font-size: 1.2rem;
					}
					&__txt02{
						font-size: 1rem;
					}
				}

			}
			.bnr-icon{
				float: right;
				width: 30px;
				height: 30px;
				background: #247404;
				padding-top: 20px;
				right: 18px;
   				position: absolute;
    			bottom: 28px;
				&:after{
					content:'';
					position: absolute;
					width: 8px;
					height: 8px;
					border-top: 1px solid #fff;
					border-right: 1px solid #fff;
					top: 33%;
					left: 33%;
					transform: rotate(45deg);
				}
				@include sp{
					width: 18px;
					height: 18px;
					right: 5%;
    				bottom: 11%;
					&:after{
						width: 6px;
						height: 6px;
					}
				}
			}
			&:hover{
				.bnr-img {
					img {
						-webkit-transform: scale(1.05);
	  					transform: scale(1.05);
	  					@include sp{
	  						-webkit-transform: scale(1);
	  						transform: scale(1);
	  					}
					}
				}

			}
		}
	}
}

//リフォームTOP お知らせ
.p-reformTop02 {
	margin-bottom: 46px;
	padding: 20px;
	background-image: url("../img/reform/bg01.jpg");
	.p-reformTop02__contents01 {
		position: relative;
		h2 {
			margin-bottom: 16px;
			padding-left: 16px;
			font-size: 2.0rem;
			font-weight: bold;
			color: $reformTextColor01;
			span {
				margin: 6px 0 0 24px;
				display: inline-block;
				vertical-align: middle;
				img {
					width: 97px;
				}
			}
		}
		.p-reformTop02__contents01__newsList {
			padding: 2px 30px 5px;
			background-color: #ffffff;
			li {
				padding: 27px 0 21px;
				border-bottom: dotted 1px $gray07;
				&:last-child {
					border: none;
				}
				p {
					width: 525px;
					color: $gray01;
					font-size: 1.4rem;
					font-weight: 500;
					line-height: 1.5;
				}
				a {
					color: $gray01;
					font-size: 1.4rem;
					font-weight: 500;
					line-height: 1.5;
					text-decoration: underline;
					&:hover{
						text-decoration: none;
						opacity: 1;
					}
				}
			}
			.p-reformTop02__contents01__newsList_data {
				width: 110px;
				font-size: 1.4rem;
				font-weight: 500;
				padding: 2px 0 0 4px;
				color: $gray03;
			}
			.c-newsCate02 {
				width: 82px;
				margin-right: 20px;
				font-weight: 500;
			}
		}
		.c-reformBtn01 {
			position: absolute;
			top: 1px;
			right: 2px;
		}
	}
}

//ライフスタイルにあわせたリフォーム
.p-reformTop03 {
	margin-bottom: 44px;
	.p-reformTop03__contents01 {
		//コンポーネント　c-gallery
		.c-gallery{
			&__item{
				margin-bottom: 8px;
				.u-hover01{
					&:hover{
						opacity: 1;
					}
				}
			}
		}
	}
}

//リフォーム空間から探す
.p-reformTop04 {
	margin-bottom: 93px;
	.p-reformTop04__contents01 {
		h3 {
			margin-bottom: 34px;
			padding: 9px 20px;
			font-size: 1.8rem;
			font-weight: bold;
			position: relative;
			a {
				color: $reformTextColor01;
			}
			&:before, &:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 1px;
				display: block;
				background: url(../img/reform/line.png) repeat-x;
				left: 0;
			}
			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
		}

		//コンポーネント　c-iconList

		.c-iconList__text {
			a,p {
				text-decoration: underline;
			}
		}

		.l-left {
			margin-bottom: 50px;
			.c-iconList{
				ul{
					li{
						a{
							&:hover{
								.c-iconList__image{
									transform: translateY(-5px);
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: $breakpoint01 + 1) {
	.p-reformTop04 {
		.u-hover01 {
			img {
				transition: opacity 0.3s ease-in-out;
			}
			&:hover {
				img {
					opacity: 0.50;
				}
			}
		}
	}
}

//リフォームの基礎知識
.p-reformTop05 {
	margin-bottom: 74px;
	.p-reformTop05__contents01 {
		//コンポーネント　c-miniBanner

	}
}

//リフォーム事例
.p-reformTop06 {
	margin-bottom: 100px;
	.p-reformTop06__contents01 {
		// コンポーネント　c-showCase

		.c-showCase {
			margin-bottom: 30px;
			&:nth-child(1){
				.c-showCase__body {
					background: #f5f5f5 url('/img/reform/case-icon02.png') no-repeat bottom 20px left 20px;
					@include sp{
						background: #f5f5f5 url('/img/reform/case-icon02SP.png') no-repeat bottom 17px left 18px;
						background-size: 33px 24px;
					}
				}
			}
		}
	}
}



@include sp {
	//リフォームTOP　MV sp
	.p-reformTop01 {
		min-width: 100%;
		height: auto;
		margin-bottom: 25px;
		background: none;
		.p-reformTop01__in {
			height: auto;
			padding: 0;
		}
		.p-reformTop01__txt01 {
			width: 100%;
			height: 220px;
			position: static;
			background: url(../img/reform/index_mv_sp.png) center top -23px;
		    background-size: cover;
		    &--wrapper{
		    	line-height: 1.5;
		    	position: relative;
		    	top: 50%;
		    	left: 20px;
		    	transform: translate(0, -59%);
		    }
			&--color1{
				font-size: 2.5rem;
			}
			&--color2{
				font-size: 2.5rem;
    			line-height: 30px;
    			letter-spacing: -3.5px;
    			position: relative;
			    top: 19%;
			    left: 4px;
			}
		}
		.p-reformTop01__bnr {
			margin-top: -12px;
			padding: 0 20px;
			li {
				width: calc(50% - 5px);
				padding: 0;
				background: none;
				margin-left: 10px;
				margin-bottom: 20px;
				margin-top: -13px;
				&:nth-child(2n + 1){
					margin-left: 0;
				}
				&:nth-child(3){
					.bnr-txt{
						padding: 9px 0 6px 3px;
					}
				}
				&:after{
					content: "";
					width: calc(100% + 18px);
					height: 14px;
					position: absolute;
					bottom: -14px;
					left: -9px;
					background: url("/img/reform/bnr_bottom.png") no-repeat center bottom;
					background-size: 100% 14px;
				}
				a{
					display: block;
					position: relative;
					&:before{
						content: "";
						width: 9px;
						height: 77%;
						left: -9px;
						bottom: 0;
						background: url("/img/reform/bnr_before_sp.png") no-repeat center right;
						background-size: 100% auto;
						position: absolute;
					}
					&:after{
						content: "";
						width: 9px;
						height: 77%;
						right: -9px;
						bottom: 0;
						background: url("/img/reform/bnr_right.png") no-repeat center left;
						background-size: 100% auto;
						position: absolute;
					}
				}
				.bnr-img{
					margin: 0;
				}
				.bnr-txt{
					background: #FFF;
					padding: 9px 0 6px;
					&__txt01{
						font-size: 1.3rem;
						line-height: 1.4rem;
					}
				}
				.bnr-icon{
					bottom: 0;
					right: 0;
					width: 15px;
					height: 15px;
					padding-top: 0;
					&:after{
						width: 4px;
						height: 4px;
					}
				}
			}
		}
	}
	//リフォームTOP お知らせ sp
	.p-reformTop02 {
		margin-bottom: 30px;
		padding: 30px 20px;
		.p-reformTop02__contents01 {
			h2 {
				margin-bottom: 19px;
				font-size: 1.6rem;
				line-height: 1;
				text-align: center;
				padding-left: 0px;
				padding-right: 2px;
				letter-spacing: 0.2px;
				span {
					margin: 5px auto 0 auto;
					display: block;
					font-size: 0.9rem;
					img{
						width: 70px;
					}
				}
			}
			&--wrapper{
				background-color: #fff;
				padding-bottom: 18px;
			}
			.p-reformTop02__contents01__newsList {
				padding: 5px 21px 14px;
				li {
					padding: 15px 0 13px;
					&:last-child {
						border-bottom: dotted 1px $gray07;
					}
					&:nth-of-type(2){
						padding: 15px 0 15px;
					}
					a {
						width: 100%;
						font-size: 1.4rem;
						padding: 0 0 0 0;
					}
				}
				.p-reformTop02__contents01__newsList_data {
					width: auto;
					margin: 0 1em 7px 0;
					font-size: 1.1rem;
					font-weight: normal;
					letter-spacing: 1.5px;
					padding: 1px 0 0;
				}
				.c-newsCate02 {
					margin: 0 0 7px 0;
					width: auto;
					min-width: 72px;
					height: auto;
				    padding: 0 9px;
				    line-height: 15px;
					&--reformCanpain, &--reformOshirase{
						font-size: 0.9rem;
					}
				}
			}
			.c-reformBtn01 {
				width: 40%;
				margin: 0 auto;
				position: static;
				padding: 3px 0 1px;
				a{
					font-size: 1.1rem;
					&:after{
						margin: -2px 0 0 5px;
					}
				}
			}
		}
	}
	//ライフスタイルにあわせたリフォーム sp
	.p-reformTop03 {
		margin-bottom: 20px;
		.p-reformTop03__contents01 {
			//コンポーネント　c-gallery
			.c-refomeTitle01{
				margin-bottom: 20px;
				line-height: 2.6rem;
				span{
					padding: 0 43px;
					background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 4px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 4px;
					background-size: 25px;
				}
				img {
				    margin: 6px auto 6px auto;
				    width: 63px;
				    height: 51px;
				}
			}
			.c-gallery{
				&__item{
					margin-bottom: 9px;
					> a{
						padding: 6px 5px 14px;
					}
					&:nth-of-type(3){
						> a{
							padding: 6px 5px 7px;
						}
						.c-gallery__title{
							padding: 7px 3px 0;
						}
					}
					&:nth-of-type(4){
						.c-gallery__title{
							padding: 14px 3px 0;
						}
					}
					&:nth-of-type(5){
						.c-gallery__title{
							padding: 24px 3px 0;
						}
					}
				}
				&__title{
					justify-content: left;
					padding: 16px 3px 0;
					p{
						&:before{
							top: 50%;
							left: 1px;
							width: 5px;
							height: 9px;
							background-size: contain;
						}
					}
				}
			}
		}
	}
	//リフォーム空間から探す sp
	.p-reformTop04 {
		margin-bottom: 25px;
		.p-reformTop04__contents01 {
			padding: 0 20px;
			h3 {
				margin-bottom: 12px;
				padding: 6px 15px;
				font-size: 1.5rem;
				text-align: center;
			}
			.c-refomeTitle01{
				margin-bottom: 21px;
				line-height: 2.6rem;
				span{
					padding: 0 43px;
					background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 4px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 4px;
					background-size: 25px;
				}
				img {
				    margin: 6px auto 10px auto;
				    width: 74px;
				    height: 44px;
				}
			}
			//コンポーネント　c-iconList
			.l-left {
				margin-bottom: 6px;
				.c-iconList{
					&__image{
						padding-top: 7px;
						margin-bottom: 10px;
					}
					&--reform01{
						margin-bottom: 11px;
						li{
							margin-bottom: 9px;
							&:nth-of-type(1){
								.c-iconList__image{
									margin-right: 15px;
								}
								.c-iconList__text{
									padding-right: 15px;
								}
							}
							&:nth-of-type(2){
								.c-iconList__text{
									padding-left: 5px;
								}
							}
							&:nth-of-type(3){
								.c-iconList__image{
									margin-left: 7px;
								}
								.c-iconList__text{
									padding-left: 6px;
								}
							}
							&:nth-of-type(4){
								.c-iconList__image{
									margin-right: 7px;
								}
								.c-iconList__text{
									padding-right: 6px;
								}
							}
						}
					}
					&--reform02{
						margin-bottom: 13px;
						li{
							margin-bottom: 7px;
							&:nth-of-type(1){
								.c-iconList__image{
									margin-right: 6px;
								}
								.c-iconList__text{
									padding-right: 6px;
								}
							}
							&:nth-of-type(3){
								.c-iconList__image{
									margin-left: 7px;
								}
								.c-iconList__text{
									padding-left: 6px;
								}
							}
							&:nth-of-type(4){
								.c-iconList__image{
									margin-right: 7px;
								}
								.c-iconList__text{
									padding-right: 6px;
								}
							}
							&:nth-of-type(6){
								.c-iconList__image{
									margin-left: 7px;
								}
								.c-iconList__text{
									padding-left: 6px;
								}
							}
						}
					}
					&--reform03{
						li{
							margin-bottom: 7px;
							&:nth-of-type(1){
								.c-iconList__image{
									margin-right: 6px;
								}
								.c-iconList__text{
									padding-right: 6px;
								}
							}
							&:nth-of-type(3){
								.c-iconList__image{
									margin-left: 7px;
								}
								.c-iconList__text{
									padding-left: 6px;
								}
							}
							&:nth-of-type(4){
								.c-iconList__image{
									padding-top: 0;
								    margin-right: 7px;
								    margin-bottom: 0;
								    height: 35px;
								}
								.c-iconList__text{
									padding-right: 6px;
								}
							}
						}
					}
				}
			}
		}
	}
	//リフォームの基礎知識 sp
	.p-reformTop05 {
		margin-bottom: 36px;
		.p-reformTop05__contents01 {
			padding: 0 20px;
			.c-refomeTitle01{
				margin-bottom: 21px;
				line-height: 2.6rem;
				span{
					padding: 0 43px;
					background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 4px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 4px;
					background-size: 25px;
				}
				img {
				    margin: 0 auto 7px auto;
				    width: 63px;
				    height: 51px;
				}
			}
			//コンポーネント　c-miniBanner
			.c-miniBanner {
				margin-bottom: 10px;
				background-size: contain;
				> a{
					padding-left: 20px;
				}
				&--2{
					> a{
					    padding-left: 16px;
					}
				}
			}
		}
	}
	//リフォーム事例 sp
	.p-reformTop06 {
		margin-bottom: 35px;
		.p-reformTop06__contents01 {
			padding: 0 20px;
			.c-refomeTitle01{
				margin-bottom: 18px;
				line-height: 2.6rem;
				span{
					padding: 0 42px;
					background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 7px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 7px;
					background-size: 25px;
				}
				img {
				    margin: 0 auto 7px auto;
				    width: 63px;
				    height: 51px;
				}
			}
			// コンポーネント　c-showCase
			.c-showCase {
				margin-bottom: 22px;
				&__head{
					padding: 6px 15px;
					h2{
						letter-spacing: -0.8px;
					}
				}
				&__body {
				    padding: 15px 15px 12px;
				    background: #f5f5f5 url(/img/component/reform_case-icon01SP.png) no-repeat bottom 14px left 18px;
				    background-size: 25px 31px;
				}
			}
			.c-reformBtn02{
				margin-top: -2px;
				padding: 0;
				a{
    				letter-spacing: -0.3px;
    				&:after{
    					margin: -2px 0 0 6px;
    				}
				}
			}
		}
	}
}
/*
------------------------------------------------------------*/
.js-fade{
    opacity: 0;
    transform: translate(0, 60px);
}
.is-effect {
    opacity: 1;
    transform: translate(0, 0);
}
.is-first{
	transition: .8s;
}
.is-second{
	transition: 1.2s;
}
.is-third{
	transition: 1.6s;
}
.is-fourth{
	transition: 2s;
}