@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　お葬式勉強会フォーム（神奈川・山梨）
------------------------------------------------------------*/
.p-sosaiContactSeminar {
	padding: 50px 0 135px 0;
	background-color: #ffffff;
}


//見出し
.p-sosaiContactSeminar01 {
	margin-bottom: 30px;
	.c-formTitle {
		color: $sosaiTextColor01;
	}
}


//本文
.p-sosaiContactSeminar02 {
	margin-bottom: 50px;
	p {
		font-size: 1.6rem;
		line-height: 1.75;
		color: $gray08;
		a {
			text-decoration: underline;
			color: $gray01;
		}
	}
}

//お申し込み前のご確認事項
.p-sosaiContactSeminar03 {
	.p-sosaiContactSeminar03__content01 {
		margin-bottom: 50px;
		dl {
			dt {
				margin-bottom: 13px;
				font-size: 2.0rem;
				font-weight: bold;
				color: $gray08;
				&:before {
					width: 8px;
					height: 8px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $sosaiBtnColor01;
					display: inline-block;
					vertical-align: middle;
				}
			}
			dd {
				margin-bottom: 20px;
				font-size: 1.6rem;
				line-height: 1.75;
				color: $gray08;
				a {
					color: $gray01;
					text-decoration: underline;
					&:hover {
						opacity: 1;
						text-decoration: none;
					}
				}
				p {
					width: 800px;
				}
			}
		}
	}
	.p-sosaiContactSeminar03__content02 {
		//コンポーネント　c-cartBtn
		&__back {
			margin-top: 20px;
			font-size: 1.8rem;
			display: block;
			text-align: center;
			color: $gray01;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
			&:before {
				width: 7px;
				height: 14px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_arrow-blue04.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}
		}
	}
}

@include sp {
	.p-sosaiContactSeminar {
		padding: 30px 20px 80px 20px;
	}

	//見出し sp
	.p-sosaiContactSeminar01 {
		margin-bottom: 20px;
		.c-formTitle {
			margin-bottom: 20px;
		}
	}

	//本文
	.p-sosaiContactSeminar02 {
		margin-bottom: 30px;
		p {
			font-size: 1.4rem;
		}
	}

	//お申し込み前のご確認事項 sp
	.p-sosaiContactSeminar03 {
		.p-sosaiContactSeminar03__content01 {
			margin-bottom: 30px;
			.c-sosaiTitle05 {
				font-size: 1.6rem;
				margin-bottom: 15px;
			}
			dl {
				dt {
					margin-bottom: 10px;
					font-size: 1.4rem;
				}
				dd {
					margin-bottom: 15px;
					font-size: 1.4rem;
					p {
						width: 100%;
						margin-bottom: 10px;
					}
					img {
						margin: 0 auto;
						display: block;
					}
				}
			}
		}
		.p-sosaiContactSeminar03__content02 {
			//コンポーネント　c-cartBtn
			&__back {
				font-size: 1.6rem;
			}
		}
	}
}
/*
------------------------------------------------------------*/
