@use '../0_base' as *;

/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
.c-article{
	margin-right: 10px;
	width: 280px;
	height: 300px;
	position: relative;
	padding: 6px 10px 10px 0;
	&:after {
		position: absolute;
		bottom: 0;
		right: 0;
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		z-index: -1;
		background: repeating-linear-gradient(-45deg, #fffbf8, #fffbf8 2px, #fee7d8 3px, #fee7d8 5px);
		content: '';
	}
	@include sp {
		width: 100%;
		height: auto;
		margin-right: 0;
	}
	&:last-child{
		margin-right: 0;
	}
	&__img{
		width: 266px;
		height: 150px;
		position: relative;
		margin-top: 10px;
	}
	&__image {
		img {
			width: 100%;
		}
	}
	&__type{
		display: inline-block;
		font-size: 1.4rem;
		font-weight: 500;
		color: #2579be;
		background: #fce14a;
		position: absolute;
		margin: -5px 0 0 10px;
		padding: 1px 7px;
		@include sp {
			font-size: 1rem;
			padding: 1px 9px;
		}
	}
	&__content{
		// width: 266px;
		// height: 135px;
		background: #fffde0;
		padding: 17px 12px 17px 18px;
		@include sp {
			width: 100%;
			height: auto;
			padding: 11px 15px 11px;
		}
	}
	&__date{
		font-size: 1.4rem;
		font-weight: normal;
		color: #80807a;
		@include sp {
			font-size: 1.2rem;
		}
	}
	&__title{
		color: #253b73;
		font-size: 1.6rem;
		padding-top: 5px;
		font-weight: bold;
		transition: 0.3s ease-in-out;
		@include sp {
			font-size: 1.3rem;
			line-height: 19px;
		}
	}
	& > a {
		display: block;
		position: relative;
		@include sp {
			height: 100%;
		}
		&:before {
			width: 100%;
			height: 100%;
			content: '';
			position: absolute;
			border: solid 3px #ff957d;
			@include radius(4px);
			opacity: 0;
			transition: 0.3s ease-in-out;
		}
		&:hover {
			opacity: 1;
			&:before {
				opacity: 1;
			}
			.c-article__title {
				color: #ff957d;
			}
		}
	}
}


.c-slide1 {
	width: 840px;
	margin: 0 auto;
	@include sp {
		width: 100%;
	}
	.slick-dots {
		margin-right: 54px;
		@include sp {
			margin-right: 26px;
		}
		li {
			margin: 0;
			@include sp {
				width: 10px;
				height: 10px;
			}
			button {
				padding: 0;
				@include sp {
					width: 10px;
					height: 10px;
				}
				&:before {
					font-size: 12px;
					@include sp {
						font-size: 10px;
						width: 10px;
						height: 10px;
						top: -7px;
					}
				}
			}
			&.slick-active button:before {
				color: #82cdf3;
			}
			&:nth-child(even) {
				margin: 0 4px;
				@include sp {
					margin: 0 8px;
				}
			}
		}
	}

	.slick-track {
		@include sp {
			display: flex;
		}
		.slick-slide {
			@include sp {
				display: flex;
				flex-direction: column;
				float: none;
			}
		}
	}

	.slider-nav {
		margin-bottom: 24px;
		@include sp {
			margin-bottom: 15px;
		}
	}

	.slider-board {
		display: flex;
		justify-content: center;
		align-items: center;
		.slider-prev, .slider-next {
			cursor: pointer;
		}
		.slick-dots {
			position: static;
			bottom: auto;
		}
	}
}

.c-slide2 {

    @include pc {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
    }

    .slick-slider {
        margin-bottom: 0;
    }

    .slick-list {
        @include pc {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: auto;
    }

    &__item {
        margin-left: 7.5px;
        margin-right: 7.5px;
        width: 150px;
        height: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        overflow: hidden;
        display: block;
        color: #000;
        font-size: 1.4rem;
        font-weight: bold;

        @include pc {
            margin-left: 20px;
            margin-right: 20px;
            width: auto;
            border-width: 3px;
            border-radius: 10px;
        }

        &__image {
            aspect-ratio: 300/200;

            @include pc {
                aspect-ratio: 373.33331/180;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            padding: 10px;

            @include pc {
                padding: 15px 20px;
            }
        }

        &__type {
            padding: 5px;
            width: fit-content;
            background-color: #E4F7FF;
            border-radius: 5px;
            color: #41B4E6;
            font-size: 1rem;
            font-weight: bold;

            @include pc {
                padding: 5px 10px;
                border-radius: 10px;
                font-size: 1.2rem;
            }
        }

        &__title {
            margin-top: 5px;
            font-size: 1.4rem;
            font-weight: bold;

            @include pc {
                margin-top: 10px;
                font-size: 1.6rem;
            }
        }
    }

    .slider-board {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 50px;

        @include pc {
            margin-top: 20px;
            column-gap: 80px;
        }
    }

    .slick-dots {
        display: flex;
        column-gap: 10px;
        position: static;
        width: auto;
        list-style: none;

        @include pc {
            column-gap: 20px;
        }

        li {
            margin: 0;
            width: 10px;
            height: 10px;

            @include pc {
                width: 12px;
                height: 12px;
            }
        }

        .slick-active button {
            background-color: #41B4E6;
        }

        button {
            padding: 0;
            width: 100%;
            height: 100%;
            background-color: #D9D9D9;
            border: none;
            border-radius: 50%;
            text-indent: -9999px;
            overflow: hidden;

            &:before {
                display: none;
            }
        }
    }

    .slider-prev,.slider-next {
        width: 42px;
        height: 42px;
        background-color: #fff;
        border: 2px solid #41B4E6;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
            width: 18px;
            height: 18px;
            display: block;
            color: #41B4E6;
        }
        @include pc {
            width: 56px;
            height: 56px;
            border-width: 3px;
            transition: .3s ease-in-out;
            svg {
                width: 28px;
                height: 28px;
            }
            &:hover {
                background-color: #41B4E6;
                border-color: #41B4E6;
                svg {
                    color: #fff;
                }
            }
        }
    }
}
