@use '../0_base' as *;

/*------------------------------------------------------------
other
------------------------------------------------------------*/

/* オーバーレイ
------------------------------------------------------------*/
.c-overlay01 {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999999999999;
}
.c-overlay02 {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
}

/* 保険 sp menu modal-overlay
------------------------------------------------------------*/
.c-overlay03 {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
    display: none;
    &.is-open2{
        display: block;
    }
}

/* チケット　ページャー
------------------------------------------------------------*/
.c-ticketPager01 {
	text-align: center;
	position: relative;
	a {
		display: inline-flex;
		width: 25px;
		height: 25px;
		justify-content: center;
		align-items: center;
		font-size: 1.8rem;
		font-weight: 500;
		color: #4b5870;
		border-radius: 50px;
		cursor: pointer;
		margin: 0 5px;
		line-height: 1;
		background-color: #fff;
	}
	.ticketPager01-js {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.jp-previous,
	.jp-next {
		font-size: 0;
		color: #fff;
	}
	.jp-previous {
		background: #fff url("../img/common/icon_arrow-gray03.png") no-repeat center;
		background-size: 10px;
		transform: rotate(180deg);
	}
	.jp-next {
		background: #fff url("../img/common/icon_arrow-gray03.png") no-repeat center;
		background-size: 10px;
	}
	.jp-current {
		color: #fff;
		background-color: $ticketColor01;
		cursor: auto;
	}
	.jp-disabled {
		cursor: auto;
	}
	#ticketPager01-js {
		display: none;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		font-size: 1.6rem;
		font-weight: 500;
		color: #4b5870;
		margin-top: 6px;
	}
}
@include sp {
	.c-ticketPager01 {
		a {
			display: none;
		}
		.jp-previous,
		.jp-next {
			display: inline-flex;
			width: 30px;
			height: 30px;
			background-size: 20px;
		}
		.jp-previous {
			margin-right: 65px;
		}
		.jp-next {
			margin-left: 65px;
		}
		#ticketPager01-js {
			display: block;
		}
	}
}

/* くらしのサービス　ページャー
------------------------------------------------------------*/
.c-kurashiPager01 {
	text-align: center;
	position: relative;
	a {
		display: inline-flex;
		width: 26px;
		height: 26px;
		justify-content: center;
		align-items: center;
		font-size: 1.8rem;
		font-weight: 500;
		color: #6c727c;
		border-radius: 50px;
		cursor: pointer;
		margin: 0 5px;
		line-height: 1;
		background-color: #fff;
	}
	.kurashiPager01-js {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.jp-previous,
	.jp-next {
		width: 40px;
		height: 40px;
		font-size: 0;
		color: #fff;
	}
	.jp-previous {
		background-image: url("../img/common/icon_maru-sankaku01.png");
		background-size: cover;
		transform: rotate(180deg);
	}
	.jp-next {
		background-image: url("../img/common/icon_maru-sankaku01.png");
		background-size: cover;
	}
	.jp-current {
		color: #fff;
		background-color: $kurashiColor01;
		cursor: auto;
	}
	.jp-disabled {
		cursor: auto;
	}
	#kurashiPager01-js {
		display: none;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		font-size: 1.6rem;
		font-weight: 500;
		color: #594242;
		margin-top: 6px;
	}
}
@include sp {
	.c-kurashiPager01 {
		a {
			display: none;
		}
		.jp-previous,
		.jp-next {
			display: inline-flex;
			width: 30px;
			height: 30px;
		}
		.jp-previous {
			margin-right: 60px;
		}
		.jp-next {
			margin-left: 60px;
		}
		#kurashiPager01-js {
			display: block;
		}
	}
}

/* 葬祭　ページャー
------------------------------------------------------------*/
.c-sosaiPager01 {
	text-align: center;
	a {
		display: inline-flex;
		width: 40px;
		height: 40px;
		justify-content: center;
		align-items: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: $sosaiNaviColor02;
		background: #fff;
		cursor: pointer;
		margin: 0 2px;
		line-height: 1;
		border: solid 1px $sosaiNaviColor02;
	}
	.sosaiPager01-js {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.jp-previous,
	.jp-next {
		width: 80px;
		height: 40px;
		font-size: 0;
		color: #fff;
	}
	.jp-previous {
		background: #fff url("../img/component/btn02.png") no-repeat center;
		background-size: contain;
	}
	.jp-next {
		background: #fff url("../img/component/btn03.png") no-repeat center;
		background-size: contain;
	}
	.jp-current {
		color: #ffffff;
		background-color: $sosaiNaviColor04;
		border-color: $sosaiNaviColor04;
		cursor: auto;
	}
	.jp-disabled {
		cursor: auto;
	}
}
@include sp {
	.c-sosaiPager01 {
		a {
			width: 35px;
			height: 35px;
			font-size: 1.2rem;
		}
		.jp-previous,
		.jp-next {
			width: 70px;
			height: 35px;
		}
	}
}

/* 保険 sp menu modal
------------------------------------------------------------*/
.c-hokenModal{
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 102;
    &.is-open2{
        display: block;
    }
}

/* 保険商品選択
------------------------------------------------------------*/
.c-hokenItemSerect {
	font-size: 1.6rem;
	.c-hokenItemCheckAll {
		font-weight: 500;
		width: 50%;
		color: $hokenColor02;
		label {
			cursor: pointer;
			letter-spacing: 0.8px;
		}
		input[type="checkbox"] {
			display: none;
			+ span {
				&:before {
					width: 16px;
					height: 16px;
					margin: -5px 10px 0 0;
					content: "";
					background-image: url("../img/common/chekbox01.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
			}
			&:checked {
				+ span {
					&:before {
						background-image: url("../img/common/chekbox01_checked.png");
					}
				}
			}
		}
	}
	&__request {
		width: 50%;
		text-align: right;
		color: $gray01;
		@include dib;
		span {
			vertical-align: middle;
			font-weight: 500;
			letter-spacing: 0.5px;
		}
		a {
			width: 221px;
			height: 50px;
			margin-left: 10px;
			font-size: 1.8rem;
			font-weight: 600;
			line-height: 2.9;
			color: #ffffff;
			text-align: center;
			vertical-align: middle;
			background-image: url("../img/hoken/bg01.jpg");
			-webkit-background-size: cover;
			background-size: cover;
			@include radius(25px);
			&:before {
				width: 30px;
				height: 30px;
				margin: -5px 15px 0 -9px;
				content: "";
				background-color: #ffffff;
				background-image: url("../img/common/icon_file02.png");
				-webkit-background-size: 50%;
				background-size: 50%;
				background-repeat: no-repeat;
				background-position: center center;
				display: inline-block;
				vertical-align: middle;
				@include radius(50%);
			}
		}
	}
	&__items {
		margin-top: 17px;
		position: relative;
		z-index: 10;
	}
	&__item {
		margin-bottom: 20px;
		padding: 40px 40px 30px;
		border: 1px solid $glaylight01;
		position: relative;
		@include radius(4px);
		&:before {
			width: 224px;
			height: 3px;
			content: "";
			position: absolute;
			background-color: $hokenColor01;
			top: 0;
			left: 40px;
		}
		&__img {
			width: 223px;
			margin-right: 28px;
			text-align: center;
			position: relative;
			z-index: 10;
		}
		&__btn {
			display: flex;
			.c-hokenItemSerect__item__request {
				margin-right: 15px;
				&:last-child {
					margin-right: 0;
				}
			}
			&02 {
				justify-content: center;
			}
		}
		&__nameBox {
			width: 505px;
			position: relative;
			z-index: 10;
			.c-hokenItemSerect__boxTitle {
				margin: 22px 0 5px;
				color: $hokenColor02;
				font-weight: bold;
				line-height: 1.4;
				display: flex;
				flex-wrap: wrap;
				&__pTtl {
					width: 350px;
					font-size: 1.6rem;
					display: block;
					margin-top: -22px;
				}
				&__pName {
					font-size: 2rem;
					width: 100%;
					&:hover {
						text-decoration: underline;
					}
				}
				@include sp {
					font-size: 1.6rem;
					margin: 0 0 5px;
					&__pTtl {
						width: 100%;
						font-size: 1.3rem;
						margin-top: 0;
					}
					&__pName {
						font-size: 1.6rem;
					}
				}
			}
		}
		&__tags {
			margin-bottom: 5px;
			letter-spacing: -0.4em;
		}
		&__request {
			a {
				width: 160px;
				height: 40px;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: 2.4;
				color: #ffffff;
				text-align: center;
				display: block;
				color: $hokenTextColor02;
				background-color: #ffffff;
				border: solid 2px $hokenTextColor02;
				@include radius(20px);
				cursor: pointer;
				&:before {
					width: 24px;
					height: 24px;
					margin: -4px 20px 0 -22px;
					content: "";
					background-color: $hokenTextColor02;
					background-image: url("../img/common/icon_file01.png");
					-webkit-background-size: 50%;
					background-size: 50%;
					background-repeat: no-repeat;
					background-position: center center;
					display: inline-block;
					vertical-align: middle;
					@include radius(50%);
				}
			}
			&--internet {
				a {
					padding-left: 20px;
					color: $hokenBtnColor02;
					border: solid 2px $hokenBtnColor02;
					&:before {
						margin: -4px 7px 0 -22px;
						background-color: $hokenBtnColor02;
						background-image: url("../img/common/icon_mail02.png");
					}
				}
			}
		}
		&__txt {
			margin-top: 25px;
			color: $gray01;
			position: relative;
			z-index: 10;
			width: 100%;
			p {
				line-height: 1.8;
				font-weight: 500;
			}
			&__hokengaisya {
				margin-top: 6px;
				color: $gray02;
				line-height: 1.5;
			}
			&__number {
				color: $gray02;
				line-height: 1;
				font-weight: 300;
				display: flex;
				-webkit-flex-direction: row-reverse;
				-ms-flex-direction: row-reverse;
				flex-direction: row-reverse;
			}
		}
		.c-hokenItemCheck {
			font-size: 1.4rem;
			font-weight: 600;
			color: $gray01;
			position: absolute;
			top: 40px;
			right: 40px;
			z-index: 20;
			label {
				cursor: pointer;
				@include sp {
					font-size: 1.1rem;
				}
			}
			input[type="checkbox"] {
				display: none;
				+ span {
					&:after {
						width: 16px;
						height: 16px;
						margin: -5px 0 0 10px;
						content: "";
						background-image: url("../img/common/chekbox01.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
				&:checked {
					+ span {
						&:after {
							background-image: url("../img/common/chekbox01_checked.png");
						}
					}
				}
			}
		}
	}
	.c-hokenItemSerect__icon {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		.c-hokenItemSerect__icon--osusume {
			width: 100%;
			height: 100%;
			background-image: url("../img/hoken/bg08.jpg");
			@include radius(4px);
			&:before {
				content: "";
				position: relative;
				background-color: $subColor09;
				display: block;
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				left: 5px;
				top: 5px;
				@include sp {
					width: calc(100% - 4px);
					height: calc(100% - 4px);
					left: 2px;
					top: 2px;
				}
			}
			img {
				position: absolute;
				top: -5px;
				left: -5px;
				z-index: 20;
				@include sp {
					transform: scale(0.6);
					top: -17px;
					left: -35px;
				}
			}
		}
	}
}
@include sp {
	.c-hokenItemSerect {
		font-size: 1.4rem;
		.c-hokenItemCheckAll {
			width: 100%;
			font-size: 1.3rem;
			margin-bottom: 10px;
		}
		&__request {
			width: 100%;
			text-align: left;
			a {
				width: 158px;
				height: 30px;
				font-size: 1.3rem;
				line-height: 32px;
				margin-left: 5px;
				@include radius(20px);
				&:before {
					width: 20px;
					height: 20px;
					margin: -3px 10px 0 -3px;
				}
			}
			span {
				font-size: 1.2rem;
				letter-spacing: 0.5px;
				line-height: 14px;
			}
		}
		&__items {
			margin-top: 30px;
		}
		&__item {
			margin-bottom: 15px;
			padding: 36px 20px 19px;
			&:before {
				width: calc(100% - 40px);
				left: 0;
				margin: 0 20px;
			}
			&__img {
				width: auto;
				margin: 0 auto 10px auto;
			}
			&__nameBox {
				width: 100%;
				margin-bottom: 10px;
				h2 {
					font-size: 1.8rem;
					span {
						font-size: 1.4rem;
					}
				}
			}
			&__tag {
				height: 15px;
				font-size: 0.9rem;
				margin: 0 5px 2px 0;
			}
			&__txt {
				font-size: 1.2rem;
				margin-top: 10px;
			}
			&__btn02 {
				flex-wrap: wrap;
				.c-hokenItemSerect__item__request {
					width: 100%;
					margin-right: 0;
					margin-top: 10px !important;
				}
			}
			&__request {
				a {
					width: 150px;
					height: 30px;
					font-size: 1.3rem;
					line-height: 30px;
					margin: 10px auto 0;
					border: solid 1px #ff5b4c;
					&:before {
						width: 20px;
						height: 20px;
						margin: -4px 20px 0 -20px;
					}
				}
				&--internet a {
					border: solid 1px $hokenBtnColor02;
					&:before {
						margin: -4px 14px 0 -20px;
					}
				}
			}
			.c-hokenItemCheck {
				top: 11px;
				right: 20px;
			}
		}
	}
}

/* 保険　ページ下部申し込みブロック
------------------------------------------------------------*/
.c-hokenMoushikomi {
	padding: 35px 45px 39px;
	background-image: url("../img/hoken/bg07.jpg");
	position: relative;
	@include radius(4px);
	&:before {
		width: calc(100% - 8px);
		height: calc(100% - 8px);
		content: "";
		background-color: #ffffff;
		position: absolute;
		top: 4px;
		left: 4px;
		@include radius(4px);
	}
	h2 {
		margin-bottom: 32px;
		font-size: 2.4rem;
		letter-spacing: 1px;
		font-weight: bold;
		text-align: center;
		color: $gray01;
		position: relative;
		z-index: 10;
		&:after {
			width: 80px;
			height: 6px;
			margin: 10px auto 0 auto;
			content: "";
			background-image: url("../img/hoken/line01.png");
			-webkit-background-size: cover;
			background-size: cover;
			display: block;
		}
	}
	&__btn {
		margin-bottom: 24px;
		position: relative;
		z-index: 10;
		.c-hokenBtn03,
		.c-hokenBtn04 {
			display: table;
			vertical-align: middle;
			a {
				font-weight: 600;
				display: inline-flex;
			}
		}
		.c-hokenBtn03,
		.c-hokenBtn04 {
			&.fixed-center {
				a {
					padding-top: 10px;
				}
			}
		}
		.c-hokenBtn03 a {
			padding: 0;
			&:before {
				margin: 0 5px 0 -25px;
				position: unset;
			}
			@include sp {
				padding-left: 22px !important;
				&:before {
					margin: 0 10px 0 -2px;
				}
			}
		}
	}
	p {
		font-size: 1.6rem;
		font-weight: 500;
		line-height: 1.75;
		color: $gray01;
		position: relative;
		z-index: 10;
		a {
			margin: 6px 0 9px;
			color: $hokenColor02;
			display: inline-block;
			&:hover {
				opacity: 1;
				color: #ff957d;
				text-decoration: underline;
			}
			&:before {
				width: 5px;
				height: 11px;
				margin: -3px 10px 0 0;
				content: "";
				background-image: url("../img/common/icon_arrow-blue02.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		.c-hokenMoushikomi__annotation {
			display: block;
			font-size: 1.4rem;
			line-height: 1.5;
		}
	}
}
@include sp {
	.c-hokenMoushikomi {
		padding: 17px 20px 16px;
		h2 {
			margin-bottom: 20px;
			font-size: 1.9rem;
			line-height: 25px;
		}
		&__btn {
			margin-bottom: 10px;
			div {
				margin: 0 auto 10px auto;
			}
		}
		p {
			margin-top: 16px;
			font-size: 1.3rem;
			line-height: 19px;
			a {
				text-decoration: underline;
			}
			.c-hokenMoushikomi__annotation {
				font-size: 1.1rem;
				font-weight: bold;
				line-height: 1.6;
			}
		}
	}
}

/* リフォーム　関連リンク
------------------------------------------------------------*/
.c-reformKanrenLink {
	li {
		width: 273px;
		padding: 10px;
		margin: 0 10px 15px 0;
		background-color: $glaylight14;
		border: solid 1px $glaylight01;
		&:nth-child(3n) {
			margin: 0 0 15px 0;
		}
		img {
			width: 100%;
			height: 126px;
			object-fit: cover;
			font-family: "object-fit: cover;"; /*IE対策*/
		}
		a {
			margin: 26px 0 15px 10px;
			display: block;
			font-size: 1.4rem;
			font-weight: bold;
			color: $reformTextColor02;
			&:before {
				width: 6px;
				height: 10px;
				margin: 0 10px 0 0;
				content: "";
				background-image: url("../img/common/icon_arrow_red.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
@include sp {
	.c-reformKanrenLink {
		li {
			width: 100%;
			padding: 10px;
			margin: 0 4% 15px 0 !important;
			&:nth-child(2n) {
				margin: 0 0 15px 0 !important;
			}
			a {
				margin: 10px 0 0 0;
				font-size: 1.2rem;
			}
			// img {
			//     width: 100%;
			//     height: auto;
			// }
		}
	}
}

/* 葬祭　お葬式受付専用ダイヤル
------------------------------------------------------------*/
.c-sosaiUketsuke {
	width: 100%;
	padding: 45px 60px 53px;
	color: $gray08;
	background-image: url("../img/sosai/uketuke_bg_pc.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	border: solid 2px $glaylight11;
	@include mincyo;
	@include sp {
		padding: 20px;
		background-image: url("../img/sosai/uketuke_bg_sp.jpg");
	}
	&__title {
		margin-bottom: 40px;
		font-size: 4rem;
		font-weight: bold;
		text-align: center;
		@include sp {
			margin-bottom: 20px;
			font-size: 2.4rem;
		}
		span {
			width: 462px;
			height: 42px;
			margin: 2px auto 0 auto;
			font-size: 2.4rem;
			letter-spacing: 1.5px;
			color: #ffffff;
			background-color: $sosaiNaviColor03;
			display: block;
			@include radius(21px);
			@include sp {
				width: 100%;
				font-size: 1.5rem;
				height: 26px;
			}
		}
	}
	&__boxArea {
		width: 353px;
		margin-right: 20px;
		@include sp {
			width: 100%;
			margin: 0;
		}
	}
	&__box {
		width: 100%;
		padding: 34px 26px 24px;
		background-color: #fff;
		border-top: solid 2px;
		@include sp {
			margin-bottom: 15px;
			padding: 15px;
		}
		&--kanagawa {
			border-color: $sosaiKanagawaColor01;
		}
		&--yamanashi {
			border-color: $sosaiYamanashiColor01;
		}
		&--shizuoka {
			border-color: $sosaiSizuokaColor01;
		}
		+ .c-sosaiUketsuke__box {
			margin-top: 18px;
			@include sp {
				margin-top: 0;
			}
		}
		&__subtitle {
			margin-bottom: 22px;
			padding-right: 90px;
			position: relative;
			h3 {
				font-size: 2.8rem;
				line-height: 1;
				img {
					display: block;
					margin: 8px 0 0 3px;
				}
			}
			> img {
				position: absolute;
				top: -6px;
				right: 7px;
			}
			@include sp {
				margin-bottom: 15px;
				padding-right: 40%;
				h3 {
					font-size: 1.7rem;
				}
				> img {
					width: 80px;
				}
			}
		}
		&__tel {
			font-size: 3.6rem;
			font-weight: bold;
			line-height: 1.25;
			margin: 2px 0 0 0;
			&:before {
				width: 42px;
				height: 24px;
				margin: -10px 10px 0 0;
				content: "";
				background-image: url("../img/common/icon_free_dial.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray08;
				letter-spacing: 2.3px;
			}
			@include sp {
				font-size: 2.2rem;
				&:before {
					width: 26px;
					height: 15px;
					margin: -5px 5px 0 0;
				}
			}
		}
		ul {
			li {
				margin-bottom: 8px;
				padding-bottom: 2px;
				border-bottom: dotted 1px $glaylight11;
				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
					border: none;
				}
				p {
					font-size: 1.5rem;
					font-weight: bold;
					@include gosick;
					span {
						font-size: 1.3rem;
						font-weight: 500;
					}
					@include sp {
						font-size: 1.3rem;
						span {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
/* 葬祭　お葬式受付専用ダイヤル2
------------------------------------------------------------*/
.c-sosaiUketsuke02 {
	width: 100%;
	height: 454px;
	background-image: url("../img/sosai/flow_tel_bg01.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	border: solid 2px $glaylight13;
	position: relative;
	@include sp {
		height: auto;
		background-position: right 0;
	}
	h2 {
		padding: 10px 40px;
		font-size: 3.4rem;
		font-weight: 500;
		color: #ffffff;
		background-color: $subColor19;
		@include mincyo;
		@include sp {
			padding: 10px 20px;
			font-size: 2.6rem;
			text-align: center;
			span {
				margin: 0 auto;
				padding: 1px 10px;
				font-size: 1.4rem;
				display: block;
			}
		}
		span {
			margin: -5px 0 0 15px;
			padding: 1px 20px;
			font-size: 1.8rem;
			font-weight: 700;
			color: $sosaiNaviColor03;
			background-color: #ffffff;
			display: inline-block;
			vertical-align: middle;
			@include radius(1000px);
			@include gosick;
		}
	}
	> img {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&__boxArea {
		width: 810px;
		padding: 50px;
		@include sp {
			width: 100%;
			padding: 15px;
		}
		> div {
			width: 50%;
			@include sp {
				width: 100%;
				margin-bottom: 15px;
				padding: 0 !important;
				border: none !important;
			}
			&:nth-child(1) {
				padding-right: 25px;
				@include sp {
					.c-sosaiUketsuke02__box {
						border-bottom: dotted 1px $gray07;
					}
				}
			}
			&:nth-child(2) {
				padding-left: 29px;
				border-left: dotted 1px $gray07;
			}
		}
	}
	&__box {
		@include sp {
			margin-bottom: 10px;
			padding-bottom: 10px;
			text-align: center;
		}
		+ .c-sosaiUketsuke02__box {
			margin-top: 20px;
			padding-top: 20px;
			border-top: dotted 1px $gray07;
			@include sp {
				margin-top: 0;
				padding-top: 0;
				border-top: none;
			}
		}
		h3 {
			margin-bottom: 5px;
			font-size: 2.6rem;
			font-weight: 500;
			color: $gray08;
			@include mincyo;
			&:before {
				width: 30px;
				height: 30px;
				margin: -5px 10px 0 0;
				content: "";
				background-image: url("../img/sosai/icon_sosai01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			span {
				font-size: 1.8rem;
			}
			@include sp {
				font-size: 2rem;
				&:before {
					width: 20px;
					height: 20px;
				}
				span {
					font-size: 1.6rem;
				}
			}
		}
		ul {
			li {
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
				p {
					font-size: 1.5rem;
					font-weight: 700;
					color: $gray04;
				}
			}
		}
		&__tel {
			font-size: 4rem;
			line-height: 1.2;
			@include mincyo;
			&:before {
				width: 28px;
				height: 28px;
				margin: -5px 5px 0 0;
				content: "";
				background-image: url("../img/common/icon_tel06.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray08;
			}
			@include sp {
				font-size: 2.8rem;
				&:before {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}

/* 葬祭　葬儀後のサービス 問い合わせ
------------------------------------------------------------*/
.c-sosaiAfterServiceContact {
	margin: 0 auto;
	text-align: center;
	display: table;
	border-collapse: collapse;
	> div {
		width: 560px;
		padding: 30px 30px 25px;
		display: table-cell;
		vertical-align: middle;
		border: solid 1px $glaylight13;
		&:not(:first-of-type) {
			a {
				margin-top: 12px;
				span {
					margin-left: -74px;
				}
			}
		}
	}
	&__cate {
		width: 100px;
		height: 20px;
		margin: 0 auto 20px auto;
		color: #ffffff;
		font-size: 1.2rem;
		font-weight: 500;
		line-height: 2rem;
		display: block;
		&--kanagawa {
			background-color: $sosaiKanagawaColor01;
		}
		&--yamanashi {
			background-color: $sosaiYamanashiColor01;
		}
		&--shizuoka {
			width: 90px;
			background-color: $sosaiSizuokaColor01;
		}
	}
	p {
		font-size: 2rem;
		line-height: 1;
		@include mincyo;
		span {
			display: block;
			font-size: 1.6rem;
			line-height: 1;
			padding-top: 10px;
			&:first-of-type {
				margin-bottom: 10px;
				padding-top: 0;
			}
		}
	}
	a {
		display: block;
		font-size: 3.6rem;
		line-height: 1;
		color: #111111;
		@include mincyo;
		margin-top: 14px;
		letter-spacing: 1px;
		text-align: left;
		> div {
			width: 360px;
			padding-left: 86px;
			margin: 0 auto;
			position: relative;
			&:before {
				content: "";
				width: 28px;
				height: 28px;
				background: url("../img/common/icon_tel06.png") no-repeat;
				background-size: cover;
				display: block;
				position: absolute;
				top: 3px;
				left: 51px;
			}
		}
		span {
			display: block;
			font-size: 1.6rem;
			line-height: 2.2rem;
			color: #2f2933;
			margin-left: -38px;
			margin-top: 6px;
			letter-spacing: 0.3px;
		}
	}
}
@include sp {
	.c-sosaiAfterServiceContact {
		display: block;
		> div {
			display: block;
			width: 100%;
			padding: 19px;
			&:not(:first-of-type) {
				> span {
					width: 57px;
				}
				a {
					margin-top: 15px;
				}
			}
		}
		&__cate {
			width: 68px;
			height: 16px;
			font-size: 1rem;
			line-height: 1.6rem;
			padding-top: 1px;
			margin-bottom: 14px;
		}
		p {
			font-size: 1.8rem;
			span {
				font-size: 1.1rem;
				padding-top: 9px;
			}
		}
		a {
			margin-top: 14px;
			background-color: #2e95a3;
			border-radius: 3px;
			font-size: 1.8rem;
			color: #fff;
			@include yu_gosick;
			font-weight: bold;
			letter-spacing: 0;
			box-shadow: 0px 3px 0px #20656e;
			> div {
				width: 240px;
				padding: 8px 0 8px 81px;
				&:before {
					left: 41px;
					top: calc(50% - 12px);
					background: url("../img/common/icon_tel07.png");
					background-size: cover;
					width: 25px;
					height: 25px;
				}
			}
			span {
				display: none;
			}
			p {
				font-size: 1rem;
				line-height: 1.3rem;
				color: #fff;
				margin: 0 0 0 -8px;
				text-indent: 7px;
				@include yu_gosick;
			}
		}
	}
}

/* 葬祭　葬儀後のサービス リンク
------------------------------------------------------------*/
.c-sosaiAfterServiceLink {
	padding-top: 5px;
	li {
		width: 216px;
		margin-right: 10px;
		padding: 21px 10px 10px 10px;
		background-color: #ffffff;
		text-align: center;
		border-top: solid 2px $sosaiNaviColor02;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
		&:after {
			width: 15px;
			height: 15px;
			content: "";
			background-image: url("../img/common/icon_button_sankaku02_blue.png");
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			bottom: 5px;
			right: 5px;
		}
		a {
			font-size: 2.2rem;
			color: $sosaiTextColor01;
			@include mincyo;
			span {
				color: #2e95a3;
				font-size: 1.2rem;
				display: block;
				line-height: 1;
				@include Garamond();
				@include sp {
					font-size: 0.7rem;
				}
			}
		}
		div {
			padding: 11px 0 29px 0;
		}
	}
	&__label {
		margin: 0 1px;
		// padding: 0 12px;
		font-size: 1.2rem;
		font-weight: 700;
		color: #ffffff;
		display: inline-block;
		&--shizuoka {
			padding: 0 19px;
			background-color: $sosaiSizuokaColor01;
		}
		&--yamanashi {
			background-color: $sosaiYamanashiColor01;
		}
		&--kanagawa {
			padding: 0 12px;
			background-color: $sosaiKanagawaColor01;
		}
		@include sp {
			font-size: 1rem;
		}
	}
}
@include sp {
	.c-sosaiAfterServiceLink {
		margin-bottom: -15px;
		li {
			width: calc(50% - 5px);
			margin-right: 10px;
			margin-bottom: 11px;
			padding: 16px 5px 5px;
			border-top: solid 1px #2e95a3;
			&:nth-child(even) {
				margin-right: 0;
			}
			&:after {
				right: 2px;
				bottom: 2px;
				width: 10px;
				height: 10px;
			}
			a {
				font-size: 1.9rem;
				line-height: 1;
				img {
					width: 21px;
					margin: -2px auto 0;
				}
			}
			div {
				padding: 3px 0 6px 0;
			}
		}
		&__label {
			height: 16px;
			font-size: 1rem;
			line-height: 1.6rem;
			width: 36px;
			padding: 0;
		}
	}
}

/* 葬祭　関連リンク
------------------------------------------------------------*/
.c-sosaiKanrenLink01 {
	li {
		width: 353px;
		margin-right: 35px;
		padding-bottom: 15px;
		text-align: center;
		background-color: #ffffff;
		position: relative;
		&:nth-child(3),
		&:last-child {
			margin-right: 0;
		}
		@include sp {
			float: left;
			width: calc(50% - 5px);
			margin: 0 10px 10px 0;
			padding-bottom: 10px;
			display: inline-block;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		&:after {
			width: 10px;
			height: 10px;
			content: "";
			background-image: url(("../img/common/icon_button_sankaku02_blue.png"));
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			bottom: 5px;
			right: 3px;
			@include sp {
				bottom: 3px;
			}
		}
		img {
			width: 100%;
			margin-bottom: 15px;
			@include sp {
				margin-bottom: 9px;
			}
		}
		a {
			color: $gray08;
			font-size: 1.6rem;
			font-weight: 700;
			@include sp {
				font-size: 1.2rem;
				line-height: 1.3;
			}
		}
	}
}

/* 葬祭 CVエリア もしもの時
------------------------------------------------------------*/
.c-sosaiCV01 {
	background: url("../img/sosai/service_detail_bg01.jpg") no-repeat center;
	background-size: cover;
	padding: 120px 0 140px;
	text-align: center;

	&__box {
		background: #343b58;
		color: #fff;
		padding: 26px 0 40px;
	}

	&__ttl {
		font-size: 3.6rem;
		font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝",
			"ＭＳ 明朝", serif;
		margin-bottom: 7px;
		font-weight: normal;
	}

	&__txt {
		font-size: 1.8rem;
		font-family: YuGothic, "Yu Gothic", sans-serif;
		font-weight: normal;
	}

	&__btn {
		background: #fff;
		padding: 34px 0 35px;

		.c-sosaiBtn03 {
			margin: 0 auto;
		}
	}

	&__btn--col2 {
		background: #fff;
		padding: 0 36px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;

		.c-sosaiCV01__btnBox {
			padding: 34px 0 35px 34px;
			width: 50%;

			&:first-child {
				border-right: 1px solid #ccc;
				padding-left: 0;
			}
		}

		.c-sosaiBtn05 {
			padding: 25px 18px;
		}
	}

	&__btn--tel {
		padding: 0;

		.c-sosaiCV01__btnBox {
			padding: 30px 0 35px 30px;
		}

		p {
			font-size: 2rem;
			font-weight: bold;
			font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝",
				"ＭＳ 明朝", serif;
		}
	}
}
@media screen and (max-width: 640px) {
	.c-sosaiCV01 {
		padding: 45px 15px 70px;

		&__box {
			padding: 30px 15px 27px;
		}

		&__ttl {
			font-size: 2.3rem;
			margin-bottom: 16px;
		}

		&__txt {
			font-size: 1.3rem;
		}

		&__btn {
			padding: 15px 15px 19px;

			.c-sosaiBtn03,
			.c-sosaiBtn05 {
				font-size: 1.4rem;
			}
		}

		&__btn--col2 {
			padding: 15px 0 19px;
			display: block;

			.c-sosaiCV01__btnBox {
				padding: 15px 15px 0;
				width: 100%;

				&:first-child {
					border-right: none;
					border-bottom: 1px solid #ccc;
					padding: 0 15px 19px;
				}
			}

			.c-sosaiBtn03 {
				font-size: 1.4rem;
			}

			.c-sosaiBtn05 {
				font-size: 1.4rem;
				padding: 15px;
			}
		}

		&__btn--tel p {
			font-size: 1.8rem;
			margin-bottom: 7px;
		}
	}
}

/* フォーム　個人情報の取扱
------------------------------------------------------------*/
.c-formPrivacyTitle {
	margin-bottom: 40px;
	padding-bottom: 10px;
	font-size: 2.4rem;
	font-weight: bold;
	color: #46483c;
	border-bottom: solid 2px #FC819B;

	@include sp {
		margin-bottom: 30px;
    padding-bottom: 5px;
    font-size: 2rem;
	}

	&.is-coopGreen {
		border-bottom-color: #8CB340;
	}
}
.c-formPrivacyText {
	&__txt {
		margin-bottom: 35px;
		padding: 26px 31px 33px;
		border: solid 1px $glaylight10;
		@include sp {
			margin-bottom: 18px;
			padding: 17px 8px 20px 19px;
		}
		dl {
			width: 100%;
			height: 240px;
			padding-right: 20px;
			overflow: auto;
			color: $gray01;
			@include sp {
				height: 261px;
				padding-right: 20px;
			}
			dt {
				margin-bottom: 31px;
				font-size: 2rem;
				font-weight: bold;
				@include sp {
					margin-bottom: 22px;
					font-size: 1.5rem;
					line-height: 1.5;
				}
			}
			dd {
				margin-bottom: 1em;
				font-size: 1.4rem;
				line-height: 1.875;
				@include sp {
					font-size: 1.2rem;
					line-height: 1.7;
				}
				ul {
					li {
						text-indent: -1em;
						padding-left: 1em;
						a {
							color: #308bbb;
							text-decoration: underline;
							position: relative;
							padding-left: 18px;
							&:before {
								content: "";
								width: 5px;
								height: 5px;
								background: #669900;
								border-radius: 100px;
								position: absolute;
								top: 6px;
								left: 0;
							}
						}
					}
				}
			}
		}
	}
	// &__list {
	// 	.c-formPrivacyText__list--text {
	// 		text-indent: -3.5rem;
	// 		padding-left: 3.5rem;
	// 	}
	// }
	&__box {
		background: #f9f9f9;
		padding: 15px;
		a {
			color: #308bbb;
		}
	}
	&__sign {
		text-align: right;
	}
	.c-checkbox {
		display: flex;
		justify-content: center;
	}
	input[type="checkbox"] + label {
		font-size: 1.8rem;
		@include sp {
			font-size: 1.3rem;
			line-height: 1.5;
		}
	}
}
.c-formPrivacyText--02 {
    .c-formPrivacyText__txt {
        margin-bottom: 15px;
        padding: 15px 20px;
        border: 1px solid #888;
        border-radius: 5px;
        line-height: 1.5;
        color: #222;
        @include pc {
            margin-bottom: 30px;
            padding: 30px 40px;
            border-radius: 10px;
        }

        dl {
            height: 225px;
            color: inherit;
            @include pc {
                height: 300px;
            }
        }

        dl dd {
            font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }
        }
    }
}

/* フォーム　入力ページ　電話問い合わせ
------------------------------------------------------------*/
.c-formTelInquiry {
	color: $gray01;
	&__pc {
		font-size: 1.6rem;
		&__tel {
			font-size: 1.8rem;
			line-height: 1;
			font-weight: bold;
			span {
				font-size: 2.8rem;
			}
		}
	}
}
@include sp {
	.c-formTelInquiry {
		&__sp {
			text-align: center;
			a {
				width: 100%;
				padding: 10px 0;
				font-size: 1.3rem;
				color: #ffffff;
				background-color: $subColor12;
				display: block;
				border: solid 3px $subColor13;
				span {
					font-size: 1.7rem;
					font-weight: bold;
					display: block;
					&:before {
						width: 21px;
						height: 16px;
						margin: -3px 5px 0 0;
						content: "";
						background-image: url("../img/common/icon_tel01.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

/* フォーム　完了ページ
------------------------------------------------------------*/
.c-formComplete {
	color: $gray01;
	font-size: 1.6rem;
	line-height: 1.75;
	&__title {
		margin-bottom: 16px;
		font-size: 2.4rem;
		line-height: 1.5;
		font-weight: bold;
		color: #669900;
	}
	p {
		margin-bottom: 7px;
	}
	&__annotation {
		font-size: 1.4rem;
		color: $strongColor02;
	}
	&__inquiry {
		width: 480px;
		margin: 54px auto 78px auto;
		padding: 19px 0 20px;
		text-align: center;
		border: solid 2px $keycolor01;
		h3 {
			margin-bottom: 10px;
			font-weight: bold;
		}
		&__text {
			color: #669900;
			font-size: 2rem;
			text-align: center;
		}
		&__tel {
			color: $subColor12;
			font-size: 1.4rem;
			span {
				margin-left: 5px;
				color: $subColor12;
				font-size: 3.2rem;
				font-weight: bold;
				line-height: 1;
				&:before {
					width: 30px;
					height: 26px;
					margin: -5px 5px 0 0;
					content: "";
					background-image: url("../img/common/icon_tel03.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
			}
			p {
				margin: 0;
			}
		}
	}
	&__box {
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		@include sp {
			display: block;
		}
		.c-formComplete__inquiry {
			border: solid 1px $keycolor01;
			padding: 20px 5px;
			margin: 54px 0 78px 0;
			width: 32.5%;
			@include sp {
				width: 100%;
				padding: 20px;
				margin: 0 0 15px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			> p {
				font-size: 1.4rem;
			}
			&__tel {
				font-size: 1.4rem;
				span {
					font-size: 2.8rem;
					&:before {
						width: 25px;
						height: 21px;
						margin: -5px 5px 0 0;
						content: "";
						background-image: url("../img/common/icon_tel03.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
			&:first-of-type {
				h3 {
					margin-bottom: 20px;
					@include sp {
						margin-bottom: auto;
					}
				}
				> p {
					margin-bottom: 20px;
					@include sp {
						margin-bottom: 10px;
					}
				}
			}
		}
		.c-formComplete__num {
			line-height: 1.3;
			margin-bottom: 10px;
		}
		.c-formComplete__time {
			font-size: 1.4rem;
			text-align: center;
		}
	}
}
@include sp {
	.c-formComplete {
		font-size: 1.4rem;
		line-height: 1.4;
		&__title {
			margin-bottom: 13px;
			font-size: 1.6rem;
			line-height: 1.4;
		}
		p {
			margin-bottom: 8px;
		}
		&__annotation {
			font-size: 1.1rem;
			font-weight: bold;
			line-height: 1.6;
		}
		&__inquiry {
			width: 100%;
			margin: 26px 0 20px;
			padding: 17px 20px 19px;
			border: solid 1px $keycolor01;
			h3 {
				font-size: 1.5rem;
				margin-bottom: 6px;
			}
			&__text {
				text-align: left;
				font-size: 1.5rem;
			}
			&__tel_sp {
				a {
					width: 100%;
					padding: 10px 0 8px;
					font-size: 1.1rem;
					font-weight: normal;
					color: #ffffff;
					background-color: $subColor12;
					display: block;
					border: solid 3px $subColor13;
					span {
						font-size: 1.4rem;
						font-weight: bold;
						display: block;
						margin-bottom: 2px;
						&:before {
							width: 21px;
							height: 16px;
							margin: -3px 5px 0 0;
							content: "";
							background-image: url("../img/common/icon_tel01.png");
							-webkit-background-size: cover;
							background-size: cover;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
}

.c-formComplete02 {
    &__inquiry {
        padding: 15px;
        border: 1.5px solid #41B4E6;
        border-radius: 5px;
        row-gap: 15px;
        font-size: 1.4rem;
        @include pc {
            margin-left: auto;
            margin-right: auto;
            max-width: 590px;
            padding: 30px;
            border-width: 3px;
            border-radius: 10px;
            row-gap: 10px;
            font-size: 1.6rem;
        }
        &__title {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #41B4E6;
            font-size: 1.6rem;
            font-weight: 700;
            text-align: center;
            @include pc {
                margin-bottom: 20px;
                padding-bottom: 20px;
                font-size: 2rem;
            }
        }
        &__tel {
            display: flex;
            justify-content: center;
        }
        &__telBtn {
            @include sp {
                padding-left: 15px;
                padding-right: 15px;
                width: fit-content;
                min-height: 47.5px;
                display: flex;
                column-gap: 7.5px;
                align-items: center;
                background-color: #41B4E6;
                border-radius: 9999px;
                color: #fff;
                font-size: 2.5rem;
                font-weight: 700;

                svg {
                    min-width: 25px;
                    width: 25px;
                    height: 25px;
                }
            }
            @include pc {
                width: fit-content;
                display: flex;
                column-gap: 15px;
                align-items: center;
                color: #41B4E6;
                font-size: 3.2rem;
                font-weight: 700;

                svg {
                    min-width: 35px;
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
}

/* コンポーネント
------------------------------------------------------------*/
.c-step {
	background: #efefef;
	padding: 29px 30px 25px;
	@include sp {
		padding: 20px 0;
	}
	&__inner {
		display: flex;
		justify-content: space-between;
		@include sp {
			display: block;
		}
	}
	&__item {
		width: 193px;
		@include sp {
			width: 100%;
			box-sizing: border-box;
			border: 2px solid #9ac985;
			position: relative;
			padding: 17px 17px 22px 17px;
			background: white;
			margin-bottom: 2px;
		}
		&:last-child .c-step__head:after {
			display: none;
		}
	}
	&__head {
		border: 3px solid #9ac985;
		box-sizing: border-box;
		text-align: center;
		background: white;
		padding-top: 8px;
		padding-bottom: 10px;
		margin-bottom: 8px;
		position: relative;
		@include sp {
			margin-bottom: 0;
			border: none;
			border-bottom: 1px solid #efefef;
			display: flex;
			align-items: center;
			text-align: left;
			padding: 0 0 11px 0;
			position: static;
		}
		&:after {
			position: absolute;
			top: 50%;
			right: -22px;
			transform: translateY(-50%);
			content: "";
			width: 22px;
			height: 34px;
			background: url("../img/component/icon_step.png") no-repeat;
			z-index: 1;
			@include sp {
				width: 30px;
				height: 18px;
				background: url("../img/component/icon_step-sp.png") no-repeat;
				top: auto;
				bottom: -18px;
				right: auto;
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
		&-inner {
			.c-step__icon {
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				@include sp {
					display: none;
				}
			}
			@include sp {
				margin-left: 17px;
			}
		}
	}
	&__icon {
		display: none;
		@include sp {
			display: block;
		}
	}
	&__title {
		font-size: 1.2rem;
		font-family: "din-2014", sans-serif;
		font-weight: bold;
		color: #8f8f8f;
		span {
			font-size: 2rem;
			@include sp {
				font-size: 1.4rem;
			}
		}
		@include sp {
			font-size: 1rem;
			color: #666;
		}
	}
	&__subtitle {
		font-size: 1.8rem;
		font-weight: bold;
		color: #8f8f8f;
		margin-top: 7px;
		@include sp {
			font-size: 1.6rem;
			color: #666;
			margin-top: 0;
		}
	}
	&__body {
		background: white;
		padding: 10px 14px 33px;
		@include sp {
			padding: 13px 0 0 0;
		}
		p {
			font-size: 1.5rem;
			font-weight: 500;
			color: #232934;
			line-height: 1.6;
			@include sp {
				font-size: 1.2rem;
				line-height: 16px;
			}
			a {
				color: #308bbb;
				text-decoration: underline;
				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
			b {
				font-weight: bold;
			}
		}
	}
	&__note {
		margin-top: 14px;
		@include sp {
			margin-top: 16px;
		}
		p {
			font-size: 1.3rem;
			font-weight: 500;
			color: #232934;
			line-height: 1.7;
			@include sp {
				font-size: 1rem;
				line-height: 15px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-gallery {
	display: flex;
	flex-wrap: wrap;
	@include sp {
		justify-content: space-between;
		padding: 0 20px;
	}
	&__item {
		background: #f5f5f5;
		border: 1px solid #ddd;
		box-sizing: border-box;
		width: 273px;
		min-height: 211px;
		margin-bottom: 10px;
		@include sp {
			width: calc(50% - 5px);
			min-height: 0;
			display: flex;
			flex-direction: column;
		}
		&:nth-child(3n + 2) {
			margin-left: 10px;
			margin-right: 10px;
			@include sp {
				margin-left: 0;
				margin-right: 0;
			}
		}
		> a {
			display: block;
			padding: 10px;
			@include sp {
				padding: 5px 5px 9px;
			}
		}
	}
	&__image {
		overflow: hidden;
		img {
			transition: 0.3s ease-in-out;
			width: 100%;
			height: 126px;
			object-fit: cover;
			font-family: "object-fit: cover;"; /*IE対策*/
			@include sp {
				height: 63px;
			}
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
	&__title {
		display: flex;
		min-height: 65px;
		align-items: center;
		padding: 9px 9px 0;
		@include sp {
			min-height: 0;
			flex: 1 1 auto;
			justify-content: center;
			padding: 16px 5px 0;
		}
		p {
			font-size: 1.4rem;
			font-weight: bold;
			line-height: 18px;
			color: #141414;
			position: relative;
			padding-left: 16px;
			@include sp {
				font-size: 1.2rem;
				line-height: 14px;
			}
			&:before {
				position: absolute;
				top: 40%;
				left: 0;
				width: 6px;
				height: 10px;
				transform: translateY(-50%);
				background: url("../img/component/113.png") no-repeat;
				content: "";
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-iconList {
	&__inner {
		display: flex;
		justify-content: space-around;
		@include sp {
			flex-wrap: wrap;
			justify-content: flex-start;
		}
	}
	li {
		@include sp {
			width: calc(100% / 3);
			margin-bottom: 15px;
		}
		a {
			display: block;
			&:hover {
				opacity: 1;
				.c-iconList__image {
					transform: translateY(-5px);
				}
				.c-iconList__text {
					p {
						text-decoration: none;
					}
				}
			}
		}
	}
	&__image {
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 28px;
		transition: 0.3s ease-in-out;
		&:hover {
			transform: translateY(-5px);
		}
		@include sp {
			justify-content: center;
			height: 40px;
			margin-bottom: 6px;
		}
	}
	&__text {
		text-align: center;
		a,
		p {
			font-size: 1.4rem;
			font-weight: 500;
			color: #04756f;
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-miniBanner {
	background: #fbf7f1 url("/img/component/reform_KnowledgeBg01.png") no-repeat top right;
	width: 400px;
	height: 100px;
	border: 1px solid #999281;
	transition: 0.3s ease-in-out;
	&:hover {
		transform: translateY(-5px);
	}
	&.c-miniBanner--2 {
		background: #fbf7f1 url("/img/component/reform_KnowledgeBg02.png") no-repeat top right;
	}
	@include sp {
		width: 100%;
		height: 70px;
	}
	> a {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		padding-left: 28px;
		position: relative;
	}
	&__text {
		p {
			font-size: 1.5rem;
			font-weight: bold;
			color: #342603;
			line-height: 20px;
			@include sp {
				font-size: 1.3rem;
				line-height: 16px;
			}
		}
	}
	&__icon {
		position: absolute;
		bottom: 0;
		right: 0;
		background: #247404;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include sp {
			width: 15px;
			height: 15px;
		}
		img {
			@include sp {
				max-width: 50%;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-banner {
	margin-right: 10px;
	width: 270px;
	height: 299px;
	position: relative;
	padding: 0 10px 10px 0;

	@include sp {
		width: 100%;
		height: auto;
		margin-right: 0;
	}

	&:after {
		position: absolute;
		bottom: 0;
		right: 0;
		width: calc(100% - 10px);
		height: calc(100% - 10px);
		background: repeating-linear-gradient(-45deg, #fffbf8, #fffbf8 2px, #fee7d8 3px, #fee7d8 5px);
		content: "";
	}

	& > a {
		display: block;
		position: relative;
		z-index: 1;
		height: 100%;
		width: 100%;

		@include sp {
			height: 100%;
		}
	}

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}

/*
------------------------------------------------------------*/
.c-showCase {
	width: 400px;
	@include sp {
		width: 100%;
	}
	&__head {
		padding: 9px 20px 10px 20px;
		background: #3a4452;
		@include sp {
			padding: 7px 15px 6px 15px;
		}
		p {
			font-size: 1.4rem;
			font-weight: bold;
			color: #fff;
		}
	}
	&__body {
		padding: 20px;
		background: #f5f5f5 url("/img/component/reform_case-icon01.png") no-repeat bottom 20px left 20px;
		display: flex;
		justify-content: space-between;
		@include sp {
			padding: 15px;
			background: #f5f5f5 url("/img/component/reform_case-icon01SP.png") no-repeat bottom 17px left 18px;
			background-size: 25px 31px;
		}
	}
	&__img {
		&.before {
			@include sp {
				margin-right: 12px;
			}
			p {
				font-size: 1rem;
				font-weight: bold;
				margin-top: 7px;
				color: #333333;
				@include sp {
					margin-top: 2px;
				}
			}
		}
		&.after {
			width: 230px;
			position: relative;
			box-sizing: border-box;
			border: 3px solid #fa5b0f;
			p {
				font-size: 1.6rem;
				position: absolute;
				left: -60px;
				bottom: -7px;
				color: #fa5b0f;
				font-weight: bold;
				@include sp {
					font-size: 1.3rem;
					left: -50px;
					bottom: -4px;
				}
			}
		}
		img {
			max-width: 100%;
		}
	}
	&__arrow {
		position: relative;
		&:after {
			position: absolute;
			bottom: -27px;
			right: 0;
			width: 15px;
			height: 17px;
			background: url("/img/component/reform_case-arrow.png") no-repeat;
			content: "";
			@include sp {
				bottom: -16px;
				width: 11px;
				height: 13px;
				background: url("/img/component/reform_case-arrowSP.png") no-repeat;
				background-size: 11px 13px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-sidebarMenu {
	&:after {
		content: "";
		height: 1px;
		display: block;
		background: url("/img/reform/line.png") no-repeat;
	}
	&__title {
		background: #46483c;
		padding: 8px 0 7px;
		@include sp {
			padding: 10px 20px;
		}
		p {
			font-size: 1.3rem;
			font-weight: bold;
			color: #fff;
			text-align: center;
			@include sp {
				text-align: left;
			}
		}
	}
	&__list {
		li {
			&:nth-child(even) {
				background: #f8f8f5;
			}
			&:after {
				content: "";
				height: 1px;
				display: block;
				background: url("/img/reform/line.png") no-repeat;
			}
			&:last-of-type {
				&:after {
					display: none;
				}
			}
		}
		span {
			display: inline-block;
			vertical-align: middle;
			margin-left: -44px;
			margin-right: 17px;
		}
		a {
			padding: 14px 20px 11px 60px;
			display: block;
			color: #04756f;
			font-size: 1.4rem;
			font-weight: 500;
			line-height: 1;
			text-decoration: underline;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
				width: 6px;
				height: 10px;
				background-image: url("/img/component/arrow-green02.png");
			}
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
		&__break {
			span {
				transform: translateY(1.2rem);
			}
		}
		.u-font13 {
			font-size: 1.3rem;
		}
	}
}

/*
------------------------------------------------------------*/
.c-sidebarListMenu {
	background: #fff2f5;
	border-top: 2px solid #fc819b;
	padding: 5px;
	&--2 {
		background: #ebf4e7;
		border-top: 2px solid #9ac985;
		.c-sidebarListMenu__menu a {
			line-height: 1.4;
			padding: 13px 0 11px 33px;
			height: 66px;
		}
	}
	&__title {
		background: #fff;
		padding: 12px 19px 9px 19px;
		margin-bottom: 1px;
		p {
			font-size: 1.5rem;
			font-weight: 600;
			color: #232934;
		}
		&:not(:first-of-type) {
			margin-top: 4px;
		}
	}
	&__list {
		&-box {
			background: #fff;
			margin-bottom: 1px;
			&.is-open {
				.c-sidebarListMenu__list-arrow:before {
					transform: translate(-50%, -50%) rotate(-90deg);
				}
			}
		}
		&-title {
			a {
				font-size: 1.5rem;
				font-weight: 500;
				color: #232934;
				padding: 8px 20px;
				display: block;
				text-decoration: none;
				&:hover {
					opacity: 0.7;
				}
			}
		}
		&-arrow {
			display: inline-block;
			width: 14px;
			height: 14px;
			background: #fc819b;
			border-radius: 2px;
			position: relative;
			vertical-align: -2px;
			margin-right: 10px;
			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 2px;
				height: 8px;
				background: #fff;
				transition: transform 0.25s ease-out;
				content: "";
			}
			&:after {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 8px;
				height: 2px;
				background: #fff;
				transition: transform 0.25s ease-out;
				content: "";
			}
		}
		&-item {
			background: #fbfbfb;
			padding: 12px 10px 3px 43px;
			position: relative;
			&:after {
				position: absolute;
				width: 100%;
				height: 1px;
				top: 0;
				left: 0;
				background: repeating-linear-gradient(to right, #fff4f6, #fff4f6 3px, #ffe1e8 4px, #ffe1e8 7px);
				content: "";
			}
			li {
				margin-bottom: 5px;
			}
			a {
				text-decoration: none;
				color: #232934;
				font-size: 1.3rem;
				font-weight: 500;
				position: relative;
				display: block;
				padding-left: 15px;
				&:before {
					position: absolute;
					top: 8px;
					left: 0;
					width: 5px;
					height: 5px;
					border-right: 1px solid #fca1b4;
					border-bottom: 1px solid #fca1b4;
					transform: rotate(-45deg);
					content: "";
				}
				&:hover {
					color: #783146;
				}
			}
		}
	}
	&__menu {
		background: #fff;
		margin-bottom: 1px;
		a {
			text-decoration: none;
			font-size: 1.5rem;
			font-weight: 500;
			color: #232934;
			display: flex;
			align-items: center;
			padding: 11px 0 6px 33px;
			position: relative;
			span {
				&:before {
					position: absolute;
					top: 50%;
					left: 16px;
					width: 6px;
					height: 6px;
					border-right: 1px solid #fc92a9;
					border-bottom: 1px solid #fc92a9;
					transform: translateY(-50%) rotate(-45deg);
					content: "";
				}
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-card1 {
	display: flex;
	// justify-content: space-between;
	flex-wrap: wrap;
	&__item {
		width: 33%;
		padding: 4px;
		margin-right: 10px;
		margin-bottom: 13px;
		width: 273px;
		height: 80px;
		background: #faad5a;
		border-radius: 8px;
		box-shadow: 0 3px #f26b51;
		position: relative;
		transition: 0.3s ease-in-out !important;
		&:hover {
			box-shadow: none;
			transform: translate(0, 3px);
		}
		&:nth-of-type(3n) {
			margin-right: 0;
		}
		@include sp {
			width: 100%;
			height: 60px;
			margin-bottom: 10px;
			margin-right: 0;
		}
		&-inner {
			background: repeating-radial-gradient(center center, #f99c39, #f99c39 1px, transparent 1px, transparent 100%);
			background-size: 6px 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			border: 1px dashed #fefce2;
			text-align: center;
			padding: 0 20px 0 45px;
		}
		&.u-hover01 {
			&:hover {
				opacity: 1;
			}
		}
	}
	&__text {
		p {
			color: #fff;
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 20px;
			@include sp {
				font-size: 1.6rem;
				line-height: 21px;
			}
		}
	}
	&__badge {
		position: absolute;
		top: -2px;
		left: 13px;
		@include sp {
			left: 10px;
			top: -1px;
		}
	}
}

/*
------------------------------------------------------------*/
.c-card2 {
	display: flex;
	flex-wrap: wrap;
	a {
		&:hover {
			opacity: 1;
		}
	}
	&__item {
		width: 195px;
		position: relative;
		border-bottom: 1px dotted #733e01;
		padding-bottom: 15px;
		margin-bottom: 20px;
		margin-right: 20px;
		&:nth-child(4n) {
			margin-right: 0;
		}
		@include sp {
			width: calc(50% - 10px);
			margin-right: 20px;
			padding-bottom: 17px;
			&:nth-child(4n) {
				margin-right: 20px;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		&:after {
			position: absolute;
			bottom: -1px;
			right: 0;
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 10px 10px;
			border-color: transparent transparent #faad5a transparent;
			content: "";
		}
		&:before {
			// position: absolute;
			// top: 0;
			// left: 0;
			// z-index: 10;
			// display: inline-block;
			// width: 100%;
			// height: 100%;
			// background-color: rgba(255,255,255,0.2);
			// content: '';
			// opacity: 0;
			// transition: 0.3s ease-in-out;
		}
		&:hover {
			&:before {
				// opacity: 1;
			}
			.c-card2__body {
				p {
					color: #f0662b;
					text-decoration: underline;
				}
			}
		}
	}
	&__title {
		background: #fddab5;
		padding: 2px;
		border-radius: 15px;
		margin-bottom: 10px;
		@media screen and (-ms-high-contrast: none) {
			padding: 5px 0 0;
		}
		p {
			font-size: 1.4rem;
			font-weight: bold;
			color: #483825;
			text-align: center;
		}
		@include sp {
			padding: 1px;
			p {
				font-size: 1rem;
			}
		}
	}
	&__tag {
		font-size: 1.3rem;
		line-height: 1;
		color: #666;
		margin-top: 10px;
		background: #000;
		background: #eee;
		display: inline-block;
		padding: 4px 10px;
		font-weight: 500;
		@media screen and (-ms-high-contrast: none) {
			padding: 5px 10px 0;
		}
		@include sp {
			font-size: 0.9rem;
			margin-top: 8px;
			padding: 2px 6px;
		}
	}
	&__body {
		p {
			margin-top: 10px;
			font-size: 1.6rem;
			line-height: 20px;
			font-weight: bold;
			color: #733e01;
			transition: 0.3s ease-in-out;
			@include sp {
				font-size: 1.3rem;
				line-height: 17px;
			}
		}
	}
	&__image {
		img {
			width: 195px;
			height: 130px;
			object-fit: cover;
			font-family: "object-fit: cover;"; /*IE対策*/
			@include sp {
				width: 100%;
				height: 86px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-listLink {
	a {
		text-decoration: underline;
		color: #594242;
		display: block;
		&:hover {
			opacity: 1;
			text-decoration: none;
		}
	}
	&__item {
		&:after {
			content: "";
			height: 1px;
			display: block;
			background: url(../img/kurashi/line02.png) repeat-x;
		}
		&:nth-child(odd) {
			background: #fdfbf9;
		}
		a {
			font-size: 1.6rem;
			line-height: 20px;
			padding: 18px 45px 18px 12px;
			position: relative;
			@include sp {
				text-decoration: none;
				font-size: 1.3rem;
				padding: 10px 10px 12px 30px;
				font-weight: bold;
			}
			.plus {
				display: inline-block;
				width: 20px;
				height: 20px;
				background: #faad5a;
				border-radius: 2px;
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 2px;
					height: 14px;
					background: #fff;
					transition: transform 0.25s ease-out;
					content: "";
				}
				&:after {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 14px;
					height: 2px;
					background: #fff;
					transition: transform 0.25s ease-out;
					content: "";
				}
			}
			.arrow {
				@include sp {
					display: inline-block;
					width: 15px;
					height: 15px;
					border-radius: 50%;
					background: #faad5a;
					position: absolute;
					top: 50%;
					right: 14px;
					transform: translateY(-50%);
					&:after {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						display: inline-block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 3px 0 3px 4px;
						border-color: transparent transparent transparent #fff;
						content: "";
					}
				}
			}
		}
		&.is-open {
			a .plus:before {
				transform: translate(-50%, -50%) rotate(-90deg);
			}
		}
	}
	&__list {
		background: #f8f8f8;
		@include sp {
			padding-bottom: 13px;
			border-top: 1px dotted #dddddd;
		}
		li {
			&:after {
				content: "";
				height: 1px;
				display: block;
				background: url(../img/kurashi/line01.png) repeat-x;
			}
			@include sp {
				border-top: none;
			}
		}
		a {
			font-size: 1.4rem;
			line-height: 20px;
			padding: 8px 0 7px 22px;
			@include sp {
				text-decoration: underline;
				position: relative;
				padding: 10px 0 0px 54px;
				font-weight: normal;
			}
			/*&:before {
                position: absolute;
                top: 15px;
                left: 44px;
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 5px 6px;
                border-color: transparent transparent transparent #faad5a;
                content: '';
            }*/
		}
	}
}

/*
------------------------------------------------------------*/
.c-sidebarMenuLink {
	border: 1px solid #ddd;
	border-radius: 2px;
	&__title {
		background: #f6fbfe;
		padding: 12px 20px 8px 18px;
		border-bottom: 2px solid #ddd;
		p {
			display: inline-flex;
			align-items: center;
			font-size: 2rem;
			line-height: 1.7;
			font-weight: 600;
			color: #46483c;
			position: relative;
			@include sp {
				font-size: 1.6rem;
			}
			&:after {
				position: absolute;
				bottom: -10px;
				left: 0;
				width: 100%;
				border-bottom: 2px solid #82cdf3;
				content: "";
			}
			img {
				margin: 0 5px 0 0;
				@media screen and (-ms-high-contrast: none) {
					margin: -4px 5px 0 0;
				}
			}
		}
	}
	&__list {
		&-title {
			border-bottom: 1px solid #82cdf3;
			padding: 15px 20px 5px 18px;
			@include sp {
				padding-top: 11px;
				padding-bottom: 12px;
			}
			p {
				color: #46483c;
				font-size: 1.6rem;
				font-weight: 600;
				position: relative;
				@include sp {
					font-size: 1.4rem;
				}
			}
		}
		&:not(:first-child) {
			.c-sidebarMenuLink__list-title {
				border-top: 1px dashed #ddd;
			}
		}
		li {
			a {
				font-size: 1.5rem;
				color: #46483c;
				display: block;
				border-bottom: 1px dashed #ddd;
				padding: 14px 20px 12px 32px;
				position: relative;
				@include sp {
					font-size: 1.3rem;
					padding-top: 6px;
					padding-bottom: 6px;
					padding-left: 33px;
				}
				&:before {
					position: absolute;
					top: 50%;
					left: 18px;
					display: inline-block;
					transform: translateY(-50%);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 6px 0 6px 5px;
					border-color: transparent transparent transparent #82cdf3;
					content: "";
					@include sp {
						left: auto;
						right: 12px;
					}
				}
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
			&:last-child a {
				border-bottom: 0;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-listCard {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	&__item {
		position: relative;
		background: #000;
		width: 410px;
		height: 154px;
		margin-bottom: 20px;
		@include sp {
			height: auto;
			width: 100%;
			margin-bottom: 10px;
		}
		img {
			@include sp {
				width: 100%;
			}
		}
		&:before {
			position: absolute;
			z-index: 10;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			//background-color: rgba(255,255,255,0.2);
			border: solid 3px $ticketColor01;
			content: "";
			opacity: 0;
			transition: 0.3s ease-in-out;
		}
		&:after {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(#000, 0) 30%, rgba(#000, 0.8) 100%);
			content: "";
		}
		&:hover {
			opacity: 1;
			&:before {
				opacity: 1;
			}
		}
	}
	&__text {
		position: absolute;
		font-size: 1.8rem;
		font-weight: bold;
		bottom: 14px;
		left: 19px;
		color: #fff;
		z-index: 2;
		@include sp {
			font-size: 1.4rem;
			bottom: 7px;
			left: 11px;
		}
	}
}

/*
------------------------------------------------------------*/
.p-search {
	width: 840px;
	background: #f5f6f7;
	padding: 16px 0 15px 18px;
	margin-bottom: 27px;
	@include sp {
		width: 100%;
		padding: 10px;
	}
	&__lable {
		font-size: 1.6rem;
		font-weight: bold;
		color: #445161;
		img {
			margin: 5px 5px 0 0;
		}
	}
	select {
		width: 464px;
		height: 40px;
		border-radius: 5px;
		font-size: 1.4rem;
		padding: 0 5px;
		margin-left: 28px;
		@include sp {
			width: 100%;
			height: auto;
			margin: 0;
			padding: 5px;
			font-size: 1.3rem;
		}
	}
}

/*
------------------------------------------------------------*/
.pagination {
	margin-top: 5px;
	text-align: center;
	display: inline-flex;
	align-items: center;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	a {
		float: left;
		list-style: none;
		margin: 5px;
		color: black;
		text-decoration: none;
		cursor: pointer;
		background: #dddddd;
		width: 12px;
		height: 12px;
		border-radius: 10px;
		color: #dddddd;
		font-size: 0;
		@include sp {
			width: 8px;
			height: 8px;
			margin: 3px;
		}
	}
	.jp-current {
		background: #faad5a;
		color: #faad5a;
	}
	.jp-previous,
	.jp-next {
		color: #fff;
		width: 40px;
		height: 40px;
		display: inline-block;
		@include sp {
			width: 30px;
			height: 30px;
		}
	}
	.jp-previous {
		background: url("../img/component/prev_btn.png");
		margin-right: 15px;
		background-size: cover !important;
		@include sp {
			margin-right: 10px;
		}
	}
	.jp-next {
		background: url("../img/component/next_btn.png");
		margin-left: 15px;
		background-size: cover !important;
		@include sp {
			margin-left: 10px;
		}
	}
	&.display-none {
		display: none;
	}
}

/*
------------------------------------------------------------*/
.c-step2 {
	display: flex;
	overflow: hidden;
	height: 40px;
	li {
		width: 170px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.4rem;
		line-height: 2rem;
		color: #b2b2b2;
		background: #fff;
		border: 1px solid #b2b2b2;
		position: relative;
		margin-right: 13px;
		text-align: center;
		@include sp {
			font-size: 1.1rem;
			width: auto;
			flex: 1;
			line-height: 1.4;
		}
		&.fixed-center {
			padding: 4px 0 0;
		}
		&:before {
			content: "";
			width: 15px;
			height: 40px;
			background: url("../img/component/step_contact.png") no-repeat;
			background-position: left;
			position: absolute;
			top: -1px;
			left: -11px;
		}
		&:after {
			content: "";
			width: 15px;
			height: 40px;
			background: url("../img/component/step_contact.png") no-repeat;
			background-position: right;
			position: absolute;
			top: -1px;
			right: -12px;
			z-index: 2;
		}
		&:first-of-type {
			// padding-left: 14px;
			&:before {
				content: none;
				display: none;
			}
		}
		&:last-of-type {
			margin-right: 0;
			padding-right: 7px;
			&:after {
				content: none;
				display: none;
			}
		}
		&.is-active {
			font-size: 1.8rem;
			font-weight: bold;
			color: #fff;
			background: #fc819b;
			border: 1px solid #fc819b;
			@include sp {
				font-size: 1.1rem;
			}
			&:before,
			&:after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 24px 0 24px 12px;
				position: absolute;
			}
			&:before {
				top: -5px;
				left: -12px;
				background: #fc819b;
				border-color: transparent transparent transparent #fff;
				z-index: 1;
				width: 15px;
			}
			&:after {
				top: -5px;
				right: -12px;
				border-color: transparent transparent transparent #fc819b;
				z-index: 0;
			}
		}
	}
	&--ticket {
		li {
			&.is-active {
				background: $ticketColor01;
				border: 1px solid $ticketColor01;
				&:before {
					background: $ticketColor01;
				}
				&:after {
					border-color: transparent transparent transparent $ticketColor01;
				}
			}
		}
	}
	&--hoken {
		li {
			&.is-active {
				background: $hokenColor01;
				border: 1px solid $hokenColor01;
				&:before {
					background: $hokenColor01;
				}
				&:after {
					border-color: transparent transparent transparent $hokenColor01;
				}
			}
		}
	}
	&--reform {
		margin-top: -10px;
		height: 60px;
		li {
			width: 150px;
			height: 60px;
			&:before,
			&:after {
				height: 60px;
			}
			&:before {
				background: url("../img/component/step_contact02.png") no-repeat;
				background-position: left;
			}
			&:after {
				background: url("../img/component/step_contact02.png") no-repeat;
				background-position: right;
				right: -12px;
			}
			&.is-active {
				background: $reformColor01;
				border: 1px solid $reformColor01;
				&:before,
				&:after {
					border-width: 34px 0 34px 11px;
				}
				&:before {
					background: $reformColor01;
					left: -12px;
				}
				&:after {
					border-color: transparent transparent transparent $reformColor01;
					right: -11px;
				}
			}
		}
	}
	&--kurashi {
		li {
			&.is-active {
				background: $kurashiColor01;
				border: 1px solid $kurashiColor01;
				&:before {
					background: $kurashiColor01;
				}
				&:after {
					border-color: transparent transparent transparent $kurashiColor01;
				}
			}
		}
	}
	&--sosai {
		margin-top: -10px;
		height: 60px;
		li {
			width: 120px;
			height: 60px;
			&:before,
			&:after {
				height: 60px;
			}
			&:before {
				background: url("../img/component/step_contact02.png") no-repeat;
				background-position: left;
			}
			&:after {
				background: url("../img/component/step_contact02.png") no-repeat;
				background-position: right;
				right: -12px;
			}
			&.is-active {
				background: $sosaiColor01;
				border: 1px solid $sosaiColor01;
				&:before,
				&:after {
					border-width: 34px 0 34px 11px;
				}
				&:before {
					background: $sosaiColor01;
					left: -12px;
				}
				&:after {
					border-color: transparent transparent transparent $sosaiColor01;
					right: -11px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-bottom {
	border-top: 1px solid #669900;
	text-align: center;
	@include sp {
		border-top: none;
		background: #f8f8f8;
	}
	&__logo {
		background: rgba(250, 247, 236, 0.5);
		padding: 31px 0 27px;
		@include sp {
			background: none;
			padding: 10px 0 1px;
			img {
				width: 140px;
				height: auto;
			}
		}
	}
	&__copyright {
		padding: 14px 0 12px;
		background: #f8f8f8;
		@include sp {
			background: none;
			padding: 0 0 8px;
		}
		p {
			font-size: 1.3rem;
			color: #999999;
			@include sp {
				font-size: 0.9rem;
			}
		}
	}
}

/*ナビ無しヘッダー
------------------------------------------------------------*/
.c-header {
	width: 1120px;
	margin: 20px auto;
	overflow: hidden;
	&__name {
		float: left;
		display: flex;
		align-items: center;
	}
	&__namePage {
		color: #783146;
		font-size: 2.4rem;
		font-weight: bold;
		margin-left: 17px;
	}
	&__logo {
		float: right;
		margin-top: 10px;
	}
	@include sp {
		width: 100%;
		margin: 0;
		padding: 10px 15px;
		&__name {
			float: right;
		}
		&__namePage {
			font-size: 1.4rem;
			margin-left: 0;
		}
		&__logoPage img {
			width: 85%;
		}
		&__logo {
			float: left;
			margin-top: 10px;
		}
	}
}

.c-header {
	width: 1120px;
	margin: 0 auto;
	padding: 20px 0;
	overflow: hidden;
	&__name {
		float: left;
	}
	&__logo {
		float: right;
		margin-top: 10px;
	}
	.c-headCateTitle {
		height: auto;
		padding: 0;
	}
	@include sp {
		width: 100%;
		padding: 10px 15px 10px 15px;
		&__name {
			float: right;
		}
		&__logo {
			float: left;
			margin-top: 5px;
			width: 130px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.c-headCateTitle {
			font-size: 1.4rem;
			.c-cateIcon01 {
				margin: 0 11px 0 0;
				width: 30px;
				height: 30px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
/* js-sample
------------------------------------------------------------*/
.p-sample {
	margin: 100px auto;
	.c-table1 {
		&__rdparent {
			display: block;
		}
		&__rdchild {
			display: block;
			padding: 10px 20px;
			p {
				float: left;
			}
			ul {
				display: block;
				margin-left: 50px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-block03 {
	background-image: url("../img/hoken/bg07.jpg");
	border-radius: 4px;
	padding: 5px;
	@include sp {
		padding: 3px;
		border-radius: 2px;
	}

	&__inner {
		background: #fff;
		padding: 73px 40px 65px;
		@include sp {
			padding: 24px 20px 26.5px;
		}
	}

	.c-hokenBtn07 {
		margin: 36px 0 0;
		@include sp {
			margin: 18px 0 0;
		}
	}

	.c-hokenBtn08 {
		margin: 30px 0 0;
		@include sp {
			max-width: 195px;
			margin: 10px auto 0;
		}
	}
}

/*
------------------------------------------------------------*/
.c-block04 {
	background-image: url("../img/hoken/bg07.jpg");
	border-radius: 4px;
	padding: 5px;
	@include sp {
		padding: 2px;
		border-radius: 2px;
	}

	&__inner {
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 61px 50px 60px 61px;
		@include sp {
			padding: 30px;
		}
	}

	&__img {
		width: 420px;
		@include sp {
			width: 100%;
		}

		img {
			width: 100%;
		}
	}

	&__info {
		width: calc(100% - 450px);
		margin: -5px 0 0;
		@include sp {
			width: 100%;
			margin: 18px 0 0;
		}
	}

	&__ttl {
	}

	&__sub {
		display: block;
		font-size: 2rem;
		line-height: 1.5;
		color: #253b73;
		font-weight: bold;
		font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
		@include sp {
			font-size: 1.6rem;
			line-height: 1.4;
		}
	}

	&__main {
		display: block;
		font-size: 2.8rem;
		line-height: 1.6;
		color: #253b73;
		font-weight: bold;
		font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
		margin: 8px 0 0;
		@include sp {
			font-size: 2rem;
			line-height: 1.3;
			margin: 7px 0 0;
		}
	}

	&__txt1 {
		display: flex;
		font-size: 1.5rem;
		line-height: 1.9;
		color: #46483c;
		font-weight: 500;
		font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
		margin: 2px 0 0;
		@include sp {
			font-size: 1rem;
			line-height: 1.4;
			margin: 9px 0 0;
		}

		span {
			flex: 1;
		}
	}

	&__txt2 {
		font-size: 1.6rem;
		line-height: 1.75;
		color: #46483c;
		font-weight: 500;
		font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
		margin: 23px 0 0;
		@include sp {
			font-size: 1.2rem;
			line-height: 1.7;
			margin: 27px 0 0;
		}
	}

	&__box {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border: 1px solid #6acff7;
		border-radius: 4px;
		padding: 23px 63px;
		margin: 40px 0 0;
		@include sp {
			padding: 25px 29px 25px 25px;
			margin: 30px 0 0;
		}
	}

	&__boxleft {
		width: 40%;
		padding: 5px 0;
		@include sp {
			width: 100%;
			padding: 0;
		}
	}

	&__boxttl {
		font-size: 1.8rem;
		line-height: 1.7;
		color: #253b73;
		font-weight: bold;
		font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
		@include sp {
			text-align: center;
			font-size: 1.5rem;
			line-height: 1.35;
			font-weight: 500;
		}
	}

	&__boxright {
		width: 56.5%;
		padding: 0 0 0 38px;
		position: relative;
		@include sp {
			width: 100%;
			padding: 19px 0 0;
			margin: 17px 0 0;
		}

		&::before {
			content: "";
			width: 1px;
			height: calc(100% - 14px);
			background: #6acff7;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			@include sp {
				width: 100%;
				height: 1px;
				top: 0;
				transform: none;
			}
		}
	}

	&__txt3 {
		font-size: 1.6rem;
		line-height: 1.75;
		color: #000000;
		font-weight: bold;
		@include sp {
			font-size: 1rem;
			line-height: 1.4;
		}
	}

	&__contact {
		margin: 7px 0 0;
		@include sp {
			background: #253b73;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 8px 10px 5px;
		}

		a {
			font-size: 2.6rem;
			line-height: 1;
			color: #46483c;
			font-weight: bold;
			position: relative;
			padding: 0 0 0 72px;
			@include sp {
				font-size: 1.3rem;
				color: #fff;
				padding: 0 0 0 20px;
			}

			&::before {
				content: "";
				width: 65px;
				height: 26px;
				background: url("/img/common/icon_tel10.png") no-repeat center;
				background-size: cover;
				position: absolute;
				top: 1px;
				left: 2px;
				@include sp {
					width: 15px;
					height: 12px;
					background: url("/img/common/icon_tel01.png") no-repeat center;
					background-size: cover;
					top: 2px;
					left: 0;
				}
			}
		}

		span {
			font-size: 1.4rem;
			line-height: 1.5;
			color: #46483c;
			font-weight: 500;
			vertical-align: bottom;
			@include sp {
				display: block;
				font-size: 7px;
				color: #fff;
				margin: 4px 0 0;
			}
		}
	}

	.c-hokenItemSerect__item__btn {
		margin: 49px 0 0;
		@include sp {
			display: block;
			text-align: center;
			margin: 33px 0 0;
		}

		.c-hokenItemSerect__item__request {
			@include sp {
				margin: 10px 0 0;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-block05 {
  border: 8px solid #FFE0E7;
  padding: 50px 60px 42px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

@include sp {
  .c-block05 {
    border-width: 4px;
    padding: 24px 26px 20px;
  }
}

.c-block05__txt {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.6rem;
}

@include sp {
  .c-block05__txt {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}

.c-block05__big {
  font-size: 3rem;
  line-height: 4.5rem;
  font-weight: 500;
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 25px;
  margin-top: 6px;

  @include sp {
    font-size: 1.6rem;
    line-height: 1.7;
    letter-spacing: -0.05rem;
    padding-bottom: 7px;
    margin-bottom: 18px;
  }

  &::after {
    position: absolute;
    content: '';
    background: url(../img/ticket/enquete/icon_line.svg) no-repeat center;
    width: 110px;
    height: 8px;
    background-size: 110px 8px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    @include sp {
      width: 70px;
      height: 5px;
      background-size: 70px 5px;
    }
  }

  > b {
    font-weight: 700;

    @include sp {
      font-size: 1.8rem;
    }
  }
}

.c-block05__ttl > span {
  color: #FC819B;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  padding: 0 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @include sp {
    font-size: 1.4rem;
    padding: 0 16px;
  }

  &::before,
  &:after {
    position: absolute;
    content: '';
    bottom: 3px;
    background: url(../img/ticket/enquete/icon_line_l.svg) no-repeat center;
    width: 16px;
    height: 16px;
    background-size: 16px 16px;

    @include sp {
      width: 11px;
      height: 11px;
      background-size: 11px 11px;
    }
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    background: url(../img/ticket/enquete/icon_line_r.svg) no-repeat center;
    width: 16px;
    height: 16px;
    background-size: 16px 16px;

    @include sp {
      width: 11px;
      height: 11px;
      background-size: 11px 11px;
    }
  }
}

.c-block05__btn {
  margin-top: 8px;
  margin-bottom: 40px;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 16px;
  max-width: 300px;
  width: 100%;
  background-color: #FC819B;
  position: relative;

  @include sp {
    font-size: 1.4rem;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 19px;
  }

  &::after {
    position: absolute;
    content: '';
    right: 10px;
    top: 10px;
    background: url(../img/ticket/enquete/icon_link_w.svg) no-repeat center;
    width: 14px;
    height: 14px;
    background-size: 14px 14px;

    @include sp {
      width: 10px;
      height: 10px;
      background-size: 10px 10px;
      right: 7px;
      top: 7px;
    }
  }
}

.c-block05__note {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.8rem;
  position: relative;
  padding-left: 20px;

  @include sp {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: left;
    letter-spacing: -0.05rem;
  }

  &::before {
    position: absolute;
    content: "※";
    font-size: 1.6rem;
    line-height: 1;
    left: 0;
    top: 6px;

    @include sp {
      font-size: 1.2rem;
      top: 2px;
    }
  }

  > a {
    color: #308BBB;
    position: relative;
    border-bottom: 1px solid #308BBB;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      background: url(../img/ticket/enquete/icon_link_blue.svg) no-repeat center;
      width: 10px;
      height: 10px;
      background-size: 10px 10px;

      @include sp {
        width: 8px;
        height: 8px;
        background-size: 8px 8px;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-step3 {
	display: flex;
	@include yu_gosick;
	font-size: 2rem;
	line-height: 1.6;
	color: #989898;
	font-weight: 500;
	overflow: hidden;

	@include sp {
		font-size: 1.4rem;
		line-height: 1.5rem;
	}

	@include max-w(374px) {
		font-size: 1.2rem;
	}

	&__text {
		position: relative;
		z-index: 3;
		display: block;
		width: 100%;
		text-align: center;
	}

	// .c-step3__item .
	&__item {
		@include step3Item(#82CDF3, 'step3-bg-active.png', 'step3-blue-sp-active.svg', 'step3-blue2-sp-active.svg', 'step3-bg1-active.png', 'step3-bg2-active.png');
	}

	&.is-pink {
		.c-step3 {
			&__item {
				@include step3Item(#FF739B, 'step3-bg-pink-active.png', 'step3-pink-sp-active.svg', 'step3-pink2-sp-active.svg', 'step3-bg1-pink-active.png', 'step3-bg2-pink-active.png');
			}
		}
	}

	&.is-green {
		.c-step3 {
			&__item {
				@include step3Item(#3FBF90, 'step3-bg-green-active.png', 'step3-green-sp-active.svg', 'step3-green2-sp-active.svg', 'step3-bg1-green-active.png', 'step3-bg2-green-active.png');
			}
		}
	}

	&.is-coopGreen {
		.c-step3 {
			&__item {
				@include step3Item(#8CB340, 'step3-bg-coopGreen-active.png', 'step3-coopGreen-sp-active.svg', 'step3-coopGreen2-sp-active.svg', 'step3-bg1-coopGreen-active.png', 'step3-bg2-coopGreen-active.png');
			}
		}
	}

	&.is-orange {
		.c-step3 {
			&__item {
				@include step3Item(#FFAA48, 'step3-bg-orange-active.png', 'step3-orange-sp-active.svg', 'step3-orange2-sp-active.svg', 'step3-bg1-orange-active.png', 'step3-bg2-orange-active.png');
			}
		}
	}

	&.is-purple {
		.c-step3 {
			&__item {
				@include step3Item(#B689C2, 'step3-bg-purple-active.png', 'step3-purple-sp-active.svg', 'step3-purple2-sp-active.svg', 'step3-bg1-purple-active.png', 'step3-bg2-purple-active.png');
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-accordion {
	&__heading {
		background: #f3f3f3;
		text-align: center;
		padding: 15px 45px 13px;
		position: relative;
		cursor: pointer;
		@include sp {
			text-align: left;
			padding: 14px 35px 9px 15px;
		}

		&::before {
			content: "";
			width: 2px;
			height: 15px;
			background: #46483c;
			position: absolute;
			top: 50%;
			right: 26.5px;
			transform: translate(0, -50%);
			transition: 0.3s ease-in-out;
			@include sp {
				height: 10px;
				right: 19px;
			}
		}

		&::after {
			content: "";
			width: 15px;
			height: 2px;
			background: #46483c;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translate(0, -50%);
			@include sp {
				width: 10px;
				right: 15px;
			}
		}

		&.is-active {
			&::before {
				transform: translate(0, -50%) rotate(90deg);
			}
		}

		&.is-no-mark{
			cursor: initial;
			&::before{
				display: none;
			}
			&::after {
				display: none;
			}
		}
	}

	&__ttl {
		font-size: 1.8rem;
		line-height: 1.68;
		color: #46483c;
		font-weight: bold;
		@include sp {
			font-size: 1.4rem;
			line-height: 1.5;
		}
	}

	&__info {
		background: #fff;
		border: 1px solid #f3f3f3;
		padding: 24px 20px 22px;
		display: none;
		@include sp {
			padding: 17px 19px 11px;
		}
		&.is-no-mark{
			display: block;
		}
	}

	&__txt {
		text-align: center;
		font-size: 1.6rem;
		line-height: 1.75;
		color: #46483c;
		font-weight: 500;

		@include sp {
			text-align: left;
			font-size: 1.4rem;
		}
	}
}

.c-banner1{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color: #46483C, $alpha: 0.5);
	z-index: 9999;
	padding: 0 40px;
	min-height: 100vh;
	display: flex;
    align-items: center;
    justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;

	@include sp{
		padding: 0 20px;
	}

	&.is-show{
		opacity: 1;
		visibility: visible;
	}

	&__wrap{
		position: relative;
	}

	&__close{
		position: absolute;
		top: -25px;
		right: -25px;
		width: 50px;
		height: 50px;
		z-index: 1;
		cursor: pointer;

		@include sp{
			top: -12.5px;
			right: -12.5px;
			width: 25px;
			height: 25px;
		}
	}

	&__btn{
		position: fixed;
		top: 30%;
		right: 0;
		width: 120px;
		height: 400px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
		z-index: 101;
		cursor: pointer;

		&.is-active{
			opacity: 1;
			visibility: visible;
		}

		@include sp{
			width: 60px;
			height: 200px;
		}

		&--horizon {
			top: inherit;
			bottom: 0;
			right: 5px;
			width: 400px;
			height: 120px;

			@include sp {
				width: 200px;
				height: 60px;
			}
		}
	}
}

.c-hokenContactNavi {
    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #41B4E6;

    @include pc {
        margin-top: 80px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &--mt0 {
        margin-top: 0;
    }

    &__title {
        margin-bottom: 15px;
        color: #fff;
        font-size: 2.2rem;
        font-weight: bold;

        @include pc {
            margin-bottom: 40px;
            font-size: 3.2rem;
        }
    }

    &__list {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        background-color: #fff;

        @include pc {
            padding: 0;
            background-color: transparent;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
        }
    }

    &__card {
        padding-top: 20px;
        padding-bottom: 20px;

        &:not(:first-child) {
            border-top: 1px dashed #D9D9D9;
        }

        @include pc {
            padding: 40px 0;
            background-color: #fff;
            border-radius: 10px;
            flex: 1;
            display: grid;
            grid-template-rows: subgrid;
            grid-row: span 3;
        }

        &__title {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            column-gap: 5px;
            font-size: 1.8rem;
            font-weight: bold;

            @include pc {
                column-gap: 10px;
                justify-content: center;
                font-size: 2.4rem;
            }

            &__icon {
                width: 20px;
                height: 20px;
                fill: currentColor;

                @include pc {
                    width: 30px;
                    height: 30px;
                }

                &--soudan {
                    color: #FA8246;
                }

                &--document {
                    color: #41B4E6;
                }

                &--contact {
                    color: #41B4E6;
                }

                &--tel {
                    color: #FA8246;
                }
            }
        }

        &__message {
            margin-top: 10px;
            color: #222;
            font-size: 1.4rem;

            @include pc {
                margin-top: 20px;
                text-align: center;
                font-size: 1.6rem;
            }
        }

        &__btn {
            margin-top: 10px;
            @include pc {
                margin-top: 20px;
                padding-left: 40px;
                padding-right: 40px;
                display: flex;
                align-items: center;
            }
        }

        &__link {
            padding: 12px 20px;
            width: 100%;
            min-height: 50px;
            display: flex;
            align-items: center;
            border-width: 1px;
            border-style: solid;
            border-radius: 9999px;
            font-size: 1.6rem;
            font-weight: bold;
            justify-content: center;

            @include pc {
                padding: 27px 0;
                min-height: auto;
                border-width: 3px;
                font-size: 2.4rem;
                column-gap: 10px;
                white-space: nowrap;
                transition: .3s ease-in-out;
            }

            &--soudan {
                color: #FA8246;
                border-color: #FA8246;
                @include pc {
                    &:hover {
                        color: #fff;
                        background-color: #FA8246;
                    }
                }
            }

            &--document,
            &--contact {
                color: #41B4E6;
                border-color: #41B4E6;
                @include pc {
                    &:hover {
                        color: #fff;
                        background-color: #41B4E6;
                    }
                }
            }

            &--tel {
                color: #FA8246;
                border-color: #FA8246;
                column-gap: 5px;

                @include pc {
                    margin-left: auto;
                    margin-right: auto;
                    padding: 0;
                    width: fit-content;
                    border: none;
                    column-gap: 10px;
                }
            }

            &__label {
                flex: 1;
                text-align: center;

                @include pc {
                    flex: none;
                }

                &--tel {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 5px;

                    @include pc {
                        column-gap: 10px;
                    }

                    svg {
                        min-width: 17px;
                        width: 17px;
                        height: 17px;

                        @include pc {
                            min-width: 34px;
                            width: 34px;
                            height: 34px;
                        }
                    }
                }
            }

            &__icon {
                min-width: 12px;
                width: 12px;
                height: 12px;

                @include pc {
                    min-width: 30px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    &__fullCard {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        color: #222;
        font-size: 1.4rem;
        border-top: 1px dashed #D9D9D9;

        @include pc {
            padding: 40px;
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            border-top: none;
            row-gap: 10px;
            font-size: 1.6rem;
        }

        a {
            width: fit-content;
            display: flex;
            align-items: baseline;
            column-gap: 2.5px;
            color: #41B4E6;
            &::before {
                content: "▶";
            }
            span {
                text-decoration: underline;
            }
        }
    }
}

.c-hokenProduct01 {
    padding: 15px;
    border: 1.5px solid #D9D9D9;
    border-radius: 5px;
    display: grid;
    row-gap: 15px;

    @include pc {
        padding: 30px;
        border-width: 3px;
        border-radius: 10px;
        grid-template-columns: 450px 1fr;
        column-gap: 30px;
    }

    &__image {
        img {
            width: 100%;
            height: auto;
        }
    }

    &__body {
        @include pc {

        }
    }

    &__subTitle {
        color: #222;
        font-size: 1.4rem;
        @include pc {
            margin-bottom: 10px;
            color: #333;
            font-size: 1.6rem;
        }
    }

    &__title {
        color: #222;
        font-size: 1.8rem;
        @include pc {
            font-size: 2.4rem;
        }
    }

    &__action {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        @include pc {
            margin-top: 30px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        @include sp {
            > :nth-child(1),
            > :nth-child(2) {
                flex: 1;
            }

            > :nth-child(3) {
                width: 100%;
            }
        }
    }
}

.c-hokenAnchor {
    position: relative;
    top: -40px;

    @include pc {
        top: -80px;
    }
}

.p-hokenPageInNav {
    display: grid;
    row-gap: 15px;

    @include pc {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        row-gap: 40px;
    }

    &__link1 {
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        column-gap: 15px;
        border-bottom: 1px solid #D9D9D9;
        color: #41B4E6;
        font-size: 1.8rem;
        font-weight: 700;

        @include pc {
            padding-bottom: 20px;
            padding-left: 40px;
            padding-right: 40px;
            font-size: 2rem;
            column-gap: 20px;
        }

        &__icon {
            min-width: 14px;
            width: 14px;
            height: 14px;
            @include pc {
                margin-left: auto;
                min-width: 18px;
                width: 18px;
                height: 18px;
                order: 2;
            }
        }
        &__text {
            @include pc {
                order: 1;
            }
        }
    }

    &__children {
        padding-left: 25px;
        padding-right: 25px;
        @include pc {
            padding-left: 40px;
            padding-right: 40px;
        }
        > li {
            margin-top: 15px;
            @include pc {
                margin-top: 10px;
            }
        }
    }

    &__link2 {
        color: #000;
        font-size: 1.6rem;
        font-weight: 700;
        text-decoration: underline;

        @include pc {
            text-decoration: none;
        }
    }
}
