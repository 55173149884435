@use '../0_base' as *;

/*------------------------------------------------------------
footer02(サービスTOP)
------------------------------------------------------------*/
.c-foot02 {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding-top: 73px;
	background-color: $subColor01;
	border-top: solid 1px $keycolor01;
}
.c-foot02NaviArea {
	margin-bottom: 74px;
}
.c-foot02Logo {
	width: 250px;
	margin-top: 7px;
}
.c-foot02Navi {
	width: 830px;
	dl {
		width: 250px;
		dt {
			margin-bottom: 10px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 2;
			border-bottom: solid 1px $keycolor01;
			padding-bottom: 6px;
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
		dd {
			margin-bottom: 4px;
			padding-left: 35px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -15px;
				content: '';
				background-image: url("../img/common/icon_arrow-green01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
	}
	ul {
		width: 250px;
		margin-left: 40px;
		li {
			margin-bottom: 4px;
			padding-left: 35px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -15px;
				content: '';
				background-image: url("../img/common/icon_arrow-green01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
	}
}

@include sp {
	.c-foot02 {
		min-width: 100%;
		padding-top: 0;
		background-color: #ffffff;
		border: none;
		position: relative;
		.c-pagetop {
			position: absolute;
			top: -45px;
			right: 0;
		}
	}
}

/*
------------------------------------------------------------*/
