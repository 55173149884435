@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　ご利用までの流れ
------------------------------------------------------------*/
.p-reformFlow {
	background-color: #ffffff;
}

//ご利用までの流れ
.p-reformFlow01 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior05.jpg");
		}
	}
}

//お申込みから施工代金支払いまでの流れ
.p-reformFlow02 {
	margin-bottom: 53px;
	font-size: 1.5rem;
	line-height: 1.73;
	color: $gray01;
	&__content01 {
		margin-bottom: 60px;
		.c-refomeTitle04{
			margin-bottom: 2px;
    		padding: 9px 0 6px;
		}
		p {
			text-align: center;
		}
	}
	&__content02 {
		margin-bottom: 60px;
		ol {
			li {
				margin-bottom: 40px;
				padding: 40px 40px 35px;
				position: relative;
				border: solid 1px;
				&:after {
					width: 0;
					height: 0;
					margin-left: -35px;
					content: '';
					display: block;
					border-style: solid;
					border-width: 20px 35px 0 35px;
					border-color: #000000 transparent transparent transparent;
					position: absolute;
					top: 100%;
					left: 50%;
				}
				dl {
					min-height: 130px;
					padding-left: 200px;
					position: relative;
					dt {
						height: 45px;
						margin-bottom: 10px;
						padding: 0 120px 45px 0;
						font-size: 2.0rem;
						letter-spacing: 1.8px;
						font-weight: bold;
						border-bottom: dotted 1px $gray07;
						position: relative;
						> img {
							margin: -5px 15px 0 0;
							display: inline-block;
							vertical-align: middle;
						}
						span {
							position: absolute;
							top: -4px;
							right: -4px;
						}
					}
					dd {
						line-height: 1.75;
						img {
							position: absolute;
							top: 0;
							left: 0;
						}
						span {
							margin-top: 5px;
							font-size: 1.1rem;
							line-height: 1.5;
							display: block;
						}
					}
				}
				&:nth-child(2){
					padding: 43px 40px 35px;
					dd{
						img{
							top: -1px;
						}
					}
				}
				&:nth-child(3){
					padding: 40px 40px 32px;
					dd{
						img{
							top: -4px;
						}
					}
				}
				&:nth-child(4){
					padding: 40px 40px 32px;
				}
				&:nth-child(5){
					padding: 39px 40px 34px;
				}
			}
		}
		&__step01 {
			background-color: rgba($reformBtnColor01,0.15);
			border-color: $reformBtnColor01 !important;
			&:after {
				border-color: $reformBtnColor01 transparent transparent transparent !important;
			}
			dl {
				dt {
					color: $reformBtnColor01;
				}
			}
		}
		&__step02 {
			padding: 43px 40px 35px;
			background-color: rgba($reformNaviColor04,0.15);
			border-color: $reformNaviColor04 !important;
			&:after {
				border-color: $reformNaviColor04 transparent transparent transparent !important;
			}
			dl {
				dt {
					color: $reformNaviColor04;
				}
			}
		}
		&__step03 {
			background-color: rgba($reformNaviColor01,0.15);
			border-color: $reformNaviColor01 !important;
			&:after {
				border-color: $reformNaviColor01 transparent transparent transparent !important;
			}
			dl {
				dt {
					color: $reformNaviColor01;
				}
			}
		}
		&__step04 {
			background-color: rgba($reformNaviColor01,0.15);
			border-color: $reformNaviColor01 !important;
			&:after {
				border-color: $reformNaviColor01 transparent transparent transparent !important;
			}
			dl {
				dt {
					color: $reformNaviColor01;
				}
			}
		}
		&__step05 {
			background-color: rgba($reformNaviColor01,0.15);
			border-color: $reformNaviColor01 !important;
			&:after {
				border-color: $reformNaviColor01 transparent transparent transparent !important;
			}
			dl {
				dt {
					color: $reformNaviColor01;
				}
			}
		}
		&__step06 {
			background-color: rgba($reformBtnColor01,0.15);
			border-color: $reformBtnColor01 !important;
			&:after {
				content: none !important;
			}
			dl {
				dt {
					color: $reformBtnColor01;
				}
			}
		}
	}
	&__content03 {
		dl {
			dt {
				margin-bottom: 18px;
    			padding: 9px 19px 8px;
				font-size: 1.4rem;
				color: #ffffff;
				background-color: $reformColor01;
			}
			dd {
				font-size: 1.3rem;
				line-height: 1.53;
			}
		}
		p{
			line-height: 1.75;
		}
	}
}

//関連ページ
.p-reformFlow03 {
	margin-bottom: 106px;
}

@include sp {

	//ご利用までの流れ sp
	.p-reformFlow01 {
		margin-bottom: 30px;
		&__content01 {
			.c-refomeTitle02 {
				background-image: url("../img/reform/title_obi_interior05_sp.jpg");
			}
		}
	}

	//お申込みから施工代金支払いまでの流れ sp
	.p-reformFlow02 {
		margin-bottom: 30px;
		padding: 0 20px;
		&__content01 {
			margin-bottom: 30px;
		}
		&__content02 {
			margin-bottom: 30px;
			ol {
				li {
					margin-bottom: 25px;
					padding: 15px;
					dl {
						min-height: auto;
						padding-left: 0;
						position: static;
						dt {
							height: auto;
							margin-bottom: 15px;
							padding: 5px 0;
							font-size: 1.8rem;
							> img {
								margin: 0 0 5px 0;
								display: block;
							}
							span {
								top: 0;
								right: 0;
							}
						}
						dd {
							line-height: 1.7;
							font-size: 1.4rem;
							@include clear;
							img {
								max-width: 50%;
								margin: 0 10px 0 0;
								float: left;
								display: block;
								position: static;
							}
						}
					}
					&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){
						padding: 15px;
						dd{
							img{
								top: 0;
							}
						}
					}
				}
			}
		}
		&__content03 {
			p {
				font-size: 1.4rem;
			}
			dl {
				dt {
					margin-bottom: 10px;
					padding: 10px;
				}
			}
		}
	}

	//関連ページ sp
	.p-reformFlow03 {
		margin-bottom: 40px;
		padding: 0 20px;
		.c-reformKanrenLink li img {
			height: auto;
		}
	}
}

/*
------------------------------------------------------------*/
