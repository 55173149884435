@use '../../0_base' as *;

/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.page-hoken_lp_sandai_sippei{
	position: relative;
	font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif ;
	background: #fcfbf5;
	min-width: 1200px;
	@include sp{
		min-width: 320px;
	}
	.l-contentServiceIn{
		display: block;
	}
	.l-contentService{
		@include sp{
			padding-top: 0;
		}
	}
	.pc-only{
		display: block;
		@include sp{
			display: none;
		}
	}
	.sp-only{
		display: none;
		@include sp{
			display: block;
		}
	}
}
.p-hokenLpSippeiMv{
	background: url('/img/hoken/lp/sandai_sippei/mv.png') no-repeat center;
	background-size: cover;
	padding: 126px 0 50px;
	position: relative;
	overflow-x: hidden;
	@include sp{
		padding: 58px 13px 0 20px;
		background: url('/img/hoken/lp/sandai_sippei/mv-sp.png') no-repeat top center;
		background-size: 100% auto;
	}
	.l-contentServiceIn{
		@include sp{
			padding: 0 !important;
		}
	}
	&__txt{
		border-radius: 10px;
		background-image: -moz-linear-gradient( 0deg, rgba(34,172,56,0.949) 0%, rgba(143,195,31,0.949) 100%);
		background-image: -webkit-linear-gradient( 0deg, rgba(34,172,56,0.949) 0%, rgba(143,195,31,0.949) 100%);
		background-image: linear-gradient( 0deg, rgba(34,172,56,0.949) 0%, rgba(143,195,31,0.949) 100%);
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		margin-top: 35px;
		padding: 40px 40px;
		@include sp{
			flex-wrap: wrap;
			margin-top: 37px;
			padding: 20px;
			margin-right: 6px;
		}
	}
	&__subttl {
	    font-size: 2.0rem;
		line-height: 1;
		color: #59493f;
		font-weight: bold;
		box-shadow: 0px 0px 10px rgba(121, 85, 72, 0.2);
		display: inline-block;
		background: #fff;
		padding: 8px 9px 7px 20px;
		text-align: center;
		border-radius: 10px;

	    @include sp {
			font-size: 1.5rem;
			padding: 8px 5px 7px 8px;
	    }
	}
	&__img{
		position: absolute;
		bottom: -42px;
		right: 23px;
		@include sp{
			bottom: auto;
			top: -15px;
			right: -10px;
			max-width: 180px;
			order: 2;
			margin: -10px -10px 0 0;
			width: 60%;
			z-index: 11;
		}
	}
	&__text1{
		max-width: 529px;
	}
	&__text2{
		@include sp{
			padding-top: 73px;
		}
	}
}
.p-hokenLpSippei{
	.l-contentServiceIn{
		@include sp{
			padding: 0 20px;
		}
	}
	&__info{
		display: flex;
		align-items: center;
		@include sp{
			display: block;
			order: 1;
			width: 50%;
			max-width: 148px;
		}
	}
	&__txt2{
		margin-left: 10px;
	}
	&__txt3{
		position: relative;
		margin-left: 22px;
		@include sp{
			margin: 57px 0 0;
			order: 3;
			width: 100%;
		}
	}
	&__txt4{
		border-radius: 10px;
		border: 5px solid #e6e4e2;
		padding: 30px 34px 28px;
		margin-top: 35px;
		@include sp{
			padding: 21px 21px 12px;
			margin-top: 31px;
		}
	}
	&__btn1{
		font-size: 1.6rem;
		line-height: 1.25;
		color: #22ac38;
		font-weight: bold;
		border-radius: 4px;
		background: rgb(255, 255, 255);
		box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
		width: 80px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include sp{
			font-size: 1rem;
			height: 20px;
			width: 136px;
		}
	}
	&__btn2{
		font-size: 2rem;
		line-height: 1.8;
		color: #fff;
		font-weight: bold;
		width: 480px;
		height: 80px;
		border: 5px solid #fff;
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		background: #ff5b4c;
		display: inline-block;
		text-align: center;
		padding: 18px 0;
		border-radius: 50px;
		position: relative;
		@include sp{
			font-size: 1.4rem;
			width: 100%;
			height: 60px;
			border: 4px solid #fff;
			padding: 15px 0;
		}
		&:after{
			content: '';
			border-style: solid;
			border-width: 6px 0 6px 7px;
			border-color: transparent transparent transparent #fff;
			position: absolute;
			top: 50%;
			right: 25px;
			transform: translateY(-50%);
			transition: .3s all;
			@include sp{
				right: 18px;
			}
		}
		span{
			position: relative;
			padding-left: 50px;
			@include sp{
				padding-left: 10px;
			}
			&:before{
				content: '';
				width: 30px;
				height: 30px;
				background: url('/img/hoken/lp/sandai_sippei/icon1.png') no-repeat center;
				background-size: cover;
				position: absolute;
				top: 53%;
				left: 12px;
				margin-top: -3px;
				transform: translateY(-50%);
				transition: .3s all;
				@include sp{
					width: 20px;
					height: 20px;
					left: -14px;
				}
			}
		}

		@include hover {
			color: #ff5b4c;
			border: 5px solid #ff5b4c;
			background: #fff;
			opacity: 1;

			&:after {
				border-color: transparent transparent transparent #ff5b4c;
				transition: .3s all;
			}

			span {
				&:before {
					background: url('/img/hoken/lp/sandai_sippei/icon1_over.png') no-repeat center;
					background-size: cover;
					transition: .3s all;
				}
			}
		}
	}
	&__text1{
		font-size: 1.6rem;
		line-height: 1.75;
		color: #595950;
		span{
			color: #ff5b4c;
			font-weight: bold;
			&.u-colorsp{
				color: #595950;
				@include sp{
					color: #ff5b4c;
				}
			}
		}
	}
	&__text3{
		font-size: 1.6rem;
		line-height: 1;
		color: #fff;
		font-weight: bold;
		text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
		@include sp{
			font-size: 1.3rem;
			padding: 5px 22px 0;
			white-space: nowrap;
		}
		span{
			font-size: 2.4rem;
			@include sp{
				font-size: 2rem;
			}
		}
	}
	&__text4{
		font-size: 2.4rem;
		line-height: 1;
		font-weight: bold;
		color: #fff;
		text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
		@include sp{
			font-size: 2rem;
			margin: 2px 0 0 -8px;
			letter-spacing: -1px;
			white-space: nowrap;
		}
		span{
			font-size: 4.4rem;
			@include sp{
				font-size: 3.6rem;
			}
		}
	}
	&__text5{
		font-size: 1.2rem;
		line-height: 1;
		color: #fff;
		font-weight: bold;
		text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
		@include sp{
			font-size: 1rem;
			margin: 0 0 0 10px;
		}
	}
	&__label{
		font-size: 1.6rem;
		line-height: 1.5;
		color: #59493f;
		background: #fcf9e8;
		box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
		border-radius: 6px;
		font-weight: bold;
		z-index: 10;
		position: absolute;
		top: -55px;
		left: 50%;
		transform: translateX(-50%);
		@include sp{
			font-size: 1.2rem;
			margin-left: 0;
			top: -40px;
			left: 0;
			transform: translateX(0);
		}
		span{
			padding: 10px 0;
			display: inline-block;
			width: 240px;
			height: 40px;
			text-align: center;
			position: relative;
			@include sp{
				padding: 6px 0;
				display: inline-block;
				width: 170px;
				height: 30px;
			}
			&:after{
				content: '';
				border-style: solid;
				border-width: 10px 5px 0 5px;
				border-color: #fcf9e8 transparent transparent transparent;
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				@include sp{
					border-width: 8px 4px 0 4px;
					bottom: -8px;
				}
			}
		}
	}
	&__ttl1{
		font-size: 4rem;
		line-height: 1;
		color: #59493f;
		font-weight: bold;
		position: relative;
		padding: 72px 0 37px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		letter-spacing: 1px;
		@include sp{
			display: block;
			text-align: center;
			font-size: 3rem;
			line-height: 1.2;
			padding: 45px 0 13px;
		}

		&--wid {
			width: 100%;
			text-align: center;
		}

		&:before{
			content: '';
			background: url('/img/hoken/lp/sandai_sippei/icon2.png') no-repeat center;
			background-size: cover;
			width: 39.5px;
			height: 40.5px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			@include sp{
				width: 30px;
				height: 29px;
			}
		}
		&:after{
			content: '';
			background: url('/img/hoken/lp/sandai_sippei/line1.png') no-repeat center;
			background-size: cover;
			width: 120px;
			height: 16px;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			@include sp{
				width: 88px;
				height: 11px;
			}
		}
		&--red {
			font-size: 6.8rem;
			color: #ff5b4c;
			font-family: 'Roboto', sans-serif;
			@include sp{
				font-size: 5.1rem;
				vertical-align: sub;
			}
		}
		img{
			vertical-align: sub;
			@include sp{
				max-width: 240.5px;
			}
		}
		i{
			font-style: normal;
		}
	}
	&__ttl2{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 15px;
	}
	&__ttl3{
		font-size: 2.4rem;
		line-height: 1.75;
		color: #59493f;
		position: relative;
		padding-left: 40px;
		margin-bottom: 12px;
		@include sp{
			font-size: 1.8rem;
			line-height: 1.5;
			margin-bottom: 8px;
		}
		&:before{
			content: '';
			width: 30px;
			height: 30px;
			background: url('/img/hoken/lp/sandai_sippei/icon3.png') no-repeat center;
			background-size: cover;
			position: absolute;
			top: 5px;
			left: 0;
			@include sp{
				top: 0;
			}
		}
	}
	&__point{
		font-size: 1.2rem;
		font-weight: bold;
		line-height: 1;
		color: #fff;
		border-radius: 4px;
		width: 70px;
		height: 70px;
		background: #22ac38;
		text-align: center;
		padding: 12px 0;
		@include sp{
			font-size: 1rem;
			width: 60px;
			height: 60px;
		}
		span{
			font-size: 4rem;
			line-height: 1;
			font-family: 'Roboto', sans-serif;
			display: block;
			@include sp{
				font-size: 3.4rem;
			}
		}
	}
	&__ttl2Main{
		font-size: 2.8rem;
		line-height: 4.2rem;
		font-weight: bold;
		color: #59493f;
		padding-left: 16px;
		@include sp{
			width: calc(100% - 60px);
			font-size: 2rem;
			line-height: 3rem;
		}
		span{
			color: #ff5b4c;
		}
	}
	&__list1{
		margin-top: 37px;
		@include sp{
			margin-top: 17px;
		}
		li{
			font-size: 1.2rem;
			line-height: 2.1rem;
			color: #595950;
			position: relative;
			padding-left: 14px;
			@include sp{
				line-height: 1.8rem;
			}
			&:before{
				content: '※';
				position: absolute;
				top: 0;
				left: 0;
			}
			a{
				color: #308bbb;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	&__list2{
		li{
			font-size: 1.2rem;
			line-height: 2.1rem;
			color: #595950;
			position: relative;
			padding-left: 12px;
			@include sp{
				line-height: 1.8rem;
			}
			&:before{
				content: '';
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #22ac38;
				position: absolute;
				top: 8px;
				left: 0;
			}
			a{
				color: #308bbb;
				text-decoration: underline;
				position: relative;
				margin-right: 15px;
				&:after{
					content: '';
					width: 10px;
					height: 10px;
					background: url('/img/hoken/lp/sandai_sippei/icon5.png') no-repeat center;
					background-size: cover;
					position: absolute;
					top: 3px;
					right: -11px;
				}
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}
.p-hokenLpSippei1{
	margin-top: 78px;
	overflow-x: hidden;
	@include sp{
		margin-top: 40px;
	}
	&__text1{
		font-size: 2.8rem;
		font-weight: bold;
		color: #59493f;
		line-height: 1.5;
		@include sp{
			font-size: 2rem;
			letter-spacing: -0.3px;
		}
		span{
			color: #22ac38;
		}
	}
	&__text2{
		font-size: 1.7rem;
		line-height: 2.7rem;
		color: #59493f;
		font-weight: bold;
		@include sp{
			font-size: 1.4rem;
			line-height: 2.1rem;
		}
		span{
			color: #ff5b4c;
		}
	}
	&__text3{
		font-size: 1.2rem;
		line-height: 1.5;
		color: #595950;
		margin-top: 20px;
		text-align: left;
		@include sp{
			width: 100%;
			margin-top: 18px;
		}
	}
	&__text4{
		font-size: 1.6rem;
		line-height: 1.75;
		color: #595950;
	}
	&__point{
		font-size: 1.2rem;
		line-height: 1.5;
		color: #fff;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		span{
			font-size: 3.2rem;
			font-family: 'Roboto', sans-serif;
			margin-left: 2px;
		}
	}
	&__list1{
		background: #e3f0c7;
		border-radius: 6px;
		padding: 40px 40px 35px;
		display: table;
		table-layout: fixed;
		margin-top: 33px;
		@include sp{
			margin-top: 23px;
			padding: 20px 20px 10px;
		}
		dl{
			display: table;
			width: 100%;
			table-layout: fixed;
			border-radius: 6px;
			overflow: hidden;
			margin-bottom: 7px;
			@include sp{
				margin-bottom: 10px;
				min-height: 60px;
			}
		}
		dt, dd{
			display: table-cell;
			vertical-align: middle;
		}
		dt{
			width: 100px;
			position: relative;
			background: #22ac38;
			@include sp{
				width: 34%;
			}
			&:after{
				content: '';
				border-style: solid;
				border-width: 24px 0 24px 10px;
				border-color: transparent transparent transparent #22ac38;
				position: absolute;
				top: 0;
				right: -10px;
				@include sp{
					border-width: 30px 0 30px 10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		dt.p-hokenLpSippei1__list1--row2{
			&:after{
				content: '';
				border-style: solid;
				border-width: 38px 0 38px 10px;
				border-color: transparent transparent transparent #22ac38;
				position: absolute;
				top: 0;
				right: -10px;
				@include sp{
					border-width: 30px 0 30px 10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		dd{
			background: #fff;
			padding: 11px 10px 10px 18px;
			@include sp{
				padding: 11px 26px 7px 20px;
				min-height: 60px;
			}
		}
	}
	&__list2{
		background: #f8f8f8;
		border-radius: 4px;
		padding: 0 20px;
		margin-top: 25px;
		@include sp{
			margin-top: 13px;
		}
		li{
			display: flex;
			padding: 15px 0;
			border-bottom: 1px dashed #59493f;
			@include sp{
				padding: 15px 0 18px;
			}
			&:last-child{
				border-bottom: 0;
			}
		}
	}
	&__number1{
		font-size: 1.2rem;
		line-height: 1.5;
		color: #fff;
		font-weight: bold;
		border-radius: 4px;
		width: 60px;
		background: #59493f;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 4px 13px 4px 0;
		@include sp{
			width: 63px;
		}
	}
	&__list2Text{
		width: calc(100% - 60px);
		font-size: 1.4rem;
		line-height: 2.45rem;
		color: #595950;
		font-weight: bold;
		@include sp{
			width: calc(100% - 63px);
		}
	}
	&__imgtxt1{
		display: flex;
		justify-content: space-between;
		margin: 55px 0 71px;
		@include sp{
			display: block;
			margin: 27px 0 40px;
		}
	}
	&__imgtxt2{
		display: flex;
		flex-wrap: wrap;
		background: #fff;
		padding: 36px 40px 43px;
		margin: -15px 0 34px;
		@include sp{
			flex-wrap: wrap;
			margin: 0 0 18px;
			padding: 19px 20px 23px;
			position: relative;
		}
		&.imgtxt2{
			@include sp{
				padding: 19px 20px 23px;
			}
			.p-hokenLpSippei1__img2{
				padding: 0px 0px 0 20px;
				@include sp{
					padding: 12px 0px 0 0;
				}
			}
			.p-hokenLpSippei1__txt2{
				padding: 0 20px 0 0;
				@include sp{
					padding: 0;
				}
			}
			.p-hokenLpSippei__ttl2{
				margin-bottom: 21px;
				@include sp{
					margin-bottom: 13px;
				}
			}
		}
		&.imgtxt3{
			@include sp{
				padding: 19px 20px 23px;
			}
			.p-hokenLpSippei1__img2{
				padding: 0;

				img {
					margin: 0 0 0 auto;

					@include sp {
						margin: 0;
					}
				}

				@include sp{
					padding: 16px 0px 0 0;
				}
			}
			.p-hokenLpSippei__ttl2{
				margin-bottom: 21px;
				@include sp{
					margin-bottom: 13px;
				}
			}
			.p-hokenLpSippei__text1 {
				& + .p-hokenLpSippei__list1 {
					margin-top: 20px;

					@include sp {
						margin-top: 10px;
					}
				}
			}
		}

		.p-hokenLpSippei__txt4 {
			width: 100%;
		}
	}
	&__txt1{
		width: calc(50% - 40px);
		@include sp{
			width: 100%;
		}
	}
	&__txt2{
		width: 50%;
		padding: 0 20px 0 0;
		@include sp{
			width: 100%;
			padding: 0;
		}
	}
	&__img1{
		width: 50%;
		margin-top: 6px;
		text-align: right;
		position: relative;
		@include sp{
			width: 100%;
			margin-top: 30px;
		}
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			background: url('/img/hoken/lp/sandai_sippei/img1-1.png') no-repeat right bottom;
			background-size: cover;
			position: absolute;
			bottom: -65px;
			right: -66px;
			z-index: 0;
			@include sp{
				width: 110%;
				height: 110%;
				bottom: -15%;
				right: -15%;
			}
		}
		img{
			position: relative;
			z-index: 9;
		}
	}
	&__img2{
		width: 50%;
		text-align: center;
		padding: 8px 0 0 22px;
		@include sp{
			width: 100%;
			padding: 11px 0 0;
		}
	}
}
.p-hokenLpSippei2{
	background: url('/img/hoken/lp/sandai_sippei/bg1.png') no-repeat center;
	background-size: cover;
	margin-top: 40px;
	padding: 54px 0 80px;
	@include sp{
		margin-top: 20px;
		background: url('/img/hoken/lp/sandai_sippei/bg1-sp.png') no-repeat center;
		background-size: cover;
		padding: 38px 0 40px;
	}
	.p-hokenLpSippei__ttl1{
		font-size: 3.2rem;
		padding: 0;
		letter-spacing: 0px;
		@include sp{
			font-size: 2.4rem;
			line-height: 1.5;
		}
		&:before, &:after{
			content: none;
		}
		img{
			vertical-align: middle;
			@include sp{
				vertical-align: bottom;
			}
		}
		p{
			&:last-child{
				margin-top: -4px;
			}
		}
	}
	&__cont{
		border-radius: 10px;
		background-image: -moz-linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
		background-image: -webkit-linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
		background-image: linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
		opacity: 0.902;
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		padding: 38px 40px 40px;
		margin-top: 53px;
		@include sp{
			margin-top: 18px;
			padding: 0;
			background-image: none;
			box-shadow: none;
		}
	}
	&__info1{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		@include sp{
			flex-wrap: wrap;
			border-radius: 10px;
			background-image: -moz-linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
			background-image: -webkit-linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
			background-image: linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
			opacity: 0.949;
			box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
			padding: 20px 20px 19px;
		}
		.p-hokenLpSippei__btn1{
			height: 74px;
			@include sp{
				height: 20px;
			}
		}
		.p-hokenLpSippei__btn2{
			height: 80px;
			padding: 20px 0;
			@include sp{
				width: 100%;
				height: 60px;
				padding: 13px 0;
			}
			span{
				padding-left: 43px;
				@include sp{
					padding-left: 13px;
				}
				&:before{
					left: 3px;
					@include sp{
						left: -12px;
					}
				}
			}
		}
		.p-hokenLpSippei__label{
			top: -53px;
			@include sp{
				top: -40px;
			}
		}
	}
	&__info2{
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		border-radius: 10px;
		background: #fff;
		padding: 10px 10px 17px;
		margin-top: 42px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		@include sp{
			margin-top: 10px;
			padding: 20px 20px 21px;
		}
	}
	&__img{
		position: absolute;
		top: -83px;
		right: -17px;
		@include sp{
			bottom: auto;
			top: -15px;
			right: -10px;
			max-width: 180px;
			order: 2;
			margin: -10px -10px 0 0;
			width: 60%;
			z-index: 11;
		}
	}
	&__ttl{
		font-size: 2rem;
		line-height: 1.5;
		font-weight: bold;
		color: #fff;
		background: #8fc31f;
		border-radius: 4px;
		width: 100%;
		text-align: center;
		padding: 5px 0;
		margin-bottom: 17px;
		@include sp{
			font-size: 1.4rem;
			padding: 5px 0;
			margin-bottom: 9px;
		}
	}
	&__text1{
		font-size: 1.6rem;
		line-height: 1.75;
		color: #595950;
		width: 43%;
		margin-right: 40px;
		@include sp{
			width: 100%;
			font-size: 1.2rem;
			line-height: 2.1rem;
			margin-right: 0;
		}
	}
	&__btn1{
		font-size: 1.6rem;
		line-height: 1;
		font-weight: bold;
		color: #22ac38;
		border: 2px solid #22ac38;
		border-radius: 50px;
		display: block;
		width: 320px;
		height: 46px;
		text-align: center;
		padding: 18px 0 31px;
		position: relative;
		@include sp{
			margin-top: 10px;
			width: 100%;
			max-width: 320px;
			height: 48px;
			font-size: 1.2rem;
			padding: 16px 0;
		}
		&:after{
			content: '';
			border-style: solid;
			border-width: 5px 0 5px 5px;
			border-color: transparent transparent transparent #22ac38;
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translateY(-50%);
			transition: .3s all;
		}
		span{
			position: relative;
			padding-left: 40px;
			@include sp{
				padding-left: 27px;
			}
			&:before{
				content: '';
				width: 28px;
				height: 24px;
				background: url('/img/hoken/lp/sandai_sippei/icon4.png') no-repeat center;
				background-size: cover;
				position: absolute;
				top: 44%;
				left: 4px;
				transform: translateY(-50%);
				transition: .3s all;

				@include sp{
					width: 20px;
					height: 17px;
					left: 1px;
				}
			}
		}

		@include hover {
			color: #fff;
			background: #22ac38;
			opacity: 1;

			&:after {
				border-color: transparent transparent transparent #fff;
				transition: .3s all;
			}

			span {
				&:before {
					background: url('/img/hoken/lp/sandai_sippei/icon4_over.png') no-repeat center;
					background-size: cover;
					transition: .3s all;
				}
			}
		}
	}
}
.p-hokenLpSippei3{
	background: #fff;
	padding: 78px 0 67px;
	margin-bottom: -41px;
	$root: &;
	@include sp{
		padding: 40px 0 72px;
		margin-bottom: -61px;
	}
	.l-contentServiceIn{
		padding: 0 40px;
		@include sp{
			padding: 0 20px;
		}
	}
	.p-hokenLpSippei__ttl1{
		padding: 72px 0 32px;
		letter-spacing: 0;
		line-height: 1.56;
		@include sp{
			padding: 44px 0 14px;
		}
	}
	&__ttl1{
		font-size: 2.4rem;
		line-height: 2.8rem;
		color: #59493f;
		margin: 58px 0 48px;
		@include sp{
			font-size: 2rem;
			line-height: 3rem;
			margin: 24px 0 17px;
		}
		span{
			color: #22ac38;
		}
	}
	&__text1{
		font-size: 1.6rem;
		line-height: 2.8rem;
		font-weight: bold;
		color: #ff5b4c;
	}
	&__text2{
		font-size: 1.6rem;
		line-height: 2.8rem;
		color: #595950;
		margin-bottom: 28px;
		@include sp{
			margin-bottom: 8px;
		}
		span{
			@include sp{
				color: #ff5b4c;
				font-weight: bold;
			}
		}
	}
	&__text3{
		font-size: 1.2rem;
		line-height: 2.1rem;
		color: #595950;
		@include sp{
			line-height: 1.8rem;
			word-break: break-all;
		}
	}
	&__text4{
		font-size: 2.4rem;
		line-height: 4.7rem;
		color: 	#59493f;
		font-weight: bold;
		text-align: center;
		@include sp{
			font-size: 1.8rem;
			line-height: 3.4rem;
			letter-spacing: -1.7px;
		}
		span{
			font-size: 3.2rem;
			@include sp{
				font-size: 2.4rem;
			}
			&.u-green{
				color: #22ac38;
			}
			&.u-red{
				color: #ff5b4c;
			}
		}
	}
	&__img{
		text-align: center;
		margin: 23px 0 26px;
		@include sp{
			margin: 17px 0 15px;
		}
	}
	&__label{
		text-align: center;
		margin: 119px 0 25px;
		@include sp{
			margin: 35px auto 15px;
			width: 100%;
			max-width: 320px;
		}
		span{
			font-size: 1.6rem;
			line-height: 2.4rem;
			color: #fff;
			font-weight: bold;
			background: #22ac38;
			position: relative;
			border-radius: 6px;
			display: inline-block;
			padding: 8px 20px;
			@include sp{
				padding: 16px 10px;
				width: 100%;
			}
			&:after{
				content: '';
				border-style: solid;
				border-width: 10px 5px 0 5px;
				border-color: #22ac38 transparent transparent transparent;
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&__list {
		width: calc(100% + 80px);
		display: flex;
		flex-wrap: wrap;
		margin: 54px 0 40px -40px;
		@include sp {
			width: 100%;
			margin: 26px 0 30px;
		}
	}

	&__item {
		width: calc((100% - 40px) / 2);
		border: 5px solid #22ac38;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 28px 34px;
		margin: 0 0 0 40px;
		@include sp {
			width: 100%;
			padding: 22px 15px;
			margin: 0 0 20px;
		}

		&:nth-child(2n + 1) {
			margin: 0;
			@include sp {
				margin: 0 0 20px;
			}
		}

		&:last-child {
			@include sp {
				margin: 0;
			}
		}
	}

	&__info {
		#{$root}__text2 {
			margin: 0 0 18px;
			@include sp {
				margin: 0 0 8px;
			}
		}
	}

	&__ttl2 {
		font-size: 2.8rem;
		line-height: 1.5;
		color: #59493f;
		font-weight: bold;
		margin: 0 0 14px;
		@include sp {
			font-size: 2rem;
			margin: 0 0 6px;
		}

		span {
			color: #ff5b4c;
		}
	}
}
.p-hokenLpSippei4{
	padding: 80px 0 74px;
	@include sp{
		padding: 40px 0 40px;
	}
	.p-hokenLpSippei__ttl1{
		padding: 71px 0 30px;
		margin-bottom: 60px;
		line-height: 1.6;
		@include sp{
			padding: 47px 0 18px;
			margin-bottom: 32px;
			line-height: 1.4;
		}
	}
	&__ttl1{
		font-size: 2.4rem;
		line-height: 3.6rem;
		font-weight: bold;
		color: #22ac38;
		border-radius: 10px;
		border: 5px solid #22ac38;
		text-align: center;
		padding: 2px 0;
	}
	&__img{
		text-align: center;
		margin: 40px 0 0;
		@include sp{
			margin: 19px 0 19px;
			overflow-x: scroll;
		}
		img{
			margin: 0 auto;
			@include sp {
				max-width: 220%;
			}
		}
	}
	.p-hokenLpSippei__list1{
		padding: 0 40px;
		margin-top: 35px;
		@include sp{
			padding: 0;
			margin-top: 16px;
		}
	}
	.p-hokenLpSippei__txt4{
		padding: 30px 34px 35px;
		margin: 55px 0 80px;
		@include sp{
			padding: 22px 20px 19px;
			margin: 27px 0 40px;
		}
	}
	&__table1{
		border-collapse: collapse;
		border: 1px solid #d8d8d8;
		width: 100%;
		margin-top: 18px;
		@include sp{
			margin-top: 14px;
		}
		th, td{
			font-size: 1.6rem;
			line-height: 2.4rem;
			color: #595950;
			border: 1px solid #d8d8d8;
			@include sp{
				font-size: 1.4rem;
				line-height: 2.1rem;
			}
		}
		th{
			font-weight: bold;
			background: #f8f8f8;
			text-align: left;
			padding: 9px 14px;
			width: 209px;
			@include sp{
				width: 33%;
				padding: 5px 10px 4px;
			}
		}
		td{
			background: #fff;
			padding: 9px 16px;
			@include sp{
				padding: 5px 10px 4px;
			}
			span{
				color: #f0662b;
				font-weight: bold;
			}
		}
	}
	&__table2{
		border-collapse: collapse;
		border: 1px solid #d8d8d8;
		width: calc(100% - 80px);
		margin: 40px auto 0;
		background: #fff;
		@include sp{
			width: 100%;
			margin: 20px auto 0;
		}
		th, td{
			font-size: 1.6rem;
			line-height: 2.4rem;
			color: #595950;
			border: 1px solid #d8d8d8;
			font-weight: bold;
			text-align: center;
			@include sp{
				font-size: 1.4rem;
				line-height: 1.4;
			}
		}
		th{
			background: #f8f8f8;
			padding: 9px 14px;
			@include sp{
				padding: 7px 14px 4px;
			}
		}
		td{
			padding: 9px 16px;
			@include sp{
				padding: 6px 5px 5px;
			}
			&.gray1{
				width: 210px;
				background: #f8f8f8;
				@include sp{
					width: calc(100% / 3);
				}
				&.gray2{
					background: #ddd;
				}
			}
			&.pink1{
				width: 416px;
				color: #fff;
				background: #fc819b;
				@include sp{
					width: calc(100% / 3);
				}
				span{
					color: #fc819b;
					background: #fff;
					font-size: 1.2rem;
					line-height: 1.8rem;
					border-radius: 4px;
					padding: 1px 10px;
					margin-left: 5px;
					@include sp {
						margin: 0;
					}
				}
			}
			&.pink2{
				background: #fff3f5;
				&.gray3{
					background: #eee;
				}
			}
			&.yellow1{
				width: 416px;
				color: #fff;
				background: #faad5a;
				@include sp{
					width: calc(100% / 3);
				}
				span{
					color: #faad5a;
					background: #fff;
					font-size: 1.2rem;
					line-height: 1.8rem;
					border-radius: 4px;
					padding: 1px 10px;
					margin-left: 5px;
					@include sp{
						padding: 0 10px;
						margin: 0;
					}
				}
			}
			&.yellow2{
				background: #fff7ef;
				&.gray3{
					background: #eee;
				}
			}

			.icon {
				display: inline-block;
				width: 19px;
				vertical-align: middle;
				margin: 0 0 0 2px;
			}
		}

		.pink--text {
			color: #ff5b4c;
		}
	}

	&__label{
		font-size: 1.4rem;
		line-height: 2rem;
		color: #fff;
		font-weight: bold;
		font-family: 'M PLUS Rounded 1c', sans-serif;
		border-radius: 4px;
		text-align: center;
		width: 160px;
		height: 80px;
		background: #f0662b;
		position: relative;
		bottom: -101px;
		left: 50%;
		transform: translateX(-50%);
		padding: 20px 0;
		z-index: 9;
		@include sp{
			font-size: 1rem;
			line-height: 1.5rem;
			max-width: 100px;
			width: 100%;
			height: 60px;
			bottom: -72px;
			padding: 15px 0;
		}
		&:after{
			content: '';
			border-style: solid;
			border-width: 20px 10px 0 0px;
			border-color: #f0662b transparent transparent transparent;
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translateX(-50%);
			@include sp{
				border-width: 10px 7px 0 0px;
				bottom: -10px;
			}
		}
		span{
			font-size: 2.5rem;
		}
	}

	&__note {
		font-size: 1.6rem;
		line-height: 1.5;
		color: #ff5b4c;
		font-weight: bold;
		position: relative;
		padding: 0 0 0 21px;
		margin: 35px 40px 0;
		@include sp {
			margin: 15px 0 0;
		}

		&::before {
			content: '';
			width: 19px;
			height: 15px;
			background: url('/img/hoken/lp/sandai_sippei/icon9.png') no-repeat center;
			background-size: cover;
			position: absolute;
			top: 4px;
			left: 0;
		}

		+ .p-hokenLpSippei__list1 {
			margin-top: 10px;
			@include sp {
				margin-top: 2px;
			}
		}
	}

	.p-hokenLpSippei__list2{
		border-top: 1px solid #d8d8d8;
		margin-top: 35px;
		padding: 35px 40px 0 43px;
		@include sp{
			margin-top: 17px;
			padding: 15px 3px 0 3px;
		}
	}
}
.p-hokenLpSippei5{
	background: #fff;
	padding: 82px 0 78px;
	@include sp{
		padding: 36px 0 42px;
	}

	.p-hokenLpSippei__ttl1{
		padding: 71px 0 43px;
		margin-bottom: 60px;
		@include sp{
			padding: 43px 0 22px;
			margin-bottom: 30px;
		}
	}
	&__q{
		font-size: 2rem;
		line-height: 3rem;
		font-weight: bold;
		color: #59493f;
		position: relative;
		padding-left: 30px;
		@include sp{
			font-size: 1.8rem;
			line-height: 2.7rem;
		}
		&:before{
			content: 'Q.';
			font-family: 'Roboto', sans-serif;
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: #22ac38;
			position: absolute;
			top: -3px;
			left: 0;
		}
	}
	&__a{
		font-size: 1.6rem;
		line-height: 2.8rem;
		color: #595950;
		position: relative;
		padding: 16px 0 0 30px;
		margin-top: 15px;
		border-top: 1px solid #d8d8d8;
		@include sp{
			padding: 14px 0 0 30px;
			margin-top: 14px;
		}
		&:before{
			content: 'A.';
			font-family: 'Roboto', sans-serif;
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: #ff5b4c;
			font-weight: bold;
			position: absolute;
			top: 12px;
			left: 0;
		}
		span{
			font-weight: bold;
		}
		a{
			color: #308bbb;
			text-decoration: underline;
			position: relative;
			margin-right: 37px;
			&:after{
				content: '';
				width: 13px;
				height: 13px;
				background: url('/img/hoken/lp/sandai_sippei/icon6.png') no-repeat center;
				background-size: cover;
				position: absolute;
				top: 4px;
				right: -18px;
			}
			&:hover{
				text-decoration: none;
			}
		}
	}
	&__txt1{
		border-radius: 10px;
		border: 5px solid #e6e4e2;
		padding: 30px 35px 28px;
		background: #fff;
		margin-bottom: 20px;
		@include sp{
			padding: 14px 20px 12px;
		}
	}
	&__txt2{
		border-radius: 10px;
		border: 5px solid #e6e4e2;
		padding: 30px 35px 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 60px;
		@include sp{
			display: block;
			padding: 0;
			margin-top: 30px;
		}
	}
	&__link1, &__link2{
		font-size: 1.6rem;
		line-height: 2.8rem;
		font-weight: bold;
		color: #595950;
		position: relative;
		padding: 0 20px 0 27px;
		margin: 0 38px;
		@include sp{
			width: 100%;
			display: block;
			margin: 0;
			padding: 13px 52px;
		}
		&:before{
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			@include sp{
				left: 22px;
			}
		}
		&:after{
			content: '';
			border-style: solid;
			border-width: 6px 0 6px 6px;
			border-color: transparent transparent transparent #8cb340;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include sp{
				right: 18px;
			}
		}
	}
	&__link1{
		@include sp{
			border-bottom: 1px solid #d8d8d8;
		}
		&:before{
			width: 18px;
			height: 22px;
			background: url('/img/hoken/lp/sandai_sippei/icon7.png') no-repeat center;
			background-size: cover;
		}
	}
	&__link2{
		padding: 0 20px 0 32px;
		@include sp{
			padding: 13px 52px;
		}
		&:before{
			width: 22px;
			height: 22px;
			background: url('/img/hoken/lp/sandai_sippei/icon8.png') no-repeat center;
			background-size: cover;
		}
	}
}
.p-hokenLpSippei6{
	background-image: -moz-linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
	background-image: -webkit-linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
	background-image: linear-gradient( 0deg, rgb(34,172,56) 0%, rgb(143,195,31) 100%);
	padding: 27px 0;

	@include sp{
		padding: 20px 0 17px;
	}
	.l-contentServiceIn{
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 40px;
		position: relative;
		@include sp{
			flex-wrap: wrap;
			padding: 0 10px;
		}
	}
	&__img1{
		margin: 10px 10px 0 30px;
		@include sp{
			order: 3;
			margin: 13px 0 0;
		}
		img{
			margin: 0 auto;
		}
	}
	&__img2{
		position: absolute;
		right: 24px;
		top: -48px;

		@include sp{
			bottom: auto;
			top: -44px;
			right: 18px;
			max-width: 180px;
			order: 2;
			margin: -10px -10px 0 0;
			width: 63%;
			z-index: 11;
		}
	}
	.p-hokenLpSippei__info{
		@include sp{
			width: 45%;
			margin-left: 10px;
		}
	}
}
.p-hokenLpSippei7{
	background: #f4f9e9;
	padding: 74px 0 76px;
	@include sp{
		padding: 35px 0 39px;
	}
	&__ttl1{
		font-size: 2rem;
		line-height: 3rem;
		color: #595950;
		position: relative;
		padding-left: 30px;
		margin-bottom: -8px;
		@include sp{
			margin-bottom: 11px;
		}
		&:before{
			content: '';
			background: url('/img/hoken/lp/sandai_sippei/icon9.png') no-repeat center;
			background-size: cover;
			width: 23px;
			height: 20px;
			position: absolute;
			top: 6px;
			left: 0;
		}
	}
	&__text1{
		font-size: 1.4rem;
		line-height: 2.34rem;
		color: #595950;
		margin: 31px 0 30px;
		@include sp{
			line-height: 2.45rem;
			margin: 9px 0 10px;
		}
	}
	.p-hokenLpSippei__list2{
		padding: 0 9px;
		@include sp{
			padding: 0 3px;
		}
		li{
			font-size: 1.4rem;
			line-height: 2.45rem;
		}
	}
	&__imgtxt1{
		display: flex;
		@include sp{
			display: block;
		}
	}
	&__txt1{
		width: 50%;
		padding: 0 40px;
		@include sp{
			width: 100%;
			padding: 0;
		}
	}
	&__img1{
		width: 50%;
		text-align: center;
		padding-top: 5px;
		@include sp{
			width: 100%;
			padding: 22px 7px 0;
		}
	}
	.kantan_web{
		border-radius: 10px;
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		background: #fff;
		padding: 55px 60px;
		margin-top: 60px;
		@include sp{
			padding: 14px 20px 20px;
			margin-top: 30px;
		}
		&__ttl1{
			font-weight:bold;
			font-size: 2rem;
			line-height: 3rem;
			color: #22ac38;
			border-bottom: #22ac38 solid 2px;
			padding-bottom: 4px;
			margin: 0 0 23px;
			@include sp{
				padding-bottom: 8px;
				margin: 0 0 14px;
			}
		}
		&__text1{
			font-size: 1.6rem;
			line-height: 2.8rem;
			color: #595950;
			margin-bottom: 8px;
		}
		&__text2{
			font-size: 1.6rem;
			line-height: 2.8rem;
			font-weight: bold;
			color: #595950;
			margin-bottom: 12px;
			span{
				color: #ff5b4c;
			}
		}
		.link01 {
			font-size: 1.6rem;
			line-height: 2.8rem;
			position: relative;
			padding-left: 20px;
			display: inline-block;
			margin-top: -2px;
			@include sp{
				padding-left: 16px;
			}

			a {
				color: #308bbb;
				text-decoration: underline;
			}

			&:before{
				content: '';
				border-style: solid;
				border-width: 7px 0 7px 7px;
				border-color: transparent transparent transparent #8cb340;
				position: absolute;
				top: 6px;
				left: 8px;
				@include sp{
					left: 3px;
				}
			}

			@include hover {
				a {
					text-decoration: none;
				}
			}
		}
		.txtbld {
			input{
				font-size: 1.6rem;
				line-height: 2.4rem;
				color: #333;
				border-radius: 4px;
				border: 2px solid #e6e4e2;
				width: 480px;
				height: 42px;
				padding: 9px 11px;
				@include sp{
					width: 100%;
				}
			}
			::placeholder{
				color: #999;
			}
		}
		.seikyo_select {
			margin: 24px 0 47px 0;
			display: flex;
			justify-content: space-between;
			@include sp{
				display: block;
				margin: 17px 0 18px 0;
			}
			li {
				margin: 0;
				font-size: 1.6rem;
				line-height: 2.4rem;
				color: #595950;
				@include sp{
					margin: 0 0 5px;
				}
			}
		}
		&__btn{
			font-size: 1.6rem;
			line-height: 60px;
			font-weight: bold;
			color: #fff;
			text-align: center;
			width: 288px;
			height: 60px;
			border-radius: 50px;
			background: #ff5b4c;
			border: 0;
			display: block;
			margin: 60px auto 25px;
			position: relative;
			@include sp{
				width: 100%;
				max-width: 292px;
				margin: 31px auto 15px;
			}

			a {
				line-height: 2.4rem;
			}

			&:after{
				content: '';
				border-style: solid;
				border-width: 7px 0 7px 7px;
				border-color: transparent transparent transparent #fff;
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
			}
		}

		.btn_default {
			.kantan_web__btn {
				background: #ccc;
			}
		}

		.alert02 {
			color: #ff5b4c;
			font-size: 1.6rem;
			line-height: 2.8rem;

			@include sp {
				text-align: center;
				font-size: 1.4rem;
			}

			img {
				margin-right: 10px;
			}
		}

		.radioBg {
			width: 320px;
			display: inline-block;
			overflow: hidden;
			border: 2px solid #e6e4e2;
			border-radius: 5px;
			box-sizing: border-box;
			padding: 8px 0 8px 37px;
			cursor: pointer;
			background: url('/img/hoken/lp/sandai_sippei/radio_off.png') no-repeat left 13px center;
			@include sp{
				width: 100%;
			}
		}
		input[type="radio"]:checked+label {
			background: url('/img/hoken/lp/sandai_sippei/radio_on.png') no-repeat left 13px center;
		}
		input[type="radio"]{
			display: none;
		}
		.column03 {
			border-radius: 4px;
			background: #f8f8f8;
			border: 1px solid #e6e4e2;
			padding: 25px 15px 31px;
			margin-top: 23px;
			@include sp{
				margin-top: 11px;
				padding: 14px 20px 21px 16px;
			}
		}
		.p-hokenLpSippei__list1{
			margin-top: 0;
		}
	}
	&__table1{
		border-collapse: collapse;
		border: 1px solid #d8d8d8;
		width: calc(100% - 80px);
		margin: 80px auto 0;
		@include sp{
			width: 100%;
			margin: 41px auto 0;
		}
		th, td{
			font-size: 1.4rem;
			line-height: 2.1rem;
			color: #595950;
			border: 1px solid #d8d8d8;
		}
		th{
			font-weight: bold;
			background: #f8f8f8;
			text-align: left;
			padding: 15px 20px;
			width: 208px;
			vertical-align: top;
			@include sp{
				padding: 5px 9px 4px;
				width: 33.5%;
			}
		}
		td{
			background: #fff;
			padding: 15px 20px;
			@include sp{
				padding: 5px 9px 4px;
			}
			p{
				margin-bottom: 10px;
				@include sp{
					margin-bottom: 5px;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
			span{
				font-weight: bold;
				display: block;
			}
		}
	}
	.p-hokenLpSippei3__text2 {
		margin: 40px 0 0;

		@include sp {
			font-size: 1.3rem;
			margin-top: 20px;
		}
	}
}
