@use '../../0_base' as *;

/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート）
------------------------------------------------------------*/
.p-ticketCart {
	padding: 50px 0 135px 0;
	background-color: #ffffff;
}

//見出し
.p-ticketCart01 {
	margin-bottom: 30px;
	.c-formTitle {
		color: $ticketNaviColor02;
	}
}

//カートの中
.p-ticketCart02 {
	margin-bottom: 50px;
	&__txt {
		margin-bottom: 20px;
		font-size: 1.6rem;
		color: $ticketNaviColor02;
		&__annotation {
			font-size: 1.4rem;
		}
	}
	//コンポーネント　c-tbl01
	.c-tbl01__total .total-number span {
		color: #232934;
	}
	.c-error {
		margin-bottom: 20px;
		font-weight: normal;
		@include sp {
			margin-bottom: 10px;
		}
		span {
			display: block;
			font-weight: bold;
			font-size: 1.6rem;
			@include sp {
				font-size: 1.3rem;
			}
		}
	}
}

//お申し込み前のご確認事項
.p-ticketCart03 {
	.p-ticketCart03__content01 {
		dl {
			dt {
				margin-bottom: 13px;
				font-size: 2.0rem;
				font-weight: bold;
				color: $ticketNaviColor02;
				&:before {
					width: 10px;
					height: 10px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $subColor08;
					display: inline-block;
					vertical-align: middle;
					@include radius(50%);
				}

			}
			dd {
				margin-bottom: 20px;
				font-size: 1.6rem;
				line-height: 1.75;
				color: $ticketNaviColor02;
				a {
					color: $linkColor01;
					text-decoration: underline;
					&:hover {
						opacity: 1;
						text-decoration: none;
					}
				}
				&:first-of-type p {
					width: 800px;
				}
			}
		}
	}
	// .p-ticketCart03__content02 {
	// 	//コンポーネント　c-cartBtn
	// }

	// &__gotoContents {
	// 	dd {
	// 		p {
	// 			margin-bottom: 20px;
	// 		}
	// 	}

	// 	.u-list {
	// 		li {
	// 			display: flex;
	// 		}
	// 	}
	// }
}


@include sp {
	.p-ticketCart {
		padding: 28px 20px 38px 20px;
	}

	//見出し sp
	.p-ticketCart01 {
		margin-bottom: 17px;
		.c-formTitle {
			margin-bottom: 15px;
		}
	}

	//カートの中 sp
	.p-ticketCart02 {
		margin-bottom: 24px;
		&__txt {
			margin-bottom: 10px;
			font-size: 1.4rem;
			line-height: 1.4;
			&__annotation {
				display: block;
				margin-top: 9px;
				font-size: 1.1rem;
				font-weight: bold;
			}
		}
		//コンポーネント　c-tbl01
	}

	//お申し込み前のご確認事項 sp
	.p-ticketCart03 {
		.p-ticketCart03__content01 {
			dl {
				dt {
					margin-bottom: 0;
					font-size: 1.4rem;
					&:before {
						width: 8px;
						height: 8px;
						margin-right: 4px;
					}
				}
				dd {
					margin-bottom: 15px;
					font-size: 1.4rem;
					line-height: 1.5;
					p {
						width: 100%;
						margin-bottom: 12px;
					}
					img {
						margin: 0 auto;
						display: block;
					}
					&:first-of-type p {
						width: 100%;
					}
				}
			}
			.c-ticketTitle03 {
				margin-bottom: 12px;
			}
		}
		.p-ticketCart03__content02 {
			//コンポーネント　c-cartBtn
			.c-cartBtn {
				margin-top: 37px;
			}
		}
	}
}
/*
------------------------------------------------------------*/
