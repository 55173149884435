@use '../0_base' as *;

/*------------------------------------------------------------
footer(共通)
------------------------------------------------------------*/
.c-pagetop {
	width: 60px;
	position: fixed;
	bottom: 60px;
	right: 60px;
	z-index: 9000;
}
.c-footBnr {
	background-color: #ffffff;
	border-top: dashed 1px $glaylight01;
	border-bottom: dashed 1px $glaylight01;
	ul {
		overflow: hidden;
		border: solid 2px $keycolor02;
		@include radius(4px);
		@include dib;
		li {
			width: 20%;
			height: 56px;
			vertical-align: top;
			border-left: solid 2px $keycolor02;
			&:first-child {
				border: none;
			}
			a {
				width: 100%;
				height: 100%;
				display: block;
				position: relative;
				img {
					margin: auto;
					vertical-align: bottom;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					-webkit-transform: scale(0.8);
					transform: scale(0.8);
				}
				&:after {
					width: 14px;
					height: 14px;
					content: '';
					background-image: url("../img/common/icon_link01.png");
					-webkit-background-size: cover;
					background-size: cover;
					position: absolute;
					top: 5px;
					right: 5px;
				}
				&:hover {
					opacity: 0.50;
				}
			}
		}
	}
}
.c-footLink {
	padding: 19px 0 17px;
	background-color: #ffffff;
	border-bottom: dashed 1px $glaylight01;
	ul {
		text-align: center;
		@include dib;
		li {
			font-size: 1.4rem;
			border-right: solid 1px $gray03;
			&:last-child {
				border: none;
			}
			a {
				padding: 0 20px;
				color: $gray03;
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
		}
	}
}
.c-footCopyright {
	padding: 10px 0;
	font-size: 1.3rem;
	text-align: center;
	color: $gray02;
	background-color: $glaylight02;
}


@include sp {
	.c-pagetop {
		width: 40px;
		height: 40px;
		margin: 0 5px 5px auto;
		position: static;
	}
	.c-footBnr {
		border: none;
		ul {
			@include radius(0);
			li {
				width: 33.3333%;
				height: 38px;
				&:first-child {
					width: 50%;
					border-left: none;
					border-bottom: solid 2px $keycolor02;
				}
				&:nth-child(2) {
					width: 50%;
					border-bottom: solid 2px $keycolor02;
				}
				&:nth-child(3) {
					border: none;
				}
				a {
					img {
						max-width: 80%;
						transform:none;
					}
					&:after {
						width: 7px;
						height: 7px;
					}
				}
			}
		}
	}
	.c-footLink {
		padding: 0;
		border: none;
		ul {
			width: 100%;
			@include clear;
			li {
				width: 50%;
				float: left;
				display: block;
				font-size: 1.1rem;
				border: none;
				a {
					width: 100%;
					height: 100%;
					padding: 6px 0;
					display: block;
					border-bottom: solid 1px $glaylight01;
					border-right: solid 1px $glaylight01;
				}
				&:nth-child(2n+1) {
					a {
						border-right: none;
					}
				}
				&:nth-child(1) {
					width: 100%;
				}
			}
		}
	}
	.c-footCopyright {
		font-size: 0.8rem;
		padding: 7px 0;
    	letter-spacing: 0.1px;
		a {
			img {
				max-width: 44%;
				margin-bottom: 4px;
			}
		}
	}

}

.c-footBnr2 {
    @include sp {
        margin-left: auto;
        margin-right: auto;
    }

    ul {
        @include sp {
            display: grid;
            gap: 5px;
            grid-template-columns: repeat(12, 1fr);

            li:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 7;
            }

            li:nth-child(2) {
                grid-column-start: 7;
                grid-column-end: 13;
            }

            li:nth-child(3) {
                grid-column-start: 1;
                grid-column-end: 5;
            }

            li:nth-child(4) {
                grid-column-start: 5;
                grid-column-end: 9;
            }

            li:nth-child(5) {
                grid-column-start: 9;
                grid-column-end: 13;
            }
        }

        @include pc {
            display: grid;
            column-gap: 5px;
            grid-template-columns: repeat(5, 1fr);
        }
    }

    .c-footBnr2__item {
        padding-right: 20px;
        width: 100%;
        height: 50px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 2px $keycolor02;
        position: relative;
        @include pc {
            height: 80px;
        }
    }

    .c-footBnr2__newWindow {
        position: absolute;
        top: 10px;
        right: 10px;
        @include pc {
            width: 20px;
            height: 20px;
        }
    }
}

.c-footLink2 {
    margin-top: 10px;

    @include pc {
        margin-top: 20px;
    }

    ul {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        @include pc {
            column-gap: 40px;
        }

        li {
            &:not(:last-child) {
                padding-right: 10px;
                border-right: 1.5px solid #000;
                @include pc {
                    padding-right: 40px;
                    border-right-width: 3px;
                    border-right-color: #D9D9D9;
                }
            }
        }

        a {
            color: #222;
            font-size: 1rem;
            @include pc {
                font-size: 1.6rem;
            }
        }
    }
}

.c-footCopyright2 {
    margin-top: 10px;
    font-size: 1rem;
    text-align: center;
    @include pc {
        margin-top: 20px;
        font-size: 1.2rem;
    }
}

/*
------------------------------------------------------------*/
