@use '../../0_base' as *;

/*------------------------------------------------------------
チケット 商品詳細
------------------------------------------------------------*/
.p-ticketServiceDetail {
	background-color: #ffffff;
}

//企画名
.p-ticketServiceDetail01 {
	margin-bottom: 39px;
	font-size: 1.6rem;
	line-height: 1.75;
	color: $ticketNaviColor02;
	&__content01 {
		&__title {
			margin-bottom: 19px;
			padding-top: 40px;
			font-weight: bold;
			background-image: url("../img/ticket/line01.png");
			background-repeat: repeat-x;
			background-position: 0 0;
			h1 {
				font-size: 2.8rem;
			}
			p {
				font-size: 2.0rem;
				letter-spacing: 1px;
				line-height: 1.8;
			}
		}
		&__tags {
			margin-bottom: 38px;
			padding: 0 13px;
			display: inline-block;
			color: #ffffff;
			font-size: 1.3rem;
			font-weight: bold;
			&--osusume {
				background-color: $subColor03;
			}
			&--ninki {
				background-color: $subColor16;
			}
		}
	}
	&__content02 {
		margin-bottom: 72px;
		&__slider {
			width: 710px;
			height: 100%;
			margin: 0 auto;
			img {
				width: auto;
				height: auto;
				max-height: 333px;
				margin: 0 auto;
			}
			.slick-slide {
				height: 100%;
			}
			.slick-track{
				display: flex;
				align-items: center;
			}
			.slick-arrow {
				width: 11px;
				height: 19px;
				margin-top: -10px;
				&:before {
					content: none;
				}
				&.slick-prev {
					background-image: url("../img/common/icon_arrow-green02.png") !important;
					-webkit-background-size: cover !important;
					background-size: cover !important;
					opacity: 1 !important;
					z-index: 10;
					left: -63px;
					transform: rotate(180deg);
				}
				&.slick-next {
					background-image: url("../img/common/icon_arrow-green02.png") !important;
					-webkit-background-size: cover !important;
					background-size: cover !important;
					opacity: 1 !important;
					z-index: 10;
					right: -66px;
					transform: none;
				}
			}
			.slick-dots {
				width: 60%;
				margin: 0 auto;
				left: 0;
				right: 0;
				bottom: -35px;
				li {
					width: 12px;
					height: 12px;
					margin: 0 5px;
					padding: 1px;
					background-color: #ffffff;
					border: solid 1px $glaylight01;
					@include radius(50%);
					button {
						width: 8px;
						height: 8px;
						padding: 0;
						background-color: #ffffff !important;
						@include radius(50%);
						&:before {
							content: none;
						}
					}
					&.slick-active {
						button {
							background-color: $subColor08 !important;
						}
					}
				}
			}
		}
	}
	&__content03 {
		margin-bottom: 36px;
		height: 100%;
		iframe {
			width: 710px;
			height: 333px;
			margin: 0 auto;
			display: block;
		}
	}
	&__content04 {
		margin-bottom: 60px;
		> p {
			margin-bottom: 23px;
		}
		&__attention {
			padding: 26px 30px 23px;
			font-size: 1.3rem;
			background-color: $glaylight10;
		}
		.c-ticketTitle02b {
			margin-bottom: 24px;
		}
	}
	&__content05 {
		margin-bottom: 36px;
		.c-table2 {
			width: 840px;
			margin-bottom: 17px;
			border-color: $glaylight01;
			font-size: 1.4rem;
			dt {
				width: 240px;
				background-color: $ticketNaviColor01;
			}
			dd {
				width: calc(100% - 240px);
			}
			a {
				color: $linkColor01;
				text-decoration: underline;
				&:hover{
					opacity: 1;
					text-decoration: none;
				}
			}
		}
		&__annotation {
			font-size: 1.3rem;
		}
	}
	&__content06 {
		margin-bottom: 40px;
		.c-tbl01 {
			width: 840px;
			margin-bottom: 20px;
			color: $ticketNaviColor02;
			th {
				padding: 10px 10px 9px;
				white-space: nowrap;
			}
			td {
				padding: 17px 10px 15px;

			}
			&__title{
				background: $ticketNaviColor01;
			}
			.c-tbl01__item{
				.col-date{
					min-width: 140px;
				}
				.col-time{
					min-width: 140px;
				}
				.col-locate{
					min-width: 80px;
				}
				.col-type{
					min-width: 80px;
				}
				.col-note{
					min-width: 90px;
				}
				.col-price{
					min-width: 100px;
				}
				.col-num{
					white-space: nowrap;
					.c-error {
						text-align: center;
						@include sp {
							text-align: left;
						}
					}
					select{
						width: 100%;
						height: 40px;
						padding: 0 7px;
						font-weight: bold;
						-webkit-appearance: none;
					    -webkit-border-radius: 0px;
					    appearance: none;
					    outline-width: 0;
						option{
							color: #000;
						}
					}
					select::-ms-expand {
    					display: none;
					}
					.selectbox{
						min-width: 63px;
						width: 80%;
						display: inline-flex;
						position: relative;
						&:after{
							content: "";
							width: 0;
						    height: 0;
						    border-left: 5px solid transparent;
						    border-right:5px solid transparent;
						    border-top: 5px solid #111;
						    position: absolute;
						    top: 50%;
						    right: 5px;
						    transform: translate(0, -50%);
						}
						@include sp{
							width: 150px;
							&:after{
								 right: 13px;
							}
						}
					}
				}
			}
		}
		.c-error {
			margin-top: 0;
			&__box {
				display: block;
				text-align: right;
				@include sp {
					text-align: center;
				}
			}
		}

		.c-cartBtn {
			margin-top: 0;
			display: block;
			@include clear;
			&__btn02b {
				width: 200px;
				height: 52px;
			}
		}
	}
}

//お申し込み前のご確認事項
.p-ticketServiceDetail02 {
	margin-bottom: 53px;
	font-size: 1.6rem;
	line-height: 1.75;
	color: $ticketNaviColor02;
	dl {
		padding: 26px 38px 29px;
		border: solid 5px $ticketNaviColor01;
		dt {
			margin-bottom: 15px;
			font-size: 2.0rem;
			font-weight: bold;
		}
		dd {
			ul {
				margin-bottom: 10px;
				font-size: 1.3rem;
			}
			a {
				font-size: 1.5rem;
				color: $linkColor01;
				text-decoration: underline;
				&:before {
					width: 6px;
					height: 10px;
					margin: -3px 10px 0 0;
					content: '';
					background-image: url("../img/common/icon_arrow-pink01.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
				&:hover{
					opacity: 1;
					text-decoration: none;
				}
			}
		}
	}
}

//関連商品
.p-ticketServiceDetail03 {
	margin-bottom: 96px;
	&__content01 {
		//コンポーネント　c-tiketItems02
		.c-ticketTitle03 {
			margin-bottom: 30px;
		}
		.c-tiketItems02__item {
			margin-right: 30px;
			@include sp {
				margin-right: 2%;
			}
			&:nth-of-type(3n) {
				margin-right: 0;
				@include sp {
					margin-right: 2%;
				}
			}
			&:nth-of-type(even) {
				@include sp {
					margin-right: 0;
				}
			}
			&__tags--osusume, &__tags--ninki{
				top: 5px;
			}
			a {
				ul {
					padding-bottom: 15px;
				}
				p {
					margin-bottom: 4px;
				}
			}
			&__subCate {
				padding: 0 12px;
				margin-bottom: 5px;
			}
		}
	}
	&__content02 {
		//ページャー
	}
}

@include sp {
	.p-ticketServiceDetail {
		padding-top: 30px;
	}
	//企画名 sp
	.p-ticketServiceDetail01 {
		margin-bottom: 20px;
		font-size-adjust: 1.4rem;
		&__content01 {
			padding: 0 20px;
			&__title {
				margin-bottom: 3px;
				padding-top: 29px;
				background-image: url("../img/ticket/line01_sp.png");
				-webkit-background-size: 12px;
				background-size: 12px;
				h1 {
					font-size: 1.8rem;
					line-height: 1.6;
				}
				p {
					font-size: 1.4rem;
					line-height: 1.5;
				}
			}
			&__tags {
				margin-bottom: 20px;
				font-size: 0.8rem;
				padding: 0 9px;
			}
		}
		&__content02 {
			margin-bottom: 45px;
			&__slider {
				width: 100%;
				img {
					max-height: 166px;
				}
				.slick-arrow {
					margin: 0;
					width: 8px;
					height: 13px;
					&.slick-prev {
						top: auto;
						bottom: -23px;
						left: 35%;
					}
					&.slick-next {
						top: auto;
						bottom: -23px;
						right: 35%;
					}
				}
				.slick-dots {
					bottom: -26px;
					li {
						width: 10px;
						height: 10px;
						margin: 0 3px;
						button {
							width: 6px;
							height: 6px;
						}
					}
				}
			}
		}
		&__content03 {
			margin-bottom: 25px;
			iframe {
				max-width: 100%;
				height: 100%;
			}
		}
		&__content04 {
			margin-bottom: 41px;
			padding: 0 20px;
			> p {
				margin-bottom: 15px;
				font-size: 1.4rem;
				line-height: 1.6;
			}
			&__attention {
				padding: 16px 17px 13px;
				font-size: 1.1rem;
				font-weight: bold;
				line-height: 1.6;
			}
			.c-ticketTitle02b {
				margin-bottom: 16px;
				font-size: 1.4rem;
				background-size: 6px;
				em:after {
					height: 2px;
				}
			}
		}
		&__content05 {
			margin-bottom: 20px;
			padding: 0 20px;
			.c-table2 {
				margin-bottom: 20px;
				dl {
					border-color: $glaylight01;
				}
			}
			&__annotation {
				font-size: 1.1rem;
			}
		}
		&__content06 {
			margin-bottom: 20px;
			padding: 0 20px;
			.c-tbl01 {
				color: $ticketNaviColor02;
				.c-tbl01__item{
					.col-num{
						select{
							width: 150px;
							margin-right: 5px;
							@include radius(5px);
						}
					}
					.col-title{
						background-color: #fef2f5;
						padding: 11px 10px 10px 11px;
					}
				}
			}
			.c-cartBtn {
				text-align: center;
				&__btn02b {
					width: 100%;
					height: 50px;
					max-width: 160px;
				}
			}
			.c-cartBtn__btn02b {
				font-size: 1.3rem;
			}
		}
	}
	//お申し込み前のご確認事項 sp
	.p-ticketServiceDetail02 {
		margin-bottom: 35px;
		padding: 0 20px;
		font-size: 1.4rem;
		dl {
			padding: 8px 15px 9px ;
			dt {
				margin-bottom: 3px;
				font-size: 1.8rem;
				text-align: center;
			}
			dd {
				ul {
					margin-bottom: 5px;
					font-size: 1.1rem;
					font-weight: bold;
					line-height: 1.7;
				}
				a {
					font-size: 1.2rem;
				}
			}
		}
	}
	//関連商品 sp
	.p-ticketServiceDetail03 {
		margin-bottom: 48px;
		padding: 0 20px;
		&__content01 {
			//コンポーネント　c-tiketItems02
			.c-ticketTitle03 {
				margin-bottom: 20px;
			}
			.c-tiketItems02__item {
				a {
					ul {
						padding-bottom: 10px;
					}
				}
				&__subCate {
					padding: 0 8px;
					margin-bottom: 6px;
				}
			}
		}
		&__content02 {
			//ページャー
		}
	}

}

/*
------------------------------------------------------------*/
