@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　グリーフ(悲嘆)とは
------------------------------------------------------------*/
.p-sosaiGrief {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//グリーフ(悲嘆)とは
.p-sosaiGrief01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//グリーフ(悲嘆)とは 本文
.p-sosaiGrief02 {
	margin-bottom: 140px;
	padding-top: 25px;
	p{
		line-height: 3rem;
	}
	&__content01 {
		margin-bottom: 92px;
		img {
			margin-left: 40px;
			margin-top: 8px;
		}		
	}
	&__content02 {
		margin-bottom: 95px;
		img {
			margin-right: 40px;
			margin-top: 8px;
		}
	}
	&__content03 {
		> p {
			margin-bottom: 33px;
			font-size: 2.2rem;
			line-height: 3.2rem;
			font-weight: 500;
			@include mincyo;
		}
		.c-sosaiTextBox01{
			> div{
				padding: 31px 35px 30px;
				&:not(:first-of-type) {
					padding: 28px 35px 28px;
				}
			}
		}
		&__txt {			
			font-size: 1.6rem;			
			font-weight: 700;
			background-color: $glaylight12;
			text-align: center;
			p{
				line-height: 2.8rem;
			}
		}
		&__tel {			
			a{
				display: block;
				text-align: center;
				font-size: 3.6rem;
				line-height: 1;
				color: #111;
				font-weight: normal;
				@include mincyo;
				> div{
					position: relative;
					&:before {
						width: 26px;
						height: 26px;
						margin: -10px 0 0 0;
						content: '';
						background-image: url("../img/common/icon_tel06.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
				span{
					display: block;
					font-size: 1.6rem;
					line-height: 1;
					margin-top: 9px;
					padding-left: 10px;
				}
			}
		}
	}
}


@include sp {
	.p-sosaiGrief {
		font-size: 1.4rem;
	}
	//グリーフ(悲嘆)とは sp
	.p-sosaiGrief01 {
		min-width: 100%;
		margin-bottom: 42px;
		padding: 32px 0 33px;
	}
	
	//グリーフ(悲嘆)とは 本文　sp
	.p-sosaiGrief02 {
		margin-bottom: 61px;
		padding: 0 15px;
		p{
			font-size: 1.4rem;
			line-height: 1.7;
			padding: 0 7px;
		}
		&__content01 {
			margin-bottom: 36px;
			img {
				margin: 0 auto 27px auto;
				display: block;
				float: none;
			}
		}
		&__content02 {
			margin-bottom: 29px;
			img {
				margin: 0 auto 27px auto;
				display: block;
				float: none;
			}
		}
		&__content03 {
			> p {
				margin-bottom: 24px;
				font-size: 1.8rem;
				line-height: 2.8rem;
				text-align: left;
				padding: 0 8px;
			}
			.c-sosaiTextBox01{
				> div{
					padding: 18px 5px 13px;
					&:not(:first-of-type) {
						padding: 16px 14px;
					}
				}
			}
			&__txt {				
				text-align: center;
				font-weight: bold;
				p{
					font-size: 1.2rem;
					line-height: 1.8rem;
					padding: 0;
				}
			}
			&__tel {
				a {
					width: 100%;
					padding: 9px 0 8px;
					font-size: 1.8rem;
					font-weight: bold;
					color: #ffffff;
					background-color: #2e95a3;
					border-radius: 3px;
					text-align: left;
					@include yu_gosick;
					letter-spacing: 0.2px;
					box-shadow: 0 3px 0 #20656e;
					> div{
						width: 210px;
						margin: 0 auto;
						padding-left: 44px;
						&:before {
							width: 25px;
							height: 25px;
							content: '';
							background-image: url("../img/common/icon_tel07.png");
							-webkit-background-size: cover;
							background-size: cover;
							position: absolute;
							top: 19px;
							left: 5px;
						}
					}
					span {
					    font-size: 1rem;
					    line-height: 1.3rem;
					    display: block;
					    padding-left: 0;
					    margin-top: -1px;
					    letter-spacing: 0;
					}
				}
			}
		}
	}
	
}

/*
------------------------------------------------------------*/
