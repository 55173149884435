@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス　商品申込み・お問い合わせフォーム（カート）　完了
------------------------------------------------------------*/
.p-kurashiCartComplete {
	padding: 30px 0 0 0;
	background-color: #ffffff;
}

//見出し
.p-kurashiCartComplete01 {
	margin: 33px 0 40px;
	.c-formTitle {
		color: $kurashiTextColor04;
	}
}

//本文
.p-kurashiCartComplete02 {
	margin-bottom: 102px;
	//コンポーネント　c-formComplete
	.c-formComplete {
		color: $kurashiTextColor01;
	}
}

@include sp {
	.p-kurashiCartComplete {
		padding: 0 20px;
	}
	
	//見出し　ｓｐ
	.p-kurashiCartComplete01 {
		margin: 30px 0;
		.c-formTitle {
			margin-bottom: 20px;
		}
	}
	
	//本文　ｓｐ
	.p-kurashiCartComplete02 {
		margin-bottom: 40px;
		//コンポーネント　c-formComplete
	}
}
/*
------------------------------------------------------------*/
