@use '../0_base' as *;

/*------------------------------------------------------------
header(共通)
------------------------------------------------------------*/
.c-headSearchBoxPc {
	width: 200px;
	margin-left: 13px;
	position: relative;
	form.gsc-search-box{
		font-size: 1.4rem;
		line-height: 2;
	}
	.gsc-input-box{
		width: 100%;
		height: 30px;
		padding: 0 30px 0 10px;
		border: solid 1px $glaylight01;
		@include radius(4px);
	}
	.gsc-search-button-v2{
		width: 18px;
		height: 18px;
		padding: 0;
		font-size: initial;
		display: block;
		border: none;
		background: none;
		background-image: url("../img/common/icon_search01.png");
		-webkit-background-size: contain;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: 6px;
		right: 6px;
		@include ct;
		&:hover{
			background-color: unset !important;
			border-color: unset !important;
			background: none;
			background-image: url("../img/common/icon_search01.png");
			-webkit-background-size: contain;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	.gsib_a {
	    padding: 0 6px 0;
	}
	.gsc-input{
		height: 28px;
		padding-right: 0 !important;
	}
	&.c-headSearchBoxPc--2 {
		width: 245px;
		.gsc-input-box{
			@include radius(15px);
		}
		.gsc-search-button-v2{
			width: 16px;
			height: 16px;
			top: 7px;
			right: 15px;
		}
	}
}
.c-headSearchBoxBtnPc {
	width: 40px;
	height: calc(100% + 10px);
	margin: -10px 0 0 0;
	padding-top: 10px;
	position: relative;
	z-index: 9999;
	.btn-search {
		width: 30px;
		height: 30px;
		margin: 0 auto;
		display: block;
		background-color: #ffffff;
		background-image: url("../img/common/icon_search01.png");
		-webkit-background-size: 14px;
		background-size: 14px;
		background-repeat: no-repeat;
		background-position: center center;
		border: solid 2px $glaylight06;
		@include radius(50%);
	}
	.c-headSearchBoxPc {
		display: none;
	}
	&.c-headSearchBoxBtnPc--1 {
		.c-headSearchBoxPc {
			position: absolute;
			top: 10px;
			right: 0;
			z-index: 99999;
		}
		.c-overlay02 {
			display: none;
		}
	}
	&.c-headSearchBoxBtnPc--2 {
		&:hover {
			background-color: $glaylight06;
			.c-headSearchBoxPc {
				display: block;
			}
		}
	}
}

.c-headCateNavi {
	margin-left: 2.3%;
	li {
		height: 50px;
		padding: 0 28px 0 20px;
		margin-top: -10px;
		font-size: 1.5rem;
		line-height: 3.33;
		font-weight: bold;
		position: relative;
		transition: 0.3s ease-in-out;
		&:after {
			width: 1px;
			height: 10px;
			content: '';
			background-color: $glaylight06;
			position: absolute;
			top: calc(50% - 5px);
			left: 0;
		}
		&:nth-child(1) {
			&:after {
				content: none;
			}
			&:hover, &.is-curent {
				background-color: $ticketColor01;
				+ li {
					&:after {
						content: none;
					}
				}
				.c-cateIcon01--ticket {
					background-color: #ffffff;
					background-image: url("../img/common/icon_ticket02.png");
					+ a {
						color: #ffffff;
					}
				}
			}
		}
		&:nth-child(2) {
			&:hover, &.is-curent {
				background-color: $hokenColor01;
				&:after {
					content: none;
				}
				+ li {
					&:after {
						content: none;
					}
				}
				.c-cateIcon01--hoken {
					background-color: #ffffff;
					background-image: url("../img/common/icon_hoken02.png");
					+ a {
						color: #ffffff;
					}
				}
			}
		}
		&:nth-child(3) {
			padding: 0 19px 0 21px;
			&:hover, &.is-curent {
				background-color: $reformColor01;
				&:after {
					content: none;
				}
				+ li {
					&:after {
						content: none;
					}
				}
				.c-cateIcon01--reform {
					background-color: #ffffff;
					background-image: url("../img/common/icon_reform02.png");
					+ a {
						color: #ffffff;
					}
				}
			}
		}
		&:nth-child(4) {
			padding: 0 18px 0 21px;
			&:hover, &.is-curent {
				background-color: $kurashiColor01;
				&:after {
					content: none;
				}
				+ li {
					&:after {
						content: none;
					}
				}
				.c-cateIcon01--kurashi {
					background-color: #ffffff;
					background-image: url("../img/common/icon_kurashi02.png");
					+ a {
						color: #ffffff;
					}
				}
			}
		}
		&:nth-child(5) {
			&:hover, &.is-curent {
				background-color: $sosaiColor01;
				&:after {
					content: none;
				}
				.c-cateIcon01--sosai {
					background-color: #ffffff;
					background-image: url("../img/common/icon_sosai02.png");
					+ a {
						color: #ffffff;
					}
				}
			}
		}
		.c-cateIcon01 {
			width: 28px;
			height: 28px;
			margin: -1px 10px 0 0;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
@media screen and (max-width: 1560px) {
	.c-headRight {
		display: none;
	}
}

.c-headCateTitle {
	height: 120px;
	padding-top: 70px;
	font-size: 2.4rem;
	.c-cateIcon01 {
		width: 40px;
		height: 40px;
		margin: -6px 17px 0 0;
		display: inline-block;
		vertical-align: middle;
	}
	strong {
		font-weight: bold;
	}
}

.c-headCateTitleBg {
	width: 100%;
	min-width: 1120px;
	height: 70px;
	margin: 0 auto;
	background-color: #ffffff;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	z-index: 998;
}





@include sp {
	header {
		min-width: 320px;
		.c-header {
			padding-top: 20px;
		}
	}
	.c-headSearchBoxSp {
		width: 100%;
		padding: 10px 20px;
		background-color: $keycolor01;
		position: relative;
		.gsc-input-box {
			width: 100%;
			height: 30px;
			padding: 0 30px 0 10px;
			font-size: 1.4rem;
			line-height: 2;
			@include radius(4px);
			border: solid 1px $glaylight01;
		}
		.gsc-search-button-v2 {
			width: 18px;
			height: 22px;
			padding: 0;
			font-size: initial;
			display: block;
			border: none;
			background: none;
			background-image: url("../img/common/icon_search01.png");
			-webkit-background-size: contain;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			position: absolute;
			top: 15px;
			right: 35px;
			z-index: 10;
			border-radius: 2px;
			@include ct;
			&:hover{
				background-color: unset !important;
				border-color: unset !important;
				background: none;
				background-image: url("../img/common/icon_search01.png");
				-webkit-background-size: contain;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
		.gsib_a {
		    padding: 0 6px 0;
		}
		.gsst_a {
	    	padding-top: 7px;
		}
		.gsc-input{
			height: 28px;
			padding-right: 0 !important;
		}
		input[name="q"] {
			background-position: 10px center !important;
			padding: 0 30px 0 10px !important;
			border: none !important;
		}
	}

	.c-headCateTitleSp {
		height: 50px;
		padding: 14px 8px 0 10px;
		font-size: 1.6rem;
		position: absolute;
		top: 0;
		right: 50px;
		.c-cateIcon01 {
			width: 28px;
			height: 28px;
			margin: -5px 5px 0 0;
			display: inline-block;
			vertical-align: middle;
		}
		strong {
			font-weight: bold;
		}
		.c-cateIcon01--ticket {
			background-color: #ffffff !important;
			background-image: url("../img/common/icon_ticket02.png") !important;
			+ strong {
				color: #ffffff !important;
			}
		}
		.c-cateIcon01--hoken {
			margin: -5px 9px 0 0;
			background-color: #ffffff !important;
			background-image: url("../img/common/icon_hoken02.png") !important;
			+ strong {
				color: #ffffff !important;
			}
		}
		.c-cateIcon01--reform {
			background-color: #ffffff !important;
			background-image: url("../img/common/icon_reform02.png") !important;
			+ strong {
				color: #ffffff !important;
			}
		}
		.c-cateIcon01--kurashi {
			background-color: #ffffff !important;
			background-image: url("../img/common/icon_kurashi02.png") !important;
			+ strong {
				color: #ffffff !important;
			}
		}
		.c-cateIcon01--sosai {
			background-color: #ffffff !important;
			background-image: url("../img/common/icon_sosai02.png") !important;
			+ strong {
				color: #ffffff !important;
			}
		}
	}
}


/*
------------------------------------------------------------*/
