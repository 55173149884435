@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス お掃除カレンダー
------------------------------------------------------------*/
.p-kurashiCalendar {
	background-color: #ffffff;
}

//今月おすすめのお掃除
.p-kurashiCalendar01 {
	font-size: 1.6rem;
	line-height: 1.75;
	color: $kurashiTextColor01;
	> p {
		margin-bottom: 10px;
		&:last-of-type {
			margin-bottom: 35px;
		}
	}
	> ul {
		margin-bottom: 10px;
	}
	&__content01 {
		margin-bottom: 30px;
		overflow: hidden;
		&__calendar {
			width: 225px;
			margin-bottom: 22px;
			padding-top: 7px;
		}
		&__txt01 {
			width: 615px;
			margin-right: 0;
			margin-left: auto;
		}
		&__box {
			width: 615px;
			h3 {
				margin-bottom: 11px;
				font-weight: bold;
				color: $kurashiTextColor04;
				&:before {
					width: 12px;
					height: 12px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $kurashiColor01;
					display: inline-block;
					vertical-align: middle;
					@include radius(4px);
				}
			}
			ol {
				padding: 20px 25px;
				border: solid 5px $subColor14;
			}
		}
	}
	.c-kurashiTitle04 {
	    margin-bottom: 23px;
	}
	&__content02 {
		&__calendarList {
			padding-top: 7px;
			.p-kurashiCalendarBox {
				margin: 0 20px 23px 0;
				&:nth-child(4n) {
					margin: 0 0 23px 0;
				}
			}
		}
	}
}

//お掃除カレンダーボックス
.p-kurashiCalendarBox {
	width: 195px;
	overflow: hidden;
	@include radius(15px);
	&__month {
		height: 30px;
		margin-bottom: 5px;
		text-align: center;
		font-size: 1.4rem;
		font-weight: bold;
		color: #ffffff;
		background-color: $kurashiColor01;
		display: flex;
    	align-items: center;
    	justify-content: center;
		@include radius(15px);
	}
	img {
		width: 100%;
		margin: 0;
		display: block;
		vertical-align: bottom;
	}
	&__txt {
		position: relative;
		h3 {
			margin-bottom: 2px;
			padding: 10px 0;
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 1.2;
			text-align: center;
			background-image: url("../img/kurashi/line02.png");
			background-repeat: repeat-x;
			background-position: 0 bottom;
		}
	}

	&.is-thisMonth {
		background-color: $kurashiBtnColor07;
		.p-kurashiCalendarBox__month{
			//background: #da5143 url("../img/kurashi/calendar_illust.png") no-repeat center top 3px;
			height: 35px;
			margin-bottom: 0;
			padding-left: 6px;
			font-size: 2.0rem;
			line-height: 1.75;
			@include radius(0);
		}
		.p-kurashiCalendarBox__txt02{
			background: url("../img/kurashi/calendar_illust.png") no-repeat center;
			-webkit-background-size: contain;
			background-size: contain;
			display: flex;
			align-items: center;
			height: 147px;
			h3 {
				font-size: 2.0rem;
				line-height: 2.4rem;
				text-align: center;
				width: 100%;
				padding: 0 20px;
				background: none;
				margin: -5px 0 0;
				span {
					display: block;
				}
			}
			p {
				display: none;
			}
		}
		&--01{
			background-color: #f4cac6;
			.p-kurashiCalendarBox__month{
				background: #da5143 url("../img/kurashi/calendar_illust04.png") no-repeat center top 3px;
			}
		}
		&--02{
			background-color: #f9d2bf;
			.p-kurashiCalendarBox__month{
				background: #f18f60 url("../img/kurashi/calendar_illust05.png") no-repeat center top 4px;
			}
		}
		&--03{
			background-color: #ffdcb0;
			.p-kurashiCalendarBox__month{
				background: #ffb458 url("../img/kurashi/calendar_illust06.png") no-repeat center top 3px;
			}
		}
		&--04{
			background-color: #f9d2dc;
			.p-kurashiCalendarBox__month{
				background: #faa0b6 url("../img/kurashi/calendar_illust03.png") no-repeat center;
			}
		}
		&--05{
			background-color: #b7dff2;
			.p-kurashiCalendarBox__month{
				background: #58b4e2 url("../img/kurashi/calendar_illust07.png") no-repeat center;
			}
		}
		&--06{
			background-color: #ddd3e5;
			.p-kurashiCalendarBox__month{
				background: #b099c3 url("../img/kurashi/calendar_illust08.png") no-repeat center;
			}
		}
		&--07{
			background-color: #c1e5e2;
			.p-kurashiCalendarBox__month{
				background: #70c3bc url("../img/kurashi/calendar_illust09.png") no-repeat center;
			}
		}
		&--08{
			background-color: #c3daee;
			.p-kurashiCalendarBox__month{
				background: #5194cf url("../img/kurashi/calendar_illust10.png") no-repeat center;
			}
		}
		&--09{
			background-color: #e5e8c0;
			.p-kurashiCalendarBox__month{
				background: #a8b22d url("../img/kurashi/calendar_illust11.png") no-repeat center;
			}
		}
		&--10{
			background-color: #cccee2;
			.p-kurashiCalendarBox__month{
				background: #7579b1 url("../img/kurashi/calendar_illust12.png") no-repeat center;
			}
		}
		&--11{
			background-color: #f4e0a5;
			.p-kurashiCalendarBox__month{
				background: #eac24b url("../img/kurashi/calendar_illust13.png") no-repeat center;
			}
		}
		&--12{
			background-color: #f4c5cb;
			.p-kurashiCalendarBox__month{
				background: #d93f53 url("../img/kurashi/calendar_illust14.png") no-repeat center top 3px;
			}
		}
	}
}


@include sp {
	.p-kurashiCalendar {
		padding: 30px 20px;
	}
	//今月おすすめのお掃除 sp
	.p-kurashiCalendar01 {
		font-size: 1.4rem;
		> p {
			margin-bottom: 15px;
		}
		> ul {
			margin-bottom: 15px;
		}
		&__content01 {
			margin-bottom: 20px;
			p {
				font-size: 1.4rem;
				line-height: 20px;
				float: none;
			}
			&__calendar {
				width: 47%;
				margin: 0 18px 10px 0;
				padding-top: 0;
				.p-kurashiCalendarBox {
					width: 100% !important;
				}
			}
			&__txt01 {
				width: 100%;
				padding: 0 0 15px 0;
				margin: -2px 0 15px 0;
			}
			&__box {
				width: 100%;
				margin-bottom: 24px;
				clear: both;
				h3{
					margin-bottom: 8px;
				}
				ol {
					padding: 9px 17px;
					li{
						line-height: 20px;
					}
				}
			}
		}
		&__content02 {
			&__calendarList {
				.p-kurashiCalendarBox {
					margin: 0 20px 15px 0 !important;
					&:nth-child(2n) {
						margin: 0 0 15px 0 !important;
					}
					&.is-thisMonth {

					}
				}
			}
		}
	}

	//お掃除カレンダーボックス sp
	.p-kurashiCalendarBox {
		width: calc(50% - 10px);
		border-radius: 0 0 15px 15px;
		&__month {
			height: 20px;
			font-size: 1.2rem;
			margin-bottom: 8px;
		}
		&__txt {
			h3 {
				margin-bottom: 6px;
    			padding: 9px 0;
				font-size: 1.5rem;
			}
			p{
				font-size: 1.4rem;
				line-height: 20px;
			}
		}
		&.is-thisMonth {
			border-radius: 15px;
			.p-kurashiCalendarBox__month {
				height: 30px;
				font-size: 1.4rem;
				line-height: 2.14;
			}
			.p-kurashiCalendarBox__txt02 {
				h3{
					font-size: 1.5rem;
					padding: 0 10px;
				}
			}
			&--01{
				.p-kurashiCalendarBox__month{
					background: #da5143 url("../img/kurashi/calendar_illust04_sp.png") no-repeat center;
					background-size: 110px 25px;
				}
			}
			&--02{
				.p-kurashiCalendarBox__month{
					background: #f18f60 url("../img/kurashi/calendar_illust05_sp.png") no-repeat center bottom -1px;
					background-size: 115px 26px;
				}
			}
			&--03{
				.p-kurashiCalendarBox__month{
					background: #ffb458 url("../img/kurashi/calendar_illust06_sp.png") no-repeat center bottom;
					background-size: 112px 26px;
				}
			}
			&--04{
				.p-kurashiCalendarBox__month{
					background: #faa0b6 url("../img/kurashi/calendar_illust03_sp.png") no-repeat center;
					background-size: 115px 23px;
				}
			}
			&--05{
				.p-kurashiCalendarBox__month{
					background: #58b4e2 url("../img/kurashi/calendar_illust07_sp.png") no-repeat center;
					background-size: 110px 23px;
				}
			}
			&--06{
				.p-kurashiCalendarBox__month{
					background: #b099c3 url("../img/kurashi/calendar_illust08_sp.png") no-repeat center;
					background-size: 110px 23px;
				}
			}
			&--07{
				.p-kurashiCalendarBox__month{
					background: #70c3bc url("../img/kurashi/calendar_illust09_sp.png") no-repeat center;
					background-size: 115px 23px;
				}
			}
			&--08{
				.p-kurashiCalendarBox__month{
					background: #5194cf url("../img/kurashi/calendar_illust10_sp.png") no-repeat center;
					background-size: 115px 23px;
				}
			}
			&--09{
				.p-kurashiCalendarBox__month{
					background: #a8b22d url("../img/kurashi/calendar_illust11_sp.png") no-repeat center;
					background-size: 110px 23px;
				}
			}
			&--10{
				.p-kurashiCalendarBox__month{
					background: #7579b1 url("../img/kurashi/calendar_illust12_sp.png") no-repeat center;
					background-size: 110px 20px;
				}
			}
			&--11{
				.p-kurashiCalendarBox__month{
					background: #eac24b url("../img/kurashi/calendar_illust13_sp.png") no-repeat center;
					background-size: 120px 23px;
				}
			}
			&--12{
				.p-kurashiCalendarBox__month{
					background: #d93f53 url("../img/kurashi/calendar_illust14_sp.png") no-repeat center bottom;
					background-size: 120px 28px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
