@use '../0_base' as *;

/*------------------------------------------------------------
footer05（リフォーム）
------------------------------------------------------------*/
.c-foot05 {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding-top: 72px;
	background-color: $subColor01;
	border-top: solid 1px $keycolor01;
}
.c-foot05NaviArea {
	margin-bottom: 70px;
}
.c-foot05Link {
	width: 208px;
	.c-foot05Logo {
		width: 200px;
		margin-bottom: 37px;
		padding-top: 8px;
	}
	dl {
		dt {
			margin-bottom: 10px;
			padding-bottom: 9px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 1.5;
			border-bottom: solid 1px $keycolor01;
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
		dd {
			margin-bottom: 4px;
			padding-left: 35px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -15px;
				content: '';
				background-image: url("../img/common/icon_arrow-green01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:last-child{
				padding-top: 26px;
				a{
					padding-left: 3px;
				}
			}
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
	}
}
.c-foot05Navi {
	width: 436px;
	margin-left: 20px;
	.c-foot05Navi__menuTitle {
		margin-bottom: 8px;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: 2;
		color: $gray01;
		border-bottom: solid 1px $reformColor01;
		padding-bottom: 6px;
	}
	dl {
		width: 195px;
		margin-left: 10px;
		&:not(:first-of-type) {
			margin-left: 35px;
		}
		dt {
			margin-bottom: 1px;
			font-size: 1.4rem;
			font-weight: bold;
			line-height: 2;
			&:not(:first-of-type) {
				margin-top: 8px;
			}
			a {
				color: $gray01;
				&:hover {
					color: $reformColor01;
				}
			}
		}
		dd {
			margin-bottom: 4px;
			padding: 0 0px 0 22px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 7px 0 -12px;
				content: '';
				background-image: url("../img/common/icon_arrow-green07.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $reformColor01;
				}
			}
		}
	}
}

.c-foot05Navi2 {
	width: 228px;
	padding-left: 20px;
	dl {
		width: 208px;
		dt {
			margin-bottom: 13px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 2;
			color: $gray01;
			border-bottom: solid 1px $reformColor01;
			padding-bottom: 6px;
		}
		dd {
			margin-bottom: 8px;
			padding: 0 6px 0 32px;
			font-size: 1.4rem;
			line-height: 1.8rem;
			&:not(:first-of-type) {
				padding: 0 0 0 32px;
			}
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -12px;
				content: '';
				background-image: url("../img/common/icon_arrow-green07.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $reformColor01;
				}
			}
		}
	}
}


@include sp {
	.c-foot05 {
		min-width: 100%;
		padding-top: 0;
		padding-bottom: 50px;
		background-color: #ffffff;
		border: none;
	}
	.c-reformFlortingBnr {
		width: 100%;
		height: 50px;
		overflow: hidden;
		position: fixed;
		top: auto;
		bottom: 0;
		z-index: 9990;
		@include dib;
		a {
			width: 50%;
			height: 100%;
			padding: 11px 0 10px;
			font-size: 1.1rem;
			font-weight: bold;
			color: #ffffff;
			text-align: center;
			vertical-align: top;
			&:before {
				margin: -3px 5px 0 0;
				content: '';
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:nth-child(1) {
				line-height: 2.64;
				background-color: $reformBtnColor03;
				&:before {
					width: 12px;
					height: 11px;
					background-image: url("../img/common/icon_tel02.png");
				}
			}
			&:nth-child(2) {
				line-height: 1.32;
				background-color: $reformBtnColor01;
				span {
					text-align: left;
					display: inline-block;
					vertical-align: middle;
				}
				&:before {
					width: 12px;
					height: 13px;
					background-image: url("../img/common/icon_file01.png");
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/


/*
------------------------------------------------------------*/
