@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,500i,700,700i,900");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a {
  transition: 0.3s ease-in-out;
}

a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

@font-face {
  font-family: "Noto Sans Japanese";
  src: url(../font/NotoSansCJKjp-Bold.woff) format("woff");
  src: url(../font/NotoSansCJKjp-Light.woff) format("woff");
  src: url(../font/NotoSansCJKjp-Regular.woff) format("woff");
}
@font-face {
  font-family: "Garamond Classico";
  src: url(../font/GaramondClassico.ttf) format("truetype");
}
/*------------------------------------------------------------
for develop
------------------------------------------------------------*/
#__bs_notify__ {
  opacity: 0.3 !important;
  font-size: 5px !important;
  line-height: 100% !important;
  padding: 0 5px 0 5px !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}

.c-dev-title1 {
  background: #000;
  color: #FFF;
  line-height: 1;
  padding: 6px 0 6px 15px;
  font-size: 1.6rem;
  margin: 60px 0 20px 0;
}

.c-dev-title2 {
  background: #888;
  color: #FFF;
  line-height: 1;
  padding: 6px 0 6px 30px;
  font-size: 1.3rem;
  margin: 50px 0 10px 0;
}

.c-dev-message1 {
  top: 0;
  left: 0;
  background: #F00;
  color: #FFF;
  z-index: 9999;
  position: fixed;
  line-height: 1;
  padding: 3px 6px;
  opacity: 0.6;
  font-size: 8px;
}

/*------------------------------------------------------------
common
------------------------------------------------------------*/
html {
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}

body {
  min-width: 1120px;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  body {
    min-width: 320px;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

@media (min-width: 641px) {
  a[href^="tel:"] {
    cursor: default;
  }
  a[href^="tel:"]:hover {
    opacity: 1;
  }
  .sp-only {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .pc-only {
    display: none;
  }
}
body.serviceHoken {
  min-width: 320px;
  font-weight: normal;
}
@media screen and (min-width: 641px) {
  body.serviceHoken {
    min-width: 1200px;
  }
}

/*------------------------------------------------------------
header(共通)
------------------------------------------------------------*/
.c-headSearchBoxPc {
  width: 200px;
  margin-left: 13px;
  position: relative;
}
.c-headSearchBoxPc form.gsc-search-box {
  font-size: 1.4rem;
  line-height: 2;
}
.c-headSearchBoxPc .gsc-input-box {
  width: 100%;
  height: 30px;
  padding: 0 30px 0 10px;
  border: solid 1px #dddddd;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-headSearchBoxPc .gsc-search-button-v2 {
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: initial;
  display: block;
  border: none;
  background: none;
  background-image: url("../img/common/icon_search01.png");
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 6px;
  right: 6px;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
}
.c-headSearchBoxPc .gsc-search-button-v2:hover {
  background-color: unset !important;
  border-color: unset !important;
  background: none;
  background-image: url("../img/common/icon_search01.png");
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.c-headSearchBoxPc .gsib_a {
  padding: 0 6px 0;
}
.c-headSearchBoxPc .gsc-input {
  height: 28px;
  padding-right: 0 !important;
}
.c-headSearchBoxPc.c-headSearchBoxPc--2 {
  width: 245px;
}
.c-headSearchBoxPc.c-headSearchBoxPc--2 .gsc-input-box {
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.c-headSearchBoxPc.c-headSearchBoxPc--2 .gsc-search-button-v2 {
  width: 16px;
  height: 16px;
  top: 7px;
  right: 15px;
}

.c-headSearchBoxBtnPc {
  width: 40px;
  height: calc(100% + 10px);
  margin: -10px 0 0 0;
  padding-top: 10px;
  position: relative;
  z-index: 9999;
}
.c-headSearchBoxBtnPc .btn-search {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  display: block;
  background-color: #ffffff;
  background-image: url("../img/common/icon_search01.png");
  -webkit-background-size: 14px;
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center center;
  border: solid 2px #dfdfdf;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.c-headSearchBoxBtnPc .c-headSearchBoxPc {
  display: none;
}
.c-headSearchBoxBtnPc.c-headSearchBoxBtnPc--1 .c-headSearchBoxPc {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 99999;
}
.c-headSearchBoxBtnPc.c-headSearchBoxBtnPc--1 .c-overlay02 {
  display: none;
}
.c-headSearchBoxBtnPc.c-headSearchBoxBtnPc--2:hover {
  background-color: #dfdfdf;
}
.c-headSearchBoxBtnPc.c-headSearchBoxBtnPc--2:hover .c-headSearchBoxPc {
  display: block;
}

.c-headCateNavi {
  margin-left: 2.3%;
}
.c-headCateNavi li {
  height: 50px;
  padding: 0 28px 0 20px;
  margin-top: -10px;
  font-size: 1.5rem;
  line-height: 3.33;
  font-weight: bold;
  position: relative;
  transition: 0.3s ease-in-out;
}
.c-headCateNavi li:after {
  width: 1px;
  height: 10px;
  content: "";
  background-color: #dfdfdf;
  position: absolute;
  top: calc(50% - 5px);
  left: 0;
}
.c-headCateNavi li:nth-child(1):after {
  content: none;
}
.c-headCateNavi li:nth-child(1):hover, .c-headCateNavi li:nth-child(1).is-curent {
  background-color: #fc819b;
}
.c-headCateNavi li:nth-child(1):hover + li:after, .c-headCateNavi li:nth-child(1).is-curent + li:after {
  content: none;
}
.c-headCateNavi li:nth-child(1):hover .c-cateIcon01--ticket, .c-headCateNavi li:nth-child(1).is-curent .c-cateIcon01--ticket {
  background-color: #ffffff;
  background-image: url("../img/common/icon_ticket02.png");
}
.c-headCateNavi li:nth-child(1):hover .c-cateIcon01--ticket + a, .c-headCateNavi li:nth-child(1).is-curent .c-cateIcon01--ticket + a {
  color: #ffffff;
}
.c-headCateNavi li:nth-child(2):hover, .c-headCateNavi li:nth-child(2).is-curent {
  background-color: #82cdf3;
}
.c-headCateNavi li:nth-child(2):hover:after, .c-headCateNavi li:nth-child(2).is-curent:after {
  content: none;
}
.c-headCateNavi li:nth-child(2):hover + li:after, .c-headCateNavi li:nth-child(2).is-curent + li:after {
  content: none;
}
.c-headCateNavi li:nth-child(2):hover .c-cateIcon01--hoken, .c-headCateNavi li:nth-child(2).is-curent .c-cateIcon01--hoken {
  background-color: #ffffff;
  background-image: url("../img/common/icon_hoken02.png");
}
.c-headCateNavi li:nth-child(2):hover .c-cateIcon01--hoken + a, .c-headCateNavi li:nth-child(2).is-curent .c-cateIcon01--hoken + a {
  color: #ffffff;
}
.c-headCateNavi li:nth-child(3) {
  padding: 0 19px 0 21px;
}
.c-headCateNavi li:nth-child(3):hover, .c-headCateNavi li:nth-child(3).is-curent {
  background-color: #68ba93;
}
.c-headCateNavi li:nth-child(3):hover:after, .c-headCateNavi li:nth-child(3).is-curent:after {
  content: none;
}
.c-headCateNavi li:nth-child(3):hover + li:after, .c-headCateNavi li:nth-child(3).is-curent + li:after {
  content: none;
}
.c-headCateNavi li:nth-child(3):hover .c-cateIcon01--reform, .c-headCateNavi li:nth-child(3).is-curent .c-cateIcon01--reform {
  background-color: #ffffff;
  background-image: url("../img/common/icon_reform02.png");
}
.c-headCateNavi li:nth-child(3):hover .c-cateIcon01--reform + a, .c-headCateNavi li:nth-child(3).is-curent .c-cateIcon01--reform + a {
  color: #ffffff;
}
.c-headCateNavi li:nth-child(4) {
  padding: 0 18px 0 21px;
}
.c-headCateNavi li:nth-child(4):hover, .c-headCateNavi li:nth-child(4).is-curent {
  background-color: #faad5a;
}
.c-headCateNavi li:nth-child(4):hover:after, .c-headCateNavi li:nth-child(4).is-curent:after {
  content: none;
}
.c-headCateNavi li:nth-child(4):hover + li:after, .c-headCateNavi li:nth-child(4).is-curent + li:after {
  content: none;
}
.c-headCateNavi li:nth-child(4):hover .c-cateIcon01--kurashi, .c-headCateNavi li:nth-child(4).is-curent .c-cateIcon01--kurashi {
  background-color: #ffffff;
  background-image: url("../img/common/icon_kurashi02.png");
}
.c-headCateNavi li:nth-child(4):hover .c-cateIcon01--kurashi + a, .c-headCateNavi li:nth-child(4).is-curent .c-cateIcon01--kurashi + a {
  color: #ffffff;
}
.c-headCateNavi li:nth-child(5):hover, .c-headCateNavi li:nth-child(5).is-curent {
  background-color: #af8ebe;
}
.c-headCateNavi li:nth-child(5):hover:after, .c-headCateNavi li:nth-child(5).is-curent:after {
  content: none;
}
.c-headCateNavi li:nth-child(5):hover .c-cateIcon01--sosai, .c-headCateNavi li:nth-child(5).is-curent .c-cateIcon01--sosai {
  background-color: #ffffff;
  background-image: url("../img/common/icon_sosai02.png");
}
.c-headCateNavi li:nth-child(5):hover .c-cateIcon01--sosai + a, .c-headCateNavi li:nth-child(5).is-curent .c-cateIcon01--sosai + a {
  color: #ffffff;
}
.c-headCateNavi li .c-cateIcon01 {
  width: 28px;
  height: 28px;
  margin: -1px 10px 0 0;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 1560px) {
  .c-headRight {
    display: none;
  }
}
.c-headCateTitle {
  height: 120px;
  padding-top: 70px;
  font-size: 2.4rem;
}
.c-headCateTitle .c-cateIcon01 {
  width: 40px;
  height: 40px;
  margin: -6px 17px 0 0;
  display: inline-block;
  vertical-align: middle;
}
.c-headCateTitle strong {
  font-weight: bold;
}

.c-headCateTitleBg {
  width: 100%;
  min-width: 1120px;
  height: 70px;
  margin: 0 auto;
  background-color: #ffffff;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 998;
}

@media screen and (max-width: 640px) {
  header {
    min-width: 320px;
  }
  header .c-header {
    padding-top: 20px;
  }
  .c-headSearchBoxSp {
    width: 100%;
    padding: 10px 20px;
    background-color: #649333;
    position: relative;
  }
  .c-headSearchBoxSp .gsc-input-box {
    width: 100%;
    height: 30px;
    padding: 0 30px 0 10px;
    font-size: 1.4rem;
    line-height: 2;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #dddddd;
  }
  .c-headSearchBoxSp .gsc-search-button-v2 {
    width: 18px;
    height: 22px;
    padding: 0;
    font-size: initial;
    display: block;
    border: none;
    background: none;
    background-image: url("../img/common/icon_search01.png");
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 15px;
    right: 35px;
    z-index: 10;
    border-radius: 2px;
    text-indent: 1000em;
    white-space: nowrap;
    overflow: hidden;
  }
  .c-headSearchBoxSp .gsc-search-button-v2:hover {
    background-color: unset !important;
    border-color: unset !important;
    background: none;
    background-image: url("../img/common/icon_search01.png");
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .c-headSearchBoxSp .gsib_a {
    padding: 0 6px 0;
  }
  .c-headSearchBoxSp .gsst_a {
    padding-top: 7px;
  }
  .c-headSearchBoxSp .gsc-input {
    height: 28px;
    padding-right: 0 !important;
  }
  .c-headSearchBoxSp input[name=q] {
    background-position: 10px center !important;
    padding: 0 30px 0 10px !important;
    border: none !important;
  }
  .c-headCateTitleSp {
    height: 50px;
    padding: 14px 8px 0 10px;
    font-size: 1.6rem;
    position: absolute;
    top: 0;
    right: 50px;
  }
  .c-headCateTitleSp .c-cateIcon01 {
    width: 28px;
    height: 28px;
    margin: -5px 5px 0 0;
    display: inline-block;
    vertical-align: middle;
  }
  .c-headCateTitleSp strong {
    font-weight: bold;
  }
  .c-headCateTitleSp .c-cateIcon01--ticket {
    background-color: #ffffff !important;
    background-image: url("../img/common/icon_ticket02.png") !important;
  }
  .c-headCateTitleSp .c-cateIcon01--ticket + strong {
    color: #ffffff !important;
  }
  .c-headCateTitleSp .c-cateIcon01--hoken {
    margin: -5px 9px 0 0;
    background-color: #ffffff !important;
    background-image: url("../img/common/icon_hoken02.png") !important;
  }
  .c-headCateTitleSp .c-cateIcon01--hoken + strong {
    color: #ffffff !important;
  }
  .c-headCateTitleSp .c-cateIcon01--reform {
    background-color: #ffffff !important;
    background-image: url("../img/common/icon_reform02.png") !important;
  }
  .c-headCateTitleSp .c-cateIcon01--reform + strong {
    color: #ffffff !important;
  }
  .c-headCateTitleSp .c-cateIcon01--kurashi {
    background-color: #ffffff !important;
    background-image: url("../img/common/icon_kurashi02.png") !important;
  }
  .c-headCateTitleSp .c-cateIcon01--kurashi + strong {
    color: #ffffff !important;
  }
  .c-headCateTitleSp .c-cateIcon01--sosai {
    background-color: #ffffff !important;
    background-image: url("../img/common/icon_sosai02.png") !important;
  }
  .c-headCateTitleSp .c-cateIcon01--sosai + strong {
    color: #ffffff !important;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
header02(サービスTOP)
------------------------------------------------------------*/
.c-head02Pc {
  width: 95%;
  min-width: 1120px;
  height: 50px;
  margin: 0 auto;
  padding: 10px 1% 0 2%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  -webkit-border-radius: 0 0 25px 25px;
  border-radius: 0 0 25px 25px;
  *zoom: 1;
}
.c-head02Pc:after {
  display: block;
  content: "";
  clear: both;
}
.c-head02Pc .c-head02Logo {
  width: 189px;
}

.c-head02GnaviPc ul {
  letter-spacing: -0.4em;
}
.c-head02GnaviPc ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head02GnaviPc ul li {
  margin-right: 19px;
}
.c-head02GnaviPc ul li a {
  color: #46483c;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.3;
}
.c-head02GnaviPc ul li a:before {
  margin: -3px 5px 0 0;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-head02GnaviPc ul li a:hover {
  color: #649333;
}
.c-head02GnaviPc ul li:nth-child(1) a:before {
  margin: -3px 7px 0 0;
  width: 14px;
  height: 19px;
  background-image: url("../img/common/icon_biginer01.png");
}
.c-head02GnaviPc ul li:nth-child(2) {
  margin-left: 11px;
}
.c-head02GnaviPc ul li:nth-child(2) a:before {
  width: 17px;
  height: 18px;
  background-image: url("../img/common/icon_reaf01.png");
}

@media screen and (max-width: 640px) {
  .c-head02Sp {
    width: 100%;
    height: 60px;
    padding: 15px 20px 0 20px;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeeee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    *zoom: 1;
  }
  .c-head02Sp:after {
    display: block;
    content: "";
    clear: both;
  }
  .c-head02Sp .c-head02Logo {
    width: 200px;
    max-width: 63%;
    margin: 0 auto;
  }
  .c-head02Sp .c-spMenuBtn {
    padding-top: 16px;
    right: 0;
  }
  .c-head02LinkSp dl {
    width: 100%;
  }
  .c-head02LinkSp dl dt {
    width: 100%;
    padding: 0 20px;
    background-color: #ffffff;
    border-bottom: solid 2px #dddddd;
  }
  .c-head02LinkSp dl dt a {
    padding: 10px 0 9px 15px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    color: #46483c;
    background-image: url("../img/common/icon_arrow-green04.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 0 center;
  }
  .c-head02LinkSp dl dd {
    padding: 9px 20px 3px;
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: solid 1px #649333;
  }
  .c-head02LinkSp dl dd ul li {
    width: 49%;
    height: 45px;
    margin: 0 2% 2% 0;
    border: solid 1px #649333;
    background-color: #ffffff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  .c-head02LinkSp dl dd ul li a {
    width: 100%;
    height: 100%;
    padding: 3px 0 3px 52px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.928;
    display: block;
    position: relative;
  }
  .c-head02LinkSp dl dd ul li a:before {
    width: 30px;
    height: 30px;
    content: "";
    background-repeat: no-repeat;
    -webkit-background-size: 80%;
    background-size: 80%;
    background-position: center center;
    position: absolute;
    top: 7px;
    left: 14px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .c-head02LinkSp dl dd ul li:nth-child(1) a {
    color: #783146;
  }
  .c-head02LinkSp dl dd ul li:nth-child(1) a:before {
    background-color: #fc819b;
    background-image: url("../img/common/icon_ticket01.png");
  }
  .c-head02LinkSp dl dd ul li:nth-child(2) {
    margin-right: 0;
  }
  .c-head02LinkSp dl dd ul li:nth-child(2) a {
    color: #253b73;
  }
  .c-head02LinkSp dl dd ul li:nth-child(2) a:before {
    background-color: #82cdf3;
    background-image: url("../img/common/icon_hoken01.png");
  }
  .c-head02LinkSp dl dd ul li:nth-child(3) a {
    color: #154827;
  }
  .c-head02LinkSp dl dd ul li:nth-child(3) a:before {
    background-color: #68ba93;
    background-image: url("../img/common/icon_reform01.png");
  }
  .c-head02LinkSp dl dd ul li:nth-child(4) {
    margin-right: 0;
  }
  .c-head02LinkSp dl dd ul li:nth-child(4) a {
    line-height: 1.2;
    color: #7c4b12;
    padding-top: 7px;
  }
  .c-head02LinkSp dl dd ul li:nth-child(4) a:before {
    background-color: #faad5a;
    background-image: url("../img/common/icon_kurashi01.png");
  }
  .c-head02LinkSp dl dd ul li:nth-child(5) a {
    color: #503f69;
  }
  .c-head02LinkSp dl dd ul li:nth-child(5) a:before {
    background-color: #af8ebe;
    background-image: url("../img/common/icon_sosai01.png");
  }
  .c-head02GnaviSp {
    background-color: #ffffff;
  }
  .c-head02GnaviSp ul li {
    padding: 0 38px;
    border-bottom: dashed 1px #dddddd;
  }
  .c-head02GnaviSp ul li:last-child {
    border: none;
  }
  .c-head02GnaviSp ul li a {
    padding-left: 15px;
    font-size: 1.4rem;
    line-height: 2.9;
    display: block;
    color: #46483c;
    background-image: url("../img/common/icon_arrow-green04.png");
    background-repeat: no-repeat;
    -webkit-background-size: 6px;
    background-size: 6px;
    background-position: 0 center;
  }
  .c-head02GnaviSp div {
    width: 100%;
    padding: 0 20px;
    background-color: #ffffff;
    border-top: solid 2px #dddddd;
  }
  .c-head02GnaviSp div a {
    padding-left: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.9;
    display: block;
    color: #46483c;
    background-image: url("../img/common/icon_arrow-green04.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 0 center;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
header03（チケット）
------------------------------------------------------------*/
.c-head03Pc {
  width: 95%;
  min-width: 1120px;
  height: 50px;
  margin: 0 auto 70px;
  padding: 10px 1% 0 2%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  border-radius: 0 0 25px 25px;
  *zoom: 1;
}
.c-head03Pc:after {
  display: block;
  content: "";
  clear: both;
}
.c-head03Pc .c-head03Logo {
  width: 189px;
}

.c-head03GnaviPc ul {
  letter-spacing: -0.4em;
}
.c-head03GnaviPc ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head03GnaviPc ul li {
  margin-right: 15px;
}
.c-head03GnaviPc ul li a {
  color: #46483c;
  font-size: 1.4rem;
  line-height: 2.14;
  font-weight: 600;
}
.c-head03GnaviPc ul li a:before {
  margin: -3px 5px 0 0;
  content: "";
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-head03GnaviPc ul li a:hover {
  color: #649333;
}
.c-head03GnaviPc ul li:nth-child(1) a:before {
  width: 14px;
  height: 19px;
  background-image: url("../img/common/icon_biginer01.png");
}
.c-head03GnaviPc ul li:nth-child(2) a:before {
  width: 17px;
  height: 18px;
  background-image: url("../img/common/icon_reaf01.png");
}

.c-head03CateTitle {
  height: 70px;
  padding-top: 15px;
  font-size: 2.4rem;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}

.c-head03BtnPc {
  height: 0;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}
.c-head03BtnPc .c-head03BtnPc__btn {
  width: 110px;
  height: 70px;
  position: absolute;
  top: 0;
}
.c-head03BtnPc .c-head03BtnPc__btn.c-head03BtnPc__btn--1 {
  background-color: #ffff9a;
  right: 78px;
}
.c-head03BtnPc .c-head03BtnPc__btn.c-head03BtnPc__btn--1 a {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  display: block;
  color: #666666;
  background-image: url("../img/common/icon_man01.png");
  background-repeat: no-repeat;
  background-position: center 15px;
  -webkit-transition: opacity 0s ease-in-out;
  transition: opacity 0s ease-in-out;
}
.c-head03BtnPc .c-head03BtnPc__btn.c-head03BtnPc__btn--2 {
  background-color: #ff6e03;
  right: 0;
}
.c-head03BtnPc .c-head03BtnPc__btn.c-head03BtnPc__btn--2 a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
}
.c-head03BtnPc .c-head03BtnPc__btn.c-head03BtnPc__btn--2 a span {
  width: 48%;
  height: 60px;
  padding: 16px 0 0 1px;
  font-size: 1.1rem;
  background-image: url("../img/common/icon_cart01.png");
  background-size: 26px;
  background-position: 49% 17px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 640px) {
  .c-head03Sp {
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeeee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    *zoom: 1;
  }
  .c-head03Sp:after {
    display: block;
    content: "";
    clear: both;
  }
  .c-head03Sp .c-head03Logo {
    width: 200px;
    max-width: 35%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 14px;
  }
  .c-head03Sp .c-head03Logo img {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .c-head03Sp .c-spMenu {
    background-color: #ffffff;
  }
  .c-head03Sp .c-headCateTitleSp {
    padding: 14px 10px 0 13px !important;
    background-color: #fc819b;
  }
  .c-head03LinkTextSp {
    padding: 0 20px;
  }
  .c-head03LinkTextSp .c-head03__LinkText01 {
    margin-bottom: 13px;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .c-head03LinkTextSp .c-head03__LinkText01:before {
    width: 6px;
    height: 10px;
    margin: -3px 10px 0 0;
    content: "";
    background-image: url("../img/common/icon_arrow-pink01.png");
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head03LinkTextSp .c-head03__LinkText01 a {
    color: #308bbb;
    text-decoration: none;
  }
  .c-head03LinkSp dl {
    width: 100%;
  }
  .c-head03LinkSp dl dt {
    width: 100%;
    background-color: #8cb340;
  }
  .c-head03LinkSp dl dt a {
    padding: 8px 35px 7px 20px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -2px;
    display: block;
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white02.png");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: 95% center;
  }
  .c-head03LinkSp dl dd {
    padding: 15px 20px 8px;
    background-color: #ffffff;
  }
  .c-head03LinkSp dl dd ul li {
    width: 49%;
    height: 45px;
    margin: 0 2% 2% 0;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .c-head03LinkSp dl dd ul li a {
    width: 100%;
    height: 100%;
    padding: 2px 0 2px 55px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.928;
    display: block;
    position: relative;
  }
  .c-head03LinkSp dl dd ul li a:before {
    width: 30px;
    height: 30px;
    content: "";
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center center;
    position: absolute;
    top: 6px;
    left: 16px;
    border-radius: 50%;
  }
  .c-head03LinkSp dl dd ul li:nth-child(1) a {
    color: #253b73;
  }
  .c-head03LinkSp dl dd ul li:nth-child(1) a:before {
    background-color: #82cdf3;
    background-image: url("../img/common/icon_hoken01.png");
  }
  .c-head03LinkSp dl dd ul li:nth-child(2) {
    margin-right: 0;
  }
  .c-head03LinkSp dl dd ul li:nth-child(2) a {
    color: #154827;
  }
  .c-head03LinkSp dl dd ul li:nth-child(2) a:before {
    background-color: #68ba93;
    background-image: url("../img/common/icon_reform01.png");
  }
  .c-head03LinkSp dl dd ul li:nth-child(3) a {
    line-height: 1.2;
    color: #7c4b12;
    padding-top: 7px;
  }
  .c-head03LinkSp dl dd ul li:nth-child(3) a:before {
    background-color: #faad5a;
    background-image: url("../img/common/icon_kurashi01.png");
  }
  .c-head03LinkSp dl dd ul li:nth-child(4) {
    margin-right: 0;
  }
  .c-head03LinkSp dl dd ul li:nth-child(4) a {
    color: #503f69;
  }
  .c-head03LinkSp dl dd ul li:nth-child(4) a:before {
    background-color: #af8ebe;
    background-image: url("../img/common/icon_sosai01.png");
  }
  .c-head03LinkSp div {
    width: 100%;
    padding: 0 20px 26px;
    background-color: #ffffff;
  }
  .c-head03LinkSp div a {
    padding: 0 0 0 9px;
    font-size: 1.4rem;
    line-height: 1.2;
    display: block;
    color: #649333;
    background-image: url("../img/common/icon_arrow-green06.png");
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: 0 2px;
  }
  .c-head03GnaviSp {
    margin-bottom: 29px;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi {
    width: 100%;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi a {
    padding: 15px 35px 14px 20px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--1 {
    background-color: #fc819b;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--1 a {
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white02.png");
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: 95% center;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--2 {
    background-color: #fef2f5;
    border-bottom: solid 1px #ffffff;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--2 a {
    color: #783146;
    background-image: url("../img/common/icon_arrow-pink01.png");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 95% center;
    font-size: 1.6rem;
    padding: 15px 35px 11px 19px;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--3 {
    border-bottom: solid 1px #ffffff;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--3:last-of-type {
    border: none;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--3 dt {
    background-color: #fef2f5;
    position: relative;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--3 dt a {
    width: calc(100% - 60px);
    color: #333745;
    background: none;
    color: #783146;
    padding: 15px 35px 14px 19px;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--3 dd {
    border-bottom: solid 1px #dddddd;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi.c-head03GnaviSp__navi--3 dd a {
    color: #232934;
    background-image: url("../img/common/icon_arrow-gray04.png");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 95% center;
    font-weight: normal;
    color: #232934;
    padding: 11px 30px 7px 30px;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle {
    padding: 19px 20px 17px 21px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    color: #232934;
    background-image: none;
    background-color: #ffffff;
    border-bottom: solid 2px #fc819b;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle:before {
    width: 18px;
    height: 18px;
    content: "";
    margin: -2px 7px 0 0;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle.c-head03GnaviSp__navi__menuTitle--search {
    padding: 14px 20px 6px;
    background-color: #fafafa;
    border: none;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle.c-head03GnaviSp__navi__menuTitle--search:before {
    background-image: url("../img/common/icon_search01.png");
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle.c-head03GnaviSp__navi__menuTitle--cate:before {
    margin: -2px 11px 0 0;
    background-image: url("../img/common/icon_light01.png");
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle.c-head03GnaviSp__navi__menuTitle--teiban {
    padding: 17px 20px 17px 21px;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle.c-head03GnaviSp__navi__menuTitle--teiban:before {
    margin: -2px 9px 0 0;
    background-image: url("../img/common/icon_shine01.png");
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle.c-head03GnaviSp__navi__menuTitle--riyou {
    padding: 16px 20px 13px 19px;
  }
  .c-head03GnaviSp .c-head03GnaviSp__navi__menuTitle.c-head03GnaviSp__navi__menuTitle--riyou:before {
    content: none;
  }
  .c-head03GnaviSp .c-head03GnaviSp__search {
    padding: 0 20px 20px;
    background-color: #fafafa;
    background-image: none;
    position: relative;
  }
  .c-head03GnaviSp .c-head03GnaviSp__search input[type=text] {
    width: 100%;
    height: 40px;
    padding: 0 30px 0 10px;
    font-size: 1rem;
    line-height: 2;
    border: solid 1px #a5abb7;
    color: green;
    border-radius: 0;
  }
  .c-head03GnaviSp .c-head03GnaviSp__search input[type=submit] {
    width: 34px;
    height: 34px;
    display: block;
    border: none;
    background: none;
    background-image: url("../img/common/icon_search06.png");
    background-size: cover;
    position: absolute;
    top: 3px;
    right: 23px;
    border-radius: 0;
    text-indent: 1000em;
    white-space: nowrap;
    overflow: hidden;
  }
  .c-head03BtnSp {
    padding: 0 20px;
    background-color: #ffffff;
    letter-spacing: -0.4em;
  }
  .c-head03BtnSp > * {
    display: inline-block;
    letter-spacing: normal;
  }
  .c-head03BtnSp a {
    width: 49%;
    padding: 3px 0;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    border: solid 3px;
  }
  .c-head03BtnSp a:nth-child(1) {
    float: left;
    margin: 0 2% 10px 0;
    padding: 10px 0 7px 9px;
    line-height: 1.2;
    color: #666666;
    background-color: #ffff9a;
    background-image: url("../img/common/icon_door01.png");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: 6px center;
    border-color: #ffea61;
  }
  .c-head03BtnSp a:nth-child(2) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 10px 0;
    padding: 10px 0 7px;
    line-height: 1.2;
    letter-spacing: -0.1em;
    background-color: #ff6e03;
    border-color: #ffa257;
  }
  .c-head03BtnSp a:nth-child(3) {
    width: 100%;
    padding: 6px 0;
    background-color: #4b5870;
    border-color: #939ba9;
  }
  .c-head03BtnSp a:nth-child(3):before {
    width: 20px;
    height: 16px;
    margin: 0px 5px 0 0px;
    content: "";
    background-image: url("../img/common/icon_tel01.png");
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
}
.c-head03BtnSp--ticket {
  padding: 0 20px;
  background-color: #ffffff;
  letter-spacing: -0.4em;
}
.c-head03BtnSp--ticket > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head03BtnSp--ticket a {
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: solid 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 0 10px 0;
  padding: 7px 0 7px;
  line-height: 1.2;
  letter-spacing: -0.1em;
  background-color: #ff6e03;
  border-color: #ffa257;
}
.c-head03BtnSp--ticket a span {
  width: 29px;
  height: 28px;
  background-image: url("../img/common/icon_cart01.png");
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: 0 2px;
  margin-right: 2px;
}

.c-head03BtnSp--blue {
  margin-bottom: 30px;
  padding: 0 20px;
  background-color: #ffffff;
  letter-spacing: -0.4em;
}
.c-head03BtnSp--blue > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head03BtnSp--blue a {
  width: 49%;
  padding: 3px 0;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: solid 3px;
}
.c-head03BtnSp--blue .c-head03BtnSp__telBtn {
  width: 100%;
  padding: 11px 0;
  background-color: #4b5870;
  border-color: #939ba9;
}
.c-head03BtnSp--blue .c-head03BtnSp__telBtn:before {
  width: 20px;
  height: 16px;
  margin: 0px 5px 0 0px;
  content: "";
  background-image: url("../img/common/icon_tel01.png");
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
header04（保険）
------------------------------------------------------------*/
.c-head04Pc {
  width: 95%;
  min-width: 1120px;
  height: 50px;
  margin: 0 auto;
  padding: 10px 1% 0 2%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  -webkit-border-radius: 0 0 25px 25px;
  border-radius: 0 0 25px 25px;
  *zoom: 1;
}
.c-head04Pc:after {
  display: block;
  content: "";
  clear: both;
}
.c-head04Pc .c-head04Logo {
  width: 189px;
}

.c-head04GnaviPc ul {
  letter-spacing: -0.4em;
}
.c-head04GnaviPc ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head04GnaviPc ul li {
  margin-right: 15px;
}
.c-head04GnaviPc ul li a {
  color: #46483c;
  font-size: 1.4rem;
  line-height: 2.14;
  font-weight: 600;
}
.c-head04GnaviPc ul li a:before {
  margin: -3px 5px 0 0;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-head04GnaviPc ul li a:hover {
  color: #649333;
}
.c-head04GnaviPc ul li:nth-child(1) a:before {
  width: 14px;
  height: 19px;
  background-image: url("../img/common/icon_biginer01.png");
}
.c-head04GnaviPc ul li:nth-child(2) a:before {
  width: 17px;
  height: 18px;
  background-image: url("../img/common/icon_reaf01.png");
}

.c-head04CateTitle {
  height: 70px;
  padding-top: 20px;
  font-size: 2.4rem;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}

.c-head04BtnPc {
  width: 100%;
  min-width: max-content;
  height: 60px;
  background: #fff;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  white-space: nowrap;
}
.c-head04BtnPc__navi ul {
  display: flex;
  column-gap: 40px;
}
.c-head04BtnPc__subNavi ul {
  display: flex;
  column-gap: 20px;
}
.c-head04BtnPc__textLink {
  display: block;
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
}
.c-head04BtnPc__btn {
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 9999px;
  column-gap: 10px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
}
.c-head04BtnPc__btn__icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}
.c-head04BtnPc__btn--soudan {
  background-color: #FA8246;
}
.c-head04BtnPc__btn--request {
  background-color: #41B4E6;
}

@media screen and (max-width: 640px) {
  .c-head04Sp {
    width: 100%;
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  .c-head04Sp .c-head04Logo {
    width: 127px;
  }
  .c-head04Sp .c-spMenu {
    background-color: #ffffff;
  }
  .c-head04Sp .c-headCateTitleSp {
    padding: 14px 14px 0 12px;
    background-color: #82cdf3;
  }
  .c-head04Sp__navi {
    margin-left: auto;
    display: flex;
    column-gap: 15px;
  }
  .c-head04LinkSp dl {
    width: 100%;
  }
  .c-head04LinkSp dl dt {
    width: 100%;
    background-color: #8cb340;
  }
  .c-head04LinkSp dl dt a {
    padding: 7px 35px 7px 20px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white02.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 95% center;
  }
  .c-head04LinkSp dl dd {
    padding: 15px 20px 5px;
    background-color: #ffffff;
  }
  .c-head04LinkSp dl dd ul li {
    width: 49%;
    height: 45px;
    margin: 0 2% 2% 0;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }
  .c-head04LinkSp dl dd ul li a {
    width: 100%;
    height: 100%;
    padding: 2px 0 2px 45px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.928;
    display: block;
    position: relative;
  }
  .c-head04LinkSp dl dd ul li a:before {
    width: 30px;
    height: 30px;
    content: "";
    background-repeat: no-repeat;
    -webkit-background-size: 80%;
    background-size: 80%;
    background-position: center center;
    position: absolute;
    top: 6px;
    left: 8px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .c-head04LinkSp dl dd ul li:nth-child(1) a {
    color: #783146;
  }
  .c-head04LinkSp dl dd ul li:nth-child(1) a:before {
    background-color: #fc819b;
    background-image: url("../img/common/icon_ticket01.png");
  }
  .c-head04LinkSp dl dd ul li:nth-child(2) {
    margin-right: 0;
  }
  .c-head04LinkSp dl dd ul li:nth-child(2) a {
    color: #154827;
  }
  .c-head04LinkSp dl dd ul li:nth-child(2) a:before {
    background-color: #68ba93;
    background-image: url("../img/common/icon_reform01.png");
  }
  .c-head04LinkSp dl dd ul li:nth-child(3) a {
    line-height: 1.2;
    color: #7c4b12;
    padding-top: 7px;
  }
  .c-head04LinkSp dl dd ul li:nth-child(3) a:before {
    background-color: #faad5a;
    background-image: url("../img/common/icon_kurashi01.png");
  }
  .c-head04LinkSp dl dd ul li:nth-child(4) {
    margin-right: 0;
  }
  .c-head04LinkSp dl dd ul li:nth-child(4) a {
    color: #503f69;
  }
  .c-head04LinkSp dl dd ul li:nth-child(4) a:before {
    background-color: #af8ebe;
    background-image: url("../img/common/icon_sosai01.png");
  }
  .c-head04LinkSp div {
    width: 100%;
    padding: 0 20px 20px;
    background-color: #ffffff;
  }
  .c-head04LinkSp div a {
    padding: 0 0 0 15px;
    font-size: 1.4rem;
    line-height: 1.5;
    display: block;
    color: #649333;
    background-image: url("../img/common/icon_arrow-green06.png");
    background-repeat: no-repeat;
    -webkit-background-size: 8px;
    background-size: 8px;
    background-position: 0 5px;
  }
  .c-head04GnaviSp {
    margin-bottom: 20px;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi {
    width: 100%;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi a,
  .c-head04GnaviSp .c-head04GnaviSp__navi em {
    padding: 11px 35px 11px 20px;
    font-size: 1.3rem;
    font-weight: bold;
    font-style: normal;
    line-height: 1.5;
    color: #46483c;
    display: block;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--1 {
    color: #ffffff;
    background-color: #82cdf3;
    background-image: url("../img/common/icon_arrow-white01.png");
    background-repeat: no-repeat;
    -webkit-background-size: 10px;
    background-size: 10px;
    background-position: 94% center;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--1 a {
    color: #ffffff;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--2 {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: dashed 1px #dddddd;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--2 a {
    position: relative;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--2 a:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto 0;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #82cdf3;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 {
    border-bottom: solid 1px #82cdf3;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dt {
    background-color: #ffffff;
    position: relative;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dt a,
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dt em {
    width: calc(100% - 60px);
    font-size: 1.4rem;
    background: none;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd {
    padding: 0 0 0 10px;
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: dashed 1px #dddddd;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd:first-of-type {
    border-top: solid 1px #82cdf3;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd:last-child {
    border-bottom: none;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd a {
    position: relative;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd a:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto 0;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #82cdf3;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub {
    padding: 0;
    background: none;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dt {
    background-color: rgba(255, 255, 255, 0.9);
    background-image: none;
    border-bottom: dashed 1px #dddddd;
    padding-left: 10px;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dt .c-spMenuAccordionBtn2 {
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dt .c-spMenuAccordionBtn2:before {
    width: 10px;
    height: 2px;
    content: "";
    margin-top: auto;
    margin-bottom: auto;
    background-color: #2579be;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dt .c-spMenuAccordionBtn2:after {
    width: 2px;
    height: 10px;
    margin-top: auto;
    margin-bottom: auto;
    content: "";
    background-color: #2579be;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 19px;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dd {
    border: none;
    background-color: rgba(37, 59, 115, 0.03);
    display: none;
    padding-left: 40px;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dd:first-of-type {
    padding-top: 5px;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dd:last-child {
    padding-bottom: 5px;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dd a {
    padding: 5px 9px;
    line-height: 1;
    text-decoration: underline;
    background-image: none;
    position: relative;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dd a:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    margin: auto 0;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4.5px 0 4.5px 4px;
    border-color: transparent transparent transparent #82cdf3;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl dd a:after {
    display: none;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl.is-open .c-spMenuAccordionBtn2:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl.is-open .c-spMenuAccordionBtn2:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi.c-head04GnaviSp__navi--3 dd.c-head04GnaviSp__navi__sub dl.is-open dd {
    display: block;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle {
    padding: 0 20px;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    color: #46483c;
    background-image: none;
    background-color: #f6fbfe;
    border-bottom: solid 2px #dddddd;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle span {
    padding: 12px 0;
    display: inline-block;
    position: relative;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle span:before {
    width: 24px;
    height: 24px;
    content: "";
    margin: -3px 10px 0 0;
    background-color: #2579be;
    -webkit-background-size: 60%;
    background-size: 60%;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle span:after {
    width: 100%;
    height: 0;
    content: "";
    position: absolute;
    border-bottom: solid 2px #82cdf3;
    top: 100%;
    left: 0;
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle.c-head04GnaviSp__navi__menuTitle--sagasu span:before {
    background-image: url("../img/common/icon_search02.png");
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle.c-head04GnaviSp__navi__menuTitle--chishiki span:before {
    background-image: url("../img/common/icon_book01.png");
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle.c-head04GnaviSp__navi__menuTitle--service span:before {
    background-image: url("../img/common/icon_hoken01.png");
  }
  .c-head04GnaviSp .c-head04GnaviSp__navi__menuTitle.c-head04GnaviSp__navi__menuTitle--policy span:before {
    background-image: url("../img/common/icon_policy.png");
  }
  .c-head04BtnSp {
    margin-bottom: 40px;
    padding: 0 20px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
  }
  .c-head04BtnSp a {
    width: 48%;
    height: 50px;
    font-size: 1.2rem;
    font-weight: bold;
    -webkit-background-size: cover;
    background-size: cover;
    vertical-align: top;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
    -webkit-border-radius: 30px;
    border-radius: 30px;
  }
  .c-head04BtnSp a:before {
    width: 28px;
    height: 28px;
    content: "";
    background-color: #ffffff;
    -webkit-background-size: 60%;
    background-size: 60%;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .c-head04BtnSp a:nth-child(1) {
    color: #253b73;
    background-image: url("../img/hoken/bg02.jpg");
  }
  .c-head04BtnSp a:nth-child(1):before {
    margin: -3px 6px 0 -6px;
    background-image: url("../img/common/icon_mail01.png");
  }
  .c-head04BtnSp a:nth-child(1) span {
    line-height: 1.425;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head04BtnSp a:nth-child(2) {
    color: #ffffff;
    line-height: 2.85;
    background-image: url("../img/hoken/bg01.jpg");
  }
  .c-head04BtnSp a:nth-child(2):before {
    margin: -3px 11px 0 -26px;
    background-image: url("../img/common/icon_file02.png");
    -webkit-background-size: 55%;
    background-size: 55%;
  }
  .c-head04GnaviSp__note {
    display: block;
    font-size: 1.1rem;
    font-weight: normal;
  }
  .c-head04GnaviSp2 {
    margin-top: 5px;
  }
  .c-head04GnaviSp2 ul ul {
    padding-left: 10px;
    padding-right: 10px;
  }
  .c-head04GnaviSp2 ul ul ul {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 0;
    padding-right: 0;
  }
  .c-head04GnaviSp2 ul[data-open=false] {
    display: none;
  }
  .c-head04GnaviSp2__item1 {
    padding: 5px 15px 10px;
    width: 100%;
    background: none;
    text-align: left;
    border: none;
    border-bottom: 1px solid #D9D9D9;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: 20px;
    color: #41B4E6;
    font-size: 2.2rem;
    font-weight: bold;
  }
  .c-head04GnaviSp2__item1__icon {
    margin-left: auto;
    width: 15px;
    height: 15px;
  }
  .c-head04GnaviSp2__item1__icon svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: currentColor;
  }
  .c-head04GnaviSp2__item1[data-open=true] .c-head04GnaviSp2__item1__icon__plus {
    display: none;
  }
  .c-head04GnaviSp2__item1[data-open=false] .c-head04GnaviSp2__item1__icon__minus {
    display: none;
  }
  .c-head04GnaviSp2__item1__note {
    font-size: 1rem;
    color: #000;
    font-weight: normal;
  }
  .c-head04GnaviSp2__item2 {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 1.6rem;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #D9D9D9;
    padding: 5px 0 10px 15px;
    background: none;
    color: #333;
  }
  .c-head04GnaviSp2__item2__icon {
    width: 12px;
    height: 12px;
    color: #41B4E6;
  }
  .c-head04GnaviSp2__item2__toggle {
    margin-left: auto;
    padding-left: 10px;
    border: none;
    border-left: 1px solid #d9d9d9;
  }
  .c-head04GnaviSp2__item2__toggle svg {
    width: 10px;
    height: 10px;
    color: #41B4E6;
  }
  .c-head04GnaviSp2__item2__toggleButton {
    padding: 2px 5px;
    display: block;
    border: none;
    background: none;
  }
  .c-head04GnaviSp2__item2__toggleButton svg {
    fill: currentColor;
  }
  .c-head04GnaviSp2__item2[data-open=true] .c-head04GnaviSp2__item2__toggleButton__plus {
    display: none;
  }
  .c-head04GnaviSp2__item2[data-open=false] .c-head04GnaviSp2__item2__toggleButton__minus {
    display: none;
  }
  .c-head04GnaviSp2__item3 {
    padding: 10px 0 10px 50px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #333;
    font-size: 1.4rem;
  }
  .c-head04GnaviSp2__item3__icon {
    width: 10px;
    height: 10px;
  }
  .c-head04SubNaviSp {
    margin-top: 40px;
  }
  .c-head04SubNaviSp li:not(:first-child) {
    margin-top: 10px;
  }
  .c-head04SubNaviSp a {
    color: #222;
    font-size: 1.4rem;
    text-decoration: underline;
  }
  .c-head04ServiceNaviSp {
    margin-top: 40px;
  }
  .c-head04ServiceNaviSp ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .c-head04ServiceNaviSp ul .c-head04ServiceNaviSp__top {
    width: 100%;
  }
  .c-head04ServiceNaviSp ul .c-head04ServiceNaviSp__top a {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.4rem;
    font-weight: bold;
  }
  .c-head04ServiceNaviSp ul .c-head04ServiceNaviSp__low {
    width: calc(50% - 5px);
  }
  .c-head04ServiceNaviSp__link1 {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    border-radius: 9999px;
    border: 1.5px solid #D9D9D9;
    color: #000;
  }
  .c-head04ServiceNaviSp__link1__icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .c-head04ServiceNaviSp__link1__icon--ticket {
    background: #fc819b url("/img/common/icon_ticket01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link1__icon--reform {
    background: #68ba93 url("/img/common/icon_reform01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link1__icon--kurashi {
    background: #faad5a url("/img/common/icon_kurashi01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link1__icon--sosai {
    background: #af8ebe url("/img/common/icon_sosai01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link1__label {
    padding-left: 7px;
    flex: 1;
  }
  .c-head04ServiceNaviSp__link1__arrow {
    width: 12px;
    height: 12px;
    color: #d9d9d9;
  }
  .c-head04ServiceNaviSp__link2 {
    padding-left: 15px;
    padding-right: 15px;
    height: 65px;
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-radius: 9999px;
    border: 1.5px solid #D9D9D9;
    color: #000;
  }
  .c-head04ServiceNaviSp__link2__icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .c-head04ServiceNaviSp__link2__icon--ticket {
    background: #fc819b url("/img/common/icon_ticket01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link2__icon--reform {
    background: #68ba93 url("/img/common/icon_reform01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link2__icon--kurashi {
    background: #faad5a url("/img/common/icon_kurashi01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link2__icon--sosai {
    background: #af8ebe url("/img/common/icon_sosai01.png") no-repeat center;
    background-size: 18px 18px;
  }
  .c-head04ServiceNaviSp__link2__label {
    padding-left: 7px;
    flex: 1;
  }
  .c-head04ServiceNaviSp__link2__arrow {
    width: 12px;
    height: 12px;
    color: #d9d9d9;
  }
  .c-head04Corporate {
    margin-top: 20px;
    text-align: center;
  }
  .c-head04Corporate__link {
    display: inline-flex;
    column-gap: 5px;
    color: #222;
    font-size: 1.4rem;
    text-decoration: underline;
  }
  .c-head04Corporate__link__icon {
    width: 10px;
    height: 10px;
    display: inline-block;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
header05（リフォーム）
------------------------------------------------------------*/
.c-head05Pc {
  width: 95%;
  min-width: 1120px;
  height: 50px;
  margin: 0 auto 70px;
  padding: 10px 1% 0 2%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  -webkit-border-radius: 0 0 25px 25px;
  border-radius: 0 0 25px 25px;
  *zoom: 1;
}
.c-head05Pc:after {
  display: block;
  content: "";
  clear: both;
}
.c-head05Pc .c-head05Logo {
  width: 189px;
}

.c-head05GnaviPc ul {
  letter-spacing: -0.4em;
}
.c-head05GnaviPc ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head05GnaviPc ul li {
  margin-right: 15px;
}
.c-head05GnaviPc ul li a {
  color: #46483c;
  font-size: 1.4rem;
  line-height: 2.14;
  font-weight: 600;
}
.c-head05GnaviPc ul li a:before {
  margin: -3px 5px 0 0;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-head05GnaviPc ul li a:hover {
  color: #649333;
}
.c-head05GnaviPc ul li:nth-child(1) a:before {
  width: 14px;
  height: 19px;
  background-image: url("../img/common/icon_biginer01.png");
}
.c-head05GnaviPc ul li:nth-child(2) a:before {
  width: 17px;
  height: 18px;
  background-image: url("../img/common/icon_reaf01.png");
}

.c-head05CateTitle {
  height: 70px;
  padding-top: 20px;
  font-size: 2.4rem;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}

.c-head05BtnPc {
  height: 0;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}
.c-head05BtnPc .c-head05BtnPc__btn {
  position: absolute;
  top: 0;
}
.c-head05BtnPc .c-head05BtnPc__btn.c-head05BtnPc__btn--1 {
  height: 70px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  right: 360px;
}
.c-head05BtnPc .c-head05BtnPc__btn.c-head05BtnPc__btn--1 span {
  padding-top: 1px;
  display: block;
  font-size: 1.2rem;
}
.c-head05BtnPc .c-head05BtnPc__btn.c-head05BtnPc__btn--1 span b {
  margin: 0 0 0 0.25em;
  font-size: 1.4rem;
  font-weight: bold;
}
.c-head05BtnPc .c-head05BtnPc__btn.c-head05BtnPc__btn--2 {
  width: 345px;
  height: 80px;
  background-color: #fa5b0f;
  right: 0;
}
.c-head05BtnPc .c-head05BtnPc__btn.c-head05BtnPc__btn--2 a {
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-head05BtnPc .c-head05BtnPc__btn.c-head05BtnPc__btn--2 a:before {
  width: 18px;
  height: 20px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_file01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 640px) {
  .c-head05Sp {
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeeee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    *zoom: 1;
  }
  .c-head05Sp:after {
    display: block;
    content: "";
    clear: both;
  }
  .c-head05Sp .c-head05Logo {
    width: 200px;
    max-width: 35%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 15px;
  }
  .c-head05Sp .c-head05Logo img {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .c-head05Sp .c-spMenu {
    background-color: #ffffff;
  }
  .c-head05Sp .c-headCateTitleSp {
    padding: 14px 15px 0 11px;
    background-color: #68ba93;
    letter-spacing: -0.1em;
  }
  .c-head05Sp .c-headCateTitleSp strong {
    white-space: nowrap;
  }
  .c-head05Sp .c-headCateTitleSp .c-cateIcon01 {
    margin: -5px 7px 0 0;
  }
  .c-head05LinkSp dl {
    width: 100%;
  }
  .c-head05LinkSp dl dt {
    width: 100%;
    background-color: #8cb340;
  }
  .c-head05LinkSp dl dt a {
    padding: 11px 35px 8px 20px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white02.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 95% center;
  }
  .c-head05LinkSp dl dd {
    padding: 15px 20px 5px;
    background-color: #ffffff;
  }
  .c-head05LinkSp dl dd ul li {
    width: 49%;
    height: 45px;
    margin: 0 2% 2% 0;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }
  .c-head05LinkSp dl dd ul li a {
    width: 100%;
    height: 100%;
    padding: 2px 0 2px 52px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.928;
    display: block;
    position: relative;
  }
  .c-head05LinkSp dl dd ul li a:before {
    width: 30px;
    height: 30px;
    content: "";
    background-repeat: no-repeat;
    -webkit-background-size: 65%;
    background-size: 65%;
    background-position: center center;
    position: absolute;
    top: 6px;
    left: 14px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .c-head05LinkSp dl dd ul li:nth-child(1) a {
    color: #783146;
  }
  .c-head05LinkSp dl dd ul li:nth-child(1) a:before {
    background-color: #fc819b;
    background-image: url("../img/common/icon_ticket01.png");
  }
  .c-head05LinkSp dl dd ul li:nth-child(2) {
    margin-right: 0;
  }
  .c-head05LinkSp dl dd ul li:nth-child(2) a {
    color: #253b73;
  }
  .c-head05LinkSp dl dd ul li:nth-child(2) a:before {
    background-color: #82cdf3;
    background-image: url("../img/common/icon_hoken01.png");
  }
  .c-head05LinkSp dl dd ul li:nth-child(3) a {
    line-height: 1.2;
    color: #7c4b12;
    padding-top: 7px;
  }
  .c-head05LinkSp dl dd ul li:nth-child(3) a:before {
    background-color: #faad5a;
    background-image: url("../img/common/icon_kurashi01.png");
  }
  .c-head05LinkSp dl dd ul li:nth-child(4) {
    margin-right: 0;
  }
  .c-head05LinkSp dl dd ul li:nth-child(4) a {
    color: #503f69;
  }
  .c-head05LinkSp dl dd ul li:nth-child(4) a:before {
    background-color: #af8ebe;
    background-image: url("../img/common/icon_sosai01.png");
  }
  .c-head05LinkSp div {
    width: 100%;
    padding: 0 20px 23px;
    background-color: #ffffff;
  }
  .c-head05LinkSp div a {
    padding: 0 0 0 9px;
    font-size: 1.4rem;
    line-height: 1.2;
    display: block;
    color: #649333;
    background-image: url("../img/common/icon_arrow-green06.png");
    background-repeat: no-repeat;
    -webkit-background-size: 8px;
    background-size: 8px;
    background-position: 0 1px;
  }
  .c-head05GnaviSp {
    margin-bottom: 20px;
    border-bottom: dotted 1px #4c4c4c;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi {
    width: 100%;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi a, .c-head05GnaviSp .c-head05GnaviSp__navi em {
    padding: 15px 35px 12px 18px;
    font-size: 1.6rem;
    font-weight: bold;
    font-style: normal;
    line-height: 1.5;
    color: #ffffff;
    display: block;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--1 {
    background-color: #68ba93;
    background-image: url("../img/common/icon_arrow-white01.png");
    background-repeat: no-repeat;
    -webkit-background-size: 6px;
    background-size: 6px;
    background-position: 95.5% center;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--2 {
    background-color: #ffffff;
    background-image: url("../img/common/icon_arrow-green05.png");
    background-repeat: no-repeat;
    -webkit-background-size: 6px;
    background-size: 6px;
    background-position: 95.5% center;
    border-bottom: dotted 1px #4c4c4c;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--2:nth-child(even) {
    background-color: #f8f8f5;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--2:last-child {
    border-bottom: none;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--2 a {
    color: #04756f;
    padding: 14px 35px 13px 19px;
    font-size: 1.2rem;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 {
    background-color: #ffffff;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dt {
    background-color: #4daaab;
    position: relative;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dt a, .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dt em {
    width: calc(100% - 60px);
    font-size: 1.5rem;
    background: none;
    padding: 11px 35px 11px 18px;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd {
    color: #04756f;
    background-image: url("../img/common/icon_arrow-green05.png");
    background-repeat: no-repeat;
    -webkit-background-size: 6px;
    background-size: 6px;
    background-position: 95.5% center;
    border-bottom: dotted 1px #4c4c4c;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd:nth-child(odd) {
    background-color: #f8f8f5;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd:last-child {
    border-bottom: none;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd a {
    color: #04756f;
    font-size: 1.2rem;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub {
    padding: 0;
    background-color: #ffffff;
    background-image: none;
    border: none;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dt {
    background-color: #46483c;
    border-bottom: solid 1px #7e7f77;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dt a {
    color: #ffffff;
    padding: 11px 35px 9.5px 20px;
    font-size: 1.3rem;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dt .c-spMenuAccordionBtn2 {
    width: 40px;
    height: 100%;
    -webkit-background-size: 20px;
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dt .c-spMenuAccordionBtn2:before {
    width: 12px;
    height: 1px;
    margin: auto;
    content: "";
    background-color: #ffffff;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dt .c-spMenuAccordionBtn2:after {
    width: 1px;
    height: 12px;
    margin: auto;
    content: "";
    background-color: #ffffff;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dd {
    display: none;
    padding: 0;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dd:nth-child(odd) {
    background-color: #f8f8f5;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl dd a {
    padding: 8px 20px 7.5px 18px;
    color: #04756f;
    font-size: 1.2rem;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl.is-open .c-spMenuAccordionBtn2:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl.is-open .c-spMenuAccordionBtn2:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub dl.is-open dd {
    display: block;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform {
    width: 29px;
    height: 29px;
    margin: 0 13px 0 0;
    display: inline-block;
    vertical-align: middle;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--1 {
    background-image: url("../img/reform/icon_reform01.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--2 {
    background-image: url("../img/reform/icon_reform02.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--3 {
    background-image: url("../img/reform/icon_reform03.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--4 {
    background-image: url("../img/reform/icon_reform04.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--5 {
    background-image: url("../img/reform/icon_reform05.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--6 {
    background-image: url("../img/reform/icon_reform06.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--7 {
    background-image: url("../img/reform/icon_reform07.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--8 {
    background-image: url("../img/reform/icon_reform08.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--9 {
    background-image: url("../img/reform/icon_reform09.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--10 {
    background-image: url("../img/reform/icon_reform10.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--11 {
    background-image: url("../img/reform/icon_reform11.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--12 {
    background-image: url("../img/reform/icon_reform12.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--13 {
    background-image: url("../img/reform/icon_reform13.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--14 {
    background-image: url("../img/reform/icon_reform14.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--15 {
    background-image: url("../img/reform/icon_reform15.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--16 {
    background-image: url("../img/reform/icon_reform16.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--17 {
    background-image: url("../img/reform/icon_reform17.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--18 {
    background-image: url("../img/reform/icon_reform18.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--19 {
    background-image: url("../img/reform/icon_reform19.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--20 {
    background-image: url("../img/reform/icon_reform20.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--21 {
    background-image: url("../img/reform/icon_reform21.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--22 {
    background-image: url("../img/reform/icon_reform22.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi.c-head05GnaviSp__navi--3 dd.c-head05GnaviSp__navi__sub .c-head05GnaviSp__navi__sub__icon_reform.c-head05GnaviSp__navi__sub__icon_reform--23 {
    background-image: url("../img/reform/icon_water.png");
  }
  .c-head05GnaviSp .c-head05GnaviSp__navi__menuTitle {
    padding: 13px 18px 10px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
    color: #ffffff;
    background-image: none;
    background-color: #3a4452;
  }
  .c-head05BtnSp {
    margin-bottom: 30px;
    padding: 10px 20px 0;
    background-color: #ffffff;
  }
  .c-head05BtnSp a {
    width: 100%;
    height: 50px;
    margin-bottom: 8px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: #ffffff;
    display: block;
  }
  .c-head05BtnSp a:before {
    margin: -3px 5px 0 0;
    content: "";
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head05BtnSp a:nth-child(1) {
    padding-top: 10px;
    letter-spacing: -1px;
    background-color: #fa5b0f;
    border: solid 3px #fdad87;
  }
  .c-head05BtnSp a:nth-child(1):before {
    width: 12px;
    height: 13px;
    background-image: url("../img/common/icon_file01.png");
  }
  .c-head05BtnSp a:nth-child(2) {
    height: 45px;
    background-color: #264028;
    letter-spacing: -1px;
    padding-top: 11px;
  }
  .c-head05BtnSp a:nth-child(2):before {
    width: 12px;
    height: 12px;
    background-image: url("../img/common/icon_tel02.png");
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
header06（くらしのサービス）
------------------------------------------------------------*/
.c-head06Pc {
  width: 95%;
  min-width: 1120px;
  height: 50px;
  margin: 0 auto 70px;
  padding: 10px 1% 0 2%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  -webkit-border-radius: 0 0 25px 25px;
  border-radius: 0 0 25px 25px;
  *zoom: 1;
}
.c-head06Pc:after {
  display: block;
  content: "";
  clear: both;
}
.c-head06Pc .c-head06Logo {
  width: 189px;
}

.c-head06GnaviPc ul {
  letter-spacing: -0.4em;
}
.c-head06GnaviPc ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head06GnaviPc ul li {
  margin-right: 15px;
}
.c-head06GnaviPc ul li a {
  color: #46483c;
  font-size: 1.4rem;
  line-height: 2.14;
  font-weight: 600;
}
.c-head06GnaviPc ul li a:before {
  margin: -3px 5px 0 0;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-head06GnaviPc ul li a:hover {
  color: #649333;
}
.c-head06GnaviPc ul li:nth-child(1) a:before {
  width: 14px;
  height: 19px;
  background-image: url("../img/common/icon_biginer01.png");
}
.c-head06GnaviPc ul li:nth-child(2) a:before {
  width: 17px;
  height: 18px;
  background-image: url("../img/common/icon_reaf01.png");
}

.c-head06CateTitle {
  height: 70px;
  padding-top: 20px;
  font-size: 2.4rem;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}

.c-head06BtnPc {
  height: 0;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}
.c-head06BtnPc .c-head06BtnPc__btn {
  width: 175px;
  height: 105px;
  background-color: #76a93b;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
.c-head06BtnPc .c-head06BtnPc__btn a {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  display: block;
  color: #ffffff;
  background-image: url("../img/common/icon_cart03.png");
  background-repeat: no-repeat;
  background-position: center 15px;
  transition: 0.1s ease-in-out;
}
.c-head06BtnPc .c-head06BtnPc__btn a:before {
  width: 95%;
  height: 95%;
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  border-left: dashed 1px #ffffff;
  border-right: dashed 1px #ffffff;
  border-bottom: dashed 1px #ffffff;
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}

@media screen and (max-width: 640px) {
  .c-head06Sp {
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeeee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    *zoom: 1;
  }
  .c-head06Sp:after {
    display: block;
    content: "";
    clear: both;
  }
  .c-head06Sp .c-head06Logo {
    width: 200px;
    max-width: 34%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 15px;
  }
  .c-head06Sp .c-head06Logo img {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .c-head06Sp .c-spMenu {
    background-color: #ffffff;
  }
  .c-head06Sp .c-headCateTitleSp {
    padding: 0;
    font-size: 1.3rem;
    line-height: 1.5;
    background-color: #faad5a;
  }
  .c-head06Sp .c-headCateTitleSp a {
    display: block;
    padding: 8px 18px 0 11px;
    height: 100%;
  }
  .c-head06Sp .c-headCateTitleSp strong {
    display: inline-block;
  }
  .c-head06Sp .c-headCateTitleSp .c-cateIcon01 {
    margin: 0 12px 5px 0;
    vertical-align: text-bottom;
  }
  .c-head06LinkSp dl {
    width: 100%;
  }
  .c-head06LinkSp dl dt {
    width: 100%;
    background-color: #8cb340;
  }
  .c-head06LinkSp dl dt a {
    padding: 10px 35px 7px 20px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white02.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 95% center;
  }
  .c-head06LinkSp dl dd {
    padding: 15px 20px 8px;
    background-color: #ffffff;
  }
  .c-head06LinkSp dl dd ul li {
    width: 49%;
    height: 45px;
    margin: 0 2% 2% 0;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }
  .c-head06LinkSp dl dd ul li a {
    width: 100%;
    height: 100%;
    padding: 2px 0 2px 55px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.928;
    display: block;
    position: relative;
  }
  .c-head06LinkSp dl dd ul li a:before {
    width: 30px;
    height: 30px;
    content: "";
    background-repeat: no-repeat;
    -webkit-background-size: 80%;
    background-size: 80%;
    background-position: center center;
    position: absolute;
    top: 8px;
    left: 15px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .c-head06LinkSp dl dd ul li:nth-child(1) a {
    color: #783146;
  }
  .c-head06LinkSp dl dd ul li:nth-child(1) a:before {
    background-color: #fc819b;
    background-image: url("../img/common/icon_ticket01.png");
  }
  .c-head06LinkSp dl dd ul li:nth-child(2) {
    margin-right: 0;
  }
  .c-head06LinkSp dl dd ul li:nth-child(2) a {
    color: #253b73;
  }
  .c-head06LinkSp dl dd ul li:nth-child(2) a:before {
    background-color: #82cdf3;
    background-image: url("../img/common/icon_hoken01.png");
  }
  .c-head06LinkSp dl dd ul li:nth-child(3) a {
    color: #154827;
  }
  .c-head06LinkSp dl dd ul li:nth-child(3) a:before {
    background-color: #68ba93;
    background-image: url("../img/common/icon_reform01.png");
  }
  .c-head06LinkSp dl dd ul li:nth-child(4) {
    margin-right: 0;
  }
  .c-head06LinkSp dl dd ul li:nth-child(4) a {
    color: #503f69;
  }
  .c-head06LinkSp dl dd ul li:nth-child(4) a:before {
    background-color: #af8ebe;
    background-image: url("../img/common/icon_sosai01.png");
  }
  .c-head06LinkSp div {
    width: 100%;
    padding: 0 20px 25px;
    background-color: #ffffff;
  }
  .c-head06LinkSp div a {
    padding: 0 0 0 10px;
    font-size: 1.4rem;
    line-height: 1.2;
    display: block;
    color: #649333;
    background-image: url("../img/common/icon_arrow-green06.png");
    background-repeat: no-repeat;
    -webkit-background-size: 8px;
    background-size: 8px;
    background-position: 0 3px;
  }
  .c-head06GnaviSp {
    margin-bottom: 20px;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi {
    width: 100%;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi a {
    padding: 10px 30px;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.5;
    color: #594242;
    display: block;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--1 {
    background-color: #faad5a;
    background-image: none;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--1 a {
    padding: 15px 35px 15px 20px;
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white02.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 96% center;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--2 {
    position: relative;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--2 a {
    position: relative;
    z-index: 10;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--2:after {
    width: 40px;
    height: 100%;
    content: "";
    background-image: url("../img/common/icon_maru-sankaku02.png");
    background-repeat: no-repeat;
    -webkit-background-size: 20px;
    background-size: 15px;
    background-position: center center;
    position: absolute;
    top: 0;
    right: 2px;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 {
    padding-bottom: 1px;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 dt {
    position: relative;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 dt a {
    width: calc(100% - 60px);
    background: none;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 dd {
    padding: 0 40px;
    background-color: rgba(255, 255, 255, 0.95);
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 dd:first-of-type {
    padding-top: 10px;
    background-color: rgba(255, 255, 255, 0.95);
    background-image: url("../img/kurashi/line_dotted01_gray.png");
    background-repeat: repeat-x;
    background-position: 0 0;
    background-size: 5px 1px;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 dd:last-of-type {
    padding-bottom: 7px;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 dd a {
    padding: 5px 17px;
    text-decoration: underline;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--3 dd a:before {
    width: 7px;
    height: 10px;
    margin: -6px 6px 0 -13px;
    content: "";
    background-image: url("../img/common/icon_arrow-orange02.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 {
    margin-top: -2px;
    background-image: none;
    border-bottom: solid 2px #faad5a;
    position: relative;
    z-index: 10;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dt {
    color: #594242;
    background-image: none;
    background-color: #fef7ee;
    position: relative;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dt em {
    padding: 15px 20px 13px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    font-style: normal;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dt .c-spMenuAccordionBtn {
    -webkit-background-size: 20px;
    background-size: 20px;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dd {
    padding: 0 40px;
    background-color: rgba(255, 255, 255, 0.95);
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dd:first-of-type {
    padding-top: 13px;
    border-top: solid 2px #dddddd;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dd:last-of-type {
    padding-bottom: 3px;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dd a {
    padding: 0 17px 10px;
    text-decoration: underline;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi.c-head06GnaviSp__navi--4 dd a:before {
    width: 7px;
    height: 10px;
    margin: -4px 6px 0 -14px;
    content: "";
    background-image: url("../img/common/icon_arrow-orange02.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head06GnaviSp > * {
    background-image: url("../img/kurashi/line_dotted01_brown.png");
    background-repeat: repeat-x;
    background-position: 0 bottom;
    background-size: 5px 1px;
  }
  .c-head06GnaviSp > *:nth-child(even).c-head06GnaviSp__navi--2 {
    background-color: #fdfbf9;
    background-image: url("../img/kurashi/line_dotted01_brown.png");
    background-repeat: repeat-x;
    background-position: 0 bottom;
  }
  .c-head06GnaviSp > *:nth-child(even).c-head06GnaviSp__navi--3 dt {
    background-color: #fdfbf9;
  }
  .c-head06GnaviSp .c-head06GnaviSp__navi__menuTitle {
    padding: 14px 20px 11px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1px;
    color: #594242;
    background-image: none;
    background-color: #fef7ee;
    border-bottom: solid 2px #faad5a;
  }
  .c-head06GnaviSp .c-head06GnaviSp__search {
    padding: 10px 20px;
    background-color: #445161;
    background-image: none;
    position: relative;
  }
  .c-head06GnaviSp .c-head06GnaviSp__search input[type=text] {
    width: 100%;
    height: 30px;
    padding: 0 30px 0 10px;
    font-size: 1.4rem;
    line-height: 2;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #dddddd;
  }
  .c-head06GnaviSp .c-head06GnaviSp__search input[type=submit] {
    width: 18px;
    height: 18px;
    display: block;
    border: none;
    background: none;
    background-image: url("../img/common/icon_search05.png");
    -webkit-background-size: cover;
    background-size: cover;
    position: absolute;
    top: 15px;
    right: 30px;
    text-indent: 1000em;
    white-space: nowrap;
    overflow: hidden;
  }
  .c-head06BtnSp {
    margin: 29px 0 35px;
    padding: 0 20px;
    background-color: #ffffff;
  }
  .c-head06BtnSp a {
    width: 100%;
    margin: 0 auto;
    padding: 9px 0 7px;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    display: block;
    color: #ffffff;
    background-color: #76a93b;
    box-shadow: 0 5px 0 #b3d378;
    position: relative;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  .c-head06BtnSp a:before {
    width: 18px;
    height: 18px;
    margin: -3px 7px 0 0;
    content: "";
    background-image: url("../img/common/icon_maru-sankaku03.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head06BtnSp a:after {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    content: "";
    border: dashed 1px #ffffff;
    position: absolute;
    top: 2px;
    left: 2px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
header07(葬祭)
------------------------------------------------------------*/
.c-head07Pc {
  width: 95%;
  min-width: 1120px;
  height: 50px;
  margin: 0 auto;
  padding: 10px 1% 0 2%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  -webkit-border-radius: 0 0 25px 25px;
  border-radius: 0 0 25px 25px;
  *zoom: 1;
}
.c-head07Pc:after {
  display: block;
  content: "";
  clear: both;
}
.c-head07Pc--2 {
  position: fixed;
}
.c-head07Pc .c-head07Logo {
  width: 189px;
}

.c-head07GnaviPc ul {
  letter-spacing: -0.4em;
}
.c-head07GnaviPc ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-head07GnaviPc ul li {
  margin-right: 15px;
}
.c-head07GnaviPc ul li a {
  color: #46483c;
  font-size: 1.4rem;
  line-height: 2.14;
  font-weight: 600;
}
.c-head07GnaviPc ul li a:before {
  margin: -3px 5px 0 0;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-head07GnaviPc ul li a:hover {
  color: #649333;
}
.c-head07GnaviPc ul li:nth-child(1) a:before {
  width: 14px;
  height: 19px;
  background-image: url("../img/common/icon_biginer01.png");
}
.c-head07GnaviPc ul li:nth-child(2) a:before {
  width: 17px;
  height: 18px;
  background-image: url("../img/common/icon_reaf01.png");
}

.c-head07CateTitle {
  height: 70px;
  padding-top: 20px;
  margin-bottom: 76px;
}

.c-sosaiNaviPc {
  width: 100%;
  min-width: 1120px;
  height: 75px;
  margin: 0 auto;
  background-color: #ffffff;
  border-top: solid 1px #e3e3e3;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 9998;
}
.c-sosaiNaviPc ul {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: table;
}
.c-sosaiNaviPc ul > * {
  display: table-cell;
}
.c-sosaiNaviPc ul li {
  margin: 0;
  height: 75px;
  font-size: 1.7rem;
  font-weight: bold;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiNaviPc ul li > span {
  width: 100%;
  height: 100%;
  padding: 20px 36px 0;
  display: block;
  text-align: center;
  color: #333745;
  white-space: nowrap;
  position: relative;
}
@media screen and (max-width: 1199px) {
  .c-sosaiNaviPc ul li > span {
    padding: 20px 30px 0;
  }
}
.c-sosaiNaviPc ul li > span:before {
  width: 1px;
  height: 10px;
  content: "";
  background-color: #dfdfdf;
  position: absolute;
  top: calc(50% - 5px);
  right: 0;
}
.c-sosaiNaviPc ul li > span:after {
  width: 7px;
  height: 15px;
  margin: 0 auto;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
}
.c-sosaiNaviPc ul li:last-child > span:before {
  content: none;
}
.c-sosaiNaviPc ul li:hover > span:after {
  width: 28px;
  height: 14px;
  background-image: url("../img/common/icon_sankaku_bage.png");
  top: auto;
  bottom: 0;
  -webkit-transform: none;
  transform: none;
}
.c-sosaiNaviPc ul li:hover .c-sosaiNaviPc__hoverMenu {
  display: block;
}
.c-sosaiNaviPc ul li.is-current > span {
  border-bottom: 3px solid #2e95a3;
}

.c-sosaiNaviPc__hoverMenu {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding: 30px 0 60px 0;
  display: none;
  background-color: #e0dccc;
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  z-index: 9999;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr {
  width: 352px;
  background-color: #ffffff;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr a {
  width: 100%;
  padding: 10px 30px;
  color: #2e95a3;
  display: block;
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: 7px;
  background-size: 7px;
  background-repeat: no-repeat;
  background-position: 94% center;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--2 {
  width: 545px;
  margin-bottom: 30px;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--3 {
  width: 545px;
  height: 120px;
  margin-bottom: 8px;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--3:nth-child(2) {
  margin: 0;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--3 img {
  width: 220px;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--3 a {
  width: 325px;
  padding: 0 30px;
  line-height: 7;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--4 {
  width: 258px;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--5 {
  margin-bottom: 30px;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--6 {
  width: 545px;
  margin: 0 auto;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--6 img {
  width: 273px;
}
.c-sosaiNaviPc__hoverMenu .c-sosaiNaviPc__hoverMenu__bnr.c-sosaiNaviPc__hoverMenu__bnr--6 a {
  width: 272px;
  padding: 0 30px;
  line-height: 7;
}

@media screen and (max-width: 640px) {
  .c-head07Sp {
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeeee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    *zoom: 1;
  }
  .c-head07Sp:after {
    display: block;
    content: "";
    clear: both;
  }
  .c-head07Sp .c-head07Logo {
    width: 200px;
    max-width: 35%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 15px;
  }
  .c-head07Sp .c-head07Logo img {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .c-head07Sp .c-spMenu {
    background-color: #ffffff;
  }
  .c-head07Sp .c-headCateTitleSp {
    padding: 14px 15px 0 12px;
    background-color: #af8ebe;
  }
  .c-head07LinkSp dl {
    width: 100%;
  }
  .c-head07LinkSp dl dt {
    width: 100%;
    background-color: #8cb340;
  }
  .c-head07LinkSp dl dt a {
    padding: 9px 35px 9px 20px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white02.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 95% center;
  }
  .c-head07LinkSp dl dd {
    padding: 15px 20px 5px;
    background-color: #ffffff;
  }
  .c-head07LinkSp dl dd ul li {
    width: 49%;
    height: 45px;
    margin: 0 2% 2% 0;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }
  .c-head07LinkSp dl dd ul li a {
    width: 100%;
    height: 100%;
    padding: 2px 0 2px 55px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.928;
    display: block;
    position: relative;
  }
  .c-head07LinkSp dl dd ul li a:before {
    width: 30px;
    height: 30px;
    content: "";
    background-repeat: no-repeat;
    -webkit-background-size: 80%;
    background-size: 80%;
    background-position: center center;
    position: absolute;
    top: 7px;
    left: 16px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .c-head07LinkSp dl dd ul li:nth-child(1) a {
    color: #783146;
  }
  .c-head07LinkSp dl dd ul li:nth-child(1) a:before {
    background-color: #fc819b;
    background-image: url("../img/common/icon_ticket01.png");
  }
  .c-head07LinkSp dl dd ul li:nth-child(2) {
    margin-right: 0;
  }
  .c-head07LinkSp dl dd ul li:nth-child(2) a {
    color: #253b73;
  }
  .c-head07LinkSp dl dd ul li:nth-child(2) a:before {
    background-color: #82cdf3;
    background-image: url("../img/common/icon_hoken01.png");
  }
  .c-head07LinkSp dl dd ul li:nth-child(3) a {
    color: #154827;
  }
  .c-head07LinkSp dl dd ul li:nth-child(3) a:before {
    background-color: #68ba93;
    background-image: url("../img/common/icon_reform01.png");
  }
  .c-head07LinkSp dl dd ul li:nth-child(4) {
    margin-right: 0;
  }
  .c-head07LinkSp dl dd ul li:nth-child(4) a {
    line-height: 1.2;
    color: #7c4b12;
    padding-top: 7px;
  }
  .c-head07LinkSp dl dd ul li:nth-child(4) a:before {
    background-color: #faad5a;
    background-image: url("../img/common/icon_kurashi01.png");
  }
  .c-head07LinkSp div {
    width: 100%;
    padding: 0 20px 12px;
    background-color: #ffffff;
  }
  .c-head07LinkSp div a {
    padding: 2px 0 0 11px;
    font-size: 1.4rem;
    line-height: 1.2;
    display: block;
    color: #649333;
    background-image: url("../img/common/icon_arrow-green06.png");
    background-repeat: no-repeat;
    -webkit-background-size: 8px;
    background-size: 8px;
    background-position: 0 5px;
  }
  .c-head07GnaviSp {
    margin-bottom: 20px;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi {
    width: 100%;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi a {
    padding: 10px 35px 9px 15px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    color: #ffffff;
    background-image: url("../img/common/icon_arrow-white03.png");
    background-repeat: no-repeat;
    -webkit-background-size: 7px;
    background-size: 7px;
    background-position: 96% center;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--1 {
    background-color: #af8ebe;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--1 a {
    font-size: 1.7rem;
    padding-top: 14px;
    padding-bottom: 12px;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--2 {
    background-color: #f2385a;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--2 a {
    font-size: 1.7rem;
    padding: 18px 35px 16px 36px;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--2 a:before {
    width: 20px;
    height: 20px;
    margin: -3px 5px 0 -20px;
    content: "";
    background-image: url("../img/common/icon_point01.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--3 {
    border-bottom: solid 1px #dddddd;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--3:last-of-type {
    border: none;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--3 dt {
    position: relative;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--3 dt a {
    width: 100%;
    color: #333745;
    background: none;
    font-size: 1.7rem;
    padding-top: 18px;
    padding-bottom: 16px;
  }
  .c-head07GnaviSp .c-head07GnaviSp__navi.c-head07GnaviSp__navi--3 dd {
    background-color: #2e95a3;
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    padding-left: 10px;
  }
  .c-head07BtnSp {
    margin-bottom: 38px;
    padding: 0 15px;
    background-color: #ffffff;
  }
  .c-head07BtnSp a {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    display: block;
    color: #ffffff;
    background-color: #0074d9;
  }
  .c-head07BtnSp a:before {
    width: 18px;
    height: 20px;
    margin: -3px 10px 0 0;
    content: "";
    background-image: url("../img/common/icon_file01.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
}
/*
------------------------------------------------------------*/
.c-header08 {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 641px) {
  .c-header08 {
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }
}
.c-header08__logo {
  display: flex;
  justify-content: center;
}
.c-header08__logo img {
  width: auto;
  height: 21.5px;
}
@media screen and (min-width: 641px) {
  .c-header08__logo img {
    height: 41px;
  }
}

/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.c-header-lp {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 40px 0;
  z-index: 999;
}
@media screen and (max-width: 640px) {
  .c-header-lp {
    padding: 20px;
  }
}
.c-header-lp__logo {
  max-width: 271px;
}
@media screen and (max-width: 640px) {
  .c-header-lp__logo {
    max-width: 160px;
  }
}

/*------------------------------------------------------------
footer(共通)
------------------------------------------------------------*/
.c-pagetop {
  width: 60px;
  position: fixed;
  bottom: 60px;
  right: 60px;
  z-index: 9000;
}

.c-footBnr {
  background-color: #ffffff;
  border-top: dashed 1px #dddddd;
  border-bottom: dashed 1px #dddddd;
}
.c-footBnr ul {
  overflow: hidden;
  border: solid 2px #8cb340;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  letter-spacing: -0.4em;
}
.c-footBnr ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-footBnr ul li {
  width: 20%;
  height: 56px;
  vertical-align: top;
  border-left: solid 2px #8cb340;
}
.c-footBnr ul li:first-child {
  border: none;
}
.c-footBnr ul li a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.c-footBnr ul li a img {
  margin: auto;
  vertical-align: bottom;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.c-footBnr ul li a:after {
  width: 14px;
  height: 14px;
  content: "";
  background-image: url("../img/common/icon_link01.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 5px;
  right: 5px;
}
.c-footBnr ul li a:hover {
  opacity: 0.5;
}

.c-footLink {
  padding: 19px 0 17px;
  background-color: #ffffff;
  border-bottom: dashed 1px #dddddd;
}
.c-footLink ul {
  text-align: center;
  letter-spacing: -0.4em;
}
.c-footLink ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-footLink ul li {
  font-size: 1.4rem;
  border-right: solid 1px #666666;
}
.c-footLink ul li:last-child {
  border: none;
}
.c-footLink ul li a {
  padding: 0 20px;
  color: #666666;
}
.c-footLink ul li a:hover {
  opacity: 1;
  text-decoration: underline;
}

.c-footCopyright {
  padding: 10px 0;
  font-size: 1.3rem;
  text-align: center;
  color: #999999;
  background-color: #f8f8f8;
}

@media screen and (max-width: 640px) {
  .c-pagetop {
    width: 40px;
    height: 40px;
    margin: 0 5px 5px auto;
    position: static;
  }
  .c-footBnr {
    border: none;
  }
  .c-footBnr ul {
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .c-footBnr ul li {
    width: 33.3333%;
    height: 38px;
  }
  .c-footBnr ul li:first-child {
    width: 50%;
    border-left: none;
    border-bottom: solid 2px #8cb340;
  }
  .c-footBnr ul li:nth-child(2) {
    width: 50%;
    border-bottom: solid 2px #8cb340;
  }
  .c-footBnr ul li:nth-child(3) {
    border: none;
  }
  .c-footBnr ul li a img {
    max-width: 80%;
    transform: none;
  }
  .c-footBnr ul li a:after {
    width: 7px;
    height: 7px;
  }
  .c-footLink {
    padding: 0;
    border: none;
  }
  .c-footLink ul {
    width: 100%;
    *zoom: 1;
  }
  .c-footLink ul:after {
    display: block;
    content: "";
    clear: both;
  }
  .c-footLink ul li {
    width: 50%;
    float: left;
    display: block;
    font-size: 1.1rem;
    border: none;
  }
  .c-footLink ul li a {
    width: 100%;
    height: 100%;
    padding: 6px 0;
    display: block;
    border-bottom: solid 1px #dddddd;
    border-right: solid 1px #dddddd;
  }
  .c-footLink ul li:nth-child(2n+1) a {
    border-right: none;
  }
  .c-footLink ul li:nth-child(1) {
    width: 100%;
  }
  .c-footCopyright {
    font-size: 0.8rem;
    padding: 7px 0;
    letter-spacing: 0.1px;
  }
  .c-footCopyright a img {
    max-width: 44%;
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 640px) {
  .c-footBnr2 {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 640px) {
  .c-footBnr2 ul {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(12, 1fr);
  }
  .c-footBnr2 ul li:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 7;
  }
  .c-footBnr2 ul li:nth-child(2) {
    grid-column-start: 7;
    grid-column-end: 13;
  }
  .c-footBnr2 ul li:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  .c-footBnr2 ul li:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 9;
  }
  .c-footBnr2 ul li:nth-child(5) {
    grid-column-start: 9;
    grid-column-end: 13;
  }
}
@media screen and (min-width: 641px) {
  .c-footBnr2 ul {
    display: grid;
    column-gap: 5px;
    grid-template-columns: repeat(5, 1fr);
  }
}
.c-footBnr2 .c-footBnr2__item {
  padding-right: 20px;
  width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #8cb340;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-footBnr2 .c-footBnr2__item {
    height: 80px;
  }
}
.c-footBnr2 .c-footBnr2__newWindow {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media screen and (min-width: 641px) {
  .c-footBnr2 .c-footBnr2__newWindow {
    width: 20px;
    height: 20px;
  }
}

.c-footLink2 {
  margin-top: 10px;
}
@media screen and (min-width: 641px) {
  .c-footLink2 {
    margin-top: 20px;
  }
}
.c-footLink2 ul {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
@media screen and (min-width: 641px) {
  .c-footLink2 ul {
    column-gap: 40px;
  }
}
.c-footLink2 ul li:not(:last-child) {
  padding-right: 10px;
  border-right: 1.5px solid #000;
}
@media screen and (min-width: 641px) {
  .c-footLink2 ul li:not(:last-child) {
    padding-right: 40px;
    border-right-width: 3px;
    border-right-color: #D9D9D9;
  }
}
.c-footLink2 ul a {
  color: #222;
  font-size: 1rem;
}
@media screen and (min-width: 641px) {
  .c-footLink2 ul a {
    font-size: 1.6rem;
  }
}

.c-footCopyright2 {
  margin-top: 10px;
  font-size: 1rem;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .c-footCopyright2 {
    margin-top: 20px;
    font-size: 1.2rem;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
footer02(サービスTOP)
------------------------------------------------------------*/
.c-foot02 {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding-top: 73px;
  background-color: #fcfbf5;
  border-top: solid 1px #649333;
}

.c-foot02NaviArea {
  margin-bottom: 74px;
}

.c-foot02Logo {
  width: 250px;
  margin-top: 7px;
}

.c-foot02Navi {
  width: 830px;
}
.c-foot02Navi dl {
  width: 250px;
}
.c-foot02Navi dl dt {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2;
  border-bottom: solid 1px #649333;
  padding-bottom: 6px;
}
.c-foot02Navi dl dt a {
  color: #46483c;
}
.c-foot02Navi dl dt a:hover {
  color: #8cb340;
}
.c-foot02Navi dl dd {
  margin-bottom: 4px;
  padding-left: 35px;
  font-size: 1.4rem;
}
.c-foot02Navi dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -15px;
  content: "";
  background-image: url("../img/common/icon_arrow-green01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot02Navi dl dd a {
  color: #46483c;
}
.c-foot02Navi dl dd a:hover {
  color: #8cb340;
}
.c-foot02Navi ul {
  width: 250px;
  margin-left: 40px;
}
.c-foot02Navi ul li {
  margin-bottom: 4px;
  padding-left: 35px;
  font-size: 1.4rem;
}
.c-foot02Navi ul li:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -15px;
  content: "";
  background-image: url("../img/common/icon_arrow-green01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot02Navi ul li a {
  color: #46483c;
}
.c-foot02Navi ul li a:hover {
  color: #8cb340;
}

@media screen and (max-width: 640px) {
  .c-foot02 {
    min-width: 100%;
    padding-top: 0;
    background-color: #ffffff;
    border: none;
    position: relative;
  }
  .c-foot02 .c-pagetop {
    position: absolute;
    top: -45px;
    right: 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
footer03（チケット）
------------------------------------------------------------*/
.c-foot03 {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding-top: 72px;
  background-color: #fcfbf5;
  border-top: solid 1px #649333;
}

.c-foot03NaviArea {
  margin-bottom: 67px;
}

.c-foot03Link {
  width: 265px;
}
.c-foot03Link .c-foot03Logo {
  width: 200px;
  margin-bottom: 37px;
  padding-top: 8px;
}
.c-foot03Link dl dt {
  margin-bottom: 10px;
  padding-bottom: 9px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  border-bottom: solid 1px #649333;
}
.c-foot03Link dl dt a {
  color: #46483c;
}
.c-foot03Link dl dt a:hover {
  color: #8cb340;
}
.c-foot03Link dl dd {
  margin-bottom: 4px;
  padding-left: 35px;
  font-size: 1.4rem;
}
.c-foot03Link dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -15px;
  content: "";
  background-image: url("../img/common/icon_arrow-green01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot03Link dl dd:last-child {
  padding-top: 26px;
}
.c-foot03Link dl dd:last-child a {
  padding-left: 4px;
}
.c-foot03Link dl dd a {
  color: #46483c;
}
.c-foot03Link dl dd a:hover {
  color: #8cb340;
}

.c-foot03Navi {
  width: 550px;
  margin-left: 20px;
  display: block;
}
.c-foot03Navi .c-foot03Navi__menuTitle {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2;
  color: #46483c;
  border-bottom: solid 1px #fc819b;
  padding-bottom: 6px;
}
.c-foot03Navi dl {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 300px;
}
.c-foot03Navi dl dt {
  width: 255px;
  margin-bottom: 1px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2;
}
.c-foot03Navi dl dt a {
  color: #46483c;
}
.c-foot03Navi dl dt a:hover {
  color: #783146;
}
.c-foot03Navi dl dd {
  width: 255px;
  margin-bottom: 4px;
  padding: 0 10px 0 23px;
  font-size: 1.4rem;
}
.c-foot03Navi dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 7px 0 -13px;
  content: "";
  background-image: url("../img/common/icon_arrow-pink02.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot03Navi dl dd a {
  color: #46483c;
}
.c-foot03Navi dl dd a:hover {
  color: #783146;
}

.c-foot03Navi2 {
  width: 285px;
  padding-left: 20px;
}
.c-foot03Navi2 dl {
  width: 265px;
}
.c-foot03Navi2 dl dt {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2;
  color: #46483c;
  border-bottom: solid 1px #fc819b;
  padding-bottom: 6px;
}
.c-foot03Navi2 dl dd {
  margin-bottom: 4px;
  padding: 0 10px 0 33px;
  font-size: 1.4rem;
}
.c-foot03Navi2 dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 5px 0 -12px;
  content: "";
  background-image: url("../img/common/icon_arrow-pink02.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot03Navi2 dl dd a {
  color: #46483c;
}
.c-foot03Navi2 dl dd a:hover {
  color: #783146;
}
.c-foot03Navi2 dl dd.c-foot03Navi2__lineBleak {
  height: 3.1em;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
}
.c-foot03Navi2 dl dd.c-foot03Navi2__lineBleak:before {
  content: none;
}
.c-foot03Navi2 dl dd.c-foot03Navi2__lineBleak02 {
  height: 1.6em;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
}
.c-foot03Navi2 dl dd.c-foot03Navi2__lineBleak02:before {
  content: none;
}

@media screen and (max-width: 640px) {
  .c-foot03 {
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 50px;
    background-color: #ffffff;
    border: none;
  }
  .c-ticketFlortingBnr {
    width: 100%;
    height: 55px;
    overflow: hidden;
    position: fixed;
    top: auto;
    bottom: 0;
    z-index: 9990;
    letter-spacing: -0.4em;
  }
  .c-ticketFlortingBnr > * {
    display: inline-block;
    letter-spacing: normal;
  }
  .c-ticketFlortingBnr a {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: -0.1em;
    color: #ffffff;
    text-align: center;
    vertical-align: top;
    background-color: #ffff9a;
    border: solid 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .c-ticketFlortingBnr a:nth-child(1) {
    line-height: 1.32;
    background-color: #ff6e03;
    border-color: #ffa257;
  }
  .c-ticketFlortingBnr a:nth-child(1) span {
    width: 29px;
    height: 28px;
    background-image: url("../img/common/icon_cart01.png");
    -webkit-background-size: 28px;
    background-size: 28px;
    background-repeat: no-repeat;
    background-position: 0 2px;
    margin-right: 2px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
footer04（保険）
------------------------------------------------------------*/
.c-foot04 {
  margin: 0 auto;
  width: 100%;
  min-width: 1120px;
}

.c-foot04NaviArea {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
}
@media screen and (max-width: 640px) {
  .c-foot04NaviArea {
    display: none;
  }
}

.c-foot04BottomArea {
  padding-top: 40px;
}
@media screen and (min-width: 641px) {
  .c-foot04BottomArea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.c-foot04Link {
  padding-top: 5px;
  padding-right: 30px;
  padding-bottom: 10px;
  width: 227px;
  border-right: 3px solid #D9D9D9;
}
.c-foot04Link dl {
  margin-top: 30px;
}
.c-foot04Link dl dt {
  font-size: 1.6rem;
  font-weight: bold;
}
.c-foot04Link dl dt:not(:first-of-type) {
  margin-top: 35px;
}
.c-foot04Link dl dt a {
  color: #68AA23;
}
.c-foot04Link dl dd {
  margin-top: 15px;
  padding-left: 10px;
  font-size: 1.6rem;
}
.c-foot04Link dl dd a {
  color: #222;
}
@media screen and (min-width: 641px) {
  .c-foot04Link dl dd a:hover {
    text-decoration: underline;
  }
}
.c-foot04Link dl dd ul li {
  margin-top: 10px;
}

.c-foot04Navi {
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.c-foot04Navi dl dt {
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: bold;
}
.c-foot04Navi dl dt:not(:first-of-type) {
  margin-top: 30px;
}
.c-foot04Navi dl dt a {
  color: inherit;
}
.c-foot04Navi dl dd {
  margin-top: 15px;
  padding-left: 10px;
  font-size: 1.6rem;
}
.c-foot04Navi dl dd a {
  color: #222;
}
.c-foot04Navi dl dd a:hover {
  text-decoration: underline;
}
.c-foot04Navi dl dd ul li {
  margin-top: 10px;
}
.c-foot04Navi__memo {
  font-size: 1.2rem;
  color: #222;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .c-foot04 {
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 40px;
    background-color: #ffffff;
    border: none;
  }
  .c-hokenFlortingBnr {
    width: calc(100% - 120px);
    background: #fff;
    overflow: hidden;
    position: fixed;
    top: auto;
    bottom: 0;
    z-index: 9990;
    display: flex;
    column-gap: 1px;
  }
  .c-hokenFlortingBnr a {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
  }
  .c-hokenFlortingBnr__reserve {
    background: #FA8246;
    color: #fff;
  }
  .c-hokenFlortingBnr__document {
    background: #41B4E6;
    color: #fff;
  }
  .c-hokenFlortingBnr__chat {
    background: #E4F7FF;
    color: #41B4E6;
  }
  .c-hokenFlortingBnrGhost {
    height: 20px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
footer05（リフォーム）
------------------------------------------------------------*/
.c-foot05 {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding-top: 72px;
  background-color: #fcfbf5;
  border-top: solid 1px #649333;
}

.c-foot05NaviArea {
  margin-bottom: 70px;
}

.c-foot05Link {
  width: 208px;
}
.c-foot05Link .c-foot05Logo {
  width: 200px;
  margin-bottom: 37px;
  padding-top: 8px;
}
.c-foot05Link dl dt {
  margin-bottom: 10px;
  padding-bottom: 9px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  border-bottom: solid 1px #649333;
}
.c-foot05Link dl dt a {
  color: #46483c;
}
.c-foot05Link dl dt a:hover {
  color: #8cb340;
}
.c-foot05Link dl dd {
  margin-bottom: 4px;
  padding-left: 35px;
  font-size: 1.4rem;
}
.c-foot05Link dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -15px;
  content: "";
  background-image: url("../img/common/icon_arrow-green01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot05Link dl dd:last-child {
  padding-top: 26px;
}
.c-foot05Link dl dd:last-child a {
  padding-left: 3px;
}
.c-foot05Link dl dd a {
  color: #46483c;
}
.c-foot05Link dl dd a:hover {
  color: #8cb340;
}

.c-foot05Navi {
  width: 436px;
  margin-left: 20px;
}
.c-foot05Navi .c-foot05Navi__menuTitle {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2;
  color: #46483c;
  border-bottom: solid 1px #68ba93;
  padding-bottom: 6px;
}
.c-foot05Navi dl {
  width: 195px;
  margin-left: 10px;
}
.c-foot05Navi dl:not(:first-of-type) {
  margin-left: 35px;
}
.c-foot05Navi dl dt {
  margin-bottom: 1px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2;
}
.c-foot05Navi dl dt:not(:first-of-type) {
  margin-top: 8px;
}
.c-foot05Navi dl dt a {
  color: #46483c;
}
.c-foot05Navi dl dt a:hover {
  color: #68ba93;
}
.c-foot05Navi dl dd {
  margin-bottom: 4px;
  padding: 0 0px 0 22px;
  font-size: 1.4rem;
}
.c-foot05Navi dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 7px 0 -12px;
  content: "";
  background-image: url("../img/common/icon_arrow-green07.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot05Navi dl dd a {
  color: #46483c;
}
.c-foot05Navi dl dd a:hover {
  color: #68ba93;
}

.c-foot05Navi2 {
  width: 228px;
  padding-left: 20px;
}
.c-foot05Navi2 dl {
  width: 208px;
}
.c-foot05Navi2 dl dt {
  margin-bottom: 13px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2;
  color: #46483c;
  border-bottom: solid 1px #68ba93;
  padding-bottom: 6px;
}
.c-foot05Navi2 dl dd {
  margin-bottom: 8px;
  padding: 0 6px 0 32px;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.c-foot05Navi2 dl dd:not(:first-of-type) {
  padding: 0 0 0 32px;
}
.c-foot05Navi2 dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -12px;
  content: "";
  background-image: url("../img/common/icon_arrow-green07.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot05Navi2 dl dd a {
  color: #46483c;
}
.c-foot05Navi2 dl dd a:hover {
  color: #68ba93;
}

@media screen and (max-width: 640px) {
  .c-foot05 {
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 50px;
    background-color: #ffffff;
    border: none;
  }
  .c-reformFlortingBnr {
    width: 100%;
    height: 50px;
    overflow: hidden;
    position: fixed;
    top: auto;
    bottom: 0;
    z-index: 9990;
    letter-spacing: -0.4em;
  }
  .c-reformFlortingBnr > * {
    display: inline-block;
    letter-spacing: normal;
  }
  .c-reformFlortingBnr a {
    width: 50%;
    height: 100%;
    padding: 11px 0 10px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    vertical-align: top;
  }
  .c-reformFlortingBnr a:before {
    margin: -3px 5px 0 0;
    content: "";
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-reformFlortingBnr a:nth-child(1) {
    line-height: 2.64;
    background-color: #264028;
  }
  .c-reformFlortingBnr a:nth-child(1):before {
    width: 12px;
    height: 11px;
    background-image: url("../img/common/icon_tel02.png");
  }
  .c-reformFlortingBnr a:nth-child(2) {
    line-height: 1.32;
    background-color: #fa5b0f;
  }
  .c-reformFlortingBnr a:nth-child(2) span {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
  .c-reformFlortingBnr a:nth-child(2):before {
    width: 12px;
    height: 13px;
    background-image: url("../img/common/icon_file01.png");
  }
}
/*
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
footer06（くらしのサービス）
------------------------------------------------------------*/
.c-foot06 {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding-top: 70px;
  background-color: #fcfbf5;
  border-top: solid 1px #649333;
}

.c-foot06NaviArea {
  margin-bottom: 70px;
}

.c-foot06Link {
  width: 208px;
}
.c-foot06Link .c-foot06Logo {
  width: 200px;
  margin-bottom: 30px;
  padding-top: 5px;
}
.c-foot06Link dl dt {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  border-bottom: solid 1px #649333;
}
.c-foot06Link dl dt a {
  color: #46483c;
}
.c-foot06Link dl dt a:hover {
  color: #8cb340;
}
.c-foot06Link dl dd {
  margin-bottom: 10px;
  padding-left: 35px;
  font-size: 1.4rem;
}
.c-foot06Link dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -15px;
  content: "";
  background-image: url("../img/common/icon_arrow-green01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot06Link dl dd:last-child {
  padding-top: 26px;
}
.c-foot06Link dl dd a {
  color: #46483c;
}
.c-foot06Link dl dd a:hover {
  color: #8cb340;
}

.c-foot06Navi01 {
  width: 654px;
  margin-left: 30px;
}
.c-foot06Navi01 .c-foot06Navi01__content {
  margin-left: 10px;
  display: block;
}
.c-foot06Navi01 .c-foot06Navi01__content dl {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.c-foot06Navi01 .c-foot06Navi01__content dt {
  width: 218px;
  margin-bottom: 5px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2;
}
.c-foot06Navi01 .c-foot06Navi01__content dt a {
  color: #46483c;
}
.c-foot06Navi01 .c-foot06Navi01__content dt a:hover {
  color: #f0662b;
}
.c-foot06Navi01 .c-foot06Navi01__content dd {
  width: 218px;
  margin-bottom: 10px;
  padding: 0 10px 0 25px;
  font-size: 1.4rem;
}
.c-foot06Navi01 .c-foot06Navi01__content dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 5px 0 -12px;
  content: "";
  background-image: url("../img/common/icon_arrow-orange01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot06Navi01 .c-foot06Navi01__content dd a {
  color: #46483c;
}
.c-foot06Navi01 .c-foot06Navi01__content dd a:hover {
  color: #f0662b;
}

.c-foot06Navi02 {
  width: 228px;
  padding-left: 20px;
}
.c-foot06Navi02 dd {
  margin-bottom: 10px;
  padding: 0 10px 0 25px;
  font-size: 1.4rem;
}
.c-foot06Navi02 dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 5px 0 -12px;
  content: "";
  background-image: url("../img/common/icon_arrow-orange01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot06Navi02 dd a {
  color: #46483c;
}
.c-foot06Navi02 dd a:hover {
  color: #f0662b;
}
.c-foot06Navi02 dd.c-foot06Navi2__lineBleak {
  height: 1em;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
}
.c-foot06Navi02 dd.c-foot06Navi2__lineBleak:before {
  content: none;
}

.c-foot06Navi__menuTitle {
  margin-bottom: 15px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2;
  color: #46483c;
  border-bottom: solid 1px #faad5a;
}

@media screen and (max-width: 640px) {
  .c-foot06 {
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 50px;
    background-color: #ffffff;
    border: none;
  }
  .c-kurashiFlortingBnr {
    width: 100%;
    height: 50px;
    overflow: hidden;
    position: fixed;
    top: auto;
    bottom: 0;
    z-index: 9990;
  }
  .c-kurashiFlortingBnr a {
    width: 100%;
    height: 47px;
    padding: 0;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.93;
    color: #ffffff;
    display: block;
    text-align: center;
    background-color: #76a93b;
    box-shadow: 0 3px 0 #b3d378;
    position: relative;
  }
  .c-kurashiFlortingBnr a:before {
    width: 15px;
    height: 15px;
    margin: -2px 5px 0 0;
    content: "";
    background-image: url("../img/common/icon_maru-sankaku03.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .c-kurashiFlortingBnr a:after {
    width: 110%;
    height: 41px;
    content: "";
    position: absolute;
    top: 3px;
    left: -5%;
    border: dashed 1px #ffffff;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
footer07(葬祭)
------------------------------------------------------------*/
.c-foot07 {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding-top: 70px;
  background-color: #fcfbf5;
  border-top: solid 1px #649333;
}

.c-foot07NaviArea {
  margin-bottom: 70px;
}

.c-foot07Link {
  width: 208px;
}
.c-foot07Link .c-foot07Logo {
  width: 200px;
  margin-bottom: 30px;
  padding-top: 5px;
}
.c-foot07Link dl dt {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  border-bottom: solid 1px #649333;
}
.c-foot07Link dl dt a {
  color: #46483c;
}
.c-foot07Link dl dt a:hover {
  color: #8cb340;
}
.c-foot07Link dl dd {
  margin-bottom: 10px;
  padding-left: 35px;
  font-size: 1.4rem;
}
.c-foot07Link dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -15px;
  content: "";
  background-image: url("../img/common/icon_arrow-green01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot07Link dl dd:last-child {
  padding-top: 26px;
}
.c-foot07Link dl dd:last-child a {
  padding-left: 4px;
}
.c-foot07Link dl dd a {
  color: #46483c;
}
.c-foot07Link dl dd a:hover {
  color: #8cb340;
}

.c-foot07Navi {
  width: 912px;
}
.c-foot07Navi dl {
  width: 208px;
  margin-left: 20px;
}
.c-foot07Navi dl dt {
  margin-bottom: 15px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2;
  border-bottom: solid 1px #af8ebe;
}
.c-foot07Navi dl dt.c-foot07Navi__noBorder {
  margin-bottom: 5px;
  font-size: 1.4rem;
  border: none;
}
.c-foot07Navi dl dt a {
  color: #46483c;
}
.c-foot07Navi dl dt a:hover {
  color: #af8ebe;
}
.c-foot07Navi dl dt span {
  color: #46483c;
}
.c-foot07Navi dl dd {
  margin-bottom: 10px;
  padding: 0 15px 0 35px;
  font-size: 1.4rem;
}
.c-foot07Navi dl dd:before {
  width: 5px;
  height: 12px;
  margin: -2px 10px 0 -15px;
  content: "";
  background-image: url("../img/common/icon_sankaku_purple.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-foot07Navi dl dd a {
  color: #46483c;
}
.c-foot07Navi dl dd a:hover {
  color: #af8ebe;
}
.c-foot07Navi dl.mt10 {
  margin-top: 10px;
}

.c-sousaiFlortingBnr {
  width: 60px;
  height: 364px;
  background-color: #ffffff;
  position: fixed;
  top: calc((100% - 270px) / 2);
  right: 0;
  z-index: 9990;
}
.c-sousaiFlortingBnr .c-sousaiFlortingBnr__1 {
  width: 100%;
  height: 166px;
  background-color: #f2385a;
  background-image: url("../img/common/icon_point01.png");
  -webkit-background-size: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center 20px;
}
.c-sousaiFlortingBnr .c-sousaiFlortingBnr__2 {
  width: 100%;
  height: 240px;
  background-color: #0074d9;
  background-image: url("../img/common/icon_file01.png");
  -webkit-background-size: 18px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center 20px;
}
.c-sousaiFlortingBnr a {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  display: block;
  color: #ffffff;
}
.c-sousaiFlortingBnr a span {
  -webkit-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
}
@media screen and (max-width: 640px) {
  .c-sousaiFlortingBnr a span {
    -webkit-writing-mode: rl-tb;
    -ms-writing-mode: rl-tb;
    writing-mode: rl-tb;
  }
}

@media screen and (max-width: 640px) {
  .c-foot07 {
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 50px;
    background-color: #ffffff;
    border: none;
  }
  .c-sousaiFlortingBnr {
    width: 100%;
    height: 50px;
    top: auto;
    bottom: 0;
    letter-spacing: -0.4em;
  }
  .c-sousaiFlortingBnr > * {
    display: inline-block;
    letter-spacing: normal;
  }
  .c-sousaiFlortingBnr .c-sousaiFlortingBnr__1 {
    width: 50%;
    height: 100%;
    background-image: none;
  }
  .c-sousaiFlortingBnr .c-sousaiFlortingBnr__1 a:before {
    width: 20px;
    height: 20px;
    background-image: url("../img/common/icon_point01.png");
  }
  .c-sousaiFlortingBnr .c-sousaiFlortingBnr__2 {
    width: 50%;
    height: 100%;
    background-image: none;
  }
  .c-sousaiFlortingBnr .c-sousaiFlortingBnr__2 a {
    font-size: 1.2rem;
    padding: 4px 0;
  }
  .c-sousaiFlortingBnr .c-sousaiFlortingBnr__2 a:before {
    width: 18px;
    height: 20px;
    background-image: url("../img/common/icon_file01.png");
  }
  .c-sousaiFlortingBnr a {
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 1.4rem;
    line-height: 3.57;
  }
  .c-sousaiFlortingBnr a:before {
    margin: -3px 5px 0 0;
    content: "";
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
}
.page-sosai__index .is-move {
  transition-delay: 2s;
}

@media screen and (min-width: 641px) {
  .js-move {
    right: -60px;
  }
  .js-move.is-move {
    transition-duration: 0.7s;
    right: 0;
  }
}

/*------------------------------------------------------------
footer08
------------------------------------------------------------*/
.c-footer08.is-fixed {
  width: 100%;
  left: 0;
  bottom: 0;
  position: fixed;
}

/*------------------------------------------------------------
footer09
------------------------------------------------------------*/
.c-footer09 {
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 8px;
  background-color: #EFEFEF;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .c-footer09 {
    margin-top: 80px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.c-footer09__logo img {
  width: 140px;
  height: auto;
}
@media screen and (min-width: 641px) {
  .c-footer09__logo img {
    width: 272px;
  }
}
.c-footer09__copyright {
  margin-top: 1px;
  color: #999;
  font-size: 0.9rem;
}
@media screen and (min-width: 641px) {
  .c-footer09__copyright {
    margin-top: 10px;
    color: #000;
    font-size: 1.2rem;
    font-weight: 400;
  }
}

/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.c-footer-lp__nav {
  background: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px dotted #ddd;
  border-bottom: 1px dotted #ddd;
  padding: 22px 0 20px;
}
@media screen and (max-width: 640px) {
  .c-footer-lp__nav {
    flex-wrap: wrap;
    border: 0;
    padding: 0;
  }
}
.c-footer-lp__nav li {
  position: relative;
  padding: 0 11px;
}
@media screen and (max-width: 640px) {
  .c-footer-lp__nav li {
    width: 50%;
    text-align: center;
    padding: 7px 0 6px;
    border-bottom: 1px solid #ddd;
  }
}
.c-footer-lp__nav li:before {
  content: "";
  width: 2px;
  height: 78%;
  background: #c7c7c5;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 640px) {
  .c-footer-lp__nav li:before {
    content: none;
  }
}
@media screen and (max-width: 640px) {
  .c-footer-lp__nav li:first-child {
    width: 100%;
    border-top: 1px solid #ddd;
  }
}
.c-footer-lp__nav li:first-child:before {
  content: none;
}
@media screen and (max-width: 640px) {
  .c-footer-lp__nav li:last-child {
    border-left: 1px solid #ddd;
  }
}
.c-footer-lp__nav li a {
  font-size: 1.4rem;
  line-height: 1;
  color: #595950;
}
@media screen and (max-width: 640px) {
  .c-footer-lp__nav li a {
    font-size: 1.1rem;
  }
}
.c-footer-lp__text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #999;
  text-align: center;
  padding: 12px 0;
}
@media screen and (max-width: 640px) {
  .c-footer-lp__text {
    font-size: 1rem;
    padding: 2px 0 6px;
  }
}

.c-footer-lp2 {
  background: #f8f8f8;
}
.c-footer-lp2__logo {
  display: none;
}
@media screen and (max-width: 640px) {
  .c-footer-lp2__logo {
    display: block;
    text-align: center;
    padding: 10px 0 0;
  }
}
@media screen and (max-width: 640px) {
  .c-footer-lp2__logo img {
    max-width: 180px;
  }
}

/*------------------------------------------------------------
side(共通)
------------------------------------------------------------*/
.c-side__bnr {
  margin-top: 20px;
}
.c-side__bnr a {
  margin: 0 auto 10px auto;
  display: block;
}
.c-side__bnr a:last-child {
  margin: 0 auto;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
side03（チケット）
------------------------------------------------------------*/
.c-side03 .c-sideNavi03__btn01 {
  width: 240px;
  height: 74px;
  margin-bottom: 25px;
  color: #ffffff;
  background-color: #ff6e03;
  position: relative;
}
.c-side03 .c-sideNavi03__btn01 a {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  display: block;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 3.8;
  color: #ffffff;
  border: solid 3px #ffa257;
  position: relative;
  z-index: 10;
}
.c-side03 .c-sideNavi03__btn01 span {
  width: 31px;
  height: 31px;
  font-size: 1.3rem;
  font-weight: normal;
  text-align: center;
  background-image: url("../img/common/icon_cart01.png");
  -webkit-background-size: 28px;
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: 0 2px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.c-side03 .c-sideNavi03__btn02 {
  width: 240px;
  height: 110px;
  margin-bottom: 6px;
  text-align: center;
  background-color: #ffff9a;
}
.c-side03 .c-sideNavi03__btn02 a {
  width: 100%;
  height: 100%;
  padding-top: 15px;
  display: block;
  color: #666666;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6;
  border: solid 3px #ffea61;
}
.c-side03 .c-sideNavi03__btn02 a b {
  font-size: 1.8rem;
  font-weight: 500;
  display: block;
}
.c-side03 .c-sideNavi03__btn02 a span {
  margin-top: 5px;
  color: #ff6e03;
  display: block;
}
.c-side03 .c-sideNavi03__btn02 a span:before {
  width: 16px;
  height: 20px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_door01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-side03 .c-sideNavi03__link01 {
  margin-bottom: 13px;
  font-size: 1.5rem;
  font-weight: 500;
}
.c-side03 .c-sideNavi03__link01:before {
  width: 6px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-pink01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-side03 .c-sideNavi03__link01 a {
  color: #308bbb;
  text-decoration: underline;
}
.c-side03 .c-sideNavi03__link01 a:hover {
  opacity: 1;
  text-decoration: none;
}
.c-side03 .c-side03Navi .c-side03Navi__title {
  margin-bottom: 7px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #232934;
}
.c-side03 .c-side03Navi .c-side03Navi__title:before {
  margin: -3px 10px 0 0;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-side03 .c-side03Navi .c-side03Navi__title.c-side03Navi__title--1:before {
  width: 20px;
  height: 20px;
  background-image: url("../img/common/icon_search03.png");
}
.c-side03 .c-side03Navi .c-side03Navi__title.c-side03Navi__title--2:before {
  width: 24px;
  height: 24px;
  background-image: url("../img/common/icon_ticket03.png");
}
.c-side03 .c-side03Navi .l-right {
  margin-bottom: 17px;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
side04（保険）
------------------------------------------------------------*/
.c-side04 .c-side04__box01 {
  width: 240px;
  margin-bottom: 28px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  background-color: #ffffff;
  border: solid 1px #82cdf3;
  overflow: hidden;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-side04 .c-side04__box01 dt {
  padding: 11px 0;
  color: #2579be;
  background-color: #f6fbfe;
  border-bottom: solid 1px #dddddd;
}
.c-side04 .c-side04__box01 dt span {
  width: 65px;
  margin-right: 5px;
  font-size: 2.4rem;
  color: #ff4433;
  display: inline-block;
  text-align: right;
}
.c-side04 .c-side04__box01 dd {
  padding: 21px 19px;
}
.c-side04 .c-side04__box01 dd a {
  width: 100%;
  padding: 16px 0 15px;
  display: block;
  font-size: 1.8rem;
  color: #ffffff;
  background-image: url("../img/hoken/bg01.jpg");
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.c-side04 .c-side04__box01 dd a:before {
  width: 40px;
  height: 40px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #ffffff;
  background-image: url("../img/common/icon_file02.png");
  -webkit-background-size: 18px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.c-side04 .c-side04Navi .l-right {
  margin-bottom: 20px;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
side05（リフォーム）
------------------------------------------------------------*/
.c-side05 .c-side05__btn01 {
  width: 240px;
  height: 90px;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  background-color: #fa5b0f;
  position: relative;
}
.c-side05 .c-side05__btn01:after {
  width: 26px;
  height: 28px;
  content: "";
  background-image: url("../img/common/icon_file01.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 31px;
  right: 20px;
}
.c-side05 .c-side05__btn01 a {
  width: 100%;
  height: 100%;
  padding: 20px 0 0 20px;
  color: #ffffff;
  display: block;
  position: relative;
  z-index: 10;
  border: solid 6px #fdad87;
  line-height: 1.3;
}
.c-side05 .c-side05__btn01 span {
  width: 22px;
  height: 22px;
  color: #ffffff;
  background-color: #a60303;
  font-size: 1.4rem;
  line-height: 1.57;
  text-align: center;
  position: absolute;
  top: 22px;
  right: 8px;
  z-index: 5;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.c-side05 .c-side05Navi {
  margin-bottom: 20px;
}
.c-side05 .c-side05Navi .c-side05Navi__title {
  padding: 14px 0 12px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #4daaab;
  text-align: center;
}
.c-side05 .c-side05Navi .c-side05Navi__title:before {
  width: 22px;
  height: 21px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_kougu01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-side05 .c-side05Navi .c-side05__box {
  border: solid 1px rgba(58, 68, 82, 0.4);
}
.c-side05 .c-side05Navi .c-side05__box dt {
  padding: 7px 0;
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #3a4452;
  text-align: center;
}
.c-side05 .c-side05Navi .c-side05__box dd {
  padding: 15px 19px;
  background-color: #ffffff;
}
.c-side05 .c-side05Navi .c-side05__box dd a {
  margin-bottom: 6px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #04756f;
  text-decoration: underline;
  display: block;
}
.c-side05 .c-side05Navi .c-side05__box dd a:hover {
  opacity: 1;
  text-decoration: none;
}
.c-side05 .c-side05Navi .c-side05__box dd a:last-child {
  margin: 0;
}
.c-side05 .c-side05Navi .c-side05__box dd a:before {
  width: 6px;
  height: 10px;
  margin: -2px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow_green03_21_35.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-side05 .c-side05Navi .l-right {
  margin-bottom: 20px;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
side06（くらしのサービス）
------------------------------------------------------------*/
.c-side06 .c-side06__box01 {
  width: 240px;
  margin-bottom: 23px;
  font-size: 1.6rem;
  font-weight: bold;
  background-color: #ffffff;
  border: solid 1px #dddddd;
}
.c-side06 .c-side06__box01 dt {
  padding: 15px 0 10px 20px;
  font-size: 1.8rem;
  color: #733e01;
  background-color: #f9f5ef;
}
.c-side06 .c-side06__box01 dt:before {
  width: 24px;
  height: 26px;
  margin: -5px 5px 0 0;
  content: "";
  background-image: url("../img/common/icon_cart02.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-side06 .c-side06__box01 dt span {
  width: 79px;
  font-size: 2.6rem;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
}
.c-side06 .c-side06__box01 dd {
  padding: 19px 20px 22px;
}
.c-side06 .c-side06__box01 dd .c-side06__btn {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  font-size: 1.8rem;
  text-align: center;
  display: block;
  color: #ffffff;
  background-color: #76a93b;
  box-shadow: 0 3px 0 #b3d378;
  position: relative;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-side06 .c-side06__box01 dd .c-side06__btn:hover {
  box-shadow: none;
  opacity: 1;
  transform: translate(0, 3px);
}
.c-side06 .c-side06__box01 dd .c-side06__btn:before {
  width: 20px;
  height: 20px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_maru-sankaku03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-side06 .c-side06__box01 dd .c-side06__btn:after {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  content: "";
  border: dashed 1px #ffffff;
  position: absolute;
  top: 2px;
  left: 2px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-side06 .c-side06__title {
  width: 240px;
  margin-bottom: 20px;
  padding-bottom: 7px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #733e01;
  border-bottom: solid 3px #faad5a;
}
.c-side06 .c-side06__title.c-side06__title--2 {
  margin: 0;
}
.c-side06 .c-side06__search {
  width: 240px;
  margin-bottom: 20px;
  position: relative;
}
.c-side06 .c-side06__search input[type=text] {
  width: 100%;
  height: 40px;
  padding: 0 45px 0 10px;
  font-size: 1.4rem;
  line-height: 2;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #445161;
}
.c-side06 .c-side06__search input[type=submit] {
  width: 40px;
  height: 40px;
  display: block;
  border: none;
  background-color: #445161;
  background-image: url("../img/common/icon_search04.png");
  -webkit-background-size: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  right: 0;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.c-side06 .c-side06Navi {
  margin-bottom: 25px;
}
.c-side06 .c-side__bnr {
  margin-top: -3px;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
breadcrumb
------------------------------------------------------------*/
.c-breadcrumb1 {
  padding: 11px 0 60px 0;
  color: #46483c;
}
.c-breadcrumb1 ul {
  letter-spacing: -0.4em;
}
.c-breadcrumb1 ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-breadcrumb1 ul li {
  font-size: 1.2rem;
  font-weight: 500;
}
.c-breadcrumb1 ul li:after {
  width: 6px;
  height: 6px;
  margin: 0 0.5em;
  content: "";
  border-top: 1px solid #46483c;
  border-right: 1px solid #46483c;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
  vertical-align: middle;
}
.c-breadcrumb1 ul li:last-child:after {
  content: none;
}
.c-breadcrumb1 ul li a {
  color: #46483c;
  text-decoration: none;
}
.c-breadcrumb1 ul li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.c-breadcrumb1--service {
  padding: 15px 0 40px 0;
}
.c-breadcrumb1--service ul li a {
  color: #308bbb;
}
.c-breadcrumb1--ticket ul li a {
  color: #308bbb;
}
.c-breadcrumb1--hoken ul li a {
  color: #253b73;
}
.c-breadcrumb1--kurashi {
  padding: 42px 0 55px 0;
  color: #594242;
}
.c-breadcrumb1--kurashi ul li:after {
  border-top: 1px solid #999999;
  border-right: 1px solid #999999;
}
.c-breadcrumb1--kurashi ul li a {
  color: #999999;
}
.c-breadcrumb1--sosai {
  color: #646464;
}
.c-breadcrumb1--sosai ul li:after {
  border-top: 1px solid #646464;
  border-right: 1px solid #646464;
}
.c-breadcrumb1--sosai ul li a {
  color: #646464;
}

.c-breadcrumb2 {
  margin-bottom: 30px;
  color: #41B4E6;
}
@media screen and (min-width: 641px) {
  .c-breadcrumb2 {
    margin-bottom: 80px;
  }
}
.c-breadcrumb2 ul {
  font-size: 1.2rem;
}
@media screen and (min-width: 641px) {
  .c-breadcrumb2 ul {
    font-size: 1.6rem;
  }
}
.c-breadcrumb2 ul :not(:last-child)::after {
  content: ">";
}
.c-breadcrumb2 ul li {
  display: inline;
}
.c-breadcrumb2 ul li a {
  color: inherit;
  text-decoration: none;
}
.c-breadcrumb2 ul li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.c-breadcrumb2--service {
  padding: 15px 0 40px 0;
}
.c-breadcrumb2--service ul li a {
  color: #308bbb;
}
.c-breadcrumb2--ticket ul li a {
  color: #308bbb;
}
.c-breadcrumb2--hoken ul li a {
  color: #253b73;
}
.c-breadcrumb2--kurashi {
  padding: 42px 0 55px 0;
  color: #594242;
}
.c-breadcrumb2--kurashi ul li:after {
  border-top: 1px solid #999999;
  border-right: 1px solid #999999;
}
.c-breadcrumb2--kurashi ul li a {
  color: #999999;
}
.c-breadcrumb2--sosai {
  color: #646464;
}
.c-breadcrumb2--sosai ul li:after {
  border-top: 1px solid #646464;
  border-right: 1px solid #646464;
}
.c-breadcrumb2--sosai ul li a {
  color: #646464;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
layout
------------------------------------------------------------*/
.l-contentCorporate {
  width: 100%;
  padding-top: 70px;
}

.l-contentService {
  width: 100%;
}

.l-contentService2 {
  width: 100%;
  padding-top: 50px;
}

.l-contentService3 {
  width: 100%;
  position: relative;
}

.l-contentServiceIn {
  width: 1120px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
}

.l-contentServiceMain {
  width: 840px;
}

.l-contentServiceSide {
  width: 240px;
  padding-bottom: 100px;
}

@media screen and (max-width: 640px) {
  .l-contentCorporate {
    padding-top: 50px;
  }
  .l-contentService {
    padding-top: 50px;
  }
  .l-contentServiceIn {
    width: 100%;
    display: inherit;
  }
  .l-contentServiceMain {
    width: 100%;
  }
  .l-contentServiceSide {
    display: none;
  }
}
/*コンポーネント
------------------------------------------------------------*/
.l-container {
  width: 1120px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .l-container {
    width: 100%;
  }
}

.l-left {
  width: 840px;
}
@media screen and (max-width: 640px) {
  .l-left {
    width: 100%;
  }
}

.l-right {
  width: 240px;
}
@media screen and (max-width: 640px) {
  .l-right {
    width: 100%;
  }
}

.l-contentService02 {
  padding-top: 50px;
}
@media screen and (min-width: 641px) {
  .l-contentService02 {
    padding-top: 60px;
  }
}

.l-contentService03 {
  padding-top: 90px;
}
@media screen and (min-width: 641px) {
  .l-contentService03 {
    padding-top: 180px;
  }
}

.l-contentService04 {
  padding-top: 70px;
}
@media screen and (min-width: 641px) {
  .l-contentService04 {
    padding-top: 100px;
  }
}

.l-contentService05 {
  padding-top: 30px;
}
@media screen and (min-width: 641px) {
  .l-contentService05 {
    padding-top: 80px;
  }
}

.l-container02 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
@media screen and (max-width: 640px) {
  .l-container02 {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.l-container03 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}
@media screen and (max-width: 640px) {
  .l-container03 {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*サービスサイト*/
.c-serviceBtn01 {
  width: 236px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7;
  text-align: center;
  background-color: #8cb340;
  background-image: url("../img/common/icon_arrow-white02.png");
  -webkit-background-size: 7px;
  background-size: 7px;
  background-repeat: no-repeat;
  background-position: 95% center;
  border: solid 2px #8cb340;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  transition: 0.3s ease-in-out;
}
.c-serviceBtn01 a {
  width: 100%;
  height: 100%;
  padding: 3px 20px;
  color: #ffffff;
  display: block;
}
.c-serviceBtn01:hover {
  background-color: #ffffff;
  background-image: url("../img/common/icon_arrow-green04.png");
}
.c-serviceBtn01:hover a {
  color: #8cb340;
}

.c-serviceBtn02 {
  width: 380px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  background-color: #8cb340;
  background-image: url("../img/common/icon_arrow-white02.png");
  -webkit-background-size: 7px;
  background-size: 7px;
  background-repeat: no-repeat;
  background-position: 95% center;
  border: solid 2px #8cb340;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.c-serviceBtn02 a {
  width: 100%;
  height: 100%;
  padding: 13px 50px;
  color: #ffffff;
  display: block;
}
.c-serviceBtn02:hover {
  background-color: #ffffff;
  background-image: url("../img/common/icon_arrow-green04.png");
}
.c-serviceBtn02:hover a {
  color: #8cb340;
}

.c-serviceBtn03 {
  width: 550px;
  margin: 0 auto;
  padding: 22px 50px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  background-color: #ffffff;
  background-image: url("../img/common/icon_arrow-green04.png");
  -webkit-background-size: 12px;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 95% center;
  border: solid 3px #dddddd;
  -webkit-border-radius: 160px;
  border-radius: 160px;
  transition: 0.3s ease-in-out;
}
.c-serviceBtn03 a {
  width: 100%;
  height: 100%;
  display: block;
  color: #46483c;
}
.c-serviceBtn03 a:before {
  margin: -6px 9px 0 -38px;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-serviceBtn03:hover {
  border-color: #8cb340;
}
.c-serviceBtn03.c-serviceBtn03--fukidashi a {
  font-weight: bold;
}
.c-serviceBtn03.c-serviceBtn03--fukidashi a:before {
  width: 29px;
  height: 24px;
  background-image: url("../img/common/icon_fukidashi01.png");
}

/*チケット*/
.c-ticketBtn01 {
  width: 115px;
  height: 30px;
  background-color: #4b5870;
}
.c-ticketBtn01 a {
  width: 100%;
  height: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2.7;
  color: #ffffff;
  display: block;
  text-align: center;
}
.c-ticketBtn01 a:after {
  width: 6px;
  height: 10px;
  margin: -2px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-white01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.c-ticketBtn02 {
  width: 300px;
  margin: 0 auto;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  background-color: #4b5870;
  border: solid 2px #939ba9;
  transition: 0.3s ease-in-out;
}
.c-ticketBtn02 a {
  padding: 15px 0;
  color: #ffffff;
  display: block;
}
.c-ticketBtn02 a:hover {
  opacity: 1;
}
.c-ticketBtn02:hover {
  background-color: #fff;
}
.c-ticketBtn02:hover a {
  color: #4b5870;
}
.c-ticketBtn02--gaibu {
  position: relative;
}
.c-ticketBtn02--gaibu:before {
  width: 14px;
  height: 14px;
  content: "";
  background-image: url("../img/common/icon_link02.png");
  position: absolute;
  top: 10px;
  right: 10px;
}
.c-ticketBtn02--gaibu:hover:before {
  background-image: url("../img/common/icon_link03.png");
}
.c-ticketBtn02--gaibu a {
  padding: 20px 0;
}

.c-ticketBtn03 {
  width: 300px;
  margin: 0 auto;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  background-color: #fc819b;
  border: solid 2px #f0bfca;
  transition: 0.3s ease-in-out;
}
.c-ticketBtn03 a {
  padding: 15px 0;
  color: #ffffff;
  display: block;
}
.c-ticketBtn03 a:hover {
  opacity: 1;
}
.c-ticketBtn03:hover {
  background-color: #fff;
}
.c-ticketBtn03:hover a {
  color: #fc819b;
}

/*保険*/
.c-hokenBtn01 {
  width: 116px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.35;
  text-align: center;
  background-image: url("../img/hoken/bg03.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-hokenBtn01 a {
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
}
.c-hokenBtn01 a:after {
  width: 7px;
  height: 10px;
  margin: -3px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-white05.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.c-hokenBtn02 {
  width: 270px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  background-image: url("../img/common/icon_hoken03.png");
  -webkit-background-size: 6px;
  background-size: 6px;
  background-repeat: no-repeat;
  background-position: 89% center;
  border: 2px solid #5fb3eb;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.c-hokenBtn02 a {
  width: 100%;
  height: 100%;
  padding: 11px 17px 9px 0;
  font-size: 1.4rem;
  color: #253b73;
  display: block;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn02 {
    width: auto;
  }
  .c-hokenBtn02 a {
    font-size: 1.3rem;
  }
}

.c-hokenBtn03 {
  width: 100%;
  max-width: 365px;
  height: 60px;
  background: url("../img/hoken/bg02.jpg") repeat;
  border-radius: 50px;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn03 {
    height: 40px;
  }
}
.c-hokenBtn03 a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: 500;
  color: #253b73;
  padding-left: 40px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn03 a {
    font-size: 1.4rem;
  }
}
.c-hokenBtn03 a:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #fff url("../img/common/icon_mail01.png") no-repeat center;
  background-size: 14px;
  position: absolute;
  left: 63px;
  top: 16px;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn03 a:before {
    left: 40px;
    top: 6px;
  }
}

.c-hokenBtn04 {
  width: 100%;
  max-width: 365px;
  height: 60px;
  background: url("../img/hoken/bg01.jpg") repeat;
  border-radius: 50px;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn04 {
    height: 40px;
  }
}
.c-hokenBtn04 a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  padding-left: 40px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn04 a {
    font-size: 1.4rem;
  }
}
.c-hokenBtn04 a:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #fff url("../img/common/icon_file02.png") no-repeat center;
  background-size: 14px;
  position: absolute;
  left: 84px;
  top: 16px;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn04 a:before {
    left: 40px;
    top: 6px;
  }
}

.c-hokenBtn05 {
  width: 290px;
  margin: 0 auto;
  padding: 2px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  background-image: url("../img/hoken/bg04.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.c-hokenBtn05 a {
  width: 100%;
  height: 100%;
  padding: 11px 17px 9px 0;
  font-size: 1.6rem;
  color: #253b73;
  background-color: #fff;
  background-image: url("../img/common/icon_hoken03.png");
  -webkit-background-size: 6px;
  background-size: 6px;
  background-repeat: no-repeat;
  background-position: 89% center;
  display: block;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

.c-hokenBtn06 {
  height: 50px;
  margin: 0 auto;
  border: solid 2px #ff5b4c;
  border-radius: 50px;
  display: table;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn06 {
    height: 40px;
  }
}
.c-hokenBtn06 a {
  padding: 0 20px 0 60px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #ff5b4c;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn06 a {
    font-size: 1.4rem;
  }
}
.c-hokenBtn06 a:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #ff5b4c url("../img/common/icon_file01.png") no-repeat center;
  background-size: 14px;
  position: absolute;
  left: 20px;
  top: calc((100% - 30px) / 2);
}

.c-hokenBtn07 {
  text-align: center;
}
.c-hokenBtn07__txt {
  max-width: 840px;
  width: 100%;
  display: inline-block;
  background: #fff58a;
  border-radius: 60px;
  text-align: center;
  font-size: 2rem;
  line-height: 1.2;
  color: #253b73;
  font-weight: bold;
  padding: 22px 40px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
}
@media screen and (max-width: 640px) {
  .c-hokenBtn07__txt {
    max-width: 235px;
    font-size: 1.3rem;
    padding: 15px;
  }
}
.c-hokenBtn07__txt span {
  display: inline-block;
  position: relative;
  padding: 11px 123px 11px 59px;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn07__txt span {
    padding: 0 12px 0 35px;
  }
}
.c-hokenBtn07__txt span::before {
  content: "";
  width: 46px;
  height: 46px;
  background: #fff url("../img/common/icon_mail01.png") no-repeat center;
  background-size: 20px 15px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
@media screen and (max-width: 640px) {
  .c-hokenBtn07__txt span::before {
    width: 30px;
    height: 30px;
    background-size: 13px 10px;
  }
}
.c-hokenBtn07__txt span::after {
  content: "";
  width: 8px;
  height: 14px;
  background: url("../img/common/icon_hoken04.png") no-repeat center;
  background-size: 8px 14px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
@media screen and (max-width: 640px) {
  .c-hokenBtn07__txt span::after {
    width: 6px;
    height: 10px;
    background-size: 6px 10px;
  }
}

.c-hokenBtn08 {
  text-align: center;
}
.c-hokenBtn08__txt {
  min-width: 322px;
  background: #fff;
  display: inline-block;
  border: 2px solid #253b73;
  border-radius: 40px;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #253b73;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
  padding: 15px 10px 13px;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn08__txt {
    min-width: 100%;
    font-size: 1rem;
    border-width: 1px;
    padding: 8px 10px;
  }
}
.c-hokenBtn08__txt span {
  display: inline-block;
  position: relative;
  padding: 0 34px 0 0;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn08__txt span {
    padding: 0 14px 0 0;
  }
}
.c-hokenBtn08__txt span::after {
  content: "";
  width: 6px;
  height: 11px;
  background: url("../img/common/icon_hoken04.png") no-repeat center;
  background-size: 6px 11px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
@media screen and (max-width: 640px) {
  .c-hokenBtn08__txt span::after {
    width: 4px;
    height: 7px;
    background-size: 4px 7px;
  }
}
.c-hokenBtn08.noicon .c-hokenBtn08__txt {
  min-width: 282px;
  border-width: 1px;
  padding: 16px 10px 14px;
}
@media screen and (max-width: 640px) {
  .c-hokenBtn08.noicon .c-hokenBtn08__txt {
    min-width: 168px;
    font-size: 1rem;
    padding: 9px 10px 7px;
  }
}
.c-hokenBtn08.noicon .c-hokenBtn08__txt span::after {
  display: none;
}

.c-hokenBtn09 {
  padding: 12px 20px;
  max-width: 590px;
  width: 100%;
  border-radius: 9999px;
  border: 1px solid #41B4E6;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn09 {
    padding: 27px 50px;
    column-gap: 10px;
    border-width: 3px;
    font-size: 2.4rem;
    transition: 0.3s ease-in-out;
  }
  .c-hokenBtn09:hover {
    color: #fff;
    background-color: #41B4E6;
  }
}
.c-hokenBtn09__label {
  flex: 1;
  text-align: center;
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn09__label {
    column-gap: 10px;
  }
}
.c-hokenBtn09__beforeIcon {
  min-width: 25px;
  width: 25px;
  height: 25px;
}
.c-hokenBtn09__beforeIcon svg {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn09__beforeIcon {
    width: 30px;
    height: 30px;
  }
}
.c-hokenBtn09__icon {
  min-width: 12px;
  width: 12px;
  height: 14px;
}
.c-hokenBtn09__icon svg {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn09__icon {
    width: 24px;
    height: 24px;
  }
}
.c-hokenBtn09--orange {
  background: #FA8246;
  color: #fff;
  border: none;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn09--orange:hover {
    background-color: #FA8246;
    opacity: 0.8;
  }
}
.c-hokenBtn09--blue {
  background: #41B4E6;
  color: #fff;
  border: none;
}
.c-hokenBtn09--navy {
  background: #08336E;
  color: #fff;
  border: none;
}
.c-hokenBtn09--gray {
  background: #D9D9D9;
  color: #fff;
  border: none;
}
.c-hokenBtn09--outline {
  background-color: #fff !important;
  border: 1.5px solid #FA8246;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn09--outline {
    border-width: 3px;
  }
}
.c-hokenBtn09--outline.c-hokenBtn09--gray {
  border-color: #D9D9D9;
  color: #333;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn09--outline.c-hokenBtn09--gray:hover {
    background-color: #EFEFEF !important;
  }
}

.c-hokenBtn10 {
  padding: 9px 20px;
  border-radius: 5px;
  border: 1.5px solid #D9D9D9;
  background: #FFF;
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn10 {
    padding: 0 40px;
    height: 80px;
    border-radius: 10px;
    column-gap: 20px;
    font-size: 1.6rem;
    border-width: 3px;
  }
}
.c-hokenBtn10__label {
  flex: 1;
}
.c-hokenBtn10__icon1 {
  min-width: 20px;
  width: 20px;
  height: 20px;
  color: #41B4E6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-hokenBtn10__icon1 svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn10__icon1 {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}
.c-hokenBtn10__icon2 {
  min-width: 12px;
  width: 12px;
  height: 14px;
  color: #41B4E6;
}
.c-hokenBtn10__icon2 svg {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn10__icon1 {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .c-hokenBtn10__icon2 {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
}

.c-hokenBtn11 {
  padding: 14.5px 10px;
  max-width: 590px;
  width: 100%;
  border-radius: 9999px;
  background: #FA8246;
  border: 1.5px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn11 {
    padding: 19px 20px 18px 20px;
    border-width: 3px;
    column-gap: 10px;
    font-size: 1.8rem;
    transition: 0.3s ease-in-out;
  }
}
.c-hokenBtn11--outline {
  background-color: #fff !important;
  border: 1.5px solid #FA8246;
  color: #FA8246;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn11--outline {
    border-width: 3px;
  }
}
.c-hokenBtn11--outline.c-hokenBtn11--outline {
  border-color: #41B4E6;
  color: #41B4E6;
}
.c-hokenBtn11--outline.c-hokenBtn11--outline:hover {
  background-color: #41B4E6 !important;
  color: #fff;
}
.c-hokenBtn11--blue {
  background-color: #41B4E6;
}
.c-hokenBtn11--navy {
  background-color: #08336E;
}
.c-hokenBtn11__beforeIcon, .c-hokenBtn11__afterIcon {
  min-width: 15px;
  width: 15px;
  height: 15px;
}
.c-hokenBtn11__beforeIcon svg, .c-hokenBtn11__afterIcon svg {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 641px) {
  .c-hokenBtn11__beforeIcon, .c-hokenBtn11__afterIcon {
    min-width: 25px;
    width: 25px;
    height: 25px;
  }
}

/*リフォーム*/
.c-reformBtn01 {
  width: 115px;
  height: 30px;
  background-color: #a7a37e;
}
.c-reformBtn01 a {
  width: 100%;
  height: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2.9;
  color: #ffffff;
  display: block;
  text-align: center;
}
.c-reformBtn01 a:after {
  width: 6px;
  height: 10px;
  margin: -2px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-white01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.c-reformBtn02 {
  width: 277px;
  height: 56px;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3.7;
  border: solid 2px #247404;
}
.c-reformBtn02 a {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  color: #247404;
  background-color: #ffffff;
  font-weight: 600;
}
.c-reformBtn02 a:hover {
  opacity: 1;
}
.c-reformBtn02 a:after {
  width: 6px;
  height: 10px;
  margin: -2px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-green03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s ease-in-out;
}
.c-reformBtn02:hover a {
  background-color: #247404;
  color: #ffffff;
}
.c-reformBtn02:hover a:after {
  background-image: url("../img/common/icon_arrow-white01.png");
}

.c-reformBtn03 {
  width: 365px;
  height: 80px;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  background-color: #fa5b0f;
}
.c-reformBtn03 a {
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-reformBtn03 a:before {
  width: 18px;
  height: 20px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_file01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

input.c-reformBtn03 {
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 365px;
  height: 80px;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  background: #fa5b0f url(../img/common/icon_file01.png) no-repeat center left 40px;
  background-size: 18px 20px;
  border: none !important;
}
@media screen and (max-width: 640px) {
  input.c-reformBtn03 {
    max-width: 365px;
    width: 100%;
    height: 60px;
    font-size: 1.5rem;
    background-size: 12px 14px;
  }
}

/*くらしのサービス*/
.c-kurashiBtn01 {
  width: 190px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.3;
  text-align: center;
  background-color: #fa9932;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-kurashiBtn01 a,
.c-kurashiBtn01 span {
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
}
.c-kurashiBtn01 a:after,
.c-kurashiBtn01 span:after {
  width: 7px;
  height: 10px;
  margin: -3px -5px 0 5px;
  content: "";
  background-image: url("../img/common/icon_arrow-white05.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.c-kurashiBtn02 {
  width: 190px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.35;
  text-align: center;
  background-color: #696056;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-kurashiBtn02 a {
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
}
.c-kurashiBtn02 a:after {
  width: 7px;
  height: 10px;
  margin: -3px -9px 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-white05.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.c-kurashiBtn03 {
  width: 300px;
  height: 56px;
  margin: 0 auto;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 3.11;
  text-align: center;
  border: solid 2px #faad5a;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-kurashiBtn03 a {
  width: 100%;
  height: 100%;
  display: block;
  color: #faad5a;
  transition: 0.3s ease-in-out;
}
.c-kurashiBtn03 a:before {
  width: 14px;
  height: 14px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_link04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-kurashiBtn03:hover {
  background-color: #faad5a;
}
.c-kurashiBtn03:hover a {
  color: #ffffff;
}
.c-kurashiBtn03:hover a:before {
  background-image: url("../img/common/icon_link02.png");
}

/*葬祭*/
.c-sosaiBtn01 {
  height: 56px;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 3.4;
  text-align: center;
  border: solid 2px #2e95a3;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-sosaiBtn01 a {
  width: 100%;
  height: 100%;
  display: block;
  color: #2e95a3;
}
.c-sosaiBtn01 a:hover {
  opacity: 1;
}
.c-sosaiBtn01 a:after {
  width: 7px;
  height: 14px;
  margin: -3px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s ease-in-out;
}
.c-sosaiBtn01:hover a {
  opacity: 1;
  background-color: #2e95a3;
  color: #ffffff;
}
.c-sosaiBtn01:hover a:after {
  background-image: url("../img/common/icon_arrow-white03.png");
}

.c-sosaiBtn02 {
  height: 56px;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 3.05;
  text-align: center;
  display: inline-block;
  border: solid 2px #2e95a3;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-sosaiBtn02 a {
  width: 100%;
  height: 100%;
  padding: 0 80px;
  display: block;
  color: #2e95a3;
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: 7px;
  background-size: 7px;
  background-repeat: no-repeat;
  background-position: 95% center;
  transition: 0.3s ease-in-out;
}
.c-sosaiBtn02 a:hover {
  opacity: 1;
}
.c-sosaiBtn02:hover a {
  background-color: #2e95a3;
  background-image: url("../img/common/icon_arrow-white03.png");
  color: #ffffff;
}

.c-sosaiBtn03 {
  display: block;
  width: 490px;
  font-size: 1.9rem;
  font-weight: bold;
  background-color: #0074d9;
  box-shadow: 0 3px 0 #004e91;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}
.c-sosaiBtn03 a {
  width: 100%;
  height: 100%;
  padding: 25px;
  color: #ffffff;
  display: block;
  box-sizing: border-box;
}
.c-sosaiBtn03 a:hover {
  opacity: 1;
}
.c-sosaiBtn03 a:before {
  width: 27px;
  height: 21px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_mail02.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-sosaiBtn03:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}

.c-sosaiBtn04 {
  height: 30px;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2.14;
  text-align: center;
  display: inline-block;
  border: solid 2px #2e95a3;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-sosaiBtn04 a {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  display: block;
  color: #2e95a3;
  background-color: #ffffff;
  transition: 0.3s ease-in-out;
}
.c-sosaiBtn04:hover a {
  background-color: #2e95a3;
  color: #ffffff;
}

.c-sosaiBtn05 {
  width: 490px;
  padding: 25px;
  font-size: 1.9rem;
  font-weight: bold;
  background-color: #2e95a3;
  box-shadow: 0 3px 0 #20656e;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-sosaiBtn05 a {
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: block;
}
.c-sosaiBtn05 a:hover {
  opacity: 1;
}
.c-sosaiBtn05 a:before {
  width: 24px;
  height: 27px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_file01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-sosaiBtn05:hover {
  transform: translate(0, 3px);
  box-shadow: none;
}
.c-sosaiBtn05--price a:before {
  width: 25px;
  height: 29px;
  background-image: url("../img/common/icon_dentaku01.png");
}

.c-sosaiBtn06 {
  display: block;
  width: 290px;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.28;
  background-color: #0074d9;
  box-shadow: 0 3px 0 #004e91;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}
.c-sosaiBtn06 a {
  width: 100%;
  height: 100%;
  padding: 8px 30px;
  color: #ffffff;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-sosaiBtn06 a:before {
  width: 27px;
  height: 21px;
  margin-right: 10px;
  content: "";
  background-image: url("../img/common/icon_mail02.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
}
.c-sosaiBtn06 a:hover {
  opacity: 1;
}
.c-sosaiBtn06--search a {
  padding: 15px 30px;
}
.c-sosaiBtn06--search a:before {
  width: 23px;
  height: 23px;
  background-image: url("../img/common/icon_search08.png");
}
.c-sosaiBtn06:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}

.c-sosaiBtn07 {
  height: 46px;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3.28;
  text-align: center;
  border: solid 2px #2e95a3;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-sosaiBtn07 a {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  display: block;
  color: #2e95a3;
}
.c-sosaiBtn07 a:hover {
  opacity: 1;
}
.c-sosaiBtn07 a:after {
  width: 7px;
  height: 14px;
  margin: -3px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s ease-in-out;
}
.c-sosaiBtn07:hover a {
  opacity: 1;
  background-color: #2e95a3;
  color: #ffffff;
}
.c-sosaiBtn07:hover a:after {
  background-image: url("../img/common/icon_arrow-white03.png");
}

.c-sosaiBtn08 {
  padding: 20px;
  font-size: 1.8rem;
  font-weight: bold;
  background-color: #2e95a3;
  box-shadow: 0 3px 0 #20656e;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-sosaiBtn08 a {
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-sosaiBtn08 a:hover {
  opacity: 1;
}
.c-sosaiBtn08 a:after {
  width: 8px;
  height: 15px;
  margin: -3px 0 0 20px;
  content: "";
  background-image: url("../img/common/icon_arrow-white03.png");
  -webkit-background-size: cover;
  background-size: cover;
  transform: rotate(90deg);
}
.c-sosaiBtn08:hover {
  transform: translate(0, 3px);
  box-shadow: none;
}

@media screen and (max-width: 640px) {
  /*サービスサイト SP*/
  .c-serviceBtn01 {
    width: 100%;
    min-width: 200px;
    margin: 0 auto;
  }
  .c-serviceBtn02 {
    width: 100%;
    min-width: 200px;
    margin: 0 auto;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .c-serviceBtn02 a {
    padding: 3px 20px;
  }
  .c-serviceBtn03 {
    width: 100%;
    min-width: 200px;
    padding: 12px 25px 10px 56px;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: -2px;
    font-weight: bold;
    -webkit-background-size: 7px;
    background-size: 7px;
    border-width: 2px;
  }
  .c-serviceBtn03.c-serviceBtn03--fukidashi a:before {
    width: 20px;
    height: 16px;
  }
  /*チケット sp*/
  .c-ticketBtn01 {
    width: 50%;
    min-width: 115px;
    height: 32px;
  }
  .c-ticketBtn01 a {
    font-size: 1.2rem;
    line-height: 2.7;
  }
  .c-ticketBtn02 {
    width: 100%;
    font-size: 1.4rem;
  }
  .c-ticketBtn03 {
    width: 100%;
    font-size: 1.4rem;
  }
  /*保険 sp*/
  .c-hokenBtn01 {
    width: 50%;
    min-width: 116px;
  }
  .c-hokenBtn01 a:after {
    margin: -3px 0 0 5px;
  }
  .c-hokenBtn05 {
    width: 100%;
    max-width: 290px;
  }
  /*リフォーム SP*/
  .c-reformBtn01 {
    width: 50%;
    min-width: 115px;
    height: 32px;
  }
  .c-reformBtn01 a {
    font-size: 1.2rem;
    line-height: 2.7;
  }
  .c-reformBtn02 {
    width: 75%;
    min-width: 230px;
    height: auto;
    font-size: 1.3rem;
    line-height: 2.73;
    padding: 4px 0;
    border: solid 1px #247404;
  }
  .c-reformBtn03 {
    max-width: 365px;
    width: 100%;
    height: 60px;
    font-size: 1.5rem;
  }
  /*くらしのサービス SP*/
  .c-kurashiBtn01 {
    width: 50%;
    min-width: 190px;
  }
  .c-kurashiBtn01 a:after {
    margin: -3px 0 0 5px;
  }
  .c-kurashiBtn02 {
    width: 50%;
    min-width: 190px;
  }
  .c-kurashiBtn02 a:after {
    margin: -3px 0 0 5px;
  }
  /*葬祭 SP*/
  .c-sosaiBtn01 {
    height: 45px;
    line-height: 3.4;
  }
  .c-sosaiBtn01 a:after {
    margin: -3px 0 0 5px;
  }
  .c-sosaiBtn02 {
    width: 100%;
    height: auto;
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .c-sosaiBtn02 a {
    padding: 10px 40px;
  }
  .c-sosaiBtn03 {
    width: 100%;
    max-width: 490px;
    font-size: 1.6rem;
  }
  .c-sosaiBtn03 a {
    padding: 15px;
  }
  .c-sosaiBtn03 a:before {
    width: 20px;
    height: 16px;
    margin: -3px 5px 0 0;
  }
  .c-sosaiBtn04 {
    height: auto;
    font-size: 1.2rem;
    line-height: 2;
  }
  .c-sosaiBtn05 {
    width: 100%;
    max-width: 490px;
    padding: 15px;
    font-size: 1.6rem;
  }
  .c-sosaiBtn05 a:before {
    width: 20px;
    height: 22px;
    margin: -3px 5px 0 0;
  }
  .c-sosaiBtn05--price a:before {
    width: 20px;
    height: 23px;
  }
  .c-sosaiBtn06 {
    max-width: 80%;
  }
  .c-sosaiBtn06 a {
    padding: 8px 15px;
  }
  .c-sosaiBtn06 a:before {
    width: 20px;
    height: 16px;
  }
  .c-sosaiBtn06--search a:before {
    width: 20px;
    height: 20px;
  }
  .c-sosaiBtn07 {
    height: 40px;
    line-height: 2.85;
  }
  .c-sosaiBtn07 a {
    padding: 0 15px;
  }
  .c-sosaiBtn07 a:after {
    margin: -3px 0 0 5px;
  }
  .c-sosaiBtn08 {
    padding: 15px;
    font-size: 1.6rem;
  }
  .c-sosaiBtn08 a:after {
    margin: -3px 0 0 15px;
  }
}
/*
------------------------------------------------------------*/
/*コンポーネント
------------------------------------------------------------*/
.c-btn01 {
  display: block;
  color: #2e95a3;
  background: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 2px;
  border: 2px solid #2e95a3;
  position: relative;
  transition: 0.3s ease-in-out;
}
.c-btn01:after {
  content: "";
  position: absolute;
  background: url(../img/component/arrow01_blue.png);
  width: 7px;
  height: 14px;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
}
.c-btn01:hover {
  opacity: 1;
  color: #fff;
  background: #2e95a3;
}
.c-btn01:hover:after {
  background: url(../img/component/arrow01_white.png);
}
@media screen and (max-width: 640px) {
  .c-btn01 {
    display: table;
    margin: 0 auto;
    width: 100% !important;
    font-size: 1.3rem;
    text-align: center;
  }
}

/*
------------------------------------------------------------*/
/*フォームボタン
------------------------------------------------------------*/
.c-cartBtn {
  display: table;
  margin: 50px auto 0;
}
@media screen and (max-width: 640px) {
  .c-cartBtn {
    width: 100%;
  }
}
.c-cartBtn input,
.c-cartBtn a,
.c-cartBtn span.c-cartBtn__btn05 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  width: 300px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.c-cartBtn input:after, .c-cartBtn input:before,
.c-cartBtn a:after,
.c-cartBtn a:before,
.c-cartBtn span.c-cartBtn__btn05:after,
.c-cartBtn span.c-cartBtn__btn05:before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  transform: rotate(45deg);
}
.c-cartBtn input:hover,
.c-cartBtn a:hover,
.c-cartBtn span.c-cartBtn__btn05:hover {
  opacity: 1;
}
@media screen and (max-width: 640px) {
  .c-cartBtn input,
  .c-cartBtn a,
  .c-cartBtn span.c-cartBtn__btn05 {
    width: 100%;
    height: 40px;
    float: none;
    font-size: 1.4rem;
    display: inline-flex;
  }
  .c-cartBtn input:after, .c-cartBtn input:before,
  .c-cartBtn a:after,
  .c-cartBtn a:before,
  .c-cartBtn span.c-cartBtn__btn05:after,
  .c-cartBtn span.c-cartBtn__btn05:before {
    width: 12px;
    height: 12px;
  }
}
.c-cartBtn input.c-cartBtn__btn01,
.c-cartBtn input.c-cartBtn__btn07 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  background: #fff url("../img/component/icon_101.png") no-repeat center left 20px;
}
@media screen and (max-width: 640px) {
  .c-cartBtn input.c-cartBtn__btn01,
  .c-cartBtn input.c-cartBtn__btn07 {
    background-size: 10px;
  }
}
.c-cartBtn input.c-cartBtn__btn01:hover,
.c-cartBtn input.c-cartBtn__btn07:hover {
  background: #b2b2b2 url("../img/component/icon_102.png") no-repeat center left 20px;
}
@media screen and (max-width: 640px) {
  .c-cartBtn input.c-cartBtn__btn01:hover,
  .c-cartBtn input.c-cartBtn__btn07:hover {
    background-size: 10px;
  }
}
.c-cartBtn input.c-cartBtn__btn02,
.c-cartBtn input.c-cartBtn__btn08 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  background: #ff6e03 url("../img/component/icon_103.png") no-repeat center right 20px;
}
@media screen and (max-width: 640px) {
  .c-cartBtn input.c-cartBtn__btn02,
  .c-cartBtn input.c-cartBtn__btn08 {
    background-size: 10px;
  }
}
.c-cartBtn input.c-cartBtn__btn02:hover,
.c-cartBtn input.c-cartBtn__btn08:hover {
  background: #fff url("../img/component/icon_104.png") no-repeat center right 20px;
}
@media screen and (max-width: 640px) {
  .c-cartBtn input.c-cartBtn__btn02:hover,
  .c-cartBtn input.c-cartBtn__btn08:hover {
    background-size: 10px;
  }
}
.c-cartBtn input.c-cartBtn__btn10 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  background: #fff url("../img/common/icon_maru-sankaku05.png") no-repeat center left 20px;
  background-size: 20px;
}
.c-cartBtn input.c-cartBtn__btn11 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  background: #76a93b url("../img/common/icon_maru-sankaku03.png") no-repeat center right 20px;
  background-size: 20px;
  padding-right: 40px;
}
@media screen and (max-width: 640px) {
  .c-cartBtn input.c-cartBtn__btn11 {
    background-size: 15px;
    justify-content: center;
    padding-left: 0;
  }
}
.c-cartBtn__fixed {
  max-width: 280px;
  margin: 0 auto 0;
  position: fixed;
  bottom: 0;
  right: 40px;
  z-index: 80;
  padding-bottom: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
}
.c-cartBtn__fixed.is-active {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__fixed {
    position: fixed !important;
    right: 0 !important;
    bottom: 0;
    z-index: 100;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px;
  }
}
.c-cartBtn__btn01 {
  float: left;
  margin-right: 20px;
  color: #232934;
  border: 2px solid #b2b2b2;
  background: #fff;
}
.c-cartBtn__btn01:before {
  left: 20px;
  border-bottom: 2px solid #b2b2b2;
  border-left: 2px solid #b2b2b2;
}
.c-cartBtn__btn01:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-cartBtn__btn01:hover:before {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn01 {
    float: none;
  }
}
.c-cartBtn__btn02 {
  float: right;
  color: #fff;
  background: #ff6e03;
  border: 2px solid #ffa257;
}
.c-cartBtn__btn02:after {
  right: 20px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.c-cartBtn__btn02:hover {
  color: #ff6e03;
  background: #fff;
}
.c-cartBtn__btn02:hover:after {
  border-top: 2px solid #ff6e03;
  border-right: 2px solid #ff6e03;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn02 {
    margin-bottom: 10px;
  }
  .c-cartBtn__btn02:after {
    right: 15px;
  }
}
.c-cartBtn__btn02b {
  float: right;
  color: #fff;
  background: #ff6e03;
  border: 2px solid #ffa257;
  border-radius: 0;
}
.c-cartBtn__btn02b:after {
  content: none;
}
.c-cartBtn__btn02b:hover {
  color: #ff6e03;
  background: #fff;
}
.c-cartBtn__btn02b:hover:after {
  border-top: 2px solid #ff6e03;
  border-right: 2px solid #ff6e03;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn02b {
    margin-bottom: 10px;
  }
  .c-cartBtn__btn02b:after {
    right: 15px;
  }
}
.c-cartBtn__btn03 {
  color: #232934;
  border: 2px solid #b2b2b2;
}
.c-cartBtn__btn03:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-cartBtn input.c-cartBtn__btn04 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  background: #fff url("../img/common/icon_arrow-gray02.png") no-repeat center right 20px;
  background-size: 6px;
}
.c-cartBtn input.c-cartBtn__btn04:hover {
  background: #b2b2b2 url("../img/common/icon_arrow-white02.png") no-repeat center right 20px;
  background-size: 6px;
}
.c-cartBtn__btn04 {
  width: 300px !important;
  float: left;
  margin-right: 20px;
  color: #46483c;
  background: #fff;
  border: 2px solid #b2b2b2;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.c-cartBtn__btn04:before {
  width: 6px !important;
  height: 10px !important;
  background-image: url("../img/common/icon_arrow-gray02.png");
  -webkit-background-size: cover;
  background-size: cover;
  border: none;
  transform: rotate(180deg) !important;
  left: 20px;
}
.c-cartBtn__btn04:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-cartBtn__btn04:hover:before {
  background-image: url("../img/common/icon_arrow-white02.png");
  border: none;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn04 {
    width: 100% !important;
    margin-bottom: 10px;
    float: none;
  }
  .c-cartBtn__btn04:before {
    left: 15px;
  }
}
.c-cartBtn__btn04--2 {
  width: 300px !important;
  height: 46px !important;
  font-size: 1.4rem !important;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn04--2 {
    width: 100% !important;
    height: 40px !important;
  }
}
.c-cartBtn__btn04--back {
  width: 300px !important;
  float: left;
  margin-right: 20px;
  color: #46483c;
  background: #fff;
  border: 2px solid #b2b2b2;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.c-cartBtn__btn04--back:before {
  width: 6px !important;
  height: 10px !important;
  background-image: url("../img/common/icon_arrow-gray02.png");
  -webkit-background-size: cover;
  background-size: cover;
  border: none;
  transform: rotate(0deg) !important;
  right: 20px;
}
.c-cartBtn__btn04--back:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-cartBtn__btn04--back:hover:before {
  background-image: url("../img/common/icon_arrow-white02.png");
  border: none;
}
.c-cartBtn input.c-cartBtn__btn05 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  background: #ff5b4c url("../img/common/icon_arrow-white02.png") no-repeat center right 20px;
  background-size: 6px;
}
.c-cartBtn input.c-cartBtn__btn05:hover {
  background: #fff url("../img/common/icon_arrow-orange06.png") no-repeat center right 20px;
  background-size: 6px;
}
.c-cartBtn__btn05 {
  width: 300px !important;
  float: right;
  color: #fff;
  background: #ff5b4c;
  border: 2px solid #ff5b4c;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.c-cartBtn__btn05:after {
  width: 6px !important;
  height: 10px !important;
  background-image: url("../img/common/icon_arrow-white02.png");
  -webkit-background-size: cover;
  background-size: cover;
  border: none;
  transform: none !important;
  right: 20px;
}
.c-cartBtn__btn05:hover {
  color: #ff5b4c;
  background: #fff;
}
.c-cartBtn__btn05:hover:after {
  background-image: url("../img/common/icon_arrow-orange06.png");
  border: none;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn05 {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .c-cartBtn__btn05:after {
    right: 15px;
  }
}
.c-cartBtn__btn05--gray {
  background: #666666;
  border-color: #666666;
}
@media screen and (min-width: 641px) {
  .c-cartBtn__btn05--gray:hover {
    background: #ffffff;
    color: #666666;
  }
}
.c-cartBtn__btn05--gray::before, .c-cartBtn__btn05--gray::after {
  display: none;
}
.c-cartBtn__btn06 {
  color: #46483c;
  border: 2px solid #b2b2b2;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.c-cartBtn__btn06:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-cartBtn__btn07 {
  float: left;
  margin-right: 20px;
  color: #46483c;
  border: 2px solid #b2b2b2;
  background: #fff;
}
.c-cartBtn__btn07:before {
  width: 10px !important;
  height: 10px !important;
  left: 20px;
  border-bottom: 2px solid #b2b2b2;
  border-left: 2px solid #b2b2b2;
}
.c-cartBtn__btn07:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-cartBtn__btn07:hover:before {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn07 {
    margin-bottom: 10px;
    float: none;
  }
  .c-cartBtn__btn07:before {
    left: 15px;
  }
}
.c-cartBtn__btn08 {
  float: right;
  color: #fff;
  background: #fa5b0f;
  border: 2px solid #fa5b0f;
}
.c-cartBtn__btn08:after {
  width: 10px !important;
  height: 10px !important;
  right: 20px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.c-cartBtn__btn08:hover {
  color: #fa5b0f;
  background: #fff;
}
.c-cartBtn__btn08:hover:after {
  border-top: 2px solid #fa5b0f;
  border-right: 2px solid #fa5b0f;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn08 {
    margin-bottom: 10px;
  }
  .c-cartBtn__btn08:after {
    right: 15px;
  }
}
.c-cartBtn__btn09 {
  color: #46483c;
  border: 2px solid #b2b2b2;
}
.c-cartBtn__btn09:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-cartBtn__btn10 {
  margin-right: 20px;
  float: left;
  color: #594242;
  border: 2px solid #b2b2b2;
  box-shadow: 0 3px 0 rgba(115, 62, 1, 0.2);
  background: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-cartBtn__btn10:before {
  width: 20px !important;
  height: 20px !important;
  background-image: url("../img/common/icon_maru-sankaku05.png");
  -webkit-background-size: cover;
  background-size: cover;
  border: none;
  transform: rotate(180deg) !important;
  left: 20px;
}
.c-cartBtn__btn10:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn10 {
    width: 100% !important;
    margin-bottom: 10px;
    float: none;
  }
  .c-cartBtn__btn10:before {
    left: 15px;
  }
}
.c-cartBtn__btn11 {
  float: right;
  color: #ffffff;
  background-color: #76a93b;
  border: 2px solid #76a93b;
  box-shadow: 0 3px 0 rgba(118, 169, 59, 0.75);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-cartBtn__btn11:after {
  width: 20px !important;
  height: 20px !important;
  background-image: url("../img/common/icon_maru-sankaku03.png");
  -webkit-background-size: cover;
  background-size: cover;
  border: none;
  right: 20px;
  transform: none !important;
}
.c-cartBtn__btn11:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn11 {
    width: 100% !important;
    margin-bottom: 10px;
    float: none;
  }
  .c-cartBtn__btn11:after {
    right: 15px;
  }
}
.c-cartBtn__btn12 {
  color: #594242;
  border: 2px solid #b2b2b2;
  box-shadow: 0 3px 0 rgba(115, 62, 1, 0.2);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-cartBtn__btn12:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn12 {
    width: 100% !important;
    margin-bottom: 10px;
    float: none;
  }
}
.c-cartBtn__btn12--2 {
  width: 370px !important;
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn12--2 {
    width: 100% !important;
  }
}
.c-cartBtn input.c-cartBtn__btn13 {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.c-cartBtn__btn13 {
  color: #ffffff;
  background-color: #2e95a3;
  border: 2px solid #2e95a3;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-cartBtn__btn13:after {
  width: 7px !important;
  height: 14px !important;
  background-image: url("../img/common/icon_arrow-white03.png");
  -webkit-background-size: cover;
  background-size: cover;
  border: none;
  right: 20px;
  transform: none !important;
}
.c-cartBtn__btn13:hover {
  color: #2e95a3;
  background-color: #ffffff;
}
.c-cartBtn__btn13:hover:after {
  background-image: url("../img/common/icon_arrow-blue04.png");
}
.c-cartBtn__btn14 {
  color: #2e95a3;
  background-color: #ffffff;
  border: 2px solid #2e95a3;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-cartBtn__btn14:hover {
  color: #ffffff;
  background-color: #2e95a3;
}
.c-cartBtn__btn15 {
  float: right;
  color: #ffffff;
  background-color: #76a93b;
  border: 2px solid #76a93b;
  box-shadow: 0 3px 0 rgba(118, 169, 59, 0.75);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.c-cartBtn__btn15:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}
@media screen and (max-width: 640px) {
  .c-cartBtn__btn15 {
    width: 100% !important;
    margin-bottom: 10px;
    float: none;
  }
}
.c-cartBtn__btn16 {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  background-color: #FA8246;
  border-radius: 9999px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .c-cartBtn__btn16 {
    padding: 20px 40px;
    font-size: 1.8rem;
    transition: 0.3s ease-in-out;
  }
  .c-cartBtn__btn16:hover {
    opacity: 0.8;
  }
}
.c-cartBtn__btn16__label {
  flex: 1;
  display: flex;
  justify-content: center;
}
.c-cartBtn__btn16__icon {
  min-width: 12px;
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 641px) {
  .c-cartBtn__btn16__icon {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
}
.c-cartBtn__btn16__icon svg {
  width: 100%;
  height: 100%;
  display: block;
}

.c-checkbox__text {
  cursor: pointer;
}
.c-checkbox input[type=checkbox] {
  position: absolute;
  display: none;
}
.c-checkbox input[type=checkbox][disabled] {
  cursor: not-allowed;
}
.c-checkbox input[type=checkbox] + label {
  position: relative;
  display: block;
  padding-left: 26px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 1.6rem;
  color: #111111;
  line-height: 1.8;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .c-checkbox input[type=checkbox] + label {
    font-size: 1.3rem;
  }
}
.c-checkbox input[type=checkbox] + label:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: inline-block;
  width: 16px;
  height: 16px;
  content: "";
  border: 1px solid #c7c7c7;
  border-radius: 3px;
}
.c-checkbox input[type=checkbox] + label:after {
  position: absolute;
  display: none;
  content: "";
}
.c-checkbox input[type=checkbox][disabled] + label:hover,
.c-checkbox input[type=checkbox][disabled] + label:before,
.c-checkbox input[type=checkbox][disabled] + label:after {
  cursor: not-allowed;
}
.c-checkbox input[type=checkbox]:checked + label:before {
  animation-name: none;
}
.c-checkbox input[type=checkbox]:checked + label:after {
  display: block;
}
.c-checkbox input[type=checkbox] + label:after {
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  box-sizing: border-box;
  width: 5px;
  height: 11px;
  transform: rotate(45deg);
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
  margin-top: -7px;
}
.c-checkbox input[type=checkbox]:checked + label:before {
  border: #9ac985;
  background: #9ac985;
}
.c-checkbox--hoken input[type=checkbox]:checked + label:before {
  border: #82cdf3;
  background: #82cdf3;
}
.c-checkbox--reform input[type=checkbox]:checked + label:before {
  border: #68ba93;
  background: #68ba93;
}
.c-checkbox--kurashi input[type=checkbox]:checked + label:before {
  border: #faad5a;
  background: #faad5a;
}
.c-checkbox--sosai input[type=checkbox]:checked + label:before {
  border: #af8ebe;
  background: #af8ebe;
}

/*
------------------------------------------------------------*/
.c-contactBtn {
  width: 480px;
  margin: 0 auto;
  padding: 32px 0 31px;
  text-align: center;
  border: 2px solid #669900;
}
.c-contactBtn__txt01 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #232934;
}
.c-contactBtn__txt02 {
  padding: 14px 0 6px;
}
.c-contactBtn__txt03 {
  font-size: 1.6rem;
  color: #232934;
}
@media screen and (max-width: 640px) {
  .c-contactBtn {
    width: calc(100% - 30px);
    margin: 0 auto 15px;
    padding: 14px 20px 17px 20px;
  }
  .c-contactBtn__txt01 {
    font-size: 1.5rem;
    padding: 0 5px 2px;
  }
  .c-contactBtn__txt03 {
    font-size: 1.1rem;
    color: #fff;
    background: #4b5870;
    border: 3px solid #939ba9;
    padding: 12px 10px 7px;
  }
  .c-contactBtn__txt03 .txt-sp {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
    padding: 0 0 8px 0;
  }
  .c-contactBtn__txt03 .txt-sp img {
    margin-top: -2px;
    display: inline-block;
  }
}

/*
------------------------------------------------------------*/
@media screen and (max-width: 640px) {
  .c-contactBtn02 {
    max-width: 260px;
    margin: 0 auto;
    color: #fff;
    background: #2e95a3;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 7px 10px 6px 68px;
    border-radius: 5px;
    box-shadow: 0 3px #20656e;
    position: relative;
  }
  .c-contactBtn02__cont {
    display: block;
  }
  .c-contactBtn02:before {
    content: "";
    position: absolute;
    background: url("../img/common/icon_tel07.png");
    background-size: 100%;
    width: 26px;
    height: 26px;
    left: 29px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.c-contactBtn03 {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .c-contactBtn03 {
    width: 100%;
    flex-direction: column-reverse;
  }
}
.c-contactBtn03__btn {
  width: 300px;
  color: #ffffff;
  background-color: #FF6E03;
  border: 2px solid #FFA257;
  height: 60px;
  font-size: 1.8rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .c-contactBtn03__btn {
    width: 100%;
  }
}
.c-contactBtn03__btn:after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  transform: rotate(45deg);
  right: 20px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}
.c-contactBtn03__btn:hover {
  color: #ff6e03;
  background: #fff;
  cursor: pointer;
}
.c-contactBtn03__btn:hover::after {
  border-top-color: #ff6e03;
  border-right-color: #ff6e03;
}
.c-contactBtn03__btn.is-rounded {
  border-radius: 30px;
}
.c-contactBtn03__btn.is-rounded input {
  border-radius: 30px;
}
.c-contactBtn03__btn.is-style1 {
  color: #232934;
  background-color: #ffffff;
  border-color: #B1B1B1;
}
.c-contactBtn03__btn.is-style1::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  transform: rotate(45deg);
  left: 20px;
  border-bottom: 2px solid #b2b2b2;
  border-left: 2px solid #b2b2b2;
}
.c-contactBtn03__btn.is-style1::after {
  content: none;
}
.c-contactBtn03__btn.is-style1:hover {
  color: #fff;
  background: #b2b2b2;
}
.c-contactBtn03__btn.is-style1:hover::before {
  border-bottom-color: #fff;
  border-left-color: #fff;
}
.c-contactBtn03__btn + .c-contactBtn03__btn {
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .c-contactBtn03__btn + .c-contactBtn03__btn {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
.c-contactBtn03__btn input {
  background: inherit !important;
  color: inherit !important;
  font-weight: bold;
  cursor: inherit;
  width: 100%;
  height: 100% !important;
  border: none;
}
.c-contactBtn03__submit {
  cursor: pointer !important;
  pointer-events: unset !important;
}

.c-btn02 {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  border-radius: 999px;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .c-btn02 {
    max-width: 335px;
    width: 100%;
  }
}
.c-btn02:after {
  width: 7px;
  height: 100%;
  right: 30px;
  top: 0;
  background: url(../img/hoken/common/icon-btn02-white.svg) no-repeat center/7px auto;
  content: "";
  position: absolute;
  transition: background-image 0.3s ease-in-out;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .c-btn02:after {
    right: 17px;
    width: 6px;
    background-size: 6px auto;
  }
}
.c-btn02 + .c-btn02 {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .c-btn02 + .c-btn02 {
    margin-top: 10px;
  }
}
@media screen and (min-width: 641px) {
  .c-btn02:hover .c-btn02__text {
    background-color: #fff !important;
    color: #FF5B4C !important;
    opacity: 1;
  }
  .c-btn02:hover:after {
    background-image: url(../img/hoken/common/icon-btn02-orange.svg);
  }
}
.c-btn02__text {
  padding: 20px 35px 19px 38px !important;
  width: 100% !important;
  display: block;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border-radius: 999px;
  overflow: hidden;
  font-size: 1.8rem !important;
  line-height: 1 !important;
  color: #fff !important;
  font-weight: 700;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  background-color: #FF5B4C !important;
  border: 2px solid #FF5B4C !important;
  transition: 0.3s ease-in-out;
  text-align: center;
  -webkit-appearance: none;
  border-radius: 999px !important;
}
@media screen and (max-width: 640px) {
  .c-btn02__text {
    padding: 12px 35px 10px 38px !important;
    font-size: 1.4rem !important;
  }
}
.c-btn02__text:focus, .c-btn02__text:hover {
  box-shadow: none;
  outline: none;
}
.c-btn02.is-style1:after {
  left: 30px;
  right: auto !important;
  background-image: url(../img/hoken/common/icon-btn02-gray.svg);
}
@media screen and (max-width: 640px) {
  .c-btn02.is-style1:after {
    left: 17px;
  }
}
@media screen and (min-width: 641px) {
  .c-btn02.is-style1:hover:after {
    background-image: url(../img/hoken/common/icon-btn02-white-left.svg);
  }
  .c-btn02.is-style1:hover .c-btn02__text {
    color: #fff !important;
    background-color: #B2B2B2 !important;
  }
}
.c-btn02.is-style1 .c-btn02__text {
  padding-left: 45px !important;
  padding-right: 44px !important;
  color: #46483C !important;
  border-color: #B2B2B2 !important;
  background-color: #fff !important;
}
@media screen and (max-width: 640px) {
  .c-btn02.is-style1 .c-btn02__text {
    padding-left: 35px !important;
    padding-right: 34px !important;
  }
}

/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*共通*/
.c-commonTitle01 {
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #46483c;
  border-bottom: solid 1px #46483c;
}

/*サービスサイト*/
.c-serviceTitle01 {
  margin-bottom: 40px;
  font-size: 3.2rem;
  font-weight: bold;
  color: #46483c;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.3;
}
.c-serviceTitle01:before {
  margin: 0 auto 13px auto;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
}
.c-serviceTitle01 span {
  margin-top: 3px;
  font-size: 1.4rem;
  font-weight: normal;
  color: #999999;
  display: block;
  letter-spacing: 0.01em;
}
@media screen and (max-width: 640px) {
  .c-serviceTitle01 span {
    margin-top: 0;
  }
}
.c-serviceTitle01.c-serviceTitle01--star:before {
  width: 30px;
  height: 29px;
  background-image: url("../img/common/icon_star01.png");
}
.c-serviceTitle01.c-serviceTitle01--news:before {
  width: 31px;
  height: 26px;
  background-image: url("../img/common/icon_news01.png");
}

/*チケット*/
.c-ticketTitle01 {
  margin-bottom: 21px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #232934;
  text-align: center;
  line-height: 1.5;
}
.c-ticketTitle01 img {
  margin: 0 auto 5px;
  display: block;
}

.c-ticketTitle02,
.c-ticketTitle02b {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  color: #232934;
  background-image: url("../img/ticket/line02.png");
  background-repeat: repeat-x;
  background-position: 0 bottom;
  line-height: 1.5;
}
.c-ticketTitle02 em,
.c-ticketTitle02b em {
  font-style: normal;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
}
.c-ticketTitle02 em:after,
.c-ticketTitle02b em:after {
  width: 100%;
  height: 3px;
  content: "";
  background-color: #fc819b;
  position: absolute;
  bottom: 0;
  left: 0;
}
.c-ticketTitle02--2 em:after,
.c-ticketTitle02b--2 em:after {
  background-color: #8cb340;
}

.c-ticketTitle03 {
  margin-bottom: 30px;
  padding-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #232934;
  border-bottom: solid 2px #fc819b;
}

.c-ticketTitle04 {
  margin-bottom: 41px;
  padding-top: 35px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #232934;
  background-image: url("../img/ticket/line01.png");
  background-repeat: repeat-x;
  background-position: 0 0;
}

.c-ticketTitle05 {
  text-align: center;
  position: relative;
  padding: 0 0 35px;
}
@media screen and (max-width: 640px) {
  .c-ticketTitle05 {
    padding: 0 0 21px;
  }
}
.c-ticketTitle05::after {
  content: "";
  width: 69px;
  height: 6px;
  background: url("../img/ticket/enquete/dots1.png") repeat-x center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
.c-ticketTitle05__icon {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto 12px;
}
@media screen and (max-width: 640px) {
  .c-ticketTitle05__icon {
    width: 40px;
    height: 40px;
    margin: 0 auto 5px;
  }
}
.c-ticketTitle05__txt {
  display: block;
  font-size: 3.2rem;
  line-height: 1.5;
  color: #46483c;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-ticketTitle05__txt {
    font-size: 2rem;
    line-height: 1.5;
  }
}

/*保険*/
.c-hokenTitle01 {
  margin-bottom: 15px;
  font-size: 2.2rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle01 {
    margin-bottom: 40px;
    font-size: 3.2rem;
  }
}
.c-hokenTitle01--mt {
  margin-top: 40px;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle01--mt {
    margin-top: 80px;
  }
}

.c-hokenTitle02 {
  margin-bottom: 31px;
  padding: 10px 10px 10px 24px;
  font-size: 2.4rem;
  letter-spacing: 1px;
  font-weight: bold;
  color: #46483c;
  background-color: rgba(130, 205, 243, 0.1);
  position: relative;
}
.c-hokenTitle02:before {
  width: 5px;
  height: 50%;
  content: "";
  background-image: url("../img/hoken/bg04.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 25%;
  left: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.c-hokenTitle02--orange {
  background-color: #fef6f0;
}
.c-hokenTitle02--orange:before {
  width: 5px;
  height: 50%;
  content: "";
  background-image: url("../img/hoken/bg07.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 25%;
  left: 0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.c-hokenTitle03 {
  margin-bottom: 24px;
  padding: 29px 0 26px 46px;
  font-size: 3.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: #46483c;
  background-image: url("../img/hoken/title_obi.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: right bottom;
  border-top: solid 4px #82cdf3;
}

.c-hokenTitle04 {
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #46483c;
  border-bottom: solid 2px #82cdf3;
}

.c-hokenTitle05 {
  margin-bottom: 14px;
  font-size: 2rem;
  letter-spacing: 1px;
  font-weight: bold;
  color: #46483c;
}
.c-hokenTitle05:before {
  width: 10px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/hoken/bg04.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.c-hokenTitle06 {
  margin-bottom: 40px;
  padding: 20px 40px;
  font-size: 2rem;
  font-weight: bold;
  color: #46483c;
  background-image: url("../img/hoken/title_obi.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: right bottom;
  border-top: solid 4px #82cdf3;
}
.c-hokenTitle06 h1,
.c-hokenTitle06 h2,
.c-hokenTitle06 h3,
.c-hokenTitle06 h4,
.c-hokenTitle06 h5,
.c-hokenTitle06 h6 {
  font-size: 2.8rem;
}

.c-hokenTitle07 {
  text-align: center;
  font-size: 3.4rem;
  line-height: 1.2;
  color: #253b73;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
}
@media screen and (max-width: 640px) {
  .c-hokenTitle07 {
    font-size: 1.6rem;
    line-height: 1.32;
  }
}

.c-hokenTitle08 {
  text-align: center;
  font-size: 3.2rem;
  line-height: 1.4;
  color: #46483c;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
}
@media screen and (max-width: 640px) {
  .c-hokenTitle08 {
    font-size: 2.1rem;
    line-height: 1.1;
  }
}

.c-hokenTitle09 {
  text-align: center;
  position: relative;
  padding: 0 0 35px;
}
@media screen and (max-width: 640px) {
  .c-hokenTitle09 {
    padding: 0 0 21px;
  }
}
.c-hokenTitle09::after {
  content: "";
  width: 69px;
  height: 6px;
  background: url("../img/hoken/common/dots2.png") repeat-x center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
.c-hokenTitle09__icon {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto 12px;
}
@media screen and (max-width: 640px) {
  .c-hokenTitle09__icon {
    width: 40px;
    height: 40px;
    margin: 0 auto 5px;
  }
}
.c-hokenTitle09__txt {
  display: block;
  font-size: 3.2rem;
  line-height: 1.5;
  color: #46483c;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-hokenTitle09__txt {
    font-size: 2rem;
    line-height: 1.5;
  }
}

.c-hokenTitle10 {
  margin-bottom: 10px;
  padding-bottom: 3px;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10 {
    padding-bottom: 10px;
  }
}
.c-hokenTitle10::before, .c-hokenTitle10::after {
  content: "";
  height: 3px;
  position: absolute;
  bottom: 0;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10::before, .c-hokenTitle10::after {
    height: 6px;
  }
}
.c-hokenTitle10::before {
  width: 57.5px;
  background-color: #FA8246;
  left: 0;
  z-index: 1;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10::before {
    width: 320px;
  }
}
.c-hokenTitle10::after {
  width: 100%;
  background-color: #41B4E6;
  right: 0;
}
.c-hokenTitle10__text {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 640px) {
  .c-hokenTitle10__text {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10__text {
    font-size: 40px;
  }
}
.c-hokenTitle10__subText {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  color: #333;
  font-size: 1rem;
}
@media screen and (max-width: 640px) {
  .c-hokenTitle10__subText {
    margin-top: -0.5em;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10__subText {
    margin-top: -0.5em;
    font-size: 1.6rem;
  }
}
.c-hokenTitle10__subText2 {
  margin-top: 10px;
  color: #333;
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10__subText2 {
    font-size: 2.4rem;
  }
}
.c-hokenTitle10__tags {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10__tags {
    margin-bottom: 0;
    column-gap: 10px;
  }
}
.c-hokenTitle10__tag {
  padding: 5px;
  background-color: #fff;
  border: 1.5px solid #41B4E6;
  border-radius: 5px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle10__tag {
    font-size: 1.2rem;
  }
}
.c-hokenTitle10__tag--corp {
  color: #649333;
  border-color: #649333;
}
.c-hokenTitle10__tag--request {
  color: #ffffff;
  background-color: #ff5b4c;
  border-color: #ff5b4c;
}
.c-hokenTitle10__tag--internet {
  color: #ffffff;
  background-color: #007a50;
  border-color: #007a50;
}
.c-hokenTitle10__tag--campain {
  color: #f99f2b;
  border-color: #f99f2b;
}
.c-hokenTitle10__tag--attention {
  color: #f76aa3;
  border-color: #f76aa3;
}
.c-hokenTitle10__tag--popular {
  color: #fd5724;
  border-color: #fd5724;
}
.c-hokenTitle10__tag--new {
  color: #f03f4e;
  border-color: #f03f4e;
}
.c-hokenTitle10__tag--interview {
  color: #41B4E6;
  border-color: #41B4E6;
}

.c-hokenTitle11 {
  margin-top: 40px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  column-gap: 7px;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle11 {
    margin-top: 80px;
    margin-bottom: 40px;
    column-gap: 14px;
    font-size: 2.4rem;
  }
}
.c-hokenTitle11::before {
  content: "";
  width: 3px;
  height: 17px;
  background: linear-gradient(0deg, #41B4E6 0%, #41B4E6 50%, #FA8246 50%, #FA8246 100%);
}
@media screen and (min-width: 641px) {
  .c-hokenTitle11::before {
    width: 6px;
    height: 20px;
  }
}

.c-hokenTitle12 {
  display: flex;
  align-items: center;
  column-gap: 7px;
  font-size: 1.9rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle12 {
    column-gap: 14px;
    font-size: 2.4rem;
  }
}
.c-hokenTitle12::before {
  content: "";
  width: 3px;
  height: 17px;
  background: linear-gradient(0deg, #41B4E6 0%, #41B4E6 50%, #FA8246 50%, #FA8246 100%);
}
@media screen and (min-width: 641px) {
  .c-hokenTitle12::before {
    width: 6px;
    height: 20px;
  }
}

.c-hokenTitle13 {
  padding-bottom: 5px;
  font-size: 2.2rem;
  font-weight: 700;
  border-bottom: 1.5px solid #41B4E6;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle13 {
    border-width: 3px;
    font-size: 3.2rem;
  }
}

.c-hokenTitle14 {
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #41B4E6;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-hokenTitle14 {
    column-gap: 10px;
    font-size: 3.2rem;
  }
}
.c-hokenTitle14 svg {
  min-width: 30px;
  width: 30px;
  height: 30px;
}

.c-sb_none {
  justify-content: center;
}

/*リフォーム*/
.c-refomeTitle01 {
  margin-bottom: 30px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #342603;
  text-align: center;
}
.c-refomeTitle01 span {
  padding: 0 60px;
  display: inline-block;
  background: url("../img/reform/title_ornament_left.png") no-repeat left bottom 12px, url("../img/reform/title_ornament_right.png") no-repeat right bottom 12px;
  -webkit-background-size: 25px;
  background-size: 25px;
}
.c-refomeTitle01 img {
  margin: 0 auto 3px auto;
  display: block;
}

.c-refomeTitle02 {
  width: 100%;
  min-width: 1120px;
  height: 200px;
  background-image: url("../img/reform/title_obi.jpg");
  background-position: center bottom;
  position: relative;
  overflow-x: hidden;
}
.c-refomeTitle02__inner {
  width: 1120px;
  margin: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-refomeTitle02__ttl1 {
  display: block;
  font-size: 2.8rem;
  line-height: 1.3;
  font-weight: bold;
  color: #342603;
  padding-bottom: 11px;
}
.c-refomeTitle02__ttl2 {
  display: block;
  font-size: 1.8rem;
  font-weight: normal;
  color: #247404;
  font-family: "Garamond Classico";
}

.c-refomeTitle03 {
  margin-bottom: 22px;
  padding: 0 115px 0 20px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #141414;
  position: relative;
}
.c-refomeTitle03:before {
  width: 5px;
  height: 28px;
  content: "";
  background-color: #247404;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.c-refomeTitle03:after {
  width: 105px;
  height: 12px;
  content: "";
  background: url("../img/reform/title_img01.png") no-repeat;
  position: absolute;
  top: 17px;
  right: 8px;
}

.c-refomeTitle03b {
  margin-bottom: 32px;
  padding: 0 25px;
  font-size: 3.2rem;
  font-weight: bold;
  color: #141414;
  position: relative;
  line-height: 1.2;
}
.c-refomeTitle03b:before {
  width: 5px;
  height: 100%;
  content: "";
  background-color: #247404;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.c-refomeTitle03b span {
  font-size: 1.4rem;
  color: #666666;
  display: block;
}

.c-refomeTitle03c {
  margin-bottom: 24px;
  padding: 0 25px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #141414;
  position: relative;
}
.c-refomeTitle03c:before {
  width: 5px;
  height: 100%;
  content: "";
  background-color: #247404;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.c-refomeTitle03c span {
  font-size: 1.4rem;
  font-weight: 500;
  color: #666666;
  display: block;
  letter-spacing: 0px;
}

.c-refomeTitle04 {
  margin-bottom: 30px;
  padding: 9px 0 8px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
  border-top: dotted 1px #4c4c4c;
  border-bottom: dotted 1px #4c4c4c;
}

.c-refomeTitle05 {
  margin-bottom: 25px;
  padding-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #46483c;
  border-bottom: solid 2px #68ba93;
}

.c-refomeTitle06 {
  margin-bottom: 26px;
  padding: 10px 20px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #68ba93;
}

.c-reformeTitle07 {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  color: #232934;
  background-image: url("../img/ticket/line02.png");
  background-repeat: repeat-x;
  background-position: 0 bottom;
  line-height: 1.5;
}
.c-reformeTitle07 em {
  font-style: normal;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
}
.c-reformeTitle07 em:after {
  width: 100%;
  height: 3px;
  content: "";
  background-color: #68ba93;
  position: absolute;
  bottom: 0;
  left: 0;
}

/*くらしのサービス*/
.c-kurashiTitle01 {
  margin-bottom: 30px;
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  color: #483825;
}
.c-kurashiTitle01:after {
  width: 340px;
  height: 5px;
  margin: 15px auto 0 auto;
  content: "";
  display: block;
  background-image: url("../img/kurashi/title01_line.png");
}
.c-kurashiTitle01 img {
  display: block;
  margin: 8px auto;
}

.c-kurashiTitle02 {
  margin-bottom: 33px;
  padding: 0 120px 0 24px;
  font-size: 2.8rem;
  line-height: 1;
  font-weight: bold;
  color: #483825;
  position: relative;
}
.c-kurashiTitle02:before {
  width: 5px;
  height: 28px;
  content: "";
  background-color: #faad5a;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.c-kurashiTitle02:after {
  width: 100px;
  height: 5px;
  content: "";
  background-image: url("../img/kurashi/title_img01.jpg");
  position: absolute;
  top: calc((100% - 5px) / 2);
  right: 0;
}

.c-kurashiTitle03 {
  margin-bottom: 30px;
  padding-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #594242;
  border-bottom: solid 2px #faad5a;
}

.c-kurashiTitle04 {
  margin-bottom: 25px;
  font-size: 2rem;
  font-weight: bold;
  color: #594242;
  background-image: url("../img/kurashi/line02.png");
  background-repeat: repeat-x;
  background-position: 0 bottom;
}
.c-kurashiTitle04 span {
  padding-bottom: 10px;
  display: inline-block;
  border-bottom: solid 1px #faad5a;
}

.c-kurashiTitle05 {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  color: #232934;
  background-image: url("../img/ticket/line02.png");
  background-repeat: repeat-x;
  background-position: 0 bottom;
  line-height: 1.5;
}
.c-kurashiTitle05 em {
  font-style: normal;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
}
.c-kurashiTitle05 em:after {
  width: 100%;
  height: 3px;
  content: "";
  background-color: #faad5a;
  position: absolute;
  bottom: 0;
  left: 0;
}

/*葬祭*/
.c-sosaiTitle01 {
  margin-bottom: 51px;
  color: #2f2933;
  font-size: 3.6rem;
  font-weight: normal;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle01 span {
  display: inline-block;
}
.c-sosaiTitle01 span:after {
  width: 100%;
  height: 3px;
  margin: 8px auto 0 auto;
  content: "";
  background-color: #2e95a3;
  display: block;
}

.c-sosaiTitle02 {
  margin-bottom: 60px;
  color: #2f2933;
  font-size: 3rem;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle02 span {
  display: inline-block;
}
.c-sosaiTitle02 span:after {
  width: 100%;
  height: 3px;
  margin: 20px auto 0 auto;
  content: "";
  background-color: #2e95a3;
  display: block;
}

.c-sosaiTitle02b {
  margin-bottom: 54px;
  color: #2f2933;
  font-size: 3.6rem;
  font-weight: 400;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle02b span {
  display: inline-block;
}
.c-sosaiTitle02b span:after {
  width: 100%;
  height: 3px;
  margin: 7px auto 0 auto;
  content: "";
  background-color: #2e95a3;
  display: block;
}

.c-sosaiTitle03 {
  margin-bottom: 30px;
  font-size: 3rem;
  font-weight: 500;
  color: #2f2933;
  text-align: center;
  line-height: 1.5;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle03 span {
  display: block;
  font-size: 1.1rem;
  font-style: italic;
  color: #2e95a3;
  padding-top: 7px;
  font-family: "Garamond Classico";
}

.c-sosaiTitle03b {
  margin-bottom: 30px;
  font-size: 3rem;
  color: #2e95a3;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle03b img {
  margin: 10px auto 0;
  display: block;
}

.c-sosaiTitle04 {
  margin-bottom: 40px;
  font-size: 3.8em;
  font-weight: normal;
  color: #2f2933;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle04 img {
  margin: 3px 4px 4px 0;
  display: block;
}
.c-sosaiTitle04 img.sp-only {
  display: none;
}

.c-sosaiTitle05 {
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #2f2933;
  border-bottom: solid 2px #2e95a3;
}

.c-sosaiTitle06 {
  margin-bottom: 32px;
  font-size: 3.2rem;
  font-weight: bold;
  color: #2e95a3;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle06:after {
  width: 40px;
  height: 1px;
  margin: 3px 0 0 0;
  content: "";
  background-color: #2e95a3;
  display: block;
}

.c-sosaiTitle07 {
  margin-bottom: 12px;
  padding-left: 20px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #2f2933;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle07:before {
  width: 8px;
  height: 8px;
  margin: -5px 12px 0 -20px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}

.c-sosaiTitle08 {
  margin-bottom: 30px;
  padding: 15px 30px 11px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle08 span {
  margin: -5px 20px 0 0;
  padding: 5px 9px;
  font-size: 1.3rem;
  background-color: #ad76af;
  display: inline-block;
  vertical-align: middle;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}

.c-sosaiTitle09 {
  margin-bottom: 25px;
  padding-left: 30px;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.28;
  color: #2f2933;
  position: relative;
}
.c-sosaiTitle09:before {
  width: 4px;
  height: 100%;
  content: "";
  background-color: #2e95a3;
  position: absolute;
  top: 0;
  left: 0;
}

.c-sosaiTitle10 {
  margin-bottom: 20px;
  padding-left: 30px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #111111;
}
.c-sosaiTitle10:before {
  width: 18px;
  height: 18px;
  margin: -5px 12px 0 -30px;
  content: "";
  background-image: url("../img/common/icon_maru_blue01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.c-sosaiTitle11 {
  margin-bottom: 30px;
  padding: 20px 15px 15px 84px;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.3;
  color: #ffffff;
  background-color: #333745;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle11:before {
  width: 50px;
  height: 50px;
  margin: -8px 19px 0 -69px;
  content: "";
  background-image: url("../img/sosai/icon_sosai02.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.c-sosaiTitle12 {
  min-height: 46px;
  margin-bottom: 14px;
  padding: 2px 0 2px 65px;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: #111111;
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiTitle12:before {
  width: 46px;
  height: 46px;
  content: "";
  background-image: url("../img/sosai/icon_sosai01.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.c-sosaiTitle12 span {
  margin: -7px 0 4px;
  font-size: 1.8rem;
  line-height: 1;
  display: block;
}

.c-sosaiTitle13 {
  margin-bottom: 30px;
  color: #2e95a3;
  font-size: 2rem;
  font-weight: 700;
}
.c-sosaiTitle13:before {
  width: 18px;
  height: 18px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_check_blue01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

/*フォームタイトル*/
.c-formTitle {
  padding-left: 40px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #46483c;
}
.c-formTitle__icon {
  margin: -6px 10px 0 -40px;
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-formTitle__icon--ticket01 {
  width: 30px;
  height: 26px;
  background-image: url("../img/common/icon_cart04.png");
}
.c-formTitle__icon--hoken01 {
  width: 27px;
  height: 30px;
  margin-right: 13px;
  background-image: url("../img/common/icon_file02.png");
}
.c-formTitle__icon--hoken02 {
  width: 30px;
  height: 23px;
  background-image: url("../img/common/icon_mail01.png");
}
.c-formTitle__icon--reform01 {
  width: 27px;
  height: 30px;
  margin-right: 13px;
  background-image: url("../img/common/icon_file03.png");
}
.c-formTitle__icon--kurashi01 {
  width: 30px;
  height: 33px;
  background-image: url("../img/common/icon_cart05.png");
}
.c-formTitle__icon--sosai01 {
  width: 27px;
  height: 30px;
  margin-right: 13px;
  background-image: url("../img/common/icon_file04.png");
}
.c-formTitle__icon--sosai02 {
  width: 30px;
  height: 30px;
  background-image: url("../img/common/icon_document01.png");
}

.c-formTitle02 {
  color: #333;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .c-formTitle02 {
    font-size: 3rem;
  }
}
.c-formTitle02::after {
  content: "";
  margin-top: 10px;
  width: 100%;
  height: 6px;
  background: url("../img/hoken/hoken_line02.svg") no-repeat center;
  background-size: contain;
  display: block;
}
@media screen and (min-width: 641px) {
  .c-formTitle02::after {
    margin-top: 10px;
    height: 12px;
  }
}

@media screen and (max-width: 640px) {
  /*共通 sp*/
  .c-commonTitle01 {
    font-size: 1.6rem;
  }
  /*サービスサイト SP*/
  .c-serviceTitle01 {
    margin-bottom: 20px;
    font-size: 2.2rem;
    line-height: 1.5;
  }
  .c-serviceTitle01.c-serviceTitle01--star:before {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
  }
  .c-serviceTitle01 span {
    font-size: 1.4rem;
    margin-top: -2px;
  }
  /*チケット SP*/
  .c-ticketTitle01 {
    margin-bottom: 20px;
    font-size: 2.1rem;
  }
  .c-ticketTitle01 img {
    width: 82px;
    height: auto;
    margin-bottom: 4px;
  }
  .c-ticketTitle02 {
    margin-bottom: 20px;
    padding: 9px 24px 8px 14px;
    font-size: 1.4rem;
    background: none;
    background-color: #ffffff;
    position: relative;
    border-top: solid 1px #efefef;
    border-bottom: solid 1px #efefef;
  }
  .c-ticketTitle02:before {
    width: 3px;
    height: 100%;
    content: "";
    background-color: #fc819b;
    position: absolute;
    top: 0;
    left: 0;
  }
  .c-ticketTitle02 em {
    padding: 0;
  }
  .c-ticketTitle02 em:after {
    content: none;
  }
  .c-ticketTitle02b {
    margin-bottom: 20px;
    font-size: 1.7rem;
  }
  .c-ticketTitle03 {
    margin-bottom: 14px;
    padding-bottom: 9px;
    font-size: 1.6rem;
    border-bottom: solid 1px #fc819b;
  }
  .c-ticketTitle04 {
    margin-bottom: 30px;
    padding-top: 20px;
    font-size: 1.7rem;
    background-image: url("../img/ticket/line01_sp.png");
    -webkit-background-size: 15px;
    background-size: 15px;
  }
  /*保険 SP*/
  .c-hokenTitle02 {
    margin-bottom: 10px;
    padding: 5px 5px 5px 17px;
    font-size: 2rem;
  }
  .c-hokenTitle02:before {
    width: 3px;
  }
  .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 30px 20px;
    font-size: 2.1rem;
    background-image: url("../img/hoken/title_obi_sp.jpg");
  }
  .c-hokenTitle04 {
    margin-bottom: 30px;
    padding-bottom: 5px;
    font-size: 2rem;
  }
  .c-hokenTitle05 {
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
  .c-hokenTitle06 {
    margin-bottom: 30px;
    padding: 30px 20px;
    font-size: 1.9rem;
    background-image: url("../img/hoken/title_obi_sp.jpg");
  }
  .c-hokenTitle06 h1,
  .c-hokenTitle06 h2,
  .c-hokenTitle06 h3,
  .c-hokenTitle06 h4,
  .c-hokenTitle06 h5,
  .c-hokenTitle06 h6 {
    font-size: 2.5rem;
  }
  /*リフォーム SP*/
  .c-refomeTitle01 {
    margin-bottom: 18px;
    font-size: 1.9rem;
    line-height: 1.5;
  }
  .c-refomeTitle01 span {
    padding: 0 35px;
    -webkit-background-size: 25px;
    background-size: 25px;
  }
  .c-refomeTitle01 img {
    margin: 0 auto 10px auto;
  }
  .c-refomeTitle02 {
    min-width: 100%;
    height: auto;
    padding: 30px 20px;
    font-size: 2rem;
    background-image: url("../img/reform/title_obi_sp.jpg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .c-refomeTitle02__inner {
    width: 100%;
    position: static;
    transform: none;
  }
  .c-refomeTitle02__ttl1 {
    font-size: 1.7rem;
    padding-bottom: 4px;
  }
  .c-refomeTitle02__ttl2 {
    font-size: 1.3rem;
  }
  .c-refomeTitle03 {
    margin-bottom: 33px;
    padding: 0 0 0 11px;
    font-size: 1.9rem;
  }
  .c-refomeTitle03:before {
    width: 3px;
    height: 18px;
  }
  .c-refomeTitle03:after {
    top: auto;
    bottom: -18px;
    left: 0;
    background-size: 66px;
  }
  .c-refomeTitle03b {
    margin-bottom: 30px;
    padding: 0 0 0 11px;
    font-size: 2.2rem;
  }
  .c-refomeTitle03b:before {
    width: 3px;
  }
  .c-refomeTitle03b span {
    font-size: 1.2rem;
  }
  .c-refomeTitle03c {
    margin-bottom: 15px;
    padding: 0 0 0 11px;
    font-size: 1.9rem;
  }
  .c-refomeTitle03c:before {
    width: 3px;
  }
  .c-refomeTitle03c span {
    font-size: 1.2rem;
  }
  .c-refomeTitle04 {
    font-size: 1.5rem;
    margin-bottom: 12px;
    padding: 6px 0 4px;
  }
  .c-refomeTitle05 {
    margin-bottom: 30px;
    padding-bottom: 5px;
    font-size: 2rem;
  }
  .c-refomeTitle06 {
    margin-bottom: 18px;
    padding: 4px 10px;
    font-size: 1.1rem;
  }
  /*くらしのサービス sp*/
  .c-kurashiTitle01 {
    margin-bottom: 20px;
    font-size: 2.4rem;
  }
  .c-kurashiTitle01 img {
    margin: 7px auto;
  }
  .c-kurashiTitle01:after {
    width: 100%;
    margin: 14px auto 0 auto;
  }
  .c-kurashiTitle02 {
    margin-bottom: 20px;
    padding: 0 60px 0 12px;
    font-size: 1.8rem;
  }
  .c-kurashiTitle02:before {
    width: 3px;
    height: 18px;
  }
  .c-kurashiTitle02:after {
    width: 52px;
  }
  .c-kurashiTitle03 {
    margin-bottom: 14px;
    padding-bottom: 14px;
    font-size: 1.6rem;
    line-height: 1;
    border-bottom: solid 1px #faad5a;
  }
  .c-kurashiTitle04 {
    margin-bottom: 12px;
    font-size: 1.4rem;
  }
  .c-kurashiTitle04 span {
    padding-bottom: 9px;
  }
  /*葬祭 sp*/
  .c-sosaiTitle01 {
    margin-bottom: 19px !important;
    font-size: 2.3rem;
  }
  .c-sosaiTitle01 span:after {
    margin: 3px auto 0 auto;
  }
  .c-sosaiTitle02,
  .c-sosaiTitle02b {
    margin-bottom: 35px;
    font-size: 2.3rem;
    line-height: 1.3;
  }
  .c-sosaiTitle02 span:after,
  .c-sosaiTitle02b span:after {
    margin: 10px auto 0 auto;
  }
  .c-sosaiTitle03 {
    margin-bottom: 15px;
    font-size: 2rem;
    line-height: 1;
  }
  .c-sosaiTitle03 span {
    padding-top: 5px;
    font-size: 0.7rem;
  }
  .c-sosaiTitle03b {
    margin-bottom: 15px;
    font-size: 2rem;
    line-height: 1;
  }
  .c-sosaiTitle03b span:after {
    margin: 3px auto 0 auto;
  }
  .c-sosaiTitle04 {
    margin-bottom: 20px;
    font-size: 2.3rem;
    line-height: 1;
  }
  .c-sosaiTitle04 img {
    margin: 8px auto 0;
  }
  .c-sosaiTitle04 img.pc-only {
    display: none;
  }
  .c-sosaiTitle04 img.sp-only {
    display: block;
  }
  .c-sosaiTitle05 {
    margin-bottom: 20px;
    font-size: 2rem;
  }
  .c-sosaiTitle06 {
    margin-bottom: 20px;
    font-size: 2.2rem;
  }
  .c-sosaiTitle07 {
    margin-bottom: 15px;
    font-size: 2rem;
  }
  .c-sosaiTitle08 {
    margin-bottom: 15px;
    padding: 14px 15px;
    font-size: 1.9rem;
  }
  .c-sosaiTitle08 span {
    margin: 0 13px 0 0;
    padding: 6px 7px;
    font-size: 1rem;
    line-height: 1.3;
  }
  .c-sosaiTitle09 {
    margin-bottom: 20px;
    padding-left: 13px;
    font-size: 1.9rem;
  }
  .c-sosaiTitle09:before {
    width: 2px;
  }
  .c-sosaiTitle10 {
    margin-bottom: 15px;
    padding-left: 15px;
    text-indent: 10px;
    font-size: 1.4rem;
  }
  .c-sosaiTitle10:before {
    width: 10px;
    height: 10px;
    margin: -5px 5px 0 -25px;
    content: "";
  }
  .c-sosaiTitle11 {
    margin-bottom: 15px;
    padding: 10px 10px 10px 55px;
    font-size: 2.4rem;
  }
  .c-sosaiTitle11:before {
    width: 30px;
    height: 30px;
    margin: -5px 15px 0 -45px;
  }
  .c-sosaiTitle12 {
    min-height: 36px;
    margin-bottom: 15px;
    padding: 2px 0 2px 50px;
    font-size: 2.24rem;
  }
  .c-sosaiTitle12:before {
    width: 33px;
    height: 33px;
  }
  .c-sosaiTitle12 span {
    font-size: 1.26rem;
  }
  .c-sosaiTitle13 {
    margin-bottom: 20px;
    font-size: 1.4rem;
  }
  .c-sosaiTitle13:before {
    width: 14px;
    height: 14px;
    margin: -3px 5px 0 0;
  }
  /*フォームタイトル sp*/
  .c-formTitle {
    font-size: 1.8rem;
  }
  .c-formTitle__icon--ticket01 {
    width: 25px;
    height: 22px;
    margin: -4px 7px 0 -40px;
  }
  .c-formTitle__icon--hoken01 {
    width: 24px;
    height: 27px;
    margin-top: -3px;
  }
}
.c-kurashi-enqueteTitle1 {
  position: relative;
  font-size: 3.2rem;
  line-height: 48px;
  font-weight: bold;
  text-align: center;
  color: #232934;
}
@media screen and (max-width: 640px) {
  .c-kurashi-enqueteTitle1 {
    font-size: 2rem;
    line-height: 30px;
  }
}
.c-kurashi-enqueteTitle1::before {
  content: "";
  position: relative;
  display: block;
  background: url("../img/kurashi/enquete/icon_pencil.svg") no-repeat center;
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  margin: 0 auto 12px;
}
@media screen and (max-width: 640px) {
  .c-kurashi-enqueteTitle1::before {
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
    margin: 0 auto 4px;
  }
}
.c-kurashi-enqueteTitle1::after {
  content: "";
  position: relative;
  display: block;
  background: url("../img/kurashi/enquete/icon_line.svg") repeat-x;
  width: 69px;
  height: 6px;
  background-size: 69px 6px;
  margin: 32px auto 0;
}
@media screen and (max-width: 640px) {
  .c-kurashi-enqueteTitle1::after {
    margin: 16px auto 0;
  }
}

/*------------------------------------------------------------
icon
------------------------------------------------------------*/
.c-cateIcon01 {
  width: 60px;
  height: 60px;
  display: block;
  background-repeat: no-repeat;
  -webkit-background-size: 66%;
  background-size: 66%;
  background-position: center center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
.c-cateIcon01.c-cateIcon01--ticket {
  background-color: #fc819b;
  background-image: url("../img/common/icon_ticket01.png");
}
.c-cateIcon01.c-cateIcon01--ticket + a, .c-cateIcon01.c-cateIcon01--ticket + strong {
  color: #783146;
}
.c-cateIcon01.c-cateIcon01--hoken {
  background-color: #82cdf3;
  background-image: url("../img/common/icon_hoken01.png");
}
.c-cateIcon01.c-cateIcon01--hoken + a, .c-cateIcon01.c-cateIcon01--hoken + strong {
  color: #253b73;
}
.c-cateIcon01.c-cateIcon01--reform {
  background-color: #68ba93;
  background-image: url("../img/common/icon_reform01.png");
}
.c-cateIcon01.c-cateIcon01--reform + a, .c-cateIcon01.c-cateIcon01--reform + strong {
  color: #154827;
}
.c-cateIcon01.c-cateIcon01--kurashi {
  background-color: #faad5a;
  background-image: url("../img/common/icon_kurashi01.png");
}
.c-cateIcon01.c-cateIcon01--kurashi + a, .c-cateIcon01.c-cateIcon01--kurashi + strong {
  color: #7c4b12;
}
.c-cateIcon01.c-cateIcon01--sosai {
  background-color: #af8ebe;
  background-image: url("../img/common/icon_sosai01.png");
}
.c-cateIcon01.c-cateIcon01--sosai + a, .c-cateIcon01.c-cateIcon01--sosai + strong {
  color: #503f69;
}
.c-cateIcon01.c-cateIcon01--kurashi-enquete {
  background-color: #faad5a;
  background-image: url("../img/common/icon_kurashi01.png");
}
.c-cateIcon01.c-cateIcon01--kurashi-enquete + a, .c-cateIcon01.c-cateIcon01--kurashi-enquete + strong {
  color: #7c4b12;
}

@media screen and (max-width: 640px) {
  .c-cateIcon01 {
    width: 40px;
    height: 40px;
    -webkit-background-size: 80%;
    background-size: 80%;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
.c-form1 {
  color: #46483C;
  text-align: left;
}
.c-form1 select,
.c-form1 input,
.c-form1 textarea {
  box-sizing: border-box;
  background-color: #fff;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  box-shadow: none;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.c-form1 select {
  width: 100%;
}
.c-form1 select::-ms-expand {
  display: none;
}
.c-form1 input {
  width: 100%;
}
.c-form1 input::-webkit-outer-spin-button, .c-form1 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.c-form1 input[type=number] {
  -moz-appearance: textfield;
}
.c-form1 input::-webkit-input-placeholder {
  color: #989898;
}
.c-form1 input:-ms-input-placeholder {
  color: #989898;
}
.c-form1 input::placeholder {
  color: #989898;
}
.c-form1 input::-ms-clear {
  display: none;
}
.c-form1 input:-webkit-autofill, .c-form1 input:-webkit-autofill:hover, .c-form1 input:-webkit-autofill:focus, .c-form1 input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
}
.c-form1 input:hover, .c-form1 input:focus {
  box-shadow: none;
  outline: none;
}
.c-form1 input[type=date]::-webkit-inner-spin-button,
.c-form1 input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.c-form1 textarea {
  padding: 15px 20px;
  resize: none;
  width: 100%;
  height: 200px;
  font-size: inherit;
  line-height: inherit;
  font-weight: 500;
  color: #46483C;
  border: 1px solid #D5D5D5;
  border-radius: 2px;
}
@media screen and (max-width: 640px) {
  .c-form1 textarea {
    height: 150px;
  }
}
.c-form1 textarea::-webkit-input-placeholder {
  color: #989898;
}
.c-form1 textarea:-ms-input-placeholder {
  color: #989898;
}
.c-form1 textarea::placeholder {
  color: #989898;
}
.c-form1 .u-style02 th,
.c-form1 .u-style02 input,
.c-form1 .u-style02 select,
.c-form1 .u-style02 textarea {
  background-color: rgba(242, 39, 39, 0.2);
}
.c-form1 .error {
  margin-top: 6px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #f22727;
  display: block;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .c-form1 .error {
    font-size: 1.1rem;
  }
}
.c-form1 .error + .c-form1__title.is-style1 {
  margin-top: 22px;
}
@media screen and (max-width: 640px) {
  .c-form1 .error + .c-form1__title.is-style1 {
    margin-top: 23px;
  }
}
.c-form1 .error + .c-form1__title.is-style2 {
  margin-top: 34px;
}
@media screen and (max-width: 640px) {
  .c-form1 .error + .c-form1__title.is-style2 {
    margin-top: 14px;
  }
}
.c-form1 .error + .c-form1__text2 {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .c-form1 .error + .c-form1__text2 {
    margin-top: 23px;
  }
}
.c-form1__grerror {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 641px) {
  .c-form1__grerror {
    padding-left: 140px;
  }
}
.c-form1__grerror + .c-form1__title.is-style2 {
  margin-top: 38px;
}
@media screen and (max-width: 640px) {
  .c-form1__grerror + .c-form1__title.is-style2 {
    margin-top: 19px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__grerror .error {
    width: 50%;
    max-width: 288px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__selects {
    flex: 1;
    display: flex;
  }
}
.c-form1__select {
  position: relative;
  flex: 1;
}
@media screen and (min-width: 641px) {
  .c-form1__select {
    max-width: 200px;
  }
}
.c-form1__select:before {
  left: 28px;
  top: 0;
  position: absolute;
  pointer-events: none;
  content: "";
  width: 8px;
  height: 100%;
  background: url(../img/hoken/common/icon_selectbox.svg) no-repeat center/9px auto;
  transform: rotate(90deg);
}
@media screen and (max-width: 640px) {
  .c-form1__select:before {
    left: 17px;
  }
}
.c-form1__select__inner {
  padding: 0 10px 0 47px;
  height: 40px;
  border: 1px solid #D5D5D5;
  border-radius: 2px;
  width: 100%;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
@media screen and (max-width: 640px) {
  .c-form1__select__inner {
    padding-left: 32px;
    border-radius: 1px;
  }
}
.c-form1__selectbox {
  display: flex;
}
@media screen and (min-width: 641px) {
  .c-form1__selectbox {
    max-width: 288px;
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__selectbox + .c-form1__selectbox {
    margin-top: 10px;
  }
}
.c-form1__selectbox__title {
  padding-top: 9px;
  padding-right: 10px;
  width: max-content;
  min-width: 58px;
}
@media screen and (max-width: 640px) {
  .c-form1__selectbox__title {
    padding-top: 10px;
    padding-right: 5px;
    min-width: 62px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__selectbox .c-form1__input {
    flex: 1;
  }
}
.c-form1__selectgr {
  font-size: 1.6rem;
  line-height: 1.5;
}
@media screen and (min-width: 641px) {
  .c-form1__selectgr {
    display: flex;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__selectgr {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__selectgr__title {
    padding-top: 9px;
    padding-right: 10px;
    width: 140px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__selectgr__title {
    margin-bottom: 6px;
  }
}
.c-form1__selectgr + .c-form1__title.is-style2 {
  margin-top: 38px;
}
@media screen and (max-width: 640px) {
  .c-form1__selectgr + .c-form1__title.is-style2 {
    margin-top: 19px;
  }
}
.c-form1__selectgr + .c-form1__selectgr {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .c-form1__selectgr + .c-form1__selectgr {
    margin-top: 12px;
  }
}
.c-form1__text2 {
  margin: 20px 0 10px;
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-form1__text2 {
    margin: 23px 0 14px;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.c-form1__row {
  margin-bottom: 33px;
}
@media screen and (max-width: 640px) {
  .c-form1__row {
    margin-bottom: 30px;
  }
}
.c-form1__row.is-hide {
  display: none;
}
.c-form1__label {
  padding: 6px 3px 5px;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 700;
  color: #fff;
  min-width: 34px;
  text-align: center;
  border-radius: 2px;
  background-color: #ff4433;
  display: inline-block;
}
.c-form1__label.is-style1 {
  background-color: #989898;
}
@media screen and (min-width: 641px) {
  .c-form1__label.is-style1 {
    padding: 4px 3px 3px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__label {
    padding: 5px 3px 4px;
    border-radius: 1px;
    font-size: 1rem;
    min-width: 28px;
  }
}
.c-form1__grcontrol {
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (max-width: 640px) {
  .c-form1__grcontrol {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.c-form1__textarea {
  font-size: 1.6rem;
  line-height: 1.875;
  display: flex;
  width: 100%;
}
.c-form1__textarea:last-child {
  margin-bottom: 40px;
}
@media screen and (max-width: 640px) {
  .c-form1__textarea:last-child {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__textarea {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__textarea textarea {
    padding: 10px 10px;
  }
}
.c-form1__radio {
  display: block;
}
.c-form1__radio + .c-form1__radio {
  margin-top: 4px;
}
@media screen and (max-width: 640px) {
  .c-form1__radio + .c-form1__radio {
    margin-top: 13px;
  }
}
.c-form1__radio + .c-form1__title.is-style2 {
  margin-top: 34px;
}
@media screen and (max-width: 640px) {
  .c-form1__radio + .c-form1__title.is-style2 {
    margin-top: 14px;
  }
}
.c-form1__radio__label {
  cursor: pointer;
  position: relative;
  display: flex;
  width: max-content;
  max-width: 100%;
}
.c-form1__radio__text {
  padding-left: 11px;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
  flex: 1;
}
@media screen and (max-width: 640px) {
  .c-form1__radio__text {
    padding-left: 10px;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.c-form1__radio__text .is-small {
  margin-top: -4px;
  font-size: 1.4rem;
  display: block;
  text-indent: -12px;
  padding-left: 11px;
}
@media screen and (max-width: 640px) {
  .c-form1__radio__text .is-small {
    margin-top: 3px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.c-form1__radio input {
  min-width: 18px;
  max-width: 18px;
  width: auto !important;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #707070 !important;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .c-form1__radio input {
    top: -1px;
    position: relative;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
  }
}
.c-form1__radio input:checked {
  border: 5px solid #82CDF3 !important;
}
.c-form1__radio + .c-form1__title.is-style1 {
  margin-top: 22px;
}
@media screen and (max-width: 640px) {
  .c-form1__radio + .c-form1__title.is-style1 {
    margin-top: 23px;
  }
}
.c-form1__title {
  display: flex;
  align-items: flex-start;
}
.c-form1__title:not(.is-style1) {
  padding: 31px 30px 26px;
  margin-bottom: 20px;
  background-color: #F3F3F3;
}
@media screen and (max-width: 640px) {
  .c-form1__title:not(.is-style1) {
    padding: 15px 15px 9px;
    margin-bottom: 19px;
  }
}
.c-form1__title.is-style1 {
  margin-bottom: 14px;
}
@media screen and (max-width: 640px) {
  .c-form1__title.is-style1 {
    margin-bottom: 9px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__title.is-style1 .c-form1__title__text {
    padding-left: 15px;
  }
}
.c-form1__title.is-style1 .c-form1__label {
  position: relative;
  top: 2px;
}
@media screen and (max-width: 640px) {
  .c-form1__title.is-style1 .c-form1__label {
    top: 0;
  }
}
.c-form1__title.is-style2 {
  padding: 0;
  background-color: transparent;
  margin-bottom: 14px;
}
@media screen and (max-width: 640px) {
  .c-form1__title.is-style2 {
    margin-bottom: 11px;
  }
}
.c-form1__title.is-style2 .c-form1__title__text {
  padding-left: 10px;
}
@media screen and (max-width: 640px) {
  .c-form1__title.is-style2 .c-form1__title__text {
    padding-left: 8px;
  }
}
.c-form1__title.is-style2 + .c-form1__note {
  margin-top: -10px;
  margin-bottom: 15px;
}
@media screen and (min-width: 641px) {
  .c-form1__title.is-style2 + .c-form1__note {
    margin-left: 15px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__title.is-style2 + .c-form1__note {
    margin-bottom: 15px;
  }
}
.c-form1__title__text {
  padding-left: 16px;
  flex: 1;
  display: flex;
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-form1__title__text {
    padding-left: 8px;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.c-form1__title__num {
  margin-right: 9px;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  min-width: max-content;
  position: relative;
  top: 1px;
}
@media screen and (max-width: 640px) {
  .c-form1__title__num {
    margin-right: 5px;
    top: 1px;
    font-size: 1.5rem;
  }
}
.c-form1__title__icon {
  display: inline-flex;
  align-items: center;
  margin-left: 25px;
}
@media screen and (max-width: 640px) {
  .c-form1__title__icon {
    margin-left: 0;
  }
}
.c-form1__title .c-form1__label {
  position: relative;
  top: 1px;
}
@media screen and (max-width: 640px) {
  .c-form1__title .c-form1__label {
    top: -1px;
  }
}
.c-form1__title.u-style02 {
  background-color: rgba(242, 39, 39, 0.2);
}
.c-form1__text1 {
  margin-bottom: 34px;
  padding-left: 40px;
  position: relative;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 700;
  color: #ff4433;
}
@media screen and (max-width: 640px) {
  .c-form1__text1 {
    margin-bottom: 24px;
    padding-left: 33px;
    font-size: 1.4rem;
    line-height: 2.45rem;
  }
}
.c-form1__text1 .c-form1__label {
  top: 2px;
  left: 0;
  position: absolute;
}
.c-form1__table {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .c-form1__table {
    margin-bottom: 41px;
  }
}
.c-form1__table__th {
  background-color: #F3F3F3;
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 700;
  vertical-align: middle;
  text-align: left;
}
@media screen and (min-width: 641px) {
  .c-form1__table__th {
    border-top: 1px solid #fff;
    width: 27.85%;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__table__th {
    margin-bottom: 13px;
    padding: 15px 15px 11px;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__table__td {
    border-top: 1px solid #f3f3f3;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__table__th, .c-form1__table__td {
    padding: 9px 20px 10.4px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__table__th, .c-form1__table__td {
    display: block;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__table__tr {
    margin-bottom: 20px;
    display: block;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__table__tr:first-child th {
    border-top-color: #F3F3F3;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__table__tr:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__table__tr:last-child th,
  .c-form1__table__tr:last-child td {
    border-bottom: 1px solid #F3F3F3;
  }
}
.c-form1__table .c-form1__label {
  margin-right: 15px;
  top: -1px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-form1__table .c-form1__label {
    margin-right: 6px;
    top: -3px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__grinput {
    display: flex;
    align-items: flex-start;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__grinput .c-form1__input {
    width: 50%;
    max-width: max-content;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__grinput .c-form1__input:not(:last-child) {
    padding-right: 30px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__grinput .c-form1__input + .c-form1__input {
    margin-top: 10px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__grinput .c-form1__input input {
    flex: 1;
    max-width: 200px;
    width: auto;
  }
}
.c-form1__title2 {
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 3rem;
  line-height: 1.5;
  font-weight: 700;
  border-bottom: 2px solid blue;
}
@media screen and (max-width: 640px) {
  .c-form1__title2 {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 1.5;
    border-bottom-width: 1px;
  }
}
.c-form1__notes {
  margin-top: 80px;
  padding-top: 37px;
  margin-bottom: 46px;
  border-top: 1px solid #82CDF3;
}
@media screen and (max-width: 640px) {
  .c-form1__notes {
    margin-top: 35px;
    padding-top: 17px;
    margin-bottom: 20px;
  }
}
.c-form1__note {
  padding-left: 15px;
  position: relative;
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 500;
  text-align: left;
}
@media screen and (max-width: 640px) {
  .c-form1__note {
    font-size: 1.4rem;
  }
}
.c-form1__note:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}
.c-form1__input {
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .c-form1__input {
    font-size: 1.4rem;
  }
}
.c-form1__input.no-wrap {
  flex-wrap: nowrap;
}
.c-form1__input__note {
  margin-top: 5px;
  font-size: 1.4rem;
  line-height: 1.3;
  display: block;
  text-align: left;
}
@media screen and (max-width: 640px) {
  .c-form1__input__note {
    margin-top: 10px;
    margin-bottom: -3px;
    font-size: 1.2rem;
  }
}
.c-form1__input__text {
  padding-right: 10px;
  width: 42px;
  font-size: 1.6rem;
  line-height: 1;
  min-width: max-content;
}
@media screen and (max-width: 640px) {
  .c-form1__input__text {
    padding-right: 10px;
    width: 40px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__input__text.is-style1 {
    display: block;
  }
}
.c-form1__input__text.is-style1 + .c-form1__input {
  margin-top: 10px;
}
@media screen and (max-width: 640px) {
  .c-form1__input__text.is-style1 + .c-form1__input {
    margin-top: 5px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__input__text.is-style1 .c-form1__input__right {
    flex: 1;
  }
}
.c-form1__input__text.is-style1 .c-form1__input__text {
  width: 140px;
}
@media screen and (max-width: 640px) {
  .c-form1__input__text.is-style1 .c-form1__input__text {
    padding-right: 0;
    width: 100%;
  }
}
.c-form1__input__subtext {
  padding-right: 10px;
  width: 62px;
  font-size: 1.6rem;
  line-height: 1;
  min-width: max-content;
}
@media screen and (max-width: 640px) {
  .c-form1__input__subtext {
    padding-right: 10px;
    font-size: 1.3rem;
  }
}
.c-form1__input input {
  padding: 5px 9px;
  border: 1px solid #D5D5D5;
  border-radius: 2px;
  height: 40px;
  border-radius: 2px;
}
@media screen and (max-width: 640px) {
  .c-form1__input input {
    padding: 2px 13px;
    border-radius: 1px;
    flex: 1;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__input input.mw242-pc {
    max-width: 242px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__input input.mw258-pc {
    max-width: 258px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__input input.mw200-pc {
    max-width: 200px;
  }
}
.c-form1__input.is-style1 {
  align-items: flex-start;
}
@media screen and (max-width: 640px) {
  .c-form1__input.is-style1 {
    display: block;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__input.is-style1 .c-form1__input__text {
    width: 140px;
    padding-top: 13px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__input.is-style1 .c-form1__input__text {
    margin-bottom: 6px;
    padding-right: 0;
    font-size: 1.4rem;
    display: block;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__input.is-style1 .c-form1__input__right {
    flex: 1;
    text-align: left;
  }
}
.c-form1__input.is-style1 + .c-form1__input {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .c-form1__input.is-style1 + .c-form1__input {
    margin-top: 14px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1__input .c-form1__grinput {
    flex: 1;
  }
}
.c-form1__inputconfirm {
  line-height: 1.875;
}
.c-form1__privacy {
  margin-bottom: 30px;
  padding: 20px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 2px;
  border: 1px solid #f3f3f3;
  height: auto;
  max-height: 400px;
}
@media screen and (max-width: 640px) {
  .c-form1__privacy {
    margin-bottom: 20px;
    padding: 15px 15px;
    max-height: 300px;
    border-radius: 1px;
  }
}
.c-form1__privacy__title {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-form1__privacy__title {
    font-size: 1.6rem;
  }
}
.c-form1__privacy__text {
  font-size: 1.6rem;
  line-height: 1.5;
}
.c-form1__list {
  counter-reset: li;
}
.c-form1__list__text {
  margin-bottom: 2px;
  display: flex;
}
.c-form1__list__text:before {
  content: counter(li);
  counter-increment: li;
  margin-right: 3px;
  min-width: max-content;
}
.c-form1__text3 {
  margin-bottom: 30px;
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 700;
  letter-spacing: 0.18px;
}
@media screen and (min-width: 641px) {
  .c-form1__text3 {
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__text3 {
    margin-bottom: 20px;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.c-form1__text4 {
  margin-top: 5px;
  font-size: 1.4rem;
  line-height: 1.5;
  display: block;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-form1__text4 {
    margin-top: 5px;
    font-size: 1.2rem;
    color: #989898;
  }
}
@media screen and (max-width: 640px) {
  .c-form1__textconfirm {
    font-size: 1.3rem;
  }
}
.c-form1.is-confirm .c-form1__table {
  margin-bottom: 75px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__table {
    margin-bottom: 29px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__table__tr {
    margin-bottom: 15px;
  }
}
.c-form1.is-confirm .c-form1__table__th, .c-form1.is-confirm .c-form1__table__td {
  padding-top: 9.5px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__table__th, .c-form1.is-confirm .c-form1__table__td {
    padding-top: 14px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__table th {
    margin-bottom: 11px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__table__td {
    padding-top: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__table input {
    height: max-content;
  }
}
@media screen and (min-width: 641px) {
  .c-form1.is-confirm .c-form1__grinput .c-form1__input {
    min-width: 273px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__grinput .c-form1__input + .c-form1__input {
    margin-top: 8px;
  }
}
.c-form1.is-confirm .c-form1__text3 {
  margin-bottom: 33px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__text3 {
    margin-bottom: 14px;
  }
}
.c-form1.is-confirm .c-form1__text2 {
  margin: 16px 0 20px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__text2 {
    margin: 13px 0 14px;
  }
}
.c-form1.is-confirm .c-form1__title:not(.is-style1) {
  margin-bottom: 17px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__title:not(.is-style1) {
    margin-bottom: 14px;
  }
}
.c-form1.is-confirm .c-form1__title.is-style1 {
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__title.is-style1 {
    margin-bottom: 5px;
  }
}
.c-form1.is-confirm .c-form1__title.is-style1:last-child {
  margin-bottom: 34px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__title.is-style1:last-child {
    margin-bottom: 24px;
  }
}
.c-form1.is-confirm .c-form1__title.is-style2 {
  margin-top: 28px;
  margin-bottom: 9px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__title.is-style2 {
    margin-top: 19px;
    margin-bottom: 11px;
  }
}
.c-form1.is-confirm .c-form1__row {
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__row {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__textarea {
    padding-left: 0;
  }
}
.c-form1.is-confirm .c-form1__textarea:last-child {
  margin-bottom: 0;
}
.c-form1.is-confirm .c-form1__textarea textarea {
  padding: 0;
  border: 0;
  height: max-content;
  max-height: unset;
  border-radius: 0;
  background-color: transparent;
}
.c-form1.is-confirm .c-form1__notes {
  margin-top: 76px;
  padding-top: 55px;
  margin-bottom: 33px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__notes {
    margin-top: 37px;
    padding-top: 39px;
    margin-bottom: 14px;
  }
}
.c-form1.is-confirm .c-form1__input__text {
  width: 54px;
  min-width: max-content;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__input__text {
    width: 36px;
  }
}
.c-form1.is-confirm .c-form1__input .c-form1__textconfirm {
  font-weight: 500;
}
.c-form1.is-confirm .c-form1__input input {
  padding: 0;
  border-radius: 0;
  border: none;
  font-weight: 500;
}
.c-form1.is-confirm .c-form1__input.is-style1 + .c-form1__input {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__input.is-style1 + .c-form1__input {
    margin-top: 12px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1.is-confirm .c-form1__input.is-style1 .c-form1__input__text {
    padding-top: 0;
    width: 140px;
    font-weight: 700;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__input.is-style1 .c-form1__input__text {
    width: 100%;
    margin-bottom: 12.5px;
  }
}
.c-form1.is-confirm .c-form1__input.is-style1 .c-form1__input__right input {
  height: max-content;
}
.c-form1.is-confirm .c-form1__radio {
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__radio {
    padding-left: 5px;
  }
}
.c-form1.is-confirm .c-form1__radio__label {
  cursor: text;
}
.c-form1.is-confirm .c-form1__radio__text {
  padding-left: 0;
}
@media screen and (min-width: 641px) {
  .c-form1.is-confirm .c-form1__radio__text {
    line-height: 1.875;
  }
}
.c-form1.is-confirm .c-form1__radio input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.c-form1.is-confirm .c-form1__radio + .c-form1__title {
  margin-top: 18px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__radio + .c-form1__title {
    margin-top: 13px;
  }
}
.c-form1.is-confirm .c-form1__radio + .c-form1__title.is-style2 {
  margin-top: 28px;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__radio + .c-form1__title.is-style2 {
    margin-top: 13px;
    margin-bottom: 9px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1.is-confirm .c-form1__selects {
    padding-top: 4px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__selects {
    display: flex;
  }
}
.c-form1.is-confirm .c-form1__selectgr__title {
  padding-top: 0;
}
@media screen and (min-width: 641px) {
  .c-form1.is-confirm .c-form1__selectgr__title {
    font-weight: 700;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__selectgr__title {
    margin-bottom: 2px;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.c-form1.is-confirm .c-form1__selectgr + .c-form1__selectgr {
  margin-top: 12px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__selectgr + .c-form1__selectgr {
    margin-top: 9px;
  }
}
.c-form1.is-confirm .c-form1__selectgr + .c-form1__title.is-style2 {
  margin-top: 31px;
  margin-bottom: 16px;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__selectgr + .c-form1__title.is-style2 {
    margin-top: 15px;
    margin-bottom: 13px;
  }
}
@media screen and (min-width: 641px) {
  .c-form1.is-confirm .c-form1__selectbox {
    width: max-content;
    min-width: 128px;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__selectbox {
    min-width: 101px;
    margin-top: 0 !important;
  }
}
.c-form1.is-confirm .c-form1__select__text {
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__select__text {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-form1__grcontrol .c-form1__title.is-style1 {
    padding-left: 0;
  }
}
.c-form1.is-confirm input:not([class^=c-btn02]) {
  cursor: text;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm input:not([class^=c-btn02]) {
    font-size: 1.3rem;
  }
}
.c-form1.is-confirm .c-checkbox {
  position: relative;
}
.c-form1.is-confirm .c-checkbox__text {
  cursor: text;
  font-size: 1.6rem;
  line-height: 3rem;
}
@media screen and (max-width: 640px) {
  .c-form1.is-confirm .c-checkbox__text {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.c-form1.is-confirm .c-checkbox input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.c-form1__checkbox__label {
  padding-left: 29px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .c-form1__checkbox__label {
    padding-left: 30px;
  }
}
.c-form1__checkbox__text {
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-form1__checkbox__text {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.c-form1__checkbox input {
  top: 3px;
  width: 18px;
  height: 18px;
  left: 0;
  border-radius: 2px;
  border: 1px solid #707070;
  position: absolute;
}
@media screen and (max-width: 640px) {
  .c-form1__checkbox input {
    top: 1px;
    border-radius: 1px;
    width: 20px;
    height: 20px;
  }
}
.c-form1__checkbox input:checked {
  background: #82CDF3 url(../img/hoken/common/icon_checked.svg) no-repeat center/11px auto;
  border-color: #82CDF3;
}
@media screen and (max-width: 640px) {
  .c-form1__checkbox input:checked {
    background-size: 12px auto;
  }
}
.c-form1__checkbox.is-term {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  text-align: center;
}
.c-form1__checkbox.is-term .c-form1__checkbox__label {
  padding-left: 26px;
}
.c-form1__checkbox.is-term .c-form1__checkbox__text {
  font-size: 1.8rem;
  line-height: 1.8;
  vertical-align: middle;
}
@media screen and (max-width: 640px) {
  .c-form1__checkbox.is-term .c-form1__checkbox__text {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}
.c-form1__checkbox.is-term input {
  width: 16px;
  height: 16px;
  border-color: #c7c7c7;
  top: 50%;
  transform: translateY(-50%);
}
.c-form1__checkbox.is-term input:checked {
  border-color: #82CDF3;
}
.c-form1__checkbox.is-pink input:checked {
  border-color: #fc819b;
  background-color: #fc819b;
}
.c-form1__checkbox.is-coopGreen input:checked {
  border-color: #8CB340;
  background-color: #8CB340;
}
.c-form1__checkbox + .c-form1__checkbox {
  margin-top: -2px;
}
@media screen and (max-width: 640px) {
  .c-form1__checkbox + .c-form1__checkbox {
    margin-top: 11px;
  }
}
.c-form1__checkbox + .c-btn02 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .c-form1__checkbox + .c-btn02 {
    margin-top: 40px;
  }
}
.c-form1 .c-table2__radio {
  margin: 0;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__radio {
    font-size: 1.4rem;
  }
}
.c-form1 .c-table2__radio + .c-table2__radio {
  margin-top: 4px;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__radio + .c-table2__radio {
    margin-top: 13px;
  }
}
.c-form1 .c-table2__radio.is-pink input:checked + label:before {
  border-color: #FC819B !important;
  background-color: #FC819B;
}
.c-form1 .c-table2__radio.is-coopGreen input:checked + label:before {
  border-color: #8CB340 !important;
  background-color: #8CB340;
}
.c-form1 .c-table2__radio li {
  width: 100%;
  margin-right: 0;
  text-align: left;
}
.c-form1 .c-table2__radio li .is-small {
  margin-top: -4px;
  font-size: 1.4rem;
  display: block;
  text-indent: -12px;
  padding-left: 11px;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__radio li .is-small {
    margin-top: 3px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.c-form1 .c-table2__radio input + label:before {
  border-color: #707070 !important;
  width: 18px;
  height: 18px;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__radio input + label:before {
    width: 20px;
    height: 20px;
  }
}
.c-form1 .c-table2__radio input + label:after {
  left: 5px;
  width: 8px;
  height: 8px;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__radio input + label:after {
    left: 6px;
  }
}
.c-form1 .c-table2__radio input:checked + label:before {
  border-color: #82cdf3 !important;
  background-color: #82cdf3;
}
.c-form1 .c-table2__radio + .c-form1__title.is-style1 {
  margin-top: 22px;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__radio + .c-form1__title.is-style1 {
    margin-top: 23px;
  }
}
.c-form1 .c-table2__radio + .c-form1__title.is-style2 {
  margin-top: 34px;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__radio + .c-form1__title.is-style2 {
    margin-top: 14px;
  }
}
.c-form1 .c-table2__checkbox {
  margin: 0;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__checkbox {
    font-size: 1.4rem;
  }
}
.c-form1 .c-table2__checkbox.is-coopGreen input:checked + label:before {
  border-color: #8CB340 !important;
  background-color: #8CB340;
}
.c-form1 .c-table2__checkbox li {
  width: 100%;
  margin-right: 0;
  text-align: left;
}
.c-form1 .c-table2__checkbox li + li {
  margin-top: 7px;
}
.c-form1 .c-table2__checkbox input {
  display: none;
}
.c-form1 .c-table2__checkbox input + label {
  position: relative;
  display: flex;
  column-gap: 3px;
  cursor: pointer;
}
.c-form1 .c-table2__checkbox input + label:before {
  content: "";
  display: inline-block;
  border-radius: 2px;
  min-width: 14px;
  width: 14px;
  height: 14px;
  border: 1px solid #58595B;
  border-color: #707070 !important;
  width: 18px;
  height: 18px;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__checkbox input + label:before {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}
.c-form1 .c-table2__checkbox input:checked + label:before {
  background: url(../img/hoken/common/icon_checked.svg) no-repeat center;
  border-color: #8CB340 !important;
  background-color: #8CB340;
}
.c-form1 .c-table2__rating + .c-table2__rating {
  margin-top: 15px;
}
.c-form1 .c-table2__rating__text {
  margin-bottom: 5px;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .c-form1 .c-table2__rating__text {
    font-size: 1.4rem;
  }
}
.c-form1 .c-table2__rating__input {
  height: 25px;
  display: flex;
  column-gap: 5px;
  position: relative;
}
.c-form1 .c-table2__rating__input__status {
  width: fit-content;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 5px;
  pointer-events: none;
}
.c-form1 .c-table2__rating__input__status div {
  width: 25px;
  height: 25px;
  mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50" viewBox="0 0 50 50"><path d="M50 19.0395V19.2655L34.6861 30.8475L40.6926 49.887L40.5303 50L25 38.1921L9.4697 50L9.25325 49.887L15.2597 30.8475L0 19.2655V19.0395H18.9394L24.8918 0H25.0541L31.0065 19.0395H50Z" /></svg>');
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  background-color: #D5D5D5;
}
.c-form1 .c-table2__rating__input__status div.is-active {
  background-color: #8CB340;
  opacity: 1;
}
.c-form1 .c-table2__rating__input input {
  display: none;
}
.c-form1 .c-table2__rating__input label {
  cursor: pointer;
  display: block;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
  overflow: hidden;
}
.c-form1 .c-table2__checkbox01 li {
  float: none;
  display: block;
  margin-right: 0;
  text-align: left;
}
.c-form1 .c-checkbox.is-disable {
  pointer-events: none;
}
.c-form1 .c-checkbox.is-disable .c-checkbox__text {
  color: #D5D5D5 !important;
}
.c-form1 .c-checkbox.is-disable .c-checkbox__text:before {
  border-color: #D5D5D5 !important;
  background-color: #F3F3F3 !important;
}
.c-form1 .c-formPrivacyText__txt {
  text-align: left;
}

/*
------------------------------------------------------------*/
textarea,
input {
  font-family: YuGothic, "Yu Gothic", sans-serif;
}

/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*------------------------------------------------------------
お知らせ　カテゴリータグ
------------------------------------------------------------*/
.c-cateTxt01 {
  padding: 0.25em 1em;
  font-size: 1.4rem;
  color: #ffffff;
  -webkit-border-radius: 1.9rem;
  border-radius: 1.9rem;
}
.c-cateTxt01.c-cateTxt01--ticket {
  background-color: #fc819b;
}
.c-cateTxt01.c-cateTxt01--hoken {
  background-color: #82cdf3;
}
.c-cateTxt01.c-cateTxt01--reform {
  background-color: #68ba93;
}
.c-cateTxt01.c-cateTxt01--kurashi {
  background-color: #faad5a;
}
.c-cateTxt01.c-cateTxt01--sosai {
  background-color: #af8ebe;
}

.c-newsCate01 {
  width: 100px;
  height: 20px;
  margin-right: 10px;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.65;
  text-align: center;
  background-color: #f0662b;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
@media screen and (-ms-high-contrast: none) {
  .c-newsCate01 {
    padding-top: 2px;
  }
}
@media screen and (max-width: 640px) {
  .c-newsCate01 {
    font-size: 1rem;
    width: 80px;
    height: 16px;
    line-height: 1.6;
    margin-top: 15px;
  }
}
.c-newsCate01.c-newsCate01--cls {
  background-color: #a4c661;
  min-width: 100px;
  width: auto;
  padding: 0 10px;
}
@media screen and (-ms-high-contrast: none) {
  .c-newsCate01.c-newsCate01--cls {
    padding-top: 2px;
  }
}
.c-newsCate01.c-newsCate01--area {
  background-color: #a4c661;
}
.c-newsCate01.c-newsCate01--ticket {
  background-color: #fc819b;
}
.c-newsCate01.c-newsCate01--hoken {
  background-color: #82cdf3;
}
.c-newsCate01.c-newsCate01--hokenOshirase {
  background-color: #82b169;
}
.c-newsCate01.c-newsCate01--reform {
  background-color: #68ba93;
}
.c-newsCate01.c-newsCate01--kurashi {
  background-color: #faad5a;
  min-width: 100px;
  width: auto;
  padding: 0 10px;
}
@media screen and (-ms-high-contrast: none) {
  .c-newsCate01.c-newsCate01--kurashi {
    padding-top: 2px;
  }
}
.c-newsCate01.c-newsCate01--sosai {
  background-color: #af8ebe;
}

.c-newsCate02 {
  width: 100px;
  height: 20px;
  margin-right: 10px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  background-color: #f0662b;
}
.c-newsCate02.c-newsCate02--ticket {
  background-color: #fc819b;
}
.c-newsCate02.c-newsCate02--ticketEvents {
  background-color: #7fc55c;
}
.c-newsCate02.c-newsCate02--ticketOshirase {
  background-color: #7fc55c;
}
.c-newsCate02.c-newsCate02--hoken {
  background-color: #82cdf3;
}
.c-newsCate02.c-newsCate02--reform {
  background-color: #68ba93;
}
.c-newsCate02.c-newsCate02--reformOshirase {
  background-color: #333745;
}
.c-newsCate02.c-newsCate02--reformCanpain {
  background-color: #f2385a;
}
.c-newsCate02.c-newsCate02--kurashi {
  background-color: #faad5a;
}
.c-newsCate02.c-newsCate02--kurashiOsusume {
  background-color: #f77b68;
}
.c-newsCate02.c-newsCate02--kurashiOshirase {
  background-color: #a8845b;
}
.c-newsCate02.c-newsCate02--sosai {
  background-color: #af8ebe;
}
.c-newsCate02.c-newsCate02--sosaiKanagawa {
  background-color: #00a384;
}
.c-newsCate02.c-newsCate02--sosaiShizuoka {
  background-color: #931719;
}
.c-newsCate02.c-newsCate02--sosaiYamanashi {
  background-color: #006da7;
}
.c-newsCate03 {
  width: 100px;
  height: 20px;
  margin-right: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  border: solid 1px;
}
.c-newsCate03.c-newsCate03--sosaiSeminar {
  color: #2e95a3;
  border-color: #2e95a3;
}
.c-newsCate03.c-newsCate03--sosaiReport {
  color: #eb5902;
  border-color: #eb5902;
}
.c-newsCate03.c-newsCate03--sosaioshirase {
  color: #eb5902;
  border-color: #eb5902;
}

@media screen and (max-width: 640px) {
  .c-cateTxt01 {
    font-size: 1.2rem;
    -webkit-border-radius: 1.7rem;
    border-radius: 1.7rem;
  }
  .c-cateTxt02 {
    font-size: 1.2rem;
  }
}
.c-error {
  margin-top: 6px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #f22727;
  display: block;
}
.c-error__privacy {
  text-align: center;
}
@media screen and (max-width: 640px) {
  .c-error__privacy {
    text-align: left;
  }
}
@media screen and (max-width: 640px) {
  .c-error {
    font-size: 1.1rem;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　承認番号テキスト
------------------------------------------------------------*/
.c-hokenNote {
  font-size: 1.2rem;
  color: #999999;
}

/*------------------------------------------------------------
保険　商品詳細タグ
------------------------------------------------------------*/
.c-hokenTags {
  margin-bottom: 10px;
  letter-spacing: -0.4em;
}
.c-hokenTags > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-hokenTags__tag {
  height: 20px;
  margin: 0 5px 5px 0;
  padding: 0 0.5em;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.65;
  background-color: #ffffff;
  border: solid 1px;
  display: inline-block;
  letter-spacing: normal;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.c-hokenTags__tag--corp {
  color: #649333;
  border-color: #649333;
}
.c-hokenTags__tag--internet {
  color: #ffffff;
  background-color: #007a50;
  border-color: #007a50;
}
.c-hokenTags__tag--campain {
  color: #f99f2b;
  border-color: #f99f2b;
}
.c-hokenTags__tag--attention {
  color: #f76aa3;
  border-color: #f76aa3;
}
.c-hokenTags__tag--popular {
  color: #fd5724;
  border-color: #fd5724;
}
.c-hokenTags__tag--new {
  color: #f03f4e;
  border-color: #f03f4e;
}
.c-hokenTags__tag--interview {
  color: #5076d1;
  border-color: #5076d1;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　商品一覧　序文
------------------------------------------------------------*/
.c-reformServiceTxet01 {
  min-height: 180px;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.c-reformServiceTxet01--img {
  width: 280px;
}
.c-reformServiceTxet01--img img {
  width: 280px;
  height: 200px;
}
.c-reformServiceTxet01--txt {
  width: calc(100% - 310px);
}
.c-reformServiceTxet01--txt h3 {
  margin-bottom: 11px;
  font-size: 2rem;
  font-weight: bold;
  color: #d33f10;
}
.c-reformServiceTxet01--txt p {
  font-size: 1.5rem;
  line-height: 1.75;
  color: #46483c;
}

@media screen and (max-width: 640px) {
  .c-reformServiceTxet01 {
    min-height: auto;
    padding-right: 0;
    display: block;
  }
  .c-reformServiceTxet01--img {
    width: 100%;
    height: auto;
    padding-left: 0px;
  }
  .c-reformServiceTxet01--txt {
    width: 100%;
  }
  .c-reformServiceTxet01--txt h3 {
    margin-bottom: 11px;
    font-size: 2rem;
    font-weight: bold;
    color: #d33f10;
  }
  .c-reformServiceTxet01--txt p {
    font-size: 1.5rem;
    line-height: 1.75;
    color: #46483c;
  }
  .c-reformServiceTxet01 img {
    width: auto;
    margin: 0 auto 14px auto;
    display: block;
    position: static;
  }
  .c-reformServiceTxet01 h3 {
    margin-bottom: 4px;
    font-size: 1.5rem;
  }
  .c-reformServiceTxet01 p {
    font-size: 1.4rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　テキストボックス01
------------------------------------------------------------*/
.c-sosaiTextBox01 + .c-sosaiTextBox01 {
  margin-top: -1px;
}
.c-sosaiTextBox01 > div {
  width: 100%;
  margin-top: -1px;
  padding: 35px 35px 40px;
  border: solid 1px #c8c8c8;
}
.c-sosaiTextBox01 > div:first-child {
  margin-top: 0;
}
@media screen and (max-width: 640px) {
  .c-sosaiTextBox01 > div {
    padding: 14px 18px 19px;
  }
}
.c-sosaiTextBox01--harf {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .c-sosaiTextBox01--harf {
    display: inherit;
  }
}
.c-sosaiTextBox01--harf > div {
  width: calc(50% + 1px);
  margin-right: -1px;
}
.c-sosaiTextBox01--harf > div:nth-child(2) {
  margin-top: 0;
}
.c-sosaiTextBox01--harf > div:nth-child(even) {
  width: 50%;
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-sosaiTextBox01--harf > div {
    width: 100% !important;
    margin-right: 0;
  }
  .c-sosaiTextBox01--harf > div:nth-child(2) {
    margin-top: -1px;
  }
}
.c-sosaiTextBox01--one_third {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .c-sosaiTextBox01--one_third {
    display: inherit;
  }
}
.c-sosaiTextBox01--one_third > div {
  margin-right: -1px;
  width: calc(33.33333333% + 1px);
}
.c-sosaiTextBox01--one_third > div:nth-child(2), .c-sosaiTextBox01--one_third > div:nth-child(3) {
  margin-top: 0;
}
.c-sosaiTextBox01--one_third > div:nth-child(3n) {
  width: 33.33333333%;
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-sosaiTextBox01--one_third > div {
    width: 100% !important;
    margin-right: 0;
  }
  .c-sosaiTextBox01--one_third > div:nth-child(2), .c-sosaiTextBox01--one_third > div:nth-child(3) {
    margin-top: -1px;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　ページタイトルカテゴリー(地域)
------------------------------------------------------------*/
.c-sosaiPagetitleCate {
  text-align: center;
  letter-spacing: -0.4em;
}
.c-sosaiPagetitleCate > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-sosaiPagetitleCate span {
  width: 60px;
  height: 20px;
  margin-right: 2px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}
.c-sosaiPagetitleCate__Kanagawa {
  background-color: #00a384;
}
.c-sosaiPagetitleCate__Shizuoka {
  background-color: #931719;
}
.c-sosaiPagetitleCate__Yamanashi {
  background-color: #006da7;
}

/*------------------------------------------------------------
検索結果の商品ないときのクラス
------------------------------------------------------------*/
.c-noitem__txt {
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .c-noitem__txt {
    font-size: 1.4rem;
  }
}

/*------------------------------------------------------------
補足クラス
------------------------------------------------------------*/
.c-supplement {
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .c-supplement {
    font-size: 1.4rem;
  }
}

.c-hokenText01 {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #46483c;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-hokenText01 {
    text-align: left;
    font-size: 1.4rem;
  }
}

.c-hokenText02 {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #ff4433;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-hokenText02 {
    text-align: left;
    font-size: 1.4rem;
  }
}

.c-hokenText03 {
  text-align: center;
  font-size: 2.8rem;
  line-height: 1.5;
  color: #46483c;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-hokenText03 {
    font-size: 1.6rem;
    line-height: 1.3;
  }
}

.c-formText01 {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #46483c;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-formText01 {
    text-align: left;
    font-size: 1.4rem;
  }
}

.c-formText02 {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #ff4433;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-formText02 {
    text-align: left;
    font-size: 1.4rem;
  }
}

.c-formText03 {
  text-align: center;
  font-size: 2.8rem;
  line-height: 1.5;
  color: #46483c;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-formText03 {
    font-size: 1.6rem;
    line-height: 1.3;
  }
}

/*------------------------------------------------------------
navi
------------------------------------------------------------*/
@media screen and (max-width: 640px) {
  /*スマホメニュー共通パーツ*/
  .c-spMenu {
    width: 100%;
    min-width: 320px;
    height: calc(100% - 50px);
    background-color: #fff;
    display: none;
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 9998;
  }
  .c-spMenu.is-open {
    display: block;
  }
  .c-spMenuCloseBtn {
    width: 100%;
    height: 40px;
    padding: 15px;
    background-color: #649333;
    text-align: center;
    position: relative;
    z-index: 100;
  }
  .c-spMenuCloseBtn.c-spMenuCloseBtn--ticket {
    background-color: #fc819b;
  }
  .c-spMenuCloseBtn.c-spMenuCloseBtn--hoken {
    background-color: #82cdf3;
  }
  .c-spMenuCloseBtn.c-spMenuCloseBtn--reform {
    background-color: #68ba93;
  }
  .c-spMenuCloseBtn.c-spMenuCloseBtn--kurashi {
    background-color: #faad5a;
  }
  .c-spMenuCloseBtn.c-spMenuCloseBtn--sosai {
    background-color: #2e95a3;
  }
  .c-spMenuCloseBtn .c-spMenuCloseBtn__1 {
    width: 15px;
    height: 2px;
    margin: 0 auto 8px auto;
    display: inline-block;
    background-color: #ffffff;
    transform: rotate(45deg) translate(3px, 2px);
  }
  .c-spMenuCloseBtn .c-spMenuCloseBtn__2 {
    width: 15px;
    height: 2px;
    margin: 0 auto 4px auto;
    display: inline-block;
    background-color: #ffffff;
    transform: rotate(-45deg) translate(-12px, -13px);
  }
  .c-spMenuCloseBtn .c-spMenuCloseBtn__3 {
    color: #ffffff;
    font-size: 1.3rem;
    letter-spacing: 1px;
    display: inline-block;
    margin-left: -14px;
    margin-top: -10px;
  }
  .c-hokenSearch {
    padding-top: 6px;
    height: 50px;
  }
  .c-hokenSearch__icon {
    height: 23px;
    color: #222;
    text-align: center;
  }
  .c-hokenSearch__icon svg {
    width: 23px;
    height: 23px;
  }
  .c-hokenSearch__label {
    margin-top: 4px;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
  }
  .c-spMenuBtn {
    width: 50px;
    height: 50px;
    padding-top: 13px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
  }
  .c-spMenuBtn span {
    transition: transform 0.3s;
  }
  .c-spMenuBtn .c-spMenuBtn__1 {
    width: 15px;
    height: 2px;
    margin: 0 auto 4px auto;
    background-color: #649333;
    display: block;
  }
  .c-spMenuBtn .c-spMenuBtn__2 {
    width: 15px;
    height: 2px;
    margin: 0 auto 4px auto;
    background-color: #649333;
    display: block;
  }
  .c-spMenuBtn .c-spMenuBtn__3 {
    width: 15px;
    height: 2px;
    margin: 0 auto 3px auto;
    background-color: #649333;
    display: block;
  }
  .c-spMenuBtn .c-spMenuBtn__4 {
    color: #649333;
    font-size: 0.8rem;
    display: block;
  }
  .c-spMenuBtn .c-spMenuBtn__5 {
    color: #ffffff;
    font-size: 0.8rem;
    display: none;
  }
  .c-spMenuBtn.is-open {
    background-color: #649333;
  }
  .c-spMenuBtn.is-open .c-spMenuBtn__1 {
    background-color: #ffffff;
    transform: rotate(225deg) translate(-5px, -3px);
  }
  .c-spMenuBtn.is-open .c-spMenuBtn__3 {
    background-color: #ffffff;
    transform: rotate(-225deg) translate(-5px, 3px);
  }
  .c-spMenuBtn.is-open .c-spMenuBtn__4 {
    display: none;
  }
  .c-spMenuBtn.is-open .c-spMenuBtn__5 {
    display: block;
  }
  .c-spMenuBtn.c-spMenuBtn--2 .c-spMenuBtn__5 {
    color: #999999;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open {
    background-color: #ffffff;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open .c-spMenuBtn__1 {
    background-color: #999999;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open .c-spMenuBtn__2 {
    background-color: #ffffff;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open .c-spMenuBtn__3 {
    background-color: #999999;
  }
  .c-spMenuBtn.c-spMenuBtn--2 .c-spMenuBtn__5 {
    color: #999999;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open {
    background-color: #ffffff;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open .c-spMenuBtn__1 {
    background-color: #999999;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open .c-spMenuBtn__2 {
    background-color: #ffffff;
  }
  .c-spMenuBtn.c-spMenuBtn--2.is-open .c-spMenuBtn__3 {
    background-color: #999999;
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn {
    width: 45px;
    height: 100%;
    -webkit-background-size: 20px;
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--ticket {
    background: none;
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--ticket:before {
    width: 20px;
    height: 1px;
    margin: auto;
    content: "";
    background-color: #fc819b;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--ticket:after {
    width: 1px;
    height: 20px;
    margin: auto;
    content: "";
    background-color: #fc819b;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--hoken {
    background: none;
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--hoken:before {
    width: 15px;
    height: 2px;
    margin: auto;
    content: "";
    background-color: #2579be;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--hoken:after {
    width: 2px;
    height: 15px;
    margin: auto;
    content: "";
    background-color: #2579be;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--reform {
    background: none;
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--reform:before {
    width: 15px;
    height: 1px;
    margin: auto;
    content: "";
    background-color: #ffffff;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--reform:after {
    width: 1px;
    height: 15px;
    margin: auto;
    content: "";
    background-color: #ffffff;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--kurashi {
    background-size: 17px;
    background-image: url("../img/common/icon_puls02.png");
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--kurashi:before, .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--kurashi:after {
    transition: transform 0.3s;
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--sosai {
    background: none;
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--sosai:before {
    width: 20px;
    height: 1px;
    margin: auto;
    content: "";
    background-color: #2e95a3;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionBtn.c-spMenuAccordionBtn--sosai:after {
    width: 1px;
    height: 20px;
    margin: auto;
    content: "";
    background-color: #2e95a3;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .c-spMenuAccordion .c-spMenuAccordionHide {
    display: none;
    transition: 0.3s ease-in-out;
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--ticket:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--ticket:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--hoken:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--hoken:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--reform:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--reform:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--kurashi {
    background-image: url("../img/common/icon_minus02.png");
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--sosai:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionBtn--sosai:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .c-spMenuAccordion.is-open .c-spMenuAccordionHide {
    display: block;
    transition: 0.3s ease-in-out;
  }
  .c-spMenuBtn2 {
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 6px;
  }
  .c-spMenuBtn2 span {
    transition: transform 0.3s;
  }
  .c-spMenuBtn2__icon {
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    width: 23px;
    height: 23px;
  }
  .c-spMenuBtn2 .c-spMenuBtn2__1 {
    width: 23px;
    height: 2px;
    border-radius: 9999px;
    background-color: #333333;
    display: block;
  }
  .c-spMenuBtn2 .c-spMenuBtn2__2 {
    margin-top: 5px;
    width: 17px;
    height: 2px;
    border-radius: 9999px;
    background-color: #333333;
    display: block;
  }
  .c-spMenuBtn2 .c-spMenuBtn2__3 {
    margin-top: 5px;
    width: 11.5px;
    height: 2px;
    border-radius: 9999px;
    background-color: #333333;
    display: block;
  }
  .c-spMenuBtn2 .c-spMenuBtn2__4 {
    margin-top: 4px;
    color: #000;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    display: block;
  }
  .c-spMenuBtn2 .c-spMenuBtn2__5 {
    margin-top: 4px;
    color: #000;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    display: none;
  }
  .c-spMenuBtn2.is-open .c-spMenuBtn2__1 {
    transform: rotate(225deg) translate(-5px, -6px);
  }
  .c-spMenuBtn2.is-open .c-spMenuBtn2__2 {
    opacity: 0;
  }
  .c-spMenuBtn2.is-open .c-spMenuBtn2__3 {
    width: 23px;
    transform: rotate(-225deg) translate(-4px, 5px);
  }
  .c-spMenuBtn2.is-open .c-spMenuBtn2__4 {
    display: none;
  }
  .c-spMenuBtn2.is-open .c-spMenuBtn2__5 {
    display: block;
  }
  .c-spMenuCloseBtn2 {
    margin-top: 40px;
    padding: 10px 15px;
    width: 100%;
    background-color: #d9d9d9;
    border-radius: 5px;
    border: none;
    font-size: 1.6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }
  .c-spMenuCloseBtn2__icon {
    width: 15px;
    height: 15px;
    color: #333;
  }
  .c-spMenu2 {
    padding: 17px 25px 30px;
    width: 100%;
    min-width: 320px;
    height: calc(100% - 50px);
    background-color: #fff;
    display: none;
    opacity: 0;
    overflow: auto;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 9998;
  }
  .c-spMenu2.is-open {
    display: block;
  }
  .c-spMenu2Form form.gsc-search-box {
    margin-bottom: 0;
    background: #fff;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
  }
  .c-spMenu2Form table.gsc-search-box {
    margin-bottom: 0;
  }
  .c-spMenu2Form .gsc-input-box {
    padding: 0;
    border-radius: 0;
    border: none;
  }
  .c-spMenu2Form .gsc-input-box input {
    padding: 0 15px !important;
    height: 45px !important;
    background-position: 15px center !important;
  }
  .c-spMenu2Form .gsib_a {
    padding: 0;
  }
  .c-spMenu2Form .gsc-search-button {
    margin-left: 0;
  }
  .c-spMenu2Form .gsc-search-button-v2 {
    padding: 0 15px;
    border-radius: 0;
    border: none;
    background: url("/img/common/icon_search10.svg") no-repeat center;
    background-size: 25px 25px;
    width: 55px;
    height: 45px;
  }
  .c-spMenu2Form .gsc-search-button-v2 svg {
    display: none;
  }
  .c-spMenu2Form .gsc-search-button-v2:hover {
    border: none;
    background: url("/img/common/icon_search10.svg") no-repeat center;
    background-size: 25px 25px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/* チケット
------------------------------------------------------------*/
.c-tiketItems01__item {
  width: 233px;
  margin: 0 15px;
  color: #232934;
}
.c-tiketItems01__item dl {
  padding-bottom: 5px;
}
.c-tiketItems01__item dl dt {
  width: 100%;
  height: 26px;
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.625;
  text-align: center;
  color: #783146;
  background-color: rgba(252, 129, 155, 0.2);
  -webkit-border-radius: 13px;
  border-radius: 13px;
}
@media screen and (-ms-high-contrast: none) {
  .c-tiketItems01__item dl dt {
    padding-top: 3px;
  }
}
.c-tiketItems01__item dl dd {
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}
.c-tiketItems01__item dl dd:before {
  width: 100%;
  height: 100%;
  content: "";
  border: solid 3px #fc819b;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.c-tiketItems01__item dl dd p {
  margin-bottom: 4px;
  padding: 0 20px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  color: #ef004f;
  transition: 0.3s ease-in-out;
}
.c-tiketItems01__item dl dd ul {
  width: 100%;
  padding: 0 20px 10px 20px;
}
.c-tiketItems01__item dl dd ul li {
  line-height: 1.5;
  font-size: 1.3rem;
  font-weight: 500;
  color: #232934;
}
.c-tiketItems01__item__img {
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow: hidden;
}
.c-tiketItems01__item__img span {
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  opacity: 0.6;
  -ms-filter: blur(5px);
  filter: blur(5px);
  transform: scale(1.1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.c-tiketItems01__item__tags {
  width: 92px;
  height: 28px;
  padding-bottom: 8px;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.9;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 5px;
  left: -15px;
  z-index: 15;
}
.c-tiketItems01__item__tags--osusume:after {
  content: "";
  width: 82px;
  height: 23px;
  position: absolute;
  top: 0;
  right: 0px;
  background: url(../img/ticket/tag_ribon_orange1.png) no-repeat;
  background-size: 82px 23px;
  z-index: 15;
}
@media screen and (max-width: 640px) {
  .c-tiketItems01__item__tags--osusume:after {
    width: 54px;
    height: 15px;
    background-size: 53px 15px;
  }
}
.c-tiketItems01__item__tags--osusume:before {
  content: "";
  width: 15px;
  height: 25px;
  position: absolute;
  left: 0px;
  top: 2px;
  background: url(../img/ticket/tag_ribon_orange2.png) no-repeat;
  background-size: 22px 25px;
  z-index: -1;
}
@media screen and (max-width: 640px) {
  .c-tiketItems01__item__tags--osusume:before {
    top: 1px;
    width: 9px;
    height: 17px;
    background-size: 15px 17px;
  }
}
.c-tiketItems01__item__tags--ninki:after {
  content: "";
  width: 82px;
  height: 23px;
  position: absolute;
  top: 0;
  right: 0px;
  background: url(../img/ticket/tag_ribon_red1.png) no-repeat;
  background-size: 82px 23px;
  z-index: 15;
}
@media screen and (max-width: 640px) {
  .c-tiketItems01__item__tags--ninki:after {
    width: 54px;
    height: 15px;
    background-size: 53px 15px;
  }
}
.c-tiketItems01__item__tags--ninki:before {
  content: "";
  width: 15px;
  height: 25px;
  position: absolute;
  left: 0px;
  top: 2px;
  background: url(../img/ticket/tag_ribon_red2.png) no-repeat;
  background-size: 22px 25px;
  z-index: -1;
}
@media screen and (max-width: 640px) {
  .c-tiketItems01__item__tags--ninki:before {
    top: 1px;
    width: 9px;
    height: 17px;
    background-size: 15px 17px;
  }
}
.c-tiketItems01__item__tags span {
  position: relative;
  z-index: 16;
}
.c-tiketItems01__item__subCate {
  height: 20px;
  margin: 0 0 2px 20px;
  padding: 0 0.5em;
  font-size: 1.3rem;
  font-weight: 500;
  color: #232934;
  background-color: rgba(75, 88, 112, 0.1);
  display: inline-block;
}
@media screen and (-ms-high-contrast: none) {
  .c-tiketItems01__item__subCate {
    padding-top: 3px;
  }
}
.c-tiketItems01__item__soldout {
  position: absolute;
  bottom: 10px;
  width: 85%;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  font-size: 1.3rem;
  background-color: #ff819a;
  display: block;
  padding: 0.25em 0.5em;
  text-align: center;
}
.c-tiketItems01__item__count {
  position: absolute;
  bottom: 10px;
  width: 85%;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #783146;
  font-size: 1.3rem;
  background-color: #ffe6eb;
  display: block;
  padding: 0.25em 0.5em;
  text-align: center;
}
.c-tiketItems01__item__many {
  position: absolute;
  bottom: 10px;
  width: 85%;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #232934;
  font-size: 1.3rem;
  background: #ebf4e7;
  display: block;
  padding: 0.25em 0.5em;
  text-align: center;
}
.c-tiketItems01__item:hover {
  opacity: 1;
}
.c-tiketItems01__item:hover dl dd:before {
  opacity: 1;
}
.c-tiketItems01__item:hover dl dd p {
  color: #9ac985;
}

@media screen and (max-width: 640px) {
  .c-tiketItems01__item {
    width: 48%;
    margin: 0 1%;
  }
  .c-tiketItems01__item dl {
    padding-bottom: 0;
  }
  .c-tiketItems01__item dl dt {
    height: auto;
    font-size: 1.2rem;
    margin-bottom: 9px;
  }
  .c-tiketItems01__item dl dd p {
    padding: 0 10px;
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 4px;
  }
  .c-tiketItems01__item dl dd ul {
    padding: 0 10px 10px 10px;
  }
  .c-tiketItems01__item dl dd ul li {
    font-size: 1rem;
    line-height: 1.3;
  }
  .c-tiketItems01__item__img {
    height: 74px;
  }
  .c-tiketItems01__item__subCate {
    height: auto;
    margin: 0 0 4px 10px;
    font-size: 0.8rem;
  }
  .c-tiketItems01__item__soldout {
    margin: 0 10px;
    font-size: 0.8rem;
  }
  .c-tiketItems01__item__count {
    margin: 0 10px;
    font-size: 0.8rem;
  }
  .c-tiketItems01__item__tags {
    top: 5px;
    left: -9px;
    font-size: 0.9rem;
    width: 60px;
    height: 17px;
    line-height: 1.9;
  }
}
/*
------------------------------------------------------------*/
.c-tiketItems02__item {
  width: 260px;
  margin-bottom: 30px;
  color: #232934;
}
.c-tiketItems02__item a {
  width: 100%;
  display: block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}
.c-tiketItems02__item a:before {
  width: 100%;
  height: 100%;
  content: "";
  border: solid 3px #fc819b;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.c-tiketItems02__item a p {
  margin-bottom: 3px;
  padding: 0 20px 0 24px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  color: #ef004f;
  transition: 0.3s ease-in-out;
}
.c-tiketItems02__item a ul {
  width: 100%;
  padding: 0 20px 12px 22px;
}
.c-tiketItems02__item a ul li {
  line-height: 1.5;
  font-size: 1.3rem;
  font-weight: 500;
  color: #232934;
}
.c-tiketItems02__item__img {
  width: 100%;
  height: 195px;
  margin-bottom: 10px;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow: hidden;
}
.c-tiketItems02__item__img span {
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  opacity: 0.6;
  -ms-filter: blur(5px);
  filter: blur(5px);
  transform: scale(1.1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.c-tiketItems02__item__tags {
  width: 92px;
  height: 28px;
  padding-bottom: 8px;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.9;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 10px;
  left: -15px;
  z-index: 15;
}
.c-tiketItems02__item__tags--osusume:after {
  content: "";
  width: 82px;
  height: 23px;
  position: absolute;
  top: 0;
  right: 0px;
  background: url(../img/ticket/tag_ribon_orange1.png) no-repeat;
  background-size: 82px 23px;
  z-index: 15;
}
@media screen and (max-width: 640px) {
  .c-tiketItems02__item__tags--osusume:after {
    width: 54px;
    height: 15px;
    background-size: 53px 15px;
  }
}
.c-tiketItems02__item__tags--osusume:before {
  content: "";
  width: 15px;
  height: 25px;
  position: absolute;
  left: 0px;
  top: 2px;
  background: url(../img/ticket/tag_ribon_orange2.png) no-repeat;
  background-size: 22px 25px;
  z-index: -1;
}
@media screen and (max-width: 640px) {
  .c-tiketItems02__item__tags--osusume:before {
    top: 1px;
    width: 9px;
    height: 17px;
    background-size: 15px 17px;
  }
}
.c-tiketItems02__item__tags--ninki:after {
  content: "";
  width: 82px;
  height: 23px;
  position: absolute;
  top: 0;
  right: 0px;
  background: url(../img/ticket/tag_ribon_red1.png) no-repeat;
  background-size: 82px 23px;
  z-index: 15;
}
@media screen and (max-width: 640px) {
  .c-tiketItems02__item__tags--ninki:after {
    width: 54px;
    height: 15px;
    background-size: 53px 15px;
  }
}
.c-tiketItems02__item__tags--ninki:before {
  content: "";
  width: 15px;
  height: 25px;
  position: absolute;
  left: 0px;
  top: 2px;
  background: url(../img/ticket/tag_ribon_red2.png) no-repeat;
  background-size: 22px 25px;
  z-index: -1;
}
@media screen and (max-width: 640px) {
  .c-tiketItems02__item__tags--ninki:before {
    top: 1px;
    width: 9px;
    height: 17px;
    background-size: 15px 17px;
  }
}
.c-tiketItems02__item__tags span {
  position: relative;
  z-index: 16;
}
.c-tiketItems02__item__subCate {
  margin: 0 0 10px 20px;
  padding: 0.25em 0.5em;
  font-size: 1.3rem;
  font-weight: 500;
  color: #232934;
  background-color: rgba(75, 88, 112, 0.1);
  display: inline-block;
}
.c-tiketItems02__item:hover a {
  opacity: 1;
}
.c-tiketItems02__item:hover a:before {
  opacity: 1;
}
.c-tiketItems02__item:hover a p {
  color: #9ac985;
}

@media screen and (max-width: 640px) {
  .c-tiketItems02__item {
    width: calc(50% - 5px);
    margin: 0 10px 15px 0;
  }
  .c-tiketItems02__item:nth-child(2n) {
    margin-right: 0;
  }
  .c-tiketItems02__item a p {
    padding: 0 10px;
    font-size: 1.2rem;
    line-height: 1.3;
    margin-bottom: 4px;
  }
  .c-tiketItems02__item a ul {
    padding: 0 10px 10px 10px;
  }
  .c-tiketItems02__item a ul li {
    font-size: 1rem;
    line-height: 1.2;
  }
  .c-tiketItems02__item__img {
    height: 98px;
  }
  .c-tiketItems02__item__subCate {
    margin: 0 0 4px 10px;
    font-size: 0.8rem;
  }
  .c-tiketItems02__item__tags {
    top: 6px;
    left: -9px;
    font-size: 0.9rem;
    width: 60px;
    height: 17px;
    line-height: 1.8;
    padding-bottom: 0;
  }
}
/* リフォーム
------------------------------------------------------------*/
.c-reformList01 li {
  width: 250px;
  margin: 0 45px 52px 0;
}
.c-reformList01 li:nth-child(3n) {
  margin: 0 0 52px 0;
}
.c-reformList01 li img {
  width: 100%;
  height: 178px;
  margin: 0 0 14px 0;
  display: block;
  object-fit: cover;
}
.c-reformList01 li h3 {
  margin-bottom: 8px;
  padding-left: 16px;
  font-size: 1.4rem;
  font-weight: 600;
}
.c-reformList01 li h3:before {
  width: 6px;
  height: 10px;
  margin: -3px 10px 0 -16px;
  content: "";
  background-image: url("../img/common/icon_arrow-green05.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-reformList01 li a {
  color: #04756f;
}
.c-reformList01 li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.c-reformList01 li p {
  color: #46483c;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
}

@media screen and (max-width: 640px) {
  .c-reformList01 li {
    width: 48%;
    margin: 0 4% 20px 0 !important;
  }
  .c-reformList01 li:nth-child(2n) {
    margin: 0 0 17px 0 !important;
  }
  .c-reformList01 li img {
    margin: 0 0 6px 0;
    height: 91px;
  }
  .c-reformList01 li h3 {
    font-size: 1.4rem;
    margin-bottom: 3px;
  }
  .c-reformList01 li p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.c-reformServiceItemList01__link {
  margin-bottom: 60px;
}
.c-reformServiceItemList01__link li {
  width: calc(20% + 2px);
  margin-right: -2px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  border: solid 2px #247404;
  transition: 0.3s ease-in-out;
}
.c-reformServiceItemList01__link li:nth-child(5n) {
  width: 20%;
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-reformServiceItemList01__link li:nth-child(5n) {
    margin-right: -2px;
  }
}
.c-reformServiceItemList01__link li:nth-child(n+6) {
  border-top: none;
}
@media screen and (max-width: 640px) {
  .c-reformServiceItemList01__link li:nth-child(n+6) {
    border-top: solid 2px #247404;
  }
}
.c-reformServiceItemList01__link li a {
  width: 100%;
  height: 100%;
  color: #247404;
  transition: 0.3s ease-in-out;
  padding: 11px 5px 7px;
  min-height: 52px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.c-reformServiceItemList01__link li a:hover {
  opacity: 1;
}
.c-reformServiceItemList01__link li a:after {
  width: 6px;
  height: 10px;
  content: "";
  display: block;
  background-image: url("../img/common/icon_arrow-green05.png");
  -webkit-background-size: cover;
  background-size: cover;
  transform: rotate(90deg) translateX(-50%);
  position: absolute;
  bottom: 7px;
  left: 50%;
}
.c-reformServiceItemList01__link li a span {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  line-height: 1;
}
.c-reformServiceItemList01__link li:hover {
  background-color: #247404;
}
.c-reformServiceItemList01__link li:hover a {
  color: #ffffff;
}
.c-reformServiceItemList01__link li:hover a:after {
  background-image: url("../img/common/icon_arrow-white01.png");
}
.c-reformServiceItemList01__items {
  margin-bottom: 50px;
}
.c-reformServiceItemList01__items ul li {
  width: 273px;
  min-height: 211px;
  margin: 0 10px 10px 0;
  padding: 10px;
  text-align: center;
  background-color: #f5f5f5;
  border: solid 1px #dddddd;
}
.c-reformServiceItemList01__items ul li:nth-child(3n) {
  margin: 0 0 10px 0;
}
.c-reformServiceItemList01__items ul li img {
  margin-bottom: 25px;
  width: 100%;
  height: 126px;
  object-fit: cover;
}
.c-reformServiceItemList01__items ul li a {
  padding: 0 10px;
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  padding-left: 1.8em;
  text-indent: -1.1em;
  color: #141414;
}
.c-reformServiceItemList01__items ul li a:before {
  width: 6px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow_red.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-reformServiceItemList01__items ul li span {
  display: block;
  text-align: left;
  padding-left: 28px;
  font-size: 1rem;
  font-weight: 400;
}
@media screen and (max-width: 640px) {
  .c-reformServiceItemList01__items ul li span {
    padding-left: 16px;
  }
}
.c-reformServiceItemList01__items ul li:hover {
  opacity: 1;
}
.c-reformServiceItemList01__items ul li:hover a {
  opacity: 1;
}
.c-reformServiceItemList01__imgs {
  overflow: hidden;
  margin-bottom: 25px;
}
.c-reformServiceItemList01__imgs img {
  transition: 0.3s ease-in-out;
  width: 100%;
  margin-bottom: 0 !important;
}
.c-reformServiceItemList01__imgs:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 640px) {
  .c-reformServiceItemList01__link {
    margin-bottom: 41px;
  }
  .c-reformServiceItemList01__link li {
    width: calc(50% + 2px) !important;
    margin: 0 -2px -2px 0;
    font-size: 1.4rem;
  }
  .c-reformServiceItemList01__link li:nth-child(2n) {
    width: 50% !important;
    margin-right: 0 !important;
  }
  .c-reformServiceItemList01__link li a {
    padding: 7px 5px 7px;
  }
  .c-reformServiceItemList01__items {
    margin-bottom: 29px;
  }
  .c-reformServiceItemList01__items ul li {
    width: 100%;
    min-height: auto;
    height: auto;
    margin: 0 0 9px 0 !important;
  }
  .c-reformServiceItemList01__items ul li img {
    margin-bottom: 11px;
  }
  .c-reformServiceItemList01__items ul li a {
    font-size: 1.2rem;
    padding-right: 0;
    padding-left: 1.2em;
    text-indent: -1.3em;
  }
}
/* 葬祭
------------------------------------------------------------*/
.c-sosaiAreaList01 img {
  margin: 17px 0 59px 187px;
  display: block;
}
.c-sosaiAreaList01 ul li {
  margin: 0 10px 10px 0;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiAreaList01 ul li a {
  padding: 12px 30px 12px 30px;
  color: #ffffff;
  display: block;
  background-color: #2e95a3;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

@media screen and (max-width: 640px) {
  .c-sosaiAreaList01 img {
    margin: 0 auto 30px auto;
  }
  .c-sosaiAreaList01 ul li {
    margin: 0 0 5px 0;
    font-size: 1.3rem;
    text-align: center;
    display: block;
    width: 100%;
  }
  .c-sosaiAreaList01 ul li a {
    width: 100%;
    min-height: 50px;
    height: 100%;
    padding: 9px 12px;
    line-height: 1.5;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    vertical-align: middle;
  }
}
/*
------------------------------------------------------------*/
.c-sosaiHallList dl {
  margin-bottom: 30px;
  background-color: #ffffff;
  border: solid 1px #333745;
}
.c-sosaiHallList dl:last-child {
  margin: 0;
}
.c-sosaiHallList dl dt {
  padding: 10px 10px 10px 60px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #333745;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiHallList dl dt:before {
  width: 30px;
  height: 30px;
  margin: -8px 15px 0 -45px;
  content: "";
  background-image: url("../img/sosai/icon_sosai01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-sosaiHallList dl dd {
  padding: 50px;
  font-size: 1.6rem;
  color: #111111;
}
.c-sosaiHallList dl dd table {
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
  border-top: dotted 1px #111111;
  line-height: 1.5;
}
.c-sosaiHallList dl dd table th {
  width: 160px;
  padding: 24px 10px;
  text-align: left;
  vertical-align: top;
  border-bottom: dotted 1px #111111;
}
.c-sosaiHallList dl dd table th:before {
  width: 8px;
  height: 8px;
  margin: -4px 5px 0 0;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.c-sosaiHallList dl dd table td {
  padding: 24px 30px 20px 0;
  vertical-align: top;
  border-bottom: dotted 1px #111111;
  font-weight: 500;
}
.c-sosaiHallList dl dd .c-sosaiBtn01 {
  width: 100%;
}
.c-sosaiHallList__img {
  width: 435px;
}
.c-sosaiHallList__img img {
  width: 435px;
  height: 326px;
  object-fit: cover;
}
.c-sosaiHallList__detail {
  width: 530px;
}

@media screen and (max-width: 640px) {
  .c-sosaiHallList dl {
    margin-bottom: 20px;
  }
  .c-sosaiHallList dl dt {
    font-size: 1.9rem;
    padding: 18px 10px 15px 57px;
  }
  .c-sosaiHallList dl dt:before {
    width: 40px;
    height: 40px;
    margin-right: 11px;
  }
  .c-sosaiHallList dl dd {
    padding: 15px 15px 20px;
  }
  .c-sosaiHallList dl dd table {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
  .c-sosaiHallList dl dd table th {
    width: 35%;
    padding: 13px 0 0 5px;
    vertical-align: middle;
  }
  .c-sosaiHallList dl dd table td {
    line-height: 1.3;
    padding: 10px 0 10px 0;
    vertical-align: middle;
  }
  .c-sosaiHallList dl dd .c-sosaiBtn01 {
    width: 56%;
    height: auto;
    font-size: 1.3rem;
    line-height: 2.4;
  }
  .c-sosaiHallList dl dd .c-sosaiBtn01 a {
    padding: 5px;
  }
  .c-sosaiHallList__img {
    width: 100%;
    margin-bottom: 15px;
  }
  .c-sosaiHallList__img img {
    width: 100%;
    height: auto;
  }
  .c-sosaiHallList__detail {
    width: 100%;
  }
}
/*
------------------------------------------------------------*/
.c-sosaiPlanFlow {
  text-align: center;
}
.c-sosaiPlanFlow > div {
  display: inline-block;
  border: 2px solid #29806c;
  overflow: hidden;
  *zoom: 1;
}
.c-sosaiPlanFlow > div:after {
  display: block;
  content: "";
  clear: both;
}
.c-sosaiPlanFlow__item {
  width: 169px;
  padding: 19px 0 19px 0;
  float: left;
  text-align: center;
  position: relative;
}
.c-sosaiPlanFlow__item:nth-child(2n) {
  background: #f6f6f6;
}
.c-sosaiPlanFlow__item:after {
  width: 40px;
  height: 21px;
  content: "";
  background: url(../img/component/3arrow.png) no-repeat;
  position: absolute;
  top: 50%;
  right: -20px;
  z-index: 10;
  transform: translate(0, -60%);
}
.c-sosaiPlanFlow__item:last-child:after {
  content: none;
}
.c-sosaiPlanFlow__txt {
  height: 120px;
  margin: 0 auto;
  padding: 13px 0;
}
.c-sosaiPlanFlow__icon {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .c-sosaiPlanFlow__item {
    width: 100%;
    display: table;
    text-align: left;
    padding: 13px 21px 12px 16px;
  }
  .c-sosaiPlanFlow__item:after {
    width: 15px;
    height: 24px;
    background: url(../img/component/3arrow02.png) no-repeat;
    background-size: 100%;
    top: calc(100% - 9px);
    left: 50%;
    transform: translate(-50%, 0);
  }
  .c-sosaiPlanFlow__txt {
    width: 83%;
    height: auto;
    padding: 0;
    display: table-cell;
    vertical-align: middle;
  }
  .c-sosaiPlanFlow__icon {
    width: 15%;
    height: auto;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding-bottom: 0;
  }
  .c-sosaiPlanFlow__icon img {
    vertical-align: middle;
  }
}
/*
------------------------------------------------------------*/
.c-sosaiFlow01 {
  border: solid 2px #2e95a3;
}
.c-sosaiFlow01 > li {
  position: relative;
  min-height: 150px;
  padding: 0 40px 0 0;
  background-color: #f8f8f8;
  display: flex;
}
.c-sosaiFlow01 > li:after {
  width: 21px;
  height: 40px;
  margin: 0 auto;
  content: "";
  background: url("../img/component/3arrow02.png") no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  position: absolute;
  bottom: -18px;
  left: -1px;
  right: 0;
  z-index: 10;
}
.c-sosaiFlow01 > li:nth-child(odd) {
  background-color: #fff;
  min-height: 148px;
}
.c-sosaiFlow01 > li:nth-child(2) p, .c-sosaiFlow01 > li:nth-child(4) p {
  padding: 59px 0 0;
}
.c-sosaiFlow01 > li:nth-child(3) p {
  padding: 46px 0 0;
}
.c-sosaiFlow01 > li:last-child:after {
  content: none;
}
.c-sosaiFlow01 > li:last-child p {
  padding: 45px 0 0;
}
.c-sosaiFlow01 > li h3 {
  width: 387px;
  padding: 62px 45px 0 119px;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: #111111;
  display: block;
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiFlow01 > li h3 img {
  width: 50px;
  position: absolute;
  top: 50px;
  left: 50px;
}
.c-sosaiFlow01 > li p {
  width: calc(100% - 387px);
  line-height: 3rem;
  padding: 42px 0;
}

@media screen and (max-width: 640px) {
  .c-sosaiFlow01 {
    border: 2px solid #277861;
  }
  .c-sosaiFlow01 > li {
    min-height: 97px;
    padding: 20px 15px 10px 55px;
    display: block;
  }
  .c-sosaiFlow01 > li:nth-child(odd) {
    min-height: 137px;
  }
  .c-sosaiFlow01 > li:after {
    display: none;
  }
  .c-sosaiFlow01 > li:nth-child(1) {
    padding: 21px 15px 21px 55px;
  }
  .c-sosaiFlow01 > li:nth-child(2) p, .c-sosaiFlow01 > li:nth-child(3) p, .c-sosaiFlow01 > li:nth-child(4) p, .c-sosaiFlow01 > li:nth-child(5) p {
    padding: 0;
  }
  .c-sosaiFlow01 > li:last-child {
    padding: 22px 15px 16px 55px;
  }
  .c-sosaiFlow01 > li h3 {
    font-size: 1.6rem;
    display: block;
    width: 100%;
    padding: 0 0 9px;
    line-height: 1.8rem;
  }
  .c-sosaiFlow01 > li h3 img {
    position: absolute;
    top: -6px;
    left: -41px;
    width: 30px;
  }
  .c-sosaiFlow01 > li p {
    width: 100%;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .c-sosaiFlow01 > li p span {
    font-size: 1.1rem;
    line-height: 1.5rem;
    display: block;
    margin-top: 3px;
  }
}
/* コンポーネント
------------------------------------------------------------*/
.c-block01 {
  width: 100%;
  min-width: 1110px;
}
@media screen and (max-width: 640px) {
  .c-block01 {
    min-width: 100%;
  }
}
.c-block01__item {
  width: 100%;
  display: table;
}
@media screen and (max-width: 640px) {
  .c-block01__item {
    margin-bottom: 20px;
  }
}
.c-block01__item:hover {
  opacity: 1;
}
.c-block01__item:hover .c-block01__img img {
  transform: scale(1.1);
}
.c-block01__item:hover .c-block01__img p:before {
  border: solid 1px #fff !important;
}
.c-block01__item:hover .c-block01__img p:after {
  opacity: 0.3;
}
.c-block01__item:hover .service-btn {
  color: #8cb340;
  background: #fff;
}
.c-block01__item:hover .service-btn:after {
  background: url("../img/component/arrow-green.png");
}
.c-block01__txt {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
  padding: 0 25px;
}
@media screen and (max-width: 640px) {
  .c-block01__txt {
    width: 100%;
    padding: 0 0 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
@media screen and (max-width: 640px) {
  .c-block01__txt .service-title {
    text-align: center;
  }
}
.c-block01__txt .service-title .wrapper {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .c-block01__txt .service-title .wrapper {
    display: block;
  }
}
.c-block01__txt .service-title span {
  font-size: 3.2rem;
  font-weight: bold;
  padding: 0 0 0 20px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 640px) {
  .c-block01__txt .service-title span {
    display: block;
    font-size: 2rem;
    padding: 8px 0 0 0;
  }
}
.c-block01__txt .service-cont {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.8rem;
  padding: 2.5% 0px 4.3% 1px;
  color: #595950;
}
@media screen and (max-width: 640px) {
  .c-block01__txt .service-cont {
    font-size: 1.4rem;
    line-height: 1.7;
    padding: 1px 0 10px;
  }
}
.c-block01__txt .service-btn {
  width: 68%;
  height: 49px;
  margin: 0 auto;
  padding: 10px 7.2% 8px 5px;
  line-height: 20px;
  display: table;
  font-size: 1.6rem;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  border-radius: 50px;
  box-sizing: border-box;
  border: 2px solid #8cb340;
  color: #fff;
  background: #8cb340;
  position: relative;
  transition: 0.3s ease-in-out;
  font-family: "Noto Sans Japanese";
}
@media screen and (max-width: 640px) {
  .c-block01__txt .service-btn {
    width: 100%;
    height: auto;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.3;
    padding: 8px 0;
  }
}
.c-block01__txt .service-btn:after {
  content: "";
  position: absolute;
  background: url(../img/component/arrow-white.png);
  width: 7px;
  height: 12px;
  right: 7.5%;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 640px) {
  .c-block01__txt .service-btn:after {
    right: 14px;
  }
}
.c-block01__txt .service-btn:hover {
  color: #8cb340;
  background: #fff;
}
.c-block01__txt .service-btn:hover:after {
  background: url(../img/component/arrow-green.png);
}
.c-block01__img {
  overflow: hidden;
}
.c-block01__img a,
.c-block01__img p {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.c-block01__img a:before,
.c-block01__img p:before {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  z-index: 2;
  transition: 0.3s ease-in-out;
}
.c-block01__img a:after,
.c-block01__img p:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.c-block01__img img {
  width: 100%;
  object-fit: cover;
  height: fit-content;
  min-height: 340px;
  transition: 0.5s ease-in-out;
}
.c-block01__img:hover img {
  transform: scale(1.1);
}
.c-block01__img:hover a:before {
  border: solid 1px #fff !important;
}
.c-block01__img:hover a:after {
  opacity: 0.3;
}
@media screen and (max-width: 640px) {
  .c-block01__imgsp img {
    width: 100%;
    height: auto;
  }
}
.c-block01 .item01 .c-block01__txt {
  background: url(../img/component/service_bg01.png);
  background-size: 100%;
}
.c-block01 .item01 .c-block01__txt .service-title span {
  color: #8e5867;
}
.c-block01 .item01 .c-block01__img a:before,
.c-block01 .item01 .c-block01__img p:before {
  border: solid 1px #fc819b;
}
.c-block01 .item01 .c-block01__img a:after,
.c-block01 .item01 .c-block01__img p:after {
  background: url(../img/component/service_bg06.png) repeat;
}
.c-block01 .item01 .service-wrapper {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
}
.c-block01 .item02 .c-block01__txt {
  background: url(../img/component/service_bg02.png);
  background-size: 100%;
}
.c-block01 .item02 .c-block01__txt .service-title span {
  color: #3d4e7e;
}
.c-block01 .item02 .c-block01__img a:before,
.c-block01 .item02 .c-block01__img p:before {
  border: solid 1px #82cdf3;
}
.c-block01 .item02 .c-block01__img a:after,
.c-block01 .item02 .c-block01__img p:after {
  background: url(../img/component/service_bg07.png) repeat;
}
.c-block01 .item02 .service-wrapper {
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
}
.c-block01 .item03 .c-block01__txt {
  background: url(../img/component/service_bg03.png);
  background-size: 100%;
}
.c-block01 .item03 .c-block01__txt .service-title span {
  padding: 0 0 0 11px;
  color: #043b18;
}
.c-block01 .item03 .c-block01__img a:before,
.c-block01 .item03 .c-block01__img p:before {
  border: solid 1px #68ba93;
}
.c-block01 .item03 .c-block01__img a:after,
.c-block01 .item03 .c-block01__img p:after {
  background: url(../img/component/service_bg08.png) repeat;
}
.c-block01 .item03 .service-wrapper {
  max-width: 561px;
  width: 100%;
  margin: 0 auto;
}
.c-block01 .item04 .c-block01__txt {
  background: url(../img/component/service_bg04.png);
  background-size: 100%;
}
.c-block01 .item04 .c-block01__txt .service-title span {
  padding: 0 0 0 10px;
  color: #7f5125;
}
.c-block01 .item04 .c-block01__img a:before,
.c-block01 .item04 .c-block01__img p:before {
  border: solid 1px #faad5a;
}
.c-block01 .item04 .c-block01__img a:after,
.c-block01 .item04 .c-block01__img p:after {
  background: url(../img/component/service_bg09.png) repeat;
}
.c-block01 .item04 .service-wrapper {
  max-width: 575px;
  width: 100%;
  margin: 0 auto;
}
.c-block01 .item05 .c-block01__txt {
  background: url(../img/component/service_bg05.png);
  background-size: 100%;
}
.c-block01 .item05 .c-block01__txt .service-title span {
  color: #503f69;
}
.c-block01 .item05 .c-block01__img a:before,
.c-block01 .item05 .c-block01__img p:before {
  border: solid 1px #af8ebe;
}
.c-block01 .item05 .c-block01__img a:after,
.c-block01 .item05 .c-block01__img p:after {
  background: url(../img/component/service_bg10.png) repeat;
}
.c-block01 .item05 .service-wrapper {
  max-width: 561px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .c-block01 .item01 .service-wrapper,
  .c-block01 .item02 .service-wrapper,
  .c-block01 .item03 .service-wrapper,
  .c-block01 .item04 .service-wrapper,
  .c-block01 .item05 .service-wrapper {
    width: 100%;
    padding: 0 20px;
    margin: -35px 0 0;
    max-width: 100%;
  }
}

/*
------------------------------------------------------------*/
.c-block02 {
  width: 100%;
  min-width: 1110px;
  display: table;
}
@media screen and (max-width: 640px) {
  .c-block02 {
    display: block;
    min-width: 100%;
  }
}
.c-block02__left {
  width: 50%;
  display: table-cell;
}
.c-block02__left img {
  width: 100%;
  object-fit: cover;
  height: fit-content;
}
@media screen and (max-width: 640px) {
  .c-block02__left {
    width: 100%;
    float: none;
    display: block;
  }
  .c-block02__left img {
    height: auto;
  }
}
.c-block02__right {
  width: 50%;
  background: #faf9f6;
  display: table-cell;
  vertical-align: middle;
}
.c-block02__right p {
  padding-bottom: 31px;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-block02__right {
    display: block;
    width: calc(100% - 30px);
    padding: 28px 31px 30px 30px;
    margin: -19px 15px 0;
    position: relative;
    z-index: 10;
  }
  .c-block02__right p {
    padding-bottom: 20px;
  }
}
.c-block02__inner {
  width: 75%;
  padding: 0 0 0 80px;
}
@media screen and (max-width: 640px) {
  .c-block02__inner {
    width: 100%;
    padding: 0;
  }
}
.c-block02__title {
  display: inline-flex;
  align-items: center;
  line-height: 1;
}
.c-block02__title .title-txt {
  padding: 1px 0 0 16px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .c-block02__title .title-txt {
    padding: 1px 0 0 10px;
  }
}
.c-block02__title .title-txt01 {
  font-size: 1.7rem;
}
@media screen and (max-width: 640px) {
  .c-block02__title .title-txt01 {
    font-size: 1.2rem;
  }
}
.c-block02__title .title-txt02 {
  font-size: 3.8rem;
  padding-top: 10px;
  font-weight: normal;
}
@media screen and (max-width: 640px) {
  .c-block02__title .title-txt02 {
    font-size: 2.5rem;
    padding-top: 7px;
  }
}
.c-block02__txt {
  padding-top: 27px;
  font-size: 1.7rem;
  line-height: 30px;
}
@media screen and (max-width: 640px) {
  .c-block02__txt {
    width: 100%;
    font-size: 1.4rem;
    padding-top: 18px;
    line-height: 20px;
  }
}
.c-block02__btn {
  width: 240px;
  padding: 15px 0 13px 30px;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-block02__btn {
    padding: 10px 0 11px 0;
  }
  .c-block02__btn:after {
    right: 30px;
  }
}

/*
------------------------------------------------------------*/
.c-list01 {
  background: url(../img/component/bg01.png);
  background-size: cover;
  padding: 60px 0 40px;
}
@media screen and (max-width: 640px) {
  .c-list01 {
    padding: 50px 15px 35px;
  }
}
.c-list01__wrapper {
  width: 1122px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}
@media screen and (max-width: 640px) {
  .c-list01__wrapper {
    width: 100%;
  }
}
.c-list01__item {
  width: 373px;
  height: 400px;
  margin: 0 1px 20px 0;
  position: relative;
}
.c-list01__item:nth-child(3n) {
  margin-right: 0;
}
.c-list01__item a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 640px) {
  .c-list01__item {
    width: 33%;
    height: auto;
  }
  .c-list01__item img {
    width: 100%;
    height: auto;
  }
}
.c-list01--txt {
  position: absolute;
  top: 29px;
  right: 17px;
  writing-mode: vertical-lr;
}
@media screen and (max-width: 640px) {
  .c-list01--txt {
    top: 21px;
    right: 8px;
  }
}
.c-list01--txt span {
  color: #111;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  line-height: 1.5;
  font-weight: normal;
}
.c-list01--txt span:nth-child(1) {
  font-size: 2rem;
}
@media screen and (max-width: 640px) {
  .c-list01--txt span:nth-child(1) {
    font-size: 1.2rem;
  }
}
.c-list01--txt span:nth-child(2) {
  font-size: 3.2rem;
}
@media screen and (max-width: 640px) {
  .c-list01--txt span:nth-child(2) {
    font-size: 1.9rem;
  }
}

/*
------------------------------------------------------------*/
.c-list02 {
  width: 1120px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}
@media screen and (max-width: 640px) {
  .c-list02 {
    width: 100%;
    padding: 0 15px;
  }
}
.c-list02__item {
  width: 354px;
  float: left;
  margin-right: 30px;
  position: relative;
}
.c-list02__item:last-child {
  margin-right: 0;
}
.c-list02__item a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 640px) {
  .c-list02__item {
    width: 33.33%;
    margin-right: 15px;
  }
  .c-list02__item img {
    width: 100%;
    height: auto;
  }
}
.c-list02--txt {
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  writing-mode: unset;
  padding: 10px 27px;
  border: 3px solid #fff;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .c-list02--txt {
    writing-mode: vertical-lr;
    padding: 20px 10px;
  }
}
.c-list02--txt span {
  font-size: 1.6rem;
  line-height: 4.8rem;
  color: #2f2933;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-list02--txt span {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.c-list02--txt:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 30px;
  background: #2e95a3;
  right: -6px;
  bottom: -18px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.c-list02--txt02 {
  position: absolute;
  top: 3px;
  right: 20px;
  writing-mode: vertical-lr;
}
@media screen and (max-width: 640px) {
  .c-list02--txt02 {
    top: 4px;
    right: 4px;
  }
}
.c-list02--txt02 span {
  color: #111;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  line-height: 1.5;
  font-weight: normal;
  font-size: 3.2rem;
}
@media screen and (max-width: 640px) {
  .c-list02--txt02 span {
    font-size: 1.9rem;
  }
}

/*
------------------------------------------------------------*/
.c-list03 {
  width: 1120px;
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .c-list03 {
    width: 100%;
    padding: 0 15px;
  }
}
.c-list03__item {
  width: 545px;
  height: 228px;
  float: left;
  margin: 0 30px 30px 0;
  position: relative;
}
.c-list03__item a {
  display: block;
  position: relative;
}
.c-list03__item:nth-child(2n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-list03__item {
    width: calc(50% - 8px);
    height: auto;
    margin: 0 15px 15px 0;
  }
}
.c-list03__img img {
  width: 100%;
}
.c-list03__lable {
  overflow: hidden;
  font-size: 1.6rem;
  font-weight: bold;
  background: #fff;
  color: #000;
  border: 3px solid #fff;
  padding: 22px 27px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-list03__lable:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 30px;
  background: #2e95a3;
  right: -6px;
  bottom: -18px;
  transform: rotate(45deg);
}
@media screen and (max-width: 640px) {
  .c-list03__lable {
    width: 85%;
    left: 50%;
    font-size: 1.2rem;
    text-align: center;
    padding: 4px 15px;
    line-height: 16px;
  }
}
.c-list03__listItem {
  display: block;
  width: 262px;
  float: left;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-list03__listItem {
    width: calc(50% - 5px);
    padding-top: 5px;
  }
}
.c-list03__listItem:last-child {
  margin-left: 21px;
}
@media screen and (max-width: 640px) {
  .c-list03__listItem:last-child {
    margin-left: 10px;
  }
}
.c-list03__listItem:last-child .c-list03__lable {
  width: 156px;
}
@media screen and (max-width: 640px) {
  .c-list03__listItem:last-child .c-list03__lable {
    width: auto;
    min-width: 100px;
  }
}
.c-list03__listItem .c-list03__lable {
  padding: 21px 22px 21px 28px;
  width: 140px;
}
@media screen and (max-width: 640px) {
  .c-list03__listItem .c-list03__lable {
    padding: 12px 10px !important;
    width: auto;
    min-width: 90px;
  }
}

/*
------------------------------------------------------------*/
.c-list04 {
  width: 1122px;
  margin: 0 auto;
  overflow: hidden;
  border: 2px solid #29806c;
}
@media screen and (max-width: 640px) {
  .c-list04 {
    width: calc(100% - 30px);
    margin: 0 15px;
  }
}
.c-list04__item {
  width: 12.5%;
  float: left;
  text-align: center;
  padding: 28px 0 44px 0;
}
.c-list04__item:nth-child(2n) {
  background: #f6f6f6;
}
.c-list04__item:last-child .c-list04__number:after {
  background: none;
}
@media screen and (max-width: 640px) {
  .c-list04__item {
    width: 100%;
    height: 60px;
    display: table;
    text-align: left;
    padding: 14px 22px 13px 14px;
  }
}
.c-list04__number {
  position: relative;
}
.c-list04__number:after {
  content: "";
  background: url(../img/component/3arrow.png) no-repeat;
  position: absolute;
  height: 21px;
  width: 40px;
  top: 18px;
  right: -20px;
  z-index: 1;
}
@media screen and (max-width: 640px) {
  .c-list04__number {
    width: 30px;
    height: 30px;
    display: table-cell;
    vertical-align: middle;
  }
  .c-list04__number:after {
    background: url(../img/component/3arrow02.png) no-repeat;
    background-size: contain;
    width: 15px;
    height: 24px;
    top: 34px;
    left: 8px;
  }
}
.c-list04__txt {
  height: 195px;
  font-size: 2.4rem;
  line-height: 28px;
  text-align: left;
  margin: 0 auto;
  display: inline-block;
  padding: 15px 0 0;
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  font-family: "Hiragino Kaku Gothic ProN";
}
@media screen and (max-width: 640px) {
  .c-list04__txt {
    font-size: 1.6rem;
    line-height: 18px;
    height: auto;
    writing-mode: unset;
    -ms-writing-mode: rl-tb;
    display: table-cell;
    vertical-align: middle;
    padding: 0 0 0 14px;
  }
}
.c-list04__icon {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .c-list04__icon {
    width: 10%;
    height: auto;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}
.c-list04 a > * {
  transition: 0.3s ease-in-out;
}
.c-list04 a:hover {
  opacity: 1;
}
.c-list04 a:hover > * {
  opacity: 0.8;
}

/*
------------------------------------------------------------*/
.c-list05 {
  width: 840px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .c-list05 {
    width: 100%;
  }
}
.c-list05__item {
  width: calc((100% - 20px) / 2);
  height: 80px;
  background: url("../img/hoken/bgbtn1.png") no-repeat;
  background-size: cover;
  border-radius: 3px;
  border: 2px solid #ffcf8a;
  margin-right: 20px;
}
@media screen and (max-width: 640px) {
  .c-list05__item {
    width: 100%;
    height: auto;
    margin: 0 0 10px;
  }
  .c-list05__item img {
    width: 100%;
  }
}
.c-list05__item:nth-child(2n) {
  background: url("../img/hoken/bgbtn2.png") no-repeat;
  background-size: cover;
  border: 2px solid #85d3b8;
  margin-right: 0;
}
.c-list05__item:nth-child(2n) a {
  color: #007a50;
}
.c-list05__item:nth-child(2n) a:before {
  background: url("../img/hoken/pc.png") no-repeat;
  background-size: cover;
  margin: -2px 14px 0 0;
}
@media screen and (max-width: 640px) {
  .c-list05__item:nth-child(2n) a:before {
    margin: -2px 3px 0 0;
  }
}
.c-list05__item:nth-child(2n) a:after {
  background: url("../img/hoken/arrow2.png") no-repeat;
  background-size: cover;
}
.c-list05__item a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  line-height: 3.8rem;
  color: #e57700;
  font-weight: bold;
  padding: 21px 40px 21px 28px;
  letter-spacing: 0.5px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-list05__item a {
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 14px 18px 14px 10px;
  }
}
.c-list05__item a:before {
  content: "";
  width: 40px;
  height: 40px;
  background: url("../img/hoken/heart01.png") no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  margin: -2px 16px 0 0;
}
@media screen and (max-width: 640px) {
  .c-list05__item a:before {
    width: 30px;
    height: 30px;
    margin: -2px 7px 0 0;
  }
}
.c-list05__item a:after {
  content: "";
  width: 8px;
  height: 14px;
  background: url("../img/hoken/arrow1.png") no-repeat;
  background-size: cover;
  position: absolute;
  right: 29px;
  top: calc(50% - 8px);
}
@media screen and (max-width: 640px) {
  .c-list05__item a:after {
    width: 6px;
    height: 10px;
    top: calc(50% - 5px);
    right: 9px;
  }
}
@media screen and (max-width: 640px) {
  .c-list05--style2 {
    max-width: 235px;
  }
}
@media screen and (max-width: 640px) {
  .c-list05--style2 .c-list05__item a {
    font-size: 1.2rem;
    padding: 12px 18px 8px 10px;
  }
}
@media screen and (max-width: 640px) and (max-width: 640px) {
  .c-list05--style2 .c-list05__item a:before {
    margin: -4px 10px 0 0;
  }
}
@media screen and (max-width: 640px) and (max-width: 640px) {
  .c-list05--style2 .c-list05__item a:after {
    right: 13px;
  }
}

/*
------------------------------------------------------------*/
.c-list06 {
  width: 840px;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .c-list06 {
    width: 100%;
  }
}
.c-list06__item {
  width: 266px;
  height: 100px;
  float: left;
  overflow: hidden;
  margin: 0 20px 20px 0;
  border-radius: 5px;
}
.c-list06__item:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-list06__item {
    width: 100%;
    height: auto;
    margin: 0 0 5px 0;
  }
}
.c-list06__item a {
  display: block;
  position: relative;
}
.c-list06__item a:before {
  width: 100%;
  height: 100%;
  content: "";
  border: solid 2px #82cdf3;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.c-list06__item a:hover {
  opacity: 1;
}
.c-list06__item a:hover:before {
  opacity: 1;
}
.c-list06__item--txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 22px;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .c-list06__item--txt {
    font-size: 1.3rem;
    line-height: 18px;
    padding-left: 15px;
  }
}

/*
------------------------------------------------------------*/
.c-list07 {
  width: 840px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .c-list07 {
    width: 100%;
  }
}
.c-list07__item {
  width: 202px;
  height: 80px;
  display: block;
  margin: 0 10px 10px 0;
  position: relative;
  z-index: 1;
}
.c-list07__item a {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
}
@media screen and (max-width: 640px) {
  .c-list07__item a {
    position: unset;
  }
}
.c-list07__item a span {
  display: block;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .c-list07__item a span {
    height: 100%;
  }
}
.c-list07__item a:before {
  width: 100%;
  height: 100%;
  content: "";
  border: 1px solid #ccc;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.c-list07__item a:after {
  width: 100%;
  height: 100%;
  content: "";
  border: solid 2px #82cdf3;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.c-list07__item a:hover {
  opacity: 1;
}
.c-list07__item a:hover:before {
  opacity: 0;
}
.c-list07__item a:hover:after {
  opacity: 1;
}
.c-list07__item:nth-child(4n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-list07__item {
    width: calc(33.33% - 4px);
    height: 40px;
    padding: 5px;
    margin: 0 5px 5px 0;
  }
  .c-list07__item img {
    width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
  }
  .c-list07__item:nth-child(3n) {
    margin-right: 0;
  }
  .c-list07__item:nth-child(4n) {
    margin: 0 5px 5px 0;
  }
  .c-list07__item:nth-child(1) {
    padding: 7px 18px;
  }
}

/*
------------------------------------------------------------*/
.c-list08 {
  display: flex;
  flex-wrap: wrap;
}
.c-list08__item {
  width: calc((100% - 60px) / 4);
  margin: 0 0 20px 20px;
}
@media screen and (max-width: 640px) {
  .c-list08__item {
    width: 100%;
    margin: 0 0 10px;
  }
}
.c-list08__item:nth-child(4n+1) {
  margin: 0 0 20px;
}
@media screen and (max-width: 640px) {
  .c-list08__item:nth-child(4n+1) {
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 640px) {
  .c-list08__item:last-child {
    margin: 0;
  }
}
.c-list08__txt {
  display: block;
  background: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #253b73;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
  font-feature-settings: "palt";
  padding: 17px 10px;
}
@media screen and (max-width: 640px) {
  .c-list08__txt {
    border-radius: 2px;
    font-size: 1.3rem;
    padding: 14.5px 10px;
  }
}
@media screen and (min-width: 641px) {
  .c-list08__txt:hover {
    border-color: #82cdf3;
    color: #82cdf3;
    opacity: 1;
  }
}

/*
------------------------------------------------------------*/
.c-list09 {
  display: flex;
  flex-wrap: wrap;
}
.c-list09__item {
  width: calc((100% - 40px) / 3);
  margin: 0 0 20px 20px;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 34px 39px 24px;
}
@media screen and (max-width: 640px) {
  .c-list09__item {
    width: 100%;
    margin: 0 0 10px;
    padding: 23px 22px 15px;
  }
}
.c-list09__item:nth-child(3n+1) {
  margin: 0 0 20px;
}
@media screen and (max-width: 640px) {
  .c-list09__item:nth-child(3n+1) {
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 640px) {
  .c-list09__item:last-child {
    margin: 0;
  }
}
.c-list09__ttl {
  font-size: 2rem;
  line-height: 1.5;
  color: #46483c;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
  position: relative;
  padding: 0 0 0 24px;
}
@media screen and (max-width: 640px) {
  .c-list09__ttl {
    font-size: 1.6rem;
    line-height: 1.4;
    padding: 0 0 0 17px;
  }
}
.c-list09__ttl::before {
  content: "";
  width: 14px;
  height: 14px;
  background: url("../img/common/icon-square.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 8px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .c-list09__ttl::before {
    width: 10px;
    height: 10px;
    top: 5px;
  }
}
.c-list09__sublist {
  margin: 20px 0 0 5px;
}
@media screen and (max-width: 640px) {
  .c-list09__sublist {
    margin: 14px 0 0 2px;
  }
}
.c-list09__subitem:not(:last-child) {
  margin: 0 0 6px;
}
@media screen and (max-width: 640px) {
  .c-list09__subitem:not(:last-child) {
    margin: 0 0 11px;
  }
}
.c-list09__txt {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #46483c;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
  position: relative;
  padding: 0 0 0 12px;
}
@media screen and (max-width: 640px) {
  .c-list09__txt {
    font-size: 1.3rem;
    line-height: 1.2;
    padding: 0 0 0 11px;
  }
}
.c-list09__txt::before {
  content: "";
  width: 6px;
  height: 6px;
  background: url("../img/common/icon-circle.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 5px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .c-list09__txt::before {
    top: 3px;
  }
}

/*
------------------------------------------------------------*/
.c-card03 {
  width: 840px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .c-card03 {
    width: 100%;
  }
}
.c-card03__item {
  width: 266px;
  float: left;
  margin: 0 20px 25px 0;
}
.c-card03__item:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-card03__item {
    width: calc(50% - 4px);
    margin: 0 8px 12px 0;
  }
  .c-card03__item:nth-child(2n) {
    margin-right: 0;
  }
  .c-card03__item:nth-child(2n+1) {
    margin: 0 8px 12px 0;
  }
}
.c-card03__item a:hover {
  opacity: 1;
}
.c-card03__item a:hover .c-card03__img:before {
  opacity: 1;
}
.c-card03__item a:hover .c-card03__title {
  color: #ff957d;
  text-decoration: underline;
}
.c-card03__img {
  width: 266px;
  height: 150px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}
.c-card03__img:before {
  width: 100%;
  height: 100%;
  content: "";
  border: solid 2px #82cdf3;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .c-card03__img {
    width: 100%;
    height: auto;
  }
  .c-card03__img img {
    width: 100%;
  }
}
.c-card03__title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #253b73;
  padding-top: 14px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .c-card03__title {
    font-size: 1.4rem;
    padding-top: 5px;
    line-height: 19px;
  }
}

/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
.c-tbl01 {
  width: 1126px;
  margin: 0 auto;
  font-size: 1.4rem;
  color: #46483c;
  border-collapse: collapse;
  border-bottom: 1px solid #b2b2b2;
}
@media screen and (max-width: 640px) {
  .c-tbl01 {
    width: 100% !important;
    display: block;
    border: none;
  }
  .c-tbl01 tbody {
    display: block;
  }
  .c-tbl01 tr {
    margin-bottom: 10px;
    display: block;
    border: none !important;
  }
}
.c-tbl01 th, .c-tbl01 td {
  text-align: left;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .c-tbl01 th, .c-tbl01 td {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.c-tbl01 th {
  padding: 10px 0 9px 16px;
  border-right: 1px solid #fff;
}
@media screen and (max-width: 640px) {
  .c-tbl01 th {
    display: none;
  }
}
.c-tbl01 td {
  padding: 17px 15px 15px;
}
@media screen and (max-width: 640px) {
  .c-tbl01 td {
    width: 100% !important;
    text-align: left !important;
    display: table;
    border-left: 1px solid #b2b2b2;
    border-right: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
    padding: 0 !important;
  }
  .c-tbl01 td:first-child {
    border-top: 1px solid #b2b2b2;
  }
  .c-tbl01 td .col-title {
    display: table-cell;
    vertical-align: middle;
    width: 65px;
    height: 100%;
    font-weight: bold;
    background-color: #f3f3f3;
    padding: 11px 2px 10px;
    line-height: 1.4;
    text-align: center;
  }
  .c-tbl01 td .col-txt {
    width: calc(100% - 65px);
    display: table-cell;
    vertical-align: middle;
    padding: 6px 15px;
  }
}
.c-tbl01__title {
  background: #f3f3f3;
  font-weight: bold;
}
.c-tbl01__item {
  line-height: 21px;
  border-bottom: 1px solid #b2b2b2;
}
@media screen and (max-width: 640px) {
  .c-tbl01__total {
    margin-top: 0;
  }
}
.c-tbl01__total td {
  text-align: right !important;
  padding: 8px 0 4px !important;
  font-size: 1.4rem;
  *zoom: 1;
}
.c-tbl01__total td:after {
  display: block;
  content: "";
  clear: both;
}
@media screen and (max-width: 640px) {
  .c-tbl01__total td {
    padding: 0 !important;
    font-size: 1.2rem;
    border: none !important;
    border-bottom: 1px solid #b2b2b2 !important;
  }
}
.c-tbl01__total table {
  float: right;
  border: none;
  margin-bottom: 8px;
}
@media screen and (max-width: 640px) {
  .c-tbl01__total table {
    margin-bottom: 6px;
    width: auto;
    display: block;
    *zoom: 1;
  }
  .c-tbl01__total table:after {
    display: block;
    content: "";
    clear: both;
  }
  .c-tbl01__total table tbody {
    display: block;
  }
  .c-tbl01__total table tr {
    display: block;
    border: none;
    margin-bottom: 0;
  }
  .c-tbl01__total table td {
    width: 50% !important;
    margin: 0;
    display: block;
    border: none;
    float: left;
    white-space: nowrap;
    border: none !important;
  }
}
.c-tbl01__total span {
  font-size: 1.8rem;
  font-weight: bold;
  padding-left: 8px;
}
@media screen and (max-width: 640px) {
  .c-tbl01__total span {
    font-size: 1.4rem;
    padding-left: 4px;
  }
}
.c-tbl01__total .total-number span {
  color: #f22727;
}
.c-tbl01__total .total-price span {
  color: #f22727;
}
.c-tbl01--ticketCart {
  color: #232934;
}
.c-tbl01--ticketCart th {
  padding: 10px 10px 9px;
  white-space: nowrap;
}
.c-tbl01--ticketCart td {
  padding: 17px 10px 15px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-name {
  min-width: 200px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-date {
  min-width: 140px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-time {
  min-width: 100px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-locate {
  min-width: 100px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-type {
  min-width: 80px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-note {
  min-width: 90px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-price {
  min-width: 90px;
}
.c-tbl01--ticketCart .c-tbl01__item .col-subtotal {
  white-space: nowrap;
}
.c-tbl01--ticketCart .c-tbl01__item .col-num {
  min-width: 100px;
  white-space: nowrap;
}
.c-tbl01--ticketCart .c-tbl01__item .col-num select {
  width: 58px;
  height: 30px;
  border-radius: 5px;
  padding: 0 7px;
}
@media screen and (max-width: 640px) {
  .c-tbl01--ticketCart .c-tbl01__item .col-num select {
    font-size: 1.2rem;
    width: 138px;
    height: 28px;
  }
}
.c-tbl01--ticketCart .c-tbl01__item .col-num span {
  color: #308bbb;
  font-size: 1.3rem;
  cursor: pointer;
  border-bottom: 1px solid #308bbb;
  margin-left: 15px;
}
@media screen and (max-width: 640px) {
  .c-tbl01--ticketCart .c-tbl01__item .col-num span {
    margin-left: 17px;
    font-size: 1.1rem;
  }
}
.c-tbl01--hokenRequest .c-tbl01__item .col-name {
  width: 416px;
}
.c-tbl01--hokenRequest .c-tbl01__item .col-hosyo {
  width: 220px;
}
.c-tbl01--hokenRequest .c-tbl01__item .col-kaisya {
  width: 220px;
}
.c-tbl01--hokenRequest .c-tbl01__item .col-kikan {
  width: 160px;
}
.c-tbl01--hokenRequest .c-tbl01__item .col-del {
  width: 104px;
}
.c-tbl01--hokenRequest .c-tbl01__item .col-del a {
  width: 60px;
  height: 26px;
  color: #ffffff;
  background-color: #666666;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 2;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-tbl01--reformContact .c-tbl01__item .col-name {
  width: 416px;
}
.c-tbl01--reformContact .c-tbl01__item .col-kind {
  width: 220px;
}
.c-tbl01--reformContact .c-tbl01__item .col-size {
  width: 160px;
}
.c-tbl01--reformContact .c-tbl01__item .col-maker {
  width: 220px;
}
.c-tbl01--reformContact .c-tbl01__item .col-note {
  width: 160px;
}
.c-tbl01--reformContact .c-tbl01__item .col-del {
  width: 104px;
}
.c-tbl01--reformContact .c-tbl01__item .col-del span {
  width: 60px;
  height: 26px;
  color: #ffffff;
  background-color: #666666;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 2;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-tbl01--kurashiCart {
  color: #594242;
}
.c-tbl01--kurashiCart .c-tbl01__item .col-name {
  width: 439px;
}
.c-tbl01--kurashiCart .c-tbl01__item .col-note {
  width: 179px;
}
.c-tbl01--kurashiCart .c-tbl01__item .col-price {
  width: 179px;
}
.c-tbl01--kurashiCart .c-tbl01__item .col-price span {
  display: block;
  font-size: 1.3rem;
}
@media screen and (max-width: 640px) {
  .c-tbl01--kurashiCart .c-tbl01__item .col-price span {
    font-size: 1.2rem;
  }
}
.c-tbl01--kurashiCart .c-tbl01__item .col-subtotal span {
  display: block;
  font-size: 1.3rem;
}
@media screen and (max-width: 640px) {
  .c-tbl01--kurashiCart .c-tbl01__item .col-subtotal span {
    font-size: 1.2rem;
  }
}
.c-tbl01--kurashiCart .c-tbl01__item .col-num {
  width: 159px;
}
.c-tbl01--kurashiCart .c-tbl01__item .col-num select {
  width: 58px;
  height: 30px;
  border-radius: 5px;
  padding: 0 7px;
}
@media screen and (max-width: 640px) {
  .c-tbl01--kurashiCart .c-tbl01__item .col-num select {
    font-size: 1.2rem;
    width: 138px;
    height: 28px;
  }
}
.c-tbl01--kurashiCart .c-tbl01__item .col-num span {
  margin-left: 1em;
  color: #308bbb;
  font-size: 1.3rem;
  cursor: pointer;
  border-bottom: 1px solid #308bbb;
}
@media screen and (max-width: 640px) {
  .c-tbl01--kurashiCart .c-tbl01__item .col-num span {
    margin-left: 17px;
    font-size: 1.1rem;
  }
}

.c-tbl02 {
  background-color: #fff;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.5;
}
.c-tbl02 th, .c-tbl02 td {
  text-align: left;
  font-weight: normal;
}
@media screen and (max-width: 640px) {
  .c-tbl02__title {
    display: none !important;
  }
  .c-tbl02 thead, .c-tbl02 tbody, .c-tbl02 tfoot, .c-tbl02 tr, .c-tbl02 th, .c-tbl02 td {
    display: block;
  }
  .c-tbl02 tbody tr {
    margin-top: 15px;
    background-color: #888;
    border: 1px solid #888;
    display: grid;
    row-gap: 1px;
  }
  .c-tbl02 tbody td {
    background-color: #fff;
    display: grid;
    grid-template-columns: 115px 1fr;
  }
  .c-tbl02 tbody .col-title {
    white-space: nowrap;
  }
  .c-tbl02 tbody .col-title, .c-tbl02 tbody .col-txt {
    padding: 8px 15px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .c-tbl02 .col-title {
    background-color: #EFEFEF;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .c-tbl02 .js-remove-item {
    width: 100px;
    line-height: 31px;
    display: block;
    background-color: #D9D9D9;
    border-radius: 5px;
    color: #333;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  .c-tbl02__total {
    border-bottom: 1px solid #D9D9D9;
  }
  .c-tbl02__total td {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
@media screen and (min-width: 641px) {
  .c-tbl02 {
    border-collapse: collapse;
  }
  .c-tbl02 th, .c-tbl02 td {
    padding: 10px 30px;
  }
  .c-tbl02__title tr > :not(:last-child) {
    border-right: 1px solid #fff;
  }
  .c-tbl02__title th {
    background-color: #EFEFEF;
    font-size: 1.8rem;
    font-weight: 700;
  }
  .c-tbl02 tbody tr {
    border-bottom: 1px solid #D9D9D9;
  }
  .c-tbl02 .col-del {
    width: 115px;
  }
  .c-tbl02 .js-remove-item {
    width: 55px;
    line-height: 28px;
    display: block;
    background-color: #D9D9D9;
    border-radius: 10px;
    color: #333;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
  .c-tbl02__total {
    border-bottom: 1px solid #D9D9D9;
  }
}

/*
------------------------------------------------------------*/
.c-table1 {
  width: 1120px;
  margin: 0 auto;
  font-size: 1.6rem;
  border-top: 1px solid #efefef;
}
@media screen and (max-width: 640px) {
  .c-table1 {
    width: calc(100% - 30px);
    margin: 0 15px;
    border: 1px solid #b2b2b2;
    font-size: 1.3rem;
  }
}
.c-table1 dl {
  display: flex;
}
.c-table1 dl:nth-child(3) input {
  width: 280px;
  padding: 0 15px;
}
.c-table1 dl:nth-child(4) li, .c-table1 dl:nth-child(5) li {
  float: left;
}
.c-table1 dl:nth-child(4) input, .c-table1 dl:nth-child(5) input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.c-table1 dl:nth-child(5) input {
  margin: 0 29px 0 7px;
}
.c-table1 dl:nth-child(6) input, .c-table1 dl:nth-child(7) input {
  padding: 0 15px;
  width: 240px;
}
.c-table1 dl:nth-child(8) input, .c-table1 dl:nth-child(9) input, .c-table1 dl:nth-child(10) input, .c-table1 dl:nth-child(11) input, .c-table1 dl:nth-child(12) input, .c-table1 dl:nth-child(13) input {
  width: 600px;
  padding: 0 15px;
}
@media screen and (max-width: 640px) {
  .c-table1 dl {
    display: block;
    border-bottom: 1px solid #b2b2b2;
  }
  .c-table1 dl:nth-child(3) input, .c-table1 dl:nth-child(6) input, .c-table1 dl:nth-child(7) input, .c-table1 dl:nth-child(8) input, .c-table1 dl:nth-child(9) input, .c-table1 dl:nth-child(10) input, .c-table1 dl:nth-child(11) input, .c-table1 dl:nth-child(12) input, .c-table1 dl:nth-child(13) input {
    width: 100%;
    padding: 10px;
  }
  .c-table1 dl:nth-child(4) li, .c-table1 dl:nth-child(5) li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .c-table1 dl:nth-child(4) input, .c-table1 dl:nth-child(5) input {
    width: 94%;
    padding: 10px;
    margin: 0 0 10px 10px;
  }
  .c-table1 dl:nth-child(5) input {
    width: 95%;
  }
}
.c-table1 dt {
  display: flex;
  align-items: center;
  width: 280px;
  background: #f3f3f3;
  position: relative;
  padding: 20px 0 15px 31px;
  margin-bottom: 1px;
}
.c-table1 dt label {
  font-weight: bold;
  color: #232934;
}
@media screen and (max-width: 640px) {
  .c-table1 dt label {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 640px) {
  .c-table1 dt {
    width: 100%;
    padding: 15px;
  }
}
.c-table1 dd {
  overflow: hidden;
  width: calc(100% - 280px);
  padding: 20px 0 15px 31px;
  border-bottom: 1px solid #efefef;
}
@media screen and (max-width: 640px) {
  .c-table1 dd {
    width: 100%;
    padding: 10px 15px;
  }
}
.c-table1 input {
  border: 1px solid #cccccc;
  border-radius: 3px;
  height: 38px;
  color: #232934;
}
.c-table1 input::placeholder {
  color: #999999;
}
@media screen and (max-width: 640px) {
  .c-table1 input {
    height: auto;
  }
}
.c-table1 textarea {
  border: 1px solid #cccccc;
  border-radius: 3px;
  height: 120px;
  color: #232934;
}
.c-table1 textarea::placeholder {
  color: #999999;
}
.c-table1--require {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 29px;
  transform: translateY(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background: #f22727;
  padding: 0 5px;
  line-height: 1.6;
  border-radius: 3px;
}
@media screen and (max-width: 640px) {
  .c-table1--require {
    position: unset;
    transform: none;
    margin-left: 10px;
  }
}
.c-table1--note1 {
  margin-top: 6px;
  font-size: 1.4rem;
  color: #999999;
  line-height: 1.4;
  display: block;
}
@media screen and (max-width: 640px) {
  .c-table1--note1 {
    font-size: 1.1rem;
  }
}
.c-table1--ttl1 {
  color: #232934;
}
@media screen and (max-width: 640px) {
  .c-table1--ttl1 {
    width: 12%;
  }
}
.c-table1__radio {
  margin: 7px 0 5px;
  color: #232934;
}
.c-table1__radio li {
  display: inline-block;
  margin-right: 26px;
}
.c-table1__radio li:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-table1__radio li {
    display: block;
  }
}
.c-table1__radio input {
  position: absolute;
  display: none;
}
.c-table1__radio input + label {
  position: relative;
  display: block;
  padding-left: 23px;
  cursor: pointer;
  vertical-align: middle;
}
.c-table1__radio input + label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}
.c-table1__radio input + label:before {
  position: absolute;
  top: 4px;
  left: 0;
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
  border: 1px solid #58595B;
}
.c-table1__radio input + label:after {
  position: absolute;
  display: none;
  content: "";
}
.c-table1__radio input[disabled] + label:hover:before {
  border: 1px solid #e4e4e4;
  animation-name: none;
}
.c-table1__radio input[disabled] + label:before {
  border-color: #e4e4e4;
}
.c-table1__radio input:checked + label:before {
  animation-name: none;
  background: #9ac985;
  border: 1px solid #9ac985 !important;
}
.c-table1__radio input:checked + label:after {
  display: block;
}
.c-table1__radio input + label:before {
  border-radius: 50%;
}
.c-table1__radio input + label:after {
  top: 8px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
}
.c-table1__radio input:checked[disabled] + label:before {
  border: 1px solid #c9e2f9;
}
.c-table1__radio input:checked[disabled] + label:after {
  background: #c9e2f9;
}
.c-table1__checkbox01 li {
  float: left;
  margin-right: 25px;
}
.c-table1__checkbox02 {
  overflow: hidden;
  padding-bottom: 9px;
}
.c-table1__checkbox02 li {
  width: 150px;
  float: left;
}
@media screen and (max-width: 640px) {
  .c-table1__checkbox02 {
    padding-bottom: 0;
  }
  .c-table1__checkbox02 li {
    width: 100%;
    float: none;
  }
}
.c-table1 .u-style01 {
  margin-top: 25px;
  border-top: 1px solid #efefef;
}
.c-table1 .u-style02 .c-table1--note2 {
  margin-top: 6px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #f22727;
}
@media screen and (max-width: 640px) {
  .c-table1 .u-style02 .c-table1--note2 {
    font-size: 1.1rem;
  }
}
.c-table1 .u-style02 dt {
  background: rgba(242, 39, 39, 0.2);
}
.c-table1 .u-style02 input {
  background: rgba(242, 39, 39, 0.2);
}

/*
------------------------------------------------------------*/
.c-table2 {
  width: 1120px;
  margin: 0 auto;
  font-size: 1.6rem;
  border-top: 1px solid #efefef;
}
@media screen and (max-width: 640px) {
  .c-table2 {
    width: 100% !important;
    border-top: 1px solid #b2b2b2;
    font-size: 1.4rem;
  }
}
.c-table2 dl {
  display: flex;
}
@media screen and (max-width: 640px) {
  .c-table2 dl {
    display: block;
    border: 1px solid #b2b2b2;
    border-top: none;
  }
}
.c-table2 dt {
  display: flex;
  align-items: center;
  width: 300px;
  background: #f3f3f3;
  position: relative;
  padding: 13px 0 11px 22px;
  margin-bottom: 1px;
}
.c-table2 dt label, .c-table2 dt span {
  font-weight: bold;
  color: #111111;
}
@media screen and (max-width: 640px) {
  .c-table2 dt label, .c-table2 dt span {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 640px) {
  .c-table2 dt {
    width: 100% !important;
    padding: 11px 14px !important;
  }
}
.c-table2 dd {
  overflow: hidden;
  width: calc(100% - 280px);
  padding: 12px 0 12px 21px;
  border-bottom: 1px solid #efefef;
}
.c-table2 dd ul.l-flex {
  display: flex;
}
@media screen and (max-width: 640px) {
  .c-table2 dd {
    width: 100% !important;
    padding: 13px 14px !important;
    border-bottom: none;
  }
  .c-table2 dd ul.l-flex {
    display: block;
  }
}
.c-table2 input {
  padding: 0 10px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  height: 38px;
  color: #111111;
}
.c-table2 input::placeholder {
  color: #999999;
}
@media screen and (max-width: 640px) {
  .c-table2 input {
    height: auto;
    padding: 10px;
  }
}
.c-table2 textarea {
  width: 600px;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  height: 120px;
  color: #232934;
}
.c-table2 textarea::placeholder {
  color: #999999;
}
@media screen and (max-width: 640px) {
  .c-table2 textarea {
    width: 100%;
  }
}
.c-table2--require {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff !important;
  background: #f22727;
  padding: 0 5px;
  line-height: 1.6;
  border-radius: 3px;
}
@media screen and (max-width: 640px) {
  .c-table2--require {
    font-size: 0.9rem;
    position: static;
    transform: none;
    margin-left: 10px;
  }
}
.c-table2__icon {
  display: inline-block;
}
.c-table2--note1 {
  margin-top: 6px;
  font-size: 1.4rem;
  color: #999999;
  line-height: 1.4;
  display: block;
}
@media screen and (max-width: 640px) {
  .c-table2--note1 {
    margin: 8px 0 0;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.6;
  }
}
.c-table2--ttl1 {
  color: #232934;
}
@media screen and (max-width: 640px) {
  .c-table2--ttl1 {
    width: 13%;
  }
}
.c-table2__radio, .c-table2__radio2, .c-table2__radio3 {
  margin: 7px 0 5px;
  color: #111111;
}
@media screen and (max-width: 640px) {
  .c-table2__radio, .c-table2__radio2, .c-table2__radio3 {
    margin: -2px 0 -1px;
  }
}
.c-table2__radio li, .c-table2__radio2 li, .c-table2__radio3 li {
  display: inline-block;
  margin-right: 21px;
}
.c-table2__radio li:last-of-type, .c-table2__radio2 li:last-of-type, .c-table2__radio3 li:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-table2__radio li, .c-table2__radio2 li, .c-table2__radio3 li {
    margin-bottom: 7px;
    display: block;
  }
  .c-table2__radio li:last-of-type, .c-table2__radio2 li:last-of-type, .c-table2__radio3 li:last-of-type {
    margin-bottom: 0;
  }
}
.c-table2__radio--2 > li, .c-table2__radio2--2 > li, .c-table2__radio3--2 > li {
  margin-bottom: 10px;
  display: list-item;
}
.c-table2__radio--2 input[type=text], .c-table2__radio2--2 input[type=text], .c-table2__radio3--2 input[type=text] {
  display: inline-block;
  position: static;
}
.c-table2__radio input, .c-table2__radio2 input, .c-table2__radio3 input {
  position: absolute;
  display: none;
}
.c-table2__radio input + label, .c-table2__radio2 input + label, .c-table2__radio3 input + label {
  position: relative;
  display: block;
  padding-left: 23px;
  cursor: pointer;
  vertical-align: middle;
}
.c-table2__radio input + label:hover:before, .c-table2__radio2 input + label:hover:before, .c-table2__radio3 input + label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}
.c-table2__radio input + label:before, .c-table2__radio2 input + label:before, .c-table2__radio3 input + label:before {
  position: absolute;
  top: 48%;
  left: 0;
  transform: translateY(-50%);
  display: inline-block;
  width: 14px;
  height: 14px;
  content: "";
  border: 1px solid #58595B;
}
@media screen and (max-width: 640px) {
  .c-table2__radio input + label:before, .c-table2__radio2 input + label:before, .c-table2__radio3 input + label:before {
    top: 11px;
  }
}
.c-table2__radio input + label:after, .c-table2__radio2 input + label:after, .c-table2__radio3 input + label:after {
  position: absolute;
  display: none;
  content: "";
}
.c-table2__radio input[disabled] + label:hover:before, .c-table2__radio2 input[disabled] + label:hover:before, .c-table2__radio3 input[disabled] + label:hover:before {
  border: 1px solid #e4e4e4;
  animation-name: none;
}
.c-table2__radio input[disabled] + label:before, .c-table2__radio2 input[disabled] + label:before, .c-table2__radio3 input[disabled] + label:before {
  border-color: #e4e4e4;
}
.c-table2__radio input:checked + label:before, .c-table2__radio2 input:checked + label:before, .c-table2__radio3 input:checked + label:before {
  animation-name: none;
  background: #9ac985;
  border: 1px solid #9ac985 !important;
}
.c-table2__radio input:checked + label:after, .c-table2__radio2 input:checked + label:after, .c-table2__radio3 input:checked + label:after {
  display: block;
}
.c-table2__radio input + label:before, .c-table2__radio2 input + label:before, .c-table2__radio3 input + label:before {
  border-radius: 50%;
}
.c-table2__radio input + label:after, .c-table2__radio2 input + label:after, .c-table2__radio3 input + label:after {
  top: 48%;
  left: 4px;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
}
@media screen and (max-width: 640px) {
  .c-table2__radio input + label:after, .c-table2__radio2 input + label:after, .c-table2__radio3 input + label:after {
    top: 11px;
  }
}
.c-table2__radio input:checked[disabled] + label:before, .c-table2__radio2 input:checked[disabled] + label:before, .c-table2__radio3 input:checked[disabled] + label:before {
  border: 1px solid #c9e2f9;
}
.c-table2__radio input:checked[disabled] + label:after, .c-table2__radio2 input:checked[disabled] + label:after, .c-table2__radio3 input:checked[disabled] + label:after {
  background: #c9e2f9;
}
.c-table2__radio3 li {
  display: block;
}
.c-table2__radio3 input + label:before {
  top: 13px;
}
@media screen and (max-width: 640px) {
  .c-table2__radio3 input + label:before {
    top: 11px;
  }
}
.c-table2__radio3 input:checked + label:before {
  background: #af8ebe;
  border: 1px solid #af8ebe !important;
}
.c-table2__radio3 input + label:after {
  top: 13px;
}
@media screen and (max-width: 640px) {
  .c-table2__radio3 input + label:after {
    top: 11px;
  }
}
.c-table2__checkbox01 li {
  float: left;
  margin-right: 25px;
}
.c-table2__checkbox02 {
  overflow: hidden;
}
.c-table2__checkbox02 li {
  font-weight: normal;
  margin-right: 40px;
  float: left;
}
@media screen and (max-width: 640px) {
  .c-table2__checkbox02 {
    padding-bottom: 0;
  }
  .c-table2__checkbox02 li {
    width: 100%;
    float: none;
  }
}
.c-table2 .u-style01 {
  margin-top: 18px;
  border-top: 1px solid #efefef;
}
@media screen and (max-width: 640px) {
  .c-table2 .u-style01 {
    margin-top: 15px;
    border-top: 1px solid #b2b2b2;
  }
}
.c-table2 .u-style02 .c-table2--note2 {
  margin-top: 6px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #f22727;
}
@media screen and (max-width: 640px) {
  .c-table2 .u-style02 .c-table2--note2 {
    font-size: 1.1rem;
  }
}
.c-table2 .u-style02 dt {
  background: rgba(242, 39, 39, 0.2);
}
.c-table2 .u-style02 input {
  background: rgba(242, 39, 39, 0.2);
}

@media screen and (max-width: 640px) {
  .c-table3 dl {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D9D9D9;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 641px) {
  .c-table3 dl {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #D9D9D9;
    display: grid;
    grid-template-columns: 400px 1fr;
    font-size: 1.8rem;
  }
}
.c-table3 dt {
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-table3 dt {
    padding: 10px 0;
  }
}
@media screen and (min-width: 641px) {
  .c-table3 dt {
    padding: 25px 30px;
  }
}
.c-table3 dt label {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
@media screen and (min-width: 641px) {
  .c-table3 dt label {
    column-gap: 20px;
  }
}
@media screen and (max-width: 640px) {
  .c-table3 dd {
    padding-top: 15px;
  }
}
.c-table3--require, .c-table3--any {
  padding-left: 2.5px;
  padding-right: 2.5px;
  border-radius: 1px;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 15px;
}
@media screen and (min-width: 641px) {
  .c-table3--require, .c-table3--any {
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 2px;
    font-size: 1.2rem;
    line-height: 20px;
  }
}
.c-table3--require {
  background-color: #F43;
  color: #fff;
}
.c-table3--any {
  background-color: #888;
  color: #fff;
}
.c-table3--question {
  min-width: 21px;
  width: 21px;
  height: 21px;
}
@media screen and (min-width: 641px) {
  .c-table3--question {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
}
.c-table3--question svg {
  width: 100%;
  height: 100%;
}
.c-table3--note1 {
  margin-top: 5px;
  display: flex;
  column-gap: 2.5px;
  align-items: baseline;
  font-size: 1.2rem;
}
.c-table3--note1::before {
  content: "※";
}
@media screen and (min-width: 641px) {
  .c-table3--note1 {
    margin-top: 10px;
    font-size: 1.4rem;
  }
}
.c-table3--errorMessage {
  margin-top: 15px;
  color: #FF4433;
  font-size: 1.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-table3--errorMessage {
    margin-top: 20px;
    font-size: 1.8rem;
  }
}

.c-table4 dl {
  font-size: 1.4rem;
}
.c-table4 dl:last-of-type {
  border-bottom: 1px solid #D9D9D9;
}
@media screen and (min-width: 641px) {
  .c-table4 dl {
    border: 1px solid #D9D9D9;
    border-bottom: none;
    display: grid;
    grid-template-columns: 400px 1fr;
    font-size: 1.8rem;
  }
}
.c-table4 dt, .c-table4 dd {
  padding: 15px 20px;
}
@media screen and (min-width: 641px) {
  .c-table4 dt, .c-table4 dd {
    padding: 20px 30px;
  }
}
.c-table4 dt {
  background-color: #EFEFEF;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-table4 dt {
    border: 1px solid #D9D9D9;
  }
}
@media screen and (min-width: 641px) {
  .c-table4 dt {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 640px) {
  .c-table4 dd {
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
  }
}
/*------------------------------------------------------------
line
------------------------------------------------------------*/
.c-lineDotted01 {
  width: 100%;
  height: 0;
  margin: 20px 0;
  border: none;
  border-bottom: dotted 1px #4c4c4c;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
.c-article {
  margin-right: 10px;
  width: 280px;
  height: 300px;
  position: relative;
  padding: 6px 10px 10px 0;
}
.c-article:after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  z-index: -1;
  background: repeating-linear-gradient(-45deg, #fffbf8, #fffbf8 2px, #fee7d8 3px, #fee7d8 5px);
  content: "";
}
@media screen and (max-width: 640px) {
  .c-article {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}
.c-article:last-child {
  margin-right: 0;
}
.c-article__img {
  width: 266px;
  height: 150px;
  position: relative;
  margin-top: 10px;
}
.c-article__image img {
  width: 100%;
}
.c-article__type {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  color: #2579be;
  background: #fce14a;
  position: absolute;
  margin: -5px 0 0 10px;
  padding: 1px 7px;
}
@media screen and (max-width: 640px) {
  .c-article__type {
    font-size: 1rem;
    padding: 1px 9px;
  }
}
.c-article__content {
  background: #fffde0;
  padding: 17px 12px 17px 18px;
}
@media screen and (max-width: 640px) {
  .c-article__content {
    width: 100%;
    height: auto;
    padding: 11px 15px 11px;
  }
}
.c-article__date {
  font-size: 1.4rem;
  font-weight: normal;
  color: #80807a;
}
@media screen and (max-width: 640px) {
  .c-article__date {
    font-size: 1.2rem;
  }
}
.c-article__title {
  color: #253b73;
  font-size: 1.6rem;
  padding-top: 5px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .c-article__title {
    font-size: 1.3rem;
    line-height: 19px;
  }
}
.c-article > a {
  display: block;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-article > a {
    height: 100%;
  }
}
.c-article > a:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  border: solid 3px #ff957d;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.c-article > a:hover {
  opacity: 1;
}
.c-article > a:hover:before {
  opacity: 1;
}
.c-article > a:hover .c-article__title {
  color: #ff957d;
}

.c-slide1 {
  width: 840px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .c-slide1 {
    width: 100%;
  }
}
.c-slide1 .slick-dots {
  margin-right: 54px;
}
@media screen and (max-width: 640px) {
  .c-slide1 .slick-dots {
    margin-right: 26px;
  }
}
.c-slide1 .slick-dots li {
  margin: 0;
}
@media screen and (max-width: 640px) {
  .c-slide1 .slick-dots li {
    width: 10px;
    height: 10px;
  }
}
.c-slide1 .slick-dots li button {
  padding: 0;
}
@media screen and (max-width: 640px) {
  .c-slide1 .slick-dots li button {
    width: 10px;
    height: 10px;
  }
}
.c-slide1 .slick-dots li button:before {
  font-size: 12px;
}
@media screen and (max-width: 640px) {
  .c-slide1 .slick-dots li button:before {
    font-size: 10px;
    width: 10px;
    height: 10px;
    top: -7px;
  }
}
.c-slide1 .slick-dots li.slick-active button:before {
  color: #82cdf3;
}
.c-slide1 .slick-dots li:nth-child(even) {
  margin: 0 4px;
}
@media screen and (max-width: 640px) {
  .c-slide1 .slick-dots li:nth-child(even) {
    margin: 0 8px;
  }
}
@media screen and (max-width: 640px) {
  .c-slide1 .slick-track {
    display: flex;
  }
}
@media screen and (max-width: 640px) {
  .c-slide1 .slick-track .slick-slide {
    display: flex;
    flex-direction: column;
    float: none;
  }
}
.c-slide1 .slider-nav {
  margin-bottom: 24px;
}
@media screen and (max-width: 640px) {
  .c-slide1 .slider-nav {
    margin-bottom: 15px;
  }
}
.c-slide1 .slider-board {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-slide1 .slider-board .slider-prev, .c-slide1 .slider-board .slider-next {
  cursor: pointer;
}
.c-slide1 .slider-board .slick-dots {
  position: static;
  bottom: auto;
}

@media screen and (min-width: 641px) {
  .c-slide2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
}
.c-slide2 .slick-slider {
  margin-bottom: 0;
}
@media screen and (min-width: 641px) {
  .c-slide2 .slick-list {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.c-slide2 .slick-track {
  display: flex;
}
.c-slide2 .slick-slide {
  height: auto;
}
.c-slide2__item {
  margin-left: 7.5px;
  margin-right: 7.5px;
  width: 150px;
  height: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  overflow: hidden;
  display: block;
  color: #000;
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .c-slide2__item {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    border-width: 3px;
    border-radius: 10px;
  }
}
.c-slide2__item__image {
  aspect-ratio: 300/200;
}
@media screen and (min-width: 641px) {
  .c-slide2__item__image {
    aspect-ratio: 373.33331/180;
  }
}
.c-slide2__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.c-slide2__item__content {
  padding: 10px;
}
@media screen and (min-width: 641px) {
  .c-slide2__item__content {
    padding: 15px 20px;
  }
}
.c-slide2__item__type {
  padding: 5px;
  width: fit-content;
  background-color: #E4F7FF;
  border-radius: 5px;
  color: #41B4E6;
  font-size: 1rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .c-slide2__item__type {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.2rem;
  }
}
.c-slide2__item__title {
  margin-top: 5px;
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .c-slide2__item__title {
    margin-top: 10px;
    font-size: 1.6rem;
  }
}
.c-slide2 .slider-board {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
}
@media screen and (min-width: 641px) {
  .c-slide2 .slider-board {
    margin-top: 20px;
    column-gap: 80px;
  }
}
.c-slide2 .slick-dots {
  display: flex;
  column-gap: 10px;
  position: static;
  width: auto;
  list-style: none;
}
@media screen and (min-width: 641px) {
  .c-slide2 .slick-dots {
    column-gap: 20px;
  }
}
.c-slide2 .slick-dots li {
  margin: 0;
  width: 10px;
  height: 10px;
}
@media screen and (min-width: 641px) {
  .c-slide2 .slick-dots li {
    width: 12px;
    height: 12px;
  }
}
.c-slide2 .slick-dots .slick-active button {
  background-color: #41B4E6;
}
.c-slide2 .slick-dots button {
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #D9D9D9;
  border: none;
  border-radius: 50%;
  text-indent: -9999px;
  overflow: hidden;
}
.c-slide2 .slick-dots button:before {
  display: none;
}
.c-slide2 .slider-prev, .c-slide2 .slider-next {
  width: 42px;
  height: 42px;
  background-color: #fff;
  border: 2px solid #41B4E6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-slide2 .slider-prev svg, .c-slide2 .slider-next svg {
  width: 18px;
  height: 18px;
  display: block;
  color: #41B4E6;
}
@media screen and (min-width: 641px) {
  .c-slide2 .slider-prev, .c-slide2 .slider-next {
    width: 56px;
    height: 56px;
    border-width: 3px;
    transition: 0.3s ease-in-out;
  }
  .c-slide2 .slider-prev svg, .c-slide2 .slider-next svg {
    width: 28px;
    height: 28px;
  }
  .c-slide2 .slider-prev:hover, .c-slide2 .slider-next:hover {
    background-color: #41B4E6;
    border-color: #41B4E6;
  }
  .c-slide2 .slider-prev:hover svg, .c-slide2 .slider-next:hover svg {
    color: #fff;
  }
}

/*------------------------------------------------------------
other
------------------------------------------------------------*/
/* オーバーレイ
------------------------------------------------------------*/
.c-overlay01 {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999;
}

.c-overlay02 {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

/* 保険 sp menu modal-overlay
------------------------------------------------------------*/
.c-overlay03 {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
}
.c-overlay03.is-open2 {
  display: block;
}

/* チケット　ページャー
------------------------------------------------------------*/
.c-ticketPager01 {
  text-align: center;
  position: relative;
}
.c-ticketPager01 a {
  display: inline-flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: #4b5870;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 5px;
  line-height: 1;
  background-color: #fff;
}
.c-ticketPager01 .ticketPager01-js {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-ticketPager01 .jp-previous,
.c-ticketPager01 .jp-next {
  font-size: 0;
  color: #fff;
}
.c-ticketPager01 .jp-previous {
  background: #fff url("../img/common/icon_arrow-gray03.png") no-repeat center;
  background-size: 10px;
  transform: rotate(180deg);
}
.c-ticketPager01 .jp-next {
  background: #fff url("../img/common/icon_arrow-gray03.png") no-repeat center;
  background-size: 10px;
}
.c-ticketPager01 .jp-current {
  color: #fff;
  background-color: #fc819b;
  cursor: auto;
}
.c-ticketPager01 .jp-disabled {
  cursor: auto;
}
.c-ticketPager01 #ticketPager01-js {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.6rem;
  font-weight: 500;
  color: #4b5870;
  margin-top: 6px;
}

@media screen and (max-width: 640px) {
  .c-ticketPager01 a {
    display: none;
  }
  .c-ticketPager01 .jp-previous,
  .c-ticketPager01 .jp-next {
    display: inline-flex;
    width: 30px;
    height: 30px;
    background-size: 20px;
  }
  .c-ticketPager01 .jp-previous {
    margin-right: 65px;
  }
  .c-ticketPager01 .jp-next {
    margin-left: 65px;
  }
  .c-ticketPager01 #ticketPager01-js {
    display: block;
  }
}
/* くらしのサービス　ページャー
------------------------------------------------------------*/
.c-kurashiPager01 {
  text-align: center;
  position: relative;
}
.c-kurashiPager01 a {
  display: inline-flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: #6c727c;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 5px;
  line-height: 1;
  background-color: #fff;
}
.c-kurashiPager01 .kurashiPager01-js {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-kurashiPager01 .jp-previous,
.c-kurashiPager01 .jp-next {
  width: 40px;
  height: 40px;
  font-size: 0;
  color: #fff;
}
.c-kurashiPager01 .jp-previous {
  background-image: url("../img/common/icon_maru-sankaku01.png");
  background-size: cover;
  transform: rotate(180deg);
}
.c-kurashiPager01 .jp-next {
  background-image: url("../img/common/icon_maru-sankaku01.png");
  background-size: cover;
}
.c-kurashiPager01 .jp-current {
  color: #fff;
  background-color: #faad5a;
  cursor: auto;
}
.c-kurashiPager01 .jp-disabled {
  cursor: auto;
}
.c-kurashiPager01 #kurashiPager01-js {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.6rem;
  font-weight: 500;
  color: #594242;
  margin-top: 6px;
}

@media screen and (max-width: 640px) {
  .c-kurashiPager01 a {
    display: none;
  }
  .c-kurashiPager01 .jp-previous,
  .c-kurashiPager01 .jp-next {
    display: inline-flex;
    width: 30px;
    height: 30px;
  }
  .c-kurashiPager01 .jp-previous {
    margin-right: 60px;
  }
  .c-kurashiPager01 .jp-next {
    margin-left: 60px;
  }
  .c-kurashiPager01 #kurashiPager01-js {
    display: block;
  }
}
/* 葬祭　ページャー
------------------------------------------------------------*/
.c-sosaiPager01 {
  text-align: center;
}
.c-sosaiPager01 a {
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: #2e95a3;
  background: #fff;
  cursor: pointer;
  margin: 0 2px;
  line-height: 1;
  border: solid 1px #2e95a3;
}
.c-sosaiPager01 .sosaiPager01-js {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-sosaiPager01 .jp-previous,
.c-sosaiPager01 .jp-next {
  width: 80px;
  height: 40px;
  font-size: 0;
  color: #fff;
}
.c-sosaiPager01 .jp-previous {
  background: #fff url("../img/component/btn02.png") no-repeat center;
  background-size: contain;
}
.c-sosaiPager01 .jp-next {
  background: #fff url("../img/component/btn03.png") no-repeat center;
  background-size: contain;
}
.c-sosaiPager01 .jp-current {
  color: #ffffff;
  background-color: #3b424c;
  border-color: #3b424c;
  cursor: auto;
}
.c-sosaiPager01 .jp-disabled {
  cursor: auto;
}

@media screen and (max-width: 640px) {
  .c-sosaiPager01 a {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }
  .c-sosaiPager01 .jp-previous,
  .c-sosaiPager01 .jp-next {
    width: 70px;
    height: 35px;
  }
}
/* 保険 sp menu modal
------------------------------------------------------------*/
.c-hokenModal {
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 102;
}
.c-hokenModal.is-open2 {
  display: block;
}

/* 保険商品選択
------------------------------------------------------------*/
.c-hokenItemSerect {
  font-size: 1.6rem;
}
.c-hokenItemSerect .c-hokenItemCheckAll {
  font-weight: 500;
  width: 50%;
  color: #253b73;
}
.c-hokenItemSerect .c-hokenItemCheckAll label {
  cursor: pointer;
  letter-spacing: 0.8px;
}
.c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] {
  display: none;
}
.c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
  width: 16px;
  height: 16px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/common/chekbox01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox]:checked + span:before {
  background-image: url("../img/common/chekbox01_checked.png");
}
.c-hokenItemSerect__request {
  width: 50%;
  text-align: right;
  color: #46483c;
  letter-spacing: -0.4em;
}
.c-hokenItemSerect__request > * {
  display: inline-block;
  letter-spacing: normal;
}
.c-hokenItemSerect__request span {
  vertical-align: middle;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.c-hokenItemSerect__request a {
  width: 221px;
  height: 50px;
  margin-left: 10px;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.9;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  background-image: url("../img/hoken/bg01.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}
.c-hokenItemSerect__request a:before {
  width: 30px;
  height: 30px;
  margin: -5px 15px 0 -9px;
  content: "";
  background-color: #ffffff;
  background-image: url("../img/common/icon_file02.png");
  -webkit-background-size: 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.c-hokenItemSerect__items {
  margin-top: 17px;
  position: relative;
  z-index: 10;
}
.c-hokenItemSerect__item {
  margin-bottom: 20px;
  padding: 40px 40px 30px;
  border: 1px solid #dddddd;
  position: relative;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-hokenItemSerect__item:before {
  width: 224px;
  height: 3px;
  content: "";
  position: absolute;
  background-color: #82cdf3;
  top: 0;
  left: 40px;
}
.c-hokenItemSerect__item__img {
  width: 223px;
  margin-right: 28px;
  text-align: center;
  position: relative;
  z-index: 10;
}
.c-hokenItemSerect__item__btn {
  display: flex;
}
.c-hokenItemSerect__item__btn .c-hokenItemSerect__item__request {
  margin-right: 15px;
}
.c-hokenItemSerect__item__btn .c-hokenItemSerect__item__request:last-child {
  margin-right: 0;
}
.c-hokenItemSerect__item__btn02 {
  justify-content: center;
}
.c-hokenItemSerect__item__nameBox {
  width: 505px;
  position: relative;
  z-index: 10;
}
.c-hokenItemSerect__item__nameBox .c-hokenItemSerect__boxTitle {
  margin: 22px 0 5px;
  color: #253b73;
  font-weight: bold;
  line-height: 1.4;
  display: flex;
  flex-wrap: wrap;
}
.c-hokenItemSerect__item__nameBox .c-hokenItemSerect__boxTitle__pTtl {
  width: 350px;
  font-size: 1.6rem;
  display: block;
  margin-top: -22px;
}
.c-hokenItemSerect__item__nameBox .c-hokenItemSerect__boxTitle__pName {
  font-size: 2rem;
  width: 100%;
}
.c-hokenItemSerect__item__nameBox .c-hokenItemSerect__boxTitle__pName:hover {
  text-decoration: underline;
}
@media screen and (max-width: 640px) {
  .c-hokenItemSerect__item__nameBox .c-hokenItemSerect__boxTitle {
    font-size: 1.6rem;
    margin: 0 0 5px;
  }
  .c-hokenItemSerect__item__nameBox .c-hokenItemSerect__boxTitle__pTtl {
    width: 100%;
    font-size: 1.3rem;
    margin-top: 0;
  }
  .c-hokenItemSerect__item__nameBox .c-hokenItemSerect__boxTitle__pName {
    font-size: 1.6rem;
  }
}
.c-hokenItemSerect__item__tags {
  margin-bottom: 5px;
  letter-spacing: -0.4em;
}
.c-hokenItemSerect__item__request a {
  width: 160px;
  height: 40px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4;
  color: #ffffff;
  text-align: center;
  display: block;
  color: #ff5b4c;
  background-color: #ffffff;
  border: solid 2px #ff5b4c;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
}
.c-hokenItemSerect__item__request a:before {
  width: 24px;
  height: 24px;
  margin: -4px 20px 0 -22px;
  content: "";
  background-color: #ff5b4c;
  background-image: url("../img/common/icon_file01.png");
  -webkit-background-size: 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.c-hokenItemSerect__item__request--internet a {
  padding-left: 20px;
  color: #007a50;
  border: solid 2px #007a50;
}
.c-hokenItemSerect__item__request--internet a:before {
  margin: -4px 7px 0 -22px;
  background-color: #007a50;
  background-image: url("../img/common/icon_mail02.png");
}
.c-hokenItemSerect__item__txt {
  margin-top: 25px;
  color: #46483c;
  position: relative;
  z-index: 10;
  width: 100%;
}
.c-hokenItemSerect__item__txt p {
  line-height: 1.8;
  font-weight: 500;
}
.c-hokenItemSerect__item__txt__hokengaisya {
  margin-top: 6px;
  color: #999999;
  line-height: 1.5;
}
.c-hokenItemSerect__item__txt__number {
  color: #999999;
  line-height: 1;
  font-weight: 300;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.c-hokenItemSerect__item .c-hokenItemCheck {
  font-size: 1.4rem;
  font-weight: 600;
  color: #46483c;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 20;
}
.c-hokenItemSerect__item .c-hokenItemCheck label {
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .c-hokenItemSerect__item .c-hokenItemCheck label {
    font-size: 1.1rem;
  }
}
.c-hokenItemSerect__item .c-hokenItemCheck input[type=checkbox] {
  display: none;
}
.c-hokenItemSerect__item .c-hokenItemCheck input[type=checkbox] + span:after {
  width: 16px;
  height: 16px;
  margin: -5px 0 0 10px;
  content: "";
  background-image: url("../img/common/chekbox01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-hokenItemSerect__item .c-hokenItemCheck input[type=checkbox]:checked + span:after {
  background-image: url("../img/common/chekbox01_checked.png");
}
.c-hokenItemSerect .c-hokenItemSerect__icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.c-hokenItemSerect .c-hokenItemSerect__icon .c-hokenItemSerect__icon--osusume {
  width: 100%;
  height: 100%;
  background-image: url("../img/hoken/bg08.jpg");
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-hokenItemSerect .c-hokenItemSerect__icon .c-hokenItemSerect__icon--osusume:before {
  content: "";
  position: relative;
  background-color: #fffdeb;
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  left: 5px;
  top: 5px;
}
@media screen and (max-width: 640px) {
  .c-hokenItemSerect .c-hokenItemSerect__icon .c-hokenItemSerect__icon--osusume:before {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    left: 2px;
    top: 2px;
  }
}
.c-hokenItemSerect .c-hokenItemSerect__icon .c-hokenItemSerect__icon--osusume img {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 20;
}
@media screen and (max-width: 640px) {
  .c-hokenItemSerect .c-hokenItemSerect__icon .c-hokenItemSerect__icon--osusume img {
    transform: scale(0.6);
    top: -17px;
    left: -35px;
  }
}

@media screen and (max-width: 640px) {
  .c-hokenItemSerect {
    font-size: 1.4rem;
  }
  .c-hokenItemSerect .c-hokenItemCheckAll {
    width: 100%;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  .c-hokenItemSerect__request {
    width: 100%;
    text-align: left;
  }
  .c-hokenItemSerect__request a {
    width: 158px;
    height: 30px;
    font-size: 1.3rem;
    line-height: 32px;
    margin-left: 5px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }
  .c-hokenItemSerect__request a:before {
    width: 20px;
    height: 20px;
    margin: -3px 10px 0 -3px;
  }
  .c-hokenItemSerect__request span {
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    line-height: 14px;
  }
  .c-hokenItemSerect__items {
    margin-top: 30px;
  }
  .c-hokenItemSerect__item {
    margin-bottom: 15px;
    padding: 36px 20px 19px;
  }
  .c-hokenItemSerect__item:before {
    width: calc(100% - 40px);
    left: 0;
    margin: 0 20px;
  }
  .c-hokenItemSerect__item__img {
    width: auto;
    margin: 0 auto 10px auto;
  }
  .c-hokenItemSerect__item__nameBox {
    width: 100%;
    margin-bottom: 10px;
  }
  .c-hokenItemSerect__item__nameBox h2 {
    font-size: 1.8rem;
  }
  .c-hokenItemSerect__item__nameBox h2 span {
    font-size: 1.4rem;
  }
  .c-hokenItemSerect__item__tag {
    height: 15px;
    font-size: 0.9rem;
    margin: 0 5px 2px 0;
  }
  .c-hokenItemSerect__item__txt {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  .c-hokenItemSerect__item__btn02 {
    flex-wrap: wrap;
  }
  .c-hokenItemSerect__item__btn02 .c-hokenItemSerect__item__request {
    width: 100%;
    margin-right: 0;
    margin-top: 10px !important;
  }
  .c-hokenItemSerect__item__request a {
    width: 150px;
    height: 30px;
    font-size: 1.3rem;
    line-height: 30px;
    margin: 10px auto 0;
    border: solid 1px #ff5b4c;
  }
  .c-hokenItemSerect__item__request a:before {
    width: 20px;
    height: 20px;
    margin: -4px 20px 0 -20px;
  }
  .c-hokenItemSerect__item__request--internet a {
    border: solid 1px #007a50;
  }
  .c-hokenItemSerect__item__request--internet a:before {
    margin: -4px 14px 0 -20px;
  }
  .c-hokenItemSerect__item .c-hokenItemCheck {
    top: 11px;
    right: 20px;
  }
}
/* 保険　ページ下部申し込みブロック
------------------------------------------------------------*/
.c-hokenMoushikomi {
  padding: 35px 45px 39px;
  background-image: url("../img/hoken/bg07.jpg");
  position: relative;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-hokenMoushikomi:before {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  content: "";
  background-color: #ffffff;
  position: absolute;
  top: 4px;
  left: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.c-hokenMoushikomi h2 {
  margin-bottom: 32px;
  font-size: 2.4rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: center;
  color: #46483c;
  position: relative;
  z-index: 10;
}
.c-hokenMoushikomi h2:after {
  width: 80px;
  height: 6px;
  margin: 10px auto 0 auto;
  content: "";
  background-image: url("../img/hoken/line01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
}
.c-hokenMoushikomi__btn {
  margin-bottom: 24px;
  position: relative;
  z-index: 10;
}
.c-hokenMoushikomi__btn .c-hokenBtn03,
.c-hokenMoushikomi__btn .c-hokenBtn04 {
  display: table;
  vertical-align: middle;
}
.c-hokenMoushikomi__btn .c-hokenBtn03 a,
.c-hokenMoushikomi__btn .c-hokenBtn04 a {
  font-weight: 600;
  display: inline-flex;
}
.c-hokenMoushikomi__btn .c-hokenBtn03.fixed-center a,
.c-hokenMoushikomi__btn .c-hokenBtn04.fixed-center a {
  padding-top: 10px;
}
.c-hokenMoushikomi__btn .c-hokenBtn03 a {
  padding: 0;
}
.c-hokenMoushikomi__btn .c-hokenBtn03 a:before {
  margin: 0 5px 0 -25px;
  position: unset;
}
@media screen and (max-width: 640px) {
  .c-hokenMoushikomi__btn .c-hokenBtn03 a {
    padding-left: 22px !important;
  }
  .c-hokenMoushikomi__btn .c-hokenBtn03 a:before {
    margin: 0 10px 0 -2px;
  }
}
.c-hokenMoushikomi p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
  color: #46483c;
  position: relative;
  z-index: 10;
}
.c-hokenMoushikomi p a {
  margin: 6px 0 9px;
  color: #253b73;
  display: inline-block;
}
.c-hokenMoushikomi p a:hover {
  opacity: 1;
  color: #ff957d;
  text-decoration: underline;
}
.c-hokenMoushikomi p a:before {
  width: 5px;
  height: 11px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue02.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-hokenMoushikomi p .c-hokenMoushikomi__annotation {
  display: block;
  font-size: 1.4rem;
  line-height: 1.5;
}

@media screen and (max-width: 640px) {
  .c-hokenMoushikomi {
    padding: 17px 20px 16px;
  }
  .c-hokenMoushikomi h2 {
    margin-bottom: 20px;
    font-size: 1.9rem;
    line-height: 25px;
  }
  .c-hokenMoushikomi__btn {
    margin-bottom: 10px;
  }
  .c-hokenMoushikomi__btn div {
    margin: 0 auto 10px auto;
  }
  .c-hokenMoushikomi p {
    margin-top: 16px;
    font-size: 1.3rem;
    line-height: 19px;
  }
  .c-hokenMoushikomi p a {
    text-decoration: underline;
  }
  .c-hokenMoushikomi p .c-hokenMoushikomi__annotation {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.6;
  }
}
/* リフォーム　関連リンク
------------------------------------------------------------*/
.c-reformKanrenLink li {
  width: 273px;
  padding: 10px;
  margin: 0 10px 15px 0;
  background-color: #f5f5f5;
  border: solid 1px #dddddd;
}
.c-reformKanrenLink li:nth-child(3n) {
  margin: 0 0 15px 0;
}
.c-reformKanrenLink li img {
  width: 100%;
  height: 126px;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
}
.c-reformKanrenLink li a {
  margin: 26px 0 15px 10px;
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  color: #141414;
}
.c-reformKanrenLink li a:before {
  width: 6px;
  height: 10px;
  margin: 0 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow_red.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 640px) {
  .c-reformKanrenLink li {
    width: 100%;
    padding: 10px;
    margin: 0 4% 15px 0 !important;
  }
  .c-reformKanrenLink li:nth-child(2n) {
    margin: 0 0 15px 0 !important;
  }
  .c-reformKanrenLink li a {
    margin: 10px 0 0 0;
    font-size: 1.2rem;
  }
}
/* 葬祭　お葬式受付専用ダイヤル
------------------------------------------------------------*/
.c-sosaiUketsuke {
  width: 100%;
  padding: 45px 60px 53px;
  color: #111111;
  background-image: url("../img/sosai/uketuke_bg_pc.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  border: solid 2px #d2d2d2;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke {
    padding: 20px;
    background-image: url("../img/sosai/uketuke_bg_sp.jpg");
  }
}
.c-sosaiUketsuke__title {
  margin-bottom: 40px;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__title {
    margin-bottom: 20px;
    font-size: 2.4rem;
  }
}
.c-sosaiUketsuke__title span {
  width: 462px;
  height: 42px;
  margin: 2px auto 0 auto;
  font-size: 2.4rem;
  letter-spacing: 1.5px;
  color: #ffffff;
  background-color: #f2385a;
  display: block;
  -webkit-border-radius: 21px;
  border-radius: 21px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__title span {
    width: 100%;
    font-size: 1.5rem;
    height: 26px;
  }
}
.c-sosaiUketsuke__boxArea {
  width: 353px;
  margin-right: 20px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__boxArea {
    width: 100%;
    margin: 0;
  }
}
.c-sosaiUketsuke__box {
  width: 100%;
  padding: 34px 26px 24px;
  background-color: #fff;
  border-top: solid 2px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__box {
    margin-bottom: 15px;
    padding: 15px;
  }
}
.c-sosaiUketsuke__box--kanagawa {
  border-color: #00a384;
}
.c-sosaiUketsuke__box--yamanashi {
  border-color: #006da7;
}
.c-sosaiUketsuke__box--shizuoka {
  border-color: #931719;
}
.c-sosaiUketsuke__box + .c-sosaiUketsuke__box {
  margin-top: 18px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__box + .c-sosaiUketsuke__box {
    margin-top: 0;
  }
}
.c-sosaiUketsuke__box__subtitle {
  margin-bottom: 22px;
  padding-right: 90px;
  position: relative;
}
.c-sosaiUketsuke__box__subtitle h3 {
  font-size: 2.8rem;
  line-height: 1;
}
.c-sosaiUketsuke__box__subtitle h3 img {
  display: block;
  margin: 8px 0 0 3px;
}
.c-sosaiUketsuke__box__subtitle > img {
  position: absolute;
  top: -6px;
  right: 7px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__box__subtitle {
    margin-bottom: 15px;
    padding-right: 40%;
  }
  .c-sosaiUketsuke__box__subtitle h3 {
    font-size: 1.7rem;
  }
  .c-sosaiUketsuke__box__subtitle > img {
    width: 80px;
  }
}
.c-sosaiUketsuke__box__tel {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.25;
  margin: 2px 0 0 0;
}
.c-sosaiUketsuke__box__tel:before {
  width: 42px;
  height: 24px;
  margin: -10px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_free_dial.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-sosaiUketsuke__box__tel a {
  color: #111111;
  letter-spacing: 2.3px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__box__tel {
    font-size: 2.2rem;
  }
  .c-sosaiUketsuke__box__tel:before {
    width: 26px;
    height: 15px;
    margin: -5px 5px 0 0;
  }
}
.c-sosaiUketsuke__box ul li {
  margin-bottom: 8px;
  padding-bottom: 2px;
  border-bottom: dotted 1px #d2d2d2;
}
.c-sosaiUketsuke__box ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.c-sosaiUketsuke__box ul li p {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}
.c-sosaiUketsuke__box ul li p span {
  font-size: 1.3rem;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke__box ul li p {
    font-size: 1.3rem;
  }
  .c-sosaiUketsuke__box ul li p span {
    font-size: 1.1rem;
  }
}

/*
------------------------------------------------------------*/
/* 葬祭　お葬式受付専用ダイヤル2
------------------------------------------------------------*/
.c-sosaiUketsuke02 {
  width: 100%;
  height: 454px;
  background-image: url("../img/sosai/flow_tel_bg01.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  border: solid 2px #c8c8c8;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02 {
    height: auto;
    background-position: right 0;
  }
}
.c-sosaiUketsuke02 h2 {
  padding: 10px 40px;
  font-size: 3.4rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #f6738b;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02 h2 {
    padding: 10px 20px;
    font-size: 2.6rem;
    text-align: center;
  }
  .c-sosaiUketsuke02 h2 span {
    margin: 0 auto;
    padding: 1px 10px;
    font-size: 1.4rem;
    display: block;
  }
}
.c-sosaiUketsuke02 h2 span {
  margin: -5px 0 0 15px;
  padding: 1px 20px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #f2385a;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 1000px;
  border-radius: 1000px;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}
.c-sosaiUketsuke02 > img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.c-sosaiUketsuke02__boxArea {
  width: 810px;
  padding: 50px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02__boxArea {
    width: 100%;
    padding: 15px;
  }
}
.c-sosaiUketsuke02__boxArea > div {
  width: 50%;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02__boxArea > div {
    width: 100%;
    margin-bottom: 15px;
    padding: 0 !important;
    border: none !important;
  }
}
.c-sosaiUketsuke02__boxArea > div:nth-child(1) {
  padding-right: 25px;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02__boxArea > div:nth-child(1) .c-sosaiUketsuke02__box {
    border-bottom: dotted 1px #4c4c4c;
  }
}
.c-sosaiUketsuke02__boxArea > div:nth-child(2) {
  padding-left: 29px;
  border-left: dotted 1px #4c4c4c;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02__box {
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
}
.c-sosaiUketsuke02__box + .c-sosaiUketsuke02__box {
  margin-top: 20px;
  padding-top: 20px;
  border-top: dotted 1px #4c4c4c;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02__box + .c-sosaiUketsuke02__box {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
.c-sosaiUketsuke02__box h3 {
  margin-bottom: 5px;
  font-size: 2.6rem;
  font-weight: 500;
  color: #111111;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiUketsuke02__box h3:before {
  width: 30px;
  height: 30px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/sosai/icon_sosai01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-sosaiUketsuke02__box h3 span {
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02__box h3 {
    font-size: 2rem;
  }
  .c-sosaiUketsuke02__box h3:before {
    width: 20px;
    height: 20px;
  }
  .c-sosaiUketsuke02__box h3 span {
    font-size: 1.6rem;
  }
}
.c-sosaiUketsuke02__box ul li {
  margin-bottom: 10px;
}
.c-sosaiUketsuke02__box ul li:last-child {
  margin-bottom: 0;
}
.c-sosaiUketsuke02__box ul li p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333333;
}
.c-sosaiUketsuke02__box__tel {
  font-size: 4rem;
  line-height: 1.2;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiUketsuke02__box__tel:before {
  width: 28px;
  height: 28px;
  margin: -5px 5px 0 0;
  content: "";
  background-image: url("../img/common/icon_tel06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-sosaiUketsuke02__box__tel a {
  color: #111111;
}
@media screen and (max-width: 640px) {
  .c-sosaiUketsuke02__box__tel {
    font-size: 2.8rem;
  }
  .c-sosaiUketsuke02__box__tel:before {
    width: 20px;
    height: 20px;
  }
}

/* 葬祭　葬儀後のサービス 問い合わせ
------------------------------------------------------------*/
.c-sosaiAfterServiceContact {
  margin: 0 auto;
  text-align: center;
  display: table;
  border-collapse: collapse;
}
.c-sosaiAfterServiceContact > div {
  width: 560px;
  padding: 30px 30px 25px;
  display: table-cell;
  vertical-align: middle;
  border: solid 1px #c8c8c8;
}
.c-sosaiAfterServiceContact > div:not(:first-of-type) a {
  margin-top: 12px;
}
.c-sosaiAfterServiceContact > div:not(:first-of-type) a span {
  margin-left: -74px;
}
.c-sosaiAfterServiceContact__cate {
  width: 100px;
  height: 20px;
  margin: 0 auto 20px auto;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2rem;
  display: block;
}
.c-sosaiAfterServiceContact__cate--kanagawa {
  background-color: #00a384;
}
.c-sosaiAfterServiceContact__cate--yamanashi {
  background-color: #006da7;
}
.c-sosaiAfterServiceContact__cate--shizuoka {
  width: 90px;
  background-color: #931719;
}
.c-sosaiAfterServiceContact p {
  font-size: 2rem;
  line-height: 1;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiAfterServiceContact p span {
  display: block;
  font-size: 1.6rem;
  line-height: 1;
  padding-top: 10px;
}
.c-sosaiAfterServiceContact p span:first-of-type {
  margin-bottom: 10px;
  padding-top: 0;
}
.c-sosaiAfterServiceContact a {
  display: block;
  font-size: 3.6rem;
  line-height: 1;
  color: #111111;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  margin-top: 14px;
  letter-spacing: 1px;
  text-align: left;
}
.c-sosaiAfterServiceContact a > div {
  width: 360px;
  padding-left: 86px;
  margin: 0 auto;
  position: relative;
}
.c-sosaiAfterServiceContact a > div:before {
  content: "";
  width: 28px;
  height: 28px;
  background: url("../img/common/icon_tel06.png") no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  top: 3px;
  left: 51px;
}
.c-sosaiAfterServiceContact a span {
  display: block;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #2f2933;
  margin-left: -38px;
  margin-top: 6px;
  letter-spacing: 0.3px;
}

@media screen and (max-width: 640px) {
  .c-sosaiAfterServiceContact {
    display: block;
  }
  .c-sosaiAfterServiceContact > div {
    display: block;
    width: 100%;
    padding: 19px;
  }
  .c-sosaiAfterServiceContact > div:not(:first-of-type) > span {
    width: 57px;
  }
  .c-sosaiAfterServiceContact > div:not(:first-of-type) a {
    margin-top: 15px;
  }
  .c-sosaiAfterServiceContact__cate {
    width: 68px;
    height: 16px;
    font-size: 1rem;
    line-height: 1.6rem;
    padding-top: 1px;
    margin-bottom: 14px;
  }
  .c-sosaiAfterServiceContact p {
    font-size: 1.8rem;
  }
  .c-sosaiAfterServiceContact p span {
    font-size: 1.1rem;
    padding-top: 9px;
  }
  .c-sosaiAfterServiceContact a {
    margin-top: 14px;
    background-color: #2e95a3;
    border-radius: 3px;
    font-size: 1.8rem;
    color: #fff;
    font-family: YuGothic, "Yu Gothic", sans-serif;
    font-weight: bold;
    letter-spacing: 0;
    box-shadow: 0px 3px 0px #20656e;
  }
  .c-sosaiAfterServiceContact a > div {
    width: 240px;
    padding: 8px 0 8px 81px;
  }
  .c-sosaiAfterServiceContact a > div:before {
    left: 41px;
    top: calc(50% - 12px);
    background: url("../img/common/icon_tel07.png");
    background-size: cover;
    width: 25px;
    height: 25px;
  }
  .c-sosaiAfterServiceContact a span {
    display: none;
  }
  .c-sosaiAfterServiceContact a p {
    font-size: 1rem;
    line-height: 1.3rem;
    color: #fff;
    margin: 0 0 0 -8px;
    text-indent: 7px;
    font-family: YuGothic, "Yu Gothic", sans-serif;
  }
}
/* 葬祭　葬儀後のサービス リンク
------------------------------------------------------------*/
.c-sosaiAfterServiceLink {
  padding-top: 5px;
}
.c-sosaiAfterServiceLink li {
  width: 216px;
  margin-right: 10px;
  padding: 21px 10px 10px 10px;
  background-color: #ffffff;
  text-align: center;
  border-top: solid 2px #2e95a3;
  position: relative;
}
.c-sosaiAfterServiceLink li:last-child {
  margin-right: 0;
}
.c-sosaiAfterServiceLink li:after {
  width: 15px;
  height: 15px;
  content: "";
  background-image: url("../img/common/icon_button_sankaku02_blue.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.c-sosaiAfterServiceLink li a {
  font-size: 2.2rem;
  color: #2f2933;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.c-sosaiAfterServiceLink li a span {
  color: #2e95a3;
  font-size: 1.2rem;
  display: block;
  line-height: 1;
  font-family: "Garamond Classico";
}
@media screen and (max-width: 640px) {
  .c-sosaiAfterServiceLink li a span {
    font-size: 0.7rem;
  }
}
.c-sosaiAfterServiceLink li div {
  padding: 11px 0 29px 0;
}
.c-sosaiAfterServiceLink__label {
  margin: 0 1px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #ffffff;
  display: inline-block;
}
.c-sosaiAfterServiceLink__label--shizuoka {
  padding: 0 19px;
  background-color: #931719;
}
.c-sosaiAfterServiceLink__label--yamanashi {
  background-color: #006da7;
}
.c-sosaiAfterServiceLink__label--kanagawa {
  padding: 0 12px;
  background-color: #00a384;
}
@media screen and (max-width: 640px) {
  .c-sosaiAfterServiceLink__label {
    font-size: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .c-sosaiAfterServiceLink {
    margin-bottom: -15px;
  }
  .c-sosaiAfterServiceLink li {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 11px;
    padding: 16px 5px 5px;
    border-top: solid 1px #2e95a3;
  }
  .c-sosaiAfterServiceLink li:nth-child(even) {
    margin-right: 0;
  }
  .c-sosaiAfterServiceLink li:after {
    right: 2px;
    bottom: 2px;
    width: 10px;
    height: 10px;
  }
  .c-sosaiAfterServiceLink li a {
    font-size: 1.9rem;
    line-height: 1;
  }
  .c-sosaiAfterServiceLink li a img {
    width: 21px;
    margin: -2px auto 0;
  }
  .c-sosaiAfterServiceLink li div {
    padding: 3px 0 6px 0;
  }
  .c-sosaiAfterServiceLink__label {
    height: 16px;
    font-size: 1rem;
    line-height: 1.6rem;
    width: 36px;
    padding: 0;
  }
}
/* 葬祭　関連リンク
------------------------------------------------------------*/
.c-sosaiKanrenLink01 li {
  width: 353px;
  margin-right: 35px;
  padding-bottom: 15px;
  text-align: center;
  background-color: #ffffff;
  position: relative;
}
.c-sosaiKanrenLink01 li:nth-child(3), .c-sosaiKanrenLink01 li:last-child {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-sosaiKanrenLink01 li {
    float: left;
    width: calc(50% - 5px);
    margin: 0 10px 10px 0;
    padding-bottom: 10px;
    display: inline-block;
  }
  .c-sosaiKanrenLink01 li:nth-child(2n) {
    margin-right: 0;
  }
}
.c-sosaiKanrenLink01 li:after {
  width: 10px;
  height: 10px;
  content: "";
  background-image: url("../img/common/icon_button_sankaku02_blue.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  bottom: 5px;
  right: 3px;
}
@media screen and (max-width: 640px) {
  .c-sosaiKanrenLink01 li:after {
    bottom: 3px;
  }
}
.c-sosaiKanrenLink01 li img {
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (max-width: 640px) {
  .c-sosaiKanrenLink01 li img {
    margin-bottom: 9px;
  }
}
.c-sosaiKanrenLink01 li a {
  color: #111111;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-sosaiKanrenLink01 li a {
    font-size: 1.2rem;
    line-height: 1.3;
  }
}

/* 葬祭 CVエリア もしもの時
------------------------------------------------------------*/
.c-sosaiCV01 {
  background: url("../img/sosai/service_detail_bg01.jpg") no-repeat center;
  background-size: cover;
  padding: 120px 0 140px;
  text-align: center;
}
.c-sosaiCV01__box {
  background: #343b58;
  color: #fff;
  padding: 26px 0 40px;
}
.c-sosaiCV01__ttl {
  font-size: 3.6rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  margin-bottom: 7px;
  font-weight: normal;
}
.c-sosaiCV01__txt {
  font-size: 1.8rem;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: normal;
}
.c-sosaiCV01__btn {
  background: #fff;
  padding: 34px 0 35px;
}
.c-sosaiCV01__btn .c-sosaiBtn03 {
  margin: 0 auto;
}
.c-sosaiCV01__btn--col2 {
  background: #fff;
  padding: 0 36px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.c-sosaiCV01__btn--col2 .c-sosaiCV01__btnBox {
  padding: 34px 0 35px 34px;
  width: 50%;
}
.c-sosaiCV01__btn--col2 .c-sosaiCV01__btnBox:first-child {
  border-right: 1px solid #ccc;
  padding-left: 0;
}
.c-sosaiCV01__btn--col2 .c-sosaiBtn05 {
  padding: 25px 18px;
}
.c-sosaiCV01__btn--tel {
  padding: 0;
}
.c-sosaiCV01__btn--tel .c-sosaiCV01__btnBox {
  padding: 30px 0 35px 30px;
}
.c-sosaiCV01__btn--tel p {
  font-size: 2rem;
  font-weight: bold;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@media screen and (max-width: 640px) {
  .c-sosaiCV01 {
    padding: 45px 15px 70px;
  }
  .c-sosaiCV01__box {
    padding: 30px 15px 27px;
  }
  .c-sosaiCV01__ttl {
    font-size: 2.3rem;
    margin-bottom: 16px;
  }
  .c-sosaiCV01__txt {
    font-size: 1.3rem;
  }
  .c-sosaiCV01__btn {
    padding: 15px 15px 19px;
  }
  .c-sosaiCV01__btn .c-sosaiBtn03,
  .c-sosaiCV01__btn .c-sosaiBtn05 {
    font-size: 1.4rem;
  }
  .c-sosaiCV01__btn--col2 {
    padding: 15px 0 19px;
    display: block;
  }
  .c-sosaiCV01__btn--col2 .c-sosaiCV01__btnBox {
    padding: 15px 15px 0;
    width: 100%;
  }
  .c-sosaiCV01__btn--col2 .c-sosaiCV01__btnBox:first-child {
    border-right: none;
    border-bottom: 1px solid #ccc;
    padding: 0 15px 19px;
  }
  .c-sosaiCV01__btn--col2 .c-sosaiBtn03 {
    font-size: 1.4rem;
  }
  .c-sosaiCV01__btn--col2 .c-sosaiBtn05 {
    font-size: 1.4rem;
    padding: 15px;
  }
  .c-sosaiCV01__btn--tel p {
    font-size: 1.8rem;
    margin-bottom: 7px;
  }
}
/* フォーム　個人情報の取扱
------------------------------------------------------------*/
.c-formPrivacyTitle {
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #46483c;
  border-bottom: solid 2px #FC819B;
}
@media screen and (max-width: 640px) {
  .c-formPrivacyTitle {
    margin-bottom: 30px;
    padding-bottom: 5px;
    font-size: 2rem;
  }
}
.c-formPrivacyTitle.is-coopGreen {
  border-bottom-color: #8CB340;
}

.c-formPrivacyText__txt {
  margin-bottom: 35px;
  padding: 26px 31px 33px;
  border: solid 1px #efefef;
}
@media screen and (max-width: 640px) {
  .c-formPrivacyText__txt {
    margin-bottom: 18px;
    padding: 17px 8px 20px 19px;
  }
}
.c-formPrivacyText__txt dl {
  width: 100%;
  height: 240px;
  padding-right: 20px;
  overflow: auto;
  color: #46483c;
}
@media screen and (max-width: 640px) {
  .c-formPrivacyText__txt dl {
    height: 261px;
    padding-right: 20px;
  }
}
.c-formPrivacyText__txt dl dt {
  margin-bottom: 31px;
  font-size: 2rem;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-formPrivacyText__txt dl dt {
    margin-bottom: 22px;
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
.c-formPrivacyText__txt dl dd {
  margin-bottom: 1em;
  font-size: 1.4rem;
  line-height: 1.875;
}
@media screen and (max-width: 640px) {
  .c-formPrivacyText__txt dl dd {
    font-size: 1.2rem;
    line-height: 1.7;
  }
}
.c-formPrivacyText__txt dl dd ul li {
  text-indent: -1em;
  padding-left: 1em;
}
.c-formPrivacyText__txt dl dd ul li a {
  color: #308bbb;
  text-decoration: underline;
  position: relative;
  padding-left: 18px;
}
.c-formPrivacyText__txt dl dd ul li a:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #669900;
  border-radius: 100px;
  position: absolute;
  top: 6px;
  left: 0;
}
.c-formPrivacyText__box {
  background: #f9f9f9;
  padding: 15px;
}
.c-formPrivacyText__box a {
  color: #308bbb;
}
.c-formPrivacyText__sign {
  text-align: right;
}
.c-formPrivacyText .c-checkbox {
  display: flex;
  justify-content: center;
}
.c-formPrivacyText input[type=checkbox] + label {
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .c-formPrivacyText input[type=checkbox] + label {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}

.c-formPrivacyText--02 .c-formPrivacyText__txt {
  margin-bottom: 15px;
  padding: 15px 20px;
  border: 1px solid #888;
  border-radius: 5px;
  line-height: 1.5;
  color: #222;
}
@media screen and (min-width: 641px) {
  .c-formPrivacyText--02 .c-formPrivacyText__txt {
    margin-bottom: 30px;
    padding: 30px 40px;
    border-radius: 10px;
  }
}
.c-formPrivacyText--02 .c-formPrivacyText__txt dl {
  height: 225px;
  color: inherit;
}
@media screen and (min-width: 641px) {
  .c-formPrivacyText--02 .c-formPrivacyText__txt dl {
    height: 300px;
  }
}
.c-formPrivacyText--02 .c-formPrivacyText__txt dl dd {
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .c-formPrivacyText--02 .c-formPrivacyText__txt dl dd {
    font-size: 1.6rem;
  }
}

/* フォーム　入力ページ　電話問い合わせ
------------------------------------------------------------*/
.c-formTelInquiry {
  color: #46483c;
}
.c-formTelInquiry__pc {
  font-size: 1.6rem;
}
.c-formTelInquiry__pc__tel {
  font-size: 1.8rem;
  line-height: 1;
  font-weight: bold;
}
.c-formTelInquiry__pc__tel span {
  font-size: 2.8rem;
}

@media screen and (max-width: 640px) {
  .c-formTelInquiry__sp {
    text-align: center;
  }
  .c-formTelInquiry__sp a {
    width: 100%;
    padding: 10px 0;
    font-size: 1.3rem;
    color: #ffffff;
    background-color: #4b5870;
    display: block;
    border: solid 3px #939ba9;
  }
  .c-formTelInquiry__sp a span {
    font-size: 1.7rem;
    font-weight: bold;
    display: block;
  }
  .c-formTelInquiry__sp a span:before {
    width: 21px;
    height: 16px;
    margin: -3px 5px 0 0;
    content: "";
    background-image: url("../img/common/icon_tel01.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
}
/* フォーム　完了ページ
------------------------------------------------------------*/
.c-formComplete {
  color: #46483c;
  font-size: 1.6rem;
  line-height: 1.75;
}
.c-formComplete__title {
  margin-bottom: 16px;
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: bold;
  color: #669900;
}
.c-formComplete p {
  margin-bottom: 7px;
}
.c-formComplete__annotation {
  font-size: 1.4rem;
  color: #f22727;
}
.c-formComplete__inquiry {
  width: 480px;
  margin: 54px auto 78px auto;
  padding: 19px 0 20px;
  text-align: center;
  border: solid 2px #649333;
}
.c-formComplete__inquiry h3 {
  margin-bottom: 10px;
  font-weight: bold;
}
.c-formComplete__inquiry__text {
  color: #669900;
  font-size: 2rem;
  text-align: center;
}
.c-formComplete__inquiry__tel {
  color: #4b5870;
  font-size: 1.4rem;
}
.c-formComplete__inquiry__tel span {
  margin-left: 5px;
  color: #4b5870;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1;
}
.c-formComplete__inquiry__tel span:before {
  width: 30px;
  height: 26px;
  margin: -5px 5px 0 0;
  content: "";
  background-image: url("../img/common/icon_tel03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-formComplete__inquiry__tel p {
  margin: 0;
}
.c-formComplete__box {
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .c-formComplete__box {
    display: block;
  }
}
.c-formComplete__box .c-formComplete__inquiry {
  border: solid 1px #649333;
  padding: 20px 5px;
  margin: 54px 0 78px 0;
  width: 32.5%;
}
@media screen and (max-width: 640px) {
  .c-formComplete__box .c-formComplete__inquiry {
    width: 100%;
    padding: 20px;
    margin: 0 0 15px;
  }
  .c-formComplete__box .c-formComplete__inquiry:last-of-type {
    margin-bottom: 0;
  }
}
.c-formComplete__box .c-formComplete__inquiry > p {
  font-size: 1.4rem;
}
.c-formComplete__box .c-formComplete__inquiry__tel {
  font-size: 1.4rem;
}
.c-formComplete__box .c-formComplete__inquiry__tel span {
  font-size: 2.8rem;
}
.c-formComplete__box .c-formComplete__inquiry__tel span:before {
  width: 25px;
  height: 21px;
  margin: -5px 5px 0 0;
  content: "";
  background-image: url("../img/common/icon_tel03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.c-formComplete__box .c-formComplete__inquiry:first-of-type h3 {
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .c-formComplete__box .c-formComplete__inquiry:first-of-type h3 {
    margin-bottom: auto;
  }
}
.c-formComplete__box .c-formComplete__inquiry:first-of-type > p {
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .c-formComplete__box .c-formComplete__inquiry:first-of-type > p {
    margin-bottom: 10px;
  }
}
.c-formComplete__box .c-formComplete__num {
  line-height: 1.3;
  margin-bottom: 10px;
}
.c-formComplete__box .c-formComplete__time {
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .c-formComplete {
    font-size: 1.4rem;
    line-height: 1.4;
  }
  .c-formComplete__title {
    margin-bottom: 13px;
    font-size: 1.6rem;
    line-height: 1.4;
  }
  .c-formComplete p {
    margin-bottom: 8px;
  }
  .c-formComplete__annotation {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.6;
  }
  .c-formComplete__inquiry {
    width: 100%;
    margin: 26px 0 20px;
    padding: 17px 20px 19px;
    border: solid 1px #649333;
  }
  .c-formComplete__inquiry h3 {
    font-size: 1.5rem;
    margin-bottom: 6px;
  }
  .c-formComplete__inquiry__text {
    text-align: left;
    font-size: 1.5rem;
  }
  .c-formComplete__inquiry__tel_sp a {
    width: 100%;
    padding: 10px 0 8px;
    font-size: 1.1rem;
    font-weight: normal;
    color: #ffffff;
    background-color: #4b5870;
    display: block;
    border: solid 3px #939ba9;
  }
  .c-formComplete__inquiry__tel_sp a span {
    font-size: 1.4rem;
    font-weight: bold;
    display: block;
    margin-bottom: 2px;
  }
  .c-formComplete__inquiry__tel_sp a span:before {
    width: 21px;
    height: 16px;
    margin: -3px 5px 0 0;
    content: "";
    background-image: url("../img/common/icon_tel01.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
}
.c-formComplete02__inquiry {
  padding: 15px;
  border: 1.5px solid #41B4E6;
  border-radius: 5px;
  row-gap: 15px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .c-formComplete02__inquiry {
    margin-left: auto;
    margin-right: auto;
    max-width: 590px;
    padding: 30px;
    border-width: 3px;
    border-radius: 10px;
    row-gap: 10px;
    font-size: 1.6rem;
  }
}
.c-formComplete02__inquiry__title {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #41B4E6;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .c-formComplete02__inquiry__title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-size: 2rem;
  }
}
.c-formComplete02__inquiry__tel {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .c-formComplete02__inquiry__telBtn {
    padding-left: 15px;
    padding-right: 15px;
    width: fit-content;
    min-height: 47.5px;
    display: flex;
    column-gap: 7.5px;
    align-items: center;
    background-color: #41B4E6;
    border-radius: 9999px;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
  }
  .c-formComplete02__inquiry__telBtn svg {
    min-width: 25px;
    width: 25px;
    height: 25px;
  }
}
@media screen and (min-width: 641px) {
  .c-formComplete02__inquiry__telBtn {
    width: fit-content;
    display: flex;
    column-gap: 15px;
    align-items: center;
    color: #41B4E6;
    font-size: 3.2rem;
    font-weight: 700;
  }
  .c-formComplete02__inquiry__telBtn svg {
    min-width: 35px;
    width: 35px;
    height: 35px;
  }
}

/* コンポーネント
------------------------------------------------------------*/
.c-step {
  background: #efefef;
  padding: 29px 30px 25px;
}
@media screen and (max-width: 640px) {
  .c-step {
    padding: 20px 0;
  }
}
.c-step__inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .c-step__inner {
    display: block;
  }
}
.c-step__item {
  width: 193px;
}
@media screen and (max-width: 640px) {
  .c-step__item {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #9ac985;
    position: relative;
    padding: 17px 17px 22px 17px;
    background: white;
    margin-bottom: 2px;
  }
}
.c-step__item:last-child .c-step__head:after {
  display: none;
}
.c-step__head {
  border: 3px solid #9ac985;
  box-sizing: border-box;
  text-align: center;
  background: white;
  padding-top: 8px;
  padding-bottom: 10px;
  margin-bottom: 8px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-step__head {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 0 11px 0;
    position: static;
  }
}
.c-step__head:after {
  position: absolute;
  top: 50%;
  right: -22px;
  transform: translateY(-50%);
  content: "";
  width: 22px;
  height: 34px;
  background: url("../img/component/icon_step.png") no-repeat;
  z-index: 1;
}
@media screen and (max-width: 640px) {
  .c-step__head:after {
    width: 30px;
    height: 18px;
    background: url("../img/component/icon_step-sp.png") no-repeat;
    top: auto;
    bottom: -18px;
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.c-step__head-inner .c-step__icon {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .c-step__head-inner .c-step__icon {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .c-step__head-inner {
    margin-left: 17px;
  }
}
.c-step__icon {
  display: none;
}
@media screen and (max-width: 640px) {
  .c-step__icon {
    display: block;
  }
}
.c-step__title {
  font-size: 1.2rem;
  font-family: "din-2014", sans-serif;
  font-weight: bold;
  color: #8f8f8f;
}
.c-step__title span {
  font-size: 2rem;
}
@media screen and (max-width: 640px) {
  .c-step__title span {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 640px) {
  .c-step__title {
    font-size: 1rem;
    color: #666;
  }
}
.c-step__subtitle {
  font-size: 1.8rem;
  font-weight: bold;
  color: #8f8f8f;
  margin-top: 7px;
}
@media screen and (max-width: 640px) {
  .c-step__subtitle {
    font-size: 1.6rem;
    color: #666;
    margin-top: 0;
  }
}
.c-step__body {
  background: white;
  padding: 10px 14px 33px;
}
@media screen and (max-width: 640px) {
  .c-step__body {
    padding: 13px 0 0 0;
  }
}
.c-step__body p {
  font-size: 1.5rem;
  font-weight: 500;
  color: #232934;
  line-height: 1.6;
}
@media screen and (max-width: 640px) {
  .c-step__body p {
    font-size: 1.2rem;
    line-height: 16px;
  }
}
.c-step__body p a {
  color: #308bbb;
  text-decoration: underline;
}
.c-step__body p a:hover {
  opacity: 1;
  text-decoration: none;
}
.c-step__body p b {
  font-weight: bold;
}
.c-step__note {
  margin-top: 14px;
}
@media screen and (max-width: 640px) {
  .c-step__note {
    margin-top: 16px;
  }
}
.c-step__note p {
  font-size: 1.3rem;
  font-weight: 500;
  color: #232934;
  line-height: 1.7;
}
@media screen and (max-width: 640px) {
  .c-step__note p {
    font-size: 1rem;
    line-height: 15px;
  }
}

/*
------------------------------------------------------------*/
.c-gallery {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .c-gallery {
    justify-content: space-between;
    padding: 0 20px;
  }
}
.c-gallery__item {
  background: #f5f5f5;
  border: 1px solid #ddd;
  box-sizing: border-box;
  width: 273px;
  min-height: 211px;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .c-gallery__item {
    width: calc(50% - 5px);
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
}
.c-gallery__item:nth-child(3n+2) {
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .c-gallery__item:nth-child(3n+2) {
    margin-left: 0;
    margin-right: 0;
  }
}
.c-gallery__item > a {
  display: block;
  padding: 10px;
}
@media screen and (max-width: 640px) {
  .c-gallery__item > a {
    padding: 5px 5px 9px;
  }
}
.c-gallery__image {
  overflow: hidden;
}
.c-gallery__image img {
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 126px;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
}
@media screen and (max-width: 640px) {
  .c-gallery__image img {
    height: 63px;
  }
}
.c-gallery__image:hover img {
  transform: scale(1.1);
}
.c-gallery__title {
  display: flex;
  min-height: 65px;
  align-items: center;
  padding: 9px 9px 0;
}
@media screen and (max-width: 640px) {
  .c-gallery__title {
    min-height: 0;
    flex: 1 1 auto;
    justify-content: center;
    padding: 16px 5px 0;
  }
}
.c-gallery__title p {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 18px;
  color: #141414;
  position: relative;
  padding-left: 16px;
}
@media screen and (max-width: 640px) {
  .c-gallery__title p {
    font-size: 1.2rem;
    line-height: 14px;
  }
}
.c-gallery__title p:before {
  position: absolute;
  top: 40%;
  left: 0;
  width: 6px;
  height: 10px;
  transform: translateY(-50%);
  background: url("../img/component/113.png") no-repeat;
  content: "";
}

/*
------------------------------------------------------------*/
.c-iconList__inner {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 640px) {
  .c-iconList__inner {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 640px) {
  .c-iconList li {
    width: 33.3333333333%;
    margin-bottom: 15px;
  }
}
.c-iconList li a {
  display: block;
}
.c-iconList li a:hover {
  opacity: 1;
}
.c-iconList li a:hover .c-iconList__image {
  transform: translateY(-5px);
}
.c-iconList li a:hover .c-iconList__text p {
  text-decoration: none;
}
.c-iconList__image {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
  transition: 0.3s ease-in-out;
}
.c-iconList__image:hover {
  transform: translateY(-5px);
}
@media screen and (max-width: 640px) {
  .c-iconList__image {
    justify-content: center;
    height: 40px;
    margin-bottom: 6px;
  }
}
.c-iconList__text {
  text-align: center;
}
.c-iconList__text a,
.c-iconList__text p {
  font-size: 1.4rem;
  font-weight: 500;
  color: #04756f;
}
@media screen and (max-width: 640px) {
  .c-iconList__text a,
  .c-iconList__text p {
    font-size: 1.2rem;
  }
}

/*
------------------------------------------------------------*/
.c-miniBanner {
  background: #fbf7f1 url("/img/component/reform_KnowledgeBg01.png") no-repeat top right;
  width: 400px;
  height: 100px;
  border: 1px solid #999281;
  transition: 0.3s ease-in-out;
}
.c-miniBanner:hover {
  transform: translateY(-5px);
}
.c-miniBanner.c-miniBanner--2 {
  background: #fbf7f1 url("/img/component/reform_KnowledgeBg02.png") no-repeat top right;
}
@media screen and (max-width: 640px) {
  .c-miniBanner {
    width: 100%;
    height: 70px;
  }
}
.c-miniBanner > a {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 28px;
  position: relative;
}
.c-miniBanner__text p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #342603;
  line-height: 20px;
}
@media screen and (max-width: 640px) {
  .c-miniBanner__text p {
    font-size: 1.3rem;
    line-height: 16px;
  }
}
.c-miniBanner__icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #247404;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .c-miniBanner__icon {
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 640px) {
  .c-miniBanner__icon img {
    max-width: 50%;
  }
}

/*
------------------------------------------------------------*/
.c-banner {
  margin-right: 10px;
  width: 270px;
  height: 299px;
  position: relative;
  padding: 0 10px 10px 0;
}
@media screen and (max-width: 640px) {
  .c-banner {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}
.c-banner:after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: repeating-linear-gradient(-45deg, #fffbf8, #fffbf8 2px, #fee7d8 3px, #fee7d8 5px);
  content: "";
}
.c-banner > a {
  display: block;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .c-banner > a {
    height: 100%;
  }
}
.c-banner img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/*
------------------------------------------------------------*/
.c-showCase {
  width: 400px;
}
@media screen and (max-width: 640px) {
  .c-showCase {
    width: 100%;
  }
}
.c-showCase__head {
  padding: 9px 20px 10px 20px;
  background: #3a4452;
}
@media screen and (max-width: 640px) {
  .c-showCase__head {
    padding: 7px 15px 6px 15px;
  }
}
.c-showCase__head p {
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
}
.c-showCase__body {
  padding: 20px;
  background: #f5f5f5 url("/img/component/reform_case-icon01.png") no-repeat bottom 20px left 20px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .c-showCase__body {
    padding: 15px;
    background: #f5f5f5 url("/img/component/reform_case-icon01SP.png") no-repeat bottom 17px left 18px;
    background-size: 25px 31px;
  }
}
@media screen and (max-width: 640px) {
  .c-showCase__img.before {
    margin-right: 12px;
  }
}
.c-showCase__img.before p {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 7px;
  color: #333333;
}
@media screen and (max-width: 640px) {
  .c-showCase__img.before p {
    margin-top: 2px;
  }
}
.c-showCase__img.after {
  width: 230px;
  position: relative;
  box-sizing: border-box;
  border: 3px solid #fa5b0f;
}
.c-showCase__img.after p {
  font-size: 1.6rem;
  position: absolute;
  left: -60px;
  bottom: -7px;
  color: #fa5b0f;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-showCase__img.after p {
    font-size: 1.3rem;
    left: -50px;
    bottom: -4px;
  }
}
.c-showCase__img img {
  max-width: 100%;
}
.c-showCase__arrow {
  position: relative;
}
.c-showCase__arrow:after {
  position: absolute;
  bottom: -27px;
  right: 0;
  width: 15px;
  height: 17px;
  background: url("/img/component/reform_case-arrow.png") no-repeat;
  content: "";
}
@media screen and (max-width: 640px) {
  .c-showCase__arrow:after {
    bottom: -16px;
    width: 11px;
    height: 13px;
    background: url("/img/component/reform_case-arrowSP.png") no-repeat;
    background-size: 11px 13px;
  }
}

/*
------------------------------------------------------------*/
.c-sidebarMenu:after {
  content: "";
  height: 1px;
  display: block;
  background: url("/img/reform/line.png") no-repeat;
}
.c-sidebarMenu__title {
  background: #46483c;
  padding: 8px 0 7px;
}
@media screen and (max-width: 640px) {
  .c-sidebarMenu__title {
    padding: 10px 20px;
  }
}
.c-sidebarMenu__title p {
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .c-sidebarMenu__title p {
    text-align: left;
  }
}
.c-sidebarMenu__list li:nth-child(even) {
  background: #f8f8f5;
}
.c-sidebarMenu__list li:after {
  content: "";
  height: 1px;
  display: block;
  background: url("/img/reform/line.png") no-repeat;
}
.c-sidebarMenu__list li:last-of-type:after {
  display: none;
}
.c-sidebarMenu__list span {
  display: inline-block;
  vertical-align: middle;
  margin-left: -44px;
  margin-right: 17px;
}
.c-sidebarMenu__list a {
  padding: 14px 20px 11px 60px;
  display: block;
  color: #04756f;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  position: relative;
}
.c-sidebarMenu__list a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 6px;
  height: 10px;
  background-image: url("/img/component/arrow-green02.png");
}
.c-sidebarMenu__list a:hover {
  opacity: 1;
  text-decoration: none;
}
.c-sidebarMenu__list__break span {
  transform: translateY(1.2rem);
}
.c-sidebarMenu__list .u-font13 {
  font-size: 1.3rem;
}

/*
------------------------------------------------------------*/
.c-sidebarListMenu {
  background: #fff2f5;
  border-top: 2px solid #fc819b;
  padding: 5px;
}
.c-sidebarListMenu--2 {
  background: #ebf4e7;
  border-top: 2px solid #9ac985;
}
.c-sidebarListMenu--2 .c-sidebarListMenu__menu a {
  line-height: 1.4;
  padding: 13px 0 11px 33px;
  height: 66px;
}
.c-sidebarListMenu__title {
  background: #fff;
  padding: 12px 19px 9px 19px;
  margin-bottom: 1px;
}
.c-sidebarListMenu__title p {
  font-size: 1.5rem;
  font-weight: 600;
  color: #232934;
}
.c-sidebarListMenu__title:not(:first-of-type) {
  margin-top: 4px;
}
.c-sidebarListMenu__list-box {
  background: #fff;
  margin-bottom: 1px;
}
.c-sidebarListMenu__list-box.is-open .c-sidebarListMenu__list-arrow:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}
.c-sidebarListMenu__list-title a {
  font-size: 1.5rem;
  font-weight: 500;
  color: #232934;
  padding: 8px 20px;
  display: block;
  text-decoration: none;
}
.c-sidebarListMenu__list-title a:hover {
  opacity: 0.7;
}
.c-sidebarListMenu__list-arrow {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: #fc819b;
  border-radius: 2px;
  position: relative;
  vertical-align: -2px;
  margin-right: 10px;
}
.c-sidebarListMenu__list-arrow:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 8px;
  background: #fff;
  transition: transform 0.25s ease-out;
  content: "";
}
.c-sidebarListMenu__list-arrow:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 2px;
  background: #fff;
  transition: transform 0.25s ease-out;
  content: "";
}
.c-sidebarListMenu__list-item {
  background: #fbfbfb;
  padding: 12px 10px 3px 43px;
  position: relative;
}
.c-sidebarListMenu__list-item:after {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(to right, #fff4f6, #fff4f6 3px, #ffe1e8 4px, #ffe1e8 7px);
  content: "";
}
.c-sidebarListMenu__list-item li {
  margin-bottom: 5px;
}
.c-sidebarListMenu__list-item a {
  text-decoration: none;
  color: #232934;
  font-size: 1.3rem;
  font-weight: 500;
  position: relative;
  display: block;
  padding-left: 15px;
}
.c-sidebarListMenu__list-item a:before {
  position: absolute;
  top: 8px;
  left: 0;
  width: 5px;
  height: 5px;
  border-right: 1px solid #fca1b4;
  border-bottom: 1px solid #fca1b4;
  transform: rotate(-45deg);
  content: "";
}
.c-sidebarListMenu__list-item a:hover {
  color: #783146;
}
.c-sidebarListMenu__menu {
  background: #fff;
  margin-bottom: 1px;
}
.c-sidebarListMenu__menu a {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
  color: #232934;
  display: flex;
  align-items: center;
  padding: 11px 0 6px 33px;
  position: relative;
}
.c-sidebarListMenu__menu a span:before {
  position: absolute;
  top: 50%;
  left: 16px;
  width: 6px;
  height: 6px;
  border-right: 1px solid #fc92a9;
  border-bottom: 1px solid #fc92a9;
  transform: translateY(-50%) rotate(-45deg);
  content: "";
}
.c-sidebarListMenu__menu a:hover {
  opacity: 0.7;
}

/*
------------------------------------------------------------*/
.c-card1 {
  display: flex;
  flex-wrap: wrap;
}
.c-card1__item {
  width: 33%;
  padding: 4px;
  margin-right: 10px;
  margin-bottom: 13px;
  width: 273px;
  height: 80px;
  background: #faad5a;
  border-radius: 8px;
  box-shadow: 0 3px #f26b51;
  position: relative;
  transition: 0.3s ease-in-out !important;
}
.c-card1__item:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}
.c-card1__item:nth-of-type(3n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-card1__item {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    margin-right: 0;
  }
}
.c-card1__item-inner {
  background: repeating-radial-gradient(center center, #f99c39, #f99c39 1px, transparent 1px, transparent 100%);
  background-size: 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px dashed #fefce2;
  text-align: center;
  padding: 0 20px 0 45px;
}
.c-card1__item.u-hover01:hover {
  opacity: 1;
}
.c-card1__text p {
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 20px;
}
@media screen and (max-width: 640px) {
  .c-card1__text p {
    font-size: 1.6rem;
    line-height: 21px;
  }
}
.c-card1__badge {
  position: absolute;
  top: -2px;
  left: 13px;
}
@media screen and (max-width: 640px) {
  .c-card1__badge {
    left: 10px;
    top: -1px;
  }
}

/*
------------------------------------------------------------*/
.c-card2 {
  display: flex;
  flex-wrap: wrap;
}
.c-card2 a:hover {
  opacity: 1;
}
.c-card2__item {
  width: 195px;
  position: relative;
  border-bottom: 1px dotted #733e01;
  padding-bottom: 15px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.c-card2__item:nth-child(4n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .c-card2__item {
    width: calc(50% - 10px);
    margin-right: 20px;
    padding-bottom: 17px;
  }
  .c-card2__item:nth-child(4n) {
    margin-right: 20px;
  }
  .c-card2__item:nth-child(2n) {
    margin-right: 0;
  }
}
.c-card2__item:after {
  position: absolute;
  bottom: -1px;
  right: 0;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent #faad5a transparent;
  content: "";
}
.c-card2__item:hover .c-card2__body p {
  color: #f0662b;
  text-decoration: underline;
}
.c-card2__title {
  background: #fddab5;
  padding: 2px;
  border-radius: 15px;
  margin-bottom: 10px;
}
@media screen and (-ms-high-contrast: none) {
  .c-card2__title {
    padding: 5px 0 0;
  }
}
.c-card2__title p {
  font-size: 1.4rem;
  font-weight: bold;
  color: #483825;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .c-card2__title {
    padding: 1px;
  }
  .c-card2__title p {
    font-size: 1rem;
  }
}
.c-card2__tag {
  font-size: 1.3rem;
  line-height: 1;
  color: #666;
  margin-top: 10px;
  background: #000;
  background: #eee;
  display: inline-block;
  padding: 4px 10px;
  font-weight: 500;
}
@media screen and (-ms-high-contrast: none) {
  .c-card2__tag {
    padding: 5px 10px 0;
  }
}
@media screen and (max-width: 640px) {
  .c-card2__tag {
    font-size: 0.9rem;
    margin-top: 8px;
    padding: 2px 6px;
  }
}
.c-card2__body p {
  margin-top: 10px;
  font-size: 1.6rem;
  line-height: 20px;
  font-weight: bold;
  color: #733e01;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .c-card2__body p {
    font-size: 1.3rem;
    line-height: 17px;
  }
}
.c-card2__image img {
  width: 195px;
  height: 130px;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
}
@media screen and (max-width: 640px) {
  .c-card2__image img {
    width: 100%;
    height: 86px;
  }
}

/*
------------------------------------------------------------*/
.c-listLink a {
  text-decoration: underline;
  color: #594242;
  display: block;
}
.c-listLink a:hover {
  opacity: 1;
  text-decoration: none;
}
.c-listLink__item:after {
  content: "";
  height: 1px;
  display: block;
  background: url(../img/kurashi/line02.png) repeat-x;
}
.c-listLink__item:nth-child(odd) {
  background: #fdfbf9;
}
.c-listLink__item a {
  font-size: 1.6rem;
  line-height: 20px;
  padding: 18px 45px 18px 12px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-listLink__item a {
    text-decoration: none;
    font-size: 1.3rem;
    padding: 10px 10px 12px 30px;
    font-weight: bold;
  }
}
.c-listLink__item a .plus {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #faad5a;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.c-listLink__item a .plus:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 14px;
  background: #fff;
  transition: transform 0.25s ease-out;
  content: "";
}
.c-listLink__item a .plus:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 2px;
  background: #fff;
  transition: transform 0.25s ease-out;
  content: "";
}
@media screen and (max-width: 640px) {
  .c-listLink__item a .arrow {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #faad5a;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
  }
  .c-listLink__item a .arrow:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 4px;
    border-color: transparent transparent transparent #fff;
    content: "";
  }
}
.c-listLink__item.is-open a .plus:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}
.c-listLink__list {
  background: #f8f8f8;
}
@media screen and (max-width: 640px) {
  .c-listLink__list {
    padding-bottom: 13px;
    border-top: 1px dotted #dddddd;
  }
}
.c-listLink__list li:after {
  content: "";
  height: 1px;
  display: block;
  background: url(../img/kurashi/line01.png) repeat-x;
}
@media screen and (max-width: 640px) {
  .c-listLink__list li {
    border-top: none;
  }
}
.c-listLink__list a {
  font-size: 1.4rem;
  line-height: 20px;
  padding: 8px 0 7px 22px;
  /*&:before {
               position: absolute;
               top: 15px;
               left: 44px;
               display: inline-block;
               width: 0;
               height: 0;
               border-style: solid;
               border-width: 5px 0 5px 6px;
               border-color: transparent transparent transparent #faad5a;
               content: '';
           }*/
}
@media screen and (max-width: 640px) {
  .c-listLink__list a {
    text-decoration: underline;
    position: relative;
    padding: 10px 0 0px 54px;
    font-weight: normal;
  }
}

/*
------------------------------------------------------------*/
.c-sidebarMenuLink {
  border: 1px solid #ddd;
  border-radius: 2px;
}
.c-sidebarMenuLink__title {
  background: #f6fbfe;
  padding: 12px 20px 8px 18px;
  border-bottom: 2px solid #ddd;
}
.c-sidebarMenuLink__title p {
  display: inline-flex;
  align-items: center;
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 600;
  color: #46483c;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-sidebarMenuLink__title p {
    font-size: 1.6rem;
  }
}
.c-sidebarMenuLink__title p:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #82cdf3;
  content: "";
}
.c-sidebarMenuLink__title p img {
  margin: 0 5px 0 0;
}
@media screen and (-ms-high-contrast: none) {
  .c-sidebarMenuLink__title p img {
    margin: -4px 5px 0 0;
  }
}
.c-sidebarMenuLink__list-title {
  border-bottom: 1px solid #82cdf3;
  padding: 15px 20px 5px 18px;
}
@media screen and (max-width: 640px) {
  .c-sidebarMenuLink__list-title {
    padding-top: 11px;
    padding-bottom: 12px;
  }
}
.c-sidebarMenuLink__list-title p {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-sidebarMenuLink__list-title p {
    font-size: 1.4rem;
  }
}
.c-sidebarMenuLink__list:not(:first-child) .c-sidebarMenuLink__list-title {
  border-top: 1px dashed #ddd;
}
.c-sidebarMenuLink__list li a {
  font-size: 1.5rem;
  color: #46483c;
  display: block;
  border-bottom: 1px dashed #ddd;
  padding: 14px 20px 12px 32px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-sidebarMenuLink__list li a {
    font-size: 1.3rem;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 33px;
  }
}
.c-sidebarMenuLink__list li a:before {
  position: absolute;
  top: 50%;
  left: 18px;
  display: inline-block;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 5px;
  border-color: transparent transparent transparent #82cdf3;
  content: "";
}
@media screen and (max-width: 640px) {
  .c-sidebarMenuLink__list li a:before {
    left: auto;
    right: 12px;
  }
}
.c-sidebarMenuLink__list li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.c-sidebarMenuLink__list li:last-child a {
  border-bottom: 0;
}

/*
------------------------------------------------------------*/
.c-listCard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.c-listCard__item {
  position: relative;
  background: #000;
  width: 410px;
  height: 154px;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .c-listCard__item {
    height: auto;
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 640px) {
  .c-listCard__item img {
    width: 100%;
  }
}
.c-listCard__item:before {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  border: solid 3px #fc819b;
  content: "";
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.c-listCard__item:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.8) 100%);
  content: "";
}
.c-listCard__item:hover {
  opacity: 1;
}
.c-listCard__item:hover:before {
  opacity: 1;
}
.c-listCard__text {
  position: absolute;
  font-size: 1.8rem;
  font-weight: bold;
  bottom: 14px;
  left: 19px;
  color: #fff;
  z-index: 2;
}
@media screen and (max-width: 640px) {
  .c-listCard__text {
    font-size: 1.4rem;
    bottom: 7px;
    left: 11px;
  }
}

/*
------------------------------------------------------------*/
.p-search {
  width: 840px;
  background: #f5f6f7;
  padding: 16px 0 15px 18px;
  margin-bottom: 27px;
}
@media screen and (max-width: 640px) {
  .p-search {
    width: 100%;
    padding: 10px;
  }
}
.p-search__lable {
  font-size: 1.6rem;
  font-weight: bold;
  color: #445161;
}
.p-search__lable img {
  margin: 5px 5px 0 0;
}
.p-search select {
  width: 464px;
  height: 40px;
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 0 5px;
  margin-left: 28px;
}
@media screen and (max-width: 640px) {
  .p-search select {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 5px;
    font-size: 1.3rem;
  }
}

/*
------------------------------------------------------------*/
.pagination {
  margin-top: 5px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.pagination a {
  float: left;
  list-style: none;
  margin: 5px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  background: #dddddd;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  color: #dddddd;
  font-size: 0;
}
@media screen and (max-width: 640px) {
  .pagination a {
    width: 8px;
    height: 8px;
    margin: 3px;
  }
}
.pagination .jp-current {
  background: #faad5a;
  color: #faad5a;
}
.pagination .jp-previous,
.pagination .jp-next {
  color: #fff;
  width: 40px;
  height: 40px;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .pagination .jp-previous,
  .pagination .jp-next {
    width: 30px;
    height: 30px;
  }
}
.pagination .jp-previous {
  background: url("../img/component/prev_btn.png");
  margin-right: 15px;
  background-size: cover !important;
}
@media screen and (max-width: 640px) {
  .pagination .jp-previous {
    margin-right: 10px;
  }
}
.pagination .jp-next {
  background: url("../img/component/next_btn.png");
  margin-left: 15px;
  background-size: cover !important;
}
@media screen and (max-width: 640px) {
  .pagination .jp-next {
    margin-left: 10px;
  }
}
.pagination.display-none {
  display: none;
}

/*
------------------------------------------------------------*/
.c-step2 {
  display: flex;
  overflow: hidden;
  height: 40px;
}
.c-step2 li {
  width: 170px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #b2b2b2;
  background: #fff;
  border: 1px solid #b2b2b2;
  position: relative;
  margin-right: 13px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .c-step2 li {
    font-size: 1.1rem;
    width: auto;
    flex: 1;
    line-height: 1.4;
  }
}
.c-step2 li.fixed-center {
  padding: 4px 0 0;
}
.c-step2 li:before {
  content: "";
  width: 15px;
  height: 40px;
  background: url("../img/component/step_contact.png") no-repeat;
  background-position: left;
  position: absolute;
  top: -1px;
  left: -11px;
}
.c-step2 li:after {
  content: "";
  width: 15px;
  height: 40px;
  background: url("../img/component/step_contact.png") no-repeat;
  background-position: right;
  position: absolute;
  top: -1px;
  right: -12px;
  z-index: 2;
}
.c-step2 li:first-of-type:before {
  content: none;
  display: none;
}
.c-step2 li:last-of-type {
  margin-right: 0;
  padding-right: 7px;
}
.c-step2 li:last-of-type:after {
  content: none;
  display: none;
}
.c-step2 li.is-active {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  background: #fc819b;
  border: 1px solid #fc819b;
}
@media screen and (max-width: 640px) {
  .c-step2 li.is-active {
    font-size: 1.1rem;
  }
}
.c-step2 li.is-active:before, .c-step2 li.is-active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 0 24px 12px;
  position: absolute;
}
.c-step2 li.is-active:before {
  top: -5px;
  left: -12px;
  background: #fc819b;
  border-color: transparent transparent transparent #fff;
  z-index: 1;
  width: 15px;
}
.c-step2 li.is-active:after {
  top: -5px;
  right: -12px;
  border-color: transparent transparent transparent #fc819b;
  z-index: 0;
}
.c-step2--ticket li.is-active {
  background: #fc819b;
  border: 1px solid #fc819b;
}
.c-step2--ticket li.is-active:before {
  background: #fc819b;
}
.c-step2--ticket li.is-active:after {
  border-color: transparent transparent transparent #fc819b;
}
.c-step2--hoken li.is-active {
  background: #82cdf3;
  border: 1px solid #82cdf3;
}
.c-step2--hoken li.is-active:before {
  background: #82cdf3;
}
.c-step2--hoken li.is-active:after {
  border-color: transparent transparent transparent #82cdf3;
}
.c-step2--reform {
  margin-top: -10px;
  height: 60px;
}
.c-step2--reform li {
  width: 150px;
  height: 60px;
}
.c-step2--reform li:before, .c-step2--reform li:after {
  height: 60px;
}
.c-step2--reform li:before {
  background: url("../img/component/step_contact02.png") no-repeat;
  background-position: left;
}
.c-step2--reform li:after {
  background: url("../img/component/step_contact02.png") no-repeat;
  background-position: right;
  right: -12px;
}
.c-step2--reform li.is-active {
  background: #68ba93;
  border: 1px solid #68ba93;
}
.c-step2--reform li.is-active:before, .c-step2--reform li.is-active:after {
  border-width: 34px 0 34px 11px;
}
.c-step2--reform li.is-active:before {
  background: #68ba93;
  left: -12px;
}
.c-step2--reform li.is-active:after {
  border-color: transparent transparent transparent #68ba93;
  right: -11px;
}
.c-step2--kurashi li.is-active {
  background: #faad5a;
  border: 1px solid #faad5a;
}
.c-step2--kurashi li.is-active:before {
  background: #faad5a;
}
.c-step2--kurashi li.is-active:after {
  border-color: transparent transparent transparent #faad5a;
}
.c-step2--sosai {
  margin-top: -10px;
  height: 60px;
}
.c-step2--sosai li {
  width: 120px;
  height: 60px;
}
.c-step2--sosai li:before, .c-step2--sosai li:after {
  height: 60px;
}
.c-step2--sosai li:before {
  background: url("../img/component/step_contact02.png") no-repeat;
  background-position: left;
}
.c-step2--sosai li:after {
  background: url("../img/component/step_contact02.png") no-repeat;
  background-position: right;
  right: -12px;
}
.c-step2--sosai li.is-active {
  background: #af8ebe;
  border: 1px solid #af8ebe;
}
.c-step2--sosai li.is-active:before, .c-step2--sosai li.is-active:after {
  border-width: 34px 0 34px 11px;
}
.c-step2--sosai li.is-active:before {
  background: #af8ebe;
  left: -12px;
}
.c-step2--sosai li.is-active:after {
  border-color: transparent transparent transparent #af8ebe;
  right: -11px;
}

/*
------------------------------------------------------------*/
.c-bottom {
  border-top: 1px solid #669900;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .c-bottom {
    border-top: none;
    background: #f8f8f8;
  }
}
.c-bottom__logo {
  background: rgba(250, 247, 236, 0.5);
  padding: 31px 0 27px;
}
@media screen and (max-width: 640px) {
  .c-bottom__logo {
    background: none;
    padding: 10px 0 1px;
  }
  .c-bottom__logo img {
    width: 140px;
    height: auto;
  }
}
.c-bottom__copyright {
  padding: 14px 0 12px;
  background: #f8f8f8;
}
@media screen and (max-width: 640px) {
  .c-bottom__copyright {
    background: none;
    padding: 0 0 8px;
  }
}
.c-bottom__copyright p {
  font-size: 1.3rem;
  color: #999999;
}
@media screen and (max-width: 640px) {
  .c-bottom__copyright p {
    font-size: 0.9rem;
  }
}

/*ナビ無しヘッダー
------------------------------------------------------------*/
.c-header {
  width: 1120px;
  margin: 20px auto;
  overflow: hidden;
}
.c-header__name {
  float: left;
  display: flex;
  align-items: center;
}
.c-header__namePage {
  color: #783146;
  font-size: 2.4rem;
  font-weight: bold;
  margin-left: 17px;
}
.c-header__logo {
  float: right;
  margin-top: 10px;
}
@media screen and (max-width: 640px) {
  .c-header {
    width: 100%;
    margin: 0;
    padding: 10px 15px;
  }
  .c-header__name {
    float: right;
  }
  .c-header__namePage {
    font-size: 1.4rem;
    margin-left: 0;
  }
  .c-header__logoPage img {
    width: 85%;
  }
  .c-header__logo {
    float: left;
    margin-top: 10px;
  }
}

.c-header {
  width: 1120px;
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden;
}
.c-header__name {
  float: left;
}
.c-header__logo {
  float: right;
  margin-top: 10px;
}
.c-header .c-headCateTitle {
  height: auto;
  padding: 0;
}
@media screen and (max-width: 640px) {
  .c-header {
    width: 100%;
    padding: 10px 15px 10px 15px;
  }
  .c-header__name {
    float: right;
  }
  .c-header__logo {
    float: left;
    margin-top: 5px;
    width: 130px;
  }
  .c-header__logo img {
    width: 100%;
    height: auto;
  }
  .c-header .c-headCateTitle {
    font-size: 1.4rem;
  }
  .c-header .c-headCateTitle .c-cateIcon01 {
    margin: 0 11px 0 0;
    width: 30px;
    height: 30px;
  }
}

/*
------------------------------------------------------------*/
/* js-sample
------------------------------------------------------------*/
.p-sample {
  margin: 100px auto;
}
.p-sample .c-table1__rdparent {
  display: block;
}
.p-sample .c-table1__rdchild {
  display: block;
  padding: 10px 20px;
}
.p-sample .c-table1__rdchild p {
  float: left;
}
.p-sample .c-table1__rdchild ul {
  display: block;
  margin-left: 50px;
}

/*
------------------------------------------------------------*/
.c-block03 {
  background-image: url("../img/hoken/bg07.jpg");
  border-radius: 4px;
  padding: 5px;
}
@media screen and (max-width: 640px) {
  .c-block03 {
    padding: 3px;
    border-radius: 2px;
  }
}
.c-block03__inner {
  background: #fff;
  padding: 73px 40px 65px;
}
@media screen and (max-width: 640px) {
  .c-block03__inner {
    padding: 24px 20px 26.5px;
  }
}
.c-block03 .c-hokenBtn07 {
  margin: 36px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block03 .c-hokenBtn07 {
    margin: 18px 0 0;
  }
}
.c-block03 .c-hokenBtn08 {
  margin: 30px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block03 .c-hokenBtn08 {
    max-width: 195px;
    margin: 10px auto 0;
  }
}

/*
------------------------------------------------------------*/
.c-block04 {
  background-image: url("../img/hoken/bg07.jpg");
  border-radius: 4px;
  padding: 5px;
}
@media screen and (max-width: 640px) {
  .c-block04 {
    padding: 2px;
    border-radius: 2px;
  }
}
.c-block04__inner {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 61px 50px 60px 61px;
}
@media screen and (max-width: 640px) {
  .c-block04__inner {
    padding: 30px;
  }
}
.c-block04__img {
  width: 420px;
}
@media screen and (max-width: 640px) {
  .c-block04__img {
    width: 100%;
  }
}
.c-block04__img img {
  width: 100%;
}
.c-block04__info {
  width: calc(100% - 450px);
  margin: -5px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block04__info {
    width: 100%;
    margin: 18px 0 0;
  }
}
.c-block04__sub {
  display: block;
  font-size: 2rem;
  line-height: 1.5;
  color: #253b73;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
}
@media screen and (max-width: 640px) {
  .c-block04__sub {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}
.c-block04__main {
  display: block;
  font-size: 2.8rem;
  line-height: 1.6;
  color: #253b73;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
  margin: 8px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block04__main {
    font-size: 2rem;
    line-height: 1.3;
    margin: 7px 0 0;
  }
}
.c-block04__txt1 {
  display: flex;
  font-size: 1.5rem;
  line-height: 1.9;
  color: #46483c;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
  margin: 2px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block04__txt1 {
    font-size: 1rem;
    line-height: 1.4;
    margin: 9px 0 0;
  }
}
.c-block04__txt1 span {
  flex: 1;
}
.c-block04__txt2 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #46483c;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
  margin: 23px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block04__txt2 {
    font-size: 1.2rem;
    line-height: 1.7;
    margin: 27px 0 0;
  }
}
.c-block04__box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #6acff7;
  border-radius: 4px;
  padding: 23px 63px;
  margin: 40px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block04__box {
    padding: 25px 29px 25px 25px;
    margin: 30px 0 0;
  }
}
.c-block04__boxleft {
  width: 40%;
  padding: 5px 0;
}
@media screen and (max-width: 640px) {
  .c-block04__boxleft {
    width: 100%;
    padding: 0;
  }
}
.c-block04__boxttl {
  font-size: 1.8rem;
  line-height: 1.7;
  color: #253b73;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
}
@media screen and (max-width: 640px) {
  .c-block04__boxttl {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.35;
    font-weight: 500;
  }
}
.c-block04__boxright {
  width: 56.5%;
  padding: 0 0 0 38px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-block04__boxright {
    width: 100%;
    padding: 19px 0 0;
    margin: 17px 0 0;
  }
}
.c-block04__boxright::before {
  content: "";
  width: 1px;
  height: calc(100% - 14px);
  background: #6acff7;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
@media screen and (max-width: 640px) {
  .c-block04__boxright::before {
    width: 100%;
    height: 1px;
    top: 0;
    transform: none;
  }
}
.c-block04__txt3 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #000000;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-block04__txt3 {
    font-size: 1rem;
    line-height: 1.4;
  }
}
.c-block04__contact {
  margin: 7px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block04__contact {
    background: #253b73;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 10px 5px;
  }
}
.c-block04__contact a {
  font-size: 2.6rem;
  line-height: 1;
  color: #46483c;
  font-weight: bold;
  position: relative;
  padding: 0 0 0 72px;
}
@media screen and (max-width: 640px) {
  .c-block04__contact a {
    font-size: 1.3rem;
    color: #fff;
    padding: 0 0 0 20px;
  }
}
.c-block04__contact a::before {
  content: "";
  width: 65px;
  height: 26px;
  background: url("/img/common/icon_tel10.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 1px;
  left: 2px;
}
@media screen and (max-width: 640px) {
  .c-block04__contact a::before {
    width: 15px;
    height: 12px;
    background: url("/img/common/icon_tel01.png") no-repeat center;
    background-size: cover;
    top: 2px;
    left: 0;
  }
}
.c-block04__contact span {
  font-size: 1.4rem;
  line-height: 1.5;
  color: #46483c;
  font-weight: 500;
  vertical-align: bottom;
}
@media screen and (max-width: 640px) {
  .c-block04__contact span {
    display: block;
    font-size: 7px;
    color: #fff;
    margin: 4px 0 0;
  }
}
.c-block04 .c-hokenItemSerect__item__btn {
  margin: 49px 0 0;
}
@media screen and (max-width: 640px) {
  .c-block04 .c-hokenItemSerect__item__btn {
    display: block;
    text-align: center;
    margin: 33px 0 0;
  }
}
@media screen and (max-width: 640px) {
  .c-block04 .c-hokenItemSerect__item__btn .c-hokenItemSerect__item__request {
    margin: 10px 0 0;
  }
}

/*
------------------------------------------------------------*/
.c-block05 {
  border: 8px solid #FFE0E7;
  padding: 50px 60px 42px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .c-block05 {
    border-width: 4px;
    padding: 24px 26px 20px;
  }
}
.c-block05__txt {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.6rem;
}

@media screen and (max-width: 640px) {
  .c-block05__txt {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}
.c-block05__big {
  font-size: 3rem;
  line-height: 4.5rem;
  font-weight: 500;
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 25px;
  margin-top: 6px;
}
@media screen and (max-width: 640px) {
  .c-block05__big {
    font-size: 1.6rem;
    line-height: 1.7;
    letter-spacing: -0.05rem;
    padding-bottom: 7px;
    margin-bottom: 18px;
  }
}
.c-block05__big::after {
  position: absolute;
  content: "";
  background: url(../img/ticket/enquete/icon_line.svg) no-repeat center;
  width: 110px;
  height: 8px;
  background-size: 110px 8px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 640px) {
  .c-block05__big::after {
    width: 70px;
    height: 5px;
    background-size: 70px 5px;
  }
}
.c-block05__big > b {
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .c-block05__big > b {
    font-size: 1.8rem;
  }
}

.c-block05__ttl > span {
  color: #FC819B;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  padding: 0 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (max-width: 640px) {
  .c-block05__ttl > span {
    font-size: 1.4rem;
    padding: 0 16px;
  }
}
.c-block05__ttl > span::before, .c-block05__ttl > span:after {
  position: absolute;
  content: "";
  bottom: 3px;
  background: url(../img/ticket/enquete/icon_line_l.svg) no-repeat center;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
@media screen and (max-width: 640px) {
  .c-block05__ttl > span::before, .c-block05__ttl > span:after {
    width: 11px;
    height: 11px;
    background-size: 11px 11px;
  }
}
.c-block05__ttl > span::before {
  left: 0;
}
.c-block05__ttl > span::after {
  right: 0;
  background: url(../img/ticket/enquete/icon_line_r.svg) no-repeat center;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}
@media screen and (max-width: 640px) {
  .c-block05__ttl > span::after {
    width: 11px;
    height: 11px;
    background-size: 11px 11px;
  }
}

.c-block05__btn {
  margin-top: 8px;
  margin-bottom: 40px;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 16px;
  max-width: 300px;
  width: 100%;
  background-color: #FC819B;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-block05__btn {
    font-size: 1.4rem;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 19px;
  }
}
.c-block05__btn::after {
  position: absolute;
  content: "";
  right: 10px;
  top: 10px;
  background: url(../img/ticket/enquete/icon_link_w.svg) no-repeat center;
  width: 14px;
  height: 14px;
  background-size: 14px 14px;
}
@media screen and (max-width: 640px) {
  .c-block05__btn::after {
    width: 10px;
    height: 10px;
    background-size: 10px 10px;
    right: 7px;
    top: 7px;
  }
}

.c-block05__note {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.8rem;
  position: relative;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .c-block05__note {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: left;
    letter-spacing: -0.05rem;
  }
}
.c-block05__note::before {
  position: absolute;
  content: "※";
  font-size: 1.6rem;
  line-height: 1;
  left: 0;
  top: 6px;
}
@media screen and (max-width: 640px) {
  .c-block05__note::before {
    font-size: 1.2rem;
    top: 2px;
  }
}
.c-block05__note > a {
  color: #308BBB;
  position: relative;
  border-bottom: 1px solid #308BBB;
}
.c-block05__note > a::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background: url(../img/ticket/enquete/icon_link_blue.svg) no-repeat center;
  width: 10px;
  height: 10px;
  background-size: 10px 10px;
}
@media screen and (max-width: 640px) {
  .c-block05__note > a::after {
    width: 8px;
    height: 8px;
    background-size: 8px 8px;
  }
}

/*
------------------------------------------------------------*/
.c-step3 {
  display: flex;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-size: 2rem;
  line-height: 1.6;
  color: #989898;
  font-weight: 500;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .c-step3 {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .c-step3 {
    font-size: 1.2rem;
  }
}
.c-step3__text {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
  text-align: center;
}
.c-step3__item {
  padding: 7px 20px 5px 21px;
  height: 60px;
  width: 30%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-step3__item {
    min-width: 378px;
    background: url(../img/hoken/common/step3-bg.png) no-repeat center right/contain;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item {
    overflow: hidden;
    width: 29.3%;
    padding: 7px 4px 5px 20px;
    height: 50px;
    background-color: #F3F3F3;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item:nth-child(1) {
    background: transparent;
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item:nth-child(1):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    right: 0;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
.c-step3__item.is-active {
  z-index: 1;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .c-step3__item.is-active {
    background-image: url(../img/hoken/common/step3-bg-active.png) !important;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active {
    background: #82CDF3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1) {
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1):after {
    background-color: #82CDF3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1) + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-blue-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(2) {
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-blue2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #82CDF3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(2) + .c-step3__item {
    background: transparent;
    z-index: 3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(2) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:nth-child(2) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-blue-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:last-child {
    z-index: 4;
    background-color: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:last-child:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-blue2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item.is-active:last-child:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #82CDF3;
    position: absolute;
  }
}
.c-step3__item:nth-child(2) {
  padding-left: 15px;
}
@media screen and (min-width: 641px) {
  .c-step3__item:nth-child(2) {
    background-image: url(../img/hoken/common/step3-bg1.png);
    margin-left: -4px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item:nth-child(2) {
    width: 33.5%;
    padding-left: 31px;
    z-index: 1;
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
@media screen and (min-width: 641px) {
  .c-step3__item:nth-child(2).is-active {
    background-image: url(../img/hoken/common/step3-bg1-active.png) !important;
  }
}
.c-step3__item:last-child {
  padding-right: 38px;
  margin-left: -24px;
  background-position: left center;
  width: auto;
  position: relative;
  left: 18px;
}
@media screen and (min-width: 641px) {
  .c-step3__item:last-child {
    background-image: url(../img/hoken/common/step3-bg2.png);
    flex: 1;
    min-width: 390px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3__item:last-child {
    margin-left: -1px;
    padding-right: 8px;
    left: 1px;
    width: 37.2%;
  }
}
@media screen and (min-width: 641px) {
  .c-step3__item:last-child.is-active {
    background-image: url(../img/hoken/common/step3-bg2-active.png) !important;
  }
}
.c-step3.is-pink .c-step3__item {
  padding: 7px 20px 5px 21px;
  height: 60px;
  width: 30%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-step3.is-pink .c-step3__item {
    min-width: 378px;
    background: url(../img/hoken/common/step3-bg.png) no-repeat center right/contain;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item {
    overflow: hidden;
    width: 29.3%;
    padding: 7px 4px 5px 20px;
    height: 50px;
    background-color: #F3F3F3;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item:nth-child(1) {
    background: transparent;
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item:nth-child(1):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    right: 0;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
.c-step3.is-pink .c-step3__item.is-active {
  z-index: 1;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .c-step3.is-pink .c-step3__item.is-active {
    background-image: url(../img/hoken/common/step3-bg-pink-active.png) !important;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active {
    background: #FF739B;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1) {
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1):after {
    background-color: #FF739B;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1) + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-pink-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(2) {
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-pink2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #FF739B;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(2) + .c-step3__item {
    background: transparent;
    z-index: 3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(2) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:nth-child(2) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-pink-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:last-child {
    z-index: 4;
    background-color: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:last-child:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-pink2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item.is-active:last-child:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #FF739B;
    position: absolute;
  }
}
.c-step3.is-pink .c-step3__item:nth-child(2) {
  padding-left: 15px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-pink .c-step3__item:nth-child(2) {
    background-image: url(../img/hoken/common/step3-bg1.png);
    margin-left: -4px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item:nth-child(2) {
    width: 33.5%;
    padding-left: 31px;
    z-index: 1;
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-pink .c-step3__item:nth-child(2).is-active {
    background-image: url(../img/hoken/common/step3-bg1-pink-active.png) !important;
  }
}
.c-step3.is-pink .c-step3__item:last-child {
  padding-right: 38px;
  margin-left: -24px;
  background-position: left center;
  width: auto;
  position: relative;
  left: 18px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-pink .c-step3__item:last-child {
    background-image: url(../img/hoken/common/step3-bg2.png);
    flex: 1;
    min-width: 390px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-pink .c-step3__item:last-child {
    margin-left: -1px;
    padding-right: 8px;
    left: 1px;
    width: 37.2%;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-pink .c-step3__item:last-child.is-active {
    background-image: url(../img/hoken/common/step3-bg2-pink-active.png) !important;
  }
}
.c-step3.is-green .c-step3__item {
  padding: 7px 20px 5px 21px;
  height: 60px;
  width: 30%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-step3.is-green .c-step3__item {
    min-width: 378px;
    background: url(../img/hoken/common/step3-bg.png) no-repeat center right/contain;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item {
    overflow: hidden;
    width: 29.3%;
    padding: 7px 4px 5px 20px;
    height: 50px;
    background-color: #F3F3F3;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item:nth-child(1) {
    background: transparent;
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item:nth-child(1):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    right: 0;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
.c-step3.is-green .c-step3__item.is-active {
  z-index: 1;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .c-step3.is-green .c-step3__item.is-active {
    background-image: url(../img/hoken/common/step3-bg-green-active.png) !important;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active {
    background: #3FBF90;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1) {
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1):after {
    background-color: #3FBF90;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1) + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-green-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(2) {
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-green2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #3FBF90;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(2) + .c-step3__item {
    background: transparent;
    z-index: 3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(2) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:nth-child(2) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-green-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:last-child {
    z-index: 4;
    background-color: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:last-child:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-green2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item.is-active:last-child:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #3FBF90;
    position: absolute;
  }
}
.c-step3.is-green .c-step3__item:nth-child(2) {
  padding-left: 15px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-green .c-step3__item:nth-child(2) {
    background-image: url(../img/hoken/common/step3-bg1.png);
    margin-left: -4px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item:nth-child(2) {
    width: 33.5%;
    padding-left: 31px;
    z-index: 1;
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-green .c-step3__item:nth-child(2).is-active {
    background-image: url(../img/hoken/common/step3-bg1-green-active.png) !important;
  }
}
.c-step3.is-green .c-step3__item:last-child {
  padding-right: 38px;
  margin-left: -24px;
  background-position: left center;
  width: auto;
  position: relative;
  left: 18px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-green .c-step3__item:last-child {
    background-image: url(../img/hoken/common/step3-bg2.png);
    flex: 1;
    min-width: 390px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-green .c-step3__item:last-child {
    margin-left: -1px;
    padding-right: 8px;
    left: 1px;
    width: 37.2%;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-green .c-step3__item:last-child.is-active {
    background-image: url(../img/hoken/common/step3-bg2-green-active.png) !important;
  }
}
.c-step3.is-coopGreen .c-step3__item {
  padding: 7px 20px 5px 21px;
  height: 60px;
  width: 30%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-step3.is-coopGreen .c-step3__item {
    min-width: 378px;
    background: url(../img/hoken/common/step3-bg.png) no-repeat center right/contain;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item {
    overflow: hidden;
    width: 29.3%;
    padding: 7px 4px 5px 20px;
    height: 50px;
    background-color: #F3F3F3;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item:nth-child(1) {
    background: transparent;
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item:nth-child(1):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    right: 0;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
.c-step3.is-coopGreen .c-step3__item.is-active {
  z-index: 1;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .c-step3.is-coopGreen .c-step3__item.is-active {
    background-image: url(../img/hoken/common/step3-bg-coopGreen-active.png) !important;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active {
    background: #8CB340;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1) {
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1):after {
    background-color: #8CB340;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1) + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-coopGreen-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(2) {
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-coopGreen2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #8CB340;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(2) + .c-step3__item {
    background: transparent;
    z-index: 3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(2) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:nth-child(2) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-coopGreen-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:last-child {
    z-index: 4;
    background-color: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:last-child:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-coopGreen2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item.is-active:last-child:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #8CB340;
    position: absolute;
  }
}
.c-step3.is-coopGreen .c-step3__item:nth-child(2) {
  padding-left: 15px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-coopGreen .c-step3__item:nth-child(2) {
    background-image: url(../img/hoken/common/step3-bg1.png);
    margin-left: -4px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item:nth-child(2) {
    width: 33.5%;
    padding-left: 31px;
    z-index: 1;
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-coopGreen .c-step3__item:nth-child(2).is-active {
    background-image: url(../img/hoken/common/step3-bg1-coopGreen-active.png) !important;
  }
}
.c-step3.is-coopGreen .c-step3__item:last-child {
  padding-right: 38px;
  margin-left: -24px;
  background-position: left center;
  width: auto;
  position: relative;
  left: 18px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-coopGreen .c-step3__item:last-child {
    background-image: url(../img/hoken/common/step3-bg2.png);
    flex: 1;
    min-width: 390px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-coopGreen .c-step3__item:last-child {
    margin-left: -1px;
    padding-right: 8px;
    left: 1px;
    width: 37.2%;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-coopGreen .c-step3__item:last-child.is-active {
    background-image: url(../img/hoken/common/step3-bg2-coopGreen-active.png) !important;
  }
}
.c-step3.is-orange .c-step3__item {
  padding: 7px 20px 5px 21px;
  height: 60px;
  width: 30%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-step3.is-orange .c-step3__item {
    min-width: 378px;
    background: url(../img/hoken/common/step3-bg.png) no-repeat center right/contain;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item {
    overflow: hidden;
    width: 29.3%;
    padding: 7px 4px 5px 20px;
    height: 50px;
    background-color: #F3F3F3;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item:nth-child(1) {
    background: transparent;
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item:nth-child(1):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    right: 0;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
.c-step3.is-orange .c-step3__item.is-active {
  z-index: 1;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .c-step3.is-orange .c-step3__item.is-active {
    background-image: url(../img/hoken/common/step3-bg-orange-active.png) !important;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active {
    background: #FFAA48;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1) {
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1):after {
    background-color: #FFAA48;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1) + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-orange-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(2) {
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-orange2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #FFAA48;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(2) + .c-step3__item {
    background: transparent;
    z-index: 3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(2) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:nth-child(2) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-orange-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:last-child {
    z-index: 4;
    background-color: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:last-child:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-orange2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item.is-active:last-child:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #FFAA48;
    position: absolute;
  }
}
.c-step3.is-orange .c-step3__item:nth-child(2) {
  padding-left: 15px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-orange .c-step3__item:nth-child(2) {
    background-image: url(../img/hoken/common/step3-bg1.png);
    margin-left: -4px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item:nth-child(2) {
    width: 33.5%;
    padding-left: 31px;
    z-index: 1;
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-orange .c-step3__item:nth-child(2).is-active {
    background-image: url(../img/hoken/common/step3-bg1-orange-active.png) !important;
  }
}
.c-step3.is-orange .c-step3__item:last-child {
  padding-right: 38px;
  margin-left: -24px;
  background-position: left center;
  width: auto;
  position: relative;
  left: 18px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-orange .c-step3__item:last-child {
    background-image: url(../img/hoken/common/step3-bg2.png);
    flex: 1;
    min-width: 390px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-orange .c-step3__item:last-child {
    margin-left: -1px;
    padding-right: 8px;
    left: 1px;
    width: 37.2%;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-orange .c-step3__item:last-child.is-active {
    background-image: url(../img/hoken/common/step3-bg2-orange-active.png) !important;
  }
}
.c-step3.is-purple .c-step3__item {
  padding: 7px 20px 5px 21px;
  height: 60px;
  width: 30%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 641px) {
  .c-step3.is-purple .c-step3__item {
    min-width: 378px;
    background: url(../img/hoken/common/step3-bg.png) no-repeat center right/contain;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item {
    overflow: hidden;
    width: 29.3%;
    padding: 7px 4px 5px 20px;
    height: 50px;
    background-color: #F3F3F3;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item:nth-child(1) {
    background: transparent;
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item:nth-child(1):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    right: 0;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
.c-step3.is-purple .c-step3__item.is-active {
  z-index: 1;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .c-step3.is-purple .c-step3__item.is-active {
    background-image: url(../img/hoken/common/step3-bg-purple-active.png) !important;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active {
    background: #B689C2;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1) {
    z-index: 0;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1):after {
    background-color: #B689C2;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1) + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-purple-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item {
    background-color: transparent;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(1) + .c-step3__item + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(2) {
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-purple2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #B689C2;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(2) + .c-step3__item {
    background: transparent;
    z-index: 3;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(2) + .c-step3__item:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #F3F3F3;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:nth-child(2) + .c-step3__item:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-purple-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
    z-index: 1;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:last-child {
    z-index: 4;
    background-color: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:last-child:before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-purple2-sp-active.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item.is-active:last-child:after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background-color: #B689C2;
    position: absolute;
  }
}
.c-step3.is-purple .c-step3__item:nth-child(2) {
  padding-left: 15px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-purple .c-step3__item:nth-child(2) {
    background-image: url(../img/hoken/common/step3-bg1.png);
    margin-left: -4px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item:nth-child(2) {
    width: 33.5%;
    padding-left: 31px;
    z-index: 1;
    background: transparent;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item:nth-child(2):before {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 0;
    top: 0;
    background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left/27px auto;
    position: absolute;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item:nth-child(2):after {
    content: "";
    height: 100%;
    width: 100%;
    pointer-events: none;
    left: 27px;
    top: 0;
    background: #f3f3f3;
    position: absolute;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-purple .c-step3__item:nth-child(2).is-active {
    background-image: url(../img/hoken/common/step3-bg1-purple-active.png) !important;
  }
}
.c-step3.is-purple .c-step3__item:last-child {
  padding-right: 38px;
  margin-left: -24px;
  background-position: left center;
  width: auto;
  position: relative;
  left: 18px;
}
@media screen and (min-width: 641px) {
  .c-step3.is-purple .c-step3__item:last-child {
    background-image: url(../img/hoken/common/step3-bg2.png);
    flex: 1;
    min-width: 390px;
  }
}
@media screen and (max-width: 640px) {
  .c-step3.is-purple .c-step3__item:last-child {
    margin-left: -1px;
    padding-right: 8px;
    left: 1px;
    width: 37.2%;
  }
}
@media screen and (min-width: 641px) {
  .c-step3.is-purple .c-step3__item:last-child.is-active {
    background-image: url(../img/hoken/common/step3-bg2-purple-active.png) !important;
  }
}

/*
------------------------------------------------------------*/
.c-accordion__heading {
  background: #f3f3f3;
  text-align: center;
  padding: 15px 45px 13px;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .c-accordion__heading {
    text-align: left;
    padding: 14px 35px 9px 15px;
  }
}
.c-accordion__heading::before {
  content: "";
  width: 2px;
  height: 15px;
  background: #46483c;
  position: absolute;
  top: 50%;
  right: 26.5px;
  transform: translate(0, -50%);
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .c-accordion__heading::before {
    height: 10px;
    right: 19px;
  }
}
.c-accordion__heading::after {
  content: "";
  width: 15px;
  height: 2px;
  background: #46483c;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}
@media screen and (max-width: 640px) {
  .c-accordion__heading::after {
    width: 10px;
    right: 15px;
  }
}
.c-accordion__heading.is-active::before {
  transform: translate(0, -50%) rotate(90deg);
}
.c-accordion__heading.is-no-mark {
  cursor: initial;
}
.c-accordion__heading.is-no-mark::before {
  display: none;
}
.c-accordion__heading.is-no-mark::after {
  display: none;
}
.c-accordion__ttl {
  font-size: 1.8rem;
  line-height: 1.68;
  color: #46483c;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-accordion__ttl {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.c-accordion__info {
  background: #fff;
  border: 1px solid #f3f3f3;
  padding: 24px 20px 22px;
  display: none;
}
@media screen and (max-width: 640px) {
  .c-accordion__info {
    padding: 17px 19px 11px;
  }
}
.c-accordion__info.is-no-mark {
  display: block;
}
.c-accordion__txt {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #46483c;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-accordion__txt {
    text-align: left;
    font-size: 1.4rem;
  }
}

.c-banner1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(70, 72, 60, 0.5);
  z-index: 9999;
  padding: 0 40px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .c-banner1 {
    padding: 0 20px;
  }
}
.c-banner1.is-show {
  opacity: 1;
  visibility: visible;
}
.c-banner1__wrap {
  position: relative;
}
.c-banner1__close {
  position: absolute;
  top: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  z-index: 1;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .c-banner1__close {
    top: -12.5px;
    right: -12.5px;
    width: 25px;
    height: 25px;
  }
}
.c-banner1__btn {
  position: fixed;
  top: 30%;
  right: 0;
  width: 120px;
  height: 400px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 101;
  cursor: pointer;
}
.c-banner1__btn.is-active {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 640px) {
  .c-banner1__btn {
    width: 60px;
    height: 200px;
  }
}
.c-banner1__btn--horizon {
  top: inherit;
  bottom: 0;
  right: 5px;
  width: 400px;
  height: 120px;
}
@media screen and (max-width: 640px) {
  .c-banner1__btn--horizon {
    width: 200px;
    height: 60px;
  }
}

.c-hokenContactNavi {
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #41B4E6;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi {
    margin-top: 80px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.c-hokenContactNavi--mt0 {
  margin-top: 0;
}
.c-hokenContactNavi__title {
  margin-bottom: 15px;
  color: #fff;
  font-size: 2.2rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__title {
    margin-bottom: 40px;
    font-size: 3.2rem;
  }
}
.c-hokenContactNavi__list {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  background-color: #fff;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__list {
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}
.c-hokenContactNavi__card {
  padding-top: 20px;
  padding-bottom: 20px;
}
.c-hokenContactNavi__card:not(:first-child) {
  border-top: 1px dashed #D9D9D9;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card {
    padding: 40px 0;
    background-color: #fff;
    border-radius: 10px;
    flex: 1;
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;
  }
}
.c-hokenContactNavi__card__title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__title {
    column-gap: 10px;
    justify-content: center;
    font-size: 2.4rem;
  }
}
.c-hokenContactNavi__card__title__icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__title__icon {
    width: 30px;
    height: 30px;
  }
}
.c-hokenContactNavi__card__title__icon--soudan {
  color: #FA8246;
}
.c-hokenContactNavi__card__title__icon--document {
  color: #41B4E6;
}
.c-hokenContactNavi__card__title__icon--contact {
  color: #41B4E6;
}
.c-hokenContactNavi__card__title__icon--tel {
  color: #FA8246;
}
.c-hokenContactNavi__card__message {
  margin-top: 10px;
  color: #222;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__message {
    margin-top: 20px;
    text-align: center;
    font-size: 1.6rem;
  }
}
.c-hokenContactNavi__card__btn {
  margin-top: 10px;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__btn {
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: center;
  }
}
.c-hokenContactNavi__card__link {
  padding: 12px 20px;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 1.6rem;
  font-weight: bold;
  justify-content: center;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link {
    padding: 27px 0;
    min-height: auto;
    border-width: 3px;
    font-size: 2.4rem;
    column-gap: 10px;
    white-space: nowrap;
    transition: 0.3s ease-in-out;
  }
}
.c-hokenContactNavi__card__link--soudan {
  color: #FA8246;
  border-color: #FA8246;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link--soudan:hover {
    color: #fff;
    background-color: #FA8246;
  }
}
.c-hokenContactNavi__card__link--document, .c-hokenContactNavi__card__link--contact {
  color: #41B4E6;
  border-color: #41B4E6;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link--document:hover, .c-hokenContactNavi__card__link--contact:hover {
    color: #fff;
    background-color: #41B4E6;
  }
}
.c-hokenContactNavi__card__link--tel {
  color: #FA8246;
  border-color: #FA8246;
  column-gap: 5px;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link--tel {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: fit-content;
    border: none;
    column-gap: 10px;
  }
}
.c-hokenContactNavi__card__link__label {
  flex: 1;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link__label {
    flex: none;
  }
}
.c-hokenContactNavi__card__link__label--tel {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link__label--tel {
    column-gap: 10px;
  }
}
.c-hokenContactNavi__card__link__label--tel svg {
  min-width: 17px;
  width: 17px;
  height: 17px;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link__label--tel svg {
    min-width: 34px;
    width: 34px;
    height: 34px;
  }
}
.c-hokenContactNavi__card__link__icon {
  min-width: 12px;
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__card__link__icon {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
}
.c-hokenContactNavi__fullCard {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  color: #222;
  font-size: 1.4rem;
  border-top: 1px dashed #D9D9D9;
}
@media screen and (min-width: 641px) {
  .c-hokenContactNavi__fullCard {
    padding: 40px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border-top: none;
    row-gap: 10px;
    font-size: 1.6rem;
  }
}
.c-hokenContactNavi__fullCard a {
  width: fit-content;
  display: flex;
  align-items: baseline;
  column-gap: 2.5px;
  color: #41B4E6;
}
.c-hokenContactNavi__fullCard a::before {
  content: "▶";
}
.c-hokenContactNavi__fullCard a span {
  text-decoration: underline;
}

.c-hokenProduct01 {
  padding: 15px;
  border: 1.5px solid #D9D9D9;
  border-radius: 5px;
  display: grid;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .c-hokenProduct01 {
    padding: 30px;
    border-width: 3px;
    border-radius: 10px;
    grid-template-columns: 450px 1fr;
    column-gap: 30px;
  }
}
.c-hokenProduct01__image img {
  width: 100%;
  height: auto;
}
.c-hokenProduct01__subTitle {
  color: #222;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .c-hokenProduct01__subTitle {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.6rem;
  }
}
.c-hokenProduct01__title {
  color: #222;
  font-size: 1.8rem;
}
@media screen and (min-width: 641px) {
  .c-hokenProduct01__title {
    font-size: 2.4rem;
  }
}
.c-hokenProduct01__action {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media screen and (min-width: 641px) {
  .c-hokenProduct01__action {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 640px) {
  .c-hokenProduct01__action > :nth-child(1),
  .c-hokenProduct01__action > :nth-child(2) {
    flex: 1;
  }
  .c-hokenProduct01__action > :nth-child(3) {
    width: 100%;
  }
}

.c-hokenAnchor {
  position: relative;
  top: -40px;
}
@media screen and (min-width: 641px) {
  .c-hokenAnchor {
    top: -80px;
  }
}

.p-hokenPageInNav {
  display: grid;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenPageInNav {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 40px;
  }
}
.p-hokenPageInNav__link1 {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  border-bottom: 1px solid #D9D9D9;
  color: #41B4E6;
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenPageInNav__link1 {
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 2rem;
    column-gap: 20px;
  }
}
.p-hokenPageInNav__link1__icon {
  min-width: 14px;
  width: 14px;
  height: 14px;
}
@media screen and (min-width: 641px) {
  .p-hokenPageInNav__link1__icon {
    margin-left: auto;
    min-width: 18px;
    width: 18px;
    height: 18px;
    order: 2;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenPageInNav__link1__text {
    order: 1;
  }
}
.p-hokenPageInNav__children {
  padding-left: 25px;
  padding-right: 25px;
}
@media screen and (min-width: 641px) {
  .p-hokenPageInNav__children {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.p-hokenPageInNav__children > li {
  margin-top: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenPageInNav__children > li {
    margin-top: 10px;
  }
}
.p-hokenPageInNav__link2 {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: underline;
}
@media screen and (min-width: 641px) {
  .p-hokenPageInNav__link2 {
    text-decoration: none;
  }
}

/*------------------------------------------------------------
サービスサイトTOP
------------------------------------------------------------*/
.p-serviceTop01 {
  width: 100%;
  min-width: 1120px;
  height: 100vh;
  min-height: 640px;
  margin: 0 auto 57px auto;
  position: relative;
}
.p-serviceTop01 .p-serviceTop01__contents01 {
  width: 90%;
  max-width: 1120px;
  margin: 0 auto;
  position: absolute;
  top: 22%;
  left: 0;
  right: 0;
  z-index: 2000;
}
.p-serviceTop01 .p-serviceTop01__contents01 h1 {
  width: 100%;
  height: 12.05vw;
  max-height: 150px;
  min-height: 135px;
  margin-bottom: 15px;
  background-image: url("../img/service/top_mvTxtPC.png");
  -webkit-background-size: cover;
  background-size: cover;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li {
  width: 18.57%;
  height: 16.47vw;
  max-height: 205px;
  min-height: 184px;
  margin-right: 1.7875%;
  background-image: url("../img/service/bg01.jpg");
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:after {
  width: 25px;
  height: 25px;
  content: "";
  background-image: url("../img/common/icon_btn-green01.png");
  position: absolute;
  bottom: 0;
  right: 0;
  transition: 0.3s ease-in-out;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:before {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 4px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:hover:after {
  opacity: 0;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:hover:before {
  opacity: 1;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:nth-child(1):before {
  border-color: #fc819b;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:nth-child(2):before {
  border-color: #82cdf3;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:nth-child(3):before {
  border-color: #68ba93;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:nth-child(4):before {
  border-color: #faad5a;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:nth-child(5) {
  margin: 0;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li:nth-child(5):before {
  border-color: #af8ebe;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li .c-cateIcon01 {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  -webkit-background-size: 50%;
  background-size: 50%;
  position: absolute;
  top: 13%;
  left: 0;
  right: 0;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li .c-cateIcon01--kurashi + a {
  margin-top: -0.5em;
}
.p-serviceTop01 .p-serviceTop01__contents01 ul li a {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.2;
  position: absolute;
  top: 68%;
  left: 0;
}
.p-serviceTop01 .p-serviceTop01__slide {
  width: 99%;
  margin: 0 auto;
}
.p-serviceTop01 .p-serviceTop01__slide li {
  width: 100%;
  height: 100vh;
  min-height: 640px;
  overflow: hidden;
  -webkit-border-radius: 60px;
  border-radius: 60px;
}
.p-serviceTop01 .p-serviceTop01__slide li span {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center top;
  -webkit-transition: transform 6s ease-out;
  transition: transform 6s ease-out;
}
.p-serviceTop01 .p-serviceTop01__slide li span.p-serviceTop01__slide__img01 {
  background-image: url("../img/service/top_mv01.jpg");
}
.p-serviceTop01 .p-serviceTop01__slide li span.p-serviceTop01__slide__img02 {
  background-image: url("../img/service/top_mv02.jpg");
}
.p-serviceTop01 .p-serviceTop01__slide li span.p-serviceTop01__slide__img03 {
  background-image: url("../img/service/top_mv03.jpg");
}
.p-serviceTop01 .p-serviceTop01__slide li.u-moving span {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.p-serviceTop01 .p-serviceTop01__slide .sp-only {
  display: none !important;
}
.p-serviceTop01 .p-serviceTop01__scroll {
  width: 100px;
  margin: 0 auto;
  position: absolute;
  top: 92%;
  left: 0;
  right: 0;
  z-index: 2000;
}
.p-serviceTop01 .p-serviceTop01__bird {
  width: 100%;
  max-width: 1360px;
  min-width: 1120px;
  margin: 0 auto;
  text-align: right;
  position: absolute;
  top: 12%;
  left: 0;
  right: 0;
  z-index: 1000;
}
.p-serviceTop01 .p-serviceTop01__bird img {
  width: 15.67vw;
  max-width: 195px;
  min-width: 175px;
}
.p-serviceTop01 .p-serviceTop01__slideFrame {
  width: 100%;
  min-width: 1120px;
  height: 100%;
  margin: 0 auto;
  border: solid 20px #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  -webkit-border-radius: 60px;
  border-radius: 60px;
}
.p-serviceTop01 .p-serviceTop01__slideFrame:before {
  width: calc(100% + 40px);
  height: 220px;
  content: "";
  background-image: url("../img/service/top_topSlidePC.png");
  background-position: center bottom;
  position: absolute;
  top: -20px;
  left: -20px;
}
.p-serviceTop01 .p-serviceTop01__slideFrame:after {
  width: calc(100% + 40px);
  height: 467px;
  content: "";
  background-image: url("../img/service/top_bottomSlidePC.png");
  background-position: center top;
  position: absolute;
  bottom: -20px;
  left: -20px;
}

.p-serviceTop02 {
  margin-bottom: 27px;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__img {
  width: 440px;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt {
  width: 640px;
  padding-top: 52px;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt .pc-only {
  width: 100%;
  height: 196px;
  padding: 28px 105px;
  text-align: center;
  background-image: url("../img/common/icon_arrow-green04.png");
  -webkit-background-size: 13px;
  background-size: 13px;
  background-position: 590px center;
  background-repeat: no-repeat;
  border: solid 6px #8cb340;
  -webkit-border-radius: 98px;
  border-radius: 98px;
  transition: 0.3s ease-in-out;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt .pc-only:hover {
  background-color: #8cb340;
  background-image: url("../img/common/icon_arrow-white02.png");
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt .pc-only:hover h2:before {
  background-image: url("../img/common/icon_biginer02.png");
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt .pc-only:hover h2 a {
  color: #ffffff;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt .pc-only:hover p {
  color: #ffffff;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt h2 {
  margin-bottom: 7px;
  font-size: 2.4rem;
  font-weight: bold;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt h2:before {
  width: 19px;
  height: 25px;
  margin: -3px 15px 0 0;
  content: "";
  background-image: url("../img/common/icon_biginer01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s ease-in-out;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt h2 a {
  color: #8cb340;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt p {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #46483c;
  transition: 0.3s ease-in-out;
  font-weight: 500;
}
.p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt p em {
  font-style: normal;
}

.p-serviceTop03 {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding: 88px 0 85px;
  background-image: url("../img/service/bg02.jpg");
  overflow: hidden;
  position: relative;
}
.p-serviceTop03:before {
  width: 100%;
  height: 20px;
  content: "";
  background-image: url("../img/service/line_topBg02.png");
  background-repeat: repeat-x;
  background-position: center 0;
  position: absolute;
  top: 0;
  left: 0;
}
.p-serviceTop03:after {
  width: 100%;
  height: 4px;
  content: "";
  background-image: url("../img/service/line_colorful01_pc.jpg");
  background-repeat: repeat-x;
  background-position: center 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.p-serviceTop03 .p-serviceTop03__contents01 {
  padding: 40px;
  background-color: #ffffff;
  position: relative;
  z-index: 100;
}
.p-serviceTop03 .p-serviceTop03__contents01 h2 {
  margin-bottom: 11px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #46483c;
}
.p-serviceTop03 .p-serviceTop03__contents01 h2:before {
  width: 31px;
  height: 26px;
  margin: -5px 9px 0 0;
  content: "";
  background-image: url("../img/common/icon_news01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-serviceTop03 .p-serviceTop03__contents01 h2 span {
  margin: -0.25em 0 0 12px;
  letter-spacing: 1px;
  font-size: 1.4rem;
  font-weight: normal;
  color: #999999;
  display: inline-block;
  vertical-align: middle;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList {
  border-top: dashed 1px #dddddd;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li {
  padding: 20px 0 16px;
  border-bottom: dashed 1px #dddddd;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li p {
  width: 785px;
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li a {
  width: 780px;
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList .p-serviceTop03__contents01__newsList_data {
  width: 128px;
  font-size: 1.6rem;
  color: #999999;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList .p-serviceTop03__contents01__newsList_data:before {
  width: 5px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-green01.png");
  display: inline-block;
  vertical-align: middle;
}
.p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList .c-newsCate01 {
  margin-right: 30px;
}
.p-serviceTop03 .p-serviceTop03__contents01 .c-serviceBtn01 {
  position: absolute;
  top: 42px;
  right: 40px;
}
.p-serviceTop03 .p-serviceTop03__img {
  width: 1120px;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.p-serviceTop03 .p-serviceTop03__img .p-serviceTop03__img__wood {
  position: absolute;
  left: -231px;
  bottom: -6px;
}
.p-serviceTop03 .p-serviceTop03__img .p-serviceTop03__img__bird {
  position: absolute;
  top: 110px;
  right: -100px;
}

.p-serviceTop04 {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding: 80px 0 100px;
  background-image: url("../img/service/bg03.jpg");
}
.p-serviceTop04 .c-serviceTitle01 {
  margin-bottom: 55px;
}
.p-serviceTop04 .p-serviceTop04__contents {
  margin-bottom: 60px;
}

@media screen and (max-width: 640px) {
  .p-serviceTop01 {
    min-width: 100%;
    height: 143.75vw;
    min-height: auto;
    margin-bottom: 35px;
  }
  .p-serviceTop01 .p-serviceTop01__contents01 {
    width: 100%;
    padding: 0 20px;
    top: 14%;
  }
  .p-serviceTop01 .p-serviceTop01__contents01 h1 {
    width: 198px;
    height: 132px;
    margin: -3px auto 13px auto;
    max-height: 240px;
    min-height: auto;
    background-image: url("../img/service/top_mvTxtSP.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .p-serviceTop01 .p-serviceTop01__contents01 ul li {
    width: 30%;
    height: 37.5vw;
    max-height: 240px;
    min-height: auto;
    margin: 0 4.5% 2.9% 0;
    border-radius: 3px;
  }
  .p-serviceTop01 .p-serviceTop01__contents01 ul li:after {
    width: 15px;
    height: 15px;
  }
  .p-serviceTop01 .p-serviceTop01__contents01 ul li:first-child {
    margin-left: 13px;
  }
  .p-serviceTop01 .p-serviceTop01__contents01 ul li .c-cateIcon01 {
    width: 16vw;
    height: 16vw;
    -webkit-background-size: 60%;
    background-size: 60%;
    top: 12%;
    left: 0;
    right: 0;
  }
  .p-serviceTop01 .p-serviceTop01__contents01 ul li a {
    font-size: 1.4rem;
    top: 69%;
  }
  .p-serviceTop01 .p-serviceTop01__slide {
    width: 100%;
  }
  .p-serviceTop01 .p-serviceTop01__slide li {
    height: auto;
    min-height: auto;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .p-serviceTop01 .p-serviceTop01__slide li img {
    -webkit-transition: transform 6s ease-out;
    transition: transform 6s ease-out;
  }
  .p-serviceTop01 .p-serviceTop01__slide li.u-moving img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .p-serviceTop01 .p-serviceTop01__slide .pc-only {
    display: none !important;
  }
  .p-serviceTop01 .p-serviceTop01__slide .sp-only {
    display: block !important;
  }
  .p-serviceTop01 .p-serviceTop01__bird {
    min-width: 100%;
    top: 5%;
  }
  .p-serviceTop01 .p-serviceTop01__bird img {
    width: 30.78vw;
    max-width: 197px;
    min-width: auto;
  }
  .p-serviceTop01 .p-serviceTop01__slideFrame {
    min-width: 100%;
    height: 87%;
    background-image: url("../img/service/top_bgSlideSP.png");
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .p-serviceTop01 .p-serviceTop01__slideFrame:before, .p-serviceTop01 .p-serviceTop01__slideFrame:after {
    content: none;
  }
  .p-serviceTop02 {
    margin-bottom: 36px;
    padding: 0 20px;
  }
  .p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__img {
    width: 100%;
    margin-bottom: 18px;
  }
  .p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt {
    width: 100%;
    padding: 0;
  }
  .p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt h2 {
    padding: 0;
    font-size: 1.6rem;
    text-align: center;
    background-image: url("../img/common/icon_arrow-green04.png");
    -webkit-background-size: 6px;
    background-size: 6px;
    background-position: 96% center;
    background-repeat: no-repeat;
    border: solid 3px #8cb340;
    margin-bottom: 15px;
    -webkit-border-radius: 98px;
    border-radius: 98px;
  }
  .p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt h2:before {
    content: none;
  }
  .p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt h2 a {
    padding: 13px 10px;
    display: block;
  }
  .p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt h2 a:before {
    width: 14px;
    height: 18px;
    margin: -3px 9px 0 0;
    content: "";
    background-image: url("../img/common/icon_biginer01.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .p-serviceTop02 .p-serviceTop02__contents01 .p-serviceTop02__contents01__txt p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-serviceTop03 {
    min-width: 100%;
    padding: 35px 0 45px;
  }
  .p-serviceTop03:before {
    content: none;
  }
  .p-serviceTop03:after {
    height: 5px;
    background-image: url("../img/service/line_colorful01_sp.jpg");
  }
  .p-serviceTop03 .p-serviceTop03__contents01 {
    padding: 0 20px;
    background: none;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 h2 {
    margin-bottom: 16px;
    font-size: 1.6rem;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 h2:before {
    width: 20px;
    height: 17px;
    margin: -3px 10px 0 0;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 h2 span {
    margin: -0.25em 0 0 10px;
    font-size: 1.2rem;
    letter-spacing: 0px;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList {
    margin-bottom: 18px;
    padding: 4px 20px;
    background-color: #ffffff;
    border: none;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li {
    padding: 15px 0 12px;
    align-items: flex-end;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li:last-child {
    border: none;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li a {
    width: 100%;
    padding-left: 20px;
    line-height: 1.4;
    font-size: 1.4rem;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList li a:before {
    width: 5px;
    height: 12px;
    margin: -3px 15px 0 -20px;
    content: "";
    background-image: url("../img/common/icon_arrow-green01.png");
    display: inline-block;
    vertical-align: middle;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList .p-serviceTop03__contents01__newsList_data {
    width: auto;
    margin: 0 11px 11px 0;
    font-size: 1.2rem;
    letter-spacing: -0.5px;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList .p-serviceTop03__contents01__newsList_data:before {
    content: none;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__newsList .c-newsCate01 {
    margin: 0 0 13px 0;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .c-serviceBtn01 {
    position: static;
    background-position: 88% center;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .c-serviceBtn01 a {
    padding: 6px 20px;
  }
  .p-serviceTop03 .p-serviceTop03__contents01 .p-serviceTop03__contents01__bird {
    width: 30px;
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .p-serviceTop04 {
    min-width: 100%;
    padding: 40px 20px 59px;
  }
  .p-serviceTop04 .c-serviceTitle01 {
    margin-bottom: 14px;
  }
  .p-serviceTop04 .p-serviceTop04__contents {
    margin-bottom: 30px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
サービスサイト　お知らせ一覧
------------------------------------------------------------*/
.p-serviceInfoIndex02__tab {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-serviceInfoIndex02__tab a {
  display: table;
  height: 70px;
  width: 14.2857142857%;
  background: url("../img/service/service_info_tab_bg.png");
  border-bottom: 3px solid transparent;
  border-left: 1px solid #fff;
}
.p-serviceInfoIndex02__tab a:nth-of-type(1) {
  background-color: #a4c661;
  border-bottom-color: #a4c661;
  border-left: none;
}
.p-serviceInfoIndex02__tab a:nth-of-type(1).current, .p-serviceInfoIndex02__tab a:nth-of-type(1):hover {
  background-color: #a4c661;
}
.p-serviceInfoIndex02__tab a:nth-of-type(2) {
  background-color: #8cb340;
  border-bottom-color: #8cb340;
}
.p-serviceInfoIndex02__tab a:nth-of-type(2).current, .p-serviceInfoIndex02__tab a:nth-of-type(2):hover {
  background-color: #8cb340;
}
.p-serviceInfoIndex02__tab a:nth-of-type(3) {
  background-color: #fc819b;
  border-bottom-color: #fc819b;
}
.p-serviceInfoIndex02__tab a:nth-of-type(3).current, .p-serviceInfoIndex02__tab a:nth-of-type(3):hover {
  background-color: #fc819b;
}
.p-serviceInfoIndex02__tab a:nth-of-type(4) {
  background-color: #82cdf3;
  border-bottom-color: #82cdf3;
}
.p-serviceInfoIndex02__tab a:nth-of-type(4).current, .p-serviceInfoIndex02__tab a:nth-of-type(4):hover {
  background-color: #82cdf3;
}
.p-serviceInfoIndex02__tab a:nth-of-type(5) {
  background-color: #68ba93;
  border-bottom-color: #68ba93;
}
.p-serviceInfoIndex02__tab a:nth-of-type(5).current, .p-serviceInfoIndex02__tab a:nth-of-type(5):hover {
  background-color: #68ba93;
}
.p-serviceInfoIndex02__tab a:nth-of-type(6) {
  background-color: #faad5a;
  border-bottom-color: #faad5a;
}
.p-serviceInfoIndex02__tab a:nth-of-type(6).current, .p-serviceInfoIndex02__tab a:nth-of-type(6):hover {
  background-color: #faad5a;
}
.p-serviceInfoIndex02__tab a:nth-of-type(7) {
  background-color: #af8ebe;
  border-bottom-color: #af8ebe;
}
.p-serviceInfoIndex02__tab a:nth-of-type(7).current, .p-serviceInfoIndex02__tab a:nth-of-type(7):hover {
  background-color: #af8ebe;
}
.p-serviceInfoIndex02__tab a.current, .p-serviceInfoIndex02__tab a:hover {
  background: transparent;
  opacity: 1;
}
.p-serviceInfoIndex02__tab a.current span, .p-serviceInfoIndex02__tab a:hover span {
  font-weight: bold;
  color: #fff;
}
.p-serviceInfoIndex02__tab a span {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 1.6rem;
  color: #46483c;
  line-height: 1.3;
  padding-top: 5px;
}

.p-serviceInfoIndex03 {
  margin-top: 19px;
}
.p-serviceInfoIndex03__card a {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px dashed #dddddd;
}
.p-serviceInfoIndex03__card a:hover {
  opacity: 1;
}
.p-serviceInfoIndex03__card a:hover .p-serviceInfoIndex03__card__img {
  opacity: 0.8;
}
.p-serviceInfoIndex03__card a:hover .p-serviceInfoIndex03__card__title--txt {
  text-decoration: none;
}
.p-serviceInfoIndex03__card__img {
  flex-shrink: 0;
  width: 200px;
  transition: 0.3s ease-in-out;
}
.p-serviceInfoIndex03__card__img img {
  width: 100%;
}
.p-serviceInfoIndex03__card__content {
  padding-left: 20px;
}
.p-serviceInfoIndex03__card__content .u-flex {
  margin-top: 13px;
}
.p-serviceInfoIndex03__card__content .u-flex .c-newsCate01 {
  min-width: 100px;
  width: auto;
  padding: 0 10px;
}
.p-serviceInfoIndex03__card__content .u-flex .c-newsCate01.c-newsCate01--area {
  background-color: #a4c661;
}
.p-serviceInfoIndex03__card__content .u-flex .c-newsCate01.c-newsCate01--cls {
  background-color: #a4c661;
}
.p-serviceInfoIndex03__card__date {
  font-size: 1.6rem;
  font-weight: normal;
  color: #999999;
  margin-top: -4px;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
.p-serviceInfoIndex03__card__title {
  margin-top: 14px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-serviceInfoIndex03__card__title--txt {
  text-decoration: underline;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-serviceInfoIndex03__card .u-flex {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: space-between;
    align-content: space-between;
    margin-top: 0;
  }
}

.p-serviceInfoIndex04 {
  margin: 40px 0 100px;
}
.p-serviceInfoIndex04 * {
  margin-left: 10px;
}
.p-serviceInfoIndex04 *:first-child {
  margin-left: 0;
}
.p-serviceInfoIndex04 .wp-pagenavi {
  display: flex;
}
.p-serviceInfoIndex04 .wp-pagenavi span.current {
  background-color: #8cb340;
  border: solid 3px #8cb340;
  color: #fff;
  display: block;
  width: 60px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3;
  text-align: center;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
.p-serviceInfoIndex04 .wp-pagenavi span.current:hover {
  border-color: #8cb340;
}
.p-serviceInfoIndex04 .wp-pagenavi .previouspostslink {
  display: block;
  width: 120px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3;
  color: #46483c;
  text-align: center;
  border: solid 3px #dddddd;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
.p-serviceInfoIndex04 .wp-pagenavi .previouspostslink:before {
  width: 6px;
  height: 10px;
  margin: -2px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-green04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}
.p-serviceInfoIndex04 .wp-pagenavi .previouspostslink:hover {
  border-color: #8cb340;
}
.p-serviceInfoIndex04 .wp-pagenavi .nextpostslink {
  display: block;
  width: 120px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3;
  color: #46483c;
  text-align: center;
  border: solid 3px #dddddd;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
.p-serviceInfoIndex04 .wp-pagenavi .nextpostslink:after {
  width: 6px;
  height: 10px;
  margin: -2px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-green04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-serviceInfoIndex04 .wp-pagenavi .nextpostslink:hover {
  border-color: #8cb340;
}
.p-serviceInfoIndex04 .wp-pagenavi .page {
  display: block;
  width: 60px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3;
  color: #46483c;
  text-align: center;
  border: solid 3px #dddddd;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
.p-serviceInfoIndex04 .wp-pagenavi .page:hover {
  border-color: #8cb340;
}
.p-serviceInfoIndex04 .wp-pagenavi .pages, .p-serviceInfoIndex04 .wp-pagenavi .extend, .p-serviceInfoIndex04 .wp-pagenavi .last {
  display: none;
}

@media screen and (max-width: 640px) {
  .p-serviceInfoIndex {
    padding-top: 29px;
  }
  .p-serviceInfoIndex01 .c-serviceTitle01 {
    margin-bottom: 25px;
    font-size: 2.1rem;
  }
  .p-serviceInfoIndex01 .c-serviceTitle01:before {
    margin-bottom: 3px;
  }
  .p-serviceInfoIndex01 .c-serviceTitle01 span {
    font-size: 1.4rem;
  }
  .p-serviceInfoIndex02__tab {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .p-serviceInfoIndex02__tab.active {
    border-bottom: 2px solid #a4c661;
  }
  .p-serviceInfoIndex02__tab a {
    width: 25%;
    height: 41px;
    border-bottom: none;
    box-sizing: border-box;
  }
  .p-serviceInfoIndex02__tab a:not(:nth-of-type(3n+1)) {
    border-left: 2px solid #fff;
  }
  .p-serviceInfoIndex02__tab a:not(:nth-of-type(-n+3)) {
    border-top: 2px solid #fff;
  }
  .p-serviceInfoIndex02__tab a span {
    font-size: 1.2rem;
    line-height: 1.2;
    padding-top: 0;
  }
  .p-serviceInfoIndex02__tab div {
    width: 25%;
    height: 40px;
  }
  .p-serviceInfoIndex03 {
    margin-top: 11px;
    padding: 0 20px;
  }
  .p-serviceInfoIndex03__card {
    display: block;
    line-height: 1.8;
    margin: -4px 0 -8px;
  }
  .p-serviceInfoIndex03__card__img {
    display: none;
  }
  .p-serviceInfoIndex03__card__content {
    padding-left: 0;
    width: 100%;
  }
  .p-serviceInfoIndex03__card__date {
    font-size: 1.2rem;
    letter-spacing: 1.1px;
    margin-top: 0;
    font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
  }
  .p-serviceInfoIndex03__card__title {
    width: 100%;
    margin-top: 15px;
    font-size: 1.4rem;
    display: flex;
    align-items: flex-start;
  }
  .p-serviceInfoIndex03__card__title--img {
    min-width: 36%;
    max-width: 36%;
    margin-right: 15px;
  }
  .p-serviceInfoIndex03__card__title--img img {
    min-width: 36%;
    width: 100%;
  }
  .p-serviceInfoIndex03__card__title--txt {
    width: 64%;
  }
  .p-serviceInfoIndex03 .u-flex .c-newsCate01 {
    margin: 13px 5px 0 0;
    font-size: 1.2rem;
    min-width: 90px;
    height: 20px;
    padding: 1px 5px;
  }
  .p-serviceInfoIndex04 {
    margin-bottom: 91px;
    position: relative;
  }
  .p-serviceInfoIndex04 * {
    margin-left: 0;
  }
  .p-serviceInfoIndex04 .wp-pagenavi .pages, .p-serviceInfoIndex04 .wp-pagenavi .extend, .p-serviceInfoIndex04 .wp-pagenavi .last {
    display: block !important;
    font-size: 1.4rem;
    color: #46483c;
    line-height: 3;
  }
  .p-serviceInfoIndex04 .wp-pagenavi .current, .p-serviceInfoIndex04 .wp-pagenavi .page {
    display: none !important;
  }
  .p-serviceInfoIndex04 .wp-pagenavi .previouspostslink {
    position: absolute;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    left: 25px;
    width: 40px;
    height: 40px;
    padding: 10px 0 0 12px;
    border: solid 1px #dddddd;
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }
  .p-serviceInfoIndex04 .wp-pagenavi .previouspostslink:before {
    width: 10px;
    height: 17px;
    margin: 0;
    display: block;
  }
  .p-serviceInfoIndex04 .wp-pagenavi .nextpostslink {
    position: absolute;
    right: 25px;
    width: 40px;
    height: 40px;
    padding: 8px 0 0 16px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    border: solid 1px #dddddd;
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }
  .p-serviceInfoIndex04 .wp-pagenavi .nextpostslink:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    height: 17px;
    background-image: url("../img/common/icon_arrow-green04.png");
    margin: auto;
    display: block;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
サービスサイト お知らせ詳細
------------------------------------------------------------*/
@media screen and (max-width: 640px) {
  .p-serviceInfoDetail .u-flex {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .p-serviceInfoDetail .c-newsCate01 {
    margin-top: 10px;
  }
}

.p-serviceInfoDetail02 {
  margin-bottom: 40px;
  padding: 43px 40px 28px;
  background-image: url("../img/service/bg03.jpg");
  position: relative;
}
.p-serviceInfoDetail02:before {
  width: 100%;
  height: 4px;
  content: "";
  background-image: url("../img/service/line_colorful01_pc.jpg");
  background-repeat: repeat-x;
  background-position: center 0;
  position: absolute;
  top: 0;
  left: 0;
}
.p-serviceInfoDetail02 .p-serviceInfoDetail02__data {
  margin-bottom: 10px;
  font-size: 1.6rem;
  color: #999999;
}
@media screen and (max-width: 640px) {
  .p-serviceInfoDetail02 .p-serviceInfoDetail02__data {
    margin-bottom: 0;
    font-size: 1.2rem;
  }
}
.p-serviceInfoDetail02 h2 {
  margin-top: 9px;
  font-size: 3.2rem;
  font-weight: bold;
  color: #46483c;
}

.p-serviceInfoDetail03 {
  margin-bottom: 40px;
  color: #46483c;
}
.p-serviceInfoDetail03 h3 {
  margin-bottom: 20px;
  padding: 20px 30px;
  font-size: 2.4rem;
  line-height: 1.3;
  color: #ffffff;
  background-color: #8cb340;
}
.p-serviceInfoDetail03 h4 {
  margin-bottom: 20px;
  padding: 0 0 10px 15px;
  font-size: 2rem;
  line-height: 1.4;
  font-weight: bold;
  color: #669900;
  border-bottom: solid 2px #669900;
  position: relative;
}
.p-serviceInfoDetail03 h4:before {
  width: 6px;
  height: calc(100% - 10px);
  content: "";
  background-color: #669900;
  position: absolute;
  top: 0;
  left: 0;
}
.p-serviceInfoDetail03 h5 {
  margin-bottom: 7px;
  padding-left: 24px;
  font-size: 1.8rem;
  font-weight: normal;
  color: #669900;
}
.p-serviceInfoDetail03 h5:before {
  width: 14px;
  height: 14px;
  margin: -4px 10px 0 -24px;
  content: "";
  background-color: #669900;
  display: inline-block;
  vertical-align: middle;
}
.p-serviceInfoDetail03 strong {
  font-weight: normal;
  color: #ef004f;
}
.p-serviceInfoDetail03 a {
  color: #308bbb;
  text-decoration: underline;
}
.p-serviceInfoDetail03 a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-serviceInfoDetail03 p {
  margin-bottom: 2px;
  font-size: 1.6rem;
  line-height: 2;
  *zoom: 1;
}
.p-serviceInfoDetail03 p:after {
  display: block;
  content: "";
  clear: both;
}
.p-serviceInfoDetail03 p + h4 {
  margin-top: 41px;
}
.p-serviceInfoDetail03 p + h5 {
  margin-top: 37px;
}
.p-serviceInfoDetail03 ol {
  margin: 0 0 13px 1em;
  font-size: 1.6rem;
  line-height: 2.1;
  counter-reset: count01;
}
.p-serviceInfoDetail03 ol > li {
  padding-left: 32px;
  counter-increment: count01;
}
.p-serviceInfoDetail03 ol > li:before {
  width: 32px;
  margin-left: -32px;
  content: counter(count01) ".";
  font-weight: bold;
  display: inline-block;
}
.p-serviceInfoDetail03 ul {
  margin: 0 0 14px 1em;
  font-size: 1.6rem;
  line-height: 2.1;
}
.p-serviceInfoDetail03 ul > li {
  padding-left: 24px;
}
.p-serviceInfoDetail03 ul > li:before {
  width: 8px;
  height: 8px;
  margin: 0 16px 0 -24px;
  content: "";
  display: inline-block;
  background-color: #8cb340;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-serviceInfoDetail03 img {
  max-width: 560px;
}
.p-serviceInfoDetail03 img.alignleft {
  margin: 0 20px 20px 0;
  float: left;
}
.p-serviceInfoDetail03 img.alignright {
  margin: 9px 0 20px 20px;
  float: right;
}
.p-serviceInfoDetail03 img.aligncenter {
  margin: 0 auto 20px auto;
  display: block;
}
.p-serviceInfoDetail03 table {
  width: 100% !important;
  margin: 28px 0 53px;
  font-size: 1.6rem;
  line-height: 2;
  border-collapse: collapse;
}
.p-serviceInfoDetail03 table tr td {
  padding: 10px 20px;
  background-color: #ffffff;
  border: solid 1px #ced5e0;
}
.p-serviceInfoDetail03 table tr td:first-of-type {
  padding: 10px 20px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  background-color: #a4c661;
  border: solid 1px #ced5e0;
  width: 350px;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox01 {
  margin-bottom: 40px;
  padding: 31px 36px 27px;
  border: solid 4px #eeeeee;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox01 h4 {
  padding: 0 0 16px;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox01 h4:before {
  display: none;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox01 *:last-child {
  margin-bottom: 0;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox02 {
  margin-bottom: 30px;
  padding: 36px;
  background-color: #f9f9f9;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox02 h4 {
  padding-left: 0;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox02 h4:before {
  display: none;
}
.p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox02 *:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .p-serviceInfoDetail03 .youtube-ar {
    width: 100%;
    aspect-ratio: 16/9;
  }
}
.p-serviceInfoDetail03 .youtube-ar iframe {
  margin: 60px 0px;
}
@media screen and (max-width: 640px) {
  .p-serviceInfoDetail03 .youtube-ar iframe {
    margin: 30px 0;
    width: 100%;
    height: 100%;
  }
}

.p-serviceInfoDetail04 {
  margin-bottom: 100px;
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__prev {
  width: 120px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3;
  color: #46483c;
  text-align: center;
  border: solid 3px #dddddd;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
@media screen and (-ms-high-contrast: none) {
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__prev {
    padding-top: 3px;
  }
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__prev:before {
  width: 6px;
  height: 10px;
  margin: -2px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-green04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__prev:hover {
  border-color: #8cb340;
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__next {
  width: 120px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3;
  color: #46483c;
  text-align: center;
  border: solid 3px #dddddd;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
@media screen and (-ms-high-contrast: none) {
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__next {
    padding-top: 3px;
  }
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__next:after {
  width: 6px;
  height: 10px;
  margin: -2px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-green04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__next:hover {
  border-color: #8cb340;
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__back {
  height: 60px;
  padding: 0 40px;
  margin: 0 10px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 3;
  text-align: center;
  background-color: #8cb340;
  border: solid 3px #8cb340;
  -webkit-border-radius: 30px;
  border-radius: 30px;
}
@media screen and (-ms-high-contrast: none) {
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__back {
    padding-top: 3px;
  }
}
.p-serviceInfoDetail04 .p-serviceInfoDetail04__back:hover {
  color: #8cb340;
  background-color: #ffffff;
}

@media screen and (max-width: 640px) {
  .p-serviceInfoDetail {
    padding-top: 29px;
  }
  .p-serviceInfoDetail01 .c-serviceTitle01 {
    margin-bottom: 24px;
    font-size: 2.1rem;
  }
  .p-serviceInfoDetail01 .c-serviceTitle01:before {
    margin-bottom: 3px;
  }
  .p-serviceInfoDetail01 .c-serviceTitle01 span {
    font-size: 1.4rem;
  }
  .p-serviceInfoDetail02 {
    margin: 24px 20px 30px;
    padding: 27px 20px 26px;
  }
  .p-serviceInfoDetail02:before {
    height: 4px;
    background-image: url("../img/service/line_colorful01_sp.jpg");
    background-size: 119px;
  }
  .p-serviceInfoDetail02 h2 {
    margin-top: 14px;
    font-size: 2.1rem;
    line-height: 1.3;
  }
  .p-serviceInfoDetail02 .c-newsCate01 {
    height: 20px;
    margin-top: 9px;
    font-size: 1.2rem;
    padding: 2px 5px;
  }
  .p-serviceInfoDetail03 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-serviceInfoDetail03 h3 {
    margin-bottom: 20px;
    padding: 17px 20px 16px;
    font-size: 1.9rem;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .p-serviceInfoDetail03 h4 {
    margin-bottom: 11px;
    padding: 0 0 5px 15px;
    font-size: 1.7rem;
    line-height: 1.5;
  }
  .p-serviceInfoDetail03 h5 {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .p-serviceInfoDetail03 h5:before {
    width: 15px;
    height: 15px;
    margin: -4px 10px 0 -20px;
  }
  .p-serviceInfoDetail03 p {
    font-size: 1.4rem;
    line-height: 1.8;
  }
  .p-serviceInfoDetail03 p + h4 {
    margin-top: 40px;
  }
  .p-serviceInfoDetail03 p + h5 {
    margin-top: 20px;
  }
  .p-serviceInfoDetail03 ol {
    margin: 0 0 17px 1em;
    font-size: 1.4rem;
  }
  .p-serviceInfoDetail03 ol > li {
    padding-left: 15px;
  }
  .p-serviceInfoDetail03 ol > li:before {
    width: 15px;
    margin-left: -16px;
  }
  .p-serviceInfoDetail03 ul {
    margin: 0 0 20px 1em;
    font-size: 1.4rem;
  }
  .p-serviceInfoDetail03 ul > li {
    padding-left: 10px;
  }
  .p-serviceInfoDetail03 ul > li:before {
    width: 8px;
    height: 8px;
    margin: 0 10px 0 -15px;
  }
  .p-serviceInfoDetail03 img {
    max-width: 100%;
  }
  .p-serviceInfoDetail03 img.alignleft {
    margin: 0 auto 10px auto;
    display: block;
    float: none;
  }
  .p-serviceInfoDetail03 img.alignright {
    margin: 0 auto 10px auto;
    display: block;
    float: none;
  }
  .p-serviceInfoDetail03 img.aligncenter {
    margin: 0 auto 10px auto;
  }
  .p-serviceInfoDetail03 table {
    margin-bottom: 41px;
    font-size: 1.4rem;
    display: block;
    border-bottom: solid 1px #ced5e0;
  }
  .p-serviceInfoDetail03 table tbody, .p-serviceInfoDetail03 table thead {
    display: block;
  }
  .p-serviceInfoDetail03 table tr {
    display: block;
  }
  .p-serviceInfoDetail03 table tr td {
    padding: 8px 20px 6px;
    display: block;
    border: none;
    border-bottom: solid 1px #ced5e0;
  }
  .p-serviceInfoDetail03 table tr td:first-of-type {
    padding: 11px 20px 12px;
    display: block;
    border: none;
    width: 100%;
  }
  .p-serviceInfoDetail03 table tr *:last-child {
    border: none;
  }
  .p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox01 {
    margin-bottom: 11px 16px 15px;
    padding: 15px;
  }
  .p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox01 h4 {
    padding: 0 0 11px;
  }
  .p-serviceInfoDetail03 .p-serviceInfoDetail03__textBox02 {
    margin-bottom: 20px;
    padding: 15px;
  }
  .p-serviceInfoDetail04 {
    margin-bottom: 80px;
  }
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__prev {
    width: 40px;
    height: 40px;
    padding: 10px 0 0 12px;
    border: solid 1px #dddddd;
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__prev:before {
    width: 10px;
    height: 17px;
    margin: 0;
    display: block;
  }
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__next {
    width: 40px;
    height: 40px;
    padding: 10px 0 0 16px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    border: solid 1px #dddddd;
  }
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__next:after {
    width: 10px;
    height: 17px;
    margin: 0;
    display: block;
  }
  .p-serviceInfoDetail04 .p-serviceInfoDetail04__back {
    font-size: 1.4rem;
    height: 40px;
    line-height: 2.5;
    padding: 0 23px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
組合員コードの見方
------------------------------------------------------------*/
.p-serviceCode {
  padding-top: 50px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode {
    padding: 85px 20px 50px;
  }
}
.p-serviceCode .u-mt50 {
  margin-top: 50px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode .u-mt50 {
    margin-top: 25px;
  }
}
.p-serviceCode .u-mt30 {
  margin-top: 30px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode .u-mt30 {
    margin-top: 20px;
  }
}
.p-serviceCode .u-mt21 {
  margin-top: 21px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode .u-mt21 {
    margin-top: 20px;
  }
}
.p-serviceCode .u-wi306 {
  width: 306px;
}
.p-serviceCode .c-breadcrumb1 {
  padding: 15px 0 31px 0;
}
.p-serviceCode .c-breadcrumb1 a {
  color: #308bbb;
}
.p-serviceCode .c-breadcrumb1 .u-inner01 li:last-child {
  margin-left: 5px;
}
.p-serviceCode__title1 {
  font-size: 3.2rem;
  line-height: 4.6rem;
  color: #46483c;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 48px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__title1 {
    font-size: 2.1rem;
    line-height: 1.5;
    margin-bottom: 25px;
  }
}
.p-serviceCode__title1 span {
  display: block;
  font-size: 1.4rem;
  line-height: 1;
  opacity: 0.5;
  font-weight: normal;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__title1 span {
    font-size: 1.2rem;
  }
}
.p-serviceCode__title2 {
  display: block;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #FFFFFE;
  background: #a4c661;
  padding: 20px 29px 18px;
  width: 100%;
  margin-bottom: 21px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__title2 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.9rem;
    line-height: 1.6;
    padding: 13px 20px 12px;
    margin-bottom: 10px;
  }
}
.p-serviceCode__text1 {
  font-size: 1.6rem;
  line-height: 3.2rem;
  color: #46483c;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__text1 {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-serviceCode__text2 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #669900;
  padding: 5px 0 7px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__text2 {
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 3px 0 4px;
  }
}
.p-serviceCode__block1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__block1 {
    display: block;
    margin-top: 30px;
  }
}
.p-serviceCode__block1.mb_100 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__block1.mb_100 {
    margin-bottom: 50px;
  }
}
.p-serviceCode__block1--wide {
  justify-content: space-between;
}
.p-serviceCode__block1--wide .p-serviceCode__cont {
  width: 800px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__block1--wide .p-serviceCode__cont {
    width: 100%;
  }
}
.p-serviceCode__cont {
  width: 560px;
  padding-right: 29px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__cont {
    width: 100%;
    padding-right: 0;
  }
}
.p-serviceCode .img_flex {
  display: flex;
  width: 45%;
}
.p-serviceCode .img_flex img {
  padding: 10px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode .img_flex img {
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  .p-serviceCode .img_flex {
    display: block;
  }
}
.p-serviceCode .img_m {
  margin: auto;
}
.p-serviceCode .img_w75 {
  width: 75%;
  margin: 0px 30px;
}
.p-serviceCode__img {
  width: 560px;
  padding-top: 9px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__img {
    width: 100%;
    padding-top: 15px;
  }
}
@media screen and (max-width: 640px) {
  .p-serviceCode__img img {
    width: 100%;
    margin: 10px 0;
  }
}
.p-serviceCode__frame {
  border: 4px solid #eeeeee;
  display: flex;
  margin-top: 40px;
  padding: 26px 35px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__frame {
    border: 2px solid #eeeeee;
    margin-top: 20px;
    padding: 15px 20px;
  }
}
.p-serviceCode__frame span {
  font-size: 2rem;
  line-height: 2.4rem;
  color: #669900;
  font-weight: bold;
  display: block;
  padding-top: 8px;
  width: 58px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__frame span {
    font-size: 1.6rem;
    line-height: 1.6rem;
    width: 40px;
  }
}
.p-serviceCode__contact {
  background: #f9f9f9;
  margin: 50px 0 100px;
  padding: 39px 39px 30px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__contact {
    margin: 25px 0 50px;
    padding: 20px 18px 15px;
  }
  .p-serviceCode__contact .p-serviceCode__text1 {
    color: #fff;
    font-size: 1rem;
  }
  .p-serviceCode__contact a {
    width: 100%;
    display: block;
    margin-top: 10px;
    background: #4b5870;
    border: 2px solid #939ba9;
    padding: 12px 10px 8px;
  }
  .p-serviceCode__contact a .c-contactBtn__txt03 {
    display: table;
    margin: 0 auto;
    border: none;
    padding: 0;
    text-align: center;
  }
}
.p-serviceCode__contact h4 {
  font-size: 2rem;
  line-height: 2.4rem;
  color: #669900;
  border-bottom: 2px solid #669900;
  padding-bottom: 18px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode__contact h4 {
    font-size: 1.6rem;
    line-height: 1.6;
    padding-bottom: 10px;
    border-bottom: 1px solid #669900;
  }
}
.p-serviceCode__number {
  font-size: 3.4rem;
  line-height: 1;
  color: #46483c;
  margin: 24px 0 17px;
  letter-spacing: 1.2px;
}
.p-serviceCode__number span {
  font-size: 2.2rem;
  margin-right: 10px;
  letter-spacing: 0;
}
.p-serviceCode__number span img {
  transform: translateY(2px);
  margin-left: 2px;
}
.p-serviceCode__number a {
  color: #46483c;
}
.p-serviceCode .ml {
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-serviceCode .ml {
    margin-left: 0;
  }
}

/*------------------------------------------------------------
はじめてご利用される方へ
------------------------------------------------------------*/
.p-serviceGuide {
  padding: 52px 0 87px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide {
    padding: 85px 20px 100px;
  }
}
.p-serviceGuide .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-serviceGuide .c-breadcrumb1 a {
  color: #308bbb;
}
.p-serviceGuide__title {
  margin-bottom: 47px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-serviceGuide__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__title span {
    font-size: 1.2rem;
  }
}
.p-serviceGuide__title02 {
  font-size: 2.4rem;
  color: #fff;
  background: #a4c661;
  padding: 16px 29px 15px;
  margin-bottom: 21px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__title02 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.9rem;
    line-height: 1.6;
    padding: 13px 20px 12px;
    margin-bottom: 20px;
  }
}
.p-serviceGuide__title03 {
  font-size: 2rem;
  color: #669900;
  border-bottom: 2px solid #a4c661;
  padding-bottom: 14px;
  margin-bottom: 25px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__title03 {
    font-size: 1.8rem;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}
.p-serviceGuide__title04 {
  font-size: 2rem;
  color: #669900;
  border-bottom: 2px solid #a4c661;
  position: relative;
  padding: 0 0 0 15px;
  margin-bottom: 23px;
  line-height: 2.2;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__title04 {
    font-size: 1.8rem;
    line-height: 1.6;
    padding: 0 0 0 10px;
    margin-bottom: 20px;
  }
}
.p-serviceGuide__title04:before {
  content: "";
  width: 6px;
  height: 30px;
  background: #a4c661;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__title04:before {
    top: 4px;
    width: 4px;
    height: 20px;
  }
}
.p-serviceGuide__txtimg {
  margin-bottom: 60px;
  *zoom: 1;
}
.p-serviceGuide__txtimg:after {
  display: block;
  content: "";
  clear: both;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__txtimg {
    margin-bottom: 40px;
  }
}
.p-serviceGuide__txtimg p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__txtimg p {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}
.p-serviceGuide__txtimg span {
  color: #ef004f;
}
.p-serviceGuide__txtimg img {
  float: right;
  margin: 9px 0 0 25px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__txtimg img {
    float: none;
    display: block;
    margin: 0 auto 20px;
  }
}
.p-serviceGuide__txt {
  margin-bottom: 20px;
}
.p-serviceGuide__txt p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-serviceGuide__txt02 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #46483c;
  margin-bottom: 8px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__txt02 {
    font-size: 1.4rem;
  }
}
.p-serviceGuide__list li {
  font-size: 1.6rem;
  color: #46483c;
  padding-left: 19px;
  position: relative;
  margin-bottom: 7px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__list li {
    font-size: 1.4rem;
    padding-left: 12px;
  }
}
.p-serviceGuide__list li:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #669900;
  border-radius: 100px;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__list li:before {
    width: 6px;
    height: 6px;
  }
}
.p-serviceGuide__list a {
  color: #308bbb;
  text-decoration: underline;
}
.p-serviceGuide__box {
  background: #f9f9f9;
  padding: 34px 40px 26px;
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__box {
    padding: 14px 20px 13px;
    margin-bottom: 30px;
  }
}
.p-serviceGuide__frame {
  display: flex;
}
.p-serviceGuide__frame > div {
  width: 50%;
  padding: 14px 0 1px 20px;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__frame > div {
    padding: 10px 10px 0;
  }
}
.p-serviceGuide__frame > div:nth-child(1) {
  border-right: 1px solid #ced5e0;
}
.p-serviceGuide__table {
  margin-bottom: 30px;
}
.p-serviceGuide__table dl {
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__table dl {
    display: block;
    border: 1px solid #ced5e0;
    margin-bottom: 20px;
  }
}
.p-serviceGuide__table dl:nth-child(1) dd {
  padding: 0;
}
.p-serviceGuide__table dl:last-of-type dd {
  border-bottom: 1px solid #ced5e0;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__table dl:last-of-type dd {
    border-bottom: none;
  }
}
.p-serviceGuide__table dt {
  width: 422px;
  height: auto;
  background: #f5efd9;
  border-bottom: 1px solid #fff;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__table dt {
    width: 100%;
    padding: 8px 0 9px;
  }
}
.p-serviceGuide__table dt span {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #46483c;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__table dt span {
    font-size: 1.4rem;
    justify-content: center;
  }
}
.p-serviceGuide__table dd {
  width: calc(100% - 422px);
  border-top: 1px solid #ced5e0;
  border-right: 1px solid #ced5e0;
  padding: 17px 0 3px 20px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__table dd {
    width: 100%;
    border: none;
  }
}
.p-serviceGuide__table .p-serviceGuide__list li {
  margin-bottom: 7px;
  min-height: 26px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__table .p-serviceGuide__list li {
    min-height: 22px;
    margin-bottom: 5px;
  }
}
.p-serviceGuide__btn {
  margin-bottom: 30px;
}
.p-serviceGuide__btn a {
  padding-right: 10px;
  text-align: center;
  width: 500px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #46483c;
  border-radius: 50px;
  border: 1px solid #8cb340;
  background: #fff;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__btn a {
    width: 100%;
    height: auto;
    font-size: 1.4rem;
    padding: 10px 40px 10px 20px;
    line-height: 1.5;
  }
}
.p-serviceGuide__btn a:after {
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/common/icon_arrow-green04.png");
  background-size: contain;
  position: absolute;
  top: 18px;
  right: 21px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__btn a:after {
    top: auto;
  }
}
.p-serviceGuide__btn a:hover {
  color: #fff;
  background: #8cb340;
}
.p-serviceGuide__btn a:hover:after {
  background: url("../img/common/icon_arrow-white02.png");
  background-size: contain;
}
.p-serviceGuide__listBtn {
  margin: -10px 0 31px;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__listBtn {
    margin: 0 0 30px;
    display: flex;
    flex-wrap: wrap;
  }
}
.p-serviceGuide__listBtn li {
  display: inline-block;
  margin: 0 8px 8px 0;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__listBtn li {
    margin: 0 4px 15px 0;
    width: 100%;
  }
  .p-serviceGuide__listBtn li:last-child {
    margin-bottom: 0;
  }
}
.p-serviceGuide__listBtn a {
  width: 240px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #669900;
  border-radius: 50px;
  border: 3px solid #dddddd;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__listBtn a {
    padding-right: 20px;
    width: 100%;
    font-size: 1.4rem;
  }
}
.p-serviceGuide__listBtn a:after {
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/common/icon_arrow-green04.png");
  background-size: contain;
  position: absolute;
  top: 15px;
  right: 17px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-serviceGuide__listBtn a:after {
    top: auto;
  }
}

/*------------------------------------------------------------
個人情報保護方針
------------------------------------------------------------*/
.p-servicePrivacy {
  padding: 52px 0 90px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy {
    padding: 85px 20px 100px;
  }
}
.p-servicePrivacy .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-servicePrivacy .c-breadcrumb1 a {
  color: #308bbb;
}
.p-servicePrivacy__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-servicePrivacy__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__title span {
    font-size: 1.2rem;
  }
}
.p-servicePrivacy__title02 {
  font-size: 3.2rem;
  color: #46483c;
  padding: 35px 40px 29px;
  background: url("../img/service/bg03.jpg");
  position: relative;
  margin-bottom: 23px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__title02 {
    font-size: 2.1rem;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 35px 20px 29px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}
.p-servicePrivacy__title02:before {
  content: "";
  width: 100%;
  height: 4px;
  background: url("../img/service/line_colorful01_pc.jpg");
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__title02:before {
    background-size: 120px;
  }
}
.p-servicePrivacy__title03 {
  background: #a4c661;
  font-size: 2.4rem;
  color: #fff;
  padding: 16px 28px 15px;
  margin-top: 50px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__title03 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.9rem;
    line-height: 1.6;
    padding: 13px 20px 12px;
    margin-top: 40px;
  }
}
.p-servicePrivacy__title04 {
  font-size: 2rem;
  color: #669900;
  border-bottom: 2px solid #a4c661;
  padding-bottom: 14px;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__title04 {
    font-size: 1.8rem;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}
.p-servicePrivacy__txt {
  margin-top: 22px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__txt {
    margin-top: 20px;
  }
}
.p-servicePrivacy__txt p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-servicePrivacy__txt p + p {
  margin-top: 33px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__txt p + p {
    margin-top: 20px;
  }
}
.p-servicePrivacy__txt .u-right {
  text-align: right;
  margin-top: 21px;
}
.p-servicePrivacy__list {
  margin: 6px 0 0;
  list-style-type: decimal;
  padding-left: 37px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list {
    padding-left: 25px;
    margin: 8px 0;
  }
}
.p-servicePrivacy__list li {
  font-size: 1.6rem;
  color: #46483c;
  margin-bottom: 7px;
  padding-left: 14px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list li {
    font-size: 1.4rem;
    line-height: 1.6;
    padding-left: 0;
  }
}
.p-servicePrivacy__list--02 {
  list-style-type: lower-alpha;
  padding-left: 89px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list--02 {
    padding-left: 62px;
  }
}
.p-servicePrivacy__list02 {
  margin: 5px 0 0;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list02 {
    padding-left: 10px;
  }
}
.p-servicePrivacy__list02 li {
  margin-bottom: 10px;
  padding-left: 18px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list02 li {
    padding-left: 15px;
    margin-bottom: 8px;
  }
}
.p-servicePrivacy__list02 li:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #669900;
  border-radius: 100px;
  position: absolute;
  top: 8px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list02 li:before {
    width: 6px;
    height: 6px;
  }
}
.p-servicePrivacy__list02 a {
  text-decoration: underline;
  font-size: 1.6rem;
  color: #308bbb;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list02 a {
    font-size: 1.4rem;
  }
}
.p-servicePrivacy__list03 {
  padding-left: 10px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list03 {
    padding-left: 0;
  }
}
.p-servicePrivacy__list03 li {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
  display: flex;
}
.p-servicePrivacy__list03 li span {
  display: block;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list03 li {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-servicePrivacy__box {
  background: #f9f9f9;
  padding: 34px 40px 32px;
  margin: 21px 0 60px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__box {
    padding: 14px 20px 13px;
    margin: 30px 0 0;
  }
}
.p-servicePrivacy__box p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__box p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-servicePrivacy__box a {
  text-decoration: underline;
  color: #308bbb;
}
.p-servicePrivacy__list04 {
  margin: 5px 0 0;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list04 {
    padding-left: 10px;
  }
}
.p-servicePrivacy__list04 li {
  margin-bottom: 10px;
  padding-left: 18px;
  position: relative;
  list-style: none;
  font-size: 1.4rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list04 li {
    padding-left: 15px;
    margin-bottom: 8px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-servicePrivacy__list04 li:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 8px;
  border-color: transparent transparent transparent #669900;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list04 li:before {
    width: 6px;
    height: 6px;
  }
}
.p-servicePrivacy__list04 a {
  text-decoration: underline;
  font-size: 1.4rem;
  color: #308bbb;
}
@media screen and (max-width: 640px) {
  .p-servicePrivacy__list04 a {
    font-size: 1.4rem;
  }
}
.p-servicePrivacy__list04 .font16 {
  font-size: 1.6rem;
}

/*------------------------------------------------------------
特定商取引法に基づく表記
------------------------------------------------------------*/
.p-serviceTokushoho {
  padding: 52px 0 69px;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho {
    padding: 85px 20px 70px;
  }
}
.p-serviceTokushoho .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-serviceTokushoho .c-breadcrumb1 a {
  color: #308bbb;
}
.p-serviceTokushoho__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-serviceTokushoho__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__title span {
    font-size: 1.2rem;
  }
}
.p-serviceTokushoho__title02 {
  font-size: 3.2rem;
  color: #46483c;
  padding: 35px 40px 29px;
  background: url("../img/service/bg03.jpg");
  position: relative;
  margin-bottom: 45px;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__title02 {
    font-size: 2.1rem;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 35px 20px 29px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}
.p-serviceTokushoho__title02:before {
  content: "";
  width: 100%;
  height: 4px;
  background: url("../img/service/line_colorful01_pc.jpg");
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__title02:before {
    background-size: 120px;
  }
}
.p-serviceTokushoho__title03 {
  font-size: 2rem;
  color: #669900;
  border-bottom: 2px solid #a4c661;
  position: relative;
  padding: 0 0 0 20px;
  margin-bottom: 23px;
  line-height: 2.2;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__title03 {
    font-size: 1.8rem;
    line-height: 1.6;
    padding: 0 0 0 10px;
    margin-bottom: 20px;
  }
}
.p-serviceTokushoho__title03:before {
  content: "";
  width: 6px;
  height: 30px;
  background: #a4c661;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__title03:before {
    top: 4px;
    width: 4px;
    height: 20px;
  }
}
.p-serviceTokushoho__txt {
  margin-bottom: 23px;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__txt {
    margin-bottom: 15px;
  }
}
.p-serviceTokushoho__txt p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-serviceTokushoho__list {
  margin: 3px 0 0 0;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__list {
    padding-left: 10px;
  }
}
.p-serviceTokushoho__list li {
  font-size: 1.6rem;
  color: #46483c;
  padding-left: 19px;
  position: relative;
  margin-bottom: 4px;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__list li {
    font-size: 1.4rem;
    padding-left: 10px;
    line-height: 1.6;
  }
}
.p-serviceTokushoho__list li:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #669900;
  border-radius: 100px;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceTokushoho__list li:before {
    width: 6px;
    height: 6px;
  }
}
.p-serviceTokushoho__list a {
  color: #308bbb;
  text-decoration: underline;
}

/*------------------------------------------------------------
反社会的勢力への基本方針
------------------------------------------------------------*/
.p-serviceAntisocial {
  padding: 52px 0 66px;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial {
    padding: 85px 20px 70px;
  }
}
.p-serviceAntisocial .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-serviceAntisocial .c-breadcrumb1 a {
  color: #308bbb;
}
.p-serviceAntisocial__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-serviceAntisocial__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__title span {
    font-size: 1.2rem;
  }
}
.p-serviceAntisocial__title02 {
  font-size: 3.2rem;
  color: #46483c;
  padding: 35px 40px 29px;
  background: url("../img/service/bg03.jpg");
  position: relative;
  margin-bottom: 24px;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__title02 {
    font-size: 2.1rem;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 35px 20px 29px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}
.p-serviceAntisocial__title02:before {
  content: "";
  width: 100%;
  height: 4px;
  background: url("../img/service/line_colorful01_pc.jpg");
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__title02:before {
    background-size: 120px;
  }
}
.p-serviceAntisocial__title03 {
  font-size: 2rem;
  color: #669900;
  border-bottom: 2px solid #a4c661;
  position: relative;
  padding: 0 0 0 15px;
  margin-bottom: 23px;
  line-height: 2.2;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__title03 {
    font-size: 1.8rem;
    line-height: 1.6;
    padding: 0 0 0 10px;
    margin-bottom: 20px;
  }
}
.p-serviceAntisocial__title03:before {
  content: "";
  width: 6px;
  height: 30px;
  background: #a4c661;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__title03:before {
    top: 4px;
    width: 4px;
    height: 20px;
  }
}
.p-serviceAntisocial__txt {
  margin-bottom: 24px;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__txt {
    margin-bottom: 30px;
  }
}
.p-serviceAntisocial__txt p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-serviceAntisocial__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-serviceAntisocial__txt .u-right {
  text-align: right;
  margin-top: 32px;
}

/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-serviceFaq {
  padding: 52px 0 40px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq {
    padding: 85px 20px 70px;
  }
}
.p-serviceFaq .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-serviceFaq .c-breadcrumb1 a {
  color: #308bbb;
}
.p-serviceFaq__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-serviceFaq__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__title span {
    font-size: 1.2rem;
  }
}
.p-serviceFaq__title02 {
  font-size: 2.4rem;
  color: #fff;
  background: #a4c661;
  padding: 16px 29px 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__title02 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.6rem;
    padding: 13px 20px 12px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
}
.p-serviceFaq__box {
  margin-bottom: 20px;
}
.p-serviceFaq__box:last-of-type {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box:last-of-type {
    margin-bottom: 30px;
  }
}
.p-serviceFaq__box__q {
  font-size: 2rem;
  color: #46483c;
  line-height: 1.6;
  background: #f2f2f2;
  padding: 15px 29px 13px;
  cursor: pointer;
  position: relative;
  display: table;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box__q {
    font-size: 1.4rem;
    padding: 13px 35px 11px 15px;
  }
}
.p-serviceFaq__box__q span {
  display: table-cell;
  width: 27px;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box__q span {
    width: 20px;
  }
}
.p-serviceFaq__box__q:after, .p-serviceFaq__box__q:before {
  content: "";
  background: #46483c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s ease-out;
}
.p-serviceFaq__box__q:after {
  width: 24px;
  height: 3px;
  right: 20px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box__q:after {
    width: 10px;
    height: 2px;
    right: 10px;
  }
}
.p-serviceFaq__box__q:before {
  width: 3px;
  height: 24px;
  right: 30px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box__q:before {
    width: 2px;
    height: 10px;
    right: 14px;
  }
}
.p-serviceFaq__box__a {
  display: none;
  background: #fff;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 24px 40px 29px 37px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box__a {
    padding: 15px;
  }
}
.p-serviceFaq__box__a p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
  display: table;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box__a p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-serviceFaq__box__a span {
  display: table-cell;
  width: 32px;
  font-size: 2rem;
  font-weight: bold;
  color: #669900;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__box__a span {
    width: 20px;
    font-size: 1.8rem;
  }
}
.p-serviceFaq__box.is-open .p-serviceFaq__box__q::before {
  transform: translateY(-50%) rotate(-90deg);
}
.p-serviceFaq__btn {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__btn {
    display: block;
    margin-bottom: 25px;
  }
}
.p-serviceFaq__btn li {
  width: 550px;
  height: 80px;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__btn li {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
}
.p-serviceFaq__btn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #8eb15b;
  border: 3px solid #dddddd;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__btn a {
    font-size: 1.5rem;
    padding: 0 30px;
    line-height: 1.6;
    border: 2px solid #dddddd;
  }
}
.p-serviceFaq__btn a:after {
  content: "";
  width: 12px;
  height: 7px;
  background: url("../img/common/icon_down.png");
  background-size: contain;
  position: absolute;
  top: 34px;
  right: 27px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-serviceFaq__btn a:after {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

/*------------------------------------------------------------
お電話でのお問い合わせ
------------------------------------------------------------*/
.p-serviceContact {
  padding: 52px 0 60px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact {
    padding: 85px 20px 70px;
  }
}
.p-serviceContact .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-serviceContact .c-breadcrumb1 a {
  color: #308bbb;
}
.p-serviceContact__title {
  margin-bottom: 45px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-serviceContact__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__title span {
    font-size: 1.2rem;
  }
}
.p-serviceContact__title02 {
  margin-bottom: 30px;
  font-size: 2rem;
  color: #fff;
  padding: 16px 50px 12px 29px;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__title02 {
    font-size: 1.6rem;
    padding: 12px 32px 11px 10px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}
.p-serviceContact__title02:after, .p-serviceContact__title02:before {
  content: "";
  background: #fff;
  position: absolute;
  top: 50%;
  transition: transform 0.25s ease-out;
  transform: translateY(-50%);
}
.p-serviceContact__title02:after {
  width: 24px;
  height: 3px;
  right: 20px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__title02:after {
    width: 20px;
    height: 2px;
    right: 10px;
  }
}
.p-serviceContact__title02:before {
  width: 3px;
  height: 24px;
  right: 30px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__title02:before {
    width: 2px;
    height: 20px;
    right: 19px;
  }
}
.p-serviceContact__title02.color1 {
  background: #fc819b;
}
.p-serviceContact__title02.color2 {
  background: #82cdf3;
}
.p-serviceContact__title02.color3 {
  background: #68ba93;
}
.p-serviceContact__title02.color4 {
  background: #faad5a;
}
.p-serviceContact__title02.color5 {
  background: #af8ebe;
}
.p-serviceContact__title02.is-close:before {
  transform: translateY(-50%) rotate(-90deg);
}
.p-serviceContact__title03 {
  font-size: 2rem;
  color: #669900;
  border-bottom: 2px solid #a4c661;
  padding-bottom: 11px;
  margin-bottom: 18px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__title03 {
    font-size: 1.8rem;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
}
.p-serviceContact__txt {
  margin-bottom: 51px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__txt {
    margin-bottom: 30px;
  }
}
.p-serviceContact__txt p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-serviceContact__txt01 {
  margin-top: 6px;
}
.p-serviceContact__txt01 p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__txt01 {
    margin-top: 0;
  }
  .p-serviceContact__txt01 p {
    color: #fff;
    font-size: 1rem;
    line-height: 1.3;
  }
}
.p-serviceContact__txt02 p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__txt02 p {
    margin-top: 15px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-serviceContact__tel {
  font-size: 3.4rem;
  font-weight: bold;
  color: #46483c;
  line-height: 1.6;
}
.p-serviceContact__tel span {
  font-size: 2.2rem;
  font-weight: normal;
  display: inline-block;
  margin-right: 40px;
  position: relative;
}
.p-serviceContact__tel span:after {
  content: "";
  width: 28px;
  height: 26px;
  background: url("../img/common/icon_tel05.png") no-repeat;
  position: absolute;
  top: -1px;
  right: -30px;
}
.p-serviceContact__tel p {
  display: inline-block;
}
.p-serviceContact__list {
  margin-bottom: 10px;
  padding-left: 21px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__list {
    margin: 10px 0 5px;
    padding-left: 15px;
  }
}
.p-serviceContact__list li {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 1.6;
  padding-left: 18px;
  position: relative;
  margin-top: 8px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__list li {
    font-size: 1.4rem;
    margin-top: 5px;
  }
}
.p-serviceContact__list li:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #669900;
  border-radius: 100px;
  position: absolute;
  top: 8px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__list li:before {
    width: 6px;
    height: 6px;
  }
}
.p-serviceContact__list a {
  color: #308bbb;
  text-decoration: underline;
}
.p-serviceContact__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__content {
    display: block;
    padding-bottom: 15px;
  }
}
.p-serviceContact__card {
  margin-bottom: 20px;
  width: 550px;
  background: #f9f9f9;
  padding: 34px 39px 30px;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__card {
    width: 100%;
    padding: 15px 15px 17px;
  }
  .p-serviceContact__card a {
    width: 100%;
    display: block;
    margin-top: 10px;
    background: #4b5870;
    border: 2px solid #939ba9;
    padding: 12px 10px 8px;
  }
  .p-serviceContact__card a .c-contactBtn__txt03 {
    display: table;
    margin: 0 auto;
    border: none;
    padding: 0;
    text-align: center;
  }
  .p-serviceContact__card a .c-contactBtn__txt03 .txt-sp {
    padding: 0 0 5px 0;
  }
}
.p-serviceContact__btn {
  display: table;
  margin: 30px auto;
}
.p-serviceContact__btn a {
  color: #46483c;
  padding: 10px 60px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  background-color: #ffffff;
  background-image: url("../img/common/icon_arrow-green04.png");
  -webkit-background-size: 12px;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 95% center;
  border: solid 2px #8cb340;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
}
.p-serviceContact__btn a:before {
  margin: -6px 9px 0 -38px;
  content: "";
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
@media screen and (max-width: 640px) {
  .p-serviceContact__btn {
    margin: 0 auto;
  }
  .p-serviceContact__btn a {
    font-size: 1.6rem;
  }
}

/*------------------------------------------------------------
404
------------------------------------------------------------*/
.p-service404 {
  padding: 102px 0 98px;
}
@media screen and (max-width: 640px) {
  .p-service404 {
    padding: 85px 0 70px;
  }
}
.p-service404__bg {
  background: url("../img/404/bg02.png") repeat;
  padding: 74px 0 0;
}
@media screen and (max-width: 640px) {
  .p-service404__bg {
    padding: 30px 0;
    background-size: 40px;
  }
}
.p-service404__bg .u-inner01 {
  background: url("../img/404/bg01.png") no-repeat bottom right -8px;
  padding-bottom: 50px;
}
@media screen and (max-width: 640px) {
  .p-service404__bg .u-inner01 {
    background: none;
    padding: 0 20px;
  }
}
.p-service404__title {
  margin-bottom: 35px;
  font-size: 10rem;
  color: #8cb340;
  line-height: 1;
  letter-spacing: 4px;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
@media screen and (max-width: 640px) {
  .p-service404__title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 8rem;
  }
}
.p-service404__title span {
  display: block;
  font-size: 3.6rem;
  font-weight: normal;
  letter-spacing: 0;
}
@media screen and (max-width: 640px) {
  .p-service404__title span {
    font-size: 3.4rem;
  }
}
.p-service404__title02 {
  margin-bottom: 9px;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.4;
}
@media screen and (max-width: 640px) {
  .p-service404__title02 {
    font-size: 2rem;
  }
}
.p-service404__txt {
  margin-bottom: 30px;
}
.p-service404__txt p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-service404__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-service404__btn a {
  width: 430px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  background: #8cb340;
  border: 2px solid #8cb340;
  border-radius: 50px;
}
@media screen and (max-width: 640px) {
  .p-service404__btn a {
    width: 100%;
    height: 50px;
    font-size: 1.4rem;
    padding: 4px 21px 0;
    line-height: 1.3;
  }
}
.p-service404__btn a:hover {
  color: #8cb340;
  background: #fff;
}
.p-service404__content {
  padding: 59px 0 0;
}
.p-service404__content ul li {
  width: 18.57%;
  height: 16.47vw;
  max-height: 205px;
  min-height: 184px;
  margin-right: 1.7875%;
  background-image: url("../img/service/bg01.jpg");
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.p-service404__content ul li:after {
  width: 25px;
  height: 25px;
  content: "";
  background-image: url("../img/common/icon_btn-green01.png");
  position: absolute;
  bottom: 0;
  right: 0;
  transition: 0.3s ease-in-out;
}
.p-service404__content ul li:before {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 4px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.p-service404__content ul li:hover:after {
  opacity: 0;
}
.p-service404__content ul li:hover:before {
  opacity: 1;
}
.p-service404__content ul li:nth-child(1):before {
  border-color: #fc819b;
}
.p-service404__content ul li:nth-child(2):before {
  border-color: #82cdf3;
}
.p-service404__content ul li:nth-child(3):before {
  border-color: #68ba93;
}
.p-service404__content ul li:nth-child(4):before {
  border-color: #faad5a;
}
.p-service404__content ul li:nth-child(5) {
  margin: 0;
}
.p-service404__content ul li:nth-child(5):before {
  border-color: #af8ebe;
}
.p-service404__content ul li .c-cateIcon01 {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  -webkit-background-size: 50%;
  background-size: 50%;
  position: absolute;
  top: 13%;
  left: 0;
  right: 0;
}
.p-service404__content ul li .c-cateIcon01--kurashi + a {
  margin-top: -0.5em;
}
.p-service404__content ul li a {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.2;
  position: absolute;
  top: 68%;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-service404__content {
    padding: 30px 20px 0;
  }
  .p-service404__content ul li {
    width: 30%;
    height: 37.5vw;
    max-height: 240px;
    min-height: auto;
    margin: 0 4.5% 3% 0;
    border-radius: 3px;
  }
  .p-service404__content ul li:after {
    width: 17px;
    height: 17px;
  }
  .p-service404__content ul li .c-cateIcon01 {
    width: 16vw;
    height: 16vw;
    -webkit-background-size: 60%;
    background-size: 60%;
    top: 12%;
    left: 0;
    right: 0;
  }
  .p-service404__content ul li a {
    font-size: 1.4rem;
    top: 69%;
  }
}

@media screen and (max-width: 640px) {
  .page-kurashi_enquete_index .c-header__logo,
  .page-kurashi_enquete_confirm .page-kurashi_enquete_complete .c-header__logo {
    margin-top: 0;
  }
}

.c-form1Privacy__wrap .c-formPrivacyText {
  border: solid 1px #efefef;
  height: 300px;
  overflow: hidden;
  padding: 28px 31px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .c-form1Privacy__wrap .c-formPrivacyText {
    padding: 18px 12px 18px 19px;
  }
}
.c-form1Privacy__wrap .c-formPrivacyText__txt {
  font-size: 1.5rem;
  line-height: 30px;
  border: none;
  padding: 0;
  overflow: auto;
  height: 244px;
  margin-bottom: 0;
  font-weight: 500;
  padding-right: 15px;
}
.c-form1Privacy__wrap .c-formPrivacyText__txt a[href^="tel:"] {
  color: #232934;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .c-form1Privacy__wrap .c-formPrivacyText__txt a[href^="tel:"] {
    pointer-events: inherit;
  }
}
@media screen and (max-width: 640px) {
  .c-form1Privacy__wrap .c-formPrivacyText__txt {
    font-size: 1.2rem;
    line-height: 22.5px;
    height: 274px;
    padding-right: 10px;
  }
}
.c-form1Privacy__wrap .c-formPrivacyText__txt span {
  display: block;
  font-size: 1.7rem;
  line-height: 30px;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-form1Privacy__wrap .c-formPrivacyText__txt span {
    font-size: 1.3rem;
    line-height: 22.5px;
  }
}

.p-kurashi-enquete {
  color: #232934;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
.p-kurashi-enquete .l-container {
  padding: 0 20px;
  width: 1160px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .l-container {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-step3 {
    margin-top: 20px !important;
  }
}
.p-kurashi-enquete .c-step3__text {
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-step3__text {
    white-space: nowrap;
  }
}
.p-kurashi-enquete .c-kurashi-enqueteText1 {
  font-size: 1.6rem;
  line-height: 28px;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-kurashi-enqueteText1 {
    text-align: left;
    font-size: 1.4rem;
    line-height: 24.5px;
  }
}
.p-kurashi-enquete .c-form1 {
  color: #232934;
}
.p-kurashi-enquete .c-form1 .is-error .c-form1__title,
.p-kurashi-enquete .c-form1 .is-error select {
  background-color: #FCD4D4;
}
.p-kurashi-enquete .c-form1 .is-error input,
.p-kurashi-enquete .c-form1 .is-error textarea {
  background-color: rgba(242, 39, 39, 0.2);
}
.p-kurashi-enquete .c-form1 .is-error .error {
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .is-error .error {
    font-size: 1.2rem;
  }
}
.p-kurashi-enquete .c-form1__text {
  color: #F22727;
}
.p-kurashi-enquete .c-form1__label {
  top: 2px;
}
.p-kurashi-enquete .c-form1__label:not(.is-style1) {
  background-color: #F22727;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__label {
    top: 0;
  }
}
.p-kurashi-enquete .c-form1__label.is-style1 {
  top: 4px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__label.is-style1 {
    top: 0;
  }
}
.p-kurashi-enquete .c-form1__select {
  max-width: 280px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__select {
    max-width: 100%;
  }
}
.p-kurashi-enquete .c-form1__select__inner {
  padding: 0 20px;
  text-indent: 25px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__select__inner {
    text-indent: 15px;
  }
}
@-moz-document url-prefix() {
  .p-kurashi-enquete .c-form1__select__inner {
    text-indent: 15px;
  }
}
.p-kurashi-enquete .c-form1__input__text {
  font-size: 1.8rem;
  width: 101px;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__input__text {
    font-size: 1.4rem;
    width: 100%;
  }
}
.p-kurashi-enquete .c-form1__input input.mw258-pc {
  max-width: 289px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__input input.mw258-pc {
    max-width: 100%;
  }
}
.p-kurashi-enquete .c-form1__input input.is-full {
  max-width: 100% !important;
}
.p-kurashi-enquete .c-form1__text4 {
  color: #999999;
}
.p-kurashi-enquete .c-form1Privacy {
  padding-top: 16px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1Privacy {
    padding-top: 28px;
  }
}
.p-kurashi-enquete .c-form1Privacy .c-hokenTitle04 {
  padding-bottom: 9px;
  border-color: #F0AF68;
  margin-bottom: 31px;
  color: #232934;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1Privacy .c-hokenTitle04 {
    font-size: 1.6rem;
    margin-bottom: 13px;
    border-width: 1px;
  }
}
.p-kurashi-enquete .c-form1Privacy .c-formPrivacyText {
  border: solid 1px #efefef;
  height: 300px;
  overflow: hidden;
  padding: 28px 31px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1Privacy .c-formPrivacyText {
    padding: 18px 12px 18px 19px;
  }
}
.p-kurashi-enquete .c-form1Privacy .c-formPrivacyText__txt {
  font-size: 1.5rem;
  line-height: 30px;
  border: none;
  padding: 0;
  overflow: auto;
  height: 244px;
  margin-bottom: 0;
  font-weight: 500;
  padding-right: 15px;
}
.p-kurashi-enquete .c-form1Privacy .c-formPrivacyText__txt a[href^="tel:"] {
  color: #232934;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1Privacy .c-formPrivacyText__txt a[href^="tel:"] {
    pointer-events: inherit;
  }
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1Privacy .c-formPrivacyText__txt {
    font-size: 1.2rem;
    line-height: 22.5px;
    height: 274px;
    padding-right: 10px;
  }
}
.p-kurashi-enquete .c-form1Privacy .c-formPrivacyText__txt span {
  display: block;
  font-size: 1.7rem;
  line-height: 30px;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1Privacy .c-formPrivacyText__txt span {
    font-size: 1.3rem;
    line-height: 22.5px;
  }
}
.p-kurashi-enquete .c-form1__checkbox.is-term {
  margin-top: 37px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__checkbox.is-term {
    margin-top: 17px;
  }
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__checkbox.is-term .c-form1__checkbox__label {
    text-align: left;
  }
}
.p-kurashi-enquete .c-form1__checkbox.is-term input:checked {
  border-color: #FC819B;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__checkbox.is-term input {
    top: 3px;
    transform: translateY(0);
  }
}
.p-kurashi-enquete .c-form1__checkbox input:checked {
  background: #FC819B url(../img/hoken/common/icon_checked.svg) no-repeat center/11px auto;
}
.p-kurashi-enquete .c-form1__title {
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__title.is-info {
    padding-right: 45px;
  }
}
.p-kurashi-enquete .c-form1__title__text .is-select-change {
  display: none;
}
.p-kurashi-enquete .c-form1__title__text .is-select-change:not(.is-washing) {
  display: block;
}
.p-kurashi-enquete .c-form1__title__num {
  line-height: 2.7rem;
  top: 0;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__title__num {
    line-height: 2.1rem;
  }
}
.p-kurashi-enquete .c-form1__info {
  position: relative;
  left: 12px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1__info {
    position: absolute;
    top: 14px;
    left: inherit;
    right: 15px;
    width: 20px;
    height: 20px;
  }
}
.p-kurashi-enquete .c-form1 .c-table2__radio {
  margin: -2px 0 -4px;
  color: #232934;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .c-table2__radio {
    margin: -3px 0 0;
  }
}
.p-kurashi-enquete .c-form1 .c-table2__radio li {
  margin-bottom: 4px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .c-table2__radio li {
    margin-bottom: 14px;
  }
}
.p-kurashi-enquete .c-form1 .c-table2__radio li:last-child {
  margin-bottom: 0;
}
.p-kurashi-enquete .c-form1 .c-table2__radio input + label {
  padding-left: 29px;
  max-width: fit-content;
  font-weight: 500;
  white-space: nowrap;
}
.p-kurashi-enquete .c-form1 .c-table2__radio input:checked + label:before {
  background-color: #FC819B;
  border-color: #FC819B !important;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .c-table2__radio input + label:before,
  .p-kurashi-enquete .c-form1 .c-table2__radio input + label::after {
    top: 10px;
  }
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .c-cartBtn {
    margin: 36px auto 0;
  }
}
.p-kurashi-enquete .c-form1 .c-cartBtn input.c-cartBtn__btn02,
.p-kurashi-enquete .c-form1 .c-cartBtn .c-cartBtn__btn01 {
  width: 300px;
  font-size: 1.8rem;
  text-align: center;
  pointer-events: inherit;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .c-cartBtn input.c-cartBtn__btn02,
  .p-kurashi-enquete .c-form1 .c-cartBtn .c-cartBtn__btn01 {
    width: 100%;
    height: 44px;
    font-size: 1.4rem;
  }
}
.p-kurashi-enquete .c-form1 .c-cartBtn .c-cartBtn__btn01 {
  border: 2px solid #b2b2b2;
}
.p-kurashi-enquete .c-form1 .c-cartBtn .c-cartBtn__btn01 + input.c-cartBtn__btn02 {
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .c-cartBtn .c-cartBtn__btn01 + input.c-cartBtn__btn02 {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
.p-kurashi-enquete .c-form1 .c-cartBtn input.c-cartBtn__btn02 {
  color: #ffffff;
  border: 2px solid #FFA257;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1 .c-cartBtn input.c-cartBtn__btn02 {
    width: 100%;
    height: 44px;
  }
}
.p-kurashi-enquete .c-form1 .c-cartBtn input.c-cartBtn__btn02:hover {
  color: #ff6e03;
}
.p-kurashi-enquete .c-form1 .c-cartBtn a::before, .p-kurashi-enquete .c-form1 .c-cartBtn a::after {
  width: 12px;
  height: 12px;
  transition: all 0.3s ease-in-out;
}
.p-kurashi-enquete .c-form1 .c-cartBtn a::before {
  left: 24px;
}
.p-kurashi-enquete .c-form1 .c-cartBtn a::after {
  right: 24px;
}
.p-kurashi-enquete .c-form1.is-confirm .c-form1__notes {
  padding-top: 0;
  border: none;
  margin-bottom: 0;
  margin-top: 75px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1.is-confirm .c-form1__notes {
    margin-top: 48px;
  }
}
.p-kurashi-enquete .c-form1.is-confirm .c-cartBtn {
  margin-top: 30px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete .c-form1.is-confirm .c-cartBtn {
    margin-top: 15px;
  }
}
.p-kurashi-enquete-complete {
  padding: 42px 0 199px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-complete {
    padding: 19px 20px;
  }
}
.p-kurashi-enquete-complete .c-step3 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-complete .c-step3 {
    margin-top: 20px;
  }
}
.p-kurashi-enquete-complete .c-step3.is-orange .c-step3__item:last-child.is-active {
  background-image: url(../img/kurashi/enquete/step3-bg2-orange-active.png) !important;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-complete .c-step3.is-orange .c-step3__item:last-child.is-active {
    background-image: none !important;
  }
  .p-kurashi-enquete-complete .c-step3.is-orange .c-step3__item:last-child.is-active::after {
    background-color: #F0AF68 !important;
  }
}
.p-kurashi-enquete-complete .c-step3.is-orange .c-step3__item:last-child.is-active .c-step3__text {
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-complete .c-step3.is-orange .c-step3__item:last-child.is-active::before {
    background: url(../img/kurashi/enquete/step3-orange-sp-active1.svg) no-repeat center left/27px auto !important;
  }
}
.p-kurashi-enquete-complete__text {
  margin-top: 134px;
  font-size: 2.8rem;
  line-height: 42px;
  letter-spacing: 0.01em;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-complete__text {
    margin-top: 57px;
    font-size: 1.6rem;
    line-height: 21px;
  }
}
.p-kurashi-enquete-input {
  padding: 42px 0 134px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-input {
    padding: 21px 20px;
  }
}
.p-kurashi-enquete-input .c-kurashi-enqueteTitle1 {
  margin-bottom: 44px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-input .c-kurashi-enqueteTitle1 {
    margin-bottom: 25px;
  }
}
.p-kurashi-enquete-input .c-step3 {
  margin-top: 86px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-input .c-step3 {
    margin-top: 24px !important;
  }
}
.p-kurashi-enquete-input .c-step3__item.is-active {
  background-image: url(../img/kurashi/enquete/step3-bg-orange-active.png) !important;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-input .c-step3__item.is-active {
    background: #F0AF68;
  }
  .p-kurashi-enquete-input .c-step3__item.is-active::after {
    background-color: #F0AF68 !important;
  }
}
.p-kurashi-enquete-input .c-step3__item.is-active .c-step3__text {
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-input .c-step3__item.is-active:nth-child(1) + .c-step3__item:after {
    background: url(../img/kurashi/enquete/step3-orange-sp-active.svg) no-repeat center left/27px auto;
  }
}
.p-kurashi-enquete-input .c-form1 {
  margin-top: 117px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-input .c-form1 {
    margin-top: 37px;
  }
}
.p-kurashi-enquete-confirm {
  padding: 42px 0 134px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm {
    padding: 19px 20px;
  }
}
.p-kurashi-enquete-confirm .c-kurashi-enqueteTitle1 {
  margin-bottom: 40px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-kurashi-enqueteTitle1 {
    margin-bottom: 20px;
  }
}
.p-kurashi-enquete-confirm .c-step3.is-orange .c-step3__item.is-active {
  background-image: url(../img/kurashi/enquete/step3-bg1-orange-active.png) !important;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-step3.is-orange .c-step3__item.is-active {
    background-image: none !important;
  }
  .p-kurashi-enquete-confirm .c-step3.is-orange .c-step3__item.is-active::after {
    background-color: #F0AF68 !important;
  }
}
.p-kurashi-enquete-confirm .c-step3.is-orange .c-step3__item.is-active .c-step3__text {
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-step3.is-orange .c-step3__item.is-active::before {
    background: url(../img/kurashi/enquete/step3-orange-sp-active1.svg) no-repeat center left/27px auto;
  }
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-step3.is-orange .c-step3__item.is-active + .c-step3__item::before {
    background: url(../img/kurashi/enquete/step3-orange-sp-active.svg) no-repeat center left/27px auto;
  }
}
.p-kurashi-enquete-confirm .c-form1 {
  margin-top: 80px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-form1 {
    margin-top: 40px;
  }
}
.p-kurashi-enquete-confirm .c-form1__radio__text {
  white-space: nowrap;
}
.p-kurashi-enquete-confirm .c-form1__grcontrol.is-style1 {
  padding-top: 6px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-form1__grcontrol.is-style1 {
    padding-top: 3px;
  }
}
.p-kurashi-enquete-confirm .c-form1__grcontrol.is-style1 .c-form1__input + .c-form1__input {
  margin-top: 26px;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-form1__grcontrol.is-style1 .c-form1__input + .c-form1__input {
    margin-top: 22px;
  }
}
.p-kurashi-enquete-confirm .c-form1__grcontrol.is-style1 .c-form1__input__text {
  width: 122px;
}
.p-kurashi-enquete-confirm .c-form1__grcontrol.is-style1 .c-form1__input__right {
  top: 3px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-form1__grcontrol.is-style1 .c-form1__input__right {
    top: -1px;
  }
}
@media screen and (max-width: 640px) {
  .p-kurashi-enquete-confirm .c-cartBtn {
    display: flex;
    flex-wrap: wrap;
  }
  .p-kurashi-enquete-confirm .c-cartBtn a {
    height: 44px;
  }
  .p-kurashi-enquete-confirm .c-cartBtn .c-cartBtn__btn01 {
    order: 2;
    margin: 0;
  }
}

/*------------------------------------------------------------
個人情報保護方針
------------------------------------------------------------*/
.p-searchresult {
  padding: 102px 0 98px;
}
@media screen and (max-width: 640px) {
  .p-searchresult {
    padding: 85px 0 70px;
  }
}

/*------------------------------------------------------------
チケットTOP
------------------------------------------------------------*/
.p-ticketTop {
  background-color: #ffffff;
}
.p-ticketTop__cateList {
  margin: 0 0 80px;
}
.p-ticketTop__cateList--txt {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-ticketTop__cateList--txt {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}
.p-ticketTop__cateList--txt2 {
  font-size: 1.5rem;
  text-align: center;
  margin: 20px 0 30px;
}
@media screen and (max-width: 640px) {
  .p-ticketTop__cateList--txt2 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}
.p-ticketTop__cateList .c-ticketTitle01 {
  margin-bottom: 0px;
}
@media screen and (max-width: 640px) {
  .p-ticketTop__cateList .c-ticketTitle01 {
    margin-bottom: 10px;
  }
}
.p-ticketTop__cateList .c-tiketItems01__item dl dd ul {
  padding: 0 20px 40px 20px;
}
@media screen and (max-width: 640px) {
  .p-ticketTop__cateList .c-tiketItems01__item dl dd ul {
    padding: 0 10px 30px 10px;
  }
}

.p-ticketTop01 {
  margin-bottom: 83px;
}
.p-ticketTop01__slide {
  height: 380px;
  display: none;
}
.p-ticketTop01__slide.slick-initialized {
  display: block;
}
.p-ticketTop01__slide .slick-slide {
  height: 380px;
  background-color: #ffffff;
  position: relative;
}
.p-ticketTop01__slide .slick-slide a {
  width: 100%;
  height: 100%;
  color: #ffffff;
  opacity: 1;
  display: block;
  position: relative;
  transition: opacity 0.3s ease-in-out;
}
.p-ticketTop01__slide .slick-slide a:hover {
  opacity: 0.8;
}
.p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__bg {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  -ms-filter: blur(5px);
  filter: blur(5px);
  overflow: hidden;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
}
.p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__img {
  width: 100%;
  height: 100%;
  display: block;
  transition: opacity 0.5s ease-in-out;
  position: relative;
  z-index: 10;
}
.p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__img img {
  width: auto;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__text {
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 15;
}
.p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__text__title {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.2;
  padding: 30px 40px 8px;
  background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.7), transparent);
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.7), transparent);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  background-size: 100%;
}
.p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__text__excerpt {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 7px 40px 30px;
  background: rgba(0, 0, 0, 0.7);
}
.p-ticketTop01__slide .slick-slide:not(.slick-center):before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background-color: transparent;
  z-index: 100;
}
.p-ticketTop01__slide .slick-slide:not(.slick-center) a {
  opacity: 0.4;
}
.p-ticketTop01__slide .slick-slide:not(.slick-center) .p-ticketTop01__slide__text {
  opacity: 0;
}
.p-ticketTop01__slide .slick-arrow {
  width: 30px;
  height: 60px;
}
.p-ticketTop01__slide .slick-arrow:before {
  content: none;
}
.p-ticketTop01__slide .slick-arrow.slick-prev {
  background-color: rgba(255, 255, 255, 0.8) !important;
  background-image: url("/img/common/icon_arrow-gray01.png") !important;
  -webkit-background-size: 8px !important;
  background-size: 8px !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  opacity: 1 !important;
  top: calc(50% - 30px);
  left: calc(20% - 30px);
  z-index: 10;
  transform: rotate(180deg);
}
.p-ticketTop01__slide .slick-arrow.slick-next {
  background-color: rgba(255, 255, 255, 0.8) !important;
  background-image: url("/img/common/icon_arrow-gray01.png") !important;
  -webkit-background-size: 8px !important;
  background-size: 8px !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  opacity: 1 !important;
  top: calc(50% - 30px);
  right: calc(20% - 30px);
  z-index: 10;
  transform: none;
}
.p-ticketTop01__slide .slick-dots {
  bottom: -33px;
}
.p-ticketTop01__slide .slick-dots li {
  width: 60px;
  height: 14px;
  margin: 0 2px;
}
.p-ticketTop01__slide .slick-dots li button {
  width: 60px;
  height: 14px;
}
.p-ticketTop01__slide .slick-dots li button:before {
  width: 60px;
  height: 4px;
  content: "";
  background-color: #dddddd;
  opacity: 1 !important;
}
.p-ticketTop01__slide .slick-dots li.slick-active button:before {
  content: "";
  background-color: #fc819b;
}

.p-ticketTop02 {
  margin-bottom: 40px;
  padding: 45px 0 43px;
  background: url("/img/ticket/line01.png") repeat-x 0 0, url("/img/ticket/line01.png") repeat-x 0 bottom;
}
.p-ticketTop02__content01 {
  margin-bottom: 21px;
  padding: 0 21px 20px;
  border-bottom: dashed 1px #999999;
}
.p-ticketTop02__content01 p {
  width: 225px;
  font-size: 2rem;
  font-weight: bold;
  color: #232934;
}
.p-ticketTop02__content01 p:before {
  width: 18px;
  height: 18px;
  margin: 0 10px 0 0;
  content: "";
  background-image: url("/img/common/icon_search01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-ticketTop02__content01__search {
  width: 570px;
  position: relative;
}
.p-ticketTop02__content01__search input[type=text] {
  width: 100%;
  height: 40px;
  padding: 0 130px 0 10px;
  font-size: 1.4rem;
  line-height: 2.57;
  color: #46483c;
  border: solid 1px rgba(75, 88, 112, 0.5);
  border-radius: 0;
}
.p-ticketTop02__content01__search input[type=submit] {
  width: 117px;
  height: 32px;
  display: block;
  border: none;
  background-image: url("/img/common/icon_search07.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 4px;
  right: 4px;
  appearance: none;
  border-radius: 0;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
}
.p-ticketTop02__content02 {
  margin-bottom: 6px;
  padding: 0 15px 0 21px;
}
.p-ticketTop02__content02 p {
  width: 225px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #232934;
}
.p-ticketTop02__content02 p:before {
  width: 18px;
  height: 18px;
  margin: -2px 10px 0 0;
  content: "";
  background-image: url("/img/common/icon_light01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-ticketTop02__content02__catelist {
  width: 575px;
}
.p-ticketTop02__content02__catelist li {
  margin: 0 5px 5px 0;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  background-color: rgba(75, 88, 112, 0.1);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.p-ticketTop02__content02__catelist li a {
  padding: 5px 13px 1px;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: block;
  color: #232934;
}
@media all and (-ms-high-contrast: none) {
  .p-ticketTop02__content02__catelist li a {
    padding: 5px 13px 1px;
  }
}
.p-ticketTop02__content03 {
  padding: 0 15px 0 21px;
}
.p-ticketTop02__content03 p {
  width: 225px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #232934;
}
.p-ticketTop02__content03 p:before {
  width: 18px;
  height: 18px;
  margin: -2px 10px 0 0;
  content: "";
  background-image: url("/img/common/icon_shine01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-ticketTop02__content03__catelist {
  width: 575px;
}
.p-ticketTop02__content03__catelist li {
  margin: 0 5px 5px 0;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: rgba(75, 88, 112, 0.1);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.p-ticketTop02__content03__catelist li a {
  padding: 3px 12px 1px;
  width: 100%;
  height: 100%;
  display: block;
  color: #232934;
}
@media all and (-ms-high-contrast: none) {
  .p-ticketTop02__content03__catelist li a {
    padding: 5px 13px 0px;
  }
}

.p-ticketTop03 {
  margin-bottom: 60px;
  padding-bottom: 52px;
  background: url("/img/ticket/line01.png") repeat-x 0 bottom;
}
.p-ticketTop03__content01__cateBox__cateList {
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
  display: table;
}
.p-ticketTop03__content01__cateBox__cateList > * {
  display: table-cell;
}
.p-ticketTop03__content01__cateBox__cateList li {
  padding: 0.5em;
  font-size: 1.6rem;
  color: #232934;
  text-align: center;
  border: solid 1px #dddddd;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.p-ticketTop03__content01__cateBox__cateList li:hover, .p-ticketTop03__content01__cateBox__cateList li.is-open {
  color: #ffffff;
  background-color: #fc819b;
}
.p-ticketTop03__content01__cateBox__items {
  padding: 0 28px;
  display: none;
}
.p-ticketTop03__content01__cateBox__items.is-open {
  display: block;
}
.p-ticketTop03__content01__cateBox__items__slider {
  display: none;
}
.p-ticketTop03__content01__cateBox__items__slider.slick-initialized {
  display: block;
}
.p-ticketTop03__content01__cateBox__items__slider .slick-arrow {
  width: 11px;
  height: 19px;
}
.p-ticketTop03__content01__cateBox__items__slider .slick-arrow:before {
  content: none;
}
.p-ticketTop03__content01__cateBox__items__slider .slick-arrow.slick-prev {
  background-image: url("/img/common/icon_arrow-green02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  transform: rotate(180deg);
}
.p-ticketTop03__content01__cateBox__items__slider .slick-arrow.slick-next {
  background-image: url("/img/common/icon_arrow-green02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  transform: none;
}
.p-ticketTop03__content01__cateBox__items__slider .slick-dots {
  bottom: -37px;
}
.p-ticketTop03__content01__cateBox__items__slider .slick-dots li {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  padding: 1px;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-ticketTop03__content01__cateBox__items__slider .slick-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
  background-color: #ffffff !important;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-ticketTop03__content01__cateBox__items__slider .slick-dots li button:before {
  content: none;
}
@media screen and (max-width: 640px) {
  .p-ticketTop03__content01__cateBox__items__slider .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
  }
  .p-ticketTop03__content01__cateBox__items__slider .slick-dots li button {
    width: 6px;
    height: 6px;
  }
}
.p-ticketTop03__content01__cateBox__items__slider .slick-dots li.slick-active button {
  background-color: #9ac985 !important;
}
.p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item {
  margin: 0 15px !important;
}
.p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item .c-tiketItems01__item__img {
  height: 130px;
}
.p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item dl dt {
  line-height: 1.7;
}

.p-ticketTop04 {
  margin-bottom: 40px;
}
.p-ticketTop04__content01 .c-ticketTitle01 {
  margin-bottom: 29px;
}

.p-ticketTop05 {
  margin-bottom: 62px;
}
.p-ticketTop05__content01 {
  padding: 22px 32px 30px;
  background: url(/img/ticket/line01.png) repeat-x 0 0, url(/img/ticket/line01.png) repeat-x 0 bottom, url(/img/ticket/wakusen_tate01.png) repeat-y 0 0;
  position: relative;
}
.p-ticketTop05__content01 h2 {
  margin-bottom: 10px;
  padding-bottom: 18px;
  font-size: 2rem;
  font-weight: bold;
  color: #232934;
  border-bottom: solid 1px #fc819b;
}
.p-ticketTop05__content01__newsList li {
  padding: 19px 0 20px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
}
.p-ticketTop05__content01__newsList li:after {
  content: "";
  height: 1px;
  width: 100%;
  display: block;
  background-image: url(/img/ticket/line03.png);
  background-repeat: repeat-x;
  position: absolute;
  left: 0;
  bottom: 0;
}
.p-ticketTop05__content01__newsList li:last-child {
  padding-bottom: 0;
}
.p-ticketTop05__content01__newsList li:last-child:after {
  content: none;
}
.p-ticketTop05__content01__newsList li a {
  display: flex;
}
.p-ticketTop05__content01__newsList li a:hover .p-ticketTop05__content01__newsList_title {
  text-decoration: none;
}
.p-ticketTop05__content01__newsList__thmbs {
  width: 60px;
  height: 45px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.p-ticketTop05__content01__newsList__thmbs img {
  width: 100%;
}
.p-ticketTop05__content01__newsList__txt {
  padding-left: 20px;
}
.p-ticketTop05__content01__newsList__txt a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-ticketTop05__content01__newsList_data {
  width: 88px;
  margin-bottom: 5px;
  color: #666666;
  font-weight: 500;
  display: block;
}
.p-ticketTop05__content01__newsList_title {
  color: #308bbb;
  text-decoration: underline;
}
.p-ticketTop05__content01__newsList .c-newsCate02 {
  width: auto;
  padding: 0 8px;
  margin-bottom: 5px;
  display: block;
}
.p-ticketTop05__content01__newsList .l-flex {
  display: flex;
}
.p-ticketTop05__content01 .c-ticketBtn01 {
  position: absolute;
  top: 23px;
  right: 30px;
}
.p-ticketTop05__content01 .c-ticketBtn01 a {
  font-size: 1.2rem;
  line-height: 2.8;
}

.p-ticketTop06 {
  margin-bottom: 96px;
}
.p-ticketTop06__content01__case {
  margin-bottom: 34px;
}
.p-ticketTop06__content01__annotation {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.5;
  color: #232934;
}

@media screen and (max-width: 640px) {
  .p-ticketTop__cateList {
    margin: 70px 0;
    padding: 0 20px;
  }
  .p-ticketTop01 {
    margin-bottom: 57px;
  }
  .p-ticketTop01__slide {
    height: auto;
  }
  .p-ticketTop01__slide .slick-slide {
    height: auto;
    background: none;
  }
  .p-ticketTop01__slide .slick-slide a {
    height: 100%;
    min-height: 280px;
    transition: none;
    overflow: hidden;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__img {
    width: 100%;
    height: 70%;
    max-height: 279px;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__img img {
    position: static;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__img:after {
    display: none;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__img.portrait img {
    width: 100%;
    height: auto;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__img.landscape img {
    width: auto;
    height: 100%;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__bg {
    max-height: 279px;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__text {
    width: 100%;
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__text__title {
    font-size: 1.7rem;
    padding: 15px 20px;
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.9), transparent);
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.9), transparent);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
  }
  .p-ticketTop01__slide .slick-slide .p-ticketTop01__slide__text__excerpt {
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0 20px 18px 20px;
    background: #000;
  }
  .p-ticketTop01__slide .slick-slide:not(.slick-center):before {
    content: none;
  }
  .p-ticketTop01__slide .slick-slide:not(.slick-center) a {
    opacity: 1;
  }
  .p-ticketTop01__slide .slick-slide:not(.slick-center) .p-ticketTop01__slide__text {
    opacity: 1;
  }
  .p-ticketTop01__slide .slick-arrow {
    width: 10px;
    height: 14px;
  }
  .p-ticketTop01__slide .slick-arrow.slick-prev {
    -webkit-background-size: cover !important;
    background-size: cover !important;
    top: auto;
    bottom: -32px;
    left: 13%;
  }
  .p-ticketTop01__slide .slick-arrow.slick-next {
    -webkit-background-size: cover !important;
    background-size: cover !important;
    top: auto;
    bottom: -32px;
    right: 13%;
  }
  .p-ticketTop01__slide .slick-dots {
    bottom: -37px;
    width: 60%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .p-ticketTop01__slide .slick-dots li {
    width: 30px;
  }
  .p-ticketTop01__slide .slick-dots li button {
    width: 30px;
  }
  .p-ticketTop01__slide .slick-dots li button:before {
    width: 30px;
  }
  .p-ticketTop02 {
    margin-bottom: 22px;
    padding: 0 20px;
    background: none;
  }
  .p-ticketTop02__content01 {
    padding-top: 26px;
    padding: 26px 0 20px;
    background: url("/img/ticket/line01_sp.png") repeat-x 0 0;
    background-size: 15px;
    margin-bottom: 14px;
  }
  .p-ticketTop02__content01 p {
    width: 100%;
    margin-bottom: 9px;
    font-size: 1.6rem;
  }
  .p-ticketTop02__content01 p:before {
    margin: -2px 5px 0 0;
  }
  .p-ticketTop02__content01__search {
    width: 100%;
  }
  .p-ticketTop02__content01__search input[type=text] {
    padding: 0 40px 0 10px;
    font-size: 1.2rem;
  }
  .p-ticketTop02__content01__search input[type=submit] {
    width: 32px;
    height: 32px;
    background-image: url("/img/common/icon_search06.png");
  }
  .p-ticketTop02__content02 {
    margin-bottom: 13px;
    padding: 0;
  }
  .p-ticketTop02__content02 p {
    width: 100%;
    margin-bottom: 15px;
    font-size: 1.4rem;
  }
  .p-ticketTop02__content02 p:before {
    margin: -2px 5px 0 0;
  }
  .p-ticketTop02__content02__catelist {
    width: 100%;
  }
  .p-ticketTop02__content02__catelist li {
    margin: 0 6px 5px 0;
    font-size: 1.2rem;
  }
  .p-ticketTop02__content02__catelist li a {
    padding: 3px 9px 1px;
  }
  .p-ticketTop02__content03 {
    background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
    background-size: 15px;
    padding: 0 0 24px;
  }
  .p-ticketTop02__content03 p {
    width: 100%;
    margin-bottom: 15px;
    font-size: 1.4rem;
  }
  .p-ticketTop02__content03 p:before {
    margin: -2px 5px 0 0;
  }
  .p-ticketTop02__content03__catelist {
    width: 100%;
  }
  .p-ticketTop02__content03__catelist li {
    margin: 0 6px 5px 0;
    font-size: 1.2rem;
  }
  .p-ticketTop02__content03__catelist li a {
    padding: 3px 9px 1px;
  }
  .p-ticketTop03 {
    margin-bottom: 41px;
    padding: 0 20px;
    background: none;
  }
  .p-ticketTop03__content01 {
    padding-bottom: 44px;
    background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
    background-size: 15px;
  }
  .p-ticketTop03__content01 > p {
    margin-bottom: 5px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #232934;
  }
  .p-ticketTop03__content01__cateBox__cateList {
    margin-bottom: 16px;
    letter-spacing: -0.4em;
  }
  .p-ticketTop03__content01__cateBox__cateList > * {
    display: inline-block;
    letter-spacing: normal;
  }
  .p-ticketTop03__content01__cateBox__cateList li {
    margin: 0 4px 5px 0;
    font-size: 1.2rem;
    padding: 1px 9px;
    letter-spacing: -0.5px;
  }
  .p-ticketTop03__content01__cateBox__items {
    padding: 0;
  }
  .p-ticketTop03__content01__cateBox__items__slider {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .p-ticketTop03__content01__cateBox__items__slider .slick-arrow {
    height: 16px;
  }
  .p-ticketTop03__content01__cateBox__items__slider .slick-arrow.slick-prev {
    top: auto;
    bottom: -31px;
    left: 15%;
  }
  .p-ticketTop03__content01__cateBox__items__slider .slick-arrow.slick-next {
    top: auto;
    bottom: -31px;
    right: 15%;
  }
  .p-ticketTop03__content01__cateBox__items__slider .slick-dots {
    bottom: -30px;
    width: 60%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item {
    margin: 0 10px !important;
    padding-bottom: 2px;
  }
  .p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item__subCate {
    padding: 0 10px;
    margin: 0 0 10px 9px;
  }
  .p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item dd p {
    line-height: 1.3;
  }
  .p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item dd li {
    line-height: 1.2;
  }
  .p-ticketTop03__content01__cateBox__items__slider .c-tiketItems01__item .c-tiketItems01__item__img {
    height: 74px;
  }
  .p-ticketTop04 {
    margin-bottom: 21px;
    padding: 0 20px;
  }
  .p-ticketTop04__content01 {
    padding-bottom: 36px;
    background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
    background-size: 15px;
  }
  .p-ticketTop04__content01 .c-ticketTitle01 {
    margin-bottom: 10px;
  }
  .p-ticketTop05 {
    margin-bottom: 39px;
    padding: 0 20px;
  }
  .p-ticketTop05__content01 {
    padding: 0 0 30px 0;
    background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
    background-size: 15px;
  }
  .p-ticketTop05__content01 h2 {
    margin-bottom: 0;
    padding-bottom: 14px;
    font-size: 1.6rem;
  }
  .p-ticketTop05__content01__newsList {
    margin-bottom: 20px;
  }
  .p-ticketTop05__content01__newsList li {
    padding: 20px 0 21px;
  }
  .p-ticketTop05__content01__newsList li:after {
    background-image: url(/img/ticket/line04.png);
    background-repeat: repeat-x;
    background-size: 4px 1px;
  }
  .p-ticketTop05__content01__newsList li a {
    font-size: 1.3rem;
    width: 100%;
  }
  .p-ticketTop05__content01__newsList li:last-child {
    padding-bottom: 22px;
  }
  .p-ticketTop05__content01__newsList li:last-child:after {
    content: "";
  }
  .p-ticketTop05__content01__newsList__thmbs {
    min-width: 32%;
    height: auto;
  }
  .p-ticketTop05__content01__newsList__thmbs img {
    width: 100%;
  }
  .p-ticketTop05__content01__newsList__txt {
    width: 61%;
    margin-left: 7%;
    padding: 0;
  }
  .p-ticketTop05__content01__newsList_data {
    width: 80px;
  }
  .p-ticketTop05__content01__newsList .c-newsCate02 {
    padding: 0 4px;
  }
  .p-ticketTop05__content01 .c-ticketBtn01 {
    margin: 0 auto;
    position: static;
    width: 72%;
    height: 40px;
  }
  .p-ticketTop05__content01 .c-ticketBtn01 a {
    font-size: 1.4rem;
    line-height: 3.1;
  }
  .p-ticketTop05__content01 .c-ticketBtn01 a:after {
    margin: -3px -16px 0 20px;
  }
  .p-ticketTop06 {
    margin-bottom: 56px;
  }
  .p-ticketTop06__content01__case {
    margin: 0;
    padding: 0;
    background-color: #efefef;
  }
  .p-ticketTop06__content01__case__accordionBtn {
    margin: 0 !important;
  }
  .p-ticketTop06__content01__case__accordionBtn span {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }
  .p-ticketTop06__content01__case__accordionBtn span:before {
    width: 20px;
    height: 2px;
    content: "";
    background-color: #fc819b;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-out;
  }
  .p-ticketTop06__content01__case__accordionBtn span:after {
    width: 2px;
    height: 20px;
    content: "";
    background-color: #fc819b;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-out;
  }
  .p-ticketTop06__content01__case .c-step {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding-bottom: 20px;
    display: none;
  }
  .p-ticketTop06__content01__case .c-step .c-step__head .c-step__icon {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .p-ticketTop06__content01__case .c-step .c-step__head .c-step__head-inner {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .p-ticketTop06__content01__case.is-open .p-ticketTop06__content01__case__accordionBtn span:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  .p-ticketTop06__content01__case.is-open .c-step {
    display: block;
  }
  .p-ticketTop06__content01__annotation {
    margin-top: 20px;
    padding: 0 20px;
  }
  .p-ticketTop06 .c-ticketTitle01 {
    line-height: 1.4;
    margin-bottom: 15px;
    padding: 0 25px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット 特集商品一覧
------------------------------------------------------------*/
.p-ticketFeature01 {
  margin-bottom: 30px;
}
.p-ticketFeature01__img {
  margin-bottom: 39px;
}
.p-ticketFeature01__img img {
  margin: 0 auto;
  display: block;
}
.p-ticketFeature01__text {
  padding-top: 20px;
  color: #232934;
  background-image: url("../img/ticket/line01.png");
  background-position: 0 0;
  background-repeat: repeat-x;
}
.p-ticketFeature01__text h1 {
  margin-bottom: 13px;
  font-size: 2.8rem;
  font-weight: bold;
}
.p-ticketFeature01__text h1 a {
  color: #232934;
}
.p-ticketFeature01__text p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}

.p-ticketFeature02 {
  margin-bottom: 54px;
}
.p-ticketFeature02 .c-tiketItems01__item dl {
  margin-bottom: 23px;
}
.p-ticketFeature02 .c-tiketItems01__item dl dt {
  font-size: 1.4rem;
  line-height: 2;
}
.p-ticketFeature02 .c-tiketItems01__item dl dd {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.p-ticketFeature02 .c-tiketItems01__item dl dd a {
  color: #ef004f;
}
.p-ticketFeature02__list .c-tiketItems01__item {
  width: 260px;
  margin-bottom: 40px;
  margin-right: 30px;
  margin-left: 0;
}
.p-ticketFeature02__list .c-tiketItems01__item:nth-of-type(3n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .p-ticketFeature02__list .c-tiketItems01__item:nth-of-type(3n) {
    margin-right: 6%;
  }
}
.p-ticketFeature02__list .c-tiketItems01__item__img {
  height: 195px;
}
.p-ticketFeature02__list .c-tiketItems01__item__subCate {
  margin-bottom: 5px;
}
.p-ticketFeature02__list .c-tiketItems01__item dl {
  padding-bottom: 7px;
}
.p-ticketFeature02__list .c-tiketItems01__item dl dd ul {
  padding-bottom: 18px;
}

.p-ticketFeature03 {
  margin-bottom: 95px;
}
.p-ticketFeature03__slider {
  padding: 0 20px;
}
.p-ticketFeature03__slider__item {
  width: calc(33.3333% - 20px);
  margin: 0 10px;
}
.p-ticketFeature03__slider__item img {
  width: 100%;
  height: 138px;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
}
.p-ticketFeature03__slider__item:hover {
  opacity: 0.8;
  transition: 0.3s ease-in-out;
}
.p-ticketFeature03__slider__item__title {
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
}
.p-ticketFeature03__slider__item a {
  color: #ef004f;
}
.p-ticketFeature03__slider .slick-arrow {
  width: 11px;
  height: 19px;
  margin-top: -19px;
}
.p-ticketFeature03__slider .slick-arrow:before {
  content: none;
}
.p-ticketFeature03__slider .slick-arrow.slick-prev {
  background-image: url("../img/common/icon_arrow-green02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  z-index: 10;
  left: 0;
  transform: rotate(180deg);
}
.p-ticketFeature03__slider .slick-arrow.slick-next {
  background-image: url("../img/common/icon_arrow-green02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  z-index: 10;
  right: 0;
  transform: none;
}

@media screen and (max-width: 640px) {
  .p-ticketFeature01 {
    margin-bottom: 26px;
  }
  .p-ticketFeature01__img {
    margin-bottom: 20px;
  }
  .p-ticketFeature01__img img {
    width: 100%;
  }
  .p-ticketFeature01__text {
    padding-top: 21px;
    margin: 0 20px;
    background-image: url("../img/ticket/line01_sp.png");
    background-size: 15px;
  }
  .p-ticketFeature01__text h1 {
    margin-bottom: 10px;
    font-size: 1.8rem;
    line-height: 1.4;
  }
  .p-ticketFeature01__text p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-ticketFeature02 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-ticketFeature02 .c-tiketItems01__item {
    width: 47%;
    margin: 0 6% 0 0;
  }
  .p-ticketFeature02 .c-tiketItems01__item:nth-child(2n) {
    margin: 0;
  }
  .p-ticketFeature02 .c-tiketItems01__item__img {
    height: 97px;
  }
  .p-ticketFeature02 .c-tiketItems01__item__subCate {
    margin-bottom: 8px;
  }
  .p-ticketFeature02 .c-tiketItems01__item dl dt {
    font-size: 1.2rem;
    line-height: 1.8;
  }
  .p-ticketFeature02 .c-tiketItems01__item dl {
    padding-bottom: 0;
  }
  .p-ticketFeature02 .c-tiketItems01__item dl dd ul {
    padding-bottom: 10px;
  }
  .p-ticketFeature03 {
    padding: 0 20px;
    margin-bottom: 69px;
  }
  .p-ticketFeature03__slider {
    padding: 0;
  }
  .p-ticketFeature03__slider__item {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: dashed 1px #666666;
  }
  .p-ticketFeature03__slider__item img {
    width: 100%;
    height: 69px;
  }
  .p-ticketFeature03__slider__item__title {
    width: 60%;
    margin: 0;
    padding-left: 10px;
    font-size: 1.4rem;
  }
  .p-ticketFeature03__slider__item a {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .p-ticketFeature03__slider__item a img {
    width: 44%;
  }
  .p-ticketFeature03__slider .slick-arrow {
    margin: 0;
  }
  .p-ticketFeature03__slider .slick-arrow.slick-prev {
    top: auto;
    bottom: -26px;
    left: 15%;
  }
  .p-ticketFeature03__slider .slick-arrow.slick-next {
    top: auto;
    bottom: -26px;
    right: 15%;
  }
  .p-ticketFeature03__slider .slick-dots {
    width: 60%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -23px;
  }
  .p-ticketFeature03__slider .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    padding: 1px;
    background-color: #ffffff;
    border: solid 1px #dddddd;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .p-ticketFeature03__slider .slick-dots li button {
    width: 6px;
    height: 6px;
    padding: 0;
    background-color: #ffffff !important;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .p-ticketFeature03__slider .slick-dots li button:before {
    content: none;
  }
  .p-ticketFeature03__slider .slick-dots li.slick-active button {
    background-color: #9ac985 !important;
  }
  .p-ticketFeature03 .c-ticketTitle03 {
    margin-bottom: 20px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット 商品一覧
------------------------------------------------------------*/
.p-ticketService {
  background-color: #ffffff;
}

.p-ticketService01__content01 {
  margin-bottom: 30px;
  padding-top: 35px;
  background-image: url("../img/ticket/line01.png");
  background-repeat: repeat-x;
  background-position: 0 0;
}
.p-ticketService01__content01 p {
  font-size: 2rem;
  font-weight: bold;
  color: #232934;
}
.p-ticketService01__content01 p:before {
  width: 18px;
  height: 18px;
  margin: -2px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_search01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-ticketService01__content01__search {
  width: 570px;
  margin: -1px 42px 0;
  position: relative;
}
.p-ticketService01__content01__search input[type=text] {
  width: 100%;
  height: 40px;
  padding: 0 130px 0 14px;
  font-size: 1.4rem;
  line-height: 2.57;
  color: #46483c;
  border: solid 1px rgba(75, 88, 112, 0.5);
}
.p-ticketService01__content01__search input[type=submit] {
  width: 117px;
  height: 32px;
  display: block;
  border: none;
  background-image: url("../img/common/icon_search07.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 4px;
  right: 4px;
  text-indent: 1000em;
  white-space: nowrap;
  overflow: hidden;
}

.p-ticketService02 {
  margin-bottom: 130px;
}
.p-ticketService02__content01 .c-ticketTitle04 {
  margin-bottom: 30px;
}
.p-ticketService02__content01 .c-tiketItems02__item {
  margin-bottom: 40px;
  margin-right: 30px;
}
.p-ticketService02__content01 .c-tiketItems02__item:nth-of-type(3n) {
  margin-right: 0;
}
.p-ticketService02__content01 .c-tiketItems02__item a {
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.1);
}
.p-ticketService02__content01 .c-tiketItems02__item__tags {
  top: 5px;
}
.p-ticketService02__content01 .c-tiketItems02__item__subCate {
  padding: 0 11px;
  margin-bottom: 5px;
}
.p-ticketService02__content01 .c-ticketPager01 {
  margin-top: -8px;
}
@media screen and (max-width: 640px) {
  .p-ticketService {
    padding: 30px 20px 0 20px;
  }
  .p-ticketService01__content01 {
    padding-top: 26px;
    margin-bottom: 25px;
    background-image: url("../img/ticket/line01_sp.png");
    -webkit-background-size: 15px;
    background-size: 15px;
  }
  .p-ticketService01__content01 p {
    width: 100%;
    margin-bottom: 9px;
    font-size: 1.6rem;
  }
  .p-ticketService01__content01 p:before {
    margin-right: 5px;
  }
  .p-ticketService01__content01__search {
    width: 100%;
    margin: 0;
  }
  .p-ticketService01__content01__search input[type=text] {
    padding: 4px 40px 4px 10px;
    font-size: 1.2rem;
    border-radius: 0;
  }
  .p-ticketService01__content01__search input[type=submit] {
    width: 32px;
    height: 32px;
    background-image: url("../img/common/icon_search06.png");
    border-radius: 0;
  }
  .p-ticketService02 {
    margin-bottom: 35px;
  }
  .p-ticketService02__content01 .c-ticketTitle04 {
    margin-bottom: 25px;
    padding-top: 31px;
  }
  .p-ticketService02__content01 .c-tiketItems02__item {
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .p-ticketService02__content01 .c-tiketItems02__item:nth-of-type(3n) {
    margin-right: 10px;
  }
  .p-ticketService02__content01 .c-tiketItems02__item:nth-of-type(2n) {
    margin-right: 0;
  }
  .p-ticketService02__content01 .c-tiketItems02__item p {
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .p-ticketService02__content01 .c-tiketItems02__item__subCate {
    padding: 0 10px;
    margin-bottom: 8px;
  }
  .p-ticketService02__content01 .c-ticketPager01 {
    margin-top: 5px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット 商品詳細
------------------------------------------------------------*/
.p-ticketServiceDetail {
  background-color: #ffffff;
}

.p-ticketServiceDetail01 {
  margin-bottom: 39px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #232934;
}
.p-ticketServiceDetail01__content01__title {
  margin-bottom: 19px;
  padding-top: 40px;
  font-weight: bold;
  background-image: url("../img/ticket/line01.png");
  background-repeat: repeat-x;
  background-position: 0 0;
}
.p-ticketServiceDetail01__content01__title h1 {
  font-size: 2.8rem;
}
.p-ticketServiceDetail01__content01__title p {
  font-size: 2rem;
  letter-spacing: 1px;
  line-height: 1.8;
}
.p-ticketServiceDetail01__content01__tags {
  margin-bottom: 38px;
  padding: 0 13px;
  display: inline-block;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: bold;
}
.p-ticketServiceDetail01__content01__tags--osusume {
  background-color: #f0662b;
}
.p-ticketServiceDetail01__content01__tags--ninki {
  background-color: #ea3d3d;
}
.p-ticketServiceDetail01__content02 {
  margin-bottom: 72px;
}
.p-ticketServiceDetail01__content02__slider {
  width: 710px;
  height: 100%;
  margin: 0 auto;
}
.p-ticketServiceDetail01__content02__slider img {
  width: auto;
  height: auto;
  max-height: 333px;
  margin: 0 auto;
}
.p-ticketServiceDetail01__content02__slider .slick-slide {
  height: 100%;
}
.p-ticketServiceDetail01__content02__slider .slick-track {
  display: flex;
  align-items: center;
}
.p-ticketServiceDetail01__content02__slider .slick-arrow {
  width: 11px;
  height: 19px;
  margin-top: -10px;
}
.p-ticketServiceDetail01__content02__slider .slick-arrow:before {
  content: none;
}
.p-ticketServiceDetail01__content02__slider .slick-arrow.slick-prev {
  background-image: url("../img/common/icon_arrow-green02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  z-index: 10;
  left: -63px;
  transform: rotate(180deg);
}
.p-ticketServiceDetail01__content02__slider .slick-arrow.slick-next {
  background-image: url("../img/common/icon_arrow-green02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  z-index: 10;
  right: -66px;
  transform: none;
}
.p-ticketServiceDetail01__content02__slider .slick-dots {
  width: 60%;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -35px;
}
.p-ticketServiceDetail01__content02__slider .slick-dots li {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  padding: 1px;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-ticketServiceDetail01__content02__slider .slick-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
  background-color: #ffffff !important;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-ticketServiceDetail01__content02__slider .slick-dots li button:before {
  content: none;
}
.p-ticketServiceDetail01__content02__slider .slick-dots li.slick-active button {
  background-color: #9ac985 !important;
}
.p-ticketServiceDetail01__content03 {
  margin-bottom: 36px;
  height: 100%;
}
.p-ticketServiceDetail01__content03 iframe {
  width: 710px;
  height: 333px;
  margin: 0 auto;
  display: block;
}
.p-ticketServiceDetail01__content04 {
  margin-bottom: 60px;
}
.p-ticketServiceDetail01__content04 > p {
  margin-bottom: 23px;
}
.p-ticketServiceDetail01__content04__attention {
  padding: 26px 30px 23px;
  font-size: 1.3rem;
  background-color: #efefef;
}
.p-ticketServiceDetail01__content04 .c-ticketTitle02b {
  margin-bottom: 24px;
}
.p-ticketServiceDetail01__content05 {
  margin-bottom: 36px;
}
.p-ticketServiceDetail01__content05 .c-table2 {
  width: 840px;
  margin-bottom: 17px;
  border-color: #dddddd;
  font-size: 1.4rem;
}
.p-ticketServiceDetail01__content05 .c-table2 dt {
  width: 240px;
  background-color: #fef2f5;
}
.p-ticketServiceDetail01__content05 .c-table2 dd {
  width: calc(100% - 240px);
}
.p-ticketServiceDetail01__content05 .c-table2 a {
  color: #308bbb;
  text-decoration: underline;
}
.p-ticketServiceDetail01__content05 .c-table2 a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-ticketServiceDetail01__content05__annotation {
  font-size: 1.3rem;
}
.p-ticketServiceDetail01__content06 {
  margin-bottom: 40px;
}
.p-ticketServiceDetail01__content06 .c-tbl01 {
  width: 840px;
  margin-bottom: 20px;
  color: #232934;
}
.p-ticketServiceDetail01__content06 .c-tbl01 th {
  padding: 10px 10px 9px;
  white-space: nowrap;
}
.p-ticketServiceDetail01__content06 .c-tbl01 td {
  padding: 17px 10px 15px;
}
.p-ticketServiceDetail01__content06 .c-tbl01__title {
  background: #fef2f5;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-date {
  min-width: 140px;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-time {
  min-width: 140px;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-locate {
  min-width: 80px;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-type {
  min-width: 80px;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-note {
  min-width: 90px;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-price {
  min-width: 100px;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num {
  white-space: nowrap;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num .c-error {
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num .c-error {
    text-align: left;
  }
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num select {
  width: 100%;
  height: 40px;
  padding: 0 7px;
  font-weight: bold;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  appearance: none;
  outline-width: 0;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num select option {
  color: #000;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num select::-ms-expand {
  display: none;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num .selectbox {
  min-width: 63px;
  width: 80%;
  display: inline-flex;
  position: relative;
}
.p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num .selectbox:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #111;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
}
@media screen and (max-width: 640px) {
  .p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num .selectbox {
    width: 150px;
  }
  .p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num .selectbox:after {
    right: 13px;
  }
}
.p-ticketServiceDetail01__content06 .c-error {
  margin-top: 0;
}
.p-ticketServiceDetail01__content06 .c-error__box {
  display: block;
  text-align: right;
}
@media screen and (max-width: 640px) {
  .p-ticketServiceDetail01__content06 .c-error__box {
    text-align: center;
  }
}
.p-ticketServiceDetail01__content06 .c-cartBtn {
  margin-top: 0;
  display: block;
  *zoom: 1;
}
.p-ticketServiceDetail01__content06 .c-cartBtn:after {
  display: block;
  content: "";
  clear: both;
}
.p-ticketServiceDetail01__content06 .c-cartBtn__btn02b {
  width: 200px;
  height: 52px;
}

.p-ticketServiceDetail02 {
  margin-bottom: 53px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #232934;
}
.p-ticketServiceDetail02 dl {
  padding: 26px 38px 29px;
  border: solid 5px #fef2f5;
}
.p-ticketServiceDetail02 dl dt {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: bold;
}
.p-ticketServiceDetail02 dl dd ul {
  margin-bottom: 10px;
  font-size: 1.3rem;
}
.p-ticketServiceDetail02 dl dd a {
  font-size: 1.5rem;
  color: #308bbb;
  text-decoration: underline;
}
.p-ticketServiceDetail02 dl dd a:before {
  width: 6px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-pink01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-ticketServiceDetail02 dl dd a:hover {
  opacity: 1;
  text-decoration: none;
}

.p-ticketServiceDetail03 {
  margin-bottom: 96px;
}
.p-ticketServiceDetail03__content01 .c-ticketTitle03 {
  margin-bottom: 30px;
}
.p-ticketServiceDetail03__content01 .c-tiketItems02__item {
  margin-right: 30px;
}
@media screen and (max-width: 640px) {
  .p-ticketServiceDetail03__content01 .c-tiketItems02__item {
    margin-right: 2%;
  }
}
.p-ticketServiceDetail03__content01 .c-tiketItems02__item:nth-of-type(3n) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .p-ticketServiceDetail03__content01 .c-tiketItems02__item:nth-of-type(3n) {
    margin-right: 2%;
  }
}
@media screen and (max-width: 640px) {
  .p-ticketServiceDetail03__content01 .c-tiketItems02__item:nth-of-type(even) {
    margin-right: 0;
  }
}
.p-ticketServiceDetail03__content01 .c-tiketItems02__item__tags--osusume, .p-ticketServiceDetail03__content01 .c-tiketItems02__item__tags--ninki {
  top: 5px;
}
.p-ticketServiceDetail03__content01 .c-tiketItems02__item a ul {
  padding-bottom: 15px;
}
.p-ticketServiceDetail03__content01 .c-tiketItems02__item a p {
  margin-bottom: 4px;
}
.p-ticketServiceDetail03__content01 .c-tiketItems02__item__subCate {
  padding: 0 12px;
  margin-bottom: 5px;
}
@media screen and (max-width: 640px) {
  .p-ticketServiceDetail {
    padding-top: 30px;
  }
  .p-ticketServiceDetail01 {
    margin-bottom: 20px;
    font-size-adjust: 1.4rem;
  }
  .p-ticketServiceDetail01__content01 {
    padding: 0 20px;
  }
  .p-ticketServiceDetail01__content01__title {
    margin-bottom: 3px;
    padding-top: 29px;
    background-image: url("../img/ticket/line01_sp.png");
    -webkit-background-size: 12px;
    background-size: 12px;
  }
  .p-ticketServiceDetail01__content01__title h1 {
    font-size: 1.8rem;
    line-height: 1.6;
  }
  .p-ticketServiceDetail01__content01__title p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-ticketServiceDetail01__content01__tags {
    margin-bottom: 20px;
    font-size: 0.8rem;
    padding: 0 9px;
  }
  .p-ticketServiceDetail01__content02 {
    margin-bottom: 45px;
  }
  .p-ticketServiceDetail01__content02__slider {
    width: 100%;
  }
  .p-ticketServiceDetail01__content02__slider img {
    max-height: 166px;
  }
  .p-ticketServiceDetail01__content02__slider .slick-arrow {
    margin: 0;
    width: 8px;
    height: 13px;
  }
  .p-ticketServiceDetail01__content02__slider .slick-arrow.slick-prev {
    top: auto;
    bottom: -23px;
    left: 35%;
  }
  .p-ticketServiceDetail01__content02__slider .slick-arrow.slick-next {
    top: auto;
    bottom: -23px;
    right: 35%;
  }
  .p-ticketServiceDetail01__content02__slider .slick-dots {
    bottom: -26px;
  }
  .p-ticketServiceDetail01__content02__slider .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
  }
  .p-ticketServiceDetail01__content02__slider .slick-dots li button {
    width: 6px;
    height: 6px;
  }
  .p-ticketServiceDetail01__content03 {
    margin-bottom: 25px;
  }
  .p-ticketServiceDetail01__content03 iframe {
    max-width: 100%;
    height: 100%;
  }
  .p-ticketServiceDetail01__content04 {
    margin-bottom: 41px;
    padding: 0 20px;
  }
  .p-ticketServiceDetail01__content04 > p {
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-ticketServiceDetail01__content04__attention {
    padding: 16px 17px 13px;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.6;
  }
  .p-ticketServiceDetail01__content04 .c-ticketTitle02b {
    margin-bottom: 16px;
    font-size: 1.4rem;
    background-size: 6px;
  }
  .p-ticketServiceDetail01__content04 .c-ticketTitle02b em:after {
    height: 2px;
  }
  .p-ticketServiceDetail01__content05 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-ticketServiceDetail01__content05 .c-table2 {
    margin-bottom: 20px;
  }
  .p-ticketServiceDetail01__content05 .c-table2 dl {
    border-color: #dddddd;
  }
  .p-ticketServiceDetail01__content05__annotation {
    font-size: 1.1rem;
  }
  .p-ticketServiceDetail01__content06 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-ticketServiceDetail01__content06 .c-tbl01 {
    color: #232934;
  }
  .p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-num select {
    width: 150px;
    margin-right: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  .p-ticketServiceDetail01__content06 .c-tbl01 .c-tbl01__item .col-title {
    background-color: #fef2f5;
    padding: 11px 10px 10px 11px;
  }
  .p-ticketServiceDetail01__content06 .c-cartBtn {
    text-align: center;
  }
  .p-ticketServiceDetail01__content06 .c-cartBtn__btn02b {
    width: 100%;
    height: 50px;
    max-width: 160px;
  }
  .p-ticketServiceDetail01__content06 .c-cartBtn__btn02b {
    font-size: 1.3rem;
  }
  .p-ticketServiceDetail02 {
    margin-bottom: 35px;
    padding: 0 20px;
    font-size: 1.4rem;
  }
  .p-ticketServiceDetail02 dl {
    padding: 8px 15px 9px;
  }
  .p-ticketServiceDetail02 dl dt {
    margin-bottom: 3px;
    font-size: 1.8rem;
    text-align: center;
  }
  .p-ticketServiceDetail02 dl dd ul {
    margin-bottom: 5px;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.7;
  }
  .p-ticketServiceDetail02 dl dd a {
    font-size: 1.2rem;
  }
  .p-ticketServiceDetail03 {
    margin-bottom: 48px;
    padding: 0 20px;
  }
  .p-ticketServiceDetail03__content01 .c-ticketTitle03 {
    margin-bottom: 20px;
  }
  .p-ticketServiceDetail03__content01 .c-tiketItems02__item a ul {
    padding-bottom: 10px;
  }
  .p-ticketServiceDetail03__content01 .c-tiketItems02__item__subCate {
    padding: 0 8px;
    margin-bottom: 6px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット ログイン
------------------------------------------------------------*/
.p-ticketLogin {
  padding: 30px 0 135px 0;
  background-color: #ffffff;
}

.p-ticketLogin01 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #232934;
}
.p-ticketLogin01__content01 {
  margin-bottom: 28px;
}
.p-ticketLogin01__content01__annotation {
  margin: 7px 0 27px 0;
  font-size: 1.4rem;
}
.p-ticketLogin01__content01__annotation2 {
  font-size: 1.4rem;
  color: #999999;
}
.p-ticketLogin01__content01 .c-ticketTitle04 {
  padding-top: 24px;
  margin-bottom: 11px;
}
.p-ticketLogin01__content02 {
  margin-bottom: 54px;
}
.p-ticketLogin01__content02__bg {
  padding: 30px;
  background-color: #efefef;
}
.p-ticketLogin01__content02__box01 {
  width: 400px;
  margin-right: 40px;
}
.p-ticketLogin01__content02__box01 ul li {
  margin-bottom: 16px;
}
.p-ticketLogin01__content02__box01 ul li label {
  font-weight: bold;
  display: block;
  margin-bottom: 2px;
}
.p-ticketLogin01__content02__box01 ul li input {
  width: 100%;
  padding: 12px 10px;
  border: solid 1px #4b5870;
  border-radius: 0px;
}
.p-ticketLogin01__content02__box01 ul li select {
  width: 100%;
  padding: 10px;
  border: solid 1px #4b5870;
  border-radius: 0px;
}
.p-ticketLogin01__content02__box01 .c-cartBtn {
  margin-top: 31px;
  text-align: center;
}
.p-ticketLogin01__content02__box01 .c-cartBtn__btn02b {
  width: 200px;
  height: 50px;
}
.p-ticketLogin01__content02__box02 {
  width: 400px;
}
.p-ticketLogin01__content02__box02 .c-ticketBtn02 {
  width: 220px;
  margin: 29px auto 35px;
}
.p-ticketLogin01__content02__box02 .c-ticketBtn02 a {
  padding: 30px 0;
}
.p-ticketLogin01__content02__box02__annotation {
  border-top: dashed 1px #999999;
  padding-top: 30px;
}
.p-ticketLogin01__content02__box02__annotation a {
  color: #308bbb;
  text-decoration: underline;
}
.p-ticketLogin01__content03 .c-formComplete p {
  text-align: center;
  color: #232934;
}
.p-ticketLogin01__content03 .c-formComplete__inquiry {
  width: 840px;
  margin: 12px auto 30px auto;
  border: solid 5px #fff2f5;
}
.p-ticketLogin01__content03 .c-formComplete__inquiry h3 {
  color: #232934;
}
.p-ticketLogin01__content03 .c-formComplete__inquiry p {
  color: #232934;
}

@media screen and (max-width: 640px) {
  .p-ticketLogin {
    padding: 30px 20px 25px 20px;
  }
  .p-ticketLogin01 {
    font-size: 1.5rem;
  }
  .p-ticketLogin01__content01 {
    margin-bottom: 24px;
  }
  .p-ticketLogin01__content01__txt {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-ticketLogin01__content01__annotation {
    margin: 1px 0 9px;
    font-size: 1.1rem;
    font-weight: bold;
  }
  .p-ticketLogin01__content01__annotation2 {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.6;
  }
  .p-ticketLogin01__content01 .c-ticketTitle04 {
    padding-top: 23px;
    margin-bottom: 6px;
    font-size: 1.8rem;
  }
  .p-ticketLogin01__content02 {
    margin-bottom: 28px;
  }
  .p-ticketLogin01__content02__bg {
    padding: 24px 30px 20px;
    background-color: #efefef;
  }
  .p-ticketLogin01__content02__txt {
    font-size: 1.4rem;
  }
  .p-ticketLogin01__content02__box01 {
    width: 100%;
    margin: 0 0 17px 0;
  }
  .p-ticketLogin01__content02__box01 ul li {
    margin-bottom: 15px;
  }
  .p-ticketLogin01__content02__box01 ul li label {
    font-size: 1.4rem;
    margin-bottom: 4px;
  }
  .p-ticketLogin01__content02__box01 ul li select {
    font-size: 1.4rem;
  }
  .p-ticketLogin01__content02__box01 .c-cartBtn {
    margin-top: 32px;
  }
  .p-ticketLogin01__content02__box01 .c-cartBtn__btn02b {
    width: 100%;
    max-width: 160px;
    height: 40px;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .p-ticketLogin01__content02__box01 .c-ticketTitle02b {
    font-size: 1.4rem;
    background-size: 4px;
    margin-bottom: 16px;
  }
  .p-ticketLogin01__content02__box01 .c-ticketTitle02b em:after {
    height: 2px;
  }
  .p-ticketLogin01__content02__box02 {
    width: 100%;
  }
  .p-ticketLogin01__content02__box02 .c-ticketBtn02 {
    width: 100%;
    max-width: 160px;
    margin: 11px auto 22px;
  }
  .p-ticketLogin01__content02__box02 .c-ticketBtn02 a {
    padding: 20px 0;
  }
  .p-ticketLogin01__content02__box02__annotation {
    padding: 15px 0 9px;
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .p-ticketLogin01__content02__box02 .c-ticketTitle02b {
    font-size: 1.4rem;
    background-size: 4px;
    margin-bottom: 16px;
  }
  .p-ticketLogin01__content02__box02 .c-ticketTitle02b em:after {
    height: 2px;
  }
  .p-ticketLogin01__content03 .c-formComplete {
    font-size: 1.4rem;
  }
  .p-ticketLogin01__content03 .c-formComplete__inquiry {
    width: 100%;
    margin: 17px auto 42px auto;
    padding: 13px 15px 15px;
  }
  .p-ticketLogin01__content03 .c-ticketBtn03 {
    max-width: 210px;
    margin: 15px auto;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット　お申込みからお届けまでの流れ
------------------------------------------------------------*/
.p-ticketFlow {
  background-color: #ffffff;
}

.p-ticketFlow01 {
  margin-bottom: 80px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #232934;
}
.p-ticketFlow01__content01 {
  margin-bottom: 50px;
}
.p-ticketFlow01__content01 dl dt {
  margin-bottom: 7px;
  font-weight: bold;
}
.p-ticketFlow01__content01 dl dt:before {
  width: 12px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #fc819b;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.p-ticketFlow01__content01 dl dd {
  margin-bottom: 18px;
}
.p-ticketFlow01__content01 dl a {
  color: #308bbb;
  text-decoration: underline;
}
.p-ticketFlow01__content01 dl a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-ticketFlow01__content02 > p {
  margin-bottom: 20px;
}
.p-ticketFlow01__content02 > p b {
  font-weight: bold;
}
.p-ticketFlow01__content02 ol li {
  margin-bottom: 30px;
  position: relative;
  border-bottom: dashed 1px rgba(0, 0, 0, 0.3);
}
.p-ticketFlow01__content02 ol li img {
  position: absolute;
}
.p-ticketFlow01__content02 ol li:nth-child(1) {
  min-height: 210px;
  padding: 0 300px 30px 0;
}
.p-ticketFlow01__content02 ol li:nth-child(1) img {
  top: 0;
  right: 0;
}
.p-ticketFlow01__content02 ol li:nth-child(2) {
  padding: 0 300px 30px 0;
}
.p-ticketFlow01__content02 ol li:nth-child(2) img {
  top: 11px;
  right: 0;
}
.p-ticketFlow01__content02 ol li:nth-child(3) {
  margin: 0;
  padding-bottom: 20px;
}
.p-ticketFlow01__content02 ol li:nth-child(3) p {
  margin-bottom: 20px;
}
.p-ticketFlow01__content02 ol li:nth-child(3) a {
  color: #308bbb;
  text-decoration: underline;
}
.p-ticketFlow01__content02 ol li:nth-child(3) a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-ticketFlow01__content02 ol li:nth-child(3) a:before {
  width: 6px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-pink01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-ticketFlow01__content02 ol li h3 {
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}
.p-ticketFlow01__content02 ol li h3:before {
  width: 25px;
  height: 25px;
  margin: -5px 10px 0 0;
  content: counter(count01);
  line-height: 1.38;
  color: #ffffff;
  background-color: #fc819b;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.p-ticketFlow02 {
  margin-bottom: 100px;
}
.p-ticketFlow02__content01__case {
  margin-bottom: 30px;
}
.p-ticketFlow02__content01__annotation {
  font-size: 1.3rem;
  line-height: 1.5;
  color: #232934;
}

@media screen and (max-width: 640px) {
  .p-ticketFlow {
    padding: 30px 20px;
  }
  .p-ticketFlow01 {
    margin-bottom: 40px;
    font-size: 1.4rem;
  }
  .p-ticketFlow01__content01 {
    margin-bottom: 30px;
  }
  .p-ticketFlow01__content01 dl dd {
    margin-bottom: 15px;
  }
  .p-ticketFlow01__content02 > p {
    margin-bottom: 15px;
  }
  .p-ticketFlow01__content02 ol li {
    min-height: auto !important;
    margin-bottom: 15px;
    padding: 0 0 15px 0 !important;
    position: static;
  }
  .p-ticketFlow01__content02 ol li img {
    margin: 0 auto 10px auto;
    display: block;
    position: static;
  }
  .p-ticketFlow01__content02 ol li:nth-child(3) {
    margin: 0 !important;
  }
  .p-ticketFlow01__content02 ol li:nth-child(3) p {
    margin-bottom: 15px;
  }
  .p-ticketFlow01__content02 ol li h3 {
    font-size: 1.6rem;
  }
  .p-ticketFlow01__content02 ol li h3:before {
    width: 20px;
    height: 20px;
  }
  .p-ticketFlow02 {
    margin-bottom: 40px;
  }
  .p-ticketFlow02__content01__case {
    margin: 0;
    padding: 0;
    background-color: #efefef;
  }
  .p-ticketFlow02__content01__case__accordionBtn {
    margin: 0 !important;
  }
  .p-ticketFlow02__content01__case__accordionBtn span {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }
  .p-ticketFlow02__content01__case__accordionBtn span:before {
    width: 20px;
    height: 2px;
    content: "";
    background-color: #fc819b;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-out;
  }
  .p-ticketFlow02__content01__case__accordionBtn span:after {
    width: 2px;
    height: 20px;
    content: "";
    background-color: #fc819b;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-out;
  }
  .p-ticketFlow02__content01__case .c-step {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding-bottom: 20px;
    display: none;
  }
  .p-ticketFlow02__content01__case .c-step .c-step__head .c-step__icon {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .p-ticketFlow02__content01__case .c-step .c-step__head .c-step__head-inner {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .p-ticketFlow02__content01__case.is-open .p-ticketFlow02__content01__case__accordionBtn span:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  .p-ticketFlow02__content01__case.is-open .c-step {
    display: block;
  }
  .p-ticketFlow02__content01__annotation {
    margin-top: 20px;
  }
  .p-ticketFlow02 .c-ticketTitle01 {
    line-height: 1.4;
    margin-bottom: 15px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-ticketFaq {
  padding: 52px 0 40px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq {
    padding: 85px 20px 70px;
  }
}
.p-ticketFaq .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-ticketFaq .c-breadcrumb1 a {
  color: #308bbb;
}
.p-ticketFaq__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-ticketFaq__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
.p-ticketFaq__title02 {
  font-size: 2.4rem;
  color: #fff;
  background: #fc819b;
  padding: 16px 29px 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__title02 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.6rem;
    padding: 13px 20px 12px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
}
.p-ticketFaq__box {
  margin-bottom: 20px;
}
.p-ticketFaq__box:last-of-type {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box:last-of-type {
    margin-bottom: 30px;
  }
}
.p-ticketFaq__box__q {
  font-size: 2rem;
  color: #46483c;
  line-height: 1.6;
  background: #f2f2f2;
  padding: 15px 29px 13px;
  cursor: pointer;
  position: relative;
  display: table;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box__q {
    font-size: 1.4rem;
    padding: 13px 35px 11px 15px;
  }
}
.p-ticketFaq__box__q span {
  display: table-cell;
  width: 27px;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box__q span {
    width: 20px;
  }
}
.p-ticketFaq__box__q:after, .p-ticketFaq__box__q:before {
  content: "";
  background: #46483c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s ease-out;
}
.p-ticketFaq__box__q:after {
  width: 24px;
  height: 3px;
  right: 20px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box__q:after {
    width: 10px;
    height: 2px;
    right: 10px;
  }
}
.p-ticketFaq__box__q:before {
  width: 3px;
  height: 24px;
  right: 30px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box__q:before {
    width: 2px;
    height: 10px;
    right: 14px;
  }
}
.p-ticketFaq__box__a {
  display: none;
  background: #fff;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 24px 40px 29px 37px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box__a {
    padding: 15px;
  }
}
.p-ticketFaq__box__a p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
  display: table;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box__a p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-ticketFaq__box__a span {
  display: table-cell;
  width: 32px;
  font-size: 2rem;
  font-weight: bold;
  color: #fc819b;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__box__a span {
    width: 20px;
    font-size: 1.8rem;
  }
}
.p-ticketFaq__box.is-open .p-ticketFaq__box__q::before {
  transform: translateY(-50%) rotate(-90deg);
}
.p-ticketFaq__btn {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__btn {
    display: block;
    margin-bottom: 25px;
  }
}
.p-ticketFaq__btn li {
  width: 550px;
  height: 80px;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__btn li {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
}
.p-ticketFaq__btn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #fc819b;
  border: 3px solid #dddddd;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__btn a {
    font-size: 1.5rem;
    padding: 0 30px 0 10px;
    line-height: 1.6;
    border: 2px solid #dddddd;
  }
}
.p-ticketFaq__btn a:after {
  content: "";
  width: 12px;
  height: 7px;
  background: url("../img/common/icon_down01.png");
  background-size: contain;
  position: absolute;
  top: 34px;
  right: 27px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-ticketFaq__btn a:after {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート）
------------------------------------------------------------*/
.p-ticketCart {
  padding: 50px 0 135px 0;
  background-color: #ffffff;
}

.p-ticketCart01 {
  margin-bottom: 30px;
}
.p-ticketCart01 .c-formTitle {
  color: #232934;
}

.p-ticketCart02 {
  margin-bottom: 50px;
}
.p-ticketCart02__txt {
  margin-bottom: 20px;
  font-size: 1.6rem;
  color: #232934;
}
.p-ticketCart02__txt__annotation {
  font-size: 1.4rem;
}
.p-ticketCart02 .c-tbl01__total .total-number span {
  color: #232934;
}
.p-ticketCart02 .c-error {
  margin-bottom: 20px;
  font-weight: normal;
}
@media screen and (max-width: 640px) {
  .p-ticketCart02 .c-error {
    margin-bottom: 10px;
  }
}
.p-ticketCart02 .c-error span {
  display: block;
  font-weight: bold;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-ticketCart02 .c-error span {
    font-size: 1.3rem;
  }
}

.p-ticketCart03 .p-ticketCart03__content01 dl dt {
  margin-bottom: 13px;
  font-size: 2rem;
  font-weight: bold;
  color: #232934;
}
.p-ticketCart03 .p-ticketCart03__content01 dl dt:before {
  width: 10px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #9ac985;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-ticketCart03 .p-ticketCart03__content01 dl dd {
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #232934;
}
.p-ticketCart03 .p-ticketCart03__content01 dl dd a {
  color: #308bbb;
  text-decoration: underline;
}
.p-ticketCart03 .p-ticketCart03__content01 dl dd a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-ticketCart03 .p-ticketCart03__content01 dl dd:first-of-type p {
  width: 800px;
}

@media screen and (max-width: 640px) {
  .p-ticketCart {
    padding: 28px 20px 38px 20px;
  }
  .p-ticketCart01 {
    margin-bottom: 17px;
  }
  .p-ticketCart01 .c-formTitle {
    margin-bottom: 15px;
  }
  .p-ticketCart02 {
    margin-bottom: 24px;
  }
  .p-ticketCart02__txt {
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
  .p-ticketCart02__txt__annotation {
    display: block;
    margin-top: 9px;
    font-size: 1.1rem;
    font-weight: bold;
  }
  .p-ticketCart03 .p-ticketCart03__content01 dl dt {
    margin-bottom: 0;
    font-size: 1.4rem;
  }
  .p-ticketCart03 .p-ticketCart03__content01 dl dt:before {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }
  .p-ticketCart03 .p-ticketCart03__content01 dl dd {
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-ticketCart03 .p-ticketCart03__content01 dl dd p {
    width: 100%;
    margin-bottom: 12px;
  }
  .p-ticketCart03 .p-ticketCart03__content01 dl dd img {
    margin: 0 auto;
    display: block;
  }
  .p-ticketCart03 .p-ticketCart03__content01 dl dd:first-of-type p {
    width: 100%;
  }
  .p-ticketCart03 .p-ticketCart03__content01 .c-ticketTitle03 {
    margin-bottom: 12px;
  }
  .p-ticketCart03 .p-ticketCart03__content02 .c-cartBtn {
    margin-top: 37px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート） 入力・エラー
------------------------------------------------------------*/
.p-ticketCartInput {
  padding: 50px 0 135px 0;
  background-color: #ffffff;
}

.p-ticketCartInput01 {
  margin-bottom: 30px;
}
.p-ticketCartInput01 .c-formTitle {
  color: #232934;
}

.p-ticketCartInput02 {
  margin-bottom: 80px;
}
.p-ticketCartInput02__require {
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background: #f22727;
  padding: 0 5px;
  line-height: 1.6;
  border-radius: 3px;
}
@media screen and (max-width: 640px) {
  .p-ticketCartInput02__require {
    margin-left: 10px;
  }
}
.p-ticketCartInput02__content01 {
  margin-bottom: 50px;
}
.p-ticketCartInput02__content01__txt {
  margin-bottom: 40px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-ticketCartInput02__content01__txt2 {
  margin-bottom: 15px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-ticketCartInput02__content02 {
  margin-bottom: 50px;
}
.p-ticketCartInput02__content03__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #253b73;
  text-decoration: underline;
}
.p-ticketCartInput02__content03__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-ticketCartInput02__content03__back:before {
  width: 7px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-ticketCartInput {
    padding: 29px 20px 0 20px;
  }
  .p-ticketCartInput01 {
    margin-bottom: 18px;
  }
  .p-ticketCartInput01 .c-formTitle {
    margin-bottom: 16px;
  }
  .p-ticketCartInput02 {
    margin-bottom: 37px;
  }
  .p-ticketCartInput02__content01 {
    margin-bottom: 25px;
  }
  .p-ticketCartInput02__content01 > p {
    margin-bottom: 15px;
    font-size: 1.3rem;
    line-height: 1.5;
  }
  .p-ticketCartInput02__content01 .p-ticketCartInput02__require {
    margin: -2px 5px 0 0;
    font-size: 0.9rem;
    line-height: 1.7;
  }
  .p-ticketCartInput02__content02 {
    margin-bottom: 36px;
  }
  .p-ticketCartInput02__content02 .c-checkbox input[type=checkbox] + label {
    padding-left: 23px;
  }
  .p-ticketCartInput02__content02 .c-checkbox input[type=checkbox] + label:before {
    top: 2px;
    transform: translateY(0);
    width: 14px;
    height: 14px;
  }
  .p-ticketCartInput02__content02 .c-checkbox input[type=checkbox] + label:after {
    top: 11px;
    left: 4px;
    transform: translateY(0) rotate(45deg);
    height: 9px;
  }
  .p-ticketCartInput02__content03__back {
    margin-top: 1px;
    font-size: 1.4rem;
  }
  .p-ticketCartInput02__content03__back:before {
    width: 5px;
    height: 9px;
    margin: 0 11px 0 0;
  }
  .p-ticketCartInput02__content03 .c-cartBtn {
    margin-top: 0;
  }
}
.p-ticketFormTable01 dl:nth-child(3) input {
  width: 280px;
  padding: 0 15px;
}
.p-ticketFormTable01 dl:nth-child(4) li, .p-ticketFormTable01 dl:nth-child(5) li {
  float: left;
}
.p-ticketFormTable01 dl:nth-child(4) input, .p-ticketFormTable01 dl:nth-child(5) input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.p-ticketFormTable01 dl:nth-child(5) input {
  margin: 0 29px 0 7px;
}
.p-ticketFormTable01 dl:nth-child(6) input, .p-ticketFormTable01 dl:nth-child(7) input {
  padding: 0 15px;
  width: 240px;
}
.p-ticketFormTable01 dl:nth-child(8) input, .p-ticketFormTable01 dl:nth-child(9) input, .p-ticketFormTable01 dl:nth-child(10) input, .p-ticketFormTable01 dl:nth-child(11) input, .p-ticketFormTable01 dl:nth-child(12) input, .p-ticketFormTable01 dl:nth-child(13) input, .p-ticketFormTable01 dl:nth-child(14) input {
  width: 600px;
  padding: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-ticketFormTable01 dl:nth-child(3) input, .p-ticketFormTable01 dl:nth-child(6) input, .p-ticketFormTable01 dl:nth-child(7) input, .p-ticketFormTable01 dl:nth-child(8) input, .p-ticketFormTable01 dl:nth-child(9) input, .p-ticketFormTable01 dl:nth-child(10) input, .p-ticketFormTable01 dl:nth-child(11) input, .p-ticketFormTable01 dl:nth-child(12) input, .p-ticketFormTable01 dl:nth-child(13) input, .p-ticketFormTable01 dl:nth-child(14) input {
    width: 100%;
    padding: 11px 15px;
    font-size: 1.3rem;
  }
  .p-ticketFormTable01 dl:nth-child(4) li, .p-ticketFormTable01 dl:nth-child(5) li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }
  .p-ticketFormTable01 dl:nth-child(4) li:last-of-type, .p-ticketFormTable01 dl:nth-child(5) li:last-of-type {
    margin-bottom: 0;
  }
  .p-ticketFormTable01 dl:nth-child(4) input, .p-ticketFormTable01 dl:nth-child(5) input {
    width: 96%;
    padding: 11px 15px;
    margin: 0 0 0 7px;
  }
  .p-ticketFormTable01 dl:nth-child(5) input {
    width: 95%;
  }
  .p-ticketFormTable01 dl input {
    height: 38px;
    line-height: 38px;
  }
}
@media screen and (max-width: 640px) {
  .p-ticketFormTable01 .c-table2__radio li {
    margin-right: 0;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート） 確認
------------------------------------------------------------*/
.p-ticketCartConfirm {
  padding: 50px 0 135px 0;
  background-color: #ffffff;
}

.p-ticketCartConfirm01 {
  margin-bottom: 30px;
}
.p-ticketCartConfirm01 .c-formTitle {
  color: #232934;
}

.p-ticketCartConfirm02 {
  margin-bottom: 80px;
}
.p-ticketCartConfirm02__content01 {
  margin-bottom: 40px;
  color: #232934;
}
.p-ticketCartConfirm02__content02 {
  margin-bottom: 60px;
  color: #232934;
}
.p-ticketCartConfirm02__content02__txt {
  margin-top: 40px;
  font-size: 1.4rem;
}
.p-ticketCartConfirm02__content02 .c-error {
  margin-bottom: 20px;
  font-weight: normal;
}
@media screen and (max-width: 640px) {
  .p-ticketCartConfirm02__content02 .c-error {
    margin-bottom: 10px;
  }
}
.p-ticketCartConfirm02__content02 .c-error span {
  display: block;
  font-weight: bold;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-ticketCartConfirm02__content02 .c-error span {
    font-size: 1.3rem;
  }
}
.p-ticketCartConfirm02__content02 .c-tbl01 .col-name {
  width: 270px;
}
.p-ticketCartConfirm02__content03 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.p-ticketCartConfirm02__content03 .c-cartBtn {
  margin: 0;
}
.p-ticketCartConfirm02__content03__back {
  margin-right: 20px;
  color: #232934;
  border: 2px solid #b2b2b2;
  background: #FFF;
  position: relative;
}
.p-ticketCartConfirm02__content03__back:before {
  left: 20px;
  border-bottom: 2px solid #b2b2b2;
  border-left: 2px solid #b2b2b2;
}
.p-ticketCartConfirm02__content03__back:hover {
  color: #fff;
  background: #b2b2b2;
}
.p-ticketCartConfirm02__content03__back:hover:before {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}

@media screen and (max-width: 640px) {
  .p-ticketCartConfirm {
    padding: 30px 20px 38px 20px;
  }
  .p-ticketCartConfirm01 {
    margin-bottom: 20px;
  }
  .p-ticketCartConfirm01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-ticketCartConfirm02 {
    margin-bottom: 0;
  }
  .p-ticketCartConfirm02__content01 {
    margin-bottom: 30px;
  }
  .p-ticketCartConfirm02__content01 .c-table2 dl {
    padding-bottom: 10px;
  }
  .p-ticketCartConfirm02__content02 {
    margin-bottom: 30px;
  }
  .p-ticketCartConfirm02__content02__txt {
    margin-top: 20px;
    font-size: 1.2rem;
  }
  .p-ticketCartConfirm02__content03 {
    display: block;
  }
  .p-ticketCartConfirm02__content03 .c-cartBtn {
    margin-top: 0;
  }
  .p-ticketCartConfirm02__content03__back {
    margin-right: 0;
  }
  .p-ticketCartConfirm02__content03__back:before {
    left: 20px;
    border-bottom: 2px solid #b2b2b2;
    border-left: 2px solid #b2b2b2;
  }
  .p-ticketCartConfirm02__content03__back:hover {
    color: #fff;
    background: #b2b2b2;
  }
  .p-ticketCartConfirm02__content03__back:hover:before {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート） 完了
------------------------------------------------------------*/
.p-ticketCartComplete {
  background-color: #ffffff;
  padding-top: 30px;
}

.p-ticketCartComplete01 {
  margin: 0 0 30px;
}
.p-ticketCartComplete01 .c-formTitle {
  color: #232934;
}

.p-ticketCartComplete02 {
  margin-bottom: 80px;
}
.p-ticketCartComplete02 .c-formComplete {
  color: #232934;
}

@media screen and (max-width: 640px) {
  .p-ticketCartComplete {
    padding: 0 20px;
  }
  .p-ticketCartComplete01 {
    margin: 27px 0 25px;
  }
  .p-ticketCartComplete01 .c-formTitle {
    margin-bottom: 17px;
  }
  .p-ticketCartComplete02 {
    margin-bottom: 40px;
  }
  .p-ticketCartComplete02 .c-cartBtn {
    margin-top: 40px;
  }
}
.p-ticketCartComplete .c-formComplete__inquiry {
  margin: 0;
}
.p-ticketCartComplete .c-formComplete__inquiry__content {
  width: 480px;
  margin: 54px auto 78px auto;
}
@media screen and (max-width: 640px) {
  .p-ticketCartComplete .c-formComplete__inquiry__content {
    width: 100%;
    margin: 26px 0 20px;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
チケットご利用者アンケート
------------------------------------------------------------*/
.p-ticketEnquete {
  padding: 41px 0 133px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnquete {
    padding: 19px 20px 35px;
  }
}
.p-ticketEnquete__formPrivacy {
  margin: 70px 0 50px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnquete__formPrivacy {
    margin: 40px 0;
  }
}
.p-ticketEnquete1 .c-formText01 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnquete1 .c-formText01 {
    margin-top: 28px;
  }
}
.p-ticketEnquete1 .c-accordion {
  margin-top: 11px;
}
.p-ticketEnquete2 .c-step3 {
  margin-top: 50px;
  margin-bottom: 80px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnquete2 .c-step3 {
    margin-top: 25px;
    margin-bottom: 40px;
  }
}
.p-ticketEnquete2 .c-form1__row .c-form1__title.mt22 {
  margin-top: 22px;
}
.p-ticketEnquete2 .c-form1__row:last-child {
  margin-bottom: 70px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnquete2 .c-form1__row:last-child {
    margin-bottom: 41px;
  }
}
.p-ticketEnquete2 .c-form1__title.is-block {
  display: block;
}
.p-ticketEnquete2 .c-form1__select {
  max-width: 280px;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-ticketEnquete2 .c-form1__select {
    max-width: 100%;
    font-size: 1.4rem;
  }
}
.p-ticketEnquete2 .c-form1__input__note {
  color: #999999;
  font-weight: 500;
}
.p-ticketEnquete2 .c-form1 .c-ticketText01 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 5px;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .p-ticketEnquete2 .c-form1 .c-ticketText01 {
    padding-left: 23.08px;
    font-size: 1.4rem;
  }
}

/*------------------------------------------------------------
チケットご利用者アンケート エラー画面
------------------------------------------------------------*/
.p-ticketEnqueteError {
  padding: 41px 0 133px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteError {
    padding: 19px 20px 35px;
  }
}
.p-ticketEnqueteError__formPrivacy {
  margin: 70px 0 50px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteError__formPrivacy {
    margin: 40px 0 30px;
  }
}
.p-ticketEnqueteError1 .c-formText01 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteError1 .c-formText01 {
    margin-top: 28px;
  }
}
.p-ticketEnqueteError1 .c-accordion {
  margin-top: 11px;
}
.p-ticketEnqueteError2 .c-step3 {
  margin-top: 50px;
  margin-bottom: 80px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteError2 .c-step3 {
    margin-top: 25px;
    margin-bottom: 40px;
  }
}
.p-ticketEnqueteError2 .c-form1__row .c-form1__title.mt22 {
  margin-top: 22px;
}
.p-ticketEnqueteError2 .c-form1__row:last-child {
  margin-bottom: 70px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteError2 .c-form1__row:last-child {
    margin-bottom: 41px;
  }
}
.p-ticketEnqueteError2 .c-form1__title.is-block {
  display: block;
}
.p-ticketEnqueteError2 .c-form1__select {
  max-width: 280px;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteError2 .c-form1__select {
    max-width: 100%;
    font-size: 1.4rem;
  }
}
.p-ticketEnqueteError2 .c-form1__input__note {
  color: #999999;
  font-weight: 500;
}
.p-ticketEnqueteError2 .c-form1 .c-ticketText01 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 5px;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteError2 .c-form1 .c-ticketText01 {
    padding-left: 23.08px;
    font-size: 1.4rem;
  }
}

/*------------------------------------------------------------
チケットご利用者アンケート 確認画面
------------------------------------------------------------*/
.p-ticketEnqueteConfirm {
  padding: 41px 0 133px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteConfirm {
    padding: 19px 20px 35px;
  }
}
.p-ticketEnqueteConfirm1 .c-step3 {
  margin-top: 43px;
  margin-bottom: 80px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteConfirm1 .c-step3 {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
.p-ticketEnqueteConfirm2 .c-form1__row .c-form1__title.mt22 {
  margin-top: 22px;
}
.p-ticketEnqueteConfirm2 .c-form1__row:last-child {
  margin-bottom: 70px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteConfirm2 .c-form1__row:last-child {
    margin-bottom: 41px;
  }
}
.p-ticketEnqueteConfirm2 .c-form1__title.is-block {
  display: block;
}
.p-ticketEnqueteConfirm2 .c-form1__text3 {
  margin: 75px 0 50px !important;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteConfirm2 .c-form1__text3 {
    margin: 29px 0 40px !important;
  }
}
.p-ticketEnqueteConfirm2 .c-form1 .c-ticketText01 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 5px;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteConfirm2 .c-form1 .c-ticketText01 {
    padding-left: 23.08px;
    font-size: 1.4rem;
  }
}

/*------------------------------------------------------------
チケットご利用者アンケート 完了画面
------------------------------------------------------------*/
.p-ticketEnqueteComplete {
  padding: 41px 0 199px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteComplete {
    padding: 19px 20px 57px;
  }
}
.p-ticketEnqueteComplete1 .c-accordion {
  margin-top: 11px;
}
.p-ticketEnqueteComplete2 .c-step3 {
  margin-top: 43px;
  margin-bottom: 134px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteComplete2 .c-step3 {
    margin-top: 20px;
    margin-bottom: 59px;
  }
}
.p-ticketEnqueteComplete3 {
  margin-top: 116px;
}
@media screen and (max-width: 640px) {
  .p-ticketEnqueteComplete3 {
    margin-top: 36px;
  }
}

/*------------------------------------------------------------
保険TOP
------------------------------------------------------------*/
.p-hokenTopFirstView {
  padding-top: 13px;
  margin-bottom: 15px;
  background: url("/img/hoken/top/first_view_bg01@sp.png") no-repeat center top;
  background-size: 375px auto;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView {
    margin-bottom: 60px;
    padding-top: 38px;
    background: url("/img/hoken/top/first_view_bg01@pc.jpg") no-repeat center bottom;
  }
}
.p-hokenTopFirstView__message {
  margin-bottom: 10px;
  color: #333;
  font-size: 0.95rem;
  font-weight: bold;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__message {
    margin-bottom: 40px;
    font-size: 1.6rem;
  }
}
.p-hokenTopFirstView__content {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__content {
    margin-top: 42px;
    max-width: none;
    display: block;
  }
}
.p-hokenTopFirstView__title {
  margin-bottom: 15px;
  width: 100%;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  column-gap: 11px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__title {
    margin-bottom: 40px;
    column-gap: 20px;
    font-size: 6rem;
  }
}
.p-hokenTopFirstView__title::before {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #82cdf3 url("../img/common/icon_hoken01.png") no-repeat center;
  background-size: 30px 30px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__title::before {
    width: 90px;
    height: 90px;
    background-size: 50px 50px;
  }
}
.p-hokenTopFirstView__benefits {
  width: 165px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__benefits {
    width: 425px;
  }
}
.p-hokenTopFirstView__benefits__title {
  margin-left: auto;
  margin-right: auto;
  padding: 5px 15px;
  width: fit-content;
  background-color: #08336E;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__benefits__title {
    padding: 15px;
    border-radius: 10px;
    font-size: 2.4rem;
  }
}
.p-hokenTopFirstView__benefits__list {
  margin-top: -1.4rem;
  padding-top: 18px;
  padding-bottom: 10px;
  background-color: #fff;
  border: 1.5px solid #08336E;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__benefits__list {
    margin-top: -3.3rem;
    padding: 24px 30px 10px;
    border-width: 3px;
    border-radius: 10px;
  }
}
.p-hokenTopFirstView__benefits__item {
  text-align: center;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__benefits__item {
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
  .p-hokenTopFirstView__benefits__item:not(:first-child) {
    border-top: 1px solid #D9D9D9;
  }
}
.p-hokenTopFirstView__benefits__item:not(:first-child)::before {
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  content: "";
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background-color: #D9D9D9;
  display: block;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__benefits__item:not(:first-child)::before {
    display: none;
  }
}
.p-hokenTopFirstView__benefits__item__1 {
  color: #333;
  font-size: 1.2rem;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__benefits__item__1 {
    font-size: 1.6rem;
  }
}
.p-hokenTopFirstView__benefits__item__2 {
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__benefits__item__2 {
    margin-right: -0.4em;
    font-size: 3.4rem;
  }
}
.p-hokenTopFirstView__image {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFirstView__image {
    display: none;
  }
}

.p-hokenTopPickup {
  margin-top: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopPickup {
    margin-top: 80px;
  }
}

.p-hokenTopSearch {
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #E4F7FF;
}
@media screen and (min-width: 641px) {
  .p-hokenTopSearch {
    margin-top: 40px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.p-hokenSearch__tabs {
  display: flex;
  column-gap: 1.5px;
}
.p-hokenSearch__tabs li {
  flex: 1;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__tabs {
    column-gap: 3px;
  }
}
.p-hokenSearch__tab {
  padding: 14px 0 9px;
  width: 100%;
  background-color: #41B4E6;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  border-top: 2.5px solid #41B4E6;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__tab:hover {
    transition: 0.3s ease-in-out;
    opacity: 0.8;
  }
}
.p-hokenSearch__tab[data-active=true] {
  background-color: #fff;
  color: #41B4E6;
}
.p-hokenSearch__tab__1 {
  font-size: 1.4rem;
  line-height: 1.2;
}
.p-hokenSearch__tab__2 {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__tab {
    padding-top: 19px;
    padding-bottom: 19px;
    border-radius: 10px 10px 0 0;
    border-top-width: 5px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    column-gap: 10px;
  }
  .p-hokenSearch__tab__1 {
    font-size: 1.8rem;
  }
  .p-hokenSearch__tab__2 {
    font-size: 1.6rem;
  }
}
.p-hokenSearch__content {
  padding: 25px 12.5px;
  background-color: #fff;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__content {
    padding: 40px;
  }
}
.p-hokenSearch__content[data-active=true] {
  display: block;
}
.p-hokenSearch__content[data-active=false] {
  display: none;
}
.p-hokenSearch__index {
  display: grid;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
.p-hokenSearch__companyIndex {
  display: grid;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__companyIndex {
    row-gap: 40px;
  }
}
.p-hokenSearch__index__categoryName {
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__categoryName {
    margin-bottom: 20px;
    font-size: 2rem;
  }
}
.p-hokenSearch__index__categoryButton, .p-hokenSearch__index__purposeButton, .p-hokenSearch__index__companyButton {
  background: #FFF;
  border-radius: 9999px;
  border: 1.5px solid #D9D9D9;
  color: #333;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__categoryButton, .p-hokenSearch__index__purposeButton, .p-hokenSearch__index__companyButton {
    border-width: 3px;
  }
}
.p-hokenSearch__index__categoryButton:hover, .p-hokenSearch__index__purposeButton:hover, .p-hokenSearch__index__companyButton:hover {
  border-color: #41B4E6;
}
.p-hokenSearch__index__categoryButton[data-selected=true], .p-hokenSearch__index__purposeButton[data-selected=true], .p-hokenSearch__index__companyButton[data-selected=true] {
  border-color: #41B4E6;
}
.p-hokenSearch__index__categoryButton {
  padding: 10px 20px;
  background: #FFF;
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__categoryButton {
    padding: 0 40px;
    height: 80px;
    column-gap: 20px;
    font-size: 1.6rem;
  }
}
.p-hokenSearch__index__categoryButton__label {
  flex: 1;
}
.p-hokenSearch__index__categoryButton__icon1 {
  min-width: 20px;
  width: 20px;
  height: 20px;
  color: #41B4E6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-hokenSearch__index__categoryButton__icon1 svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__categoryButton__icon1 {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}
.p-hokenSearch__index__categoryButton__icon2 {
  min-width: 12px;
  width: 12px;
  height: 14px;
  color: #41B4E6;
}
.p-hokenSearch__index__categoryButton__icon2 svg {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__categoryButton__icon1 {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .p-hokenSearch__index__categoryButton__icon2 {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
}
.p-hokenSearch__index__purposeButton {
  padding: 10px 20px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__purposeButton {
    padding: 0 40px;
    height: 112px;
    column-gap: 20px;
    font-size: 1.6rem;
  }
}
.p-hokenSearch__index__purposeButton__label {
  flex: 1;
}
.p-hokenSearch__index__purposeButton__icon1 {
  min-width: 20px;
  width: 20px;
  height: 20px;
  color: #41B4E6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-hokenSearch__index__purposeButton__icon1 svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__purposeButton__icon1 {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}
.p-hokenSearch__index__purposeButton__icon2 {
  min-width: 12px;
  width: 12px;
  height: 14px;
  color: #41B4E6;
}
.p-hokenSearch__index__purposeButton__icon2 svg {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__purposeButton__icon1 {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .p-hokenSearch__index__purposeButton__icon2 {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
}
.p-hokenSearch__index__companyButton {
  padding-right: 20px;
  padding-left: 20px;
  height: 80px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__companyButton {
    padding-right: 20px;
    padding-left: 40px;
    font-size: 1.6rem;
    height: 140px;
    column-gap: 20px;
  }
}
.p-hokenSearch__index__companyButton__image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-hokenSearch__index__companyButton__image img {
  object-fit: contain;
}
@media screen and (max-width: 640px) {
  .p-hokenSearch__index__companyButton__image img {
    height: 40px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__companyButton__image img {
    height: 60px;
  }
}
.p-hokenSearch__index__companyButton__image--4 img {
  height: 62px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__companyButton__image--4 img {
    height: 94px;
  }
}
.p-hokenSearch__index__companyButton__image--8 img {
  height: 30px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__companyButton__image--8 img {
    height: 45px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__companyButton__image--15 img {
    height: 65px;
  }
}
.p-hokenSearch__index__companyButton__icon2 {
  min-width: 12px;
  width: 12px;
  height: 14px;
  color: #41B4E6;
}
.p-hokenSearch__index__companyButton__icon2 svg {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenSearch__index__companyButton__icon1 {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .p-hokenSearch__index__companyButton__icon2 {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
}

.p-hokenTopBannerSection01 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBannerSection01 {
    column-gap: 20px;
  }
}

.p-hokenTopBanner01 {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  border-radius: 9999px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner01 {
    padding: 30px;
    height: auto;
    column-gap: 20px;
    justify-content: center;
    font-size: 2.4rem;
  }
}
.p-hokenTopBanner01--soudan {
  background-color: #FA8246;
}
.p-hokenTopBanner01--request {
  background-color: #41B4E6;
}
.p-hokenTopBanner01__icon {
  min-width: 25px;
  width: 25px;
  height: 25px;
  fill: #fff;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner01__icon {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenTopBanner01__label {
    text-align: center;
  }
}

.p-hokenTopBannerSection02 {
  margin-top: 40px;
  display: grid;
  row-gap: 40px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBannerSection02 {
    margin-top: 80px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
}

.p-hokenTopBanner02 {
  padding: 15px;
  border-radius: 5px;
  border: 1.5px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner02 {
    padding: 40px;
    border-radius: 10px;
    border-width: 3px;
    row-gap: 20px;
  }
}
.p-hokenTopBanner02__title {
  font-size: 2.2rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner02__title {
    font-size: 3.2rem;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner02__img01 {
    order: 3;
  }
}
.p-hokenTopBanner02__description {
  color: #222;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner02__description {
    font-size: 1.6rem;
    order: 2;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner02__link {
    order: 4;
    flex: 1;
    display: flex;
    align-items: flex-end;
  }
}

.p-hokenTopBanner03 {
  padding: 15px;
  border-radius: 5px;
  border: 1.5px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner03 {
    padding: 40px;
    border-radius: 10px;
    border-width: 3px;
    row-gap: 20px;
  }
}
.p-hokenTopBanner03__title {
  font-size: 2.2rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner03__title {
    font-size: 3.2rem;
  }
}
.p-hokenTopBanner03__img01 {
  padding: 15px;
  background-color: #E4F7FF;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner03__img01 {
    padding: 20px;
  }
}
.p-hokenTopBanner03__img01__title {
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenTopBanner03__img01__title {
    margin-bottom: 20px;
    font-size: 2rem;
  }
}
.p-hokenTopBanner03__img01 img {
  cursor: pointer;
}

.p-hokenTopFaq {
  margin-top: 40px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFaq {
    margin-top: 80px;
  }
}
.p-hokenTopFaq .p-hokenFaq__box + .p-hokenFaq__box {
  margin-top: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFaq .p-hokenFaq__box + .p-hokenFaq__box {
    margin-top: 20px;
  }
}
.p-hokenTopFaq__more {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 641px) {
  .p-hokenTopFaq__more {
    max-width: 590px;
    margin-top: 40px;
  }
}

.p-hokenTopRecommend {
  margin-top: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopRecommend {
    margin-top: 80px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.p-hokenTopRecommend__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopRecommend__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}
.p-hokenTopRecommend__list__item a {
  color: inherit;
}
.p-hokenTopRecommend__list__item img {
  width: 100%;
}
.p-hokenTopRecommend__list__item__caption {
  margin-top: 10px;
  font-size: 1.6rem;
}
@media screen and (min-width: 641px) {
  .p-hokenTopRecommend__list__item__caption {
    font-size: 1.8rem;
  }
}

.p-hokenTopInformation {
  margin-top: 40px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopInformation {
    margin-top: 80px;
  }
}
.p-hokenTopInformation__list__link {
  margin-top: -1px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopInformation__list__link {
    margin-top: -3px;
    padding: 20px 60px 20px 20px;
    column-gap: 20px;
    border-top-width: 3px;
    border-bottom-width: 3px;
    position: relative;
  }
  .p-hokenTopInformation__list__link:hover .p-hokenTopInformation__list__icon {
    transform: translateY(-50%) translateX(10px);
  }
}
.p-hokenTopInformation__list__date {
  width: 100%;
  color: #41B4E6;
  font-size: 1.2rem;
}
@media screen and (min-width: 641px) {
  .p-hokenTopInformation__list__date {
    width: auto;
    font-size: 1.6rem;
  }
}
.p-hokenTopInformation__list__message {
  color: #333;
  font-size: 1.4rem;
  flex: 1;
}
.p-hokenTopInformation__list__icon {
  min-width: 12px;
  width: 12px;
  height: auto;
  color: #D9D9D9;
}
.p-hokenTopInformation__list__icon svg {
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 641px) {
  .p-hokenTopInformation__list__icon {
    width: 24px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: 0.3s ease-in-out;
  }
  .p-hokenTopInformation__list__icon:hover {
    color: #41B4E6;
  }
  .p-hokenTopInformation__list__icon svg {
    width: 24px;
    height: 24px;
  }
}
.p-hokenTopInformation__notFound {
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenTopInformation__notFound {
    font-size: 1.6rem;
  }
}
.p-hokenTopInformation__more {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 641px) {
  .p-hokenTopInformation__more {
    max-width: 590px;
    margin-top: 40px;
  }
}

.p-hokenCode {
  margin-top: 40px;
  color: #333;
  font-size: 1rem;
}
.p-hokenCode > * + * {
  margin-top: 1.5em;
}
@media screen and (min-width: 641px) {
  .p-hokenCode {
    font-size: 1.2rem;
  }
}

/*------------------------------------------------------------
保険　相談予約申込みフォーム（所属生協確認）
------------------------------------------------------------*/
.p-hokenSoudan03__content01__title {
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 1.9rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenSoudan03__content01__title {
    column-gap: 10px;
    font-size: 2.4rem;
  }
}
.p-hokenSoudan03__content01__title::before {
  content: "";
  min-width: 7.5px;
  width: 7.5px;
  height: 7.5px;
  background-color: #41B4E6;
  border-radius: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenSoudan03__content01__title::before {
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
}
.p-hokenSoudan03__content01__confirm {
  display: grid;
  row-gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenSoudan03__content01__confirm {
    grid-template-columns: 1fr 380px;
    grid-template-rows: auto 1fr;
    gap: 20px;
  }
}
.p-hokenSoudan03__content01__confirm__image img {
  width: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenSoudan03__content01__confirm__image {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　相談予約申込みフォーム（所属生協確認） 入力・エラー
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　相談予約申込みフォーム（所属生協確認） 確認
------------------------------------------------------------*/
@media screen and (min-width: 641px) {
  .p-hokenSoudanConfirm02__content02 {
    text-align: center;
  }
}
.p-hokenSoudanConfirm02__content02__txt {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenSoudanConfirm02__content02__txt {
    margin-bottom: 20px;
    font-size: 1.6rem;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　相談予約申込みフォーム（所属生協確認） 完了
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　資料請求フォーム（カート）
------------------------------------------------------------*/
.p-hokenRequestStep {
  display: flex;
  column-gap: 4px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequestStep {
    column-gap: 12px;
  }
}
.p-hokenRequestStep li {
  padding-left: 8px;
  flex: 1;
  height: 50px;
  color: #888;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  font-size: 1.2rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRequestStep li {
    height: 80px;
    font-size: 1.8rem;
  }
}
.p-hokenRequestStep li > span {
  position: relative;
  z-index: 1;
}
.p-hokenRequestStep li::before, .p-hokenRequestStep li::after {
  content: "";
  width: 100%;
  height: 50%;
  background-color: #EFEFEF;
  position: absolute;
  left: 5px;
  pointer-events: none;
}
.p-hokenRequestStep li::before {
  top: 0;
  transform: skewX(8deg);
}
@media screen and (min-width: 641px) {
  .p-hokenRequestStep li::before {
    transform: skewX(12deg);
  }
}
.p-hokenRequestStep li::after {
  bottom: 0;
  transform: skewX(-8deg);
}
@media screen and (min-width: 641px) {
  .p-hokenRequestStep li::after {
    transform: skewX(-12deg);
  }
}
.p-hokenRequestStep li:first-child {
  padding-left: 0;
  background-color: #EFEFEF;
}
.p-hokenRequestStep li:first-child.is-active {
  background-color: #41B4E6;
}
.p-hokenRequestStep li:last-child {
  padding-left: 4px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #EFEFEF 50%);
}
.p-hokenRequestStep li:last-child::before, .p-hokenRequestStep li:last-child::after {
  width: 50%;
}
.p-hokenRequestStep li.is-active {
  color: #fff;
  font-weight: 700;
}
.p-hokenRequestStep li.is-active::before, .p-hokenRequestStep li.is-active::after {
  background-color: #41B4E6;
}
.p-hokenRequestStep li.is-active:last-child {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #41B4E6 50%);
}

.p-hokenRequestCart__title {
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #41B4E6;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRequestCart__title {
    column-gap: 10px;
    font-size: 3.2rem;
  }
}
.p-hokenRequestCart__title svg {
  min-width: 30px;
  width: 30px;
  height: 30px;
}

.p-hokenRequest .c-hokenBlock01__title {
  color: #333;
  font-size: 1.9rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenBlock01__title {
    font-size: 2.4rem;
  }
}
.p-hokenRequest .c-hokenBlock01__txt {
  color: #333;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenBlock01__txt {
    font-size: 1.6rem;
  }
}
.p-hokenRequest .c-hokenBlock01__text {
  padding: 15px;
  background-color: #FFEDE3;
  border-radius: 5px;
  display: flex;
  align-items: baseline;
  column-gap: 2.5px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenBlock01__text {
    padding: 30px;
    border-radius: 10px;
    column-gap: 5px;
    font-size: 1.6rem;
  }
}
.p-hokenRequest .c-hokenBlock01__text::before {
  content: "※";
}
.p-hokenRequest .c-hokenList01__title {
  padding: 15px;
  background-color: #41B4E6;
  border-radius: 5px;
  color: #fff;
  font-size: 1.9rem;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList01__title {
    padding: 30px;
    border-radius: 10px;
    font-size: 2.4rem;
  }
}
.p-hokenRequest .c-hokenList01__title.is-active .c-hokenList01__icon::before {
  transform: translate(-50%, -50%) rotate(90deg);
}
.p-hokenRequest .c-hokenList01__title .c-hokenList01__txt {
  position: relative;
  z-index: 2;
}
.p-hokenRequest .c-hokenList01__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: 15px;
  height: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList01__icon {
    width: 30px;
    height: 30px;
    right: 30px;
  }
}
.p-hokenRequest .c-hokenList01__icon::before, .p-hokenRequest .c-hokenList01__icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  transition: 0.3s ease-in-out;
  border-radius: 9999px;
}
.p-hokenRequest .c-hokenList01__icon::before {
  height: 13px;
  width: 2px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList01__icon::before {
    height: 28px;
  }
}
.p-hokenRequest .c-hokenList01__icon::after {
  width: 13px;
  height: 2px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList01__icon::after {
    width: 28px;
  }
}
.p-hokenRequest .c-hokenList01__content {
  overflow: hidden;
  display: none;
}
.p-hokenRequest .c-hokenList02 {
  display: grid;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList02 {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
.p-hokenRequest .c-hokenList02__tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
  background: #333333;
  text-align: center;
  border-radius: 4px;
  padding: 12px 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-hokenRequest .c-hokenList02__tooltip {
    font-size: 1rem;
    line-height: 14px;
    width: calc(100% - 35px);
    padding: 11px 0;
  }
}
.p-hokenRequest .c-hokenList02__tooltip--pink {
  background: #FF5B4C;
  width: 330px;
}
@media screen and (max-width: 640px) {
  .p-hokenRequest .c-hokenList02__tooltip--pink {
    width: calc(100% - 35px);
  }
}
.p-hokenRequest .c-hokenList02__tooltip.is-active {
  opacity: 1;
  visibility: visible;
}
.p-hokenRequest .c-hokenList02__link {
  padding: 15px;
  position: relative;
  display: grid;
  grid-template-columns: 140px 1fr;
  gap: 15px;
  border: 1.5px solid #D9D9D9;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList02__link {
    padding: 30px;
    grid-template-columns: 211px 1fr;
    column-gap: 30px;
    row-gap: 10px;
    border-width: 3px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList02__img {
    grid-column: 1/2;
    grid-row: 1/3;
  }
}
.p-hokenRequest .c-hokenList02__title {
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hokenList02__title {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenRequest .c-hokenList02__btn {
    padding-top: 9px;
    padding-bottom: 9px;
    grid-column: 1/3;
    grid-row: 2/3;
    font-size: 1.6rem;
  }
}
.p-hokenRequest .c-hoken__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;
  padding: 40px 25px;
  display: none;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup {
    padding: 140px 0;
    z-index: 99;
  }
}
.p-hokenRequest .c-hoken__popupClose {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 12.5px;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popupClose {
    width: 50px;
    height: 50px;
    top: 30px;
    right: 40px;
  }
}
.p-hokenRequest .c-hoken__popupClose svg {
  width: 100%;
  height: 100%;
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03 {
  margin: 0 auto;
  padding: 40px 25px;
  background: #ffffff;
  position: relative;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03 {
    padding: 80px 100px;
    max-width: 1200px;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__title {
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 1.9rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__title {
    column-gap: 10px;
    font-size: 2.4rem;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__title::before {
  content: "";
  min-width: 7.5px;
  width: 7.5px;
  height: 7.5px;
  background-color: #41B4E6;
  border-radius: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__title::before {
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__confirm {
  display: grid;
  row-gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__confirm {
    grid-template-columns: 1fr 380px;
    grid-template-rows: auto 1fr;
    gap: 20px;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__confirm__image img {
  width: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content01__confirm__image {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02 {
  padding: 15px;
  background-color: #FFEDE3;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02 {
    padding: 30px;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02__title {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #333;
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02__title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 2rem;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02 li {
  display: flex;
  column-gap: 2.5px;
  align-items: baseline;
  font-size: 1.4rem;
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02 li::before {
  content: "※";
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02 li {
    font-size: 1.6rem;
  }
}
.p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02__note p {
  display: flex;
  column-gap: 2.5px;
  align-items: baseline;
  font-size: 1rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRequest .c-hoken__popup .p-hokenRequest03__content02__note p {
    font-size: 1.2rem;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　資料請求フォーム（カート） 入力・エラー
------------------------------------------------------------*/
.p-hokenRequestInput02__content01__txt {
  color: #333;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRequestInput02__content01__txt {
    font-size: 1.6rem;
  }
}
.p-hokenRequestInput02__content01__txt2 {
  margin-bottom: 15px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-hokenRequestInput02__content02 {
  padding-top: 20px;
}
@media screen and (min-width: 641px) {
  .p-hokenRequestInput02__content02 {
    padding-top: 40px;
  }
}
.p-hokenRequestInput02__content03 .c-cartBtn {
  margin: 0;
}
.p-hokenRequestInput02__content03 .c-cartBtn input.c-cartBtn__btn05 {
  margin: 0 !important;
  padding: 15px 20px !important;
  max-width: 590px !important;
  height: auto !important;
  display: block !important;
  border-radius: 9999px !important;
  background: #FA8246 url("../img/common/icon_arrow_right01_white.svg") no-repeat center right 20px !important;
  background-size: 12px auto !important;
  border: 1.5px solid transparent !important;
  color: #fff !important;
  font-size: 1.6rem !important;
  font-weight: bold !important;
  cursor: pointer !important;
  float: none !important;
}
@media screen and (min-width: 641px) {
  .p-hokenRequestInput02__content03 .c-cartBtn input.c-cartBtn__btn05 {
    width: 100% !important;
    padding: 33px 50px !important;
    background-position: center right 50px !important;
    background-size: 24px auto !important;
    border-width: 3px;
    column-gap: 10px !important;
    border-width: 3px !important;
    font-size: 2.4rem !important;
    transition: 0.3s ease-in-out;
  }
  .p-hokenRequestInput02__content03 .c-cartBtn input.c-cartBtn__btn05:hover {
    opacity: 0.8;
  }
}

.c-hokenFormInput01 {
  padding: 5px 20px;
  width: 100%;
  border: 1px solid #888;
  border-radius: 5px;
  height: 50px;
  font-size: 1.4rem;
}
.c-hokenFormInput01::placeholder {
  color: #888;
}
@media screen and (min-width: 641px) {
  .c-hokenFormInput01 {
    padding: 10px 40px;
    height: 80px;
    border-radius: 10px;
    font-size: 1.6rem;
  }
}

.c-hokenFormTextArea01 {
  padding: 15px 20px;
  width: 100%;
  height: 206px;
  border: 1px solid #888;
  border-radius: 5px;
  font-size: 1.4rem;
}
.c-hokenFormTextArea01::placeholder {
  color: #888;
}
@media screen and (min-width: 641px) {
  .c-hokenFormTextArea01 {
    padding: 30px 40px;
    height: 300px;
    border-radius: 10px;
    font-size: 1.6rem;
  }
}

.c-hokenFormSelect01 {
  width: 100%;
}
.c-hokenFormSelect01 > select {
  padding: 5px 20px;
  width: 100%;
  border: 1px solid #888;
  border-radius: 5px;
  height: 50px;
  font-size: 1.4rem;
}
.c-hokenFormSelect01 > select::placeholder {
  color: #888;
}
@media screen and (min-width: 641px) {
  .c-hokenFormSelect01 > select {
    padding: 10px 40px;
    height: 80px;
    border-radius: 10px;
    font-size: 1.6rem;
  }
}

.c-hokenFormRadioGroup01 {
  display: grid;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .c-hokenFormRadioGroup01 {
    row-gap: 25px;
  }
}

.c-hokenFormRadio01 {
  width: fit-content;
}
.c-hokenFormRadio01 input {
  display: none;
}
.c-hokenFormRadio01 label {
  cursor: pointer;
  display: flex;
  column-gap: 8.5px;
}
@media screen and (min-width: 641px) {
  .c-hokenFormRadio01 label {
    column-gap: 17px;
  }
}
.c-hokenFormRadio01 label::before {
  content: "";
  min-width: 21px;
  width: 21px;
  height: 21px;
  background-color: #fff;
  border: 1px solid #888;
  border-radius: 50%;
  display: block;
}
@media screen and (min-width: 641px) {
  .c-hokenFormRadio01 label::before {
    min-width: 27px;
    width: 27px;
    height: 27px;
  }
}
.c-hokenFormRadio01 input:checked + label::before {
  border-color: #41B4E6;
  border-width: 7px;
}
@media screen and (min-width: 641px) {
  .c-hokenFormRadio01 input:checked + label::before {
    border-width: 10px;
  }
}

.c-hokenFormCheckboxGroup01 {
  display: grid;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .c-hokenFormCheckboxGroup01 {
    row-gap: 25px;
  }
}

.c-hokenFormCheckbox01 {
  width: fit-content;
}
.c-hokenFormCheckbox01 input {
  display: none;
}
.c-hokenFormCheckbox01 label {
  position: relative;
  cursor: pointer;
  display: flex;
  column-gap: 8.5px;
  font-size: 1.4rem !important;
}
@media screen and (min-width: 641px) {
  .c-hokenFormCheckbox01 label {
    column-gap: 17px;
    font-size: 1.8rem !important;
  }
}
.c-hokenFormCheckbox01 label::before {
  content: "";
  min-width: 21px;
  width: 21px;
  height: 21px;
  background-color: #fff;
  border: 1.5px solid #888;
  border-radius: 2.5px;
  display: block;
  position: relative;
  top: -1px;
}
@media screen and (min-width: 641px) {
  .c-hokenFormCheckbox01 label::before {
    min-width: 27px;
    width: 27px;
    height: 27px;
    border-radius: 5px;
  }
}
.c-hokenFormCheckbox01 label::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 6px;
  width: 9px;
  height: 14px;
  transform: rotate(45deg);
  border-width: 3px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}
@media screen and (min-width: 641px) {
  .c-hokenFormCheckbox01 label::after {
    top: 2px;
    left: 8px;
    width: 11px;
    height: 17px;
  }
}
.c-hokenFormCheckbox01 input:checked + label::before {
  background-color: #41B4E6;
  border-color: #41B4E6;
}

.c-table3--error .c-hokenFormInput01,
.c-table3--error .c-hokenFormSelect01 > select,
.c-table3--error .c-hokenFormTextArea01 {
  border-color: #F43;
  background-color: #FFEDE3;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　資料請求フォーム（カート） 確認
------------------------------------------------------------*/
@media screen and (min-width: 641px) {
  .p-hokenRequestConfirm02__content02 {
    text-align: center;
  }
}
.p-hokenRequestConfirm02__content02__txt {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRequestConfirm02__content02__txt {
    margin-bottom: 20px;
    font-size: 1.6rem;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　資料請求フォーム（カート） 完了
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険料が割安になる保険一覧
------------------------------------------------------------*/
.p-hokenService01 {
  background-color: #ffffff;
}

.p-hokenService01-01 {
  margin-bottom: 40px;
}
.p-hokenService01-01 .p-hokenService01-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service01.jpg");
}
.p-hokenService01-01 .p-hokenService01-01__content01 .p-hokenService01-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService01-01 .p-hokenService01-01__content01 .p-hokenService01-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService01-02 {
  margin-bottom: 50px;
}
.p-hokenService01-03 {
  margin-bottom: 40px;
}
.p-hokenService01-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService01-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService01-01 {
    margin-bottom: 17px;
  }
  .p-hokenService01-01 .p-hokenService01-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service01_sp.jpg");
  }
  .p-hokenService01-01 .p-hokenService01-01__content01 .p-hokenService01-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService01-01 .p-hokenService01-01__content01 .p-hokenService01-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService01-02 {
    margin-bottom: 26px;
  }
  .p-hokenService01-02 .p-hokenService01-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService01-02 .p-hokenService01-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService01-02 .p-hokenService01-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService01-03 {
    margin-bottom: 25px;
  }
  .p-hokenService01-03 .p-hokenService01-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService01-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService01-04 {
    margin-bottom: 51px;
  }
  .p-hokenService01-04 .p-hokenService01-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService01-04 .p-hokenService01-04__content01 .c-hokenBtn03 a, .p-hokenService01-04 .p-hokenService01-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService01-04 .p-hokenService01-04__content01 .c-hokenBtn03 a:before, .p-hokenService01-04 .p-hokenService01-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService01-04 .p-hokenService01-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
ネット申込みができる保険一覧
------------------------------------------------------------*/
.p-hokenService02 {
  background-color: #ffffff;
}

.p-hokenService02-01 {
  margin-bottom: 40px;
}
.p-hokenService02-01 .p-hokenService02-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service02.jpg");
}
.p-hokenService02-01 .p-hokenService02-01__content01 .p-hokenService02-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService02-01 .p-hokenService02-01__content01 .p-hokenService02-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService02-02 {
  margin-bottom: 50px;
}
.p-hokenService02-03 {
  margin-bottom: 40px;
}
.p-hokenService02-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService02-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService02-01 {
    margin-bottom: 17px;
  }
  .p-hokenService02-01 .p-hokenService02-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service02_sp.jpg");
  }
  .p-hokenService02-01 .p-hokenService02-01__content01 .p-hokenService02-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService02-01 .p-hokenService02-01__content01 .p-hokenService02-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService02-02 {
    margin-bottom: 26px;
  }
  .p-hokenService02-02 .p-hokenService02-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService02-02 .p-hokenService02-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService02-02 .p-hokenService02-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService02-03 {
    margin-bottom: 25px;
  }
  .p-hokenService02-03 .p-hokenService02-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService02-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService02-04 {
    margin-bottom: 51px;
  }
  .p-hokenService02-04 .p-hokenService02-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService02-04 .p-hokenService02-04__content01 .c-hokenBtn03 a, .p-hokenService02-04 .p-hokenService02-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService02-04 .p-hokenService02-04__content01 .c-hokenBtn03 a:before, .p-hokenService02-04 .p-hokenService02-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService02-04 .p-hokenService02-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
ケガ保険一覧
------------------------------------------------------------*/
.p-hokenService03 {
  background-color: #ffffff;
}

.p-hokenService03-01 {
  margin-bottom: 40px;
}
.p-hokenService03-01 .p-hokenService03-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service03.jpg");
}
.p-hokenService03-01 .p-hokenService03-01__content01 .p-hokenService03-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService03-01 .p-hokenService03-01__content01 .p-hokenService03-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService03-02 {
  margin-bottom: 50px;
}
.p-hokenService03-03 {
  margin-bottom: 40px;
}
.p-hokenService03-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService03-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService03-01 {
    margin-bottom: 17px;
  }
  .p-hokenService03-01 .p-hokenService03-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service03_sp.jpg");
  }
  .p-hokenService03-01 .p-hokenService03-01__content01 .p-hokenService03-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService03-01 .p-hokenService03-01__content01 .p-hokenService03-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService03-02 {
    margin-bottom: 26px;
  }
  .p-hokenService03-02 .p-hokenService03-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService03-02 .p-hokenService03-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService03-02 .p-hokenService03-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService03-03 {
    margin-bottom: 25px;
  }
  .p-hokenService03-03 .p-hokenService03-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService03-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService03-04 {
    margin-bottom: 51px;
  }
  .p-hokenService03-04 .p-hokenService03-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService03-04 .p-hokenService03-04__content01 .c-hokenBtn03 a, .p-hokenService03-04 .p-hokenService03-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService03-04 .p-hokenService03-04__content01 .c-hokenBtn03 a:before, .p-hokenService03-04 .p-hokenService03-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService03-04 .p-hokenService03-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
医療保険一覧
------------------------------------------------------------*/
.p-hokenService04 {
  background-color: #ffffff;
}

.p-hokenService04-01 {
  margin-bottom: 40px;
}
.p-hokenService04-01 .p-hokenService04-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service04.jpg");
}
.p-hokenService04-01 .p-hokenService04-01__content01 .p-hokenService04-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService04-01 .p-hokenService04-01__content01 .p-hokenService04-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService04-02 {
  margin-bottom: 50px;
}
.p-hokenService04-03 {
  margin-bottom: 40px;
}
.p-hokenService04-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService04-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService04-01 {
    margin-bottom: 17px;
  }
  .p-hokenService04-01 .p-hokenService04-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service04_sp.jpg");
  }
  .p-hokenService04-01 .p-hokenService04-01__content01 .p-hokenService04-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService04-01 .p-hokenService04-01__content01 .p-hokenService04-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService04-02 {
    margin-bottom: 26px;
  }
  .p-hokenService04-02 .p-hokenService04-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService04-02 .p-hokenService04-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService04-02 .p-hokenService04-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService04-03 {
    margin-bottom: 25px;
  }
  .p-hokenService04-03 .p-hokenService04-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService04-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService04-04 {
    margin-bottom: 51px;
  }
  .p-hokenService04-04 .p-hokenService04-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService04-04 .p-hokenService04-04__content01 .c-hokenBtn03 a, .p-hokenService04-04 .p-hokenService04-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService04-04 .p-hokenService04-04__content01 .c-hokenBtn03 a:before, .p-hokenService04-04 .p-hokenService04-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService04-04 .p-hokenService04-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
がん保険一覧
------------------------------------------------------------*/
.p-hokenService05 {
  background-color: #ffffff;
}

.p-hokenService05-01 {
  margin-bottom: 40px;
}
.p-hokenService05-01 .p-hokenService05-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service05.jpg");
}
.p-hokenService05-01 .p-hokenService05-01__content01 .p-hokenService05-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService05-01 .p-hokenService05-01__content01 .p-hokenService05-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService05-02 {
  margin-bottom: 50px;
}
.p-hokenService05-03 {
  margin-bottom: 40px;
}
.p-hokenService05-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService05-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService05-01 {
    margin-bottom: 17px;
  }
  .p-hokenService05-01 .p-hokenService05-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service05_sp.jpg");
  }
  .p-hokenService05-01 .p-hokenService05-01__content01 .p-hokenService05-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService05-01 .p-hokenService05-01__content01 .p-hokenService05-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService05-02 {
    margin-bottom: 26px;
  }
  .p-hokenService05-02 .p-hokenService05-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService05-02 .p-hokenService05-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService05-02 .p-hokenService05-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService05-03 {
    margin-bottom: 25px;
  }
  .p-hokenService05-03 .p-hokenService05-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService05-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService05-04 {
    margin-bottom: 51px;
  }
  .p-hokenService05-04 .p-hokenService05-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService05-04 .p-hokenService05-04__content01 .c-hokenBtn03 a, .p-hokenService05-04 .p-hokenService05-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService05-04 .p-hokenService05-04__content01 .c-hokenBtn03 a:before, .p-hokenService05-04 .p-hokenService05-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService05-04 .p-hokenService05-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
死亡保険一覧
------------------------------------------------------------*/
.p-hokenService06 {
  background-color: #ffffff;
}

.p-hokenService06-01 {
  margin-bottom: 40px;
}
.p-hokenService06-01 .p-hokenService06-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service06.jpg");
}
.p-hokenService06-01 .p-hokenService06-01__content01 .p-hokenService06-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService06-01 .p-hokenService06-01__content01 .p-hokenService06-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService06-02 {
  margin-bottom: 50px;
}
.p-hokenService06-03 {
  margin-bottom: 40px;
}
.p-hokenService06-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService06-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService06-01 {
    margin-bottom: 17px;
  }
  .p-hokenService06-01 .p-hokenService06-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service06_sp.jpg");
  }
  .p-hokenService06-01 .p-hokenService06-01__content01 .p-hokenService06-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService06-01 .p-hokenService06-01__content01 .p-hokenService06-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService06-02 {
    margin-bottom: 26px;
  }
  .p-hokenService06-02 .p-hokenService06-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService06-02 .p-hokenService06-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService06-02 .p-hokenService06-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService06-03 {
    margin-bottom: 25px;
  }
  .p-hokenService06-03 .p-hokenService06-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService06-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService06-04 {
    margin-bottom: 51px;
  }
  .p-hokenService06-04 .p-hokenService06-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService06-04 .p-hokenService06-04__content01 .c-hokenBtn03 a, .p-hokenService06-04 .p-hokenService06-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService06-04 .p-hokenService06-04__content01 .c-hokenBtn03 a:before, .p-hokenService06-04 .p-hokenService06-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService06-04 .p-hokenService06-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
介護・認知症保険一覧
------------------------------------------------------------*/
.p-hokenService07 {
  background-color: #ffffff;
}

.p-hokenService07-01 {
  margin-bottom: 40px;
}
.p-hokenService07-01 .p-hokenService07-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service07.jpg");
}
.p-hokenService07-01 .p-hokenService07-01__content01 .p-hokenService07-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService07-01 .p-hokenService07-01__content01 .p-hokenService07-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService07-02 {
  margin-bottom: 50px;
}
.p-hokenService07-03 {
  margin-bottom: 40px;
}
.p-hokenService07-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService07-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService07-01 {
    margin-bottom: 17px;
  }
  .p-hokenService07-01 .p-hokenService07-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service07_sp.jpg");
  }
  .p-hokenService07-01 .p-hokenService07-01__content01 .p-hokenService07-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService07-01 .p-hokenService07-01__content01 .p-hokenService07-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService07-02 {
    margin-bottom: 26px;
  }
  .p-hokenService07-02 .p-hokenService07-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService07-02 .p-hokenService07-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService07-02 .p-hokenService07-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService07-03 {
    margin-bottom: 25px;
  }
  .p-hokenService07-03 .p-hokenService07-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService07-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService07-04 {
    margin-bottom: 51px;
  }
  .p-hokenService07-04 .p-hokenService07-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService07-04 .p-hokenService07-04__content01 .c-hokenBtn03 a, .p-hokenService07-04 .p-hokenService07-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService07-04 .p-hokenService07-04__content01 .c-hokenBtn03 a:before, .p-hokenService07-04 .p-hokenService07-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService07-04 .p-hokenService07-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
学資保険一覧
------------------------------------------------------------*/
.p-hokenService08 {
  background-color: #ffffff;
}

.p-hokenService08-01 {
  margin-bottom: 40px;
}
.p-hokenService08-01 .p-hokenService08-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service08.jpg");
}
.p-hokenService08-01 .p-hokenService08-01__content01 .p-hokenService08-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService08-01 .p-hokenService08-01__content01 .p-hokenService08-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService08-02 {
  margin-bottom: 50px;
}
.p-hokenService08-03 {
  margin-bottom: 40px;
}
.p-hokenService08-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService08-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService08-01 {
    margin-bottom: 17px;
  }
  .p-hokenService08-01 .p-hokenService08-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service08_sp.jpg");
  }
  .p-hokenService08-01 .p-hokenService08-01__content01 .p-hokenService08-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService08-01 .p-hokenService08-01__content01 .p-hokenService08-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService08-02 {
    margin-bottom: 26px;
  }
  .p-hokenService08-02 .p-hokenService08-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService08-02 .p-hokenService08-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService08-02 .p-hokenService08-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService08-03 {
    margin-bottom: 25px;
  }
  .p-hokenService08-03 .p-hokenService08-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService08-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService08-04 {
    margin-bottom: 51px;
  }
  .p-hokenService08-04 .p-hokenService08-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService08-04 .p-hokenService08-04__content01 .c-hokenBtn03 a, .p-hokenService08-04 .p-hokenService08-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService08-04 .p-hokenService08-04__content01 .c-hokenBtn03 a:before, .p-hokenService08-04 .p-hokenService08-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService08-04 .p-hokenService08-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
個人年金保険一覧
------------------------------------------------------------*/
.p-hokenService09 {
  background-color: #ffffff;
}

.p-hokenService09-01 {
  margin-bottom: 40px;
}
.p-hokenService09-01 .p-hokenService09-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service09.jpg");
}
.p-hokenService09-01 .p-hokenService09-01__content01 .p-hokenService09-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService09-01 .p-hokenService09-01__content01 .p-hokenService09-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService09-02 {
  margin-bottom: 50px;
}
.p-hokenService09-03 {
  margin-bottom: 40px;
}
.p-hokenService09-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService09-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService09-01 {
    margin-bottom: 17px;
  }
  .p-hokenService09-01 .p-hokenService09-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service09_sp.jpg");
  }
  .p-hokenService09-01 .p-hokenService09-01__content01 .p-hokenService09-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService09-01 .p-hokenService09-01__content01 .p-hokenService09-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService09-02 {
    margin-bottom: 26px;
  }
  .p-hokenService09-02 .p-hokenService09-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService09-02 .p-hokenService09-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService09-02 .p-hokenService09-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService09-03 {
    margin-bottom: 25px;
  }
  .p-hokenService09-03 .p-hokenService09-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService09-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService09-04 {
    margin-bottom: 51px;
  }
  .p-hokenService09-04 .p-hokenService09-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService09-04 .p-hokenService09-04__content01 .c-hokenBtn03 a, .p-hokenService09-04 .p-hokenService09-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService09-04 .p-hokenService09-04__content01 .c-hokenBtn03 a:before, .p-hokenService09-04 .p-hokenService09-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService09-04 .p-hokenService09-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
収入保障保険一覧
------------------------------------------------------------*/
.p-hokenService10 {
  background-color: #ffffff;
}

.p-hokenService10-01 {
  margin-bottom: 40px;
}
.p-hokenService10-01 .p-hokenService10-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service10.jpg");
}
.p-hokenService10-01 .p-hokenService10-01__content01 .p-hokenService10-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService10-01 .p-hokenService10-01__content01 .p-hokenService10-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService10-02 {
  margin-bottom: 50px;
}
.p-hokenService10-03 {
  margin-bottom: 40px;
}
.p-hokenService10-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService10-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService10-01 {
    margin-bottom: 17px;
  }
  .p-hokenService10-01 .p-hokenService10-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service10_sp.jpg");
  }
  .p-hokenService10-01 .p-hokenService10-01__content01 .p-hokenService10-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService10-01 .p-hokenService10-01__content01 .p-hokenService10-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService10-02 {
    margin-bottom: 26px;
  }
  .p-hokenService10-02 .p-hokenService10-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService10-02 .p-hokenService10-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService10-02 .p-hokenService10-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService10-03 {
    margin-bottom: 25px;
  }
  .p-hokenService10-03 .p-hokenService10-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService10-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService10-04 {
    margin-bottom: 51px;
  }
  .p-hokenService10-04 .p-hokenService10-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService10-04 .p-hokenService10-04__content01 .c-hokenBtn03 a, .p-hokenService10-04 .p-hokenService10-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService10-04 .p-hokenService10-04__content01 .c-hokenBtn03 a:before, .p-hokenService10-04 .p-hokenService10-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService10-04 .p-hokenService10-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
自動車・バイク保険一覧
------------------------------------------------------------*/
.p-hokenService11 {
  background-color: #ffffff;
}

.p-hokenService11-01 {
  margin-bottom: 40px;
}
.p-hokenService11-01 .p-hokenService11-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service27.jpg");
}
.p-hokenService11-01 .p-hokenService11-01__content01 .p-hokenService11-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService11-01 .p-hokenService11-01__content01 .p-hokenService11-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService11-02 {
  margin-bottom: 50px;
}
.p-hokenService11-03 {
  margin-bottom: 40px;
}
.p-hokenService11-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService11-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService11-01 {
    margin-bottom: 17px;
  }
  .p-hokenService11-01 .p-hokenService11-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service27_sp.jpg");
  }
  .p-hokenService11-01 .p-hokenService11-01__content01 .p-hokenService11-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService11-01 .p-hokenService11-01__content01 .p-hokenService11-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService11-02 {
    margin-bottom: 26px;
  }
  .p-hokenService11-02 .p-hokenService11-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService11-02 .p-hokenService11-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService11-02 .p-hokenService11-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService11-03 {
    margin-bottom: 25px;
  }
  .p-hokenService11-03 .p-hokenService11-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService11-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService11-04 {
    margin-bottom: 51px;
  }
  .p-hokenService11-04 .p-hokenService11-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService11-04 .p-hokenService11-04__content01 .c-hokenBtn03 a, .p-hokenService11-04 .p-hokenService11-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService11-04 .p-hokenService11-04__content01 .c-hokenBtn03 a:before, .p-hokenService11-04 .p-hokenService11-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService11-04 .p-hokenService11-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
火災・地震保険一覧
------------------------------------------------------------*/
.p-hokenService12 {
  background-color: #ffffff;
}

.p-hokenService12-01 {
  margin-bottom: 40px;
}
.p-hokenService12-01 .p-hokenService12-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service12.jpg");
}
.p-hokenService12-01 .p-hokenService12-01__content01 .p-hokenService12-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService12-01 .p-hokenService12-01__content01 .p-hokenService12-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService12-02 {
  margin-bottom: 50px;
}
.p-hokenService12-03 {
  margin-bottom: 40px;
}
.p-hokenService12-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService12-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService12-01 {
    margin-bottom: 17px;
  }
  .p-hokenService12-01 .p-hokenService12-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service12_sp.jpg");
  }
  .p-hokenService12-01 .p-hokenService12-01__content01 .p-hokenService12-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService12-01 .p-hokenService12-01__content01 .p-hokenService12-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService12-02 {
    margin-bottom: 26px;
  }
  .p-hokenService12-02 .p-hokenService12-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService12-02 .p-hokenService12-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService12-02 .p-hokenService12-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService12-03 {
    margin-bottom: 25px;
  }
  .p-hokenService12-03 .p-hokenService12-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService12-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService12-04 {
    margin-bottom: 51px;
  }
  .p-hokenService12-04 .p-hokenService12-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService12-04 .p-hokenService12-04__content01 .c-hokenBtn03 a, .p-hokenService12-04 .p-hokenService12-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService12-04 .p-hokenService12-04__content01 .c-hokenBtn03 a:before, .p-hokenService12-04 .p-hokenService12-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService12-04 .p-hokenService12-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
ペット保険一覧
------------------------------------------------------------*/
.p-hokenService13 {
  background-color: #ffffff;
}

.p-hokenService13-01 {
  margin-bottom: 40px;
}
.p-hokenService13-01 .p-hokenService13-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service13.jpg");
}
.p-hokenService13-01 .p-hokenService13-01__content01 .p-hokenService13-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService13-01 .p-hokenService13-01__content01 .p-hokenService13-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService13-02 {
  margin-bottom: 50px;
}
.p-hokenService13-03 {
  margin-bottom: 40px;
}
.p-hokenService13-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService13-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService13-01 {
    margin-bottom: 17px;
  }
  .p-hokenService13-01 .p-hokenService13-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service13_sp.jpg");
  }
  .p-hokenService13-01 .p-hokenService13-01__content01 .p-hokenService13-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService13-01 .p-hokenService13-01__content01 .p-hokenService13-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService13-02 {
    margin-bottom: 26px;
  }
  .p-hokenService13-02 .p-hokenService13-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService13-02 .p-hokenService13-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService13-02 .p-hokenService13-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService13-03 {
    margin-bottom: 25px;
  }
  .p-hokenService13-03 .p-hokenService13-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService13-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService13-04 {
    margin-bottom: 51px;
  }
  .p-hokenService13-04 .p-hokenService13-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService13-04 .p-hokenService13-04__content01 .c-hokenBtn03 a, .p-hokenService13-04 .p-hokenService13-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService13-04 .p-hokenService13-04__content01 .c-hokenBtn03 a:before, .p-hokenService13-04 .p-hokenService13-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService13-04 .p-hokenService13-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
旅行保険一覧
------------------------------------------------------------*/
.p-hokenService14 {
  background-color: #ffffff;
}

.p-hokenService14-01 {
  margin-bottom: 40px;
}
.p-hokenService14-01 .p-hokenService14-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service14.jpg");
}
.p-hokenService14-01 .p-hokenService14-01__content01 .p-hokenService14-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService14-01 .p-hokenService14-01__content01 .p-hokenService14-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService14-02 {
  margin-bottom: 50px;
}
.p-hokenService14-03 {
  margin-bottom: 40px;
}
.p-hokenService14-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService14-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService14-01 {
    margin-bottom: 17px;
  }
  .p-hokenService14-01 .p-hokenService14-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service14_sp.jpg");
  }
  .p-hokenService14-01 .p-hokenService14-01__content01 .p-hokenService14-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService14-01 .p-hokenService14-01__content01 .p-hokenService14-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService14-02 {
    margin-bottom: 26px;
  }
  .p-hokenService14-02 .p-hokenService14-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService14-02 .p-hokenService14-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService14-02 .p-hokenService14-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService14-03 {
    margin-bottom: 25px;
  }
  .p-hokenService14-03 .p-hokenService14-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService14-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService14-04 {
    margin-bottom: 51px;
  }
  .p-hokenService14-04 .p-hokenService14-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService14-04 .p-hokenService14-04__content01 .c-hokenBtn03 a, .p-hokenService14-04 .p-hokenService14-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService14-04 .p-hokenService14-04__content01 .c-hokenBtn03 a:before, .p-hokenService14-04 .p-hokenService14-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService14-04 .p-hokenService14-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
ゴルファー保険一覧
------------------------------------------------------------*/
.p-hokenService15 {
  background-color: #ffffff;
}

.p-hokenService15-01 {
  margin-bottom: 40px;
}
.p-hokenService15-01 .p-hokenService15-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service15.jpg");
}
.p-hokenService15-01 .p-hokenService15-01__content01 .p-hokenService15-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService15-01 .p-hokenService15-01__content01 .p-hokenService15-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService15-02 {
  margin-bottom: 50px;
}
.p-hokenService15-03 {
  margin-bottom: 40px;
}
.p-hokenService15-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService15-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService15-01 {
    margin-bottom: 17px;
  }
  .p-hokenService15-01 .p-hokenService15-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service15_sp.jpg");
  }
  .p-hokenService15-01 .p-hokenService15-01__content01 .p-hokenService15-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService15-01 .p-hokenService15-01__content01 .p-hokenService15-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService15-02 {
    margin-bottom: 26px;
  }
  .p-hokenService15-02 .p-hokenService15-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService15-02 .p-hokenService15-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService15-02 .p-hokenService15-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService15-03 {
    margin-bottom: 25px;
  }
  .p-hokenService15-03 .p-hokenService15-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService15-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService15-04 {
    margin-bottom: 51px;
  }
  .p-hokenService15-04 .p-hokenService15-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService15-04 .p-hokenService15-04__content01 .c-hokenBtn03 a, .p-hokenService15-04 .p-hokenService15-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService15-04 .p-hokenService15-04__content01 .c-hokenBtn03 a:before, .p-hokenService15-04 .p-hokenService15-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService15-04 .p-hokenService15-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
女性がかかりやすい病気やがんの保障を手厚くした保険
------------------------------------------------------------*/
.p-hokenService16 {
  background-color: #ffffff;
}

.p-hokenService16-01 {
  margin-bottom: 40px;
}
.p-hokenService16-01 .p-hokenService16-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 34px 0 43px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service16.jpg");
  line-height: 1.4;
}
.p-hokenService16-01 .p-hokenService16-01__content01 .p-hokenService16-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService16-01 .p-hokenService16-01__content01 .p-hokenService16-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService16-02 {
  margin-bottom: 50px;
}
.p-hokenService16-03 {
  margin-bottom: 40px;
}
.p-hokenService16-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService16-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService16-01 {
    margin-bottom: 17px;
  }
  .p-hokenService16-01 .p-hokenService16-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 10px 19px 12px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service16_sp.jpg");
  }
  .p-hokenService16-01 .p-hokenService16-01__content01 .p-hokenService16-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService16-01 .p-hokenService16-01__content01 .p-hokenService16-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService16-02 {
    margin-bottom: 26px;
  }
  .p-hokenService16-02 .p-hokenService16-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService16-02 .p-hokenService16-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService16-02 .p-hokenService16-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService16-03 {
    margin-bottom: 25px;
  }
  .p-hokenService16-03 .p-hokenService16-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService16-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService16-04 {
    margin-bottom: 51px;
  }
  .p-hokenService16-04 .p-hokenService16-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService16-04 .p-hokenService16-04__content01 .c-hokenBtn03 a, .p-hokenService16-04 .p-hokenService16-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService16-04 .p-hokenService16-04__content01 .c-hokenBtn03 a:before, .p-hokenService16-04 .p-hokenService16-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService16-04 .p-hokenService16-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
子どものために備えたい
------------------------------------------------------------*/
.p-hokenService17 {
  background-color: #ffffff;
}

.p-hokenService17-01 {
  margin-bottom: 40px;
}
.p-hokenService17-01 .p-hokenService17-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service17.jpg");
}
.p-hokenService17-01 .p-hokenService17-01__content01 .p-hokenService17-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService17-01 .p-hokenService17-01__content01 .p-hokenService17-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService17-02 {
  margin-bottom: 50px;
}
.p-hokenService17-03 {
  margin-bottom: 40px;
}
.p-hokenService17-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService17-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService17-01 {
    margin-bottom: 17px;
  }
  .p-hokenService17-01 .p-hokenService17-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service17_sp.jpg");
  }
  .p-hokenService17-01 .p-hokenService17-01__content01 .p-hokenService17-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService17-01 .p-hokenService17-01__content01 .p-hokenService17-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService17-02 {
    margin-bottom: 26px;
  }
  .p-hokenService17-02 .p-hokenService17-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService17-02 .p-hokenService17-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService17-02 .p-hokenService17-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService17-03 {
    margin-bottom: 25px;
  }
  .p-hokenService17-03 .p-hokenService17-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService17-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService17-04 {
    margin-bottom: 51px;
  }
  .p-hokenService17-04 .p-hokenService17-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService17-04 .p-hokenService17-04__content01 .c-hokenBtn03 a, .p-hokenService17-04 .p-hokenService17-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService17-04 .p-hokenService17-04__content01 .c-hokenBtn03 a:before, .p-hokenService17-04 .p-hokenService17-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService17-04 .p-hokenService17-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
薬を服用中など、健康に不安のある方でも入りやすい保険
------------------------------------------------------------*/
.p-hokenService18 {
  background-color: #ffffff;
}

.p-hokenService18-01 {
  margin-bottom: 40px;
}
.p-hokenService18-01 .p-hokenService18-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 34px 0 34px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service18.jpg");
  line-height: 1.4;
}
.p-hokenService18-01 .p-hokenService18-01__content01 .p-hokenService18-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService18-01 .p-hokenService18-01__content01 .p-hokenService18-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService18-02 {
  margin-bottom: 50px;
}
.p-hokenService18-03 {
  margin-bottom: 40px;
}
.p-hokenService18-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService18-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService18-01 {
    margin-bottom: 17px;
  }
  .p-hokenService18-01 .p-hokenService18-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 11px 0 11px 20px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service18_sp.jpg");
  }
  .p-hokenService18-01 .p-hokenService18-01__content01 .p-hokenService18-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService18-01 .p-hokenService18-01__content01 .p-hokenService18-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService18-02 {
    margin-bottom: 26px;
  }
  .p-hokenService18-02 .p-hokenService18-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService18-02 .p-hokenService18-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService18-02 .p-hokenService18-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService18-03 {
    margin-bottom: 25px;
  }
  .p-hokenService18-03 .p-hokenService18-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService18-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService18-04 {
    margin-bottom: 51px;
  }
  .p-hokenService18-04 .p-hokenService18-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService18-04 .p-hokenService18-04__content01 .c-hokenBtn03 a, .p-hokenService18-04 .p-hokenService18-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService18-04 .p-hokenService18-04__content01 .c-hokenBtn03 a:before, .p-hokenService18-04 .p-hokenService18-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService18-04 .p-hokenService18-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
入院・死亡の保障をまとめて備えたい
------------------------------------------------------------*/
.p-hokenService19 {
  background-color: #ffffff;
}

.p-hokenService19-01 {
  margin-bottom: 40px;
}
.p-hokenService19-01 .p-hokenService19-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service19.jpg");
}
.p-hokenService19-01 .p-hokenService19-01__content01 .p-hokenService19-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService19-01 .p-hokenService19-01__content01 .p-hokenService19-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService19-02 {
  margin-bottom: 50px;
}
.p-hokenService19-03 {
  margin-bottom: 40px;
}
.p-hokenService19-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService19-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService19-01 {
    margin-bottom: 17px;
  }
  .p-hokenService19-01 .p-hokenService19-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 18px 0 19px 20px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service19_sp.jpg");
  }
  .p-hokenService19-01 .p-hokenService19-01__content01 .p-hokenService19-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService19-01 .p-hokenService19-01__content01 .p-hokenService19-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService19-02 {
    margin-bottom: 26px;
  }
  .p-hokenService19-02 .p-hokenService19-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService19-02 .p-hokenService19-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService19-02 .p-hokenService19-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService19-03 {
    margin-bottom: 25px;
  }
  .p-hokenService19-03 .p-hokenService19-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService19-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService19-04 {
    margin-bottom: 51px;
  }
  .p-hokenService19-04 .p-hokenService19-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService19-04 .p-hokenService19-04__content01 .c-hokenBtn03 a, .p-hokenService19-04 .p-hokenService19-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService19-04 .p-hokenService19-04__content01 .c-hokenBtn03 a:before, .p-hokenService19-04 .p-hokenService19-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService19-04 .p-hokenService19-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
三大疾病の保障を手厚くしたい
------------------------------------------------------------*/
.p-hokenService20 {
  background-color: #ffffff;
}

.p-hokenService20-01 {
  margin-bottom: 40px;
}
.p-hokenService20-01 .p-hokenService20-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service20.jpg");
}
.p-hokenService20-01 .p-hokenService20-01__content01 .p-hokenService20-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService20-01 .p-hokenService20-01__content01 .p-hokenService20-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService20-02 {
  margin-bottom: 50px;
}
.p-hokenService20-03 {
  margin-bottom: 40px;
}
.p-hokenService20-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService20-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService20-01 {
    margin-bottom: 17px;
  }
  .p-hokenService20-01 .p-hokenService20-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 18px 0 18px 20px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service20_sp.jpg");
  }
  .p-hokenService20-01 .p-hokenService20-01__content01 .p-hokenService20-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService20-01 .p-hokenService20-01__content01 .p-hokenService20-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService20-02 {
    margin-bottom: 26px;
  }
  .p-hokenService20-02 .p-hokenService20-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService20-02 .p-hokenService20-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService20-02 .p-hokenService20-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService20-03 {
    margin-bottom: 25px;
  }
  .p-hokenService20-03 .p-hokenService20-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService20-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService20-04 {
    margin-bottom: 51px;
  }
  .p-hokenService20-04 .p-hokenService20-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService20-04 .p-hokenService20-04__content01 .c-hokenBtn03 a, .p-hokenService20-04 .p-hokenService20-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService20-04 .p-hokenService20-04__content01 .c-hokenBtn03 a:before, .p-hokenService20-04 .p-hokenService20-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService20-04 .p-hokenService20-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬儀代に備えたい
------------------------------------------------------------*/
.p-hokenService21 {
  background-color: #ffffff;
}

.p-hokenService21-01 {
  margin-bottom: 40px;
}
.p-hokenService21-01 .p-hokenService21-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service21.jpg");
}
.p-hokenService21-01 .p-hokenService21-01__content01 .p-hokenService21-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService21-01 .p-hokenService21-01__content01 .p-hokenService21-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService21-02 {
  margin-bottom: 50px;
}
.p-hokenService21-03 {
  margin-bottom: 40px;
}
.p-hokenService21-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService21-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService21-01 {
    margin-bottom: 17px;
  }
  .p-hokenService21-01 .p-hokenService21-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service21_sp.jpg");
  }
  .p-hokenService21-01 .p-hokenService21-01__content01 .p-hokenService21-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService21-01 .p-hokenService21-01__content01 .p-hokenService21-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService21-02 {
    margin-bottom: 26px;
  }
  .p-hokenService21-02 .p-hokenService21-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService21-02 .p-hokenService21-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService21-02 .p-hokenService21-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService21-03 {
    margin-bottom: 25px;
  }
  .p-hokenService21-03 .p-hokenService21-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService21-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService21-04 {
    margin-bottom: 51px;
  }
  .p-hokenService21-04 .p-hokenService21-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService21-04 .p-hokenService21-04__content01 .c-hokenBtn03 a, .p-hokenService21-04 .p-hokenService21-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService21-04 .p-hokenService21-04__content01 .c-hokenBtn03 a:before, .p-hokenService21-04 .p-hokenService21-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService21-04 .p-hokenService21-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
遺族のために備えたい
------------------------------------------------------------*/
.p-hokenService22 {
  background-color: #ffffff;
}

.p-hokenService22-01 {
  margin-bottom: 40px;
}
.p-hokenService22-01 .p-hokenService22-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service22.jpg");
}
.p-hokenService22-01 .p-hokenService22-01__content01 .p-hokenService22-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService22-01 .p-hokenService22-01__content01 .p-hokenService22-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService22-02 {
  margin-bottom: 50px;
}
.p-hokenService22-03 {
  margin-bottom: 40px;
}
.p-hokenService22-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService22-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService22-01 {
    margin-bottom: 17px;
  }
  .p-hokenService22-01 .p-hokenService22-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service22_sp.jpg");
  }
  .p-hokenService22-01 .p-hokenService22-01__content01 .p-hokenService22-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService22-01 .p-hokenService22-01__content01 .p-hokenService22-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService22-02 {
    margin-bottom: 26px;
  }
  .p-hokenService22-02 .p-hokenService22-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService22-02 .p-hokenService22-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService22-02 .p-hokenService22-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService22-03 {
    margin-bottom: 25px;
  }
  .p-hokenService22-03 .p-hokenService22-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService22-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService22-04 {
    margin-bottom: 51px;
  }
  .p-hokenService22-04 .p-hokenService22-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService22-04 .p-hokenService22-04__content01 .c-hokenBtn03 a, .p-hokenService22-04 .p-hokenService22-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService22-04 .p-hokenService22-04__content01 .c-hokenBtn03 a:before, .p-hokenService22-04 .p-hokenService22-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService22-04 .p-hokenService22-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
先進医療にも備えたい
------------------------------------------------------------*/
.p-hokenService23 {
  background-color: #ffffff;
}

.p-hokenService23-01 {
  margin-bottom: 40px;
}
.p-hokenService23-01 .p-hokenService23-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service23.jpg");
}
.p-hokenService23-01 .p-hokenService23-01__content01 .p-hokenService23-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService23-01 .p-hokenService23-01__content01 .p-hokenService23-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService23-02 {
  margin-bottom: 50px;
}
.p-hokenService23-03 {
  margin-bottom: 40px;
}
.p-hokenService23-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService23-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService23-01 {
    margin-bottom: 17px;
  }
  .p-hokenService23-01 .p-hokenService23-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service23_sp.jpg");
  }
  .p-hokenService23-01 .p-hokenService23-01__content01 .p-hokenService23-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService23-01 .p-hokenService23-01__content01 .p-hokenService23-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService23-02 {
    margin-bottom: 26px;
  }
  .p-hokenService23-02 .p-hokenService23-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService23-02 .p-hokenService23-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService23-02 .p-hokenService23-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService23-03 {
    margin-bottom: 25px;
  }
  .p-hokenService23-03 .p-hokenService23-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService23-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService23-04 {
    margin-bottom: 51px;
  }
  .p-hokenService23-04 .p-hokenService23-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService23-04 .p-hokenService23-04__content01 .c-hokenBtn03 a, .p-hokenService23-04 .p-hokenService23-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService23-04 .p-hokenService23-04__content01 .c-hokenBtn03 a:before, .p-hokenService23-04 .p-hokenService23-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService23-04 .p-hokenService23-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
75歳を過ぎても入れる保険
------------------------------------------------------------*/
.p-hokenService24 {
  background-color: #ffffff;
}

.p-hokenService24-01 {
  margin-bottom: 40px;
}
.p-hokenService24-01 .p-hokenService24-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service24.jpg");
}
.p-hokenService24-01 .p-hokenService24-01__content01 .p-hokenService24-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService24-01 .p-hokenService24-01__content01 .p-hokenService24-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService24-02 {
  margin-bottom: 50px;
}
.p-hokenService24-03 {
  margin-bottom: 40px;
}
.p-hokenService24-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService24-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService24-01 {
    margin-bottom: 17px;
  }
  .p-hokenService24-01 .p-hokenService24-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 0 20px 20px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service24_sp.jpg");
  }
  .p-hokenService24-01 .p-hokenService24-01__content01 .p-hokenService24-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService24-01 .p-hokenService24-01__content01 .p-hokenService24-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService24-02 {
    margin-bottom: 26px;
  }
  .p-hokenService24-02 .p-hokenService24-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService24-02 .p-hokenService24-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService24-02 .p-hokenService24-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService24-03 {
    margin-bottom: 25px;
  }
  .p-hokenService24-03 .p-hokenService24-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService24-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService24-04 {
    margin-bottom: 51px;
  }
  .p-hokenService24-04 .p-hokenService24-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService24-04 .p-hokenService24-04__content01 .c-hokenBtn03 a, .p-hokenService24-04 .p-hokenService24-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService24-04 .p-hokenService24-04__content01 .c-hokenBtn03 a:before, .p-hokenService24-04 .p-hokenService24-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService24-04 .p-hokenService24-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
自転車に乗るときの保険
------------------------------------------------------------*/
.p-hokenService25 {
  background-color: #ffffff;
}

.p-hokenService25-01 {
  margin-bottom: 40px;
}
.p-hokenService25-01 .p-hokenService25-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service25.jpg");
}
.p-hokenService25-01 .p-hokenService25-01__content01 .p-hokenService25-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService25-01 .p-hokenService25-01__content01 .p-hokenService25-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService25-02 {
  margin-bottom: 50px;
}
.p-hokenService25-03 {
  margin-bottom: 40px;
}
.p-hokenService25-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService25-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService25-01 {
    margin-bottom: 17px;
  }
  .p-hokenService25-01 .p-hokenService25-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service25_sp.jpg");
  }
  .p-hokenService25-01 .p-hokenService25-01__content01 .p-hokenService25-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService25-01 .p-hokenService25-01__content01 .p-hokenService25-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService25-02 {
    margin-bottom: 26px;
  }
  .p-hokenService25-02 .p-hokenService25-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService25-02 .p-hokenService25-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService25-02 .p-hokenService25-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService25-03 {
    margin-bottom: 25px;
  }
  .p-hokenService25-03 .p-hokenService25-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService25-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService25-04 {
    margin-bottom: 51px;
  }
  .p-hokenService25-04 .p-hokenService25-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService25-04 .p-hokenService25-04__content01 .c-hokenBtn03 a, .p-hokenService25-04 .p-hokenService25-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService25-04 .p-hokenService25-04__content01 .c-hokenBtn03 a:before, .p-hokenService25-04 .p-hokenService25-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService25-04 .p-hokenService25-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
日常生活のリスクに備える保険
------------------------------------------------------------*/
.p-hokenService26 {
  background-color: #ffffff;
}

.p-hokenService26-01 {
  margin-bottom: 40px;
}
.p-hokenService26-01 .p-hokenService26-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service26.jpg");
}
.p-hokenService26-01 .p-hokenService26-01__content01 .p-hokenService26-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService26-01 .p-hokenService26-01__content01 .p-hokenService26-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService26-02 {
  margin-bottom: 50px;
}
.p-hokenService26-03 {
  margin-bottom: 40px;
}
.p-hokenService26-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService26-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService26-01 {
    margin-bottom: 17px;
  }
  .p-hokenService26-01 .p-hokenService26-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 18px 0 19px 20px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service26_sp.jpg");
  }
  .p-hokenService26-01 .p-hokenService26-01__content01 .p-hokenService26-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService26-01 .p-hokenService26-01__content01 .p-hokenService26-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService26-02 {
    margin-bottom: 26px;
  }
  .p-hokenService26-02 .p-hokenService26-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService26-02 .p-hokenService26-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService26-02 .p-hokenService26-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService26-03 {
    margin-bottom: 25px;
  }
  .p-hokenService26-03 .p-hokenService26-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService26-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService26-04 {
    margin-bottom: 51px;
  }
  .p-hokenService26-04 .p-hokenService26-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService26-04 .p-hokenService26-04__content01 .c-hokenBtn03 a, .p-hokenService26-04 .p-hokenService26-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService26-04 .p-hokenService26-04__content01 .c-hokenBtn03 a:before, .p-hokenService26-04 .p-hokenService26-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService26-04 .p-hokenService26-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険　自動車やバイクの事故に備えたい
------------------------------------------------------------*/
.p-hokenService27 {
  background-color: #ffffff;
}

.p-hokenService27-01 {
  margin-bottom: 40px;
}
.p-hokenService27-01 .p-hokenService27-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service27.jpg");
}
.p-hokenService27-01 .p-hokenService27-01__content01 .p-hokenService27-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService27-01 .p-hokenService27-01__content01 .p-hokenService27-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService27-02 {
  margin-bottom: 50px;
}
.p-hokenService27-03 {
  margin-bottom: 40px;
}
.p-hokenService27-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService27-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService27-01 {
    margin-bottom: 17px;
  }
  .p-hokenService27-01 .p-hokenService27-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service27_sp.jpg");
  }
  .p-hokenService27-01 .p-hokenService27-01__content01 .p-hokenService27-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService27-01 .p-hokenService27-01__content01 .p-hokenService27-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService27-02 {
    margin-bottom: 26px;
  }
  .p-hokenService27-02 .p-hokenService27-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService27-02 .p-hokenService27-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService27-02 .p-hokenService27-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService27-03 {
    margin-bottom: 25px;
  }
  .p-hokenService27-03 .p-hokenService27-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService27-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService27-04 {
    margin-bottom: 51px;
  }
  .p-hokenService27-04 .p-hokenService27-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService27-04 .p-hokenService27-04__content01 .c-hokenBtn03 a, .p-hokenService27-04 .p-hokenService27-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService27-04 .p-hokenService27-04__content01 .c-hokenBtn03 a:before, .p-hokenService27-04 .p-hokenService27-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService27-04 .p-hokenService27-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
火事や地震補償を手厚くしたい
------------------------------------------------------------*/
.p-hokenService28 {
  background-color: #ffffff;
}

.p-hokenService28-01 {
  margin-bottom: 40px;
}
.p-hokenService28-01 .p-hokenService28-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service28.jpg");
}
.p-hokenService28-01 .p-hokenService28-01__content01 .p-hokenService28-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService28-01 .p-hokenService28-01__content01 .p-hokenService28-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService28-02 {
  margin-bottom: 50px;
}
.p-hokenService28-03 {
  margin-bottom: 40px;
}
.p-hokenService28-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService28-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService28-01 {
    margin-bottom: 17px;
  }
  .p-hokenService28-01 .p-hokenService28-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 18px 0 19px 20px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service28_sp.jpg");
  }
  .p-hokenService28-01 .p-hokenService28-01__content01 .p-hokenService28-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService28-01 .p-hokenService28-01__content01 .p-hokenService28-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService28-02 {
    margin-bottom: 26px;
  }
  .p-hokenService28-02 .p-hokenService28-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService28-02 .p-hokenService28-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService28-02 .p-hokenService28-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService28-03 {
    margin-bottom: 25px;
  }
  .p-hokenService28-03 .p-hokenService28-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService28-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService28-04 {
    margin-bottom: 51px;
  }
  .p-hokenService28-04 .p-hokenService28-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService28-04 .p-hokenService28-04__content01 .c-hokenBtn03 a, .p-hokenService28-04 .p-hokenService28-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService28-04 .p-hokenService28-04__content01 .c-hokenBtn03 a:before, .p-hokenService28-04 .p-hokenService28-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService28-04 .p-hokenService28-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
犬や猫の病気・ケガに備えたい
------------------------------------------------------------*/
.p-hokenService29 {
  background-color: #ffffff;
}

.p-hokenService29-01 {
  margin-bottom: 40px;
}
.p-hokenService29-01 .p-hokenService29-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service29.jpg");
}
.p-hokenService29-01 .p-hokenService29-01__content01 .p-hokenService29-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService29-01 .p-hokenService29-01__content01 .p-hokenService29-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService29-02 {
  margin-bottom: 50px;
}
.p-hokenService29-03 {
  margin-bottom: 40px;
}
.p-hokenService29-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService29-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService29-01 {
    margin-bottom: 17px;
  }
  .p-hokenService29-01 .p-hokenService29-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 18px 0 19px 20px;
    line-height: 27px;
    background-image: url("../img/hoken/title_obi_service29_sp.jpg");
  }
  .p-hokenService29-01 .p-hokenService29-01__content01 .p-hokenService29-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService29-01 .p-hokenService29-01__content01 .p-hokenService29-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService29-02 {
    margin-bottom: 26px;
  }
  .p-hokenService29-02 .p-hokenService29-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService29-02 .p-hokenService29-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService29-02 .p-hokenService29-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService29-03 {
    margin-bottom: 25px;
  }
  .p-hokenService29-03 .p-hokenService29-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService29-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService29-04 {
    margin-bottom: 51px;
  }
  .p-hokenService29-04 .p-hokenService29-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService29-04 .p-hokenService29-04__content01 .c-hokenBtn03 a, .p-hokenService29-04 .p-hokenService29-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService29-04 .p-hokenService29-04__content01 .c-hokenBtn03 a:before, .p-hokenService29-04 .p-hokenService29-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService29-04 .p-hokenService29-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
安心して旅行に行きたい
------------------------------------------------------------*/
.p-hokenService30 {
  background-color: #ffffff;
}

.p-hokenService30-01 {
  margin-bottom: 40px;
}
.p-hokenService30-01 .p-hokenService30-01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
  background-image: url("../img/hoken/title_obi_service30.jpg");
}
.p-hokenService30-01 .p-hokenService30-01__content01 .p-hokenService30-01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokenService30-01 .p-hokenService30-01__content01 .p-hokenService30-01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokenService30-02 {
  margin-bottom: 50px;
}
.p-hokenService30-03 {
  margin-bottom: 40px;
}
.p-hokenService30-03 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokenService30-04 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenService30-01 {
    margin-bottom: 17px;
  }
  .p-hokenService30-01 .p-hokenService30-01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
    background-image: url("../img/hoken/title_obi_service30_sp.jpg");
  }
  .p-hokenService30-01 .p-hokenService30-01__content01 .p-hokenService30-01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokenService30-01 .p-hokenService30-01__content01 .p-hokenService30-01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokenService30-02 {
    margin-bottom: 26px;
  }
  .p-hokenService30-02 .p-hokenService30-02__content01 {
    padding: 0 20px;
  }
  .p-hokenService30-02 .p-hokenService30-02__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokenService30-02 .p-hokenService30-02__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokenService30-03 {
    margin-bottom: 25px;
  }
  .p-hokenService30-03 .p-hokenService30-03__content01 {
    padding: 0 20px;
  }
  .p-hokenService30-03 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokenService30-04 {
    margin-bottom: 51px;
  }
  .p-hokenService30-04 .p-hokenService30-04__content01 {
    padding: 0 20px;
  }
  .p-hokenService30-04 .p-hokenService30-04__content01 .c-hokenBtn03 a, .p-hokenService30-04 .p-hokenService30-04__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokenService30-04 .p-hokenService30-04__content01 .c-hokenBtn03 a:before, .p-hokenService30-04 .p-hokenService30-04__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokenService30-04 .p-hokenService30-04__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
.p-hokenService .c-hokenItemSerect__item__request {
  margin-top: 35px;
  text-align: center;
}
.p-hokenService .c-hokenItemSerect__item__request a {
  width: auto;
  display: inline-block;
  padding: 0 10px;
  height: auto;
  margin: 0 auto;
  border-radius: 100px;
  line-height: 1.6;
  padding: 11px 44px 10px;
}
.p-hokenService .c-hokenItemSerect__item__request a::before {
  margin: -5px 3px 0 -3px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01 {
    margin: 0 -20px;
  }
}
.p-hokenService .c-box01__banner {
  background: url(/img/hoken/detail/c-box01_banner.jpg) no-repeat bottom left;
  background-size: cover !important;
  padding: 62px 20px 54px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__banner {
    background: url(/img/hoken/detail/c-box01_bannerSP.jpg) no-repeat bottom right;
    padding: 20px 20px 40px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__banner img {
    width: 190px;
  }
}
.p-hokenService .c-box01__inner {
  background: #FDF6AE;
  padding: 30px 30px 10px;
  margin-top: -35px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__inner {
    padding: 44px 20px 10px;
    position: relative;
    margin-top: -10px;
  }
}
.p-hokenService .c-box01__wrap {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__wrap {
    justify-content: center;
  }
}
@media screen and (max-width: 374px) {
  .p-hokenService .c-box01__wrap {
    flex-wrap: wrap;
  }
}
.p-hokenService .c-box01__title {
  position: relative;
  top: -19px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__title {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0 20px;
  }
}
.p-hokenService .c-box01__content {
  display: flex;
  margin-left: 36px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__content {
    margin-left: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-hokenService .c-box01__content {
    width: 100%;
  }
}
.p-hokenService .c-box01__item {
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 26px 18px 9px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__item {
    padding: 30px 12px 20px;
  }
}
@media screen and (max-width: 374px) {
  .p-hokenService .c-box01__item {
    width: 50%;
  }
}
.p-hokenService .c-box01__item:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__item:not(:last-child) {
    margin-right: 5px;
  }
}
.p-hokenService .c-box01__item .c-box01__itemTitle {
  background: #FFFFFF;
  border-radius: 13px;
  text-align: center;
  padding: 5px 0;
  max-width: 110px;
  margin: -41px auto 12px;
  line-height: initial;
}
.p-hokenService .c-box01__select {
  width: 120px;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  background: #F6FBFE;
  border: 1px solid #DDDDDD;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__select {
    width: 80px;
    position: relative;
    top: 10px;
  }
}
@media screen and (max-width: 374px) {
  .p-hokenService .c-box01__select {
    width: 100%;
  }
}
.p-hokenService .c-box01__select:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 7px solid #BABABA;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__select:after {
    right: 8px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid #BABABA;
  }
}
.p-hokenService .c-box01__select .c-box01__selectLabel {
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 0;
  font-size: 1.6rem;
  font-weight: bold;
  color: #253B73;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__select .c-box01__selectLabel {
    font-size: 1.2rem;
    line-height: 22px;
    height: 34px;
    padding: 0 10px;
  }
}
.p-hokenService .c-box01__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  cursor: pointer;
  width: 100%;
  border: none;
  height: 50px;
  opacity: 0;
  position: relative;
  z-index: 2;
  padding: 0 7px;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__select select {
    height: 34px;
    font-size: 1.3rem;
  }
}
.p-hokenService .c-box01__radio {
  display: flex;
  align-items: center;
}
.p-hokenService .c-box01__radioLabel {
  font-size: 1.4rem !important;
  line-height: 20px !important;
  font-weight: bold;
  color: #2579BE;
  margin-top: 0 !important;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__radioLabel {
    font-size: 1.3rem !important;
    line-height: 18px !important;
    letter-spacing: 0.05em;
  }
}
.p-hokenService .c-box01__radioContent {
  margin-left: 15px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__radioContent {
    margin-left: 10px;
  }
}
.p-hokenService .c-box01__radioItem {
  display: flex;
  align-items: center;
}
.p-hokenService .c-box01__radioItem:not(:last-child) {
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__radioItem:not(:last-child) {
    margin-bottom: 5px;
  }
}
.p-hokenService .c-box01__radioItem input {
  display: none;
}
.p-hokenService .c-box01__radioItem .checkmark {
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: bold;
  color: #253B73;
  background: url(/img/hoken/detail/c-box01_radio_off.png) no-repeat center left;
  background-size: 22px 22px !important;
  padding-left: 27px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__radioItem .checkmark {
    font-size: 1.4rem;
    background-size: 18px 18px !important;
    padding-left: 23px;
  }
}
.p-hokenService .c-box01__radioItem input[type=radio]:checked + label {
  background: url(/img/hoken/detail/c-box01_radio_on.png) no-repeat center left;
}
.p-hokenService .c-box01__btn {
  width: 110px;
  height: 100px;
  background: #2579BE;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__btn {
    width: 66px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 374px) {
  .p-hokenService .c-box01__btn {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    height: 50px;
  }
}
.p-hokenService .c-box01__btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: 0.3s;
}
.p-hokenService .c-box01__btn:hover::before {
  opacity: 0.3;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__btn img {
    width: 40px;
  }
}
.p-hokenService .c-box01__note {
  font-size: 1.2rem;
  color: #9E9E9E;
  margin-top: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-box01__note {
    margin-top: 15px;
  }
}
.p-hokenService .c-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(70, 72, 60, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup {
    padding: 20px;
  }
}
.p-hokenService .c-popup.show {
  opacity: 1;
  visibility: visible;
}
.p-hokenService .c-popup__wrap {
  width: 670px;
}
.p-hokenService .c-popup__close {
  text-align: right;
  margin-bottom: 20px;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__close {
    margin-bottom: 10px;
  }
}
.p-hokenService .c-popup__title {
  background: #2579BE;
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 4px 4px 0 0;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__title {
    font-size: 1.8rem;
    line-height: 26px;
    padding: 8px 10px;
  }
}
.p-hokenService .c-popup__content {
  background: #fff;
  border-radius: 0 0 4px 4px;
  padding: 60px 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__content {
    padding: 30px;
  }
}
.p-hokenService .c-popup__item {
  display: flex;
}
.p-hokenService .c-popup__item:not(:last-child) {
  margin-bottom: 37px;
}
.p-hokenService .c-popup__label {
  min-width: 110px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__label {
    min-width: 82px;
  }
}
.p-hokenService .c-popup__label span {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  background: #2579BE;
  border-radius: 4px;
  padding: 4px 6px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__label span {
    font-size: 1.4rem;
    line-height: 22px;
  }
}
.p-hokenService .c-popup__text {
  flex: 1;
  font-size: 2rem;
  font-weight: bold;
  color: #46483C;
  line-height: 24px;
  padding-left: 30px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__text {
    padding-left: 20px;
    font-size: 1.8rem;
    line-height: 22px;
  }
}
.p-hokenService .c-popup__text.cl-blue {
  color: #2579BE;
}
.p-hokenService .c-popup__text .price {
  font-size: 3rem;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__text .price {
    font-size: 3rem;
  }
}
.p-hokenService .c-popup__text .txt {
  display: block;
  font-size: 1.8rem;
  margin-top: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup__text .txt {
    font-size: 1.8rem;
    line-height: 22px;
  }
}
.p-hokenService .c-popup .c-hokenItemSerect__item__btn {
  margin-top: 37px;
  padding-top: 40px;
  border-top: 1px solid #DDDDDD;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup .c-hokenItemSerect__item__btn {
    margin-top: 24px;
    padding-top: 30px;
  }
}
.p-hokenService .c-popup .c-hokenItemSerect__item__btn .c-hokenItemSerect__item__request {
  margin-top: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup .c-hokenItemSerect__item__btn .c-hokenItemSerect__item__request a {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenService .c-popup .c-hokenItemSerect__item__btn .c-hokenItemSerect__item__request:first-child {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 641px) {
  .p-hokenService-02 {
    display: flex;
    column-gap: 10px;
    align-items: baseline;
  }
}
.p-hokenService-02 dt {
  color: #333;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenService-02 dt {
    font-size: 1.6rem;
  }
}
.p-hokenService-02 dd {
  margin-top: 5px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
@media screen and (min-width: 641px) {
  .p-hokenService-02 dd {
    gap: 10px;
  }
}

.p-hokenService-03__box {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 840px;
  height: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/*コンテンツ
---------------------------------------------------------------------------*/
#contents {
  max-width: 840px;
  font-size: 16px;
  line-height: 1.7;
  color: #46483d;
}

/*リンク
---------------------------------------------------------------------------*/
#contents a {
  color: #336699; /*リンクテキストの色*/
}

#contents a:visited {
  color: #7B517B;
}

#contents a:hover {
  text-decoration: none;
}

/*見出し
---------------------------------------------------------------------------*/
#contents h2 {
  margin-top: 40px;
  margin-bottom: 15px;
  color: #fff;
  padding: 8px 20px;
  background: #82cdf4;
  border-radius: 4px;
  font-size: 20px;
}
@media screen and (max-width: 640px) {
  #contents h2 {
    font-size: 1.8rem;
  }
}

/*汎用
---------------------------------------------------------------------------*/
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.txtbld {
  font-weight: bold;
}

.txt-attention {
  color: #F00;
}

.m-top-5 {
  margin-top: 5px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-bottom-5 {
  margin-bottom: 5px;
}

.m-bottom-6 {
  margin-bottom: 6px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.m-bottom-15 {
  margin-bottom: 15px;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.notes01 {
  padding-left: 1em;
  text-indent: -1em;
  font-size: 14px;
  line-height: 1.5;
}
@media screen and (max-width: 640px) {
  .notes01 {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

.notes02 {
  font-size: 14px;
  padding-left: 25px;
  background: url(../img/hoken/ico_caution.gif) no-repeat left top;
  margin-top: 5px;
}
@media screen and (max-width: 640px) {
  .notes02 {
    font-size: 1.2rem;
  }
}

.caution {
  font-weight: bold;
  color: #D8347B;
}

#contents sup {
  font-style: normal;
  font-size: 70%;
  height: 0;
  line-height: 1;
  vertical-align: top;
  _vertical-align: top;
  position: relative;
  top: 0.5em;
}

/*商品ページ簡易版テンプレート
---------------------------------------------------------------------------*/
.ribbon {
  display: inline-block;
  position: relative;
  height: 60px; /*高さ*/
  line-height: 60px; /*高さ*/
  vertical-align: middle;
  text-align: center;
  padding: 0 40px 0 18px; /*文字の左右の余白*/
  font-size: 18px; /*文字サイズ*/
  background: #DD0729; /*背景色*/
  color: #FFF; /*文字色*/
  box-sizing: border-box;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .ribbon {
    font-size: 1.6rem;
  }
}

.ribbon:after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  z-index: 1;
}

.ribbon:after {
  top: 0;
  right: 0;
  border-width: 30px 15px 30px 0px;
  border-color: transparent #fff transparent transparent;
  border-style: solid;
}

.frame {
  background-color: #FFF;
  border-radius: 6px;
  color: #DD0729;
  padding: 0.5em;
  margin-right: 1em;
}

.figure {
  font-size: 38px;
  vertical-align: middle;
}

.shudan-wrap {
  border: 2px solid #E83743;
  border-radius: 10px;
  margin-bottom: 30px;
}

.shudan-wrap .shudan-ttl,
.shudan-wrap .txt {
  display: table-cell;
  vertical-align: middle;
}

.shudan-wrap .txt {
  padding: 4px 15px 4px 24px;
}

.shudan-ttl {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 140px;
  background: #E83743;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.shudan-ttl:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -15px;
  border: 15px solid transparent;
  border-left: 15px solid #E83743;
}

.shudan-ttl img {
  margin: 0 auto;
  display: block;
}

/*.shudan02-wrap {
	position: relative;
 	margin: -15px auto 30px;
 	padding: 2.5em 1em 1em;
 	background-color: #FFEBE9;
}
.shudan02-ttl {
 	position: absolute;
	top: 8px;
 	left: 15px;
 	background-color: #FFEBE9;
 	color: #E83743;
 	font-size: 1.1em;
 	font-weight: bold;
}*/
.point-wrap {
  overflow: hidden;
  _zoom: 1;
  border: 2px solid #ddd;
  padding: 10px;
  margin-top: 13px;
}

.point-img {
  padding-right: 15px;
  float: left;
}

.point-txt {
  overflow: hidden;
  _zoom: 1;
}

.cv-area {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
}
@media screen and (max-width: 640px) {
  .cv-area {
    font-size: 1.2rem;
  }
}

.linearrow {
  position: relative;
}

.linearrow img {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding-top: 24px;
}

.linearrow:before {
  position: absolute;
  top: 50%;
  z-index: 1;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #644141;
}

.cv-txt {
  text-align: center;
  margin: 30px 0 0;
}

.point-sp,
.shudan-ttl-sp {
  display: none;
}

.c-hoken_txt01 {
  font-size: 2rem;
}
@media screen and (max-width: 640px) {
  .c-hoken_txt01 {
    font-size: 1.8rem;
  }
}

.c-hoken_txt02 {
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .c-hoken_txt02 {
    font-size: 1.4rem;
  }
}

.c-hoken_txt03 {
  font-size: 1.4rem;
}
@media screen and (max-width: 640px) {
  .c-hoken_txt03 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 640px) {
  .point-img,
  .shudan-wrap .shudan-ttl {
    display: none;
  }
  .point-sp {
    display: block;
    padding: 0.3em 0.75em;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 5px;
    color: #fff;
    font-weight: bold;
  }
  .shudan-ttl-sp {
    display: block;
    color: #E83743;
    font-weight: bold;
    text-align: center;
  }
  .ribbon {
    line-height: 1.2;
  }
  #contents {
    font-size: 1.4rem;
    line-height: 1.65;
  }
  #contents input {
    width: 100%;
  }
  #contents label {
    width: 100%;
  }
}
.p-hokenService-04 {
  margin-top: 40px;
}
.p-hokenService-04__table {
  width: 100%;
  border-collapse: collapse;
}
.p-hokenService-04__table th, .p-hokenService-04__table td {
  padding: 15px 30px 13px;
  vertical-align: middle;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  font-size: 1.6rem;
  line-height: 1.6;
}
.p-hokenService-04__table th {
  width: 240px;
  text-align: left;
  font-weight: bold;
  color: #253b73;
  background-color: #e6f5fd;
}
.p-hokenService-04__table td .p-hokenService-04__table__midashi {
  font-size: 1.8rem;
  font-weight: bold;
  color: #000;
}
.p-hokenService-04__table td .p-hokenService-04__table__name {
  font-weight: bold;
  color: #000;
}
.p-hokenService-04__table td span {
  color: #999999;
}
.p-hokenService-04__table td dl dt {
  margin-top: 4px;
  padding-left: 12px;
  font-size: 1.6rem;
  font-weight: bold;
  background: url("../img/hoken/service_dot.png") no-repeat left center;
}
.p-hokenService-04__table td dl dt span {
  color: #999999;
}
.p-hokenService-04__table td dl dd {
  padding-left: 15px;
}
.p-hokenService-04__table td dl dd span {
  color: #999999;
}
.p-hokenService-04 .p-hokenService-04__number {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #999999;
}

@media screen and (max-width: 640px) {
  .page-hoken__service .c-hokenItemSerect__item__request {
    margin-top: 16px;
  }
  .page-hoken__service .c-hokenItemSerect__item__request a {
    display: block;
    position: relative;
    width: 75%;
    height: auto;
    padding: 10px 34px 8px;
    font-weight: bold;
    line-height: 1.2;
  }
  .page-hoken__service .c-hokenItemSerect__item__request a::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    margin: 0;
  }
  .p-hokenService-03 .c-hokenTitle02 {
    margin-bottom: 21px;
    font-size: 1.8rem;
  }
  .p-hokenService-04__table {
    border: 1px solid #dddddd;
    border-top: none;
  }
  .p-hokenService-04__table tr, .p-hokenService-04__table th, .p-hokenService-04__table td {
    display: block;
    width: 100%;
  }
  .p-hokenService-04__table th, .p-hokenService-04__table td {
    font-size: 1.4rem;
  }
  .p-hokenService-04__table th {
    border-bottom: none;
    padding: 12px 15px 9px;
  }
  .p-hokenService-04__table td {
    padding: 8px 15px 11px;
    border: none;
  }
  .p-hokenService-04__table td .p-hokenService-04__table__midashi {
    font-size: 1.5rem;
  }
  .p-hokenService-04__table td dl dt {
    margin-top: 3px;
    font-size: 1.4rem;
  }
  .p-hokenService-04__table td dl dd {
    padding-left: 15px;
  }
  .p-hokenService-04 .p-hokenService-04__number {
    margin-top: 12px;
    font-size: 0.9rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険 コープライフサービスの無料保険相談
------------------------------------------------------------*/
.p-hokenConsultation01__list {
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation01__list {
    padding: 30px;
    border-radius: 10px;
    border: 3px solid #888;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
.p-hokenConsultation01__list__icon {
  min-width: 25px;
  width: 25px;
  height: 25px;
  color: #888;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation01__list__icon {
    min-width: 50px;
    width: 50px;
    height: 50px;
  }
}
.p-hokenConsultation01__list__item {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  color: #333;
  font-size: 1.4rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation01__list__item {
    padding: 0;
    flex-direction: column;
    text-align: center;
    row-gap: 20px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenConsultation01__list :not(:first-child) {
    border-top: 1px solid #D9D9D9;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation01__list :not(:first-child) {
    border-left: 1px solid #D9D9D9;
  }
}
.p-hokenConsultation01__text01 {
  margin-top: 14px;
  color: #41B4E6;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation01__text01 {
    margin-top: 40px;
    font-size: 1.6rem;
  }
}
.p-hokenConsultation01__text02 {
  margin-top: 2px;
  color: #222;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation01__text02 {
    margin-top: 10px;
    font-size: 1.8rem;
  }
}
.p-hokenConsultation01__text03 {
  margin-top: 14px;
  color: #333;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation01__text03 {
    font-size: 1.6rem;
    text-align: center;
  }
}

.p-hokenConsultation02__list {
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #41B4E6;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation02__list {
    padding: 30px 10px;
    border-radius: 10px;
    border: 3px solid #41B4E6;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
.p-hokenConsultation02__list__icon {
  min-width: 25px;
  width: 25px;
  height: 25px;
  color: #41B4E6;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation02__list__icon {
    min-width: 50px;
    width: 50px;
    height: 50px;
  }
}
.p-hokenConsultation02__list__item {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  column-gap: 15px;
  color: #333;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation02__list__item {
    padding: 0 20px;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenConsultation02__list__item:not(:first-of-type) {
    border-top: 1px solid #D9D9D9;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation02__list__item:not(:first-of-type) {
    border-left: 1px solid #D9D9D9;
  }
}
.p-hokenConsultation02__list__item h3 {
  margin-bottom: 5px;
  font-weight: 700;
  text-align: center;
}

.p-hokenConsultation03 {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #E4F7FF;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation03 {
    margin-top: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.p-hokenConsultation03__tabs {
  margin-top: 20px;
  display: flex;
  column-gap: 1.5px;
}
.p-hokenConsultation03__tabs li {
  flex: 1;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation03__tabs {
    column-gap: 3px;
  }
}
.p-hokenConsultation03__tab {
  padding-top: 7.5px;
  width: 100%;
  background-color: #41B4E6;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  border-top: 2.5px solid #41B4E6;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation03__tab:hover {
    transition: 0.3s ease-in-out;
    opacity: 0.8;
  }
}
.p-hokenConsultation03__tab[data-active=true] {
  background-color: #fff;
  color: #41B4E6;
}
.p-hokenConsultation03__tab[data-active=true] .p-hokenConsultation03__tab__1 svg {
  color: #41B4E6;
}
.p-hokenConsultation03__tab__1 svg {
  width: 15px;
  height: 15px;
  color: #fff;
}
.p-hokenConsultation03__tab__2 {
  font-size: 1.4rem;
  line-height: 1.5;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation03__tab__2 {
    margin-top: 0;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation03__tab {
    padding-top: 15px;
    padding-bottom: 17px;
    border-radius: 10px 10px 0 0;
    border-top-width: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }
}
@media screen and (min-width: 641px) and (min-width: 641px) {
  .p-hokenConsultation03__tab__1 svg {
    width: 30px;
    height: 30px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation03__tab__2 {
    font-size: 1.8rem;
  }
}
.p-hokenConsultation03__content {
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation03__content {
    padding: 50px 40px;
    border-radius: 0 0 10px 10px;
    font-size: 1.6rem;
  }
}
.p-hokenConsultation03__content[data-active=true] {
  display: block;
}
.p-hokenConsultation03__content[data-active=false] {
  display: none;
}

@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__merit {
    display: grid;
    grid-template-columns: 545px 1fr;
    align-items: center;
    column-gap: 30px;
  }
}
.p-hokenConsultation__flow__images {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__images {
    row-gap: 20px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__images__note {
    order: 2;
  }
}
.p-hokenConsultation__flow__images__image img {
  width: 100%;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__images__image {
    order: 1;
  }
}
.p-hokenConsultation__flow__list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__list {
    margin-top: 0;
    row-gap: 10px;
  }
}
.p-hokenConsultation__flow__list li {
  display: flex;
  column-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__list li {
    column-gap: 10px;
  }
}
.p-hokenConsultation__flow__list li::before {
  content: "";
  min-width: 6px;
  width: 6px;
  height: 6px;
  background-color: #41B4E6;
  border-radius: 9999px;
  position: relative;
  top: 0.5em;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__list li::before {
    min-width: 8px;
    width: 8px;
    height: 8px;
  }
}
.p-hokenConsultation__flow__title {
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__title {
    margin-top: 80px;
    margin-bottom: 10px;
    font-size: 2rem;
  }
}
.p-hokenConsultation__flow__steps {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__steps {
    row-gap: 10px;
  }
}
.p-hokenConsultation__flow__step {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__step {
    padding: 17px;
    border-width: 3px;
    border-radius: 10px;
    flex-direction: row;
    column-gap: 20px;
    align-items: baseline;
  }
}
.p-hokenConsultation__flow__step__no {
  padding: 5px;
  width: fit-content;
  background-color: #E4F7FF;
  border-radius: 5px;
  color: #41B4E6;
  font-size: 1.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__step__no {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.4rem;
  }
}
.p-hokenConsultation__flow__step__online05 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__step__online05 {
    row-gap: 20px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__step__online05 img {
    width: 360px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenConsultation__flow__step__note {
    font-size: 1.2rem;
  }
}
.p-hokenConsultation__flow__stepArrow {
  display: flex;
  justify-content: center;
}
.p-hokenConsultation__flow__stepArrow svg {
  width: 15px;
  height: 15px;
  color: #D9D9D9;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__stepArrow svg {
    width: 20px;
    height: 20px;
  }
}
.p-hokenConsultation__flow__notes__read {
  font-size: 1.2rem;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__notes__read {
    font-size: 1.4rem;
  }
}
.p-hokenConsultation__flow__notes__list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__notes__list {
    margin-top: 10px;
    row-gap: 10px;
  }
}
.p-hokenConsultation__flow__notes__list > li {
  display: flex;
  column-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__notes__list > li {
    column-gap: 10px;
  }
}
.p-hokenConsultation__flow__notes__list > li::before {
  content: "";
  min-width: 6px;
  width: 6px;
  height: 6px;
  background-color: #41B4E6;
  border-radius: 9999px;
  position: relative;
  top: 0.5em;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__notes__list > li::before {
    min-width: 8px;
    width: 8px;
    height: 8px;
  }
}
.p-hokenConsultation__flow__notes__online {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__notes__online {
    row-gap: 10px;
  }
}
.p-hokenConsultation__flow__notes__online ul {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__notes__online ul {
    row-gap: 3px;
  }
}
.p-hokenConsultation__flow__notes__online ul > li {
  display: flex;
  align-items: baseline;
}
.p-hokenConsultation__flow__notes__online ul > li::before {
  content: "・";
}
.p-hokenConsultation__flow__notes__online p {
  margin-bottom: -5px;
}
@media screen and (min-width: 641px) {
  .p-hokenConsultation__flow__notes__online p {
    margin-bottom: -10px;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険加入のメリット
------------------------------------------------------------*/
.p-hokenMerit01__list__title {
  margin-bottom: 20px;
  font-size: 1.9rem;
}
@media screen and (min-width: 641px) {
  .p-hokenMerit01__list__title {
    font-size: 2.4rem;
  }
}

.p-hokenMerit03__item__avatar img {
  width: 53px;
  height: 53px;
}
@media screen and (min-width: 641px) {
  .p-hokenMerit03__item__avatar img {
    width: 68px;
    height: 68px;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-hokenFaq__box {
  border-radius: 5px;
  border: 1.5px solid #D9D9D9;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box {
    border-radius: 10px;
    border-width: 3px;
  }
}
.p-hokenFaq__box + .p-hokenFaq__box {
  margin-top: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box + .p-hokenFaq__box {
    margin-top: 40px;
  }
}
.p-hokenFaq__box__q {
  padding: 16px 15px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box__q {
    padding: 30px;
    column-gap: 20px;
    transition: 0.3s ease-in-out;
  }
  .p-hokenFaq__box__q:hover {
    color: #41B4E6;
  }
}
.p-hokenFaq__box__q__1 {
  color: #41B4E6;
  font-size: 1.9rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box__q__1 {
    font-size: 2.4rem;
  }
}
.p-hokenFaq__box__q__2 {
  flex: 1;
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box__q__2 {
    font-size: 1.8rem;
  }
}
.p-hokenFaq__box__q__icon {
  min-width: 10px;
  width: 10px;
  height: 10px;
  color: #41B4E6;
}
.p-hokenFaq__box__q__icon svg {
  fill: currentColor;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box__q__icon {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}
.p-hokenFaq__box__q__icon__plus {
  width: 100%;
  height: 100%;
}
.p-hokenFaq__box__q__icon__minus {
  width: 100%;
  height: 100%;
  display: none;
}
.p-hokenFaq__box__a {
  display: none;
}
.p-hokenFaq__box__a__inner {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  column-gap: 10px;
  border-top: 1.5px dashed #D9D9D9;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box__a__inner {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    column-gap: 20px;
    border-top-width: 3px;
  }
}
.p-hokenFaq__box__a__1 {
  color: #FA8246;
  font-size: 1.9rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box__a__1 {
    font-size: 2.4rem;
  }
}
.p-hokenFaq__box__a__2 {
  flex: 1;
  color: #222;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__box__a__2 {
    font-size: 1.6rem;
  }
}
.p-hokenFaq__box__a a {
  color: #253b73;
  text-decoration: underline;
}
.p-hokenFaq__box.is-open .p-hokenFaq__box__q__icon__plus {
  display: none;
}
.p-hokenFaq__box.is-open .p-hokenFaq__box__q__icon__minus {
  display: block;
}
.p-hokenFaq__box .note {
  color: #46483c;
  display: block;
  font-size: 1.3rem;
  text-indent: -1rem;
  padding-left: 1rem;
}
.p-hokenFaq__box .note--large {
  display: block;
  color: #46483c;
  font-size: 1.6rem;
  text-indent: -1.6rem;
  padding-left: 1.6rem;
  margin-top: 25px;
}
.p-hokenFaq__box .note--large.style1 {
  margin-top: 0;
  margin-left: 8rem;
}
.p-hokenFaq__note {
  margin-top: 20px;
  color: #888;
  font-size: 1.2rem;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq__note {
    margin-top: 40px;
    font-size: 1.4rem;
  }
}

.p-hokenFaq02 {
  margin-top: 40px;
  padding: 15px;
  border-radius: 5px;
  border: 1.5px solid #D9D9D9;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq02 {
    margin-top: 80px;
    padding: 30px;
    border-radius: 10px;
    border-width: 3px;
    font-size: 1.6rem;
  }
}
.p-hokenFaq02__title {
  margin-bottom: 15px;
  font-size: 2.2rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq02__title {
    margin-bottom: 30px;
    font-size: 3.2rem;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenFaq02__body {
    display: grid;
    grid-template-columns: 450px 1fr;
    column-gap: 30px;
    align-items: center;
  }
}
.p-hokenFaq02__text {
  color: #222;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq02__button .c-hokenBtn09 {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
  }
}

.p-hokenFaq03 {
  margin-top: 40px;
  padding: 15px;
  border-radius: 5px;
  border: 1.5px solid #D9D9D9;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq03 {
    margin-top: 80px;
    padding: 30px;
    border-radius: 10px;
    border-width: 3px;
    font-size: 1.6rem;
  }
}
.p-hokenFaq03__title {
  margin-bottom: 15px;
  font-size: 2.2rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq03__title {
    margin-bottom: 30px;
    font-size: 3.2rem;
  }
}
.p-hokenFaq03__text {
  color: #222;
}
.p-hokenFaq03__list {
  display: grid;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenFaq03__list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 10px;
  }
}

.p-hokenFaqReturnTop {
  margin-top: 40px;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqReturnTop {
    margin-top: 80px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenFaqReturnTop .c-hokenBtn09 {
    margin-left: auto;
    margin-right: auto;
    width: 590px;
  }
}

.p-hokenFaqProductContact {
  padding: 15px;
  border-radius: 5px;
  border: 1.5px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqProductContact {
    padding: 30px;
    row-gap: 10px;
    border-radius: 10px;
    border: 3px solid #FA8246;
  }
}
.p-hokenFaqProductContact__title {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqProductContact__title {
    font-size: 2.4rem;
  }
}
.p-hokenFaqProductContact__title span {
  color: #FA8246;
}
.p-hokenFaqProductContact__text {
  color: #222;
  font-size: 1.4rem;
  text-align: center;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqProductContact__text {
    font-size: 1.6rem;
    font-weight: 700;
  }
}
.p-hokenFaqProductContact__link {
  padding: 12px 20px;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  border: 1.5px solid #FA8246;
  border-radius: 9999px;
  font-size: 1.6rem;
  font-weight: bold;
  justify-content: center;
  color: #FA8246;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqProductContact__link {
    padding: 0;
    min-height: auto;
    border: none;
    font-size: 2.4rem;
    column-gap: 10px;
    white-space: nowrap;
  }
}
.p-hokenFaqProductContact__link__label {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqProductContact__link__label {
    padding-left: 0;
    padding-right: 0;
    column-gap: 10px;
  }
}
.p-hokenFaqProductContact__link__label svg {
  min-width: 17px;
  width: 17px;
  height: 17px;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqProductContact__link__label svg {
    min-width: 34px;
    width: 34px;
    height: 34px;
  }
}
.p-hokenFaqProductContact__link__icon {
  min-width: 12px;
  width: 12px;
  height: 12px;
}
@media screen and (min-width: 641px) {
  .p-hokenFaqProductContact__link__icon {
    display: none;
  }
}

/*------------------------------------------------------------
保険 ライフステージ別おすすめ保険
------------------------------------------------------------*/
.p-hokenLifeevent01 {
  margin-left: auto;
  margin-right: auto;
  max-width: 325px;
  position: relative;
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01 {
    max-width: none;
  }
}
.p-hokenLifeevent01__bg {
  display: grid;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01__bg {
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
  }
}
.p-hokenLifeevent01__bg > div {
  background-color: #eee;
  height: 65px;
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01__bg > div {
    height: 322px;
  }
}
.p-hokenLifeevent01__content01 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.p-hokenLifeevent01__content01 ul {
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01__content01 ul {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
  }
}
.p-hokenLifeevent01__content01 a {
  padding-left: 15px;
  padding-right: 15px;
  width: 96px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1.5px solid #41B4E6;
  border-radius: 9999px;
  color: #41B4E6;
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01__content01 a {
    padding-left: 30px;
    padding-right: 30px;
    width: 192px;
    height: 70px;
    border-width: 3px;
    font-size: 20px;
  }
  .p-hokenLifeevent01__content01 a:hover {
    background-color: #41B4E6;
    color: #fff;
  }
}
.p-hokenLifeevent01__content01 a svg {
  min-width: 15px;
  width: 15px;
  height: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01__content01 a svg {
    min-width: 17px;
    width: 17px;
    height: 17px;
  }
}
.p-hokenLifeevent01__content02 {
  position: absolute;
}
@media screen and (max-width: 640px) {
  .p-hokenLifeevent01__content02 {
    top: 30px;
    left: 115px;
  }
  .p-hokenLifeevent01__content02 ul {
    display: grid;
    row-gap: 3px;
  }
  .p-hokenLifeevent01__content02 ul li {
    padding-top: 12px;
    width: 119px;
    height: 43px;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.4;
  }
  .p-hokenLifeevent01__content02 ul li::before, .p-hokenLifeevent01__content02 ul li::after {
    content: "";
    width: 50%;
    height: 100%;
    background-color: #41B4E6;
    position: absolute;
    top: 5px;
    pointer-events: none;
  }
  .p-hokenLifeevent01__content02 ul li::before {
    left: 0;
    transform: skewY(8deg);
  }
  .p-hokenLifeevent01__content02 ul li::after {
    right: 0;
    transform: skewY(-8deg);
  }
  .p-hokenLifeevent01__content02 ul li > span {
    position: relative;
    z-index: 1;
  }
  .p-hokenLifeevent01__content02 ul li > :nth-child(1) {
    font-size: 14px;
  }
  .p-hokenLifeevent01__content02 ul li > :nth-child(2) {
    color: #E4F7FF;
    font-size: 10px;
  }
  .p-hokenLifeevent01__content02 ul > :nth-child(1) {
    padding-top: 6px;
    height: 37px;
    background-color: #41B4E6;
  }
  .p-hokenLifeevent01__content02 ul > :nth-child(5) {
    margin-top: 35px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01__content02 {
    padding-left: 20px;
    padding-right: 28px;
    width: 100%;
    top: 120px;
    left: 0;
  }
  .p-hokenLifeevent01__content02 ul {
    display: flex;
    column-gap: 10px;
  }
  .p-hokenLifeevent01__content02 ul li {
    width: 150px;
    height: 80px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
    text-align: center;
    position: relative;
  }
  .p-hokenLifeevent01__content02 ul li::before, .p-hokenLifeevent01__content02 ul li::after {
    content: "";
    width: 100%;
    height: 50%;
    background-color: #41B4E6;
    position: absolute;
    left: 5px;
    pointer-events: none;
  }
  .p-hokenLifeevent01__content02 ul li::before {
    top: 0;
    transform: skewX(12deg);
  }
  .p-hokenLifeevent01__content02 ul li::after {
    bottom: 0;
    transform: skewX(-12deg);
  }
  .p-hokenLifeevent01__content02 ul li > span {
    position: relative;
    z-index: 1;
  }
  .p-hokenLifeevent01__content02 ul li > :nth-child(1) {
    font-size: 18px;
  }
  .p-hokenLifeevent01__content02 ul li > :nth-child(2) {
    color: #E4F7FF;
    font-size: 14px;
  }
  .p-hokenLifeevent01__content02 ul > :nth-child(1) {
    width: 142px;
    background-color: #41B4E6;
  }
  .p-hokenLifeevent01__content02 ul > :nth-child(4) {
    width: 285px;
  }
  .p-hokenLifeevent01__content02 ul > :nth-child(5) {
    margin-left: auto;
    width: 280px;
  }
}
.p-hokenLifeevent01__content03 {
  position: absolute;
}
.p-hokenLifeevent01__content03 li {
  background-color: #FA8246;
  color: #fff;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .p-hokenLifeevent01__content03 {
    top: 90px;
    right: 8px;
  }
  .p-hokenLifeevent01__content03 ul {
    display: grid;
    row-gap: 5px;
  }
  .p-hokenLifeevent01__content03 li {
    padding: 5px 10px;
    width: 75px;
    font-size: 10px;
  }
  .p-hokenLifeevent01__content03 li svg {
    min-width: 15px;
    width: 15px;
    height: 15px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLifeevent01__content03 {
    top: 215px;
    left: 174px;
  }
  .p-hokenLifeevent01__content03 ul {
    display: flex;
  }
  .p-hokenLifeevent01__content03 ul li {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    font-size: 14px;
  }
  .p-hokenLifeevent01__content03 ul li svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
  .p-hokenLifeevent01__content03 ul > :nth-child(1) {
    width: 112px;
  }
  .p-hokenLifeevent01__content03 ul > :nth-child(2) {
    margin-left: 24px;
    width: 168px;
  }
  .p-hokenLifeevent01__content03 ul > :nth-child(3) {
    margin-left: 216px;
    width: 140px;
  }
  .p-hokenLifeevent01__content03 ul > :nth-child(4) {
    margin-left: 24px;
    width: 168px;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険 保険見直しのタイミング
------------------------------------------------------------*/
.p-hokenTimingEvent {
  padding: 20px;
  border: 1.5px solid #D9D9D9;
  border-radius: 5px;
  position: relative;
}
.p-hokenTimingEvent .c-hokenAnchor {
  position: absolute;
}
@media screen and (min-width: 641px) {
  .p-hokenTimingEvent {
    padding: 40px;
    border-width: 3px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 30px;
  }
}
.p-hokenTimingEvent__title {
  font-size: 1.9rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenTimingEvent__title {
    font-size: 2.4rem;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
}
.p-hokenTimingEvent__image {
  margin-top: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenTimingEvent__image {
    margin-top: 0;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
.p-hokenTimingEvent__image img {
  width: 100%;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenTimingEvent__image img {
    border-radius: 10px;
  }
}
.p-hokenTimingEvent__text {
  margin-top: 15px;
  color: #222;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenTimingEvent__text {
    flex: 1;
    font-size: 1.6rem;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
ご契約者の方へ（問い合わせ一覧）
------------------------------------------------------------*/
.p-hokenContact02 .c-hokenTitle02 {
  padding-left: 26px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-hokenContact02 .c-hokenTitle02 {
    padding-left: 15px;
    margin-bottom: 20px;
  }
}
.p-hokenContact02__list {
  display: grid;
  row-gap: 20px;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}
.p-hokenContact02__list2 {
  display: grid;
  row-gap: 20px;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__list2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}
.p-hokenContact02__card {
  padding: 15px;
  border: 1.5px solid #FA8246;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card {
    padding: 30px;
    height: 100%;
    border-width: 3px;
    border-radius: 10px;
    row-gap: 10px;
  }
}
.p-hokenContact02__card__ttl {
  padding-bottom: 5px;
  border-bottom: 1px solid #FA8246;
  font-size: 1.9rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__ttl {
    font-size: 2.4rem;
  }
}
.p-hokenContact02__card__ttl02 {
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__ttl02 {
    font-size: 1.6rem;
  }
}
.p-hokenContact02__card__ttl02::before {
  content: "";
  min-width: 6px;
  width: 6px;
  height: 6px;
  background-color: #41B4E6;
  border-radius: 50%;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__ttl02::before {
    min-width: 8px;
    width: 8px;
    height: 8px;
    font-size: 1.6rem;
  }
}
.p-hokenContact02__card__ttl03 {
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__ttl03 {
    font-size: 1.6rem;
  }
}
.p-hokenContact02__card__ttl03::before {
  content: "";
  min-width: 6px;
  width: 6px;
  height: 6px;
  background-color: #FA8246;
  border-radius: 50%;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__ttl03::before {
    min-width: 8px;
    width: 8px;
    height: 8px;
    font-size: 1.6rem;
  }
}
.p-hokenContact02__card__txt {
  color: #222;
  font-size: 1.4rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__txt {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenContact02__card__tel a {
    padding-left: 15px;
    padding-right: 15px;
    width: fit-content;
    min-height: 47.5px;
    display: flex;
    column-gap: 7.5px;
    align-items: center;
    background-color: #FA8246;
    border-radius: 9999px;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
  }
  .p-hokenContact02__card__tel a svg {
    min-width: 25px;
    width: 25px;
    height: 25px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__tel a {
    width: fit-content;
    display: flex;
    column-gap: 15px;
    align-items: center;
    color: #FA8246;
    font-size: 3.2rem;
    font-weight: 700;
  }
  .p-hokenContact02__card__tel a svg {
    min-width: 35px;
    width: 35px;
    height: 35px;
  }
}
.p-hokenContact02__card__telTime {
  color: #222;
  font-size: 1.4rem;
}
.p-hokenContact02__card__web {
  color: #222;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenContact02__card__web {
    font-size: 1.6rem;
  }
}
.p-hokenContact02__card__web a {
  width: fit-content;
  display: flex;
  align-items: baseline;
  color: #41B4E6;
}
.p-hokenContact02__card__web a > span {
  text-decoration: underline;
}
.p-hokenContact02__card__web a:before {
  content: "▶";
}
.p-hokenContact02 #contact01 .p-hokenContact02__card__tel p:nth-child(1) {
  font-size: 3.2rem;
  letter-spacing: 0;
}
.p-hokenContact02 #contact01 .p-hokenContact02__card__tel p:nth-child(1) span {
  font-size: 1.4rem;
  margin-right: 38px;
}
.p-hokenContact02 #contact01 .p-hokenContact02__card__tel p:nth-child(1) span:after {
  width: 24px;
  height: 24px;
  background-size: contain;
  top: -9px;
  right: -29px;
}

/*------------------------------------------------------------
取扱商品一覧
------------------------------------------------------------*/
.p-hokencompany {
  background-color: #ffffff;
}

.p-hokencompany01 {
  margin-bottom: 50px;
}
.p-hokencompany01 .p-hokencompany01__content01 .c-hokenTitle03 {
  margin-bottom: 33px;
  padding: 29px 0 29px 33px;
  letter-spacing: 1.4px;
}
.p-hokencompany01 .p-hokencompany01__content01 .p-hokencompany01__content01__txt {
  color: #46483c;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-hokencompany01 .p-hokencompany01__content01 .p-hokencompany01__content01__txt img {
  width: 317px;
  margin: 5px 0 0 20px;
}

.p-hokencompany02 {
  margin-bottom: 50px;
}
.p-hokencompany02 .c-hokenTitle01 {
  margin-bottom: 14px;
}

.p-hokencompany03 {
  margin-bottom: 100px;
}
@media screen and (max-width: 640px) {
  .p-hokencompany01 {
    margin-bottom: 26px;
  }
  .p-hokencompany01 .p-hokencompany01__content01 .c-hokenTitle03 {
    margin-bottom: 30px;
    padding: 17px 18px 18px;
    line-height: 28px;
  }
  .p-hokencompany01 .p-hokencompany01__content01 .p-hokencompany01__content01__txt {
    padding: 0 20px;
    font-size: 1.4rem;
    line-height: normal;
  }
  .p-hokencompany01 .p-hokencompany01__content01 .p-hokencompany01__content01__txt img {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-hokencompany01 .p-hokencompany01__content01 .c-hokenTitle02 {
    font-size: 1.8rem;
    margin-bottom: 12px;
  }
  .p-hokencompany01 .p-hokencompany01__content01 .c-hokenItemSerect {
    padding: 0 20px;
  }
  .p-hokencompany01 .p-hokencompany01__content01 .c-hokenItemSerect .c-hokenItemCheckAll input[type=checkbox] + span:before {
    width: 14px;
    height: 14px;
    margin: -3px 4px 0 0;
  }
  .p-hokencompany02 {
    margin-bottom: 25px;
  }
  .p-hokencompany02 .p-hokencompany02__content01 {
    padding: 0 20px;
  }
  .p-hokencompany02 .c-hokenTitle01 {
    margin-bottom: 22px;
  }
  .p-hokencompany03 {
    margin-bottom: 51px;
  }
  .p-hokencompany03 .p-hokencompany03__content01 {
    padding: 0 20px;
  }
  .p-hokencompany03 .p-hokencompany03__content01 .c-hokenBtn03 a, .p-hokencompany03 .p-hokencompany03__content01 .c-hokenBtn04 a {
    padding-left: 60px;
    justify-content: left;
    font-weight: bold;
  }
  .p-hokencompany03 .p-hokencompany03__content01 .c-hokenBtn03 a:before, .p-hokencompany03 .p-hokencompany03__content01 .c-hokenBtn04 a:before {
    left: 20px;
    top: 5px;
  }
  .p-hokencompany03 .p-hokencompany03__content01 .c-hokenMoushikomi p a:before {
    margin-right: 4px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
お客様本位の業務運営に関する方針
------------------------------------------------------------*/
.p-hokenCustomer__title02 {
  padding: 10px 15px;
  background-color: #E4F7FF;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__title02 {
    padding: 20px 30px;
    border-radius: 10px;
    font-size: 4rem;
  }
}
.p-hokenCustomer__title03 {
  border-bottom: 1.5px solid #41B4E6;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__title03 {
    border-width: 3px;
    font-size: 3.2rem;
  }
}
.p-hokenCustomer__title04 {
  display: flex;
  align-items: baseline;
  column-gap: 2.5px;
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__title04 {
    column-gap: 5px;
    font-size: 1.8rem;
  }
}
.p-hokenCustomer__title04::before {
  content: "■";
}
.p-hokenCustomer__title05 {
  font-size: 1.9rem;
  color: #41B4E6;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__title05 {
    font-size: 2.4rem;
  }
}
.p-hokenCustomer__txt {
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__txt {
    font-size: 1.6rem;
  }
}
.p-hokenCustomer__note {
  margin-top: 20px;
}
.p-hokenCustomer__note p {
  font-size: 1.4rem;
  color: #46483c;
  line-height: 2;
}
@media screen and (max-width: 640px) {
  .p-hokenCustomer__note p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__list {
    padding-left: 30px;
  }
}
.p-hokenCustomer__list > li {
  display: flex;
  align-items: baseline;
}
.p-hokenCustomer__list02 {
  font-size: 1.6rem;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__list02 {
    font-size: 1.8rem;
  }
}
.p-hokenCustomer__list02 li {
  display: flex;
  align-items: baseline;
  column-gap: 2.5px;
}
.p-hokenCustomer__list02 li:before {
  column-gap: 5px;
  content: counter(count01) ".";
  color: #41B4E6;
  font-weight: bold;
}
.p-hokenCustomer__list03 li {
  padding-left: 11px;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.6;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__list03 li {
    padding-left: 18px;
    font-size: 1.6rem;
  }
}
.p-hokenCustomer__list03 li:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #41B4E6;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__list03 li:before {
    width: 8px;
    height: 8px;
    top: 8px;
  }
}
.p-hokenCustomer__list03 a {
  color: #41B4E6;
  text-decoration: underline;
}
.p-hokenCustomer__box {
  padding: 15px;
  border: 1.5px solid #D9D9D9;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenCustomer__box {
    padding: 30px;
    border-width: 3px;
    border-radius: 10px;
  }
}

/*------------------------------------------------------------
保険販売・勧誘方針
------------------------------------------------------------*/
.p-hokenPolicy {
  padding: 0 0 100px;
}
@media screen and (max-width: 640px) {
  .p-hokenPolicy {
    padding: 85px 20px 70px;
  }
}
.p-hokenPolicy__title {
  margin-bottom: 54px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-hokenPolicy__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-hokenPolicy__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
@media screen and (max-width: 640px) {
  .p-hokenPolicy__title span {
    font-size: 1.2rem;
  }
}
.p-hokenPolicy__title02 {
  padding: 10px 15px;
  background-color: #E4F7FF;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__title02 {
    padding: 20px 30px;
    border-radius: 10px;
    font-size: 4rem;
  }
}
.p-hokenPolicy__title03 {
  border-bottom: 1.5px solid #41B4E6;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__title03 {
    border-width: 3px;
    font-size: 3.2rem;
  }
}
.p-hokenPolicy__title04 {
  font-size: 1.9rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__title04 {
    font-size: 2.4rem;
  }
}
.p-hokenPolicy__title05 {
  padding-bottom: 10px;
  border-bottom: 1px solid #41B4E6;
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__title05 {
    padding-bottom: 20px;
    font-size: 2rem;
  }
}
.p-hokenPolicy__txt {
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__txt {
    font-size: 1.6rem;
  }
}
.p-hokenPolicy__list li {
  padding-left: 11px;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.6;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__list li {
    padding-left: 18px;
    font-size: 1.6rem;
  }
}
.p-hokenPolicy__list li:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #41B4E6;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 0;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__list li:before {
    width: 8px;
    height: 8px;
    top: 8px;
  }
}
.p-hokenPolicy__box {
  padding: 15px;
  border: 1.5px solid #41B4E6;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenPolicy__box {
    padding: 30px;
    border-width: 3px;
    border-radius: 10px;
  }
}

/*------------------------------------------------------------
保険商品のご提案にあたって
------------------------------------------------------------*/
.p-hokenRecommend__title02 {
  padding: 10px 15px;
  background-color: #E4F7FF;
  border-radius: 5px;
  font-size: 2.2rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__title02 {
    padding: 80px 0 0;
    border-radius: 10px;
    font-size: 4rem;
  }
}
.p-hokenRecommend__title03 {
  border-bottom: 1.5px solid #41B4E6;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__title03 {
    border-width: 3px;
    font-size: 3.2rem;
  }
}
.p-hokenRecommend__txt {
  font-size: 1.4rem;
  margin: 20px 0px;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__txt {
    font-size: 1.6rem;
    margin: 20px 0;
  }
}
.p-hokenRecommend__list li {
  font-size: 1.6rem;
  display: flex;
}
.p-hokenRecommend__list li span {
  display: block;
}
@media screen and (max-width: 640px) {
  .p-hokenRecommend__list li {
    font-size: 1.4rem;
  }
}
.p-hokenRecommend__list02 > li {
  font-size: 1.6rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__list02 > li {
    font-size: 1.8rem;
  }
}
.p-hokenRecommend__list02 ol {
  padding-left: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__list02 ol {
    padding-left: 30px;
  }
}
.p-hokenRecommend__list02 ol li {
  display: flex;
  column-gap: 2.5px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__list02 ol li {
    column-gap: 5px;
    font-size: 1.6rem;
  }
}
.p-hokenRecommend__title04 {
  display: flex;
  align-items: baseline;
  column-gap: 2.5px;
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__title04 {
    column-gap: 5px;
    font-size: 1.8rem;
  }
}
.p-hokenRecommend__title04::before {
  content: "■";
}
.p-hokenRecommend__table {
  width: 100%;
  border-collapse: collapse;
  line-height: 1.5;
}
@media screen and (max-width: 640px) {
  .p-hokenRecommend__table {
    border: 1px solid #D9D9D9;
    border-top: none;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__table {
    border-bottom: 1px solid #D9D9D9;
  }
}
.p-hokenRecommend__table th, .p-hokenRecommend__table td {
  padding: 15px 20px;
  text-align: left;
}
@media screen and (max-width: 640px) {
  .p-hokenRecommend__table th, .p-hokenRecommend__table td {
    border-top: 1px solid #D9D9D9;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__table th, .p-hokenRecommend__table td {
    padding: 30px 40px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenRecommend__table tr, .p-hokenRecommend__table th, .p-hokenRecommend__table td {
    display: block;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__table tr {
    border-top: 1px solid #D9D9D9;
  }
}
.p-hokenRecommend__table th {
  background-color: #E4F7FF;
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__table th {
    width: 300px;
    font-size: 1.8rem;
  }
}
.p-hokenRecommend__table td {
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__table td {
    font-size: 1.6rem;
  }
}
.p-hokenRecommend__box {
  padding: 15px;
  border: 1.5px solid #41B4E6;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__box {
    padding: 30px;
    border-width: 3px;
    border-radius: 10px;
  }
}
.p-hokenRecommend__title05 {
  padding-bottom: 10px;
  border-bottom: 1px solid #41B4E6;
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenRecommend__title05 {
    padding-bottom: 20px;
    font-size: 2rem;
  }
}

.recommend-navi {
  grid-template-columns: initial;
  margin: 80px 0px;
}

.recomend-content {
  padding: 50px 60px;
  background: white;
}
@media screen and (max-width: 640px) {
  .recomend-content {
    padding: 20px;
  }
}

.bg-E4F7FF {
  background: #E4F7FF;
}

.f {
  font-size: 1.8rem;
}

.tacenter {
  text-align: center;
}

.pb-100 {
  padding-bottom: 100px;
}

.mb10 {
  margin-bottom: 10px;
}

/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.page-hoken_lp_sandai_sippei {
  position: relative;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  background: #fcfbf5;
  min-width: 1200px;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_sandai_sippei {
    min-width: 320px;
  }
}
.page-hoken_lp_sandai_sippei .l-contentServiceIn {
  display: block;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_sandai_sippei .l-contentService {
    padding-top: 0;
  }
}
.page-hoken_lp_sandai_sippei .pc-only {
  display: block;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_sandai_sippei .pc-only {
    display: none;
  }
}
.page-hoken_lp_sandai_sippei .sp-only {
  display: none;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_sandai_sippei .sp-only {
    display: block;
  }
}

.p-hokenLpSippeiMv {
  background: url("/img/hoken/lp/sandai_sippei/mv.png") no-repeat center;
  background-size: cover;
  padding: 126px 0 50px;
  position: relative;
  overflow-x: hidden;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippeiMv {
    padding: 58px 13px 0 20px;
    background: url("/img/hoken/lp/sandai_sippei/mv-sp.png") no-repeat top center;
    background-size: 100% auto;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippeiMv .l-contentServiceIn {
    padding: 0 !important;
  }
}
.p-hokenLpSippeiMv__txt {
  border-radius: 10px;
  background-image: -moz-linear-gradient(0deg, rgba(34, 172, 56, 0.949) 0%, rgba(143, 195, 31, 0.949) 100%);
  background-image: -webkit-linear-gradient(0deg, rgba(34, 172, 56, 0.949) 0%, rgba(143, 195, 31, 0.949) 100%);
  background-image: linear-gradient(0deg, rgba(34, 172, 56, 0.949) 0%, rgba(143, 195, 31, 0.949) 100%);
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-top: 35px;
  padding: 40px 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippeiMv__txt {
    flex-wrap: wrap;
    margin-top: 37px;
    padding: 20px;
    margin-right: 6px;
  }
}
.p-hokenLpSippeiMv__subttl {
  font-size: 2rem;
  line-height: 1;
  color: #59493f;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(121, 85, 72, 0.2);
  display: inline-block;
  background: #fff;
  padding: 8px 9px 7px 20px;
  text-align: center;
  border-radius: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippeiMv__subttl {
    font-size: 1.5rem;
    padding: 8px 5px 7px 8px;
  }
}
.p-hokenLpSippeiMv__img {
  position: absolute;
  bottom: -42px;
  right: 23px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippeiMv__img {
    bottom: auto;
    top: -15px;
    right: -10px;
    max-width: 180px;
    order: 2;
    margin: -10px -10px 0 0;
    width: 60%;
    z-index: 11;
  }
}
.p-hokenLpSippeiMv__text1 {
  max-width: 529px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippeiMv__text2 {
    padding-top: 73px;
  }
}

@media screen and (max-width: 640px) {
  .p-hokenLpSippei .l-contentServiceIn {
    padding: 0 20px;
  }
}
.p-hokenLpSippei__info {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__info {
    display: block;
    order: 1;
    width: 50%;
    max-width: 148px;
  }
}
.p-hokenLpSippei__txt2 {
  margin-left: 10px;
}
.p-hokenLpSippei__txt3 {
  position: relative;
  margin-left: 22px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__txt3 {
    margin: 57px 0 0;
    order: 3;
    width: 100%;
  }
}
.p-hokenLpSippei__txt4 {
  border-radius: 10px;
  border: 5px solid #e6e4e2;
  padding: 30px 34px 28px;
  margin-top: 35px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__txt4 {
    padding: 21px 21px 12px;
    margin-top: 31px;
  }
}
.p-hokenLpSippei__btn1 {
  font-size: 1.6rem;
  line-height: 1.25;
  color: #22ac38;
  font-weight: bold;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__btn1 {
    font-size: 1rem;
    height: 20px;
    width: 136px;
  }
}
.p-hokenLpSippei__btn2 {
  font-size: 2rem;
  line-height: 1.8;
  color: #fff;
  font-weight: bold;
  width: 480px;
  height: 80px;
  border: 5px solid #fff;
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  background: #ff5b4c;
  display: inline-block;
  text-align: center;
  padding: 18px 0;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__btn2 {
    font-size: 1.4rem;
    width: 100%;
    height: 60px;
    border: 4px solid #fff;
    padding: 15px 0;
  }
}
.p-hokenLpSippei__btn2:after {
  content: "";
  border-style: solid;
  border-width: 6px 0 6px 7px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__btn2:after {
    right: 18px;
  }
}
.p-hokenLpSippei__btn2 span {
  position: relative;
  padding-left: 50px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__btn2 span {
    padding-left: 10px;
  }
}
.p-hokenLpSippei__btn2 span:before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("/img/hoken/lp/sandai_sippei/icon1.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 53%;
  left: 12px;
  margin-top: -3px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__btn2 span:before {
    width: 20px;
    height: 20px;
    left: -14px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLpSippei__btn2:hover {
    color: #ff5b4c;
    border: 5px solid #ff5b4c;
    background: #fff;
    opacity: 1;
  }
  .p-hokenLpSippei__btn2:hover:after {
    border-color: transparent transparent transparent #ff5b4c;
    transition: 0.3s all;
  }
  .p-hokenLpSippei__btn2:hover span:before {
    background: url("/img/hoken/lp/sandai_sippei/icon1_over.png") no-repeat center;
    background-size: cover;
    transition: 0.3s all;
  }
}
.p-hokenLpSippei__text1 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #595950;
}
.p-hokenLpSippei__text1 span {
  color: #ff5b4c;
  font-weight: bold;
}
.p-hokenLpSippei__text1 span.u-colorsp {
  color: #595950;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__text1 span.u-colorsp {
    color: #ff5b4c;
  }
}
.p-hokenLpSippei__text3 {
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__text3 {
    font-size: 1.3rem;
    padding: 5px 22px 0;
    white-space: nowrap;
  }
}
.p-hokenLpSippei__text3 span {
  font-size: 2.4rem;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__text3 span {
    font-size: 2rem;
  }
}
.p-hokenLpSippei__text4 {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__text4 {
    font-size: 2rem;
    margin: 2px 0 0 -8px;
    letter-spacing: -1px;
    white-space: nowrap;
  }
}
.p-hokenLpSippei__text4 span {
  font-size: 4.4rem;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__text4 span {
    font-size: 3.6rem;
  }
}
.p-hokenLpSippei__text5 {
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__text5 {
    font-size: 1rem;
    margin: 0 0 0 10px;
  }
}
.p-hokenLpSippei__label {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #59493f;
  background: #fcf9e8;
  box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
  border-radius: 6px;
  font-weight: bold;
  z-index: 10;
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__label {
    font-size: 1.2rem;
    margin-left: 0;
    top: -40px;
    left: 0;
    transform: translateX(0);
  }
}
.p-hokenLpSippei__label span {
  padding: 10px 0;
  display: inline-block;
  width: 240px;
  height: 40px;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__label span {
    padding: 6px 0;
    display: inline-block;
    width: 170px;
    height: 30px;
  }
}
.p-hokenLpSippei__label span:after {
  content: "";
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #fcf9e8 transparent transparent transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__label span:after {
    border-width: 8px 4px 0 4px;
    bottom: -8px;
  }
}
.p-hokenLpSippei__ttl1 {
  font-size: 4rem;
  line-height: 1;
  color: #59493f;
  font-weight: bold;
  position: relative;
  padding: 72px 0 37px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl1 {
    display: block;
    text-align: center;
    font-size: 3rem;
    line-height: 1.2;
    padding: 45px 0 13px;
  }
}
.p-hokenLpSippei__ttl1--wid {
  width: 100%;
  text-align: center;
}
.p-hokenLpSippei__ttl1:before {
  content: "";
  background: url("/img/hoken/lp/sandai_sippei/icon2.png") no-repeat center;
  background-size: cover;
  width: 39.5px;
  height: 40.5px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl1:before {
    width: 30px;
    height: 29px;
  }
}
.p-hokenLpSippei__ttl1:after {
  content: "";
  background: url("/img/hoken/lp/sandai_sippei/line1.png") no-repeat center;
  background-size: cover;
  width: 120px;
  height: 16px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl1:after {
    width: 88px;
    height: 11px;
  }
}
.p-hokenLpSippei__ttl1--red {
  font-size: 6.8rem;
  color: #ff5b4c;
  font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl1--red {
    font-size: 5.1rem;
    vertical-align: sub;
  }
}
.p-hokenLpSippei__ttl1 img {
  vertical-align: sub;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl1 img {
    max-width: 240.5px;
  }
}
.p-hokenLpSippei__ttl1 i {
  font-style: normal;
}
.p-hokenLpSippei__ttl2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}
.p-hokenLpSippei__ttl3 {
  font-size: 2.4rem;
  line-height: 1.75;
  color: #59493f;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl3 {
    font-size: 1.8rem;
    line-height: 1.5;
    margin-bottom: 8px;
  }
}
.p-hokenLpSippei__ttl3:before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("/img/hoken/lp/sandai_sippei/icon3.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 5px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl3:before {
    top: 0;
  }
}
.p-hokenLpSippei__point {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  border-radius: 4px;
  width: 70px;
  height: 70px;
  background: #22ac38;
  text-align: center;
  padding: 12px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__point {
    font-size: 1rem;
    width: 60px;
    height: 60px;
  }
}
.p-hokenLpSippei__point span {
  font-size: 4rem;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__point span {
    font-size: 3.4rem;
  }
}
.p-hokenLpSippei__ttl2Main {
  font-size: 2.8rem;
  line-height: 4.2rem;
  font-weight: bold;
  color: #59493f;
  padding-left: 16px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__ttl2Main {
    width: calc(100% - 60px);
    font-size: 2rem;
    line-height: 3rem;
  }
}
.p-hokenLpSippei__ttl2Main span {
  color: #ff5b4c;
}
.p-hokenLpSippei__list1 {
  margin-top: 37px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__list1 {
    margin-top: 17px;
  }
}
.p-hokenLpSippei__list1 li {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #595950;
  position: relative;
  padding-left: 14px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__list1 li {
    line-height: 1.8rem;
  }
}
.p-hokenLpSippei__list1 li:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}
.p-hokenLpSippei__list1 li a {
  color: #308bbb;
  text-decoration: underline;
}
.p-hokenLpSippei__list1 li a:hover {
  text-decoration: none;
}
.p-hokenLpSippei__list2 li {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #595950;
  position: relative;
  padding-left: 12px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei__list2 li {
    line-height: 1.8rem;
  }
}
.p-hokenLpSippei__list2 li:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #22ac38;
  position: absolute;
  top: 8px;
  left: 0;
}
.p-hokenLpSippei__list2 li a {
  color: #308bbb;
  text-decoration: underline;
  position: relative;
  margin-right: 15px;
}
.p-hokenLpSippei__list2 li a:after {
  content: "";
  width: 10px;
  height: 10px;
  background: url("/img/hoken/lp/sandai_sippei/icon5.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 3px;
  right: -11px;
}
.p-hokenLpSippei__list2 li a:hover {
  text-decoration: none;
}

.p-hokenLpSippei1 {
  margin-top: 78px;
  overflow-x: hidden;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1 {
    margin-top: 40px;
  }
}
.p-hokenLpSippei1__text1 {
  font-size: 2.8rem;
  font-weight: bold;
  color: #59493f;
  line-height: 1.5;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__text1 {
    font-size: 2rem;
    letter-spacing: -0.3px;
  }
}
.p-hokenLpSippei1__text1 span {
  color: #22ac38;
}
.p-hokenLpSippei1__text2 {
  font-size: 1.7rem;
  line-height: 2.7rem;
  color: #59493f;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__text2 {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.p-hokenLpSippei1__text2 span {
  color: #ff5b4c;
}
.p-hokenLpSippei1__text3 {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #595950;
  margin-top: 20px;
  text-align: left;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__text3 {
    width: 100%;
    margin-top: 18px;
  }
}
.p-hokenLpSippei1__text4 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #595950;
}
.p-hokenLpSippei1__point {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.p-hokenLpSippei1__point span {
  font-size: 3.2rem;
  font-family: "Roboto", sans-serif;
  margin-left: 2px;
}
.p-hokenLpSippei1__list1 {
  background: #e3f0c7;
  border-radius: 6px;
  padding: 40px 40px 35px;
  display: table;
  table-layout: fixed;
  margin-top: 33px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list1 {
    margin-top: 23px;
    padding: 20px 20px 10px;
  }
}
.p-hokenLpSippei1__list1 dl {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 7px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list1 dl {
    margin-bottom: 10px;
    min-height: 60px;
  }
}
.p-hokenLpSippei1__list1 dt, .p-hokenLpSippei1__list1 dd {
  display: table-cell;
  vertical-align: middle;
}
.p-hokenLpSippei1__list1 dt {
  width: 100px;
  position: relative;
  background: #22ac38;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list1 dt {
    width: 34%;
  }
}
.p-hokenLpSippei1__list1 dt:after {
  content: "";
  border-style: solid;
  border-width: 24px 0 24px 10px;
  border-color: transparent transparent transparent #22ac38;
  position: absolute;
  top: 0;
  right: -10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list1 dt:after {
    border-width: 30px 0 30px 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.p-hokenLpSippei1__list1 dt.p-hokenLpSippei1__list1--row2:after {
  content: "";
  border-style: solid;
  border-width: 38px 0 38px 10px;
  border-color: transparent transparent transparent #22ac38;
  position: absolute;
  top: 0;
  right: -10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list1 dt.p-hokenLpSippei1__list1--row2:after {
    border-width: 30px 0 30px 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.p-hokenLpSippei1__list1 dd {
  background: #fff;
  padding: 11px 10px 10px 18px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list1 dd {
    padding: 11px 26px 7px 20px;
    min-height: 60px;
  }
}
.p-hokenLpSippei1__list2 {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 0 20px;
  margin-top: 25px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list2 {
    margin-top: 13px;
  }
}
.p-hokenLpSippei1__list2 li {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px dashed #59493f;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list2 li {
    padding: 15px 0 18px;
  }
}
.p-hokenLpSippei1__list2 li:last-child {
  border-bottom: 0;
}
.p-hokenLpSippei1__number1 {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  width: 60px;
  background: #59493f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 13px 4px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__number1 {
    width: 63px;
  }
}
.p-hokenLpSippei1__list2Text {
  width: calc(100% - 60px);
  font-size: 1.4rem;
  line-height: 2.45rem;
  color: #595950;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__list2Text {
    width: calc(100% - 63px);
  }
}
.p-hokenLpSippei1__imgtxt1 {
  display: flex;
  justify-content: space-between;
  margin: 55px 0 71px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt1 {
    display: block;
    margin: 27px 0 40px;
  }
}
.p-hokenLpSippei1__imgtxt2 {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 36px 40px 43px;
  margin: -15px 0 34px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2 {
    flex-wrap: wrap;
    margin: 0 0 18px;
    padding: 19px 20px 23px;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt2 {
    padding: 19px 20px 23px;
  }
}
.p-hokenLpSippei1__imgtxt2.imgtxt2 .p-hokenLpSippei1__img2 {
  padding: 0px 0px 0 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt2 .p-hokenLpSippei1__img2 {
    padding: 12px 0px 0 0;
  }
}
.p-hokenLpSippei1__imgtxt2.imgtxt2 .p-hokenLpSippei1__txt2 {
  padding: 0 20px 0 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt2 .p-hokenLpSippei1__txt2 {
    padding: 0;
  }
}
.p-hokenLpSippei1__imgtxt2.imgtxt2 .p-hokenLpSippei__ttl2 {
  margin-bottom: 21px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt2 .p-hokenLpSippei__ttl2 {
    margin-bottom: 13px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt3 {
    padding: 19px 20px 23px;
  }
}
.p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei1__img2 {
  padding: 0;
}
.p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei1__img2 img {
  margin: 0 0 0 auto;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei1__img2 img {
    margin: 0;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei1__img2 {
    padding: 16px 0px 0 0;
  }
}
.p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei__ttl2 {
  margin-bottom: 21px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei__ttl2 {
    margin-bottom: 13px;
  }
}
.p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei__text1 + .p-hokenLpSippei__list1 {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__imgtxt2.imgtxt3 .p-hokenLpSippei__text1 + .p-hokenLpSippei__list1 {
    margin-top: 10px;
  }
}
.p-hokenLpSippei1__imgtxt2 .p-hokenLpSippei__txt4 {
  width: 100%;
}
.p-hokenLpSippei1__txt1 {
  width: calc(50% - 40px);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__txt1 {
    width: 100%;
  }
}
.p-hokenLpSippei1__txt2 {
  width: 50%;
  padding: 0 20px 0 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__txt2 {
    width: 100%;
    padding: 0;
  }
}
.p-hokenLpSippei1__img1 {
  width: 50%;
  margin-top: 6px;
  text-align: right;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__img1 {
    width: 100%;
    margin-top: 30px;
  }
}
.p-hokenLpSippei1__img1:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url("/img/hoken/lp/sandai_sippei/img1-1.png") no-repeat right bottom;
  background-size: cover;
  position: absolute;
  bottom: -65px;
  right: -66px;
  z-index: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__img1:after {
    width: 110%;
    height: 110%;
    bottom: -15%;
    right: -15%;
  }
}
.p-hokenLpSippei1__img1 img {
  position: relative;
  z-index: 9;
}
.p-hokenLpSippei1__img2 {
  width: 50%;
  text-align: center;
  padding: 8px 0 0 22px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei1__img2 {
    width: 100%;
    padding: 11px 0 0;
  }
}

.p-hokenLpSippei2 {
  background: url("/img/hoken/lp/sandai_sippei/bg1.png") no-repeat center;
  background-size: cover;
  margin-top: 40px;
  padding: 54px 0 80px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2 {
    margin-top: 20px;
    background: url("/img/hoken/lp/sandai_sippei/bg1-sp.png") no-repeat center;
    background-size: cover;
    padding: 38px 0 40px;
  }
}
.p-hokenLpSippei2 .p-hokenLpSippei__ttl1 {
  font-size: 3.2rem;
  padding: 0;
  letter-spacing: 0px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2 .p-hokenLpSippei__ttl1 {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}
.p-hokenLpSippei2 .p-hokenLpSippei__ttl1:before, .p-hokenLpSippei2 .p-hokenLpSippei__ttl1:after {
  content: none;
}
.p-hokenLpSippei2 .p-hokenLpSippei__ttl1 img {
  vertical-align: middle;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2 .p-hokenLpSippei__ttl1 img {
    vertical-align: bottom;
  }
}
.p-hokenLpSippei2 .p-hokenLpSippei__ttl1 p:last-child {
  margin-top: -4px;
}
.p-hokenLpSippei2__cont {
  border-radius: 10px;
  background-image: -moz-linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
  background-image: linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
  opacity: 0.902;
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  padding: 38px 40px 40px;
  margin-top: 53px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__cont {
    margin-top: 18px;
    padding: 0;
    background-image: none;
    box-shadow: none;
  }
}
.p-hokenLpSippei2__info1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__info1 {
    flex-wrap: wrap;
    border-radius: 10px;
    background-image: -moz-linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
    background-image: linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
    opacity: 0.949;
    box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
    padding: 20px 20px 19px;
  }
}
.p-hokenLpSippei2__info1 .p-hokenLpSippei__btn1 {
  height: 74px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__info1 .p-hokenLpSippei__btn1 {
    height: 20px;
  }
}
.p-hokenLpSippei2__info1 .p-hokenLpSippei__btn2 {
  height: 80px;
  padding: 20px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__info1 .p-hokenLpSippei__btn2 {
    width: 100%;
    height: 60px;
    padding: 13px 0;
  }
}
.p-hokenLpSippei2__info1 .p-hokenLpSippei__btn2 span {
  padding-left: 43px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__info1 .p-hokenLpSippei__btn2 span {
    padding-left: 13px;
  }
}
.p-hokenLpSippei2__info1 .p-hokenLpSippei__btn2 span:before {
  left: 3px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__info1 .p-hokenLpSippei__btn2 span:before {
    left: -12px;
  }
}
.p-hokenLpSippei2__info1 .p-hokenLpSippei__label {
  top: -53px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__info1 .p-hokenLpSippei__label {
    top: -40px;
  }
}
.p-hokenLpSippei2__info2 {
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  padding: 10px 10px 17px;
  margin-top: 42px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__info2 {
    margin-top: 10px;
    padding: 20px 20px 21px;
  }
}
.p-hokenLpSippei2__img {
  position: absolute;
  top: -83px;
  right: -17px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__img {
    bottom: auto;
    top: -15px;
    right: -10px;
    max-width: 180px;
    order: 2;
    margin: -10px -10px 0 0;
    width: 60%;
    z-index: 11;
  }
}
.p-hokenLpSippei2__ttl {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: bold;
  color: #fff;
  background: #8fc31f;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 17px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__ttl {
    font-size: 1.4rem;
    padding: 5px 0;
    margin-bottom: 9px;
  }
}
.p-hokenLpSippei2__text1 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #595950;
  width: 43%;
  margin-right: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__text1 {
    width: 100%;
    font-size: 1.2rem;
    line-height: 2.1rem;
    margin-right: 0;
  }
}
.p-hokenLpSippei2__btn1 {
  font-size: 1.6rem;
  line-height: 1;
  font-weight: bold;
  color: #22ac38;
  border: 2px solid #22ac38;
  border-radius: 50px;
  display: block;
  width: 320px;
  height: 46px;
  text-align: center;
  padding: 18px 0 31px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__btn1 {
    margin-top: 10px;
    width: 100%;
    max-width: 320px;
    height: 48px;
    font-size: 1.2rem;
    padding: 16px 0;
  }
}
.p-hokenLpSippei2__btn1:after {
  content: "";
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #22ac38;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
.p-hokenLpSippei2__btn1 span {
  position: relative;
  padding-left: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__btn1 span {
    padding-left: 27px;
  }
}
.p-hokenLpSippei2__btn1 span:before {
  content: "";
  width: 28px;
  height: 24px;
  background: url("/img/hoken/lp/sandai_sippei/icon4.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 44%;
  left: 4px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei2__btn1 span:before {
    width: 20px;
    height: 17px;
    left: 1px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLpSippei2__btn1:hover {
    color: #fff;
    background: #22ac38;
    opacity: 1;
  }
  .p-hokenLpSippei2__btn1:hover:after {
    border-color: transparent transparent transparent #fff;
    transition: 0.3s all;
  }
  .p-hokenLpSippei2__btn1:hover span:before {
    background: url("/img/hoken/lp/sandai_sippei/icon4_over.png") no-repeat center;
    background-size: cover;
    transition: 0.3s all;
  }
}

.p-hokenLpSippei3 {
  background: #fff;
  padding: 78px 0 67px;
  margin-bottom: -41px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3 {
    padding: 40px 0 72px;
    margin-bottom: -61px;
  }
}
.p-hokenLpSippei3 .l-contentServiceIn {
  padding: 0 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3 .l-contentServiceIn {
    padding: 0 20px;
  }
}
.p-hokenLpSippei3 .p-hokenLpSippei__ttl1 {
  padding: 72px 0 32px;
  letter-spacing: 0;
  line-height: 1.56;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3 .p-hokenLpSippei__ttl1 {
    padding: 44px 0 14px;
  }
}
.p-hokenLpSippei3__ttl1 {
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #59493f;
  margin: 58px 0 48px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__ttl1 {
    font-size: 2rem;
    line-height: 3rem;
    margin: 24px 0 17px;
  }
}
.p-hokenLpSippei3__ttl1 span {
  color: #22ac38;
}
.p-hokenLpSippei3__text1 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: bold;
  color: #ff5b4c;
}
.p-hokenLpSippei3__text2 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #595950;
  margin-bottom: 28px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__text2 {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__text2 span {
    color: #ff5b4c;
    font-weight: bold;
  }
}
.p-hokenLpSippei3__text3 {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #595950;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__text3 {
    line-height: 1.8rem;
    word-break: break-all;
  }
}
.p-hokenLpSippei3__text4 {
  font-size: 2.4rem;
  line-height: 4.7rem;
  color: #59493f;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__text4 {
    font-size: 1.8rem;
    line-height: 3.4rem;
    letter-spacing: -1.7px;
  }
}
.p-hokenLpSippei3__text4 span {
  font-size: 3.2rem;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__text4 span {
    font-size: 2.4rem;
  }
}
.p-hokenLpSippei3__text4 span.u-green {
  color: #22ac38;
}
.p-hokenLpSippei3__text4 span.u-red {
  color: #ff5b4c;
}
.p-hokenLpSippei3__img {
  text-align: center;
  margin: 23px 0 26px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__img {
    margin: 17px 0 15px;
  }
}
.p-hokenLpSippei3__label {
  text-align: center;
  margin: 119px 0 25px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__label {
    margin: 35px auto 15px;
    width: 100%;
    max-width: 320px;
  }
}
.p-hokenLpSippei3__label span {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #fff;
  font-weight: bold;
  background: #22ac38;
  position: relative;
  border-radius: 6px;
  display: inline-block;
  padding: 8px 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__label span {
    padding: 16px 10px;
    width: 100%;
  }
}
.p-hokenLpSippei3__label span:after {
  content: "";
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #22ac38 transparent transparent transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.p-hokenLpSippei3__list {
  width: calc(100% + 80px);
  display: flex;
  flex-wrap: wrap;
  margin: 54px 0 40px -40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__list {
    width: 100%;
    margin: 26px 0 30px;
  }
}
.p-hokenLpSippei3__item {
  width: calc((100% - 40px) / 2);
  border: 5px solid #22ac38;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 28px 34px;
  margin: 0 0 0 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__item {
    width: 100%;
    padding: 22px 15px;
    margin: 0 0 20px;
  }
}
.p-hokenLpSippei3__item:nth-child(2n+1) {
  margin: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__item:nth-child(2n+1) {
    margin: 0 0 20px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__item:last-child {
    margin: 0;
  }
}
.p-hokenLpSippei3__info .p-hokenLpSippei3__text2 {
  margin: 0 0 18px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__info .p-hokenLpSippei3__text2 {
    margin: 0 0 8px;
  }
}
.p-hokenLpSippei3__ttl2 {
  font-size: 2.8rem;
  line-height: 1.5;
  color: #59493f;
  font-weight: bold;
  margin: 0 0 14px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei3__ttl2 {
    font-size: 2rem;
    margin: 0 0 6px;
  }
}
.p-hokenLpSippei3__ttl2 span {
  color: #ff5b4c;
}

.p-hokenLpSippei4 {
  padding: 80px 0 74px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4 {
    padding: 40px 0 40px;
  }
}
.p-hokenLpSippei4 .p-hokenLpSippei__ttl1 {
  padding: 71px 0 30px;
  margin-bottom: 60px;
  line-height: 1.6;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4 .p-hokenLpSippei__ttl1 {
    padding: 47px 0 18px;
    margin-bottom: 32px;
    line-height: 1.4;
  }
}
.p-hokenLpSippei4__ttl1 {
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: bold;
  color: #22ac38;
  border-radius: 10px;
  border: 5px solid #22ac38;
  text-align: center;
  padding: 2px 0;
}
.p-hokenLpSippei4__img {
  text-align: center;
  margin: 40px 0 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__img {
    margin: 19px 0 19px;
    overflow-x: scroll;
  }
}
.p-hokenLpSippei4__img img {
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__img img {
    max-width: 220%;
  }
}
.p-hokenLpSippei4 .p-hokenLpSippei__list1 {
  padding: 0 40px;
  margin-top: 35px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4 .p-hokenLpSippei__list1 {
    padding: 0;
    margin-top: 16px;
  }
}
.p-hokenLpSippei4 .p-hokenLpSippei__txt4 {
  padding: 30px 34px 35px;
  margin: 55px 0 80px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4 .p-hokenLpSippei__txt4 {
    padding: 22px 20px 19px;
    margin: 27px 0 40px;
  }
}
.p-hokenLpSippei4__table1 {
  border-collapse: collapse;
  border: 1px solid #d8d8d8;
  width: 100%;
  margin-top: 18px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table1 {
    margin-top: 14px;
  }
}
.p-hokenLpSippei4__table1 th, .p-hokenLpSippei4__table1 td {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #595950;
  border: 1px solid #d8d8d8;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table1 th, .p-hokenLpSippei4__table1 td {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}
.p-hokenLpSippei4__table1 th {
  font-weight: bold;
  background: #f8f8f8;
  text-align: left;
  padding: 9px 14px;
  width: 209px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table1 th {
    width: 33%;
    padding: 5px 10px 4px;
  }
}
.p-hokenLpSippei4__table1 td {
  background: #fff;
  padding: 9px 16px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table1 td {
    padding: 5px 10px 4px;
  }
}
.p-hokenLpSippei4__table1 td span {
  color: #f0662b;
  font-weight: bold;
}
.p-hokenLpSippei4__table2 {
  border-collapse: collapse;
  border: 1px solid #d8d8d8;
  width: calc(100% - 80px);
  margin: 40px auto 0;
  background: #fff;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 {
    width: 100%;
    margin: 20px auto 0;
  }
}
.p-hokenLpSippei4__table2 th, .p-hokenLpSippei4__table2 td {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #595950;
  border: 1px solid #d8d8d8;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 th, .p-hokenLpSippei4__table2 td {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}
.p-hokenLpSippei4__table2 th {
  background: #f8f8f8;
  padding: 9px 14px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 th {
    padding: 7px 14px 4px;
  }
}
.p-hokenLpSippei4__table2 td {
  padding: 9px 16px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 td {
    padding: 6px 5px 5px;
  }
}
.p-hokenLpSippei4__table2 td.gray1 {
  width: 210px;
  background: #f8f8f8;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 td.gray1 {
    width: 33.3333333333%;
  }
}
.p-hokenLpSippei4__table2 td.gray1.gray2 {
  background: #ddd;
}
.p-hokenLpSippei4__table2 td.pink1 {
  width: 416px;
  color: #fff;
  background: #fc819b;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 td.pink1 {
    width: 33.3333333333%;
  }
}
.p-hokenLpSippei4__table2 td.pink1 span {
  color: #fc819b;
  background: #fff;
  font-size: 1.2rem;
  line-height: 1.8rem;
  border-radius: 4px;
  padding: 1px 10px;
  margin-left: 5px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 td.pink1 span {
    margin: 0;
  }
}
.p-hokenLpSippei4__table2 td.pink2 {
  background: #fff3f5;
}
.p-hokenLpSippei4__table2 td.pink2.gray3 {
  background: #eee;
}
.p-hokenLpSippei4__table2 td.yellow1 {
  width: 416px;
  color: #fff;
  background: #faad5a;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 td.yellow1 {
    width: 33.3333333333%;
  }
}
.p-hokenLpSippei4__table2 td.yellow1 span {
  color: #faad5a;
  background: #fff;
  font-size: 1.2rem;
  line-height: 1.8rem;
  border-radius: 4px;
  padding: 1px 10px;
  margin-left: 5px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__table2 td.yellow1 span {
    padding: 0 10px;
    margin: 0;
  }
}
.p-hokenLpSippei4__table2 td.yellow2 {
  background: #fff7ef;
}
.p-hokenLpSippei4__table2 td.yellow2.gray3 {
  background: #eee;
}
.p-hokenLpSippei4__table2 td .icon {
  display: inline-block;
  width: 19px;
  vertical-align: middle;
  margin: 0 0 0 2px;
}
.p-hokenLpSippei4__table2 .pink--text {
  color: #ff5b4c;
}
.p-hokenLpSippei4__label {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #fff;
  font-weight: bold;
  font-family: "M PLUS Rounded 1c", sans-serif;
  border-radius: 4px;
  text-align: center;
  width: 160px;
  height: 80px;
  background: #f0662b;
  position: relative;
  bottom: -101px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 0;
  z-index: 9;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__label {
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 100px;
    width: 100%;
    height: 60px;
    bottom: -72px;
    padding: 15px 0;
  }
}
.p-hokenLpSippei4__label:after {
  content: "";
  border-style: solid;
  border-width: 20px 10px 0 0px;
  border-color: #f0662b transparent transparent transparent;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__label:after {
    border-width: 10px 7px 0 0px;
    bottom: -10px;
  }
}
.p-hokenLpSippei4__label span {
  font-size: 2.5rem;
}
.p-hokenLpSippei4__note {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #ff5b4c;
  font-weight: bold;
  position: relative;
  padding: 0 0 0 21px;
  margin: 35px 40px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__note {
    margin: 15px 0 0;
  }
}
.p-hokenLpSippei4__note::before {
  content: "";
  width: 19px;
  height: 15px;
  background: url("/img/hoken/lp/sandai_sippei/icon9.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 4px;
  left: 0;
}
.p-hokenLpSippei4__note + .p-hokenLpSippei__list1 {
  margin-top: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4__note + .p-hokenLpSippei__list1 {
    margin-top: 2px;
  }
}
.p-hokenLpSippei4 .p-hokenLpSippei__list2 {
  border-top: 1px solid #d8d8d8;
  margin-top: 35px;
  padding: 35px 40px 0 43px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei4 .p-hokenLpSippei__list2 {
    margin-top: 17px;
    padding: 15px 3px 0 3px;
  }
}

.p-hokenLpSippei5 {
  background: #fff;
  padding: 82px 0 78px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5 {
    padding: 36px 0 42px;
  }
}
.p-hokenLpSippei5 .p-hokenLpSippei__ttl1 {
  padding: 71px 0 43px;
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5 .p-hokenLpSippei__ttl1 {
    padding: 43px 0 22px;
    margin-bottom: 30px;
  }
}
.p-hokenLpSippei5__q {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: #59493f;
  position: relative;
  padding-left: 30px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__q {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
}
.p-hokenLpSippei5__q:before {
  content: "Q.";
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #22ac38;
  position: absolute;
  top: -3px;
  left: 0;
}
.p-hokenLpSippei5__a {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #595950;
  position: relative;
  padding: 16px 0 0 30px;
  margin-top: 15px;
  border-top: 1px solid #d8d8d8;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__a {
    padding: 14px 0 0 30px;
    margin-top: 14px;
  }
}
.p-hokenLpSippei5__a:before {
  content: "A.";
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #ff5b4c;
  font-weight: bold;
  position: absolute;
  top: 12px;
  left: 0;
}
.p-hokenLpSippei5__a span {
  font-weight: bold;
}
.p-hokenLpSippei5__a a {
  color: #308bbb;
  text-decoration: underline;
  position: relative;
  margin-right: 37px;
}
.p-hokenLpSippei5__a a:after {
  content: "";
  width: 13px;
  height: 13px;
  background: url("/img/hoken/lp/sandai_sippei/icon6.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 4px;
  right: -18px;
}
.p-hokenLpSippei5__a a:hover {
  text-decoration: none;
}
.p-hokenLpSippei5__txt1 {
  border-radius: 10px;
  border: 5px solid #e6e4e2;
  padding: 30px 35px 28px;
  background: #fff;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__txt1 {
    padding: 14px 20px 12px;
  }
}
.p-hokenLpSippei5__txt2 {
  border-radius: 10px;
  border: 5px solid #e6e4e2;
  padding: 30px 35px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__txt2 {
    display: block;
    padding: 0;
    margin-top: 30px;
  }
}
.p-hokenLpSippei5__link1, .p-hokenLpSippei5__link2 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: bold;
  color: #595950;
  position: relative;
  padding: 0 20px 0 27px;
  margin: 0 38px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__link1, .p-hokenLpSippei5__link2 {
    width: 100%;
    display: block;
    margin: 0;
    padding: 13px 52px;
  }
}
.p-hokenLpSippei5__link1:before, .p-hokenLpSippei5__link2:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__link1:before, .p-hokenLpSippei5__link2:before {
    left: 22px;
  }
}
.p-hokenLpSippei5__link1:after, .p-hokenLpSippei5__link2:after {
  content: "";
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #8cb340;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__link1:after, .p-hokenLpSippei5__link2:after {
    right: 18px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__link1 {
    border-bottom: 1px solid #d8d8d8;
  }
}
.p-hokenLpSippei5__link1:before {
  width: 18px;
  height: 22px;
  background: url("/img/hoken/lp/sandai_sippei/icon7.png") no-repeat center;
  background-size: cover;
}
.p-hokenLpSippei5__link2 {
  padding: 0 20px 0 32px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei5__link2 {
    padding: 13px 52px;
  }
}
.p-hokenLpSippei5__link2:before {
  width: 22px;
  height: 22px;
  background: url("/img/hoken/lp/sandai_sippei/icon8.png") no-repeat center;
  background-size: cover;
}

.p-hokenLpSippei6 {
  background-image: -moz-linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
  background-image: linear-gradient(0deg, rgb(34, 172, 56) 0%, rgb(143, 195, 31) 100%);
  padding: 27px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei6 {
    padding: 20px 0 17px;
  }
}
.p-hokenLpSippei6 .l-contentServiceIn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei6 .l-contentServiceIn {
    flex-wrap: wrap;
    padding: 0 10px;
  }
}
.p-hokenLpSippei6__img1 {
  margin: 10px 10px 0 30px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei6__img1 {
    order: 3;
    margin: 13px 0 0;
  }
}
.p-hokenLpSippei6__img1 img {
  margin: 0 auto;
}
.p-hokenLpSippei6__img2 {
  position: absolute;
  right: 24px;
  top: -48px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei6__img2 {
    bottom: auto;
    top: -44px;
    right: 18px;
    max-width: 180px;
    order: 2;
    margin: -10px -10px 0 0;
    width: 63%;
    z-index: 11;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei6 .p-hokenLpSippei__info {
    width: 45%;
    margin-left: 10px;
  }
}

.p-hokenLpSippei7 {
  background: #f4f9e9;
  padding: 74px 0 76px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 {
    padding: 35px 0 39px;
  }
}
.p-hokenLpSippei7__ttl1 {
  font-size: 2rem;
  line-height: 3rem;
  color: #595950;
  position: relative;
  padding-left: 30px;
  margin-bottom: -8px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__ttl1 {
    margin-bottom: 11px;
  }
}
.p-hokenLpSippei7__ttl1:before {
  content: "";
  background: url("/img/hoken/lp/sandai_sippei/icon9.png") no-repeat center;
  background-size: cover;
  width: 23px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 0;
}
.p-hokenLpSippei7__text1 {
  font-size: 1.4rem;
  line-height: 2.34rem;
  color: #595950;
  margin: 31px 0 30px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__text1 {
    line-height: 2.45rem;
    margin: 9px 0 10px;
  }
}
.p-hokenLpSippei7 .p-hokenLpSippei__list2 {
  padding: 0 9px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .p-hokenLpSippei__list2 {
    padding: 0 3px;
  }
}
.p-hokenLpSippei7 .p-hokenLpSippei__list2 li {
  font-size: 1.4rem;
  line-height: 2.45rem;
}
.p-hokenLpSippei7__imgtxt1 {
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__imgtxt1 {
    display: block;
  }
}
.p-hokenLpSippei7__txt1 {
  width: 50%;
  padding: 0 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__txt1 {
    width: 100%;
    padding: 0;
  }
}
.p-hokenLpSippei7__img1 {
  width: 50%;
  text-align: center;
  padding-top: 5px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__img1 {
    width: 100%;
    padding: 22px 7px 0;
  }
}
.p-hokenLpSippei7 .kantan_web {
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  background: #fff;
  padding: 55px 60px;
  margin-top: 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web {
    padding: 14px 20px 20px;
    margin-top: 30px;
  }
}
.p-hokenLpSippei7 .kantan_web__ttl1 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 3rem;
  color: #22ac38;
  border-bottom: #22ac38 solid 2px;
  padding-bottom: 4px;
  margin: 0 0 23px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web__ttl1 {
    padding-bottom: 8px;
    margin: 0 0 14px;
  }
}
.p-hokenLpSippei7 .kantan_web__text1 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #595950;
  margin-bottom: 8px;
}
.p-hokenLpSippei7 .kantan_web__text2 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: bold;
  color: #595950;
  margin-bottom: 12px;
}
.p-hokenLpSippei7 .kantan_web__text2 span {
  color: #ff5b4c;
}
.p-hokenLpSippei7 .kantan_web .link01 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  position: relative;
  padding-left: 20px;
  display: inline-block;
  margin-top: -2px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .link01 {
    padding-left: 16px;
  }
}
.p-hokenLpSippei7 .kantan_web .link01 a {
  color: #308bbb;
  text-decoration: underline;
}
.p-hokenLpSippei7 .kantan_web .link01:before {
  content: "";
  border-style: solid;
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #8cb340;
  position: absolute;
  top: 6px;
  left: 8px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .link01:before {
    left: 3px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLpSippei7 .kantan_web .link01:hover a {
    text-decoration: none;
  }
}
.p-hokenLpSippei7 .kantan_web .txtbld input {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #333;
  border-radius: 4px;
  border: 2px solid #e6e4e2;
  width: 480px;
  height: 42px;
  padding: 9px 11px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .txtbld input {
    width: 100%;
  }
}
.p-hokenLpSippei7 .kantan_web .txtbld ::placeholder {
  color: #999;
}
.p-hokenLpSippei7 .kantan_web .seikyo_select {
  margin: 24px 0 47px 0;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .seikyo_select {
    display: block;
    margin: 17px 0 18px 0;
  }
}
.p-hokenLpSippei7 .kantan_web .seikyo_select li {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #595950;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .seikyo_select li {
    margin: 0 0 5px;
  }
}
.p-hokenLpSippei7 .kantan_web__btn {
  font-size: 1.6rem;
  line-height: 60px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  width: 288px;
  height: 60px;
  border-radius: 50px;
  background: #ff5b4c;
  border: 0;
  display: block;
  margin: 60px auto 25px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web__btn {
    width: 100%;
    max-width: 292px;
    margin: 31px auto 15px;
  }
}
.p-hokenLpSippei7 .kantan_web__btn a {
  line-height: 2.4rem;
}
.p-hokenLpSippei7 .kantan_web__btn:after {
  content: "";
  border-style: solid;
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.p-hokenLpSippei7 .kantan_web .btn_default .kantan_web__btn {
  background: #ccc;
}
.p-hokenLpSippei7 .kantan_web .alert02 {
  color: #ff5b4c;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .alert02 {
    text-align: center;
    font-size: 1.4rem;
  }
}
.p-hokenLpSippei7 .kantan_web .alert02 img {
  margin-right: 10px;
}
.p-hokenLpSippei7 .kantan_web .radioBg {
  width: 320px;
  display: inline-block;
  overflow: hidden;
  border: 2px solid #e6e4e2;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 8px 0 8px 37px;
  cursor: pointer;
  background: url("/img/hoken/lp/sandai_sippei/radio_off.png") no-repeat left 13px center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .radioBg {
    width: 100%;
  }
}
.p-hokenLpSippei7 .kantan_web input[type=radio]:checked + label {
  background: url("/img/hoken/lp/sandai_sippei/radio_on.png") no-repeat left 13px center;
}
.p-hokenLpSippei7 .kantan_web input[type=radio] {
  display: none;
}
.p-hokenLpSippei7 .kantan_web .column03 {
  border-radius: 4px;
  background: #f8f8f8;
  border: 1px solid #e6e4e2;
  padding: 25px 15px 31px;
  margin-top: 23px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .kantan_web .column03 {
    margin-top: 11px;
    padding: 14px 20px 21px 16px;
  }
}
.p-hokenLpSippei7 .kantan_web .p-hokenLpSippei__list1 {
  margin-top: 0;
}
.p-hokenLpSippei7__table1 {
  border-collapse: collapse;
  border: 1px solid #d8d8d8;
  width: calc(100% - 80px);
  margin: 80px auto 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__table1 {
    width: 100%;
    margin: 41px auto 0;
  }
}
.p-hokenLpSippei7__table1 th, .p-hokenLpSippei7__table1 td {
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #595950;
  border: 1px solid #d8d8d8;
}
.p-hokenLpSippei7__table1 th {
  font-weight: bold;
  background: #f8f8f8;
  text-align: left;
  padding: 15px 20px;
  width: 208px;
  vertical-align: top;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__table1 th {
    padding: 5px 9px 4px;
    width: 33.5%;
  }
}
.p-hokenLpSippei7__table1 td {
  background: #fff;
  padding: 15px 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__table1 td {
    padding: 5px 9px 4px;
  }
}
.p-hokenLpSippei7__table1 td p {
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7__table1 td p {
    margin-bottom: 5px;
  }
}
.p-hokenLpSippei7__table1 td p:last-child {
  margin-bottom: 0;
}
.p-hokenLpSippei7__table1 td span {
  font-weight: bold;
  display: block;
}
.p-hokenLpSippei7 .p-hokenLpSippei3__text2 {
  margin: 40px 0 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpSippei7 .p-hokenLpSippei3__text2 {
    font-size: 1.3rem;
    margin-top: 20px;
  }
}

/*------------------------------------------------------------
かんたん資金シミュレーション
------------------------------------------------------------*/
.p-hokenSimulation .c-breadcrumb1 {
  padding: 19px 0 56px;
}
.p-hokenSimulation .c-hokenTitle03 {
  background-image: url("../img/hoken/title_obi_simulation.jpg");
  padding: 30px 0 28px 34px;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation .c-hokenTitle03 {
    background-image: url("../img/hoken/title_obi_simulation_sp.jpg");
  }
}
.p-hokenSimulation__text1 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #46483c;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation__text1 {
    font-size: 1.4rem;
    line-height: 1.75;
  }
}
.p-hokenSimulation__box {
  width: 590px;
  margin: 40px auto 0;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation__box {
    width: 100%;
  }
}
.p-hokenSimulation__box img {
  width: 100%;
  height: auto;
}
.p-hokenSimulation__img {
  width: 100%;
  height: auto;
}
.p-hokenSimulation .c-hokenBtn02 {
  width: 500px;
  margin: 22px auto 0;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation .c-hokenBtn02 {
    width: auto;
    margin: 10px 20px 0;
  }
}
.p-hokenSimulation .c-hokenBtn02 a {
  padding: 11px 12px 10px 0;
  font-size: 1.4rem;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation .c-hokenBtn02 a {
    font-size: 1.3rem;
  }
}
.p-hokenSimulation .c-hokenBtn02__icon {
  width: auto;
  height: auto;
  transform: translateY(3px);
  margin-left: 4px;
}
.p-hokenSimulation__note1 {
  font-size: 1.4rem;
  line-height: 2.8rem;
  color: #46483c;
  margin-top: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation__note1 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation01 {
    padding: 0 20px;
  }
}
.p-hokenSimulation01 .c-hokenTitle02 {
  margin: 33px 0 24px;
  padding: 10px 10px 10px 26px;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation01 .c-hokenTitle02 {
    margin: 17px 0 15px;
    padding: 5px 5px 5px 17px;
  }
}
.p-hokenSimulation01 .c-hokenTitle05 {
  margin-bottom: 13px;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation01 .c-hokenTitle05 {
    margin-bottom: 7px;
  }
}
.p-hokenSimulation01__list {
  margin-top: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation01__list {
    margin-top: 0;
    display: block;
  }
}
.p-hokenSimulation01__list li {
  width: 410px;
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation01__list li {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
}
.p-hokenSimulation01__list li::first-child {
  margin-left: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation01__list li img {
    width: 100%;
  }
}
.p-hokenSimulation02 {
  padding-top: 41px;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation02 {
    padding: 20px 20px 0;
  }
}
.p-hokenSimulation02 .c-hokenTitle02 {
  margin-bottom: 24px;
}
@media screen and (max-width: 640px) {
  .p-hokenSimulation02 .c-hokenTitle02 {
    margin-bottom: 15px;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.page-hoken_lp_kega_hoken,
.page-hoken_lp_kega_hoken_202205 {
  position: relative;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  background: #ffffff;
  min-width: 1200px;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken,
  .page-hoken_lp_kega_hoken_202205 {
    min-width: 320px;
  }
}
.page-hoken_lp_kega_hoken .l-contentServiceIn,
.page-hoken_lp_kega_hoken_202205 .l-contentServiceIn {
  display: block;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken .l-contentService,
  .page-hoken_lp_kega_hoken_202205 .l-contentService {
    padding-top: 0;
  }
}
.page-hoken_lp_kega_hoken .pc-only,
.page-hoken_lp_kega_hoken_202205 .pc-only {
  display: block;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken .pc-only,
  .page-hoken_lp_kega_hoken_202205 .pc-only {
    display: none;
  }
}
.page-hoken_lp_kega_hoken .sp-only,
.page-hoken_lp_kega_hoken_202205 .sp-only {
  display: none;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken .sp-only,
  .page-hoken_lp_kega_hoken_202205 .sp-only {
    display: block;
  }
}
.page-hoken_lp_kega_hoken .c-header-lp__logo,
.page-hoken_lp_kega_hoken_202205 .c-header-lp__logo {
  max-width: 266px;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken .c-header-lp__logo,
  .page-hoken_lp_kega_hoken_202205 .c-header-lp__logo {
    width: 132px;
  }
}

.p-hokenLpKegaMv {
  background: url("/img/hoken/lp/kega_hoken/mv.png") no-repeat center;
  background-size: cover;
  padding: 97px 0 50px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv {
    padding: 48px 13px 0 20px;
    background: url("/img/hoken/lp/kega_hoken/mv-sp.png") no-repeat top center;
    background-size: 100% auto;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv .l-contentServiceIn {
    padding: 0 !important;
  }
}
.p-hokenLpKegaMv__txt {
  border-radius: 10px;
  background: rgba(58, 100, 173, 0.85);
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin-top: 27px;
  padding: 40px 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__txt {
    flex-wrap: wrap;
    margin-top: 25px;
    padding: 23px 20px 20px;
    margin-right: 6px;
  }
}
.p-hokenLpKegaMv__txt.no-img {
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__txt.no-img .p-hokenLpKega__info {
    width: auto;
  }
}
.p-hokenLpKegaMv__subttl {
  background: #3A64AD;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  align-items: center;
  max-width: 540px;
  margin-top: 15px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__subttl {
    margin-top: 102px;
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    position: relative;
    left: -2px;
  }
}
.p-hokenLpKegaMv__subttl__text, .p-hokenLpKegaMv__subttl__item {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.03em;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__subttl__text, .p-hokenLpKegaMv__subttl__item {
    font-size: 1.2rem;
  }
}
.p-hokenLpKegaMv__subttl__text {
  color: #fff;
  padding-left: 12px;
  padding-right: 17px;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__subttl__text {
    padding-left: 6px;
    padding-right: 14px;
    line-height: 14px;
  }
}
.p-hokenLpKegaMv__subttl__item {
  color: #3A64AD;
  position: relative;
  text-align: left;
  padding-left: 19px;
  margin-left: 15px;
  white-space: nowrap;
}
.p-hokenLpKegaMv__subttl__item::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #3A64AD;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__subttl__item {
    padding-left: 9px;
    margin-left: 0;
  }
  .p-hokenLpKegaMv__subttl__item:nth-child(2n) {
    margin-left: 16px;
  }
  .p-hokenLpKegaMv__subttl__item:nth-child(1), .p-hokenLpKegaMv__subttl__item:nth-child(3) {
    width: 68px;
  }
}
.p-hokenLpKegaMv__subttl__item:first-child {
  padding-left: 0;
  margin-left: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__subttl__item:first-child {
    padding-left: 9px;
    margin-left: 0;
  }
}
.p-hokenLpKegaMv__subttl__item:first-child::before {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__subttl__item:first-child::before {
    display: block;
  }
}
.p-hokenLpKegaMv__subttl__lst {
  background: #fff;
  display: flex;
  align-items: center;
  width: 452px;
  padding: 5px 15px 3px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__subttl__lst {
    flex-wrap: wrap;
    width: 100%;
    padding: 3px 15px;
  }
}
.p-hokenLpKegaMv__img {
  position: absolute;
  bottom: -53px;
  right: 20px;
  width: 300px;
}
.p-hokenLpKegaMv__img img {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__img {
    bottom: auto;
    top: -5px;
    right: 16px;
    max-width: 187px;
    order: 2;
    margin: -10px -10px 0 0;
    width: 60%;
    z-index: 11;
  }
}
.p-hokenLpKegaMv__text1 {
  max-width: 535px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__text1 {
    max-width: 320px;
    margin: 0 auto;
    position: relative;
    left: -4px;
  }
}
.p-hokenLpKegaMv__text2 {
  padding: 0 48px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKegaMv__text2 {
    padding: 0;
    max-width: 335px;
    margin: 4px 0 0;
  }
}
.p-hokenLpKegaMv__text3 {
  margin-top: 10px;
}

.p-hokenLpKega__lst1 .p-hokenLpKega__box1:nth-child(2), .p-hokenLpKega__lst1 .p-hokenLpKega__box1:nth-child(3) {
  padding-bottom: 40px;
}
.p-hokenLpKega__lst1 .p-hokenLpKega__box1:nth-child(4) .p-hokenLpKega__box1__ttl {
  margin-bottom: 20px;
}
.p-hokenLpKega__box1 {
  display: flex;
  background: #fff;
  padding: 40px 40px 32px;
  border-radius: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1 {
    display: block;
    padding: 30px 20px;
  }
}
.p-hokenLpKega__box1 + .p-hokenLpKega__box1 {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1 + .p-hokenLpKega__box1 {
    margin-top: 10px;
  }
}
.p-hokenLpKega__box1__ttl {
  font-size: 3.2rem;
  line-height: 1.2;
  font-weight: bold;
  margin: 10px 0 28px;
  /* white-space: nowrap; */
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1__ttl {
    font-size: 2.3rem;
    line-height: 30px;
    white-space: inherit;
    margin: 0 0 17px;
  }
}
.p-hokenLpKega__box1__ttl .cl-red {
  color: #E55552;
}
.p-hokenLpKega__box1__text {
  font-size: 1.8rem;
  line-height: 32px;
  font-weight: 500;
}
.p-hokenLpKega__box1__text .style-1 {
  position: relative;
  font-weight: bold;
  background: rgba(229, 85, 82, 0.15);
}
.p-hokenLpKega__box1__txt {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 22px;
  position: relative;
  padding-left: 17px;
  margin-top: 26px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1__txt {
    margin-top: 5px;
  }
}
.p-hokenLpKega__box1__txt::before {
  content: "※";
  position: absolute;
  top: -3px;
  left: 0;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 28px;
}
.p-hokenLpKega__box1__content {
  width: 500px;
  margin-right: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1__content {
    width: 100%;
  }
}
.p-hokenLpKega__box1__img {
  width: 500px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1__img {
    width: 100%;
    margin-top: 30px;
  }
}
.p-hokenLpKega__box1__img img {
  width: 100%;
}
.p-hokenLpKega__box1__img .p-hokenLpKega__box1__txt {
  text-align: right;
  padding-left: 0;
  margin-top: 18px;
}
.p-hokenLpKega__box1__img .p-hokenLpKega__box1__txt::before {
  display: none;
}
.p-hokenLpKega__box1__btn {
  border-radius: 10px;
  background: #fff;
  border: 5px solid #009DE4;
  font-size: 2rem;
  line-height: 28px;
  color: #009DE4;
  font-weight: bold;
  text-align: center;
  padding: 7px 10px 4px;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1__btn {
    font-size: 2.1rem;
    border-width: 4px;
  }
}
.p-hokenLpKega__box1 .p-hokenLpKega__noteList {
  margin-top: 26px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__box1 .p-hokenLpKega__noteList {
    margin-top: 15px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega .l-contentServiceIn {
    padding: 0 20px;
  }
}
.p-hokenLpKega__info {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__info {
    display: block;
    order: 1;
    width: 50%;
    max-width: 148px;
  }
}
.p-hokenLpKega__txt2 {
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__txt2 {
    margin-left: 8px;
  }
}
.p-hokenLpKega__txt3 {
  position: relative;
  margin-left: 68px;
}
.p-hokenLpKega__txt3::before {
  content: "";
  position: absolute;
  top: -40px;
  left: -44px;
  width: 158px;
  height: 138px;
  background: url("/img/hoken/lp/kega_hoken/notify.png") no-repeat;
  z-index: 1;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__txt3::before {
    top: -23px;
    left: 0;
    width: 108px;
    height: 92px;
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__txt3 {
    margin: 39px 0 0;
    order: 3;
    width: 100%;
  }
}
.p-hokenLpKega__txt4 {
  border-radius: 10px;
  border: 5px solid #e6e4e2;
  padding: 30px 34px 28px;
  margin-top: 44px;
  background: #ffffff;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__txt4 {
    padding: 20px;
    margin-top: 30px;
  }
}
.p-hokenLpKega__txt4 .p-hokenLpKega3__txt1 {
  margin-top: 2px;
}
.p-hokenLpKega__note {
  font-size: 1.4rem;
  position: relative;
  padding-left: 20px;
  margin: 15px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__note {
    font-size: 1.2rem;
  }
}
.p-hokenLpKega__note:before {
  content: "＊";
  position: absolute;
  font-size: 1.4rem;
  top: 6px;
  left: 3px;
  line-height: 1;
}
.p-hokenLpKega__noteList {
  font-size: 1.4rem;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__noteList {
    font-size: 1.2rem;
  }
}
.p-hokenLpKega__noteList__item {
  margin-top: 5px;
}
.p-hokenLpKega__btn1 {
  font-size: 1.6rem;
  line-height: 1.25;
  color: #3A64AD;
  font-weight: bold;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__btn1 {
    font-size: 1.2rem;
    height: 25px;
    width: 120px;
  }
}
.p-hokenLpKega__btn2 {
  font-size: 2.4rem;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  width: 460px;
  height: 80px;
  border: 5px solid #fff;
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  background: #E55552;
  display: inline-block;
  text-align: center;
  padding: 18px 0;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__btn2 {
    font-size: 1.4rem;
    width: 100%;
    height: 60px;
    border: 4px solid #fff;
    padding: 15px 0 15px 60px;
  }
}
.p-hokenLpKega__btn2:after {
  content: "";
  border-style: solid;
  border-width: 6px 0 6px 7px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__btn2:after {
    right: 18px;
  }
}
.p-hokenLpKega__btn2 span {
  position: relative;
  padding-left: 50px;
  top: 3px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__btn2 span {
    padding-left: 10px;
    top: -4px;
  }
}
.p-hokenLpKega__btn2 span:before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("/img/hoken/lp/kega_hoken/icon1.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 53%;
  left: 0;
  margin-top: -2px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__btn2 span:before {
    width: 20px;
    height: 20px;
    left: -14px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLpKega__btn2:hover {
    color: #ff5b4c;
    border: 5px solid #ff5b4c;
    background: #fff;
    opacity: 1;
  }
  .p-hokenLpKega__btn2:hover:after {
    border-color: transparent transparent transparent #ff5b4c;
    transition: 0.3s all;
  }
  .p-hokenLpKega__btn2:hover span:before {
    background: url("/img/hoken/lp/kega_hoken/icon1_over.png") no-repeat center;
    background-size: cover;
    transition: 0.3s all;
  }
}
.p-hokenLpKega__text1 {
  font-size: 1.6rem;
  line-height: 28px;
  color: #333;
  font-weight: 500;
}
.p-hokenLpKega__text1 span {
  color: #ff5b4c;
  font-weight: bold;
}
.p-hokenLpKega__text3 {
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__text3 {
    font-size: 1.5rem;
    padding: 8px 12px 0;
    white-space: nowrap;
  }
}
.p-hokenLpKega__text3 span {
  font-size: 2.4rem;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__text3 span {
    font-size: 2.3rem;
  }
}
.p-hokenLpKega__text4 {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__text4 {
    font-size: 2.2rem;
    margin: -2px 0 4px -8px;
    letter-spacing: -1px;
    white-space: nowrap;
  }
}
.p-hokenLpKega__text4 span {
  font-size: 4.4rem;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__text4 span {
    font-size: 4rem;
    position: relative;
    top: 3px;
  }
}
.p-hokenLpKega__text5 {
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__text5 {
    font-size: 1rem;
    margin: 0 0 0 3px;
  }
}
.p-hokenLpKega__label {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #59493f;
  background: #fcf9e8;
  box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
  border-radius: 6px;
  font-weight: bold;
  z-index: 10;
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__label {
    font-size: 1.2rem;
    margin-left: 0;
    top: -40px;
    left: 0;
    transform: translateX(0);
  }
}
.p-hokenLpKega__label span {
  padding: 10px 0;
  display: inline-block;
  width: 240px;
  height: 40px;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__label span {
    padding: 6px 0;
    display: inline-block;
    width: 170px;
    height: 30px;
  }
}
.p-hokenLpKega__label span:after {
  content: "";
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #fcf9e8 transparent transparent transparent;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__label span:after {
    border-width: 8px 4px 0 4px;
    bottom: -8px;
  }
}
.p-hokenLpKega__ttl1 {
  font-size: 4rem;
  line-height: 50px;
  color: #59493f;
  font-weight: bold;
  position: relative;
  padding: 77px 0 31px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__ttl1 {
    display: block;
    text-align: center;
    font-size: 3rem;
    line-height: 1.2;
    padding: 45px 0 27px !important;
  }
}
.p-hokenLpKega__ttl1--wid {
  width: 100%;
  text-align: center;
  position: relative;
  top: -9px;
}
.p-hokenLpKega__ttl1:before {
  content: "";
  background: url("/img/hoken/lp/kega_hoken/icon2.png") no-repeat center;
  background-size: 39px 40px;
  width: 39px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__ttl1:before {
    width: 30px;
    height: 29px;
    background-size: 30px 29px;
  }
}
.p-hokenLpKega__ttl1:after {
  content: "";
  background: url("/img/hoken/lp/kega_hoken/line1.png") no-repeat center;
  background-size: 120px 16px;
  width: 120px;
  height: 16px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__ttl1:after {
    width: 88px;
    height: 11px;
    background-size: 88px 11px;
  }
}
.p-hokenLpKega__ttl1--red {
  color: #ff5b4c;
}
.p-hokenLpKega__ttl1 .number {
  font-family: "Roboto", sans-serif;
}
.p-hokenLpKega__ttl1 img {
  vertical-align: sub;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__ttl1 img {
    max-width: 220px;
  }
}
.p-hokenLpKega__ttl1 i {
  font-style: normal;
}
.p-hokenLpKega__ttl2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 26px;
}
.p-hokenLpKega__ttl3 {
  font-size: 2rem;
  line-height: 38px;
  color: #333333;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__ttl3 {
    font-size: 1.8rem;
    line-height: 1.5;
    margin-bottom: 14px;
  }
}
.p-hokenLpKega__ttl3:before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("/img/hoken/lp/kega_hoken/icon3.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: -2px;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__ttl3:before {
    top: 0;
  }
}
.p-hokenLpKega__point {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  border-radius: 4px;
  width: 70px;
  height: 70px;
  min-width: 70px;
  background: #009DE4;
  text-align: center;
  padding: 12px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__point {
    font-size: 1rem;
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
}
.p-hokenLpKega__point span {
  font-size: 4rem;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__point span {
    font-size: 3.4rem;
  }
}
.p-hokenLpKega__ttl2Main {
  font-size: 2.4rem;
  line-height: 38px;
  font-weight: bold;
  color: #333;
  padding-left: 16px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__ttl2Main {
    width: calc(100% - 60px);
    font-size: 1.8rem;
    line-height: 23px;
  }
}
.p-hokenLpKega__ttl2Main span {
  text-decoration: underline;
}
.p-hokenLpKega__list1 {
  margin-top: 37px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__list1 {
    margin-top: 17px;
  }
}
.p-hokenLpKega__list1 li {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #595950;
  position: relative;
  padding-left: 14px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__list1 li {
    line-height: 1.8rem;
  }
}
.p-hokenLpKega__list1 li:before {
  content: "※";
  position: absolute;
  top: 0;
  left: 0;
}
.p-hokenLpKega__list1 li a {
  color: #308bbb;
  text-decoration: underline;
}
.p-hokenLpKega__list1 li a:hover {
  text-decoration: none;
}
.p-hokenLpKega__list2 li {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #595950;
  position: relative;
  padding-left: 12px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega__list2 li {
    line-height: 1.8rem;
  }
}
.p-hokenLpKega__list2 li:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #009DE4;
  position: absolute;
  top: 8px;
  left: 0;
}
.p-hokenLpKega__list2 li a {
  color: #308bbb;
  text-decoration: underline;
  position: relative;
  margin-right: 15px;
}
.p-hokenLpKega__list2 li a:after {
  content: "";
  width: 10px;
  height: 10px;
  background: url("/img/hoken/lp/kega_hoken/icon5.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 3px;
  right: -11px;
}
.p-hokenLpKega__list2 li a:hover {
  text-decoration: none;
}

.p-hokenLpKega1 {
  margin-top: -58px;
  padding: 136px 0 83px;
  background: #F8F7F6;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega1 {
    padding: 40px 0;
    margin-top: 0;
  }
}
.p-hokenLpKega1 .p-hokenLpKega__ttl1 {
  margin-bottom: 60px;
}
.p-hokenLpKega1 .p-hokenLpKega__box1__content .p-hokenLpKega__txt4 {
  margin-top: 28px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega1 .p-hokenLpKega__box1__content .p-hokenLpKega__txt4 {
    margin-top: 25px;
  }
}

.p-hokenLpKega2 {
  background: url("/img/hoken/lp/kega_hoken/bg1.png") no-repeat center;
  background-size: cover;
  padding: 70px 0 80px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2 {
    background: url("/img/hoken/lp/kega_hoken/bg1-sp.png") no-repeat center;
    background-size: cover;
    padding: 38px 0 40px;
  }
}
.p-hokenLpKega2 .p-hokenLpKega__ttl1 {
  font-size: 3.2rem;
  padding: 0;
  letter-spacing: 0px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2 .p-hokenLpKega__ttl1 {
    font-size: 2.4rem;
    line-height: 1.5;
    margin-top: -19px;
    padding: 0 !important;
  }
}
.p-hokenLpKega2 .p-hokenLpKega__ttl1:before, .p-hokenLpKega2 .p-hokenLpKega__ttl1:after {
  content: none;
}
.p-hokenLpKega2 .p-hokenLpKega__ttl1 img {
  vertical-align: middle;
  position: relative;
  top: -11px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2 .p-hokenLpKega__ttl1 img {
    vertical-align: bottom;
    top: 11px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2 .p-hokenLpKega__ttl1--wid {
    top: 10px;
  }
}
.p-hokenLpKega2 .p-hokenLpKega__ttl1 p:last-child {
  margin-top: -4px;
}
.p-hokenLpKega2__cont {
  border-radius: 10px;
  background: rgba(58, 100, 173, 0.85);
  opacity: 0.902;
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  padding: 40px 40px 40px;
  margin-top: 19px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__cont {
    margin-top: 28px;
    padding: 0;
    background: none;
    box-shadow: none;
  }
}
.p-hokenLpKega2__info1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1 {
    flex-wrap: wrap;
    border-radius: 10px;
    background: rgba(58, 100, 173, 0.85);
    opacity: 0.949;
    box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
    padding: 23px 20px 25px;
  }
}
.p-hokenLpKega2__info1 .p-hokenLpKega__btn1 {
  height: 80px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1 .p-hokenLpKega__btn1 {
    height: 25px;
  }
}
.p-hokenLpKega2__info1 .p-hokenLpKega__btn2 {
  height: 80px;
  padding: 20px 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1 .p-hokenLpKega__btn2 {
    width: 100%;
    height: 60px;
    padding: 15px 0 15px 60px;
  }
}
.p-hokenLpKega2__info1 .p-hokenLpKega__btn2 span {
  padding-left: 43px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1 .p-hokenLpKega__btn2 span {
    padding-left: 13px;
  }
}
.p-hokenLpKega2__info1 .p-hokenLpKega__btn2 span:before {
  left: 3px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1 .p-hokenLpKega__btn2 span:before {
    left: -12px;
  }
}
.p-hokenLpKega2__info1 .p-hokenLpKega__label {
  top: -53px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1 .p-hokenLpKega__label {
    top: -40px;
  }
}
.p-hokenLpKega2__info1 .p-hokenLpKega__txt3 {
  top: 5px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1 .p-hokenLpKega__txt3 {
    margin-top: 34px;
  }
}
.p-hokenLpKega2__info1.no-img {
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info1.no-img .p-hokenLpKega__info {
    width: auto;
  }
}
.p-hokenLpKega2__info2 {
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  padding: 10px 10px 17px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__info2 {
    margin-top: 10px;
    padding: 20px 20px 21px;
  }
}
.p-hokenLpKega2__img {
  position: absolute;
  top: -116px;
  right: -70px;
  width: 300px;
}
.p-hokenLpKega2__img img {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__img {
    bottom: auto;
    top: -5px;
    right: 12px;
    max-width: 190px;
    order: 2;
    margin: -10px -10px 0 0;
    width: 60%;
    z-index: 11;
  }
}
.p-hokenLpKega2__ttl {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: bold;
  color: #fff;
  background: #009DE4;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 16px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__ttl {
    font-size: 1.4rem;
    padding: 5px 0;
    margin-bottom: 9px;
  }
}
.p-hokenLpKega2__text1 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #333;
  width: 43%;
  margin-right: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__text1 {
    width: 100%;
    font-size: 1.2rem;
    line-height: 2.1rem;
    margin-right: 0;
  }
}
.p-hokenLpKega2__btn1 {
  font-size: 1.6rem;
  line-height: 1;
  font-weight: bold;
  color: #009DE4;
  border: 2px solid #009DE4;
  border-radius: 50px;
  display: block;
  width: 320px;
  height: 50px;
  text-align: center;
  padding: 16px 0 10px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__btn1 {
    margin-top: 10px;
    width: 100%;
    max-width: 320px;
    height: 48px;
    font-size: 1.2rem;
    padding: 16px 0;
  }
}
.p-hokenLpKega2__btn1:after {
  content: "";
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #009DE4;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
.p-hokenLpKega2__btn1 span {
  position: relative;
  padding-left: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__btn1 span {
    padding-left: 27px;
  }
}
.p-hokenLpKega2__btn1 span:before {
  content: "";
  width: 28px;
  height: 24px;
  background: url("/img/hoken/lp/kega_hoken/icon4.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 44%;
  left: 4px;
  transform: translateY(-50%);
  transition: 0.3s all;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega2__btn1 span:before {
    width: 20px;
    height: 17px;
    left: 1px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLpKega2__btn1:hover {
    color: #fff;
    background: #009DE4;
    opacity: 1;
  }
  .p-hokenLpKega2__btn1:hover:after {
    border-color: transparent transparent transparent #fff;
    transition: 0.3s all;
  }
  .p-hokenLpKega2__btn1:hover span:before {
    background: url("/img/hoken/lp/kega_hoken/icon4_over.png") no-repeat center;
    background-size: cover;
    transition: 0.3s all;
  }
}
.p-hokenLpKega2 .mw100 {
  max-width: 100%;
}

.p-hokenLpKega3 {
  padding: 78px 0 73px;
  overflow-x: hidden;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3 {
    padding: 30px 0 40px;
  }
}
.p-hokenLpKega3 .p-hokenLpKega__ttl1 {
  padding: 73px 0 57px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3 .p-hokenLpKega__ttl1 {
    padding: 59px 0 21px !important;
  }
}
.p-hokenLpKega3 .p-hokenLpKega__ttl1--wid {
  font-size: 3.2rem;
  font-weight: bold;
  margin-bottom: -9px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3 .p-hokenLpKega__ttl1--wid {
    font-size: 2.3rem;
    line-height: 26px;
    display: block;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }
}
.p-hokenLpKega3 .p-hokenLpKega__ttl1 img {
  position: relative;
  left: -3px;
  top: 14px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3 .p-hokenLpKega__ttl1 img {
    top: 16px;
    max-width: 240px;
  }
}
.p-hokenLpKega3 .p-hokenLpKega__ttl1--red {
  font-size: 6.8rem;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3 .p-hokenLpKega__ttl1--red {
    font-size: 5.1rem;
    position: relative;
    top: 9px;
  }
}
.p-hokenLpKega3__ttl2 {
  font-size: 2.8rem;
  text-align: center;
  line-height: 42px;
  font-weight: bold;
  margin: 60px 0 62px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__ttl2 {
    font-size: 2rem;
    line-height: 30px;
    text-align: left;
    margin: 30px 0;
  }
}
.p-hokenLpKega3__lst3 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__lst3 {
    display: block;
  }
}
.p-hokenLpKega3__lst3 li {
  width: calc(50% - 20px);
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  padding-left: 14px;
  margin-bottom: 7px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__lst3 li {
    width: 100%;
  }
  .p-hokenLpKega3__lst3 li:last-child {
    margin-bottom: 0;
  }
}
.p-hokenLpKega3__lst3 li:nth-child(2n) {
  margin-left: 20px;
  width: 50%;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__lst3 li:nth-child(2n) {
    width: 100%;
    margin-left: 0;
  }
}
.p-hokenLpKega3__lst3 li::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #009DE4;
}
.p-hokenLpKega3__lst3.no-col li {
  width: 100%;
}
.p-hokenLpKega3__lst3.no-col li:nth-child(2n) {
  margin-left: 0;
  width: 100%;
}
.p-hokenLpKega3__txt1 {
  font-size: 1.2rem;
  line-height: 21px;
  font-weight: 500;
  color: #333333;
  display: flex;
}
.p-hokenLpKega3__txt1 span {
  margin: 0 2px;
  line-height: 1;
  display: block;
  position: relative;
  top: 4px;
}
.p-hokenLpKega3__text1 {
  font-size: 2.8rem;
  font-weight: bold;
  color: #59493f;
  line-height: 1.5;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__text1 {
    font-size: 2rem;
    letter-spacing: -0.3px;
  }
}
.p-hokenLpKega3__text1 span {
  color: #22ac38;
}
.p-hokenLpKega3__point {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.p-hokenLpKega3__point span {
  font-size: 3.2rem;
  font-family: "Roboto", sans-serif;
  margin-left: 2px;
}
.p-hokenLpKega3__imgtxt2 {
  display: flex;
  flex-wrap: wrap;
  background: #F8F7F6;
  padding: 36px 40px 40px;
  margin: -15px 0 35px;
  border-radius: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2 {
    flex-wrap: wrap;
    margin: 0 0 18px;
    padding: 21px 20px;
    position: relative;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt1 .p-hokenLpKega__txt4 {
  padding-bottom: 21px;
}
.p-hokenLpKega3__imgtxt2.imgtxt2 {
  padding: 37px 40px 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt2 {
    padding: 19px 20px 23px;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__img2 {
  padding: 0px 0px 0 20px;
  width: 310px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__img2 {
    width: 100%;
    padding: 12px 0px 0 0;
  }
  .p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__img2 img {
    width: 100%;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__txt2 {
  padding: 0 20px 0 0;
  width: calc(100% - 310px);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__txt2 {
    padding: 0;
    width: 100%;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega__ttl2 {
  margin-bottom: 24px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega__ttl2 {
    margin-bottom: 13px;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__lst3 li {
  width: calc((100% - 60px) / 3);
  margin-bottom: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__lst3 li {
    width: 100%;
    margin-bottom: 7px;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__lst3 li:nth-child(2n) {
  margin-left: 0;
}
.p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__lst3 li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__lst3 li:not(:last-child) {
    margin-right: 0;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt2 .p-hokenLpKega3__lst3 li:last-child {
    margin-bottom: 0;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt3 {
  padding: 42px 40px 40px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt3 {
    padding: 19px 20px 23px;
    margin-bottom: 0;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt3 .p-hokenLpKega3__img2 {
  width: 310px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt3 .p-hokenLpKega3__img2 {
    width: 100%;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt3 .p-hokenLpKega3__txt2 {
  width: calc(100% - 310px);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt3 .p-hokenLpKega3__txt2 {
    width: 100%;
  }
}
.p-hokenLpKega3__imgtxt2.imgtxt3 .p-hokenLpKega__ttl2 {
  margin-bottom: 28px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__imgtxt2.imgtxt3 .p-hokenLpKega__ttl2 {
    margin-bottom: 13px;
  }
}
.p-hokenLpKega3__imgtxt2 .p-hokenLpKega__txt4 {
  width: 100%;
}
.p-hokenLpKega3__txt1 {
  width: calc(50% - 40px);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__txt1 {
    width: 100%;
  }
}
.p-hokenLpKega3__txt2 {
  width: 50%;
  padding: 0 20px 0 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__txt2 {
    width: 100%;
    padding: 0;
  }
}
.p-hokenLpKega3__img1 {
  width: 50%;
  margin-top: 6px;
  text-align: right;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__img1 {
    width: 100%;
    margin-top: 30px;
  }
}
.p-hokenLpKega3__img1:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url("/img/hoken/lp/kega_hoken/img1-1.png") no-repeat right bottom;
  background-size: cover;
  position: absolute;
  bottom: -65px;
  right: -66px;
  z-index: 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__img1:after {
    width: 110%;
    height: 110%;
    bottom: -15%;
    right: -15%;
  }
}
.p-hokenLpKega3__img1 img {
  position: relative;
  z-index: 9;
}
.p-hokenLpKega3__img2 {
  width: 50%;
  text-align: center;
  padding: 0 0 0 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega3__img2 {
    width: 100%;
    padding: 11px 0 0;
  }
  .p-hokenLpKega3__img2 img {
    width: 100%;
  }
}

.p-hokenLpKega4 {
  background: #F7F6F4;
  padding: 81px 0 67px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4 {
    padding: 40px 0;
  }
}
.p-hokenLpKega4 .p-hokenLpKega__ttl1 {
  padding: 57px 0 31px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4 .p-hokenLpKega__ttl1 {
    padding: 35px 0 15px !important;
  }
}
.p-hokenLpKega4 .p-hokenLpKega__ttl1 img {
  position: relative;
  top: 14px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4 .p-hokenLpKega__ttl1 img {
    max-width: 240px;
  }
}
.p-hokenLpKega4 .p-hokenLpKega__ttl1--wid {
  margin-top: 31px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4 .p-hokenLpKega__ttl1--wid {
    display: block;
    margin-top: 35px;
  }
}
.p-hokenLpKega4__bl1 {
  margin-top: 61px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__bl1 {
    margin-top: 30px;
  }
}
.p-hokenLpKega4__ttl1 {
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: bold;
  color: #3A64AD;
  border-radius: 10px;
  border: 5px solid #3A64AD;
  text-align: center;
  padding: 2px 0;
}
.p-hokenLpKega4__ttl1.style-1 {
  border-color: #4CB5B9;
  color: #4CB5B9;
}
.p-hokenLpKega4__text2 {
  font-size: 2rem;
  line-height: 28px;
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 19px 0 12px;
  background: #3A64AD;
  max-width: 460px;
  margin: 30px auto;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text2 {
    font-size: 1.4rem;
    line-height: 1;
  }
}
.p-hokenLpKega4__text2 span {
  font-size: 2.4rem;
  position: relative;
  top: 1px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text2 span {
    font-size: 1.6rem;
    line-height: 28px;
  }
}
.p-hokenLpKega4__text2 span::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 11px;
  width: 123px;
  height: 4px;
  background: url("/img/hoken/lp/kega_hoken/img-line1.png") no-repeat;
  background-size: 123px 4px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text2 span::before {
    left: 6px;
    width: 82px;
    height: 3px;
    background-size: 82px 3px;
  }
}
.p-hokenLpKega4__text3 {
  font-size: 1.8rem;
  line-height: 24px;
  font-weight: bold;
  position: relative;
  color: #333333;
  padding-left: 12px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text3 {
    font-size: 1.6rem;
    line-height: 23px;
  }
}
.p-hokenLpKega4__text3::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  background: #3A64AD;
  border-radius: 50%;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text3::before {
    top: 8px;
  }
}
.p-hokenLpKega4__text3 + .p-hokenLpKega4__text3 {
  margin-top: 8px;
}
.p-hokenLpKega4__text3 span {
  color: #E55552;
}
.p-hokenLpKega4__img {
  margin-top: 25px;
}
.p-hokenLpKega4__text4 {
  font-size: 2rem;
  font-weight: 500;
  position: relative;
  color: #333333;
  padding-left: 25px;
  margin-top: -4px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text4 {
    font-size: 1.6rem;
    line-height: 22px;
    margin-top: 15px;
  }
}
.p-hokenLpKega4__text4::before {
  content: "※";
  position: absolute;
  top: 9px;
  left: 3px;
  font-size: 1.6rem;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text4::before {
    top: 4px;
  }
}
.p-hokenLpKega4__text4 span {
  color: #E55552;
  font-weight: bold;
}
.p-hokenLpKega4__text5 {
  font-size: 2rem;
  line-height: 28px;
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 18px 20px 8px;
  background: #4CB5B9;
  max-width: 460px;
  margin: 30px auto;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text5 {
    font-size: 1.4rem;
    line-height: 1;
    padding: 11px 0;
  }
}
.p-hokenLpKega4__text5-1 {
  font-size: 2.4rem;
  position: relative;
  top: 1px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text5-1 {
    font-size: 1.6rem;
    line-height: 28px;
  }
}
.p-hokenLpKega4__text5-1::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 12px;
  width: 78px;
  height: 4px;
  background: url("/img/hoken/lp/kega_hoken/img-line2.png") no-repeat;
  background-size: 78px 4px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text5-1::before {
    left: 8px;
    width: 50px;
    height: 3px;
    background-size: 50px 3px;
  }
}
.p-hokenLpKega4__text5-2 {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: -1px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__text5-2 {
    font-size: 1.2rem;
  }
}
.p-hokenLpKega4__bl2 {
  margin-top: 69px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__bl2 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__bl2 .p-hokenLpKega__text1 {
    padding-left: 10px;
    margin-top: 10px;
  }
}
.p-hokenLpKega4__text6 {
  font-size: 1.2rem;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  color: #333333;
  padding-left: 11px;
}
.p-hokenLpKega4__text6::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  background: #009DE4;
  border-radius: 50%;
}
.p-hokenLpKega4__lst1 {
  margin-top: 49px;
  padding: 36px 0 0 40px;
  border-top: 1px solid #D8D8D8;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega4__lst1 {
    padding: 20px 0 0;
    margin-top: 20px;
  }
}

.p-hokenLpKega6 {
  background: #fff;
  padding: 113px 0 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6 {
    padding: 36px 0 42px;
  }
}
.p-hokenLpKega6 .p-hokenLpKega__ttl1 {
  padding: 66px 0 40px;
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6 .p-hokenLpKega__ttl1 {
    padding: 43px 0 22px;
    margin-bottom: 30px;
  }
}
.p-hokenLpKega6__q {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  color: #333333;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__q {
    font-size: 1.8rem;
    line-height: 2.7rem;
    padding-right: 30px;
  }
}
.p-hokenLpKega6__q:before {
  content: "Q.";
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #009DE4;
  position: absolute;
  top: -3px;
  left: 0;
}
.p-hokenLpKega6__q::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 20px;
  height: 20px;
  background: url("/img/hoken/lp/kega_hoken/faq-open.svg") no-repeat;
  background-size: 20px 20px;
  transition: all 0.3s;
}
.p-hokenLpKega6__q.is-active::after {
  background: url("/img/hoken/lp/kega_hoken/faq-close.svg") no-repeat;
  background-size: 20px 2px;
  height: 2px;
}
.p-hokenLpKega6__a {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #333333;
  position: relative;
  padding: 16px 0 0 30px;
  margin-top: 15px;
  border-top: 1px solid #d8d8d8;
  display: none;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__a {
    padding: 14px 0 0 30px;
    margin-top: 14px;
  }
}
.p-hokenLpKega6__a:before {
  content: "A.";
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #E55552;
  font-weight: bold;
  position: absolute;
  top: 12px;
  left: 0;
}
.p-hokenLpKega6__a span {
  font-weight: bold;
}
.p-hokenLpKega6__a a {
  color: #308bbb;
  text-decoration: underline;
  position: relative;
  margin-right: 37px;
}
.p-hokenLpKega6__a a:hover {
  text-decoration: none;
}
.p-hokenLpKega6__txt1 {
  border-radius: 10px;
  border: 5px solid #e6e4e2;
  padding: 30px 35px 28px;
  background: #fff;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__txt1 {
    padding: 14px 20px 12px;
  }
}
.p-hokenLpKega6__txt2 {
  border-radius: 10px;
  border: 5px solid #e6e4e2;
  padding: 30px 35px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__txt2 {
    display: block;
    padding: 0;
    margin-top: 30px;
  }
}
.p-hokenLpKega6__link1, .p-hokenLpKega6__link2 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: bold;
  color: #595950;
  position: relative;
  padding: 0 20px 0 27px;
  margin: 0 38px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__link1, .p-hokenLpKega6__link2 {
    width: 100%;
    display: block;
    margin: 0;
    padding: 13px 52px;
  }
}
.p-hokenLpKega6__link1:before, .p-hokenLpKega6__link2:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__link1:before, .p-hokenLpKega6__link2:before {
    left: 22px;
  }
}
.p-hokenLpKega6__link1:after, .p-hokenLpKega6__link2:after {
  content: "";
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #8cb340;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__link1:after, .p-hokenLpKega6__link2:after {
    right: 18px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__link1 {
    border-bottom: 1px solid #d8d8d8;
  }
}
.p-hokenLpKega6__link1:before {
  width: 18px;
  height: 22px;
  background: url("/img/hoken/lp/kega_hoken/icon7.png") no-repeat center;
  background-size: cover;
}
.p-hokenLpKega6__link2 {
  padding: 0 20px 0 32px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega6__link2 {
    padding: 13px 52px;
  }
}
.p-hokenLpKega6__link2:before {
  width: 22px;
  height: 22px;
  background: url("/img/hoken/lp/kega_hoken/icon8.png") no-repeat center;
  background-size: cover;
}

.p-hokenLpKega7 {
  background: #3A64AD;
  padding: 27px 0 21px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega7 {
    padding: 20px 0 17px;
  }
}
.p-hokenLpKega7 .l-contentServiceIn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega7 .l-contentServiceIn {
    flex-wrap: wrap;
    padding: 0 10px;
  }
}
.p-hokenLpKega7 .l-contentServiceIn.no-img {
  justify-content: center;
}
.p-hokenLpKega7__img1 {
  margin: 14px 10px 0 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega7__img1 {
    order: 3;
    margin: 13px 0 0;
  }
}
.p-hokenLpKega7__img1 img {
  margin: 0 auto;
}
.p-hokenLpKega7__img2 {
  position: absolute;
  right: -21px;
  top: -55px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega7__img2 {
    bottom: auto;
    top: -43px;
    right: 16px;
    max-width: 196px;
    order: 2;
    margin: -10px -10px 0 0;
    width: 63%;
    z-index: 11;
  }
}
.p-hokenLpKega7 .p-hokenLpKega__info {
  display: block;
  justify-content: center;
  position: relative;
  top: -2px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega7 .p-hokenLpKega__info {
    width: 45%;
    margin-left: 10px;
  }
}
.p-hokenLpKega7 .p-hokenLpKega__info * {
  text-align: center;
}
.p-hokenLpKega7 .p-hokenLpKega__info .p-hokenLpKega__btn1 {
  width: 148px;
  height: 30px;
}
.p-hokenLpKega7 .p-hokenLpKega__info .p-hokenLpKega__txt2 {
  margin-left: 0;
  margin-top: 11px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega7 .p-hokenLpKega__info .p-hokenLpKega__txt2 {
    margin-top: 0;
  }
}
.p-hokenLpKega7 .p-hokenLpKega__info .p-hokenLpKega__text4 {
  margin: 1px 0 5px;
}

.p-hokenLpKega8 {
  background: #F2FAFE;
  padding: 74px 0 76px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 {
    padding: 35px 0 39px;
  }
}
.p-hokenLpKega8__ttl1 {
  font-size: 2rem;
  line-height: 3rem;
  color: #333333;
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__ttl1 {
    margin-bottom: 11px;
  }
}
.p-hokenLpKega8__ttl1:before {
  content: "";
  background: url("/img/hoken/lp/kega_hoken/icon9.png") no-repeat center;
  background-size: cover;
  width: 23px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 0;
}
.p-hokenLpKega8__text1 {
  font-size: 1.2rem;
  line-height: 21px;
  margin-top: 20px;
  color: #333333;
  padding: 0 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__text1 {
    padding: 0;
    margin-top: 15px;
  }
}
.p-hokenLpKega8__text2 {
  font-size: 1.2rem;
  line-height: 21px;
  margin-top: 20px;
  color: #707070;
  padding: 0 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__text2 {
    padding: 0;
    margin-top: 15px;
  }
}
.p-hokenLpKega8 .p-hokenLpKega__list2 {
  padding: 0 9px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .p-hokenLpKega__list2 {
    padding: 0 3px;
  }
}
.p-hokenLpKega8 .p-hokenLpKega__list2 li {
  font-size: 1.4rem;
  line-height: 2.45rem;
}
.p-hokenLpKega8__imgtxt1 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__imgtxt1 {
    display: block;
  }
}
.p-hokenLpKega8__txt1 {
  width: 50%;
  padding: 0 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__txt1 {
    width: 100%;
    padding: 0;
  }
}
.p-hokenLpKega8__img1 {
  width: 50%;
  text-align: center;
  padding-top: 5px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__img1 {
    width: 100%;
    padding: 22px 7px 0;
  }
}
.p-hokenLpKega8 .kantan_web {
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
  background: #fff;
  padding: 55px 60px;
  margin-top: 60px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web {
    padding: 14px 20px 20px;
    margin-top: 30px;
  }
}
.p-hokenLpKega8 .kantan_web__ttl1 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 3rem;
  color: #009DE4;
  border-bottom: #009DE4 solid 2px;
  padding-bottom: 4px;
  margin: 0 0 23px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web__ttl1 {
    padding-bottom: 8px;
    margin: 0 0 14px;
  }
}
.p-hokenLpKega8 .kantan_web__text1 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #333333;
  margin-bottom: 8px;
}
.p-hokenLpKega8 .kantan_web__text2 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 12px;
}
.p-hokenLpKega8 .kantan_web__text2 span {
  color: #ff5b4c;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web__text2 {
    line-height: 22px;
  }
}
.p-hokenLpKega8 .kantan_web .link01 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  position: relative;
  padding-left: 20px;
  display: inline-block;
  margin-top: -2px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .link01 {
    padding-left: 16px;
  }
}
.p-hokenLpKega8 .kantan_web .link01 a {
  color: #308bbb;
  text-decoration: underline;
}
.p-hokenLpKega8 .kantan_web .link01:before {
  content: "";
  border-style: solid;
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #009DE4;
  position: absolute;
  top: 6px;
  left: 8px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .link01:before {
    left: 3px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenLpKega8 .kantan_web .link01:hover a {
    text-decoration: none;
  }
}
.p-hokenLpKega8 .kantan_web .txtbld {
  font-weight: bold;
}
.p-hokenLpKega8 .kantan_web .txtbld .checkboxBg {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #333;
  background: #ffffff url("/img/hoken/lp/kega_hoken/checkbox__off.png") no-repeat left 65px center;
  border-radius: 4px;
  border: 2px solid #e6e4e2;
  width: 480px;
  height: 44px;
  padding: 9px 11px;
  display: block;
  cursor: pointer;
  margin: 0 auto;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .txtbld .checkboxBg {
    width: 100%;
    height: auto;
    padding: 10px 20px 10px 45px;
    text-align: left;
    background: #ffffff url("/img/hoken/lp/kega_hoken/checkbox__off.png") no-repeat left 20px top 13px;
  }
}
.p-hokenLpKega8 .kantan_web .txtbld input[type=checkbox]:checked + label {
  background: #ffffff url("/img/hoken/lp/kega_hoken/checkbox__on.png") no-repeat left 65px center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .txtbld input[type=checkbox]:checked + label {
    background: #ffffff url("/img/hoken/lp/kega_hoken/checkbox__on.png") no-repeat left 20px top 13px;
  }
}
.p-hokenLpKega8 .kantan_web .txtbld input[type=checkbox] {
  display: none;
}
.p-hokenLpKega8 .kantan_web .seikyo_select {
  margin: 24px 0 47px 0;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .seikyo_select {
    display: block;
    margin: 17px 0 18px 0;
  }
}
.p-hokenLpKega8 .kantan_web .seikyo_select li {
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #595950;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .seikyo_select li {
    margin: 0 0 5px;
  }
}
.p-hokenLpKega8 .kantan_web__btn {
  font-size: 1.6rem;
  line-height: 60px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  width: 288px;
  height: 60px;
  border-radius: 50px;
  background: #ff5b4c;
  border: 0;
  display: block;
  margin: 40px auto 25px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web__btn {
    width: 100%;
    max-width: 292px;
    margin: 31px auto 15px;
  }
}
.p-hokenLpKega8 .kantan_web__btn a {
  line-height: 2.4rem;
}
.p-hokenLpKega8 .kantan_web__btn:after {
  content: "";
  border-style: solid;
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.p-hokenLpKega8 .kantan_web .btn_default .kantan_web__btn {
  background: #ccc;
}
.p-hokenLpKega8 .kantan_web .alert02 {
  color: #ff5b4c;
  font-size: 1.6rem;
  line-height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .alert02 {
    text-align: center;
    font-size: 1.4rem;
  }
}
.p-hokenLpKega8 .kantan_web .alert02 img {
  margin-right: 10px;
  height: 20px;
}
.p-hokenLpKega8 .kantan_web .radioBg {
  width: 235px;
  display: inline-block;
  overflow: hidden;
  border: 2px solid #e6e4e2;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 8px 0 8px 37px;
  cursor: pointer;
  background: url("/img/hoken/lp/kega_hoken/radio_off.png") no-repeat left 13px center;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .radioBg {
    width: 100%;
  }
}
.p-hokenLpKega8 .kantan_web input[type=radio]:checked + label {
  background: url("/img/hoken/lp/kega_hoken/radio_on.png") no-repeat left 13px center;
}
.p-hokenLpKega8 .kantan_web input[type=radio] {
  display: none;
}
.p-hokenLpKega8 .kantan_web .column03 {
  border-radius: 4px;
  background: #f8f8f8;
  border: 1px solid #e6e4e2;
  padding: 25px 15px 31px;
  margin-top: 23px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8 .kantan_web .column03 {
    margin-top: 11px;
    padding: 14px 20px 21px 16px;
  }
}
.p-hokenLpKega8 .kantan_web .p-hokenLpKega__list1 {
  margin-top: 0;
}
.p-hokenLpKega8__table1 {
  border-collapse: collapse;
  border: 1px solid #d8d8d8;
  width: calc(100% - 80px);
  margin: 80px auto 0;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__table1 {
    width: 100%;
    margin: 41px auto 0;
  }
}
.p-hokenLpKega8__table1 th, .p-hokenLpKega8__table1 td {
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #333333;
  border: 1px solid #d8d8d8;
}
.p-hokenLpKega8__table1 th {
  font-weight: bold;
  background: #f8f8f8;
  text-align: left;
  padding: 15px 20px;
  width: 208px;
  vertical-align: top;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__table1 th {
    padding: 5px 9px 4px;
    width: 33.5%;
  }
}
.p-hokenLpKega8__table1 td {
  background: #fff;
  padding: 15px 20px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__table1 td {
    padding: 5px 9px 4px;
  }
}
.p-hokenLpKega8__table1 td p {
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega8__table1 td p {
    margin-bottom: 5px;
  }
}
.p-hokenLpKega8__table1 td p:last-child {
  margin-bottom: 0;
}
.p-hokenLpKega8__table1 td span {
  font-weight: bold;
  display: block;
}

/* .page-hoken_lp_kega_hoken_202205 */
.page-hoken_lp_kega_hoken_202205 .p-hokenLpKegaMv__txt {
  width: 75%;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKegaMv__txt {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega__info {
    width: 40%;
  }
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega__btn2 {
    height: auto;
    line-height: 18px;
  }
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega__btn2 span {
    text-indent: 0;
    display: block;
    width: 100%;
    top: 0;
  }
}
.page-hoken_lp_kega_hoken_202205 .p-hokenLpKega__btn2 span:before {
  left: 21px;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega__txt3 {
    width: 58%;
  }
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega__txt3:before {
    position: absolute;
    top: -53px;
  }
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega__text4 {
    display: inline-block;
  }
}
.page-hoken_lp_kega_hoken_202205 .p-hokenLpKega2__info1 {
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega2__info1 {
    justify-content: space-between;
  }
}
.page-hoken_lp_kega_hoken_202205 .p-hokenLpKega7 .l-contentServiceIn {
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega7 .p-hokenLpKega__info {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .page-hoken_lp_kega_hoken_202205 .p-hokenLpKega7 .p-hokenLpKega__btn1 {
    margin-right: 15px;
  }
}

/* .p-hokenLpKega202406 */
.p-hokenLpKega202406 .p-hokenLpKegaMv {
  background: url("/img/hoken/lp/kega_hoken/sincoop_kega202406/mv.png") no-repeat center;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega202406 .p-hokenLpKegaMv {
    background: url("/img/hoken/lp/kega_hoken/sincoop_kega202406/mv-sp.png") no-repeat top center;
    background-size: 100% auto;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega202406 .p-hokenLpKegaMv__text2 {
    width: 56%;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega202406 .p-hokenLpKegaMv__subttl {
    margin-top: 60px;
  }
}

/* .p-hokenLpKega202502 */
.p-hokenLpKega202502 .p-hokenLpKegaMv {
  background: url("/img/hoken/lp/kega_hoken/sincoop_kega202502/mv.png") no-repeat center;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega202502 .p-hokenLpKegaMv {
    background: url("/img/hoken/lp/kega_hoken/sincoop_kega202502/mv-sp.png") no-repeat top center;
    background-size: 100% auto;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega202502 .p-hokenLpKegaMv__text2 {
    width: 56%;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenLpKega202502 .p-hokenLpKegaMv__subttl {
    margin-top: 60px;
  }
}

/*------------------------------------------------------------
component
------------------------------------------------------------*/
.c-hokenColumnArticle01__link,
.c-hokenColumnArticle02__link,
.c-hokenColumnArticle03__link {
  padding: 15px;
  height: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: auto 1fr;
  gap: 10px;
  border: 1.5px solid #D9D9D9;
  border-radius: 5px;
  align-items: center;
  color: #000;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle01__link,
  .c-hokenColumnArticle02__link,
  .c-hokenColumnArticle03__link {
    padding: 40px;
    border-width: 3px;
    border-radius: 10px;
    grid-template-columns: 180px 1fr;
    grid-template-rows: auto 1fr;
    gap: 20px;
    align-items: start;
  }
}
.c-hokenColumnArticle01__image,
.c-hokenColumnArticle02__image,
.c-hokenColumnArticle03__image {
  aspect-ratio: 1/1;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle01__image,
  .c-hokenColumnArticle02__image,
  .c-hokenColumnArticle03__image {
    grid-column: 1/2;
    grid-row: 1/3;
  }
}
.c-hokenColumnArticle01__image img,
.c-hokenColumnArticle02__image img,
.c-hokenColumnArticle03__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle01__image img,
  .c-hokenColumnArticle02__image img,
  .c-hokenColumnArticle03__image img {
    border-radius: 10px;
  }
}
.c-hokenColumnArticle01__title,
.c-hokenColumnArticle02__title,
.c-hokenColumnArticle03__title {
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle01__title,
  .c-hokenColumnArticle02__title,
  .c-hokenColumnArticle03__title {
    font-size: 1.8rem;
  }
}

.c-hokenColumnArticle01__description,
.c-hokenColumnArticle02__description {
  font-size: 1.4rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
@media screen and (max-width: 640px) {
  .c-hokenColumnArticle01__description,
  .c-hokenColumnArticle02__description {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}

.c-hokenColumnArticle02__link {
  grid-template-rows: 60% 1fr;
  align-items: center;
  row-gap: 0;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle02__link {
    grid-template-rows: 1fr 1fr;
    row-gap: 20px;
  }
}
.c-hokenColumnArticle02__image {
  grid-column: 1/2;
  grid-row: 1/3;
}
.c-hokenColumnArticle02__title {
  margin-top: auto;
  margin-bottom: auto;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle02__title {
    margin-bottom: 0;
  }
}
.c-hokenColumnArticle02__more {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  column-gap: 5px;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle02__more {
    margin-top: 0;
    font-size: 1.6rem;
  }
}
.c-hokenColumnArticle02__more__icon {
  min-width: 30px;
  width: 30px;
  height: 30px;
  color: #41B4E6;
}
@media screen and (min-width: 641px) {
  .c-hokenColumnArticle02__more__icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 640px) {
  .c-hokenColumnArticle03__body {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}
.c-hokenColumnArticle03__description {
  font-size: 1.4rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.c-tag01 {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
@media screen and (min-width: 641px) {
  .c-tag01 {
    gap: 10px;
  }
}
.c-tag01 li {
  padding: 5px;
  background-color: #E4F7FF;
  color: #41B4E6;
  font-weight: 700;
  border-radius: 5px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .c-tag01 li {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.2rem;
  }
}

.p-hokenFFColumnTaxonomy .wp-pagenavi {
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  position: relative;
}
@media screen and (min-width: 641px) {
  .p-hokenFFColumnTaxonomy .wp-pagenavi {
    max-width: 380px;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .page,
.p-hokenFFColumnTaxonomy .wp-pagenavi .current {
  width: 50px;
  height: 50px;
  border: 1.5px solid #41B4E6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenFFColumnTaxonomy .wp-pagenavi .page,
  .p-hokenFFColumnTaxonomy .wp-pagenavi .current {
    width: 60px;
    height: 60px;
    border-width: 3px;
    border-radius: 10px;
    font-size: 1.8rem;
  }
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .current {
  background-color: #41B4E6;
  color: #fff;
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .pages {
  display: none;
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .extend {
  display: none;
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .nextpostslink,
.p-hokenFFColumnTaxonomy .wp-pagenavi .previouspostslink {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-indent: -9999px;
  position: absolute;
}
@media screen and (min-width: 641px) {
  .p-hokenFFColumnTaxonomy .wp-pagenavi .nextpostslink,
  .p-hokenFFColumnTaxonomy .wp-pagenavi .previouspostslink {
    width: 70px;
    height: 70px;
  }
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .nextpostslink::before,
.p-hokenFFColumnTaxonomy .wp-pagenavi .previouspostslink::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: no-repeat center;
  background-size: contain;
}
@media screen and (min-width: 641px) {
  .p-hokenFFColumnTaxonomy .wp-pagenavi .nextpostslink::before,
  .p-hokenFFColumnTaxonomy .wp-pagenavi .previouspostslink::before {
    width: 70px;
    height: 70px;
  }
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .previouspostslink {
  margin-right: auto;
  left: 0;
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .previouspostslink::before {
  background-image: url("/img/common/icon_arrow_left_circle03.svg");
}
@media screen and (min-width: 641px) {
  .p-hokenFFColumnTaxonomy .wp-pagenavi .previouspostslink::before {
    background-image: url("/img/common/icon_arrow_left_circle04.svg");
  }
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .nextpostslink {
  margin-left: auto;
  right: 0;
}
.p-hokenFFColumnTaxonomy .wp-pagenavi .nextpostslink::before {
  background-image: url("/img/common/icon_arrow_right_circle03.svg");
}
@media screen and (min-width: 641px) {
  .p-hokenFFColumnTaxonomy .wp-pagenavi .nextpostslink::before {
    background-image: url("/img/common/icon_arrow_right_circle04.svg");
  }
}

.c-date01 {
  color: #41B4E6;
  font-size: 1.4rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-date01 {
    font-size: 1.2rem;
  }
}
.c-date01__publish + .c-date01__last {
  border-left: 1px solid #DDDDDD;
  padding-left: 10px;
  margin-left: 10px;
}

.c-pageHeaderPost01__title {
  font-size: 2.4rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-pageHeaderPost01__title {
    font-size: 4rem;
  }
}
.u-fw700 {
  font-weight: 700;
}

.u-w600 {
  width: 600px;
}

.u-blue {
  color: #2579BE;
}

.u-scroll {
  overflow: auto;
}

.c-contentPost01 {
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 {
    font-size: 1.6rem;
  }
}
.c-contentPost01 .c-wrap01 {
  padding: 25px;
  background: #E4F7FF;
}
.c-contentPost01 .c-wrap01 > :first-child {
  margin-top: 0;
}
.c-contentPost01 .c-wrap01 > :last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 .c-wrap01 {
    padding: 80px;
  }
}
@media screen and (max-width: 640px) {
  .c-contentPost01 .c-tbStyle1 table {
    min-width: 478px;
    border: 1px solid #DDDDDD;
  }
  .c-contentPost01 .c-tbStyle1 tr {
    text-align: center;
  }
  .c-contentPost01 .c-tbStyle1 tr {
    display: flex;
  }
  .c-contentPost01 .c-tbStyle1 tr:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }
  .c-contentPost01 .c-tbStyle1 th,
  .c-contentPost01 .c-tbStyle1 td {
    width: 33.3333333333%;
    padding: 18px 15px 16px;
    border: none;
  }
  .c-contentPost01 .c-tbStyle1 th:not(:last-child),
  .c-contentPost01 .c-tbStyle1 td:not(:last-child) {
    border-right: 1px solid #DDDDDD;
  }
}
.c-contentPost01 p, .c-contentPost01 ul, .c-contentPost01 ol {
  margin-bottom: 1.5em;
}
.c-contentPost01 h2 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 h2 {
    margin-top: 80px;
    margin-bottom: 20px;
    font-size: 3.2rem;
  }
}
.c-contentPost01 h3 {
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  column-gap: 7px;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 h3 {
    margin-top: 80px;
    margin-bottom: 20px;
    column-gap: 14px;
    font-size: 2.4rem;
  }
}
.c-contentPost01 h3::before {
  content: "";
  width: 3px;
  height: 17px;
  background: linear-gradient(0deg, #41B4E6 0%, #41B4E6 50%, #FA8246 50%, #FA8246 100%);
}
@media screen and (min-width: 641px) {
  .c-contentPost01 h3::before {
    width: 6px;
    height: 20px;
  }
}
.c-contentPost01 h4 {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #41B4E6;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}
.c-contentPost01 h2 > *, .c-contentPost01 h3 > *, .c-contentPost01 h4 > * {
  margin-top: 0;
}
.c-contentPost01 img {
  margin-bottom: 40px;
  display: block;
  border-radius: 5px;
  overflow: hidden;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 img {
    margin: 0 auto 80px;
    max-width: 700px;
    border-radius: 5px;
  }
}
.c-contentPost01 ul {
  padding-left: 20px;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 ul {
    padding-left: 40px;
  }
}
.c-contentPost01 ul li {
  margin-top: 2.5px;
  position: relative;
  padding-left: 11px;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 ul li {
    margin-top: 5px;
    padding-left: 18px;
  }
}
.c-contentPost01 ul li:before {
  content: "";
  background: #82CDF3;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  top: 7px;
  left: 0;
  z-index: 2;
}
@media screen and (min-width: 641px) {
  .c-contentPost01 ul li:before {
    width: 8px;
    height: 8px;
    top: 8px;
  }
}
.c-contentPost01 ul + ol {
  margin-top: 40px;
}
.c-contentPost01 ol {
  padding-left: 15px;
  margin-bottom: 27px;
  counter-reset: counter;
}
.c-contentPost01 ol li {
  position: relative;
  /* padding-left: 14px; */
  margin-bottom: 4px;
  counter-increment: counter;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 ol li {
    letter-spacing: 0.2px;
    /* padding-left: 15px; */
  }
}
.c-contentPost01 ol li:before {
  content: counter(counter) ".";
  font-size: 1.6rem;
  font-weight: 700;
  color: #2579BE;
  /* position: absolute;
  top: -3px;
  left: 0;
  z-index: 2; */
  padding-right: 0.4em;
  text-align: right;
  display: inline-block;
  width: 2em;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 ol li:before {
    top: -1px;
  }
}
.c-contentPost01 ol + a {
  margin-top: 60px;
}
.c-contentPost01 a {
  color: #41B4E6;
}
.c-contentPost01 table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #DDDDDD;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 table {
    border-left: none;
    border-right: none;
  }
}
@media screen and (max-width: 640px) {
  .c-contentPost01 table tr:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }
}
.c-contentPost01 table td {
  line-height: 24px;
  padding: 19px 19px 15px;
  border: 1px solid #DDDDDD;
  background-color: #fff;
  text-align: center;
}
.c-contentPost01 table td:first-child {
  background-color: #F3F3F3;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 table td {
    width: 100% !important;
    padding: 13px 15px 12px;
    display: block;
    border: none;
    text-align: left;
  }
}
.c-contentPost01 table.c-tbStyle1 {
  border: 1px solid #DDDDDD !important;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 table.c-tbStyle1 {
    min-width: 478px;
  }
}
.c-contentPost01 table.c-tbStyle1 td {
  text-align: center;
}
.c-contentPost01 table.c-tbStyle1 td:first-child {
  background: #fff !important;
}
.c-contentPost01 table.c-tbStyle1 tr:first-child td {
  background: #F3F3F3 !important;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 table.c-tbStyle1 tr {
    display: flex;
    justify-content: flex-start;
  }
}
.c-contentPost01 .table-scroll {
  overflow: auto;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 .table-scroll {
    position: relative;
  }
  .c-contentPost01 .table-scroll::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 60px;
    height: 60px;
    background: url("../img/common/icon-scroll.svg") no-repeat center;
    background-size: 60px 60px;
    opacity: 1;
    transition: 300ms;
  }
  .c-contentPost01 .table-scroll.is-hide::before {
    opacity: 0;
  }
  .c-contentPost01 .table-scroll.hide::before {
    display: none !important;
  }
}
.c-contentPost01 .table-scroll table {
  margin-top: 0;
}
.c-contentPost01 .wp-caption img {
  margin-bottom: 0;
}
.c-contentPost01 .wp-caption .wp-caption-text {
  text-align: right;
}
.c-contentPost01 .aligncenter {
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .c-contentPost01 .aligncenter {
    width: 100% !important;
  }
}

/*------------------------------------------------------------
/service/hoken/enquete/input.php
------------------------------------------------------------*/
.p-hokenEnqueteInput {
  padding: 41px 0 133px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteInput {
    padding: 19px 20px 35px;
  }
}
.p-hokenEnqueteInput1 .c-hokenText01 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteInput1 .c-hokenText01 {
    margin-top: 28px;
  }
}
.p-hokenEnqueteInput1 .c-accordion {
  margin-top: 11px;
}
.p-hokenEnqueteInput1 .c-hokenText02 {
  margin-top: 25px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteInput1 .c-hokenText02 {
    margin-top: 18px;
  }
}
.p-hokenEnqueteInput1 .c-step3 {
  margin-top: 53px;
  margin-bottom: 97px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteInput1 .c-step3 {
    margin-top: 12px;
    margin-bottom: 37px;
  }
}

/*------------------------------------------------------------
/service/hoken/enquete/confirm.php
------------------------------------------------------------*/
.p-hokenEnqueteConfirm {
  padding: 41px 0 133px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteConfirm {
    padding: 19px 20px 35px;
  }
}
.p-hokenEnqueteConfirm1 .c-hokenText01 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteConfirm1 .c-hokenText01 {
    margin-top: 28px;
  }
}
.p-hokenEnqueteConfirm1 .c-accordion {
  margin-top: 11px;
}
.p-hokenEnqueteConfirm1 .c-hokenText02 {
  margin-top: 25px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteConfirm1 .c-hokenText02 {
    margin-top: 17px;
  }
}
.p-hokenEnqueteConfirm1 .c-step3 {
  margin-top: 43px;
  margin-bottom: 80px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteConfirm1 .c-step3 {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

/*------------------------------------------------------------
/service/hoken/enquete/complete.php
------------------------------------------------------------*/
.p-hokenEnqueteComplete {
  padding: 41px 0 199px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteComplete {
    padding: 19px 20px 106px;
  }
}
.p-hokenEnqueteComplete1 .c-step3 {
  margin-top: 43px;
  margin-bottom: 134px;
}
@media screen and (max-width: 640px) {
  .p-hokenEnqueteComplete1 .c-step3 {
    margin-top: 20px;
    margin-bottom: 59px;
  }
}

/*------------------------------------------------------------
/service/hoken/intention/input.php
------------------------------------------------------------*/
.p-hokenIntentionInput {
  padding: 41px 0 133px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionInput {
    padding: 19px 20px 35px;
  }
}
.p-hokenIntentionInput1 .c-hokenText01 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionInput1 .c-hokenText01 {
    margin-top: 28px;
  }
}
.p-hokenIntentionInput1 .c-accordion {
  margin-top: 11px;
}
.p-hokenIntentionInput1 .c-hokenText02 {
  margin-top: 25px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionInput1 .c-hokenText02 {
    margin-top: 18px;
  }
}
.p-hokenIntentionInput1 .c-step3 {
  margin-top: 60px;
  margin-bottom: 97px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionInput1 .c-step3 {
    margin-top: 30px;
    margin-bottom: 37px;
  }
}
.p-hokenIntentionInput1 .c-formPrivacyText {
  margin-bottom: 32px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionInput1 .c-formPrivacyText {
    margin-bottom: 30px;
  }
}

/*------------------------------------------------------------
/service/hoken/intention/confirm.php
------------------------------------------------------------*/
.p-hokenIntentionConfirm {
  padding: 41px 0 133px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionConfirm {
    padding: 19px 20px 35px;
  }
}
.p-hokenIntentionConfirm1 .c-step3 {
  margin-top: 43px;
  margin-bottom: 80px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionConfirm1 .c-step3 {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

/*------------------------------------------------------------
/service/hoken/intention/complete.php
------------------------------------------------------------*/
.p-hokenIntentionComplete {
  padding: 41px 0 199px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionComplete {
    padding: 19px 20px 106px;
  }
}
.p-hokenIntentionComplete1 .c-step3 {
  margin-top: 43px;
  margin-bottom: 134px;
}
@media screen and (max-width: 640px) {
  .p-hokenIntentionComplete1 .c-step3 {
    margin-top: 20px;
    margin-bottom: 59px;
  }
}

/*------------------------------------------------------------
/hoken/movie
------------------------------------------------------------*/
.p-hokenMovieSection {
  padding: 15px;
  border: 1.5px solid #D9D9D9;
  border-radius: 5px;
  display: grid;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenMovieSection {
    padding: 30px;
    border-width: 3px;
    border-radius: 10px;
    grid-template-columns: 450px 1fr;
    column-gap: 30px;
  }
}
.p-hokenMovieSection__image {
  margin: 0 !important;
}
.p-hokenMovieSection__image img {
  width: 100%;
  height: auto;
}
.p-hokenMovieSection__title {
  font-size: 1.8rem;
}
@media screen and (min-width: 641px) {
  .p-hokenMovieSection__title {
    font-size: 2.4rem;
  }
}
.p-hokenMovieSection__action {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenMovieSection__action {
    margin-top: 30px;
    gap: 20px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenMovieSection__action__details .c-hokenBtn11 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .p-hokenMovieSection__action__details .c-hokenBtn11__label,
  .p-hokenMovieSection__action__details .c-hokenBtn11__afterIcon {
    margin-left: auto;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: grey;
  opacity: 0.6;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.modal {
  height: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 10001;
  width: 100%;
  max-width: 640px;
  max-height: 370px;
}
@media screen and (max-width: 640px) {
  .modal {
    max-width: 320px;
    max-height: 220px;
  }
}

.modal p {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 14px;
}

.modal-close__wrap {
  position: absolute;
  right: 40px;
  top: 20px;
}

.modal-close {
  background: transparent;
  border-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 28px;
  display: block;
  height: 28px;
}

.modal-close span {
  position: relative;
  width: 100%;
  height: 1px;
  background: black;
  display: block;
}

.modal-close span:nth-child(1) {
  transform: rotate(45deg);
}

.modal-close span:nth-child(2) {
  transform: rotate(-45deg);
}

.modal-open {
  cursor: pointer;
  background: orange;
  border: orange;
  color: #fff;
  padding: 10px 12px;
}

.overlay.open {
  display: block;
}

.modal.open {
  display: block;
}

/*------------------------------------------------------------
保険を探す
------------------------------------------------------------*/
.page-hoken__search .c-breadcrumb2 {
  margin-bottom: 10px;
}
@media screen and (min-width: 641px) {
  .page-hoken__search .c-breadcrumb2 {
    margin-bottom: 20px;
  }
}

.p-hokenSearchContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #E4F7FF;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchContainer {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.p-hokenSearchResult__list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__list {
    row-gap: 30px;
  }
}

.p-hokenSearchResult__item {
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #333;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item {
    padding: 40px;
    border-width: 3px;
    border-radius: 10px;
  }
}
.p-hokenSearchResult__item[data-recommendation=true] {
  border-width: 2.5px;
  border-color: #FA8246;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item[data-recommendation=true] {
    border-width: 5px;
  }
}
.p-hokenSearchResult__item__recommendation {
  margin: -15px -15px 15px;
  padding: 5px 15px;
  background-color: #FA8246;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__recommendation {
    margin: -40px -40px 20px;
    padding: 5px 40px 10px;
    font-size: 20px;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__outline {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 40px;
    align-items: center;
  }
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__action {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 20px;
  }
}
.p-hokenSearchResult__item__image {
  margin-bottom: 15px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__image {
    margin-bottom: 0;
  }
}
.p-hokenSearchResult__item__image a {
  display: block;
}
.p-hokenSearchResult__item__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__tags {
    gap: 10px;
  }
}
.p-hokenSearchResult__item__subTitle {
  margin-top: 15px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__subTitle {
    margin-top: 12px;
    font-size: 1.6rem;
  }
}
.p-hokenSearchResult__item__title {
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__title {
    font-size: 2.4rem;
  }
}
.p-hokenSearchResult__item__description {
  margin-top: 15px;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__description {
    margin-top: 12px;
    font-size: 1.2rem;
  }
}
.p-hokenSearchResult__item__details {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__details {
    margin-top: 0;
    column-gap: 20px;
  }
}
.p-hokenSearchResult__item__apply {
  margin-top: 10px;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__apply {
    margin-top: 0;
  }
}
.p-hokenSearchResult__item__button {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  border-radius: 9999px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__button {
    padding: 20px;
    height: auto;
    column-gap: 10px;
    justify-content: center;
    font-size: 1.8rem;
  }
}
.p-hokenSearchResult__item__button--view {
  background-color: #fff;
  border: 1px solid #41B4E6;
  color: #41B4E6;
}
.p-hokenSearchResult__item__button--document {
  background-color: #41B4E6;
}
.p-hokenSearchResult__item__button--apply {
  background-color: #08336E;
}
.p-hokenSearchResult__item__button__icon {
  min-width: 15px;
  width: 15px;
  height: 15px;
  fill: #fff;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__button__icon {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 640px) {
  .p-hokenSearchResult__item__button__label {
    text-align: center;
  }
}
.p-hokenSearchResult__item__no {
  margin-top: 7.5px;
  color: #888;
  font-size: 1.4rem;
}
@media screen and (min-width: 641px) {
  .p-hokenSearchResult__item__no {
    margin-top: 10px;
    font-size: 1.4rem;
    text-align: right;
  }
}

/*------------------------------------------------------------
リフォームTOP
------------------------------------------------------------*/
.p-reformTop01 {
  width: 100%;
  min-width: 1120px;
  height: 675px;
  margin: 0 auto;
  background-color: #ffffff;
  background-image: url("../img/reform/index_mv.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  overflow: hidden;
}
.p-reformTop01 .p-reformTop01__in {
  height: 560px;
  padding-top: 420px;
  position: relative;
}
.p-reformTop01 .p-reformTop01__txt01 {
  width: 470px;
  position: absolute;
  top: 175px;
  left: 0;
}
.p-reformTop01 .p-reformTop01__txt01--color1 {
  font-size: 2.7rem;
  color: #043b18;
  letter-spacing: -4px;
  padding-top: 8px;
  padding-bottom: 16px;
  padding-left: 1px;
}
.p-reformTop01 .p-reformTop01__txt01--color2 {
  font-size: 3.8rem;
  color: #342603;
  letter-spacing: -5px;
}
.p-reformTop01 .p-reformTop01__txt01--color2 span {
  color: red;
}
.p-reformTop01 .p-reformTop01__txt01 .u-redColor {
  color: #d23600;
}
.p-reformTop01 .p-reformTop01__bnr {
  width: 100%;
}
.p-reformTop01 .p-reformTop01__bnr li {
  width: 301px;
  height: 234px;
  margin: 8px -24px;
  padding: 0 18px 0 18px;
  position: relative;
  background-size: 100% 100%;
  background-image: url("../img/reform/mv_bnr_bg01.png");
}
@media screen and (max-width: 640px) {
  .p-reformTop01 .p-reformTop01__bnr li {
    padding: 1% 3% 0;
    margin: 0;
    height: auto;
  }
}
.p-reformTop01 .p-reformTop01__bnr li .bnr-img {
  margin: 8px 7px;
  height: 122px;
  overflow: hidden;
}
.p-reformTop01 .p-reformTop01__bnr li .bnr-img img {
  height: 122px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-reformTop01 .p-reformTop01__bnr li .bnr-img {
    margin: -6px 3px;
    height: auto;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-img img {
    width: 100%;
    height: auto;
  }
}
.p-reformTop01 .p-reformTop01__bnr li .bnr-txt {
  text-align: center;
  color: #342603;
  font-weight: bold;
  padding-top: 10px;
}
.p-reformTop01 .p-reformTop01__bnr li .bnr-txt__txt01 {
  font-size: 2rem;
  line-height: 1;
}
.p-reformTop01 .p-reformTop01__bnr li .bnr-txt__txt02 {
  font-size: 1.3rem;
}
@media screen and (max-width: 640px) {
  .p-reformTop01 .p-reformTop01__bnr li .bnr-txt {
    padding-top: 19px;
    padding-bottom: 30px;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-txt__txt01 {
    font-size: 1.2rem;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-txt__txt02 {
    font-size: 1rem;
  }
}
.p-reformTop01 .p-reformTop01__bnr li .bnr-icon {
  float: right;
  width: 30px;
  height: 30px;
  background: #247404;
  padding-top: 20px;
  right: 18px;
  position: absolute;
  bottom: 28px;
}
.p-reformTop01 .p-reformTop01__bnr li .bnr-icon:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  top: 33%;
  left: 33%;
  transform: rotate(45deg);
}
@media screen and (max-width: 640px) {
  .p-reformTop01 .p-reformTop01__bnr li .bnr-icon {
    width: 18px;
    height: 18px;
    right: 5%;
    bottom: 11%;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-icon:after {
    width: 6px;
    height: 6px;
  }
}
.p-reformTop01 .p-reformTop01__bnr li:hover .bnr-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
@media screen and (max-width: 640px) {
  .p-reformTop01 .p-reformTop01__bnr li:hover .bnr-img img {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.p-reformTop02 {
  margin-bottom: 46px;
  padding: 20px;
  background-image: url("../img/reform/bg01.jpg");
}
.p-reformTop02 .p-reformTop02__contents01 {
  position: relative;
}
.p-reformTop02 .p-reformTop02__contents01 h2 {
  margin-bottom: 16px;
  padding-left: 16px;
  font-size: 2rem;
  font-weight: bold;
  color: #342603;
}
.p-reformTop02 .p-reformTop02__contents01 h2 span {
  margin: 6px 0 0 24px;
  display: inline-block;
  vertical-align: middle;
}
.p-reformTop02 .p-reformTop02__contents01 h2 span img {
  width: 97px;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList {
  padding: 2px 30px 5px;
  background-color: #ffffff;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li {
  padding: 27px 0 21px;
  border-bottom: dotted 1px #4c4c4c;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li:last-child {
  border: none;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li p {
  width: 525px;
  color: #46483c;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li a {
  color: #46483c;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li a:hover {
  text-decoration: none;
  opacity: 1;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList .p-reformTop02__contents01__newsList_data {
  width: 110px;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 2px 0 0 4px;
  color: #666666;
}
.p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList .c-newsCate02 {
  width: 82px;
  margin-right: 20px;
  font-weight: 500;
}
.p-reformTop02 .p-reformTop02__contents01 .c-reformBtn01 {
  position: absolute;
  top: 1px;
  right: 2px;
}

.p-reformTop03 {
  margin-bottom: 44px;
}
.p-reformTop03 .p-reformTop03__contents01 .c-gallery__item {
  margin-bottom: 8px;
}
.p-reformTop03 .p-reformTop03__contents01 .c-gallery__item .u-hover01:hover {
  opacity: 1;
}

.p-reformTop04 {
  margin-bottom: 93px;
}
.p-reformTop04 .p-reformTop04__contents01 h3 {
  margin-bottom: 34px;
  padding: 9px 20px;
  font-size: 1.8rem;
  font-weight: bold;
  position: relative;
}
.p-reformTop04 .p-reformTop04__contents01 h3 a {
  color: #342603;
}
.p-reformTop04 .p-reformTop04__contents01 h3:before, .p-reformTop04 .p-reformTop04__contents01 h3:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  background: url(../img/reform/line.png) repeat-x;
  left: 0;
}
.p-reformTop04 .p-reformTop04__contents01 h3:before {
  top: 0;
}
.p-reformTop04 .p-reformTop04__contents01 h3:after {
  bottom: 0;
}
.p-reformTop04 .p-reformTop04__contents01 .c-iconList__text a, .p-reformTop04 .p-reformTop04__contents01 .c-iconList__text p {
  text-decoration: underline;
}
.p-reformTop04 .p-reformTop04__contents01 .l-left {
  margin-bottom: 50px;
}
.p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList ul li a:hover .c-iconList__image {
  transform: translateY(-5px);
}

@media (min-width: 641px) {
  .p-reformTop04 .u-hover01 img {
    transition: opacity 0.3s ease-in-out;
  }
  .p-reformTop04 .u-hover01:hover img {
    opacity: 0.5;
  }
}
.p-reformTop05 {
  margin-bottom: 74px;
}
.p-reformTop06 {
  margin-bottom: 100px;
}
.p-reformTop06 .p-reformTop06__contents01 .c-showCase {
  margin-bottom: 30px;
}
.p-reformTop06 .p-reformTop06__contents01 .c-showCase:nth-child(1) .c-showCase__body {
  background: #f5f5f5 url("/img/reform/case-icon02.png") no-repeat bottom 20px left 20px;
}
@media screen and (max-width: 640px) {
  .p-reformTop06 .p-reformTop06__contents01 .c-showCase:nth-child(1) .c-showCase__body {
    background: #f5f5f5 url("/img/reform/case-icon02SP.png") no-repeat bottom 17px left 18px;
    background-size: 33px 24px;
  }
}

@media screen and (max-width: 640px) {
  .p-reformTop01 {
    min-width: 100%;
    height: auto;
    margin-bottom: 25px;
    background: none;
  }
  .p-reformTop01 .p-reformTop01__in {
    height: auto;
    padding: 0;
  }
  .p-reformTop01 .p-reformTop01__txt01 {
    width: 100%;
    height: 220px;
    position: static;
    background: url(../img/reform/index_mv_sp.png) center top -23px;
    background-size: cover;
  }
  .p-reformTop01 .p-reformTop01__txt01--wrapper {
    line-height: 1.5;
    position: relative;
    top: 50%;
    left: 20px;
    transform: translate(0, -59%);
  }
  .p-reformTop01 .p-reformTop01__txt01--color1 {
    font-size: 2.5rem;
  }
  .p-reformTop01 .p-reformTop01__txt01--color2 {
    font-size: 2.5rem;
    line-height: 30px;
    letter-spacing: -3.5px;
    position: relative;
    top: 19%;
    left: 4px;
  }
  .p-reformTop01 .p-reformTop01__bnr {
    margin-top: -12px;
    padding: 0 20px;
  }
  .p-reformTop01 .p-reformTop01__bnr li {
    width: calc(50% - 5px);
    padding: 0;
    background: none;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: -13px;
  }
  .p-reformTop01 .p-reformTop01__bnr li:nth-child(2n+1) {
    margin-left: 0;
  }
  .p-reformTop01 .p-reformTop01__bnr li:nth-child(3) .bnr-txt {
    padding: 9px 0 6px 3px;
  }
  .p-reformTop01 .p-reformTop01__bnr li:after {
    content: "";
    width: calc(100% + 18px);
    height: 14px;
    position: absolute;
    bottom: -14px;
    left: -9px;
    background: url("/img/reform/bnr_bottom.png") no-repeat center bottom;
    background-size: 100% 14px;
  }
  .p-reformTop01 .p-reformTop01__bnr li a {
    display: block;
    position: relative;
  }
  .p-reformTop01 .p-reformTop01__bnr li a:before {
    content: "";
    width: 9px;
    height: 77%;
    left: -9px;
    bottom: 0;
    background: url("/img/reform/bnr_before_sp.png") no-repeat center right;
    background-size: 100% auto;
    position: absolute;
  }
  .p-reformTop01 .p-reformTop01__bnr li a:after {
    content: "";
    width: 9px;
    height: 77%;
    right: -9px;
    bottom: 0;
    background: url("/img/reform/bnr_right.png") no-repeat center left;
    background-size: 100% auto;
    position: absolute;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-img {
    margin: 0;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-txt {
    background: #FFF;
    padding: 9px 0 6px;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-txt__txt01 {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-icon {
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    padding-top: 0;
  }
  .p-reformTop01 .p-reformTop01__bnr li .bnr-icon:after {
    width: 4px;
    height: 4px;
  }
  .p-reformTop02 {
    margin-bottom: 30px;
    padding: 30px 20px;
  }
  .p-reformTop02 .p-reformTop02__contents01 h2 {
    margin-bottom: 19px;
    font-size: 1.6rem;
    line-height: 1;
    text-align: center;
    padding-left: 0px;
    padding-right: 2px;
    letter-spacing: 0.2px;
  }
  .p-reformTop02 .p-reformTop02__contents01 h2 span {
    margin: 5px auto 0 auto;
    display: block;
    font-size: 0.9rem;
  }
  .p-reformTop02 .p-reformTop02__contents01 h2 span img {
    width: 70px;
  }
  .p-reformTop02 .p-reformTop02__contents01--wrapper {
    background-color: #fff;
    padding-bottom: 18px;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList {
    padding: 5px 21px 14px;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li {
    padding: 15px 0 13px;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li:last-child {
    border-bottom: dotted 1px #4c4c4c;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li:nth-of-type(2) {
    padding: 15px 0 15px;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList li a {
    width: 100%;
    font-size: 1.4rem;
    padding: 0 0 0 0;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList .p-reformTop02__contents01__newsList_data {
    width: auto;
    margin: 0 1em 7px 0;
    font-size: 1.1rem;
    font-weight: normal;
    letter-spacing: 1.5px;
    padding: 1px 0 0;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList .c-newsCate02 {
    margin: 0 0 7px 0;
    width: auto;
    min-width: 72px;
    height: auto;
    padding: 0 9px;
    line-height: 15px;
  }
  .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList .c-newsCate02--reformCanpain, .p-reformTop02 .p-reformTop02__contents01 .p-reformTop02__contents01__newsList .c-newsCate02--reformOshirase {
    font-size: 0.9rem;
  }
  .p-reformTop02 .p-reformTop02__contents01 .c-reformBtn01 {
    width: 40%;
    margin: 0 auto;
    position: static;
    padding: 3px 0 1px;
  }
  .p-reformTop02 .p-reformTop02__contents01 .c-reformBtn01 a {
    font-size: 1.1rem;
  }
  .p-reformTop02 .p-reformTop02__contents01 .c-reformBtn01 a:after {
    margin: -2px 0 0 5px;
  }
  .p-reformTop03 {
    margin-bottom: 20px;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-refomeTitle01 {
    margin-bottom: 20px;
    line-height: 2.6rem;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-refomeTitle01 span {
    padding: 0 43px;
    background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 4px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 4px;
    background-size: 25px;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-refomeTitle01 img {
    margin: 6px auto 6px auto;
    width: 63px;
    height: 51px;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__item {
    margin-bottom: 9px;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__item > a {
    padding: 6px 5px 14px;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__item:nth-of-type(3) > a {
    padding: 6px 5px 7px;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__item:nth-of-type(3) .c-gallery__title {
    padding: 7px 3px 0;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__item:nth-of-type(4) .c-gallery__title {
    padding: 14px 3px 0;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__item:nth-of-type(5) .c-gallery__title {
    padding: 24px 3px 0;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__title {
    justify-content: left;
    padding: 16px 3px 0;
  }
  .p-reformTop03 .p-reformTop03__contents01 .c-gallery__title p:before {
    top: 50%;
    left: 1px;
    width: 5px;
    height: 9px;
    background-size: contain;
  }
  .p-reformTop04 {
    margin-bottom: 25px;
  }
  .p-reformTop04 .p-reformTop04__contents01 {
    padding: 0 20px;
  }
  .p-reformTop04 .p-reformTop04__contents01 h3 {
    margin-bottom: 12px;
    padding: 6px 15px;
    font-size: 1.5rem;
    text-align: center;
  }
  .p-reformTop04 .p-reformTop04__contents01 .c-refomeTitle01 {
    margin-bottom: 21px;
    line-height: 2.6rem;
  }
  .p-reformTop04 .p-reformTop04__contents01 .c-refomeTitle01 span {
    padding: 0 43px;
    background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 4px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 4px;
    background-size: 25px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .c-refomeTitle01 img {
    margin: 6px auto 10px auto;
    width: 74px;
    height: 44px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left {
    margin-bottom: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList__image {
    padding-top: 7px;
    margin-bottom: 10px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 {
    margin-bottom: 11px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li {
    margin-bottom: 9px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li:nth-of-type(1) .c-iconList__image {
    margin-right: 15px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li:nth-of-type(1) .c-iconList__text {
    padding-right: 15px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li:nth-of-type(2) .c-iconList__text {
    padding-left: 5px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li:nth-of-type(3) .c-iconList__image {
    margin-left: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li:nth-of-type(3) .c-iconList__text {
    padding-left: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li:nth-of-type(4) .c-iconList__image {
    margin-right: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform01 li:nth-of-type(4) .c-iconList__text {
    padding-right: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 {
    margin-bottom: 13px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li {
    margin-bottom: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(1) .c-iconList__image {
    margin-right: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(1) .c-iconList__text {
    padding-right: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(3) .c-iconList__image {
    margin-left: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(3) .c-iconList__text {
    padding-left: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(4) .c-iconList__image {
    margin-right: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(4) .c-iconList__text {
    padding-right: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(6) .c-iconList__image {
    margin-left: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform02 li:nth-of-type(6) .c-iconList__text {
    padding-left: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform03 li {
    margin-bottom: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform03 li:nth-of-type(1) .c-iconList__image {
    margin-right: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform03 li:nth-of-type(1) .c-iconList__text {
    padding-right: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform03 li:nth-of-type(3) .c-iconList__image {
    margin-left: 7px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform03 li:nth-of-type(3) .c-iconList__text {
    padding-left: 6px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform03 li:nth-of-type(4) .c-iconList__image {
    padding-top: 0;
    margin-right: 7px;
    margin-bottom: 0;
    height: 35px;
  }
  .p-reformTop04 .p-reformTop04__contents01 .l-left .c-iconList--reform03 li:nth-of-type(4) .c-iconList__text {
    padding-right: 6px;
  }
  .p-reformTop05 {
    margin-bottom: 36px;
  }
  .p-reformTop05 .p-reformTop05__contents01 {
    padding: 0 20px;
  }
  .p-reformTop05 .p-reformTop05__contents01 .c-refomeTitle01 {
    margin-bottom: 21px;
    line-height: 2.6rem;
  }
  .p-reformTop05 .p-reformTop05__contents01 .c-refomeTitle01 span {
    padding: 0 43px;
    background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 4px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 4px;
    background-size: 25px;
  }
  .p-reformTop05 .p-reformTop05__contents01 .c-refomeTitle01 img {
    margin: 0 auto 7px auto;
    width: 63px;
    height: 51px;
  }
  .p-reformTop05 .p-reformTop05__contents01 .c-miniBanner {
    margin-bottom: 10px;
    background-size: contain;
  }
  .p-reformTop05 .p-reformTop05__contents01 .c-miniBanner > a {
    padding-left: 20px;
  }
  .p-reformTop05 .p-reformTop05__contents01 .c-miniBanner--2 > a {
    padding-left: 16px;
  }
  .p-reformTop06 {
    margin-bottom: 35px;
  }
  .p-reformTop06 .p-reformTop06__contents01 {
    padding: 0 20px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-refomeTitle01 {
    margin-bottom: 18px;
    line-height: 2.6rem;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-refomeTitle01 span {
    padding: 0 42px;
    background: url(../img/reform/title_ornament_left.png) no-repeat left bottom 7px, url(../img/reform/title_ornament_right.png) no-repeat right bottom 7px;
    background-size: 25px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-refomeTitle01 img {
    margin: 0 auto 7px auto;
    width: 63px;
    height: 51px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-showCase {
    margin-bottom: 22px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-showCase__head {
    padding: 6px 15px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-showCase__head h2 {
    letter-spacing: -0.8px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-showCase__body {
    padding: 15px 15px 12px;
    background: #f5f5f5 url(/img/component/reform_case-icon01SP.png) no-repeat bottom 14px left 18px;
    background-size: 25px 31px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-reformBtn02 {
    margin-top: -2px;
    padding: 0;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-reformBtn02 a {
    letter-spacing: -0.3px;
  }
  .p-reformTop06 .p-reformTop06__contents01 .c-reformBtn02 a:after {
    margin: -2px 0 0 6px;
  }
}
/*
------------------------------------------------------------*/
.js-fade {
  opacity: 0;
  transform: translate(0, 60px);
}

.is-effect {
  opacity: 1;
  transform: translate(0, 0);
}

.is-first {
  transition: 0.8s;
}

.is-second {
  transition: 1.2s;
}

.is-third {
  transition: 1.6s;
}

.is-fourth {
  transition: 2s;
}

/*------------------------------------------------------------
リフォーム　無料見積もり・お問い合わせフォーム（カート）
------------------------------------------------------------*/
.p-reformContact {
  padding: 49px 0 140px 0;
  background-color: #ffffff;
}

.p-reformContact01 {
  margin-bottom: 24px;
}

.p-reformContact02 {
  margin-bottom: 52px;
}
.p-reformContact02 .p-reformCart {
  padding-top: 5px;
}
.p-reformContact02__txt {
  font-size: 1.6rem;
  color: #46483c;
}
.p-reformContact02__txt:first-of-type {
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-reformContact02__txt:first-of-type {
    margin-bottom: 10px;
  }
}
.p-reformContact02__annotation {
  color: #46483c;
  font-size: 1.4rem;
  margin-top: 3px;
  margin-bottom: 20px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-reformContact02__annotation {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
}
.p-reformContact02_2 {
  margin-bottom: 0;
}

.p-reformContact03 .p-reformContact03__content01 {
  margin-bottom: 55px;
}
.p-reformContact03 .p-reformContact03__content01 dl dt {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: bold;
  color: #46483c;
}
.p-reformContact03 .p-reformContact03__content01 dl dt:before {
  width: 10px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #247404;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-reformContact03 .p-reformContact03__content01 dl dd {
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #46483c;
}
.p-reformContact03 .p-reformContact03__content01 dl dd a {
  color: #04756f;
  text-decoration: underline;
}
.p-reformContact03 .p-reformContact03__content01 dl dd a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-reformContact03 .p-reformContact03__content01 dl dd p {
  width: 800px;
}
@media screen and (max-width: 640px) {
  .p-reformContact {
    padding: 30px 20px 28px 20px;
  }
  .p-reformContact01 {
    margin-bottom: 16px;
  }
  .p-reformContact01 .c-formTitle {
    margin-bottom: 27px;
  }
  .p-reformContact02 {
    margin-bottom: 24px;
  }
  .p-reformContact02__txt {
    margin-bottom: 8px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
  .p-reformContact02 .p-reformCart {
    padding-top: 0;
  }
  .p-reformContact03 .p-reformContact03__content01 {
    margin-bottom: 30px;
  }
  .p-reformContact03 .p-reformContact03__content01 dl dt {
    margin-bottom: 2px;
    font-size: 1.4rem;
  }
  .p-reformContact03 .p-reformContact03__content01 dl dt:before {
    width: 8px;
    height: 8px;
    margin: -3px 4px 0 0;
  }
  .p-reformContact03 .p-reformContact03__content01 dl dd {
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
  .p-reformContact03 .p-reformContact03__content01 dl dd p {
    width: 100%;
    margin-bottom: 10px;
  }
  .p-reformContact03 .p-reformContact03__content01 dl dd img {
    margin: 0 auto;
    display: block;
  }
  .p-reformContact03 .p-reformContact03__content01 .c-refomeTitle05 {
    font-size: 1.6rem;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .p-reformContact03 .p-reformContact03__content02 .c-cartBtn {
    margin-top: 37px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　無料見積もり・お問い合わせフォーム（カート） 入力・エラー
------------------------------------------------------------*/
.p-reformContactInput {
  padding: 49px 0 100px 0;
  background-color: #ffffff;
}

.p-reformContactInput01 {
  margin-bottom: 24px;
}
.p-reformContactInput01 .c-formTitle {
  color: #141414;
}

.p-reformContactInput02 {
  margin-bottom: 80px;
}
.p-reformContactInput02__require {
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background: #f22727;
  padding: 0 5px;
  line-height: 1.6;
  border-radius: 3px;
}
.p-reformContactInput02__content01 {
  margin-bottom: 50px;
}
.p-reformContactInput02__content01 .c-formTelInquiry {
  margin-bottom: 28px;
}
.p-reformContactInput02__content01__txt {
  margin-bottom: 36px;
  font-size: 1.6rem;
  color: #46483c;
}
@media screen and (max-width: 640px) {
  .p-reformContactInput02__content01__txt {
    font-size: 1.3rem;
    line-height: 1.5;
  }
}
.p-reformContactInput02__content01__txt2 {
  margin-bottom: 15px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-reformContactInput02__content02 {
  margin-bottom: 50px;
}
.p-reformContactInput02__content03__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #04756f;
  text-decoration: underline;
}
.p-reformContactInput02__content03__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-reformContactInput02__content03__back:before {
  width: 7px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-reformContactInput {
    padding: 30px 20px 0 20px;
  }
  .p-reformContactInput01 {
    margin-bottom: 20px;
  }
  .p-reformContactInput01 .c-formTitle {
    margin-bottom: 27px;
  }
  .p-reformContactInput02 {
    margin-bottom: 35px;
  }
  .p-reformContactInput02__content01 {
    margin-bottom: 25px;
  }
  .p-reformContactInput02__content01 .c-formTelInquiry {
    margin-bottom: 30px;
  }
  .p-reformContactInput02__content01 > p {
    margin-bottom: 16px;
    font-size: 1.3rem;
  }
  .p-reformContactInput02__content01 .p-reformContactInput02__require {
    margin: -3px 6px 0 0;
    font-size: 1rem;
    padding: 0 4px;
  }
  .p-reformContactInput02__content01 .c-checkbox input[type=checkbox] + label {
    padding-left: 23px;
  }
  .p-reformContactInput02__content01 .c-checkbox input[type=checkbox] + label:before {
    width: 14px;
    height: 14px;
  }
  .p-reformContactInput02__content01 .c-checkbox input[type=checkbox] + label:after {
    left: 5px;
    margin-top: -6px;
    height: 9px;
  }
  .p-reformContactInput02__content01 .c-table2__checkbox01 li {
    margin: 0 40px 5px 0;
  }
  .p-reformContactInput02__content02 {
    margin-bottom: 30px;
  }
  .p-reformContactInput02__content02 .c-refomeTitle05 {
    font-size: 1.6rem;
    margin-bottom: 13px;
    padding-bottom: 9px;
  }
  .p-reformContactInput02__content02 .c-checkbox input[type=checkbox] + label {
    padding-left: 23px;
  }
  .p-reformContactInput02__content02 .c-checkbox input[type=checkbox] + label:before {
    top: 2px;
    transform: translateY(0);
    width: 14px;
    height: 14px;
  }
  .p-reformContactInput02__content02 .c-checkbox input[type=checkbox] + label:after {
    top: 10px;
    left: 4px;
    transform: translateY(0) rotate(45deg);
    height: 9px;
  }
  .p-reformContactInput02__content03 {
    margin-bottom: 35px;
  }
  .p-reformContactInput02__content03__back {
    margin-top: 2px;
    font-size: 1.4rem;
  }
  .p-reformContactInput02__content03__back:before {
    width: 5px;
    height: 9px;
    margin: 0 11px 0 0;
  }
  .p-reformContactInput02__content03 .c-cartBtn {
    margin: 36px auto 0;
  }
}
.p-reformFormTable01 dl:nth-child(2) input {
  width: 280px;
  padding: 0 15px;
}
.p-reformFormTable01 dl:nth-child(3) li, .p-reformFormTable01 dl:nth-child(4) li {
  float: left;
}
.p-reformFormTable01 dl:nth-child(3) input, .p-reformFormTable01 dl:nth-child(4) input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.p-reformFormTable01 dl:nth-child(4) input {
  margin: 0 29px 0 7px;
}
.p-reformFormTable01 dl:nth-child(5) input, .p-reformFormTable01 dl:nth-child(6) input {
  padding: 0 15px;
  width: 240px;
}
.p-reformFormTable01 dl:nth-child(14) dd .c-table2__checkbox02 {
  display: flex;
  flex-wrap: wrap;
}
.p-reformFormTable01 dl:nth-child(14) dd .c-table2__checkbox02 li {
  width: 28%;
  margin-right: 0;
}
.p-reformFormTable01 dl:nth-child(18) input {
  width: 80px;
  margin: 0 10px;
  padding: 0 15px;
}
.p-reformFormTable01 dl:nth-child(7) input, .p-reformFormTable01 dl:nth-child(8) input, .p-reformFormTable01 dl:nth-child(9) input, .p-reformFormTable01 dl:nth-child(10) input, .p-reformFormTable01 dl:nth-child(11) input, .p-reformFormTable01 dl:nth-child(12) input, .p-reformFormTable01 dl:nth-child(15) input, .p-reformFormTable01 dl:nth-child(16) input, .p-reformFormTable01 dl:nth-child(19) input {
  width: 600px;
  padding: 0 15px;
}
.p-reformFormTable01 dl:nth-child(7) textarea, .p-reformFormTable01 dl:nth-child(8) textarea, .p-reformFormTable01 dl:nth-child(9) textarea, .p-reformFormTable01 dl:nth-child(10) textarea, .p-reformFormTable01 dl:nth-child(11) textarea, .p-reformFormTable01 dl:nth-child(12) textarea, .p-reformFormTable01 dl:nth-child(15) textarea, .p-reformFormTable01 dl:nth-child(16) textarea, .p-reformFormTable01 dl:nth-child(19) textarea {
  width: 100%;
  padding: 15px;
}
@media screen and (max-width: 640px) {
  .p-reformFormTable01 dl:nth-child(2) input, .p-reformFormTable01 dl:nth-child(2) textarea, .p-reformFormTable01 dl:nth-child(5) input, .p-reformFormTable01 dl:nth-child(5) textarea, .p-reformFormTable01 dl:nth-child(6) input, .p-reformFormTable01 dl:nth-child(6) textarea, .p-reformFormTable01 dl:nth-child(7) input, .p-reformFormTable01 dl:nth-child(7) textarea, .p-reformFormTable01 dl:nth-child(8) input, .p-reformFormTable01 dl:nth-child(8) textarea, .p-reformFormTable01 dl:nth-child(9) input, .p-reformFormTable01 dl:nth-child(9) textarea, .p-reformFormTable01 dl:nth-child(10) input, .p-reformFormTable01 dl:nth-child(10) textarea, .p-reformFormTable01 dl:nth-child(11) input, .p-reformFormTable01 dl:nth-child(11) textarea, .p-reformFormTable01 dl:nth-child(12) input, .p-reformFormTable01 dl:nth-child(12) textarea, .p-reformFormTable01 dl:nth-child(15) input, .p-reformFormTable01 dl:nth-child(15) textarea, .p-reformFormTable01 dl:nth-child(16) input, .p-reformFormTable01 dl:nth-child(16) textarea, .p-reformFormTable01 dl:nth-child(19) input, .p-reformFormTable01 dl:nth-child(19) textarea {
    width: 100%;
    padding: 11px 15px;
  }
  .p-reformFormTable01 dl:nth-child(18) input {
    padding: 10px;
  }
  .p-reformFormTable01 dl:nth-child(3) li, .p-reformFormTable01 dl:nth-child(4) li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }
  .p-reformFormTable01 dl:nth-child(3) li:last-of-type, .p-reformFormTable01 dl:nth-child(4) li:last-of-type {
    margin-bottom: 0;
  }
  .p-reformFormTable01 dl:nth-child(3) input, .p-reformFormTable01 dl:nth-child(4) input {
    width: 94%;
    padding: 12px;
    margin: 0 0 0 7px;
  }
  .p-reformFormTable01 dl:nth-child(4) input {
    width: 95%;
  }
  .p-reformFormTable01 dl:nth-child(6) dd, .p-reformFormTable01 dl:nth-child(7) dd, .p-reformFormTable01 dl:nth-child(8) dd, .p-reformFormTable01 dl:nth-child(9) dd, .p-reformFormTable01 dl:nth-child(11) dd, .p-reformFormTable01 dl:nth-child(15) dd, .p-reformFormTable01 dl:nth-child(16) dd, .p-reformFormTable01 dl:nth-child(18) dd, .p-reformFormTable01 dl:nth-child(19) dd {
    padding: 13px 14px 14px !important;
  }
  .p-reformFormTable01 dl:nth-child(13) dd, .p-reformFormTable01 dl:nth-child(14) dd {
    padding: 10px 14px 4px !important;
  }
  .p-reformFormTable01 dl:nth-child(14) dd .c-table2__checkbox02 {
    display: block;
  }
  .p-reformFormTable01 dl:nth-child(14) dd .c-table2__checkbox02 li {
    width: 100%;
    margin-bottom: 5px;
  }
  .p-reformFormTable01 dl .c-table2__checkbox01 li {
    margin-bottom: 5px;
  }
  .p-reformFormTable01 dl textarea {
    line-height: 1.5;
  }
  .p-reformFormTable01 dl input {
    height: 38px;
    line-height: 38px;
  }
}
.p-reformFormTable01 .c-table2__radio input:checked + label:before {
  background: #68ba93;
  border: 1px solid #68ba93 !important;
}
@media screen and (max-width: 640px) {
  .p-reformFormTable01 .c-table2__radio li {
    margin-right: 0;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　無料見積もり・お問い合わせフォーム（カート） 確認
------------------------------------------------------------*/
.p-reformContactConfirm {
  padding: 50px 0 40px 0;
  background-color: #ffffff;
}

.p-reformContactConfirm01 {
  margin-bottom: 30px;
}

.p-reformContactConfirm02 {
  margin-bottom: 30px;
}
.p-reformContactConfirm02__content01 {
  margin-bottom: 50px;
  color: #46483c;
}
.p-reformContactConfirm02__content01 .c-cartBtn {
  margin-bottom: 45px;
}
.p-reformContactConfirm02__content03 {
  margin-bottom: 80px;
}
.p-reformContactConfirm02__content03__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #04756f;
  text-decoration: underline;
}
.p-reformContactConfirm02__content03__back:before {
  width: 7px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-reformContactConfirm {
    padding: 30px 0 0 0;
    background-color: #ffffff;
  }
  .p-reformContactConfirm01 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-reformContactConfirm01 .c-formTitle {
    margin-bottom: 27px;
  }
  .p-reformContactConfirm02 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-reformContactConfirm02__content01 {
    margin-bottom: 30px;
  }
  .p-reformContactConfirm02__content01 .c-cartBtn {
    margin-bottom: 20px;
  }
  .p-reformContactConfirm02__content01 .p-reformFormTable01 dd {
    padding: 13px 14px 14px !important;
  }
  .p-reformContactConfirm02__content03 {
    margin-bottom: 40px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　無料見積もり・お問い合わせフォーム（カート） 完了
------------------------------------------------------------*/
.p-reformContactComplete {
  padding: 50px 0 135px 0;
  background-color: #ffffff;
}

.p-reformContactComplete01 {
  margin: 30px 0;
}
.p-reformContactComplete01 .c-formTitle {
  color: #141414;
}

.p-reformContactComplete02 {
  margin-bottom: 80px;
}
.p-reformContactComplete02 .c-formComplete__inquiry {
  width: 530px;
}

@media screen and (max-width: 640px) {
  .p-reformContactComplete {
    padding: 0 20px;
  }
  .p-reformContactComplete01 {
    margin: 30px 0;
  }
  .p-reformContactComplete01 .c-formTitle {
    margin-bottom: 27px;
  }
  .p-reformContactComplete02 {
    margin-bottom: 40px;
  }
  .p-reformContactComplete02 .c-formComplete__inquiry {
    width: 100%;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
水廻りの改修をお考えの方（商品一覧）
------------------------------------------------------------*/
.p-reformWetArea {
  background-color: #ffffff;
}
.p-reformWetArea .c-breadcrumb1 {
  padding: 11px 0 48px 0;
}

.p-reformWetArea01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior02.jpg");
}

.p-reformWetArea02 {
  margin-bottom: 80px;
}
.p-reformWetArea02__content01 .c-refomeTitle03 {
  margin-bottom: 32px;
}
.p-reformWetArea02__content01__point li {
  min-width: 180px;
  margin-bottom: 64px;
  padding-right: 280px;
  position: relative;
}
.p-reformWetArea02__content01__point li:last-child {
  margin: 0;
}
.p-reformWetArea02__content01__point li h3 {
  margin-bottom: 12px;
  font-size: 2rem;
  font-weight: bold;
  color: #342603;
}
.p-reformWetArea02__content01__point li h3 span {
  margin-right: 13px;
  font-size: 2.6rem;
  color: #247404;
  display: inline-block;
}
.p-reformWetArea02__content01__point li img {
  width: 253px;
  position: absolute;
  top: 7px;
  right: 0;
}
.p-reformWetArea02__content01__point li p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
  min-height: 130px;
}

.p-reformWetArea03 {
  margin-bottom: 41px;
}
.p-reformWetArea03 .c-refomeTitle03 {
  margin-bottom: 32px;
}
@media screen and (max-width: 640px) {
  .p-reformWetArea01 {
    margin-bottom: 25px;
  }
  .p-reformWetArea01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior02_sp.jpg");
    padding: 27px 20px 30px 18px;
  }
  .p-reformWetArea02 {
    margin-bottom: 28px;
  }
  .p-reformWetArea02__content01 {
    padding: 0 20px;
  }
  .p-reformWetArea02__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformWetArea02__content01__point li {
    min-width: auto;
    margin-bottom: 10px;
    padding: 0;
    position: static;
  }
  .p-reformWetArea02__content01__point li h3 {
    margin-bottom: 9px;
    font-size: 1.6rem;
  }
  .p-reformWetArea02__content01__point li h3 span {
    margin: 0 0 3px 0;
    font-size: 1.6rem;
    display: block;
  }
  .p-reformWetArea02__content01__point li img {
    margin-bottom: 11px;
    display: block;
    position: static;
    width: 100%;
  }
  .p-reformWetArea02__content01__point li p {
    font-size: 1.4rem;
    line-height: 2.1rem;
    min-height: 100%;
  }
  .p-reformWetArea03 {
    margin-bottom: 13px;
  }
  .p-reformWetArea03 .p-reformWetArea03__content01 {
    padding: 0 20px;
  }
  .p-reformWetArea03 .p-reformWetArea03__content01 .c-refomeTitle03 {
    margin-bottom: 36px;
  }
  .p-reformWetArea03 .p-reformWetArea03__content01 .c-reformList01 li {
    width: 46%;
    margin: 0 8% 17px 0 !important;
  }
  .p-reformWetArea03 .p-reformWetArea03__content01 .c-reformList01 li:nth-child(2n+2) {
    margin: 0 0 17px 0 !important;
  }
  .p-reformWetArea03 .p-reformWetArea03__content01 .c-reformList01 li img {
    margin: 0 0 7px 0;
  }
  .p-reformWetArea03 .p-reformWetArea03__content01 .c-reformList01 li h3 a:before {
    margin: -3px 4px 0 -10px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
屋根や外壁の塗装をお考えの方（商品一覧）
------------------------------------------------------------*/
.p-reformExterior {
  background-color: #ffffff;
}
.p-reformExterior .c-breadcrumb1 {
  padding: 11px 0 48px 0;
}

.p-reformExterior01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior03.jpg");
}

.p-reformExterior02 {
  margin-bottom: 109px;
}
.p-reformExterior02__content01 .c-refomeTitle03 {
  margin-bottom: 32px;
}
.p-reformExterior02__content01__point li {
  min-width: 180px;
  margin-bottom: 64px;
  padding-right: 280px;
  position: relative;
}
.p-reformExterior02__content01__point li:last-child {
  margin: 0;
}
.p-reformExterior02__content01__point li h3 {
  margin-bottom: 12px;
  font-size: 2rem;
  font-weight: bold;
  color: #342603;
}
.p-reformExterior02__content01__point li h3 span {
  margin-right: 13px;
  font-size: 2.6rem;
  color: #247404;
  display: inline-block;
}
.p-reformExterior02__content01__point li img {
  width: 253px;
  position: absolute;
  top: 7px;
  right: 0;
}
.p-reformExterior02__content01__point li p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-reformExterior02__content01__txt {
  margin: -10px 0 40px;
}
.p-reformExterior02__content01__txt p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
}

.p-reformExterior03 {
  margin-bottom: 41px;
}
.p-reformExterior03 .c-refomeTitle03 {
  margin-bottom: 32px;
}
@media screen and (max-width: 640px) {
  .p-reformExterior01 {
    margin-bottom: 25px;
  }
  .p-reformExterior01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior03_sp.jpg");
  }
  .p-reformExterior02 {
    margin-bottom: 28px;
  }
  .p-reformExterior02__content01 {
    padding: 0 20px;
  }
  .p-reformExterior02__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformExterior02__content01__point li {
    min-width: auto;
    margin-bottom: 10px;
    padding: 0;
    position: static;
  }
  .p-reformExterior02__content01__point li h3 {
    margin-bottom: 9px;
    font-size: 1.6rem;
  }
  .p-reformExterior02__content01__point li h3 span {
    margin: 0 0 3px 0;
    font-size: 1.6rem;
    display: block;
  }
  .p-reformExterior02__content01__point li img {
    margin: 0 auto 11px auto;
    display: block;
    position: static;
  }
  .p-reformExterior02__content01__point li p {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
  .p-reformExterior02__content01__txt {
    margin: 10px 0 30px;
  }
  .p-reformExterior02__content01__txt p {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
  .p-reformExterior03 {
    margin-bottom: 13px;
  }
  .p-reformExterior03 .p-reformExterior03__content01 {
    padding: 0 20px;
  }
  .p-reformExterior03 .p-reformExterior03__content01 .c-refomeTitle03 {
    margin-bottom: 36px;
  }
  .p-reformExterior03 .p-reformExterior03__content01 .c-reformList01 li {
    width: 46%;
    margin: 0 8% 17px 0 !important;
  }
  .p-reformExterior03 .p-reformExterior03__content01 .c-reformList01 li:nth-child(2n+2) {
    margin: 0 0 17px 0 !important;
  }
  .p-reformExterior03 .p-reformExterior03__content01 .c-reformList01 li img {
    margin: 0 0 7px 0;
  }
  .p-reformExterior03 .p-reformExterior03__content01 .c-reformList01 li h3 a:before {
    margin: -3px 4px 0 -10px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装リフォームをお考えの方（商品一覧）
------------------------------------------------------------*/
.p-reformInterior {
  background-color: #ffffff;
}
.p-reformInterior .c-breadcrumb1 {
  padding: 11px 0 48px 0;
}

.p-reformInterior01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformInterior02 {
  margin-bottom: 109px;
}
.p-reformInterior02__content01 .c-refomeTitle03 {
  margin-bottom: 32px;
}
.p-reformInterior02__content01__point li {
  min-width: 180px;
  margin-bottom: 64px;
  padding-right: 280px;
  position: relative;
}
.p-reformInterior02__content01__point li:last-child {
  margin: 0;
}
.p-reformInterior02__content01__point li h3 {
  margin-bottom: 12px;
  font-size: 2rem;
  font-weight: bold;
  color: #342603;
}
.p-reformInterior02__content01__point li h3 span {
  margin-right: 13px;
  font-size: 2.6rem;
  color: #247404;
  display: inline-block;
}
.p-reformInterior02__content01__point li img {
  width: 253px;
  position: absolute;
  top: 7px;
  right: 0;
}
.p-reformInterior02__content01__point li p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
}

.p-reformInterior03 {
  margin-bottom: 41px;
}
.p-reformInterior03 .c-refomeTitle03 {
  margin-bottom: 32px;
}
@media screen and (max-width: 640px) {
  .p-reformInterior01 {
    margin-bottom: 25px;
  }
  .p-reformInterior01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformInterior02 {
    margin-bottom: 28px;
  }
  .p-reformInterior02__content01 {
    padding: 0 20px;
  }
  .p-reformInterior02__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformInterior02__content01__point li {
    min-width: auto;
    margin-bottom: 10px;
    padding: 0;
    position: static;
  }
  .p-reformInterior02__content01__point li h3 {
    margin-bottom: 9px;
    font-size: 1.6rem;
  }
  .p-reformInterior02__content01__point li h3 span {
    margin: 0 0 3px 0;
    font-size: 1.6rem;
    display: block;
  }
  .p-reformInterior02__content01__point li img {
    margin: 0 auto 11px auto;
    display: block;
    position: static;
  }
  .p-reformInterior02__content01__point li p {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
  .p-reformInterior03 {
    margin-bottom: 13px;
  }
  .p-reformInterior03 .p-reformInterior03__content01 {
    padding: 0 20px;
  }
  .p-reformInterior03 .p-reformInterior03__content01 .c-refomeTitle03 {
    margin-bottom: 36px;
  }
  .p-reformInterior03 .p-reformInterior03__content01 .c-reformList01 li {
    width: 46%;
    margin: 0 8% 17px 0 !important;
  }
  .p-reformInterior03 .p-reformInterior03__content01 .c-reformList01 li:nth-child(2n+2) {
    margin: 0 0 17px 0 !important;
  }
  .p-reformInterior03 .p-reformInterior03__content01 .c-reformList01 li img {
    margin: 0 0 7px 0;
  }
  .p-reformInterior03 .p-reformInterior03__content01 .c-reformList01 li h3 a:before {
    margin: -3px 4px 0 -10px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
外注の予防・駆除をお考えの方（商品一覧）
------------------------------------------------------------*/
.p-reformMaintenance {
  background-color: #ffffff;
}
.p-reformMaintenance .c-breadcrumb1 {
  padding: 11px 0 48px 0;
}

.p-reformMaintenance01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior04.jpg");
}

.p-reformMaintenance02 {
  margin-bottom: 109px;
}
.p-reformMaintenance02__content01 .c-refomeTitle03 {
  margin-bottom: 32px;
}
.p-reformMaintenance02__content01__point li {
  min-width: 180px;
  margin-bottom: 64px;
  padding-right: 280px;
  position: relative;
}
.p-reformMaintenance02__content01__point li:last-child {
  margin: 0;
}
.p-reformMaintenance02__content01__point li h3 {
  margin-bottom: 12px;
  font-size: 2rem;
  font-weight: bold;
  color: #342603;
}
.p-reformMaintenance02__content01__point li h3 span {
  margin-right: 13px;
  font-size: 2.6rem;
  color: #247404;
  display: inline-block;
}
.p-reformMaintenance02__content01__point li img {
  width: 253px;
  position: absolute;
  top: 7px;
  right: 0;
}
.p-reformMaintenance02__content01__point li p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
}
.p-reformMaintenance02__content01__txt {
  margin: -10px 0 40px;
}
.p-reformMaintenance02__content01__txt p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
}

.p-reformMaintenance03 {
  margin-bottom: 41px;
}
.p-reformMaintenance03 .c-refomeTitle03 {
  margin-bottom: 32px;
}
@media screen and (max-width: 640px) {
  .p-reformMaintenance01 {
    margin-bottom: 25px;
  }
  .p-reformMaintenance01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior04_sp.jpg");
    padding: 27px 20px 30px 18px;
  }
  .p-reformMaintenance01__content01 .c-refomeTitle02 span img {
    margin-top: 7px;
    width: 40px;
    margin-left: 2px;
  }
  .p-reformMaintenance02 {
    margin-bottom: 28px;
  }
  .p-reformMaintenance02__content01 {
    padding: 0 20px;
  }
  .p-reformMaintenance02__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformMaintenance02__content01__point li {
    min-width: auto;
    margin-bottom: 10px;
    padding: 0;
    position: static;
  }
  .p-reformMaintenance02__content01__point li h3 {
    margin-bottom: 9px;
    font-size: 1.6rem;
  }
  .p-reformMaintenance02__content01__point li h3 span {
    margin: 0 0 3px 0;
    font-size: 1.6rem;
    display: block;
  }
  .p-reformMaintenance02__content01__point li img {
    margin: 0 auto 11px auto;
    display: block;
    position: static;
  }
  .p-reformMaintenance02__content01__point li p {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
  .p-reformMaintenance02__content01__txt {
    margin: 10px 0 30px;
  }
  .p-reformMaintenance02__content01__txt p {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
  .p-reformMaintenance03 {
    margin-bottom: 13px;
  }
  .p-reformMaintenance03 .p-reformMaintenance03__content01 {
    padding: 0 20px;
  }
  .p-reformMaintenance03 .p-reformMaintenance03__content01 .c-refomeTitle03 {
    margin-bottom: 36px;
  }
  .p-reformMaintenance03 .p-reformMaintenance03__content01 .c-reformList01 li {
    width: 46%;
    margin: 0 8% 17px 0 !important;
  }
  .p-reformMaintenance03 .p-reformMaintenance03__content01 .c-reformList01 li:nth-child(2n+2) {
    margin: 0 0 17px 0 !important;
  }
  .p-reformMaintenance03 .p-reformMaintenance03__content01 .c-reformList01 li img {
    margin: 0 0 7px 0;
  }
  .p-reformMaintenance03 .p-reformMaintenance03__content01 .c-reformList01 li h3 a:before {
    margin: -3px 4px 0 -10px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService01 {
  background-color: #ffffff;
}

.p-reformService0101__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior02.jpg");
}

.p-reformService0102 {
  margin-bottom: 80px;
}
.p-reformService0103 {
  margin-bottom: 100px;
}
.p-reformService0103__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0103__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0101 {
    margin-bottom: 28px;
  }
  .p-reformService0101__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior02_sp.jpg");
  }
  .p-reformService0102 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0102__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0102__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0102__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0102__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0103 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0103__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0103__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0103__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService02 {
  background-color: #ffffff;
}

.p-reformService0201__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior02.jpg");
}

.p-reformService0202 {
  margin-bottom: 80px;
}
.p-reformService0203 {
  margin-bottom: 100px;
}
.p-reformService0203__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0203__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0201 {
    margin-bottom: 28px;
  }
  .p-reformService0201__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior02_sp.jpg");
  }
  .p-reformService0202 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0202__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0202__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0202__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0202__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0203 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0203__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0203__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0203__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService03 {
  background-color: #ffffff;
}

.p-reformService0301__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior02.jpg");
}

.p-reformService0302 {
  margin-bottom: 80px;
}
.p-reformService0303 {
  margin-bottom: 100px;
}
.p-reformService0303__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0303__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0301 {
    margin-bottom: 28px;
  }
  .p-reformService0301__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior02_sp.jpg");
  }
  .p-reformService0302 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0302__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0302__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0302__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0302__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0303 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0303__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0303__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0303__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService04 {
  background-color: #ffffff;
}

.p-reformService0401__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior02.jpg");
}

.p-reformService0402 {
  margin-bottom: 80px;
}
.p-reformService0403 {
  margin-bottom: 100px;
}
.p-reformService0403__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0403__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0401 {
    margin-bottom: 28px;
  }
  .p-reformService0401__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior02_sp.jpg");
  }
  .p-reformService0402 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0402__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0402__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0402__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0402__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0403 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0403__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0403__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0403__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService05 {
  background-color: #ffffff;
}

.p-reformService0501__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior02.jpg");
}

.p-reformService0502 {
  margin-bottom: 80px;
}
.p-reformService0503 {
  margin-bottom: 100px;
}
.p-reformService0503__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0503__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0501 {
    margin-bottom: 28px;
  }
  .p-reformService0501__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior02_sp.jpg");
  }
  .p-reformService0502 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0502__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0502__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0502__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0502__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0503 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0503__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0503__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0503__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService06 {
  background-color: #ffffff;
}

.p-reformService0601__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService0602 {
  margin-bottom: 80px;
}
.p-reformService0603 {
  margin-bottom: 100px;
}
.p-reformService0603__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0603__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0601 {
    margin-bottom: 28px;
  }
  .p-reformService0601__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService0602 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0602__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0602__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0602__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0602__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0603 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0603__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0603__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0603__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService07 {
  background-color: #ffffff;
}

.p-reformService0701__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService0702 {
  margin-bottom: 80px;
}
.p-reformService0703 {
  margin-bottom: 100px;
}
.p-reformService0703__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0703__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0701 {
    margin-bottom: 28px;
  }
  .p-reformService0701__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService0702 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0702__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0702__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0702__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0702__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0703 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0703__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0703__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0703__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService08 {
  background-color: #ffffff;
}

.p-reformService0801__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService0802 {
  margin-bottom: 80px;
}
.p-reformService0803 {
  margin-bottom: 100px;
}
.p-reformService0803__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0803__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0801 {
    margin-bottom: 28px;
  }
  .p-reformService0801__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService0802 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0802__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0802__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0802__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0802__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0803 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0803__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0803__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0803__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService09 {
  background-color: #ffffff;
}

.p-reformService0901__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService0902 {
  margin-bottom: 80px;
}
.p-reformService0903 {
  margin-bottom: 100px;
}
.p-reformService0903__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService0903__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService0901 {
    margin-bottom: 28px;
  }
  .p-reformService0901__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService0902 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService0902__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService0902__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService0902__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService0902__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService0903 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0903__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0903__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService0903__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService10 {
  background-color: #ffffff;
}

.p-reformService1001__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService1002 {
  margin-bottom: 80px;
}
.p-reformService1003 {
  margin-bottom: 100px;
}
.p-reformService1003__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1003__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1001 {
    margin-bottom: 28px;
  }
  .p-reformService1001__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService1002 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1002__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1002__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1002__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1002__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1003 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1003__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1003__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1003__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService11 {
  background-color: #ffffff;
}

.p-reformService1101__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService1102 {
  margin-bottom: 80px;
}
.p-reformService1103 {
  margin-bottom: 100px;
}
.p-reformService1103__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1103__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1101 {
    margin-bottom: 28px;
  }
  .p-reformService1101__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService1102 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1102__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1102__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1102__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1102__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1103 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1103__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1103__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1103__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService12 {
  background-color: #ffffff;
}

.p-reformService1201__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService1202 {
  margin-bottom: 80px;
}
.p-reformService1203 {
  margin-bottom: 100px;
}
.p-reformService1203__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1203__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1201 {
    margin-bottom: 28px;
  }
  .p-reformService1201__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService1202 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1202__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1202__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1202__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1202__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1203 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1203__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1203__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1203__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService13 {
  background-color: #ffffff;
}

.p-reformService1301__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior03.jpg");
}

.p-reformService1302 {
  margin-bottom: 80px;
}
.p-reformService1303 {
  margin-bottom: 100px;
}
.p-reformService1303__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1303__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1301 {
    margin-bottom: 28px;
  }
  .p-reformService1301__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior03_sp.jpg");
  }
  .p-reformService1302 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1302__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1302__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1302__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1302__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1303 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1303__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1303__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1303__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService14 {
  background-color: #ffffff;
}

.p-reformService1401__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior03.jpg");
}

.p-reformService1402 {
  margin-bottom: 80px;
}
.p-reformService1403 {
  margin-bottom: 100px;
}
.p-reformService1403__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1403__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1401 {
    margin-bottom: 28px;
  }
  .p-reformService1401__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior03_sp.jpg");
  }
  .p-reformService1402 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1402__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1402__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1402__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1402__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1403 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1403__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1403__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1403__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService15 {
  background-color: #ffffff;
}

.p-reformService1501__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior03.jpg");
}

.p-reformService1502 {
  margin-bottom: 80px;
}
.p-reformService1503 {
  margin-bottom: 100px;
}
.p-reformService1503__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1503__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1501 {
    margin-bottom: 28px;
  }
  .p-reformService1501__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior03_sp.jpg");
  }
  .p-reformService1502 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1502__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1502__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1502__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1502__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1503 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1503__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1503__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1503__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService16 {
  background-color: #ffffff;
}

.p-reformService1601__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior03.jpg");
}

.p-reformService1602 {
  margin-bottom: 80px;
}
.p-reformService1603 {
  margin-bottom: 100px;
}
.p-reformService1603__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1603__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1601 {
    margin-bottom: 28px;
  }
  .p-reformService1601__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior03_sp.jpg");
  }
  .p-reformService1602 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1602__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1602__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1602__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1602__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1603 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1603__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1603__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1603__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService17 {
  background-color: #ffffff;
}

.p-reformService1701__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior04.jpg");
}

.p-reformService1702 {
  margin-bottom: 80px;
}
.p-reformService1703 {
  margin-bottom: 100px;
}
.p-reformService1703__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1703__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1701 {
    margin-bottom: 28px;
  }
  .p-reformService1701__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior04_sp.jpg");
  }
  .p-reformService1702 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1702__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1702__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1702__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1702__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1703 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1703__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1703__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1703__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService18 {
  background-color: #ffffff;
}

.p-reformService1801__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior04.jpg");
}

.p-reformService1802 {
  margin-bottom: 80px;
}
.p-reformService1803 {
  margin-bottom: 100px;
}
.p-reformService1803__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1803__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1801 {
    margin-bottom: 28px;
  }
  .p-reformService1801__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior04_sp.jpg");
  }
  .p-reformService1802 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1802__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1802__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1802__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1802__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1803 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1803__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1803__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1803__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService19 {
  background-color: #ffffff;
}

.p-reformService1901__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior04.jpg");
}

.p-reformService1902 {
  margin-bottom: 80px;
}
.p-reformService1903 {
  margin-bottom: 100px;
}
.p-reformService1903__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService1903__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService1901 {
    margin-bottom: 28px;
  }
  .p-reformService1901__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior04_sp.jpg");
  }
  .p-reformService1902 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService1902__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService1902__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService1902__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService1902__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService1903 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService1903__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService1903__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService1903__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService20 {
  background-color: #ffffff;
}

.p-reformService2001__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior04.jpg");
}

.p-reformService2002 {
  margin-bottom: 80px;
}
.p-reformService2003 {
  margin-bottom: 100px;
}
.p-reformService2003__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService2003__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService2001 {
    margin-bottom: 28px;
  }
  .p-reformService2001__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior04_sp.jpg");
  }
  .p-reformService2002 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService2002__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService2002__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService2002__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService2002__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService2003 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService2003__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService2003__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService2003__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService21 {
  background-color: #ffffff;
}

.p-reformService2101__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior04.jpg");
}

.p-reformService2102 {
  margin-bottom: 80px;
}
.p-reformService2103 {
  margin-bottom: 100px;
}
.p-reformService2103__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService2103__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService2101 {
    margin-bottom: 28px;
  }
  .p-reformService2101__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior04_sp.jpg");
  }
  .p-reformService2102 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService2102__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService2102__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService2102__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService2102__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService2103 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService2103__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService2103__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService2103__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService22 {
  background-color: #ffffff;
}

.p-reformService2201__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior04.jpg");
}

.p-reformService2202 {
  margin-bottom: 80px;
}
.p-reformService2203 {
  margin-bottom: 100px;
}
.p-reformService2203__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformService2203__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformService2201 {
    margin-bottom: 28px;
  }
  .p-reformService2201__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior04_sp.jpg");
  }
  .p-reformService2202 {
    margin-bottom: 29px;
    padding: 0 20px;
  }
  .p-reformService2202__content01 .c-refomeTitle03 {
    margin-bottom: 37px;
    line-height: 1.4;
  }
  .p-reformService2202__content01 .c-refomeTitle03:after {
    bottom: -21px;
  }
  .p-reformService2202__content01 .c-refomeTitle03:before {
    top: 24%;
  }
  .p-reformService2202__content01 .c-reformServiceTxet01--txt p {
    line-height: 2.1rem;
  }
  .p-reformService2203 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService2203__content01 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService2203__content01 .c-refomeTitle03 {
    margin-bottom: 35px;
  }
  .p-reformService2203__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　商品詳細
------------------------------------------------------------*/
.p-reformService00 {
  background-color: #ffffff;
}

.p-reformService0001__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior.jpg");
}

.p-reformService0002 {
  margin-bottom: 60px;
}
.p-reformService0002__content01 {
  margin-bottom: 60px;
}
.p-reformService0002__content01__detail {
  margin-bottom: 45px;
  display: flex;
  flex-direction: row-reverse;
}
.p-reformService0002__content01__detail__img {
  width: 340px;
  margin-left: 25px;
}
.p-reformService0002__content01__detail__img__img {
  margin: 3px 0 20px;
}
.p-reformService0002__content01__detail__img__movie {
  position: relative;
  padding-bottom: 56.25%;
}
.p-reformService0002__content01__detail__img__movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-reformService0002__content01__detail__txt {
  width: calc(100% - 365px);
}
.p-reformService0002__content01__detail__txt h3 {
  margin-bottom: 12px;
  font-size: 2rem;
  font-weight: bold;
  color: #d33f10;
}
.p-reformService0002__content01__detail__txt p {
  margin-bottom: 28px;
  font-size: 1.5rem;
  line-height: 1.75;
  color: #46483c;
}
.p-reformService0002__content01__detail__txt table {
  width: 100%;
  font-size: 1.3rem;
  border-collapse: collapse;
}
.p-reformService0002__content01__detail__txt table tr {
  border-bottom: dotted 1px #4c4c4c;
}
.p-reformService0002__content01__detail__txt table tr:last-child {
  border: none;
}
.p-reformService0002__content01__detail__txt table th {
  width: 160px;
  padding: 16px 0 12px;
  font-weight: bold;
  text-align: left;
  color: #141414;
}
.p-reformService0002__content01__detail__txt table td {
  padding: 16px 0 12px;
  color: #333333;
}
.p-reformService0002__content01__detail__txt table strong {
  color: #f2385a;
  font-weight: bold;
}
.p-reformService0002__content01__priceArea {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-reformService0002__content01__priceArea {
    margin-bottom: 30px;
  }
}
.p-reformService0002__content01__priceArea dt {
  padding: 8px 0;
  font-size: 1.3rem;
  color: #ffffff;
  text-align: center;
}
.p-reformService0002__content01__priceArea dd table {
  width: 100%;
  margin-bottom: 11px;
  font-size: 1.6rem;
}
.p-reformService0002__content01__priceArea dd table th {
  vertical-align: top;
  font-weight: bold;
  text-align: left;
  padding: 4px 0 0;
}
.p-reformService0002__content01__priceArea dd table td {
  vertical-align: top;
  font-weight: bold;
  text-align: right;
}
.p-reformService0002__content01__priceArea dd table td em {
  font-size: 2.4rem;
  font-style: normal;
}
.p-reformService0002__content01__priceArea dd table td span {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 6px;
}
.p-reformService0002__content01__priceArea dd p {
  font-size: 1.1rem;
  color: #666666;
  line-height: 1.5;
}
.p-reformService0002__content01__priceArea dd .c-lineDotted01 {
  margin: 26px 0 22px;
}
.p-reformService0002__content01__priceArea__price01 {
  width: 450px;
  flex: 1;
}
.p-reformService0002__content01__priceArea__price01 dt {
  background-color: #46483c;
}
.p-reformService0002__content01__priceArea__price01 dd {
  padding: 23px 0;
}
.p-reformService0002__content01__priceArea__price01__discount {
  color: #f2385a;
}
.p-reformService0002__content01__priceArea__price01__discount th {
  padding: 7px 0 0 !important;
}
.p-reformService0002__content01__priceArea__price01__discount td em {
  font-size: 4.6rem !important;
}
.p-reformService0002__content01__priceArea__price01__discount td span {
  padding-top: 1px !important;
}
.p-reformService0002__content01__priceArea__price02 {
  width: 340px;
  margin-left: 50px;
}
@media screen and (max-width: 640px) {
  .p-reformService0002__content01__priceArea__price02 {
    margin-left: 0;
  }
}
.p-reformService0002__content01__priceArea__price02 dt {
  background-color: #3a4452;
}
.p-reformService0002__content01__priceArea__price02 dd {
  padding: 23px 28px 26px;
  border: solid 3px #c8c8c8;
  border-top: none;
}
.p-reformService0002__content01__priceArea__price02 dd span {
  padding-top: 2px !important;
  line-height: 1.5;
}
.p-reformService0002__content01__priceArea__price02 table:not(:first-of-type) th {
  display: block;
  margin-bottom: 5px;
}
.p-reformService0002__content01__priceArea__price02 table:not(:first-of-type) td {
  display: block;
  text-align: left;
}
.p-reformService0002__content02 {
  margin-bottom: 58px;
}
.p-reformService0002__content02 span {
  display: block;
  font-size: 1.3rem;
  line-height: 1.53;
  color: #46483c;
}
@media screen and (max-width: 640px) {
  .p-reformService0002__content02 span {
    font-size: 1.2rem;
    line-height: 1.4;
  }
}
.p-reformService0002__content02__txtArea {
  margin-bottom: 26px;
  display: flex;
  flex-direction: row-reverse;
}
.p-reformService0002__content02__txtArea__img {
  width: 253px;
  padding-top: 5px;
  margin-left: 25px;
}
.p-reformService0002__content02__txtArea__txt {
  width: 560px;
  flex: 1;
}
.p-reformService0002__content02__txtArea__txt h3 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #342603;
}
.p-reformService0002__content02__txtArea__txt p {
  font-size: 1.5rem;
  line-height: 1.73;
  color: #46483c;
}
.p-reformService0002__content03 .c-refomeTitle06 {
  margin-bottom: 15px;
}
.p-reformService0002__content03 ul {
  margin-bottom: 47px;
  font-size: 1.3rem;
  line-height: 1.53;
  color: #46483c;
}
.p-reformService0002__content03 ul li {
  margin-bottom: 9px;
  padding-left: 20px;
}
.p-reformService0002__content03 ul li:before {
  width: 20px;
  margin: 0 0 0 -20px;
  content: "※";
  display: inline-block;
}

.p-reformService0003 {
  margin-bottom: 100px;
}

@media screen and (max-width: 640px) {
  .p-reformService0001 {
    margin-bottom: 30px;
  }
  .p-reformService0001__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior_sp.jpg");
  }
  .p-reformService0002 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformService0002__content01 {
    margin-bottom: 29px;
  }
  .p-reformService0002__content01__detail {
    min-height: auto;
    margin-bottom: 23px;
    padding-right: 0;
    display: block;
  }
  .p-reformService0002__content01__detail .c-refomeTitle03c:before {
    top: 2px;
    height: 35px;
    transform: translate(0, 0);
  }
  .p-reformService0002__content01__detail .c-refomeTitle03c span {
    padding-left: 1px;
    letter-spacing: 1px;
  }
  .p-reformService0002__content01__detail__img {
    width: 100%;
    margin: 0 0 12px 0;
  }
  .p-reformService0002__content01__detail__img__img {
    margin: 3px 0 16px;
  }
  .p-reformService0002__content01__detail__txt {
    width: 100%;
  }
  .p-reformService0002__content01__detail__txt h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .p-reformService0002__content01__detail__txt p {
    margin-bottom: 25px;
    font-size: 1.4rem;
  }
  .p-reformService0002__content01__detail__txt table {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
  .p-reformService0002__content01__detail__txt table tr {
    border-bottom: 1px solid transparent;
    background: url("../img/reform/borderSP.png") repeat-x top left;
  }
  .p-reformService0002__content01__detail__txt table tr:nth-of-type(2) td {
    padding: 2px 0 16px;
  }
  .p-reformService0002__content01__detail__txt table tr:nth-of-type(3) th {
    padding: 13px 0 12px;
  }
  .p-reformService0002__content01__detail__txt table tr:nth-of-type(3) td {
    padding: 14px 0 12px;
  }
  .p-reformService0002__content01__detail__txt table tr:nth-of-type(5) th {
    padding: 11px 0 9px;
  }
  .p-reformService0002__content01__detail__txt table tr:nth-of-type(5) td {
    padding: 14px 0 11px;
  }
  .p-reformService0002__content01__detail__txt table th {
    width: 39%;
    padding: 11px 0;
  }
  .p-reformService0002__content01__detail__txt table td {
    padding: 11px 0;
    vertical-align: middle;
  }
  .p-reformService0002__content01__priceArea dd table {
    font-size: 1.4rem;
  }
  .p-reformService0002__content01__priceArea dd table th {
    font-size: 1.3rem;
    line-height: 1.7rem;
    padding: 0;
    transform: translateX(-2px);
  }
  .p-reformService0002__content01__priceArea dd table td {
    font-size: 1.1rem;
  }
  .p-reformService0002__content01__priceArea dd table td em {
    font-size: 2.5rem;
  }
  .p-reformService0002__content01__priceArea dd table td span {
    font-size: 1.2rem;
    padding-top: 3px;
  }
  .p-reformService0002__content01__priceArea dd p {
    font-size: 1.1rem;
    line-height: 1.2rem;
  }
  .p-reformService0002__content01__priceArea dd .c-lineDotted01 {
    margin: 18px 0 10px;
  }
  .p-reformService0002__content01__priceArea__price01 {
    width: 100%;
  }
  .p-reformService0002__content01__priceArea__price01 dt {
    padding: 2px 0;
  }
  .p-reformService0002__content01__priceArea__price01 dd {
    padding: 15px 0;
  }
  .p-reformService0002__content01__priceArea__price01__discount td {
    padding-top: 8px;
  }
  .p-reformService0002__content01__priceArea__price01__discount td em {
    font-size: 3rem !important;
  }
  .p-reformService0002__content01__priceArea__price02 {
    width: 100%;
    padding-top: 2px;
  }
  .p-reformService0002__content01__priceArea__price02 dt {
    padding: 1px 0;
  }
  .p-reformService0002__content01__priceArea__price02 dd {
    padding: 15px 18px 19px;
    border: solid 1px #c8c8c8;
  }
  .p-reformService0002__content01__priceArea__price02 dd table {
    margin-bottom: 8px;
  }
  .p-reformService0002__content01__priceArea__price02 dd table:not(:first-of-type) td span {
    font-size: 1.3rem;
    line-height: 1.6rem;
    transform: translate(2px, -2px);
  }
  .p-reformService0002__content01__priceArea__price02 dd .c-lineDotted01 {
    margin: 18px 0 16px;
  }
  .p-reformService0002__content02 {
    margin-bottom: 23px;
  }
  .p-reformService0002__content02__txtArea {
    display: block;
    margin-bottom: 18px;
  }
  .p-reformService0002__content02__txtArea__img {
    width: 100%;
    margin-bottom: 12px;
    margin-left: 0;
  }
  .p-reformService0002__content02__txtArea__txt {
    width: 100%;
  }
  .p-reformService0002__content02__txtArea__txt h3 {
    margin-bottom: 6px;
    font-size: 1.5rem;
  }
  .p-reformService0002__content02__txtArea__txt p {
    font-size: 1.4rem;
    line-height: 1.75;
  }
  .p-reformService0002__content02 .c-refomeTitle06 {
    margin-bottom: 12px;
  }
  .p-reformService0002__content03 .c-refomeTitle06 {
    margin-bottom: 14px;
  }
  .p-reformService0002__content03 ul {
    margin-bottom: 28px;
    font-size: 1.2rem;
  }
  .p-reformService0002__content03 ul li {
    padding-left: 15px;
    line-height: 1.4rem;
    margin-bottom: 11px;
  }
  .p-reformService0002__content03 .c-reformBtn03 a {
    letter-spacing: -1px;
    padding-top: 4px;
  }
  .p-reformService0002__content03 .c-reformBtn03 a:before {
    width: 12px;
    height: 14px;
    margin: 2px 12px 0 0;
  }
  .p-reformService0003 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-reformService0003 .c-refomeTitle04 {
    margin-bottom: 20px;
  }
  .p-reformService0003 .c-reformKanrenLink li {
    margin: 0 4% 11px 0 !important;
  }
  .p-reformService0003 .c-reformKanrenLink li:nth-of-type(2) {
    margin: 0 4% 10px 0 !important;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　提携会社について
------------------------------------------------------------*/
.p-reformPartner {
  background-color: #ffffff;
}

.p-reformPartner01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior05.jpg");
}

.p-reformPartner02 {
  color: #46483c;
  font-size: 1.5rem;
  line-height: 1.74;
}
.p-reformPartner02__content01 > p {
  margin-bottom: 32px;
}
.p-reformPartner02__content02 {
  margin-bottom: 15px;
  padding: 26px 29px 25px;
  background-color: #fbf7f1;
}
.p-reformPartner02__content02 h3 {
  margin-bottom: 6px;
  font-size: 1.8rem;
  font-weight: bold;
}
.p-reformPartner02__content02 ul {
  margin-right: 10px;
}
.p-reformPartner02__content02 ul:last-child {
  margin-right: 0;
}
.p-reformPartner02__content02 ul li {
  padding-left: 10px;
  font-feature-settings: "palt";
}
.p-reformPartner02__content02 ul li:before {
  width: 5px;
  height: 5px;
  margin: -3px 5px 0 -10px;
  content: "";
  background-color: #247404;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-reformPartner02__annotation {
  font-size: 1.3rem;
  text-align: right;
}

@media screen and (max-width: 640px) {
  .p-reformPartner01 {
    margin-bottom: 30px;
  }
  .p-reformPartner01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior05_sp.jpg");
  }
  .p-reformPartner02 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformPartner02__content01 > p {
    margin-bottom: 20px;
    font-size: 1.4rem;
  }
  .p-reformPartner02__content02 {
    padding: 15px;
  }
  .p-reformPartner02__content02 li {
    font-size: 1.4rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム リフォーム事例
------------------------------------------------------------*/
.p-reformVoice {
  background-color: #ffffff;
}
.p-reformVoice .c-breadcrumb1 {
  padding: 11px 0 43px;
}

.p-reformVoice01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior05.jpg");
}

.p-reformVoice02 {
  margin-bottom: 41px;
  font-size: 1.5rem;
  line-height: 1.73;
  color: #46483c;
}
.p-reformVoice02__content01 p {
  margin-bottom: 34px;
  line-height: 2.6rem;
}
.p-reformVoice02__content01 ul li {
  width: 50%;
  height: 60px;
  background-color: #ffffff;
  border: solid 2px #247404;
  transition: 0.3s ease-in-out;
}
.p-reformVoice02__content01 ul li:nth-child(1) {
  border-right-width: 1px;
}
.p-reformVoice02__content01 ul li:nth-child(2) {
  border-left-width: 1px;
}
.p-reformVoice02__content01 ul li:hover {
  background-color: #247404;
}
.p-reformVoice02__content01 ul li:hover a {
  color: #ffffff;
}
.p-reformVoice02__content01 ul li:hover a:after {
  background-image: url("../img/common/icon_arrow-white01.png");
}
.p-reformVoice02__content01 ul li a {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  font-weight: bold;
  color: #247404;
  text-align: center;
  display: block;
}
.p-reformVoice02__content01 ul li a:hover {
  opacity: 1;
}
.p-reformVoice02__content01 ul li a:after {
  width: 6px;
  height: 10px;
  margin: 0 auto;
  content: "";
  background-image: url("../img/common/icon_arrow-green03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
  transform: rotate(90deg);
}

.p-reformVoice03 {
  margin-bottom: 80px;
}
.p-reformVoice03 .c-refomeTitle03b {
  font-size: 2.8rem;
  line-height: 1;
  margin-bottom: 38px;
  padding: 0 22px;
}
.p-reformVoice03 .c-refomeTitle04 {
  padding: 9px 0 6px;
  margin-bottom: 27px;
}
.p-reformVoice03__content01 .p-reformVoiceBox {
  margin-bottom: 59px;
}

.p-reformVoice04 .c-refomeTitle03b {
  font-size: 2.8rem;
  line-height: 1;
  margin-bottom: 38px;
  padding: 0 22px;
}
.p-reformVoice04 .c-refomeTitle04 {
  padding: 9px 0 6px;
  margin-bottom: 27px;
}
.p-reformVoice04__content01 .p-reformVoiceBox {
  margin-bottom: 59px;
}

.p-reformVoiceBox {
  font-size: 1.5rem;
  line-height: 1.73;
  color: #46483c;
}
.p-reformVoiceBox__before_after {
  margin: 0;
}
.p-reformVoiceBox__before {
  width: 400px;
  position: relative;
}
.p-reformVoiceBox__before:after {
  width: 0;
  height: 0;
  content: "";
  display: block;
  border-style: solid;
  border-color: transparent transparent transparent #fa5b0f;
  position: absolute;
  right: -32px;
  top: 175px;
  border-width: 13px 0 13px 20px;
}
.p-reformVoiceBox__before span {
  line-height: 2.6rem;
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 1.8rem;
  font-weight: bold;
  color: rgba(70, 72, 60, 0.5);
  display: block;
}
.p-reformVoiceBox__after {
  width: 400px;
}
.p-reformVoiceBox__after span {
  line-height: 2.6rem;
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fa5b0f;
  display: block;
}
.p-reformVoiceBox__img01 {
  position: relative;
}
.p-reformVoiceBox__img01:before {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 8px #cccccc;
  position: absolute;
  top: 0;
  left: 0;
}
.p-reformVoiceBox__img01 img {
  margin-bottom: 17px;
  display: block;
}
.p-reformVoiceBox__img02 {
  position: relative;
}
.p-reformVoiceBox__img02:before {
  content: "";
  width: 100%;
  height: 100%;
  border: solid 8px #fa5b0f;
  position: absolute;
  top: 0;
  left: 0;
}
.p-reformVoiceBox__img02 img {
  margin-bottom: 17px;
  display: block;
}
.p-reformVoiceBox__text {
  font-size: 1.5rem;
  line-height: 2.6rem;
  margin: 8px 0 23px;
  text-align: justify;
}
.p-reformVoiceBox__voice {
  padding: 38px 40px 37px 140px;
  background-color: #fbf7f1;
  position: relative;
}
.p-reformVoiceBox__voice h3 {
  color: #141414;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 8px;
}
.p-reformVoiceBox__voice p {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: justify;
}
.p-reformVoiceBox__voice img {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.p-reformVoice05 {
  margin-bottom: 104px;
}
.p-reformVoice05__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformVoice01 {
    margin-bottom: 30px;
  }
  .p-reformVoice01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior05_sp.jpg");
  }
  .p-reformVoice02 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-reformVoice02__content01 p {
    margin-bottom: 20px;
  }
  .p-reformVoice02__content01 ul li {
    height: auto;
  }
  .p-reformVoice02__content01 ul li a {
    padding: 10px;
  }
  .p-reformVoice03 {
    padding: 0 20px;
  }
  .p-reformVoice03__content01 .p-reformVoiceBox {
    margin-bottom: 30px;
  }
  .p-reformVoice04 {
    padding: 0 20px;
  }
  .p-reformVoice04__content01 .p-reformVoiceBox {
    margin-bottom: 30px;
  }
  .p-reformVoiceBox__before_after {
    margin: 0 0 15px;
  }
  .p-reformVoiceBox__before {
    width: 100%;
    margin-bottom: 40px;
  }
  .p-reformVoiceBox__before:after {
    margin-right: -35px;
    border-width: 20px 35px 0 35px;
    border-color: #fa5b0f transparent transparent transparent;
    top: calc(100% + 10px);
    right: 50%;
  }
  .p-reformVoiceBox__before span {
    font-size: 1.6rem;
  }
  .p-reformVoiceBox__before img {
    margin: 0 auto 10px auto;
    display: block;
  }
  .p-reformVoiceBox__after {
    width: 100%;
  }
  .p-reformVoiceBox__after span {
    font-size: 1.6rem;
  }
  .p-reformVoiceBox__after img {
    margin: 0 auto 10px auto;
    display: block;
  }
  .p-reformVoiceBox__voice {
    padding: 15px;
  }
  .p-reformVoiceBox__voice p {
    *zoom: 1;
  }
  .p-reformVoiceBox__voice p:after {
    display: block;
    content: "";
    clear: both;
  }
  .p-reformVoiceBox__voice img {
    transform: translateY(0);
    margin: 0 10px 10px 0;
    float: left;
    position: static;
  }
  .p-reformVoice05 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　ご利用までの流れ
------------------------------------------------------------*/
.p-reformFlow {
  background-color: #ffffff;
}

.p-reformFlow01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior05.jpg");
}

.p-reformFlow02 {
  margin-bottom: 53px;
  font-size: 1.5rem;
  line-height: 1.73;
  color: #46483c;
}
.p-reformFlow02__content01 {
  margin-bottom: 60px;
}
.p-reformFlow02__content01 .c-refomeTitle04 {
  margin-bottom: 2px;
  padding: 9px 0 6px;
}
.p-reformFlow02__content01 p {
  text-align: center;
}
.p-reformFlow02__content02 {
  margin-bottom: 60px;
}
.p-reformFlow02__content02 ol li {
  margin-bottom: 40px;
  padding: 40px 40px 35px;
  position: relative;
  border: solid 1px;
}
.p-reformFlow02__content02 ol li:after {
  width: 0;
  height: 0;
  margin-left: -35px;
  content: "";
  display: block;
  border-style: solid;
  border-width: 20px 35px 0 35px;
  border-color: #000000 transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
}
.p-reformFlow02__content02 ol li dl {
  min-height: 130px;
  padding-left: 200px;
  position: relative;
}
.p-reformFlow02__content02 ol li dl dt {
  height: 45px;
  margin-bottom: 10px;
  padding: 0 120px 45px 0;
  font-size: 2rem;
  letter-spacing: 1.8px;
  font-weight: bold;
  border-bottom: dotted 1px #4c4c4c;
  position: relative;
}
.p-reformFlow02__content02 ol li dl dt > img {
  margin: -5px 15px 0 0;
  display: inline-block;
  vertical-align: middle;
}
.p-reformFlow02__content02 ol li dl dt span {
  position: absolute;
  top: -4px;
  right: -4px;
}
.p-reformFlow02__content02 ol li dl dd {
  line-height: 1.75;
}
.p-reformFlow02__content02 ol li dl dd img {
  position: absolute;
  top: 0;
  left: 0;
}
.p-reformFlow02__content02 ol li dl dd span {
  margin-top: 5px;
  font-size: 1.1rem;
  line-height: 1.5;
  display: block;
}
.p-reformFlow02__content02 ol li:nth-child(2) {
  padding: 43px 40px 35px;
}
.p-reformFlow02__content02 ol li:nth-child(2) dd img {
  top: -1px;
}
.p-reformFlow02__content02 ol li:nth-child(3) {
  padding: 40px 40px 32px;
}
.p-reformFlow02__content02 ol li:nth-child(3) dd img {
  top: -4px;
}
.p-reformFlow02__content02 ol li:nth-child(4) {
  padding: 40px 40px 32px;
}
.p-reformFlow02__content02 ol li:nth-child(5) {
  padding: 39px 40px 34px;
}
.p-reformFlow02__content02__step01 {
  background-color: rgba(250, 91, 15, 0.15);
  border-color: #fa5b0f !important;
}
.p-reformFlow02__content02__step01:after {
  border-color: #fa5b0f transparent transparent transparent !important;
}
.p-reformFlow02__content02__step01 dl dt {
  color: #fa5b0f;
}
.p-reformFlow02__content02__step02 {
  padding: 43px 40px 35px;
  background-color: rgba(58, 68, 82, 0.15);
  border-color: #3a4452 !important;
}
.p-reformFlow02__content02__step02:after {
  border-color: #3a4452 transparent transparent transparent !important;
}
.p-reformFlow02__content02__step02 dl dt {
  color: #3a4452;
}
.p-reformFlow02__content02__step03 {
  background-color: rgba(77, 170, 171, 0.15);
  border-color: #4daaab !important;
}
.p-reformFlow02__content02__step03:after {
  border-color: #4daaab transparent transparent transparent !important;
}
.p-reformFlow02__content02__step03 dl dt {
  color: #4daaab;
}
.p-reformFlow02__content02__step04 {
  background-color: rgba(77, 170, 171, 0.15);
  border-color: #4daaab !important;
}
.p-reformFlow02__content02__step04:after {
  border-color: #4daaab transparent transparent transparent !important;
}
.p-reformFlow02__content02__step04 dl dt {
  color: #4daaab;
}
.p-reformFlow02__content02__step05 {
  background-color: rgba(77, 170, 171, 0.15);
  border-color: #4daaab !important;
}
.p-reformFlow02__content02__step05:after {
  border-color: #4daaab transparent transparent transparent !important;
}
.p-reformFlow02__content02__step05 dl dt {
  color: #4daaab;
}
.p-reformFlow02__content02__step06 {
  background-color: rgba(250, 91, 15, 0.15);
  border-color: #fa5b0f !important;
}
.p-reformFlow02__content02__step06:after {
  content: none !important;
}
.p-reformFlow02__content02__step06 dl dt {
  color: #fa5b0f;
}
.p-reformFlow02__content03 dl dt {
  margin-bottom: 18px;
  padding: 9px 19px 8px;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: #68ba93;
}
.p-reformFlow02__content03 dl dd {
  font-size: 1.3rem;
  line-height: 1.53;
}
.p-reformFlow02__content03 p {
  line-height: 1.75;
}

.p-reformFlow03 {
  margin-bottom: 106px;
}

@media screen and (max-width: 640px) {
  .p-reformFlow01 {
    margin-bottom: 30px;
  }
  .p-reformFlow01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior05_sp.jpg");
  }
  .p-reformFlow02 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-reformFlow02__content01 {
    margin-bottom: 30px;
  }
  .p-reformFlow02__content02 {
    margin-bottom: 30px;
  }
  .p-reformFlow02__content02 ol li {
    margin-bottom: 25px;
    padding: 15px;
  }
  .p-reformFlow02__content02 ol li dl {
    min-height: auto;
    padding-left: 0;
    position: static;
  }
  .p-reformFlow02__content02 ol li dl dt {
    height: auto;
    margin-bottom: 15px;
    padding: 5px 0;
    font-size: 1.8rem;
  }
  .p-reformFlow02__content02 ol li dl dt > img {
    margin: 0 0 5px 0;
    display: block;
  }
  .p-reformFlow02__content02 ol li dl dt span {
    top: 0;
    right: 0;
  }
  .p-reformFlow02__content02 ol li dl dd {
    line-height: 1.7;
    font-size: 1.4rem;
    *zoom: 1;
  }
  .p-reformFlow02__content02 ol li dl dd:after {
    display: block;
    content: "";
    clear: both;
  }
  .p-reformFlow02__content02 ol li dl dd img {
    max-width: 50%;
    margin: 0 10px 0 0;
    float: left;
    display: block;
    position: static;
  }
  .p-reformFlow02__content02 ol li:nth-child(2), .p-reformFlow02__content02 ol li:nth-child(3), .p-reformFlow02__content02 ol li:nth-child(4), .p-reformFlow02__content02 ol li:nth-child(5) {
    padding: 15px;
  }
  .p-reformFlow02__content02 ol li:nth-child(2) dd img, .p-reformFlow02__content02 ol li:nth-child(3) dd img, .p-reformFlow02__content02 ol li:nth-child(4) dd img, .p-reformFlow02__content02 ol li:nth-child(5) dd img {
    top: 0;
  }
  .p-reformFlow02__content03 p {
    font-size: 1.4rem;
  }
  .p-reformFlow02__content03 dl dt {
    margin-bottom: 10px;
    padding: 10px;
  }
  .p-reformFlow03 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformFlow03 .c-reformKanrenLink li img {
    height: auto;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-reformFaq {
  padding: 52px 0 40px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq {
    padding: 85px 20px 70px;
  }
}
.p-reformFaq .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-reformFaq .c-breadcrumb1 a {
  color: #308bbb;
}
.p-reformFaq__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-reformFaq__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
.p-reformFaq__title02 {
  font-size: 2.4rem;
  color: #fff;
  background: #68ba93;
  padding: 16px 29px 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__title02 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.6rem;
    padding: 13px 15px 12px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
}
.p-reformFaq__box {
  margin-bottom: 20px;
}
.p-reformFaq__box:last-of-type {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box:last-of-type {
    margin-bottom: 30px;
  }
}
.p-reformFaq__box__q {
  font-size: 2rem;
  color: #46483c;
  line-height: 1.6;
  background: #f2f2f2;
  padding: 15px 29px 13px;
  cursor: pointer;
  position: relative;
  display: table;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box__q {
    font-size: 1.4rem;
    padding: 13px 35px 11px 15px;
  }
}
.p-reformFaq__box__q span {
  display: table-cell;
  width: 27px;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box__q span {
    width: 20px;
  }
}
.p-reformFaq__box__q:after, .p-reformFaq__box__q:before {
  content: "";
  background: #46483c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s ease-out;
}
.p-reformFaq__box__q:after {
  width: 24px;
  height: 3px;
  right: 20px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box__q:after {
    width: 10px;
    height: 2px;
    right: 10px;
  }
}
.p-reformFaq__box__q:before {
  width: 3px;
  height: 24px;
  right: 30px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box__q:before {
    width: 2px;
    height: 10px;
    right: 14px;
  }
}
.p-reformFaq__box__a {
  display: none;
  background: #fff;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 24px 40px 29px 37px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box__a {
    padding: 15px;
  }
}
.p-reformFaq__box__a p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
  display: table;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box__a p {
    font-size: 1.3rem;
    line-height: 1.6;
  }
}
.p-reformFaq__box__a span {
  display: table-cell;
  width: 32px;
  font-size: 2rem;
  font-weight: bold;
  color: #68ba93;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__box__a span {
    width: 20px;
    font-size: 1.8rem;
  }
}
.p-reformFaq__box.is-open .p-reformFaq__box__q::before {
  transform: translateY(-50%) rotate(-90deg);
}
.p-reformFaq__btn {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__btn {
    display: block;
    margin-bottom: 25px;
  }
}
.p-reformFaq__btn li {
  width: 550px;
  height: 80px;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__btn li {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
}
.p-reformFaq__btn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #68ba93;
  border: 3px solid #dddddd;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__btn a {
    font-size: 1.5rem;
    padding: 0 30px 0 10px;
    line-height: 1.6;
    border: 2px solid #dddddd;
  }
}
.p-reformFaq__btn a:after {
  content: "";
  width: 12px;
  height: 7px;
  background: url("../img/common/icon_down03.png");
  background-size: contain;
  position: absolute;
  top: 34px;
  right: 27px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-reformFaq__btn a:after {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

/*------------------------------------------------------------
リフォーム メンテナンススケジュール
------------------------------------------------------------*/
.p-reformCalendar {
  background-color: #ffffff;
}

.p-reformCalendar01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior05.jpg");
}

.p-reformCalendar02 {
  margin-bottom: 54px;
  color: #46483c;
  font-size: 1.5rem;
  line-height: 1.75;
}
.p-reformCalendar02__content01 .c-refomeTitle03 {
  margin: -10px 0 22px;
  padding-left: 23px;
}
.p-reformCalendar02__content01 .c-refomeTitle03:after {
  display: none;
}

.p-reformCalendar03 {
  margin-bottom: 80px;
  color: #46483c;
  font-size: 1.5rem;
  line-height: 1.73;
}
.p-reformCalendar03__tab__btn {
  width: 32%;
  color: #247404;
  padding: 12px 20px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: 2px solid #247404;
  border-right: none;
  transition: 0.3s ease-in-out;
}
.p-reformCalendar03__tab__btn:last-child {
  border-right: 2px solid #247404;
}
.p-reformCalendar03__tab__btn.is-open, .p-reformCalendar03__tab__btn:hover {
  color: #ffffff !important;
  background: #247404;
}
.p-reformCalendar03__tab__btn.is-open {
  cursor: default;
  position: relative;
}
.p-reformCalendar03__tab__btn.is-open:before {
  width: 0;
  height: 0;
  margin-left: -4px;
  content: "";
  border: solid transparent;
  border-width: 6px;
  border-color: #247404;
  pointer-events: none;
  position: absolute;
  left: 50%;
}
@media screen and (max-width: 640px) {
  .p-reformCalendar03__tab__btn.is-open:before {
    margin: 0 0 3px -4px;
    border-width: 4px;
  }
}
.p-reformCalendar03__tab--top {
  margin-bottom: 45px;
}
.p-reformCalendar03__tab--top .p-reformCalendar03__tab__btn.is-open:before {
  bottom: -8px;
  transform: rotate(45deg);
}
.p-reformCalendar03__tab--bottom {
  margin-top: 83px;
}
.p-reformCalendar03__tab--bottom .p-reformCalendar03__tab__btn.is-open:before {
  top: -7px;
  bottom: 100%;
  transform: rotate(45deg);
}
.p-reformCalendar03__content {
  display: none;
}
.p-reformCalendar03__content.is-open {
  display: block;
}
.p-reformCalendar03__content > h2 {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: bold;
  color: #fa5b0f;
}
.p-reformCalendar03__content > h2 span {
  padding: 0 10px 0 5px;
  letter-spacing: 0;
}
.p-reformCalendar03__content__img {
  margin-bottom: 72px;
}
.p-reformCalendar03__content__img img {
  margin: 0 auto;
}
.p-reformCalendar03__content__place {
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.p-reformCalendar03__content__place .c-refomeTitle03b {
  margin-bottom: 37px;
}
.p-reformCalendar03__content__place:last-of-type {
  padding-bottom: 0 !important;
  border-bottom: none;
}
.p-reformCalendar03__content__place .c-refomeTitle04 {
  width: 100%;
  padding: 9px 0 6px;
  margin-bottom: 32px;
}
@media screen and (max-width: 640px) {
  .p-reformCalendar03__content__place .c-refomeTitle04 {
    margin-bottom: 15px;
  }
}
.p-reformCalendar03__content__place__price {
  width: 190px;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 32px;
  text-align: right;
  color: #f2385a;
  border-bottom: dotted 1px #4c4c4c;
}
.p-reformCalendar03__content__place__price i {
  display: inline-block;
  vertical-align: middle;
  transform: translate(0, -5px);
}
.p-reformCalendar03__content__place__price em {
  margin: 0 2px;
  font-size: 3.2rem;
  font-style: normal;
}
.p-reformCalendar03__content__place__point {
  margin-bottom: 42px;
  padding: 32px 40px;
  background-color: #fbf7f1;
}
.p-reformCalendar03__content__place__point dt {
  margin-bottom: 8px;
  font-size: 1.8rem;
  font-weight: bold;
}
.p-reformCalendar03__content__place__point dd ul li {
  padding-left: 20px;
}
.p-reformCalendar03__content__place__point dd ul li:before {
  width: 12px;
  height: 9px;
  margin: -3px 8px 0 -20px;
  content: "";
  background-image: url("../img/common/icon_check_orange01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-reformCalendar03__content .place01 {
  margin-bottom: 10px;
}
.p-reformCalendar03__content .place01 .c-refomeTitle03b {
  margin-bottom: 26px;
}
.p-reformCalendar03 .p-reformCalendar__box__title {
  display: none;
}

.p-reformCalendar04 {
  margin-bottom: 105px;
}
.p-reformCalendar04__content01 .c-refomeTitle04 {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .p-reformCalendar01 {
    margin-bottom: 30px;
  }
  .p-reformCalendar01__content01 .c-refomeTitle02 {
    padding: 27px 20px;
    background-image: url("../img/reform/title_obi_interior05_sp.jpg");
  }
  .p-reformCalendar02 {
    margin-bottom: 32px;
    padding: 0 20px;
    font-size: 1.4rem;
  }
  .p-reformCalendar02__content01 .c-refomeTitle04 {
    margin-bottom: 12px;
  }
  .p-reformCalendar02__content01 .c-refomeTitle03 {
    padding-left: 12px;
    margin: 0 0 5px;
  }
  .p-reformCalendar03 {
    margin-bottom: 30px;
  }
  .p-reformCalendar03__tab {
    padding: 0 20px;
  }
  .p-reformCalendar03__tab__btn {
    padding: 10px 3px 8px;
    font-size: 1.4rem;
  }
  .p-reformCalendar03__tab__btn span {
    display: block;
  }
  .p-reformCalendar03__tab--top {
    margin-bottom: 33px;
  }
  .p-reformCalendar03__tab--bottom {
    margin-top: 30px;
  }
  .p-reformCalendar03__content > h2 {
    margin-bottom: 10px;
    padding: 0 20px;
    font-size: 1.6rem;
  }
  .p-reformCalendar03__content__img {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-reformCalendar03__content__place, .p-reformCalendar03__content .place01 {
    margin-bottom: 0;
    padding-bottom: 1px;
  }
  .p-reformCalendar03__content__place__item, .p-reformCalendar03__content .place01__item {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
  .p-reformCalendar03__content__place__price, .p-reformCalendar03__content .place01__price {
    margin-bottom: 20px;
    font-size: 1.4rem;
  }
  .p-reformCalendar03__content__place__price em, .p-reformCalendar03__content .place01__price em {
    font-size: 2.6rem;
  }
  .p-reformCalendar03__content__place__point, .p-reformCalendar03__content .place01__point {
    margin-bottom: 20px;
    padding: 15px 20px;
  }
  .p-reformCalendar03__content__place__point dt, .p-reformCalendar03__content .place01__point dt {
    font-size: 1.3rem;
    margin-bottom: 3px;
  }
  .p-reformCalendar03__content__place__point dd, .p-reformCalendar03__content .place01__point dd {
    font-size: 1.2rem;
  }
  .p-reformCalendar03 .p-reformCalendar__box {
    margin-bottom: 0;
  }
  .p-reformCalendar03 .p-reformCalendar__box__title {
    font-size: 1.5rem;
    color: #fff;
    line-height: 1.6;
    background: #48a3a4;
    padding: 14px 25px 14px 20px;
    cursor: pointer;
    position: relative;
    display: table;
    width: 100%;
  }
  .p-reformCalendar03 .p-reformCalendar__box__title span {
    display: table-cell;
    width: 27px;
    margin-right: 5px;
  }
  .p-reformCalendar03 .p-reformCalendar__box__title:after, .p-reformCalendar03 .p-reformCalendar__box__title:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.25s ease-out;
  }
  .p-reformCalendar03 .p-reformCalendar__box__title:after {
    width: 10px;
    height: 1px;
    right: 15px;
  }
  .p-reformCalendar03 .p-reformCalendar__box__title:before {
    width: 1px;
    height: 10px;
    right: 19px;
  }
  .p-reformCalendar03 .p-reformCalendar__box__content {
    padding: 27px 20px 15px;
  }
  .p-reformCalendar03 .p-reformCalendar__box.is-open02 .p-reformCalendar__box__title::before {
    transform: translateY(-50%) rotate(-90deg);
  }
  .p-reformCalendar04 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
リフォーム　商品一覧ページ
------------------------------------------------------------*/
.p-reformSample {
  background-color: #ffffff;
}

.p-reformSample01__content01 .c-refomeTitle02 {
  background-image: url("../img/reform/title_obi_interior05.jpg");
}

.p-reformSample02 {
  margin-bottom: 100px;
}
.p-reformSample02__content01 .c-refomeTitle03 {
  margin-bottom: 30px;
}
.p-reformSample02 .c-reformServiceItemList01__items li {
  margin-bottom: 25px;
}

@media screen and (max-width: 640px) {
  .p-reformSample01 {
    margin-bottom: 28px;
  }
  .p-reformSample01__content01 .c-refomeTitle02 {
    background-image: url("../img/reform/title_obi_interior05_sp.jpg");
  }
  .p-reformSample02 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-reformSample02__content01 .c-reformServiceItemList01__imgs {
    margin-bottom: 11px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービスTOP
------------------------------------------------------------*/
.p-kurashiTop {
  background-color: #ffffff;
}
.p-kurashiTop .l-contentServiceSide .c-side06 .u-hover01:hover {
  opacity: 1;
}

.p-kurashiTop01 {
  min-width: 1120px;
  margin-bottom: 60px;
  overflow: hidden;
  position: relative;
}
.p-kurashiTop01 .p-kurashiTop01__sliderBord .slick-dotBox .slick-dots {
  position: static;
}
.p-kurashiTop01 .p-kurashiTop01__sliderBord .slick-dotBox .slick-dots li {
  margin: 0;
}
.p-kurashiTop01 .p-kurashiTop01__sliderBord .slick-dotBox .slick-dots li button:before {
  font-size: 10px;
  color: #dddddd;
  opacity: 1 !important;
}
.p-kurashiTop01 .p-kurashiTop01__sliderBord .slick-dotBox .slick-dots li.slick-active button:before {
  color: #faad5a;
}
.p-kurashiTop01 .p-kurashiTop01__sliderBord .slick-arrow2 {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
  background-image: url("../img/common/icon_maru-sankaku01.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
}
.p-kurashiTop01 .p-kurashiTop01__sliderBord .slick-arrow2.slick-prev2 {
  transform: rotate(180deg);
}
.p-kurashiTop01__PrevNext {
  position: absolute;
  top: 240px;
  left: calc(50% - 560px);
  width: 1120px;
}
@media screen and (max-width: 640px) {
  .p-kurashiTop01__PrevNext {
    display: none;
  }
}
.p-kurashiTop01__PrevNext .slick-arrow3 {
  width: 60px;
  height: 60px;
  position: absolute;
  top: calc(50% - 30px);
  cursor: pointer;
}
.p-kurashiTop01__PrevNext .slick-arrow3.slick-prev3 {
  background-image: url("../img/common/icon_maru-sankaku01.png");
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 1;
  left: -49px;
  z-index: 10;
  transform: rotate(180deg);
}
.p-kurashiTop01__PrevNext .slick-arrow3.slick-next3 {
  background-image: url("../img/common/icon_maru-sankaku01.png");
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 1;
  right: -49px;
  z-index: 10;
  transform: none;
}

.p-kurashiSlider {
  display: none;
}
.p-kurashiSlider.slick-initialized {
  display: block;
}
.p-kurashiSlider .slick-slide {
  width: 1120px;
  height: 480px;
  margin: 0 20px;
  position: relative;
  overflow: hidden;
  border: solid 1px #f8f8f8;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.p-kurashiSlider .slick-slide:before {
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(253, 250, 207, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.p-kurashiSlider .slick-slide a {
  width: 100%;
  height: 100%;
  display: table;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-kurashiSlider .slick-center:before {
  content: none;
}
.p-kurashiSlider .slick-center .p-kurashiSlider__text {
  opacity: 1;
}
.p-kurashiSlider .slick-arrow {
  width: 60px;
  height: 60px;
  display: none !important;
}
.p-kurashiSlider .slick-arrow:before {
  content: none;
}
.p-kurashiSlider .slick-arrow.slick-prev {
  background-image: url("../img/common/icon_maru-sankaku01.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  top: calc(50% - 30px);
  left: 18%;
  z-index: 10;
  transform: rotate(180deg);
}
.p-kurashiSlider .slick-arrow.slick-next {
  background-image: url("../img/common/icon_maru-sankaku01.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  top: calc(50% - 30px);
  right: 18%;
  z-index: 10;
  transform: none;
}
.p-kurashiSlider__text {
  padding: 50px;
  color: #483825;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
  background-image: url("../img/kurashi/index_mv_mask.png");
  background-repeat: no-repeat;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.p-kurashiSlider__text__title {
  width: 300px;
  margin-bottom: 25px;
  padding-left: 25px;
  display: block;
  font-size: 4.4rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  position: relative;
}
.p-kurashiSlider__text__title:before {
  width: 5px;
  height: 100%;
  content: "";
  background-color: #faad5a;
  position: absolute;
  top: 0;
  left: 0;
}
.p-kurashiSlider__text__excerpt {
  width: 300px;
  display: block;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 1.75;
}

.p-kurashiTop02 {
  margin-bottom: 42px;
}
.p-kurashiTop02 .p-kurashiTop02__content01 .c-card1 .u-hover01:hover {
  opacity: 1;
}

.p-kurashiTop03 {
  margin-bottom: 36px;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f5f6f7;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select p {
  font-size: 1.6rem;
  font-weight: bold;
  color: #445161;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select p:before {
  width: 30px;
  height: 30px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_search05.png");
  -webkit-background-size: 66%;
  background-size: 66%;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select .p-kurashiTop03__content01__select__wrap {
  position: relative;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select .p-kurashiTop03__content01__select__wrap:before {
  width: 7px;
  height: 10px;
  content: "";
  background-image: url("../img/common/icon_arrow-gray05.png");
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  pointer-events: none;
  position: absolute;
  top: calc((100% - 7px) / 2);
  right: 10px;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select .p-kurashiTop03__content01__select__wrap select {
  width: 465px;
  padding: 5px 10px;
  color: #999999;
  background-color: #ffffff;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  border: solid 1px #445161;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select .p-kurashiTop03__content01__select__wrap select::-ms-expand {
  display: none;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select .p-kurashiTop03__content01__select__wrap select option {
  font-size: 1.6rem;
  line-height: 2;
  color: #46483c;
  display: block;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__sliderBord .slick-dotBox2 .slick-dots {
  position: static;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__sliderBord .slick-dotBox2 .slick-dots li {
  margin: 0;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__sliderBord .slick-dotBox2 .slick-dots li button:before {
  font-size: 10px;
  color: #dddddd;
  opacity: 1 !important;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__sliderBord .slick-dotBox2 .slick-dots li.slick-active button:before {
  color: #faad5a;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__sliderBord .slick-arrow3 {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
  background-image: url("../img/common/icon_maru-sankaku01.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
}
.p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__sliderBord .slick-arrow3.slick-prev3 {
  transform: rotate(180deg);
}

.p-kurashiTop04 {
  margin-bottom: 40px;
}
.p-kurashiTop04 .p-kurashiTop04__content01 {
  width: 100%;
  height: 200px;
  overflow: hidden;
  background-image: url("../img/kurashi/carender_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  border: solid 2px #fa9932;
  position: relative;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.p-kurashiTop04 .p-kurashiTop04__content01 h2 {
  margin-bottom: 6px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #483825;
}
.p-kurashiTop04 .p-kurashiTop04__content01 h2:before {
  width: 30px;
  height: 30px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_carender01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-kurashiTop04 .p-kurashiTop04__content01 p {
  width: 370px;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #483825;
}
.p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #FFF;
}
.p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 span {
  transition: 0.3s ease-in-out;
  background: #fa9932;
  border-radius: 4px;
}
.p-kurashiTop04 .p-kurashiTop04__content01 a {
  padding: 24px 30px 30px 28px;
  display: block;
}
.p-kurashiTop04 .p-kurashiTop04__content01 a:hover {
  opacity: 1;
}
.p-kurashiTop04 .p-kurashiTop04__content01 a:hover h2, .p-kurashiTop04 .p-kurashiTop04__content01 a:hover p {
  opacity: 0.8;
}
.p-kurashiTop04 .p-kurashiTop04__content01 a:hover .c-kurashiBtn01 span {
  opacity: 0.7;
}

.p-kurashiTop05 {
  margin-bottom: 40px;
  padding: 29px 30px 11px 28px;
  background-color: #fefce2;
}
.p-kurashiTop05 .p-kurashiTop05__content01 {
  position: relative;
}
.p-kurashiTop05 .p-kurashiTop05__content01 h2 {
  margin-bottom: 10px;
  padding-bottom: 25px;
  font-size: 2rem;
  font-weight: bold;
  color: #483825;
  background-image: url("../img/kurashi/title01_line.png");
  background-repeat: repeat-x;
  background-position: 0 bottom;
}
.p-kurashiTop05 .p-kurashiTop05__content01 h2 span {
  margin: 10px 0 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList li {
  padding: 19px 0 17px;
  border-bottom: dashed 1px rgba(115, 62, 1, 0.3);
}
.p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList li:last-child {
  border: none;
}
.p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList li p {
  width: 550px;
  color: #594242;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
}
.p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList li a {
  color: #594242;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
}
.p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList li a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList .p-kurashiTop05__content01__newsList_data {
  width: 112px;
  font-size: 1.6rem;
  padding-left: 5px;
  color: #733e01;
}
.p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList .c-newsCate02 {
  width: 100px;
  margin-right: 20px;
  font-weight: 500;
}
.p-kurashiTop05 .p-kurashiTop05__content01 .c-kurashiBtn02 {
  width: 116px;
  position: absolute;
  top: 0;
  right: -2px;
}

@media screen and (max-width: 640px) {
  .p-kurashiTop01 {
    min-width: 100%;
    margin-bottom: 32px;
    overflow: visible;
  }
  .p-kurashiTop01 .p-kurashiTop01__sliderBord .slick-arrow2 {
    width: 30px;
    height: 30px;
  }
  .p-kurashiSlider .slick-slide {
    width: 100%;
    height: auto;
    margin: 0;
    border: none;
    border-bottom: solid 1px #f8f8f8;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .p-kurashiSlider .slick-slide:before {
    content: none;
  }
  .p-kurashiSlider .slick-slide a {
    background: none !important;
    display: block;
  }
  .p-kurashiSlider .p-kurashiSlider__img {
    display: block;
    position: relative;
  }
  .p-kurashiSlider .p-kurashiSlider__img:after {
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../img/kurashi/index_mv_mask_sp.png");
    background-repeat: no-repeat;
    background-position: 0 bottom;
    position: absolute;
    top: 0;
    left: 0;
  }
  .p-kurashiSlider__text {
    padding: 2px 20px 10px 20px;
    display: block;
    background: none;
    background-color: #ffffff;
    opacity: 1;
  }
  .p-kurashiSlider__text__title {
    width: 100%;
    margin-bottom: 8px;
    display: block;
    font-size: 2rem;
    padding-left: 14px;
  }
  .p-kurashiSlider__text__excerpt {
    width: 100%;
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .slick-slider {
    margin-bottom: 15px !important;
  }
  .p-kurashiTop02 {
    margin-bottom: 32px;
  }
  .p-kurashiTop02 .p-kurashiTop02__content01 {
    padding: 0 20px;
  }
  .p-kurashiTop03 {
    margin-bottom: 21px;
  }
  .p-kurashiTop03 .p-search {
    margin-bottom: 15px;
  }
  .p-kurashiTop03 .p-kurashiTop03__content01 {
    padding: 0 20px;
  }
  .p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select {
    margin-bottom: 20px;
    padding: 15px;
  }
  .p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select .p-kurashiTop03__content01__select__wrap {
    width: 100%;
  }
  .p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__content01__select .p-kurashiTop03__content01__select__wrap select {
    width: 100%;
  }
  .p-kurashiTop03 .p-kurashiTop03__content01 .p-kurashiTop03__sliderBord .slick-arrow3 {
    width: 30px;
    height: 30px;
  }
  .p-kurashiTop04 {
    margin-bottom: 30px;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 {
    padding: 0 20px;
    height: auto;
    overflow: visible;
    background: none;
    border: none;
    position: static;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 h2 {
    margin-bottom: 3px;
    font-size: 2rem;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 h2:before {
    width: 20px;
    height: 20px;
    margin: -5px 2px 0 0;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 p {
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 {
    width: 100%;
    height: 60px;
    text-align: left;
    font-size: 1.5rem;
    line-height: 2.66;
    background-image: url("../img/kurashi/carender_bg_sp.jpg");
    -webkit-background-size: cover;
    background-size: cover;
    position: static;
    border: solid 1px #fa9932;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 a, .p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 span {
    padding: 10px 10px 10px 14px;
    color: #fa9932;
    font-size: 1.4rem;
    font-weight: bold;
    background: none;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 a:before, .p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 span:before {
    width: 20px;
    height: 20px;
    margin: 0 9px 0 0;
    content: "";
    background-image: url("../img/common/icon_maru-sankaku02.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 a:after, .p-kurashiTop04 .p-kurashiTop04__content01 .c-kurashiBtn01 span:after {
    content: none;
  }
  .p-kurashiTop04 .p-kurashiTop04__content01 a {
    padding: 0;
  }
  .p-kurashiTop05 {
    margin: 0 15px 25px;
    padding: 20px 15px;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 h2 {
    margin-bottom: 0;
    font-size: 1.7rem;
    line-height: 1;
    text-align: center;
    padding-bottom: 7px;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 h2 span {
    margin: 3px auto 0 auto;
    display: block;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList {
    margin-bottom: 10px;
    padding: 0 10px 0;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList li {
    padding: 15px 0 11px;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList li a {
    width: 100%;
    font-size: 1.4rem;
    line-height: 2.1rem;
    padding-top: 5px;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList .p-kurashiTop05__content01__newsList_data {
    width: auto;
    font-size: 1.2rem;
    color: #733e01;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList .p-reformTop02__contents01__newsList_data {
    width: auto;
    margin: 0 1em 10px 0;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 .p-kurashiTop05__content01__newsList .c-newsCate02 {
    width: auto;
    height: auto;
    font-size: 1rem;
    line-height: 1;
    padding: 3px 15px;
    margin: 0 0 0 9px;
  }
  .p-kurashiTop05 .p-kurashiTop05__content01 .c-kurashiBtn02 {
    margin: 0 auto;
    position: static;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス　特集商品一覧
------------------------------------------------------------*/
.p-kurashiFeature {
  background-color: #ffffff;
}

.p-kurashiFeature01 {
  margin-bottom: 32px;
}
.p-kurashiFeature01__content01__img {
  margin-bottom: 32px;
}
.p-kurashiFeature01__content01__img img {
  width: 100%;
}
.p-kurashiFeature01__content01__text p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiFeature01__content01 .c-kurashiTitle02 {
  margin-bottom: 14px;
}

.p-kurashiFeature02 {
  margin-bottom: 52px;
}
.p-kurashiFeature02__content01 {
  margin-bottom: 30px;
}
.p-kurashiFeature02__content01 .c-kurashiPager01 {
  margin-top: 10px;
}

.p-kurashiFeature03 {
  margin-bottom: 100px;
}
.p-kurashiFeature03__slider {
  padding: 0 20px;
}
.p-kurashiFeature03__slider__item {
  width: calc(33.3333% - 20px);
  margin: 0 10px;
}
.p-kurashiFeature03__slider__item__title {
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
}
.p-kurashiFeature03__slider__item a {
  color: #733e01;
  text-decoration: underline;
  display: block;
}
.p-kurashiFeature03__slider__item a:hover {
  text-decoration: none;
  opacity: 1;
}
.p-kurashiFeature03__slider__item img {
  width: 100%;
  height: 138px;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
}
.p-kurashiFeature03__slider .slick-arrow {
  width: 11px;
  height: 16px;
  margin-top: -23px;
}
.p-kurashiFeature03__slider .slick-arrow:before {
  content: none;
}
.p-kurashiFeature03__slider .slick-arrow.slick-prev {
  background-image: url("../img/common/icon_arrow-orange02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  z-index: 10;
  left: 0;
  transform: rotate(180deg);
}
.p-kurashiFeature03__slider .slick-arrow.slick-next {
  background-image: url("../img/common/icon_arrow-orange02.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  z-index: 10;
  right: 0;
  transform: none;
}

@media screen and (max-width: 640px) {
  .p-kurashiFeature01 {
    margin-bottom: 25px;
  }
  .p-kurashiFeature01__content01__img {
    margin-bottom: 20px;
  }
  .p-kurashiFeature01__content01__text {
    padding: 0 20px;
  }
  .p-kurashiFeature01__content01__text p {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .p-kurashiFeature01__content01 .c-kurashiTitle02 {
    letter-spacing: 2px;
    margin-bottom: 16px;
  }
  .p-kurashiFeature02 {
    margin-bottom: 26px;
  }
  .p-kurashiFeature02__content01 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-kurashiFeature02__content01 .c-kurashiPager01 {
    margin-top: 0;
  }
  .p-kurashiFeature03 {
    padding: 0 20px;
    margin-bottom: 90px;
  }
  .p-kurashiFeature03__slider {
    padding: 0;
  }
  .p-kurashiFeature03__slider__item {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: dashed 1px #666666;
  }
  .p-kurashiFeature03__slider__item__title {
    width: 60%;
    margin: 0;
    padding-left: 10px;
    font-size: 1.4rem;
  }
  .p-kurashiFeature03__slider__item a {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .p-kurashiFeature03__slider__item a img {
    width: 44%;
    height: 69px;
  }
  .p-kurashiFeature03__slider .slick-arrow {
    width: 30px;
    height: 30px;
    margin: 0;
  }
  .p-kurashiFeature03__slider .slick-arrow.slick-prev {
    background-image: url("../img/common/icon_maru-sankaku01.png") !important;
    top: auto;
    bottom: -40px;
    left: 15%;
  }
  .p-kurashiFeature03__slider .slick-arrow.slick-next {
    background-image: url("../img/common/icon_maru-sankaku01.png") !important;
    top: auto;
    bottom: -40px;
    right: 15%;
  }
  .p-kurashiFeature03__slider .slick-dots {
    width: 60%;
    margin: 0 auto;
    bottom: -29px;
    left: 0;
    right: 0;
  }
  .p-kurashiFeature03__slider .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 5px;
    padding: 1px;
    background-color: #dddddd;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .p-kurashiFeature03__slider .slick-dots li button {
    width: 8px;
    height: 8px;
    padding: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .p-kurashiFeature03__slider .slick-dots li button:before {
    content: none;
  }
  .p-kurashiFeature03__slider .slick-dots li.slick-active {
    background-color: #faad5a !important;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス 商品一覧
------------------------------------------------------------*/
.p-kurashiService {
  background-color: #ffffff;
}

.p-kurashiService01__content02 .c-kurashiPager01 {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .p-kurashiService {
    padding: 20px 20px 49px;
  }
  .p-kurashiService01__content02 .c-kurashiPager01 {
    margin-top: 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス 商品詳細
------------------------------------------------------------*/
.p-kurashiServiceDetail {
  background-color: #ffffff;
}

.p-kurashiServiceDetai__txtarea p {
  margin-bottom: 30px;
}

.p-kurashiServiceDetail01 {
  margin-bottom: 50px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiServiceDetail01 .c-kurashiTitle02 {
  margin-bottom: 40px;
  line-height: 1.3;
}
.p-kurashiServiceDetail01__content01 {
  margin-bottom: 33px;
}
.p-kurashiServiceDetail01__content01__slide {
  width: 710px;
  margin: 0 auto 8px auto !important;
}
.p-kurashiServiceDetail01__content01__slide img {
  width: auto;
  height: 100%;
  max-height: 406px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .p-kurashiServiceDetail01__content01__slide img {
    height: 183px;
  }
}
.p-kurashiServiceDetail01__content01__sliderBord .slick-dotBox .slick-dots {
  position: static;
}
.p-kurashiServiceDetail01__content01__sliderBord .slick-dotBox .slick-dots li {
  margin: 0;
}
.p-kurashiServiceDetail01__content01__sliderBord .slick-dotBox .slick-dots li button:before {
  font-size: 10px;
  color: #dddddd;
  opacity: 1 !important;
}
.p-kurashiServiceDetail01__content01__sliderBord .slick-dotBox .slick-dots li.slick-active button:before {
  color: #faad5a;
}
.p-kurashiServiceDetail01__content01__sliderBord .slick-arrow2 {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  display: none;
  vertical-align: middle;
  background-image: url("../img/common/icon_maru-sankaku01.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
}
.p-kurashiServiceDetail01__content01__sliderBord .slick-arrow2.slick-prev2 {
  transform: rotate(180deg);
}
.p-kurashiServiceDetail01__content01 .slick-arrow {
  width: 21px;
  height: 34px;
}
.p-kurashiServiceDetail01__content01 .slick-arrow:before {
  content: none;
}
.p-kurashiServiceDetail01__content01 .slick-arrow.slick-prev {
  background-image: url("../img/common/icon_arrow-orange03.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  top: calc(50% - 17px);
  left: -60px;
  z-index: 10;
  transform: rotate(180deg);
}
.p-kurashiServiceDetail01__content01 .slick-arrow.slick-next {
  background-image: url("../img/common/icon_arrow-orange03.png") !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  opacity: 1 !important;
  top: calc(50% - 17px);
  right: -60px;
  z-index: 10;
  transform: none;
}
.p-kurashiServiceDetail01__content02 {
  margin-bottom: 31px;
}
.p-kurashiServiceDetail01__content02__movie {
  width: 710px;
  margin: 0 auto;
}
.p-kurashiServiceDetail01__content02__movie iframe {
  width: 100%;
  height: 330px;
}
.p-kurashiServiceDetail01__content02__movie + .p-kurashiServiceDetail01__content02__movie {
  margin-top: 30px;
}
.p-kurashiServiceDetail01__content03 {
  margin-bottom: 52px;
}
.p-kurashiServiceDetail01__content04 {
  margin-bottom: 35px;
}
.p-kurashiServiceDetail01__content04 .c-table2 {
  width: 100%;
  margin-bottom: 17px;
}
.p-kurashiServiceDetail01__content04 .c-table2 dl {
  color: #594242;
}
.p-kurashiServiceDetail01__content04 .c-table2 dl label {
  color: #594242;
}
.p-kurashiServiceDetail01__content04 .c-table2 dl dt {
  width: 160px;
  background-color: rgba(250, 173, 90, 0.15);
  padding: 14px 0 12px 30px;
}
.p-kurashiServiceDetail01__content04 .c-table2 dl dd {
  width: calc(100% - 160px);
  padding: 14px 0 12px 31px;
}
.p-kurashiServiceDetail01__content04 .c-table2 dl dd strong {
  color: #f60b26;
  font-weight: bold;
}
.p-kurashiServiceDetail01__content04__annotation {
  font-size: 1.3rem;
}
.p-kurashiServiceDetail01__content04__annotation2 {
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.p-kurashiServiceDetail01__content05 {
  margin-bottom: 62px;
}
.p-kurashiServiceDetail01__content05 form {
  margin-bottom: 17px;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart {
  width: 100%;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart th {
  padding: 10px 0 10px 28px;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart td {
  padding: 19px 17px 21px 31px !important;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__title {
  background-color: rgba(250, 173, 90, 0.15);
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item .col-name {
  width: auto;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item .col-note {
  width: 180px;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item .col-price {
  width: 180px;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item .col-num {
  width: 180px;
}
.p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item .col-num select {
  width: 58px;
  height: 30px;
  border-radius: 5px;
  padding: 0 7px;
  margin-right: 10px;
  display: inline-block;
}
.p-kurashiServiceDetail01__content05 .l-errorBox {
  text-align: right;
}
@media screen and (max-width: 640px) {
  .p-kurashiServiceDetail01__content05 .l-errorBox {
    text-align: left;
  }
}
.p-kurashiServiceDetail01__content05 .c-error {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .p-kurashiServiceDetail01__content05 .c-error {
    margin-top: 10px;
  }
}
.p-kurashiServiceDetail01__content05 .c-cartBtn {
  margin-top: 10px;
  display: block;
  *zoom: 1;
}
.p-kurashiServiceDetail01__content05 .c-cartBtn:after {
  display: block;
  content: "";
  clear: both;
}
.p-kurashiServiceDetail01__content05 .c-cartBtn__btn15 {
  width: 140px;
  height: 34px;
  font-size: 1.6rem;
}
.p-kurashiServiceDetail01__content06 {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .p-kurashiServiceDetail01__content06 {
    display: block;
  }
}
.p-kurashiServiceDetail01__content06 .c-kurashiBtn03 {
  margin: 0;
}
.p-kurashiServiceDetail01__content06 .c-kurashiBtn03:nth-of-type(2) {
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-kurashiServiceDetail01__content06 .c-kurashiBtn03:nth-of-type(2) {
    margin-left: 0;
    margin-top: 10px;
  }
}
.p-kurashiServiceDetail01__content07 {
  margin-bottom: 30px;
}
.p-kurashiServiceDetail01__content08__box {
  padding: 27px 22px;
  border: solid 5px rgba(250, 173, 90, 0.15);
}
.p-kurashiServiceDetail01__content08__box__tel {
  margin-top: -5px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.p-kurashiServiceDetail01__content08__box__tel span {
  font-size: 2.2rem !important;
  letter-spacing: 1px !important;
}
.p-kurashiServiceDetail01__content08__box__tel span:before {
  width: 21px;
  height: 18px;
  margin: -3px 11px 0 3px;
  content: "";
  background-image: url("../img/common/icon_tel03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-kurashiServiceDetail01__content08__box table {
  width: 100%;
}
.p-kurashiServiceDetail01__content08__box table th {
  width: 140px;
  padding: 5px 0 9px;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}
.p-kurashiServiceDetail01__content08__box table td {
  padding: 5px 0 9px;
  vertical-align: top;
  color: #445161;
}
.p-kurashiServiceDetail01__content08__box table td a {
  color: #308bbb;
  text-decoration: underline;
}
.p-kurashiServiceDetail01__content08__box table td a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-kurashiServiceDetail01__content08__box table td span {
  font-size: 1.4rem;
  line-height: 1.5;
}

.p-kurashiServiceDetail02 {
  margin-bottom: 100px;
}
.p-kurashiServiceDetail02__content01 .c-kurashiPager01 {
  margin-top: 13px;
}

@media screen and (max-width: 640px) {
  .p-kurashiServiceDetail {
    padding: 30px 0;
  }
  .p-kurashiServiceDetai__txtarea p {
    margin-bottom: 15px;
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .p-kurashiServiceDetail01 {
    margin-bottom: 30px;
  }
  .p-kurashiServiceDetail01 .c-kurashiTitle02 {
    margin: -3px 20px 33px;
    padding: 0 0 0 13px;
    line-height: 1.5;
    letter-spacing: 2.2px;
    border-left: 3px solid #faad5a;
  }
  .p-kurashiServiceDetail01 .c-kurashiTitle02:before {
    display: none;
  }
  .p-kurashiServiceDetail01 .c-kurashiTitle02:after {
    left: 0;
    top: unset;
    bottom: -13px;
  }
  .p-kurashiServiceDetail01 p {
    font-size: 1.4rem;
  }
  .p-kurashiServiceDetail01__content01 {
    margin-bottom: 20px;
  }
  .p-kurashiServiceDetail01__content01__slide {
    width: 100%;
    margin: 0 auto 10px !important;
    max-height: 100%;
  }
  .p-kurashiServiceDetail01__content01__sliderBord .slick-arrow2 {
    display: inline-block;
  }
  .p-kurashiServiceDetail01__content02 {
    margin-bottom: 21px;
  }
  .p-kurashiServiceDetail01__content02__movie {
    width: 100%;
    margin: 0 auto;
  }
  .p-kurashiServiceDetail01__content02__movie iframe {
    max-width: 100%;
    height: 100%;
  }
  .p-kurashiServiceDetail01__content02__movie + .p-kurashiServiceDetail01__content02__movie {
    margin-top: 15px;
  }
  .p-kurashiServiceDetail01__content03 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-kurashiServiceDetail01__content04 {
    margin-bottom: 13px;
    padding: 6px 20px 0;
  }
  .p-kurashiServiceDetail01__content04 .c-table2 {
    margin-bottom: 6px;
  }
  .p-kurashiServiceDetail01__content04 .c-table2 dt {
    padding: 10px 15px 11px !important;
  }
  .p-kurashiServiceDetail01__content04 .c-table2 dd {
    padding: 10px 15px !important;
  }
  .p-kurashiServiceDetail01__content04__annotation {
    font-size: 1.1rem;
  }
  .p-kurashiServiceDetail01__content05 {
    margin-bottom: 21px;
    padding: 0 20px;
  }
  .p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item td {
    padding: 0 !important;
  }
  .p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item td .col-title {
    width: 24%;
    line-height: 1.4;
    margin: 0 10px 0 0;
    background-color: rgba(250, 173, 90, 0.15);
    display: table-cell;
    vertical-align: middle;
  }
  .p-kurashiServiceDetail01__content05 .c-tbl01--kurashiCart .c-tbl01__item .col-num select {
    width: 140px;
    margin: 3px 13px 0 0;
  }
  .p-kurashiServiceDetail01__content05 .c-cartBtn {
    margin-top: 16px;
  }
  .p-kurashiServiceDetail01__content05 .c-cartBtn__btn15 {
    max-width: 150px;
    height: 40px;
    margin: 0 auto;
    display: block;
  }
  .p-kurashiServiceDetail01__content06 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-kurashiServiceDetail01__content06 .c-kurashiBtn03 {
    width: 100%;
    height: auto;
    padding: 10px;
    line-height: 1;
  }
  .p-kurashiServiceDetail01__content06 .c-kurashiBtn03 a:before {
    margin: -3px 5px 0 20px;
  }
  .p-kurashiServiceDetail01__content07 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-kurashiServiceDetail01__content08 {
    padding: 0 20px;
  }
  .p-kurashiServiceDetail01__content08__box {
    padding: 10px 13px 2px;
  }
  .p-kurashiServiceDetail01__content08__box__tel_sp {
    text-align: center;
    margin-bottom: 14px;
  }
  .p-kurashiServiceDetail01__content08__box__tel_sp a {
    width: 100%;
    padding: 15px 0;
    font-size: 1.1rem;
    color: #ffffff !important;
    text-decoration: none !important;
    background-color: #4b5870;
    display: block;
    position: relative;
    box-shadow: 0 3px 0 #7798b1;
    transition: 0.3s ease-in-out;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }
  .p-kurashiServiceDetail01__content08__box__tel_sp a:before {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    content: "";
    border: dashed 1px #ffffff;
    position: absolute;
    top: 2px;
    left: 2px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }
  .p-kurashiServiceDetail01__content08__box__tel_sp a span {
    font-size: 1.4rem !important;
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  .p-kurashiServiceDetail01__content08__box__tel_sp a span:before {
    width: 21px;
    height: 16px;
    margin: -3px 5px 0 0;
    content: "";
    background-image: url("../img/common/icon_tel01.png");
    -webkit-background-size: cover;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .p-kurashiServiceDetail01__content08__box__tel_sp a:hover {
    transform: translate(0, 3px);
    box-shadow: none;
  }
  .p-kurashiServiceDetail01__content08__box table {
    width: 100%;
    font-size: 1.3rem;
  }
  .p-kurashiServiceDetail01__content08__box table th {
    width: 100%;
    margin-bottom: 9px;
    padding: 0;
    display: block;
  }
  .p-kurashiServiceDetail01__content08__box table td {
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
    display: block;
  }
  .p-kurashiServiceDetail01__content08__box table td span {
    font-size: 1.1rem;
  }
  .p-kurashiServiceDetail02 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-kurashiServiceDetail02__content01 .c-kurashiPager01 {
    margin-top: 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス　商品申込み・お問い合わせフォーム（カート）
------------------------------------------------------------*/
.p-kurashiCart {
  padding: 37px 0 140px 0;
  background-color: #ffffff;
}

.p-kurashiCart01 {
  margin-bottom: 21px;
}
.p-kurashiCart01 .c-formTitle {
  color: #483825;
}

.p-kurashiCart02 {
  margin-bottom: 53px;
}
.p-kurashiCart02__txt {
  margin-bottom: 17px;
  font-size: 1.6rem;
  line-height: 1.7;
  color: #594242;
}
.p-kurashiCart02__txt__annotation {
  font-size: 1.4rem;
}
.p-kurashiCart02 .c-tbl01--kurashiCart {
  margin-top: 23px;
}

.p-kurashiCart03 .p-kurashiCart03__content01 dl dt {
  margin-bottom: 13px;
  font-size: 2rem;
  line-height: 1;
  font-weight: bold;
  color: #594242;
}
.p-kurashiCart03 .p-kurashiCart03__content01 dl dt:before {
  width: 10px;
  height: 10px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #faad5a;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-kurashiCart03 .p-kurashiCart03__content01 dl dd {
  margin-bottom: 32px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiCart03 .p-kurashiCart03__content01 dl dd a {
  color: #308bbb;
  text-decoration: underline;
}
.p-kurashiCart03 .p-kurashiCart03__content01 dl dd a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-kurashiCart03 .p-kurashiCart03__content01 dl dd:first-of-type p {
  width: 800px;
}
@media screen and (max-width: 640px) {
  .p-kurashiCart03 .p-kurashiCart03__content01 dl dd:first-of-type p {
    width: 100%;
  }
}
.p-kurashiCart03 .p-kurashiCart03__content01__img {
  margin-top: 5px;
}
@media screen and (max-width: 640px) {
  .p-kurashiCart {
    padding: 30px 20px 29px 20px;
  }
  .p-kurashiCart01 {
    margin-bottom: 16px;
  }
  .p-kurashiCart01 .c-formTitle {
    margin-bottom: 14px;
  }
  .p-kurashiCart02 {
    margin-bottom: 30px;
  }
  .p-kurashiCart02__txt {
    margin-bottom: 7px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
  .p-kurashiCart02__txt__annotation {
    font-size: 1.1rem;
    font-weight: bold;
    padding-top: 10px;
    display: block;
  }
  .p-kurashiCart02 .c-tbl01--kurashiCart {
    margin-top: 12px;
  }
  .p-kurashiCart03 .p-kurashiCart03__content01 dl dt {
    margin-bottom: 5px;
    font-size: 1.4rem;
  }
  .p-kurashiCart03 .p-kurashiCart03__content01 dl dt:before {
    width: 8px;
    height: 8px;
    margin: -3px 4px 0 0;
  }
  .p-kurashiCart03 .p-kurashiCart03__content01 dl dd {
    margin-bottom: 21px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
  .p-kurashiCart03 .p-kurashiCart03__content01 dl dd p {
    width: 100%;
    margin-bottom: 10px;
  }
  .p-kurashiCart03 .p-kurashiCart03__content01 dl dd img {
    margin: 0 auto;
    display: block;
  }
  .p-kurashiCart03 .p-kurashiCart03__content01 .c-kurashiTitle03 {
    margin-bottom: 16px;
  }
  .p-kurashiCart03 .p-kurashiCart03__content02 .c-cartBtn {
    margin-top: 34px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス　商品申込み・お問い合わせフォーム（カート）　入力・エラー
------------------------------------------------------------*/
.p-kurashiCartInput {
  padding: 37px 0 100px 0;
  background-color: #ffffff;
}

.p-kurashiCartInput01 {
  margin-bottom: 22px;
}
.p-kurashiCartInput01 .c-formTitle {
  color: #232934;
}

.p-kurashiCartInput02 {
  margin-bottom: 80px;
}
.p-kurashiCartInput02__require {
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background: #f22727;
  padding: 0 5px;
  line-height: 1.6;
  border-radius: 3px;
}
@media screen and (max-width: 640px) {
  .p-kurashiCartInput02__require {
    margin-left: 10px;
  }
}
.p-kurashiCartInput02__content01 {
  margin-bottom: 53px;
}
.p-kurashiCartInput02__content01__txt {
  margin-bottom: 35px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-kurashiCartInput02__content01__txt2 {
  margin-bottom: 15px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-kurashiCartInput02__content02 {
  margin-bottom: 50px;
}
.p-kurashiCartInput02__content03__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #308bbb;
  text-decoration: underline;
}
.p-kurashiCartInput02__content03__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-kurashiCartInput02__content03__back:before {
  width: 7px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-kurashiCartInput {
    padding: 28px 20px 0 20px;
  }
  .p-kurashiCartInput01 {
    margin-bottom: 17px;
  }
  .p-kurashiCartInput01 .c-formTitle {
    margin-bottom: 16px;
  }
  .p-kurashiCartInput02 {
    margin-bottom: 37px;
  }
  .p-kurashiCartInput02__content01 {
    margin-bottom: 30px;
  }
  .p-kurashiCartInput02__content01 > p {
    margin-bottom: 17px;
    font-size: 1.3rem;
    line-height: 1.9rem;
  }
  .p-kurashiCartInput02__content01 .p-kurashiCartInput02__require {
    margin: -1px 5px 0 0;
    line-height: 1;
    font-size: 0.9rem;
    padding: 2px 5px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl dd input {
    padding: 11.5px 10px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(3).u-style01 {
    margin-top: 13px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(3) dt .c-table2--require {
    margin-left: 6px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(3) dd {
    padding: 15px 14px 1px !important;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(3) dd .c-table2--note1 {
    margin: 6px 0px 10px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(4) dd ul li .c-table2--ttl1 {
    margin-top: -7px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(5) dt .c-table2--require {
    margin-left: 2px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(5) dd ul li .c-table2--ttl1 {
    margin-top: -7px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(6) dd .c-table2--note1 {
    margin: 8px 0 5px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(7) dd, .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(8) dd, .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(9) dd, .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(10) dd, .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(12) dd, .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(16) dd, .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(17) dd, .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(18) dd {
    padding: 15px 14px 13px !important;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(11) dd {
    padding: 14px 14px 2px !important;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(14) dd {
    padding: 10px 14px 3px !important;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(14) dd ul li {
    margin-right: 39px;
    margin-bottom: 5px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(14) dd ul li .c-checkbox__text {
    padding-left: 23px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(14) dd ul li .c-checkbox__text:before {
    width: 14px;
    height: 14px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(14) dd ul li .c-checkbox__text:after {
    left: 5px;
    height: 9px;
    margin-top: -6px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(15) dd {
    padding: 10px 14px 4px !important;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(15) dd ul li {
    margin-bottom: 5px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(15) dd ul li .c-checkbox__text {
    padding-left: 23px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(15) dd ul li .c-checkbox__text:before {
    width: 14px;
    height: 14px;
  }
  .p-kurashiCartInput02__content01 .p-kurashiFormTable01 dl:nth-child(15) dd ul li .c-checkbox__text:after {
    left: 5px;
    height: 9px;
    margin-top: -6px;
  }
  .p-kurashiCartInput02__content02 {
    margin-bottom: 30px;
  }
  .p-kurashiCartInput02__content02 .c-kurashiTitle03 {
    margin-bottom: 14px;
  }
  .p-kurashiCartInput02__content02 .c-checkbox .c-checkbox__text {
    padding-left: 23px;
  }
  .p-kurashiCartInput02__content02 .c-checkbox .c-checkbox__text:before {
    top: 0;
    transform: translateY(0);
    width: 14px;
    height: 14px;
  }
  .p-kurashiCartInput02__content02 .c-checkbox .c-checkbox__text:after {
    top: 7px;
    transform: translateY(0) rotate(45deg);
    left: 5px;
    height: 9px;
    margin-top: -6px;
  }
  .p-kurashiCartInput02__content03 .c-cartBtn {
    margin-top: 35px;
  }
  .p-kurashiCartInput02__content03__back {
    margin-top: 5px;
    font-size: 1.4rem;
  }
  .p-kurashiCartInput02__content03__back:before {
    width: 5px;
    height: 9px;
    margin: 0 11px 0 0;
  }
}
.p-kurashiFormTable01 dl {
  color: #594242;
}
.p-kurashiFormTable01 dl label {
  color: #594242;
}
.p-kurashiFormTable01 dl:nth-child(3) input {
  width: 280px;
  padding: 0 15px;
}
.p-kurashiFormTable01 dl:nth-child(4) li, .p-kurashiFormTable01 dl:nth-child(5) li {
  float: left;
}
.p-kurashiFormTable01 dl:nth-child(4) input, .p-kurashiFormTable01 dl:nth-child(5) input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.p-kurashiFormTable01 dl:nth-child(5) input {
  margin: 0 29px 0 7px;
}
.p-kurashiFormTable01 dl:nth-child(6) input, .p-kurashiFormTable01 dl:nth-child(7) input {
  padding: 0 15px;
  width: 240px;
}
.p-kurashiFormTable01 dl:nth-child(8) input, .p-kurashiFormTable01 dl:nth-child(9) input, .p-kurashiFormTable01 dl:nth-child(10) input, .p-kurashiFormTable01 dl:nth-child(11) input, .p-kurashiFormTable01 dl:nth-child(12) input, .p-kurashiFormTable01 dl:nth-child(13) input, .p-kurashiFormTable01 dl:nth-child(16) input, .p-kurashiFormTable01 dl:nth-child(17) input, .p-kurashiFormTable01 dl:nth-child(18) input {
  width: 600px;
  padding: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFormTable01 dl:nth-child(8) input, .p-kurashiFormTable01 dl:nth-child(9) input, .p-kurashiFormTable01 dl:nth-child(10) input, .p-kurashiFormTable01 dl:nth-child(11) input, .p-kurashiFormTable01 dl:nth-child(12) input, .p-kurashiFormTable01 dl:nth-child(13) input, .p-kurashiFormTable01 dl:nth-child(16) input, .p-kurashiFormTable01 dl:nth-child(17) input, .p-kurashiFormTable01 dl:nth-child(18) input {
    width: 100%;
    padding: 10px;
  }
}
.p-kurashiFormTable01 dl:nth-child(8) textarea, .p-kurashiFormTable01 dl:nth-child(9) textarea, .p-kurashiFormTable01 dl:nth-child(10) textarea, .p-kurashiFormTable01 dl:nth-child(11) textarea, .p-kurashiFormTable01 dl:nth-child(12) textarea, .p-kurashiFormTable01 dl:nth-child(13) textarea, .p-kurashiFormTable01 dl:nth-child(16) textarea, .p-kurashiFormTable01 dl:nth-child(17) textarea, .p-kurashiFormTable01 dl:nth-child(18) textarea {
  width: 100%;
  padding: 15px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFormTable01 dl:nth-child(8) textarea, .p-kurashiFormTable01 dl:nth-child(9) textarea, .p-kurashiFormTable01 dl:nth-child(10) textarea, .p-kurashiFormTable01 dl:nth-child(11) textarea, .p-kurashiFormTable01 dl:nth-child(12) textarea, .p-kurashiFormTable01 dl:nth-child(13) textarea, .p-kurashiFormTable01 dl:nth-child(16) textarea, .p-kurashiFormTable01 dl:nth-child(17) textarea, .p-kurashiFormTable01 dl:nth-child(18) textarea {
    padding: 10px;
  }
}
@media screen and (max-width: 640px) {
  .p-kurashiFormTable01 dl:nth-child(3) input, .p-kurashiFormTable01 dl:nth-child(6) input, .p-kurashiFormTable01 dl:nth-child(7) input, .p-kurashiFormTable01 dl:nth-child(8) input, .p-kurashiFormTable01 dl:nth-child(9) input, .p-kurashiFormTable01 dl:nth-child(10) input, .p-kurashiFormTable01 dl:nth-child(11) input, .p-kurashiFormTable01 dl:nth-child(12) input {
    width: 100%;
    padding: 10px;
  }
  .p-kurashiFormTable01 dl:nth-child(4) li, .p-kurashiFormTable01 dl:nth-child(5) li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .p-kurashiFormTable01 dl:nth-child(4) input, .p-kurashiFormTable01 dl:nth-child(5) input {
    width: 94%;
    padding: 10px;
    margin: 0 0 10px 10px;
  }
  .p-kurashiFormTable01 dl:nth-child(5) input {
    width: 95%;
  }
  .p-kurashiFormTable01 dl textarea {
    line-height: 1.5;
  }
  .p-kurashiFormTable01 dl input {
    height: 38px;
    line-height: 38px;
  }
}
.p-kurashiFormTable01 dl .c-table2__radio input:checked + label:before {
  background: #faad5a;
  border: 1px solid #faad5a !important;
}
@media screen and (max-width: 640px) {
  .p-kurashiFormTable01 dl .c-table2__radio li {
    margin-right: 0;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス　商品申込み・お問い合わせフォーム（カート）　確認
------------------------------------------------------------*/
.p-kurashiCartConfirm {
  padding: 50px 0 40px 0;
  background-color: #ffffff;
}

.p-kurashiCartConfirm01 {
  margin-bottom: 30px;
}

.p-kurashiCartConfirm02 {
  margin-bottom: 30px;
}
.p-kurashiCartConfirm02__content01 {
  margin-bottom: 50px;
  color: #46483c;
}
.p-kurashiCartConfirm02__content01 .c-cartBtn {
  margin-bottom: 45px;
}
.p-kurashiCartConfirm02__content03 {
  margin-bottom: 80px;
}
.p-kurashiCartConfirm02__content03__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #308bbb;
  text-decoration: underline;
}
.p-kurashiCartConfirm02__content03__back:before {
  width: 7px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}
.p-kurashiCartConfirm02 .p-sosaiFormTable04 dt {
  width: 330px;
}
.p-kurashiCartConfirm02 .p-sosaiFormTable04 dd {
  width: calc(100% - 330px);
}

@media screen and (max-width: 640px) {
  .p-kurashiCartConfirm {
    padding: 30px 0 0 0;
    background-color: #ffffff;
  }
  .p-kurashiCartConfirm01 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-kurashiCartConfirm01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-kurashiCartConfirm02 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-kurashiCartConfirm02__content01 {
    margin-bottom: 30px;
  }
  .p-kurashiCartConfirm02__content01 .c-cartBtn {
    margin-bottom: 20px;
  }
  .p-kurashiCartConfirm02__content01 .p-kurashiFormTable01 dl {
    padding-bottom: 10px;
  }
  .p-kurashiCartConfirm02__content03 {
    margin-bottom: 40px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス　商品申込み・お問い合わせフォーム（カート）　完了
------------------------------------------------------------*/
.p-kurashiCartComplete {
  padding: 30px 0 0 0;
  background-color: #ffffff;
}

.p-kurashiCartComplete01 {
  margin: 33px 0 40px;
}
.p-kurashiCartComplete01 .c-formTitle {
  color: #483825;
}

.p-kurashiCartComplete02 {
  margin-bottom: 102px;
}
.p-kurashiCartComplete02 .c-formComplete {
  color: #594242;
}

@media screen and (max-width: 640px) {
  .p-kurashiCartComplete {
    padding: 0 20px;
  }
  .p-kurashiCartComplete01 {
    margin: 30px 0;
  }
  .p-kurashiCartComplete01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-kurashiCartComplete02 {
    margin-bottom: 40px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス 組合員の声
------------------------------------------------------------*/
.p-kurashiVoice {
  background-color: #ffffff;
}
.p-kurashiVoice .l-contentServiceMain {
  width: 850px;
}
@media screen and (max-width: 640px) {
  .p-kurashiVoice .l-contentServiceMain {
    width: 100%;
  }
}

.p-kurashiVoice01 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiVoice01 .c-kurashiTitle02 {
  margin-bottom: 35px;
}
@media screen and (max-width: 640px) {
  .p-kurashiVoice01 .c-kurashiTitle02 {
    margin-bottom: 12px;
  }
}
.p-kurashiVoice01__content01 {
  margin-bottom: 23px;
}
.p-kurashiVoice01__content02 {
  margin-bottom: 92px;
}
.p-kurashiVoice01__content02 ul {
  margin-bottom: -20px;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box {
  width: 420px;
  margin-bottom: 13px;
  padding: 41px 50px 48px 39px;
  background-image: url("/img/kurashi/voice_bg01.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box:before {
  content: "";
  width: 88px;
  height: 38px;
  background-image: url("/img/kurashi/voice_icon01.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  left: 49%;
  transform: translateX(-50%);
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(1), .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(4), .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(7) {
  background-image: url("/img/kurashi/voice_bg02.png");
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(2), .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(5), .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(10) {
  background-image: url("/img/kurashi/voice_bg03.png");
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(2):before, .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(5):before, .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(8):before {
  background-image: url("/img/kurashi/voice_icon02.png");
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(3):before, .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(6):before, .p-kurashiVoice01__content02 ul .kurashiVoice-box:nth-child(9):before {
  background-image: url("/img/kurashi/voice_icon03.png");
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box__headding {
  padding-right: 7px;
  margin-bottom: 13px;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--silhouette {
  width: 80px;
  height: 80px;
  text-align: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--txt {
  width: 225px;
  display: table;
  border-bottom: 6px solid transparent;
  -webkit-border-image: url("/img/kurashi/voiceBox-line.png") 22 round; /* Safari 3.1-5 */
  -o-border-image: url("/img/kurashi/voiceBox-line.png") 22 round; /* Opera 11-12.1 */
  border-image: url("/img/kurashi/voiceBox-line.png") 22 round;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--txt .heading-txt01 {
  color: #faad5a;
  font-size: 1.8rem;
  font-weight: bold;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--txt .heading-txt02 {
  color: #733e01;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: bold;
  padding: 2px 0 6px;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box__content {
  font-size: 1.6rem;
  color: #594242;
}
.p-kurashiVoice01__content02 ul .kurashiVoice-box__content span {
  color: #f26b51;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .p-kurashiVoice {
    padding: 30px 20px;
  }
  .p-kurashiVoice01 {
    font-size: 1.4rem;
  }
  .p-kurashiVoice01__content01 {
    margin-bottom: 16px;
  }
  .p-kurashiVoice01__content01 p {
    line-height: 1.5;
  }
  .p-kurashiVoice01__content02 {
    margin-bottom: 20px;
  }
  .p-kurashiVoice01__content02 ul {
    margin-bottom: 0;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box {
    width: 100%;
    margin-bottom: 15px;
    padding: 29px 20px 47px;
    background-size: 100% 100%;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box:before {
    width: 60px;
    height: 25px;
    top: 0;
    left: 50%;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box__headding {
    padding-right: 0;
    margin-bottom: 10px;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--silhouette {
    width: 50px;
    height: 50px;
    margin: 5px 0 0 0;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--txt {
    width: calc(100% - 64px);
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--txt .heading-txt01 {
    font-size: 1.4rem;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box__headding--txt .heading-txt02 {
    font-size: 1.5rem;
    padding: 2px 0 3px;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box__content {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-kurashiVoice01__content02 ul .kurashiVoice-box__content span {
    color: #594242;
    font-weight: normal;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
くらしのサービス お申込み方法
------------------------------------------------------------*/
.p-kurashiApplication {
  background-color: #ffffff;
}

.p-kurashiApplication01 {
  margin: -6px 0 41px 0;
}
.p-kurashiApplication01__content01 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiApplication01__content01 dl dt {
  margin-bottom: 2px;
  font-weight: bold;
}
.p-kurashiApplication01__content01 dl dt:before {
  width: 12px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #faad5a;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.p-kurashiApplication01__content01 dl dd {
  margin-bottom: 20px;
}
.p-kurashiApplication01__content01 dl a {
  color: #308bbb;
  text-decoration: underline;
}
.p-kurashiApplication01__content01 dl a:hover {
  opacity: 1;
  text-decoration: none;
}

.p-kurashiApplication02__content01 {
  margin-bottom: 18px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiApplication02__content02 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiApplication02__content02 ol li {
  margin-bottom: 28px;
  position: relative;
  border-bottom: dashed 1px rgba(115, 62, 1, 0.3);
}
.p-kurashiApplication02__content02 ol li img {
  position: absolute;
}
.p-kurashiApplication02__content02 ol li:nth-child(1) {
  min-height: 213px;
  padding: 0 300px 30px 0;
}
.p-kurashiApplication02__content02 ol li:nth-child(1) img {
  top: 1px;
  right: 0;
}
.p-kurashiApplication02__content02 ol li:nth-child(2) {
  min-height: 180px;
  padding: 0 300px 30px 0;
}
.p-kurashiApplication02__content02 ol li:nth-child(2) img {
  top: 35px;
  right: 0;
}
.p-kurashiApplication02__content02 ol li:nth-child(3) {
  margin: 0;
  border: none;
}
.p-kurashiApplication02__content02 ol li h3 {
  margin-bottom: 5px;
  color: #483825;
  font-size: 1.8rem;
  font-weight: bold;
}
.p-kurashiApplication02__content02 ol li h3:before {
  width: 25px;
  height: 25px;
  margin: -5px 10px 0 0;
  content: counter(count01);
  line-height: 1.38;
  color: #ffffff;
  background-color: #faad5a;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-kurashiApplication02__content02__contact {
  width: 410px;
  padding: 22px 25px 13px;
  margin-top: 4px;
  border: solid 5px #fef3e6;
}
.p-kurashiApplication02__content02__contact dt {
  margin-bottom: 5px;
  font-weight: bold;
}
.p-kurashiApplication02__content02__contact dt:before {
  width: 12px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #faad5a;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.p-kurashiApplication02__content02__contact dd p {
  margin-bottom: 5px;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #445161;
}
.p-kurashiApplication02__content02__contact__tel {
  font-size: 1.4rem;
  font-weight: bold;
}
.p-kurashiApplication02__content02__contact__tel .pc-only {
  color: #445161;
}
.p-kurashiApplication02__content02__contact__tel .pc-only span {
  font-size: 2.2rem;
}
.p-kurashiApplication02__content02__contact__tel .pc-only span:before {
  width: 21px;
  height: 18px;
  margin: -5px 5px 0 5px;
  content: "";
  background-image: url("../img/common/icon_tel03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 640px) {
  .p-kurashiApplication {
    padding: 30px 20px;
  }
  .p-kurashiApplication01 {
    margin-bottom: 20px;
  }
  .p-kurashiApplication01__content01 dl {
    font-size: 1.4rem;
  }
  .p-kurashiApplication01__content01 dl dd {
    margin-bottom: 15px;
  }
  .p-kurashiApplication02__content01 {
    font-size: 1.4rem;
  }
  .p-kurashiApplication02__content02 {
    font-size: 1.4rem;
  }
  .p-kurashiApplication02__content02 ol li {
    min-height: auto !important;
    margin-bottom: 15px;
    padding: 0 0 15px 0 !important;
    position: static;
  }
  .p-kurashiApplication02__content02 ol li img {
    margin: 0 auto 10px auto;
    display: block;
    position: static;
  }
  .p-kurashiApplication02__content02 ol li:nth-child(3) {
    margin: 0 !important;
  }
  .p-kurashiApplication02__content02 ol li h3 {
    font-size: 1.6rem;
  }
  .p-kurashiApplication02__content02 ol li h3:before {
    width: 20px;
    height: 20px;
  }
  .p-kurashiApplication02__content02__contact {
    width: 100%;
    margin-bottom: 10px;
  }
  .p-kurashiApplication02__content02__contact dd p {
    font-size: 1.2rem;
  }
  .p-kurashiApplication02__content02__contact__tel {
    font-size: 1.2rem;
  }
  .p-kurashiApplication02__content02__contact__tel a {
    display: block;
    text-align: center;
    background-color: #4b5870;
    display: block;
    border: solid 3px #939ba9;
    padding: 10px 5px 8px;
    color: #ffffff;
  }
  .p-kurashiApplication02__content02__contact__tel a span {
    display: block;
  }
  .p-kurashiApplication02__content02__contact__tel a span:nth-child(1) {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2px;
    line-height: 1;
  }
  .p-kurashiApplication02__content02__contact__tel a span:nth-child(1):before {
    width: 21px;
    height: 16px;
    margin: -3px 5px 0 0;
    content: "";
    background-image: url("../img/common/icon_tel01.png");
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
  }
  .p-kurashiApplication02__content02__contact__tel a span:nth-child(2) {
    font-size: 1.1rem;
    font-weight: normal;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-kurashiFaq {
  padding: 52px 0 40px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq {
    padding: 85px 20px 70px;
  }
}
.p-kurashiFaq .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-kurashiFaq .c-breadcrumb1 a {
  color: #308bbb;
}
.p-kurashiFaq__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-kurashiFaq__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
.p-kurashiFaq__title02 {
  font-size: 2.4rem;
  color: #fff;
  background: #faad5a;
  padding: 16px 29px 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__title02 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.6rem;
    padding: 13px 20px 12px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
}
.p-kurashiFaq__box {
  margin-bottom: 20px;
}
.p-kurashiFaq__box:last-of-type {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box:last-of-type {
    margin-bottom: 30px;
  }
}
.p-kurashiFaq__box__q {
  font-size: 2rem;
  color: #46483c;
  line-height: 1.6;
  background: #f2f2f2;
  padding: 15px 29px 13px;
  cursor: pointer;
  position: relative;
  display: table;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box__q {
    font-size: 1.4rem;
    padding: 13px 35px 11px 15px;
  }
}
.p-kurashiFaq__box__q span {
  display: table-cell;
  width: 27px;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box__q span {
    width: 20px;
  }
}
.p-kurashiFaq__box__q:after, .p-kurashiFaq__box__q:before {
  content: "";
  background: #46483c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s ease-out;
}
.p-kurashiFaq__box__q:after {
  width: 24px;
  height: 3px;
  right: 20px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box__q:after {
    width: 10px;
    height: 2px;
    right: 10px;
  }
}
.p-kurashiFaq__box__q:before {
  width: 3px;
  height: 24px;
  right: 30px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box__q:before {
    width: 2px;
    height: 10px;
    right: 14px;
  }
}
.p-kurashiFaq__box__a {
  display: none;
  background: #fff;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 24px 40px 29px 37px;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box__a {
    padding: 15px;
  }
}
.p-kurashiFaq__box__a p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
  display: table;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box__a p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-kurashiFaq__box__a span {
  display: table-cell;
  width: 32px;
  font-size: 2rem;
  font-weight: bold;
  color: #faad5a;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__box__a span {
    width: 20px;
    font-size: 1.8rem;
  }
}
.p-kurashiFaq__box.is-open .p-kurashiFaq__box__q::before {
  transform: translateY(-50%) rotate(-90deg);
}
.p-kurashiFaq__btn {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__btn {
    display: block;
    margin-bottom: 25px;
  }
}
.p-kurashiFaq__btn li {
  width: 33%;
  height: 80px;
  margin-bottom: 10px;
  margin-right: 5px;
}
.p-kurashiFaq__btn li:nth-child(3) {
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__btn li {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
}
.p-kurashiFaq__btn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #faad5a;
  border: 3px solid #dddddd;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__btn a {
    font-size: 1.5rem;
    padding: 0 30px;
    line-height: 1.6;
    border: 2px solid #dddddd;
  }
}
.p-kurashiFaq__btn a:after {
  content: "";
  width: 12px;
  height: 7px;
  background: url("../img/common/icon_down04.png");
  background-size: contain;
  position: absolute;
  top: 34px;
  right: 27px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-kurashiFaq__btn a:after {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

/*------------------------------------------------------------
くらしのサービス お掃除カレンダー
------------------------------------------------------------*/
.p-kurashiCalendar {
  background-color: #ffffff;
}

.p-kurashiCalendar01 {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #594242;
}
.p-kurashiCalendar01 > p {
  margin-bottom: 10px;
}
.p-kurashiCalendar01 > p:last-of-type {
  margin-bottom: 35px;
}
.p-kurashiCalendar01 > ul {
  margin-bottom: 10px;
}
.p-kurashiCalendar01__content01 {
  margin-bottom: 30px;
  overflow: hidden;
}
.p-kurashiCalendar01__content01__calendar {
  width: 225px;
  margin-bottom: 22px;
  padding-top: 7px;
}
.p-kurashiCalendar01__content01__txt01 {
  width: 615px;
  margin-right: 0;
  margin-left: auto;
}
.p-kurashiCalendar01__content01__box {
  width: 615px;
}
.p-kurashiCalendar01__content01__box h3 {
  margin-bottom: 11px;
  font-weight: bold;
  color: #483825;
}
.p-kurashiCalendar01__content01__box h3:before {
  width: 12px;
  height: 12px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #faad5a;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.p-kurashiCalendar01__content01__box ol {
  padding: 20px 25px;
  border: solid 5px #fef3e6;
}
.p-kurashiCalendar01 .c-kurashiTitle04 {
  margin-bottom: 23px;
}
.p-kurashiCalendar01__content02__calendarList {
  padding-top: 7px;
}
.p-kurashiCalendar01__content02__calendarList .p-kurashiCalendarBox {
  margin: 0 20px 23px 0;
}
.p-kurashiCalendar01__content02__calendarList .p-kurashiCalendarBox:nth-child(4n) {
  margin: 0 0 23px 0;
}

.p-kurashiCalendarBox {
  width: 195px;
  overflow: hidden;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.p-kurashiCalendarBox__month {
  height: 30px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #faad5a;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.p-kurashiCalendarBox img {
  width: 100%;
  margin: 0;
  display: block;
  vertical-align: bottom;
}
.p-kurashiCalendarBox__txt {
  position: relative;
}
.p-kurashiCalendarBox__txt h3 {
  margin-bottom: 2px;
  padding: 10px 0;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  background-image: url("../img/kurashi/line02.png");
  background-repeat: repeat-x;
  background-position: 0 bottom;
}
.p-kurashiCalendarBox.is-thisMonth {
  background-color: #fddab5;
}
.p-kurashiCalendarBox.is-thisMonth .p-kurashiCalendarBox__month {
  height: 35px;
  margin-bottom: 0;
  padding-left: 6px;
  font-size: 2rem;
  line-height: 1.75;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.p-kurashiCalendarBox.is-thisMonth .p-kurashiCalendarBox__txt02 {
  background: url("../img/kurashi/calendar_illust.png") no-repeat center;
  -webkit-background-size: contain;
  background-size: contain;
  display: flex;
  align-items: center;
  height: 147px;
}
.p-kurashiCalendarBox.is-thisMonth .p-kurashiCalendarBox__txt02 h3 {
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;
  width: 100%;
  padding: 0 20px;
  background: none;
  margin: -5px 0 0;
}
.p-kurashiCalendarBox.is-thisMonth .p-kurashiCalendarBox__txt02 h3 span {
  display: block;
}
.p-kurashiCalendarBox.is-thisMonth .p-kurashiCalendarBox__txt02 p {
  display: none;
}
.p-kurashiCalendarBox.is-thisMonth--01 {
  background-color: #f4cac6;
}
.p-kurashiCalendarBox.is-thisMonth--01 .p-kurashiCalendarBox__month {
  background: #da5143 url("../img/kurashi/calendar_illust04.png") no-repeat center top 3px;
}
.p-kurashiCalendarBox.is-thisMonth--02 {
  background-color: #f9d2bf;
}
.p-kurashiCalendarBox.is-thisMonth--02 .p-kurashiCalendarBox__month {
  background: #f18f60 url("../img/kurashi/calendar_illust05.png") no-repeat center top 4px;
}
.p-kurashiCalendarBox.is-thisMonth--03 {
  background-color: #ffdcb0;
}
.p-kurashiCalendarBox.is-thisMonth--03 .p-kurashiCalendarBox__month {
  background: #ffb458 url("../img/kurashi/calendar_illust06.png") no-repeat center top 3px;
}
.p-kurashiCalendarBox.is-thisMonth--04 {
  background-color: #f9d2dc;
}
.p-kurashiCalendarBox.is-thisMonth--04 .p-kurashiCalendarBox__month {
  background: #faa0b6 url("../img/kurashi/calendar_illust03.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--05 {
  background-color: #b7dff2;
}
.p-kurashiCalendarBox.is-thisMonth--05 .p-kurashiCalendarBox__month {
  background: #58b4e2 url("../img/kurashi/calendar_illust07.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--06 {
  background-color: #ddd3e5;
}
.p-kurashiCalendarBox.is-thisMonth--06 .p-kurashiCalendarBox__month {
  background: #b099c3 url("../img/kurashi/calendar_illust08.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--07 {
  background-color: #c1e5e2;
}
.p-kurashiCalendarBox.is-thisMonth--07 .p-kurashiCalendarBox__month {
  background: #70c3bc url("../img/kurashi/calendar_illust09.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--08 {
  background-color: #c3daee;
}
.p-kurashiCalendarBox.is-thisMonth--08 .p-kurashiCalendarBox__month {
  background: #5194cf url("../img/kurashi/calendar_illust10.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--09 {
  background-color: #e5e8c0;
}
.p-kurashiCalendarBox.is-thisMonth--09 .p-kurashiCalendarBox__month {
  background: #a8b22d url("../img/kurashi/calendar_illust11.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--10 {
  background-color: #cccee2;
}
.p-kurashiCalendarBox.is-thisMonth--10 .p-kurashiCalendarBox__month {
  background: #7579b1 url("../img/kurashi/calendar_illust12.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--11 {
  background-color: #f4e0a5;
}
.p-kurashiCalendarBox.is-thisMonth--11 .p-kurashiCalendarBox__month {
  background: #eac24b url("../img/kurashi/calendar_illust13.png") no-repeat center;
}
.p-kurashiCalendarBox.is-thisMonth--12 {
  background-color: #f4c5cb;
}
.p-kurashiCalendarBox.is-thisMonth--12 .p-kurashiCalendarBox__month {
  background: #d93f53 url("../img/kurashi/calendar_illust14.png") no-repeat center top 3px;
}

@media screen and (max-width: 640px) {
  .p-kurashiCalendar {
    padding: 30px 20px;
  }
  .p-kurashiCalendar01 {
    font-size: 1.4rem;
  }
  .p-kurashiCalendar01 > p {
    margin-bottom: 15px;
  }
  .p-kurashiCalendar01 > ul {
    margin-bottom: 15px;
  }
  .p-kurashiCalendar01__content01 {
    margin-bottom: 20px;
  }
  .p-kurashiCalendar01__content01 p {
    font-size: 1.4rem;
    line-height: 20px;
    float: none;
  }
  .p-kurashiCalendar01__content01__calendar {
    width: 47%;
    margin: 0 18px 10px 0;
    padding-top: 0;
  }
  .p-kurashiCalendar01__content01__calendar .p-kurashiCalendarBox {
    width: 100% !important;
  }
  .p-kurashiCalendar01__content01__txt01 {
    width: 100%;
    padding: 0 0 15px 0;
    margin: -2px 0 15px 0;
  }
  .p-kurashiCalendar01__content01__box {
    width: 100%;
    margin-bottom: 24px;
    clear: both;
  }
  .p-kurashiCalendar01__content01__box h3 {
    margin-bottom: 8px;
  }
  .p-kurashiCalendar01__content01__box ol {
    padding: 9px 17px;
  }
  .p-kurashiCalendar01__content01__box ol li {
    line-height: 20px;
  }
  .p-kurashiCalendar01__content02__calendarList .p-kurashiCalendarBox {
    margin: 0 20px 15px 0 !important;
  }
  .p-kurashiCalendar01__content02__calendarList .p-kurashiCalendarBox:nth-child(2n) {
    margin: 0 0 15px 0 !important;
  }
  .p-kurashiCalendarBox {
    width: calc(50% - 10px);
    border-radius: 0 0 15px 15px;
  }
  .p-kurashiCalendarBox__month {
    height: 20px;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  .p-kurashiCalendarBox__txt h3 {
    margin-bottom: 6px;
    padding: 9px 0;
    font-size: 1.5rem;
  }
  .p-kurashiCalendarBox__txt p {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .p-kurashiCalendarBox.is-thisMonth {
    border-radius: 15px;
  }
  .p-kurashiCalendarBox.is-thisMonth .p-kurashiCalendarBox__month {
    height: 30px;
    font-size: 1.4rem;
    line-height: 2.14;
  }
  .p-kurashiCalendarBox.is-thisMonth .p-kurashiCalendarBox__txt02 h3 {
    font-size: 1.5rem;
    padding: 0 10px;
  }
  .p-kurashiCalendarBox.is-thisMonth--01 .p-kurashiCalendarBox__month {
    background: #da5143 url("../img/kurashi/calendar_illust04_sp.png") no-repeat center;
    background-size: 110px 25px;
  }
  .p-kurashiCalendarBox.is-thisMonth--02 .p-kurashiCalendarBox__month {
    background: #f18f60 url("../img/kurashi/calendar_illust05_sp.png") no-repeat center bottom -1px;
    background-size: 115px 26px;
  }
  .p-kurashiCalendarBox.is-thisMonth--03 .p-kurashiCalendarBox__month {
    background: #ffb458 url("../img/kurashi/calendar_illust06_sp.png") no-repeat center bottom;
    background-size: 112px 26px;
  }
  .p-kurashiCalendarBox.is-thisMonth--04 .p-kurashiCalendarBox__month {
    background: #faa0b6 url("../img/kurashi/calendar_illust03_sp.png") no-repeat center;
    background-size: 115px 23px;
  }
  .p-kurashiCalendarBox.is-thisMonth--05 .p-kurashiCalendarBox__month {
    background: #58b4e2 url("../img/kurashi/calendar_illust07_sp.png") no-repeat center;
    background-size: 110px 23px;
  }
  .p-kurashiCalendarBox.is-thisMonth--06 .p-kurashiCalendarBox__month {
    background: #b099c3 url("../img/kurashi/calendar_illust08_sp.png") no-repeat center;
    background-size: 110px 23px;
  }
  .p-kurashiCalendarBox.is-thisMonth--07 .p-kurashiCalendarBox__month {
    background: #70c3bc url("../img/kurashi/calendar_illust09_sp.png") no-repeat center;
    background-size: 115px 23px;
  }
  .p-kurashiCalendarBox.is-thisMonth--08 .p-kurashiCalendarBox__month {
    background: #5194cf url("../img/kurashi/calendar_illust10_sp.png") no-repeat center;
    background-size: 115px 23px;
  }
  .p-kurashiCalendarBox.is-thisMonth--09 .p-kurashiCalendarBox__month {
    background: #a8b22d url("../img/kurashi/calendar_illust11_sp.png") no-repeat center;
    background-size: 110px 23px;
  }
  .p-kurashiCalendarBox.is-thisMonth--10 .p-kurashiCalendarBox__month {
    background: #7579b1 url("../img/kurashi/calendar_illust12_sp.png") no-repeat center;
    background-size: 110px 20px;
  }
  .p-kurashiCalendarBox.is-thisMonth--11 .p-kurashiCalendarBox__month {
    background: #eac24b url("../img/kurashi/calendar_illust13_sp.png") no-repeat center;
    background-size: 120px 23px;
  }
  .p-kurashiCalendarBox.is-thisMonth--12 .p-kurashiCalendarBox__month {
    background: #d93f53 url("../img/kurashi/calendar_illust14_sp.png") no-repeat center bottom;
    background-size: 120px 28px;
  }
}
/*
------------------------------------------------------------*/
.p-pokke {
  font-weight: bold;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}
.p-pokke__top {
  display: none;
  position: fixed;
  bottom: 16vw;
  right: 5.3333333333vw;
  width: 11.7333333333vw;
  height: 11.7333333333vw;
  z-index: 99;
}
@media screen and (min-width: 600px) {
  .p-pokke .p-pokke__top {
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 60px;
  }
  .p-pokke .p-pokke__top img {
    width: 100%;
  }
}
.p-pokke .c-mainvisual {
  background: url("/img/kurashi/lp/pokke/pokke_img02.jpg") no-repeat bottom center;
  height: 114.1333333333vw;
  background-size: cover;
}
.p-pokke .c-mainvisual__inner {
  padding: 7.2vw 0 100px;
}
.p-pokke .c-mainvisual__title {
  text-align: center;
  width: 42.6666666667vw;
  height: 33.3333333333vw;
  font-size: 6.4vw;
  line-height: 8.5333333333vw;
  background: url("/img/kurashi/lp/pokke/pokke_img01.png") no-repeat center;
  margin: 0 auto 7.4666666667vw;
  padding: 14.4vw 0 10px;
  background-size: cover;
}
.p-pokke .c-mainvisual__text {
  font-size: 3.2vw;
  line-height: 5.6vw;
  text-align: center;
}
.p-pokke .c-title01 {
  background: #000000;
  color: #fff;
  text-align: center;
  font-size: 3.7333333333vw;
  padding: 1px 0 1px;
}
.p-pokke .c-title02 {
  text-align: center;
  font-size: 6.9333333333vw;
  line-height: 10.1333333333vw;
  padding: 1px 0 1px;
}
.p-pokke .white-space {
  white-space: nowrap;
  position: relative;
}
.p-pokke .c-title03 {
  position: relative;
  font-size: 4.2666666667vw;
  line-height: 5.8666666667vw;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 0 6.9333333333vw;
  white-space: nowrap;
}
.p-pokke .c-title03::before, .p-pokke .c-title03::after {
  content: "";
  position: absolute;
  top: -0.5333333333vw;
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  background-size: 6.6666666667vw 6.6666666667vw !important;
}
.p-pokke .c-title03::before {
  left: 0;
  background: url("/img/kurashi/lp/pokke/pokke_lineL.png") no-repeat center;
}
.p-pokke .c-title03::after {
  right: 0;
  background: url("/img/kurashi/lp/pokke/pokke_lineR.png") no-repeat center;
}
.p-pokke .c-title04 {
  position: relative;
  text-align: center;
  background: #FFDD66;
  padding: 4.8vw 0 2.1333333333vw;
  background-size: 78.4vw 29.3333333333vw;
  margin: 0 2.6666666667vw;
}
.p-pokke .c-title04::before, .p-pokke .c-title04::after {
  content: "";
  position: absolute;
  width: 10.6666666667vw;
  height: 10.6666666667vw;
}
.p-pokke .c-title04::before {
  top: 2.1333333333vw;
  left: -2.6666666667vw;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
}
.p-pokke .c-title04::after {
  bottom: 2.1333333333vw;
  right: -2.6666666667vw;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
}
.p-pokke .c-title04 span {
  display: block;
}
.p-pokke .c-title04__1 {
  font-size: 3.7333333333vw;
  line-height: 5.6vw;
  margin-bottom: 1.6vw;
}
.p-pokke .c-title04__2 {
  font-size: 6.4vw;
  line-height: 9.6vw;
}
.p-pokke .c-title05 {
  font-size: 6.4vw;
  line-height: 9.6vw;
}
.p-pokke .c-text01 {
  position: relative;
  font-size: 3.7333333333vw;
  line-height: 6.1333333333vw;
  padding-left: 10.6666666667vw !important;
}
.p-pokke .c-text01::before {
  content: "";
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  left: 0;
  width: 7.2vw;
  height: 6.9333333333vw;
  background: url("/img/kurashi/lp/pokke/pokke_img03.svg") no-repeat center;
  background-size: 7.2vw 6.9333333333vw !important;
}
.p-pokke .c-text02 {
  font-size: 3.7333333333vw;
  line-height: 6.1333333333vw;
  font-weight: 500;
}
.p-pokke .c-listtext .c-text01 {
  padding: 2.6666666667vw 0 3.2vw;
  border-bottom: 1px dashed #000000;
}
.p-pokke .c-listtext .c-text01:first-child {
  border-top: 1px dashed #000000;
}
.p-pokke .c-blcktext {
  background: #FFF6E1;
  padding: 5.3333333333vw 5.3333333333vw 4.5333333333vw;
  text-align: center;
}
.p-pokke .c-blcktext__icon img {
  width: 17.3333333333vw;
}
.p-pokke .c-blcktext__title {
  font-size: 4.2666666667vw;
  line-height: 9.6vw;
  margin: 2.1333333333vw 0 1.3333333333vw;
}
.p-pokke .c-blcktext .c-text02 {
  text-align: left;
}
.p-pokke .c-button01 {
  position: relative;
}
.p-pokke .c-button01 a {
  display: block;
  position: relative;
  border: 1px solid #000000;
  font-size: 4.2666666667vw;
  line-height: 1;
  padding: 4vw 0 3.7333333333vw;
  text-align: center;
  border-radius: 6.4vw;
  color: #000000;
  z-index: 1;
  margin-left: 4px;
  top: 4px;
}
.p-pokke .c-button01 a::after {
  content: "";
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  right: 6.9333333333vw;
  width: 9.8666666667vw;
  height: 2.4vw;
  background: url("/img/kurashi/lp/pokke/pokke_img08.svg") no-repeat center;
  background-size: 9.8666666667vw 2.4vw;
}
.p-pokke .c-button01::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFDD66;
  border-radius: 6.4vw;
}
.p-pokke .c-blck01 {
  background: #fff;
  border: 1px solid #000000;
  padding: 8vw 0 8.8vw;
}
.p-pokke .c-blck01 + .c-blck01 {
  margin-top: 8vw;
}
.p-pokke .c-blck01__01 {
  padding: 0 11.2vw;
}
.p-pokke .c-blck01__02 {
  padding: 0 5.3333333333vw;
}
.p-pokke .c-blck01 .c-title01 {
  margin-bottom: 4.2666666667vw;
}
.p-pokke .c-blck01 .c-title02 {
  margin-bottom: 7.7333333333vw;
}
.p-pokke .c-blck01 .c-title03 {
  margin-bottom: 8vw;
}
.p-pokke .c-blck01 .c-listtext {
  position: relative;
}
.p-pokke .c-blck01 .c-listtext::after {
  content: "";
  position: absolute;
  bottom: -10.6666666667vw;
  left: 50%;
  transform: translateX(-50%);
  width: 8.5333333333vw;
  height: 8.5333333333vw;
  background: url("/img/kurashi/lp/pokke/pokke_arrow.png") no-repeat center;
  background-size: 8.5333333333vw 8.5333333333vw;
}
.p-pokke .c-blck01 .c-title04 {
  position: relative;
  margin-top: 16vw;
  margin-bottom: 7.4666666667vw;
}
.p-pokke .c-blck01 .c-listimg {
  margin: 7.2vw 0 8vw;
  text-align: center;
}
.p-pokke .c-blck01__img + .c-blck01__img {
  margin-top: 5.3333333333vw;
}
.p-pokke .c-blck01__img img {
  width: 100%;
}
.p-pokke .c-blck01 .c-button01 {
  margin-top: 8vw;
}
@media screen and (min-width: 600px) {
  .p-pokke .c-blck01 .c-blck01__img {
    width: 50%;
    padding: 0 20px;
  }
  .p-pokke .c-blck01 .c-blck01__img + .c-blck01__img {
    margin-top: 0;
  }
  .p-pokke .c-blck01 .c-listimg {
    display: flex;
    margin: 7.2vw -20px 8vw;
  }
}
.p-pokke .c-lst01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -4.8vw;
}
.p-pokke .c-lst01__item {
  width: 33.3333333333%;
  padding: 0 2.6666666667vw;
  margin-bottom: 4.8vw;
}
.p-pokke .c-lst01__text {
  font-size: 3.7333333333vw;
  line-height: 5.3333333333vw;
  margin-top: 2.6666666667vw;
  color: #000000;
}
.p-pokke01 {
  background: #FDF0D5;
  padding: 8vw 5.3333333333vw;
}
.p-pokke01 .c-blck01:nth-child(1) .c-listtext .c-text01:nth-child(2) {
  padding: 2.6666666667vw 0 1.6vw;
}
.p-pokke01 .c-blck01:nth-child(1) .c-listtext .c-text01:nth-child(3) {
  padding: 2.1333333333vw 0 2.1333333333vw;
}
.p-pokke01 .c-blck01:nth-child(2) .c-listtext .c-text01:first-child {
  padding: 2.4vw 0 1.6vw;
}
.p-pokke01 .c-blck01:nth-child(2) .c-listtext .c-text01:nth-child(2) {
  padding: 2.1333333333vw 0 1.6vw;
}
.p-pokke01 .c-blck01:nth-child(2) .c-listtext .c-text01:nth-child(3) {
  padding: 2.4vw 0 1.6vw;
}
.p-pokke01 .c-blck01:nth-child(2) .c-title02 .white-space {
  left: -3.7333333333vw;
}
.p-pokke01 .c-blck01:nth-child(2) .c-title04 {
  padding: 4.8vw 0;
}
.p-pokke01 .c-blck01:nth-child(3) .c-title01 {
  margin-bottom: 3.4666666667vw;
}
.p-pokke01 .c-blck01:nth-child(3) .c-listtext .c-text01:first-child {
  padding: 2.1333333333vw 0 1.3333333333vw;
}
.p-pokke01 .c-blck01:nth-child(3) .c-listtext .c-text01:nth-child(2) {
  padding: 2.4vw 0 1.8666666667vw;
}
.p-pokke01 .c-blck01:nth-child(3) .c-listtext .c-text01:nth-child(3) {
  padding: 3.4666666667vw 0 2.4vw;
}
.p-pokke01 .c-blck01:nth-child(3) .c-blcktext__title {
  margin: 2.1333333333vw 0 1.6vw;
}
.p-pokke01 .c-blck01:nth-child(3) .c-title04 {
  margin-top: 18.6666666667vw;
  margin-bottom: 4.5333333333vw;
}
.p-pokke02 {
  padding: 7.4666666667vw 5.3333333333vw 14.6666666667vw;
  text-align: center;
}
.p-pokke02 .c-title05 {
  margin-bottom: 4vw;
}
.p-pokke02 .c-text02 {
  line-height: 6.3466666667vw;
}
.p-pokke02 .c-lst01 {
  margin-top: 4.2666666667vw;
}
.p-pokke02 .c-button01 {
  max-width: 78.1333333333vw;
  margin: 2.6666666667vw auto 0;
}
.p-pokke .bold {
  font-weight: bold;
}
.p-pokke-ft {
  text-align: center;
}
.p-pokke-ft__text {
  background: #E2DFD7;
  padding: 6.6666666667vw 0 4.8vw;
  font-size: 3.7333333333vw;
  line-height: 6.3466666667vw;
  font-weight: 500;
}
.p-pokke-ft__text a {
  color: #000000;
  opacity: 1;
}
.p-pokke-ft__copyright {
  background: #000000;
  color: #fff;
  font-size: 2.6666666667vw;
  padding: 5.0666666667vw 0 4.8vw;
}

.p-pokke210920 {
  font-weight: bold;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}
.p-pokke210920 .c-mainvisual__img {
  margin: 5.3333333333vw 0 0 5.3333333333vw;
  border-radius: 2.6666666667vw 0 0 2.6666666667vw;
  overflow: hidden;
}
.p-pokke210920 .c-mainvisual__img img {
  width: 100%;
}
.p-pokke210920 .c-mainvisual__inner {
  background: #8CB340;
  padding: 17.8666666667vw 5.3333333333vw 6.6666666667vw;
  text-align: center;
  margin-top: -10.6666666667vw;
  color: #fff;
}
.p-pokke210920 .c-mainvisual__text01 {
  font-size: 5.0666666667vw;
  line-height: 1;
  line-height: 8vw;
  letter-spacing: -0.1em;
  margin: 0 -1.3333333333vw 2.6666666667vw;
  white-space: nowrap;
}
.p-pokke210920 .c-mainvisual__text02 {
  width: 32.2666666667vw;
  font-size: 5.3333333333vw;
  line-height: 1;
  color: #F29E4A;
  background: #FFFB84;
  padding: 2.2666666667vw 1.4666666667vw 2vw;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-pokke210920 .c-mainvisual__text02::after {
  content: "";
  position: absolute;
  bottom: -2.1333333333vw;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 2vw solid transparent;
  border-right: 2vw solid transparent;
  border-top: 2.1333333333vw solid #FFFB84;
}
.p-pokke210920 .c-mainvisual__title {
  font-size: 9.3333333333vw;
  line-height: 1;
  margin: 4.2666666667vw 0 4vw;
}
.p-pokke210920 .c-mainvisual__text03 {
  width: 70.4vw;
  padding: 2.4vw 2.6666666667vw 2.6666666667vw;
  margin: 0 auto;
  font-size: 3.2vw;
  border: 0.2666666667vw solid #fff;
  line-height: 1;
}
.p-pokke210920 .c-mainvisual__text032 {
  margin: 1.8666666667vw 0 -1.6vw;
  display: block;
}
.p-pokke210920 .c-mainvisual__text03-1 {
  font-size: 5.3333333333vw;
  line-height: 1;
}
.p-pokke210920 .c-mainvisual__text03-2 {
  font-size: 2.9333333333vw;
  font-weight: 500;
  line-height: 1;
}
.p-pokke210920 .c-mainvisual__text04 {
  background: #FCFBF5;
  padding: 6.4vw 5.3333333333vw;
  font-size: 3.7333333333vw;
  line-height: 5.6vw;
  color: #000000;
}
.p-pokke210920 .l-container {
  padding: 0 5.3333333333vw;
}
.p-pokke210920 .c-title01 {
  position: relative;
  color: #8CB340;
  text-align: center;
  font-size: 6.1333333333vw;
  line-height: 7.2vw;
  margin-bottom: 10.6666666667vw;
}
.p-pokke210920 .c-title01::after {
  content: "";
  position: absolute;
  bottom: -3.4666666667vw;
  left: 50%;
  transform: translateX(-50%);
  width: 8vw;
  height: 1.0666666667vw;
  background: #8CB340;
}
.p-pokke210920 .c-title01__tit {
  font-size: 2.6666666667vw;
  line-height: 1;
  text-transform: uppercase;
  display: block;
  font-family: "Roboto", sans-serif;
  margin-bottom: 2.4vw;
}
.p-pokke210920 .c-title02 {
  text-align: center;
  font-size: 6.9333333333vw;
  line-height: 10.1333333333vw;
  padding: 1px 0 1px;
}
.p-pokke210920 .c-text01 {
  position: relative;
  font-size: 3.2vw;
  line-height: 1;
  padding: 3.7333333333vw 5.3333333333vw 4.5333333333vw;
  text-align: center;
  background: #F29E4A;
  color: #fff;
  margin-bottom: 7.3333333333vw;
}
.p-pokke210920 .c-text01__1 {
  display: block;
}
.p-pokke210920 .c-text01__1 + .c-text01__1 {
  margin-top: 1.4666666667vw;
}
.p-pokke210920 .c-text01__2 {
  font-size: 5.3333333333vw;
}
.p-pokke210920 .c-text01::before {
  content: "";
  position: absolute;
  bottom: -2vw;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 2vw solid transparent;
  border-right: 2vw solid transparent;
  border-top: 2.1333333333vw solid #F29E4A;
}
.p-pokke210920 .c-text02 {
  font-size: 5.0666666667vw;
  line-height: 6.9333333333vw;
  text-align: center;
  color: #000000;
  margin-bottom: 5.4666666667vw;
}
.p-pokke210920 .c-text03 {
  position: relative;
  font-size: 4vw;
  line-height: 5.0666666667vw;
  text-align: center;
  color: #8CB340;
  padding: 0 10.6666666667vw;
  margin-bottom: 4.8vw;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.p-pokke210920 .c-text03::before, .p-pokke210920 .c-text03::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-size: 7.0666666667vw 10.8vw !important;
  width: 7.0666666667vw;
  height: 10.8vw;
}
.p-pokke210920 .c-text03::before {
  background: url("/img/kurashi/lp/pokke210920/icon-titleL.svg") no-repeat center;
  left: 0;
}
.p-pokke210920 .c-text03::after {
  background: url("/img/kurashi/lp/pokke210920/icon-titleR.svg") no-repeat center;
  right: 0;
}
.p-pokke210920 .c-text04 {
  font-size: 3.4666666667vw;
  color: #fff;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2666666667vw 4vw;
  margin: 0 auto 2.6666666667vw;
  width: fit-content;
}
.p-pokke210920 .c-text05 {
  font-size: 3.7333333333vw;
  line-height: 5.3333333333vw;
  text-align: center;
  font-weight: 500;
}
.p-pokke210920 .c-text05 + .c-text05 {
  margin-top: 1.3333333333vw;
}
.p-pokke210920 .c-text05 + .c-text04 {
  margin-top: 6.6666666667vw;
}
.p-pokke210920 .c-text05 + .c-text06 {
  margin-top: 2.6666666667vw;
}
.p-pokke210920 .c-text06 {
  font-size: 3.2vw;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2.9333333333vw;
}
.p-pokke210920 .c-listimg {
  margin-top: 8vw;
}
.p-pokke210920 .c-listimg + .c-text03 {
  margin-top: 10.6666666667vw;
}
.p-pokke210920 .c-img + .c-img {
  margin-top: 2.6666666667vw;
}
.p-pokke210920 .c-blocktext {
  background: #FCFBF5;
  padding: 6.4vw 2.6666666667vw 3.6vw;
  text-align: center;
}
.p-pokke210920 .c-blocktext + .c-blocktext {
  margin-top: 2.6666666667vw;
}
.p-pokke210920 .c-blocktext + .c-text05 {
  margin-top: 5.3333333333vw;
}
.p-pokke210920 .c-blocktext01 {
  font-size: 4.5333333333vw;
  line-height: 1;
  color: #000000;
}
.p-pokke210920 .c-blocktext02 {
  margin-top: 3.3333333333vw;
  font-size: 3.7333333333vw;
  line-height: 1;
  color: #8CB340;
}
.p-pokke210920 .c-blocktext03 {
  font-size: 3.7333333333vw;
  font-weight: 500;
  line-height: 1;
  margin-top: 1.3333333333vw;
}
.p-pokke210920 .c-blocktext__number {
  position: relative;
  top: 0.5333333333vw;
  font-size: 7.4666666667vw;
  line-height: 1;
}
.p-pokke210920 .c-blcktext01 {
  padding: 5.3333333333vw;
  font-size: 3.7333333333vw;
  font-weight: 500;
  line-height: 5.3333333333vw;
  background: #FCFBF5;
}
.p-pokke210920 .c-blcktext01 + .c-blcktext01 {
  margin-top: 2.6666666667vw;
}
.p-pokke210920 .c-listtext {
  margin-bottom: 8vw;
}
.p-pokke210920 .c-listtext__item {
  position: relative;
  font-size: 4vw;
  line-height: 5.0666666667vw;
  padding: 4vw 5.3333333333vw 4vw 17.3333333333vw;
  border-bottom: 1px dashed #000000;
}
.p-pokke210920 .c-listtext__item::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5.3333333333vw;
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  background: url("/img/kurashi/lp/pokke210920/icon-checkbox.svg") no-repeat center;
  background-size: 6.6666666667vw 6.6666666667vw;
}
.p-pokke210920 .c-listtext__item:first-child {
  border-top: 1px dashed #000000;
}
.p-pokke210920 .c-listtext--circle {
  margin-bottom: 8vw;
}
.p-pokke210920 .c-listtext--circle .c-listtext__item {
  font-size: 4vw;
  line-height: 5.0666666667vw;
  padding: 4vw 5.3333333333vw;
  border-bottom: 1px dashed #000000;
}
.p-pokke210920 .c-listtext--circle .c-listtext__item:before {
  content: none;
}
.p-pokke210920 .c-listtext--circle .c-listtext__title {
  display: block;
  margin-bottom: 5px;
  padding-left: 4.9333333333vw;
  position: relative;
}
.p-pokke210920 .c-listtext--circle .c-listtext__title:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-57%);
  width: 3.3333333333vw;
  height: 3.3333333333vw;
  border-radius: 50%;
  background: #8CB340;
}
.p-pokke210920 .c-listtext--circle .c-listtext__text {
  font-size: 3.7333333333vw;
  line-height: 5.3333333333vw;
  font-weight: 500;
}
.p-pokke210920 .c-button02 {
  margin-top: 5.3333333333vw;
}
.p-pokke210920 .c-button02 a {
  border-radius: 1.3333333333vw;
  color: #fff;
  font-size: 4vw;
  line-height: 1;
  height: 16vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8CB340 url("/img/kurashi/lp/pokke210920/icon-arrow.svg") no-repeat center right 5.3333333333vw/2.4vw 4vw;
}
.p-pokke210920 .c-total {
  color: #000000;
  background: #FCFBF5;
  text-align: center;
  padding: 5.3333333333vw;
}
.p-pokke210920 .c-total__row + .c-total__row {
  margin-top: 4vw;
}
.p-pokke210920 .c-total__text01 {
  font-size: 4.5333333333vw;
  line-height: 1;
}
.p-pokke210920 .c-total__text02 {
  font-size: 3.7333333333vw;
  font-weight: 500;
}
.p-pokke210920 .c-total__text03 {
  font-size: 3.7333333333vw;
  line-height: 6.9333333333vw;
  margin: 4vw 0 2vw;
}
.p-pokke210920 .c-total__text04 {
  background: #F29E4A;
  height: 13.0666666667vw;
  padding: 0 2.6666666667vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3.3333333333vw;
  line-height: 1;
  white-space: nowrap;
}
.p-pokke210920 .c-total__text04__number {
  font-size: 6.6666666667vw;
}
.p-pokke210920 .c-total__text04__number span {
  font-size: 3.3333333333vw;
  position: relative;
  top: -0.2666666667vw;
}
.p-pokke210920 .c-total__text05 {
  font-size: 3.4666666667vw;
  line-height: 1;
  font-weight: 500;
  margin: 3.7333333333vw 2.6666666667vw;
}
.p-pokke210920 .c-total__text06 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-pokke210920 .c-total__text06:last-of-type {
  border-top: 1px dashed #000000;
  margin-top: 2.6666666667vw;
  padding-top: 2.6666666667vw;
}
.p-pokke210920 .c-total__label, .p-pokke210920 .c-total__unit {
  font-size: 3.7333333333vw;
  line-height: 1;
}
.p-pokke210920 .c-total__price {
  font-size: 4.5333333333vw;
}
.p-pokke210920 .c-total__price.cl-green {
  color: #8CB340;
}
.p-pokke210920 .c-total__text07 {
  border: 0.2666666667vw solid #8CB340;
  padding: 3.2vw;
  color: #8CB340;
  font-size: 4.5333333333vw;
  line-height: 6.9333333333vw;
  margin-top: 4vw;
}
.p-pokke210920 .c-total__text08 {
  font-size: 3.4666666667vw;
  line-height: 1;
  font-weight: 500;
  text-align: right;
  margin-top: 2.6666666667vw;
}
.p-pokke210920 .c-total + .c-text04 {
  margin-top: 6.6666666667vw;
}
.p-pokke210920 .c-lst01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 9.3333333333vw;
}
.p-pokke210920 .c-lst01__img {
  text-align: center;
}
.p-pokke210920 .c-lst01__img img {
  width: 100%;
}
.p-pokke210920 .c-lst01__item {
  width: 33.3333333333%;
  padding: 0 2.6666666667vw;
  margin-bottom: 4.8vw;
}
.p-pokke210920 .c-lst01__text {
  font-size: 3.4666666667vw;
  line-height: 4vw;
  margin-top: 2.6666666667vw;
  color: #000000;
  text-align: center;
}
.p-pokke210920-ft {
  text-align: center;
}
.p-pokke210920-ft__text {
  background: #8CB340;
  padding: 9.3333333333vw 0;
  font-size: 3.7333333333vw;
  line-height: 6.3466666667vw;
  font-weight: 500;
  color: #fff;
}
.p-pokke210920-ft__text a {
  color: #fff;
  opacity: 1;
}
.p-pokke210920-ft__text .bold {
  display: block;
  font-weight: bold;
  font-size: 4vw;
  margin-bottom: 2.6666666667vw;
}
.p-pokke210920-ft__copyright {
  background: #333333;
  color: #fff;
  font-size: 2.6666666667vw;
  padding: 5.0666666667vw 0 4.8vw;
}
.p-pokke210920__01 {
  padding: 10.6666666667vw 0 8vw;
}
.p-pokke210920__02 {
  padding: 8vw 0;
}
.p-pokke210920__02 .p-pokke210920 .c-blocktext + .c-text05 {
  margin-top: 4vw;
}
.p-pokke210920__03 {
  padding: 8vw 0;
}
.p-pokke210920__03 .c-blocktext01 {
  line-height: 6.9333333333vw;
}
.p-pokke210920__04 {
  padding: 8vw 0 18.6666666667vw;
}

.p-pokke221031 {
  font-weight: bold;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}
.p-pokke221031 .c-mainvisual__img {
  margin: 5.3333333333vw 0 0 5.3333333333vw;
  border-radius: 2.6666666667vw 0 0 2.6666666667vw;
  overflow: hidden;
}
.p-pokke221031 .c-mainvisual__img img {
  width: 100%;
}
.p-pokke221031 .c-mainvisual__inner {
  background: #8CB340;
  padding: 17.8666666667vw 5.3333333333vw 6.6666666667vw;
  text-align: center;
  margin-top: -10.6666666667vw;
  color: #fff;
}
.p-pokke221031 .c-mainvisual__text01 {
  font-size: 5.0666666667vw;
  line-height: 1;
  line-height: 8vw;
  letter-spacing: -0.1em;
  margin: 0 -1.3333333333vw 2.6666666667vw;
  white-space: nowrap;
}
.p-pokke221031 .c-mainvisual__text02 {
  width: 32.2666666667vw;
  font-size: 5.3333333333vw;
  line-height: 1;
  color: #F29E4A;
  background: #FFFB84;
  padding: 2.2666666667vw 1.4666666667vw 2vw;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-pokke221031 .c-mainvisual__text02::after {
  content: "";
  position: absolute;
  bottom: -2.1333333333vw;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 2vw solid transparent;
  border-right: 2vw solid transparent;
  border-top: 2.1333333333vw solid #FFFB84;
}
.p-pokke221031 .c-mainvisual__title {
  font-size: 7.2vw;
  line-height: 1;
  margin: 4.2666666667vw 0 4vw;
}
.p-pokke221031 .c-mainvisual__text03 {
  padding: 2.4vw 2.6666666667vw 2.6666666667vw;
  margin: 0 auto;
  font-size: 3.2vw;
  border: 0.2666666667vw solid #fff;
  line-height: 1;
}
.p-pokke221031 .c-mainvisual__text032 {
  margin: 1.8666666667vw 0 -1.6vw;
  display: block;
}
.p-pokke221031 .c-mainvisual__text03-1 {
  font-size: 5.3333333333vw;
  line-height: 1.5;
}
.p-pokke221031 .c-mainvisual__text03-2 {
  font-size: 2.9333333333vw;
  font-weight: 500;
  line-height: 1;
}
.p-pokke221031 .c-mainvisual__text03-3 {
  font-size: 4.5333333333vw;
  line-height: 1.6;
  display: block;
}
.p-pokke221031 .c-mainvisual__text03.left {
  text-align: left;
}
.p-pokke221031 .c-mainvisual__text04 {
  background: #FCFBF5;
  padding: 6.5333333333vw 3.2vw;
  font-size: 4.5333333333vw;
  line-height: 6.6666666667vw;
  color: #000000;
  white-space: nowrap;
}
.p-pokke221031 .c-mainvisual__text05 {
  padding: 5.0666666667vw 3.7333333333vw;
  font-size: 4.5333333333vw;
  line-height: 6.6666666667vw;
  color: #FDFA87;
  text-align: left;
}
.p-pokke221031 .l-container {
  padding: 0 5.3333333333vw;
}
.p-pokke221031 .c-title01 {
  position: relative;
  color: #8CB340;
  text-align: center;
  font-size: 4.8vw;
  line-height: 7.2vw;
  margin-bottom: 10.6666666667vw;
}
.p-pokke221031 .c-title01::after {
  content: "";
  position: absolute;
  bottom: -3.4666666667vw;
  left: 50%;
  transform: translateX(-50%);
  width: 8vw;
  height: 1.0666666667vw;
  background: #8CB340;
}
.p-pokke221031 .c-title01__tit {
  font-size: 4vw;
  line-height: 1;
  text-transform: uppercase;
  display: block;
  font-family: "Roboto", sans-serif;
  margin-bottom: 2.4vw;
}
.p-pokke221031 .c-title02 {
  text-align: center;
  font-size: 6.9333333333vw;
  line-height: 10.1333333333vw;
  padding: 1px 0 1px;
}
.p-pokke221031 .c-text01 {
  position: relative;
  font-size: 4.9333333333vw;
  line-height: 1.5;
  padding: 3.7333333333vw 5.3333333333vw 4.5333333333vw;
  text-align: center;
  background: #F29E4A;
  color: #fff;
  margin-bottom: 7.3333333333vw;
}
.p-pokke221031 .c-text01__1 {
  display: block;
}
.p-pokke221031 .c-text01__1 + .c-text01__1 {
  margin-top: 1.4666666667vw;
}
.p-pokke221031 .c-text01__2 {
  font-size: 5.3333333333vw;
}
.p-pokke221031 .c-text01::before {
  content: "";
  position: absolute;
  bottom: -2vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 2vw solid transparent;
  border-right: 2vw solid transparent;
  border-top: 2.1333333333vw solid #F29E4A;
}
.p-pokke221031 .c-text02 {
  font-size: 5.0666666667vw;
  line-height: 6.9333333333vw;
  text-align: center;
  color: #000000;
  margin-bottom: 5.4666666667vw;
}
.p-pokke221031 .c-text03 {
  position: relative;
  font-size: 4vw;
  line-height: 5.0666666667vw;
  text-align: center;
  color: #8CB340;
  padding: 0 10.6666666667vw;
  margin-bottom: 4.8vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.p-pokke221031 .c-text03::before, .p-pokke221031 .c-text03::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-size: 7.0666666667vw 10.8vw !important;
  width: 7.0666666667vw;
  height: 10.8vw;
}
.p-pokke221031 .c-text03::before {
  background: url("/img/kurashi/lp/pokke210920/icon-titleL.svg") no-repeat center;
  left: 0;
}
.p-pokke221031 .c-text03::after {
  background: url("/img/kurashi/lp/pokke210920/icon-titleR.svg") no-repeat center;
  right: 0;
}
.p-pokke221031 .c-text04 {
  font-size: 3.4666666667vw;
  color: #fff;
  background: #000000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.2666666667vw 4vw;
  margin: 0 auto 2.6666666667vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.p-pokke221031 .c-text05 {
  font-size: 3.7333333333vw;
  line-height: 5.3333333333vw;
  text-align: center;
  font-weight: 500;
}
.p-pokke221031 .c-text05 + .c-text05 {
  margin-top: 1.3333333333vw;
}
.p-pokke221031 .c-text05 + .c-text04 {
  margin-top: 6.6666666667vw;
}
.p-pokke221031 .c-text05 + .c-text06 {
  margin-top: 2.6666666667vw;
}
.p-pokke221031 .c-text06 {
  font-size: 3.2vw;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2.9333333333vw;
}
.p-pokke221031 .c-listimg {
  margin-top: 8vw;
}
.p-pokke221031 .c-listimg + .c-text03 {
  margin-top: 10.6666666667vw;
}
.p-pokke221031 .c-img + .c-img {
  margin-top: 2.6666666667vw;
}
.p-pokke221031 .c-blocktext {
  background: #FCFBF5;
  padding: 6.4vw 2.6666666667vw 3.6vw;
  text-align: center;
}
.p-pokke221031 .c-blocktext + .c-blocktext {
  margin-top: 2.6666666667vw;
}
.p-pokke221031 .c-blocktext + .c-text05 {
  margin-top: 5.3333333333vw;
}
.p-pokke221031 .c-blocktext01 {
  font-size: 4.5333333333vw;
  line-height: 1;
  color: #000000;
}
.p-pokke221031 .c-blocktext02 {
  margin-top: 3.3333333333vw;
  font-size: 3.7333333333vw;
  line-height: 1;
  color: #8CB340;
}
.p-pokke221031 .c-blocktext03 {
  font-size: 3.7333333333vw;
  font-weight: 500;
  line-height: 1;
  margin-top: 1.3333333333vw;
}
.p-pokke221031 .c-blocktext__number {
  position: relative;
  top: 0.5333333333vw;
  font-size: 7.4666666667vw;
  line-height: 1;
}
.p-pokke221031 .c-blcktext01 {
  padding: 5.3333333333vw;
  font-size: 3.7333333333vw;
  font-weight: 500;
  line-height: 5.3333333333vw;
  background: #FCFBF5;
}
.p-pokke221031 .c-blcktext01 + .c-blcktext01 {
  margin-top: 2.6666666667vw;
}
.p-pokke221031 .c-listtext {
  margin-bottom: 8vw;
}
.p-pokke221031 .c-listtext__item {
  position: relative;
  font-size: 4.5333333333vw;
  line-height: 6.6666666667vw;
  padding: 4vw 5.3333333333vw 4vw 17.3333333333vw;
  border-bottom: 1px dashed #000000;
}
.p-pokke221031 .c-listtext__item::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5.3333333333vw;
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  background: url("/img/kurashi/lp/pokke210920/icon-checkbox.svg") no-repeat center;
  background-size: 6.6666666667vw 6.6666666667vw;
}
.p-pokke221031 .c-listtext__item:first-child {
  border-top: 1px dashed #000000;
}
.p-pokke221031 .c-listtext__small {
  font-size: 4vw;
  font-weight: 300;
}
.p-pokke221031 .c-listtext--circle {
  margin-bottom: 8vw;
}
.p-pokke221031 .c-listtext--circle .c-listtext__item {
  font-size: 4vw;
  line-height: 5.0666666667vw;
  padding: 4vw 5.3333333333vw;
  border-bottom: 1px dashed #000000;
}
.p-pokke221031 .c-listtext--circle .c-listtext__item:before {
  content: none;
}
.p-pokke221031 .c-listtext--circle .c-listtext__title {
  display: block;
  margin-bottom: 5px;
  padding-left: 4.9333333333vw;
  position: relative;
}
.p-pokke221031 .c-listtext--circle .c-listtext__title:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  -webkit-transform: translateY(-57%);
  -ms-transform: translateY(-57%);
  transform: translateY(-57%);
  width: 3.3333333333vw;
  height: 3.3333333333vw;
  border-radius: 50%;
  background: #8CB340;
}
.p-pokke221031 .c-listtext--circle .c-listtext__text {
  font-size: 3.7333333333vw;
  line-height: 5.3333333333vw;
  font-weight: 500;
}
.p-pokke221031 .c-button02 {
  margin-top: 5.3333333333vw;
}
.p-pokke221031 .c-button02 a {
  border-radius: 1.3333333333vw;
  color: #fff;
  font-size: 4vw;
  line-height: 1;
  height: 16vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #8CB340 url("/img/kurashi/lp/pokke221031/icon-arrow.svg") no-repeat center right 5.3333333333vw/2.4vw 4vw;
}
.p-pokke221031 .c-total-cont {
  text-align: center;
}
.p-pokke221031 .c-total {
  color: #000000;
  background: #FCFBF5;
  text-align: center;
  padding: 5.3333333333vw;
}
.p-pokke221031 .c-total__row + .c-total__row {
  margin-top: 4vw;
  white-space: nowrap;
}
.p-pokke221031 .c-total__text01 {
  font-size: 4.5333333333vw;
  line-height: 1;
}
.p-pokke221031 .c-total__text02 {
  font-size: 3.7333333333vw;
  font-weight: 500;
}
.p-pokke221031 .c-total__text03 {
  font-size: 4vw;
  line-height: 6.9333333333vw;
  margin: 4vw 0 3.4666666667vw;
  text-align: center;
}
.p-pokke221031 .c-total__text04 {
  background: #F29E4A;
  height: 13.0666666667vw;
  padding: 0 2.6666666667vw;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 3.3333333333vw;
  line-height: 1;
  white-space: nowrap;
}
.p-pokke221031 .c-total__text04__number {
  font-size: 6.6666666667vw;
}
.p-pokke221031 .c-total__text04__number span {
  font-size: 3.3333333333vw;
  position: relative;
  top: -0.2666666667vw;
}
.p-pokke221031 .c-total__text05 {
  font-size: 3.4666666667vw;
  line-height: 1;
  font-weight: 500;
  margin: 3.7333333333vw 2.6666666667vw;
}
.p-pokke221031 .c-total__text06 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-pokke221031 .c-total__text06:last-of-type {
  border-top: 1px dashed #000000;
  margin-top: 2.6666666667vw;
  padding-top: 2.6666666667vw;
}
.p-pokke221031 .c-total__label, .p-pokke221031 .c-total__unit {
  font-size: 3.7333333333vw;
  line-height: 1;
}
.p-pokke221031 .c-total__price {
  font-size: 4.5333333333vw;
}
.p-pokke221031 .c-total__price.cl-green {
  color: #8CB340;
}
.p-pokke221031 .c-total__text07 {
  border: 0.2666666667vw solid #8CB340;
  padding: 3.2vw;
  color: #8CB340;
  font-size: 4.5333333333vw;
  line-height: 6.9333333333vw;
  margin-top: 4vw;
  text-align: center;
}
.p-pokke221031 .c-total__text08 {
  font-size: 3.4666666667vw;
  line-height: 1;
  font-weight: 500;
  text-align: right;
  margin-top: 2.6666666667vw;
}
.p-pokke221031 .c-total + .c-text04 {
  margin-top: 6.6666666667vw;
}
.p-pokke221031 .t-pink {
  color: #F70D55;
  font-size: 4vw;
}
.p-pokke221031 .t-green {
  color: #8CB340;
  font-size: 4vw;
}
.p-pokke221031 .c-point {
  text-align: center;
}
.p-pokke221031 .c-point__text01 {
  font-size: 4.5333333333vw;
  line-height: 6.9333333333vw;
  margin: 4vw 0 2vw;
}
.p-pokke221031 .c-point-box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4.5333333333vw;
}
.p-pokke221031 .c-lst01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 9.3333333333vw;
}
.p-pokke221031 .c-lst01__img {
  text-align: center;
}
.p-pokke221031 .c-lst01__img img {
  width: 100%;
}
.p-pokke221031 .c-lst01__item {
  width: 33.3333333333%;
  padding: 0 2.6666666667vw;
  margin-bottom: 4.8vw;
}
.p-pokke221031 .c-lst01__text {
  font-size: 3.4666666667vw;
  line-height: 4vw;
  margin-top: 2.6666666667vw;
  color: #000000;
  text-align: center;
}
.p-pokke221031-ft {
  text-align: center;
}
.p-pokke221031-ft__text {
  background: #8CB340;
  padding: 9.3333333333vw 0;
  font-size: 3.7333333333vw;
  line-height: 6.3466666667vw;
  font-weight: 500;
  color: #fff;
}
.p-pokke221031-ft__text a {
  color: #fff;
  opacity: 1;
}
.p-pokke221031-ft__text .bold {
  display: block;
  font-weight: bold;
  font-size: 4vw;
  margin-bottom: 2.6666666667vw;
}
.p-pokke221031-ft__copyright {
  background: #333333;
  color: #fff;
  font-size: 2.6666666667vw;
  padding: 5.0666666667vw 0 4.8vw;
}
.p-pokke221031__01 {
  padding: 10.6666666667vw 0 8vw;
}
.p-pokke221031__02 {
  padding: 8vw 0;
}
.p-pokke221031__02 .p-pokke221031 .c-blocktext + .c-text05 {
  margin-top: 4vw;
}
.p-pokke221031__03 {
  padding: 8vw 0;
}
.p-pokke221031__03 .c-blocktext01 {
  line-height: 6.9333333333vw;
}
.p-pokke221031__04 {
  padding: 8vw 0 18.6666666667vw;
}

.c-point-area {
  font-size: 4vw;
  color: #fff;
  background: #000000;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  margin: 5px 33px 5px 0;
  width: -webkit-fill-available;
}

.c-point-price {
  width: 50%;
  white-space: nowrap;
}

/*------------------------------------------------------------
葬祭TOP
------------------------------------------------------------*/
.p-sosaiTop {
  background-color: #ffffff;
}

.p-sosaiTop01 {
  min-width: 1120px;
  margin: 0 auto;
  padding-bottom: 116px;
  background-color: #ffffff;
  background-image: url("../img/sosai/index_mv_bg.jpg");
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center 0;
}
.p-sosaiTop01 .p-sosaiTop01__mv {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 24px 0;
}
.p-sosaiTop01 .p-sosaiTop01__mv--pc {
  display: flex;
}
.p-sosaiTop01 .p-sosaiTop01__mv--pc li {
  width: 16.2%;
  margin-right: 1.6%;
  list-style: none;
}
.p-sosaiTop01 .p-sosaiTop01__mv--pc li:nth-last-of-type(2) {
  margin-right: 0;
}
.p-sosaiTop01 .p-sosaiTop01__mv--pc li:nth-child(2n) {
  margin-top: 31px;
}
.p-sosaiTop01 .p-sosaiTop01__mv--pc li:last-of-type {
  width: 460px;
  min-width: 460px;
  height: 420px;
  margin-right: 0;
  margin-top: 80px;
  margin-left: 62px;
}
.p-sosaiTop01 .p-sosaiTop01__mv--pc li:not(:last-of-type) img {
  width: 100%;
  height: auto;
}

.p-sosaiTop02__txt {
  font-size: 1.4rem;
  font-weight: 500;
  color: #111111;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop02__txt {
    padding: 0 18px;
    font-size: 1.1rem;
    line-height: 1.5;
  }
}

.p-sosaiTop03 {
  padding: 89px 0 100px;
  background-color: #ffffff;
}
.p-sosaiTop03 .c-sosaiTitle01 {
  margin-bottom: 53px;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList li {
  padding: 20px 0;
  border-bottom: solid 1px #dddddd;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList li:last-child {
  border: none;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList li p {
  width: 910px;
  color: #46483c;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList li a {
  color: #46483c;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList li a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList .p-sosaiTop03__contents01__newsList_data {
  width: 120px;
  font-size: 1.7rem;
  color: #666666;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList .c-newsCate02 {
  width: 60px;
  margin: 4px 5px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList .c-newsCate03 {
  min-width: 70px;
  width: inherit;
  padding: 0 5px;
  margin: 4px 34px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiTop03 .p-sosaiTop03__contents01 .c-sosaiBtn01 {
  width: 200px;
  margin-top: 30px;
  font-weight: bold;
}

.p-sosaiTop04 {
  min-width: 1120px;
  margin: 0 auto;
}
.p-sosaiTop04 .c-block02__btn {
  width: 277px;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop04 .c-block02__btn {
    width: 232px !important;
    text-align: left;
    padding: 10px 29px 10px;
  }
}
.p-sosaiTop04 .c-block02__left img {
  min-height: 760px;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop04 .c-block02__left img {
    min-height: auto;
  }
}

.p-sosaiTop05 {
  padding: 108px 0 119px;
  background-color: #ffffff;
}
.p-sosaiTop05 .c-sosaiTitle01 {
  margin-bottom: 60px;
}
.p-sosaiTop05 .p-sosaiTop05__contents01 .c-list02--txt {
  background: #FFF;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  writing-mode: unset;
  padding: 10px 27px;
  border: 3px solid #fff;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop05 .p-sosaiTop05__contents01 .c-list02--txt {
    writing-mode: vertical-lr;
    padding: 20px 7px;
  }
}
.p-sosaiTop05 .p-sosaiTop05__contents01 .c-list02--txt span {
  font-size: 1.6rem;
  line-height: 4.8rem;
  color: #2f2933;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop05 .p-sosaiTop05__contents01 .c-list02--txt span {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.p-sosaiTop05 .p-sosaiTop05__contents01 .c-list02--txt:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 30px;
  background: #2e95a3;
  right: -6px;
  bottom: -18px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-sosaiTop06 {
  padding: 110px 0 121px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  margin-bottom: 110px;
}
.p-sosaiTop06 .p-sosaiTop06__contents01 .p-sosaiTop06__contents01__txt01 {
  margin-bottom: 51px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.8;
  color: #111111;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop06 .p-sosaiTop06__contents01 .p-sosaiTop06__contents01__txt01 {
    padding: 0 18px;
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 24px;
  }
}
.p-sosaiTop06 .p-sosaiTop06__contents01 .p-sosaiTop06__contents01__txt02 {
  margin: 36px 0 34px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #111111;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop06 .p-sosaiTop06__contents01 .p-sosaiTop06__contents01__txt02 {
    padding: 0 18px;
    font-size: 1.1rem;
    margin: 16px 0 20px;
    line-height: 1.5;
  }
}
.p-sosaiTop06 .p-sosaiTop06__contents01 .c-list04 {
  background-color: #ffffff;
}
.p-sosaiTop06 .c-sosaiBtn01 {
  width: 281px;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop06 .c-sosaiBtn01 {
    width: 73%;
    font-size: 1.3rem;
    line-height: 4.5rem;
  }
}

.p-sosaiTop07 {
  padding: 90px 0 97px;
}
.p-sosaiTop07 .p-sosaiTop07__contents01 .p-sosaiTop07__contents01__txt01 {
  margin-bottom: 55px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.8;
  color: #111111;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop07 .p-sosaiTop07__contents01 .p-sosaiTop07__contents01__txt01 {
    padding: 0 15px;
    font-size: 1.4rem;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 24px;
  }
}
.p-sosaiTop07 .p-sosaiTop07__contents01 .p-sosaiTop07__contents01__txt01 b {
  font-weight: bold;
}

.p-sosaiTop08 {
  padding: 0 0 87px 0;
}
.p-sosaiTop08 .p-sosaiTop08__contents01 .p-sosaiTop08__contents01__txt01 {
  margin-bottom: 51px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.8;
  color: #111111;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08 .p-sosaiTop08__contents01 .p-sosaiTop08__contents01__txt01 {
    margin-bottom: 36px;
    font-size: 1.4rem;
    padding: 0 20px;
    line-height: 1.6;
    text-align: left;
  }
}
.p-sosaiTop08 .p-sosaiTop08__contents01 .p-sosaiTop08__contents01__txt01 b {
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08 .p-sosaiTop08__contents01 .c-list03__item {
    width: 100%;
    margin: 0 0 10px;
  }
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08 .p-sosaiTop08__contents01 .c-list03__item:nth-child(1) .c-list03__lable {
    min-width: 170px;
  }
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08 .p-sosaiTop08__contents01 .c-list03__item:nth-child(3) .c-list03__lable {
    min-width: 170px;
  }
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08 .p-sosaiTop08__contents01 .c-list03__lable {
    width: auto;
    left: 50%;
    font-size: 1.2rem;
    text-align: center;
    padding: 12px 16px;
    line-height: 1.5rem;
  }
}
.p-sosaiTop08__listItem {
  display: block;
  width: 262px;
  float: left;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08__listItem {
    width: calc(50% - 5px);
    padding-top: 5px;
  }
}
.p-sosaiTop08__listItem:last-child {
  margin-left: 21px;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08__listItem:last-child {
    margin-left: 10px;
  }
}
.p-sosaiTop08__listItem:last-child .c-list03__lable {
  width: 156px;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08__listItem:last-child .c-list03__lable {
    width: auto;
    min-width: 100px;
  }
}
.p-sosaiTop08__listItem .c-list03__lable {
  padding: 21px 22px 21px 28px;
  width: 140px;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop08__listItem .c-list03__lable {
    padding: 12px 10px !important;
    width: auto;
    min-width: 90px;
  }
}

.p-sosaiTop09 {
  min-width: 1120px;
  margin: 0 auto 140px auto;
}
.p-sosaiTop09 .c-block02__btn {
  width: 328px;
  padding: 15px 7px 13px 0;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop09 .c-block02__btn {
    width: 210px !important;
    padding: 5px 16px 4px 0;
    line-height: 1.3;
  }
}
.p-sosaiTop09 .c-block02__left img {
  min-height: 556px;
}
@media screen and (max-width: 640px) {
  .p-sosaiTop09 .c-block02__left img {
    min-height: auto;
  }
}

@media screen and (max-width: 640px) {
  .p-sosaiTop01 {
    min-width: 100%;
    background-image: none;
    padding-bottom: 0;
  }
  .p-sosaiTop01 .p-sosaiTop01__mv {
    width: 100%;
    padding: 0;
  }
  .p-sosaiTop01 .p-sosaiTop01__mv--sp ul {
    display: flex;
  }
  .p-sosaiTop01 .p-sosaiTop01__mv--sp li {
    list-style: none;
  }
  .p-sosaiTop01 .p-sosaiTop01__mv--sp img {
    width: 100%;
    height: auto;
  }
  .p-sosaiTop01 .p-sosaiTop01__mv--logo {
    padding: 33px 60px 31px;
    background-image: url("../img/sosai/mv_bg-sp.png");
    -webkit-background-size: 100%;
    background-size: 100%;
  }
  .p-sosaiTop03 {
    padding: 40px 20px 50px;
  }
  .p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList li {
    padding: 19px 0 15px;
  }
  .p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList li a {
    width: 100%;
    font-size: 1.4rem;
    padding: 4px 0 0;
  }
  .p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList .p-sosaiTop03__contents01__newsList_data {
    width: auto;
    margin: 0 11px 0 0;
    font-size: 1.3rem;
  }
  .p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList .c-newsCate02 {
    width: 40px;
    height: 15px;
    margin: 2px 3px 2px 0;
    font-size: 1rem;
    padding-top: 2px;
  }
  .p-sosaiTop03 .p-sosaiTop03__contents01 .p-sosaiTop03__contents01__newsList .c-newsCate03 {
    min-width: 50px;
    width: inherit;
    height: 15px;
    margin: 2px 0;
    font-size: 1rem;
    padding-top: 3px;
  }
  .p-sosaiTop03 .p-sosaiTop03__contents01 .c-sosaiBtn01 {
    width: 61%;
    margin-top: 11px;
    font-size: 1.3rem;
  }
  .p-sosaiTop04 {
    min-width: 100%;
  }
  .p-sosaiTop05 {
    padding: 43px 0 50px;
  }
  .p-sosaiTop05 .c-sosaiTitle01 {
    margin-bottom: 39px !important;
  }
  .p-sosaiTop06 {
    padding: 43px 0 50px;
    margin-bottom: 0;
  }
  .p-sosaiTop06 .c-sosaiTitle01 {
    margin-bottom: 37px !important;
  }
  .p-sosaiTop07 {
    padding: 43px 0 31px;
  }
  .p-sosaiTop07 .c-sosaiTitle01 {
    margin-bottom: 37px !important;
  }
  .p-sosaiTop08 {
    padding: 44px 0 40px;
  }
  .p-sosaiTop08 .c-sosaiTitle01 {
    margin-bottom: 36px !important;
  }
  .p-sosaiTop09 {
    min-width: 100%;
    margin-bottom: 25px;
  }
}
.js-fade02 {
  opacity: 0;
  transform: translate(0, 130px);
}
.js-fade02.is-fade02--1 {
  transition-duration: 3s;
}
.js-fade02.is-fade02--2 {
  transition-delay: 1s;
  transition-duration: 3s;
}
.js-fade02.is-fade02--3 {
  transition-delay: 2s;
  transition-duration: 3s;
}
.js-fade02.is-fade02--4 {
  transition-delay: 3s;
  transition-duration: 3s;
}
.js-fade02.is-fade02 {
  opacity: 1;
  transform: translate(0, 0);
}

.js-fade03 {
  opacity: 0;
}
@keyframes fadeIn02 {
  0% {
    filter: opacity(0%) blur(10px);
    opacity: 0;
  }
  50% {
    filter: opacity(100%) blur(10px);
  }
  100% {
    filter: opacity(100%) blur(0px);
    opacity: 1;
  }
}
.js-fade03.is-fade03 {
  animation-name: fadeIn02;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  filter: blur(0px);
}

/*------------------------------------------------------------
葬祭　ご危篤・ご逝去でお急ぎの方
------------------------------------------------------------*/
.p-sosaiUrgency {
  background-color: #ffffff;
  font-size: 1.8rem;
  line-height: 1.77;
  color: #111111;
}

.p-sosaiUrgency01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiUrgency01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiUrgency02 {
  margin-bottom: 120px;
}
.p-sosaiUrgency02 > p {
  margin: 22px 0 71px;
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 3.2rem;
}

.p-sosaiUrgency03 {
  margin-bottom: 106px;
  padding: 107px 0 120px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiUrgency03 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiUrgency03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiUrgency03 ol {
  border: 1px solid #c8c8c8;
}
.p-sosaiUrgency03 ol li {
  width: 33.3333333333%;
  background-color: #ffffff;
  position: relative;
}
.p-sosaiUrgency03 ol li + li {
  border-left: 1px solid #c8c8c8;
}
.p-sosaiUrgency03 ol li:before {
  content: "";
  width: 10px;
  height: 10px;
  background: url("../img/sosai/urgency_before.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 2px;
}
.p-sosaiUrgency03 ol li p {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: bold;
  padding: 34px 40px 32px;
}

.p-sosaiUrgency04 {
  margin-bottom: 118px;
}
.p-sosaiUrgency04 > p {
  font-size: 1.7rem;
  line-height: 3rem;
}
.p-sosaiUrgency04 .c-sosaiTitle02b {
  padding-right: 7px;
  margin-bottom: 52px;
}
.p-sosaiUrgency04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiUrgency04__text01 {
  text-align: center;
  margin-bottom: 72px;
}
.p-sosaiUrgency04__text02 {
  text-align: left;
}
.p-sosaiUrgency04__box {
  margin-bottom: 71px;
  border: solid 2px #2e95a3;
}
.p-sosaiUrgency04__box ul {
  display: flex;
}
.p-sosaiUrgency04__box ul li {
  width: 25%;
  display: flex;
  align-items: center;
  padding: 30px 10px 30px 30px;
}
.p-sosaiUrgency04__box ul li:nth-child(2n) {
  background: #f6f6f6;
}
.p-sosaiUrgency04__box ul li img {
  width: 34px;
  height: 34px;
}
.p-sosaiUrgency04__box ul li p {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #111;
  font-weight: bold;
  padding-left: 18px;
}

.p-sosaiUrgency05 {
  margin-bottom: 106px;
}
.p-sosaiUrgency05 .c-sosaiTitle02b {
  margin-bottom: 60px;
  padding-left: 4px;
}
.p-sosaiUrgency05 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiUrgency05__content01 {
  padding: 101px 0 83px 0;
  background-color: #f8f8f8;
  position: relative;
}
.p-sosaiUrgency05__content01:after {
  width: 30px;
  height: 48px;
  margin: 0 auto;
  content: "";
  background-image: url("../img/component/3arrow02.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
}
.p-sosaiUrgency05__content01 .p-sosaiUrgency05__text h3 {
  align-items: center;
}
.p-sosaiUrgency05__content01 .p-sosaiUrgency05__text h3 img {
  margin-top: 0;
}
.p-sosaiUrgency05__content02 {
  padding: 96px 0 92px;
  position: relative;
}
.p-sosaiUrgency05__content02:after {
  width: 30px;
  height: 48px;
  margin: 0 auto;
  content: "";
  background-image: url("../img/component/3arrow02.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
}
.p-sosaiUrgency05__content02 .p-sosaiUrgency05__text h3 {
  margin-bottom: 15px;
}
.p-sosaiUrgency05__content02 .p-sosaiUrgency05__textImg {
  padding-top: 5px;
}
.p-sosaiUrgency05__content03 {
  padding: 100px 0;
  background-color: #f8f8f8;
}
.p-sosaiUrgency05__content03 .p-sosaiUrgency05__text h3 {
  padding-top: 3px;
  margin-bottom: 15px;
}
.p-sosaiUrgency05__content03 .p-sosaiUrgency05__text h3 img {
  margin-top: -3px;
}
.p-sosaiUrgency05__text {
  width: 660px;
}
.p-sosaiUrgency05__text h3 {
  display: flex;
  align-items: flex-start;
  font-size: 3.4rem;
  line-height: 4.4rem;
  font-weight: normal;
  margin-bottom: 22px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  letter-spacing: -0.1px;
}
.p-sosaiUrgency05__text h3 img {
  margin-right: 15px;
  margin-top: 5px;
  width: 50px;
  height: 50px;
}
.p-sosaiUrgency05__text p {
  font-size: 1.7rem;
  line-height: 3rem;
  color: #111;
}
.p-sosaiUrgency05__text02 {
  margin: 33px 0 16px;
  padding: 26px 40px 32px;
  background-color: #FFF;
  border: solid 1px #c8c8c8;
}
.p-sosaiUrgency05__text02 .c-sosaiTitle10 {
  margin-bottom: 8px;
  font-size: 2rem;
  line-height: 4.8rem;
  color: #111;
  font-weight: bold;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}
.p-sosaiUrgency05__text02 .c-sosaiTitle10:before {
  margin: -1px 12px 0 -30px;
}
.p-sosaiUrgency05__text02 p {
  font-size: 1.7rem;
  line-height: 3rem;
  color: #111;
}
.p-sosaiUrgency05__textImg {
  width: 400px;
  margin-left: 60px;
}
.p-sosaiUrgency05__textImg p {
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: #111;
  margin-top: 24px;
  padding-left: 16px;
  position: relative;
}
.p-sosaiUrgency05__textImg p span {
  position: absolute;
  left: 0;
  top: 0;
}
.p-sosaiUrgency05__box {
  min-height: 240px;
  display: flex;
  position: relative;
}
.p-sosaiUrgency05__note {
  margin-top: 16px;
}
.p-sosaiUrgency05__note p {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #111;
  padding-left: 15px;
  position: relative;
}
.p-sosaiUrgency05__note p span {
  position: absolute;
  left: 0;
  top: 0;
}
.p-sosaiUrgency05__note p + p {
  margin-top: 9px;
}

.p-sosaiUrgency06 {
  margin-bottom: 72px;
}
.p-sosaiUrgency06 .c-sosaiTitle02b {
  margin-bottom: 60px;
  padding-left: 4px;
}
.p-sosaiUrgency06 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiUrgency06 > p {
  font-size: 1.8rem;
  line-height: 3.2rem;
  color: #111;
  font-weight: bold;
  text-align: center;
  margin-top: 93px;
}
.p-sosaiUrgency06 ul li {
  width: 353px;
  margin-right: 30px;
  border-bottom: 1px solid #c8c8c8;
  position: relative;
}
.p-sosaiUrgency06 ul li:nth-child(3) {
  margin-right: 0;
}
.p-sosaiUrgency06 ul li:before, .p-sosaiUrgency06 ul li:after {
  content: "";
  width: 1px;
  height: calc(100% - 200px);
  background: #c8c8c8;
  position: absolute;
  bottom: 0;
}
.p-sosaiUrgency06 ul li:before {
  left: 0;
}
.p-sosaiUrgency06 ul li:after {
  right: 0;
}
.p-sosaiUrgency06__text {
  padding: 33px 40px 32px;
}
.p-sosaiUrgency06__text h3 {
  font-size: 2rem;
  line-height: 3.2rem;
  color: #2e95a3;
  text-align: center;
  font-weight: bold;
  margin-bottom: 17px;
}
.p-sosaiUrgency06__text--01 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #111;
}
.p-sosaiUrgency06__text--02 {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #111;
  position: relative;
}

.p-sosaiUrgency07 {
  padding: 0 0 140px;
}

.p-sosaiUrgencyBox {
  background: url("../img/sosai/bg_urgency.png") no-repeat top right;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox {
    padding-top: 10px;
    background: url("../img/sosai/bg_urgency_sp.png") no-repeat top 10px right;
    background-size: cover;
  }
}
.p-sosaiUrgencyBox > img {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox > img {
    top: 0;
  }
}
.p-sosaiUrgencyBox__title {
  padding: 24px 38px 22px;
  background: #f6738b;
  font-size: 3.4rem;
  line-height: 1;
  color: #FFF;
  font-weight: normal;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__title {
    display: block;
    font-size: 2.3rem;
    line-height: 2.5rem;
    padding: 12px 98px 13px 10px;
    text-align: center;
    height: 115px;
  }
}
.p-sosaiUrgencyBox__title span {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: bold;
  color: #f2385a;
  background: #FFF;
  padding: 7px 28px 6px 31px;
  margin-top: -3px;
  margin-left: 16px;
  letter-spacing: 0.7px;
  border-radius: 15px;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__title span {
    display: block;
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin: 5px auto 0;
    padding: 5px 10px 4px;
    max-width: 130px;
    letter-spacing: 0;
    border-radius: 20px;
  }
}
.p-sosaiUrgencyBox__Area {
  border-left: 2px solid #c8c8c8;
  border-bottom: 2px solid #c8c8c8;
  border-right: 2px solid #c8c8c8;
  padding: 42px 0;
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area {
    display: block;
    border-left: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
    padding: 18px 0 22px;
  }
}
.p-sosaiUrgencyBox__Area ul {
  width: 418px;
  padding: 0 38px 0 51px;
  background: url("../img/sosai/urgency_border02.png") repeat-y top right;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul {
    width: 100%;
    background: none;
    padding: 0 20px;
  }
}
.p-sosaiUrgencyBox__Area ul:not(:first-of-type) {
  padding: 0 30px;
  background: none;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul:not(:first-of-type) {
    padding: 18px 20px 0;
  }
}
.p-sosaiUrgencyBox__Area ul:not(:first-of-type) li:last-child {
  background: none;
  padding-bottom: 0;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul:not(:first-of-type) li a > div {
    width: 210px;
    padding-left: 51px;
  }
}
.p-sosaiUrgencyBox__Area ul:not(:first-of-type) li a > div:before {
  left: 12px;
}
.p-sosaiUrgencyBox__Area ul li {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  padding-bottom: 23px;
  background: url("../img/sosai/urgency_border01.png") repeat-x bottom left;
  margin-bottom: 29px;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li {
    padding-bottom: 24px;
    margin-bottom: 19px;
  }
}
.p-sosaiUrgencyBox__Area ul li:last-child {
  background: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li:last-child {
    background: url("../img/sosai/urgency_border01.png") repeat-x bottom left;
    padding-bottom: 24px;
  }
}
.p-sosaiUrgencyBox__Area ul li h3 {
  font-size: 2.6rem;
  line-height: 3.4rem;
  font-weight: normal;
  margin-bottom: 14px;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li h3 {
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-bottom: 5px;
  }
}
.p-sosaiUrgencyBox__Area ul li h3 img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-top: 1px;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li h3 img {
    width: 21px;
    height: 21px;
    margin-right: 5px;
    margin-top: 2px;
  }
}
.p-sosaiUrgencyBox__Area ul li h3 span {
  display: inline-block;
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li h3 span {
    font-size: 1.3rem;
  }
}
.p-sosaiUrgencyBox__Area ul li a {
  display: block;
  font-size: 4rem;
  line-height: 1;
  color: #111;
  letter-spacing: 3px;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li a {
    background: #2e95a3;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #FFF;
    letter-spacing: 0.2px;
    padding: 10px 10px 5px 10px;
    font-family: YuGothic, "Yu Gothic", sans-serif;
    font-weight: bold;
    box-shadow: 0px 3px 0px #20656e;
    border-radius: 5px;
  }
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li a > div {
    width: 185px;
    margin: 0 auto;
    position: relative;
    padding-left: 40px;
  }
}
.p-sosaiUrgencyBox__Area ul li a > div:before {
  content: "";
  width: 25px;
  height: 25px;
  background: url("../img/common/icon_tel07.png") no-repeat;
  background-size: cover;
  display: none;
  position: absolute;
  top: calc(50% - 13px);
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li a > div:before {
    display: block;
  }
}
.p-sosaiUrgencyBox__Area ul li a span {
  display: block;
  color: #333;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li a span {
    display: block;
    font-size: 1rem;
    line-height: 1.2;
    color: #FFF;
    margin-bottom: 1px;
  }
}
.p-sosaiUrgencyBox__Area ul li a span.p-sosaiUrgencyBox__name {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: bold;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  letter-spacing: 0;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li a span.p-sosaiUrgencyBox__name {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 0;
  }
}
.p-sosaiUrgencyBox__Area ul li a img {
  margin-right: 9px;
  margin-top: 3px;
}
.p-sosaiUrgencyBox__Area ul li a + a {
  margin-top: 18px;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li a + a {
    margin-top: 8px;
  }
}
.p-sosaiUrgencyBox__Area ul li a.mb10 {
  margin-bottom: 10px;
}
.p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__area {
  font-weight: normal;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 10px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__area {
    font-size: 1.3rem;
  }
}
.p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__tel {
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__tel {
    font-size: 1.8rem;
  }
}
.p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__note {
  font-size: 1.3rem;
  line-height: 1.3;
  font-weight: normal;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  padding-top: 5px;
}
.p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__note small {
  margin-bottom: 7px;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__note {
    padding-top: 15px;
  }
}
.p-sosaiUrgencyBox__Area ul li .p-sosaiUrgencyBox__note:first-of-type {
  margin-bottom: 10px;
}

@media screen and (max-width: 640px) {
  .p-sosaiUrgency {
    font-size: 1.4rem;
  }
  .p-sosaiUrgency01 {
    min-width: 100%;
    margin-bottom: 48px;
    padding: 32px 0 33px;
  }
  .p-sosaiUrgency02 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiUrgency02 > p {
    margin-bottom: 16px;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .p-sosaiUrgency03 {
    margin-bottom: 50px;
    padding: 47px 20px 50px;
  }
  .p-sosaiUrgency03 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiUrgency03 .c-sosaiTitle02b span:after {
    margin: 11px auto 0 auto;
  }
  .p-sosaiUrgency03 ol li {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #c8c8c8;
  }
  .p-sosaiUrgency03 ol li:last-child {
    border-bottom: 0;
  }
  .p-sosaiUrgency03 ol li + li {
    border-left: 0;
  }
  .p-sosaiUrgency03 ol li p {
    text-align: left;
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 17px 20px 15px;
  }
  .p-sosaiUrgency04 {
    margin-bottom: 44px;
    padding: 0 15px;
  }
  .p-sosaiUrgency04 > p {
    font-size: 1.4rem;
    line-height: 1.7;
    padding: 0 5px;
  }
  .p-sosaiUrgency04 .c-sosaiTitle02b {
    margin-bottom: 27px;
    padding-right: 2px;
  }
  .p-sosaiUrgency04 .c-sosaiTitle02b span:after {
    margin: 11px auto 0 auto;
  }
  .p-sosaiUrgency04__text01 {
    text-align: left;
    margin-bottom: 34px;
  }
  .p-sosaiUrgency04__box {
    margin-bottom: 33px;
  }
  .p-sosaiUrgency04__box ul {
    display: block;
  }
  .p-sosaiUrgency04__box ul li {
    width: 100%;
    padding: 15px 10px 15px 15px;
  }
  .p-sosaiUrgency04__box ul li img {
    width: 30px;
    height: 30px;
  }
  .p-sosaiUrgency04__box ul li p {
    font-size: 1.3rem;
    line-height: 2rem;
    padding-left: 9px;
  }
  .p-sosaiUrgency05 {
    margin-bottom: 47px;
  }
  .p-sosaiUrgency05__content01 {
    padding: 51px 20px 49px;
  }
  .p-sosaiUrgency05__content02 {
    padding: 47px 20px 49px;
  }
  .p-sosaiUrgency05__content02 .p-sosaiUrgency05__text h3 {
    margin-bottom: 10px;
  }
  .p-sosaiUrgency05__content02 .p-sosaiUrgency05__note {
    margin-top: 12px;
  }
  .p-sosaiUrgency05__content03 {
    padding: 49px 20px 45px;
  }
  .p-sosaiUrgency05__content03 .p-sosaiUrgency05__text h3 {
    margin-bottom: 15px;
  }
  .p-sosaiUrgency05__content03 .p-sosaiUrgency05__text h3 img {
    margin-right: 9px;
  }
  .p-sosaiUrgency05__text {
    width: 100%;
  }
  .p-sosaiUrgency05__text h3 {
    font-size: 2.1rem;
    line-height: 2.6rem;
    margin-bottom: 19px;
  }
  .p-sosaiUrgency05__text h3 img {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    margin-top: 2px;
  }
  .p-sosaiUrgency05__text p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiUrgency05__text02 {
    margin: 14px 0 0;
    padding: 15px 18px 14px 20px;
  }
  .p-sosaiUrgency05__text02 .c-sosaiTitle10 {
    font-size: 1.5rem;
    line-height: 2.4rem;
    padding-left: 16px;
    margin-bottom: 4px;
    position: relative;
  }
  .p-sosaiUrgency05__text02 .c-sosaiTitle10:before {
    margin: 0;
    position: absolute;
    left: 0;
    top: 7px;
    width: 11px;
    height: 11px;
  }
  .p-sosaiUrgency05__textImg {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .p-sosaiUrgency05__textImg img {
    width: 100%;
  }
  .p-sosaiUrgency05__box {
    min-height: auto;
    display: block;
    position: relative;
  }
  .p-sosaiUrgency05__note {
    margin-top: 20px;
  }
  .p-sosaiUrgency05__note p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-sosaiUrgency05__note p + p {
    margin-top: 7px;
  }
  .p-sosaiUrgency06 {
    margin-bottom: 0;
    padding: 0 20px;
  }
  .p-sosaiUrgency06 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiUrgency06 .c-sosaiTitle02b span:after {
    margin: 11px auto 0 auto;
  }
  .p-sosaiUrgency06 > p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 46px;
  }
  .p-sosaiUrgency06__text {
    padding: 18px 21px 8px;
    border-bottom: 1px solid #c8c8c8;
    border-top: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
    width: calc(100% - 90px);
  }
  .p-sosaiUrgency06__text h3 {
    font-size: 1.5rem;
    line-height: 1.6rem;
    text-align: left;
    margin-bottom: 5px;
  }
  .p-sosaiUrgency06__text--01 {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .p-sosaiUrgency06__text--02 {
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: bold;
    position: relative;
    padding-left: 10px;
    margin-top: 2px;
  }
  .p-sosaiUrgency06__text--02 span {
    position: absolute;
    left: -1px;
    top: 1px;
  }
  .p-sosaiUrgency06__img {
    width: 33%;
    border-bottom: 1px solid #c8c8c8;
    border-top: 1px solid #c8c8c8;
    border-left: 1px solid #c8c8c8;
  }
  .p-sosaiUrgency06__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;"; /*IE対策*/
  }
  .p-sosaiUrgency06 ul li {
    width: 100%;
    margin: 0 0 10px 0;
    border-bottom: 0;
    display: flex;
  }
  .p-sosaiUrgency06 ul li:before, .p-sosaiUrgency06 ul li:after {
    display: none;
  }
  .p-sosaiUrgency06 ul li:nth-child(2) .p-sosaiUrgency06__text--02 {
    margin-top: 11px;
  }
  .p-sosaiUrgency06 ul li:nth-child(3) .p-sosaiUrgency06__text--01 {
    margin-top: 10px;
  }
  .p-sosaiUrgency07 {
    margin-bottom: 40px;
    padding: 17px 20px 20px;
  }
  .p-sosaiUrgency07 .u-inner01 > p {
    margin-bottom: 30px;
    font-size: 1em;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　資料請求・お問い合わせ
------------------------------------------------------------*/
.p-sosaiContactIndex {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiContactIndex .c-breadcrumb1 {
  padding: 9px 0 43px;
}

.p-sosaiContactIndex01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiContactIndex01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiContactIndex02 {
  margin-top: -30px;
  padding: 91px 0 135px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiContactIndex02 h2 {
  margin-bottom: 70px;
  font-size: 2rem;
  font-weight: 700;
  color: #111111;
  text-align: center;
}
.p-sosaiContactIndex02__txt {
  font-size: 1.3rem;
}
.p-sosaiContactIndex02__contact {
  margin-bottom: 16px;
}
.p-sosaiContactIndex02__contact__box {
  width: 33%;
  margin-right: 1px;
  border-top: solid 2px;
  background-color: #ffffff;
  text-align: center;
}
.p-sosaiContactIndex02__contact__box--yamanashi {
  border-top-color: #006da7;
}
.p-sosaiContactIndex02__contact__box--kanagawa {
  border-top-color: #00a384;
}
.p-sosaiContactIndex02__contact__box--shizuoka {
  border-top-color: #931719;
}
.p-sosaiContactIndex02__contact__box:nth-child(2) .p-sosaiContactIndex02__contact__box__txt {
  margin-bottom: 20px;
}
.p-sosaiContactIndex02__contact__box:nth-child(2) .p-sosaiContactIndex02__contact__box__txt p:not(.l-company) {
  margin-bottom: 10px;
}
.p-sosaiContactIndex02__contact__box:last-child {
  margin: 0;
}
.p-sosaiContactIndex02__contact__box h3 {
  margin-top: 10px;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 25px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiContactIndex02__contact__box h3 span {
  font-size: 3.2rem;
  line-height: 1;
  display: block;
}
.p-sosaiContactIndex02__contact__box h3 img {
  margin: 0 auto;
  display: block;
}
.p-sosaiContactIndex02__contact__box__txt {
  margin: 0 40px;
}
.p-sosaiContactIndex02__contact__box__txt p {
  font-weight: 700;
  font-size: 1.6rem;
}
.p-sosaiContactIndex02__contact__box__txt p.l-company {
  font-size: 1.3rem;
  letter-spacing: -0.2px;
  line-height: 1.3;
  margin-bottom: 0;
}
.p-sosaiContactIndex02__contact__box__txt a {
  display: block;
  line-height: 1;
  color: #111111;
}
.p-sosaiContactIndex02__contact__box__txt a span {
  display: block;
}
.p-sosaiContactIndex02__contact__box__txt a span:nth-child(1) {
  font-size: 3.4rem;
  letter-spacing: 2.5px;
  padding: 4px 0 6px 8px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiContactIndex02__contact__box__txt a span:nth-child(1):before {
  width: 24px;
  height: 24px;
  margin: -9px 8px 0 -4px;
  content: "";
  background-image: url("../img/common/icon_tel06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiContactIndex02__contact__box__txt a span:nth-child(2) {
  font-size: 1.3rem;
  letter-spacing: -0.2px;
}
.p-sosaiContactIndex02__contact__box__txt-note {
  display: block;
  font-size: 1.3rem;
  letter-spacing: -0.2px;
  line-height: 1.3;
}
.p-sosaiContactIndex02__contact__box .c-sosaiBtn06 {
  padding: 4px 0;
  margin-bottom: 20px;
}
.p-sosaiContactIndex02__contact__box .c-sosaiBtn06 a:before {
  margin-right: 20px;
}
.p-sosaiContactIndex02__note {
  font-size: 1.3rem;
}
.p-sosaiContactIndex02__note a {
  margin-left: 9px;
  font-weight: bold;
  color: #111111;
}
.p-sosaiContactIndex02__note span {
  margin-left: 25px;
  position: relative;
}
.p-sosaiContactIndex02__note span:after {
  content: "";
  width: 10px;
  height: 10px;
  background-image: url(../img/common/icon_tel08.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 2px;
  left: -16px;
}

@media screen and (max-width: 640px) {
  .p-sosaiContactIndex {
    font-size: 1.6rem;
  }
  .p-sosaiContactIndex01 {
    min-width: 100%;
    padding: 32px 0 33px;
  }
  .p-sosaiContactIndex02 {
    margin: 0 0 58px;
    padding: 46px 20px 34px;
  }
  .p-sosaiContactIndex02 h2 {
    margin-bottom: 23px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiContactIndex02__contact {
    margin-bottom: 14px;
  }
  .p-sosaiContactIndex02__contact__box {
    width: 100%;
    height: auto;
    margin-bottom: 19px;
    padding-bottom: 29px;
  }
  .p-sosaiContactIndex02__contact__box:nth-child(2) .p-sosaiContactIndex02__contact__box__txt {
    margin-bottom: 10px;
  }
  .p-sosaiContactIndex02__contact__box:nth-child(2) .p-sosaiContactIndex02__contact__box__txt p:not(.l-company) {
    margin-bottom: 10px;
  }
  .p-sosaiContactIndex02__contact__box:nth-child(2) .p-sosaiContactIndex02__contact__box__txt a {
    padding: 15px 0 15px 43px;
    margin-bottom: 10px;
  }
  .p-sosaiContactIndex02__contact__box:nth-child(2) .p-sosaiContactIndex02__contact__box__txt a div span {
    padding: 5px 0 0 0;
  }
  .p-sosaiContactIndex02__contact__box:nth-child(2) .p-sosaiContactIndex02__contact__box__txt a div:before {
    top: 0;
  }
  .p-sosaiContactIndex02__contact__box h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  .p-sosaiContactIndex02__contact__box h3 span {
    font-size: 2.8rem;
  }
  .p-sosaiContactIndex02__contact__box__txt {
    margin: 10px 20px 7px;
    padding-top: 15px;
  }
  .p-sosaiContactIndex02__contact__box__txt p {
    font-size: 1.4rem;
  }
  .p-sosaiContactIndex02__contact__box__txt a {
    text-align: left;
    color: #fff;
    margin-bottom: 0;
    margin-top: 3px;
    background: #2e95a3;
    box-shadow: 0px 3px 0px #1f6267;
    border-radius: 5px;
    padding: 8px 0 8px 43px;
  }
  .p-sosaiContactIndex02__contact__box__txt a span:nth-child(1) {
    font-size: 1.8rem;
    letter-spacing: 1px;
    padding: 0 0 2px;
  }
  .p-sosaiContactIndex02__contact__box__txt a span:nth-child(1):before {
    content: none;
  }
  .p-sosaiContactIndex02__contact__box__txt a span:nth-child(2) {
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.2;
  }
  .p-sosaiContactIndex02__contact__box__txt a div {
    width: 162px;
    margin: 0 auto;
    position: relative;
  }
  .p-sosaiContactIndex02__contact__box__txt a div:before {
    content: "";
    width: 25px;
    height: 25px;
    background-image: url(../img/common/icon_tel07.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    left: -40px;
  }
  .p-sosaiContactIndex02__contact__box .c-sosaiBtn06 {
    max-width: 100%;
    width: calc(100% - 40px);
    padding: 0;
    margin-bottom: 0;
  }
  .p-sosaiContactIndex02__contact__box .c-sosaiBtn06 a {
    padding: 10px 15px;
    line-height: 1.1;
  }
  .p-sosaiContactIndex02__contact__box .c-sosaiBtn06 a:before {
    margin: 0 14px 0 12px;
  }
  .p-sosaiContactIndex02__note {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiContactIndex02__note a {
    margin: 25px auto 0;
    padding: 10px 0;
    width: calc(100% - 40px);
    display: block;
    font-size: 1.5rem;
    color: #fff;
    background: #2e95a3;
    box-shadow: 0px 2px 0px #1f6267;
    border-radius: 3px;
    text-align: center;
  }
  .p-sosaiContactIndex02__note span {
    margin-left: 29px;
  }
  .p-sosaiContactIndex02__note span:after {
    width: 12px;
    height: 12px;
    background-image: url(../img/common/icon_tel09.png);
    position: absolute;
    left: -21px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　資料請求・お問い合わせフォーム（神奈川/山梨/静岡）
------------------------------------------------------------*/
.p-sosaiContact {
  padding: 50px 0 135px 0;
  background-color: #ffffff;
}

.p-sosaiContact01 {
  margin-bottom: 30px;
}
.p-sosaiContact01 .c-formTitle {
  color: #2f2933;
  font-size: 2.4rem;
}

.p-sosaiContact02 {
  margin-bottom: 50px;
}
.p-sosaiContact02 p {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #111111;
}
.p-sosaiContact02 p a {
  text-decoration: underline;
  color: #46483c;
}
.p-sosaiContact02 p a:hover {
  opacity: 1;
  text-decoration: none;
}

.p-sosaiContact03 .p-sosaiContact03__content01 {
  margin-bottom: 50px;
}
.p-sosaiContact03 .p-sosaiContact03__content01 dl dt {
  margin-bottom: 13px;
  font-size: 2rem;
  font-weight: bold;
  color: #111111;
}
.p-sosaiContact03 .p-sosaiContact03__content01 dl dt:before {
  width: 8px;
  height: 8px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiContact03 .p-sosaiContact03__content01 dl dd {
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #111111;
}
.p-sosaiContact03 .p-sosaiContact03__content01 dl dd a {
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContact03 .p-sosaiContact03__content01 dl dd a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContact03 .p-sosaiContact03__content01 dl dd p {
  width: 800px;
}
.p-sosaiContact03 .p-sosaiContact03__content02__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContact03 .p-sosaiContact03__content02__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContact03 .p-sosaiContact03__content02__back:before {
  width: 7px;
  height: 14px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-sosaiContact {
    padding: 30px 20px 80px 20px;
  }
  .p-sosaiContact01 {
    margin-bottom: 20px;
  }
  .p-sosaiContact01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContact02 {
    margin-bottom: 30px;
  }
  .p-sosaiContact02 p {
    font-size: 1.4rem;
  }
  .p-sosaiContact03 .p-sosaiContact03__content01 {
    margin-bottom: 30px;
  }
  .p-sosaiContact03 .p-sosaiContact03__content01 .c-sosaiTitle05 {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
  .p-sosaiContact03 .p-sosaiContact03__content01 dl dt {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
  .p-sosaiContact03 .p-sosaiContact03__content01 dl dd {
    margin-bottom: 15px;
    font-size: 1.4rem;
  }
  .p-sosaiContact03 .p-sosaiContact03__content01 dl dd p {
    width: 100%;
    margin-bottom: 10px;
  }
  .p-sosaiContact03 .p-sosaiContact03__content01 dl dd img {
    margin: 0 auto;
    display: block;
  }
  .p-sosaiContact03 .p-sosaiContact03__content02__back {
    font-size: 1.4rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　資料請求・お問い合わせフォーム（神奈川/山梨/静岡）　入力・エラー
------------------------------------------------------------*/
.p-sosaiContactInput {
  padding: 50px 0 100px 0;
  background-color: #ffffff;
}

.p-sosaiContactInput01 {
  margin-bottom: 30px;
}
.p-sosaiContactInput01 .c-formTitle {
  color: #2f2933;
}

.p-sosaiContactInput02 {
  margin-bottom: 80px;
}
.p-sosaiContactInput02__require {
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background: #f22727;
  padding: 0 5px;
  line-height: 1.6;
  border-radius: 3px;
}
@media screen and (max-width: 640px) {
  .p-sosaiContactInput02__require {
    margin-left: 10px;
  }
}
.p-sosaiContactInput02__content01 {
  margin-bottom: 50px;
}
.p-sosaiContactInput02__content01__txt {
  margin-bottom: 40px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-sosaiContactInput02__content01__txt2 {
  margin-bottom: 15px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-sosaiContactInput02__content02 {
  margin-bottom: 50px;
}
.p-sosaiContactInput02__content03__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContactInput02__content03__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContactInput02__content03__back:before {
  width: 7px;
  height: 14px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-sosaiContactInput {
    padding: 30px 20px 80px 20px;
  }
  .p-sosaiContactInput01 {
    margin-bottom: 20px;
  }
  .p-sosaiContactInput01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContactInput02 {
    margin-bottom: 40px;
  }
  .p-sosaiContactInput02__content01 {
    margin-bottom: 30px;
  }
  .p-sosaiContactInput02__content01 > p {
    margin-bottom: 20px;
    font-size: 1.3rem;
  }
  .p-sosaiContactInput02__content01 .p-sosaiContactInput02__require {
    margin: 0 5px;
  }
  .p-sosaiContactInput02__content02 {
    margin-bottom: 30px;
  }
  .p-sosaiContactInput02__content02 .c-sosaiTitle05 {
    font-size: 1.6rem;
  }
  .p-sosaiContactInput02__content03 {
    margin-bottom: 40px;
  }
  .p-sosaiContactInput02__content03__back {
    font-size: 1.4rem;
  }
}
.p-sosaiFormTable01 dl .float li {
  float: left;
}
.p-sosaiFormTable01 dl .float input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.p-sosaiFormTable01 dl .kana input {
  margin: 0 29px 0 7px;
}
.p-sosaiFormTable01 dl .small input {
  padding: 0 15px;
  width: 240px;
}
.p-sosaiFormTable01 dl .wide input {
  width: 600px;
  padding: 0 15px;
}
.p-sosaiFormTable01 dl .wide textarea {
  width: 100%;
  padding: 15px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable01 dl .wide input, .p-sosaiFormTable01 dl .wide textarea,
  .p-sosaiFormTable01 dl .small input,
  .p-sosaiFormTable01 dl .small textarea {
    width: 100%;
    padding: 10px;
  }
  .p-sosaiFormTable01 dl .float li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }
  .p-sosaiFormTable01 dl .float input {
    width: 94%;
    padding: 10px;
    margin: 0 0 0 10px;
  }
  .p-sosaiFormTable01 dl input {
    height: 38px;
    line-height: 38px;
  }
}
.p-sosaiFormTable01 .c-table2__radio input:checked + label:before {
  background: #af8ebe;
  border: 1px solid #af8ebe !important;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable01 .c-table2__radio li {
    margin-right: 0;
  }
}

.p-sosaiFormTable02 dl .float li {
  float: left;
}
.p-sosaiFormTable02 dl .float input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.p-sosaiFormTable02 dl .kana input {
  margin: 0 29px 0 7px;
}
.p-sosaiFormTable02 dl .small input {
  padding: 0 15px;
  width: 240px;
}
.p-sosaiFormTable02 dl .document .c-table2__checkbox01 {
  *zoom: 1;
}
.p-sosaiFormTable02 dl .document .c-table2__checkbox01:after {
  display: block;
  content: "";
  clear: both;
}
.p-sosaiFormTable02 dl .wide input {
  width: 600px;
  padding: 0 15px;
}
.p-sosaiFormTable02 dl .wide textarea {
  width: 100%;
  padding: 15px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable02 dl .wide input, .p-sosaiFormTable02 dl .wide textarea,
  .p-sosaiFormTable02 dl .small input,
  .p-sosaiFormTable02 dl .small textarea {
    width: 100%;
    padding: 10px;
  }
  .p-sosaiFormTable02 dl .float li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }
  .p-sosaiFormTable02 dl .float input {
    width: 94%;
    padding: 10px;
    margin: 0 0 0 10px;
  }
  .p-sosaiFormTable02 dl input {
    height: 38px;
    line-height: 38px;
  }
}
.p-sosaiFormTable02 .c-table2__radio input:checked + label:before {
  background: #af8ebe;
  border: 1px solid #af8ebe !important;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable02 .c-table2__radio li {
    margin-right: 0;
  }
}

.p-sosaiFormTable03 dl .float li {
  float: left;
}
.p-sosaiFormTable03 dl .float input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.p-sosaiFormTable03 dl .kana input {
  margin: 0 29px 0 7px;
}
.p-sosaiFormTable03 dl .small input {
  padding: 0 15px;
  width: 240px;
}
.p-sosaiFormTable03 dl .wide input {
  width: 600px;
  padding: 0 15px;
}
.p-sosaiFormTable03 dl .wide textarea {
  width: 100%;
  padding: 15px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable03 dl .wide input, .p-sosaiFormTable03 dl .wide textarea,
  .p-sosaiFormTable03 dl .small input,
  .p-sosaiFormTable03 dl .small textarea {
    width: 100%;
    padding: 10px;
  }
  .p-sosaiFormTable03 dl .float li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }
  .p-sosaiFormTable03 dl .float input {
    width: 94%;
    padding: 10px;
    margin: 0 0 0 10px;
  }
  .p-sosaiFormTable03 dl input {
    height: 38px;
    line-height: 38px;
  }
}
.p-sosaiFormTable03 .c-table2__radio input:checked + label:before {
  background: #af8ebe;
  border: 1px solid #af8ebe !important;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable03 .c-table2__radio li {
    margin-right: 0;
  }
}
.p-sosaiFormTable03 .c-table2__radio .c-table2__checkbox01 li {
  margin-right: 21px;
  margin-bottom: 0;
}
.p-sosaiFormTable03 .c-table2__radio .c-table2__checkbox01 .c-checkbox input[type=checkbox] + label:after {
  border-radius: inherit;
  background: none;
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　資料請求・お問い合わせフォーム（神奈川/山梨/静岡）　確認
------------------------------------------------------------*/
.p-sosaiContactConfirm {
  padding: 50px 0 40px 0;
  background-color: #ffffff;
}

.p-sosaiContactConfirm01 {
  margin-bottom: 30px;
}
.p-sosaiContactConfirm01 .c-formTitle {
  color: #2f2933;
}

.p-sosaiContactConfirm02 {
  margin-bottom: 30px;
}
.p-sosaiContactConfirm02__content01 {
  margin-bottom: 50px;
  color: #46483c;
}
.p-sosaiContactConfirm02__content01 .c-cartBtn {
  margin-bottom: 45px;
}
.p-sosaiContactConfirm02__content02 {
  margin-bottom: 80px;
}
.p-sosaiContactConfirm02__content02__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContactConfirm02__content02__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContactConfirm02__content02__back:before {
  width: 7px;
  height: 14px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-sosaiContactConfirm {
    padding: 30px 0 40px 0;
    background-color: #ffffff;
  }
  .p-sosaiContactConfirm01 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-sosaiContactConfirm01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContactConfirm02 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-sosaiContactConfirm02__content01 {
    margin-bottom: 30px;
  }
  .p-sosaiContactConfirm02__content01 .c-cartBtn {
    margin-bottom: 20px;
  }
  .p-sosaiContactConfirm02__content02 {
    margin-bottom: 40px;
  }
  .p-sosaiContactConfirm02__content02__back {
    font-size: 1.4rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　資料請求・お問い合わせフォーム（神奈川/山梨/静岡）　完了
------------------------------------------------------------*/
.p-sosaiContactComplete {
  background-color: #ffffff;
}

.p-sosaiContactComplete01 {
  margin: 30px 0;
}
.p-sosaiContactComplete01 .c-formTitle {
  color: #2f2933;
}

.p-sosaiContactComplete02 {
  margin-bottom: 80px;
}
.p-sosaiContactComplete02 .c-formComplete {
  color: #111111;
}
.p-sosaiContactComplete02 .c-formComplete__inquiry__tel {
  color: #333745;
}
.p-sosaiContactComplete02 .c-formComplete__inquiry__tel span {
  color: #333745;
}
.p-sosaiContactComplete02 .c-formComplete__inquiry__tel span:before {
  background-image: url("../img/common/icon_tel04.png");
}
.p-sosaiContactComplete02 .c-formComplete__inquiry__tel p {
  font-size: 1.6rem;
  color: #111111;
}

@media screen and (max-width: 640px) {
  .p-sosaiContactComplete {
    padding: 0 20px;
  }
  .p-sosaiContactComplete01 {
    margin: 30px 0;
  }
  .p-sosaiContactComplete01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContactComplete02 {
    margin-bottom: 40px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　お葬式勉強会フォーム（神奈川・山梨）
------------------------------------------------------------*/
.p-sosaiContactSeminar {
  padding: 50px 0 135px 0;
  background-color: #ffffff;
}

.p-sosaiContactSeminar01 {
  margin-bottom: 30px;
}
.p-sosaiContactSeminar01 .c-formTitle {
  color: #2f2933;
}

.p-sosaiContactSeminar02 {
  margin-bottom: 50px;
}
.p-sosaiContactSeminar02 p {
  font-size: 1.6rem;
  line-height: 1.75;
  color: #111111;
}
.p-sosaiContactSeminar02 p a {
  text-decoration: underline;
  color: #46483c;
}

.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 {
  margin-bottom: 50px;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dt {
  margin-bottom: 13px;
  font-size: 2rem;
  font-weight: bold;
  color: #111111;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dt:before {
  width: 8px;
  height: 8px;
  margin: -3px 10px 0 0;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dd {
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #111111;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dd a {
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dd a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dd p {
  width: 800px;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content02__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content02__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content02__back:before {
  width: 7px;
  height: 14px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-sosaiContactSeminar {
    padding: 30px 20px 80px 20px;
  }
  .p-sosaiContactSeminar01 {
    margin-bottom: 20px;
  }
  .p-sosaiContactSeminar01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContactSeminar02 {
    margin-bottom: 30px;
  }
  .p-sosaiContactSeminar02 p {
    font-size: 1.4rem;
  }
  .p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 {
    margin-bottom: 30px;
  }
  .p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 .c-sosaiTitle05 {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
  .p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dt {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
  .p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dd {
    margin-bottom: 15px;
    font-size: 1.4rem;
  }
  .p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dd p {
    width: 100%;
    margin-bottom: 10px;
  }
  .p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content01 dl dd img {
    margin: 0 auto;
    display: block;
  }
  .p-sosaiContactSeminar03 .p-sosaiContactSeminar03__content02__back {
    font-size: 1.6rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　お葬式勉強会フォーム（神奈川・山梨）　入力・エラー
------------------------------------------------------------*/
.p-sosaiContactSeminarInput {
  padding: 50px 0 100px 0;
  background-color: #ffffff;
}

.p-sosaiContactSeminarInput01 {
  margin-bottom: 30px;
}
.p-sosaiContactSeminarInput01 .c-formTitle {
  color: #2f2933;
}

.p-sosaiContactSeminarInput02 {
  margin-bottom: 80px;
}
.p-sosaiContactSeminarInput02__require {
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background: #f22727;
  padding: 0 5px;
  line-height: 1.6;
  border-radius: 3px;
}
@media screen and (max-width: 640px) {
  .p-sosaiContactSeminarInput02__require {
    margin-left: 10px;
  }
}
.p-sosaiContactSeminarInput02__title {
  font-weight: bold;
  color: #111111;
  margin-top: 15px;
}
.p-sosaiContactSeminarInput02__title:first-child {
  margin-top: 0px;
}
.p-sosaiContactSeminarInput02__title .c-table2--note1 {
  font-weight: normal;
}
@media screen and (max-width: 640px) {
  .p-sosaiContactSeminarInput02__title .c-table2--note1 {
    font-weight: bold;
  }
}
.p-sosaiContactSeminarInput02__content01 {
  margin-bottom: 50px;
}
.p-sosaiContactSeminarInput02__content01__txt {
  margin-bottom: 40px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-sosaiContactSeminarInput02__content01__txt2 {
  margin-bottom: 15px;
  font-size: 1.6rem;
  color: #46483c;
}
.p-sosaiContactSeminarInput02__content02 {
  margin-bottom: 50px;
}
.p-sosaiContactSeminarInput02__content03__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContactSeminarInput02__content03__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContactSeminarInput02__content03__back:before {
  width: 7px;
  height: 14px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}
.p-sosaiContactSeminarInput02 .p-sosaiFormTable04 dt {
  width: 330px;
}
.p-sosaiContactSeminarInput02 .p-sosaiFormTable04 dd {
  width: calc(100% - 330px);
}

@media screen and (max-width: 640px) {
  .p-sosaiContactSeminarInput {
    padding: 30px 20px 80px 20px;
  }
  .p-sosaiContactSeminarInput01 {
    margin-bottom: 20px;
  }
  .p-sosaiContactSeminarInput01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContactSeminarInput02 {
    margin-bottom: 40px;
  }
  .p-sosaiContactSeminarInput02__title {
    margin-bottom: 10px;
  }
  .p-sosaiContactSeminarInput02__content01 {
    margin-bottom: 30px;
  }
  .p-sosaiContactSeminarInput02__content01 > p {
    margin-bottom: 20px;
    font-size: 1.3rem;
  }
  .p-sosaiContactSeminarInput02__content01 .p-sosaiContactSeminarInput02__require {
    margin: 0 5px;
  }
  .p-sosaiContactSeminarInput02__content02 {
    margin-bottom: 30px;
  }
  .p-sosaiContactSeminarInput02__content02 .c-sosaiTitle05 {
    font-size: 1.6rem;
  }
  .p-sosaiContactSeminarInput02__content03 {
    margin-bottom: 40px;
  }
  .p-sosaiContactSeminarInput02__content03__back {
    font-size: 1.4rem;
  }
}
.p-sosaiFormTable04 dl .float li {
  float: left;
}
.p-sosaiFormTable04 dl .float input {
  width: 240px;
  padding: 0 15px;
  margin: 0 28px 0 23px;
}
.p-sosaiFormTable04 dl .kana input {
  margin: 0 29px 0 7px;
}
.p-sosaiFormTable04 dl .small input {
  padding: 0 15px;
  width: 240px;
}
.p-sosaiFormTable04 dl .select select {
  width: 60px;
  height: 38px;
  margin: 0 10px;
  padding: 5px 10px;
  border: solid 1px #cccccc;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.p-sosaiFormTable04 dl .select select option {
  font-size: 1.6rem;
  line-height: 2;
}
.p-sosaiFormTable04 dl .wide input {
  width: 600px;
  padding: 0 15px;
}
.p-sosaiFormTable04 dl .wide textarea {
  width: 100%;
  padding: 15px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable04 dl .wide input, .p-sosaiFormTable04 dl .wide textarea,
  .p-sosaiFormTable04 dl .small input,
  .p-sosaiFormTable04 dl .small textarea {
    width: 100%;
    padding: 10px;
  }
  .p-sosaiFormTable04 dl .float li {
    float: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }
  .p-sosaiFormTable04 dl .float input {
    width: 94%;
    padding: 10px;
    margin: 0 0 0 10px;
  }
  .p-sosaiFormTable04 dl input {
    height: 38px;
    line-height: 38px;
  }
}
.p-sosaiFormTable04 .c-table2__radio input:checked + label:before {
  background: #af8ebe;
  border: 1px solid #af8ebe !important;
}
@media screen and (max-width: 640px) {
  .p-sosaiFormTable04 .c-table2__radio li {
    margin-right: 0;
  }
}

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　お葬式勉強会フォーム（神奈川・山梨）　確認
------------------------------------------------------------*/
.p-sosaiContactSeminarConfirm {
  padding: 50px 0 40px 0;
  background-color: #ffffff;
}

.p-sosaiContactSeminarConfirm01 {
  margin-bottom: 30px;
}
.p-sosaiContactSeminarConfirm01 .c-formTitle {
  color: #2f2933;
}

.p-sosaiContactSeminarConfirm02 {
  margin-bottom: 30px;
}
.p-sosaiContactSeminarConfirm02__content01 {
  margin-bottom: 50px;
  color: #46483c;
}
.p-sosaiContactSeminarConfirm02__content01 .c-cartBtn {
  margin-bottom: 45px;
}
.p-sosaiContactSeminarConfirm02__content02 {
  margin-bottom: 80px;
}
.p-sosaiContactSeminarConfirm02__content02__back {
  margin-top: 20px;
  font-size: 1.8rem;
  display: block;
  text-align: center;
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiContactSeminarConfirm02__content02__back:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiContactSeminarConfirm02__content02__back:before {
  width: 7px;
  height: 14px;
  margin: -3px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(180deg);
}

@media screen and (max-width: 640px) {
  .p-sosaiContactSeminarConfirm {
    padding: 30px 0 40px 0;
    background-color: #ffffff;
  }
  .p-sosaiContactSeminarConfirm01 {
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .p-sosaiContactSeminarConfirm01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContactSeminarConfirm02 {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .p-sosaiContactSeminarConfirm02__content01 {
    margin-bottom: 30px;
  }
  .p-sosaiContactSeminarConfirm02__content01 .c-cartBtn {
    margin-bottom: 20px;
  }
  .p-sosaiContactSeminarConfirm02__content02 {
    margin-bottom: 40px;
  }
  .p-sosaiContactSeminarConfirm02__content02__back {
    font-size: 1.4rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　お葬式勉強会フォーム（神奈川・山梨）　完了
------------------------------------------------------------*/
.p-sosaiContactSeminarComplete {
  background-color: #ffffff;
}

.p-sosaiContactSeminarComplete01 {
  margin: 30px 0;
}
.p-sosaiContactSeminarComplete01 .c-formTitle {
  color: #2f2933;
}

.p-sosaiContactSeminarComplete02 {
  margin-bottom: 80px;
}
.p-sosaiContactSeminarComplete02 .c-formComplete {
  color: #111111;
}
.p-sosaiContactSeminarComplete02 .c-formComplete__inquiry__tel {
  color: #333745;
}
.p-sosaiContactSeminarComplete02 .c-formComplete__inquiry__tel span {
  color: #333745;
}
.p-sosaiContactSeminarComplete02 .c-formComplete__inquiry__tel span:before {
  background-image: url("../img/common/icon_tel04.png");
}
.p-sosaiContactSeminarComplete02 .c-formComplete__inquiry__tel p {
  font-size: 1.6rem;
  color: #111111;
}

@media screen and (max-width: 640px) {
  .p-sosaiContactSeminarComplete {
    padding: 0 20px;
  }
  .p-sosaiContactSeminarComplete01 {
    margin: 30px 0;
  }
  .p-sosaiContactSeminarComplete01 .c-formTitle {
    margin-bottom: 20px;
  }
  .p-sosaiContactSeminarComplete02 {
    margin-bottom: 40px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　コープの葬祭サービス
------------------------------------------------------------*/
.p-sosaiServiceIndex {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiServiceIndex .c-breadcrumb1 {
  padding: 9px 0 43px;
}

.p-sosaiServiceIndex01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiServiceIndex01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiServiceIndex02 {
  margin-top: -30px;
  padding: 107px 0 120px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiServiceIndex02 .c-sosaiTitle02b {
  margin-bottom: 53px;
}
.p-sosaiServiceIndex02 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiServiceIndex02__content01 {
  margin-top: 53px;
}
.p-sosaiServiceIndex02__content01__box {
  width: 373px;
  height: 384px;
  margin-right: 1px;
  border-top: solid 2px;
  background-color: #ffffff;
  text-align: center;
  position: relative;
}
.p-sosaiServiceIndex02__content01__box:after {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-size: cover;
}
.p-sosaiServiceIndex02__content01__box--yamanashi {
  border-top-color: #006da7;
}
.p-sosaiServiceIndex02__content01__box--yamanashi:after {
  background-image: url(../img/common/icon_button_sankaku02_blue02.png);
}
.p-sosaiServiceIndex02__content01__box--kanagawa {
  border-top-color: #00a384;
}
.p-sosaiServiceIndex02__content01__box--kanagawa:after {
  background-image: url(../img/common/icon_button_sankaku02_blue.png);
}
.p-sosaiServiceIndex02__content01__box--shizuoka {
  border-top-color: #931719;
}
.p-sosaiServiceIndex02__content01__box--shizuoka:after {
  background-image: url(../img/common/icon_button_sankaku02_red.png);
}
.p-sosaiServiceIndex02__content01__box:last-child {
  margin: 0;
}
.p-sosaiServiceIndex02__content01__box__img {
  width: 290px;
  height: 201px;
  margin: 0 auto;
  border-bottom: dotted 1px #4c4c4c;
  position: relative;
}
.p-sosaiServiceIndex02__content01__box__img img {
  position: absolute;
  top: calc(50% + 2px);
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-sosaiServiceIndex02__content01__box h3 {
  margin: 34px 0 31px;
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceIndex02__content01__box h3 span {
  font-size: 3.2rem;
  line-height: 1;
  display: block;
}
.p-sosaiServiceIndex02__content01__box h3 img {
  margin: 8px auto 0;
  display: block;
}
.p-sosaiServiceIndex02__content01__box .c-sosaiBtn06 {
  padding: 4px 0;
}
.p-sosaiServiceIndex02__content01__box .c-sosaiBtn06 a:before {
  margin-right: 2px;
  transform: translateX(-7px);
}

.p-sosaiServiceIndex03 {
  padding: 105px 0 120px 0;
}
.p-sosaiServiceIndex03__txt {
  text-align: center;
}
.p-sosaiServiceIndex03__txt a {
  text-decoration: underline;
  color: #111111;
}
.p-sosaiServiceIndex03__txt a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceIndex03__btn {
  margin-top: 54px;
}
.p-sosaiServiceIndex03__btn a {
  width: 354px;
  margin: 0 auto;
  padding: 18px 0 17px 31px;
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  color: #2e95a3;
  border: 2px solid #2e95a3;
  border-radius: 5px;
  text-align: center;
}
.p-sosaiServiceIndex03__btn a:before {
  background-image: url(../img/sosai/icon_sosai03.png);
  background-size: contain;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 8px -11px -40px;
}

@media screen and (max-width: 640px) {
  .p-sosaiServiceIndex {
    font-size: 1.4rem;
  }
  .p-sosaiServiceIndex01 {
    min-width: 100%;
    padding: 32px 0 33px;
  }
  .p-sosaiServiceIndex02 {
    margin-top: 0;
    padding: 50px 20px;
  }
  .p-sosaiServiceIndex02 p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiServiceIndex02 .c-sosaiTitle02b {
    margin-bottom: 34px;
  }
  .p-sosaiServiceIndex02__content01 {
    margin-top: 30px;
  }
  .p-sosaiServiceIndex02__content01__box {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    padding-bottom: 22px;
  }
  .p-sosaiServiceIndex02__content01__box--yamanashi .p-sosaiServiceIndex02__content01__box__img, .p-sosaiServiceIndex02__content01__box--kanagawa .p-sosaiServiceIndex02__content01__box__img {
    padding: 43px 69px;
  }
  .p-sosaiServiceIndex02__content01__box--shizuoka .p-sosaiServiceIndex02__content01__box__img {
    padding: 10px 106px;
  }
  .p-sosaiServiceIndex02__content01__box__img {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .p-sosaiServiceIndex02__content01__box__img img {
    margin: 0 auto;
    display: block;
    position: static;
    transform: none;
  }
  .p-sosaiServiceIndex02__content01__box h3 {
    margin: 14px 0;
    font-size: 1.5rem;
  }
  .p-sosaiServiceIndex02__content01__box h3 span {
    font-size: 2.3rem;
    margin-top: 2px;
  }
  .p-sosaiServiceIndex02__content01__box h3 img {
    margin: 5px auto 0;
  }
  .p-sosaiServiceIndex02__content01__box .c-sosaiBtn06 {
    max-width: 85%;
  }
  .p-sosaiServiceIndex02__content01__box .c-sosaiBtn06 a {
    padding: 11px 15px;
  }
  .p-sosaiServiceIndex03 {
    padding: 50px 20px 60px;
  }
  .p-sosaiServiceIndex03__txt {
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiServiceIndex03__btn {
    margin-top: 24px;
  }
  .p-sosaiServiceIndex03__btn a {
    width: 100%;
    padding: 12px 0 10px 31px;
    font-size: 1.5rem;
    border: 1px solid #2e95a3;
  }
  .p-sosaiServiceIndex03__btn a:before {
    width: 20px;
    height: 20px;
    margin: 0 5px -4px -36px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭 神奈川県にお住まいの方
------------------------------------------------------------*/
.p-sosaiServiceKanagawa {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.77;
  color: #111111;
}

.p-sosaiServiceKanagawa01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiServiceKanagawa01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiServiceKanagawa02 {
  margin: 18px 0 120px;
}
.p-sosaiServiceKanagawa02__txt01 {
  margin-bottom: 57px;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
.p-sosaiServiceKanagawa02__txt02__img {
  width: 540px;
  margin: 9px 0 0;
}
.p-sosaiServiceKanagawa02__txt02__txt {
  width: 540px;
  font-size: 1.7rem;
}

.p-sosaiServiceKanagawa03 {
  margin-bottom: 105px;
  padding: 106px 0 90px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiServiceKanagawa03__listBox {
  padding-top: 53px;
}
.p-sosaiServiceKanagawa03__listBox li {
  width: 353px;
  margin: 0 30px 70px 0;
  padding: 34px 40px;
  background-color: #ffffff;
  border: solid 2px #2e95a3;
}
.p-sosaiServiceKanagawa03__listBox li:nth-child(3n) {
  margin: 0 0 70px 0;
}
.p-sosaiServiceKanagawa03__listBox li span {
  margin-top: -83px;
  margin-bottom: 28px;
  display: block;
  text-align: center;
}
.p-sosaiServiceKanagawa03__listBox li .listBox-txt01 {
  color: #197e7e;
  font-size: 2.6rem;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 27px;
  padding: 0 30px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceKanagawa03__listBox li .listBox-txt02 {
  text-align: left;
  line-height: 1.7;
}
.p-sosaiServiceKanagawa03__listBox li .listBox-txt02 img {
  width: 100%;
  max-width: 470px;
  margin: 0 auto 10px;
  display: block;
}
.p-sosaiServiceKanagawa03__listBox li .listBox-txt02 b {
  font-weight: bold;
}
.p-sosaiServiceKanagawa03 .c-list03 {
  margin: 16px auto 0;
}
.p-sosaiServiceKanagawa03 .c-list03__lable {
  white-space: nowrap;
}

.p-sosaiServiceKanagawa04 {
  margin-bottom: 120px;
}
.p-sosaiServiceKanagawa04 .c-sosaiTitle02b {
  margin-bottom: 48px;
}
.p-sosaiServiceKanagawa04__newsList li {
  padding: 22px 0 18px;
  border-bottom: solid 1px #dddddd;
}
.p-sosaiServiceKanagawa04__newsList li:last-child {
  border: none;
}
.p-sosaiServiceKanagawa04__newsList li a {
  width: 835px;
  color: #46483c;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
}
.p-sosaiServiceKanagawa04__newsList li a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceKanagawa04__newsList_data {
  width: 115px;
  font-size: 1.7rem;
  color: #666666;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceKanagawa04__newsList .c-newsCate02 {
  width: 60px;
  margin: 4px 5px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiServiceKanagawa04__newsList .c-newsCate03 {
  width: 70px;
  margin: 4px 30px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiServiceKanagawa04 .c-sosaiBtn01 {
  width: 175px;
  margin-top: 25px;
  font-weight: bold;
}

.p-sosaiServiceKanagawa05 {
  padding: 104px 0 140px;
  background-color: #faf9f6;
}
.p-sosaiServiceKanagawa05__txt01 {
  line-height: 1.9;
  margin-bottom: 91px;
  text-align: center;
}
.p-sosaiServiceKanagawa05__txt01 b {
  font-weight: bold;
}
.p-sosaiServiceKanagawa05__txt01 a {
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiServiceKanagawa05__txt01 a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceKanagawa05__infomation {
  margin-bottom: 30px;
}
.p-sosaiServiceKanagawa05__infomation__schedule {
  width: 545px;
  background-color: #ffffff;
}
.p-sosaiServiceKanagawa05__infomation__schedule h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceKanagawa05__infomation__report {
  width: 545px;
  background-color: #ffffff;
}
.p-sosaiServiceKanagawa05__infomation__report h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceKanagawa05__infomation__data {
  width: 105px;
  height: 20px;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.17;
  color: #666666;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceKanagawa05__infomation .c-newsCate02, .p-sosaiServiceKanagawa05__infomation .c-newsCate03 {
  width: auto;
  padding: 0 11px;
  line-height: 20px;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-sosaiServiceKanagawa05__infomation .c-newsCate02, .p-sosaiServiceKanagawa05__infomation .c-newsCate03 {
    height: 15px;
    font-size: 1rem;
    line-height: 15px;
    padding: 0 5px;
  }
}
.p-sosaiServiceKanagawa05__infomation a {
  font-size: 1.7rem;
  color: #666666;
  text-decoration: underline;
}
.p-sosaiServiceKanagawa05__infomation a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceKanagawa05__infomation ul li {
  padding: 30px 30px 23px;
  border-bottom: dotted 1px #4c4c4c;
}
.p-sosaiServiceKanagawa05__infomation ul li > div {
  margin-bottom: 16px;
}
@media screen and (max-width: 640px) {
  .p-sosaiServiceKanagawa05__infomation ul li > div {
    margin-bottom: 0;
  }
}
.p-sosaiServiceKanagawa05__btn {
  margin: 0 auto;
  display: table;
}
.p-sosaiServiceKanagawa05__btn__grbtn01 {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
.p-sosaiServiceKanagawa05__btn__fb {
  margin-right: 19px;
}
.p-sosaiServiceKanagawa05__btn__fb a {
  width: 355px;
  color: #4267b2;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 10px 10px 12px 29px;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  border: 2px solid #4267b2;
  background: #fff;
}
.p-sosaiServiceKanagawa05__btn__fb a img {
  float: left;
  margin-right: 16px;
}
.p-sosaiServiceKanagawa05__btn__fb a p {
  line-height: 1.4;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
.p-sosaiServiceKanagawa05__btn__fb a span {
  font-size: 1.2rem;
  font-weight: normal;
}
.p-sosaiServiceKanagawa05__btn__btn02 a {
  width: 355px;
  height: 70px;
  padding: 19px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #faad5a;
  font-size: 1.9rem;
  font-weight: bold;
  background: #fff;
  border-radius: 3px;
  border: 2px solid #faad5a;
}
.p-sosaiServiceKanagawa05__btn__btn02 a span {
  font-size: 1.2rem;
}
.p-sosaiServiceKanagawa05__btn .c-sosaiBtn05 {
  margin-right: 20px;
  text-align: center;
  float: left;
  padding: 25px 18px;
}
.p-sosaiServiceKanagawa05__btn .c-sosaiBtn03 {
  text-align: center;
  float: right;
}

@media screen and (max-width: 640px) {
  .p-sosaiServiceKanagawa01 {
    min-width: 100%;
    margin-bottom: 33px;
    padding: 32px 0 33px;
  }
  .p-sosaiServiceKanagawa02 {
    margin-bottom: 45px;
    padding: 0 20px;
  }
  .p-sosaiServiceKanagawa02 .c-sosaiTitle02b {
    margin-bottom: 50px;
  }
  .p-sosaiServiceKanagawa02__txt01 {
    margin-bottom: 27px;
    font-size: 1.4rem;
    text-align: left;
  }
  .p-sosaiServiceKanagawa02__txt02__img {
    width: 100%;
    margin-bottom: 27px;
  }
  .p-sosaiServiceKanagawa02__txt02__txt {
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiServiceKanagawa03 {
    margin-bottom: 32px;
    padding: 46px 0 50px;
  }
  .p-sosaiServiceKanagawa03__listBox {
    margin-bottom: 39px;
    padding: 20px 20px 0;
  }
  .p-sosaiServiceKanagawa03__listBox li {
    width: 100%;
    height: auto;
    margin-bottom: 39px;
    padding: 13px 20px;
    font-size: 1.3rem;
  }
  .p-sosaiServiceKanagawa03__listBox li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiServiceKanagawa03__listBox li span {
    margin-top: -45px;
    margin-bottom: 15px;
    display: block;
    text-align: center;
  }
  .p-sosaiServiceKanagawa03__listBox li span img {
    width: 60px;
  }
  .p-sosaiServiceKanagawa03__listBox li .listBox-txt01 {
    font-size: 2.1rem;
    margin-bottom: 14px;
  }
  .p-sosaiServiceKanagawa03__listBox li .listBox-txt02 {
    line-height: 1.6;
    font-size: 1.4rem;
  }
  .p-sosaiServiceKanagawa03__listBox li .listBox-txt02 img {
    margin: 0 auto;
  }
  .p-sosaiServiceKanagawa03 .c-list03 {
    padding: 0 15px;
  }
  .p-sosaiServiceKanagawa03 .c-list03__lable {
    white-space: normal;
  }
  .p-sosaiServiceKanagawa04 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiServiceKanagawa04 .c-sosaiTitle02b {
    margin-bottom: 22px;
  }
  .p-sosaiServiceKanagawa04__newsList li {
    padding: 15px 0;
  }
  .p-sosaiServiceKanagawa04__newsList li a {
    width: 100%;
    font-size: 1.4rem;
    padding: 4px 0 0;
  }
  .p-sosaiServiceKanagawa04__newsList li p {
    font-size: 1.4rem;
    padding: 4px 0 0;
  }
  .p-sosaiServiceKanagawa04__newsList_data {
    width: auto;
    margin: 0 7px 0 0;
    font-size: 1.3rem;
  }
  .p-sosaiServiceKanagawa04__newsList .c-newsCate02 {
    width: 40px;
    height: 15px;
    margin: 2px 5px 2px 0;
    font-size: 1rem;
  }
  .p-sosaiServiceKanagawa04__newsList .c-newsCate03 {
    width: 50px;
    height: 15px;
    margin: 2px 0;
    font-size: 1rem;
  }
  .p-sosaiServiceKanagawa04 .c-sosaiBtn01 {
    width: 50%;
    margin-top: 13px;
    line-height: 3;
    font-size: 1.3rem;
  }
  .p-sosaiServiceKanagawa05 {
    padding: 48px 15px 50px;
  }
  .p-sosaiServiceKanagawa05__txt01 {
    margin-bottom: 34px;
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 0 5px;
  }
  .p-sosaiServiceKanagawa05__infomation {
    margin-bottom: 17px;
  }
  .p-sosaiServiceKanagawa05__infomation__schedule {
    width: 100%;
    margin-bottom: 27px;
  }
  .p-sosaiServiceKanagawa05__infomation__schedule h2 {
    font-size: 1.6rem;
    padding: 6px;
  }
  .p-sosaiServiceKanagawa05__infomation__report {
    width: 100%;
  }
  .p-sosaiServiceKanagawa05__infomation__report h2 {
    font-size: 1.6rem;
    padding: 6px;
  }
  .p-sosaiServiceKanagawa05__infomation__data {
    width: auto;
    margin-right: 10px;
    font-size: 1.3rem;
  }
  .p-sosaiServiceKanagawa05__infomation a {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiServiceKanagawa05__infomation p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiServiceKanagawa05__infomation ul li {
    padding: 15px;
    line-height: 1;
  }
  .p-sosaiServiceKanagawa05__btn {
    display: block;
  }
  .p-sosaiServiceKanagawa05__btn__grbtn01 {
    margin-bottom: 30px;
    display: block;
  }
  .p-sosaiServiceKanagawa05__btn__fb {
    width: 100%;
    margin: 0 auto 10px auto;
  }
  .p-sosaiServiceKanagawa05__btn__fb a {
    width: 100%;
    font-size: 1.3rem;
    justify-content: center;
    padding: 6px 10px 5px 19px;
  }
  .p-sosaiServiceKanagawa05__btn__fb a img {
    width: 25px;
  }
  .p-sosaiServiceKanagawa05__btn__fb a span {
    font-size: 1rem;
  }
  .p-sosaiServiceKanagawa05__btn__btn02 a {
    width: 100%;
    height: auto;
    color: #faad5a;
    font-size: 1.3rem;
    padding: 12px 5px;
  }
  .p-sosaiServiceKanagawa05__btn__btn02 a span {
    font-size: 1rem;
  }
  .p-sosaiServiceKanagawa05__btn .c-sosaiBtn05 {
    font-size: 1.4rem;
    margin: 0 0 15px 0;
    float: none;
    max-width: 100%;
  }
  .p-sosaiServiceKanagawa05__btn .c-sosaiBtn03 {
    float: none;
    font-size: 1.4rem;
    max-width: 100%;
  }
  .p-sosaiServiceKanagawa05__btn .c-sosaiBtn03 a {
    padding: 20px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
ゆきげ葬（やまなし）
------------------------------------------------------------*/
.p-sosaiServiceYamanashi {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.77;
  color: #111111;
}

.p-sosaiServiceYamanashi01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiServiceYamanashi01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiServiceYamanashi02 {
  margin: 18px 0 120px;
}
.p-sosaiServiceYamanashi02__txt01 {
  margin-bottom: 57px;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
.p-sosaiServiceYamanashi02__txt02__img {
  width: 540px;
  margin: 9px 0 0;
}
.p-sosaiServiceYamanashi02__txt02__txt {
  width: 540px;
  font-size: 1.7rem;
}

.p-sosaiServiceYamanashi03 {
  margin-bottom: 105px;
  padding: 106px 0 90px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiServiceYamanashi03 ol {
  padding-top: 53px;
}
.p-sosaiServiceYamanashi03 ol li {
  width: 353px;
  margin: 0 30px 70px 0;
  padding: 34px 40px;
  background-color: #ffffff;
  border: solid 2px #2e95a3;
}
.p-sosaiServiceYamanashi03 ol li:nth-child(3n) {
  margin: 0 0 70px 0;
}
.p-sosaiServiceYamanashi03 ol li span {
  margin-top: -83px;
  margin-bottom: 11px;
  display: block;
  text-align: center;
}
.p-sosaiServiceYamanashi03 ol li p {
  text-align: left;
  line-height: 1.7;
}
.p-sosaiServiceYamanashi03 ol li p img {
  width: 100%;
  max-width: 470px;
  margin: 0 auto 10px;
  display: block;
}
.p-sosaiServiceYamanashi03 ol li p b {
  font-weight: bold;
}
.p-sosaiServiceYamanashi03 .c-list03 {
  margin: 16px auto 0;
}
.p-sosaiServiceYamanashi03 .c-list03__lable {
  white-space: nowrap;
}

.p-sosaiServiceYamanashi04 {
  margin-bottom: 120px;
}
.p-sosaiServiceYamanashi04 .c-sosaiTitle02b {
  margin-bottom: 48px;
}
.p-sosaiServiceYamanashi04__newsList li {
  padding: 22px 0 18px;
  border-bottom: solid 1px #dddddd;
}
.p-sosaiServiceYamanashi04__newsList li:last-child {
  border: none;
}
.p-sosaiServiceYamanashi04__newsList li a {
  width: 835px;
  color: #46483c;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
}
.p-sosaiServiceYamanashi04__newsList li a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceYamanashi04__newsList_data {
  width: 115px;
  font-size: 1.7rem;
  color: #666666;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceYamanashi04__newsList .c-newsCate02 {
  width: 60px;
  margin: 4px 5px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiServiceYamanashi04__newsList .c-newsCate03 {
  width: 70px;
  margin: 4px 30px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiServiceYamanashi04 .c-sosaiBtn01 {
  width: 175px;
  margin-top: 25px;
  font-weight: bold;
}

.p-sosaiServiceYamanashi05 {
  padding: 104px 0 140px;
  background-color: #faf9f6;
}
.p-sosaiServiceYamanashi05__txt01 {
  line-height: 1.9;
  margin-bottom: 91px;
  text-align: center;
}
.p-sosaiServiceYamanashi05__txt01 b {
  font-weight: bold;
}
.p-sosaiServiceYamanashi05__txt01 a {
  color: #46483c;
  text-decoration: underline;
}
.p-sosaiServiceYamanashi05__txt01 a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceYamanashi05__infomation {
  margin-bottom: 30px;
}
.p-sosaiServiceYamanashi05__infomation__schedule {
  width: 545px;
  background-color: #ffffff;
}
.p-sosaiServiceYamanashi05__infomation__schedule h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceYamanashi05__infomation__report {
  width: 545px;
  background-color: #ffffff;
}
.p-sosaiServiceYamanashi05__infomation__report h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceYamanashi05__infomation__data {
  width: 105px;
  height: 20px;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.17;
  color: #666666;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceYamanashi05__infomation .c-newsCate02, .p-sosaiServiceYamanashi05__infomation .c-newsCate03 {
  width: auto;
  padding: 0 11px;
  line-height: 20px;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-sosaiServiceYamanashi05__infomation .c-newsCate02, .p-sosaiServiceYamanashi05__infomation .c-newsCate03 {
    height: 15px;
    font-size: 1rem;
    line-height: 15px;
    padding: 0 5px;
  }
}
.p-sosaiServiceYamanashi05__infomation a {
  font-size: 1.7rem;
  color: #666666;
  text-decoration: underline;
}
.p-sosaiServiceYamanashi05__infomation a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceYamanashi05__infomation ul li {
  padding: 30px 30px 23px;
  border-bottom: dotted 1px #4c4c4c;
}
.p-sosaiServiceYamanashi05__infomation ul li > div {
  margin-bottom: 16px;
}
@media screen and (max-width: 640px) {
  .p-sosaiServiceYamanashi05__infomation ul li > div {
    margin-bottom: 0;
  }
}
.p-sosaiServiceYamanashi05__btn {
  margin: 0 auto;
  display: table;
}
.p-sosaiServiceYamanashi05__btn__grbtn01 {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
.p-sosaiServiceYamanashi05__btn__fb {
  float: left;
  margin-right: 19px;
}
.p-sosaiServiceYamanashi05__btn__fb a {
  width: 355px;
  color: #4267b2;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 10px 10px 12px 29px;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  border: 2px solid #4267b2;
  background: #fff;
}
.p-sosaiServiceYamanashi05__btn__fb a img {
  float: left;
  margin-right: 16px;
}
.p-sosaiServiceYamanashi05__btn__fb a p {
  line-height: 1.4;
  font-family: YuGothic, "Yu Gothic", sans-serif;
}
.p-sosaiServiceYamanashi05__btn__fb a span {
  font-size: 1.2rem;
  font-weight: normal;
}
.p-sosaiServiceYamanashi05__btn__btn02 a {
  width: 355px;
  height: 70px;
  padding: 19px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #faad5a;
  font-size: 1.9rem;
  font-weight: bold;
  background: #fff;
  border-radius: 3px;
  border: 2px solid #faad5a;
}
.p-sosaiServiceYamanashi05__btn__btn02 a span {
  font-size: 1.2rem;
}
.p-sosaiServiceYamanashi05__btn .c-sosaiBtn05 {
  margin-right: 20px;
  text-align: center;
  float: left;
  padding: 25px 18px;
}
.p-sosaiServiceYamanashi05__btn .c-sosaiBtn03 {
  text-align: center;
  float: right;
}

@media screen and (max-width: 640px) {
  .p-sosaiServiceYamanashi01 {
    min-width: 100%;
    margin-bottom: 33px;
    padding: 32px 0 33px;
  }
  .p-sosaiServiceYamanashi02 {
    margin-bottom: 45px;
    padding: 0 20px;
  }
  .p-sosaiServiceYamanashi02 .c-sosaiTitle02b {
    margin-bottom: 50px;
  }
  .p-sosaiServiceYamanashi02__txt01 {
    margin-bottom: 27px;
    font-size: 1.4rem;
    text-align: left;
  }
  .p-sosaiServiceYamanashi02__txt02__img {
    width: 100%;
    margin-bottom: 27px;
  }
  .p-sosaiServiceYamanashi02__txt02__txt {
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiServiceYamanashi03 {
    margin-bottom: 32px;
    padding: 46px 0 50px;
  }
  .p-sosaiServiceYamanashi03 ol {
    margin-bottom: 39px;
    padding: 20px 20px 0;
  }
  .p-sosaiServiceYamanashi03 ol li {
    width: 100%;
    height: auto;
    margin-bottom: 39px;
    padding: 13px 20px;
    font-size: 1.3rem;
  }
  .p-sosaiServiceYamanashi03 ol li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiServiceYamanashi03 ol li span {
    margin-top: -45px;
    margin-bottom: 0;
    display: block;
    text-align: center;
  }
  .p-sosaiServiceYamanashi03 ol li span img {
    width: 60px;
  }
  .p-sosaiServiceYamanashi03 ol li p {
    line-height: 1.6;
    font-size: 1.4rem;
  }
  .p-sosaiServiceYamanashi03 ol li p img {
    margin: 0 auto;
  }
  .p-sosaiServiceYamanashi03 .c-list03 {
    padding: 0 15px;
  }
  .p-sosaiServiceYamanashi03 .c-list03__lable {
    white-space: normal;
  }
  .p-sosaiServiceYamanashi04 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiServiceYamanashi04 .c-sosaiTitle02b {
    margin-bottom: 22px;
  }
  .p-sosaiServiceYamanashi04__newsList li {
    padding: 15px 0;
  }
  .p-sosaiServiceYamanashi04__newsList li a {
    width: 100%;
    font-size: 1.4rem;
    padding: 4px 0 0;
  }
  .p-sosaiServiceYamanashi04__newsList li p {
    font-size: 1.4rem;
    padding: 4px 0 0;
  }
  .p-sosaiServiceYamanashi04__newsList_data {
    width: auto;
    margin: 0 7px 0 0;
    font-size: 1.3rem;
  }
  .p-sosaiServiceYamanashi04__newsList .c-newsCate02 {
    width: 40px;
    height: 15px;
    margin: 2px 5px 2px 0;
    font-size: 1rem;
  }
  .p-sosaiServiceYamanashi04__newsList .c-newsCate03 {
    width: 50px;
    height: 15px;
    margin: 2px 0;
    font-size: 1rem;
  }
  .p-sosaiServiceYamanashi04 .c-sosaiBtn01 {
    width: 50%;
    margin-top: 13px;
    line-height: 3;
    font-size: 1.3rem;
  }
  .p-sosaiServiceYamanashi05 {
    padding: 48px 15px 50px;
  }
  .p-sosaiServiceYamanashi05__txt01 {
    margin-bottom: 34px;
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 0 5px;
  }
  .p-sosaiServiceYamanashi05__infomation {
    margin-bottom: 17px;
  }
  .p-sosaiServiceYamanashi05__infomation__schedule {
    width: 100%;
    margin-bottom: 27px;
  }
  .p-sosaiServiceYamanashi05__infomation__schedule h2 {
    font-size: 1.6rem;
    padding: 6px;
  }
  .p-sosaiServiceYamanashi05__infomation__report {
    width: 100%;
  }
  .p-sosaiServiceYamanashi05__infomation__report h2 {
    font-size: 1.6rem;
    padding: 6px;
  }
  .p-sosaiServiceYamanashi05__infomation__data {
    width: auto;
    margin-right: 10px;
    font-size: 1.3rem;
  }
  .p-sosaiServiceYamanashi05__infomation a {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiServiceYamanashi05__infomation p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiServiceYamanashi05__infomation ul li {
    padding: 15px;
    line-height: 1;
  }
  .p-sosaiServiceYamanashi05__btn {
    display: block;
  }
  .p-sosaiServiceYamanashi05__btn__grbtn01 {
    margin-bottom: 30px;
    display: block;
  }
  .p-sosaiServiceYamanashi05__btn__fb {
    width: 100%;
    margin: 0 auto 10px auto;
  }
  .p-sosaiServiceYamanashi05__btn__fb a {
    width: 100%;
    font-size: 1.3rem;
    justify-content: center;
    padding: 6px 10px 5px 19px;
  }
  .p-sosaiServiceYamanashi05__btn__fb a img {
    width: 25px;
  }
  .p-sosaiServiceYamanashi05__btn__fb a span {
    font-size: 1rem;
  }
  .p-sosaiServiceYamanashi05__btn__btn02 a {
    width: 100%;
    height: auto;
    color: #faad5a;
    font-size: 1.3rem;
    padding: 12px 5px;
  }
  .p-sosaiServiceYamanashi05__btn__btn02 a span {
    font-size: 1rem;
  }
  .p-sosaiServiceYamanashi05__btn .c-sosaiBtn05 {
    font-size: 1.4rem;
    margin: 0 0 15px 0;
    float: none;
  }
  .p-sosaiServiceYamanashi05__btn .c-sosaiBtn03 {
    float: none;
    font-size: 1.4rem;
    max-width: 100%;
  }
  .p-sosaiServiceYamanashi05__btn .c-sosaiBtn03 a {
    padding: 20px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭 静岡県にお住まいの方
------------------------------------------------------------*/
.p-sosaiServiceShizuoka {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.77;
  color: #111111;
}

.p-sosaiServiceShizuoka01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiServiceShizuoka01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiServiceShizuoka02 {
  margin: 16px 0 125px;
}
.p-sosaiServiceShizuoka02__txt01 {
  margin-bottom: 51px;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
.p-sosaiServiceShizuoka02__txt02__img {
  width: 400px;
  transform: translateY(5px);
}
.p-sosaiServiceShizuoka02__txt02__txt {
  width: 680px;
}
.p-sosaiServiceShizuoka02__txt02__txt h3 {
  margin-bottom: 23px;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.25;
  color: #2e95a3;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka02__txt02__txt p {
  font-size: 1.7rem;
}

.p-sosaiServiceShizuoka03 {
  margin-bottom: 104px;
  padding: 107px 0 78px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiServiceShizuoka03__listBox {
  padding-top: 55px;
}
.p-sosaiServiceShizuoka03__listBox li {
  width: 353px;
  margin: 0 30px 70px 0;
  padding: 34px 40px;
  background-color: #ffffff;
  border: solid 2px #2e95a3;
}
.p-sosaiServiceShizuoka03__listBox li:nth-child(3n) {
  margin: 0 0 70px 0;
}
.p-sosaiServiceShizuoka03__listBox li span {
  margin-top: -83px;
  margin-bottom: 28px;
  display: block;
  text-align: center;
}
.p-sosaiServiceShizuoka03__listBox li .listBox-txt01 {
  color: #197e7e;
  font-size: 2.6rem;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 27px;
  padding: 0 30px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka03__listBox li .listBox-txt02 {
  text-align: left;
}
.p-sosaiServiceShizuoka03__listBox li .listBox-txt02 img {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  display: block;
}
.p-sosaiServiceShizuoka03__listBox li .listBox-txt02 b {
  font-weight: bold;
}
.p-sosaiServiceShizuoka03__txt {
  margin-bottom: 30px;
}
.p-sosaiServiceShizuoka03__txt:last-of-type {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-sosaiServiceShizuoka03__txt {
    margin-bottom: 15px;
    padding: 0 15px;
  }
}
.p-sosaiServiceShizuoka03 .c-list03 {
  margin: 10px auto;
}
.p-sosaiServiceShizuoka03 .c-list03__lable {
  white-space: nowrap;
}

.p-sosaiServiceShizuoka04 {
  margin-bottom: 120px;
}
.p-sosaiServiceShizuoka04 .c-sosaiTitle02b {
  margin-bottom: 46px;
}
.p-sosaiServiceShizuoka04__newsList li {
  padding: 24px 0 16px;
  border-bottom: solid 1px #dddddd;
}
.p-sosaiServiceShizuoka04__newsList li:last-child {
  border: none;
}
.p-sosaiServiceShizuoka04__newsList li a {
  width: 830px;
  color: #46483c;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
}
.p-sosaiServiceShizuoka04__newsList li a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiServiceShizuoka04__newsList_data {
  width: 120px;
  font-size: 1.7rem;
  color: #666666;
  padding-left: 5px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka04__newsList .c-newsCate02 {
  width: 60px;
  margin: 4px 5px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiServiceShizuoka04__newsList .c-newsCate03 {
  width: 70px;
  margin: 4px 34px 0 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiServiceShizuoka04 .c-sosaiBtn01 {
  width: 175px;
  margin-top: 27px;
  font-weight: bold;
}

.p-sosaiServiceShizuoka05 {
  padding: 103px 0 140px;
  background-color: #faf9f6;
}
.p-sosaiServiceShizuoka05__txt01 {
  margin-bottom: 92px;
  text-align: center;
  line-height: 1.9;
}
.p-sosaiServiceShizuoka05__txt01 b {
  font-weight: bold;
}
.p-sosaiServiceShizuoka05__infomation {
  margin-bottom: 40px;
}
.p-sosaiServiceShizuoka05__infomation__schedule {
  width: 545px;
  background-color: #ffffff;
}
.p-sosaiServiceShizuoka05__infomation__schedule h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka05__infomation__report {
  width: 545px;
  background-color: #ffffff;
}
.p-sosaiServiceShizuoka05__infomation__report h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka05__infomation__data {
  width: 103px;
  height: 20px;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.17;
  color: #666666;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka05__infomation .c-newsCate02, .p-sosaiServiceShizuoka05__infomation .c-newsCate03 {
  width: auto;
  margin-right: 3px;
  line-height: 20px;
}
.p-sosaiServiceShizuoka05__infomation .c-newsCate02--sosaiShizuoka, .p-sosaiServiceShizuoka05__infomation .c-newsCate03--sosaiShizuoka {
  padding: 0 19px;
}
.p-sosaiServiceShizuoka05__infomation .c-newsCate02--sosaiSeminar, .p-sosaiServiceShizuoka05__infomation .c-newsCate02--sosaiReport, .p-sosaiServiceShizuoka05__infomation .c-newsCate03--sosaiSeminar, .p-sosaiServiceShizuoka05__infomation .c-newsCate03--sosaiReport {
  padding: 0 11px;
}
@media screen and (max-width: 640px) {
  .p-sosaiServiceShizuoka05__infomation .c-newsCate02, .p-sosaiServiceShizuoka05__infomation .c-newsCate03 {
    height: 15px;
    font-size: 1rem;
    line-height: 15px;
  }
  .p-sosaiServiceShizuoka05__infomation .c-newsCate02--sosaiShizuoka, .p-sosaiServiceShizuoka05__infomation .c-newsCate03--sosaiShizuoka {
    padding: 0 10px;
  }
  .p-sosaiServiceShizuoka05__infomation .c-newsCate02--sosaiSeminar, .p-sosaiServiceShizuoka05__infomation .c-newsCate02--sosaiReport, .p-sosaiServiceShizuoka05__infomation .c-newsCate03--sosaiSeminar, .p-sosaiServiceShizuoka05__infomation .c-newsCate03--sosaiReport {
    padding: 0 4px;
  }
}
.p-sosaiServiceShizuoka05__infomation a {
  font-size: 1.7rem;
  color: #666666;
  text-decoration: underline;
}
.p-sosaiServiceShizuoka05__infomation a:hover {
  text-decoration: none;
  opacity: 1;
}
.p-sosaiServiceShizuoka05__infomation ul li {
  padding: 30px 32px 23px;
  border-bottom: dotted 1px #4c4c4c;
}
.p-sosaiServiceShizuoka05__infomation ul li > div {
  margin-bottom: 16px;
}
@media screen and (max-width: 640px) {
  .p-sosaiServiceShizuoka05__infomation ul li > div {
    margin-bottom: 0;
  }
}
.p-sosaiServiceShizuoka05__btn {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  border: solid 1px #c8c8c8;
}
.p-sosaiServiceShizuoka05__btn > div {
  width: 50%;
  padding: 23px 33px;
}
.p-sosaiServiceShizuoka05__btn > div:first-child {
  border-right: solid 1px #c8c8c8;
}
.p-sosaiServiceShizuoka05__btn p {
  font-size: 2rem;
  font-weight: bold;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka05__btn__tel {
  font-size: 2rem;
  font-weight: bold;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiServiceShizuoka05__btn__tel em {
  font-size: 3.6rem;
  font-weight: 500;
  line-height: 1;
  font-style: normal;
  letter-spacing: 0.8px;
  color: #2e95a3;
}
.p-sosaiServiceShizuoka05__btn__tel span {
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@media screen and (max-width: 640px) {
  .p-sosaiServiceShizuoka {
    font-size: 1.3rem;
  }
  .p-sosaiServiceShizuoka01 {
    min-width: 100%;
    margin-bottom: 34px;
    padding: 32px 0 33px;
  }
  .p-sosaiServiceShizuoka02 {
    margin-bottom: 47px;
    padding: 0 20px;
  }
  .p-sosaiServiceShizuoka02 .c-sosaiTitle02b {
    margin-bottom: 39px;
  }
  .p-sosaiServiceShizuoka02__txt01 {
    margin-bottom: 19px;
    font-size: 1.4rem;
  }
  .p-sosaiServiceShizuoka02__txt02__img {
    width: 100%;
    margin-bottom: 29px;
  }
  .p-sosaiServiceShizuoka02__txt02__txt {
    width: 100%;
  }
  .p-sosaiServiceShizuoka02__txt02__txt h3 {
    margin-bottom: 18px;
    font-size: 2.3rem;
    line-height: 1.1;
    text-align: center;
  }
  .p-sosaiServiceShizuoka02__txt02__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiServiceShizuoka03 {
    margin-bottom: 20px;
    padding: 48px 0 50px;
  }
  .p-sosaiServiceShizuoka03 .c-sosaiTitle02b {
    margin-bottom: 48px;
    padding: 0 20px;
  }
  .p-sosaiServiceShizuoka03__listBox {
    padding: 20px 20px 50px;
  }
  .p-sosaiServiceShizuoka03__listBox li {
    width: 100%;
    height: auto;
    margin-bottom: 39px;
    padding: 15px 18px;
    line-height: 1.6;
  }
  .p-sosaiServiceShizuoka03__listBox li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiServiceShizuoka03__listBox li span {
    margin-top: -45px;
    margin-bottom: 15px;
    display: block;
    text-align: center;
  }
  .p-sosaiServiceShizuoka03__listBox li span img {
    width: 60px;
  }
  .p-sosaiServiceShizuoka03__listBox li .listBox-txt01 {
    font-size: 2.1rem;
    margin-bottom: 14px;
  }
  .p-sosaiServiceShizuoka03__listBox li .listBox-txt02 {
    font-size: 1.4rem;
  }
  .p-sosaiServiceShizuoka03 .c-list03 {
    padding: 0 15px;
  }
  .p-sosaiServiceShizuoka03 .c-list03__lable {
    white-space: normal;
  }
  .p-sosaiServiceShizuoka04 {
    margin-bottom: 49px;
    padding: 0 20px;
  }
  .p-sosaiServiceShizuoka04 .c-sosaiTitle02b {
    margin-bottom: 23px;
  }
  .p-sosaiServiceShizuoka04__newsList li {
    padding: 15px 0;
  }
  .p-sosaiServiceShizuoka04__newsList li a {
    width: 100%;
    font-size: 1.4rem;
    padding: 4px 0 0;
  }
  .p-sosaiServiceShizuoka04__newsList li p {
    font-size: 1.4rem;
    padding: 4px 0 0;
  }
  .p-sosaiServiceShizuoka04__newsList_data {
    width: auto;
    padding: 0;
    margin: 0 13px 0 0;
    font-size: 1.3rem;
  }
  .p-sosaiServiceShizuoka04__newsList .c-newsCate02 {
    width: 40px;
    height: 15px;
    margin: 2px 3px 2px 0;
    font-size: 1rem;
  }
  .p-sosaiServiceShizuoka04__newsList .c-newsCate03 {
    width: 50px;
    height: 15px;
    margin: 2px 0;
    font-size: 1rem;
  }
  .p-sosaiServiceShizuoka04 .c-sosaiBtn01 {
    width: 50%;
    margin-top: 13px;
    line-height: 3;
    font-size: 1.3rem;
  }
  .p-sosaiServiceShizuoka05 {
    padding: 46px 15px 50px;
  }
  .p-sosaiServiceShizuoka05__txt01 {
    margin-bottom: 34px;
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 0 5px;
  }
  .p-sosaiServiceShizuoka05__infomation {
    margin-bottom: 35px;
  }
  .p-sosaiServiceShizuoka05__infomation__schedule {
    width: 100%;
    margin-bottom: 31px;
  }
  .p-sosaiServiceShizuoka05__infomation__schedule h2 {
    font-size: 1.6rem;
    padding: 6px;
  }
  .p-sosaiServiceShizuoka05__infomation__report {
    width: 100%;
  }
  .p-sosaiServiceShizuoka05__infomation__report h2 {
    font-size: 1.6rem;
    padding: 6px;
  }
  .p-sosaiServiceShizuoka05__infomation__data {
    width: auto;
    margin-right: 14px;
    font-size: 1.3rem;
  }
  .p-sosaiServiceShizuoka05__infomation a {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiServiceShizuoka05__infomation p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiServiceShizuoka05__infomation ul li {
    padding: 17px 19px 12px;
    line-height: 1;
  }
  .p-sosaiServiceShizuoka05__btn > div {
    width: 100%;
    padding: 15px;
  }
  .p-sosaiServiceShizuoka05__btn > div:first-child {
    border: none;
    border-bottom: solid 1px #c8c8c8;
  }
  .p-sosaiServiceShizuoka05__btn p {
    font-size: 1.8rem;
    margin-bottom: 7px;
  }
  .p-sosaiServiceShizuoka05__btn__tel_sp {
    font-weight: bold;
    background-color: #2e95a3;
    text-align: left;
    box-shadow: 0 3px 0 #20656e;
    transition: 0.3s ease-in-out;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .p-sosaiServiceShizuoka05__btn__tel_sp a {
    width: 100%;
    height: 100%;
    padding: 10px 0 15px 30px;
    color: #ffffff;
  }
  .p-sosaiServiceShizuoka05__btn__tel_sp a img {
    width: 26px;
    margin-right: 15px;
    vertical-align: middle;
  }
  .p-sosaiServiceShizuoka05__btn__tel_sp a em {
    font-size: 1.4rem;
    font-style: normal;
    line-height: 1.2;
  }
  .p-sosaiServiceShizuoka05__btn__tel_sp a span {
    font-size: 1.1rem;
    line-height: 1;
    display: block;
  }
  .p-sosaiServiceShizuoka05__btn__tel_sp:hover {
    transform: translate(0, 3px);
    box-shadow: none;
  }
  .p-sosaiServiceShizuoka05__btn .c-sosaiBtn03 {
    display: none;
  }
  .p-sosaiServiceShizuoka05__btn__mail_sp {
    font-weight: bold;
    background-color: #0074d9;
    text-align: left;
    box-shadow: 0 3px 0 #004e91;
    transition: 0.3s ease-in-out;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .p-sosaiServiceShizuoka05__btn__mail_sp a {
    width: 100%;
    height: 100%;
    padding: 19px 19px 10px 26px;
    color: #ffffff;
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .p-sosaiServiceShizuoka05__btn__mail_sp a img {
    width: 26px;
    margin-right: 20px;
    vertical-align: middle;
  }
  .p-sosaiServiceShizuoka05__btn__mail_sp:hover {
    transform: translate(0, 3px);
    box-shadow: none;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　ゆきげ神奈川の料金プラン
------------------------------------------------------------*/
.p-sosaiPrice-kanagawa {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiPrice-kanagawa .c-breadcrumb1 {
  padding: 9px 0 76px;
}

.p-sosaiPrice-kanagawa01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiPrice-kanagawa01 .c-sosaiTitle03 {
  margin: 0;
}
.p-sosaiPrice-kanagawa01 .c-sosaiTitle03 img {
  margin-top: 6px;
}

.p-sosaiPrice-kanagawa02 {
  margin-bottom: 120px;
}
.p-sosaiPrice-kanagawa02 .c-sosaiTitle02b {
  padding-top: 25px;
  margin-bottom: 61px;
  font-weight: normal;
  line-height: 0.9;
}
.p-sosaiPrice-kanagawa02 .c-sosaiTitle02b span {
  font-size: 2rem;
  padding-top: 14px;
}
.p-sosaiPrice-kanagawa02 .c-sosaiTitle02b span:after {
  margin-top: 21px;
}
.p-sosaiPrice-kanagawa02__content01 {
  margin-bottom: 47px;
}
.p-sosaiPrice-kanagawa02__content01 .c-sosaiTitle09 {
  margin-bottom: 22px;
  padding-left: 28px;
  line-height: 1;
}
.p-sosaiPrice-kanagawa02__content01 p {
  font-size: 1.7rem;
  line-height: 3rem;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-kanagawa02__content01 p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-sosaiPrice-kanagawa02__content02 .c-sosaiTitle10 {
  margin-bottom: 21px;
}
.p-sosaiPrice-kanagawa02__content02 .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}
.p-sosaiPrice-kanagawa02__content02__plus {
  align-self: center;
}
.p-sosaiPrice-kanagawa02__content02__box {
  border: solid 1px #c8c8c8;
}
.p-sosaiPrice-kanagawa02__content02__box--01 {
  width: 220px;
}
.p-sosaiPrice-kanagawa02__content02__box--01 dd {
  padding: 94px 17px 74px;
}
.p-sosaiPrice-kanagawa02__content02__box--02 {
  width: 812px;
}
.p-sosaiPrice-kanagawa02__content02__box--02 dd {
  padding: 30px 30px 29px;
}
.p-sosaiPrice-kanagawa02__content02__box__title1 {
  width: 100%;
  display: table;
  border-bottom: dotted 1px #4c4c4c;
  margin: 17px 0 18px;
  padding-bottom: 12px;
}
.p-sosaiPrice-kanagawa02__content02__box__title1 h5 {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.4;
  vertical-align: middle;
  display: table-cell;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-kanagawa02__content02__box__title2 {
  width: 100%;
  height: 83px;
  display: table;
  border-bottom: dotted 1px #4c4c4c;
  padding-top: 3px;
}
.p-sosaiPrice-kanagawa02__content02__box__title2 h5 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  vertical-align: middle;
  display: table-cell;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-kanagawa02__content02__box__title2 span {
  font-size: 1.2rem;
  display: block;
}
.p-sosaiPrice-kanagawa02__content02__box__border {
  border: 1px solid #c8c8c8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-sosaiPrice-kanagawa02__content02__box__border > p {
  width: 100%;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin-bottom: 16px;
}
.p-sosaiPrice-kanagawa02__content02__box__border > div {
  width: 190px;
  margin: 0 auto;
}
.p-sosaiPrice-kanagawa02__content02__box__border:nth-child(1) {
  width: 452px;
  padding: 27px 12px 24px;
}
.p-sosaiPrice-kanagawa02__content02__box__border:nth-child(2) {
  width: 268px;
  padding: 27px 0 44px;
}
.p-sosaiPrice-kanagawa02__content02__box dt {
  width: 100%;
  padding: 14px 5px 9px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
}
.p-sosaiPrice-kanagawa02__content02__box dd > div {
  text-align: center;
}
.p-sosaiPrice-kanagawa02__content02__box dd > div p {
  font-size: 1.4rem;
  line-height: 2rem;
}
.p-sosaiPrice-kanagawa02__content02__box dd > div img {
  width: 160px;
  height: auto;
}

.p-sosaiPrice-kanagawa03 {
  margin-bottom: 120px;
  padding: 107px 0 120px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiPrice-kanagawa03 .u-inner01 > p {
  margin-bottom: 73px;
}
.p-sosaiPrice-kanagawa03 h4.c-sosaiTitle09 {
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-kanagawa03 h4.c-sosaiTitle09 {
    font-size: 1.4rem;
  }
}
.p-sosaiPrice-kanagawa03__content01 {
  margin-bottom: 60px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__box01 {
  margin-bottom: 47px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__box02 {
  padding: 33px 30px 6px 37px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__flow01 {
  margin: 0px auto 68px auto;
  padding-top: 2px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service {
  padding-top: 10px;
  margin-bottom: 0;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li {
  padding: 0 20px 58px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) img {
  margin: -11px auto 15px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) p {
  padding-right: 17px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) img {
  margin: -8px 0 12px -3px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) p {
  padding-right: 10px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(8) img {
  margin: 0 auto 4px -4px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) img {
  margin: -8px auto 12px 6px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) p {
  padding-left: 10px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) img {
  margin: -8px auto 12px 9px;
}
.p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) p {
  padding-left: 16px;
}

.p-sosaiPrice-kanagawaPlanArea {
  padding: 50px 50px 43px;
  background-color: #ffffff;
  font-size: 1.4rem;
  line-height: 1.8;
  border: solid 1px #333745;
}
.p-sosaiPrice-kanagawaPlanArea .c-sosaiTitle11 {
  width: calc(100% + 100px);
  margin-top: -50px;
  margin-right: -50px;
  margin-left: -50px;
  padding: 22px 15px 14px 84px;
}
.p-sosaiPrice-kanagawaPlanArea .c-sosaiTitle10:before {
  margin: -5px 9px 0 -30px;
}
.p-sosaiPrice-kanagawaPlanArea__box01 {
  margin-bottom: 35px;
  padding: 32px 35px 36px 44px;
  font-size: 2rem;
  font-weight: 500;
  color: #333745;
  position: relative;
  z-index: 10;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-kanagawaPlanArea__box01 em {
  color: #f2385a;
}
.p-sosaiPrice-kanagawaPlanArea__box01:before, .p-sosaiPrice-kanagawaPlanArea__box01:after {
  width: calc(100% - 10px);
  height: calc(100% - 14px);
  content: "";
  border: solid 1px rgba(242, 56, 90, 0.4);
  position: absolute;
  z-index: -1;
}
.p-sosaiPrice-kanagawaPlanArea__box01:before {
  top: 0;
  left: 1px;
}
.p-sosaiPrice-kanagawaPlanArea__box01:after {
  top: 10px;
  left: 11px;
}
.p-sosaiPrice-kanagawaPlanArea__box02 {
  margin: 25px auto 17px;
  padding: 33px 30px 9px 37px;
  background-color: #f8f8f8;
  border: solid 1px #c8c8c8;
  flex-wrap: wrap;
}
.p-sosaiPrice-kanagawaPlanArea__box02 p {
  line-height: 3.2rem;
  width: 100%;
}
.p-sosaiPrice-kanagawaPlanArea__annotation {
  font-size: 1.4rem;
  padding-right: 4px;
  margin-top: -4px;
}
.p-sosaiPrice-kanagawaPlanArea__annotation2 {
  font-size: 1.2rem;
}
.p-sosaiPrice-kanagawaPlanArea__price01 {
  margin-bottom: 10px;
}
.p-sosaiPrice-kanagawaPlanArea__price01 li {
  width: 320px;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-kanagawaPlanArea__price01__name {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1;
  color: #333745;
}
.p-sosaiPrice-kanagawaPlanArea__price01__price {
  color: #333745;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}
.p-sosaiPrice-kanagawaPlanArea__price01__price em {
  color: #f2385a;
  font-size: 6.6rem;
  margin-right: 5px;
  line-height: 1;
}
.p-sosaiPrice-kanagawaPlanArea__price01__price span {
  margin: 0 5px;
  font-size: 3rem;
  line-height: 1;
  font-weight: 500;
  display: inline-block;
}
.p-sosaiPrice-kanagawaPlanArea__price01__price--sub {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.3rem;
}
.p-sosaiPrice-kanagawaPlanArea__price01__price--sub .small {
  font-size: 3rem;
}
.p-sosaiPrice-kanagawaPlanArea__price01__img {
  margin-top: 5px;
}
.p-sosaiPrice-kanagawaPlanArea__price01__img img {
  width: 100%;
  margin: 0;
}
.p-sosaiPrice-kanagawaPlanArea__price01__text {
  margin: 10px 0 20px 0;
}
.p-sosaiPrice-kanagawaPlanArea__price01__text p {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  color: #333745;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}
.p-sosaiPrice-kanagawaPlanArea__price02 {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  align-items: baseline;
}
.p-sosaiPrice-kanagawaPlanArea__price02 em {
  font-size: 6.6rem;
  margin-right: 5px;
}
.p-sosaiPrice-kanagawaPlanArea__price02 span {
  margin: 0 5px;
  font-size: 3rem;
  font-weight: 500;
  display: inline-block;
}
.p-sosaiPrice-kanagawaPlanArea__price02--sub {
  display: flex;
  align-items: center;
  font-size: 3.3rem;
}
.p-sosaiPrice-kanagawaPlanArea__price02--sub .small {
  font-size: 3rem;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 {
  margin: -8px auto 66px auto;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt {
  padding: 18px 0 8px;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt {
  padding: 19px 0 7px 4px;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__txt {
  padding: 18px 0 8px 3px;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__icon {
  padding-bottom: 35px;
  padding-left: 5px;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__txt {
  padding: 12px 0 14px 8px;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__icon {
  padding-bottom: 35px;
  padding-left: 5px;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__txt {
  padding: 20px 0 6px 8px;
}
.p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__icon {
  padding-bottom: 33px;
  padding-left: 5px;
}
.p-sosaiPrice-kanagawaPlanArea__service {
  margin-bottom: 10px;
}
.p-sosaiPrice-kanagawaPlanArea__service li {
  width: 20%;
  padding: 0 20px 44px;
  text-align: center;
}
.p-sosaiPrice-kanagawaPlanArea__service li img {
  margin: -2px auto 5px auto;
  display: block;
}
.p-sosaiPrice-kanagawaPlanArea__service li p {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  color: #333745;
}
.p-sosaiPrice-kanagawaPlanArea__service li p span {
  font-size: 1.2rem;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(1) p {
  padding-right: 14px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(2) p {
  padding-right: 4px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(3) img {
  margin-bottom: 17px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(3) p {
  padding-left: 3px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(4) img {
  margin-bottom: 17px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(5) img {
  margin-bottom: 17px;
  margin-left: 12px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(5) p {
  padding-left: 21px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) img {
  margin-left: -3px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) p {
  padding-right: 13px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) img {
  margin-bottom: 7px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) p {
  padding-right: 3px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(8) img {
  margin-bottom: 7px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) img {
  margin-bottom: 2px;
  margin-left: 8px;
  margin-top: -7px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) p {
  padding-left: 10px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) img {
  margin-bottom: 21px;
  margin-left: 12px;
  margin-top: -2px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) p {
  padding-left: 20px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(11) img {
  margin-bottom: -1px;
  margin-left: -7px;
  margin-top: -14px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(11) p {
  padding-right: 14px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(12) img {
  margin-bottom: 10px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(12) p {
  padding-right: 6px;
}
.p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(13) img {
  margin-bottom: -2px;
  margin-top: -4px;
}
.p-sosaiPrice-kanagawaPlanArea__service2 {
  margin-bottom: 15px;
  border: 2px solid rgba(242, 56, 90, 0.4);
  padding: 35px 20px 34px 38px;
}
.p-sosaiPrice-kanagawaPlanArea__service2 ul li:before {
  margin: -3px 15px 0 -16px;
}
.p-sosaiPrice-kanagawaPlanArea__service2 ul.mb30 {
  margin-bottom: 30px;
}
.p-sosaiPrice-kanagawaPlanArea__service2 p {
  margin-bottom: 10px;
}
.p-sosaiPrice-kanagawaPlanArea__service2:nth-of-type(2n) {
  margin-bottom: 30px;
}
.p-sosaiPrice-kanagawaPlanArea__service2.mb58 {
  margin-bottom: 58px;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-kanagawaPlanArea__service2.mb58 {
    margin-bottom: 20px;
  }
}
.p-sosaiPrice-kanagawaPlanArea__note {
  font-size: 1.4rem;
  margin-bottom: 30px;
}
.p-sosaiPrice-kanagawaPlanArea__note.mb45 {
  margin-bottom: 45px;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-kanagawaPlanArea__note.mb45 {
    margin-bottom: 24px;
  }
}
.p-sosaiPrice-kanagawaPlanArea__marker {
  background: #f8f8f8;
  display: inline-block;
  padding: 10px 20px;
}
.p-sosaiPrice-kanagawaPlanArea__text {
  line-height: 2rem;
  margin-bottom: 10px;
}
.p-sosaiPrice-kanagawaPlanArea__List01 li {
  margin-right: 2em;
  padding-left: 16px;
  font-weight: 700;
}
.p-sosaiPrice-kanagawaPlanArea__List01 li:before {
  width: 6px;
  height: 6px;
  margin: -3px 10px 0 -16px;
  content: "";
  background-color: #2e95a3;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-sosaiPrice-kanagawaPlanArea__List02 {
  width: 25%;
  margin-top: 19px;
  margin-bottom: 14px;
}
.p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(2) li:first-child {
  margin-bottom: 30px;
}
.p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(2) li:last-child {
  margin-right: -70px;
}
.p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(3) li:before, .p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(4) li:before {
  margin: -3px 13px 0 -22px;
}
.p-sosaiPrice-kanagawaPlanArea__List02 li {
  margin-bottom: 15px;
  padding: 0 5px 0 21px;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.p-sosaiPrice-kanagawaPlanArea__List02 li:before {
  width: 6px;
  height: 6px;
  margin: -3px 14px 0 -19px;
  content: "";
  background-color: #2e95a3;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-sosaiPrice-kanagawaPlanArea__List03 {
  margin-bottom: 20px;
}
.p-sosaiPrice-kanagawaPlanArea__List03 li {
  margin-right: 2em;
  padding-left: 16px;
}
.p-sosaiPrice-kanagawaPlanArea__List03 li:before {
  width: 6px;
  height: 6px;
  margin: -3px 10px 0 -16px;
  content: "";
  background-color: #2e95a3;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-sosaiPrice-kanagawaPlanArea__List03 li:last-of-type {
  margin-bottom: 0;
}

.p-sosaiPrice-kanagawa04 {
  margin-bottom: 128px;
}
.p-sosaiPrice-kanagawa04 .c-sosaiTitle02b {
  padding-top: 5px;
  margin-bottom: 59px;
}
.p-sosaiPrice-kanagawa04__table {
  width: 100%;
  margin-bottom: 60px;
  border-collapse: collapse;
  position: relative;
}
.p-sosaiPrice-kanagawa04__table thead tr {
  position: relative;
}
.p-sosaiPrice-kanagawa04__table thead tr:after {
  width: 100%;
  height: 0;
  content: "";
  border-bottom: dotted 1px #4c4c4c;
  position: absolute;
  bottom: 0;
  left: 0;
}
.p-sosaiPrice-kanagawa04__table thead tr th {
  padding: 19px 20px;
}
.p-sosaiPrice-kanagawa04__table tbody tr {
  position: relative;
}
.p-sosaiPrice-kanagawa04__table tbody tr:after {
  width: 100%;
  height: 0;
  content: "";
  border-bottom: dotted 1px #4c4c4c;
  position: absolute;
  left: 0;
}
.p-sosaiPrice-kanagawa04__table tbody tr th {
  padding: 29px 20px 28px 48px;
  font-weight: 500;
  text-align: left;
  background-color: #ffffff;
}
.p-sosaiPrice-kanagawa04__table tbody tr th:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiPrice-kanagawa04__table tbody tr td {
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
}
.p-sosaiPrice-kanagawa04__table tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}
.p-sosaiPrice-kanagawa04__table tbody tr:nth-child(odd) td {
  background-color: #f3f3f3;
}
.p-sosaiPrice-kanagawa04__table__title {
  width: 36%;
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #333745;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-kanagawa04__table__title--01 {
  position: relative;
}
.p-sosaiPrice-kanagawa04__table__title--01:before {
  width: 10px;
  height: 100%;
  content: "";
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: -5px;
}
.p-sosaiPrice-kanagawa04__table__content {
  width: 35%;
}
.p-sosaiPrice-kanagawa04__table__content--01 {
  position: relative;
}
.p-sosaiPrice-kanagawa04__table__content--01:before {
  width: 10px;
  height: 100%;
  content: "";
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: -5px;
}
.p-sosaiPrice-kanagawa04 .c-sosaiBtn03 {
  width: 498px;
  margin: 0 auto;
  text-align: center;
}
.p-sosaiPrice-kanagawa04 .c-sosaiBtn03 a {
  padding: 23px;
}
.p-sosaiPrice-kanagawa04 .c-sosaiBtn03 a:before {
  margin: -5px 20px 0 0;
}

.p-sosaiPrice-kanagawa05 {
  padding: 106px 0 121px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiPrice-kanagawa05 .c-sosaiTitle02b {
  margin-bottom: 56px;
}
.p-sosaiPrice-kanagawa05 .c-sosaiKanrenLink01 li {
  margin-right: 30px;
}
.p-sosaiPrice-kanagawa05 .c-sosaiKanrenLink01 li:last-child {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .p-sosaiPrice-kanagawa {
    font-size: 1.6rem;
  }
  .p-sosaiPrice-kanagawa01 {
    min-width: 100%;
    margin-bottom: 25px;
    padding: 16px 0 10px;
  }
  .p-sosaiPrice-kanagawa01 .c-sosaiTitle03 {
    line-height: 2.4rem;
  }
  .p-sosaiPrice-kanagawa01 .c-sosaiTitle03 span {
    padding-top: 2px;
  }
  .p-sosaiPrice-kanagawa02 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiPrice-kanagawa02 .c-sosaiTitle02b {
    margin-bottom: 36px;
  }
  .p-sosaiPrice-kanagawa02 .c-sosaiTitle02b span {
    font-size: 1.5rem;
    padding-top: 9px;
  }
  .p-sosaiPrice-kanagawa02 .c-sosaiTitle02b span:after {
    height: 2px;
    margin-top: 16px;
  }
  .p-sosaiPrice-kanagawa02__content01 {
    margin-bottom: 40px;
  }
  .p-sosaiPrice-kanagawa02__content01 .c-sosaiTitle09 {
    margin-left: -5px;
    padding-left: 14px;
    margin-bottom: 18px;
  }
  .p-sosaiPrice-kanagawa02__content02 .c-sosaiTitle10 {
    margin-bottom: 19px;
  }
  .p-sosaiPrice-kanagawa02__content02 .c-sosaiTitle10:before {
    margin: -5px 5px 0 -30px;
  }
  .p-sosaiPrice-kanagawa02__content02__plus {
    margin: 20px auto;
    display: block;
    width: 22px;
  }
  .p-sosaiPrice-kanagawa02__content02__box--01 {
    width: 100%;
  }
  .p-sosaiPrice-kanagawa02__content02__box--01 dd {
    padding: 20px 20px 15px;
  }
  .p-sosaiPrice-kanagawa02__content02__box--02 {
    width: 100%;
  }
  .p-sosaiPrice-kanagawa02__content02__box--02 dd {
    padding: 20px 20px 21px;
  }
  .p-sosaiPrice-kanagawa02__content02__box__title1 {
    padding-bottom: 5px;
    display: block;
    margin: 19px 0 8px;
    letter-spacing: 1px;
  }
  .p-sosaiPrice-kanagawa02__content02__box__title1 h5 {
    font-size: 1.8rem;
    display: inherit;
    line-height: 1.2;
  }
  .p-sosaiPrice-kanagawa02__content02__box__title2 {
    height: auto;
    padding-bottom: 10px;
    display: block;
  }
  .p-sosaiPrice-kanagawa02__content02__box__title2 h5 {
    font-size: 1.8rem;
    display: inherit;
  }
  .p-sosaiPrice-kanagawa02__content02__box__border > p {
    font-size: 1.7rem !important;
    margin-bottom: 29px;
  }
  .p-sosaiPrice-kanagawa02__content02__box__border:nth-child(1) {
    width: 100%;
    padding: 22px 15px 9px;
    margin-bottom: 20px;
  }
  .p-sosaiPrice-kanagawa02__content02__box__border:nth-child(1) > div:last-of-type {
    margin-top: 14px;
  }
  .p-sosaiPrice-kanagawa02__content02__box__border:nth-child(2) {
    width: 100%;
    padding: 22px 15px 10px;
  }
  .p-sosaiPrice-kanagawa02__content02__box dt {
    padding: 6px 5px 3px;
    letter-spacing: 1px;
    font-size: 1.4rem;
    letter-spacing: 0;
  }
  .p-sosaiPrice-kanagawa02__content02__box dd > div {
    width: 100%;
    text-align: center;
  }
  .p-sosaiPrice-kanagawa02__content02__box dd > div img {
    width: 104px;
  }
  .p-sosaiPrice-kanagawa02__content02__box dd > div > div {
    width: 100%;
  }
  .p-sosaiPrice-kanagawa03 {
    margin-bottom: 52px;
    padding: 47px 20px;
  }
  .p-sosaiPrice-kanagawa03 .c-sosaiTitle02b {
    margin-bottom: 26px;
  }
  .p-sosaiPrice-kanagawa03 .u-inner01 > p {
    margin-bottom: 23px;
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiPrice-kanagawa03__content01 {
    margin-bottom: 49px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__box01 {
    margin-bottom: 20px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__flow01 {
    margin: 0px auto 19px auto;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) {
    padding: 15px 21px 13px 18px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt {
    padding-top: 5px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) {
    padding: 24px 21px 13px 18px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt img {
    width: 32px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__icon img {
    width: 28px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service {
    margin-bottom: 0;
    padding-top: 4px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li {
    padding: 0;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(4) {
    width: 33.3333333%;
    padding: 0;
    margin-bottom: 17px;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) p {
    padding-right: 0;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) p {
    padding-right: 0;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(8) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) p {
    padding-left: 0;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) p {
    padding-left: 0;
  }
  .p-sosaiPrice-kanagawa03__content02 .p-sosaiPrice-kanagawaPlanArea__box02 {
    margin: 10px 5px 14px;
    padding: 15px 15px 9px;
  }
  .p-sosaiPrice-kanagawaPlanArea {
    padding: 15px;
  }
  .p-sosaiPrice-kanagawaPlanArea .c-sosaiTitle11 {
    width: calc(100% + 30px);
    margin-top: -15px;
    margin-right: -15px;
    margin-left: -15px;
    padding: 20px 0 19px 62px;
    font-size: 1.9rem;
    line-height: 2.4rem;
    margin-bottom: 24px;
  }
  .p-sosaiPrice-kanagawaPlanArea .c-sosaiTitle11:before {
    width: 40px;
    height: 40px;
    margin: -8px 9px -6px -50px;
  }
  .p-sosaiPrice-kanagawaPlanArea .c-sosaiTitle10 {
    line-height: 2rem;
    margin: 0 auto 10px;
  }
  .p-sosaiPrice-kanagawaPlanArea .c-sosaiTitle10:before {
    margin: -5px 6px 0 -20px;
  }
  .p-sosaiPrice-kanagawaPlanArea__box01 {
    margin-bottom: 52px;
    padding: 22px 30px 22px;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__box01:before {
    width: calc(100% - 15px);
    height: calc(100% - 11px);
    top: 5px;
    left: 5px;
  }
  .p-sosaiPrice-kanagawaPlanArea__box01:after {
    width: calc(100% - 15px);
    height: calc(100% - 11px);
    top: 10px;
    left: 10px;
  }
  .p-sosaiPrice-kanagawaPlanArea__box02 {
    margin: 10px 5px 14px;
    padding: 15px 15px 1px;
  }
  .p-sosaiPrice-kanagawaPlanArea__box02 p {
    line-height: 1.5;
  }
  .p-sosaiPrice-kanagawaPlanArea__annotation {
    font-size: 1.4rem;
    line-height: 1.7;
    margin: 10px -7px 0;
    padding-right: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__annotation2 {
    font-size: 1.1rem;
    line-height: 1.5;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01 {
    margin-bottom: 28px;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01 li {
    width: 100%;
    margin-bottom: 28px;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01 li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01__name {
    font-size: 1.7rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01__price {
    font-size: 1.7rem;
    margin-bottom: 7px;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01__price em {
    font-size: 5rem;
    line-height: 1;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01__price span {
    font-size: 2.3rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01__price--sub {
    font-size: 2.6rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01__price--sub .small {
    font-size: 2.3rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__price01__img {
    padding: 0 5px;
    margin-top: 3px;
  }
  .p-sosaiPrice-kanagawaPlanArea__price02 {
    font-size: 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  .p-sosaiPrice-kanagawaPlanArea__price02 em {
    font-size: 3.6rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__price02 span {
    font-size: 1.9rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__price02--sub {
    font-size: 2.2rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__price02--sub .small {
    font-size: 1.9rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 {
    margin: 0 auto 18px auto;
    padding: 0 5px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) {
    padding: 13px 21px 12px 18px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt img {
    width: 47px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__icon {
    width: 16%;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) {
    padding: 23px 21px 11px 19px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) .c-sosaiPlanFlow__txt img {
    width: 45px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) .c-sosaiPlanFlow__icon img {
    width: 17px;
    margin-top: -8px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) {
    padding: 25px 21px 11px 19px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt img {
    width: 46px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__icon img {
    width: 15px;
    margin-top: -10px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) {
    padding: 15px 21px 13px 21px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__txt img {
    width: 46px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__icon {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__icon img {
    width: 28px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) {
    padding: 22px 21px 9px 20px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__txt img {
    width: 41px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__icon {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__icon img {
    width: 25px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) {
    padding: 15px 21px 14px 20px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__txt img {
    width: 63px;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__icon {
    padding: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__icon img {
    width: 28px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li {
    width: 33.3333333%;
    padding: 0;
    margin-bottom: 17px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(1) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(2) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(3) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(4) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(5) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(8) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(11) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(12) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(13) img {
    padding: 0;
    margin: 0 auto 10px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(1) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(2) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(3) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(4) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(5) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(6) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(8) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(11) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(12) p, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(13) p {
    padding: 0;
    margin: auto;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(7) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(8) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) {
    margin-bottom: 11px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(9) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(11) {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(11) img {
    margin-left: 8px;
    margin-bottom: 4px;
    margin-top: -5px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(10) img, .p-sosaiPrice-kanagawaPlanArea__service li:nth-of-type(12) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__service li p span {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__service2 {
    margin-bottom: 10px;
    padding: 20px 20px 10px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service2 p {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-bottom: 13px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service2 ul {
    display: block;
  }
  .p-sosaiPrice-kanagawaPlanArea__service2 ul li {
    font-size: 1.1rem;
    width: 100%;
    margin-bottom: 6px;
  }
  .p-sosaiPrice-kanagawaPlanArea__service2 ul li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-kanagawaPlanArea__text {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiPrice-kanagawaPlanArea__note {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 24px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List01 li {
    padding-left: 11px;
    font-size: 1.2rem;
    width: 100%;
    margin-bottom: 6px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List01 li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List01 li:last-of-type {
    margin-bottom: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__List02 {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(2) li:first-child {
    margin-bottom: 10px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(2) li:last-child {
    margin-right: 0;
  }
  .p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(3) li:before, .p-sosaiPrice-kanagawaPlanArea__List02:nth-of-type(4) li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List02 li {
    padding: 0 5px 0 11px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .p-sosaiPrice-kanagawaPlanArea__List02 li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List03 {
    margin-bottom: 20px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List03 li {
    padding-left: 11px;
    font-size: 1.4rem;
    width: 100%;
    margin-bottom: 6px;
  }
  .p-sosaiPrice-kanagawaPlanArea__List03 li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-kanagawa04 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiPrice-kanagawa04__table {
    margin-bottom: 30px;
  }
  .p-sosaiPrice-kanagawa04__table thead tr th {
    padding: 16px 5px;
  }
  .p-sosaiPrice-kanagawa04__table tbody tr {
    position: relative;
  }
  .p-sosaiPrice-kanagawa04__table tbody tr:nth-child(odd) th, .p-sosaiPrice-kanagawa04__table tbody tr:nth-child(odd) td {
    background-color: #f6f6f6;
  }
  .p-sosaiPrice-kanagawa04__table tbody tr:last-child .p-sosaiPrice-kanagawa04__table__content--01:after {
    width: calc(100% - 1px);
    height: 0;
    content: "";
    border-bottom: dotted 1px #4c4c4c;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .p-sosaiPrice-kanagawa04__table tbody tr th {
    width: 33.3333333333%;
    padding: 10px 5px 10px 24px;
    font-size: 1.3rem;
    line-height: 2rem;
    height: 62px;
  }
  .p-sosaiPrice-kanagawa04__table tbody tr th:before {
    margin: -3px 7px 0 -13px;
  }
  .p-sosaiPrice-kanagawa04__table tbody tr td {
    width: 33.3333333333%;
    padding: 10px;
    background-color: transparent;
  }
  .p-sosaiPrice-kanagawa04__table__title {
    width: 33.3333333333%;
    font-size: 1.4rem;
    line-height: 1.5rem;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
  .p-sosaiPrice-kanagawa04__table__title--01:before {
    width: 1px;
    right: -1px;
  }
  .p-sosaiPrice-kanagawa04__table__content {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .p-sosaiPrice-kanagawa04__table__content--01:before {
    display: none;
  }
  .p-sosaiPrice-kanagawa04 .c-sosaiBtn03 {
    width: 100%;
  }
  .p-sosaiPrice-kanagawa04 .c-sosaiBtn03 a {
    padding: 13px 5px 12px;
    font-size: 1.5rem;
  }
  .p-sosaiPrice-kanagawa04 .c-sosaiBtn03 a:before {
    margin: -4px 6px 0 0;
  }
  .p-sosaiPrice-kanagawa05 {
    padding: 48px 15px 39px;
    margin-bottom: 59px;
  }
  .p-sosaiPrice-kanagawa05 .c-sosaiTitle02b {
    margin-bottom: 42px;
  }
  .p-sosaiPrice-kanagawa05 .c-sosaiKanrenLink01 {
    flex-wrap: wrap;
  }
  .p-sosaiPrice-kanagawa05 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-bottom: 8px;
    margin-right: 10px;
    float: none;
  }
  .p-sosaiPrice-kanagawa05 .c-sosaiKanrenLink01 li:nth-child(2n) {
    margin-right: 0;
  }
  .p-sosaiPrice-kanagawa05 .c-sosaiKanrenLink01 li img {
    margin-bottom: 8px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
料金プラン　ゆきげ葬（やまなし）
------------------------------------------------------------*/
.p-sosaiPrice-yamanashi {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiPrice-yamanashi .c-breadcrumb1 {
  padding: 9px 0 76px;
}

.p-sosaiPrice-yamanashi01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiPrice-yamanashi01 .c-sosaiTitle03 {
  margin: 0;
}
.p-sosaiPrice-yamanashi01 .c-sosaiTitle03 img {
  margin-top: 6px;
}

.p-sosaiPrice-yamanashi02 {
  margin-bottom: 120px;
}
.p-sosaiPrice-yamanashi02 .c-sosaiTitle02b {
  padding-top: 25px;
  margin-bottom: 61px;
  font-weight: normal;
  line-height: 0.9;
}
.p-sosaiPrice-yamanashi02 .c-sosaiTitle02b span {
  font-size: 2rem;
  padding-top: 14px;
}
.p-sosaiPrice-yamanashi02 .c-sosaiTitle02b span:after {
  margin-top: 21px;
}
.p-sosaiPrice-yamanashi02__content01 {
  margin-bottom: 47px;
}
.p-sosaiPrice-yamanashi02__content01 .c-sosaiTitle09 {
  margin-bottom: 22px;
  padding-left: 28px;
  line-height: 1;
}
.p-sosaiPrice-yamanashi02__content01 p {
  font-size: 1.7rem;
  line-height: 3rem;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-yamanashi02__content01 p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
}
.p-sosaiPrice-yamanashi02__content02 .c-sosaiTitle10 {
  margin-bottom: 21px;
}
.p-sosaiPrice-yamanashi02__content02 .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}
.p-sosaiPrice-yamanashi02__content02__plus {
  align-self: center;
}
.p-sosaiPrice-yamanashi02__content02__box {
  border: solid 1px #c8c8c8;
}
.p-sosaiPrice-yamanashi02__content02__box--01 {
  width: 220px;
}
.p-sosaiPrice-yamanashi02__content02__box--01 dd {
  padding: 94px 17px 74px;
}
.p-sosaiPrice-yamanashi02__content02__box--02 {
  width: 812px;
}
.p-sosaiPrice-yamanashi02__content02__box--02 dd {
  padding: 30px 30px 29px;
}
.p-sosaiPrice-yamanashi02__content02__box__title1 {
  width: 100%;
  display: table;
  border-bottom: dotted 1px #4c4c4c;
  margin: 17px 0 18px;
  padding-bottom: 12px;
}
.p-sosaiPrice-yamanashi02__content02__box__title1 h5 {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.4;
  vertical-align: middle;
  display: table-cell;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-yamanashi02__content02__box__title2 {
  width: 100%;
  height: 83px;
  display: table;
  border-bottom: dotted 1px #4c4c4c;
  padding-top: 3px;
}
.p-sosaiPrice-yamanashi02__content02__box__title2 h5 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  vertical-align: middle;
  display: table-cell;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-yamanashi02__content02__box__title2 span {
  font-size: 1.2rem;
  display: block;
}
.p-sosaiPrice-yamanashi02__content02__box__border {
  border: 1px solid #c8c8c8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-sosaiPrice-yamanashi02__content02__box__border > p {
  width: 100%;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin-bottom: 16px;
}
.p-sosaiPrice-yamanashi02__content02__box__border > div {
  width: 190px;
  margin: 0 auto;
}
.p-sosaiPrice-yamanashi02__content02__box__border:nth-child(1) {
  width: 452px;
  padding: 27px 12px 24px;
}
.p-sosaiPrice-yamanashi02__content02__box__border:nth-child(2) {
  width: 268px;
  padding: 27px 0 44px;
}
.p-sosaiPrice-yamanashi02__content02__box dt {
  width: 100%;
  padding: 14px 5px 9px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
}
.p-sosaiPrice-yamanashi02__content02__box dd > div {
  text-align: center;
}
.p-sosaiPrice-yamanashi02__content02__box dd > div p {
  font-size: 1.4rem;
  line-height: 2rem;
}
.p-sosaiPrice-yamanashi02__content02__box dd > div img {
  width: 160px;
  height: auto;
}

.p-sosaiPrice-yamanashi03 {
  margin-bottom: 120px;
  padding: 107px 0 120px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiPrice-yamanashi03 .u-inner01 > p {
  margin-bottom: 73px;
}
.p-sosaiPrice-yamanashi03 h4.c-sosaiTitle09 {
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-yamanashi03 h4.c-sosaiTitle09 {
    font-size: 1.4rem;
  }
}
.p-sosaiPrice-yamanashi03__content01 {
  margin-bottom: 60px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__box01 {
  margin-bottom: 47px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__box02 {
  padding: 33px 30px 6px 37px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__flow01 {
  margin: 0px auto 68px auto;
  padding-top: 2px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service {
  padding-top: 10px;
  margin-bottom: 0;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li {
  padding: 0 20px 58px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) img {
  margin: -11px auto 15px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) p {
  padding-right: 17px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) img {
  margin: -8px 0 12px -3px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) p {
  padding-right: 10px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(8) img {
  margin: 0 auto 4px -4px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) img {
  margin: -8px auto 12px 6px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) p {
  padding-left: 10px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) img {
  margin: -8px auto 12px 9px;
}
.p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) p {
  padding-left: 16px;
}

.p-sosaiPrice-yamanashiPlanArea {
  padding: 50px 50px 43px;
  background-color: #ffffff;
  font-size: 1.4rem;
  line-height: 1.8;
  border: solid 1px #333745;
}
.p-sosaiPrice-yamanashiPlanArea .c-sosaiTitle11 {
  width: calc(100% + 100px);
  margin-top: -50px;
  margin-right: -50px;
  margin-left: -50px;
  padding: 22px 15px 14px 84px;
}
.p-sosaiPrice-yamanashiPlanArea .c-sosaiTitle10:before {
  margin: -5px 9px 0 -30px;
}
.p-sosaiPrice-yamanashiPlanArea__box01 {
  margin-bottom: 35px;
  padding: 32px 35px 36px 44px;
  font-size: 2rem;
  font-weight: 500;
  color: #333745;
  position: relative;
  z-index: 10;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-yamanashiPlanArea__box01 em {
  color: #f2385a;
}
.p-sosaiPrice-yamanashiPlanArea__box01:before, .p-sosaiPrice-yamanashiPlanArea__box01:after {
  width: calc(100% - 10px);
  height: calc(100% - 14px);
  content: "";
  border: solid 1px rgba(242, 56, 90, 0.4);
  position: absolute;
  z-index: -1;
}
.p-sosaiPrice-yamanashiPlanArea__box01:before {
  top: 0;
  left: 1px;
}
.p-sosaiPrice-yamanashiPlanArea__box01:after {
  top: 10px;
  left: 11px;
}
.p-sosaiPrice-yamanashiPlanArea__box02 {
  margin: 25px auto 17px;
  padding: 33px 30px 9px 37px;
  background-color: #f8f8f8;
  border: solid 1px #c8c8c8;
  flex-wrap: wrap;
}
.p-sosaiPrice-yamanashiPlanArea__box02 p {
  line-height: 3.2rem;
  width: 100%;
}
.p-sosaiPrice-yamanashiPlanArea__annotation {
  font-size: 1.4rem;
  padding-right: 4px;
  margin-top: -4px;
}
.p-sosaiPrice-yamanashiPlanArea__annotation2 {
  font-size: 1.2rem;
}
.p-sosaiPrice-yamanashiPlanArea__price01 {
  margin-bottom: 10px;
}
.p-sosaiPrice-yamanashiPlanArea__price01 li {
  width: 320px;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-yamanashiPlanArea__price01__name {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1;
  color: #333745;
}
.p-sosaiPrice-yamanashiPlanArea__price01__price {
  color: #333745;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
}
.p-sosaiPrice-yamanashiPlanArea__price01__price em {
  color: #f2385a;
  font-size: 6.6rem;
  margin-right: 5px;
  line-height: 1;
}
.p-sosaiPrice-yamanashiPlanArea__price01__price span {
  margin: 0 5px;
  font-size: 3rem;
  line-height: 1;
  font-weight: 500;
  display: inline-block;
}
.p-sosaiPrice-yamanashiPlanArea__price01__price--sub {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.3rem;
}
.p-sosaiPrice-yamanashiPlanArea__price01__price--sub .small {
  font-size: 3rem;
}
.p-sosaiPrice-yamanashiPlanArea__price01__img {
  margin-top: 5px;
}
.p-sosaiPrice-yamanashiPlanArea__price01__img img {
  width: 100%;
  margin: 0;
}
.p-sosaiPrice-yamanashiPlanArea__price01__text {
  margin: 10px 0 20px 0;
}
.p-sosaiPrice-yamanashiPlanArea__price01__text p {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  color: #333745;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}
.p-sosaiPrice-yamanashiPlanArea__price02 {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  align-items: baseline;
}
.p-sosaiPrice-yamanashiPlanArea__price02 em {
  font-size: 6.6rem;
  margin-right: 5px;
}
.p-sosaiPrice-yamanashiPlanArea__price02 span {
  margin: 0 5px;
  font-size: 3rem;
  font-weight: 500;
  display: inline-block;
}
.p-sosaiPrice-yamanashiPlanArea__price02--sub {
  display: flex;
  align-items: center;
  font-size: 3.3rem;
}
.p-sosaiPrice-yamanashiPlanArea__price02--sub .small {
  font-size: 3rem;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 {
  margin: -8px auto 66px auto;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt {
  padding: 18px 0 8px;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt {
  padding: 19px 0 7px 4px;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__txt {
  padding: 18px 0 8px 3px;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__icon {
  padding-bottom: 35px;
  padding-left: 5px;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__txt {
  padding: 20px 0 6px 8px;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__icon {
  padding-bottom: 33px;
  padding-left: 5px;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__txt {
  padding: 12px 0 14px 8px;
}
.p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__icon {
  padding-bottom: 35px;
  padding-left: 5px;
}
.p-sosaiPrice-yamanashiPlanArea__service {
  margin-bottom: 10px;
}
.p-sosaiPrice-yamanashiPlanArea__service li {
  width: 20%;
  padding: 0 20px 44px;
  text-align: center;
}
.p-sosaiPrice-yamanashiPlanArea__service li img {
  margin: -2px auto 5px auto;
  display: block;
}
.p-sosaiPrice-yamanashiPlanArea__service li p {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  color: #333745;
}
.p-sosaiPrice-yamanashiPlanArea__service li p span {
  font-size: 1.2rem;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(1) p {
  padding-right: 14px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(2) p {
  padding-right: 4px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(3) img {
  margin-bottom: 17px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(3) p {
  padding-left: 3px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(4) img {
  margin-bottom: 17px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(5) img {
  margin-bottom: 17px;
  margin-left: 12px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(5) p {
  padding-left: 21px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) img {
  margin-left: -3px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) p {
  padding-right: 13px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) img {
  margin-bottom: 7px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) p {
  padding-right: 3px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(8) img {
  margin-bottom: 7px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) img {
  margin-bottom: 2px;
  margin-left: 8px;
  margin-top: -7px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) p {
  padding-left: 10px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) img {
  margin-bottom: 21px;
  margin-left: 12px;
  margin-top: -2px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) p {
  padding-left: 20px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(11) img {
  margin-bottom: -1px;
  margin-left: -7px;
  margin-top: -14px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(11) p {
  padding-right: 14px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(12) img {
  margin-bottom: 10px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(12) p {
  padding-right: 6px;
}
.p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(13) img {
  margin-bottom: -2px;
  margin-top: -4px;
}
.p-sosaiPrice-yamanashiPlanArea__service2 {
  margin-bottom: 15px;
  border: 2px solid rgba(242, 56, 90, 0.4);
  padding: 35px 20px 34px 38px;
}
.p-sosaiPrice-yamanashiPlanArea__service2 ul li:before {
  margin: -3px 15px 0 -16px;
}
.p-sosaiPrice-yamanashiPlanArea__service2 ul.mb30 {
  margin-bottom: 30px;
}
.p-sosaiPrice-yamanashiPlanArea__service2 p {
  margin-bottom: 10px;
}
.p-sosaiPrice-yamanashiPlanArea__service2:nth-of-type(2n) {
  margin-bottom: 30px;
}
.p-sosaiPrice-yamanashiPlanArea__service2.mb30 {
  margin-bottom: 30px;
}
.p-sosaiPrice-yamanashiPlanArea__note {
  font-size: 1.4rem;
  margin-bottom: 47px;
}
.p-sosaiPrice-yamanashiPlanArea__note.mb45 {
  margin-bottom: 45px;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-yamanashiPlanArea__note.mb45 {
    margin-bottom: 24px;
  }
}
.p-sosaiPrice-yamanashiPlanArea__marker {
  background: #f8f8f8;
  display: inline-block;
  padding: 10px 20px;
}
.p-sosaiPrice-yamanashiPlanArea__text {
  line-height: 2rem;
  margin-bottom: 10px;
}
.p-sosaiPrice-yamanashiPlanArea__List01 li {
  margin-right: 2em;
  padding-left: 16px;
  font-weight: 700;
}
.p-sosaiPrice-yamanashiPlanArea__List01 li:before {
  width: 6px;
  height: 6px;
  margin: -3px 10px 0 -16px;
  content: "";
  background-color: #2e95a3;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-sosaiPrice-yamanashiPlanArea__List02 {
  width: 25%;
  margin-top: 19px;
  margin-bottom: 14px;
}
.p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(2) li:first-child {
  margin-bottom: 30px;
}
.p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(2) li:last-child {
  margin-right: -70px;
}
.p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(3) li:before, .p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(4) li:before {
  margin: -3px 13px 0 -22px;
}
.p-sosaiPrice-yamanashiPlanArea__List02 li {
  margin-bottom: 15px;
  padding: 0 5px 0 21px;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.p-sosaiPrice-yamanashiPlanArea__List02 li:before {
  width: 6px;
  height: 6px;
  margin: -3px 14px 0 -19px;
  content: "";
  background-color: #2e95a3;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.p-sosaiPrice-yamanashi04 {
  margin-bottom: 128px;
}
.p-sosaiPrice-yamanashi04 .c-sosaiTitle02b {
  padding-top: 5px;
  margin-bottom: 59px;
}
.p-sosaiPrice-yamanashi04__table {
  width: 100%;
  margin-bottom: 60px;
  border-collapse: collapse;
  position: relative;
}
.p-sosaiPrice-yamanashi04__table thead tr {
  position: relative;
}
F .p-sosaiPrice-yamanashi04__table thead tr:after {
  width: 100%;
  height: 0;
  content: "";
  border-bottom: dotted 1px #4c4c4c;
  position: absolute;
  bottom: 0;
  left: 0;
}
.p-sosaiPrice-yamanashi04__table thead tr th {
  padding: 19px 20px;
}
.p-sosaiPrice-yamanashi04__table tbody tr {
  position: relative;
}
.p-sosaiPrice-yamanashi04__table tbody tr:after {
  width: 100%;
  height: 0;
  content: "";
  border-bottom: dotted 1px #4c4c4c;
  position: absolute;
  left: 0;
}
.p-sosaiPrice-yamanashi04__table tbody tr th {
  padding: 29px 20px 28px 48px;
  font-weight: 500;
  text-align: left;
  background-color: #ffffff;
}
.p-sosaiPrice-yamanashi04__table tbody tr th:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiPrice-yamanashi04__table tbody tr td {
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
}
.p-sosaiPrice-yamanashi04__table tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}
.p-sosaiPrice-yamanashi04__table tbody tr:nth-child(odd) td {
  background-color: #f3f3f3;
}
.p-sosaiPrice-yamanashi04__table__title {
  width: 36%;
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #333745;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-yamanashi04__table__title--01 {
  position: relative;
}
.p-sosaiPrice-yamanashi04__table__title--01:before {
  width: 10px;
  height: 100%;
  content: "";
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: -5px;
}
.p-sosaiPrice-yamanashi04__table__content {
  width: 35%;
}
.p-sosaiPrice-yamanashi04__table__content--01 {
  position: relative;
}
.p-sosaiPrice-yamanashi04__table__content--01:before {
  width: 10px;
  height: 100%;
  content: "";
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: -5px;
}
.p-sosaiPrice-yamanashi04 .c-sosaiBtn03 {
  width: 498px;
  margin: 0 auto;
  text-align: center;
}
.p-sosaiPrice-yamanashi04 .c-sosaiBtn03 a {
  padding: 23px;
}
.p-sosaiPrice-yamanashi04 .c-sosaiBtn03 a:before {
  margin: -5px 20px 0 0;
}

.p-sosaiPrice-yamanashi05 {
  padding: 106px 0 121px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiPrice-yamanashi05 .c-sosaiTitle02b {
  margin-bottom: 56px;
}
.p-sosaiPrice-yamanashi05 .c-sosaiKanrenLink01 li {
  margin-right: 30px;
}
.p-sosaiPrice-yamanashi05 .c-sosaiKanrenLink01 li:last-child {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .p-sosaiPrice-yamanashi {
    font-size: 1.6rem;
  }
  .p-sosaiPrice-yamanashi01 {
    min-width: 100%;
    margin-bottom: 25px;
    padding: 16px 0 10px;
  }
  .p-sosaiPrice-yamanashi01 .c-sosaiTitle03 {
    line-height: 2.4rem;
  }
  .p-sosaiPrice-yamanashi01 .c-sosaiTitle03 span {
    padding-top: 2px;
  }
  .p-sosaiPrice-yamanashi02 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiPrice-yamanashi02 .c-sosaiTitle02b {
    margin-bottom: 36px;
  }
  .p-sosaiPrice-yamanashi02 .c-sosaiTitle02b span {
    font-size: 1.5rem;
    padding-top: 9px;
  }
  .p-sosaiPrice-yamanashi02 .c-sosaiTitle02b span:after {
    height: 2px;
    margin-top: 16px;
  }
  .p-sosaiPrice-yamanashi02__content01 {
    margin-bottom: 40px;
  }
  .p-sosaiPrice-yamanashi02__content01 .c-sosaiTitle09 {
    margin-left: -5px;
    padding-left: 14px;
    margin-bottom: 18px;
  }
  .p-sosaiPrice-yamanashi02__content02 .c-sosaiTitle10 {
    margin-bottom: 19px;
  }
  .p-sosaiPrice-yamanashi02__content02 .c-sosaiTitle10:before {
    margin: -5px 5px 0 -30px;
  }
  .p-sosaiPrice-yamanashi02__content02__plus {
    margin: 20px auto;
    display: block;
    width: 22px;
  }
  .p-sosaiPrice-yamanashi02__content02__box--01 {
    width: 100%;
  }
  .p-sosaiPrice-yamanashi02__content02__box--01 dd {
    padding: 20px 20px 15px;
  }
  .p-sosaiPrice-yamanashi02__content02__box--02 {
    width: 100%;
  }
  .p-sosaiPrice-yamanashi02__content02__box--02 dd {
    padding: 20px 20px 21px;
  }
  .p-sosaiPrice-yamanashi02__content02__box__title1 {
    padding-bottom: 5px;
    display: block;
    margin: 19px 0 8px;
    letter-spacing: 1px;
  }
  .p-sosaiPrice-yamanashi02__content02__box__title1 h5 {
    font-size: 1.8rem;
    display: inherit;
    line-height: 1.2;
  }
  .p-sosaiPrice-yamanashi02__content02__box__title2 {
    height: auto;
    padding-bottom: 10px;
    display: block;
  }
  .p-sosaiPrice-yamanashi02__content02__box__title2 h5 {
    font-size: 1.8rem;
    display: inherit;
  }
  .p-sosaiPrice-yamanashi02__content02__box__border > p {
    font-size: 1.7rem !important;
    margin-bottom: 29px;
  }
  .p-sosaiPrice-yamanashi02__content02__box__border:nth-child(1) {
    width: 100%;
    padding: 22px 15px 9px;
    margin-bottom: 20px;
  }
  .p-sosaiPrice-yamanashi02__content02__box__border:nth-child(1) > div:last-of-type {
    margin-top: 14px;
  }
  .p-sosaiPrice-yamanashi02__content02__box__border:nth-child(2) {
    width: 100%;
    padding: 22px 15px 10px;
  }
  .p-sosaiPrice-yamanashi02__content02__box dt {
    padding: 6px 5px 3px;
    letter-spacing: 1px;
    font-size: 1.4rem;
    letter-spacing: 0;
  }
  .p-sosaiPrice-yamanashi02__content02__box dd > div {
    width: 100%;
    text-align: center;
  }
  .p-sosaiPrice-yamanashi02__content02__box dd > div img {
    width: 104px;
  }
  .p-sosaiPrice-yamanashi02__content02__box dd > div > div {
    width: 100%;
  }
  .p-sosaiPrice-yamanashi03 {
    margin-bottom: 52px;
    padding: 47px 20px;
  }
  .p-sosaiPrice-yamanashi03 .c-sosaiTitle02b {
    margin-bottom: 26px;
  }
  .p-sosaiPrice-yamanashi03 .u-inner01 > p {
    margin-bottom: 23px;
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiPrice-yamanashi03__content01 {
    margin-bottom: 49px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__box01 {
    margin-bottom: 20px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__flow01 {
    margin: 0px auto 19px auto;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) {
    padding: 15px 21px 13px 18px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt {
    padding-top: 5px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) {
    padding: 24px 21px 13px 18px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt img {
    width: 32px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__icon img {
    width: 28px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service {
    margin-bottom: 0;
    padding-top: 4px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li {
    padding: 0;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(4) {
    width: 33.3333333%;
    padding: 0;
    margin-bottom: 17px;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) p {
    padding-right: 0;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) p {
    padding-right: 0;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(8) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) p {
    padding-left: 0;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) p {
    padding-left: 0;
  }
  .p-sosaiPrice-yamanashi03__content02 .p-sosaiPrice-yamanashiPlanArea__box02 {
    margin: 10px 5px 14px;
    padding: 15px 15px 9px;
  }
  .p-sosaiPrice-yamanashiPlanArea {
    padding: 15px;
  }
  .p-sosaiPrice-yamanashiPlanArea .c-sosaiTitle11 {
    width: calc(100% + 30px);
    margin-top: -15px;
    margin-right: -15px;
    margin-left: -15px;
    padding: 20px 0 19px 62px;
    font-size: 1.9rem;
    line-height: 2.4rem;
    margin-bottom: 24px;
  }
  .p-sosaiPrice-yamanashiPlanArea .c-sosaiTitle11:before {
    width: 40px;
    height: 40px;
    margin: -8px 9px -6px -50px;
  }
  .p-sosaiPrice-yamanashiPlanArea .c-sosaiTitle10 {
    line-height: 2rem;
    margin: 0 auto 10px;
  }
  .p-sosaiPrice-yamanashiPlanArea .c-sosaiTitle10:before {
    margin: -5px 6px 0 -20px;
  }
  .p-sosaiPrice-yamanashiPlanArea__box01 {
    margin-bottom: 52px;
    padding: 22px 30px 22px;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__box01:before {
    width: calc(100% - 15px);
    height: calc(100% - 11px);
    top: 5px;
    left: 5px;
  }
  .p-sosaiPrice-yamanashiPlanArea__box01:after {
    width: calc(100% - 15px);
    height: calc(100% - 11px);
    top: 10px;
    left: 10px;
  }
  .p-sosaiPrice-yamanashiPlanArea__box02 {
    margin: 10px 5px 14px;
    padding: 15px 15px 1px;
  }
  .p-sosaiPrice-yamanashiPlanArea__box02 p {
    line-height: 1.5;
  }
  .p-sosaiPrice-yamanashiPlanArea__annotation {
    font-size: 1.4rem;
    line-height: 1.7;
    margin: 10px -7px 0;
    padding-right: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__annotation2 {
    font-size: 1.1rem;
    line-height: 1.5;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01 {
    margin-bottom: 14px;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01 li {
    width: 100%;
    margin-bottom: 28px;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01 li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01__name {
    font-size: 1.7rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01__price {
    font-size: 1.7rem;
    margin-bottom: 7px;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01__price em {
    font-size: 5rem;
    line-height: 1;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01__price span {
    font-size: 2.3rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01__price--sub {
    font-size: 2.6rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01__price--sub .small {
    font-size: 2.3rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__price01__img {
    padding: 0 5px;
    margin-top: 3px;
  }
  .p-sosaiPrice-yamanashiPlanArea__price02 {
    font-size: 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  .p-sosaiPrice-yamanashiPlanArea__price02 em {
    font-size: 3.6rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__price02 span {
    font-size: 1.9rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__price02--sub {
    font-size: 2.2rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__price02--sub .small {
    font-size: 1.9rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 {
    margin: 0 auto 18px auto;
    padding: 0 5px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) {
    padding: 13px 21px 12px 18px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__txt img {
    width: 47px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(1) .c-sosaiPlanFlow__icon {
    width: 16%;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) {
    padding: 23px 21px 11px 19px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) .c-sosaiPlanFlow__txt img {
    width: 45px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(2) .c-sosaiPlanFlow__icon img {
    width: 17px;
    margin-top: -8px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) {
    padding: 25px 21px 11px 19px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__txt img {
    width: 46px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(3) .c-sosaiPlanFlow__icon img {
    width: 15px;
    margin-top: -10px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) {
    padding: 15px 21px 13px 21px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__txt img {
    width: 46px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__icon {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(4) .c-sosaiPlanFlow__icon img {
    width: 28px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) {
    padding: 15px 21px 14px 20px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__txt img {
    width: 63px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__icon {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(5) .c-sosaiPlanFlow__icon img {
    width: 28px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) {
    padding: 22px 21px 9px 20px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__txt {
    padding: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__txt img {
    width: 41px;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__icon {
    padding: 0 10px 0 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__flow01 .c-sosaiPlanFlow__item:nth-of-type(6) .c-sosaiPlanFlow__icon img {
    width: 25px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li {
    width: 33.3333333%;
    padding: 0;
    margin-bottom: 17px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(1) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(2) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(3) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(4) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(5) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(8) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(11) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(12) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(13) img {
    padding: 0;
    margin: 0 auto 10px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(1) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(2) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(3) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(4) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(5) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(6) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(8) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(11) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(12) p, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(13) p {
    padding: 0;
    margin: auto;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(7) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(8) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) {
    margin-bottom: 11px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(9) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(11) {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(11) img {
    margin-left: 8px;
    margin-bottom: 4px;
    margin-top: -5px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(10) img, .p-sosaiPrice-yamanashiPlanArea__service li:nth-of-type(12) img {
    margin-bottom: 3px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li img {
    margin: 0 auto 10px auto;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__service li p span {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__service2 {
    margin-bottom: 10px;
    padding: 20px 20px 10px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service2 p {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-bottom: 13px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service2 ul {
    display: block;
  }
  .p-sosaiPrice-yamanashiPlanArea__service2 ul li {
    font-size: 1.1rem;
    width: 100%;
    margin-bottom: 6px;
  }
  .p-sosaiPrice-yamanashiPlanArea__service2 ul li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-yamanashiPlanArea__text {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiPrice-yamanashiPlanArea__note {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 24px;
  }
  .p-sosaiPrice-yamanashiPlanArea__List01 li {
    padding-left: 11px;
    font-size: 1.2rem;
    width: 100%;
    margin-bottom: 6px;
  }
  .p-sosaiPrice-yamanashiPlanArea__List01 li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-yamanashiPlanArea__List01 li:last-of-type {
    margin-bottom: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__List02 {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(2) li:first-child {
    margin-bottom: 10px;
  }
  .p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(2) li:last-child {
    margin-right: 0;
  }
  .p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(3) li:before, .p-sosaiPrice-yamanashiPlanArea__List02:nth-of-type(4) li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-yamanashiPlanArea__List02 li {
    padding: 0 5px 0 11px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .p-sosaiPrice-yamanashiPlanArea__List02 li:before {
    margin: -3px 7px 0 -6px;
    width: 5px;
    height: 5px;
  }
  .p-sosaiPrice-yamanashi04 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiPrice-yamanashi04__table {
    margin-bottom: 30px;
  }
  .p-sosaiPrice-yamanashi04__table thead tr th {
    padding: 16px 5px;
  }
  .p-sosaiPrice-yamanashi04__table tbody tr {
    position: relative;
  }
  .p-sosaiPrice-yamanashi04__table tbody tr:nth-child(odd) th, .p-sosaiPrice-yamanashi04__table tbody tr:nth-child(odd) td {
    background-color: #f6f6f6;
  }
  .p-sosaiPrice-yamanashi04__table tbody tr:last-child .p-sosaiPrice-yamanashi04__table__content--01:after {
    width: calc(100% - 1px);
    height: 0;
    content: "";
    border-bottom: dotted 1px #4c4c4c;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .p-sosaiPrice-yamanashi04__table tbody tr th {
    width: 33.3333333333%;
    padding: 10px 5px 10px 24px;
    font-size: 1.3rem;
    line-height: 2rem;
    height: 62px;
  }
  .p-sosaiPrice-yamanashi04__table tbody tr th:before {
    margin: -3px 7px 0 -13px;
  }
  .p-sosaiPrice-yamanashi04__table tbody tr td {
    width: 33.3333333333%;
    padding: 10px;
    background-color: transparent;
  }
  .p-sosaiPrice-yamanashi04__table__title {
    width: 33.3333333333%;
    font-size: 1.4rem;
    line-height: 1.5rem;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
  .p-sosaiPrice-yamanashi04__table__title--01:before {
    width: 1px;
    right: -1px;
  }
  .p-sosaiPrice-yamanashi04__table__content {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .p-sosaiPrice-yamanashi04__table__content--01:before {
    display: none;
  }
  .p-sosaiPrice-yamanashi04 .c-sosaiBtn03 {
    width: 100%;
  }
  .p-sosaiPrice-yamanashi04 .c-sosaiBtn03 a {
    padding: 13px 5px 12px;
    font-size: 1.5rem;
  }
  .p-sosaiPrice-yamanashi04 .c-sosaiBtn03 a:before {
    margin: -4px 6px 0 0;
  }
  .p-sosaiPrice-yamanashi05 {
    padding: 48px 15px 39px;
    margin-bottom: 59px;
  }
  .p-sosaiPrice-yamanashi05 .c-sosaiTitle02b {
    margin-bottom: 42px;
  }
  .p-sosaiPrice-yamanashi05 .c-sosaiKanrenLink01 {
    flex-wrap: wrap;
  }
  .p-sosaiPrice-yamanashi05 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-bottom: 8px;
    margin-right: 10px;
    float: none;
  }
  .p-sosaiPrice-yamanashi05 .c-sosaiKanrenLink01 li:nth-child(2n) {
    margin-right: 0;
  }
  .p-sosaiPrice-yamanashi05 .c-sosaiKanrenLink01 li img {
    margin-bottom: 8px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　ラビュー（しずおか）料金プラン
------------------------------------------------------------*/
.p-sosaiPrice-shizuoka {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiPrice-shizuoka .c-breadcrumb1 {
  padding: 11px 0 76px;
}

.p-sosaiPrice-shizuoka01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiPrice-shizuoka01 .c-sosaiTitle03 {
  margin: 0;
}
.p-sosaiPrice-shizuoka01 .c-sosaiTitle03 img {
  margin: 6px auto 0;
}

.p-sosaiPrice-shizuoka02 {
  margin-bottom: 120px;
}
.p-sosaiPrice-shizuoka02 .c-sosaiTitle02b {
  margin-bottom: 57px;
}
.p-sosaiPrice-shizuoka02__content01 {
  margin-bottom: 47px;
}
.p-sosaiPrice-shizuoka02__content01 .c-sosaiTitle09 {
  margin-bottom: 18px;
}
.p-sosaiPrice-shizuoka02__content01 p {
  font-size: 1.7rem;
  line-height: 3rem;
}
@media screen and (max-width: 640px) {
  .p-sosaiPrice-shizuoka02__content01 p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
}
.p-sosaiPrice-shizuoka02__content02 .c-sosaiTitle10 {
  margin-bottom: 21px;
}
.p-sosaiPrice-shizuoka02__content02 .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}
.p-sosaiPrice-shizuoka02__content02__plus {
  align-self: center;
}
.p-sosaiPrice-shizuoka02__content02__box {
  border: solid 1px #c8c8c8;
}
.p-sosaiPrice-shizuoka02__content02__box--01 {
  width: 220px;
}
.p-sosaiPrice-shizuoka02__content02__box--01 dd {
  padding: 94px 17px 74px;
}
.p-sosaiPrice-shizuoka02__content02__box--02 {
  width: 812px;
}
.p-sosaiPrice-shizuoka02__content02__box--02 dd {
  padding: 30px 30px 29px;
}
.p-sosaiPrice-shizuoka02__content02__box__title1 {
  width: 100%;
  display: table;
  border-bottom: dotted 1px #4c4c4c;
  margin: 17px 0 18px;
  padding-bottom: 12px;
}
.p-sosaiPrice-shizuoka02__content02__box__title1 h5 {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.4;
  vertical-align: middle;
  display: table-cell;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-shizuoka02__content02__box__title2 {
  width: 100%;
  height: 83px;
  display: table;
  border-bottom: dotted 1px #4c4c4c;
  padding-top: 3px;
}
.p-sosaiPrice-shizuoka02__content02__box__title2 h5 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  vertical-align: middle;
  display: table-cell;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-shizuoka02__content02__box__title2 span {
  font-size: 1.2rem;
  display: block;
}
.p-sosaiPrice-shizuoka02__content02__box__border {
  border: 1px solid #c8c8c8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.p-sosaiPrice-shizuoka02__content02__box__border > p {
  width: 100%;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin-bottom: 16px;
}
.p-sosaiPrice-shizuoka02__content02__box__border > div {
  width: 190px;
  margin: 0 auto;
}
.p-sosaiPrice-shizuoka02__content02__box__border:nth-child(1) {
  width: 452px;
  padding: 27px 12px 24px;
}
.p-sosaiPrice-shizuoka02__content02__box__border:nth-child(2) {
  width: 268px;
  padding: 27px 0 44px;
}
.p-sosaiPrice-shizuoka02__content02__box dt {
  width: 100%;
  padding: 14px 5px 9px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
}
.p-sosaiPrice-shizuoka02__content02__box dd > div {
  text-align: center;
}
.p-sosaiPrice-shizuoka02__content02__box dd > div p {
  font-size: 1.4rem;
  line-height: 2rem;
}
.p-sosaiPrice-shizuoka02__content02__box dd > div img {
  width: 160px;
  height: auto;
}

.p-sosaiPrice-shizuoka03 {
  margin-bottom: 100px;
  padding: 107px 0 120px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiPrice-shizuoka03__txt {
  text-align: center;
  margin-bottom: 73px;
}
.p-sosaiPrice-shizuoka03__txt02 {
  font-size: 1.2rem;
  margin-bottom: 34px;
}
.p-sosaiPrice-shizuoka03__txt03 {
  font-size: 1.2rem;
  margin-top: -10px;
}
.p-sosaiPrice-shizuoka03__txt04 {
  font-size: 1.2rem !important;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
  margin-top: 10px !important;
}
.p-sosaiPrice-shizuoka03 .c-sosaiTitle11 {
  padding: 21px 15px 15px 84px;
  margin-bottom: 39px;
}
.p-sosaiPrice-shizuoka03__content01 {
  margin-bottom: 60px;
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 {
  margin-bottom: 6px;
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service {
  margin: 20px 0 4px;
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li p {
  padding-top: 15px;
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(1) img {
  transform: translate(0, -7px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(2) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(11) img {
  transform: translate(0, -4px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) img {
  transform: translate(0, -10px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) img {
  transform: translate(-6px, 5px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(8) img {
  transform: translate(0, 5px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) img {
  transform: translate(0, -5px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(12) img {
  transform: translate(5px, -7px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(13) img {
  transform: translate(-2px, -8px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(14) img {
  transform: translate(1px, -9px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(18) img {
  transform: translate(0, -10px);
}
.p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(18) p {
  padding-top: 0;
}
.p-sosaiPrice-shizuoka03__content02 {
  margin-bottom: 60px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 {
  margin-bottom: 10px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__annotation2 {
  margin-bottom: 32px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service {
  margin: 27px 0 -5px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li p {
  padding-top: 13px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(1) img {
  transform: translate(0, -7px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(2) img {
  transform: translate(0, -4px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) img {
  transform: translate(0, -11px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) p {
  margin: -1px 0 0 4px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) img {
  transform: translate(-9px, 0);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) img, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(8) img {
  transform: translate(0, 5px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) img {
  transform: translate(6px, -19px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) p {
  margin: -10px 0 0 4px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(11) img {
  transform: translate(1px, -9px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(12) img {
  transform: translate(0, -9px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(12) p {
  margin-top: -13px;
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(13) img {
  transform: translate(5px, -7px);
}
.p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(13) p {
  margin: 0 0 0 4px;
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__flow01 {
  margin-bottom: 10px;
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service {
  margin: 29px 0 -11px;
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li p {
  padding-top: 14px;
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(1) img {
  transform: translate(0, -4px);
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(4) img {
  transform: translate(0, 14px);
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) img {
  transform: translate(-6px, -1px);
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) img {
  transform: translate(-6px, 5px);
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) img {
  transform: translate(4px, -17px);
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) p {
  margin-top: -10px;
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(8) img {
  transform: translate(2px, -8px);
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) img {
  transform: translate(0, -7px);
}
.p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) p {
  margin: -12px 0 0 6px;
}
.p-sosaiPrice-shizuoka03__content03__note {
  width: 510px;
  margin: 0 auto 30px;
}
.p-sosaiPrice-shizuoka03__content03__note p {
  font-size: 1.2rem;
}

.p-sosaiPrice-shizuokaPlanArea {
  padding: 50px 50px 31px;
  background-color: #ffffff;
  font-size: 1.4rem;
  line-height: 1.8;
  border: solid 1px #333745;
}
.p-sosaiPrice-shizuokaPlanArea .c-sosaiTitle11 {
  width: calc(100% + 100px);
  margin-top: -50px;
  margin-right: -50px;
  margin-left: -50px;
}
.p-sosaiPrice-shizuokaPlanArea__box01 {
  margin-bottom: 38px;
  padding: 41px;
  font-size: 2rem;
  font-weight: 500;
  color: #333745;
  position: relative;
  z-index: 10;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPrice-shizuokaPlanArea__box01 em {
  color: #f2385a;
}
.p-sosaiPrice-shizuokaPlanArea__box01:before, .p-sosaiPrice-shizuokaPlanArea__box01:after {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  content: "";
  border: solid 1px rgba(242, 56, 90, 0.4);
  position: absolute;
  z-index: -1;
}
.p-sosaiPrice-shizuokaPlanArea__box01:before {
  top: 0;
  left: 0;
}
.p-sosaiPrice-shizuokaPlanArea__box01:after {
  top: 10px;
  left: 10px;
}
.p-sosaiPrice-shizuokaPlanArea__box02 {
  margin: 17px auto 19px;
  padding: 30px 30px 31px;
  background-color: #f8f8f8;
  border: solid 1px #c8c8c8;
}
.p-sosaiPrice-shizuokaPlanArea__annotation {
  font-size: 1.4rem;
}
.p-sosaiPrice-shizuokaPlanArea__annotation2 {
  font-size: 1.2rem;
}
.p-sosaiPrice-shizuokaPlanArea__priceImg {
  margin-right: 40px;
}
.p-sosaiPrice-shizuokaPlanArea__price {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
}
.p-sosaiPrice-shizuokaPlanArea__price p {
  font-size: 2rem;
  margin: 0 0 6px -6px;
}
.p-sosaiPrice-shizuokaPlanArea__price i {
  width: 170px;
  margin: 0 25px 0 0;
  padding: 5px 9px;
  font-size: 1.2rem;
  font-style: normal;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  background-color: #f2385a;
  font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}
.p-sosaiPrice-shizuokaPlanArea__price em {
  font-size: 7rem;
  line-height: 1;
  margin-right: 4px;
}
.p-sosaiPrice-shizuokaPlanArea__price span {
  margin: 0 5px;
  font-size: 3rem;
  font-weight: 500;
  display: inline-block;
}
.p-sosaiPrice-shizuokaPlanArea__price__box {
  display: flex;
  align-items: center;
}
.p-sosaiPrice-shizuokaPlanArea__price__item {
  display: flex;
  flex-wrap: wrap;
}
.p-sosaiPrice-shizuokaPlanArea__price__item--sub {
  font-size: 3.5rem !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
}
.p-sosaiPrice-shizuokaPlanArea__price__item .small {
  font-size: 3rem;
  border: none;
  padding: 0;
  margin: 0;
}
.p-sosaiPrice-shizuokaPlanArea__osusume {
  margin: -2px 0 51px;
}
.p-sosaiPrice-shizuokaPlanArea__osusume li {
  width: 50%;
  height: 96px;
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  display: table-cell;
}
.p-sosaiPrice-shizuokaPlanArea__osusume li:after {
  width: 10px;
  height: 10px;
  content: "";
  background-image: url("../img/common/icon_sankaku_left_top_brown01.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 2px;
}
.p-sosaiPrice-shizuokaPlanArea__osusume li:nth-child(1) {
  border: solid 1px #c8c8c8;
}
.p-sosaiPrice-shizuokaPlanArea__osusume li:nth-child(2) {
  border-top: solid 1px #c8c8c8;
  border-bottom: solid 1px #c8c8c8;
  border-right: solid 1px #c8c8c8;
}
.p-sosaiPrice-shizuokaPlanArea__osusume li p {
  padding: 35px 30px 31px 41px;
  display: table-cell;
  vertical-align: middle;
}
.p-sosaiPrice-shizuokaPlanArea__osusume li p.two-row {
  padding: 20px 30px 17px 41px;
}
.p-sosaiPrice-shizuokaPlanArea__detail dt {
  padding: 20px 0 18px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.28;
  color: #ffffff;
  background-color: #2e95a3;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 0 #20656e;
  transition: 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.p-sosaiPrice-shizuokaPlanArea__detail dt:before {
  width: 10px;
  height: 21px;
  margin: 0 26px 0 7px;
  content: "";
  background-image: url("../img/common/icon_arrow-white03.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: block;
  transform: rotate(90deg);
}
.p-sosaiPrice-shizuokaPlanArea__detail dt:hover {
  box-shadow: none;
  transform: translate(0, 3px);
}
.p-sosaiPrice-shizuokaPlanArea__detail dd {
  padding-top: 41px;
  display: none;
}
.p-sosaiPrice-shizuokaPlanArea__detail dd h4 {
  margin: 0;
}
.p-sosaiPrice-shizuokaPlanArea__detail dd .p-sosaiPrice-shizuokaPlanArea__service li {
  width: 190px;
  height: 210px;
  padding: 0;
  margin-right: 17px;
}
.p-sosaiPrice-shizuokaPlanArea__detail dd .p-sosaiPrice-shizuokaPlanArea__service li:nth-child(5n) {
  margin-right: 0;
}
.p-sosaiPrice-shizuokaPlanArea__detail dd .p-sosaiPrice-shizuokaPlanArea__service li .shizuokaPlanArea__service-img {
  height: 118px;
}
.p-sosaiPrice-shizuokaPlanArea__detail dd .p-sosaiPrice-shizuokaPlanArea__service li p {
  line-height: 1.3;
}
.p-sosaiPrice-shizuokaPlanArea__detail.is-open dt:before {
  transform: rotate(-90deg);
}
.p-sosaiPrice-shizuokaPlanArea__service {
  margin-bottom: 45px;
}
.p-sosaiPrice-shizuokaPlanArea__service li {
  width: 20%;
  padding: 0 20px 41px;
  text-align: center;
}
.p-sosaiPrice-shizuokaPlanArea__service li img {
  margin: 0 auto 4px auto;
  display: block;
}
.p-sosaiPrice-shizuokaPlanArea__service li p {
  padding-top: 0.5em;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  color: #333745;
}
.p-sosaiPrice-shizuokaPlanArea__service li p span {
  font-size: 1.2rem;
}
.p-sosaiPrice-shizuokaPlanArea__service__2line {
  padding-top: 0 !important;
}
.p-sosaiPrice-shizuokaPlanArea__List {
  width: 49%;
}
.p-sosaiPrice-shizuokaPlanArea__List li {
  margin-bottom: 5px;
  padding: 10px 5px 0 27px;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.p-sosaiPrice-shizuokaPlanArea__List li:before {
  width: 6px;
  height: 6px;
  margin: -3px 10px 0 -17px;
  content: "";
  background-color: #2e95a3;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-sosaiPrice-shizuokaPlanArea h4 {
  margin: 0 0 17px;
  font-size: 2.8rem;
  font-weight: 500;
  color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.p-sosaiPrice-shizuoka04 {
  margin-bottom: 123px;
}
.p-sosaiPrice-shizuoka04 .c-sosaiTitle02b {
  padding-top: 5px;
  margin-bottom: 59px;
}
.p-sosaiPrice-shizuoka04__table {
  width: 100%;
  margin-bottom: 50px;
  border-collapse: collapse;
  position: relative;
}
.p-sosaiPrice-shizuoka04__table thead tr th {
  padding: 24px 20px 18px;
}
.p-sosaiPrice-shizuoka04__table tbody tr {
  position: relative;
}
.p-sosaiPrice-shizuoka04__table tbody tr:last-child {
  border-bottom: dotted 1px #4c4c4c;
}
.p-sosaiPrice-shizuoka04__table tbody tr:after {
  width: 100%;
  height: 0;
  content: "";
  border-bottom: dotted 1px #4c4c4c;
  position: absolute;
  left: 0;
}
.p-sosaiPrice-shizuoka04__table tbody tr th {
  width: 26.7%;
  padding: 22px 20px 18px 47px;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
  background-color: #ffffff;
}
.p-sosaiPrice-shizuoka04__table tbody tr th:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiPrice-shizuoka04__table tbody tr td {
  padding: 22px 20px 18px;
  font-size: 1.5rem;
  text-align: center;
  background-color: #f9f9f9;
}
.p-sosaiPrice-shizuoka04__table tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}
.p-sosaiPrice-shizuoka04__table tbody tr:nth-child(odd) td {
  background-color: #f3f3f3;
}
.p-sosaiPrice-shizuoka04__table__title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #333745;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  border-right: 1px solid #FFF;
}
.p-sosaiPrice-shizuoka04__table__title:last-child {
  border-right: 0;
}
.p-sosaiPrice-shizuoka04__table__title img {
  width: 23px;
  margin: -4px 9px 0 4px;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiPrice-shizuoka04 .c-sosaiBtn03 {
  width: 500px;
  margin: 0 auto;
  text-align: center;
}
.p-sosaiPrice-shizuoka04 .c-sosaiBtn03 a:before {
  margin: -4px 20px 0 0;
}

.p-sosaiPrice-shizuoka05 {
  padding: 106px 0 122px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiPrice-shizuoka05 .c-sosaiTitle02b {
  margin-bottom: 59px;
}

@media screen and (max-width: 640px) {
  .p-sosaiPrice-shizuoka {
    font-size: 1.4rem;
  }
  .p-sosaiPrice-shizuoka01 {
    min-width: 100%;
    margin-bottom: 46px;
    padding: 19px 0 8px;
  }
  .p-sosaiPrice-shizuoka01 .c-sosaiTitle03 {
    line-height: 2.4rem;
  }
  .p-sosaiPrice-shizuoka01 .c-sosaiTitle03 span {
    padding-top: 2px;
  }
  .p-sosaiPrice-shizuoka02 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiPrice-shizuoka02 .c-sosaiTitle02b {
    margin-bottom: 28px;
  }
  .p-sosaiPrice-shizuoka02__content01 {
    margin-bottom: 44px;
  }
  .p-sosaiPrice-shizuoka02__content01 .c-sosaiTitle09 {
    margin-bottom: 16px;
  }
  .p-sosaiPrice-shizuoka02__content01 p {
    padding: 0 6px;
    line-height: 1.45;
  }
  .p-sosaiPrice-shizuoka02__content02 {
    padding: 0 5px;
  }
  .p-sosaiPrice-shizuoka02__content02 .c-sosaiTitle10 {
    margin-bottom: 21px;
  }
  .p-sosaiPrice-shizuoka02__content02 .c-sosaiTitle10:before {
    margin: -5px 5px 0 -30px;
  }
  .p-sosaiPrice-shizuoka02__content02__plus {
    margin: 20px auto;
    display: block;
    width: 22px;
  }
  .p-sosaiPrice-shizuoka02__content02__box--01 {
    width: 100%;
  }
  .p-sosaiPrice-shizuoka02__content02__box--01 dd {
    padding: 20px 20px 15px;
  }
  .p-sosaiPrice-shizuoka02__content02__box--02 {
    width: 100%;
  }
  .p-sosaiPrice-shizuoka02__content02__box--02 dd {
    padding: 20px 20px 21px;
  }
  .p-sosaiPrice-shizuoka02__content02__box__title1 {
    padding-bottom: 5px;
    display: block;
    margin: 19px 0 8px;
    letter-spacing: 1px;
  }
  .p-sosaiPrice-shizuoka02__content02__box__title1 h5 {
    font-size: 1.8rem;
    display: inherit;
    line-height: 1.2;
  }
  .p-sosaiPrice-shizuoka02__content02__box__title2 {
    height: auto;
    padding-bottom: 10px;
    display: block;
  }
  .p-sosaiPrice-shizuoka02__content02__box__title2 h5 {
    font-size: 1.8rem;
    display: inherit;
  }
  .p-sosaiPrice-shizuoka02__content02__box__border > p {
    font-size: 1.7rem !important;
    margin-bottom: 29px;
  }
  .p-sosaiPrice-shizuoka02__content02__box__border:nth-child(1) {
    width: 100%;
    padding: 22px 15px 9px;
    margin-bottom: 20px;
  }
  .p-sosaiPrice-shizuoka02__content02__box__border:nth-child(1) > div:last-of-type {
    margin-top: 14px;
  }
  .p-sosaiPrice-shizuoka02__content02__box__border:nth-child(2) {
    width: 100%;
    padding: 22px 15px 10px;
  }
  .p-sosaiPrice-shizuoka02__content02__box dt {
    padding: 6px 5px 3px;
    letter-spacing: 1px;
    font-size: 1.4rem;
    letter-spacing: 0;
  }
  .p-sosaiPrice-shizuoka02__content02__box dd > div {
    width: 100%;
    text-align: center;
  }
  .p-sosaiPrice-shizuoka02__content02__box dd > div img {
    width: 104px;
  }
  .p-sosaiPrice-shizuoka02__content02__box dd > div > div {
    width: 100%;
  }
  .p-sosaiPrice-shizuoka03 {
    margin-bottom: 41px;
    padding: 50px 20px 0;
    overflow: hidden;
  }
  .p-sosaiPrice-shizuoka03 .c-sosaiTitle02b {
    margin-bottom: 25px;
  }
  .p-sosaiPrice-shizuoka03__txt {
    text-align: left;
    line-height: 1.5;
    margin-bottom: 24px;
  }
  .p-sosaiPrice-shizuoka03__txt02 {
    font-size: 1rem;
    margin-bottom: 25px;
    line-height: 1.4;
  }
  .p-sosaiPrice-shizuoka03__txt03 {
    font-size: 1rem;
    margin-top: 0;
  }
  .p-sosaiPrice-shizuoka03__txt04 {
    font-size: 1rem !important;
  }
  .p-sosaiPrice-shizuoka03__content01 {
    margin-bottom: 50px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 {
    margin-bottom: 12px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(1), .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(4) {
    padding: 15px 21px 14px 17px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(2), .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(3), .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(5), .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(6) {
    padding: 22px 21px 12px 17px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(2) .c-sosaiPlanFlow__icon, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(5) .c-sosaiPlanFlow__icon {
    transform: translateY(-4px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(3) .c-sosaiPlanFlow__icon {
    transform: translateY(-2px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(6) .c-sosaiPlanFlow__icon {
    transform: translate(-4px, -4px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service {
    margin: 19px 0 6px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li p {
    padding-top: 7px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(1) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(13) img {
    transform: translate(3px, -1px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(2) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(14) img {
    transform: translate(1px, 3px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(4) img {
    transform: translate(2px, 1px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) img {
    transform: translate(5px, 1px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(11) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(14) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) p, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(11) p, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(14) p {
    margin: 10px 0 0 2px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(8) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(10) img, .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(15) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(16) img {
    transform: translate(1px, -15px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(16) p {
    margin: -8px 0 0 2px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(17) img {
    transform: translate(5px, -16px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(17) p {
    margin: -16px 0 0 2px;
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(18) img {
    transform: translate(0, -15px);
  }
  .p-sosaiPrice-shizuoka03__content01 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(18) p {
    margin: -8px 0 0 2px;
  }
  .p-sosaiPrice-shizuoka03__content02 {
    margin-bottom: 50px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 {
    margin-bottom: 4px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(1), .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(4) {
    padding: 13px 21px 15px 18px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(2), .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(3), .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(5), .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(6) {
    padding: 22px 21px 12px 18px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(2) .c-sosaiPlanFlow__icon, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(3) .c-sosaiPlanFlow__icon, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(5) .c-sosaiPlanFlow__icon {
    transform: translateY(-4px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(6) .c-sosaiPlanFlow__icon {
    transform: translate(-4px, -4px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__annotation2 {
    margin-bottom: 23px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service {
    margin: 27px 0 -9px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li p {
    padding-top: 0;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(1) img {
    transform: translate(3px, -9px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(2) img, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(14) img {
    transform: translate(0, -4px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(3) img {
    transform: translate(1px, -8px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(4) img, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) img, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(4) p, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) p, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) p {
    padding-top: 7px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) img, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(12) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) p, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(12) p {
    margin: 10px 0 0 -4px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(8) img, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) img {
    transform: translate(0, -5px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(8) p, .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) p {
    margin: 8px 0 0 2px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(10) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(10) p {
    margin: 10px 0 0 4px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(11) p {
    margin: 10px 0 0 4px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(13) img {
    transform: translate(5px, -1px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(13) p {
    margin: 10px 0 0 4px;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(14) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(14) p {
    margin: 10px 0 0 0;
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(15) img {
    transform: translate(0, -2px);
  }
  .p-sosaiPrice-shizuoka03__content02 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(15) p {
    margin: 10px 0 0 4px;
  }
  .p-sosaiPrice-shizuoka03__content03 {
    margin-bottom: 50px;
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__flow01 {
    margin-bottom: 4px;
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item {
    padding: 22px 21px 13px 18px;
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:after {
    top: calc(100% - 11px);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item .c-sosaiPlanFlow__icon {
    transform: translateY(-4px);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__flow01 .c-sosaiPlanFlow__item:nth-child(3) .c-sosaiPlanFlow__icon {
    transform: translate(-4px, -4px);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service {
    margin: 19px 0 8px;
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li p {
    padding-top: 8px;
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(1) img {
    transform: translate(2px, 3px) w;
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(3) img, .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(4) img, .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(8) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(5) img, .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(6) img {
    transform: translate(-2px, 0);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) img {
    transform: translate(0, -2px);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(7) p {
    margin-top: 10px;
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(9) img {
    transform: translate(3px, 0);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(10) img {
    transform: translate(0);
  }
  .p-sosaiPrice-shizuoka03__content03 .p-sosaiPrice-shizuokaPlanArea__service li:nth-of-type(10) p {
    margin: -3px 0 0 6px;
  }
  .p-sosaiPrice-shizuoka03__content03__note {
    width: 100%;
    margin-bottom: 25px;
  }
  .p-sosaiPrice-shizuoka03__content03__note p {
    font-size: 1rem;
  }
  .p-sosaiPrice-shizuokaPlanArea {
    padding: 0 20px 30px;
  }
  .p-sosaiPrice-shizuokaPlanArea .c-sosaiTitle11 {
    width: calc(100% + 40px);
    margin: 0 -20px 30px -20px;
    font-size: 1.9rem;
    line-height: 1.2;
    padding: 10px 15px 10px 61px;
    position: relative;
  }
  .p-sosaiPrice-shizuokaPlanArea .c-sosaiTitle11 span {
    display: block;
    font-size: 1.9rem;
  }
  .p-sosaiPrice-shizuokaPlanArea .c-sosaiTitle11:before {
    width: 42px;
    height: 42px;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
  }
  .p-sosaiPrice-shizuokaPlanArea__box01 {
    margin-bottom: 27px;
    padding: 20px 20px 18px;
    font-size: 1.7rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__box01:before, .p-sosaiPrice-shizuokaPlanArea__box01:after {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
  }
  .p-sosaiPrice-shizuokaPlanArea__box01:after {
    top: 5px;
    left: 5px;
  }
  .p-sosaiPrice-shizuokaPlanArea__box02 {
    margin: 10px 0;
    padding: 17px 20px 4px;
  }
  .p-sosaiPrice-shizuokaPlanArea__annotation {
    font-size: 1.2rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__annotation2 {
    font-size: 1rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__priceImg {
    margin: 0 auto 11px auto;
    display: block;
  }
  .p-sosaiPrice-shizuokaPlanArea__price {
    font-size: 1.5rem;
    text-align: center;
  }
  .p-sosaiPrice-shizuokaPlanArea__price p {
    padding: 0 5px 3px;
    font-size: 1.2rem;
    line-height: 1.7;
  }
  .p-sosaiPrice-shizuokaPlanArea__price i {
    font-size: 1rem;
    padding: 2px 9px;
    margin: 0 0 8px 0;
  }
  .p-sosaiPrice-shizuokaPlanArea__price em {
    font-size: 4.5rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__price span {
    font-size: 2rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__price__box {
    flex-wrap: wrap;
    justify-content: center;
  }
  .p-sosaiPrice-shizuokaPlanArea__price__item {
    justify-content: center;
  }
  .p-sosaiPrice-shizuokaPlanArea__price__item--sub {
    font-size: 2.4rem !important;
  }
  .p-sosaiPrice-shizuokaPlanArea__price__item .small {
    font-size: 2rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__osusume {
    margin-bottom: 15px;
  }
  .p-sosaiPrice-shizuokaPlanArea__osusume li {
    width: 100% !important;
    height: auto;
    margin-bottom: 13px;
    font-size: 1.4rem;
    display: inherit;
    border: solid 1px #c8c8c8;
  }
  .p-sosaiPrice-shizuokaPlanArea__osusume li:nth-child(5) {
    margin-bottom: 0;
  }
  .p-sosaiPrice-shizuokaPlanArea__osusume li p {
    padding: 17px 0 14px 18px;
    display: inherit;
    line-height: 1.5;
  }
  .p-sosaiPrice-shizuokaPlanArea__osusume li p.two-row {
    padding: 17px 18px 14px;
  }
  .p-sosaiPrice-shizuokaPlanArea__detail dt {
    font-size: 1.5rem;
    padding: 16px 15px 13px;
  }
  .p-sosaiPrice-shizuokaPlanArea__detail dt:before {
    margin: 0 12px 0 7px;
  }
  .p-sosaiPrice-shizuokaPlanArea__detail dd {
    padding-top: 29px;
  }
  .p-sosaiPrice-shizuokaPlanArea__service li {
    width: 31% !important;
    height: auto !important;
    padding: 8px;
    margin: 0 8px 22px 0 !important;
  }
  .p-sosaiPrice-shizuokaPlanArea__service li:nth-child(3n) {
    margin-right: 0 !important;
  }
  .p-sosaiPrice-shizuokaPlanArea__service li .shizuokaPlanArea__service-img {
    height: auto !important;
  }
  .p-sosaiPrice-shizuokaPlanArea__service li img {
    margin: 0 auto 2px auto;
  }
  .p-sosaiPrice-shizuokaPlanArea__service li p {
    padding-top: 0;
    font-size: 1.1rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__service li p span {
    font-size: 1rem;
  }
  .p-sosaiPrice-shizuokaPlanArea__List {
    width: 100%;
  }
  .p-sosaiPrice-shizuokaPlanArea__List li {
    font-size: 1.1rem;
    line-height: 1.5;
    padding: 0 0 2px 11px;
  }
  .p-sosaiPrice-shizuokaPlanArea__List li:before {
    margin: -3px 5px 0 -11px;
  }
  .p-sosaiPrice-shizuokaPlanArea h4 {
    margin: 10px 0;
    font-size: 1.9rem;
    line-height: 1.3;
  }
  .p-sosaiPrice-shizuoka04 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiPrice-shizuoka04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiPrice-shizuoka04__table {
    margin-bottom: 30px;
  }
  .p-sosaiPrice-shizuoka04__table thead tr th {
    line-height: 1.3;
    padding: 7px 4px 5px;
  }
  .p-sosaiPrice-shizuoka04__table tbody tr th {
    width: 34%;
    padding: 10px 5px 12px 24px;
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .p-sosaiPrice-shizuoka04__table tbody tr th:before {
    margin: -3px 5px 0 -12px;
  }
  .p-sosaiPrice-shizuoka04__table tbody tr td {
    padding: 5px 2px;
    font-size: 1rem;
  }
  .p-sosaiPrice-shizuoka04__table tbody tr:nth-child(1) th, .p-sosaiPrice-shizuoka04__table tbody tr:nth-child(8) th {
    padding: 21px 5px 20px 24px;
  }
  .p-sosaiPrice-shizuoka04__table tbody tr:nth-child(2) th {
    padding: 23px 5px 22px 24px;
  }
  .p-sosaiPrice-shizuoka04__table tbody tr:nth-child(11) th {
    padding: 12px 5px 10px 24px;
  }
  .p-sosaiPrice-shizuoka04__table__title {
    font-size: 1.3rem;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
  .p-sosaiPrice-shizuoka04__table__title img {
    width: 15px;
    margin: 0 auto 2px;
    display: block;
  }
  .p-sosaiPrice-shizuoka04 .c-sosaiBtn03 {
    width: 100%;
    font-size: 1.5rem;
  }
  .p-sosaiPrice-shizuoka04 .c-sosaiBtn03 a {
    padding: 11px;
  }
  .p-sosaiPrice-shizuoka04 .c-sosaiBtn03 a:before {
    margin: -4px 6px 0 0;
  }
  .p-sosaiPrice-shizuoka05 {
    margin-bottom: 58px;
    padding: 50px 15px 41px;
    overflow: hidden;
  }
  .p-sosaiPrice-shizuoka05 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　斎場情報（検索）
------------------------------------------------------------*/
.p-sosaiHall {
  background-color: #ffffff;
}
.p-sosaiHall .c-breadcrumb1 {
  padding: 16px 0 65px 0;
}

.p-sosaiHall01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiHall01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiHall02 {
  margin-bottom: 100px;
}

.p-sosaiHall03 {
  margin-bottom: 140px;
}
.p-sosaiHall03__content01 {
  padding: 80px 0 70px;
  background-color: #faf9f6;
}
.p-sosaiHall03__content01 .c-sosaiAreaList01 img {
  padding-top: 21px;
  margin-top: 0;
  margin-bottom: 60px;
}
.p-sosaiHall03__content02 {
  padding: 80px 0 71px;
}
.p-sosaiHall03__content02 .c-sosaiAreaList01 img {
  margin-top: 0;
  margin-bottom: 61px;
  margin-left: 229px;
}
.p-sosaiHall03__content02 .c-sosaiAreaList01 ul li a {
  padding: 12px 30px 12px 30px;
}
.p-sosaiHall03__content03 {
  padding: 77px 0 70px;
  background-color: #faf9f6;
}
.p-sosaiHall03__content03 .c-sosaiAreaList01 img {
  padding-top: 5px;
  margin-top: 0;
  margin-bottom: 60px;
  margin-left: 179px;
}
.p-sosaiHall03__content01 .u-inner01, .p-sosaiHall03__content02 .u-inner01, .p-sosaiHall03__content03 .u-inner01 {
  position: relative;
}
.p-sosaiHall03__content01 .c-sosaiTitle04, .p-sosaiHall03__content02 .c-sosaiTitle04, .p-sosaiHall03__content03 .c-sosaiTitle04 {
  height: 400px;
  margin: 0;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  margin-bottom: 14px;
  line-height: 1;
}
.p-sosaiHall03__content01 .c-sosaiTitle04 img, .p-sosaiHall03__content02 .c-sosaiTitle04 img, .p-sosaiHall03__content03 .c-sosaiTitle04 img {
  margin: 3px 15px 4px 0;
}

@media screen and (max-width: 640px) {
  .p-sosaiHall .c-sosaiTitle04 img {
    width: 20%;
    margin: 9px auto 0;
  }
  .p-sosaiHall01 {
    min-width: 100%;
    margin-bottom: 37px;
    padding: 32px 0 33px;
  }
  .p-sosaiHall02 {
    margin-bottom: 51px;
  }
  .p-sosaiHall03 {
    margin-bottom: 0;
  }
  .p-sosaiHall03__content01 {
    padding: 50px 15px 45px;
  }
  .p-sosaiHall03__content01 .c-sosaiAreaList01 img {
    padding-top: 0;
    margin: 0 auto 38px;
  }
  .p-sosaiHall03__content01 .c-sosaiAreaList01 ul li a {
    line-height: 1.5rem;
  }
  .p-sosaiHall03__content01 .c-sosaiAreaList01 ul li:nth-child(2) a, .p-sosaiHall03__content01 .c-sosaiAreaList01 ul li:nth-child(6) a, .p-sosaiHall03__content01 .c-sosaiAreaList01 ul li:nth-child(7) a {
    padding: 9px 12px 9px 17px;
  }
  .p-sosaiHall03__content01 .c-sosaiAreaList01 ul li:nth-child(3) a, .p-sosaiHall03__content01 .c-sosaiAreaList01 ul li:nth-child(4) a, .p-sosaiHall03__content01 .c-sosaiAreaList01 ul li:nth-child(5) a {
    padding: 9px 10px 9px 18px;
  }
  .p-sosaiHall03__content02 {
    padding: 50px 15px 45px;
  }
  .p-sosaiHall03__content02 .c-sosaiAreaList01 img {
    padding: 0 35px;
    margin: 0 auto 40px;
  }
  .p-sosaiHall03__content02 .c-sosaiAreaList01 ul li a {
    padding: 11px 12px 7px;
  }
  .p-sosaiHall03__content03 {
    padding: 51px 15px 19px;
  }
  .p-sosaiHall03__content03 .c-sosaiAreaList01 img {
    padding: 0 10px;
    margin: 0 auto 39px;
  }
  .p-sosaiHall03__content03 .c-sosaiAreaList01 ul li a {
    padding: 9px 5px 9px 10px;
  }
  .p-sosaiHall03__content03 .c-sosaiAreaList01 ul li:nth-child(3) a {
    padding: 9px 5px;
  }
  .p-sosaiHall03__content01 .c-sosaiTitle04, .p-sosaiHall03__content02 .c-sosaiTitle04, .p-sosaiHall03__content03 .c-sosaiTitle04 {
    height: auto;
    margin-bottom: 29px;
    font-weight: normal;
    color: #2f2933;
    text-align: center;
    writing-mode: unset;
    position: unset;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　斎場情報一覧（神奈川）
------------------------------------------------------------*/
.sosaiHallKanagawa {
  background-color: #ffffff;
}

.sosaiHallKanagawa01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.sosaiHallKanagawa01 .c-sosaiTitle03 {
  margin: 0;
}

.sosaiHallKanagawa02 {
  margin-bottom: 90px;
}
.sosaiHallKanagawa02__content01 {
  position: relative;
}

@media (min-width: 641px) {
  .sosaiHallKanagawa02__content01 .c-sosaiTitle04 {
    height: 400px;
    margin: 0;
    text-align: left;
    position: absolute;
    top: 0;
    right: -14px;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    margin-bottom: 14px;
  }
  .sosaiHallKanagawa02__content01 .c-sosaiTitle04 img {
    margin: 0 6px 0 0;
  }
}
.sosaiHallKanagawa03 {
  padding: 70px 0 110px 0;
  background-color: #faf9f6;
}
.sosaiHallKanagawa03__content01 .c-sosaiHallList {
  margin-bottom: 58px;
}
.sosaiHallKanagawa03__content01 .c-sosaiHallList dl dd {
  padding: 50px 50px 53px;
}
@media screen and (max-width: 640px) {
  .sosaiHallKanagawa03__content01 .c-sosaiHallList dl dd {
    padding: 15px 15px 20px;
  }
}
.sosaiHallKanagawa03__content01 .c-sosaiHallList dl dd table td {
  padding: 24px 20px 20px 10px;
}
@media screen and (max-width: 640px) {
  .sosaiHallKanagawa03__content01 .c-sosaiHallList dl dd table td {
    padding: 13px 0 12px 0;
  }
}
.sosaiHallKanagawa03__content01 .c-sosaiHallList dl dd table th {
  padding: 28px 10px 23px;
}
@media screen and (max-width: 640px) {
  .sosaiHallKanagawa03__content01 .c-sosaiHallList dl dd table th {
    padding: 10px 0 10px 5px;
  }
}
.sosaiHallKanagawa03 .c-sosaiTitle02 span:after {
  margin: 10px auto 0 auto;
}

@media screen and (max-width: 640px) {
  .sosaiHallKanagawa01 {
    min-width: 100%;
    margin-bottom: 40px;
    padding: 32px 0 33px;
  }
  .sosaiHallKanagawa02 {
    margin-bottom: 50px;
  }
  .sosaiHallKanagawa02__content01 {
    padding: 0 15px;
  }
  .sosaiHallKanagawa02 .c-sosaiTitle04 {
    margin-bottom: 28px;
  }
  .sosaiHallKanagawa02 .c-sosaiTitle04 img {
    width: 20%;
    margin: 9px auto 0;
  }
  .sosaiHallKanagawa02 .c-sosaiAreaList01 img {
    margin: 0 auto 38px auto;
  }
  .sosaiHallKanagawa03 {
    margin-bottom: -65px;
    padding: 48px 0 90px 0;
    background-color: #faf9f6;
  }
  .sosaiHallKanagawa03 .c-sosaiTitle02 {
    margin-bottom: 40px;
  }
  .sosaiHallKanagawa03 .c-sosaiTitle02 span:after {
    margin: 7px auto 0 auto;
  }
  .sosaiHallKanagawa03__content01 {
    padding: 0 15px;
  }
  .sosaiHallKanagawa03__content01 .c-sosaiHallList {
    margin-bottom: 20px;
  }
  .sosaiHallKanagawa03__content01 .c-sosaiHallList dt {
    padding: 16px 10px 14px 57px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　斎場情報詳細
------------------------------------------------------------*/
.p-sosaiHallDetail {
  background-color: #ffffff;
}

.p-sosaiHallDetail01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  margin-bottom: 7px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiHallDetail01 .c-sosaiTitle03 {
  margin: 0;
}
.p-sosaiHallDetail01 .c-sosaiTitle03 img {
  margin: 8px auto 0;
}

.p-sosaiHallDetail02 {
  margin-bottom: 162px;
}
.p-sosaiHallDetail02__content01 {
  margin-bottom: 40px;
}
.p-sosaiHallDetail02__content01__slideArea__slide {
  width: 790px;
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-slider {
  margin: 0;
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-slide {
  position: relative;
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-slide span {
  width: 100%;
  padding: 15px 20px;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-slide img {
  width: 100%;
  max-height: 380px;
  min-height: 380px;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-prev {
  width: 20px;
  height: 40px;
  left: 20px;
  z-index: 10;
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-prev:before {
  width: 20px;
  height: 40px;
  content: "";
  display: block;
  background-image: url("../img/common/icon_arrow-white04.png");
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 1 !important;
  transform: rotate(180deg);
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-next {
  width: 20px;
  height: 40px;
  right: 20px;
  z-index: 10;
}
.p-sosaiHallDetail02__content01__slideArea__slide .slick-next:before {
  width: 20px;
  height: 40px;
  content: "";
  display: block;
  background-image: url("../img/common/icon_arrow-white04.png");
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 1 !important;
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail {
  width: 300px;
  padding: 20px 20px 0 20px;
  background-color: #f5f3ed;
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail li {
  margin-bottom: 20px;
  margin-right: 20px;
  max-height: 100px;
  overflow: hidden;
  float: left;
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail li:nth-child(odd) {
  margin-right: 20px;
}
@media screen and (max-width: 640px) {
  .p-sosaiHallDetail02__content01__slideArea__thumbnail li:nth-child(odd) {
    margin-right: 0;
  }
}
@media screen and (max-width: 640px) {
  .p-sosaiHallDetail02__content01__slideArea__thumbnail li:not(:nth-child(3n-1)) {
    margin-right: 5%;
  }
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail li img {
  width: 120px;
  height: 100px;
  object-fit: cover;
  font-family: "object-fit: cover;"; /*IE対策*/
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail li.is-active {
  display: none;
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail li .slick-track {
  width: auto !important;
  left: 0 !important;
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail li .slick-list {
  display: flex !important;
}
.p-sosaiHallDetail02__content02 {
  margin-bottom: 40px;
}
.p-sosaiHallDetail02__content02 table {
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
  border-top: dotted 1px #111111;
  font-size: 1.7rem;
  line-height: 1.5;
  color: #111111;
}
.p-sosaiHallDetail02__content02 table tr:nth-child(odd) {
  background-color: #f9f9f9;
}
.p-sosaiHallDetail02__content02 table tr:nth-child(2) td {
  padding: 27px 0 25px;
}
.p-sosaiHallDetail02__content02 table th {
  width: 218px;
  padding: 23px 10px 22px 28px;
  text-align: left;
  vertical-align: middle;
  border-bottom: dotted 1px #111111;
}
.p-sosaiHallDetail02__content02 table th:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 0;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiHallDetail02__content02 table td {
  padding: 23px 30px 22px 0;
  vertical-align: middle;
  border-bottom: dotted 1px #111111;
  font-weight: 500;
  line-height: 1.45;
}
.p-sosaiHallDetail02__content02 table td .c-sosaiBtn04 {
  margin-left: 20px;
}
.p-sosaiHallDetail02__content03__box {
  width: 854px;
  margin: 80px auto;
}
.p-sosaiHallDetail02__content03__youtube {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0px;
  position: relative;
}
.p-sosaiHallDetail02__content03__youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-sosaiHallDetail02__content03__btn {
  margin: 0 auto;
  display: table;
}
.p-sosaiHallDetail02__content03__btn .c-sosaiBtn05 {
  width: 500px;
  margin-right: 20px;
  text-align: center;
  float: left;
}
.p-sosaiHallDetail02__content03__btn .c-sosaiBtn03 {
  width: 500px;
  text-align: center;
  float: right;
}

@media screen and (max-width: 640px) {
  .p-sosaiHallDetail01 {
    min-width: 100%;
    margin-bottom: 36px;
    padding: 32px 0 33px;
  }
  .p-sosaiHallDetail02 {
    margin-bottom: 52px;
    padding: 0 15px;
  }
  .p-sosaiHallDetail02__content01 {
    margin-bottom: 35px;
  }
  .p-sosaiHallDetail02__content01__slideArea__slide {
    width: 100%;
    margin-bottom: 15px;
  }
  .p-sosaiHallDetail02__content01__slideArea__slide .slick-slide span {
    padding: 5px 11px;
    font-size: 1.2rem;
  }
  .p-sosaiHallDetail02__content01__slideArea__slide .slick-slide img {
    min-height: auto;
    height: 216px;
    width: 100%;
  }
  .p-sosaiHallDetail02__content01__slideArea__thumbnail {
    width: 100%;
    padding: 10px 10px 0 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .p-sosaiHallDetail02__content01__slideArea__thumbnail li {
    width: 30%;
    margin-bottom: 10px;
    float: none;
  }
  .p-sosaiHallDetail02__content01__slideArea__thumbnail li img {
    height: 68px;
  }
  .p-sosaiHallDetail02__content02 {
    margin-bottom: 20px;
  }
  .p-sosaiHallDetail02__content02 table {
    font-size: 1.4rem;
    margin-bottom: 26px;
  }
  .p-sosaiHallDetail02__content02 table th {
    width: 37%;
    padding: 11px;
  }
  .p-sosaiHallDetail02__content02 table td {
    padding: 11px 0 15px 0;
    line-height: 1.3;
  }
  .p-sosaiHallDetail02__content02 table td .c-sosaiBtn04 {
    border: solid 1px #2e95a3;
    margin-left: 10px;
  }
  .p-sosaiHallDetail02__content02 table tr:nth-child(2) td {
    padding: 14px 0 15px 0;
  }
  .p-sosaiHallDetail02__content03__box {
    width: 100%;
    margin: 35px 0;
  }
  .p-sosaiHallDetail02__content03__youtube {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0px;
    position: relative;
  }
  .p-sosaiHallDetail02__content03__btn {
    display: block;
  }
  .p-sosaiHallDetail02__content03__btn .c-sosaiBtn05 {
    float: none;
    width: 100%;
    padding: 18px;
    margin: 0 0 10px 0;
    font-size: 1.5rem;
  }
  .p-sosaiHallDetail02__content03__btn .c-sosaiBtn05 a:before {
    margin: -2px 14px 0 0;
  }
  .p-sosaiHallDetail02__content03__btn .c-sosaiBtn03 {
    width: 100%;
    float: none;
    font-size: 1.5rem;
    padding: 18px;
  }
  .p-sosaiHallDetail02__content03__btn .c-sosaiBtn03 a {
    padding: 0;
  }
  .p-sosaiHallDetail02__content03__btn .c-sosaiBtn03 a:before {
    margin: -2px 17px 0 6px;
  }
}
/*
------------------------------------------------------------*/
.p-sosaiHallDetail02__content01__slideArea__thumbnail {
  overflow: hidden;
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail .slick-track {
  width: calc(100% + 20px) !important;
  left: inherit !important;
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail .slick-track li {
  margin-right: 0 !important;
  width: calc(50% - 20px);
  margin-right: 20px !important;
}
@media screen and (max-width: 640px) {
  .p-sosaiHallDetail02__content01__slideArea__thumbnail .slick-track li {
    float: left !important;
    width: calc(33.333333% - 10px);
    margin-right: 10px !important;
  }
}
.p-sosaiHallDetail02__content01__slideArea__thumbnail .slick-track li img {
  width: 100% !important;
}

/*------------------------------------------------------------
葬祭　お葬式の流れ
------------------------------------------------------------*/
.p-sosaiFlow {
  background-color: #ffffff;
  font-size: 1.8rem;
  line-height: 1.77;
  color: #111111;
}
.p-sosaiFlow .c-breadcrumb1 {
  padding: 7px 0 85px;
}

.p-sosaiFlow01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiFlow01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiFlow02 {
  margin-bottom: 40px;
}
.p-sosaiFlow02__content01 {
  margin-bottom: 120px;
}
.p-sosaiFlow02__content01 > p {
  margin-bottom: 52px;
}
.p-sosaiFlow02__content01 .c-list04 {
  border: 3px solid #29806c;
  margin-bottom: 35px;
}
.p-sosaiFlow02__content01 .c-list04__item {
  padding: 27px 0 64px 0;
}
.p-sosaiFlow02__content01 .c-list04__icon {
  position: relative;
  margin-top: -8px;
}
.p-sosaiFlow02__content01 .c-list04__icon:before {
  width: 12px;
  height: 22px;
  margin: 0 auto;
  content: "";
  background-image: url("../img/common/icon_arrow-blue04.png");
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 0.6;
  transform: rotate(90deg);
  position: absolute;
  top: 131%;
  left: 0;
  right: 0;
}
.p-sosaiFlow02__content01 .c-supplement {
  text-align: center;
}
.p-sosaiFlow02__content02 .c-sosaiTitle10 {
  margin-bottom: 16px;
}

.p-sosaiFlowList {
  font-size: 1.7rem;
  line-height: 1.77;
}
.p-sosaiFlowList b {
  font-weight: 700;
}
.p-sosaiFlowList > li {
  padding: 101px 0;
  position: relative;
}
.p-sosaiFlowList > li:after {
  width: 30px;
  height: 48px;
  margin: 0 auto;
  content: "";
  background-image: url("../img/component/3arrow02.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  z-index: 10;
}
.p-sosaiFlowList > li:nth-child(odd) {
  background-color: #f8f8f8;
}
.p-sosaiFlowList > li:last-child:after {
  content: none;
}
.p-sosaiFlowList > li .u-inner01 {
  position: relative;
}
.p-sosaiFlowList > li:nth-child(1) .p-sosaiFlowList__title {
  transform: translateY(-5px);
}
.p-sosaiFlowList > li:nth-child(1) .p-sosaiFlowList__title img {
  margin: -5px 15px 0 0;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 h2 {
  padding: 10px 35px 9px;
  font-weight: normal;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 h2 span {
  margin: -12px 0 0 17px;
  padding: 1px 40px 0px 30px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__boxArea {
  padding: 37px 25px 37px 50px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box h3 {
  margin-bottom: 9px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box h3 span {
  margin-left: 9px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span {
  display: block;
  color: #333333;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span:nth-child(1) {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span:nth-child(2) {
  font-size: 4rem;
  padding-left: 38px;
  position: relative;
  line-height: 1;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span:nth-child(2):before {
  content: "";
  width: 28px;
  height: 28px;
  background: url("../img/common/icon_tel06.png");
  position: absolute;
  top: 3px;
  left: 1px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box__tel .tel-number {
  margin-top: -2px;
  letter-spacing: 3px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box__tel .tel-number:before {
  margin: -14px 9px 0 0;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box + .c-sosaiUketsuke02__box {
  margin-top: 22px;
  padding-top: 23px;
}
.p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 ul li {
  margin-bottom: 17px;
}
.p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__title {
  transform: translateY(-5px);
  margin-bottom: 14px;
}
.p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__title img {
  margin: -5px 15px 0 0;
}
.p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__txt03 p {
  margin-bottom: 17px;
}
.p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__txt03__annotation li {
  margin-bottom: 9px;
}
.p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__txt03__annotation li:last-child {
  margin-bottom: 0;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__title {
  transform: translateY(-5px);
  margin-bottom: 11px;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__title img {
  margin: -5px 15px 0 0;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt03 p {
  margin-bottom: 46px;
}
.p-sosaiFlowList > li:nth-child(3) .c-sosaiTitle10 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt03 .p-sosaiFlowList__box01 {
  margin-top: -5px;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 {
  padding-top: 56px;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 ul {
  margin-top: -3px;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 ul li h3 {
  margin: 33px 0 15px;
}
.p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 ul li p {
  padding: 0 40px 32px 40px;
}
.p-sosaiFlowList > li:nth-child(4), .p-sosaiFlowList > li:nth-child(5), .p-sosaiFlowList > li:nth-child(6), .p-sosaiFlowList > li:nth-child(7) {
  padding: 101px 0 79px;
}
.p-sosaiFlowList > li:nth-child(4) .p-sosaiFlowList__title, .p-sosaiFlowList > li:nth-child(5) .p-sosaiFlowList__title, .p-sosaiFlowList > li:nth-child(6) .p-sosaiFlowList__title, .p-sosaiFlowList > li:nth-child(7) .p-sosaiFlowList__title {
  margin-bottom: 13px;
}
.p-sosaiFlowList > li:nth-child(8) {
  padding: 101px 0 61px;
}
.p-sosaiFlowList__txt01 {
  width: 660px;
}
.p-sosaiFlowList__txt02 {
  padding: 70px 0 30px 0;
  clear: both;
}
.p-sosaiFlowList__txt02 p {
  font-size: 2rem;
}
.p-sosaiFlowList__txt03 {
  min-height: 240px;
  padding-right: 460px;
  position: relative;
}
.p-sosaiFlowList__txt03 p {
  margin-bottom: 20px;
}
.p-sosaiFlowList__txt03 p img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiFlowList__txt03__annotation {
  font-size: 1.4rem;
}
.p-sosaiFlowList__txt03__annotation li {
  padding-left: 1em;
  text-indent: -1em;
}
.p-sosaiFlowList__txt03 .p-sosaiFlowList__box01 ul {
  letter-spacing: -0.4em;
}
.p-sosaiFlowList__txt03 .p-sosaiFlowList__box01 ul > * {
  display: inline-block;
  letter-spacing: normal;
}
.p-sosaiFlowList__txt03 .p-sosaiFlowList__box01 ul li {
  width: 50%;
  font-weight: 700;
}
.p-sosaiFlowList__txt03 .p-sosaiFlowList__box01 ul li:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 0;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiFlowList__txt04 {
  padding-top: 60px;
  clear: both;
}
.p-sosaiFlowList__txt04 ul li {
  width: 353px;
  margin-right: 30px;
  background-color: #ffffff;
}
.p-sosaiFlowList__txt04 ul li:last-child {
  margin-right: 0;
}
.p-sosaiFlowList__txt04 ul li h3 {
  margin: 20px 0;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: #2e95a3;
}
.p-sosaiFlowList__txt04 ul li p {
  padding: 0 40px 40px 40px;
  font-size: 1.6rem;
}
.p-sosaiFlowList__img01 {
  width: 400px;
}
.p-sosaiFlowList__img01 img {
  margin-bottom: 25px;
}
.p-sosaiFlowList__img01__annotation {
  padding-left: 1em;
  font-size: 1.5rem;
  text-indent: -1em;
  line-height: 1.6;
}
.p-sosaiFlowList__title {
  margin-bottom: 20px;
  font-size: 3.4rem;
  font-weight: 500;
  color: #111111;
  transform: translateY(-5px);
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiFlowList__title img {
  width: 50px;
  margin: -5px 15px 0 0;
  vertical-align: middle;
}
.p-sosaiFlowList__box01 {
  padding: 35px 40px 32px;
  background-color: #ffffff;
  border: solid 1px #c8c8c8;
}

.p-sosaiFlow03 {
  padding: 105px 0 140px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiFlow03 .c-sosaiTitle02b {
  margin-bottom: 59px;
}

@media screen and (max-width: 640px) {
  .p-sosaiFlow {
    font-size: 1.6rem;
    padding-bottom: 59px;
  }
  .p-sosaiFlow01 {
    min-width: 100%;
    margin-bottom: 45px;
    padding: 32px 0 33px;
  }
  .p-sosaiFlow02 {
    margin-bottom: 20px;
  }
  .p-sosaiFlow02__content01 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiFlow02__content01 > p {
    margin-bottom: 24px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiFlow02__content01 .c-list04 {
    width: calc(100% + 10px);
    margin: 0 0 15px -5px;
    border: 2px solid #29806c;
  }
  .p-sosaiFlow02__content01 .c-list04__item {
    padding: 14px 15px 13px 14px;
  }
  .p-sosaiFlow02__content01 .c-list04__icon {
    position: static;
    width: 15%;
  }
  .p-sosaiFlow02__content01 .c-list04__icon img {
    vertical-align: middle;
  }
  .p-sosaiFlow02__content01 .c-list04__icon:before {
    content: none;
  }
  .p-sosaiFlow02__content02 .c-sosaiTitle10 {
    font-size: 1.5rem;
    margin-bottom: 1px;
  }
  .p-sosaiFlowList {
    font-size: 1.6rem;
  }
  .p-sosaiFlowList > li {
    padding: 52px 20px;
  }
  .p-sosaiFlowList > li:after {
    width: 30px;
    height: 48px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 {
    border: none;
    padding-top: 10px;
    background: url(../img/sosai/sosai_bg_urgency_sp.png) no-repeat top 10px right;
    background-size: cover;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 > img {
    top: 0;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 h2 {
    display: block;
    font-size: 2.3rem;
    line-height: 2.5rem;
    padding: 12px 98px 13px 10px;
    text-align: center;
    height: 115px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 h2 span {
    display: block;
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin: 5px auto 0;
    padding: 5px 10px 4px;
    max-width: 130px;
    letter-spacing: 0;
    border-radius: 20px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__boxArea {
    border: 1px solid #c8c8c8;
    border-top: none;
    padding: 14px 20px 7px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__boxArea .u-flr--pc:nth-child(2) .c-sosaiUketsuke02__box {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box {
    margin-bottom: -2px;
    padding-bottom: 15px;
    text-align: left;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box h3 {
    font-size: 1.9rem;
    margin-bottom: 4px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box h3:before {
    margin: -5px 6px 0 0;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box h3 span {
    margin-left: 4px;
    font-size: 1.3rem;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a {
    display: block;
    background: #2e95a3;
    text-align: center;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 3px 0px #1f6267;
    padding: 8px 0;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a div {
    width: 185px;
    margin: 0 auto;
    position: relative;
    padding-left: 39px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a div:before {
    content: "";
    width: 25px;
    height: 25px;
    background: url("../img/common/icon_tel07.png");
    background-size: contain;
    position: absolute;
    top: 9px;
    left: 0;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span {
    display: block;
    color: #fff;
    font-weight: bold;
    text-align: left;
    line-height: 1.3;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span:nth-child(1) {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span:nth-child(2) {
    font-size: 1.8rem;
    padding-left: 0;
    font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box a span:nth-child(2):before {
    content: none;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box__tel .tel-number {
    margin-top: -2px;
    letter-spacing: 3px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box__tel .tel-number:before {
    margin: -14px 9px 0 0;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02__box + .c-sosaiUketsuke02__box {
    margin-top: 1px;
    padding-top: 14px;
  }
  .p-sosaiFlowList > li:nth-child(1) .c-sosaiUketsuke02 ul li {
    margin-bottom: 8px;
  }
  .p-sosaiFlowList > li:nth-child(1) .p-sosaiFlowList__title img {
    margin: -6px 6px 0 0;
  }
  .p-sosaiFlowList > li:nth-child(2) {
    padding-bottom: 46px;
  }
  .p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__title {
    margin-bottom: 11px;
  }
  .p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__title img {
    margin: -7px 6px 0 0;
  }
  .p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__txt03 p {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-bottom: 12px;
  }
  .p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__txt03 p img {
    margin-bottom: 14px;
  }
  .p-sosaiFlowList > li:nth-child(2) .p-sosaiFlowList__txt03__annotation li {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 5px;
  }
  .p-sosaiFlowList > li:nth-child(3) {
    padding-bottom: 42px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__title img {
    margin: -7px 9px 0 0;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt03 p {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 19px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt03 p img {
    margin-bottom: 15px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 {
    padding-top: 24px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 ul li {
    display: flex;
    padding: 0;
    margin-bottom: 10px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 ul li h3 {
    font-size: 1.5rem;
    margin: 0 0 0;
    padding: 16px 20px 1px;
    text-align: left;
    line-height: 1.5;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 ul li p {
    font-size: 1.3rem;
    padding: 0 20px 0;
    line-height: 1.5;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04 ul li p span {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.3;
    display: block;
    margin-top: 6px;
    text-indent: -10px;
    padding-left: 10px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04__img {
    width: 90px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__txt04__text {
    width: calc(100% - 90px);
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__box01 {
    padding: 15px 19px 13px;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__box01 ul li {
    font-size: 1.4rem;
    line-height: 1.88;
  }
  .p-sosaiFlowList > li:nth-child(3) .p-sosaiFlowList__box01 ul li:before {
    margin-right: 4px;
  }
  .p-sosaiFlowList > li:nth-child(3) .c-sosaiTitle10 {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
  .p-sosaiFlowList > li:nth-child(4), .p-sosaiFlowList > li:nth-child(5), .p-sosaiFlowList > li:nth-child(6), .p-sosaiFlowList > li:nth-child(7), .p-sosaiFlowList > li:nth-child(8) {
    padding: 52px 20px 25px;
  }
  .p-sosaiFlowList > li:nth-child(4) .p-sosaiFlowList__title, .p-sosaiFlowList > li:nth-child(5) .p-sosaiFlowList__title, .p-sosaiFlowList > li:nth-child(6) .p-sosaiFlowList__title, .p-sosaiFlowList > li:nth-child(7) .p-sosaiFlowList__title, .p-sosaiFlowList > li:nth-child(8) .p-sosaiFlowList__title {
    margin-bottom: 10px;
  }
  .p-sosaiFlowList > li:nth-child(4) .p-sosaiFlowList__title img, .p-sosaiFlowList > li:nth-child(5) .p-sosaiFlowList__title img, .p-sosaiFlowList > li:nth-child(6) .p-sosaiFlowList__title img, .p-sosaiFlowList > li:nth-child(7) .p-sosaiFlowList__title img, .p-sosaiFlowList > li:nth-child(8) .p-sosaiFlowList__title img {
    margin: -7px 10px 0 0;
  }
  .p-sosaiFlowList > li:nth-child(4) .p-sosaiFlowList__txt03 p, .p-sosaiFlowList > li:nth-child(5) .p-sosaiFlowList__txt03 p, .p-sosaiFlowList > li:nth-child(6) .p-sosaiFlowList__txt03 p, .p-sosaiFlowList > li:nth-child(7) .p-sosaiFlowList__txt03 p, .p-sosaiFlowList > li:nth-child(8) .p-sosaiFlowList__txt03 p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiFlowList > li:nth-child(4) .p-sosaiFlowList__txt03 p img, .p-sosaiFlowList > li:nth-child(5) .p-sosaiFlowList__txt03 p img, .p-sosaiFlowList > li:nth-child(6) .p-sosaiFlowList__txt03 p img, .p-sosaiFlowList > li:nth-child(7) .p-sosaiFlowList__txt03 p img, .p-sosaiFlowList > li:nth-child(8) .p-sosaiFlowList__txt03 p img {
    margin-bottom: 16px;
  }
  .p-sosaiFlowList__txt01 {
    width: 100%;
  }
  .p-sosaiFlowList__txt02 {
    padding: 23px 0 17px;
  }
  .p-sosaiFlowList__txt02 p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiFlowList__txt02 .u-txtCenter {
    text-align: left;
  }
  .p-sosaiFlowList__txt03 {
    min-height: auto;
    padding-right: 0;
    position: static;
  }
  .p-sosaiFlowList__txt03 p {
    margin-bottom: 20px;
  }
  .p-sosaiFlowList__txt03 p img {
    margin: 10px auto;
    display: block;
    position: static;
  }
  .p-sosaiFlowList__txt03__annotation {
    font-size: 1.2rem;
  }
  .p-sosaiFlowList__txt03 .p-sosaiFlowList__box01 ul li {
    width: 100%;
    display: block;
  }
  .p-sosaiFlowList__txt04 {
    padding-top: 30px;
    clear: both;
  }
  .p-sosaiFlowList__txt04 ul li {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 15px;
  }
  .p-sosaiFlowList__txt04 ul li h3 {
    margin: 10px 0;
    font-size: 1.8rem;
  }
  .p-sosaiFlowList__txt04 ul li p {
    padding: 0;
    font-size: 1.4rem;
  }
  .p-sosaiFlowList__img01 {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .p-sosaiFlowList__img01 img {
    margin: 0;
  }
  .p-sosaiFlowList__img01__annotation {
    margin-top: 19px;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    line-height: 1.2;
  }
  .p-sosaiFlowList__title {
    margin-bottom: 11px;
    font-size: 2.1rem;
  }
  .p-sosaiFlowList__title img {
    width: 30px;
    margin: -6px 6px 0 0;
  }
  .p-sosaiFlowList__box01 {
    padding: 15px;
    margin-top: 20px;
  }
  .p-sosaiFlowList__box01 p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiFlow03 {
    padding: 50px 15px 39px;
  }
  .p-sosaiFlow03 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiFlow03 .c-sosaiKanrenLink01 {
    display: flex;
    flex-wrap: wrap;
  }
  .p-sosaiFlow03 .c-sosaiKanrenLink01 li {
    width: 48%;
    padding-bottom: 9px;
    margin-bottom: 11px;
  }
  .p-sosaiFlow03 .c-sosaiKanrenLink01 li:after {
    bottom: 3px;
  }
  .p-sosaiFlow03 .c-sosaiKanrenLink01 li img {
    margin-bottom: 5px;
  }
  .p-sosaiFlow03 .c-sosaiKanrenLink01 li a {
    font-size: 1.2rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　遺言・相続に関するご相談
------------------------------------------------------------*/
.p-sosaiInheritance {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiInheritance01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiInheritance01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiInheritance02 {
  margin-bottom: 106px;
  padding-top: 21px;
}
.p-sosaiInheritance02 .c-sosaiTitle02b {
  margin-bottom: 52px;
}
.p-sosaiInheritance02 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiInheritance02__txt {
  width: 720px;
  line-height: 1.77;
  float: left;
}
.p-sosaiInheritance02__txt b {
  font-weight: 700;
}
.p-sosaiInheritance02__txt p {
  padding-bottom: 15px;
}
.p-sosaiInheritance02__img {
  margin: 5px 0 50px;
  transform: translateY(5px);
}

.p-sosaiInheritance03 {
  margin-bottom: 120px;
}
.p-sosaiInheritance03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiInheritance03 ul {
  padding-top: 5px;
}
.p-sosaiInheritance03 ul li {
  width: 33.333333%;
  height: 124px;
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  display: table;
}
.p-sosaiInheritance03 ul li:after {
  width: 10px;
  height: 10px;
  content: "";
  background-image: url("../img/common/icon_sankaku_left_top_brown01.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 2px;
}
.p-sosaiInheritance03 ul li:nth-child(1) {
  border: solid 1px #c8c8c8;
}
.p-sosaiInheritance03 ul li:nth-child(2), .p-sosaiInheritance03 ul li:nth-child(3) {
  border-top: solid 1px #c8c8c8;
  border-bottom: solid 1px #c8c8c8;
  border-right: solid 1px #c8c8c8;
}
.p-sosaiInheritance03 ul li:nth-child(4) {
  border-bottom: solid 1px #c8c8c8;
  border-left: solid 1px #c8c8c8;
  border-right: solid 1px #c8c8c8;
}
.p-sosaiInheritance03 ul li:nth-child(5) {
  width: 66.666666%;
  border-bottom: solid 1px #c8c8c8;
  border-right: solid 1px #c8c8c8;
}
.p-sosaiInheritance03 ul li p {
  padding: 35px 40px 32px;
  display: table-cell;
  vertical-align: middle;
}

.p-sosaiInheritance04 {
  padding: 107px 0 140px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiInheritance04 .c-sosaiTitle02b {
  margin-bottom: 52px;
}
.p-sosaiInheritance04__list {
  margin-top: 71px;
}
.p-sosaiInheritance04__list__btn {
  width: 372px;
  margin-right: 1px;
  padding: 18px 16px 14px;
  color: #777777;
  background-color: #e6e6e6;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
.p-sosaiInheritance04__list__btn:last-child {
  margin-right: 0;
}
.p-sosaiInheritance04__list__btn--yamanashi {
  border-top: solid 2px #006da7;
}
.p-sosaiInheritance04__list__btn--kanagawa {
  border-top: solid 2px #00a384;
}
.p-sosaiInheritance04__list__btn--shizuoka {
  border-top: solid 2px #931719;
}
.p-sosaiInheritance04__list__btn.is-open {
  color: #111111;
  background-color: #ffffff;
  opacity: 1 !important;
  cursor: default;
}
.p-sosaiInheritance04__list__btn:hover {
  opacity: 0.7;
}
.p-sosaiInheritance04__content {
  padding: 54px 60px 59px;
  background-color: #ffffff;
  display: none;
}
.p-sosaiInheritance04__content.is-open {
  display: block;
}
.p-sosaiInheritance04__content__adviser {
  margin-bottom: 53px;
  padding-bottom: 49px;
  border-bottom: dotted 1px #4c4c4c;
}
.p-sosaiInheritance04__content__adviser:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.p-sosaiInheritance04__content__adviser h3 {
  margin-bottom: 3px;
  padding-left: 45px;
  font-size: 2.6rem;
  font-weight: 700;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiInheritance04__content__adviser h3:before {
  width: 30px;
  height: 30px;
  margin: -8px 10px 0 -45px;
  content: "";
  background-image: url("../img/sosai/icon_sosai01.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiInheritance04__content__adviser p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333333;
}
.p-sosaiInheritance04__content__adviser--01 {
  padding-right: 490px;
  position: relative;
}
.p-sosaiInheritance04__content__adviser--01 .adviser-img {
  position: absolute;
  top: 6px;
  right: 0;
}
.p-sosaiInheritance04__content__adviser--02__left {
  width: 500px;
}
.p-sosaiInheritance04__content__adviser--02__left h3 {
  margin-bottom: 1px;
}
.p-sosaiInheritance04__content__adviser--02__right {
  width: 480px;
}
.p-sosaiInheritance04__content__adviser__tel {
  margin: 10px 0 13px;
}
.p-sosaiInheritance04__content__adviser__tel .tel-number {
  margin-right: 10px;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: 3px;
  color: #111111;
  display: inline-block;
  vertical-align: middle;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiInheritance04__content__adviser__tel .tel-number:before {
  width: 28px;
  height: 28px;
  margin: -5px 10px 0 0;
  content: "";
  background-image: url("../img/common/icon_tel06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiInheritance04__content__adviser__tel .tel-number span {
  font-size: 1.3rem;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  letter-spacing: 0;
  margin-left: 10px;
  margin-top: -8px;
}
.p-sosaiInheritance04__content__adviser__tel .tel-number a {
  display: block;
}
.p-sosaiInheritance04__content__adviser__tel span {
  font-size: 1.3rem;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiInheritance04__content__adviser__tel2 {
  width: 100%;
  margin-top: 8px;
}
.p-sosaiInheritance04__content__adviser__tel2 li {
  padding: 12px 15px 14px 40px;
  background-color: #f7f5f1;
}
.p-sosaiInheritance04__content__adviser__tel2 li:nth-child(2n) {
  background-color: #FFF;
}
.p-sosaiInheritance04__content__adviser__tel2 li a {
  color: #111111;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2.4rem;
  line-height: 1;
}
.p-sosaiInheritance04__content__adviser__tel2 li a div {
  display: flex;
  align-items: center;
}
.p-sosaiInheritance04__content__adviser__tel2 li a div p {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: left;
  font-family: YuGothic, "Yu Gothic", sans-serif;
  width: 234px;
}
.p-sosaiInheritance04__content__adviser__tel2 li a div p:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -20px;
  content: "";
  background-color: #2e95a3;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiInheritance04__content__adviser__tel2 li a div span {
  font-size: 1.6rem;
  margin-right: 7px;
  margin-top: 7px;
}
.p-sosaiInheritance04__content__adviser__time {
  font-size: 1.6rem;
  font-weight: 700;
}
.p-sosaiInheritance04__content__adviser__time span {
  font-weight: 500;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiInheritance04__content__adviser__time span em {
  margin: 0 0 0 12px;
  font-size: 3.2rem;
  letter-spacing: 3px;
  font-style: normal;
}
.p-sosaiInheritance04__content__adviser__link {
  font-size: 1.5rem;
  font-weight: 700;
}
.p-sosaiInheritance04__content__adviser__link a {
  color: #2e95a3;
}
.p-sosaiInheritance04__content__adviser__link a:after {
  width: 12px;
  height: 12px;
  margin: -5px 0 0 10px;
  content: "";
  background-image: url("../img/common/icon_link05.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiInheritance04__content__adviser__link a:hover {
  text-decoration: underline;
}
.p-sosaiInheritance04__content__adviser__address {
  font-size: 1.5rem;
  font-weight: 700;
}
.p-sosaiInheritance04__content__adviser__address .c-sosaiBtn04 {
  margin-left: 10px;
}
.p-sosaiInheritance04__content__adviser__annotation {
  margin-top: 9px;
}
.p-sosaiInheritance04__content__adviser__annotation li {
  font-size: 1.2rem;
  color: #666666;
  padding-left: 1em;
  text-indent: -1em;
}
.p-sosaiInheritance04__content__adviser__annotation li b {
  font-weight: 700;
}

@media screen and (max-width: 640px) {
  .p-sosaiInheritance {
    font-size: 1.4rem;
  }
  .p-sosaiInheritance01 {
    min-width: 100%;
    margin-bottom: 47px;
    padding: 32px 0 33px;
  }
  .p-sosaiInheritance02 {
    margin-bottom: 74px;
    padding: 0 20px;
  }
  .p-sosaiInheritance02 .c-sosaiTitle02b {
    margin-bottom: 25px;
    padding-left: 4px;
  }
  .p-sosaiInheritance02 .c-sosaiTitle02b span:after {
    height: 2px;
    margin: 13px auto 0;
  }
  .p-sosaiInheritance02__txt {
    width: 100%;
    margin-top: 15px;
    float: none;
  }
  .p-sosaiInheritance02__txt p {
    padding-bottom: 10px;
  }
  .p-sosaiInheritance02__img {
    margin: 22px auto 0;
    display: block;
    transform: translateY(0);
    width: 93%;
  }
  .p-sosaiInheritance03 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiInheritance03 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiInheritance03 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiInheritance03 ul {
    border: solid 1px #c8c8c8;
    padding: 0;
  }
  .p-sosaiInheritance03 ul li {
    width: 100% !important;
    height: auto;
    font-size: 1.3rem;
    line-height: 2rem;
    display: block;
    border-top: solid 1px #c8c8c8;
  }
  .p-sosaiInheritance03 ul li:nth-child(1) {
    border: 0;
  }
  .p-sosaiInheritance03 ul li:nth-child(2), .p-sosaiInheritance03 ul li:nth-child(3), .p-sosaiInheritance03 ul li:nth-child(4), .p-sosaiInheritance03 ul li:nth-child(5) {
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
  }
  .p-sosaiInheritance03 ul li p {
    padding: 17px 20px 15px;
    display: inherit;
  }
  .p-sosaiInheritance04 {
    padding: 50px 15px;
    margin-bottom: 57px;
  }
  .p-sosaiInheritance04 .c-sosaiTitle02b {
    margin-bottom: 25px;
  }
  .p-sosaiInheritance04 .u-inner01 > p {
    text-align: left;
    padding: 0 5px;
  }
  .p-sosaiInheritance04__list {
    margin-top: 32px;
  }
  .p-sosaiInheritance04__list__btn {
    width: 33.3333%;
    font-size: 1.3rem;
    padding: 10px 5px 8px;
  }
  .p-sosaiInheritance04__content {
    padding: 20px;
  }
  .p-sosaiInheritance04__content__adviser {
    margin-bottom: 20px;
    padding-bottom: 18px;
  }
  .p-sosaiInheritance04__content__adviser h3 {
    padding-left: 29px;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-bottom: 8px;
  }
  .p-sosaiInheritance04__content__adviser h3:before {
    width: 21px;
    height: 21px;
    margin: -5px 8px 0 -29px;
  }
  .p-sosaiInheritance04__content__adviser p {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  .p-sosaiInheritance04__content__adviser--01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiInheritance04__content__adviser--01 .adviser-img {
    margin: 0 auto 20px auto;
    display: block;
    position: static;
  }
  .p-sosaiInheritance04__content__adviser--02__left {
    width: 100%;
    margin-bottom: 20px;
  }
  .p-sosaiInheritance04__content__adviser--02__left .p-sosaiInheritance04__content__adviser__link {
    margin-top: 6px;
  }
  .p-sosaiInheritance04__content__adviser--02__right {
    width: 100%;
  }
  .p-sosaiInheritance04__content__adviser__tel {
    margin: 12px 0 13px;
  }
  .p-sosaiInheritance04__content__adviser__tel .tel-number {
    display: none;
  }
  .p-sosaiInheritance04__content__adviser__tel a {
    display: block;
    background-color: #2e95a3;
    padding: 15px 0;
    font-family: YuGothic, "Yu Gothic", sans-serif;
    font-weight: bold;
    box-shadow: 0px 3px 0px #20656e;
    border-radius: 5px;
  }
  .p-sosaiInheritance04__content__adviser__tel a p {
    width: 190px;
    margin: 0 auto;
    position: relative;
    padding-left: 40px;
    font-size: 1.8rem;
    color: #FFF;
    line-height: 1;
    text-align: left;
  }
  .p-sosaiInheritance04__content__adviser__tel a p:before {
    content: "";
    width: 25px;
    height: 25px;
    background: url("../img/common/icon_tel07.png") no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    top: calc(50% - 13px);
    left: 0;
  }
  .p-sosaiInheritance04__content__adviser__tel a p span {
    font-size: 1rem;
    line-height: 1;
    display: block;
    margin-top: 2px;
  }
  .p-sosaiInheritance04__content__adviser__tel2 {
    margin-top: 0;
  }
  .p-sosaiInheritance04__content__adviser__tel2 li {
    padding: 0;
    background-color: #FFF;
    margin-bottom: 7px;
  }
  .p-sosaiInheritance04__content__adviser__tel2 li a {
    background-color: #2e95a3;
    padding: 13px 0;
    font-family: YuGothic, "Yu Gothic", sans-serif;
    font-weight: bold;
    box-shadow: 0px 3px 0px #20656e;
    border-radius: 5px;
    color: #FFF;
    display: block;
    font-size: 1.8rem;
    text-align: left;
  }
  .p-sosaiInheritance04__content__adviser__tel2 li a div {
    width: 170px;
    margin: 0 auto;
    padding-left: 40px;
    display: block;
    position: relative;
  }
  .p-sosaiInheritance04__content__adviser__tel2 li a div:before {
    content: "";
    width: 25px;
    height: 25px;
    background: url("../img/common/icon_tel07.png") no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    top: calc(50% - 13px);
    left: 0;
  }
  .p-sosaiInheritance04__content__adviser__tel2 li a div p {
    font-size: 1rem;
    width: 100%;
    color: #FFF;
    margin-bottom: 1px;
  }
  .p-sosaiInheritance04__content__adviser__tel2 li a div p:before {
    display: none;
  }
  .p-sosaiInheritance04__content__adviser__tel2 li a div span {
    font-size: 1.6rem;
    margin-right: 7px;
    margin-top: 7px;
  }
  .p-sosaiInheritance04__content__adviser__time {
    font-size: 1.2rem;
    margin-top: 6px;
  }
  .p-sosaiInheritance04__content__adviser__time span {
    margin-left: -4px;
  }
  .p-sosaiInheritance04__content__adviser__time span em {
    font-size: 2.4rem;
    letter-spacing: 2px;
    line-height: 1;
  }
  .p-sosaiInheritance04__content__adviser__link {
    font-size: 1.2rem;
  }
  .p-sosaiInheritance04__content__adviser__link a {
    color: #2e95a3;
  }
  .p-sosaiInheritance04__content__adviser__link a:after {
    width: 10px;
    height: 10px;
  }
  .p-sosaiInheritance04__content__adviser__address {
    font-size: 1.3rem;
  }
  .p-sosaiInheritance04__content__adviser__address .c-sosaiBtn04 {
    margin-left: 5px;
  }
  .p-sosaiInheritance04__content__adviser__annotation {
    margin-top: 10px;
  }
  .p-sosaiInheritance04__content__adviser__annotation li {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: bold;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　葬儀後の手続き
------------------------------------------------------------*/
.p-sosaiAfter {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiAfter01 {
  min-width: 1120px;
  padding: 66px 0 67px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiAfter01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiAfter02 {
  margin-bottom: 120px;
  padding-top: 16px;
}
.p-sosaiAfter02__content01 {
  margin: 69px 0 78px;
}
.p-sosaiAfter02__content02 {
  margin-bottom: 60px;
}

.p-sosaiAfterProcedureList ul + ul {
  margin-top: -1px;
}
.p-sosaiAfterProcedureList ul li {
  padding: 39px 40px 33px;
  border-left: solid 1px #c8c8c8;
  border-bottom: solid 1px #c8c8c8;
}
.p-sosaiAfterProcedureList ul li h4 {
  margin-bottom: 18px;
  line-height: 1.4;
  font-weight: 700;
  color: #2e95a3;
}
.p-sosaiAfterProcedureList ul li dl {
  padding-bottom: 17px;
  margin-bottom: 24px;
  font-size: 1.6rem;
  border-bottom: dotted 1px #4c4c4c;
}
.p-sosaiAfterProcedureList ul li dl dt {
  font-weight: 700;
  padding-bottom: 4px;
}
.p-sosaiAfterProcedureList ul li dl dd {
  max-width: 72%;
}
.p-sosaiAfterProcedureList ul li p {
  font-size: 1.6rem;
  line-height: 1.75;
}
.p-sosaiAfterProcedureList__w1-2 {
  width: 50%;
}
.p-sosaiAfterProcedureList__w1-2:nth-child(even), .p-sosaiAfterProcedureList__w1-2:last-child {
  border-right: solid 1px #c8c8c8;
}
.p-sosaiAfterProcedureList__w1-2:nth-child(1), .p-sosaiAfterProcedureList__w1-2:nth-child(2) {
  border-top: solid 1px #c8c8c8;
}
.p-sosaiAfterProcedureList__w1-2 h4 {
  font-size: 2rem;
}
.p-sosaiAfterProcedureList__w1-3 {
  width: 33.3333333%;
  padding: 39px 40px 32px !important;
}
.p-sosaiAfterProcedureList__w1-3:nth-child(3n), .p-sosaiAfterProcedureList__w1-3:last-child {
  border-right: solid 1px #c8c8c8;
}
.p-sosaiAfterProcedureList__w1-3:nth-child(1), .p-sosaiAfterProcedureList__w1-3:nth-child(2), .p-sosaiAfterProcedureList__w1-3:nth-child(3) {
  border-top: solid 1px #c8c8c8;
}
.p-sosaiAfterProcedureList__w1-3 h4 {
  font-size: 1.8rem;
  margin-bottom: 22px !important;
}
.p-sosaiAfterProcedureList__label {
  margin: 0 0 0.2rem 0;
  padding: 0 10px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  display: block;
  transform: translate(0, 0.2rem);
}
.p-sosaiAfterProcedureList__label--red {
  background-color: #931719;
}
.p-sosaiAfterProcedureList__label--blue {
  background-color: #006da7;
}
.p-sosaiAfterProcedureList__label--green {
  background-color: #00a384;
}

.p-sosaiAfter03 {
  margin-bottom: 105px;
  padding: 105px 0 118px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}

.p-sosaiAfter04 {
  margin-bottom: 140px;
}
.p-sosaiAfter04 ul {
  padding-top: 6px;
}
.p-sosaiAfter04 ul li {
  width: 33.3333333%;
  padding: 35px 40px 33px;
  text-align: center;
  position: relative;
  border-top: solid 1px #c8c8c8;
  border-bottom: solid 1px #c8c8c8;
  border-left: solid 1px #c8c8c8;
}
.p-sosaiAfter04 ul li:last-child {
  border-right: solid 1px #c8c8c8;
}
.p-sosaiAfter04 ul li:after {
  width: 10px;
  height: 10px;
  content: "";
  background-image: url("../img/common/icon_button_sankaku02_blue.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  bottom: 3px;
  right: 2px;
}
.p-sosaiAfter04 ul li a {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: 700;
  color: #2e95a3;
  display: block;
}
.p-sosaiAfter04 ul li a img {
  margin: -4px 16px 0 -16px;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiAfter04 ul li p {
  font-size: 1.5rem;
  line-height: 1.75;
  text-align: left;
}

@media screen and (max-width: 640px) {
  .p-sosaiAfter {
    font-size: 1.4rem;
  }
  .p-sosaiAfter01 {
    min-width: 100%;
    margin-bottom: 42px;
    padding: 32px 0 33px;
  }
  .p-sosaiAfter02 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-sosaiAfter02__content01 {
    margin: 30px 0;
  }
  .p-sosaiAfter02__content02 {
    margin-bottom: 30px;
  }
  .p-sosaiAfterProcedureList ul li {
    margin-top: -1px;
    padding: 15px;
  }
  .p-sosaiAfterProcedureList ul li:first-child {
    margin-top: 0;
  }
  .p-sosaiAfterProcedureList ul li h4 {
    margin-bottom: 10px;
    font-size: 1.6rem !important;
  }
  .p-sosaiAfterProcedureList ul li dl {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .p-sosaiAfterProcedureList ul li dl dd {
    max-width: 67%;
  }
  .p-sosaiAfterProcedureList ul li p {
    font-size: 1.4rem;
  }
  .p-sosaiAfterProcedureList__w1-2, .p-sosaiAfterProcedureList__w1-3 {
    width: 100%;
    padding: 15px !important;
    border: none !important;
    border: solid 1px #c8c8c8 !important;
  }
  .p-sosaiAfterProcedureList__w1-2 h4, .p-sosaiAfterProcedureList__w1-3 h4 {
    font-size: 1.6rem;
    margin-bottom: 10px !important;
  }
  .p-sosaiAfterProcedureList__label {
    font-size: 1rem;
    padding: 0 5px;
  }
  .p-sosaiAfter03 {
    margin-bottom: 40px;
    padding: 40px 20px;
  }
  .p-sosaiAfter04 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-sosaiAfter04 ul li {
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    border: solid 1px #c8c8c8;
  }
  .p-sosaiAfter04 ul li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiAfter04 ul li a {
    margin-bottom: 15px;
    font-size: 1.7rem;
  }
  .p-sosaiAfter04 ul li p {
    font-size: 1.4rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　樹木葬
------------------------------------------------------------*/
.p-sosaiTimber {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiTimber01 {
  min-width: 1120px;
  padding: 43px 0 46px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiTimber01 > span {
  margin: 0 0 2px 0;
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiTimber01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiTimber02 {
  margin-bottom: 130px;
  padding-top: 25px;
}
.p-sosaiTimber02__content01 {
  min-height: 240px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiTimber02__content01 > img {
  position: absolute;
  top: 8px;
  right: 0;
}
.p-sosaiTimber02__content01 > p {
  margin-bottom: 30px;
  line-height: 3rem;
}

.p-sosaiTimber03 {
  margin-bottom: 107px;
  padding: 106px 0 120px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiTimber03 .c-sosaiTitle02b {
  padding-left: 10px;
  margin-bottom: 60px;
}
.p-sosaiTimber03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiTimber03__content01 .c-sosaiTextBox01 {
  background-color: #ffffff;
}
.p-sosaiTimber03__content01 .c-sosaiTextBox01 > div {
  padding: 36px 40px 32px;
}
.p-sosaiTimber03__content01 .c-sosaiTextBox01 > div .c-sosaiTitle10 {
  margin-bottom: 15px;
}
.p-sosaiTimber03__content01 .c-sosaiTextBox01 > div .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}
.p-sosaiTimber03__content01 .c-sosaiTextBox01 > div p {
  line-height: 3rem;
  padding-left: 2px;
}
.p-sosaiTimber03__content01 dl {
  margin-top: -1px;
  background-color: #f8f8f8;
  border: solid 1px #c8c8c8;
}
.p-sosaiTimber03__content01 dl dt {
  width: 146px;
  padding: 34px 20px 30px 0;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}
.p-sosaiTimber03__content01 dl dd {
  width: calc(100% - 146px);
  padding: 32px 0 30px;
  font-size: 1.4rem;
  line-height: 2.9rem;
}

.p-sosaiTimber04 {
  margin-bottom: 128px;
}
.p-sosaiTimber04 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiTimber04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}

.p-sosaiTimber05 {
  margin-bottom: 130px;
}
.p-sosaiTimber05 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiTimber05 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiTimber05 .c-sosaiAfterServiceContact > div a span {
  margin-left: -50px;
}

.p-sosaiTimber06 {
  padding: 107px 0 118px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiTimber06 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiTimber06 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiTimber06 .c-sosaiAfterServiceLink {
  padding-top: 0;
}

@media screen and (max-width: 640px) {
  .p-sosaiTimber {
    font-size: 1.6rem;
  }
  .p-sosaiTimber01 {
    min-width: 100%;
    margin-bottom: 35px;
    padding: 15px 0;
  }
  .p-sosaiTimber01 > span {
    font-size: 1.2rem;
    margin: 0 0 7px 0;
  }
  .p-sosaiTimber01 .c-sosaiPagetitleCate span {
    width: 37px;
    height: 16px;
    font-size: 1rem;
    line-height: 1.6rem;
  }
  .p-sosaiTimber01 .c-sosaiPagetitleCate__Shizuoka {
    width: 35px;
    margin-right: 0;
  }
  .p-sosaiTimber02 {
    margin-bottom: 45px;
    padding: 0 20px;
  }
  .p-sosaiTimber02__content01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiTimber02__content01 .sp-only {
    display: block;
  }
  .p-sosaiTimber02__content01 > img {
    margin: 0 auto 8px;
    position: static;
  }
  .p-sosaiTimber02__content01 > p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .p-sosaiTimber03 {
    margin-bottom: 47px;
    padding: 47px 20px 49px;
  }
  .p-sosaiTimber03 .c-sosaiTitle02b {
    padding-left: 5px;
    margin-bottom: 40px;
  }
  .p-sosaiTimber03 .c-sosaiTitle02b span:after {
    margin: 10px auto 0;
  }
  .p-sosaiTimber03__content01 .c-sosaiTextBox01 > div {
    padding: 15px 20px 13px;
  }
  .p-sosaiTimber03__content01 .c-sosaiTextBox01 > div .c-sosaiTitle10 {
    margin-bottom: 4px;
  }
  .p-sosaiTimber03__content01 .c-sosaiTextBox01 > div .c-sosaiTitle10:before {
    margin: -3px 5px 0 -26px;
  }
  .p-sosaiTimber03__content01 .c-sosaiTextBox01 > div p {
    font-size: 1.4rem;
    line-height: 1.7;
    padding-left: 0;
  }
  .p-sosaiTimber03__content01 dl dt {
    width: 100%;
    padding: 16px 19px 5px;
    font-size: 1.4rem;
    text-align: left;
  }
  .p-sosaiTimber03__content01 dl dd {
    width: 100%;
    padding: 4px 15px 15px 19px;
  }
  .p-sosaiTimber03__content01 dl dd p {
    font-size: 1.4rem;
    line-height: 1.7;
    padding-left: 13px;
    position: relative;
  }
  .p-sosaiTimber03__content01 dl dd p span {
    position: absolute;
    top: 0;
    left: 0;
  }
  .p-sosaiTimber03__content01 dl dd p + p {
    margin-top: 6px;
  }
  .p-sosaiTimber04 {
    margin-bottom: 78px;
    padding: 0 15px;
  }
  .p-sosaiTimber04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiTimber04 .c-sosaiTitle02b span:after {
    margin: 10px auto 0;
  }
  .p-sosaiTimber04 .c-sosaiFlow01 > li h3 {
    font-size: 1.8rem;
  }
  .p-sosaiTimber05 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiTimber05 .c-sosaiTitle02b {
    margin-bottom: 31px;
  }
  .p-sosaiTimber05 .c-sosaiTitle02b span:after {
    margin: 10px auto 0;
  }
  .p-sosaiTimber06 {
    padding: 47px 20px 56px;
    margin-bottom: 60px;
  }
  .p-sosaiTimber06 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiTimber06 .c-sosaiTitle02b span:after {
    margin: 10px auto 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　霊園
------------------------------------------------------------*/
.p-sosaiCemetery {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.75;
  color: #111111;
}

.p-sosaiCemetery01 {
  min-width: 1120px;
  padding: 42px 0 45px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiCemetery01 > span {
  margin: 0 0 2px 0;
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiCemetery01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiCemetery02 {
  margin: 25px 0 130px;
}
.p-sosaiCemetery02__content01 {
  min-height: 240px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiCemetery02__content01 > img {
  position: absolute;
  top: 7px;
  right: 0;
}
.p-sosaiCemetery02__content01 > p {
  margin-bottom: 30px;
  line-height: 1.8;
}

.p-sosaiCemetery03 {
  margin-bottom: 106px;
  padding: 103px 0 120px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiCemetery03 .c-sosaiTitle09 {
  margin-bottom: 19px;
}
.p-sosaiCemetery03__txt {
  text-align: center;
}
.p-sosaiCemetery03__content01 {
  margin: 69px 0 70px;
  padding-right: 440px;
  position: relative;
}
.p-sosaiCemetery03__content01 > img {
  position: absolute;
  top: 3px;
  right: 0;
}
.p-sosaiCemetery03__content02 {
  padding-right: 445px;
  position: relative;
}
.p-sosaiCemetery03__content02 > img {
  position: absolute;
  top: 90px;
  right: 0;
}
.p-sosaiCemetery03__content02 > p {
  margin-bottom: 34px;
}
.p-sosaiCemetery03__content02 .c-sosaiTextBox01 {
  width: 660px;
  background-color: #ffffff;
}
.p-sosaiCemetery03__content02 .c-sosaiTextBox01 > div {
  padding: 34px 42px 25px;
}
.p-sosaiCemetery03__content02 .c-sosaiTextBox01 h4 {
  margin-bottom: 18px;
  font-size: 1.8rem;
  font-weight: 700;
}
.p-sosaiCemetery03__content02 .c-sosaiTextBox01 ul li {
  margin-bottom: 7px;
  padding-left: 18px;
  font-size: 1.6rem;
}
.p-sosaiCemetery03__content02 .c-sosaiTextBox01 ul li:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}

.p-sosaiCemetery04 {
  margin-bottom: 127px;
}
.p-sosaiCemetery04 .c-sosaiTitle02b {
  margin-bottom: 59px;
}
.p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(5) p {
  padding: 59px 0 0;
}
.p-sosaiCemetery04 .c-sosaiFlow01 li:after {
  bottom: -24px;
}

.p-sosaiCemetery05 {
  margin-bottom: 130px;
}
.p-sosaiCemetery05 .c-sosaiTitle02b {
  margin-bottom: 59px;
}
.p-sosaiCemetery05 .c-sosaiAfterServiceContact > div a span {
  margin-left: -50px;
}

.p-sosaiCemetery06 {
  padding: 105px 0 118px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}

@media screen and (max-width: 640px) {
  .p-sosaiCemetery {
    font-size: 1.4rem;
  }
  .p-sosaiCemetery01 {
    min-width: 100%;
    margin-bottom: 50px;
    padding: 15px 0;
  }
  .p-sosaiCemetery01 > span {
    font-size: 1.2rem;
    margin: 0 0 7px 0;
  }
  .p-sosaiCemetery01 .c-sosaiPagetitleCate {
    margin-top: 3px;
  }
  .p-sosaiCemetery01 .c-sosaiPagetitleCate span {
    width: 35px;
    height: auto;
    font-size: 1rem;
    line-height: 15px;
    padding: 1px 0 0;
  }
  .p-sosaiCemetery02 {
    margin-bottom: 42px;
    padding: 0 20px;
  }
  .p-sosaiCemetery02__content01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiCemetery02__content01 > img {
    margin: 10px auto 17px;
    display: block;
    position: static;
  }
  .p-sosaiCemetery02__content01 p {
    line-height: 1.6;
  }
  .p-sosaiCemetery03 {
    margin-bottom: 46px;
    padding: 50px 20px;
  }
  .p-sosaiCemetery03__txt {
    text-align: left;
    line-height: 1.6;
  }
  .p-sosaiCemetery03 .c-sosaiTitle09 {
    margin-bottom: 16px;
  }
  .p-sosaiCemetery03__content01 {
    margin: 32px 0 31px 0;
    padding-right: 0;
    position: static;
  }
  .p-sosaiCemetery03__content01 > img {
    margin: 10px auto 17px;
    display: block;
    position: static;
  }
  .p-sosaiCemetery03__content01 p {
    line-height: 1.6;
  }
  .p-sosaiCemetery03__content02 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiCemetery03__content02 > img {
    margin: 10px auto 17px;
    display: block;
    position: static;
  }
  .p-sosaiCemetery03__content02 > p {
    margin-bottom: 25px;
    line-height: 1.6;
  }
  .p-sosaiCemetery03__content02 .c-sosaiTextBox01 {
    width: 100%;
    background-color: #ffffff;
  }
  .p-sosaiCemetery03__content02 .c-sosaiTextBox01 > div {
    padding: 15px 20px 5px;
  }
  .p-sosaiCemetery03__content02 .c-sosaiTextBox01 h4 {
    margin-bottom: 11px;
    font-size: 1.3rem;
  }
  .p-sosaiCemetery03__content02 .c-sosaiTextBox01 ul li {
    padding-left: 13px;
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  .p-sosaiCemetery03__content02 .c-sosaiTextBox01 ul li:before {
    margin: -3px 8px 0 -13px;
  }
  .p-sosaiCemetery04 {
    margin-bottom: 78px;
    padding: 0 15px;
  }
  .p-sosaiCemetery04 .c-sosaiTitle02b {
    margin-bottom: 39px;
  }
  .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(1) {
    padding: 21px 15px 7px 55px;
  }
  .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(2), .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(3), .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(4), .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(5), .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(6) {
    padding: 20px 15px 10px 55px;
  }
  .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(2) p, .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(3) p, .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(4) p, .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(5) p, .p-sosaiCemetery04 .c-sosaiFlow01 li:nth-child(6) p {
    padding: 0;
  }
  .p-sosaiCemetery05 {
    margin-bottom: 48px;
    padding: 0 15px;
  }
  .p-sosaiCemetery05 .c-sosaiTitle02b {
    margin-bottom: 30px;
  }
  .p-sosaiCemetery06 {
    padding: 50px 20px 59px;
    margin-bottom: 59px;
  }
  .p-sosaiCemetery06 .c-sosaiAfterServiceLink li {
    margin-bottom: 11px;
    padding: 22px 5px 5px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　墓石
------------------------------------------------------------*/
.p-sosaiTombstone {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiTombstone01 {
  min-width: 1120px;
  padding: 43px 0;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiTombstone01 > span {
  margin: 0 0 5px 0;
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiTombstone01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiTombstone02 {
  margin-bottom: 127px;
  padding-top: 25px;
}
.p-sosaiTombstone02__content01 {
  min-height: 240px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiTombstone02__content01 > img {
  position: absolute;
  top: 8px;
  right: 0;
}
.p-sosaiTombstone02__content01 > p {
  margin-bottom: 30px;
  line-height: 3rem;
}
.p-sosaiTombstone02__content01 > p a {
  color: #111111;
  text-decoration: underline;
}

.p-sosaiTombstone03 {
  margin-bottom: 106px;
  padding: 107px 0 50px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiTombstone03 ol {
  padding-top: 53px;
}
.p-sosaiTombstone03 ol li {
  width: 353px;
  margin: 0 30px 70px 0;
  padding: 32px 40px;
  background-color: #ffffff;
  border: solid 2px #2e95a3;
}
.p-sosaiTombstone03 ol li:nth-child(3) {
  margin: 0 0 70px 0;
  padding: 32px 20px;
}
.p-sosaiTombstone03 ol li:nth-child(3) > img, .p-sosaiTombstone03 ol li:nth-child(3) h3 {
  padding-left: 17px;
}
.p-sosaiTombstone03 ol li:nth-child(3) p {
  padding: 0 22px;
}
.p-sosaiTombstone03 ol li span {
  margin-top: -83px;
  margin-bottom: 32px;
  display: block;
  text-align: center;
}
.p-sosaiTombstone03 ol li > img {
  margin: 0 auto 29px auto;
  display: block;
}
.p-sosaiTombstone03 ol li h3 {
  margin-bottom: 20px;
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1.15;
  color: #2e95a3;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiTombstone03 ol li p {
  text-align: left;
  line-height: 3rem;
}
.p-sosaiTombstone03 ol li p b {
  font-weight: bold;
}

.p-sosaiTombstone04 {
  margin-bottom: 120px;
}
.p-sosaiTombstone04 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiTombstone04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiTombstone04 .c-sosaiAfterServiceContact > div a span {
  margin-left: -50px;
}

.p-sosaiTombstone05 {
  padding: 107px 0 118px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiTombstone05 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}

@media screen and (max-width: 640px) {
  .p-sosaiTombstone {
    font-size: 1.6rem;
  }
  .p-sosaiTombstone01 {
    min-width: 100%;
    margin-bottom: 49px;
    padding: 15px 0;
  }
  .p-sosaiTombstone01 > span {
    font-size: 1.2rem;
  }
  .p-sosaiTombstone01 .c-sosaiPagetitleCate span {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-top: 9px;
    margin-right: 0;
    height: 16px;
    width: 36px;
  }
  .p-sosaiTombstone02 {
    margin-bottom: 48px;
    padding: 0 20px;
  }
  .p-sosaiTombstone02__content01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiTombstone02__content01 > img {
    margin: 0 auto 16px;
    display: block;
    position: static;
  }
  .p-sosaiTombstone02__content01 > p {
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 0;
  }
  .p-sosaiTombstone03 {
    margin-bottom: 47px;
    padding: 48px 20px 49px;
  }
  .p-sosaiTombstone03 .c-sosaiTitle02b {
    margin-bottom: 39px;
  }
  .p-sosaiTombstone03 ol {
    margin: 20px 0 0;
    padding-top: 20px;
  }
  .p-sosaiTombstone03 ol li {
    width: 100%;
    height: auto;
    margin-bottom: 39px;
    padding: 18px 18px 15px;
    font-size: 1.3rem;
  }
  .p-sosaiTombstone03 ol li:nth-child(3) {
    margin-bottom: 0;
    padding: 18px 18px 15px;
  }
  .p-sosaiTombstone03 ol li:nth-child(3) > img, .p-sosaiTombstone03 ol li:nth-child(3) h3 {
    padding-left: 0;
  }
  .p-sosaiTombstone03 ol li:nth-child(3) p {
    padding: 0;
  }
  .p-sosaiTombstone03 ol li span {
    margin-top: -50px;
    margin-bottom: 21px;
    display: block;
    text-align: center;
  }
  .p-sosaiTombstone03 ol li span img {
    width: 60px;
  }
  .p-sosaiTombstone03 ol li > img {
    margin-bottom: 18px;
  }
  .p-sosaiTombstone03 ol li h3 {
    margin-bottom: 14px;
    font-size: 2rem;
  }
  .p-sosaiTombstone03 ol li p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiTombstone04 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiTombstone04 .c-sosaiTitle02b {
    margin-bottom: 29px;
  }
  .p-sosaiTombstone04 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiTombstone04 .c-sosaiAfterServiceContact a > div {
    padding: 8px 0 9px 81px;
  }
  .p-sosaiTombstone04 .c-sosaiAfterServiceContact a > div:before {
    top: 9px;
  }
  .p-sosaiTombstone05 {
    padding: 47px 20px 55px;
    margin-bottom: 60px;
  }
  .p-sosaiTombstone05 .c-sosaiTitle02b {
    margin-bottom: 34px;
  }
  .p-sosaiTombstone05 .c-sosaiTitle02b span:after {
    margin: 11px auto 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　海洋散骨
------------------------------------------------------------*/
.p-sosaiSea {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiSea01 {
  min-width: 1120px;
  padding: 43px 0;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiSea01 > span {
  margin: 0 0 5px 0;
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSea01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiSea02 {
  margin-bottom: 129px;
  padding-top: 25px;
}
.p-sosaiSea02__content01 {
  min-height: 240px;
  padding-right: 444px;
  position: relative;
}
.p-sosaiSea02__content01 > img {
  position: absolute;
  top: 8px;
  right: 0;
}
.p-sosaiSea02__content01 > p {
  margin-bottom: 30px;
  line-height: 3rem;
}

.p-sosaiSea03 {
  margin-bottom: 107px;
  padding: 107px 0 120px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiSea03 .c-sosaiTitle02b {
  margin-bottom: 53px;
}
.p-sosaiSea03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiSea03__content01 {
  margin: 72px 0 40px 0;
}
.p-sosaiSea03__content01__plan dl {
  width: 540px;
  background-color: #ffffff;
  border: solid 1px #333745;
}
.p-sosaiSea03__content01__plan dl dt {
  padding: 15px 10px 13px 64px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.3;
  color: #ffffff;
  background-color: #333745;
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSea03__content01__plan dl dt:before {
  width: 30px;
  height: 30px;
  content: "";
  background-image: url("../img/sosai/icon_sosai01.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 14px;
  left: 20px;
}
.p-sosaiSea03__content01__plan dl dd {
  padding: 38px 50px 50px;
}
.p-sosaiSea03__content01__plan dl dd > *:last-child {
  margin-bottom: 0;
}
.p-sosaiSea03__content01__plan__price {
  margin-bottom: 26px;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSea03__content01__plan__price p {
  font-size: 2.2rem;
  display: inline-block;
  vertical-align: 15px;
}
.p-sosaiSea03__content01__plan__price em {
  font-size: 6.6rem;
  margin-right: 5px;
  line-height: 1;
  padding-top: 7px;
  display: inline-block;
  color: #f2385a;
}
@media all and (-ms-high-contrast: none) {
  .p-sosaiSea03__content01__plan__price em {
    transform: translateY(3px);
  }
}
.p-sosaiSea03__content01__plan__price span {
  margin: 0 2px;
  font-size: 2.7rem;
  font-weight: 500;
  display: inline-block;
}
.p-sosaiSea03__content01__plan__price--sub {
  display: flex !important;
  align-items: center !important;
  font-size: 3.5rem !important;
  transform: translateX(-10px);
}
.p-sosaiSea03__content01__plan__price--sub .small {
  font-size: 2.7rem;
}
.p-sosaiSea03__content01__plan__txt {
  margin-bottom: 23px;
  padding: 26px 10px 25px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-top: dotted 1px #333745;
  border-bottom: dotted 1px #333745;
}
.p-sosaiSea03__content01__plan__list {
  margin-bottom: 21px;
}
.p-sosaiSea03__content01__plan__list li {
  margin-bottom: 8px;
  padding: 0 0 0 28px;
  font-size: 1.5rem;
  font-weight: 700;
}
.p-sosaiSea03__content01__plan__list li:last-child {
  margin-bottom: 0;
}
.p-sosaiSea03__content01__plan__list li:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiSea03__content01__plan__option {
  padding: 25px 31px 23px;
  background-color: #f8f8f8;
  border: solid 1px #c8c8c8;
}
.p-sosaiSea03__content01__plan__option p {
  margin-bottom: 8px;
  font-size: 1.6rem;
  font-weight: 700;
}
.p-sosaiSea03__content01__plan__option ul {
  font-size: 1.4rem;
  font-weight: 700;
}
.p-sosaiSea03__content01__plan__option ul li {
  width: 50%;
}
.p-sosaiSea03__content01__plan__option ul li:before {
  width: 6px;
  height: 6px;
  margin: -4px 13px 0 0;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.p-sosaiSea03__content02 {
  padding: 27px 30px 25px;
  font-size: 1.2rem;
  background-color: #f8f8f8;
  border: solid 1px #c8c8c8;
}
.p-sosaiSea03__content02 h3 {
  font-size: 1.2rem;
  margin-bottom: 11px;
  font-weight: 500;
}
.p-sosaiSea03__content02 ol li {
  padding-left: 18px;
  font-weight: 700;
  margin-bottom: -1px;
}
.p-sosaiSea03__content02 ol li:before {
  width: 19px;
  margin-left: -18px;
  content: counter(count01) ".";
  display: inline-block;
}

.p-sosaiSea04 {
  margin-bottom: 127px;
}
.p-sosaiSea04 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiSea04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiSea04 .c-sosaiFlow01 li {
  padding: 0 55px 0 0;
  align-items: flex-start;
}
.p-sosaiSea04 .c-sosaiFlow01 li:nth-child(odd) {
  min-height: 150px;
}
.p-sosaiSea04 .c-sosaiFlow01 li:nth-child(2) p {
  padding: 44px 0 2px 0;
}
.p-sosaiSea04 .c-sosaiFlow01 li:nth-child(3) p {
  padding: 56px 0 8px 0;
}
.p-sosaiSea04 .c-sosaiFlow01 li:nth-child(4) p {
  padding: 45px 0 0 0;
}
.p-sosaiSea04 .c-sosaiFlow01 li:nth-child(5) p {
  padding: 44px 0 56px 0;
}
.p-sosaiSea04 .c-sosaiFlow01 li:nth-child(6) h3 {
  display: block;
  padding: 51px 0 0 119px;
}
.p-sosaiSea04 .c-sosaiFlow01 li:nth-child(6) p {
  padding: 45px 0 0 0;
}
.p-sosaiSea04 .c-sosaiFlow01 li:after {
  bottom: -23px;
}
.p-sosaiSea04 .c-sosaiFlow01 li h3 {
  padding: 62px 0 0 119px;
}
.p-sosaiSea04 .c-sosaiFlow01 li h3 span {
  display: block;
  font-size: 1.6rem;
}
.p-sosaiSea04 .c-sosaiFlow01 li p {
  padding: 42px 0 48px;
}

.p-sosaiSea05 {
  margin-bottom: 130px;
}
.p-sosaiSea05 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiSea05 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiSea05 .c-sosaiAfterServiceContact > div {
  padding: 42px 30px 39px;
}
.p-sosaiSea05 .c-sosaiAfterServiceContact > div a span {
  margin-left: -60px;
}

.p-sosaiSea06 {
  padding: 107px 0 118px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiSea06 .c-sosaiTitle02b {
  margin-bottom: 55px;
}
.p-sosaiSea06 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}

@media screen and (max-width: 640px) {
  .p-sosaiSea {
    font-size: 1.6rem;
  }
  .p-sosaiSea01 {
    min-width: 100%;
    margin-bottom: 49px;
    padding: 15px 0;
  }
  .p-sosaiSea01 > span {
    font-size: 1.2rem;
  }
  .p-sosaiSea01 .c-sosaiPagetitleCate span {
    font-size: 1rem;
    line-height: 1.6rem;
    margin-top: 9px;
    margin-right: 0;
    height: 16px;
    width: 36px;
  }
  .p-sosaiSea02 {
    margin-bottom: 0;
    padding: 0 20px;
  }
  .p-sosaiSea02__content01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiSea02__content01 > img {
    margin: 0 auto 16px;
    display: block;
    position: static;
  }
  .p-sosaiSea02__content01 > p {
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 0;
  }
  .p-sosaiSea03 {
    margin-bottom: 69px;
    padding: 48px 20px;
    margin-top: 40px;
  }
  .p-sosaiSea03 .c-sosaiTitle02b {
    margin-bottom: 25px;
  }
  .p-sosaiSea03 .c-sosaiTitle02b span:after {
    margin: 10px auto 0;
  }
  .p-sosaiSea03 .u-txtCenter {
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiSea03__content01 {
    margin: 18px 0 20px 0;
  }
  .p-sosaiSea03__content01__plan dl {
    width: 100%;
    margin-bottom: 15px;
  }
  .p-sosaiSea03__content01__plan dl dt {
    padding: 0px 0px 0 7px;
    font-size: 1.2rem;
    text-align: center;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-sosaiSea03__content01__plan dl dt:before {
    width: 15px;
    height: 15px;
    display: inline-block;
    position: static;
    margin-right: 7px;
  }
  .p-sosaiSea03__content01__plan dl dd {
    padding: 15px;
  }
  .p-sosaiSea03__content01__plan__price {
    margin-bottom: 14px;
    font-size: 1.2rem;
    text-align: center;
    padding-right: 25px;
  }
  .p-sosaiSea03__content01__plan__price p {
    font-size: 1.1rem;
    vertical-align: 6px;
  }
  .p-sosaiSea03__content01__plan__price em {
    font-size: 3.3rem;
    margin-right: 2px;
    padding-top: 0;
  }
  .p-sosaiSea03__content01__plan__price span {
    font-size: 2rem;
  }
  .p-sosaiSea03__content01__plan__price--sub {
    font-size: 2.4rem !important;
    transform: translateX(0);
  }
  .p-sosaiSea03__content01__plan__price--sub .small {
    font-size: 2rem;
  }
  .p-sosaiSea03__content01__plan__txt {
    margin-bottom: 10px;
    padding: 11px 5px 10px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiSea03__content01__plan__list {
    margin-bottom: 13px;
  }
  .p-sosaiSea03__content01__plan__list li {
    margin-bottom: 4px;
    padding: 0 0 0 22px;
    font-size: 1.4rem;
  }
  .p-sosaiSea03__content01__plan__list li:before {
    margin: -3px 7px 0 -13px;
  }
  .p-sosaiSea03__content01__plan__option {
    padding: 17px 19px 9px;
    margin: 0 4px;
  }
  .p-sosaiSea03__content01__plan__option p {
    margin-bottom: 10px;
    font-size: 1.4rem;
    margin-bottom: 6px;
  }
  .p-sosaiSea03__content01__plan__option ul {
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 700;
  }
  .p-sosaiSea03__content01__plan__option ul li {
    float: none;
    width: 100%;
    margin-bottom: 4px;
  }
  .p-sosaiSea03__content01__plan__option ul li:before {
    margin: -4px 5px 0 0;
  }
  .p-sosaiSea03__content02 {
    padding: 15px 20px 14px;
    background-color: #f8f8f8;
    border: solid 1px #c8c8c8;
  }
  .p-sosaiSea03__content02 h3 {
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 12px;
    font-weight: 500;
  }
  .p-sosaiSea03__content02 ol li {
    padding-left: 20px;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 0px;
  }
  .p-sosaiSea03__content02 ol li:before {
    width: 20px;
    margin-left: -20px;
    content: counter(count01) ".";
    display: inline-block;
  }
  .p-sosaiSea04 {
    margin-bottom: 60px;
    padding: 0 15px;
  }
  .p-sosaiSea04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiSea04 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li {
    padding: 22px 12px 10px 56px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(odd) {
    min-height: auto;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(2) {
    padding: 22px 12px 10px 51px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(2) h3 img {
    left: -36px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(2) p {
    padding: 0 0 0 5px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(3) p {
    padding: 0;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(4) p {
    padding: 0;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(5) {
    padding: 25px 12px 10px 56px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(5) h3 {
    padding: 0 0 8px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(5) p {
    padding: 0;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(6) {
    padding: 18px 12px 17px 56px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(6) h3 {
    padding: 0 0 8px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(6) h3 span {
    font-size: 1rem;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li:nth-child(6) p {
    padding: 0;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li h3 {
    padding: 0 0 8px;
  }
  .p-sosaiSea04 .c-sosaiFlow01 li p {
    padding: 0;
  }
  .p-sosaiSea05 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiSea05 .c-sosaiTitle02b {
    margin-bottom: 29px;
  }
  .p-sosaiSea05 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiSea05 .c-sosaiAfterServiceContact > div {
    padding: 20px 19px 19px;
  }
  .p-sosaiSea06 {
    padding: 47px 20px 55px;
    margin-bottom: 128px;
  }
  .p-sosaiSea06 .c-sosaiTitle02b {
    margin-bottom: 35px;
  }
  .p-sosaiSea06 .c-sosaiTitle02b span:after {
    margin: 10px auto 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　改葬
------------------------------------------------------------*/
.p-sosaiReburial {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiReburial01 {
  min-width: 1120px;
  padding: 43px 0 47px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiReburial01 > span {
  margin: 0 0 1px 0;
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiReburial01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiReburial02 {
  margin: 25px 0 125px;
}
.p-sosaiReburial02__content01 {
  min-height: 240px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiReburial02__content01 > img {
  position: absolute;
  top: 7px;
  right: 0;
}
.p-sosaiReburial02__content01 > p {
  line-height: 1.8;
  margin-bottom: 30px;
}

.p-sosaiReburial03 {
  margin-bottom: 106px;
  padding: 107px 0 113px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiReburial03__content01 {
  padding-top: 6px;
}
.p-sosaiReburial03__content01 ul {
  margin-bottom: 34px;
}
.p-sosaiReburial03__content01 ul li {
  width: 33.333333%;
  height: 124px;
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: #ffffff;
  display: table;
}
.p-sosaiReburial03__content01 ul li:after {
  width: 10px;
  height: 10px;
  content: "";
  background-image: url("../img/common/icon_sankaku_left_top_brown01.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 2px;
}
.p-sosaiReburial03__content01 ul li:nth-child(1) {
  height: 96px;
  border: solid 1px #c8c8c8;
}
.p-sosaiReburial03__content01 ul li:nth-child(2), .p-sosaiReburial03__content01 ul li:nth-child(3) {
  height: 96px;
  border-top: solid 1px #c8c8c8;
  border-bottom: solid 1px #c8c8c8;
  border-right: solid 1px #c8c8c8;
}
.p-sosaiReburial03__content01 ul li:nth-child(4) {
  border-bottom: solid 1px #c8c8c8;
  border-left: solid 1px #c8c8c8;
  border-right: solid 1px #c8c8c8;
}
.p-sosaiReburial03__content01 ul li:nth-child(5), .p-sosaiReburial03__content01 ul li:nth-child(6) {
  border-bottom: solid 1px #c8c8c8;
  border-right: solid 1px #c8c8c8;
}
.p-sosaiReburial03__content01 ul li p {
  padding: 0 40px;
  display: table-cell;
  vertical-align: middle;
}
.p-sosaiReburial03__txt {
  text-align: center;
}

.p-sosaiReburial04 {
  margin-bottom: 116px;
}
.p-sosaiReburial04 .c-sosaiTitle02b {
  margin-bottom: 59px;
}
.p-sosaiReburial04 .c-sosaiFlow01 {
  margin-bottom: 52px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:after {
  bottom: -23px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li p {
  padding: 60px 0 61px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(3) p {
  padding: 56px 0 0;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(4) p {
  padding: 41px 0 48px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(5) p {
  padding: 44px 0 41px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(6) p {
  padding: 44px 0 41px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(7) p, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(8) p {
  padding: 56px 0 0;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(4) h3 {
  padding-top: 107px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(4) img {
  top: 95px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(5) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(6) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(8) h3 {
  padding-top: 48px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(7) h3 {
  padding-top: 59px;
}
.p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(6) h3 span {
  font-size: 1.6rem;
}
.p-sosaiReburial04__txt {
  line-height: 1.8;
  text-align: center;
}

.p-sosaiReburial05 {
  margin-bottom: 130px;
}
.p-sosaiReburial05 .c-sosaiTitle02b {
  margin-bottom: 60px;
}

.p-sosaiReburial06 {
  padding: 105px 0 118px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}

@media screen and (max-width: 640px) {
  .p-sosaiReburial {
    font-size: 1.4rem;
  }
  .p-sosaiReburial01 {
    min-width: 100%;
    margin-bottom: 48px;
    padding: 15px 0;
  }
  .p-sosaiReburial01 > span {
    font-size: 1.2rem;
    margin: 0 0 6px 0;
  }
  .p-sosaiReburial01 .c-sosaiPagetitleCate {
    padding-top: 4px;
  }
  .p-sosaiReburial01 .c-sosaiPagetitleCate span {
    font-size: 1rem;
    line-height: 15px;
    padding: 1px 3px 0;
  }
  .p-sosaiReburial01 .c-sosaiPagetitleCate__Kanagawa {
    width: auto;
    height: auto;
  }
  .p-sosaiReburial02 {
    margin-bottom: 42px;
    padding: 0 20px;
  }
  .p-sosaiReburial02__content01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiReburial02__content01 > img {
    margin: 0 auto 17px;
    display: block;
    position: static;
  }
  .p-sosaiReburial02__content01 p {
    line-height: 1.6;
  }
  .p-sosaiReburial03 {
    margin-bottom: 47px;
    padding: 50px 20px 45px;
  }
  .p-sosaiReburial03__content01 ul {
    margin-bottom: 27px;
  }
  .p-sosaiReburial03__content01 ul li {
    width: 100% !important;
    height: auto !important;
    font-size: 1.3rem;
    line-height: 1.5;
    display: inherit;
    border: solid 1px #c8c8c8;
    border-bottom: none !important;
  }
  .p-sosaiReburial03__content01 ul li:last-child {
    border-bottom: solid 1px #c8c8c8 !important;
  }
  .p-sosaiReburial03__content01 ul li p {
    padding: 15px 20px;
    display: inherit;
  }
  .p-sosaiReburial03__txt {
    text-align: left;
    line-height: 1.6;
  }
  .p-sosaiReburial04 {
    margin-bottom: 70px;
    padding: 0 15px;
  }
  .p-sosaiReburial04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 {
    margin-bottom: 36px;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li {
    min-height: auto;
    padding: 20px 15px 11px 55px;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li p {
    font-size: 1.3rem;
    line-height: 1.6;
    padding: 8px 0 0;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li h3 {
    padding: 0;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(1) {
    padding: 21px 15px 12px 55px;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(4) {
    padding: 21px 15px 15px 55px;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(2) p, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(3) p, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(4) p, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(5) p, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(6) p, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(7) p, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(8) p {
    padding: 9px 0 0;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(2) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(3) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(4) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(5) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(6) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(7) h3, .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(8) h3 {
    padding: 0;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(8) {
    padding: 21px 15px 16px 55px;
  }
  .p-sosaiReburial04 .c-sosaiFlow01 > li:nth-child(4) h3 img {
    top: -8px;
  }
  .p-sosaiReburial04__txt {
    text-align: left;
    line-height: 1.6;
    padding: 0 5px;
  }
  .p-sosaiReburial05 {
    margin-bottom: 48px;
    padding: 0 15px;
  }
  .p-sosaiReburial05 .c-sosaiTitle02b {
    margin-bottom: 30px;
  }
  .p-sosaiReburial06 {
    padding: 50px 20px 58px;
    margin-bottom: 60px;
  }
  .p-sosaiReburial06 .c-sosaiAfterServiceLink li {
    padding: 20px 5px 5px;
  }
  .p-sosaiReburial06 .c-sosaiAfterServiceLink li div {
    padding: 6px 0 6px 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　グリーフ(悲嘆)とは
------------------------------------------------------------*/
.p-sosaiGrief {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiGrief01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiGrief01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiGrief02 {
  margin-bottom: 140px;
  padding-top: 25px;
}
.p-sosaiGrief02 p {
  line-height: 3rem;
}
.p-sosaiGrief02__content01 {
  margin-bottom: 92px;
}
.p-sosaiGrief02__content01 img {
  margin-left: 40px;
  margin-top: 8px;
}
.p-sosaiGrief02__content02 {
  margin-bottom: 95px;
}
.p-sosaiGrief02__content02 img {
  margin-right: 40px;
  margin-top: 8px;
}
.p-sosaiGrief02__content03 > p {
  margin-bottom: 33px;
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-weight: 500;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiGrief02__content03 .c-sosaiTextBox01 > div {
  padding: 31px 35px 30px;
}
.p-sosaiGrief02__content03 .c-sosaiTextBox01 > div:not(:first-of-type) {
  padding: 28px 35px 28px;
}
.p-sosaiGrief02__content03__txt {
  font-size: 1.6rem;
  font-weight: 700;
  background-color: #f6f6f6;
  text-align: center;
}
.p-sosaiGrief02__content03__txt p {
  line-height: 2.8rem;
}
.p-sosaiGrief02__content03__tel a {
  display: block;
  text-align: center;
  font-size: 3.6rem;
  line-height: 1;
  color: #111;
  font-weight: normal;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiGrief02__content03__tel a > div {
  position: relative;
}
.p-sosaiGrief02__content03__tel a > div:before {
  width: 26px;
  height: 26px;
  margin: -10px 0 0 0;
  content: "";
  background-image: url("../img/common/icon_tel06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiGrief02__content03__tel a span {
  display: block;
  font-size: 1.6rem;
  line-height: 1;
  margin-top: 9px;
  padding-left: 10px;
}

@media screen and (max-width: 640px) {
  .p-sosaiGrief {
    font-size: 1.4rem;
  }
  .p-sosaiGrief01 {
    min-width: 100%;
    margin-bottom: 42px;
    padding: 32px 0 33px;
  }
  .p-sosaiGrief02 {
    margin-bottom: 61px;
    padding: 0 15px;
  }
  .p-sosaiGrief02 p {
    font-size: 1.4rem;
    line-height: 1.7;
    padding: 0 7px;
  }
  .p-sosaiGrief02__content01 {
    margin-bottom: 36px;
  }
  .p-sosaiGrief02__content01 img {
    margin: 0 auto 27px auto;
    display: block;
    float: none;
  }
  .p-sosaiGrief02__content02 {
    margin-bottom: 29px;
  }
  .p-sosaiGrief02__content02 img {
    margin: 0 auto 27px auto;
    display: block;
    float: none;
  }
  .p-sosaiGrief02__content03 > p {
    margin-bottom: 24px;
    font-size: 1.8rem;
    line-height: 2.8rem;
    text-align: left;
    padding: 0 8px;
  }
  .p-sosaiGrief02__content03 .c-sosaiTextBox01 > div {
    padding: 18px 5px 13px;
  }
  .p-sosaiGrief02__content03 .c-sosaiTextBox01 > div:not(:first-of-type) {
    padding: 16px 14px;
  }
  .p-sosaiGrief02__content03__txt {
    text-align: center;
    font-weight: bold;
  }
  .p-sosaiGrief02__content03__txt p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 0;
  }
  .p-sosaiGrief02__content03__tel a {
    width: 100%;
    padding: 9px 0 8px;
    font-size: 1.8rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #2e95a3;
    border-radius: 3px;
    text-align: left;
    font-family: YuGothic, "Yu Gothic", sans-serif;
    letter-spacing: 0.2px;
    box-shadow: 0 3px 0 #20656e;
  }
  .p-sosaiGrief02__content03__tel a > div {
    width: 210px;
    margin: 0 auto;
    padding-left: 44px;
  }
  .p-sosaiGrief02__content03__tel a > div:before {
    width: 25px;
    height: 25px;
    content: "";
    background-image: url("../img/common/icon_tel07.png");
    -webkit-background-size: cover;
    background-size: cover;
    position: absolute;
    top: 19px;
    left: 5px;
  }
  .p-sosaiGrief02__content03__tel a span {
    font-size: 1rem;
    line-height: 1.3rem;
    display: block;
    padding-left: 0;
    margin-top: -1px;
    letter-spacing: 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭 コープの終活応援
------------------------------------------------------------*/
.p-sosaiSeminar {
  background-color: #ffffff;
  font-size: 1.8rem;
  line-height: 1.77;
  color: #111111;
}

.p-sosaiSeminar01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiSeminar01 .c-sosaiTitle03 {
  margin: 0;
}
.p-sosaiSeminar01 .c-sosaiTitle03 img {
  margin-top: 6px;
}

.p-sosaiSeminar02 {
  margin: 18px 0 112px;
}
.p-sosaiSeminar02 .c-sosaiTitle02b {
  margin-bottom: 50px;
}
.p-sosaiSeminar02 .c-sosaiTitle02b span:after {
  margin-top: 5px;
}
.p-sosaiSeminar02 > p {
  text-align: center;
  font-size: 1.7rem;
}

.p-sosaiSeminar03 {
  margin-bottom: 140px;
}
.p-sosaiSeminar03 .c-supplement {
  text-align: center;
}
.p-sosaiSeminar03__list__btn {
  width: 372px;
  margin-right: 1px;
  padding: 17px 15px 15px;
  color: #777777;
  background-color: #e6e6e6;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}
.p-sosaiSeminar03__list__btn:last-child {
  margin-right: 0;
}
.p-sosaiSeminar03__list__btn--yamanashi {
  border-top: solid 2px #006da7;
}
.p-sosaiSeminar03__list__btn--kanagawa {
  border-top: solid 2px #00a384;
}
.p-sosaiSeminar03__list__btn--shizuoka {
  border-top: solid 2px #931719;
}
.p-sosaiSeminar03__list__btn.is-open {
  color: #111111;
  background-color: #ffffff;
  opacity: 1 !important;
  cursor: default;
}
.p-sosaiSeminar03__list__btn:hover {
  opacity: 0.7;
}
.p-sosaiSeminar03__bg {
  padding: 107px 0 120px;
  background-image: url(../img/sosai/index_bg.jpg);
  background-size: cover;
}
.p-sosaiSeminar03__content {
  display: none;
}
.p-sosaiSeminar03__content.is-open {
  display: block;
}
.p-sosaiSeminar03__content .c-sosaiTitle02b {
  margin-bottom: 50px;
}
.p-sosaiSeminar03__content .c-sosaiTitle02b span:after {
  margin-top: 5px;
}
.p-sosaiSeminar03__txt {
  text-align: center;
  margin-bottom: 112px;
}
.p-sosaiSeminar03__txt b {
  font-size: 2rem;
}
.p-sosaiSeminar03__txt p {
  font-size: 1.7rem;
}
.p-sosaiSeminar03__txt p + p {
  margin-top: 13px;
}
.p-sosaiSeminar03__ttl {
  font-size: 2.4rem;
  font-weight: bold;
  color: #fff;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  background-color: #333745;
  position: relative;
  padding: 10px 10px 7px 63px;
}
.p-sosaiSeminar03__ttl:before {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url(../img/sosai/icon_sosai01.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translateY(-50%);
}
.p-sosaiSeminar03__box {
  border: 1px solid #333745;
  padding: 69px 61px 74px 59px;
  background-color: #fff;
}
.p-sosaiSeminar03__box__ttl {
  font-size: 2.8rem;
  font-weight: 500;
  padding-left: 24px;
  border-left: 4px solid #2e95a3;
  line-height: 1;
  margin-bottom: 24px;
}
.p-sosaiSeminar03__box__ttl02 {
  font-size: 2rem;
  color: #2e95a3;
  margin-bottom: 3px;
}
.p-sosaiSeminar03__box__txt p {
  font-size: 1.7rem;
  margin-bottom: 24px;
}
.p-sosaiSeminar03__box__note {
  font-size: 1.2rem !important;
  font-weight: bold;
  color: #666666;
  margin: 30px 0 0 !important;
}
.p-sosaiSeminar03__box__col {
  display: flex;
}
.p-sosaiSeminar03__box__col__txt {
  width: calc(100% - 460px);
}
.p-sosaiSeminar03__box__col__img {
  width: 460px;
  text-align: right;
  padding-top: 1px;
}
.p-sosaiSeminar03__box__col:not(:first-of-type) {
  margin-top: 99px;
}
.p-sosaiSeminar03__box__col:not(:first-of-type) .p-sosaiSeminar03__box__ttl {
  margin-bottom: 34px;
}
.p-sosaiSeminar03__box .c-sosaiBtn02 {
  margin-top: -2px;
  display: block;
}
.p-sosaiSeminar03__box .c-sosaiBtn02 a {
  padding: 0 28px 0 0;
  text-align: center;
  background-position: 87% center;
}
.p-sosaiSeminar03__bnr01 {
  margin: 112px 0 81px;
}
.p-sosaiSeminar03__bnr01__txt {
  margin-bottom: 20px;
}
.p-sosaiSeminar03__bnr01__txt p {
  text-align: center;
  font-size: 2.2rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSeminar03__bnr01__tel {
  display: flex;
  border: 1px solid #c8c8c8;
}
.p-sosaiSeminar03__bnr01__tel p {
  width: 50%;
  background: #faf9f6;
  font-size: 1.6rem;
  font-weight: bold;
  color: #2f2933;
  border-right: 1px solid #c8c8c8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}
.p-sosaiSeminar03__bnr01__tel a {
  width: 50%;
  font-size: 1.6rem;
  color: #2f2933;
  text-align: center;
  padding: 28px 0 22px;
}
@media screen and (min-width: 641px) {
  .p-sosaiSeminar03__bnr01__tel a {
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
}
.p-sosaiSeminar03__bnr01__tel a span {
  display: block;
  font-size: 3.6rem;
  color: #111111;
  line-height: 1;
  letter-spacing: 1px;
  margin-bottom: 3px;
}
.p-sosaiSeminar03__bnr01__tel a span:before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(../img/common/icon_tel06.png) no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: baseline;
  margin: 0 10px 0 1px;
}
.p-sosaiSeminar03__bnr02 {
  margin-bottom: 0 0 0;
}
.p-sosaiSeminar03__bnr02__txt {
  margin-bottom: 19px;
}
.p-sosaiSeminar03__bnr02__txt p {
  text-align: center;
  font-size: 2.2rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSeminar03__bnr02__box {
  display: flex;
  border: 1px solid #c8c8c8;
}
.p-sosaiSeminar03__bnr02__tel {
  width: 50%;
  border-right: 1px solid #c8c8c8;
  padding: 21px 0 23px;
}
@media screen and (min-width: 641px) {
  .p-sosaiSeminar03__bnr02__tel {
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
}
.p-sosaiSeminar03__bnr02__tel p {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 3px;
}
.p-sosaiSeminar03__bnr02__tel a {
  font-size: 1.6rem;
  color: #2f2933;
  text-align: center;
  padding: 28px 0 22px;
}
.p-sosaiSeminar03__bnr02__tel a span {
  display: block;
  font-size: 3.6rem;
  color: #111111;
  line-height: 1;
  letter-spacing: 1px;
  margin-bottom: 3px;
}
.p-sosaiSeminar03__bnr02__tel a span:before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(../img/common/icon_tel06.png) no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: baseline;
  margin: 0 10px 0 1px;
}
.p-sosaiSeminar03__bnr02__btn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-sosaiSeminar03__bnr02__btn .c-sosaiBtn03 a {
  padding: 23px 2px 24px 50px;
}
.p-sosaiSeminar03__bnr02__btn .c-sosaiBtn03 a:before {
  margin-right: 20px;
  margin-top: 0;
}
.p-sosaiSeminar03__infomation {
  margin-bottom: 73px;
}
.p-sosaiSeminar03__infomation__schedule {
  width: 720px;
  background-color: #ffffff;
}
.p-sosaiSeminar03__infomation__schedule h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #007399;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSeminar03__infomation__report {
  width: 370px;
  background-color: #ffffff;
}
.p-sosaiSeminar03__infomation__report h2 {
  padding: 7px;
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #333745;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSeminar03__infomation__data {
  width: 104px;
  height: 20px;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.17;
  color: #666666;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiSeminar03__infomation .c-newsCate02, .p-sosaiSeminar03__infomation .c-newsCate03 {
  width: auto;
  margin-right: 5px;
}
.p-sosaiSeminar03__infomation .c-newsCate02 {
  padding: 0 18px;
}
.p-sosaiSeminar03__infomation .c-newsCate03 {
  padding: 0 11px;
}
.p-sosaiSeminar03__infomation a {
  font-size: 1.7rem;
  color: #666666;
  text-decoration: underline;
}
.p-sosaiSeminar03__infomation a:hover {
  opacity: 1;
  text-decoration: none;
}
.p-sosaiSeminar03__infomation ul li {
  padding: 30px 30px 23px;
  border-bottom: dotted 1px #4c4c4c;
}
.p-sosaiSeminar03__infomation ul li:last-child {
  border: none;
}
.p-sosaiSeminar03__infomation ul li > div {
  margin-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .p-sosaiSeminar {
    font-size: 1.6rem;
  }
  .p-sosaiSeminar01 {
    min-width: 100%;
    margin-bottom: 50px;
    padding: 32px 0 33px;
  }
  .p-sosaiSeminar02 {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-sosaiSeminar02 .c-sosaiTitle02b {
    margin-bottom: 50px;
  }
  .p-sosaiSeminar02 .c-sosaiTitle02b span:after {
    margin-top: 13px;
  }
  .p-sosaiSeminar02 > p {
    font-size: 1.4rem;
    text-align: left;
  }
  .p-sosaiSeminar02 .c-sosaiTitle02b {
    margin-bottom: 23px;
  }
  .p-sosaiSeminar03 {
    margin-bottom: 60px;
  }
  .p-sosaiSeminar03__list {
    padding: 0 15px;
  }
  .p-sosaiSeminar03__list__btn {
    width: 33.3333%;
    font-size: 1.3rem;
    padding: 10px 5px 8px;
  }
  .p-sosaiSeminar03__bg {
    padding: 47px 15px 49px;
  }
  .p-sosaiSeminar03__content .c-sosaiTitle02b {
    margin-bottom: 28px;
  }
  .p-sosaiSeminar03__content .c-sosaiTitle02b span:after {
    margin-top: 12px;
  }
  .p-sosaiSeminar03__txt {
    margin-bottom: 44px;
    text-align: left;
    padding: 0 5px;
  }
  .p-sosaiSeminar03__txt p, .p-sosaiSeminar03__txt b {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiSeminar03__txt p + p {
    margin-top: 9px;
  }
  .p-sosaiSeminar03__ttl {
    font-size: 1.9rem;
    padding: 16px 5px 15px 63px;
  }
  .p-sosaiSeminar03__ttl:before {
    width: 40px;
    height: 40px;
    left: 14px;
  }
  .p-sosaiSeminar03__box {
    padding: 19px 20px 28px;
  }
  .p-sosaiSeminar03__box__col {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .p-sosaiSeminar03__box__col__txt {
    width: 100%;
  }
  .p-sosaiSeminar03__box__col__img {
    margin-bottom: 19px;
    width: 100%;
  }
  .p-sosaiSeminar03__box__col__img img {
    display: block;
    margin: 0 aoto;
  }
  .p-sosaiSeminar03__box__col:not(:first-of-type) {
    margin-top: 39px;
  }
  .p-sosaiSeminar03__box__col:not(:first-of-type) .p-sosaiSeminar03__box__ttl {
    margin-bottom: 13px;
  }
  .p-sosaiSeminar03__box__ttl {
    font-size: 1.9rem;
    border-left: 2px solid #2e95a3;
    padding-left: 12px;
    line-height: 1.1;
    margin-bottom: 15px;
  }
  .p-sosaiSeminar03__box__ttl02 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  .p-sosaiSeminar03__box__txt p {
    font-size: 1.4rem;
    line-height: 1.49;
    margin-bottom: 20px;
  }
  .p-sosaiSeminar03__box__note {
    font-size: 1rem !important;
    margin: -3px 0 0 !important;
  }
  .p-sosaiSeminar03__box .c-sosaiBtn02 {
    margin-top: -5px;
    font-size: 1.3rem;
    line-height: 1.3;
  }
  .p-sosaiSeminar03__box .c-sosaiBtn02 a {
    padding: 12px 28px 11px;
    background-position: 94% center;
  }
  .p-sosaiSeminar03__bnr01 {
    margin: 44px 0 38px;
    padding: 0 15px;
  }
  .p-sosaiSeminar03__bnr01__txt {
    margin-bottom: 21px;
    padding: 0 8px;
  }
  .p-sosaiSeminar03__bnr01__txt p {
    text-align: left;
    font-size: 1.8rem;
    line-height: 1.6;
  }
  .p-sosaiSeminar03__bnr01__tel {
    display: block;
    padding-bottom: 17px;
  }
  .p-sosaiSeminar03__bnr01__tel p {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    border-right: none;
    padding: 15px 5px 13px;
    margin-bottom: 17px;
  }
  .p-sosaiSeminar03__bnr01__tel div {
    position: relative;
    width: 165px;
    margin: 0 auto;
    transform: translateX(20px);
  }
  .p-sosaiSeminar03__bnr01__tel div:before {
    content: "";
    width: 25px;
    height: 25px;
    background: url(../img/common/icon_tel07.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 10%;
    left: -38px;
  }
  .p-sosaiSeminar03__bnr01__tel a {
    width: calc(100% - 30px);
    color: #fff;
    background-color: #2e95a3;
    display: block;
    margin: 0 auto;
    font-size: 1rem;
    text-align: left;
    padding: 7px 0;
    line-height: 1.4;
    border-radius: 5px;
    box-shadow: 0px 3px 0px #20656e;
  }
  .p-sosaiSeminar03__bnr01__tel a span {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 0;
  }
  .p-sosaiSeminar03__bnr01__tel a span:before {
    content: none;
  }
  .p-sosaiSeminar03__bnr02 {
    padding: 0 15px;
  }
  .p-sosaiSeminar03__bnr02__txt {
    margin-bottom: 11px;
  }
  .p-sosaiSeminar03__bnr02__txt p {
    font-size: 1.8rem;
  }
  .p-sosaiSeminar03__bnr02__box {
    display: block;
  }
  .p-sosaiSeminar03__bnr02__tel {
    width: 100%;
    border-right: none;
    padding: 14px 15px 17px;
    border-bottom: 1px solid #c8c8c8;
  }
  .p-sosaiSeminar03__bnr02__tel p {
    font-size: 1.6rem;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    margin-bottom: 8px;
  }
  .p-sosaiSeminar03__bnr02__tel div {
    position: relative;
    width: 165px;
    margin: 0 auto;
    transform: translateX(20px);
  }
  .p-sosaiSeminar03__bnr02__tel div:before {
    content: "";
    width: 25px;
    height: 25px;
    background: url(../img/common/icon_tel07.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 10px;
    left: -38px;
  }
  .p-sosaiSeminar03__bnr02__tel a {
    display: block;
    margin: 0 auto;
    font-size: 1rem;
    color: #fff;
    background-color: #2e95a3;
    box-shadow: 0px 3px 0px #20656e;
    border-radius: 5px;
    text-align: left;
    padding: 7px 0;
    line-height: 1.4;
  }
  .p-sosaiSeminar03__bnr02__tel a span {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 0;
  }
  .p-sosaiSeminar03__bnr02__tel a span:before {
    content: none;
  }
  .p-sosaiSeminar03__bnr02__btn {
    width: 100%;
    padding: 15px 15px 17px;
    display: block;
  }
  .p-sosaiSeminar03__bnr02__btn .c-sosaiBtn03 {
    font-size: 1.4rem;
    line-height: 1.2;
    max-width: 100%;
  }
  .p-sosaiSeminar03__bnr02__btn .c-sosaiBtn03 a {
    display: block;
    padding: 15px 0 13px 0;
  }
  .p-sosaiSeminar03__bnr02__btn .c-sosaiBtn03 a:before {
    content: none;
  }
  .p-sosaiSeminar03__bnr02__btn .c-sosaiBtn03 a span {
    display: block;
    width: 170px;
    margin: 0 auto;
    position: relative;
    transform: translateX(20px);
  }
  .p-sosaiSeminar03__bnr02__btn .c-sosaiBtn03 a span:before {
    content: "";
    width: 24px;
    height: 19px;
    background-image: url(../img/common/icon_mail02.png);
    background-size: contain;
    position: absolute;
    top: 50%;
    left: -38px;
    transform: translateY(-50%);
  }
  .p-sosaiSeminar03__infomation {
    margin-bottom: 45px;
    padding: 0 15px;
  }
  .p-sosaiSeminar03__infomation__schedule {
    width: 100%;
    margin-bottom: 29px;
  }
  .p-sosaiSeminar03__infomation__schedule h2 {
    padding: 6px 5px;
    font-size: 1.7rem;
  }
  .p-sosaiSeminar03__infomation__report {
    width: 100%;
  }
  .p-sosaiSeminar03__infomation__report h2 {
    padding: 7px 5px 5px;
    font-size: 1.7rem;
  }
  .p-sosaiSeminar03__infomation__data {
    width: 80px;
    font-size: 1.4rem;
    margin-right: 20px;
  }
  .p-sosaiSeminar03__infomation .c-newsCate02, .p-sosaiSeminar03__infomation .c-newsCate03 {
    font-size: 1rem;
    height: 16px;
    line-height: 16px;
  }
  .p-sosaiSeminar03__infomation .c-newsCate02 {
    padding: 0 9px;
  }
  .p-sosaiSeminar03__infomation .c-newsCate03 {
    padding: 0 4px;
  }
  .p-sosaiSeminar03__infomation a {
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .p-sosaiSeminar03__infomation p {
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .p-sosaiSeminar03__infomation ul li {
    padding: 14px 18px 13px;
    line-height: 1.2;
  }
  .p-sosaiSeminar03__infomation ul li > div {
    margin-bottom: 2px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
 葬祭　葬儀の基礎知識・マナー
------------------------------------------------------------*/
.p-sosaiKnowledge {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiKnowledge01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledge01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledge02 {
  margin-top: -47px;
  padding: 112px 0 80px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledge02 .u-inner01 > p {
  line-height: 3rem;
  margin-bottom: 72px;
}
.p-sosaiKnowledge02__content {
  margin-bottom: 41px;
  padding: 60px;
  background-color: #ffffff;
  border-top: solid 2px #2e95a3;
}
.p-sosaiKnowledge02__content__txt {
  padding-right: 520px;
  position: relative;
}
.p-sosaiKnowledge02__content__txt .c-sosaiTitle12 {
  margin-bottom: 24px;
  padding: 4px 0 1px 65px;
}
.p-sosaiKnowledge02__content__txt img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledge02__content p {
  margin-bottom: 32px;
  line-height: 3rem;
}
.p-sosaiKnowledge02__content .c-sosaiBtn07 {
  width: 157px;
  margin: 0;
}
.p-sosaiKnowledge02__content .c-sosaiBtn07 a {
  font-size: 1.4rem;
  font-weight: bold;
}
.p-sosaiKnowledge02__content .c-sosaiBtn07 a:after {
  margin: -3px 0 0 16px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledge {
    font-size: 1.6rem;
  }
  .p-sosaiKnowledge01 {
    min-width: 100%;
    padding: 32px 0 33px;
  }
  .p-sosaiKnowledge01 .c-sosaiTitle03 img {
    margin: 8px auto 0;
    width: 91px;
  }
  .p-sosaiKnowledge02 {
    margin: 0 0 60px;
    padding: 46px 20px 30px;
  }
  .p-sosaiKnowledge02 .u-inner01 > p {
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 35px;
  }
  .p-sosaiKnowledge02__content {
    margin: 20px -5px 20px;
    padding: 20px;
    border-top: solid 1px #2e95a3;
  }
  .p-sosaiKnowledge02__content__txt {
    padding: 0;
    position: static;
  }
  .p-sosaiKnowledge02__content__txt .c-sosaiTitle12 {
    font-size: 1.8rem;
    line-height: 2.1rem;
    padding: 2px 0 1px 29px;
    min-height: 21px;
    margin-bottom: 17px;
  }
  .p-sosaiKnowledge02__content__txt .c-sosaiTitle12:before {
    width: 21px;
    height: 21px;
  }
  .p-sosaiKnowledge02__content__txt img {
    margin: 0 auto 16px auto;
    display: block;
    position: static;
  }
  .p-sosaiKnowledge02__content p {
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledge02__content .c-sosaiBtn07 {
    width: 94%;
    height: 45px;
    margin: 0 auto;
    line-height: 3.2;
  }
  .p-sosaiKnowledge02__content .c-sosaiBtn07 a {
    font-size: 1.3rem;
  }
  .p-sosaiKnowledge02__content .c-sosaiBtn07 a:after {
    margin: -3px 0 0 6px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　喪主・遺族側の基礎知識
------------------------------------------------------------*/
.p-sosaiKnowledgeHost {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.8;
  color: #111111;
}

.p-sosaiKnowledgeHost01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeHost01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeHost02 {
  margin: 23px 0 107px;
}
.p-sosaiKnowledgeHost02__txt {
  text-align: center;
}
.p-sosaiKnowledgeHost02 .c-list03 {
  margin-top: 71px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeHost {
    font-size: 1.3rem;
  }
  .p-sosaiKnowledgeHost01 {
    min-width: 100%;
    margin-bottom: 37px;
    padding: 32px 0 33px;
  }
  .p-sosaiKnowledgeHost02 {
    margin-bottom: 49px;
    padding: 0 15px;
  }
  .p-sosaiKnowledgeHost02__txt {
    font-size: 1.3rem;
    line-height: 1.6;
    text-align: left;
    padding: 0 5px;
  }
  .p-sosaiKnowledgeHost02 .c-list03 {
    margin-top: 35px;
    padding: 0;
  }
  .p-sosaiKnowledgeHost02 .c-list03__item {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .p-sosaiKnowledgeHost02 .c-list03__item:nth-child(1) .c-list03__lable, .p-sosaiKnowledgeHost02 .c-list03__item:nth-child(2) .c-list03__lable {
    width: 41%;
    padding: 12px 0;
  }
  .p-sosaiKnowledgeHost02 .c-list03__item:nth-child(3) .c-list03__lable, .p-sosaiKnowledgeHost02 .c-list03__item:nth-child(4) .c-list03__lable {
    width: 59%;
    padding: 12px 0;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　葬儀のご挨拶
------------------------------------------------------------*/
.p-sosaiKnowledgeHostGreeting {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiKnowledgeHostGreeting__annotation01 {
  margin: 1em 0;
  font-size: 1.2rem;
}

.p-sosaiKnowledgeHostGreeting01 {
  min-width: 1120px;
  padding: 49px 0 50px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeHostGreeting01 > span {
  margin: 0 0 5px 0;
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiKnowledgeHostGreeting01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeHostGreeting02 {
  margin-bottom: 121px;
  padding-top: 21px;
}
.p-sosaiKnowledgeHostGreeting02 .c-sosaiTitle02b {
  margin-bottom: 100px;
}
.p-sosaiKnowledgeHostGreeting02 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiKnowledgeHostGreeting02 .c-sosaiTitle09 {
  line-height: 1;
  margin-bottom: 30px;
  padding-left: 28px;
}
.p-sosaiKnowledgeHostGreeting02__content01 {
  padding-right: 440px;
  position: relative;
}
.p-sosaiKnowledgeHostGreeting02__content01 > img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 > div {
  padding: 35px 40px 33px;
}
.p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 > div > p {
  line-height: 3rem;
}
.p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 .c-sosaiTitle10 {
  margin-bottom: 16px;
}
.p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}

.p-sosaiKnowledgeHostGreeting03 {
  margin-bottom: 117px;
}
.p-sosaiKnowledgeHostGreeting03 .c-sosaiTitle02b {
  margin-bottom: 101px;
}
.p-sosaiKnowledgeHostGreeting03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiKnowledgeHostGreeting03 .c-sosaiTitle09 {
  line-height: 1;
  margin-bottom: 22px;
  padding-left: 28px;
}
.p-sosaiKnowledgeHostGreeting03__content01 {
  margin-bottom: 101px;
}
.p-sosaiKnowledgeHostGreeting03__content01__01 {
  min-height: 240px;
  margin-bottom: 34px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeHostGreeting03__content01__01 > img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeHostGreeting03__content01__01 > p {
  line-height: 3rem;
}
.p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 {
  padding: 35px 40px 32px;
  border: solid 1px #c8c8c8;
  margin-top: -1px;
}
.p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 .c-sosaiTitle10 {
  margin-bottom: 16px;
}
.p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}
.p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 > p {
  line-height: 3rem;
}
.p-sosaiKnowledgeHostGreeting03__content02 {
  margin-bottom: 100px;
}
.p-sosaiKnowledgeHostGreeting03__content02__01 {
  min-height: 240px;
  margin-bottom: 33px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeHostGreeting03__content02__01 > img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeHostGreeting03__content02__01 > p {
  line-height: 3rem;
}
.p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 > div {
  padding: 35px 40px 31px;
}
.p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 > div > p {
  line-height: 3rem;
}
.p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 .c-sosaiTitle10 {
  margin-bottom: 16px;
}
.p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}
.p-sosaiKnowledgeHostGreeting03__content03 > p {
  line-height: 3rem;
}
.p-sosaiKnowledgeHostGreeting03__content03 .p-sosaiKnowledgeHostGreeting__annotation01 {
  margin-top: 3px;
}

.p-sosaiKnowledgeHostGreeting04 {
  margin-bottom: 120px;
}
.p-sosaiKnowledgeHostGreeting04 .c-sosaiTitle02b {
  margin-bottom: 100px;
}
.p-sosaiKnowledgeHostGreeting04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 > div {
  padding: 35px 40px 32px;
}
.p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 > div > p {
  line-height: 3rem;
}
.p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 .c-sosaiTitle10 {
  margin-bottom: 16px;
}
.p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}

.p-sosaiKnowledgeHostGreeting05 {
  padding: 106px 0 140px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeHostGreeting05 .c-sosaiTitle02b {
  margin-bottom: 59px;
}
.p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li {
  margin-right: 30px;
}
.p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li:last-child {
  margin-right: 0;
}
.p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li:after {
  bottom: 2px;
  right: 2px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeHostGreeting {
    font-size: 1.4rem;
  }
  .p-sosaiKnowledgeHostGreeting__annotation01 {
    font-size: 1rem;
  }
  .p-sosaiKnowledgeHostGreeting01 {
    min-width: 100%;
    margin-bottom: 50px;
    padding: 20px 0 22px;
  }
  .p-sosaiKnowledgeHostGreeting01 > span {
    font-size: 1rem;
    margin: 0 0 6px 0;
  }
  .p-sosaiKnowledgeHostGreeting02 {
    margin-bottom: 73px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeHostGreeting02 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeHostGreeting02 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeHostGreeting02 .c-sosaiTitle09 {
    margin: 0 -5px 18px;
    padding-left: 14px;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 > img {
    margin: 0 auto 20px;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 > div {
    padding: 15px 20px 13px;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 > div > p {
    line-height: 1.7;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 .c-sosaiTitle10 {
    padding-left: 15px;
    margin-bottom: 2px;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 .c-sosaiTextBox01 .c-sosaiTitle10:before {
    margin: -4px 4px 0 -15px;
    width: 11px;
    height: 11px;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 .p-sosaiKnowledgeHostGreeting__annotation01 {
    margin-top: 12px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding-left: 12px;
    position: relative;
  }
  .p-sosaiKnowledgeHostGreeting02__content01 .p-sosaiKnowledgeHostGreeting__annotation01 span {
    position: absolute;
    left: 0;
    top: 0;
  }
  .p-sosaiKnowledgeHostGreeting03 {
    margin-bottom: 72px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeHostGreeting03 .c-sosaiTitle02b {
    margin-bottom: 38px;
  }
  .p-sosaiKnowledgeHostGreeting03 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeHostGreeting03 .c-sosaiTitle09 {
    margin: 0 -5px 18px;
    padding-left: 14px;
    line-height: 2.1rem;
  }
  .p-sosaiKnowledgeHostGreeting03__content01 {
    margin-bottom: 49px;
  }
  .p-sosaiKnowledgeHostGreeting03__content01__01 {
    min-height: auto;
    margin-bottom: 25px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeHostGreeting03__content01__01 > img {
    margin: 0 auto 14px;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeHostGreeting03__content01__01 > p {
    line-height: 1.7;
    padding-left: 2px;
  }
  .p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 {
    padding: 15px 20px 12px;
  }
  .p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 > p {
    line-height: 1.7;
  }
  .p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 .c-sosaiTitle10 {
    padding-left: 15px;
    margin-bottom: 2px;
  }
  .p-sosaiKnowledgeHostGreeting03__content01 .c-sosaiTextBox01 .c-sosaiTitle10:before {
    margin: -4px 4px 0 -15px;
    width: 11px;
    height: 11px;
  }
  .p-sosaiKnowledgeHostGreeting03__content02 {
    margin-bottom: 49px;
  }
  .p-sosaiKnowledgeHostGreeting03__content02__01 {
    min-height: auto;
    padding-right: 0;
    position: static;
    margin-bottom: 25px;
  }
  .p-sosaiKnowledgeHostGreeting03__content02__01 > img {
    margin: 0 auto 14px;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeHostGreeting03__content02__01 > p {
    line-height: 1.7;
    padding-left: 2px;
  }
  .p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 > div {
    padding: 15px 20px 12px;
  }
  .p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 > div > p {
    line-height: 1.7;
  }
  .p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 .c-sosaiTitle10 {
    padding-left: 15px;
    margin-bottom: 2px;
  }
  .p-sosaiKnowledgeHostGreeting03__content02 .c-sosaiTextBox01 .c-sosaiTitle10:before {
    margin: -4px 4px 0 -15px;
    width: 11px;
    height: 11px;
  }
  .p-sosaiKnowledgeHostGreeting03__content03 > p {
    line-height: 1.7;
    padding-left: 2px;
  }
  .p-sosaiKnowledgeHostGreeting03__content03 .c-sosaiTitle09 {
    margin: 0 -5px 12px;
  }
  .p-sosaiKnowledgeHostGreeting03__content03 .p-sosaiKnowledgeHostGreeting__annotation01 {
    margin-top: 11px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding-left: 12px;
    position: relative;
  }
  .p-sosaiKnowledgeHostGreeting03__content03 .p-sosaiKnowledgeHostGreeting__annotation01 span {
    position: absolute;
    left: 0;
    top: 0;
  }
  .p-sosaiKnowledgeHostGreeting04 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeHostGreeting04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeHostGreeting04 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 > div {
    padding: 15px 20px 12px;
  }
  .p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 > div > p {
    line-height: 1.7;
  }
  .p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 .c-sosaiTitle10 {
    padding-left: 15px;
    margin-bottom: 2px;
  }
  .p-sosaiKnowledgeHostGreeting04 .c-sosaiTextBox01 .c-sosaiTitle10:before {
    margin: -4px 4px 0 -15px;
    width: 11px;
    height: 11px;
  }
  .p-sosaiKnowledgeHostGreeting05 {
    padding: 47px 15px 40px;
    margin-bottom: 59px;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiTitle02b span:after {
    margin: 13px auto 0;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 {
    flex-wrap: wrap;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-right: 10px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li:nth-child(2n) {
    margin-right: 0;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li:after {
    bottom: 2px;
    right: 2px;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li img {
    margin-bottom: 8px;
  }
  .p-sosaiKnowledgeHostGreeting05 .c-sosaiKanrenLink01 li a {
    font-size: 1.2rem;
    line-height: 3rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　葬儀後のマナー
------------------------------------------------------------*/
.p-sosaiKnowledgeHostManner {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiKnowledgeHostManner__annotation01 {
  margin: 1em 0;
  font-size: 1.2rem;
}
.p-sosaiKnowledgeHostManner .c-sosaiTitle09 {
  margin-bottom: 18px;
}

.p-sosaiKnowledgeHostManner01 {
  min-width: 1120px;
  padding: 50px 0 53px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeHostManner01 > span {
  margin: 0 0 1px 0;
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiKnowledgeHostManner01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeHostManner02 {
  margin: 15px 0 125px;
}
.p-sosaiKnowledgeHostManner02__txt01 {
  text-align: center;
}
.p-sosaiKnowledgeHostManner02__content01 {
  margin: 111px 0 98px 0;
  padding-right: 440px;
  position: relative;
}
.p-sosaiKnowledgeHostManner02__content01 > img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeHostManner02__content01 > p {
  margin-bottom: 33px;
}
.p-sosaiKnowledgeHostManner02__content01 .c-sosaiTextBox01 > div {
  padding: 35px 40px 32px;
}
.p-sosaiKnowledgeHostManner02__content01 .c-sosaiTextBox01 .c-sosaiTitle10 {
  margin-bottom: 18px;
}
.p-sosaiKnowledgeHostManner02__content02 dl dt {
  margin: 28px 0 4px;
  color: #2e95a3;
  font-size: 2rem;
  font-weight: 700;
}
.p-sosaiKnowledgeHostManner02__content02 dl dd {
  margin-bottom: 54px;
}
.p-sosaiKnowledgeHostManner02__content02 dl dd p {
  margin-bottom: 22px;
}
.p-sosaiKnowledgeHostManner02__content02 dl dd:last-child {
  margin-bottom: 0;
}
.p-sosaiKnowledgeHostManner02__content02 .c-sosaiTextBox01 > div {
  padding: 35px 40px 11px;
}
.p-sosaiKnowledgeHostManner02__content02 .c-sosaiTextBox01 .c-sosaiTitle10 {
  margin-bottom: 18px;
}

.p-sosaiKnowledgeHostManner03 {
  margin-bottom: 113px;
}
.p-sosaiKnowledgeHostManner03__txt01 {
  text-align: center;
}
.p-sosaiKnowledgeHostManner03__content01 {
  min-height: 240px;
  margin: 112px 0 100px 0;
  padding-right: 440px;
  position: relative;
}
.p-sosaiKnowledgeHostManner03__content01 > img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeHostManner03__content01 > p {
  margin-bottom: 30px;
}
.p-sosaiKnowledgeHostManner03__content02 {
  margin-bottom: 90px;
}
.p-sosaiKnowledgeHostManner03__content02 p {
  line-height: 1.8;
}
.p-sosaiKnowledgeHostManner03__content02 .c-sosaiTitle09 {
  margin-bottom: 16px;
}
.p-sosaiKnowledgeHostManner03__content03--list {
  margin: 33px 0;
}
.p-sosaiKnowledgeHostManner03__content03--list li {
  margin-bottom: 2px;
}

.p-sosaiKnowledgeHostManner04 {
  padding: 106px 0 140px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeHostManner04 .c-sosaiTitle02b {
  margin-bottom: 59px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeHostManner {
    font-size: 1.4rem;
    line-height: 1.45;
  }
  .p-sosaiKnowledgeHostManner__annotation01 {
    font-size: 1rem;
  }
  .p-sosaiKnowledgeHostManner .c-sosaiTitle09 {
    margin-bottom: 14px;
  }
  .p-sosaiKnowledgeHostManner01 {
    min-width: 100%;
    margin-bottom: 50px;
    padding: 22px 0 23px;
  }
  .p-sosaiKnowledgeHostManner01 > span {
    font-size: 1rem;
    margin-bottom: 6px;
  }
  .p-sosaiKnowledgeHostManner02 {
    margin-bottom: 78px;
    padding: 0 15px;
  }
  .p-sosaiKnowledgeHostManner02 .c-sosaiTitle02b {
    margin-bottom: 26px;
  }
  .p-sosaiKnowledgeHostManner02__txt01 {
    text-align: left;
    padding: 0 6px;
  }
  .p-sosaiKnowledgeHostManner02 .c-sosaiTextBox01 > div {
    padding: 17px 20px 12px;
  }
  .p-sosaiKnowledgeHostManner02 .c-sosaiTextBox01 p {
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .p-sosaiKnowledgeHostManner02 .c-sosaiTextBox01 .c-sosaiTitle10 {
    margin-bottom: 7px;
  }
  .p-sosaiKnowledgeHostManner02__content01 {
    margin: 42px 0 47px 0;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeHostManner02__content01 > img {
    margin: 0 auto 18px;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeHostManner02__content01 > p {
    padding: 0 5px;
    margin-bottom: 13px;
  }
  .p-sosaiKnowledgeHostManner02__content01 .c-sosaiTextBox01 {
    margin: 0 5px;
  }
  .p-sosaiKnowledgeHostManner02__content01 .c-sosaiTitle02b {
    margin-bottom: 25px;
  }
  .p-sosaiKnowledgeHostManner02__content02 dl dt {
    margin: 19px 5px 8px;
    color: #2e95a3;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .p-sosaiKnowledgeHostManner02__content02 dl dd {
    margin: 0 5px 38px;
  }
  .p-sosaiKnowledgeHostManner02__content02 dl dd p {
    font-size: 1.4rem;
    line-height: 1.45;
    margin-bottom: 14px;
  }
  .p-sosaiKnowledgeHostManner02__content02 dl dd:last-child {
    margin-bottom: 0;
  }
  .p-sosaiKnowledgeHostManner02__content02 .c-sosaiTextBox01 p {
    margin: 0;
  }
  .p-sosaiKnowledgeHostManner03 {
    margin-bottom: 44px;
    padding: 0 15px;
  }
  .p-sosaiKnowledgeHostManner03 .c-sosaiTitle02b {
    margin-bottom: 26px;
  }
  .p-sosaiKnowledgeHostManner03__txt01 {
    text-align: left;
    padding: 0 5px;
  }
  .p-sosaiKnowledgeHostManner03__content01 {
    min-height: auto;
    margin: 42px 0 34px 0;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeHostManner03__content01 > img {
    margin: 17px auto;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeHostManner03__content01 p {
    padding: 0 5px;
  }
  .p-sosaiKnowledgeHostManner03__content02, .p-sosaiKnowledgeHostManner03__content03 {
    margin-bottom: 34px;
  }
  .p-sosaiKnowledgeHostManner03__content02 p, .p-sosaiKnowledgeHostManner03__content03 p {
    line-height: 1.45;
    padding: 0 5px;
  }
  .p-sosaiKnowledgeHostManner03__content03--list {
    padding: 0 5px;
    margin: 21px 0;
  }
  .p-sosaiKnowledgeHostManner03__content03--list li {
    margin-bottom: 10px;
  }
  .p-sosaiKnowledgeHostManner04 {
    margin-bottom: 62px;
    padding: 49px 15px 30px;
    overflow: hidden;
  }
  .p-sosaiKnowledgeHostManner04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeHostManner04 .c-sosaiKanrenLink01 {
    margin: 0 auto;
    display: flex;
  }
  .p-sosaiKnowledgeHostManner04 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-right: 10px;
    float: left;
  }
  .p-sosaiKnowledgeHostManner04 .c-sosaiKanrenLink01 li:nth-child(2n), .p-sosaiKnowledgeHostManner04 .c-sosaiKanrenLink01 li:last-child {
    margin-right: 0;
  }
  .p-sosaiKnowledgeHostManner04 .c-sosaiKanrenLink01 li a {
    font-size: 1.2rem;
    line-height: 1.3;
    padding: 0 15px;
    display: block;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　葬儀の事前準備
------------------------------------------------------------*/
.p-sosaiKnowledgeHostPreparation {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.8;
  color: #111111;
}
.p-sosaiKnowledgeHostPreparation__annotation01 {
  margin: 1em 0;
  font-size: 1.2rem;
}

.p-sosaiKnowledgeHostPreparation01 {
  min-width: 1120px;
  padding: 51px 0 53px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeHostPreparation01 > span {
  font-size: 1.6rem;
  display: block;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiKnowledgeHostPreparation01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeHostPreparation02 {
  margin: 24px 0 112px;
}
.p-sosaiKnowledgeHostPreparation02 .c-sosaiTitle02b {
  margin-bottom: 59px;
}
.p-sosaiKnowledgeHostPreparation02__txt01, .p-sosaiKnowledgeHostPreparation02__txt02 {
  font-size: 1.7rem;
  line-height: 1.8;
  text-align: center;
}
.p-sosaiKnowledgeHostPreparation02__content01 {
  margin: 114px 0 50px;
}
.p-sosaiKnowledgeHostPreparation02__content01 dl {
  padding: 0 5px;
}
.p-sosaiKnowledgeHostPreparation02__content01 dl dt {
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: dotted 1px #c8c8c8;
}
.p-sosaiKnowledgeHostPreparation02__content01 dl dd p {
  margin-bottom: 33px;
  font-size: 1.6rem;
  line-height: 1.75;
}
.p-sosaiKnowledgeHostPreparation02__content01 dl dd img {
  margin: 0 auto;
  display: block;
}

.p-sosaiKnowledgeHostPreparation03 {
  padding: 120px 0 0 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeHostPreparation03__content01 {
  padding: 60px 60px 85px;
  background-color: #ffffff;
  border-top: solid 2px #2e95a3;
}
.p-sosaiKnowledgeHostPreparation03__content01__txt {
  padding-right: 520px;
  position: relative;
}
.p-sosaiKnowledgeHostPreparation03__content01__txt img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeHostPreparation03__content01 p {
  margin-bottom: 33px;
}
.p-sosaiKnowledgeHostPreparation03__content01 .c-sosaiBtn07 {
  width: 157px;
  margin: 0;
}

.p-sosaiKnowledgeHostPreparation04 {
  padding: 106px 0 139px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeHostPreparation04 .c-sosaiTitle02b {
  margin-bottom: 59px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeHostPreparation {
    font-size: 1.3rem;
  }
  .p-sosaiKnowledgeHostPreparation__annotation01 {
    font-size: 1rem;
  }
  .p-sosaiKnowledgeHostPreparation01 {
    min-width: 100%;
    margin-bottom: 47px;
    padding: 20px 0 21px;
  }
  .p-sosaiKnowledgeHostPreparation01 > span {
    font-size: 1rem;
    margin-bottom: 6px;
  }
  .p-sosaiKnowledgeHostPreparation02 {
    margin-bottom: 45px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeHostPreparation02 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeHostPreparation02__txt01, .p-sosaiKnowledgeHostPreparation02__txt02 {
    font-size: 1.4rem;
    line-height: 1.6;
    text-align: left;
  }
  .p-sosaiKnowledgeHostPreparation02__content01 {
    margin: 73px 0 35px;
  }
  .p-sosaiKnowledgeHostPreparation02__content01 dl {
    padding: 0;
  }
  .p-sosaiKnowledgeHostPreparation02__content01 dl dt {
    margin-bottom: 10px;
    padding-bottom: 7px;
  }
  .p-sosaiKnowledgeHostPreparation02__content01 dl dd p {
    margin-bottom: 10px;
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .p-sosaiKnowledgeHostPreparation03 {
    margin: 0;
    padding: 50px 15px 0;
  }
  .p-sosaiKnowledgeHostPreparation03__content01 {
    padding: 32px 19px 20px;
  }
  .p-sosaiKnowledgeHostPreparation03__content01 .c-sosaiTitle12 {
    margin-bottom: 25px;
  }
  .p-sosaiKnowledgeHostPreparation03__content01 .c-sosaiTitle12:before {
    left: 4px;
  }
  .p-sosaiKnowledgeHostPreparation03__content01 .c-sosaiTitle12 span {
    margin: -4px 0 1px;
  }
  .p-sosaiKnowledgeHostPreparation03__content01__txt {
    padding: 0;
    position: static;
  }
  .p-sosaiKnowledgeHostPreparation03__content01__txt img {
    margin: 0 auto 15px;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeHostPreparation03__content01 p {
    margin-bottom: 16px;
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .p-sosaiKnowledgeHostPreparation03__content01 .c-sosaiBtn07 {
    width: 93%;
    height: auto;
    line-height: 2.8;
    margin: 0 auto;
  }
  .p-sosaiKnowledgeHostPreparation04 {
    margin-bottom: 63px;
    padding: 51px 15px 20px;
    overflow: hidden;
  }
  .p-sosaiKnowledgeHostPreparation04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeHostPreparation04 .c-sosaiKanrenLink01 {
    margin: 0 auto;
    display: flex;
  }
  .p-sosaiKnowledgeHostPreparation04 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-right: 10px;
    float: left;
  }
  .p-sosaiKnowledgeHostPreparation04 .c-sosaiKanrenLink01 li:nth-child(2n), .p-sosaiKnowledgeHostPreparation04 .c-sosaiKanrenLink01 li:last-child {
    margin-right: 0;
  }
  .p-sosaiKnowledgeHostPreparation04 .c-sosaiKanrenLink01 li a {
    font-size: 1.2rem;
    line-height: 1.3;
    padding: 0 15px;
    display: block;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　参列者のマナー
------------------------------------------------------------*/
.p-sosaiKnowledgeAttendee {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}
.p-sosaiKnowledgeAttendee__annotation01 {
  margin: 13px 0;
  font-size: 1.2rem;
}

.p-sosaiKnowledgeAttendee01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeAttendee01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeAttendee02 {
  margin-bottom: 110px;
  padding-top: 4px;
}
.p-sosaiKnowledgeAttendee02 p {
  margin-bottom: 52px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee02 ul li {
  width: 352px;
  padding: 21px 20px 18px;
}

.p-sosaiKnowledgeAttendee03 {
  margin-bottom: 119px;
}
.p-sosaiKnowledgeAttendee03 .c-sosaiTitle02b {
  margin-bottom: 61px;
}
.p-sosaiKnowledgeAttendee03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeAttendee03 .c-sosaiTitle09 {
  line-height: 1;
  padding-left: 28px;
  margin-bottom: 22px;
}
.p-sosaiKnowledgeAttendee03__content01 {
  margin-bottom: 70px;
  padding-right: 440px;
  position: relative;
}
.p-sosaiKnowledgeAttendee03__content01 > img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeAttendee03__content01 > p {
  line-height: 3rem;
  padding-right: 5px;
}
.p-sosaiKnowledgeAttendee03__content01__table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  margin-top: 32px;
}
.p-sosaiKnowledgeAttendee03__content01__table thead tr {
  position: relative;
}
.p-sosaiKnowledgeAttendee03__content01__table thead tr td {
  width: 50%;
  padding: 20px 30px 18px;
}
.p-sosaiKnowledgeAttendee03__content01__table tbody tr {
  position: relative;
}
.p-sosaiKnowledgeAttendee03__content01__table tbody tr th {
  width: 50%;
  padding: 20px 20px 19px 48px;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
  background: #f9f9f9 url("../img/sosai/urgency_border01.png") repeat-x bottom left;
}
.p-sosaiKnowledgeAttendee03__content01__table tbody tr th:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiKnowledgeAttendee03__content01__table tbody tr td {
  width: 50%;
  padding: 20px 28px 19px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: right;
  background: #f9f9f9 url("../img/sosai/urgency_border01.png") repeat-x bottom left;
}
.p-sosaiKnowledgeAttendee03__content01__table tbody tr:nth-child(odd) th {
  background-color: #ffffff;
}
.p-sosaiKnowledgeAttendee03__content01__table tbody tr:nth-child(odd) td {
  background-color: #ffffff;
}
.p-sosaiKnowledgeAttendee03__content01__table__title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #333745;
  text-align: right;
}
.p-sosaiKnowledgeAttendee03__content01__table__title--01 {
  text-align: left;
  position: relative;
}
.p-sosaiKnowledgeAttendee03__content01__table__title--01:before {
  width: 1px;
  height: 100%;
  content: "";
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeAttendee03__content02 {
  margin-bottom: 72px;
}
.p-sosaiKnowledgeAttendee03__content02 > p {
  margin-bottom: 45px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 {
  margin: 0 0 32px 0;
}
.p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 > div {
  padding: 35px 20px 32px 40px;
}
.p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 > div h4 {
  margin-bottom: 17px;
  letter-spacing: 2.5px;
}
.p-sosaiKnowledgeAttendee03__content02 .c-sosaiTitle13 {
  margin-bottom: 20px;
}
.p-sosaiKnowledgeAttendee03__content03 > p {
  line-height: 3rem;
}

.p-sosaiKnowledgeAttendee04 {
  margin-bottom: 127px;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTitle02b {
  margin-bottom: 60px;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTitle09 {
  line-height: 1;
  padding-left: 28px;
  margin-bottom: 22px;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTitle13 {
  margin-bottom: 20px;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTitle13:before {
  margin: -5px 12px 0 0;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTextBox01 > div {
  padding: 35px 40px 32px;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTextBox01 > div h4 {
  margin-bottom: 16px;
  letter-spacing: 2.5px;
}
.p-sosaiKnowledgeAttendee04 .c-sosaiTextBox01 > div p {
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee04__content01 {
  margin-bottom: 100px;
  padding-right: 372px;
  position: relative;
}
.p-sosaiKnowledgeAttendee04__content01 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeAttendee04__content01 > p {
  margin-bottom: 45px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee04__content02 {
  margin-bottom: 100px;
  padding-right: 372px;
  position: relative;
}
.p-sosaiKnowledgeAttendee04__content02 img {
  position: absolute;
  top: 102px;
  right: 0;
}
.p-sosaiKnowledgeAttendee04__content02 > p {
  margin-bottom: 45px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee04__content03 {
  padding-right: 372px;
  position: relative;
}
.p-sosaiKnowledgeAttendee04__content03 img {
  position: absolute;
  top: 76px;
  right: 0;
}
.p-sosaiKnowledgeAttendee04__content03 > p {
  margin-bottom: 45px;
  line-height: 3rem;
}

.p-sosaiKnowledgeAttendee05 {
  margin-bottom: 126px;
}
.p-sosaiKnowledgeAttendee05 .c-sosaiTitle02b {
  margin-bottom: 53px;
}
.p-sosaiKnowledgeAttendee05 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeAttendee05 .c-sosaiTitle09 {
  line-height: 1;
  padding-left: 28px;
  margin-bottom: 22px;
}
.p-sosaiKnowledgeAttendee05__content01 {
  min-height: 240px;
  margin-bottom: 68px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeAttendee05__content01 img {
  position: absolute;
  top: 7px;
  right: 0;
}
.p-sosaiKnowledgeAttendee05__content01 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee05__content02 {
  margin-bottom: 80px;
}
.p-sosaiKnowledgeAttendee05__content02 > p {
  margin-bottom: 23px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee05__content02 ol li {
  width: 257px;
  margin-right: 30px;
  border: solid 2px #2e95a3;
}
.p-sosaiKnowledgeAttendee05__content02 ol li:nth-child(4) {
  margin-right: 0;
}
.p-sosaiKnowledgeAttendee05__content02 ol li p {
  padding: 16px 18px 20px 30px;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
.p-sosaiKnowledgeAttendee05__content03 {
  margin-bottom: 80px;
}
.p-sosaiKnowledgeAttendee05__content03 ol {
  padding-top: 8px;
}
.p-sosaiKnowledgeAttendee05__content03 ol li {
  width: 257px;
  margin-right: 30px;
  border: solid 2px #2e95a3;
}
.p-sosaiKnowledgeAttendee05__content03 ol li:nth-child(4) {
  margin-right: 0;
}
.p-sosaiKnowledgeAttendee05__content03 ol li p {
  padding: 16px 18px 23px 29px;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
.p-sosaiKnowledgeAttendee05__content04 {
  margin-bottom: 80px;
}
.p-sosaiKnowledgeAttendee05__content04 > p {
  margin-bottom: 23px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee05__content04 ol li {
  width: 257px;
  margin-right: 30px;
  border: solid 2px #2e95a3;
}
.p-sosaiKnowledgeAttendee05__content04 ol li:nth-child(4) {
  margin-right: 0;
}
.p-sosaiKnowledgeAttendee05__content04 ol li p {
  padding: 15px 18px 20px 29px;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
.p-sosaiKnowledgeAttendee05__content05 > p {
  margin-bottom: 23px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee05__content05 ol li {
  width: 257px;
  margin-right: 30px;
  border: solid 2px #2e95a3;
}
.p-sosaiKnowledgeAttendee05__content05 ol li:nth-child(4) {
  margin-right: 0;
}
.p-sosaiKnowledgeAttendee05__content05 ol li p {
  padding: 16px 18px 20px 29px;
  font-size: 1.6rem;
  line-height: 2.6rem;
}

.p-sosaiKnowledgeAttendee06 {
  margin-bottom: 120px;
}
.p-sosaiKnowledgeAttendee06 .c-sosaiTitle02b {
  margin-bottom: 52px;
}
.p-sosaiKnowledgeAttendee06 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeAttendee06 > p {
  margin-bottom: 52px;
  line-height: 3rem;
}
.p-sosaiKnowledgeAttendee06 .c-sosaiTextBox01 {
  margin: 0 auto;
}
.p-sosaiKnowledgeAttendee06 .c-sosaiTextBox01 > div {
  background-color: #f6f6f6;
  padding: 35px 40px 34px 41px;
}
.p-sosaiKnowledgeAttendee06 h4 {
  margin-bottom: 19px;
  font-size: 1.8rem;
  font-weight: 700;
}
.p-sosaiKnowledgeAttendee06 ul li {
  margin-bottom: 10px;
  padding-left: 18px;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.p-sosaiKnowledgeAttendee06 ul li:last-child {
  margin-bottom: 0;
}
.p-sosaiKnowledgeAttendee06 ul li:before {
  width: 8px;
  height: 8px;
  margin: -3px 10px 0 -17px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}

.p-sosaiKnowledgeAttendee07 {
  padding: 108px 0 120px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
}
.p-sosaiKnowledgeAttendee07 .c-sosaiTitle02b {
  margin-bottom: 59px;
}
.p-sosaiKnowledgeAttendee07 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeAttendee07 .c-sosaiKanrenLink01 li img {
  margin-bottom: 17px;
}
.p-sosaiKnowledgeAttendee07 .c-sosaiKanrenLink01 li:after {
  bottom: 3px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeAttendee {
    font-size: 1.6rem;
  }
  .p-sosaiKnowledgeAttendee__annotation01 {
    font-size: 1.2rem !important;
    padding-left: 3px;
    margin: 8px 0;
  }
  .p-sosaiKnowledgeAttendee01 {
    min-width: 100%;
    margin-bottom: 47px;
    padding: 32px 0 33px;
  }
  .p-sosaiKnowledgeAttendee01 img {
    margin: 8px auto 0;
    width: 50px;
    transform: translateX(-4px);
  }
  .p-sosaiKnowledgeAttendee02 {
    margin-bottom: 57px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeAttendee02 p {
    margin-bottom: 35px;
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiKnowledgeAttendee02 ul {
    margin: 0 -5px;
  }
  .p-sosaiKnowledgeAttendee02 ul li {
    width: 100%;
    margin-bottom: 13px;
    font-size: 1.5rem;
    padding: 12px 20px 11px;
  }
  .p-sosaiKnowledgeAttendee02 ul li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiKnowledgeAttendee02 ul li a:after {
    margin: -3px 0 0 10px;
  }
  .p-sosaiKnowledgeAttendee03 {
    margin-bottom: 71px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeAttendee03 .c-sosaiTitle02b {
    margin-bottom: 38px;
  }
  .p-sosaiKnowledgeAttendee03 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeAttendee03 .c-sosaiTitle09 {
    padding-left: 14px;
    margin-bottom: 19px;
    margin-left: -5px;
  }
  .p-sosaiKnowledgeAttendee03__content01 {
    margin-bottom: 44px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeAttendee03__content01 > img {
    margin: 0 auto 16px;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeAttendee03__content01 > p {
    font-size: 1.4rem;
    line-height: 1.7;
    padding-right: 0;
  }
  .p-sosaiKnowledgeAttendee03__content01__table {
    margin: 34px -5px 0;
    width: calc(100% + 10px);
  }
  .p-sosaiKnowledgeAttendee03__content01__table thead tr td {
    padding: 17px 10px 14px;
    text-align: center;
  }
  .p-sosaiKnowledgeAttendee03__content01__table tbody tr th {
    padding: 17px 10px 12px 24px;
    font-size: 1.3rem;
  }
  .p-sosaiKnowledgeAttendee03__content01__table tbody tr th:before {
    margin: -3px 5px 0 -13px;
  }
  .p-sosaiKnowledgeAttendee03__content01__table tbody tr td {
    padding: 11px 8px 12px;
    font-size: 1.3rem;
  }
  .p-sosaiKnowledgeAttendee03__content01__table__title {
    font-size: 1.3rem;
  }
  .p-sosaiKnowledgeAttendee03__content02 {
    margin-bottom: 34px;
  }
  .p-sosaiKnowledgeAttendee03__content02 > p {
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 20px;
  }
  .p-sosaiKnowledgeAttendee03__content02 .c-sosaiTitle09 {
    margin-bottom: 15px;
  }
  .p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 {
    margin: 14px 0 16px;
  }
  .p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 > div {
    padding: 15px 20px 14px 19px;
  }
  .p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 > div h4 {
    margin-bottom: 4px;
    padding-left: 17px;
    letter-spacing: 0;
  }
  .p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 > div h4:before {
    margin: -5px 5px 0 -17px;
  }
  .p-sosaiKnowledgeAttendee03__content02 .c-sosaiTextBox01 > div p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee03__content02 .c-sosaiTitle13 {
    margin-bottom: 13px;
  }
  .p-sosaiKnowledgeAttendee03__content03 > p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee03__content03 .c-sosaiTitle09 {
    margin-bottom: 15px;
  }
  .p-sosaiKnowledgeAttendee04 {
    margin-bottom: 76px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeAttendee04 .c-sosaiTitle02b {
    margin-bottom: 39px;
  }
  .p-sosaiKnowledgeAttendee04 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeAttendee04 .c-sosaiTitle09 {
    padding-left: 14px;
    margin-bottom: 19px;
    margin-left: -5px;
  }
  .p-sosaiKnowledgeAttendee04 .c-sosaiTitle13 {
    margin-bottom: 13px;
  }
  .p-sosaiKnowledgeAttendee04 .c-sosaiTitle13:before {
    width: 12px;
    height: 12px;
    margin: -3px 8px 0 0;
  }
  .p-sosaiKnowledgeAttendee04__content01, .p-sosaiKnowledgeAttendee04__content02, .p-sosaiKnowledgeAttendee04__content03 {
    margin-bottom: 40px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeAttendee04__content01 img, .p-sosaiKnowledgeAttendee04__content02 img, .p-sosaiKnowledgeAttendee04__content03 img {
    width: 146px;
    display: block;
    margin: 0 auto 16px;
    position: static;
  }
  .p-sosaiKnowledgeAttendee04__content01 > p, .p-sosaiKnowledgeAttendee04__content02 > p, .p-sosaiKnowledgeAttendee04__content03 > p {
    margin-bottom: 20px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee04__content01 .c-sosaiTextBox01 > div, .p-sosaiKnowledgeAttendee04__content02 .c-sosaiTextBox01 > div, .p-sosaiKnowledgeAttendee04__content03 .c-sosaiTextBox01 > div {
    padding: 15px 20px 13px 19px;
  }
  .p-sosaiKnowledgeAttendee04__content01 .c-sosaiTextBox01 > div h4, .p-sosaiKnowledgeAttendee04__content02 .c-sosaiTextBox01 > div h4, .p-sosaiKnowledgeAttendee04__content03 .c-sosaiTextBox01 > div h4 {
    margin-bottom: 4px;
    padding-left: 17px;
    letter-spacing: 0;
  }
  .p-sosaiKnowledgeAttendee04__content01 .c-sosaiTextBox01 > div h4:before, .p-sosaiKnowledgeAttendee04__content02 .c-sosaiTextBox01 > div h4:before, .p-sosaiKnowledgeAttendee04__content03 .c-sosaiTextBox01 > div h4:before {
    margin: -5px 5px 0 -17px;
  }
  .p-sosaiKnowledgeAttendee04__content01 .c-sosaiTextBox01 > div p, .p-sosaiKnowledgeAttendee04__content02 .c-sosaiTextBox01 > div p, .p-sosaiKnowledgeAttendee04__content03 .c-sosaiTextBox01 > div p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee04__content02 img {
    transform: translateX(-12px);
  }
  .p-sosaiKnowledgeAttendee04__content03 {
    margin-bottom: 0;
  }
  .p-sosaiKnowledgeAttendee04__content03 .c-sosaiTitle09 {
    margin-bottom: 18px;
  }
  .p-sosaiKnowledgeAttendee04__content03 img {
    margin: 0 auto 15px;
  }
  .p-sosaiKnowledgeAttendee04__content03 .c-sosaiTextBox01 > div {
    padding: 16px 20px 14px 19px;
  }
  .p-sosaiKnowledgeAttendee05 {
    margin-bottom: 66px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeAttendee05 .c-sosaiTitle02b {
    margin-bottom: 29px;
  }
  .p-sosaiKnowledgeAttendee05 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeAttendee05 .c-sosaiTitle09 {
    padding-left: 14px;
    margin-bottom: 16px;
    margin-left: -5px;
  }
  .p-sosaiKnowledgeAttendee05 .c-sosaiTitle13 {
    margin-bottom: 13px;
  }
  .p-sosaiKnowledgeAttendee05 .c-sosaiTitle13:before {
    width: 12px;
    height: 12px;
    margin: -3px 8px 0 0;
  }
  .p-sosaiKnowledgeAttendee05__content01 {
    min-height: auto;
    margin-bottom: 43px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeAttendee05__content01 img {
    display: block;
    margin: 0 auto 17px;
    position: static;
  }
  .p-sosaiKnowledgeAttendee05__content01 > p {
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee05__content02 {
    margin-bottom: 29px;
  }
  .p-sosaiKnowledgeAttendee05__content02 > p {
    margin-bottom: 23px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee05__content02 > p span {
    display: block;
    font-size: 1.2rem;
    margin-top: 5px;
    padding-left: 3px;
  }
  .p-sosaiKnowledgeAttendee05__content02 ol li {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    border: solid 1px #2e95a3;
  }
  .p-sosaiKnowledgeAttendee05__content02 ol li:nth-child(even) {
    margin-right: 0;
  }
  .p-sosaiKnowledgeAttendee05__content02 ol li p {
    padding: 12px 15px 12px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiKnowledgeAttendee05__content03 {
    margin-bottom: 29px;
  }
  .p-sosaiKnowledgeAttendee05__content03 ol {
    padding-top: 2px;
  }
  .p-sosaiKnowledgeAttendee05__content03 ol li {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    border: solid 1px #2e95a3;
  }
  .p-sosaiKnowledgeAttendee05__content03 ol li:nth-child(even) {
    margin-right: 0;
  }
  .p-sosaiKnowledgeAttendee05__content03 ol li p {
    padding: 13px 15px 11px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiKnowledgeAttendee05__content04 {
    margin-bottom: 30px;
  }
  .p-sosaiKnowledgeAttendee05__content04 > p {
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee05__content04 ol li {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    border: solid 1px #2e95a3;
  }
  .p-sosaiKnowledgeAttendee05__content04 ol li:nth-child(even) {
    margin-right: 0;
  }
  .p-sosaiKnowledgeAttendee05__content04 ol li p {
    padding: 13px 15px 11px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiKnowledgeAttendee05__content05 > p {
    margin-bottom: 14px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee05__content05 ol li {
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    border: solid 1px #2e95a3;
  }
  .p-sosaiKnowledgeAttendee05__content05 ol li:nth-child(even) {
    margin-right: 0;
  }
  .p-sosaiKnowledgeAttendee05__content05 ol li p {
    padding: 13px 15px 11px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiKnowledgeAttendee06 {
    margin-bottom: 50px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeAttendee06 .c-sosaiTitle02b {
    margin-bottom: 24px;
  }
  .p-sosaiKnowledgeAttendee06 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeAttendee06 > p {
    margin-bottom: 35px;
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiKnowledgeAttendee06 .c-sosaiTextBox01 > div {
    padding: 15px 15px 13px 19px;
  }
  .p-sosaiKnowledgeAttendee06 h4 {
    margin-bottom: 8px;
    font-size: 1.4rem;
  }
  .p-sosaiKnowledgeAttendee06 ul li {
    margin-bottom: 7px;
    padding-left: 13px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeAttendee06 ul li:before {
    margin: -3px 7px 0 -13px;
  }
  .p-sosaiKnowledgeAttendee07 {
    padding: 49px 20px 47px;
    margin-bottom: 60px;
  }
  .p-sosaiKnowledgeAttendee07 .c-sosaiTitle02b {
    margin-bottom: 29px;
  }
  .p-sosaiKnowledgeAttendee07 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeAttendee07 .c-sosaiKanrenLink01 {
    display: flex;
  }
  .p-sosaiKnowledgeAttendee07 .c-sosaiKanrenLink01 li {
    width: 50%;
    margin: 0 auto;
  }
  .p-sosaiKnowledgeAttendee07 .c-sosaiKanrenLink01 li img {
    margin-bottom: 15px;
  }
  .p-sosaiKnowledgeAttendee07 .c-sosaiKanrenLink01 li a {
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;
    padding: 0 15px;
    display: block;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　葬儀スタイルによる違い
------------------------------------------------------------*/
.p-sosaiKnowledgeStyle {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiKnowledgeStyle01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeStyle01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeStyle02 {
  padding-top: 20px;
  margin-bottom: 127px;
}
.p-sosaiKnowledgeStyle02 > p {
  margin-bottom: 112px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle02 .c-sosaiTitle02b {
  margin-bottom: 52px;
}
.p-sosaiKnowledgeStyle02 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiKnowledgeStyle02 .c-sosaiTitle09 {
  line-height: 1;
  margin-bottom: 22px;
  padding-left: 28px;
}
.p-sosaiKnowledgeStyle02__content01 {
  min-height: 240px;
  margin-bottom: 93px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle02__content01 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle02__content01 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle02__content02 {
  min-height: 240px;
  margin-bottom: 93px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle02__content02 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle02__content02 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle02__content03 {
  min-height: 240px;
  margin-bottom: 92px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle02__content03 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle02__content03 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle02__content04 {
  min-height: 240px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle02__content04 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle02__content04 > p {
  margin-bottom: 32px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle02__content04 .c-sosaiBtn07 {
  width: 295px;
  height: 56px;
  margin: 0;
}
.p-sosaiKnowledgeStyle02__content04 .c-sosaiBtn07 a {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  padding: 15px 30px 13px;
}
.p-sosaiKnowledgeStyle02__content04 .c-sosaiBtn07 a:after {
  margin: -3px 0 0 16px;
}

.p-sosaiKnowledgeStyle03 {
  margin-bottom: 120px;
}
.p-sosaiKnowledgeStyle03 > p {
  margin-bottom: 113px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle03 .c-sosaiTitle02b {
  margin-bottom: 52px;
}
.p-sosaiKnowledgeStyle03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiKnowledgeStyle03 .c-sosaiTitle09 {
  line-height: 1;
  margin-bottom: 22px;
  padding-left: 28px;
}
.p-sosaiKnowledgeStyle03__content01 {
  min-height: 240px;
  margin-bottom: 100px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle03__content01 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle03__content01 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle03__content02 {
  min-height: 240px;
  margin-bottom: 99px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle03__content02 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle03__content02 > p {
  margin-bottom: 32px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle03__content02 .c-sosaiBtn07 {
  width: 282px;
  height: 56px;
  margin: 0;
}
.p-sosaiKnowledgeStyle03__content02 .c-sosaiBtn07 a {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  padding: 15px 30px 13px 27px;
}
.p-sosaiKnowledgeStyle03__content02 .c-sosaiBtn07 a:after {
  margin: -3px 0 0 16px;
}
.p-sosaiKnowledgeStyle03__content03 {
  min-height: 240px;
  margin-bottom: 100px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle03__content03 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle03__content03 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyle03__content04 {
  min-height: 240px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyle03__content04 img {
  position: absolute;
  top: 0;
  right: 0;
}
.p-sosaiKnowledgeStyle03__content04 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}

.p-sosaiKnowledgeStyle04 {
  padding: 108px 0 141px;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeStyle04 .c-sosaiTitle02b {
  margin-bottom: 59px;
}
.p-sosaiKnowledgeStyle04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0 auto;
}
.p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li {
  margin-right: 30px;
}
.p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li:last-child {
  margin-right: 0;
}
.p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li:after {
  bottom: 2px;
  right: 2px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeStyle {
    font-size: 1.4rem;
  }
  .p-sosaiKnowledgeStyle01 {
    min-width: 100%;
    margin-bottom: 47px;
    padding: 32px 0 33px;
  }
  .p-sosaiKnowledgeStyle02 {
    margin-bottom: 76px;
    padding: 0 15px;
  }
  .p-sosaiKnowledgeStyle02 > p {
    margin-bottom: 44px;
    line-height: 1.7;
    text-align: left;
    padding: 0 7px;
  }
  .p-sosaiKnowledgeStyle02 .c-sosaiTitle02b {
    margin-bottom: 24px;
  }
  .p-sosaiKnowledgeStyle02 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeStyle02 .c-sosaiTitle09 {
    margin-bottom: 19px;
    padding-left: 14px;
  }
  .p-sosaiKnowledgeStyle02__content01 {
    margin-bottom: 33px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content01 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content01 > p {
    margin-bottom: 10px;
    line-height: 1.7;
    padding: 0 8px;
  }
  .p-sosaiKnowledgeStyle02__content02 {
    margin-bottom: 33px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content02 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content02 > p {
    margin-bottom: 10px;
    line-height: 1.7;
    padding: 0 8px;
  }
  .p-sosaiKnowledgeStyle02__content03 {
    margin-bottom: 33px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content03 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content03 > p {
    margin-bottom: 10px;
    line-height: 1.7;
    padding: 0 8px;
  }
  .p-sosaiKnowledgeStyle02__content04 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content04 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle02__content04 > p {
    margin-bottom: 19px;
    line-height: 1.7;
    padding: 0 8px;
  }
  .p-sosaiKnowledgeStyle02__content04 .c-sosaiBtn07 {
    width: 85%;
    height: 46px;
    margin: 0 auto;
  }
  .p-sosaiKnowledgeStyle02__content04 .c-sosaiBtn07 a {
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 12px 10px;
  }
  .p-sosaiKnowledgeStyle02__content04 .c-sosaiBtn07 a:after {
    margin: -3px 0 0 8px;
  }
  .p-sosaiKnowledgeStyle03 {
    margin-bottom: 45px;
    padding: 0 15px;
  }
  .p-sosaiKnowledgeStyle03 > p {
    margin-bottom: 45px;
    line-height: 1.7;
    text-align: left;
    padding: 0 8px;
  }
  .p-sosaiKnowledgeStyle03 .c-sosaiTitle02b {
    margin-bottom: 24px;
  }
  .p-sosaiKnowledgeStyle03 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeStyle03 .c-sosaiTitle09 {
    margin-bottom: 19px;
    padding-left: 14px;
  }
  .p-sosaiKnowledgeStyle03__content01 {
    margin-bottom: 35px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content01 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content01 > p {
    margin-bottom: 10px;
    line-height: 1.7;
    padding: 0 7px 0 8px;
  }
  .p-sosaiKnowledgeStyle03__content02 {
    margin-bottom: 50px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content02 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content02 > p {
    margin-bottom: 19px;
    line-height: 1.7;
    padding: 0 7px 0 8px;
  }
  .p-sosaiKnowledgeStyle03__content02 .c-sosaiBtn07 {
    width: 81%;
    height: 46px;
    margin: 0 auto;
  }
  .p-sosaiKnowledgeStyle03__content02 .c-sosaiBtn07 a {
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 12px 10px;
  }
  .p-sosaiKnowledgeStyle03__content02 .c-sosaiBtn07 a:after {
    margin: -3px 0 0 8px;
  }
  .p-sosaiKnowledgeStyle03__content03 {
    margin-bottom: 34px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content03 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content03 > p {
    margin-bottom: 19px;
    line-height: 1.7;
    padding: 0 7px 0 8px;
  }
  .p-sosaiKnowledgeStyle03__content04 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content04 img {
    display: block;
    margin: 0 auto 14px;
    position: static;
  }
  .p-sosaiKnowledgeStyle03__content04 > p {
    margin-bottom: 19px;
    line-height: 1.7;
    padding: 0 7px 0 8px;
  }
  .p-sosaiKnowledgeStyle04 {
    padding: 46px 15px 35px;
    margin-bottom: 60px;
  }
  .p-sosaiKnowledgeStyle04 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeStyle04 .c-sosaiTitle02b span:after {
    margin: 13px auto 0;
  }
  .p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-right: 10px;
    padding-bottom: 5px;
  }
  .p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li:last-child {
    margin-right: 0;
  }
  .p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li:after {
    bottom: 2px;
    right: 2px;
  }
  .p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li img {
    margin-bottom: 8px;
  }
  .p-sosaiKnowledgeStyle04 .c-sosaiKanrenLink01 li a {
    font-size: 1.2rem;
    line-height: 3rem;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　家族葬とは
------------------------------------------------------------*/
.p-sosaiKnowledgeStyleFamily {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiKnowledgeStyleFamily01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeStyleFamily01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeStyleFamily02 {
  margin-bottom: 113px;
  padding-top: 19px;
}
.p-sosaiKnowledgeStyleFamily02 .c-sosaiTitle02b {
  margin-bottom: 52px;
}
.p-sosaiKnowledgeStyleFamily02 .c-sosaiTitle02b span:after {
  margin: 2px auto 0;
}
.p-sosaiKnowledgeStyleFamily02__content01 {
  min-height: 240px;
  margin-bottom: 60px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyleFamily02__content01 img {
  position: absolute;
  top: 8px;
  right: 0;
}
.p-sosaiKnowledgeStyleFamily02__content01 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}

.p-sosaiKnowledgeStyleFamily03 {
  margin-bottom: 106px;
  padding: 106px 0 120px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeStyleFamily03 .c-sosaiTitle02b {
  margin-bottom: 52px;
  padding-left: 9px;
}
.p-sosaiKnowledgeStyleFamily03 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeStyleFamily03 ol {
  margin-top: 49px;
  padding-top: 53px;
}
.p-sosaiKnowledgeStyleFamily03 ol li {
  width: 353px;
  margin-right: 30px;
  padding: 32px 30px 32px;
  background-color: #ffffff;
  border: solid 2px #2e95a3;
}
.p-sosaiKnowledgeStyleFamily03 ol li:nth-child(3n) {
  margin-right: 0;
}
.p-sosaiKnowledgeStyleFamily03 ol li span {
  margin-top: -83px;
  margin-bottom: 39px;
  display: block;
  text-align: center;
}
.p-sosaiKnowledgeStyleFamily03 ol li h3 {
  margin-bottom: 27px;
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 3rem;
  color: #2e95a3;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiKnowledgeStyleFamily03 ol li p {
  line-height: 3rem;
  padding: 0 11px;
}

.p-sosaiKnowledgeStyleFamily04 {
  margin-bottom: 127px;
}
.p-sosaiKnowledgeStyleFamily04 .c-sosaiTitle02b {
  margin-bottom: 53px;
}
.p-sosaiKnowledgeStyleFamily04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeStyleFamily04 .c-sosaiTitle09 {
  line-height: 1;
  padding-left: 28px;
  margin-bottom: 40px;
}
.p-sosaiKnowledgeStyleFamily04 > p {
  margin-bottom: 72px;
  line-height: 3rem;
}
.p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div {
  padding: 36px 40px 32px;
}
.p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div .c-sosaiTitle10 {
  margin-bottom: 15px;
}
.p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div .c-sosaiTitle10:before {
  margin: -5px 10px 0 -30px;
}
.p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div p {
  line-height: 3rem;
}

.p-sosaiKnowledgeStyleFamily05 .c-sosaiTitle02b {
  margin-bottom: 60px;
  padding-left: 6px;
}
.p-sosaiKnowledgeStyleFamily05 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeStyleFamily05__content01 b {
  font-weight: 700;
}
.p-sosaiKnowledgeStyleFamily05__content01 > li {
  padding: 108px 0 73px;
  position: relative;
}
.p-sosaiKnowledgeStyleFamily05__content01 > li:after {
  width: 30px;
  height: 48px;
  margin: 0 auto;
  content: "";
  background-image: url("../img/component/3arrow02.png");
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  z-index: 10;
}
.p-sosaiKnowledgeStyleFamily05__content01 > li:nth-child(odd) {
  background-color: #f8f8f8;
}
.p-sosaiKnowledgeStyleFamily05__content01 > li:last-child {
  padding: 106px 0 92px;
}
.p-sosaiKnowledgeStyleFamily05__content01 > li:last-child:after {
  content: none;
}
.p-sosaiKnowledgeStyleFamily05__content01 > li .u-inner01 {
  position: relative;
}
.p-sosaiKnowledgeStyleFamily05__content01__title {
  margin-bottom: 22px;
  font-size: 3.2rem;
  line-height: 1;
  font-weight: normal;
  color: #111111;
  padding-left: 65px;
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiKnowledgeStyleFamily05__content01__title img {
  width: 50px;
  margin: 0;
  position: absolute;
  top: -10px;
  left: 0;
}
.p-sosaiKnowledgeStyleFamily05__content01__txt {
  min-height: 240px;
  padding-right: 460px;
  position: relative;
}
.p-sosaiKnowledgeStyleFamily05__content01__txt p {
  margin-bottom: 20px;
  line-height: 3rem;
  text-align: justify;
}
.p-sosaiKnowledgeStyleFamily05__content01__txt p img {
  position: absolute;
  top: -10px;
  right: 0;
}

.p-sosaiKnowledgeStyleFamily06 {
  padding: 106px 0 140px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeStyleFamily06 .c-sosaiTitle02b {
  margin-bottom: 57px;
}
.p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 li {
  margin-right: 30px;
}
.p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 li:last-child {
  margin-right: 0;
}
.p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 li:after {
  bottom: 3px;
}
.p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 li img {
  margin-bottom: 17px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeStyleFamily {
    font-size: 1.6rem;
  }
  .p-sosaiKnowledgeStyleFamily01 {
    min-width: 100%;
    margin-bottom: 46px;
    padding: 32px 0 33px;
  }
  .p-sosaiKnowledgeStyleFamily02 {
    margin-bottom: 45px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeStyleFamily02 .c-sosaiTitle02b {
    margin-bottom: 30px;
  }
  .p-sosaiKnowledgeStyleFamily02 .c-sosaiTitle02b span:after {
    margin: 11px auto 0;
  }
  .p-sosaiKnowledgeStyleFamily02__content01 {
    margin-bottom: 40px;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyleFamily02__content01 img {
    display: block;
    margin: 0 auto 16px;
    position: static;
  }
  .p-sosaiKnowledgeStyleFamily02__content01 > p {
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeStyleFamily03 {
    margin-bottom: 47px;
    padding: 48px 20px 11px;
  }
  .p-sosaiKnowledgeStyleFamily03 .c-sosaiTitle02b {
    margin-bottom: 26px;
    padding-left: 4px;
  }
  .p-sosaiKnowledgeStyleFamily03 .c-sosaiTitle02b span:after {
    margin: 11px auto 0;
  }
  .p-sosaiKnowledgeStyleFamily03 .u-inner01 > p {
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiKnowledgeStyleFamily03 ol {
    margin: 24px 0 39px 0;
    padding-top: 20px;
  }
  .p-sosaiKnowledgeStyleFamily03 ol li {
    width: 100%;
    height: auto;
    margin-bottom: 39px;
    padding: 10px 18px 15px;
  }
  .p-sosaiKnowledgeStyleFamily03 ol li:last-child {
    margin-bottom: 0;
  }
  .p-sosaiKnowledgeStyleFamily03 ol li span {
    margin-top: -42px;
    margin-bottom: 18px;
    display: block;
    text-align: center;
  }
  .p-sosaiKnowledgeStyleFamily03 ol li span img {
    width: 60px;
  }
  .p-sosaiKnowledgeStyleFamily03 ol li h3 {
    margin-bottom: 23px;
    font-size: 2.1rem;
    line-height: 2.5rem;
  }
  .p-sosaiKnowledgeStyleFamily03 ol li p {
    font-size: 1.4rem;
    line-height: 1.7;
    padding: 0;
  }
  .p-sosaiKnowledgeStyleFamily04 {
    margin-bottom: 75px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeStyleFamily04 .c-sosaiTitle02b {
    margin-bottom: 27px;
  }
  .p-sosaiKnowledgeStyleFamily04 .c-sosaiTitle02b span:after {
    margin: 11px auto 0;
  }
  .p-sosaiKnowledgeStyleFamily04 .c-sosaiTitle09 {
    font-size: 1.9rem;
    line-height: 2.1rem;
    margin-left: -5px;
    padding-left: 14px;
    margin-bottom: 18px;
  }
  .p-sosaiKnowledgeStyleFamily04 > p {
    margin-bottom: 33px;
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div {
    padding: 15px 20px;
  }
  .p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div .c-sosaiTitle10 {
    margin-bottom: 4px;
  }
  .p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div .c-sosaiTitle10:before {
    margin: -5px 5px 0 -25px;
  }
  .p-sosaiKnowledgeStyleFamily04 .c-sosaiTextBox01 > div p {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeStyleFamily05 .c-sosaiTitle02b {
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeStyleFamily05 .c-sosaiTitle02b span:after {
    margin: 11px auto 0;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li {
    padding: 47px 20px 47px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li:after {
    width: 30px;
    height: 48px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li:nth-child(2) {
    padding: 53px 20px 44px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li:nth-child(2) .p-sosaiKnowledgeStyleFamily05__content01__title {
    margin-bottom: 24px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li:nth-child(2) .p-sosaiKnowledgeStyleFamily05__content01__title img {
    top: -4px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li:last-child {
    padding: 53px 20px 44px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li:last-child .p-sosaiKnowledgeStyleFamily05__content01__title {
    margin-bottom: 23px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01 > li:last-child .p-sosaiKnowledgeStyleFamily05__content01__title img {
    top: -4px;
  }
  .p-sosaiKnowledgeStyleFamily05__content01__txt {
    min-height: auto;
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyleFamily05__content01__txt p {
    margin-bottom: 0px;
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiKnowledgeStyleFamily05__content01__txt p img {
    margin: 0 auto 16px;
    display: block;
    position: static;
  }
  .p-sosaiKnowledgeStyleFamily05__content01__title {
    margin-bottom: 17px;
    font-size: 2.1rem;
    line-height: 2.3rem;
    padding-left: 36px;
    position: relative;
  }
  .p-sosaiKnowledgeStyleFamily05__content01__title img {
    width: 30px;
    margin: 0;
    position: absolute;
    top: -3px;
    left: 0;
  }
  .p-sosaiKnowledgeStyleFamily06 {
    padding: 48px 15px 39px;
    margin-bottom: 59px;
  }
  .p-sosaiKnowledgeStyleFamily06 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeStyleFamily06 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 {
    flex-wrap: wrap;
  }
  .p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-bottom: 8px;
    margin-right: 10px;
    float: none;
  }
  .p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 li:nth-child(2n) {
    margin-right: 0;
  }
  .p-sosaiKnowledgeStyleFamily06 .c-sosaiKanrenLink01 li img {
    margin-bottom: 8px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　無宗教葬とは
------------------------------------------------------------*/
.p-sosaiKnowledgeStyleReligion {
  background-color: #ffffff;
  font-size: 1.7rem;
  line-height: 1.76;
  color: #111111;
}

.p-sosaiKnowledgeStyleReligion01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiKnowledgeStyleReligion01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiKnowledgeStyleReligion02 {
  margin-bottom: 100px;
  padding-top: 19px;
}
.p-sosaiKnowledgeStyleReligion02 .c-sosaiTitle02b {
  margin-bottom: 52px;
}
.p-sosaiKnowledgeStyleReligion02 .c-sosaiTitle02b span:after {
  margin: 2px auto 0;
}
.p-sosaiKnowledgeStyleReligion02__content01 {
  min-height: 240px;
  margin-bottom: 60px;
  padding-right: 445px;
  position: relative;
}
.p-sosaiKnowledgeStyleReligion02__content01 img {
  position: absolute;
  top: 8px;
  right: 0;
}
.p-sosaiKnowledgeStyleReligion02__content01 > p {
  margin-bottom: 40px;
  line-height: 3rem;
}

.p-sosaiKnowledgeStyleReligion03 {
  margin-bottom: 127px;
}
.p-sosaiKnowledgeStyleReligion03 .c-sosaiTitle02b span:after {
  margin: 4px auto 0;
}
.p-sosaiKnowledgeStyleReligion03 > p {
  margin-bottom: 72px;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(2) h3 {
  padding-left: 125px;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(2) p {
  padding: 60px 0;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(3) p {
  padding: 56px 0 64px;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(4) h3 {
  padding-top: 42px;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(4) h3 img {
  top: 42px;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(4) p {
  padding: 42px 20px 43px 0;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(6) p {
  padding: 45px 20px 45px 0;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(7) p {
  padding: 60px 0;
}
.p-sosaiKnowledgeStyleReligion03__content01 > li p {
  padding: 59px 0 61px;
}

.p-sosaiKnowledgeStyleReligion04 {
  margin-bottom: 113px;
}
.p-sosaiKnowledgeStyleReligion04 .c-sosaiTitle02b {
  margin-bottom: 53px;
}
.p-sosaiKnowledgeStyleReligion04 .c-sosaiTitle02b span:after {
  margin: 5px auto 0;
}
.p-sosaiKnowledgeStyleReligion04__content01 p {
  line-height: 3rem;
}
.p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 {
  margin: 72px 0 52px;
  background-color: #f9f9f9;
}
.p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 > div {
  padding: 36px 30px 24px 40px;
}
.p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 ul li {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 10px;
  padding-left: 18px;
  font-weight: 700;
}
.p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 ul li:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}

.p-sosaiKnowledgeStyleReligion05 {
  padding: 106px 0 139px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiKnowledgeStyleReligion05 .c-sosaiTitle02b {
  margin-bottom: 58px;
}
.p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 li {
  margin-right: 30px;
}
.p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 li:last-child {
  margin-right: 0;
}
.p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 li:after {
  bottom: 3px;
}
.p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 li img {
  margin-bottom: 17px;
}

@media screen and (max-width: 640px) {
  .p-sosaiKnowledgeStyleReligion {
    font-size: 1.6rem;
  }
  .p-sosaiKnowledgeStyleReligion01 {
    min-width: 100%;
    margin-bottom: 46px;
    padding: 32px 0 33px;
  }
  .p-sosaiKnowledgeStyleReligion02 {
    margin-bottom: 72px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeStyleReligion02 .c-sosaiTitle02b {
    margin-bottom: 29px;
  }
  .p-sosaiKnowledgeStyleReligion02 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeStyleReligion02__content01 {
    padding-right: 0;
    position: static;
  }
  .p-sosaiKnowledgeStyleReligion02__content01 img {
    display: block;
    margin: 0 auto 16px;
    position: static;
  }
  .p-sosaiKnowledgeStyleReligion02__content01 > p {
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeStyleReligion03 {
    margin-bottom: 74px;
    padding: 0 15px;
  }
  .p-sosaiKnowledgeStyleReligion03 .c-sosaiTitle02b {
    margin-bottom: 25px;
  }
  .p-sosaiKnowledgeStyleReligion03 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeStyleReligion03 > p {
    margin-bottom: 24px;
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li {
    min-height: auto;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(odd) {
    min-height: auto;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(1) {
    padding: 21px 15px 10px 56px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(1) h3 {
    padding: 0 0 8px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(2) p, .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(3) p, .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(4) p, .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(5) p, .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(6) p, .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(7) p {
    padding: 0;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(2) {
    padding: 21px 15px 11px 56px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(2) h3 {
    padding: 0 0 9px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(3) {
    padding: 21px 15px 11px 56px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(3) h3 {
    padding: 0 0 8px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(4) {
    padding: 21px 15px 10px 56px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(4) h3 {
    padding: 0 0 8px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(4) h3 img {
    top: -5px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(6) {
    padding: 21px 15px 9px 56px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(6) h3 {
    padding: 0 0 8px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li:nth-child(7) {
    padding: 21px 15px 17px 56px;
  }
  .p-sosaiKnowledgeStyleReligion03__content01 > li p {
    padding: 0;
  }
  .p-sosaiKnowledgeStyleReligion04 {
    margin-bottom: 43px;
    padding: 0 20px;
  }
  .p-sosaiKnowledgeStyleReligion04 .c-sosaiTitle02b {
    margin-bottom: 26px;
  }
  .p-sosaiKnowledgeStyleReligion04 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeStyleReligion04__content01 > p {
    font-size: 1.4rem;
    line-height: 1.7;
    text-align: left;
  }
  .p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 {
    margin: 25px 0 26px;
  }
  .p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 > div {
    padding: 15px 20px 1px 20px;
  }
  .p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 ul li {
    margin-bottom: 12px;
    padding-left: 13px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .p-sosaiKnowledgeStyleReligion04__content01 .c-sosaiTextBox01 ul li:before {
    margin: -3px 7px 0 -14px;
  }
  .p-sosaiKnowledgeStyleReligion05 {
    padding: 49px 15px 40px;
    margin-bottom: 58px;
  }
  .p-sosaiKnowledgeStyleReligion05 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
  .p-sosaiKnowledgeStyleReligion05 .c-sosaiTitle02b span:after {
    margin: 12px auto 0;
  }
  .p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 {
    flex-wrap: wrap;
  }
  .p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 li {
    width: calc(50% - 5px);
    margin-bottom: 8px;
    margin-right: 10px;
    float: none;
  }
  .p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 li:nth-child(2n) {
    margin-right: 0;
  }
  .p-sosaiKnowledgeStyleReligion05 .c-sosaiKanrenLink01 li img {
    margin-bottom: 8px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
葬祭　組合員の声
------------------------------------------------------------*/
.p-sosaiVoice {
  background-color: #ffffff;
  font-size: 1.8rem;
  line-height: 1.77;
  color: #111111;
}

.p-sosaiVoice01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiVoice01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiVoice02 {
  margin-bottom: 120px;
  padding-top: 22px;
}
.p-sosaiVoice02__txt {
  text-align: center;
}
.p-sosaiVoice02__content01 {
  margin-top: 70px;
}
.p-sosaiVoice02__content01__box {
  min-height: 265px;
  margin-bottom: 40px;
  padding: 40px 480px 42px 40px;
  border: solid 1px #e3e3e3;
  position: relative;
}
.p-sosaiVoice02__content01__box__title {
  margin-bottom: 24px;
  color: #2e95a3;
}
.p-sosaiVoice02__content01__box__title__icon {
  width: 80px;
  margin-right: 21px;
}
.p-sosaiVoice02__content01__box__title__txt {
  width: calc(100% - 95px);
  padding-top: 7px;
}
.p-sosaiVoice02__content01__box__title__txt p {
  font-size: 1.5rem;
  font-weight: 700;
}
.p-sosaiVoice02__content01__box__title__txt p span {
  font-size: 1.2rem;
  color: #ffffff;
  background: #2e95a3;
  margin-left: 15px;
  padding: 4px 12px 2px;
}
.p-sosaiVoice02__content01__box__title__txt h2 {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 8px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiVoice02__content01__box__txt {
  font-size: 1.7rem;
}
.p-sosaiVoice02__content01__box__txt img {
  position: absolute;
  top: 40px;
  right: 40px;
}

.p-sosaiVoice03 {
  padding: 106px 0 140px 0;
  background-image: url("../img/sosai/index_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.p-sosaiVoice03 .c-sosaiTitle02b {
  margin-bottom: 60px;
}

@media screen and (max-width: 640px) {
  .p-sosaiVoice {
    font-size: 1.4rem;
  }
  .p-sosaiVoice01 {
    min-width: 100%;
    margin-bottom: 46px;
    padding: 32px 0 33px;
  }
  .p-sosaiVoice02 {
    margin-bottom: 50px;
    padding: 0 15px;
  }
  .p-sosaiVoice02__txt {
    text-align: left;
    padding: 0 5px;
  }
  .p-sosaiVoice02__content01 {
    margin-top: 20px;
  }
  .p-sosaiVoice02__content01__box {
    margin-bottom: 20px;
    padding: 19px 19px 15px;
    position: static;
  }
  .p-sosaiVoice02__content01__box__title {
    margin-bottom: 16px;
    position: relative;
  }
  .p-sosaiVoice02__content01__box__title__icon {
    width: 48px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .p-sosaiVoice02__content01__box__title__txt {
    width: 100%;
    padding-top: 0;
  }
  .p-sosaiVoice02__content01__box__title__txt p {
    font-size: 1.3rem;
    padding-left: 60px;
  }
  .p-sosaiVoice02__content01__box__title__txt p span {
    font-size: 1rem;
    padding: 2px 11px 1px;
    margin-left: 0;
  }
  .p-sosaiVoice02__content01__box__title__txt h2 {
    font-size: 1.8rem;
    line-height: 1.2;
    margin-top: 13px;
  }
  .p-sosaiVoice02__content01__box__txt {
    font-size: 1.3rem;
    line-height: 1.6;
  }
  .p-sosaiVoice02__content01__box__txt img {
    margin: 0 auto 15px auto;
    display: block;
    position: static;
  }
  .p-sosaiVoice03 {
    padding: 50px 20px;
    margin-bottom: 55px;
    overflow: hidden;
  }
  .p-sosaiVoice03 .c-sosaiTitle02b {
    margin-bottom: 40px;
  }
}
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-sosaiFaq {
  padding: 52px 0 40px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq {
    padding: 85px 20px 70px;
  }
}
.p-sosaiFaq .c-breadcrumb1 {
  padding-bottom: 32px;
}
.p-sosaiFaq .c-breadcrumb1 a {
  color: #308bbb;
}
.p-sosaiFaq__title {
  margin-bottom: 53px;
  text-align: center;
  font-size: 3.2rem;
  color: #46483c;
  line-height: 1.35;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__title {
    margin-bottom: 30px;
    font-size: 2.1rem;
  }
}
.p-sosaiFaq__title span {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  opacity: 0.5;
}
.p-sosaiFaq__title02 {
  font-size: 2.4rem;
  color: #fff;
  background: #af8ebe;
  padding: 16px 29px 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__title02 {
    width: calc(100% + 40px);
    margin-left: -20px;
    font-size: 1.6rem;
    padding: 13px 15px 12px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
}
.p-sosaiFaq__box {
  margin-bottom: 20px;
}
.p-sosaiFaq__box:last-of-type {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box:last-of-type {
    margin-bottom: 30px;
  }
}
.p-sosaiFaq__box__q {
  font-size: 2rem;
  color: #46483c;
  line-height: 1.6;
  background: #f2f2f2;
  padding: 15px 29px 13px;
  cursor: pointer;
  position: relative;
  display: table;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box__q {
    font-size: 1.4rem;
    padding: 13px 35px 11px 15px;
  }
}
.p-sosaiFaq__box__q span {
  display: table-cell;
  width: 27px;
  margin-right: 5px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box__q span {
    width: 20px;
  }
}
.p-sosaiFaq__box__q:after, .p-sosaiFaq__box__q:before {
  content: "";
  background: #46483c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s ease-out;
}
.p-sosaiFaq__box__q:after {
  width: 24px;
  height: 3px;
  right: 20px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box__q:after {
    width: 10px;
    height: 2px;
    right: 10px;
  }
}
.p-sosaiFaq__box__q:before {
  width: 3px;
  height: 24px;
  right: 30px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box__q:before {
    width: 2px;
    height: 10px;
    right: 14px;
  }
}
.p-sosaiFaq__box__a {
  display: none;
  background: #fff;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 24px 40px 29px 37px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box__a {
    padding: 15px;
  }
}
.p-sosaiFaq__box__a p {
  font-size: 1.6rem;
  color: #46483c;
  line-height: 2;
  display: table;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box__a p {
    font-size: 1.3rem;
    line-height: 1.6;
  }
}
.p-sosaiFaq__box__a span {
  display: table-cell;
  width: 32px;
  font-size: 2rem;
  font-weight: bold;
  color: #af8ebe;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__box__a span {
    width: 20px;
    font-size: 1.8rem;
  }
}
.p-sosaiFaq__box.is-open .p-sosaiFaq__box__q::before {
  transform: translateY(-50%) rotate(-90deg);
}
.p-sosaiFaq__btn {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__btn {
    display: block;
    margin-bottom: 25px;
  }
}
.p-sosaiFaq__btn li {
  width: 550px;
  height: 80px;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__btn li {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
}
.p-sosaiFaq__btn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #af8ebe;
  border: 3px solid #dddddd;
  border-radius: 50px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__btn a {
    font-size: 1.5rem;
    padding: 0 30px 0 10px;
    line-height: 1.6;
    border: 2px solid #dddddd;
  }
}
.p-sosaiFaq__btn a:after {
  content: "";
  width: 12px;
  height: 7px;
  background: url("../img/common/icon_down05.png");
  background-size: contain;
  position: absolute;
  top: 34px;
  right: 27px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-sosaiFaq__btn a:after {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

/*------------------------------------------------------------
葬祭　ペットの火葬・霊園
------------------------------------------------------------*/
.p-sosaiPet {
  background-color: #ffffff;
  font-size: 1.8rem;
  line-height: 1.77;
  color: #111111;
}

.p-sosaiPet01 {
  min-width: 1120px;
  padding: 63px 0 69px;
  background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.p-sosaiPet01 .c-sosaiTitle03 {
  margin: 0;
}

.p-sosaiPet02 {
  margin-bottom: 115px;
  padding-top: 15px;
}
.p-sosaiPet02__content01 {
  margin-bottom: 116px;
}
.p-sosaiPet02__content01--txt01, .p-sosaiPet02__content01--txt02 {
  font-size: 1.8rem;
  line-height: 1.8;
}
.p-sosaiPet02__content01--txt01 b, .p-sosaiPet02__content01--txt02 b {
  font-weight: 700;
}
.p-sosaiPet02__content01--img {
  margin: 70px auto 52px;
  display: block;
}
.p-sosaiPet02__content02 {
  overflow: hidden;
}
.p-sosaiPet02__content02 .c-sosaiTitle02b {
  margin-bottom: 56px;
}
.p-sosaiPet02__content02__txt {
  width: 680px;
  float: left;
}
.p-sosaiPet02__content02__txt .c-sosaiTitle09 {
  margin-bottom: 18px;
}
.p-sosaiPet02__content02__txt p {
  margin-bottom: 69px;
  font-size: 1.7rem;
}
.p-sosaiPet02__content02__txt p:last-child {
  margin: 0;
}
.p-sosaiPet02__content02__img {
  width: 400px;
  float: right;
}

.p-sosaiPet03 {
  padding: 104px 0 120px 0;
  background-color: #faf9f6;
}
.p-sosaiPet03__content01 {
  margin-bottom: 60px;
  padding-top: 15px;
}
.p-sosaiPet03__content01 dl {
  margin-bottom: 60px;
  background-color: #ffffff;
  border: solid 1px #333745;
}
.p-sosaiPet03__content01 dl:last-child {
  margin: 0;
}
.p-sosaiPet03__content01 dl dt {
  padding: 9px 10px 8px 66px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #ffffff;
  background-color: #333745;
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPet03__content01 dl dt:before {
  width: 30px;
  height: 30px;
  content: "";
  position: absolute;
  background: url("../img/sosai/icon_sosai01.png") no-repeat;
  -webkit-background-size: 100%;
  background-size: 100%;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
}
.p-sosaiPet03__content01 dl dd {
  padding: 50px;
  font-size: 1.6rem;
  color: #111111;
}
.p-sosaiPet03__content01 dl dd .p-sosaiPet03__table {
  width: 530px;
  margin-bottom: 40px;
}
.p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row {
  width: 100%;
  display: table;
  padding: 22px 10px 22px 0;
  border-top: dotted 1px #111111;
}
.p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:last-child {
  border-bottom: dotted 1px #111111;
}
.p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row .col-left {
  display: table-cell;
  width: 170px;
  text-align: left;
  vertical-align: top;
  padding-left: 10px;
}
.p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row .col-left:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 0;
  content: "";
  background-color: #0074d9;
  display: inline-block;
}
.p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row .col-right {
  display: table-cell;
  vertical-align: top;
  font-weight: 500;
}
.p-sosaiPet03__content01 dl dd .c-sosaiBtn01 {
  width: 100%;
}
.p-sosaiPet03__content01__box01 {
  margin-bottom: 40px;
  padding: 25px 20px;
  text-align: center;
  background-color: #f8f8f8;
  border: solid 1px #c8c8c8;
}
.p-sosaiPet03__content01__box01 .c-sosaiTitle10 {
  margin-bottom: 7px;
}
.p-sosaiPet03__content01__box01 p {
  margin-bottom: 9px;
  font-size: 1.5rem;
  font-weight: 700;
}
.p-sosaiPet03__content01__box01 span {
  display: block;
  font-size: 1.2rem;
}
.p-sosaiPet03__content01__box02 {
  width: 500px;
  padding: 36px 30px 34px 41px;
  border: solid 1px #c8c8c8;
}
.p-sosaiPet03__content01__box02 .c-sosaiTitle10 {
  margin-bottom: 11px;
}
.p-sosaiPet03__content01__box02 ul li {
  margin-bottom: 11px;
  padding-left: 18px;
  font-size: 1.5rem;
  line-height: 1.6;
}
.p-sosaiPet03__content01__box02 ul li:last-child {
  margin-bottom: 0;
}
.p-sosaiPet03__content01__box02 ul li:before {
  width: 8px;
  height: 8px;
  margin: -4px 10px 0 -18px;
  content: "";
  background-color: #0074d9;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiPet03__content01__img {
  width: 435px;
}
.p-sosaiPet03__content01__img img {
  width: 100%;
}
.p-sosaiPet03__content01__detail {
  width: 530px;
}
.p-sosaiPet03__content01__detail__tel .tel-num {
  padding: 5px 0 11px;
  display: block;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: 3px;
  color: #111111;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPet03__content01__detail__tel .tel-num:before {
  width: 25px;
  height: 28px;
  margin: -13px 11px 0 7px;
  content: "";
  background-image: url("../img/common/icon_tel06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiPet03__content01__detail__tel span {
  padding: 0 0 3px 17px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #333333;
  text-indent: -1em;
  display: block;
}
.p-sosaiPet03__content02__tel {
  width: 560px;
  margin: 0 auto;
  padding: 23px 30px;
  color: #2f2933;
  background-color: #ffffff;
  text-align: center;
  border: solid 1px #c8c8c8;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPet03__content02__tel h3 {
  font-size: 2rem;
  font-weight: 700;
}
.p-sosaiPet03__content02__tel .tel-number {
  margin: 3px 0;
  display: block;
  font-size: 3.6rem;
  line-height: 1;
  letter-spacing: 1px;
  color: #111111;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.p-sosaiPet03__content02__tel .tel-number:before {
  width: 24px;
  height: 24px;
  margin: -13px 13px 0 0;
  content: "";
  background-image: url("../img/common/icon_tel06.png");
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.p-sosaiPet03__content02__tel p {
  color: #111111;
  font-size: 1.6rem;
  line-height: 1.5;
}

@media screen and (max-width: 640px) {
  .p-sosaiPet {
    font-size: 1.6rem;
  }
  .p-sosaiPet01 {
    min-width: 100%;
    margin-bottom: 50px;
    padding: 32px 0 33px;
  }
  .p-sosaiPet02 {
    margin-bottom: 45px;
    padding: 0 15px;
  }
  .p-sosaiPet02__content01 {
    margin-bottom: 73px;
  }
  .p-sosaiPet02__content01 .c-sosaiTitle02b {
    margin-bottom: 26px;
  }
  .p-sosaiPet02__content01--txt01, .p-sosaiPet02__content01--txt02 {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .p-sosaiPet02__content01--txt02 {
    text-align: left;
  }
  .p-sosaiPet02__content01--img {
    margin: 25px auto 26px;
  }
  .p-sosaiPet02__content02 .c-sosaiTitle02b {
    margin-bottom: 37px;
  }
  .p-sosaiPet02__content02__txt {
    width: 100%;
    padding: 0 0 0 10px;
  }
  .p-sosaiPet02__content02__txt p {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 34px;
  }
  .p-sosaiPet02__content02__txt .c-sosaiTitle09 {
    margin-bottom: 12px;
  }
  .p-sosaiPet02__content02__img {
    width: 100%;
    margin-bottom: 50px !important;
  }
  .p-sosaiPet03 {
    padding: 48px 15px;
    margin-bottom: 62px;
  }
  .p-sosaiPet03 .c-sosaiTitle02b {
    margin-bottom: 25px;
  }
  .p-sosaiPet03__content01 {
    margin-bottom: 30px;
  }
  .p-sosaiPet03__content01 dl {
    margin-bottom: 30px;
  }
  .p-sosaiPet03__content01 dl dt {
    font-size: 1.9rem;
    line-height: 1;
    padding: 14px 20px 12px 61px;
    display: flex;
    align-items: center;
  }
  .p-sosaiPet03__content01 dl dt:before {
    width: 40px;
    height: 40px;
    left: 10px;
  }
  .p-sosaiPet03__content01 dl dd {
    padding: 20px 15px 15px;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table {
    width: 100%;
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row {
    width: 100%;
    display: table;
    padding: 14px 10px 13px 0;
    border-top: dotted 1px #111111;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:last-child {
    border-bottom: dotted 1px #111111;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:nth-child(3) {
    display: block;
    padding: 15px 0 18px;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:nth-child(3) .col-left, .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:nth-child(3) .col-right {
    width: 100%;
    display: block;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:nth-child(3) .col-right .c-contactBtn02 {
    width: 100%;
    height: 59px;
    max-width: 260px;
    margin: 12px auto 18px;
    padding: 4px 10px 6px 71px;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:nth-child(3) .col-right .c-contactBtn02--btn02 {
    padding: 4px 10px 6px 90px;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row:nth-child(3) .col-right .c-contactBtn02--btn02:before {
    left: 50px;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row .col-left {
    width: 29%;
    padding: 0 0 0 2px;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row .col-left:before {
    width: 6px;
    height: 6px;
    margin: -4px 6px 0 0;
  }
  .p-sosaiPet03__content01 dl dd .p-sosaiPet03__table--row .col-right {
    display: table-cell;
    font-weight: 500;
    line-height: 1.5;
  }
  .p-sosaiPet03__content01 dl dd .c-sosaiBtn01 {
    width: 56%;
    height: auto;
    font-size: 1.3rem;
  }
  .p-sosaiPet03__content01 dl dd .c-sosaiBtn01 a {
    padding: 5px;
  }
  .p-sosaiPet03__content01__box01 {
    margin-bottom: 15px;
    padding: 20px 20px 15px;
    text-align: left;
  }
  .p-sosaiPet03__content01__box01 .c-sosaiTitle10 {
    margin-bottom: 7px;
    line-height: 1;
    text-align: center;
  }
  .p-sosaiPet03__content01__box01 p {
    margin-bottom: 2px;
    font-size: 1.1rem;
    line-height: 1.5;
  }
  .p-sosaiPet03__content01__box01 span {
    font-size: 1rem;
  }
  .p-sosaiPet03__content01__box02 {
    width: 100%;
    margin-bottom: 15px;
    padding: 14px 20px 16px 20px;
  }
  .p-sosaiPet03__content01__box02 .c-sosaiTitle10 {
    font-size: 1.5rem;
    margin-bottom: 6px;
  }
  .p-sosaiPet03__content01__box02:last-child {
    margin-bottom: 0;
  }
  .p-sosaiPet03__content01__box02 ul li {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 4px;
  }
  .p-sosaiPet03__content01__box02 ul li:before {
    width: 6px;
    height: 6px;
    margin: -2px 8px 0 -18px;
  }
  .p-sosaiPet03__content01__img {
    width: 100%;
    margin-bottom: 17px;
  }
  .p-sosaiPet03__content01__detail {
    width: 100%;
  }
  .p-sosaiPet03__content01__detail__tel .tel-num {
    color: #fff;
    font-size: 1.8rem;
    line-height: 1;
    letter-spacing: 1.1px;
    padding: 5px 0 0;
  }
  .p-sosaiPet03__content01__detail__tel .tel-num:before {
    display: none;
  }
  .p-sosaiPet03__content01__detail__tel .contact-time {
    font-size: 1rem;
    line-height: 1.3;
    margin: -1px 0 0 0;
  }
  .p-sosaiPet03__content01__detail__tel span {
    font-size: 1rem;
    line-height: 1.3;
    padding: 0 0 3px 12px;
  }
  .p-sosaiPet03__content02__tel {
    width: 100%;
    margin: 0 auto;
    padding: 15px 15px 17px;
  }
  .p-sosaiPet03__content02__tel h3 {
    font-size: 1.6rem;
    margin-bottom: 7px;
  }
  .p-sosaiPet03__content02__tel .tel-number {
    color: #fff;
    font-size: 1.9rem;
    margin: 0;
  }
  .p-sosaiPet03__content02__tel .tel-number:before {
    display: none;
  }
  .p-sosaiPet03__content02__tel p {
    color: #fff;
    font-size: 1rem;
    line-height: 1.4;
  }
}
.page-syukatsu {
  min-width: 1320px;
}
@media screen and (max-width: 640px) {
  .page-syukatsu {
    min-width: 320px;
  }
}
.page-syukatsu.overflow {
  overflow: hidden;
}

.p-syukatsu {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  overflow-x: hidden;
}
.p-syukatsu .l-container {
  width: 100%;
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .l-container {
    width: 100%;
  }
}
.p-syukatsu .img-shadow {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}
.p-syukatsu .c-popup {
  background: rgba(50, 73, 97, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease-in-out;
  z-index: 999;
  overflow: auto;
  padding: 100px 0 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup {
    padding: 60px 0;
  }
}
.p-syukatsu .c-popup.is-visible {
  opacity: 1;
  visibility: visible;
}
.p-syukatsu .c-popup__close {
  position: absolute;
  top: -52px;
  right: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__close {
    width: 23px;
    height: 23px;
    top: -33px;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-popup__close:hover .on {
    display: none;
  }
  .p-syukatsu .c-popup__close:hover .off {
    display: block;
  }
}
.p-syukatsu .c-popup__close .off {
  display: none;
}
.p-syukatsu .c-popup .l-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.p-syukatsu .c-popup__main {
  position: relative;
  width: 100%;
  max-width: 1000px;
  height: 100%;
}
.p-syukatsu .c-popup__wrapper {
  background: #fff;
  padding: 60px 100px;
  width: 1000px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  overflow: auto;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__wrapper {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__wrapper {
    padding: 10px;
  }
}
.p-syukatsu .c-popup__text01 {
  font-size: 1.8rem;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: #fff;
  background: #4C93A1;
  text-align: center;
  padding: 8px 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text01 {
    font-size: 1.4rem;
    line-height: 20px;
    padding: 7px 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__text01 {
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
  }
}
.p-syukatsu .c-popup__inner {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.p-syukatsu .c-popup__inner .c-popup__text02 {
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__inner .c-popup__text02 {
    display: none;
  }
}
.p-syukatsu .c-popup__content {
  width: calc(100% - 107px);
  padding-left: 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__content {
    width: 100%;
    padding-left: 0;
  }
}
.p-syukatsu .c-popup__img {
  text-align: center;
}
.p-syukatsu .c-popup__text02 {
  width: 107px;
  min-width: 107px;
  height: 107px;
  border-radius: 50%;
  border: 1px solid #4C93A1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  line-height: 30px;
  font-weight: 900;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #4C93A1;
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text02 {
    width: 100%;
    height: auto;
    font-size: 1.2rem;
    line-height: 1;
    padding: 4px 0 2px;
    border-radius: 29px;
    margin-top: 15px;
    display: flex;
  }
}
.p-syukatsu .c-popup__text03 {
  font-size: 3.8rem;
  line-height: 42px;
  letter-spacing: 0.075em;
  font-weight: 900;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #324961;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text03 {
    font-size: 1.9rem;
    line-height: 1;
    text-align: center;
    max-width: 295px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__text03 {
    font-size: 4.2666666667vw;
  }
}
.p-syukatsu .c-popup__text03dot {
  position: relative;
}
.p-syukatsu .c-popup__text03dot::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background: #E04A5E;
  border-radius: 50%;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text03dot::before {
    width: 3px;
    height: 3px;
    top: -5px;
  }
}
.p-syukatsu .c-popup__text04 {
  color: #4C93A1;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 5.8rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text04 {
    text-align: center;
    justify-content: center;
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__text04 {
    font-size: 8.8vw;
  }
}
.p-syukatsu .c-popup__text04-1 {
  font-size: 4.6rem;
  position: relative;
  white-space: nowrap;
}
.p-syukatsu .c-popup__text04-1 span {
  font-size: 4.2rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text04-1 span {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__text04-1 span {
    font-size: 5.6vw;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text04-1 {
    font-size: 2.7rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__text04-1 {
    font-size: 6.6666666667vw;
  }
}
.p-syukatsu .c-popup__text04-1::after {
  content: "※";
  color: #4C93A1;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 900;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text04-1::after {
    font-size: 1rem;
    right: -5px;
    top: -10px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__text04-1::after {
    font-size: 2.6666666667vw;
  }
}
.p-syukatsu .c-popup__text05 {
  font-size: 1.4rem;
  line-height: 20px;
  font-weight: 500;
  text-align: right;
  margin-top: 15px;
  color: #2F2933;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text05 {
    font-size: 1.2rem;
    line-height: 18px;
    margin-top: 8px;
  }
}
.p-syukatsu .c-popup__text05 span {
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__text05 span:first-child {
    color: #324961;
  }
  .p-syukatsu .c-popup__text05 span:nth-child(2) {
    margin: 8px 0 6px;
  }
}
.p-syukatsu .c-popup__btn {
  margin-top: 25px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__btn {
    margin-top: 8px;
  }
}
.p-syukatsu .c-popup__btn a {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 3px solid #3C76D2;
  border-radius: 50px;
  font-size: 2.4rem;
  line-height: 36px;
  color: #3C76D2;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: relative;
  padding: 19px 40px;
  font-weight: 600;
  max-width: 480px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__btn a {
    border-width: 2px;
    font-size: 1.6rem;
    line-height: 24px;
    letter-spacing: 0.05em;
    padding: 9px 20px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__btn a {
    font-size: 4.2666666667vw;
    line-height: 6.4vw;
    padding: 10px 20px;
  }
}
.p-syukatsu .c-popup__btn a span {
  margin: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__btn a span {
    flex: 1;
    margin: 0 10px;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-popup__btn a:hover {
    opacity: 1;
    background: #3C76D2;
    color: #fff;
  }
  .p-syukatsu .c-popup__btn a:hover::before {
    background: url(/img/sosai/lp/2022_syukatsu/icon_tab_W.svg) no-repeat center;
  }
  .p-syukatsu .c-popup__btn a:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-popup__btn a::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 20px;
  height: 26px;
  background: url(/img/sosai/lp/2022_syukatsu/icon_tab.svg) no-repeat center;
  background-size: 20px 26px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-popup__btn a::after {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 18px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  background-size: 8px 18px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-popup__list {
  background: #F9F9F9;
  padding: 30px;
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__list {
    margin-top: 10px;
    padding: 15px;
  }
}
.p-syukatsu .c-popup__listcnt {
  display: flex;
  justify-content: center;
}
.p-syukatsu .c-popup__listitem {
  width: 190px;
  text-align: center;
}
.p-syukatsu .c-popup__listitem:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__listitem:not(:last-child) {
    margin-right: 10px;
  }
}
.p-syukatsu .c-popup__listtitle {
  font-size: 1.4rem;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 900;
  color: #4C93A1;
  border: 1px solid #4C93A1;
  padding: 3px 0;
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-popup__listtitle {
    font-size: 1.2rem;
    line-height: 15px;
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-popup__listtitle {
    font-size: 3.2vw;
    line-height: 4vw;
    min-height: 11.2vw;
  }
}
.p-syukatsu .c-logo {
  padding: 30px 40px 20px;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-logo {
    padding: 10px 20px;
    text-align: center;
    width: 100%;
  }
  .p-syukatsu .c-logo img {
    height: 17px;
  }
}
.p-syukatsu .c-mv__wrapper {
  background: url(/img/sosai/lp/2022_syukatsu/mv.jpg) no-repeat top right 45%;
  padding: 80px 0 124px;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__wrapper {
    padding: 34px 0 0;
    position: relative;
    background: none;
  }
  .p-syukatsu .c-mv__wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: url(/img/sosai/lp/2022_syukatsu/mv-sp.jpg) no-repeat center top;
    background-size: cover;
    z-index: -1;
  }
}
.p-syukatsu .c-mv__content {
  width: 50%;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__content {
    width: 100%;
  }
}
.p-syukatsu .c-mv__text01 {
  font-size: 2rem;
  line-height: 28px;
  letter-spacing: 0.15em;
  font-weight: 900;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  background: rgba(255, 255, 255, 0.6);
  color: #4C93A1;
  border: 1px solid #4C93A1;
  padding: 5px 0;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text01 {
    line-height: 0;
    margin-bottom: 56%;
  }
  .p-syukatsu .c-mv__text01 img {
    height: 10px;
  }
}
.p-syukatsu .c-mv__text02 {
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 0.25em;
  line-height: 41px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #2F2933;
  position: relative;
  z-index: 1;
}
.p-syukatsu .c-mv__img {
  margin: -160px -100% -120px 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__img {
    margin: 10px 0 30px;
  }
}
.p-syukatsu .c-mv__circle {
  position: absolute;
  top: -3px;
  left: 20px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__circle {
    left: 10px;
  }
}
.p-syukatsu .c-mv__circle span {
  display: block;
  width: 3px;
  height: 3px;
  background: #E04A5E;
  border-radius: 50%;
}
.p-syukatsu .c-mv__circle span:not(:last-child) {
  margin-right: 19px;
}
@media screen and (max-width: 1320px) {
  .p-syukatsu .c-mv__circle span:not(:last-child) {
    margin-right: 11px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__circle span:not(:last-child) {
    margin-right: 7px;
  }
}
.p-syukatsu .c-mv__text03 {
  width: 224px;
  height: 96px;
  text-align: center;
  position: relative;
  margin-left: 210px;
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text03 {
    width: 135px;
    height: 65px;
    margin-left: 95px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__text03 {
    width: 100px;
    height: 60px;
    margin-left: 80px;
  }
}
.p-syukatsu .c-mv__text03::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 42px;
  background: url(/img/sosai/lp/2022_syukatsu/mv_line.svg) no-repeat center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text03::after {
    height: 25px;
    background-size: 100% 25px;
  }
}
.p-syukatsu .c-mv__text03-1 {
  font-size: 1.6rem;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: #324961;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 6px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text03-1 {
    font-size: 0.95rem;
    line-height: 15px;
  }
}
.p-syukatsu .c-mv__text03-1::before, .p-syukatsu .c-mv__text03-1::after {
  content: "";
  position: relative;
  display: block;
  top: 0;
  width: 19px;
  height: 17px;
  background-size: 19px 17px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text03-1::before, .p-syukatsu .c-mv__text03-1::after {
    width: 14px;
    height: 12px;
    background-size: 14px 12px;
  }
}
.p-syukatsu .c-mv__text03-1::before {
  background: url(/img/sosai/lp/2022_syukatsu/icon03.svg) no-repeat center;
  margin-right: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text03-1::before {
    margin-right: 8px;
  }
}
.p-syukatsu .c-mv__text03-1::after {
  background: url(/img/sosai/lp/2022_syukatsu/icon04.svg) no-repeat center;
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text03-1::after {
    margin-left: 8px;
  }
}
.p-syukatsu .c-mv__text03-2 {
  color: #E04A5E;
  font-size: 2.2rem;
  line-height: 28px;
  letter-spacing: 0.025em;
  position: relative;
  font-weight: 900;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text03-2 {
    font-size: 1.4rem;
    line-height: 16px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__text03-2 {
    font-size: 1rem;
  }
}
.p-syukatsu .c-mv__text03-3 {
  color: #324961;
}
.p-syukatsu .c-mv__text04 {
  font-size: 1.4rem;
  line-height: 19px;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: #324961;
  text-align: right;
  margin-top: 20px;
  padding: 0 40px;
  text-shadow: 2px 2px 2px #fff, -2px -2px 2px #fff, -2px 2px 2px #fff, 2px -2px 2px #fff, 2px 0px 2px #fff, -2px 0px 2px #fff, 0px 2px 2px #fff, 0px -2px 2px #fff;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__text04 {
    font-size: 1.1rem;
    line-height: 16px;
    margin-top: 10px;
    padding: 0;
    text-shadow: none;
  }
}
.p-syukatsu .c-mv__box01 {
  border: 3px solid #3C76D2;
  border-radius: 90px;
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  padding: 25px 117px 25px 253px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01 {
    padding: 15px;
    display: block;
    border-radius: 12px;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-mv__box01:hover {
    background: #3C76D2;
    opacity: 1;
  }
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt01,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt02,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt04,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt05,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt06,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt07 {
    color: #fff;
  }
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt01::after,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt02::after,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt04::after,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt05::after,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt06::after,
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt07::after {
    color: #fff;
  }
  .p-syukatsu .c-mv__box01:hover .c-mv__box01Txt03 {
    background: #fff;
  }
  .p-syukatsu .c-mv__box01:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-mv__box01::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 24px;
  background-size: 11px 24px !important;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01::after {
    width: 5px;
    height: 12px;
    background-size: 5px 12px !important;
    right: 20px;
    top: 23%;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01::after {
    top: 32px;
    right: 12px;
    transform: none;
  }
}
.p-syukatsu .c-mv__box01Img01 {
  width: 193px;
  position: absolute;
  bottom: 10px;
  left: -5px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Img01 {
    width: 78px;
    bottom: inherit;
    top: -5%;
    transform: rotate(-10deg);
    left: -90px;
  }
}
.p-syukatsu .c-mv__box01Txt01 {
  font-size: 2.4rem;
  line-height: 32px;
  letter-spacing: 0.03em;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #2F2933;
  margin-bottom: 5px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt01 {
    font-size: 1.4rem;
    line-height: 23px;
    text-align: center;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01Txt01 {
    font-size: 1.2rem;
  }
}
.p-syukatsu .c-mv__box01Txt02 {
  font-size: 4rem;
  letter-spacing: 0.1em;
  line-height: 53px;
  font-weight: 900;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #3C76D2;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt02 {
    font-size: 2.3rem;
    line-height: 31px;
    text-align: center;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01Txt02 {
    font-size: 2rem;
  }
}
.p-syukatsu .c-mv__box01Content02 {
  display: flex;
  align-items: center;
  margin-left: 45px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Content02 {
    margin-left: 0;
    margin-top: 8px;
    justify-content: center;
  }
}
.p-syukatsu .c-mv__box01Txt03 {
  font-size: 1.4rem;
  line-height: 20px;
  font-weight: bold;
  color: #4C93A1;
  text-align: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 1px solid #4C93A1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt03 {
    width: 70px;
    height: 70px;
    min-width: 70px;
    font-size: 1rem;
    line-height: 14px;
    margin-right: 8px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01Txt03 {
    font-size: 0.6rem;
    line-height: normal;
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
}
.p-syukatsu .c-mv__box01Txt04 {
  font-size: 1.6rem;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: #2F2933;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt04 {
    font-size: 1.2rem;
    line-height: 16px;
    margin-bottom: -8px;
  }
}
.p-syukatsu .c-mv__box01Content04 {
  display: flex;
  align-items: center;
}
.p-syukatsu .c-mv__box01Img02 {
  width: 126px;
  margin-left: 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Img02 {
    width: 96px;
    margin-left: 8px;
    position: relative;
    top: 6px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01Img02 {
    width: 78px;
  }
}
.p-syukatsu .c-mv__box01Txt05 {
  font-size: 2.4rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  letter-spacing: 0.05em;
  line-height: 31px;
  color: #4C93A1;
  position: relative;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt05 {
    font-size: 1.6rem;
    line-height: 22px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01Txt05 {
    font-size: 1.4rem;
  }
}
.p-syukatsu .c-mv__box01Txt05::after {
  content: "※";
  position: absolute;
  bottom: 15px;
  right: -5px;
  font-size: 1rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  color: #2F2933;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt05::after {
    bottom: 10px;
    font-size: 0.7rem;
  }
}
.p-syukatsu .c-mv__box01Txt06 {
  font-size: 3.8rem;
  letter-spacing: 0.05em;
  color: #4C93A1;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: relative;
  top: 3px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt06 {
    font-size: 3rem;
    line-height: 40px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01Txt06 {
    font-size: 2.5rem;
  }
}
.p-syukatsu .c-mv__box01Txt07 {
  font-size: 1.8rem;
  line-height: 31px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  color: #2F2933;
  position: relative;
  top: 2px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box01Txt07 {
    font-size: 1.2rem;
    line-height: 22px;
    top: 1px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__box01Txt07 {
    font-size: 1rem;
  }
}
.p-syukatsu .c-mv__bottom {
  background: #fff url(/img/sosai/lp/2022_syukatsu/bg05.png) no-repeat center;
  background-size: cover !important;
  padding: 80px 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__bottom {
    margin-top: 30px;
    padding: 30px 0;
    background: url(/img/sosai/lp/2022_syukatsu/bg05-sp.png) no-repeat center;
  }
}
.p-syukatsu .c-mv__box02 {
  border: 1px solid #4C93A1;
  display: flex;
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box02 {
    margin-top: 0;
    display: block;
    margin-bottom: 20px;
  }
}
.p-syukatsu .c-mv__box02Title {
  width: 266px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  line-height: 37px;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  text-align: center;
  background: url(/img/sosai/lp/2022_syukatsu/bg06.png) no-repeat center;
  background-size: cover;
  color: #fff;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box02Title {
    width: 100%;
    font-size: 1.9rem;
    line-height: 24px;
    padding: 8px 0;
  }
}
.p-syukatsu .c-mv__box02Content {
  width: calc(100% - 266px);
  padding: 40px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 36px;
  color: #2F2933;
  background: #fff;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__box02Content {
    width: 100%;
    padding: 20px;
    font-size: 1.3rem;
    line-height: 26px;
    letter-spacing: 0.05em;
  }
}
.p-syukatsu .c-mv__box02Cl {
  font-weight: bold;
  color: #4C93A1;
}
.p-syukatsu .c-mv__list {
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__list {
    display: block;
  }
}
.p-syukatsu .c-mv__list.js-scroll {
  position: fixed;
  left: 0;
  top: -121px;
  transition: 0.5s ease-in-out;
  width: 100%;
  background: #fff;
  z-index: 99;
  padding: 20px 0;
  justify-content: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__list.js-scroll {
    display: none;
  }
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem {
  border: none;
  box-shadow: none;
  font-size: 1.4rem;
  width: calc((100% - 250px) / 3);
  max-width: 353px;
  line-height: normal;
  padding: 10px 20px;
  border-radius: 0;
  position: relative;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem br {
  display: none;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 50px;
  width: 1px;
  border-left: 1px solid #D4D4D4;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem1 {
  width: 260px;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem1 .c-mv__listLink {
  padding: 10px 15px;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem1 .c-mv__listLink::after {
  right: 10px;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem1 .c-mv__listText01 {
  display: none;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem1 .c-mv__listText02 .scroll-only {
  display: none;
  transition: 0.5s ease-in-out;
}
.p-syukatsu .c-mv__list.js-scroll .c-mv__listItem1 .c-mv__listText02-2 {
  font-size: 2.8rem;
}
.p-syukatsu .c-mv__list.js-scroll.fixed {
  position: fixed;
  top: 0;
}
.p-syukatsu .c-mv__list.js-scroll.fixed .c-mv__listText02 .scroll-only {
  transition: 0.5s ease-in-out;
  display: inline-block;
}
.p-syukatsu .c-mv__list a {
  width: calc(25% - 10px);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__list a {
    width: 100%;
  }
}
.p-syukatsu .c-mv__list a:not(:last-child) {
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__list a:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5px;
  }
}
.p-syukatsu .c-mv__listItem {
  border: 2px solid #D4D4D4;
  border-radius: 8px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 32px;
  font-size: 2.2rem;
  line-height: 32px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  text-align: center;
  color: #324961;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listItem {
    font-size: 1.4rem;
    line-height: 16px;
    border-width: 1px;
    padding: 16px 40px;
    border-radius: 4px;
  }
  .p-syukatsu .c-mv__listItem br {
    display: none;
  }
}
.p-syukatsu .c-mv__listItem:hover {
  opacity: 1;
  border-color: #4C93A1;
  color: #4C93A1;
}
.p-syukatsu .c-mv__listItem::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 8px;
  background-size: 15px 8px !important;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow02.svg) no-repeat center;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listItem::after {
    left: inherit;
    bottom: inherit;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
.p-syukatsu .c-mv__listItem1 {
  width: calc(25% - 10px);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listItem1 {
    position: fixed;
    width: 100%;
    bottom: -116px;
    left: 0;
    z-index: 99;
    padding: 20px 40px;
    background: rgba(50, 73, 97, 0.5);
    transition: 0.5s ease-in-out;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listItem1.fixed {
    bottom: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__listItem1 {
    padding: 10px;
  }
}
.p-syukatsu .c-mv__listItem1 .c-mv__listLink {
  width: 100%;
  border: 3px solid #3C76D2;
  border-radius: 8px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 22px 20px;
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink {
    padding: 15px 10px;
    justify-content: center;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink {
    padding: 10px;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink:hover {
    opacity: 1;
    background: #3C76D2;
  }
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink:hover .c-mv__listText01 {
    border-color: #fff;
    color: #fff;
  }
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink:hover .c-mv__listText02-1 {
    color: #fff;
  }
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink:hover .c-mv__listText02-2 {
    color: #fff;
  }
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-mv__listItem1 .c-mv__listLink::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 24px;
  background-size: 11px 24px !important;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listItem1 .c-mv__listLink::after {
    width: 5px;
    height: 12px;
    background-size: 5px 12px !important;
    right: 10px;
  }
}
.p-syukatsu .c-mv__listText01 {
  font-size: 1.6rem;
  line-height: 21px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3C76D2;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-right: 15px;
  letter-spacing: 0.1em;
  color: #3C76D2;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listText01 {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 1.2rem;
    margin-right: 5px;
  }
}
.p-syukatsu .c-mv__listText02 {
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listText02 {
    display: flex;
    align-items: center;
  }
}
.p-syukatsu .c-mv__listText02-1 {
  font-size: 1.4rem;
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #3C76D2;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listText02-1 {
    font-size: 1.2rem;
    line-height: 15px;
  }
}
.p-syukatsu .c-mv__listText02-2 {
  font-size: 3.4rem;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 0.2em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #3C76D2;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-mv__listText02-2 {
    font-size: 2.6rem;
    margin-left: 5px;
  }
}
.p-syukatsu .c-title01 {
  font-size: 4.6rem;
  line-height: 52px;
  letter-spacing: 0.1em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  text-align: center;
  position: relative;
  color: #324961;
  padding-bottom: 34px;
  font-weight: 600;
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-title01 {
    font-size: 2.7rem;
    line-height: 38px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
}
.p-syukatsu .c-title01 .cl-01 {
  color: #4C93A1;
}
.p-syukatsu .c-title01::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
  height: 10px;
  background: url(/img/sosai/lp/2022_syukatsu/icon01.svg) no-repeat center;
  background-size: 38px 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-title01::after {
    width: 34px;
    height: 8px;
    background-size: 34px 8px;
  }
}
.p-syukatsu .c-title02 {
  text-align: center;
}
.p-syukatsu .c-title02__text01 {
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  line-height: 32px;
  font-weight: bold;
  background: #4C93A1;
  color: #fff;
  width: 470px;
  text-align: center;
  padding: 10px 0 8px;
  margin: 0 auto;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-title02__text01 {
    width: calc(100% - 20px);
    font-size: 1.6rem;
    line-height: 21px;
    padding: 4px 0 5px;
  }
}
.p-syukatsu .c-title02__img {
  margin: 40px 0 55px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-title02__img {
    max-width: 202px;
    margin: 20px auto 16px;
  }
}
.p-syukatsu .c-title02__img img {
  margin: 0 auto;
}
.p-syukatsu .c-title02__text03 {
  margin: 30px 0;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-title02__text03 {
    margin: 12px 0;
  }
}
.p-syukatsu .c-title02__text03 img {
  margin: 0 auto;
}
.p-syukatsu .c-title02__text02 {
  display: block;
  font-size: 2.8rem;
  letter-spacing: 0.15em;
  line-height: 37px;
  font-weight: 600;
  color: #2F2933;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-title02__text02 {
    font-size: 2rem;
    line-height: 30px;
  }
}
.p-syukatsu .c-text001 {
  font-size: 1.4rem;
  line-height: 20px;
  font-weight: 500;
  margin-top: 28px;
  text-align: right;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-text001 {
    font-size: 1.1rem;
    line-height: 17px;
    margin-top: 15px;
  }
}
.p-syukatsu .c-text002 {
  font-size: 2rem;
  line-height: 38px;
  letter-spacing: 0.15em;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #2F2933;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-text002 {
    font-size: 1.5rem;
    line-height: 26px;
    text-align: left;
  }
}
.p-syukatsu .c-imgtext01 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-imgtext01 {
    flex-wrap: wrap;
  }
}
.p-syukatsu .c-imgtext01__content {
  width: 50%;
  max-width: 640px;
  padding: 60px;
  background: #fff;
  color: #2F2933;
  position: relative;
  z-index: 1;
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: 0.1em;
  font-weight: 400;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-imgtext01__content {
    width: calc(100% - 40px);
    order: 2;
    padding: 20px;
    font-size: 1.5rem;
    line-height: 26px;
  }
}
.p-syukatsu .c-imgtext01__img {
  margin: 0 -260px 0 -80px;
  width: calc(50% + 340px);
}
.p-syukatsu .c-imgtext01__img img {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-imgtext01__img {
    width: calc(100% + 10px);
    margin: 0 -10px -50px 20px;
    order: 1;
  }
}
.p-syukatsu .c-imgtext01.is-style01 .c-imgtext01__img {
  order: 1;
  margin: 0 -100px 0 -240px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-imgtext01.is-style01 .c-imgtext01__img {
    margin: 0 20px -50px -10px;
  }
}
.p-syukatsu .c-imgtext01.is-style01 .c-imgtext01__content {
  order: 2;
  margin-left: auto;
}
.p-syukatsu .c-imgtext02 {
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-imgtext02 {
    display: block;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-imgtext02__img img {
    width: 100%;
  }
}
.p-syukatsu .c-imgtext02__content {
  width: calc(100% - 380px);
  padding-right: 40px;
  font-size: 1.8rem;
  line-height: 36px;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: #2F2933;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-imgtext02__content {
    width: 100%;
    padding-right: 0;
    font-size: 1.3rem;
    line-height: 22px;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
  }
}
.p-syukatsu .c-block001 {
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-block001 {
    display: block;
  }
}
.p-syukatsu .c-block001.pc-only {
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-block001.pc-only {
    display: none;
  }
}
.p-syukatsu .c-block001.sp-only {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-block001.sp-only {
    display: block;
  }
}
.p-syukatsu .c-block001__item {
  width: calc(50% - 30px);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-block001__item {
    width: 100%;
  }
}
.p-syukatsu .c-block001__item:not(:last-child) {
  margin-right: 60px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-block001__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.p-syukatsu .c-block001__title {
  border: 3px solid #4C93A1;
  font-size: 2.4rem;
  line-height: 32px;
  letter-spacing: 0.1em;
  color: #4C93A1;
  font-weight: 900;
  text-align: center;
  padding: 13px 0 9px;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-block001__title {
    font-size: 1.6rem;
    line-height: 23px;
    letter-spacing: 0.05em;
    padding: 3px 12px;
    margin-bottom: 10px;
  }
}
.p-syukatsu .c-box01 {
  background: #fff;
  padding: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box01 {
    padding: 10px;
  }
}
.p-syukatsu .c-box01 + .c-box01 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box01 + .c-box01 {
    margin-top: 20px;
  }
}
.p-syukatsu .c-box01__wrapper {
  padding: 40px 60px 60px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box01__wrapper {
    padding: 20px 15px 20px;
  }
}
.p-syukatsu .c-box01__title {
  background: #4C93A1;
  font-size: 3.2rem;
  line-height: 43px;
  padding: 20px 0 17px;
  color: #fff;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box01__title {
    font-size: 2rem;
    line-height: 28px;
    padding: 12px 20px;
  }
}
.p-syukatsu .c-box01__text {
  font-size: 1.8rem;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #2F2933;
  font-weight: 500;
  margin: 40px 0 34px;
  padding: 0 60px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box01__text {
    padding: 0 15px;
    font-size: 1.3rem;
    line-height: 22px;
    letter-spacing: 0.1em;
    margin: 15px 0;
  }
}
.p-syukatsu .c-box01__text + .c-box01__wrapper {
  padding-top: 0;
}
.p-syukatsu .c-box02 {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}
.p-syukatsu .c-box02__top {
  padding: 57px 50px 24px;
  background: url(/img/sosai/lp/2022_syukatsu/bg02.jpg) no-repeat center;
  color: #fff;
  display: flex;
  justify-content: space-between;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__top {
    padding: 71px 20px 12px;
    justify-content: flex-start;
  }
}
.p-syukatsu .c-box02__bottom {
  padding: 20px 50px 40px;
  background: #fff;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__bottom {
    padding: 12px 20px 20px;
  }
}
.p-syukatsu .c-box02__content {
  display: flex;
  justify-content: space-between;
}
.p-syukatsu .c-box02__img {
  margin: -87px 0 -250px -10px;
  width: 282px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__img {
    margin: 0;
    width: 67px;
  }
}
.p-syukatsu .c-box02__bltext {
  display: flex;
  justify-content: flex-end;
  margin-left: 26.7%;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__bltext {
    margin-left: 0;
    position: relative;
    padding-top: 65px;
    justify-content: center;
  }
}
.p-syukatsu .c-box02__bltextImg {
  padding: 14px 0 0;
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__bltextImg {
    width: 140px;
    margin-left: 0;
    padding: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02__bltextImg {
    width: 100px;
  }
}
.p-syukatsu .c-box02__bltextContent {
  min-width: 542px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__bltextContent {
    min-width: inherit;
    width: calc(100% - 140px);
    padding-right: 9px;
    max-width: 155px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02__bltextContent {
    width: calc(100% - 100px);
  }
}
.p-syukatsu .c-box02__title {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 13px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__title {
    width: calc(100% - 67px);
    padding-left: 10px;
    margin-left: 0;
  }
}
.p-syukatsu .c-box02__titleContent {
  width: calc(100% - 120px);
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__titleContent {
    width: 100%;
    padding-left: 0;
  }
}
.p-syukatsu .c-box02__text01 {
  position: absolute;
  top: -87px;
  left: 120px;
  background: #4C93A1;
  border-radius: 7px;
  color: #fff;
  padding: 15px 0;
  text-align: center;
  font-size: 2.4rem;
  line-height: 32px;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  width: 620px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text01 {
    top: -96px;
    width: calc(100% + 67px);
    left: -67px;
    font-size: 1.3rem;
    line-height: 20px;
    font-weight: bold;
    padding: 6px 20px;
    border-radius: 3.5px;
  }
}
.p-syukatsu .c-box02__text01::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 20px solid #4C93A1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text01::after {
    bottom: -10px;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 12.5px solid #4C93A1;
  }
}
.p-syukatsu .c-box02__text02 {
  width: 120px;
  height: 120px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text02 {
    position: absolute;
    top: -27px;
    left: -67px;
    width: calc(100% + 67px);
    height: auto;
    border-radius: 29px;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 14px;
    height: 20px;
  }
}
.p-syukatsu .c-box02__text02-2 {
  font-size: 3rem;
  letter-spacing: 0.1em;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text02-2 {
    font-size: 1.2rem;
    line-height: 14px;
    display: inline;
    letter-spacing: 0;
  }
}
.p-syukatsu .c-box02__text03 {
  font-size: 2.4rem;
  line-height: 38px;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  margin-bottom: 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text03 {
    font-size: 1.4rem;
    line-height: 20px;
  }
}
.p-syukatsu .c-box02__text04 {
  font-size: 4.6rem;
  line-height: 51px;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text04 {
    font-size: 2.2rem;
    line-height: 24px;
  }
}
.p-syukatsu .c-box02__text05 {
  font-size: 1.6rem;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: #324961;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text05 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 1.2rem;
    line-height: 16px;
    margin-bottom: 0;
  }
}
.p-syukatsu .c-box02__text05::before, .p-syukatsu .c-box02__text05::after {
  content: "";
  position: relative;
  display: block;
  top: 0;
  width: 19px;
  height: 17px;
  background-size: 19px 17px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text05::before, .p-syukatsu .c-box02__text05::after {
    width: 14px;
    height: 12px;
    background-size: 14px 12px;
  }
}
.p-syukatsu .c-box02__text05::before {
  background: url(/img/sosai/lp/2022_syukatsu/icon03.svg) no-repeat center;
  margin-right: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text05::before {
    margin-right: 12px;
  }
}
.p-syukatsu .c-box02__text05::after {
  background: url(/img/sosai/lp/2022_syukatsu/icon04.svg) no-repeat center;
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text05::after {
    margin-left: 12px;
  }
}
.p-syukatsu .c-box02__text06 {
  font-size: 1.8rem;
  line-height: 24px;
  letter-spacing: 0.2em;
  font-weight: bold;
  color: #fff;
  background: #3C76D2;
  padding: 5px 20px 3px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text06 {
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    font-size: 1.3rem;
    line-height: 18px;
    padding: 9px 10px 8px;
    letter-spacing: 0.05em;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02__text06 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}
.p-syukatsu .c-box02__text07 {
  font-size: 3rem;
  line-height: 40px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  color: #324961;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text07 {
    text-align: left;
    font-size: 2.3rem;
    line-height: 31px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02__text07 {
    font-size: 2rem;
  }
}
.p-syukatsu .c-box02__text07-1, .p-syukatsu .c-box02__text07-2 {
  color: #4C93A1;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text07-1, .p-syukatsu .c-box02__text07-2 {
    display: inline;
  }
}
.p-syukatsu .c-box02__text07-1 {
  font-size: 5.4rem;
  line-height: 72px;
  position: relative;
  top: 5px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text07-1 {
    font-size: 3.9rem;
    letter-spacing: 0.04em;
    line-height: 1;
    top: 3px;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text07-1 span {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02__text07-1 {
    font-size: 3.2rem;
  }
}
.p-syukatsu .c-box02__text07-3 {
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text07-3 {
    display: inline;
  }
}
.p-syukatsu .c-box02__text08 {
  font-size: 1.5rem;
  line-height: 20px;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: #2F2933;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__text08 {
    font-size: 1.2rem;
    line-height: 18px;
    width: calc(100% + 140px);
    text-align: center;
    margin-top: 10px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02__text08 {
    width: calc(100% + 100px);
  }
  .p-syukatsu .c-box02__text08 br {
    display: none;
  }
}
.p-syukatsu .c-box02__circle {
  position: absolute;
  top: -13px;
  left: 20px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02__circle {
    display: none;
  }
}
.p-syukatsu .c-box02__circle span {
  display: block;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
}
.p-syukatsu .c-box02__circle span:not(:last-child) {
  margin-right: 41px;
}
.p-syukatsu .c-box02 .c-btn001 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02 .c-btn001 {
    margin-top: 10px;
  }
}
.p-syukatsu .c-box02 .c-btn001__link {
  display: block;
  max-width: 840px;
  margin: 0 auto;
  border: 3px solid #3C76D2;
  color: #3C76D2;
  border-radius: 70px;
  padding: 30px 0 17px;
  text-align: center;
  line-height: 56px;
  font-weight: 500;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: relative;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02 .c-btn001__link {
    line-height: normal;
    padding: 15px 0;
    border-width: 1.5px;
  }
}
.p-syukatsu .c-box02 .c-btn001__link::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 21px;
  background-size: 11px 21px !important;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02 .c-btn001__link::after {
    width: 5px;
    height: 12px;
    background-size: 5px 12px !important;
    right: 20px;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-box02 .c-btn001__link:hover {
    background: #3C76D2;
    color: #fff;
  }
  .p-syukatsu .c-box02 .c-btn001__link:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
  .p-syukatsu .c-box02 .c-btn001__link:hover .c-btn001__text01::before {
    background: url(/img/sosai/lp/2022_syukatsu/icon05_W.svg) no-repeat center;
  }
  .p-syukatsu .c-box02 .c-btn001__link:hover .c-btn001__text01::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon06_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-box02 .c-btn001__text01 {
  font-size: 4.3rem;
  position: relative;
  padding: 0 20px;
  margin-right: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02 .c-btn001__text01 {
    font-size: 2rem;
    line-height: 27px;
    margin-right: 10px;
    padding: 0 8px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02 .c-btn001__text01 {
    font-size: 1.6rem;
  }
}
.p-syukatsu .c-box02 .c-btn001__text01::before, .p-syukatsu .c-box02 .c-btn001__text01::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 34px;
  background-size: 13px 34px !important;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02 .c-btn001__text01::before, .p-syukatsu .c-box02 .c-btn001__text01::after {
    width: 6px;
    height: 16px;
    background-size: 6px 16px !important;
  }
}
.p-syukatsu .c-box02 .c-btn001__text01::before {
  background: url(/img/sosai/lp/2022_syukatsu/icon05.svg) no-repeat center;
  top: 0;
  left: 0;
}
.p-syukatsu .c-box02 .c-btn001__text01::after {
  background: url(/img/sosai/lp/2022_syukatsu/icon06.svg) no-repeat center;
  bottom: 0;
  right: 0;
}
.p-syukatsu .c-box02 .c-btn001__text02 {
  font-size: 4.3rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box02 .c-btn001__text02 {
    font-size: 2rem;
    line-height: 27px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box02 .c-btn001__text02 {
    font-size: 1.6rem;
  }
}
.p-syukatsu .c-box02 .c-btn001__text03 {
  font-size: 3.4rem;
  position: relative;
  top: 4px;
}
.p-syukatsu .c-box03 {
  border: 10px solid rgba(76, 147, 161, 0.3);
  background: #fff;
  padding: 75px;
  position: relative;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03 {
    padding: 30px 20px 20px;
    border-width: 5px;
  }
}
.p-syukatsu .c-box03__inner01 {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__inner01 {
    display: block;
  }
}
.p-syukatsu .c-box03__inner02 {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__inner02 {
    display: block;
    margin-top: 20px;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__img img {
    width: 100%;
  }
}
.p-syukatsu .c-box03__content {
  margin-right: 22px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__content {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.p-syukatsu .c-box03__bltext {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__bltext {
    margin-top: 10px;
  }
}
.p-syukatsu .c-box03__info {
  padding-left: 30px;
  border-left: 1px solid #D4D4D4;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__info {
    padding-left: 0;
    border-left: 0;
  }
}
.p-syukatsu .c-box03__phone {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__phone {
    border: 1.5px solid #D4D4D4;
    border-radius: 50px;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    height: 70px;
    margin-top: 10px;
  }
  .p-syukatsu .c-box03__phone .c-box03__text05 {
    display: flex;
  }
  .p-syukatsu .c-box03__phone::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 21px;
    background-size: 11px 21px !important;
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    transition: 0.3s ease-in-out;
  }
}
@media screen and (max-width: 640px) and (max-width: 640px) {
  .p-syukatsu .c-box03__phone::after {
    width: 5px;
    height: 12px;
    background-size: 5px 12px !important;
    right: 20px;
  }
}
.p-syukatsu .c-box03__phone .c-box03__text05 {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__phone .c-box03__text05 {
    display: flex;
  }
}
.p-syukatsu .c-box03__text01 {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 2.4rem;
  line-height: 32px;
  letter-spacing: 0.1em;
  font-weight: bold;
  background: #4C93A1;
  padding: 10px 0 8px;
  width: 380px;
  color: #fff;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text01 {
    top: -20px;
    width: calc(100% - 80px);
    font-size: 1.6rem;
    line-height: 21px;
    padding: 4px 0 5px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box03__text01 {
    width: calc(100% - 40px);
  }
}
.p-syukatsu .c-box03__text02 {
  font-size: 2.6rem;
  letter-spacing: 0.15em;
  line-height: 46px;
  font-weight: 500;
  color: #324961;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text02 {
    font-size: 1.3rem;
    line-height: 19px;
    text-align: center;
    font-weight: bold;
    font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  }
}
.p-syukatsu .c-box03__text03 {
  width: 120px;
  height: 120px;
  border: 1px solid #4C93A1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4C93A1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text03 {
    width: 70px;
    height: 70px;
  }
}
.p-syukatsu .c-box03__text03Content * {
  display: block;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text03Content {
    width: 37px;
  }
}
.p-syukatsu .c-box03__text03-1 {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.15em;
}
.p-syukatsu .c-box03__text03-2 {
  font-size: 2.8rem;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  margin: 5px 0;
}
.p-syukatsu .c-box03__text03-3 {
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.01em;
}
.p-syukatsu .c-box03__text04 {
  width: calc(100% - 120px);
  padding-left: 20px;
  font-size: 3.8rem;
  line-height: 60px;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #4C93A1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text04 {
    width: calc(100% - 70px);
    padding-left: 10px;
    font-size: 1.8rem;
    line-height: 27px;
    letter-spacing: 0.1em !important;
  }
  .p-syukatsu .c-box03__text04 br {
    display: none;
  }
}
.p-syukatsu .c-box03__text04-1 {
  letter-spacing: 0.12em;
}
.p-syukatsu .c-box03__text04-2 {
  letter-spacing: 0.14em;
}
.p-syukatsu .c-box03__text05 {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 27px;
  color: #3C76D2;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text05 {
    font-size: 1.3rem;
    line-height: 16px;
    justify-content: center;
    display: none;
    position: relative;
    top: 5px;
  }
}
.p-syukatsu .c-box03__text05::before {
  content: "";
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  background-size: 22px 22px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
  margin-right: 8px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text05::before {
    width: 12px;
    height: 12px;
    background-size: 12px 12px;
    margin-right: 4px;
  }
}
.p-syukatsu .c-box03__text06 {
  font-size: 1.2rem;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #fff;
  background: #3C76D2;
  width: 54px;
  padding: 3px 0 1px;
  text-align: center;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text06 {
    width: 62px;
    font-size: 1rem;
    line-height: 14px;
    padding: 3px 0 1px;
  }
  .p-syukatsu .c-box03__text06 br {
    display: none;
  }
}
.p-syukatsu .c-box03__number {
  font-size: 4rem;
  line-height: 1;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #3C76D2;
  letter-spacing: 0.035em;
  font-weight: 600;
  margin-left: 6px;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__number {
    font-size: 2.2rem;
    line-height: 29px;
    letter-spacing: 0.025em;
    margin-left: 4px;
  }
}
.p-syukatsu .c-box03__text07 {
  color: #666666;
  text-align: center;
  width: 135px;
  white-space: nowrap;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text07 {
    width: 100%;
    position: relative;
    top: -4px;
  }
  .p-syukatsu .c-box03__text07 br {
    display: none;
  }
}
.p-syukatsu .c-box03__text07-1 {
  font-size: 1.4rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text07-1 {
    font-size: 1rem;
    line-height: 16px;
  }
}
.p-syukatsu .c-box03__text07-2 {
  font-size: 1.2rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text07-2 {
    font-size: 1rem;
    line-height: 16px;
  }
}
.p-syukatsu .c-box03__text08 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: #3C76D2;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text08 {
    font-size: 1.3rem;
    line-height: 16px;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
  }
}
.p-syukatsu .c-box03__text08::before, .p-syukatsu .c-box03__text08::after {
  content: "";
  position: relative;
  display: block;
  width: 91px;
  height: 3px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat-x;
  background-size: 8px 3px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text08::before, .p-syukatsu .c-box03__text08::after {
    width: 55px;
    height: 2px;
    background-size: 4px 2px;
  }
}
.p-syukatsu .c-box03__text08::before {
  margin-right: 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text08::before {
    margin-right: 5px;
  }
}
.p-syukatsu .c-box03__text08::after {
  margin-left: 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03__text08::after {
    margin-left: 5px;
  }
}
.p-syukatsu .c-box03 .c-btn002 {
  margin-right: 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03 .c-btn002 {
    margin-right: 0;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03 .c-btn002__text {
    text-align: left;
  }
}
.p-syukatsu .c-box03 .c-btn002 a {
  display: block;
  border: 3px solid #3C76D2;
  border-radius: 50px;
  color: #3C76D2;
  width: 610px;
  text-align: center;
  font-size: 2.8rem;
  line-height: 36px;
  font-weight: 600;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  padding: 29px 0;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03 .c-btn002 a {
    width: 100%;
    font-size: 1.8rem;
    line-height: 24px;
    letter-spacing: 0.05em;
    border-width: 1.5px;
    padding: 10px 30px 10px 55px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-box03 .c-btn002 a:hover {
    background: #3C76D2;
    color: #fff;
  }
  .p-syukatsu .c-box03 .c-btn002 a:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
  .p-syukatsu .c-box03 .c-btn002 a:hover::before {
    background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-box03 .c-btn002 a::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-size: 24px 24px !important;
  background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03 .c-btn002 a::before {
    width: 23px;
    height: 23px;
    background-size: 23px 23px !important;
    left: 20px;
  }
}
.p-syukatsu .c-box03 .c-btn002 a::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 21px;
  background-size: 11px 21px !important;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box03 .c-btn002 a::after {
    width: 5px;
    height: 12px;
    background-size: 5px 12px !important;
    right: 20px;
  }
}
.p-syukatsu .c-box04 {
  padding: 60px 60px 90px;
  background: #fff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box04 {
    padding: 20px;
  }
}
.p-syukatsu .c-box04 .c-imgtext02__content {
  width: calc(100% - 400px);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box04 .c-imgtext02__content {
    width: 100%;
  }
}
.p-syukatsu .c-list001 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list001 {
    display: block;
  }
}
.p-syukatsu .c-list001__item {
  border: 5px solid rgba(76, 147, 161, 0.3);
  padding: 40px 40px 60px;
  width: calc(50% - 15px);
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list001__item {
    width: 100%;
    border-width: 2.5px;
    padding: 20px 20px 30px;
    margin-bottom: 20px;
  }
}
.p-syukatsu .c-list001__item:nth-child(2n) {
  margin-left: 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list001__item:nth-child(2n) {
    margin-left: 0;
  }
}
.p-syukatsu .c-list001__title {
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2.2rem;
  line-height: 36px;
  color: #4C93A1;
  font-weight: 900;
  width: calc(100% - 34px);
  border-bottom: 1px solid #4C93A1;
  padding-bottom: 13px;
  margin-bottom: 13px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list001__title {
    font-size: 1.6rem;
    line-height: normal;
    width: calc(100% - 20px);
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
}
.p-syukatsu .c-list001__title::after {
  content: "";
  position: absolute;
  top: 0;
  right: -34px;
  width: 34px;
  height: 34px;
  background: url(/img/sosai/lp/2022_syukatsu/icon02.svg) no-repeat center;
  background-size: 34px 34px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list001__title::after {
    top: inherit;
    bottom: 5px;
    right: -20px;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }
}
.p-syukatsu .c-list001__text {
  font-size: 1.6rem;
  line-height: 28px;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list001__text {
    font-size: 1.3rem;
    line-height: 22px;
  }
}
.p-syukatsu .c-list002__item {
  display: flex;
  align-items: center;
  padding: 40px;
  border: 1px solid #4C93A1;
  position: relative;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list002__item {
    display: block;
    padding: 20px;
  }
}
.p-syukatsu .c-list002__item:not(:last-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list002__item:not(:last-child) {
    margin-bottom: 25px;
  }
}
.p-syukatsu .c-list002__item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: -26px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 20px solid #4C93A1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list002__item:not(:last-child)::after {
    bottom: -21px;
    border-left: 22.5px solid transparent;
    border-right: 22.5px solid transparent;
    border-top: 15px solid #4C93A1;
  }
}
.p-syukatsu .c-list002__stt {
  display: flex;
  align-items: center;
  border-right: 1px solid #DDDDDD;
  padding-right: 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list002__stt {
    border-right: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
}
.p-syukatsu .c-list002__title {
  font-size: 2rem;
  line-height: 28px;
  font-weight: 900;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #4C93A1;
  margin-left: 20px;
  width: 240px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list002__title {
    font-size: 1.6rem;
    line-height: normal;
    margin-left: 10px;
    width: 100%;
  }
}
.p-syukatsu .c-list002__number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  min-width: 60px;
  background: url(/img/sosai/lp/2022_syukatsu/bg01.jpg) no-repeat center;
  color: #fff;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2rem;
  line-height: 36px;
  letter-spacing: 0.1em;
  font-weight: 900;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list002__number {
    width: 30px;
    height: 30px;
    min-width: 30px;
    font-size: 1.4rem;
    line-height: 18px;
  }
}
.p-syukatsu .c-list002__text {
  padding-left: 40px;
  font-size: 1.6rem;
  line-height: 28px;
  font-weight: 500;
  color: #2F2933;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list002__text {
    padding-left: 0;
    font-size: 1.3rem;
    line-height: 22px;
  }
}
.p-syukatsu .c-list003 {
  display: flex;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list003 {
    display: block;
  }
}
.p-syukatsu .c-list003__item {
  position: relative;
  width: calc((100% - 30px) / 3);
  background: #fff;
  border: 5px solid rgba(76, 147, 161, 0.3);
  padding: 35px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list003__item {
    width: 100%;
    padding: 20px;
    border-width: 2.5px;
  }
}
.p-syukatsu .c-list003__item:not(:last-child) {
  margin-right: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list003__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.p-syukatsu .c-list003__point {
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  line-height: 24px;
  color: #4C93A1;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 900;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list003__point {
    position: absolute;
    top: 20px;
    right: 20px;
    width: calc(100% - 120px);
    font-size: 1.2rem;
    line-height: 18px;
    text-align: left;
  }
}
.p-syukatsu .c-list003__img {
  margin: 30px 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list003__img {
    width: 70px;
    height: 70px;
    margin: 0;
  }
}
.p-syukatsu .c-list003__title {
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  line-height: 36px;
  color: #4C93A1;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list003__title {
    width: calc(100% - 80px);
    margin-left: auto;
    margin-top: -50px;
    font-size: 2.1rem;
    line-height: 28px;
    margin-bottom: 0;
    text-align: left;
  }
}
.p-syukatsu .c-list003__text {
  text-align: left;
  font-size: 1.6rem;
  line-height: 32px;
  font-weight: 500;
  color: #2F2933;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-list003__text {
    font-size: 1.3rem;
    line-height: 21px;
    letter-spacing: 0.05em;
    margin-top: 12px;
  }
}
.p-syukatsu .c-copyright {
  font-size: 1.3rem;
  line-height: 17px;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  background: #324961;
  text-align: center;
  padding: 21px 0 22px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-copyright {
    font-size: 1rem;
    line-height: 14px;
    padding: 8px 0;
  }
}
.p-syukatsu .c-box05 {
  background: #fff;
  border: 10px solid rgba(76, 147, 161, 0.3);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 60px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05 {
    border-width: 5px;
    padding: 30px 15px 10px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05 {
    padding: 30px 10px 10px;
  }
}
.p-syukatsu .c-box05__label {
  background: #4C93A1;
  border-radius: 7px;
  color: #fff;
  font-size: 2.4rem;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 32px;
  width: 484px;
  padding: 12px 0;
  position: absolute;
  top: -33px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__label {
    width: calc(100% - 40px);
    font-size: 1.3rem;
    line-height: 20px;
    padding: 8px 0 7px;
    top: -20px;
    border-radius: 4px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__label {
    font-size: 3.4666666667vw;
  }
}
.p-syukatsu .c-box05__label::after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 20px solid #4C93A1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__label::after {
    bottom: -7px;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 12.5px solid #4C93A1;
  }
}
.p-syukatsu .c-box05__text {
  font-size: 2.4rem;
  line-height: 30px;
  letter-spacing: -0.01em;
  font-weight: bold;
  color: #324961;
  white-space: nowrap;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__text {
    font-size: 1.1rem;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0;
    white-space: normal;
    margin-bottom: 5px;
  }
}
.p-syukatsu .c-box05__circle {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4C93A1;
  border-radius: 50%;
}
.p-syukatsu .c-box05__circle img {
  width: 70px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__circle img {
    width: 35px;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__circle {
    display: none;
    width: 70px;
    height: 70px;
  }
}
.p-syukatsu .c-box05__content {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__content {
    display: block;
  }
}
.p-syukatsu .c-box05__cnt {
  margin: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__cnt {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  }
}
.p-syukatsu .c-box05__cnt .c-box05__circle {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__cnt .c-box05__circle {
    display: flex;
  }
}
.p-syukatsu .c-box05__cnttitle {
  font-size: 4rem;
  line-height: 60px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #4C93A1;
  white-space: nowrap;
  margin-bottom: 6px;
}
.p-syukatsu .c-box05__cnttitle span {
  font-size: 3.8rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__cnttitle span {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__cnttitle {
    font-size: 1.8rem;
    line-height: 27px;
    letter-spacing: 0;
    width: calc(100% - 70px);
    white-space: normal;
    padding-left: 10px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__cnttitle {
    font-size: 4.8vw;
    line-height: 7.2vw;
  }
}
.p-syukatsu .c-box05__cntstage {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__cntstage {
    width: 100%;
    margin-top: 5px;
    justify-content: center;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__img {
    display: none;
  }
}
.p-syukatsu .c-box05__stagelabel {
  width: 78px;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  background: #4C93A1;
  padding: 6px 0;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  flex-shrink: 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__stagelabel {
    width: 26px;
    font-size: 0.8rem;
    line-height: 11px;
    padding: 4px 0 3px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__stagelabel {
    width: 6.9333333333vw;
    font-size: 2.1333333333vw;
    line-height: 2.9333333333vw;
    padding: 1.0666666667vw 0 0.8vw;
    margin-right: 1.3333333333vw;
  }
}
.p-syukatsu .c-box05__stagedate {
  font-size: 3.6rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #324961;
  line-height: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__stagedate {
    font-size: 2rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__stagedate {
    font-size: 5.3333333333vw;
  }
}
.p-syukatsu .c-box05__stagedate + .c-box05__stagedate {
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__stagedate + .c-box05__stagedate {
    margin-left: 5px;
  }
}
.p-syukatsu .c-box05__stagedate span {
  font-size: 2.8rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__stagedate span {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__stagedate span {
    font-size: 4.2666666667vw;
  }
}
.p-syukatsu .c-box05__stagedate span.c-box05__stageyear {
  font-size: 3.6rem;
  white-space: nowrap;
}
.p-syukatsu .c-box05__stagedate span.c-box05__stageyear span {
  font-size: 2.8rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__stagedate span.c-box05__stageyear {
    display: none;
  }
}
.p-syukatsu .c-box05__stagedate::after {
  content: "金";
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #324961;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: bold;
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__stagedate::after {
    font-size: 1rem;
    width: 16px;
    height: 17px;
    margin-left: 5px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__stagedate::after {
    font-size: 2.6666666667vw;
    width: 4.2666666667vw;
    height: 5.0666666667vw;
    margin-left: 1.3333333333vw;
  }
}
.p-syukatsu .c-box05__stagedate:last-of-type::before {
  content: "〜";
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: 0.035em;
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__stagedate:last-of-type::before {
    font-size: 1.5rem;
    margin-right: 5px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__stagedate:last-of-type::before {
    font-size: 4vw;
    margin-right: 1.3333333333vw;
  }
}
.p-syukatsu .c-box05__bottom {
  display: flex;
  align-items: center;
  margin: 30px -30px 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__bottom {
    display: block;
    margin: 10px 0 0;
  }
}
.p-syukatsu .c-box05__btn {
  width: 700px;
  padding: 0 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__btn {
    width: 100%;
    padding: 0;
  }
}
.p-syukatsu .c-box05__btntext {
  font-size: 1.6rem;
  line-height: 20px;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-align: center;
  color: #324961;
  position: relative;
  padding: 0 15px;
  max-width: 402px;
  margin: 0 auto 6px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__btntext {
    font-size: 1.1rem;
    line-height: 1;
    max-width: 270px;
    letter-spacing: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__btntext {
    max-width: 72vw;
    font-size: 2.9333333333vw;
  }
}
.p-syukatsu .c-box05__btntext::before, .p-syukatsu .c-box05__btntext::after {
  content: "";
  width: 1px;
  height: 20px;
  border-left: 1px solid #324961;
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__btntext::before, .p-syukatsu .c-box05__btntext::after {
    height: 12.5px;
  }
}
.p-syukatsu .c-box05__btntext::before {
  transform: rotate(-30deg);
  left: 0;
}
.p-syukatsu .c-box05__btntext::after {
  transform: rotate(-150deg);
  right: 0;
}
.p-syukatsu .c-box05__btnlink {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #3C76D2;
  border-radius: 50px;
  font-size: 2.8rem;
  line-height: 36px;
  color: #3C76D2;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: relative;
  padding: 29px 0;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__btnlink {
    border-width: 2px;
    font-size: 1.8rem;
    line-height: 24px;
    letter-spacing: 0.05em;
    padding: 9px 25px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box05__btnlink {
    font-size: 4.2666666667vw;
    line-height: 6.4vw;
    padding: 9px 20px;
  }
}
.p-syukatsu .c-box05__btnlink span {
  margin: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__btnlink span {
    text-align: left;
    flex: 1;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-box05__btnlink:hover {
    opacity: 1;
    background: #3C76D2;
    color: #fff;
  }
  .p-syukatsu .c-box05__btnlink:hover::before {
    background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
  }
  .p-syukatsu .c-box05__btnlink:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-box05__btnlink::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
  background-size: 24px 24px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-box05__btnlink::after {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 18px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  background-size: 8px 18px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-box05__info {
  width: 50%;
  max-width: 510px;
  padding: 8px 30px;
  border-left: 1px solid #D4D4D4;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__info {
    width: 100%;
    max-width: 100%;
    border-left: none;
    padding: 0;
  }
}
.p-syukatsu .c-box05__infotitle {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3C76D2;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infotitle {
    font-size: 1.3rem;
    line-height: 16px;
    display: none;
  }
}
.p-syukatsu .c-box05__infotitle::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
  background-size: 22px 22px !important;
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infotitle::before {
    height: 12px;
    width: 12px;
    background-size: 12px 12px !important;
    margin-right: 5px;
  }
}
.p-syukatsu .c-box05__infocnt {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
  pointer-events: none;
  opacity: 1 !important;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infocnt {
    justify-content: center;
    flex-wrap: wrap;
    border: 2px solid #D4D4D4;
    padding: 5px 0;
    border-radius: 70px;
    margin: 10px 0 8px;
    pointer-events: initial;
  }
}
.p-syukatsu .c-box05__infocnt .c-box05__infotitle {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infocnt .c-box05__infotitle {
    display: flex !important;
    width: 100%;
  }
}
.p-syukatsu .c-box05__infolabel {
  width: 54px;
  background: #3C76D2;
  font-size: 1.2rem;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 5px 0 2px;
  flex-shrink: 0;
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infolabel {
    width: 62px;
    font-size: 1rem;
    line-height: normal;
    display: inline-block;
    padding: 2px 0 0;
  }
}
.p-syukatsu .c-box05__infophone {
  font-size: 4rem;
  line-height: 1;
  letter-spacing: 0.035em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  color: #3C76D2;
  margin: 0 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infophone {
    display: inline-block;
    font-size: 2.2rem;
    letter-spacing: 0.025em;
    margin: 4px 0 4px 8px;
  }
}
.p-syukatsu .c-box05__infotxt {
  font-size: 1.4rem;
  color: #666666;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infotxt {
    width: 100%;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.p-syukatsu .c-box05__infotxt span {
  font-size: 1.2rem;
  margin-top: 4px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infotxt span {
    font-size: 1rem;
    margin-top: 0;
  }
}
.p-syukatsu .c-box05__infotext {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #3C76D2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infotext {
    font-size: 1.3rem;
  }
}
.p-syukatsu .c-box05__infotext span {
  margin: 0 15px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box05__infotext span {
    margin: 0 10px;
  }
}
.p-syukatsu .c-box05__infotext::before, .p-syukatsu .c-box05__infotext::after {
  content: "";
  position: relative;
  display: inline-block;
  height: 3px;
  width: 100%;
  background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat center;
  background-size: 9px 3px;
}
.p-syukatsu .c-box06 {
  margin-top: 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06 {
    margin-top: 20px;
  }
}
.p-syukatsu .c-box06__link {
  border-radius: 90px;
  border: 3px solid #3C76D2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0 16px;
  position: relative;
  background: #fff;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__link {
    border-radius: 12px;
    border-width: 2px;
    padding: 15px;
    display: block;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-box06__link:hover {
    background: #3C76D2;
    opacity: 1;
  }
  .p-syukatsu .c-box06__link:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
  .p-syukatsu .c-box06__link:hover .c-box06__title, .p-syukatsu .c-box06__link:hover .c-box06__subtitle, .p-syukatsu .c-box06__link:hover .c-box06__cnttext {
    color: #fff !important;
  }
  .p-syukatsu .c-box06__link:hover .c-box06__cntlabel {
    background: #fff;
    color: #3C76D2;
  }
  .p-syukatsu .c-box06__link:hover .cl-1 {
    color: #fff;
  }
}
.p-syukatsu .c-box06__link::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  width: 10px;
  height: 24px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  background-size: 10px 24px !important;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__link::after {
    display: none;
  }
}
.p-syukatsu .c-box06__subtitle {
  font-size: 2.4rem;
  letter-spacing: -0.03em;
  color: #2F2933;
  display: block;
  margin-bottom: 12px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__subtitle {
    font-size: 1.4rem;
    letter-spacing: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box06__subtitle {
    font-size: 3.7333333333vw;
  }
}
.p-syukatsu .c-box06__title {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  font-size: 4rem;
  letter-spacing: 0.1em;
  color: #3C76D2;
  line-height: 1;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__title {
    position: relative;
    font-size: 2.3rem;
    text-align: center;
  }
  .p-syukatsu .c-box06__title::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 10px;
    height: 24px;
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
    background-size: 10px 24px !important;
    transition: 0.3s ease-in-out;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box06__title {
    text-align: left;
    font-size: 5.3333333333vw;
  }
}
.p-syukatsu .c-box06__txt {
  display: inline-block;
  position: relative;
  padding: 0 15px;
  margin-right: 20px;
}
.p-syukatsu .c-box06__txt::before, .p-syukatsu .c-box06__txt::after {
  content: "";
  position: absolute;
  height: 34px;
  width: 13px;
  background-size: 13px 34px !important;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__txt::before, .p-syukatsu .c-box06__txt::after {
    height: 17px;
    width: 7px;
    background-size: 7px 17px !important;
  }
}
.p-syukatsu .c-box06__txt::before {
  top: 0;
  left: 0;
  background: url(/img/sosai/lp/2022_syukatsu/icon05.svg) repeat center;
}
.p-syukatsu .c-box06__txt::after {
  bottom: 0;
  right: 0;
  background: url(/img/sosai/lp/2022_syukatsu/icon06.svg) repeat center;
}
.p-syukatsu .c-box06__cnt {
  border-left: 1px solid #D4D4D4;
  padding: 0 20px 0 30px;
  margin: 0 0 0 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__cnt {
    border-left: none;
    padding: 0;
    margin: 15px 0 0;
    position: relative;
  }
}
.p-syukatsu .c-box06__cntlabel {
  font-size: 1.8rem;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  letter-spacing: 0.15em;
  background: #4C93A1;
  padding: 9px 0 7px;
  width: 374px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__cntlabel {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1;
    padding: 5px 0 4px;
    margin-bottom: 10px;
  }
}
.p-syukatsu .c-box06__cnttext {
  font-size: 2.6rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #2F2933;
  letter-spacing: -0.06em;
  margin-top: 5px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__cnttext {
    font-size: 1.8rem;
    margin-top: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box06__cnttext {
    font-size: 4.8vw;
  }
}
.p-syukatsu .c-box06__cnttext .cl-1 {
  color: #4C93A1;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-box06__cnttext .fz-20 {
  font-size: 2rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__cnttext .fz-20 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box06__cnttext .fz-20 {
    font-size: 4vw;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__img {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
.p-syukatsu .c-box06__img img {
  height: 121px;
  width: auto;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box06__img img {
    height: 58px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box06__img img {
    height: 15.4666666667vw;
  }
}
.p-syukatsu .c-box07 {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}
.p-syukatsu .c-box07__label {
  background: #4C93A1;
  border-radius: 7px;
  color: #fff;
  font-size: 2.4rem;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 32px;
  width: 484px;
  padding: 12px 0;
  position: absolute;
  top: -33px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__label {
    width: calc(100% - 40px);
    font-size: 1.3rem;
    line-height: 20px;
    padding: 8px 0 7px;
    top: -20px;
    border-radius: 4px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__label {
    font-size: 3.4666666667vw;
  }
}
.p-syukatsu .c-box07__label::after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 20px solid #4C93A1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__label::after {
    bottom: -7px;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 12.5px solid #4C93A1;
  }
}
.p-syukatsu .c-box07__top {
  background: url(/img/sosai/lp/2022_syukatsu/bg07.png) no-repeat center;
  background-size: cover;
  padding: 50px 40px 35px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__top {
    padding: 30px 20px 10px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__top {
    padding: 30px 15px 10px;
  }
}
.p-syukatsu .c-box07__text {
  font-size: 2rem;
  line-height: 30px;
  letter-spacing: -0.01em;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__text {
    font-size: 1.1rem;
    line-height: 18px;
    letter-spacing: 0;
    white-space: normal;
    margin-bottom: 5px;
  }
}
.p-syukatsu .c-box07__circle {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50%;
}
.p-syukatsu .c-box07__circle img {
  width: 70px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__circle img {
    width: 35px;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__circle {
    display: none;
    width: 70px;
    height: 70px;
  }
}
.p-syukatsu .c-box07__content {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__content {
    display: block;
  }
}
.p-syukatsu .c-box07__cnt {
  margin: 0 35px 0 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__cnt {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  }
}
.p-syukatsu .c-box07__cnt .c-box07__circle {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__cnt .c-box07__circle {
    display: flex;
  }
}
.p-syukatsu .c-box07__cnttitle {
  font-size: 4rem;
  line-height: 60px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #fff;
  white-space: nowrap;
  margin-bottom: 6px;
}
.p-syukatsu .c-box07__cnttitle span {
  font-size: 3.8rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__cnttitle span {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__cnttitle {
    font-size: 1.8rem;
    line-height: 27px;
    letter-spacing: 0;
    width: calc(100% - 70px);
    white-space: normal;
    padding-left: 10px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__cnttitle {
    font-size: 4.8vw;
    line-height: 7.2vw;
  }
}
.p-syukatsu .c-box07__cntstage {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__cntstage {
    width: 100%;
    margin-top: 5px;
    justify-content: center;
  }
}
.p-syukatsu .c-box07__img img {
  width: 362px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__img img {
    width: auto;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__img {
    margin: 15px 0 -44px;
  }
}
.p-syukatsu .c-box07__stagelabel {
  width: 78px;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  background: #4C93A1;
  padding: 6px 0;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  flex-shrink: 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__stagelabel {
    width: 26px;
    font-size: 0.8rem;
    line-height: 11px;
    padding: 4px 0 3px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__stagelabel {
    width: 6.9333333333vw;
    font-size: 2.1333333333vw;
    line-height: 2.9333333333vw;
    padding: 1.0666666667vw 0 0.8vw;
    margin-right: 1.3333333333vw;
  }
}
.p-syukatsu .c-box07__stagedate {
  font-size: 3.6rem;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__stagedate {
    font-size: 2rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__stagedate {
    font-size: 5.3333333333vw;
  }
}
.p-syukatsu .c-box07__stagedate + .c-box07__stagedate {
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__stagedate + .c-box07__stagedate {
    margin-left: 5px;
  }
}
.p-syukatsu .c-box07__stagedate span {
  font-size: 2.8rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__stagedate span {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__stagedate span {
    font-size: 4.2666666667vw;
  }
}
.p-syukatsu .c-box07__stagedate span.c-box07__stageyear {
  font-size: 3.6rem;
  white-space: nowrap;
}
.p-syukatsu .c-box07__stagedate span.c-box07__stageyear span {
  font-size: 2.8rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__stagedate span.c-box07__stageyear {
    display: none;
  }
}
.p-syukatsu .c-box07__stagedate::after {
  content: "金";
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #324961;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: bold;
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__stagedate::after {
    font-size: 1rem;
    width: 16px;
    height: 17px;
    margin-left: 5px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__stagedate::after {
    font-size: 2.6666666667vw;
    width: 4.2666666667vw;
    height: 5.0666666667vw;
    margin-left: 1.3333333333vw;
  }
}
.p-syukatsu .c-box07__stagedate:last-of-type::before {
  content: "〜";
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: 0.035em;
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__stagedate:last-of-type::before {
    font-size: 1.5rem;
    margin-right: 5px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__stagedate:last-of-type::before {
    font-size: 4vw;
    margin-right: 1.3333333333vw;
  }
}
.p-syukatsu .c-box07__bottom {
  display: flex;
  align-items: center;
  padding: 30px 40px;
  background: #fff;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__bottom {
    display: block;
    padding: 56px 20px 20px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__bottom {
    padding: 56px 15px 15px;
  }
}
.p-syukatsu .c-box07__btn {
  width: 700px;
  padding: 0 30px 0 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__btn {
    width: 100%;
    padding: 0;
  }
}
.p-syukatsu .c-box07__btntext {
  font-size: 1.6rem;
  line-height: 20px;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-align: center;
  color: #324961;
  position: relative;
  padding: 0 15px;
  max-width: 402px;
  margin: 0 auto 6px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__btntext {
    font-size: 1.1rem;
    line-height: 1;
    max-width: 270px;
    letter-spacing: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__btntext {
    max-width: 72vw;
    font-size: 2.9333333333vw;
  }
}
.p-syukatsu .c-box07__btntext::before, .p-syukatsu .c-box07__btntext::after {
  content: "";
  width: 1px;
  height: 20px;
  border-left: 1px solid #324961;
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__btntext::before, .p-syukatsu .c-box07__btntext::after {
    height: 12.5px;
  }
}
.p-syukatsu .c-box07__btntext::before {
  transform: rotate(-30deg);
  left: 0;
}
.p-syukatsu .c-box07__btntext::after {
  transform: rotate(-150deg);
  right: 0;
}
.p-syukatsu .c-box07__btnlink {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #3C76D2;
  border-radius: 50px;
  font-size: 2.8rem;
  line-height: 36px;
  color: #3C76D2;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: relative;
  padding: 29px 0;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__btnlink {
    border-width: 2px;
    font-size: 1.8rem;
    line-height: 24px;
    letter-spacing: 0.05em;
    padding: 9px 25px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box07__btnlink {
    font-size: 4.2666666667vw;
    line-height: 6.4vw;
    padding: 9px 20px;
  }
}
.p-syukatsu .c-box07__btnlink span {
  margin: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__btnlink span {
    text-align: left;
    flex: 1;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-box07__btnlink:hover {
    opacity: 1;
    background: #3C76D2;
    color: #fff;
  }
  .p-syukatsu .c-box07__btnlink:hover::before {
    background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
  }
  .p-syukatsu .c-box07__btnlink:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-box07__btnlink::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
  background-size: 24px 24px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-box07__btnlink::after {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 18px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  background-size: 8px 18px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-box07__info {
  width: 50%;
  max-width: 510px;
  padding: 8px 0 8px 30px;
  border-left: 1px solid #D4D4D4;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__info {
    width: 100%;
    max-width: 100%;
    border-left: none;
    padding: 0;
  }
}
.p-syukatsu .c-box07__infotitle {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3C76D2;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infotitle {
    font-size: 1.3rem;
    line-height: 16px;
    display: none;
  }
}
.p-syukatsu .c-box07__infotitle::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
  background-size: 22px 22px !important;
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infotitle::before {
    height: 12px;
    width: 12px;
    background-size: 12px 12px !important;
    margin-right: 5px;
  }
}
.p-syukatsu .c-box07__infocnt {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
  pointer-events: none;
  opacity: 1 !important;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infocnt {
    justify-content: center;
    flex-wrap: wrap;
    border: 2px solid #D4D4D4;
    padding: 5px 0;
    border-radius: 70px;
    margin: 10px 0 8px;
    pointer-events: initial;
  }
}
.p-syukatsu .c-box07__infocnt .c-box07__infotitle {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infocnt .c-box07__infotitle {
    display: flex !important;
    width: 100%;
  }
}
.p-syukatsu .c-box07__infolabel {
  width: 54px;
  background: #3C76D2;
  font-size: 1.2rem;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 5px 0 2px;
  flex-shrink: 0;
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infolabel {
    width: 62px;
    font-size: 1rem;
    line-height: normal;
    display: inline-block;
    padding: 2px 0 0;
  }
}
.p-syukatsu .c-box07__infophone {
  font-size: 4rem;
  line-height: 1;
  letter-spacing: 0.035em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  color: #3C76D2;
  margin: 0 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infophone {
    display: inline-block;
    font-size: 2.2rem;
    letter-spacing: 0.025em;
    margin: 4px 0 4px 8px;
  }
}
.p-syukatsu .c-box07__infotxt {
  font-size: 1.4rem;
  color: #666666;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infotxt {
    width: 100%;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.p-syukatsu .c-box07__infotxt span {
  font-size: 1.2rem;
  margin-top: 4px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infotxt span {
    font-size: 1rem;
    margin-top: 0;
  }
}
.p-syukatsu .c-box07__infotext {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #3C76D2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infotext {
    font-size: 1.3rem;
  }
}
.p-syukatsu .c-box07__infotext span {
  margin: 0 15px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box07__infotext span {
    margin: 0 10px;
  }
}
.p-syukatsu .c-box07__infotext::before, .p-syukatsu .c-box07__infotext::after {
  content: "";
  position: relative;
  display: inline-block;
  height: 3px;
  width: 100%;
  background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat center;
  background-size: 9px 3px;
}
.p-syukatsu .c-box08 {
  max-width: 640px;
  margin: 0 auto;
}
.p-syukatsu .c-box08__title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-syukatsu .c-box08__circle {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4C93A1;
  border-radius: 50%;
}
.p-syukatsu .c-box08__circle img {
  width: 62px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__circle img {
    width: 35px;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__circle {
    width: 70px;
    height: 70px;
  }
}
.p-syukatsu .c-box08__txt {
  font-size: 3.2rem;
  line-height: 48px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  letter-spacing: 0.075em;
  color: #4C93A1;
  white-space: nowrap;
  margin-left: 16px;
}
.p-syukatsu .c-box08__txt span {
  font-size: 3rem;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__txt span {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__txt {
    font-size: 1.8rem;
    line-height: 27px;
    letter-spacing: 0;
    width: calc(100% - 70px);
    white-space: normal;
    margin-left: 8px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box08__txt {
    font-size: 4.8vw;
    line-height: 7.2vw;
  }
}
.p-syukatsu .c-box08__img {
  margin: 20px 0 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__img {
    margin: 10px 0;
  }
}
.p-syukatsu .c-box08__list li {
  border-bottom: 1px solid #4C93A1;
  padding: 25px 0;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  line-height: 28px;
  color: #2F2933;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__list li {
    padding: 15px 0;
    display: block;
    font-size: 1.3rem;
    line-height: 22px;
  }
}
.p-syukatsu .c-box08__list li:first-child {
  border-top: 1px solid #4C93A1;
}
.p-syukatsu .c-box08__listtext {
  font-weight: bold;
  width: 165px;
  padding-right: 30px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__listtext {
    width: 100%;
    padding-right: 0;
  }
}
.p-syukatsu .c-box08__listtitle {
  flex: 1;
}
.p-syukatsu .c-box08__btn {
  width: 580px;
  margin: 30px auto 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__btn {
    width: 100%;
    margin: 15px 0 10px;
  }
}
.p-syukatsu .c-box08__btnlink {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 3px solid #3C76D2;
  border-radius: 50px;
  font-size: 2.4rem;
  line-height: 36px;
  color: #3C76D2;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  position: relative;
  padding: 19px 40px;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__btnlink {
    border-width: 2px;
    font-size: 1.6rem;
    line-height: 22px;
    letter-spacing: 0.05em;
    padding: 9px 25px;
  }
}
@media screen and (max-width: 374px) {
  .p-syukatsu .c-box08__btnlink {
    font-size: 4.2666666667vw;
    line-height: 6.4vw;
    padding: 9px 20px;
  }
}
.p-syukatsu .c-box08__btnlink span {
  margin: 0 15px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__btnlink span {
    text-align: left;
    flex: 1;
  }
}
@media screen and (min-width: 641px) {
  .p-syukatsu .c-box08__btnlink:hover {
    opacity: 1;
    background: #3C76D2;
    color: #fff;
  }
  .p-syukatsu .c-box08__btnlink:hover::before {
    background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
  }
  .p-syukatsu .c-box08__btnlink:hover::after {
    background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
  }
}
.p-syukatsu .c-box08__btnlink::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
  background-size: 24px 24px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-box08__btnlink::after {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 18px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
  background-size: 8px 18px !important;
  transition: 0.3s ease-in-out;
}
.p-syukatsu .c-box08__text {
  display: none !important;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__text {
    display: flex !important;
  }
}
.p-syukatsu .c-box08__info {
  white-space: nowrap;
  background: #F9F9F9;
  padding: 25px 0 20px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__info {
    width: 100%;
    max-width: 100%;
    border-left: none;
    padding: 0;
    background: none;
  }
}
.p-syukatsu .c-box08__infotitle, .p-syukatsu .c-box08__infocnt, .p-syukatsu .c-box08__infotext {
  width: 450px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infotitle, .p-syukatsu .c-box08__infocnt, .p-syukatsu .c-box08__infotext {
    width: 100%;
  }
}
.p-syukatsu .c-box08__infotitle {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3C76D2;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infotitle {
    font-size: 1.3rem;
    line-height: 16px;
    display: none;
  }
}
.p-syukatsu .c-box08__infotitle::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
  background-size: 22px 22px !important;
  margin-right: 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infotitle::before {
    height: 12px;
    width: 12px;
    background-size: 12px 12px !important;
    margin-right: 5px;
  }
}
.p-syukatsu .c-box08__infocnt {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px auto;
  pointer-events: none;
  opacity: 1 !important;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infocnt {
    justify-content: center;
    flex-wrap: wrap;
    border: 2px solid #D4D4D4;
    padding: 5px 0;
    border-radius: 70px;
    margin: 10px 0 12px;
    pointer-events: initial;
  }
}
.p-syukatsu .c-box08__infocnt .c-box08__infotitle {
  display: none;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infocnt .c-box08__infotitle {
    display: flex !important;
    width: 100%;
  }
}
.p-syukatsu .c-box08__infolabel {
  width: 54px;
  background: #3C76D2;
  font-size: 1.2rem;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 5px 0 2px;
  flex-shrink: 0;
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infolabel {
    width: 62px;
    font-size: 1rem;
    line-height: normal;
    display: inline-block;
    padding: 2px 0 0;
  }
}
.p-syukatsu .c-box08__infophone {
  font-size: 4rem;
  line-height: 1;
  letter-spacing: 0.035em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 600;
  color: #3C76D2;
  margin: 0 10px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infophone {
    display: inline-block;
    font-size: 2.2rem;
    letter-spacing: 0.025em;
    margin: 4px 0 4px 8px;
  }
}
.p-syukatsu .c-box08__infotxt {
  font-size: 1.4rem;
  color: #666666;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infotxt {
    width: 100%;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.p-syukatsu .c-box08__infotxt span {
  font-size: 1.2rem;
  margin-top: 4px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infotxt span {
    font-size: 1rem;
    margin-top: 0;
  }
}
.p-syukatsu .c-box08__infotext {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #3C76D2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infotext {
    font-size: 1.3rem;
  }
}
.p-syukatsu .c-box08__infotext span {
  margin: 0 15px;
  display: block;
}
@media screen and (max-width: 640px) {
  .p-syukatsu .c-box08__infotext span {
    margin: 0 10px;
  }
}
.p-syukatsu .c-box08__infotext::before, .p-syukatsu .c-box08__infotext::after {
  content: "";
  position: relative;
  display: inline-block;
  height: 3px;
  width: 100%;
  background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat center;
  background-size: 9px 3px;
}
.p-syukatsu__01 {
  padding: 120px 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__01 {
    padding: 60px 0;
  }
}
.p-syukatsu__01 .c-block001 {
  margin-top: 40px;
}
.p-syukatsu__02 {
  padding: 120px 0;
  background: #F9F9F9;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__02 {
    padding: 60px 0;
  }
}
@media screen and (max-width: 640px) {
  .p-syukatsu__02 .c-imgtext01 {
    margin-bottom: 30px;
  }
}
.p-syukatsu__03 {
  padding: 120px 0;
  background: url(/img/sosai/lp/2022_syukatsu/bg03.jpg) no-repeat center;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__03 {
    padding: 60px 0;
  }
}
.p-syukatsu__04 {
  padding: 120px 0 60px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__04 {
    padding: 60px 0 30px;
  }
}
.p-syukatsu__05 {
  padding: 80px 0 120px;
  background: url(/img/sosai/lp/2022_syukatsu/bg04.png) no-repeat center;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__05 {
    padding: 40px 0 60px;
  }
}
.p-syukatsu__05 .c-list003 {
  margin-top: 56px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__05 .c-list003 {
    margin-top: 28px;
  }
}
.p-syukatsu__06 {
  padding: 120px 0;
  background: url(/img/sosai/lp/2022_syukatsu/bg03.jpg) no-repeat center;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__06 {
    padding: 60px 0;
  }
}
.p-syukatsu__07 {
  padding: 140px 0 120px;
  background: #FBFCF8;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__07 {
    padding: 70px 0 60px;
  }
}
.p-syukatsu__07 .c-box03 {
  margin-bottom: 120px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__07 .c-box03 {
    margin-bottom: 60px;
  }
}
.p-syukatsu__07 .c-title01 .cl-01 {
  position: relative;
  padding: 0 10px;
  margin-left: 40px;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__07 .c-title01 .cl-01 {
    margin-left: 0;
    padding: 0 10px;
  }
}
.p-syukatsu__07 .c-title01 .cl-01::before, .p-syukatsu__07 .c-title01 .cl-01::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 36px;
  background-size: 14px 36px !important;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__07 .c-title01 .cl-01::before, .p-syukatsu__07 .c-title01 .cl-01::after {
    width: 8px;
    height: 21px;
    background-size: 8px 21px !important;
  }
}
.p-syukatsu__07 .c-title01 .cl-01::before {
  background: url(/img/sosai/lp/2022_syukatsu/icon07.svg) no-repeat center;
  top: 0;
  left: 0;
}
.p-syukatsu__07 .c-title01 .cl-01::after {
  background: url(/img/sosai/lp/2022_syukatsu/icon08.svg) no-repeat center;
  bottom: 0;
  right: 0;
}
.p-syukatsu__07 .c-box04 {
  margin: 50px 0;
}
@media screen and (max-width: 640px) {
  .p-syukatsu__07 .c-box04 {
    margin: 20px 0;
  }
}

/*------------------------------------------------------------
/lp/pokke_column/ ポッケアプリ
------------------------------------------------------------*/
.p-lpPokkeCl {
  margin: 0 auto;
  overflow-x: hidden;
}
.p-lpPokkeCl .l-lpcontainer {
  padding: 0 5.3333333333vw;
}
.p-lpPokkeCl .c-lpheader {
  background: #F0F8E9;
}
.p-lpPokkeCl .c-lpheader__head {
  background: #6FBA2C;
  width: 89.3333333333vw;
  margin: 0 auto;
  border-radius: 0 0 2.6666666667vw 2.6666666667vw;
  text-align: center;
  padding: 5.3333333333vw;
}
.p-lpPokkeCl .c-lpheader__logo {
  width: 44.5333333333vw;
  display: block;
  margin: 0 auto;
}
.p-lpPokkeCl .c-lpheader__title {
  font-size: 3.7333333333vw;
  line-height: 5.6vw;
  font-weight: bold;
  color: #fff;
  margin-top: 2.6666666667vw;
}
.p-lpPokkeCl .bg-blue {
  background: #1971BD !important;
}
.p-lpPokkeCl .c-lptitle01 {
  font-size: 6.4vw;
  line-height: 9.6vw;
  font-weight: bold;
}
.p-lpPokkeCl .c-lptitle01.small {
  font-size: 5.3333333333vw;
}
.p-lpPokkeCl .c-lplisttag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 3.4666666667vw;
}
.p-lpPokkeCl .c-lptag {
  display: block;
  font-family: "Noto Sans Japanese";
  color: #333333;
  line-height: 7.4666666667vw;
  font-weight: 400;
  border: 0.5333333333vw solid #6FBA2C;
  border-radius: 4vw;
  font-size: 3.2vw;
  line-height: 4.6666666667vw;
  padding: 1.0666666667vw 2.1333333333vw;
  text-align: center;
  margin-top: 1.3333333333vw;
}
.p-lpPokkeCl .c-lptag:not(:last-child) {
  margin-right: 1.3333333333vw;
}
.p-lpPokkeCl .c-lpimgtext01__img img {
  width: 100%;
}
.p-lpPokkeCl .c-lpimgtext01 p {
  padding: 6.4vw 5.3333333333vw 6.9333333333vw;
  font-size: 4.2666666667vw;
  line-height: 8.5333333333vw;
  font-family: "Noto Sans Japanese";
  color: #333333;
  line-height: 7.4666666667vw;
  font-weight: 400;
}
.p-lpPokkeCl .c-lpimgtext02__img {
  width: 50%;
  margin: auto;
}
.p-lpPokkeCl .c-lpimgtext03__img {
  padding: 0 20px;
}
.p-lpPokkeCl .c-lplogo02 {
  display: flex;
  background: #fff;
  padding: 10px;
}
.p-lpPokkeCl .c-logotext {
  width: 25%;
  align-self: center;
  text-align: right;
}
.p-lpPokkeCl .c-logo {
  width: 75%;
  text-align: center;
}
.p-lpPokkeCl .c-logo2-txt {
  font-size: 1.2rem;
  margin-top: 0;
}
.p-lpPokkeCl .c-radius_none {
  border-radius: 0;
}
.p-lpPokkeCl__section01 {
  background: #F0F8E9;
  padding: 8vw 0;
}
.p-lpPokkeCl__section01 .c-lpimgtext01 {
  margin: 0 -5.3333333333vw -8vw;
}
.p-lpPokkeCl__section01 .c-lpimgtext01 img {
  margin-top: 8vw;
}
.p-lpPokkeCl .sec-blue {
  background: #f5faff;
}
.p-lpPokkeCl__section02 {
  background: #fff;
  padding: 0 5.3333333333vw;
}
.p-lpPokkeCl__column {
  margin-top: 10.6666666667vw;
  word-break: break-all;
}
.p-lpPokkeCl__column a.c-color-style1:link,
.p-lpPokkeCl__column a.c-color-style1:visited,
.p-lpPokkeCl__column a.c-color-style1:active,
.p-lpPokkeCl__column a.c-color-style1:hover {
  color: #333;
}
.p-lpPokkeCl__column h2 {
  font-size: 5.3333333333vw;
  line-height: 8vw;
  font-weight: bold;
  color: #497A1D;
  margin-top: 10.6666666667vw;
}
.p-lpPokkeCl__column h3 {
  font-size: 4.8vw;
  line-height: 7.2vw;
  font-weight: bold;
  border-bottom: 0.5333333333vw solid #6FBA2C;
  padding-bottom: 2.6666666667vw;
  margin-top: 7.7333333333vw;
}
.p-lpPokkeCl__column img {
  margin-top: 8vw;
  overflow: hidden;
  border-radius: 2.6666666667vw;
  width: 100%;
}
.p-lpPokkeCl__column p {
  font-family: "Noto Sans Japanese";
  color: #333333;
  font-size: 4.2666666667vw;
  line-height: 7.4666666667vw;
  font-weight: 400;
  margin-top: 7.2vw;
}
.p-lpPokkeCl__column .ps {
  font-size: 1.5rem;
  margin: 0;
  text-align: end;
}
.p-lpPokkeCl__column .c-lpimg__caption {
  font-size: 3.2vw;
  line-height: 5.0666666667vw;
  margin-top: 1.3333333333vw;
}
.p-lpPokkeCl__column ul,
.p-lpPokkeCl__column ol {
  font-family: "Noto Sans Japanese";
  color: #333333;
  font-size: 4.2666666667vw;
  line-height: 7.4666666667vw;
  font-weight: 400;
  margin-top: 7.2vw;
}
.p-lpPokkeCl__column ul li {
  position: relative;
  padding-left: 4.2666666667vw;
}
.p-lpPokkeCl__column ul li:before {
  content: "";
  width: 1.8666666667vw;
  height: 1.8666666667vw;
  background: #497A1D;
  border-radius: 50%;
  position: absolute;
  top: 3.2vw;
  left: 5px;
}
.p-lpPokkeCl__column ol {
  display: table;
}
.p-lpPokkeCl__column ol li {
  list-style-type: decimal;
  counter-increment: table-ol;
  display: table-row;
}
.p-lpPokkeCl__column ol li::before {
  content: counter(table-ol) ".";
  padding-right: 0.4em;
  text-align: right;
  display: table-cell;
  width: 2em;
}
.p-lpPokkeCl .c-lpbox01 {
  font-family: "Noto Sans Japanese";
  color: #333333;
  font-size: 4.2666666667vw;
  line-height: 7.4666666667vw;
  font-weight: 400;
  background: #F0F8E9;
  border: 0.5333333333vw solid #6FBA2C;
  border-radius: 2.6666666667vw;
  padding: 4.2666666667vw 4.5333333333vw;
  margin-top: 9.3333333333vw;
}
.p-lpPokkeCl .c-lpbox01--noborder {
  border: none;
}
.p-lpPokkeCl .c-lpbox01 p:first-child,
.p-lpPokkeCl .c-lpbox01 h2:first-child {
  margin-top: 0;
}
.p-lpPokkeCl .c-lpbox02 {
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  margin-top: 7.2vw;
  padding-bottom: 7.2vw;
  display: flex;
}
.p-lpPokkeCl .c-lpbox02__img01 {
  width: 105px !important;
  height: 105px;
  display: block;
}
.p-lpPokkeCl .c-lpbox02__wrapper {
  width: calc(100% - 105px);
  margin-left: 4.2666666667vw;
}
.p-lpPokkeCl .c-lpbox02__title {
  font-size: 4.8vw !important;
  font-weight: bold !important;
}
.p-lpPokkeCl .c-lpbox02__text {
  font-size: 3.7333333333vw;
  margin-top: 3.7333333333vw;
}
.p-lpPokkeCl .c-lptexttotal {
  font-family: "Noto Sans Japanese";
  color: #333333;
  font-size: 4.2666666667vw;
  line-height: 7.4666666667vw;
  font-weight: 400;
  line-height: 8.5333333333vw;
  text-align: right;
  margin-top: 1.3333333333vw;
}
.p-lpPokkeCl .c-lpfooter {
  background: #6FBA2C;
  margin-top: 9.3333333333vw;
  padding: 8vw 0;
}
.p-lpPokkeCl .c-lpfooter__title {
  font-size: 5.3333333333vw;
  line-height: 8vw;
  color: #fff;
  text-align: center;
  margin-bottom: 5.3333333333vw;
}
.p-lpPokkeCl .c-lpfooter__logo {
  display: flex;
  align-items: center;
}
.p-lpPokkeCl .c-lpfooter__item {
  width: calc(50% - 2.1333333333vw);
}
.p-lpPokkeCl .c-lpfooter__item:not(:last-child) {
  margin-right: 4.2666666667vw;
}
.p-lpPokkeCl .c-lpfooter__item img {
  width: 100%;
}
.p-lpPokkeCl .border_blue {
  border-bottom: 0.5333333333vw solid #1971BD;
}

.c-list10 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 640px) {
  .c-list10 {
    display: block;
  }
}
.c-list10__item {
  width: 50%;
  margin-bottom: 38px;
}
@media screen and (max-width: 640px) {
  .c-list10__item {
    width: 100%;
    margin-bottom: 40px;
  }
  .c-list10__item:not(:last-child) {
    margin-bottom: 12px;
  }
}
.c-list10__item:nth-child(2n) {
  padding-left: 30px;
}
@media screen and (max-width: 640px) {
  .c-list10__item:nth-child(2n) {
    padding-left: 0;
  }
}
.c-list10__item:nth-child(2n+1) {
  padding-right: 30px;
}
@media screen and (max-width: 640px) {
  .c-list10__item:nth-child(2n+1) {
    padding-right: 0;
  }
}

.c-card04 {
  border: 1px solid #DDDDDD;
  border-radius: 20px;
  position: relative;
  padding: 60px;
}
@media screen and (max-width: 640px) {
  .c-card04 {
    border-radius: 10px;
    padding: 30px;
  }
}
.c-card04::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 120px);
  height: 9px;
  background: #82CDF4;
  border-radius: 0 0 6px 6px;
}
@media screen and (max-width: 640px) {
  .c-card04::before {
    height: 5px;
    border-radius: 0 0 5px 5px;
    width: calc(100% - 60px);
  }
}
.c-card04__content {
  padding: 28px 0 21px;
}
@media screen and (max-width: 640px) {
  .c-card04__content {
    padding: 19px 0 15px;
  }
}
.c-card04__title {
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  line-height: 36px;
  font-weight: bold;
  color: #253B73;
  margin-bottom: 14px;
}
@media screen and (max-width: 640px) {
  .c-card04__title {
    font-size: 2rem;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
.c-card04__text {
  font-size: 1.6rem;
  line-height: 28px;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  .c-card04__text {
    font-size: 1.4rem;
    line-height: 25px;
  }
}
.c-card04__txt {
  font-size: 1.4rem;
  line-height: 24.5px;
  font-weight: 500;
  margin-top: 9px;
}
@media screen and (max-width: 640px) {
  .c-card04__txt {
    font-size: 1.2rem;
    line-height: 21px;
  }
}
.c-card04__tag {
  display: flex;
  margin-top: 15px;
}
@media screen and (max-width: 640px) {
  .c-card04__tag {
    margin-top: 14px;
  }
}
.c-card04__tagLabel {
  font-size: 1.4rem;
  font-weight: bold;
  color: #253B73;
  margin-right: 10px;
  min-width: 56px;
  position: relative;
  top: 1px;
}
@media screen and (max-width: 640px) {
  .c-card04__tagLabel {
    font-size: 1.2rem;
    min-width: 50px;
    margin-right: 7px;
    top: 0;
  }
}
.c-card04__lsttag {
  display: flex;
  flex-wrap: wrap;
}
.c-card04__lsttagItem {
  background: #E9F4F8;
  padding: 4px 6px;
  border-radius: 4px;
  color: #253B73;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 10px 8px 0;
  max-height: 26px;
}
@media screen and (max-width: 640px) {
  .c-card04__lsttagItem {
    font-size: 1rem;
    padding: 2px 4px 0;
    border-radius: 2px;
    margin: 0 5px 5px 0;
  }
}
.c-card04__btInner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;
}
@media screen and (max-width: 374px) {
  .c-card04__btInner {
    display: block;
    margin: 0;
  }
}
.c-card04__btn01 {
  padding: 0 5px;
  width: 50%;
}
@media screen and (max-width: 374px) {
  .c-card04__btn01 {
    width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 374px) {
  .c-card04__btn01 + .c-card04__btn01 {
    margin-top: 10px;
  }
}
.c-card04__btn01 a {
  display: block;
  text-align: center;
  background: #fff;
  color: #256DAA;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  border: 1px solid #256DAA;
  position: relative;
  padding: 12px 0 10px;
  border-radius: 70px;
  text-indent: 12px;
}
@media screen and (max-width: 640px) {
  .c-card04__btn01 a {
    font-size: 1.2rem;
    letter-spacing: 0.025em;
    text-indent: 9px;
    padding: 9px 0;
  }
}
@media screen and (min-width: 641px) {
  .c-card04__btn01 a:hover {
    opacity: 0.4;
  }
}
.c-card04__btn01 a::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 19px;
  background: #256DAA url("../img/lp/coop_dantai-hoken/icon-copy.svg") no-repeat center;
  background-size: 13px 15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
@media screen and (max-width: 640px) {
  .c-card04__btn01 a::before {
    height: 23px;
    width: 23px;
    background-size: 10px 11px;
    left: 10px;
  }
}
.c-card04__btn01.is-style01 a {
  border-color: #253B73;
  color: #253B73;
  text-indent: 40px;
}
@media screen and (max-width: 640px) {
  .c-card04__btn01.is-style01 a {
    text-indent: 28px;
  }
}
.c-card04__btn01.is-style01 a::before {
  background: #253B73 url("../img/lp/coop_dantai-hoken/icon-mail.svg") no-repeat center;
  background-size: 13px 10px;
}
@media screen and (max-width: 640px) {
  .c-card04__btn01.is-style01 a::before {
    background-size: 10px 8px;
  }
}
.c-card04__btn02 {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .c-card04__btn02 {
    margin-top: 15px;
  }
}
.c-card04__btn02 a {
  display: block;
  text-align: center;
  background: #FFF58A url("../img/lp/coop_dantai-hoken/icon-arrow.svg") no-repeat;
  background-size: 8px 14px;
  background-position: center right 45px;
  color: #253B73;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding: 25px 20px 23px;
  border-radius: 60px;
  text-indent: -26px;
}
@media screen and (min-width: 641px) {
  .c-card04__btn02 a:hover {
    opacity: 0.4;
  }
}
@media screen and (max-width: 640px) {
  .c-card04__btn02 a {
    font-size: 1.4rem;
    background-size: 6px 12px;
    border-radius: 35px;
    padding: 14px 0;
    background-position: center right 30px;
  }
}
@media screen and (max-width: 374px) {
  .c-card04__btn02 a {
    background-position: center right 15px;
    font-size: 1.2rem;
    text-indent: -18px;
  }
}

#p-coop_dantai-hoken {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  color: #46483C;
}
#p-coop_dantai-hoken .l-container {
  width: 100%;
  max-width: 1120px;
}
#p-coop_dantai-hoken .c-hokenNote {
  margin-top: 100px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-hokenNote {
    margin-top: 30px;
  }
}
#p-coop_dantai-hoken .c-CoopDantaiHeader {
  background: #82CDF4;
  border-radius: 0 0 60px 60px;
  margin: 0 40px;
  text-align: center;
  padding: 51px 0 41px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-CoopDantaiHeader {
    margin: 0 20px;
    border-radius: 0 0 30px 30px;
    padding: 40px 0 32px;
    min-width: auto;
  }
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-CoopDantaiHeader .c-cpHeader__title {
    width: 67%;
    margin: 0 auto;
    max-width: 450px;
  }
}
#p-coop_dantai-hoken .c-CoopDantaiHeader .c-cpHeader__text {
  font-size: 2.4rem;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  margin-top: 29px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-CoopDantaiHeader .c-cpHeader__text {
    font-size: 1.4rem;
    line-height: 25px;
    padding: 0 30px;
    text-align: left;
    margin-top: 26px;
  }
}
#p-coop_dantai-hoken .c-CoopDantaiFooter__inner {
  margin: 0 40px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-CoopDantaiFooter__inner {
    margin: 0;
  }
}
#p-coop_dantai-hoken .c-cpContact {
  background: url("../img/lp/coop_dantai-hoken/bg-contact.jpg") no-repeat;
  background-size: cover !important;
  padding: 10px;
  border-radius: 20px;
  margin: 10px 0 80px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact {
    border-radius: 10px;
    background: url("../img/lp/coop_dantai-hoken/bg-contact-sp.jpg") no-repeat;
    margin: 0 20px 40px;
  }
}
#p-coop_dantai-hoken .c-cpContact__inner {
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px 80px 54px;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__inner {
    display: block;
    white-space: normal;
    padding: 25px 30px 27px;
    border-radius: 5px;
    text-align: center;
  }
}
@media screen and (max-width: 374px) {
  #p-coop_dantai-hoken .c-cpContact__inner {
    padding: 20px 15px;
  }
}
#p-coop_dantai-hoken .c-cpContact__title {
  font-size: 3.4rem;
  line-height: 51px;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #253B73;
  padding-right: 60px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__title {
    font-size: 2.2rem;
    line-height: 33px;
    letter-spacing: 0.025em;
    padding-right: 0;
  }
}
#p-coop_dantai-hoken .c-cpContact__content {
  padding-left: 60px;
  position: relative;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__content {
    padding-left: 0;
    margin-top: 13px;
    padding-top: 21px;
  }
}
#p-coop_dantai-hoken .c-cpContact__content::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 152px;
  border-left: 1px solid #707070;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__content::before {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: 1px;
    width: 100%;
    border-left: 0;
    border-top: 1px solid #707070;
  }
}
#p-coop_dantai-hoken .c-cpContact__phone {
  position: relative;
  padding-left: 100px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__phone {
    padding-left: 0;
  }
}
#p-coop_dantai-hoken .c-cpContact__phone::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: #253B73 url("../img/lp/coop_dantai-hoken/icon-phone.svg") no-repeat center;
  background-size: 34px 34px;
  height: 86px;
  width: 86px;
  border-radius: 50%;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__phone::before {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    transform: none;
    width: 43px;
    height: 43px;
    background-size: 17px 17px;
    margin: 0 auto 8px;
  }
}
#p-coop_dantai-hoken .c-cpContact__phoneNum {
  font-family: "Roboto", sans-serif;
  font-size: 6.7rem;
  font-weight: bold;
  color: #253B73;
  line-height: 1;
}
#p-coop_dantai-hoken .c-cpContact__phoneNum a {
  color: #253B73;
  pointer-events: none;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__phoneNum a {
    pointer-events: inherit;
  }
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__phoneNum {
    font-size: 3.95rem;
    white-space: nowrap;
  }
}
@media screen and (max-width: 374px) {
  #p-coop_dantai-hoken .c-cpContact__phoneNum {
    font-size: 3rem;
  }
}
#p-coop_dantai-hoken .c-cpContact__phoneText {
  font-size: 1.6rem;
  line-height: 28px;
  font-weight: 500;
  margin-top: 1px;
  text-indent: -11px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__phoneText {
    font-size: 1.2rem;
    text-indent: 0;
    line-height: 21px;
    margin-top: 5px;
  }
}
#p-coop_dantai-hoken .c-cpContact__phoneText span {
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
#p-coop_dantai-hoken .c-cpContact__tit {
  font-size: 2.4rem;
  line-height: 36px;
  font-weight: bold;
  letter-spacing: 0.01em;
  color: #253B73;
  margin: 6px 0 -2px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__tit {
    font-size: 1.4rem;
    line-height: 18px;
    margin: 14px 0 6px;
  }
}
#p-coop_dantai-hoken .c-cpContact__text {
  font-size: 1.6rem;
  line-height: 28px;
  font-weight: 500;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpContact__text {
    font-size: 1rem;
    line-height: 14px;
  }
}
#p-coop_dantai-hoken .c-cpCopyright {
  background: #F8F8F8;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  padding: 28px 0;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpCopyright {
    font-size: 0.9rem;
    line-height: 10px;
    padding: 15px 0;
  }
}
#p-coop_dantai-hoken .c-cpGotop {
  position: fixed;
  bottom: 40px;
  right: 40px;
}
@media screen and (max-width: 640px) {
  #p-coop_dantai-hoken .c-cpGotop {
    width: 90px;
    bottom: 20px;
    right: 20px;
  }
}

.p-coop_dantai-hoken01 {
  padding: 132px 40px 0;
}
@media screen and (max-width: 640px) {
  .p-coop_dantai-hoken01 {
    padding: 42px 20px 0;
  }
}

.p-anniversaryEnqueteMainVisual {
  margin-inline: auto;
  padding-inline: 20px;
  padding-top: 30px;
  padding-bottom: 40px;
  max-width: 640px;
  background: url(../img/anniversary-enquete/mainVisual_deco01.svg) no-repeat left top/111.5px auto, url(../img/anniversary-enquete/mainVisual_deco02.svg) no-repeat right top/109.5px auto, url(../img/anniversary-enquete/mainVisual_deco03.svg) no-repeat calc(50% + 15px) 43px/411px auto, url(../img/anniversary-enquete/mainVisual_bg01.png) no-repeat center center/cover;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteMainVisual {
    font-size: 1.3rem;
  }
}
.p-anniversaryEnqueteMainVisual__logo {
  text-align: center;
}
.p-anniversaryEnqueteMainVisual__title {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.p-anniversaryEnqueteMainVisual__present {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.p-anniversaryEnqueteMainVisual__text {
  margin-top: 10px;
}
.p-anniversaryEnqueteMainVisual__text__link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: inherit;
  text-decoration: underline;
}
.p-anniversaryEnqueteMainVisual__newWindow {
  width: 13px;
  height: 13px;
  background: url(../img/anniversary-enquete/icon_newWindow_white.svg) no-repeat center center/contain;
  display: inline-block;
}

.p-anniversaryEnqueteSummary {
  padding: 20px;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteSummary {
    font-size: 1.4rem;
  }
}
.p-anniversaryEnqueteSummary__title {
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 2.4rem;
  font-weight: bold;
  border-bottom: 2px solid #8CB340;
  line-height: 1.19444444;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteSummary__title {
    font-size: 1.8rem;
  }
}
.p-anniversaryEnqueteSummary__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.p-anniversaryEnqueteSummary__list dt {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #8CB340;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteSummary__list dt {
    font-size: 1.5rem;
  }
}
.p-anniversaryEnqueteSummary__list ul li {
  margin-top: 5px;
  padding-left: 15px;
  position: relative;
}
.p-anniversaryEnqueteSummary__list ul li::before {
  position: absolute;
  top: 0.5em;
  left: 0;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background: #8CB340;
  border-radius: 50%;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteSummary__list ul li::before {
    font-size: 0.4em;
  }
}
.p-anniversaryEnqueteSummary__linkText {
  color: inherit;
  text-decoration: underline;
}
.p-anniversaryEnqueteSummary__newWindow {
  width: 13px;
  height: 13px;
  background: url(../img/anniversary-enquete/icon_newWindow_coopGreen.svg) no-repeat center center/contain;
  display: inline-block;
}

.p-anniversaryEnqueteConfirm {
  padding: 70px 0 60px;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteConfirm {
    padding: 35px 20px 30px;
  }
}

.p-anniversaryEnqueteComplete {
  padding: 70px 0 60px;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteComplete {
    padding: 35px 20px 30px;
  }
}

.p-anniversaryEnqueteService {
  width: 100%;
  min-width: 1120px;
  margin: 0 auto;
  padding: 80px 0 40px;
  background-image: url("../img/service/bg03.jpg");
  position: relative;
}
.p-anniversaryEnqueteService::before {
  width: 100%;
  height: 4px;
  content: "";
  background-image: url("../img/service/line_colorful01_pc.jpg");
  background-repeat: repeat-x;
  background-position: center 0;
  position: absolute;
  top: 0;
  left: 0;
}
.p-anniversaryEnqueteService .c-serviceTitle01 {
  margin-bottom: 55px;
}
.p-anniversaryEnqueteService .p-serviceTop04__contents {
  margin-bottom: 60px;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnqueteService {
    min-width: 100%;
    padding: 40px 20px 20px;
  }
  .p-anniversaryEnqueteService .c-serviceTitle01 {
    margin-bottom: 14px;
  }
  .p-anniversaryEnqueteService .p-serviceTop04__contents {
    margin-bottom: 30px;
  }
}

.p-anniversaryEnquetePrivacyPolicy {
  margin-bottom: 35px;
  padding: 26px 31px 33px;
  border: solid 1px #efefef;
  font-size: 1.6rem;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnquetePrivacyPolicy {
    padding: 17px 8px 20px 19px;
    font-size: 1.3rem;
  }
}
.p-anniversaryEnquetePrivacyPolicy__scroller {
  height: 240px;
  padding-right: 20px;
  overflow: auto;
  color: #46483c;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnquetePrivacyPolicy__scroller {
    height: 261px;
    padding-right: 20px;
  }
}
.p-anniversaryEnquetePrivacyPolicy h3 {
  margin-bottom: 10px;
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnquetePrivacyPolicy h3 {
    font-size: 1.5rem;
  }
}
.p-anniversaryEnquetePrivacyPolicy h3 span {
  font-size: 1.6rem;
  font-weight: normal;
}
@media screen and (max-width: 640px) {
  .p-anniversaryEnquetePrivacyPolicy h3 span {
    font-size: 1.3rem;
  }
}
.p-anniversaryEnquetePrivacyPolicy section + section {
  margin-top: 10px;
}

.u-anchor:before {
  content: "";
  margin-top: -100px;
  padding-top: 100px;
}

/*------------------------------------------------------------
//float & clear
//------------------------------------------------------------*/
.u-flt, .u-flt--pc {
  float: left;
}

.u-flr, .u-flr--pc {
  float: right;
}

.u-clearfix {
  *zoom: 1;
}
.u-clearfix:after {
  display: block;
  content: "";
  clear: both;
}

.u-clear {
  clear: both;
}

@media screen and (max-width: 640px) {
  .u-flt--pc, .u-flr--pc {
    float: none;
  }
}
.u-tel,
.u-email {
  color: inherit !important;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: none;
}

@media screen and (min-width: 641px) {
  .u-tel {
    pointer-events: none;
  }
}

/*------------------------------------------------------------
//flex
//------------------------------------------------------------*/
.u-flex {
  display: flex;
}
.u-flex.u-flex--wrap {
  flex-wrap: wrap;
}
.u-flex.u-flex--wrap_r {
  flex-wrap: wrap-reverse;
}
.u-flex.u-flex--jc_end {
  justify-content: flex-end;
}
.u-flex.u-flex--jc_center {
  justify-content: center;
}
.u-flex.u-flex--jc_between {
  justify-content: space-between;
}
.u-flex.u-flex--jc_around {
  justify-content: space-around;
}
.u-flex.u-flex--ai_start {
  align-items: flex-start;
}
.u-flex.u-flex--ai_end {
  align-items: flex-end;
}
.u-flex.u-flex--ai_center {
  align-items: center;
}
.u-flex.u-flex--ac_start {
  align-content: flex-start;
}
.u-flex.u-flex--ac_end {
  align-content: flex-end;
}
.u-flex.u-flex--ac_center {
  align-content: center;
}
.u-flex.u-flex--ac_between {
  align-content: space-between;
}
.u-flex.u-flex--ac_around {
  align-content: space-around;
}

@media screen and (max-width: 640px) {
  .u-sp_noflex {
    display: inherit;
  }
}
/*------------------------------------------------------------
//inner
//------------------------------------------------------------*/
.u-inner01 {
  width: 1120px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 640px) {
  .u-inner01 {
    width: 100%;
  }
}
/*------------------------------------------------------------
//テキスト揃え
//------------------------------------------------------------*/
.u-txtCenter {
  text-align: center;
}

.u-txtLeft {
  text-align: left;
}

.u-txtRight {
  text-align: right;
}

/*------------------------------------------------------------
//カウンター
//------------------------------------------------------------*/
.u-count01 {
  counter-reset: count01;
}
.u-count01 > * {
  counter-increment: count01;
}

/*------------------------------------------------------------
//非表示
//------------------------------------------------------------*/
.u-dispNone {
  display: none !important;
}

/*------------------------------------------------------------
//ホバー
//------------------------------------------------------------*/
@media (min-width: 641px) {
  .u-hover01 {
    transition: opacity 0.3s ease-in-out;
  }
  .u-hover01:hover {
    opacity: 0.7;
  }
}
/*------------------------------------------------------------
//テキストカラー
//------------------------------------------------------------*/
.u-red {
  color: #f22727;
}

/*------------------------------------------------------------
//その他
//------------------------------------------------------------*/
.u-fadeIn {
  /*遅延表示（js連動）
   * サイドナビのないページのみ有効（サービスTOP、葬祭）
   * プラグイン　scrollrevea*/
}

.u-biggerLink {
  /*ブロック全体をリンク（js連動）
   プラグイン　jquery.biggerlink*/
}

.u-matchHeight {
  /*高さ揃え（js連動）
   プラグイン　jquery.matchHeight*/
}

.u-headPcScroll {
  /*フィックスメニュー　横スクロール対応（js連動）*/
}

.u-noSmoothScroll {
  /*スムーススクロールを無効化（js連動）
   * タブ切り替えなどの際に使用*/
}

.u-datepicker {
  /*カレンダーで日付入力（js連動）
   フォームページのみ有効
   プラグイン　jquery-ui（CSSとdatepicker-ja.jsも読み込み必須）*/
}

.u-yuGothicWeight {
  font-weight: 400;
}

.u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-space-y-0 > * + * {
  margin-top: 0px !important;
}

.u-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-0 {
  margin-top: 0px !important;
}

.u-mr-0 {
  margin-right: 0px !important;
}

.u-mb-0 {
  margin-bottom: 0px !important;
}

.u-ml-0 {
  margin-left: 0px !important;
}

.u-p-0 {
  padding: 0px !important;
}

.u-py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.u-px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.u-pt-0 {
  padding-top: 0px !important;
}

.u-pr-0 {
  padding-right: 0px !important;
}

.u-pb-0 {
  padding-bottom: 0px !important;
}

.u-pl-0 {
  padding-left: 0px !important;
}

.u-space-y-5 > * + * {
  margin-top: 5px !important;
}

.u-my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-5 {
  margin-top: 5px !important;
}

.u-mr-5 {
  margin-right: 5px !important;
}

.u-mb-5 {
  margin-bottom: 5px !important;
}

.u-ml-5 {
  margin-left: 5px !important;
}

.u-p-5 {
  padding: 5px !important;
}

.u-py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.u-px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.u-pt-5 {
  padding-top: 5px !important;
}

.u-pr-5 {
  padding-right: 5px !important;
}

.u-pb-5 {
  padding-bottom: 5px !important;
}

.u-pl-5 {
  padding-left: 5px !important;
}

.u-space-y-10 > * + * {
  margin-top: 10px !important;
}

.u-my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-10 {
  margin-top: 10px !important;
}

.u-mr-10 {
  margin-right: 10px !important;
}

.u-mb-10 {
  margin-bottom: 10px !important;
}

.u-ml-10 {
  margin-left: 10px !important;
}

.u-p-10 {
  padding: 10px !important;
}

.u-py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.u-px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.u-pt-10 {
  padding-top: 10px !important;
}

.u-pr-10 {
  padding-right: 10px !important;
}

.u-pb-10 {
  padding-bottom: 10px !important;
}

.u-pl-10 {
  padding-left: 10px !important;
}

.u-space-y-15 > * + * {
  margin-top: 15px !important;
}

.u-my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-15 {
  margin-top: 15px !important;
}

.u-mr-15 {
  margin-right: 15px !important;
}

.u-mb-15 {
  margin-bottom: 15px !important;
}

.u-ml-15 {
  margin-left: 15px !important;
}

.u-p-15 {
  padding: 15px !important;
}

.u-py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.u-px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.u-pt-15 {
  padding-top: 15px !important;
}

.u-pr-15 {
  padding-right: 15px !important;
}

.u-pb-15 {
  padding-bottom: 15px !important;
}

.u-pl-15 {
  padding-left: 15px !important;
}

.u-space-y-20 > * + * {
  margin-top: 20px !important;
}

.u-my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-20 {
  margin-top: 20px !important;
}

.u-mr-20 {
  margin-right: 20px !important;
}

.u-mb-20 {
  margin-bottom: 20px !important;
}

.u-ml-20 {
  margin-left: 20px !important;
}

.u-p-20 {
  padding: 20px !important;
}

.u-py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.u-px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.u-pt-20 {
  padding-top: 20px !important;
}

.u-pr-20 {
  padding-right: 20px !important;
}

.u-pb-20 {
  padding-bottom: 20px !important;
}

.u-pl-20 {
  padding-left: 20px !important;
}

.u-space-y-25 > * + * {
  margin-top: 25px !important;
}

.u-my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-25 {
  margin-top: 25px !important;
}

.u-mr-25 {
  margin-right: 25px !important;
}

.u-mb-25 {
  margin-bottom: 25px !important;
}

.u-ml-25 {
  margin-left: 25px !important;
}

.u-p-25 {
  padding: 25px !important;
}

.u-py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.u-px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.u-pt-25 {
  padding-top: 25px !important;
}

.u-pr-25 {
  padding-right: 25px !important;
}

.u-pb-25 {
  padding-bottom: 25px !important;
}

.u-pl-25 {
  padding-left: 25px !important;
}

.u-space-y-30 > * + * {
  margin-top: 30px !important;
}

.u-my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-30 {
  margin-top: 30px !important;
}

.u-mr-30 {
  margin-right: 30px !important;
}

.u-mb-30 {
  margin-bottom: 30px !important;
}

.u-ml-30 {
  margin-left: 30px !important;
}

.u-p-30 {
  padding: 30px !important;
}

.u-py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.u-px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.u-pt-30 {
  padding-top: 30px !important;
}

.u-pr-30 {
  padding-right: 30px !important;
}

.u-pb-30 {
  padding-bottom: 30px !important;
}

.u-pl-30 {
  padding-left: 30px !important;
}

.u-space-y-35 > * + * {
  margin-top: 35px !important;
}

.u-my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-35 {
  margin-top: 35px !important;
}

.u-mr-35 {
  margin-right: 35px !important;
}

.u-mb-35 {
  margin-bottom: 35px !important;
}

.u-ml-35 {
  margin-left: 35px !important;
}

.u-p-35 {
  padding: 35px !important;
}

.u-py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.u-px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.u-pt-35 {
  padding-top: 35px !important;
}

.u-pr-35 {
  padding-right: 35px !important;
}

.u-pb-35 {
  padding-bottom: 35px !important;
}

.u-pl-35 {
  padding-left: 35px !important;
}

.u-space-y-40 > * + * {
  margin-top: 40px !important;
}

.u-my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-40 {
  margin-top: 40px !important;
}

.u-mr-40 {
  margin-right: 40px !important;
}

.u-mb-40 {
  margin-bottom: 40px !important;
}

.u-ml-40 {
  margin-left: 40px !important;
}

.u-p-40 {
  padding: 40px !important;
}

.u-py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.u-px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.u-pt-40 {
  padding-top: 40px !important;
}

.u-pr-40 {
  padding-right: 40px !important;
}

.u-pb-40 {
  padding-bottom: 40px !important;
}

.u-pl-40 {
  padding-left: 40px !important;
}

.u-space-y-45 > * + * {
  margin-top: 45px !important;
}

.u-my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-45 {
  margin-top: 45px !important;
}

.u-mr-45 {
  margin-right: 45px !important;
}

.u-mb-45 {
  margin-bottom: 45px !important;
}

.u-ml-45 {
  margin-left: 45px !important;
}

.u-p-45 {
  padding: 45px !important;
}

.u-py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.u-px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.u-pt-45 {
  padding-top: 45px !important;
}

.u-pr-45 {
  padding-right: 45px !important;
}

.u-pb-45 {
  padding-bottom: 45px !important;
}

.u-pl-45 {
  padding-left: 45px !important;
}

.u-space-y-50 > * + * {
  margin-top: 50px !important;
}

.u-my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-50 {
  margin-top: 50px !important;
}

.u-mr-50 {
  margin-right: 50px !important;
}

.u-mb-50 {
  margin-bottom: 50px !important;
}

.u-ml-50 {
  margin-left: 50px !important;
}

.u-p-50 {
  padding: 50px !important;
}

.u-py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.u-px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.u-pt-50 {
  padding-top: 50px !important;
}

.u-pr-50 {
  padding-right: 50px !important;
}

.u-pb-50 {
  padding-bottom: 50px !important;
}

.u-pl-50 {
  padding-left: 50px !important;
}

.u-space-y-55 > * + * {
  margin-top: 55px !important;
}

.u-my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-55 {
  margin-top: 55px !important;
}

.u-mr-55 {
  margin-right: 55px !important;
}

.u-mb-55 {
  margin-bottom: 55px !important;
}

.u-ml-55 {
  margin-left: 55px !important;
}

.u-p-55 {
  padding: 55px !important;
}

.u-py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.u-px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.u-pt-55 {
  padding-top: 55px !important;
}

.u-pr-55 {
  padding-right: 55px !important;
}

.u-pb-55 {
  padding-bottom: 55px !important;
}

.u-pl-55 {
  padding-left: 55px !important;
}

.u-space-y-60 > * + * {
  margin-top: 60px !important;
}

.u-my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-60 {
  margin-top: 60px !important;
}

.u-mr-60 {
  margin-right: 60px !important;
}

.u-mb-60 {
  margin-bottom: 60px !important;
}

.u-ml-60 {
  margin-left: 60px !important;
}

.u-p-60 {
  padding: 60px !important;
}

.u-py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.u-px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.u-pt-60 {
  padding-top: 60px !important;
}

.u-pr-60 {
  padding-right: 60px !important;
}

.u-pb-60 {
  padding-bottom: 60px !important;
}

.u-pl-60 {
  padding-left: 60px !important;
}

.u-space-y-65 > * + * {
  margin-top: 65px !important;
}

.u-my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-65 {
  margin-top: 65px !important;
}

.u-mr-65 {
  margin-right: 65px !important;
}

.u-mb-65 {
  margin-bottom: 65px !important;
}

.u-ml-65 {
  margin-left: 65px !important;
}

.u-p-65 {
  padding: 65px !important;
}

.u-py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.u-px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.u-pt-65 {
  padding-top: 65px !important;
}

.u-pr-65 {
  padding-right: 65px !important;
}

.u-pb-65 {
  padding-bottom: 65px !important;
}

.u-pl-65 {
  padding-left: 65px !important;
}

.u-space-y-70 > * + * {
  margin-top: 70px !important;
}

.u-my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-70 {
  margin-top: 70px !important;
}

.u-mr-70 {
  margin-right: 70px !important;
}

.u-mb-70 {
  margin-bottom: 70px !important;
}

.u-ml-70 {
  margin-left: 70px !important;
}

.u-p-70 {
  padding: 70px !important;
}

.u-py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.u-px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.u-pt-70 {
  padding-top: 70px !important;
}

.u-pr-70 {
  padding-right: 70px !important;
}

.u-pb-70 {
  padding-bottom: 70px !important;
}

.u-pl-70 {
  padding-left: 70px !important;
}

.u-space-y-75 > * + * {
  margin-top: 75px !important;
}

.u-my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-75 {
  margin-top: 75px !important;
}

.u-mr-75 {
  margin-right: 75px !important;
}

.u-mb-75 {
  margin-bottom: 75px !important;
}

.u-ml-75 {
  margin-left: 75px !important;
}

.u-p-75 {
  padding: 75px !important;
}

.u-py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.u-px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.u-pt-75 {
  padding-top: 75px !important;
}

.u-pr-75 {
  padding-right: 75px !important;
}

.u-pb-75 {
  padding-bottom: 75px !important;
}

.u-pl-75 {
  padding-left: 75px !important;
}

.u-space-y-80 > * + * {
  margin-top: 80px !important;
}

.u-my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-80 {
  margin-top: 80px !important;
}

.u-mr-80 {
  margin-right: 80px !important;
}

.u-mb-80 {
  margin-bottom: 80px !important;
}

.u-ml-80 {
  margin-left: 80px !important;
}

.u-p-80 {
  padding: 80px !important;
}

.u-py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.u-px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.u-pt-80 {
  padding-top: 80px !important;
}

.u-pr-80 {
  padding-right: 80px !important;
}

.u-pb-80 {
  padding-bottom: 80px !important;
}

.u-pl-80 {
  padding-left: 80px !important;
}

.u-space-y-85 > * + * {
  margin-top: 85px !important;
}

.u-my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-85 {
  margin-top: 85px !important;
}

.u-mr-85 {
  margin-right: 85px !important;
}

.u-mb-85 {
  margin-bottom: 85px !important;
}

.u-ml-85 {
  margin-left: 85px !important;
}

.u-p-85 {
  padding: 85px !important;
}

.u-py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.u-px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.u-pt-85 {
  padding-top: 85px !important;
}

.u-pr-85 {
  padding-right: 85px !important;
}

.u-pb-85 {
  padding-bottom: 85px !important;
}

.u-pl-85 {
  padding-left: 85px !important;
}

.u-space-y-90 > * + * {
  margin-top: 90px !important;
}

.u-my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-90 {
  margin-top: 90px !important;
}

.u-mr-90 {
  margin-right: 90px !important;
}

.u-mb-90 {
  margin-bottom: 90px !important;
}

.u-ml-90 {
  margin-left: 90px !important;
}

.u-p-90 {
  padding: 90px !important;
}

.u-py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.u-px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.u-pt-90 {
  padding-top: 90px !important;
}

.u-pr-90 {
  padding-right: 90px !important;
}

.u-pb-90 {
  padding-bottom: 90px !important;
}

.u-pl-90 {
  padding-left: 90px !important;
}

.u-space-y-95 > * + * {
  margin-top: 95px !important;
}

.u-my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-95 {
  margin-top: 95px !important;
}

.u-mr-95 {
  margin-right: 95px !important;
}

.u-mb-95 {
  margin-bottom: 95px !important;
}

.u-ml-95 {
  margin-left: 95px !important;
}

.u-p-95 {
  padding: 95px !important;
}

.u-py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.u-px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.u-pt-95 {
  padding-top: 95px !important;
}

.u-pr-95 {
  padding-right: 95px !important;
}

.u-pb-95 {
  padding-bottom: 95px !important;
}

.u-pl-95 {
  padding-left: 95px !important;
}

.u-space-y-100 > * + * {
  margin-top: 100px !important;
}

.u-my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.u-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-mt-100 {
  margin-top: 100px !important;
}

.u-mr-100 {
  margin-right: 100px !important;
}

.u-mb-100 {
  margin-bottom: 100px !important;
}

.u-ml-100 {
  margin-left: 100px !important;
}

.u-p-100 {
  padding: 100px !important;
}

.u-py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.u-px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.u-pt-100 {
  padding-top: 100px !important;
}

.u-pr-100 {
  padding-right: 100px !important;
}

.u-pb-100 {
  padding-bottom: 100px !important;
}

.u-pl-100 {
  padding-left: 100px !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-0 > * + * {
    margin-top: 0px !important;
  }
  .pc\:u-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-0 {
    margin-top: 0px !important;
  }
  .pc\:u-mr-0 {
    margin-right: 0px !important;
  }
  .pc\:u-mb-0 {
    margin-bottom: 0px !important;
  }
  .pc\:u-ml-0 {
    margin-left: 0px !important;
  }
  .pc\:u-p-0 {
    padding: 0px !important;
  }
  .pc\:u-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pc\:u-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pc\:u-pt-0 {
    padding-top: 0px !important;
  }
  .pc\:u-pr-0 {
    padding-right: 0px !important;
  }
  .pc\:u-pb-0 {
    padding-bottom: 0px !important;
  }
  .pc\:u-pl-0 {
    padding-left: 0px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-5 > * + * {
    margin-top: 5px !important;
  }
  .pc\:u-my-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-5 {
    margin-top: 5px !important;
  }
  .pc\:u-mr-5 {
    margin-right: 5px !important;
  }
  .pc\:u-mb-5 {
    margin-bottom: 5px !important;
  }
  .pc\:u-ml-5 {
    margin-left: 5px !important;
  }
  .pc\:u-p-5 {
    padding: 5px !important;
  }
  .pc\:u-py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pc\:u-px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .pc\:u-pt-5 {
    padding-top: 5px !important;
  }
  .pc\:u-pr-5 {
    padding-right: 5px !important;
  }
  .pc\:u-pb-5 {
    padding-bottom: 5px !important;
  }
  .pc\:u-pl-5 {
    padding-left: 5px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-10 > * + * {
    margin-top: 10px !important;
  }
  .pc\:u-my-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-10 {
    margin-top: 10px !important;
  }
  .pc\:u-mr-10 {
    margin-right: 10px !important;
  }
  .pc\:u-mb-10 {
    margin-bottom: 10px !important;
  }
  .pc\:u-ml-10 {
    margin-left: 10px !important;
  }
  .pc\:u-p-10 {
    padding: 10px !important;
  }
  .pc\:u-py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pc\:u-px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pc\:u-pt-10 {
    padding-top: 10px !important;
  }
  .pc\:u-pr-10 {
    padding-right: 10px !important;
  }
  .pc\:u-pb-10 {
    padding-bottom: 10px !important;
  }
  .pc\:u-pl-10 {
    padding-left: 10px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-15 > * + * {
    margin-top: 15px !important;
  }
  .pc\:u-my-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-15 {
    margin-top: 15px !important;
  }
  .pc\:u-mr-15 {
    margin-right: 15px !important;
  }
  .pc\:u-mb-15 {
    margin-bottom: 15px !important;
  }
  .pc\:u-ml-15 {
    margin-left: 15px !important;
  }
  .pc\:u-p-15 {
    padding: 15px !important;
  }
  .pc\:u-py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pc\:u-px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .pc\:u-pt-15 {
    padding-top: 15px !important;
  }
  .pc\:u-pr-15 {
    padding-right: 15px !important;
  }
  .pc\:u-pb-15 {
    padding-bottom: 15px !important;
  }
  .pc\:u-pl-15 {
    padding-left: 15px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-20 > * + * {
    margin-top: 20px !important;
  }
  .pc\:u-my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-20 {
    margin-top: 20px !important;
  }
  .pc\:u-mr-20 {
    margin-right: 20px !important;
  }
  .pc\:u-mb-20 {
    margin-bottom: 20px !important;
  }
  .pc\:u-ml-20 {
    margin-left: 20px !important;
  }
  .pc\:u-p-20 {
    padding: 20px !important;
  }
  .pc\:u-py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pc\:u-px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pc\:u-pt-20 {
    padding-top: 20px !important;
  }
  .pc\:u-pr-20 {
    padding-right: 20px !important;
  }
  .pc\:u-pb-20 {
    padding-bottom: 20px !important;
  }
  .pc\:u-pl-20 {
    padding-left: 20px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-25 > * + * {
    margin-top: 25px !important;
  }
  .pc\:u-my-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-25 {
    margin-top: 25px !important;
  }
  .pc\:u-mr-25 {
    margin-right: 25px !important;
  }
  .pc\:u-mb-25 {
    margin-bottom: 25px !important;
  }
  .pc\:u-ml-25 {
    margin-left: 25px !important;
  }
  .pc\:u-p-25 {
    padding: 25px !important;
  }
  .pc\:u-py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pc\:u-px-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .pc\:u-pt-25 {
    padding-top: 25px !important;
  }
  .pc\:u-pr-25 {
    padding-right: 25px !important;
  }
  .pc\:u-pb-25 {
    padding-bottom: 25px !important;
  }
  .pc\:u-pl-25 {
    padding-left: 25px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-30 > * + * {
    margin-top: 30px !important;
  }
  .pc\:u-my-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-30 {
    margin-top: 30px !important;
  }
  .pc\:u-mr-30 {
    margin-right: 30px !important;
  }
  .pc\:u-mb-30 {
    margin-bottom: 30px !important;
  }
  .pc\:u-ml-30 {
    margin-left: 30px !important;
  }
  .pc\:u-p-30 {
    padding: 30px !important;
  }
  .pc\:u-py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pc\:u-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .pc\:u-pt-30 {
    padding-top: 30px !important;
  }
  .pc\:u-pr-30 {
    padding-right: 30px !important;
  }
  .pc\:u-pb-30 {
    padding-bottom: 30px !important;
  }
  .pc\:u-pl-30 {
    padding-left: 30px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-35 > * + * {
    margin-top: 35px !important;
  }
  .pc\:u-my-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-35 {
    margin-top: 35px !important;
  }
  .pc\:u-mr-35 {
    margin-right: 35px !important;
  }
  .pc\:u-mb-35 {
    margin-bottom: 35px !important;
  }
  .pc\:u-ml-35 {
    margin-left: 35px !important;
  }
  .pc\:u-p-35 {
    padding: 35px !important;
  }
  .pc\:u-py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pc\:u-px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .pc\:u-pt-35 {
    padding-top: 35px !important;
  }
  .pc\:u-pr-35 {
    padding-right: 35px !important;
  }
  .pc\:u-pb-35 {
    padding-bottom: 35px !important;
  }
  .pc\:u-pl-35 {
    padding-left: 35px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-40 > * + * {
    margin-top: 40px !important;
  }
  .pc\:u-my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-40 {
    margin-top: 40px !important;
  }
  .pc\:u-mr-40 {
    margin-right: 40px !important;
  }
  .pc\:u-mb-40 {
    margin-bottom: 40px !important;
  }
  .pc\:u-ml-40 {
    margin-left: 40px !important;
  }
  .pc\:u-p-40 {
    padding: 40px !important;
  }
  .pc\:u-py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pc\:u-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .pc\:u-pt-40 {
    padding-top: 40px !important;
  }
  .pc\:u-pr-40 {
    padding-right: 40px !important;
  }
  .pc\:u-pb-40 {
    padding-bottom: 40px !important;
  }
  .pc\:u-pl-40 {
    padding-left: 40px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-45 > * + * {
    margin-top: 45px !important;
  }
  .pc\:u-my-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-45 {
    margin-top: 45px !important;
  }
  .pc\:u-mr-45 {
    margin-right: 45px !important;
  }
  .pc\:u-mb-45 {
    margin-bottom: 45px !important;
  }
  .pc\:u-ml-45 {
    margin-left: 45px !important;
  }
  .pc\:u-p-45 {
    padding: 45px !important;
  }
  .pc\:u-py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pc\:u-px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .pc\:u-pt-45 {
    padding-top: 45px !important;
  }
  .pc\:u-pr-45 {
    padding-right: 45px !important;
  }
  .pc\:u-pb-45 {
    padding-bottom: 45px !important;
  }
  .pc\:u-pl-45 {
    padding-left: 45px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-50 > * + * {
    margin-top: 50px !important;
  }
  .pc\:u-my-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-50 {
    margin-top: 50px !important;
  }
  .pc\:u-mr-50 {
    margin-right: 50px !important;
  }
  .pc\:u-mb-50 {
    margin-bottom: 50px !important;
  }
  .pc\:u-ml-50 {
    margin-left: 50px !important;
  }
  .pc\:u-p-50 {
    padding: 50px !important;
  }
  .pc\:u-py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pc\:u-px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .pc\:u-pt-50 {
    padding-top: 50px !important;
  }
  .pc\:u-pr-50 {
    padding-right: 50px !important;
  }
  .pc\:u-pb-50 {
    padding-bottom: 50px !important;
  }
  .pc\:u-pl-50 {
    padding-left: 50px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-55 > * + * {
    margin-top: 55px !important;
  }
  .pc\:u-my-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-55 {
    margin-top: 55px !important;
  }
  .pc\:u-mr-55 {
    margin-right: 55px !important;
  }
  .pc\:u-mb-55 {
    margin-bottom: 55px !important;
  }
  .pc\:u-ml-55 {
    margin-left: 55px !important;
  }
  .pc\:u-p-55 {
    padding: 55px !important;
  }
  .pc\:u-py-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pc\:u-px-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .pc\:u-pt-55 {
    padding-top: 55px !important;
  }
  .pc\:u-pr-55 {
    padding-right: 55px !important;
  }
  .pc\:u-pb-55 {
    padding-bottom: 55px !important;
  }
  .pc\:u-pl-55 {
    padding-left: 55px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-60 > * + * {
    margin-top: 60px !important;
  }
  .pc\:u-my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-60 {
    margin-top: 60px !important;
  }
  .pc\:u-mr-60 {
    margin-right: 60px !important;
  }
  .pc\:u-mb-60 {
    margin-bottom: 60px !important;
  }
  .pc\:u-ml-60 {
    margin-left: 60px !important;
  }
  .pc\:u-p-60 {
    padding: 60px !important;
  }
  .pc\:u-py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pc\:u-px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .pc\:u-pt-60 {
    padding-top: 60px !important;
  }
  .pc\:u-pr-60 {
    padding-right: 60px !important;
  }
  .pc\:u-pb-60 {
    padding-bottom: 60px !important;
  }
  .pc\:u-pl-60 {
    padding-left: 60px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-65 > * + * {
    margin-top: 65px !important;
  }
  .pc\:u-my-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-65 {
    margin-top: 65px !important;
  }
  .pc\:u-mr-65 {
    margin-right: 65px !important;
  }
  .pc\:u-mb-65 {
    margin-bottom: 65px !important;
  }
  .pc\:u-ml-65 {
    margin-left: 65px !important;
  }
  .pc\:u-p-65 {
    padding: 65px !important;
  }
  .pc\:u-py-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pc\:u-px-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .pc\:u-pt-65 {
    padding-top: 65px !important;
  }
  .pc\:u-pr-65 {
    padding-right: 65px !important;
  }
  .pc\:u-pb-65 {
    padding-bottom: 65px !important;
  }
  .pc\:u-pl-65 {
    padding-left: 65px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-70 > * + * {
    margin-top: 70px !important;
  }
  .pc\:u-my-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-70 {
    margin-top: 70px !important;
  }
  .pc\:u-mr-70 {
    margin-right: 70px !important;
  }
  .pc\:u-mb-70 {
    margin-bottom: 70px !important;
  }
  .pc\:u-ml-70 {
    margin-left: 70px !important;
  }
  .pc\:u-p-70 {
    padding: 70px !important;
  }
  .pc\:u-py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pc\:u-px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .pc\:u-pt-70 {
    padding-top: 70px !important;
  }
  .pc\:u-pr-70 {
    padding-right: 70px !important;
  }
  .pc\:u-pb-70 {
    padding-bottom: 70px !important;
  }
  .pc\:u-pl-70 {
    padding-left: 70px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-75 > * + * {
    margin-top: 75px !important;
  }
  .pc\:u-my-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-75 {
    margin-top: 75px !important;
  }
  .pc\:u-mr-75 {
    margin-right: 75px !important;
  }
  .pc\:u-mb-75 {
    margin-bottom: 75px !important;
  }
  .pc\:u-ml-75 {
    margin-left: 75px !important;
  }
  .pc\:u-p-75 {
    padding: 75px !important;
  }
  .pc\:u-py-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pc\:u-px-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .pc\:u-pt-75 {
    padding-top: 75px !important;
  }
  .pc\:u-pr-75 {
    padding-right: 75px !important;
  }
  .pc\:u-pb-75 {
    padding-bottom: 75px !important;
  }
  .pc\:u-pl-75 {
    padding-left: 75px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-80 > * + * {
    margin-top: 80px !important;
  }
  .pc\:u-my-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-80 {
    margin-top: 80px !important;
  }
  .pc\:u-mr-80 {
    margin-right: 80px !important;
  }
  .pc\:u-mb-80 {
    margin-bottom: 80px !important;
  }
  .pc\:u-ml-80 {
    margin-left: 80px !important;
  }
  .pc\:u-p-80 {
    padding: 80px !important;
  }
  .pc\:u-py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pc\:u-px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .pc\:u-pt-80 {
    padding-top: 80px !important;
  }
  .pc\:u-pr-80 {
    padding-right: 80px !important;
  }
  .pc\:u-pb-80 {
    padding-bottom: 80px !important;
  }
  .pc\:u-pl-80 {
    padding-left: 80px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-85 > * + * {
    margin-top: 85px !important;
  }
  .pc\:u-my-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-85 {
    margin-top: 85px !important;
  }
  .pc\:u-mr-85 {
    margin-right: 85px !important;
  }
  .pc\:u-mb-85 {
    margin-bottom: 85px !important;
  }
  .pc\:u-ml-85 {
    margin-left: 85px !important;
  }
  .pc\:u-p-85 {
    padding: 85px !important;
  }
  .pc\:u-py-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pc\:u-px-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .pc\:u-pt-85 {
    padding-top: 85px !important;
  }
  .pc\:u-pr-85 {
    padding-right: 85px !important;
  }
  .pc\:u-pb-85 {
    padding-bottom: 85px !important;
  }
  .pc\:u-pl-85 {
    padding-left: 85px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-90 > * + * {
    margin-top: 90px !important;
  }
  .pc\:u-my-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-90 {
    margin-top: 90px !important;
  }
  .pc\:u-mr-90 {
    margin-right: 90px !important;
  }
  .pc\:u-mb-90 {
    margin-bottom: 90px !important;
  }
  .pc\:u-ml-90 {
    margin-left: 90px !important;
  }
  .pc\:u-p-90 {
    padding: 90px !important;
  }
  .pc\:u-py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pc\:u-px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .pc\:u-pt-90 {
    padding-top: 90px !important;
  }
  .pc\:u-pr-90 {
    padding-right: 90px !important;
  }
  .pc\:u-pb-90 {
    padding-bottom: 90px !important;
  }
  .pc\:u-pl-90 {
    padding-left: 90px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-95 > * + * {
    margin-top: 95px !important;
  }
  .pc\:u-my-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-95 {
    margin-top: 95px !important;
  }
  .pc\:u-mr-95 {
    margin-right: 95px !important;
  }
  .pc\:u-mb-95 {
    margin-bottom: 95px !important;
  }
  .pc\:u-ml-95 {
    margin-left: 95px !important;
  }
  .pc\:u-p-95 {
    padding: 95px !important;
  }
  .pc\:u-py-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pc\:u-px-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .pc\:u-pt-95 {
    padding-top: 95px !important;
  }
  .pc\:u-pr-95 {
    padding-right: 95px !important;
  }
  .pc\:u-pb-95 {
    padding-bottom: 95px !important;
  }
  .pc\:u-pl-95 {
    padding-left: 95px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-space-y-100 > * + * {
    margin-top: 100px !important;
  }
  .pc\:u-my-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .pc\:u-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .pc\:u-mt-100 {
    margin-top: 100px !important;
  }
  .pc\:u-mr-100 {
    margin-right: 100px !important;
  }
  .pc\:u-mb-100 {
    margin-bottom: 100px !important;
  }
  .pc\:u-ml-100 {
    margin-left: 100px !important;
  }
  .pc\:u-p-100 {
    padding: 100px !important;
  }
  .pc\:u-py-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pc\:u-px-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .pc\:u-pt-100 {
    padding-top: 100px !important;
  }
  .pc\:u-pr-100 {
    padding-right: 100px !important;
  }
  .pc\:u-pb-100 {
    padding-bottom: 100px !important;
  }
  .pc\:u-pl-100 {
    padding-left: 100px !important;
  }
}
.pc\:u-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-w-0 {
  width: 0px !important;
}

.u-h-0 {
  height: 0px !important;
}

.u-w-5 {
  width: 5px !important;
}

.u-h-5 {
  height: 5px !important;
}

.u-w-10 {
  width: 10px !important;
}

.u-h-10 {
  height: 10px !important;
}

.u-w-15 {
  width: 15px !important;
}

.u-h-15 {
  height: 15px !important;
}

.u-w-20 {
  width: 20px !important;
}

.u-h-20 {
  height: 20px !important;
}

.u-w-25 {
  width: 25px !important;
}

.u-h-25 {
  height: 25px !important;
}

.u-w-30 {
  width: 30px !important;
}

.u-h-30 {
  height: 30px !important;
}

.u-w-35 {
  width: 35px !important;
}

.u-h-35 {
  height: 35px !important;
}

.u-w-40 {
  width: 40px !important;
}

.u-h-40 {
  height: 40px !important;
}

.u-w-45 {
  width: 45px !important;
}

.u-h-45 {
  height: 45px !important;
}

.u-w-50 {
  width: 50px !important;
}

.u-h-50 {
  height: 50px !important;
}

.u-w-55 {
  width: 55px !important;
}

.u-h-55 {
  height: 55px !important;
}

.u-w-60 {
  width: 60px !important;
}

.u-h-60 {
  height: 60px !important;
}

.u-w-65 {
  width: 65px !important;
}

.u-h-65 {
  height: 65px !important;
}

.u-w-70 {
  width: 70px !important;
}

.u-h-70 {
  height: 70px !important;
}

.u-w-75 {
  width: 75px !important;
}

.u-h-75 {
  height: 75px !important;
}

.u-w-80 {
  width: 80px !important;
}

.u-h-80 {
  height: 80px !important;
}

.u-w-85 {
  width: 85px !important;
}

.u-h-85 {
  height: 85px !important;
}

.u-w-90 {
  width: 90px !important;
}

.u-h-90 {
  height: 90px !important;
}

.u-w-95 {
  width: 95px !important;
}

.u-h-95 {
  height: 95px !important;
}

.u-w-100 {
  width: 100px !important;
}

.u-h-100 {
  height: 100px !important;
}

.u-w-105 {
  width: 105px !important;
}

.u-h-105 {
  height: 105px !important;
}

.u-w-110 {
  width: 110px !important;
}

.u-h-110 {
  height: 110px !important;
}

.u-w-115 {
  width: 115px !important;
}

.u-h-115 {
  height: 115px !important;
}

.u-w-120 {
  width: 120px !important;
}

.u-h-120 {
  height: 120px !important;
}

.u-w-125 {
  width: 125px !important;
}

.u-h-125 {
  height: 125px !important;
}

.u-w-130 {
  width: 130px !important;
}

.u-h-130 {
  height: 130px !important;
}

.u-w-135 {
  width: 135px !important;
}

.u-h-135 {
  height: 135px !important;
}

.u-w-140 {
  width: 140px !important;
}

.u-h-140 {
  height: 140px !important;
}

.u-w-145 {
  width: 145px !important;
}

.u-h-145 {
  height: 145px !important;
}

.u-w-150 {
  width: 150px !important;
}

.u-h-150 {
  height: 150px !important;
}

.u-w-155 {
  width: 155px !important;
}

.u-h-155 {
  height: 155px !important;
}

.u-w-160 {
  width: 160px !important;
}

.u-h-160 {
  height: 160px !important;
}

.u-w-165 {
  width: 165px !important;
}

.u-h-165 {
  height: 165px !important;
}

.u-w-170 {
  width: 170px !important;
}

.u-h-170 {
  height: 170px !important;
}

.u-w-175 {
  width: 175px !important;
}

.u-h-175 {
  height: 175px !important;
}

.u-w-180 {
  width: 180px !important;
}

.u-h-180 {
  height: 180px !important;
}

.u-w-185 {
  width: 185px !important;
}

.u-h-185 {
  height: 185px !important;
}

.u-w-190 {
  width: 190px !important;
}

.u-h-190 {
  height: 190px !important;
}

.u-w-195 {
  width: 195px !important;
}

.u-h-195 {
  height: 195px !important;
}

.u-w-200 {
  width: 200px !important;
}

.u-h-200 {
  height: 200px !important;
}

.u-w-205 {
  width: 205px !important;
}

.u-h-205 {
  height: 205px !important;
}

.u-w-210 {
  width: 210px !important;
}

.u-h-210 {
  height: 210px !important;
}

.u-w-215 {
  width: 215px !important;
}

.u-h-215 {
  height: 215px !important;
}

.u-w-220 {
  width: 220px !important;
}

.u-h-220 {
  height: 220px !important;
}

.u-w-225 {
  width: 225px !important;
}

.u-h-225 {
  height: 225px !important;
}

.u-w-230 {
  width: 230px !important;
}

.u-h-230 {
  height: 230px !important;
}

.u-w-235 {
  width: 235px !important;
}

.u-h-235 {
  height: 235px !important;
}

.u-w-240 {
  width: 240px !important;
}

.u-h-240 {
  height: 240px !important;
}

.u-w-245 {
  width: 245px !important;
}

.u-h-245 {
  height: 245px !important;
}

.u-w-250 {
  width: 250px !important;
}

.u-h-250 {
  height: 250px !important;
}

.u-w-255 {
  width: 255px !important;
}

.u-h-255 {
  height: 255px !important;
}

.u-w-260 {
  width: 260px !important;
}

.u-h-260 {
  height: 260px !important;
}

.u-w-265 {
  width: 265px !important;
}

.u-h-265 {
  height: 265px !important;
}

.u-w-270 {
  width: 270px !important;
}

.u-h-270 {
  height: 270px !important;
}

.u-w-275 {
  width: 275px !important;
}

.u-h-275 {
  height: 275px !important;
}

.u-w-280 {
  width: 280px !important;
}

.u-h-280 {
  height: 280px !important;
}

.u-w-285 {
  width: 285px !important;
}

.u-h-285 {
  height: 285px !important;
}

.u-w-290 {
  width: 290px !important;
}

.u-h-290 {
  height: 290px !important;
}

.u-w-295 {
  width: 295px !important;
}

.u-h-295 {
  height: 295px !important;
}

.u-w-300 {
  width: 300px !important;
}

.u-h-300 {
  height: 300px !important;
}

.u-w-305 {
  width: 305px !important;
}

.u-h-305 {
  height: 305px !important;
}

.u-w-310 {
  width: 310px !important;
}

.u-h-310 {
  height: 310px !important;
}

.u-w-315 {
  width: 315px !important;
}

.u-h-315 {
  height: 315px !important;
}

.u-w-320 {
  width: 320px !important;
}

.u-h-320 {
  height: 320px !important;
}

.u-w-325 {
  width: 325px !important;
}

.u-h-325 {
  height: 325px !important;
}

.u-w-330 {
  width: 330px !important;
}

.u-h-330 {
  height: 330px !important;
}

.u-w-335 {
  width: 335px !important;
}

.u-h-335 {
  height: 335px !important;
}

.u-w-340 {
  width: 340px !important;
}

.u-h-340 {
  height: 340px !important;
}

.u-w-345 {
  width: 345px !important;
}

.u-h-345 {
  height: 345px !important;
}

.u-w-350 {
  width: 350px !important;
}

.u-h-350 {
  height: 350px !important;
}

.u-w-355 {
  width: 355px !important;
}

.u-h-355 {
  height: 355px !important;
}

.u-w-360 {
  width: 360px !important;
}

.u-h-360 {
  height: 360px !important;
}

.u-w-365 {
  width: 365px !important;
}

.u-h-365 {
  height: 365px !important;
}

.u-w-370 {
  width: 370px !important;
}

.u-h-370 {
  height: 370px !important;
}

.u-w-375 {
  width: 375px !important;
}

.u-h-375 {
  height: 375px !important;
}

.u-w-380 {
  width: 380px !important;
}

.u-h-380 {
  height: 380px !important;
}

.u-w-385 {
  width: 385px !important;
}

.u-h-385 {
  height: 385px !important;
}

.u-w-390 {
  width: 390px !important;
}

.u-h-390 {
  height: 390px !important;
}

.u-w-395 {
  width: 395px !important;
}

.u-h-395 {
  height: 395px !important;
}

.u-w-400 {
  width: 400px !important;
}

.u-h-400 {
  height: 400px !important;
}

.u-w-405 {
  width: 405px !important;
}

.u-h-405 {
  height: 405px !important;
}

.u-w-410 {
  width: 410px !important;
}

.u-h-410 {
  height: 410px !important;
}

.u-w-415 {
  width: 415px !important;
}

.u-h-415 {
  height: 415px !important;
}

.u-w-420 {
  width: 420px !important;
}

.u-h-420 {
  height: 420px !important;
}

.u-w-425 {
  width: 425px !important;
}

.u-h-425 {
  height: 425px !important;
}

.u-w-430 {
  width: 430px !important;
}

.u-h-430 {
  height: 430px !important;
}

.u-w-435 {
  width: 435px !important;
}

.u-h-435 {
  height: 435px !important;
}

.u-w-440 {
  width: 440px !important;
}

.u-h-440 {
  height: 440px !important;
}

.u-w-445 {
  width: 445px !important;
}

.u-h-445 {
  height: 445px !important;
}

.u-w-450 {
  width: 450px !important;
}

.u-h-450 {
  height: 450px !important;
}

.u-w-455 {
  width: 455px !important;
}

.u-h-455 {
  height: 455px !important;
}

.u-w-460 {
  width: 460px !important;
}

.u-h-460 {
  height: 460px !important;
}

.u-w-465 {
  width: 465px !important;
}

.u-h-465 {
  height: 465px !important;
}

.u-w-470 {
  width: 470px !important;
}

.u-h-470 {
  height: 470px !important;
}

.u-w-475 {
  width: 475px !important;
}

.u-h-475 {
  height: 475px !important;
}

.u-w-480 {
  width: 480px !important;
}

.u-h-480 {
  height: 480px !important;
}

.u-w-485 {
  width: 485px !important;
}

.u-h-485 {
  height: 485px !important;
}

.u-w-490 {
  width: 490px !important;
}

.u-h-490 {
  height: 490px !important;
}

.u-w-495 {
  width: 495px !important;
}

.u-h-495 {
  height: 495px !important;
}

.u-w-500 {
  width: 500px !important;
}

.u-h-500 {
  height: 500px !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-w-0 {
    width: 0px !important;
  }
  .pc\:u-h-0 {
    height: 0px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-5 {
    width: 5px !important;
  }
  .pc\:u-h-5 {
    height: 5px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-10 {
    width: 10px !important;
  }
  .pc\:u-h-10 {
    height: 10px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-15 {
    width: 15px !important;
  }
  .pc\:u-h-15 {
    height: 15px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-20 {
    width: 20px !important;
  }
  .pc\:u-h-20 {
    height: 20px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-25 {
    width: 25px !important;
  }
  .pc\:u-h-25 {
    height: 25px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-30 {
    width: 30px !important;
  }
  .pc\:u-h-30 {
    height: 30px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-35 {
    width: 35px !important;
  }
  .pc\:u-h-35 {
    height: 35px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-40 {
    width: 40px !important;
  }
  .pc\:u-h-40 {
    height: 40px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-45 {
    width: 45px !important;
  }
  .pc\:u-h-45 {
    height: 45px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-50 {
    width: 50px !important;
  }
  .pc\:u-h-50 {
    height: 50px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-55 {
    width: 55px !important;
  }
  .pc\:u-h-55 {
    height: 55px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-60 {
    width: 60px !important;
  }
  .pc\:u-h-60 {
    height: 60px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-65 {
    width: 65px !important;
  }
  .pc\:u-h-65 {
    height: 65px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-70 {
    width: 70px !important;
  }
  .pc\:u-h-70 {
    height: 70px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-75 {
    width: 75px !important;
  }
  .pc\:u-h-75 {
    height: 75px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-80 {
    width: 80px !important;
  }
  .pc\:u-h-80 {
    height: 80px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-85 {
    width: 85px !important;
  }
  .pc\:u-h-85 {
    height: 85px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-90 {
    width: 90px !important;
  }
  .pc\:u-h-90 {
    height: 90px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-95 {
    width: 95px !important;
  }
  .pc\:u-h-95 {
    height: 95px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-100 {
    width: 100px !important;
  }
  .pc\:u-h-100 {
    height: 100px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-105 {
    width: 105px !important;
  }
  .pc\:u-h-105 {
    height: 105px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-110 {
    width: 110px !important;
  }
  .pc\:u-h-110 {
    height: 110px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-115 {
    width: 115px !important;
  }
  .pc\:u-h-115 {
    height: 115px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-120 {
    width: 120px !important;
  }
  .pc\:u-h-120 {
    height: 120px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-125 {
    width: 125px !important;
  }
  .pc\:u-h-125 {
    height: 125px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-130 {
    width: 130px !important;
  }
  .pc\:u-h-130 {
    height: 130px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-135 {
    width: 135px !important;
  }
  .pc\:u-h-135 {
    height: 135px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-140 {
    width: 140px !important;
  }
  .pc\:u-h-140 {
    height: 140px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-145 {
    width: 145px !important;
  }
  .pc\:u-h-145 {
    height: 145px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-150 {
    width: 150px !important;
  }
  .pc\:u-h-150 {
    height: 150px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-155 {
    width: 155px !important;
  }
  .pc\:u-h-155 {
    height: 155px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-160 {
    width: 160px !important;
  }
  .pc\:u-h-160 {
    height: 160px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-165 {
    width: 165px !important;
  }
  .pc\:u-h-165 {
    height: 165px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-170 {
    width: 170px !important;
  }
  .pc\:u-h-170 {
    height: 170px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-175 {
    width: 175px !important;
  }
  .pc\:u-h-175 {
    height: 175px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-180 {
    width: 180px !important;
  }
  .pc\:u-h-180 {
    height: 180px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-185 {
    width: 185px !important;
  }
  .pc\:u-h-185 {
    height: 185px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-190 {
    width: 190px !important;
  }
  .pc\:u-h-190 {
    height: 190px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-195 {
    width: 195px !important;
  }
  .pc\:u-h-195 {
    height: 195px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-200 {
    width: 200px !important;
  }
  .pc\:u-h-200 {
    height: 200px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-205 {
    width: 205px !important;
  }
  .pc\:u-h-205 {
    height: 205px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-210 {
    width: 210px !important;
  }
  .pc\:u-h-210 {
    height: 210px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-215 {
    width: 215px !important;
  }
  .pc\:u-h-215 {
    height: 215px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-220 {
    width: 220px !important;
  }
  .pc\:u-h-220 {
    height: 220px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-225 {
    width: 225px !important;
  }
  .pc\:u-h-225 {
    height: 225px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-230 {
    width: 230px !important;
  }
  .pc\:u-h-230 {
    height: 230px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-235 {
    width: 235px !important;
  }
  .pc\:u-h-235 {
    height: 235px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-240 {
    width: 240px !important;
  }
  .pc\:u-h-240 {
    height: 240px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-245 {
    width: 245px !important;
  }
  .pc\:u-h-245 {
    height: 245px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-250 {
    width: 250px !important;
  }
  .pc\:u-h-250 {
    height: 250px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-255 {
    width: 255px !important;
  }
  .pc\:u-h-255 {
    height: 255px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-260 {
    width: 260px !important;
  }
  .pc\:u-h-260 {
    height: 260px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-265 {
    width: 265px !important;
  }
  .pc\:u-h-265 {
    height: 265px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-270 {
    width: 270px !important;
  }
  .pc\:u-h-270 {
    height: 270px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-275 {
    width: 275px !important;
  }
  .pc\:u-h-275 {
    height: 275px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-280 {
    width: 280px !important;
  }
  .pc\:u-h-280 {
    height: 280px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-285 {
    width: 285px !important;
  }
  .pc\:u-h-285 {
    height: 285px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-290 {
    width: 290px !important;
  }
  .pc\:u-h-290 {
    height: 290px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-295 {
    width: 295px !important;
  }
  .pc\:u-h-295 {
    height: 295px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-300 {
    width: 300px !important;
  }
  .pc\:u-h-300 {
    height: 300px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-305 {
    width: 305px !important;
  }
  .pc\:u-h-305 {
    height: 305px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-310 {
    width: 310px !important;
  }
  .pc\:u-h-310 {
    height: 310px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-315 {
    width: 315px !important;
  }
  .pc\:u-h-315 {
    height: 315px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-320 {
    width: 320px !important;
  }
  .pc\:u-h-320 {
    height: 320px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-325 {
    width: 325px !important;
  }
  .pc\:u-h-325 {
    height: 325px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-330 {
    width: 330px !important;
  }
  .pc\:u-h-330 {
    height: 330px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-335 {
    width: 335px !important;
  }
  .pc\:u-h-335 {
    height: 335px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-340 {
    width: 340px !important;
  }
  .pc\:u-h-340 {
    height: 340px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-345 {
    width: 345px !important;
  }
  .pc\:u-h-345 {
    height: 345px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-350 {
    width: 350px !important;
  }
  .pc\:u-h-350 {
    height: 350px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-355 {
    width: 355px !important;
  }
  .pc\:u-h-355 {
    height: 355px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-360 {
    width: 360px !important;
  }
  .pc\:u-h-360 {
    height: 360px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-365 {
    width: 365px !important;
  }
  .pc\:u-h-365 {
    height: 365px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-370 {
    width: 370px !important;
  }
  .pc\:u-h-370 {
    height: 370px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-375 {
    width: 375px !important;
  }
  .pc\:u-h-375 {
    height: 375px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-380 {
    width: 380px !important;
  }
  .pc\:u-h-380 {
    height: 380px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-385 {
    width: 385px !important;
  }
  .pc\:u-h-385 {
    height: 385px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-390 {
    width: 390px !important;
  }
  .pc\:u-h-390 {
    height: 390px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-395 {
    width: 395px !important;
  }
  .pc\:u-h-395 {
    height: 395px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-400 {
    width: 400px !important;
  }
  .pc\:u-h-400 {
    height: 400px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-405 {
    width: 405px !important;
  }
  .pc\:u-h-405 {
    height: 405px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-410 {
    width: 410px !important;
  }
  .pc\:u-h-410 {
    height: 410px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-415 {
    width: 415px !important;
  }
  .pc\:u-h-415 {
    height: 415px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-420 {
    width: 420px !important;
  }
  .pc\:u-h-420 {
    height: 420px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-425 {
    width: 425px !important;
  }
  .pc\:u-h-425 {
    height: 425px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-430 {
    width: 430px !important;
  }
  .pc\:u-h-430 {
    height: 430px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-435 {
    width: 435px !important;
  }
  .pc\:u-h-435 {
    height: 435px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-440 {
    width: 440px !important;
  }
  .pc\:u-h-440 {
    height: 440px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-445 {
    width: 445px !important;
  }
  .pc\:u-h-445 {
    height: 445px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-450 {
    width: 450px !important;
  }
  .pc\:u-h-450 {
    height: 450px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-455 {
    width: 455px !important;
  }
  .pc\:u-h-455 {
    height: 455px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-460 {
    width: 460px !important;
  }
  .pc\:u-h-460 {
    height: 460px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-465 {
    width: 465px !important;
  }
  .pc\:u-h-465 {
    height: 465px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-470 {
    width: 470px !important;
  }
  .pc\:u-h-470 {
    height: 470px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-475 {
    width: 475px !important;
  }
  .pc\:u-h-475 {
    height: 475px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-480 {
    width: 480px !important;
  }
  .pc\:u-h-480 {
    height: 480px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-485 {
    width: 485px !important;
  }
  .pc\:u-h-485 {
    height: 485px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-490 {
    width: 490px !important;
  }
  .pc\:u-h-490 {
    height: 490px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-495 {
    width: 495px !important;
  }
  .pc\:u-h-495 {
    height: 495px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-w-500 {
    width: 500px !important;
  }
  .pc\:u-h-500 {
    height: 500px !important;
  }
}
.u-w-fit {
  width: fit-content !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-w-fit {
    width: fit-content !important;
  }
}
.u-max-w-none {
  max-width: none;
}

@media screen and (min-width: 641px) {
  .pc\:u-max-w-none {
    max-width: none;
  }
}
.u-text-center {
  text-align: center !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-text-center {
    text-align: center !important;
  }
}
.u-text-right {
  text-align: right !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-text-right {
    text-align: right !important;
  }
}
.u-w-full {
  width: 100% !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-w-full {
    width: 100% !important;
  }
}
.u-flex-1 {
  flex: 1 !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-flex-1 {
    flex: 1 !important;
  }
}
.u-flex-none {
  flex: none !important;
}

@media screen and (min-width: 641px) {
  .pc\:flex-none {
    flex: none !important;
  }
}
.u-order-0 {
  order: 0 !important;
}

.u-order-1 {
  order: 1 !important;
}

.u-order-2 {
  order: 2 !important;
}

.u-order-3 {
  order: 3 !important;
}

.u-order-4 {
  order: 4 !important;
}

.u-order-5 {
  order: 5 !important;
}

.u-order-6 {
  order: 6 !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-order-0 {
    order: 0 !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-order-1 {
    order: 1 !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-order-2 {
    order: 2 !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-order-3 {
    order: 3 !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-order-4 {
    order: 4 !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-order-5 {
    order: 5 !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-order-6 {
    order: 6 !important;
  }
}
.u-text-xs {
  font-size: 1.2rem !important;
}

.u-text-sm {
  font-size: 1.4rem !important;
}

.u-text-base {
  font-size: 1.6rem !important;
}

.u-text-lg {
  font-size: 1.8rem !important;
}

.u-text-xl {
  font-size: 2rem !important;
}

.u-text-19 {
  font-size: 1.9rem !important;
}

.u-text-24 {
  font-size: 2.4rem !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-text-xs {
    font-size: 1.2rem !important;
  }
  .pc\:u-text-sm {
    font-size: 1.4rem !important;
  }
  .pc\:u-text-base {
    font-size: 1.6rem !important;
  }
  .pc\:u-text-lg {
    font-size: 1.8rem !important;
  }
  .pc\:u-text-xl {
    font-size: 2rem !important;
  }
  .pc\:u-text-19 {
    font-size: 1.9rem !important;
  }
  .pc\:u-text-24 {
    font-size: 2.4rem !important;
  }
}
.u-font-normal {
  font-weight: 400 !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-font-normal {
    font-weight: 400 !important;
  }
}
.u-font-bold {
  font-weight: 700 !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-font-bold {
    font-weight: 700 !important;
  }
}
.u-underline {
  text-decoration: underline !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-underline {
    text-decoration: underline !important;
  }
}
.u-bg-none {
  background-color: transparent !important;
}

.u-bg-white {
  background-color: #fff !important;
}

.u-bg-gray-300 {
  background-color: #EFEFEF !important;
}

.u-bg-blue-200 {
  background-color: #E4F7FF !important;
}

.u-bg-blue-500 {
  background-color: #41B4E6 !important;
}

.u-bg-green-200 {
  background-color: #DEFAEE !important;
}

.u-bg-green-200 {
  background-color: #DEFAEE !important;
}

.u-bg-yellowGreen-200 {
  background-color: #ECFADF !important;
}

.u-bg-yellow-200 {
  background-color: #FFF8DA !important;
}

.u-bg-orange-200 {
  background-color: #FFEDE3 !important;
}

.u-bg-red-200 {
  background-color: #FFEDE3 !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-bg-none {
    background-color: transparent !important;
  }
  .pc\:u-bg-white {
    background-color: #fff !important;
  }
  .pc\:u-bg-gray-300 {
    background-color: #EFEFEF !important;
  }
  .pc\:u-bg-blue-200 {
    background-color: #E4F7FF !important;
  }
  .pc\:u-bg-blue-500 {
    background-color: #41B4E6 !important;
  }
  .pc\:u-bg-green-200 {
    background-color: #DEFAEE !important;
  }
  .pc\:u-bg-yellowGreen-200 {
    background-color: #ECFADF !important;
  }
  .pc\:u-bg-yellow-200 {
    background-color: #FFF8DA !important;
  }
  .pc\:u-bg-red-200 {
    background-color: #FFEDE3 !important;
  }
}
.u-border-none {
  border-color: transparent !important;
}

.u-border-white {
  border-color: #fff !important;
}

.u-border-gray-200 {
  border-color: #D9D9D9 !important;
}

.u-border-blue-500 {
  border-color: #41B4E6 !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-border-none {
    border-color: transparent !important;
  }
  .pc\:u-border-white {
    border-color: #fff !important;
  }
  .pc\:u-border-gray-200 {
    border-color: #D9D9D9 !important;
  }
  .pc\:u-border-blue-500 {
    border-color: #41B4E6 !important;
  }
}
.u-text-white {
  color: #fff !important;
}

.u-text-blue-500 {
  color: #41B4E6 !important;
}

.u-text-orange-500 {
  color: #FA8246 !important;
}

.u-text-gray-1000 {
  color: #222 !important;
}

.u-text-gray-900 {
  color: #333 !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-text-white {
    color: #fff !important;
  }
  .pc\:u-text-blue-500 {
    color: #41B4E6 !important;
  }
  .pc\:u-text-gray-1000 {
    color: #222 !important;
  }
  .pc\:u-text-gray-900 {
    color: #333 !important;
  }
}
.u-rounded-5 {
  border-radius: 5px !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-rounded-10 {
    border-radius: 10px !important;
  }
}
.u-rounded-full {
  border-radius: 9999px !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-rounded-full {
    border-radius: 9999px !important;
  }
}
.u-flex {
  display: flex !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-flex {
    display: flex !important;
  }
}
.u-flex-col {
  flex-direction: column !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-flex-col {
    flex-direction: column !important;
  }
}
.u-justify-center {
  justify-content: center !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-justify-center {
    justify-content: center !important;
  }
}
.u-justify-end {
  justify-content: end !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-justify-end {
    justify-content: end !important;
  }
}
.u-justify-between {
  justify-content: space-between !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-justify-between {
    justify-content: space-between !important;
  }
}
.u-items-center {
  align-items: center !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-items-center {
    align-items: center !important;
  }
}
.u-items-end {
  align-items: flex-end !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-items-end {
    align-items: flex-end !important;
  }
}
.u-column-gap-2\.5 {
  column-gap: 2.5px !important;
}

.u-column-gap-0 {
  column-gap: 0px !important;
}

.u-row-gap-0 {
  row-gap: 0px !important;
}

.u-column-gap-5 {
  column-gap: 5px !important;
}

.u-row-gap-5 {
  row-gap: 5px !important;
}

.u-column-gap-10 {
  column-gap: 10px !important;
}

.u-row-gap-10 {
  row-gap: 10px !important;
}

.u-column-gap-15 {
  column-gap: 15px !important;
}

.u-row-gap-15 {
  row-gap: 15px !important;
}

.u-column-gap-20 {
  column-gap: 20px !important;
}

.u-row-gap-20 {
  row-gap: 20px !important;
}

.u-column-gap-25 {
  column-gap: 25px !important;
}

.u-row-gap-25 {
  row-gap: 25px !important;
}

.u-column-gap-30 {
  column-gap: 30px !important;
}

.u-row-gap-30 {
  row-gap: 30px !important;
}

.u-column-gap-35 {
  column-gap: 35px !important;
}

.u-row-gap-35 {
  row-gap: 35px !important;
}

.u-column-gap-40 {
  column-gap: 40px !important;
}

.u-row-gap-40 {
  row-gap: 40px !important;
}

.u-column-gap-45 {
  column-gap: 45px !important;
}

.u-row-gap-45 {
  row-gap: 45px !important;
}

.u-column-gap-50 {
  column-gap: 50px !important;
}

.u-row-gap-50 {
  row-gap: 50px !important;
}

.u-column-gap-55 {
  column-gap: 55px !important;
}

.u-row-gap-55 {
  row-gap: 55px !important;
}

.u-column-gap-60 {
  column-gap: 60px !important;
}

.u-row-gap-60 {
  row-gap: 60px !important;
}

.u-column-gap-65 {
  column-gap: 65px !important;
}

.u-row-gap-65 {
  row-gap: 65px !important;
}

.u-column-gap-70 {
  column-gap: 70px !important;
}

.u-row-gap-70 {
  row-gap: 70px !important;
}

.u-column-gap-75 {
  column-gap: 75px !important;
}

.u-row-gap-75 {
  row-gap: 75px !important;
}

.u-column-gap-80 {
  column-gap: 80px !important;
}

.u-row-gap-80 {
  row-gap: 80px !important;
}

.u-column-gap-85 {
  column-gap: 85px !important;
}

.u-row-gap-85 {
  row-gap: 85px !important;
}

.u-column-gap-90 {
  column-gap: 90px !important;
}

.u-row-gap-90 {
  row-gap: 90px !important;
}

.u-column-gap-95 {
  column-gap: 95px !important;
}

.u-row-gap-95 {
  row-gap: 95px !important;
}

.u-column-gap-100 {
  column-gap: 100px !important;
}

.u-row-gap-100 {
  row-gap: 100px !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-column-gap-2\.5 {
    column-gap: 2.5px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-0 {
    column-gap: 0px !important;
  }
  .pc\:u-row-gap-0 {
    row-gap: 0px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-5 {
    column-gap: 5px !important;
  }
  .pc\:u-row-gap-5 {
    row-gap: 5px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-10 {
    column-gap: 10px !important;
  }
  .pc\:u-row-gap-10 {
    row-gap: 10px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-15 {
    column-gap: 15px !important;
  }
  .pc\:u-row-gap-15 {
    row-gap: 15px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-20 {
    column-gap: 20px !important;
  }
  .pc\:u-row-gap-20 {
    row-gap: 20px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-25 {
    column-gap: 25px !important;
  }
  .pc\:u-row-gap-25 {
    row-gap: 25px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-30 {
    column-gap: 30px !important;
  }
  .pc\:u-row-gap-30 {
    row-gap: 30px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-35 {
    column-gap: 35px !important;
  }
  .pc\:u-row-gap-35 {
    row-gap: 35px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-40 {
    column-gap: 40px !important;
  }
  .pc\:u-row-gap-40 {
    row-gap: 40px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-45 {
    column-gap: 45px !important;
  }
  .pc\:u-row-gap-45 {
    row-gap: 45px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-50 {
    column-gap: 50px !important;
  }
  .pc\:u-row-gap-50 {
    row-gap: 50px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-55 {
    column-gap: 55px !important;
  }
  .pc\:u-row-gap-55 {
    row-gap: 55px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-60 {
    column-gap: 60px !important;
  }
  .pc\:u-row-gap-60 {
    row-gap: 60px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-65 {
    column-gap: 65px !important;
  }
  .pc\:u-row-gap-65 {
    row-gap: 65px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-70 {
    column-gap: 70px !important;
  }
  .pc\:u-row-gap-70 {
    row-gap: 70px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-75 {
    column-gap: 75px !important;
  }
  .pc\:u-row-gap-75 {
    row-gap: 75px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-80 {
    column-gap: 80px !important;
  }
  .pc\:u-row-gap-80 {
    row-gap: 80px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-85 {
    column-gap: 85px !important;
  }
  .pc\:u-row-gap-85 {
    row-gap: 85px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-90 {
    column-gap: 90px !important;
  }
  .pc\:u-row-gap-90 {
    row-gap: 90px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-95 {
    column-gap: 95px !important;
  }
  .pc\:u-row-gap-95 {
    row-gap: 95px !important;
  }
}
@media screen and (min-width: 641px) {
  .pc\:u-column-gap-100 {
    column-gap: 100px !important;
  }
  .pc\:u-row-gap-100 {
    row-gap: 100px !important;
  }
}
.u-grid {
  display: grid !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-grid {
    display: grid !important;
  }
}
.u-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.u-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.u-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.u-grid-cols-auto\/1fr {
  grid-template-columns: auto 1fr !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .pc\:u-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .pc\:u-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .pc\:u-grid-cols-auto\/1fr {
    grid-template-columns: auto 1fr !important;
  }
}
.u-border-1 {
  border-width: 1px !important;
  border-style: solid !important;
}

.u-border-1\.5 {
  border-width: 1.5px !important;
  border-style: solid !important;
}

.u-border-2 {
  border-width: 2px !important;
  border-style: solid !important;
}

.u-border-3 {
  border-width: 3px !important;
  border-style: solid !important;
}

@media screen and (min-width: 641px) {
  .pc\:u-border-1 {
    border-width: 1px !important;
    border-style: solid !important;
  }
  .pc\:u-border-1\.5 {
    border-width: 1.5px !important;
    border-style: solid !important;
  }
  .pc\:u-border-2 {
    border-width: 2px !important;
    border-style: solid !important;
  }
  .pc\:u-border-3 {
    border-width: 3px !important;
    border-style: solid !important;
  }
}