@use '../../0_base' as *;

/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-hokenFaq {
    &__box {
        border-radius: 5px;
        border: 1.5px solid #D9D9D9;

        @include pc {
            border-radius: 10px;
            border-width: 3px;
        }

        & + & {
            margin-top: 15px;

            @include pc {
                margin-top: 40px;
            }
        }

        &__q {
            padding: 16px 15px;
            display: flex;
            column-gap: 10px;
            align-items: center;
            cursor: pointer;
            @include pc {
                padding: 30px;
                column-gap: 20px;
                transition: .3s ease-in-out;
                &:hover {
                    color: #41B4E6;
                }
            }

            &__1 {
                color: #41B4E6;
                font-size: 1.9rem;
                font-weight: bold;
                @include pc {
                    font-size: 2.4rem;
                }
            }

            &__2 {
                flex: 1;
                font-size: 1.4rem;
                font-weight: bold;

                @include pc {
                    font-size: 1.8rem;
                }
            }

            &__icon {
                min-width: 10px;
                width: 10px;
                height: 10px;
                color: #41B4E6;

                svg {
                    fill: currentColor;
                }

                @include pc {
                    min-width: 20px;
                    width: 20px;
                    height: 20px;
                }

                &__plus {
                    width: 100%;
                    height: 100%;
                }
                &__minus {
                    width: 100%;
                    height: 100%;
                    display: none;
                }
            }
        }
        &__a {
            display: none;

            &__inner {
                padding-top: 16px;
                padding-bottom: 16px;
                margin-left: 15px;
                margin-right: 15px;
                display: flex;
                column-gap: 10px;
                border-top: 1.5px dashed #D9D9D9;

                @include pc {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    margin-left: 30px;
                    margin-right: 30px;
                    column-gap: 20px;
                    border-top-width: 3px;
                }
            }

            &__1 {
                color: #FA8246;
                font-size: 1.9rem;
                font-weight: bold;
                @include pc {
                    font-size: 2.4rem;
                }
            }

            &__2 {
                flex: 1;
                color: #222;
                font-size: 1.4rem;

                @include pc {
                    font-size: 1.6rem;
                }
            }

            a {
                color: #253b73;
                text-decoration: underline;
            }
        }
        &.is-open {
            .p-hokenFaq__box__q__icon__plus {
                display: none;
            }
            .p-hokenFaq__box__q__icon__minus {
                display: block;
            }
        }

        .note {
            color: #46483c;
            display: block;
            font-size: 1.3rem;
            text-indent: -1rem;
            padding-left: 1rem;
        }

        .note--large {
            display: block;
            color: #46483c;
            font-size: 1.6rem;
            text-indent: -1.6rem;
            padding-left: 1.6rem;
            margin-top: 25px;
            &.style1 {
                margin-top: 0;
                margin-left: 8rem;
            }
        }
    }

    &__note {
        margin-top: 20px;
        color: #888;
        font-size: 1.2rem;
        @include pc {
            margin-top: 40px;
            font-size: 1.4rem;
        }
    }
}

// コープライフサービスの無料保険相談
.p-hokenFaq02 {
    margin-top: 40px;
    padding: 15px;
    border-radius: 5px;
    border: 1.5px solid #D9D9D9;
    font-size: 1.4rem;

    @include pc {
        margin-top: 80px;
        padding: 30px;
        border-radius: 10px;
        border-width: 3px;
        font-size: 1.6rem;
    }

    &__title {
        margin-bottom: 15px;
        font-size: 2.2rem;
        font-weight: bold;

        @include pc {
            margin-bottom: 30px;
            font-size: 3.2rem;
        }
    }

    &__body {
        @include pc {
            display: grid;
            grid-template-columns: 450px 1fr;
            column-gap: 30px;
            align-items: center;
        }
    }

    &__text {
        color: #222;
    }

    &__button {
        .c-hokenBtn09 {
            @include pc {
                margin-left: auto;
                margin-right: auto;
                width: 500px;
            }
        }
    }
}

// 保険ごとのよくあるご質問
.p-hokenFaq03 {
    margin-top: 40px;
    padding: 15px;
    border-radius: 5px;
    border: 1.5px solid #D9D9D9;
    font-size: 1.4rem;

    @include pc {
        margin-top: 80px;
        padding: 30px;
        border-radius: 10px;
        border-width: 3px;
        font-size: 1.6rem;
    }

    &__title {
        margin-bottom: 15px;
        font-size: 2.2rem;
        font-weight: bold;

        @include pc {
            margin-bottom: 30px;
            font-size: 3.2rem;
        }
    }

    &__text {
        color: #222;
    }

    &__list {
        display: grid;
        row-gap: 15px;

        @include pc {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 50px;
            row-gap: 10px;
        }
    }
}

.p-hokenFaqReturnTop {
    margin-top: 40px;
    text-align: center;

    @include pc {
        margin-top: 80px;
    }

    .c-hokenBtn09 {
        @include pc {
            margin-left: auto;
            margin-right: auto;
            width: 590px;
        }
    }
}

.p-hokenFaqProductContact {
    padding: 15px;
    border-radius: 5px;
    border: 1.5px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    @include pc {
        padding: 30px;
        row-gap: 10px;
        border-radius: 10px;
        border: 3px solid #FA8246;
    }

    &__title {
        font-size: 1.6rem;
        font-weight: 700;
        text-align: center;

        @include pc {
            font-size: 2.4rem;
        }

        span {
            color: #FA8246;
        }
    }

    &__text {
        color: #222;
        font-size: 1.4rem;
        text-align: center;

        @include pc {
            font-size: 1.6rem;
            font-weight: 700;
        }
    }

    &__link {
        padding: 12px 20px;
        width: 100%;
        min-height: 50px;
        display: flex;
        align-items: center;
        column-gap: 5px;
        border: 1.5px solid #FA8246;
        border-radius: 9999px;
        font-size: 1.6rem;
        font-weight: bold;
        justify-content: center;
        color: #FA8246;

        @include pc {
            padding: 0;
            min-height: auto;
            border: none;
            font-size: 2.4rem;
            column-gap: 10px;
            white-space: nowrap;
        }

        &__label {
            padding-left: 25px;
            padding-right: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;

            @include pc {
                padding-left: 0;
                padding-right: 0;
                column-gap: 10px;
            }

            svg {
                min-width: 17px;
                width: 17px;
                height: 17px;

                @include pc {
                    min-width: 34px;
                    width: 34px;
                    height: 34px;
                }
            }
        }

        &__icon {
            min-width: 12px;
            width: 12px;
            height: 12px;

            @include pc {
                display: none;
            }
        }
    }
}
