@use '../../0_base' as *;

/*------------------------------------------------------------
組合員コードの見方
------------------------------------------------------------*/
.p-serviceCode{
	padding-top: 50px;
	@include sp{
		padding: 85px 20px 50px;
	}
	.u-mt50{
		margin-top: 50px;
		@include sp{
			margin-top: 25px;
		}
	}
	.u-mt30{
		margin-top: 30px;
		@include sp{
			margin-top: 20px;
		}
	}
	.u-mt21{
		margin-top: 21px;
		@include sp{
			margin-top: 20px;
		}
	}
	.u-wi306 {
		width: 306px;
	}
	.c-breadcrumb1{
		padding: 15px 0 31px 0;
		a {
			color: #308bbb;
		}
		.u-inner01{
			li{
				&:last-child{
					margin-left: 5px;
				}
			}
		}
	}
	&__title1{
		font-size: 3.2rem;
		line-height: 4.6rem;
		color: $gray01;
		text-align: center;
		font-weight: bold;
		letter-spacing: 1.5px;
		margin-bottom: 48px;
		@include sp{
			font-size: 2.1rem;
			line-height: 1.5;
			margin-bottom: 25px;
		}
		span{
			display: block;
			font-size: 1.4rem;
			line-height: 1;
			opacity: 0.5;
			font-weight: normal;
			letter-spacing: 0.5px;
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
	&__title2{
		display: block;
		font-size: 2.4rem;
		line-height: 3.2rem;
		color: #FFFFFE;
		background: #a4c661;
		padding: 20px 29px 18px;
		width: 100%;
		margin-bottom: 21px;
		@include sp{
			width: calc(100% + 40px);
			margin-left: -20px;
			font-size: 1.9rem;
			line-height: 1.6;
			padding: 13px 20px 12px;
			margin-bottom: 10px;
		}
	}
	&__text1{
		font-size: 1.6rem;
		line-height: 3.2rem;
		color: $gray01;
		@include sp{
			font-size: 1.4rem;
			line-height: 1.6;
		}
	}
	&__text2{
		font-size: 1.8rem;
		line-height: 2.4rem;
		color: #669900;
		padding: 5px 0 7px;
		@include sp{
			font-size: 1.4rem;
			line-height: 1.6;
			padding: 3px 0 4px;
		}
	}
	&__block1{
		display: flex;
		flex-wrap: wrap;
		margin-top: 60px;
		@include sp{
			display: block;
			margin-top: 30px;
		}
		&.mb_100 {
			margin-bottom: 100px;
			@include sp {
				margin-bottom: 50px;
			}
		}
		&--wide {
			justify-content: space-between;

			.p-serviceCode__cont {
				width: 800px;

				@include sp {
					width: 100%;
				}
			}
		}
	}
	&__cont{
		width: 560px;
		padding-right: 29px;
		@include sp{
			width: 100%;
			padding-right: 0;
		}
	}
	.img_flex {
		display: flex;
		width: 45%;
		img {
			padding: 10px;
			@include sp {
				padding: 0;
			}
		}
		@include sp {
			display: block;

		}
	}
	.img_m {
		margin: auto;
	}
	.img_w75 {
		width: 75%;
		margin: 0px 30px;
	}
	&__img{
		width: 560px;
		padding-top: 9px;
		@include sp{
			width: 100%;
			padding-top: 15px;
		}
		img{
			@include sp{
				width: 100%;
				margin: 10px 0;
			}
		}
	}
	&__frame{
		border: 4px solid #eeeeee;
		display: flex;
		margin-top: 40px;
		padding: 26px 35px;
		@include sp{
			border: 2px solid #eeeeee;
			margin-top: 20px;
			padding: 15px 20px;
		}
		span{
			font-size: 2.0rem;
		    line-height: 2.4rem;
		    color: #669900;
		    font-weight: bold;
		    display: block;
		    padding-top: 8px;
		    width: 58px;
		    @include sp{
		    	font-size: 1.6rem;
		    	line-height: 1.6rem;
		    	width: 40px;
		    }
		}
	}
	&__contact{
		background: $glaylight03;
		margin: 50px 0 100px;
		padding: 39px 39px 30px;
		@include sp{
			margin: 25px 0 50px;
			padding: 20px 18px 15px;
			.p-serviceCode__text1{
				color: #fff;
				font-size: 1rem;
			}
			a{
				width: 100%;
				display: block;
				margin-top: 10px;
				background: #4b5870;
			    border: 2px solid #939ba9;
			    padding: 12px 10px 8px;
				.c-contactBtn__txt03{
					display: table;
					margin: 0 auto;
					border: none;
					padding: 0;
					text-align: center;
				}
			}
		}
		h4{
			font-size: 2.0rem;
			line-height: 2.4rem;
			color: #669900;
			border-bottom: 2px solid #669900;
			padding-bottom: 18px;
			@include sp{
				font-size: 1.6rem;
				line-height: 1.6;
				padding-bottom: 10px;
				border-bottom: 1px solid #669900;
			}
		}
	}
	&__number{
		font-size: 3.4rem;
		line-height: 1;
		color: $gray01;
		margin: 24px 0 17px;
		letter-spacing: 1.2px;
		span{
			font-size: 2.2rem;
			margin-right: 10px;
			letter-spacing: 0;
			img{
				transform: translateY(2px);
				margin-left: 2px;
			}
		}
		a {
			color: $gray01;
		}
	}
	.ml {
		margin-left: 20px;
		@include sp {
			margin-left: 0;
		}
	}
}
