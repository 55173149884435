@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　コープの葬祭サービス
------------------------------------------------------------*/
.p-sosaiServiceIndex {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
	.c-breadcrumb1{
		padding: 9px 0 43px;
	}
}
//コープの葬祭サービス
.p-sosaiServiceIndex01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//神奈川、山梨、静岡でのお葬式
.p-sosaiServiceIndex02 {
	margin-top: -30px;
	padding: 107px 0 120px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 53px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	&__content01 {
		margin-top: 53px;
		&__box {
			width: 373px;
			height: 384px;
			margin-right: 1px;
			border-top: solid 2px;
			background-color: #ffffff;
			text-align: center;
			position: relative;
			&:after{
				width: 20px;
			    height: 20px;
			    content: '';
			    position: absolute;
			    bottom: 5px;
			    right: 5px;
			    background-size: cover;
			}
			&--yamanashi {
				border-top-color: $sosaiYamanashiColor01;
				&:after{
				    background-image: url(../img/common/icon_button_sankaku02_blue02.png);
				}
			}
			&--kanagawa {
				border-top-color: $sosaiKanagawaColor01;
				&:after{
				    background-image: url(../img/common/icon_button_sankaku02_blue.png);
				}
			}
			&--shizuoka {
				border-top-color: $sosaiSizuokaColor01;
				&:after{
				    background-image: url(../img/common/icon_button_sankaku02_red.png);
				}
			}
			&:last-child {
				margin: 0;
			}
			&__img {
				width: 290px;
				height: 201px;
				margin: 0 auto;
				border-bottom: dotted 1px $gray07;
				position: relative;
				img {
					position: absolute;
					top: calc(50% + 2px);
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}
			h3 {
				margin: 34px 0 31px;
				font-size: 2.0rem;
				font-weight: 500;
				color: #000;
				@include mincyo;
				span {
					font-size: 3.2rem;
					line-height: 1;
					display: block;
				}
				img {
					margin: 8px auto 0;
					display: block;
				}
			}
			.c-sosaiBtn06{
				padding: 4px 0;
				a{
					&:before{
						margin-right: 2px;
						transform: translateX(-7px);
					}
				}
			}
		}

	}
}

//全国のコープ葬祭サービス
.p-sosaiServiceIndex03 {
	padding: 105px 0 120px 0;
	&__txt {
		text-align: center;
		a {
			text-decoration: underline;
			color: $gray08;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	&__btn {
		margin-top: 54px;
		a {
			width: 354px;
			margin: 0 auto;
			padding: 18px 0 17px 31px;
			display: block;
			font-size: 1.8rem;
			font-weight: bold;
			color: $sosaiNaviColor02;
			border: 2px solid $sosaiNaviColor02;
			border-radius: 5px;
			text-align: center;
			&:before {
				background-image: url(../img/sosai/icon_sosai03.png);
				background-size: contain;
				background-repeat: no-repeat;
				content: '';
				display: inline-block;
				width: 32px;
				height: 32px;
				margin: 0 8px -11px -40px;
			}
		}
	}
}

@include sp {
	.p-sosaiServiceIndex {
		font-size: 1.4rem;
	}
	//コープの葬祭サービス　sp
	.p-sosaiServiceIndex01 {
		min-width: 100%;
		// margin-bottom: 52px;
		padding: 32px 0 33px;
	}

	//神奈川、山梨、静岡でのお葬式 sp
	.p-sosaiServiceIndex02 {
		margin-top: 0;
		padding: 50px 20px;
		p {
			font-size: 1.4rem;
			line-height: 1.6;
		}
		.c-sosaiTitle02b{
			margin-bottom: 34px;

		}
		&__content01 {
			margin-top: 30px;
			&__box {
				width: 100%;
				height: auto;
				margin-bottom: 30px;
				padding-bottom: 22px;
				&--yamanashi, &--kanagawa {
					.p-sosaiServiceIndex02__content01__box__img {
						padding: 43px 69px;
					}
				}
				&--shizuoka{
					.p-sosaiServiceIndex02__content01__box__img {
						padding: 10px 106px;
					}
				}
				&__img {
					width: 100%;
					height: auto;
					padding: 20px;
					img {
						margin: 0 auto;
						display: block;
						position: static;
						transform: none;
					}
				}
				h3 {
					margin: 14px 0;
					font-size: 1.5rem;
					span {
						font-size: 2.3rem;
						margin-top: 2px;
					}
					img{
						margin: 5px auto 0;
					}
				}
				.c-sosaiBtn06{
					max-width: 85%;
					a{
						padding: 11px 15px;
					}
				}
			}

		}
	}

	//全国のコープ葬祭サービス
	.p-sosaiServiceIndex03 {
		padding: 50px 20px 60px;
		&__txt{
			text-align: left;
			font-size: 1.4rem;
			line-height: 1.6;
		}
		&__btn {
			margin-top: 24px;
			a {
				width: 100%;
				padding: 12px 0 10px 31px;
				font-size: 1.5rem;
				border: 1px solid $sosaiNaviColor02;
				&:before {
					width: 20px;
					height: 20px;
					margin: 0 5px -4px -36px
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
