@use '../../0_base' as *;

/*------------------------------------------------------------
特定商取引法に基づく表記
------------------------------------------------------------*/
.p-serviceTokushoho {
	padding: 52px 0 69px;
	@include sp {
		padding: 85px 20px 70px;
	}
	.c-breadcrumb1{
		padding-bottom: 32px;
		a {
			color: $linkColor01;
		}
	}
	&__title {
		margin-bottom: 53px;
		text-align: center;
		font-size: 3.2rem;
		color: $gray01;
		line-height: 1.35;
		@include sp {
			margin-bottom: 30px;
			font-size: 2.1rem;
		}
		span {
			display: block;
			font-size: 1.4rem;
			font-weight: normal;
			opacity: 0.5;
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
	&__title02 {
		font-size: 3.2rem;
		color: $gray01;
		padding: 35px 40px 29px;
		background: url("../img/service/bg03.jpg");
		position: relative;
		margin-bottom: 45px;
		@include sp {
			font-size: 2.1rem;
			width: calc(100% + 40px);
			margin-left: -20px;
			padding: 35px 20px 29px;
			line-height: 1.6;
			margin-bottom: 20px;
		}
		&:before {
			content: '';
			width: 100%;
			height: 4px;
			background: url("../img/service/line_colorful01_pc.jpg");
			position: absolute;
			top: 0;
			left: 0;
			@include sp {
				background-size: 120px;
			}
		}
	}
	&__title03 {
		font-size: 2rem;
		color: #669900;
		border-bottom: 2px solid #a4c661;
		position: relative;
		padding: 0 0 0 20px;
		margin-bottom: 23px;
		line-height: 2.2;
		@include sp {
			font-size: 1.8rem;
			line-height: 1.6;
			padding: 0 0 0 10px;
			margin-bottom: 20px;
		}
		&:before {
			content: '';
			width: 6px;
			height: 30px;
			background: #a4c661;
			position: absolute;
			top: 7px;
			left: 0;
			@include sp {
				top: 4px;
				width: 4px;
				height: 20px;
			}
		}
	}
	&__txt {
		margin-bottom: 23px;
		@include sp {
			margin-bottom: 15px;
		}
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}
	&__list {
		margin: 3px 0 0 0;
		padding-left: 20px;
		@include sp {
			padding-left: 10px;
		}
		li {
			font-size: 1.6rem;
			color: $gray01;
			padding-left: 19px;
			position: relative;
			margin-bottom: 4px;
			line-height: 2;
			@include sp {
				font-size: 1.4rem;
				padding-left: 10px;
				line-height: 1.6;
			}
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				background: #669900;
				border-radius: 100px;
				position: absolute;
				top: 7px;
				left: 0;
				@include sp {
					width: 6px;
					height: 6px;
				}
			}
		}
		a {
			color: $linkColor01;
			text-decoration: underline;
		}
	}
}