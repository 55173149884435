@use '../0_base' as *;

/*------------------------------------------------------------
side06（くらしのサービス）
------------------------------------------------------------*/

.c-side06 {
	.c-side06__box01 {
		width: 240px;
		margin-bottom: 23px;
		font-size: 1.6rem;
		font-weight: bold;
		background-color: #ffffff;
		border: solid 1px $glaylight01;
		dt {
			padding: 15px 0 10px 20px;
			font-size: 1.8rem;
			color: $kurashiTextColor03;
			background-color: $kurashiNaviColor03;
			&:before {
				width: 24px;
				height: 26px;
				margin: -5px 5px 0 0;
				content: '';
				background-image: url("../img/common/icon_cart02.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			span {
				width: 79px;
				font-size: 2.6rem;
				text-align: right;
				display: inline-block;
				vertical-align: middle;
			}
		}
		dd {
			padding: 19px 20px 22px;
			.c-side06__btn {
				width: 100%;
				height: 100%;
				padding: 20px 0;
				font-size: 1.8rem;
				text-align: center;
				display: block;
				color: #ffffff;
				background-color: $kurashiBtnColor01;
				box-shadow: 0 3px 0 $kurashiBtnColor02;
				position: relative;
				transition: 0.3s ease-in-out;
				@include radius(3px);
				&:hover {
					box-shadow: none;
					opacity: 1;
		            transform: translate(0, 3px);
		        }
				&:before {
					width: 20px;
					height: 20px;
					margin: -3px 10px 0 0;
					content: '';
					background-image: url("../img/common/icon_maru-sankaku03.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
				&:after {
					width: calc(100% - 4px);
					height: calc(100% - 4px);
					content: '';
					border: dashed 1px #ffffff;
					position: absolute;
					top: 2px;
					left: 2px;
					@include radius(3px);
				}
			}
		}
	}

	.c-side06__title {
		width: 240px;
		margin-bottom: 20px;
		padding-bottom: 7px;
		font-size: 1.8rem;
		font-weight: bold;
		color: $kurashiTextColor03;
		border-bottom: solid 3px $kurashiColor01;
		&.c-side06__title--2 {
			margin: 0;
		}
	}

	.c-side06__search {
		width: 240px;
		margin-bottom: 20px;
		position: relative;
		input[type="text"] {
			width: 100%;
			height: 40px;
			padding: 0 45px 0 10px;
			font-size: 1.4rem;
			line-height: 2;
			@include radius(4px);
			border: solid 1px $subColor05;
		}
		input[type="submit"] {
			width: 40px;
			height: 40px;
			display: block;
			border: none;
			background-color: $subColor05;
			background-image: url("../img/common/icon_search04.png");
			-webkit-background-size: 20px;
			background-size: 20px;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			top: 0;
			right: 0;
			@include ct;
			@include radius(0 4px 4px 0);
		}
	}
	.c-side06Navi {
		margin-bottom: 25px;
	}

	.c-side__bnr{
		margin-top: -3px;
	}
}

/*
------------------------------------------------------------*/
