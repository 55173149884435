@use '../0_base' as *;

/*------------------------------------------------------------
line
------------------------------------------------------------*/
.c-lineDotted01 {
	width: 100%;
	height: 0;
	margin: 20px 0;
	border: none;
	border-bottom: dotted 1px $gray07;
}




/*
------------------------------------------------------------*/
