@use '../../0_base' as *;

.p-pokke221031 {
  font-weight: bold;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

  .c-mainvisual__img {
    margin: calc((40/750)* 100vw) 0 0 calc((40/750)* 100vw);
    border-radius: calc((20/750)* 100vw) 0 0 calc((20/750)* 100vw);
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .c-mainvisual__inner {
    background: #8CB340;
    padding: calc((134/750)* 100vw) calc((40/750)* 100vw) calc((50/750)* 100vw);
    text-align: center;
    margin-top: calc((-80/750)* 100vw);
    color: #fff;
  }

  .c-mainvisual__text01 {
    font-size: calc((38/750)* 100vw);
    line-height: 1;
    line-height: calc((60/750)* 100vw);
    letter-spacing: -0.1em;
    margin: 0 calc((-10/750)* 100vw) calc((20/750)* 100vw);
    white-space: nowrap;
  }

  .c-mainvisual__text02 {
    width: calc((242/750)* 100vw);
    font-size: calc((40/750)* 100vw);
    line-height: 1;
    color: #F29E4A;
    background: #FFFB84;
    padding: calc((17/750)* 100vw) calc((11/750)* 100vw) calc((15/750)* 100vw);
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      bottom: calc((-16/750)* 100vw);
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: calc((15/750)* 100vw) solid transparent;
      border-right: calc((15/750)* 100vw) solid transparent;
      border-top: calc((16/750)* 100vw) solid #FFFB84;
    }
  }

  .c-mainvisual__title {
    font-size: calc((54/750)* 100vw);
    line-height: 1;
    margin: calc((32/750)* 100vw) 0 calc((30/750)* 100vw);
  }

  .c-mainvisual__text03 {
    padding: calc((18/750)* 100vw) calc((20/750)* 100vw) calc((20/750)* 100vw);
    margin: 0 auto;
    font-size: calc((24/750)* 100vw);
    border: calc((2/750)* 100vw) solid #fff;
    line-height: 1;

    &2 {
        margin: calc((14/750)* 100vw) 0 calc((-12/750)* 100vw);
        display: block;
    }

    &-1 {
    font-size: calc((40/750)* 100vw);
    line-height: 1.5;
    }

    &-2 {
    font-size: calc((22/750)* 100vw);
    font-weight: 500;
    line-height: 1;
    }

    &-3 {
    font-size: calc((34/750)* 100vw);
    line-height: 1.6;
    display: block;
    }

    &.left{
        text-align: left;
    }
    }

  .c-mainvisual__text04 {
    background: #FCFBF5;
    padding: calc((49/750)* 100vw) calc((24/750)* 100vw);
    font-size: calc((34/750)* 100vw);
    line-height: calc((50/750)* 100vw);
    color: #000000;
    white-space: nowrap;
  }

  .c-mainvisual__text05 {
    padding: calc((38/750)* 100vw) calc((28/750)* 100vw);
    font-size: calc((34/750)* 100vw);
    line-height: calc((50/750)* 100vw);
    color: #FDFA87;
    text-align: left;
  }

  .l-container {
    padding: 0 calc((40/750)* 100vw);
  }

  .c-title01 {
    position: relative;
    color: #8CB340;
    text-align: center;
    font-size: calc((36/750)* 100vw);
    line-height: calc((54/750)* 100vw);
    margin-bottom: calc((40/375)* 100vw);

    &::after {
      content: "";
      position: absolute;
      bottom: calc((-26/750)* 100vw);
      left: 50%;
      transform: translateX(-50%);
      width: calc((60/750)* 100vw);
      height: calc((8/750)* 100vw);
      background: #8CB340;
    }

    &__tit {
      font-size: calc((30/750)* 100vw);
      line-height: 1;
      text-transform: uppercase;
      display: block;
      font-family: 'Roboto', sans-serif;
      margin-bottom: calc((18/750)* 100vw);
    }
  }

  .c-title02 {
    text-align: center;
    font-size: calc((26/375)* 100vw);
    line-height: calc((38/375)* 100vw);
    padding: 1px 0 1px;
  }

  .c-text01 {
    position: relative;
    font-size: calc((37/750)* 100vw);
    line-height: 1.5;
    padding: calc((28/750)* 100vw) calc((40/750)* 100vw) calc((34/750)* 100vw);
    text-align: center;
    background: #F29E4A;
    color: #fff;
    margin-bottom: calc((55/750)* 100vw);

    &__1 {
      display: block;

      & + .c-text01__1 {
        margin-top: calc((11/750)* 100vw);
      }
    }

    &__2 {
      font-size: calc((40/750)* 100vw);
    }

    &::before {
      content: "";
      position: absolute;
      bottom: calc((-15/750)* 100vw);
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: calc((15/750)* 100vw) solid transparent;
      border-right: calc((15/750)* 100vw) solid transparent;
      border-top: calc((16/750)* 100vw) solid #F29E4A;
    }
  }

  .c-text02 {
    font-size: calc((38/750)* 100vw);
    line-height: calc((52/750)* 100vw);
    text-align: center;
    color: #000000;
    margin-bottom: calc((41/750)* 100vw);
  }

  .c-text03 {
    position: relative;
    font-size: calc((30/750)* 100vw);
    line-height: calc((38/750)* 100vw);
    text-align: center;
    color: #8CB340;
    padding: 0 calc((80/750)* 100vw);
    margin-bottom: calc((36/750)* 100vw);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-size: calc((53/750)* 100vw) calc((81/750)* 100vw) !important;
      width: calc((53/750)* 100vw);
      height: calc((81/750)* 100vw);
    }

    &::before {
      background: url("/img/kurashi/lp/pokke210920/icon-titleL.svg") no-repeat center;
      left: 0;
    }

    &::after {
      background: url("/img/kurashi/lp/pokke210920/icon-titleR.svg") no-repeat center;
      right: 0;
    }
  }

  .c-text04 {
    font-size: calc((26/750)* 100vw);
    color: #fff;
    background: #000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: calc((2/750)* 100vw) calc((30/750)* 100vw);
    margin: 0 auto calc((20/750)* 100vw);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .c-text05 {
    font-size: calc((28/750)* 100vw);
    line-height: calc((40/750)* 100vw);
    text-align: center;
    font-weight: 500;

    & + .c-text05 {
      margin-top: calc((10/750)* 100vw);
    }

    & + .c-text04 {
      margin-top: calc((50/750)* 100vw);
    }

    & + .c-text06 {
      margin-top: calc((20/750)* 100vw);
    }
  }

  .c-text06 {
    font-size: calc((24/750)* 100vw);
    text-align: center;
    font-weight: 500;
    margin-bottom: calc((22/750)* 100vw);
  }

  .c-listimg {
    margin-top: calc((60/750)* 100vw);

    & + .c-text03 {
      margin-top: calc((80/750)* 100vw);
    }
  }

  .c-img {
    & + .c-img {
      margin-top: calc((20/750)* 100vw);
    }
  }

  .c-blocktext {
    background: #FCFBF5;
    padding: calc((48/750)* 100vw) calc((20/750)* 100vw) calc((27/750)* 100vw);
    text-align: center;

    & + .c-blocktext {
      margin-top: calc((20/750)* 100vw);
    }

    & + .c-text05 {
      margin-top: calc((40/750)* 100vw);
    }

    &01 {
      font-size: calc((34/750)* 100vw);
      line-height: 1;
      color: #000000;
    }

    &02 {
      margin-top: calc((25/750)* 100vw);
      font-size: calc((28/750)* 100vw);
      line-height: 1;
      color: #8CB340;
    }

    &03 {
      font-size: calc((28/750)* 100vw);
      font-weight: 500;
      line-height: 1;
      margin-top: calc((10/750)* 100vw);
    }

    &__number {
      position: relative;
      top: calc((4/750)* 100vw);
      font-size: calc((56/750)* 100vw);
      line-height: 1;
    }
  }

  .c-blcktext01 {
    padding: calc((40/750)* 100vw);
    font-size: calc((28/750)* 100vw);
    font-weight: 500;
    line-height: calc((40/750)* 100vw);
    background: #FCFBF5;

    & + .c-blcktext01 {
      margin-top: calc((20/750)* 100vw);
    }
  }

  .c-listtext {
    margin-bottom: calc((60/750)* 100vw);

    &__item {
      position: relative;
      font-size: calc((34/750)* 100vw);
      line-height: calc((50/750)* 100vw);
      padding: calc((30/750)* 100vw) calc((40/750)* 100vw) calc((30/750)* 100vw) calc((130/750)* 100vw);
      border-bottom: 1px dashed #000000;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        left: calc((40/750)* 100vw);
        width: calc((50/750)* 100vw);
        height: calc((50/750)* 100vw);
        background: url("/img/kurashi/lp/pokke210920/icon-checkbox.svg") no-repeat center;
        background-size: calc((50/750)* 100vw) calc((50/750)* 100vw);
      }

      &:first-child {
        border-top: 1px dashed #000000;
      }
    }

    &__small {
      font-size: calc((30/750)* 100vw);
      font-weight: 300;
    }

    &--circle {
      margin-bottom: calc((60/750)* 100vw);

      .c-listtext__item {
        font-size: calc((30/750)* 100vw);
        line-height: calc((38/750)* 100vw);
        padding: calc((30/750)* 100vw) calc((40/750)* 100vw);
        border-bottom: 1px dashed #000000;

        &:before {
          content: none;
        }
      }

      .c-listtext__title {
        display: block;
        margin-bottom: 5px;
        padding-left: calc((37/750)* 100vw);
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0px;
          -webkit-transform: translateY(-57%);
          -ms-transform: translateY(-57%);
          transform: translateY(-57%);
          width: calc((25/750)* 100vw);
          height: calc((25/750)* 100vw);
          border-radius: 50%;
          background: #8CB340;
        }
      }

      .c-listtext__text {
        font-size: calc((28/750)* 100vw);
        line-height: calc((40/750)* 100vw);
        font-weight: 500;
      }
    }
  }

  .c-button02 {
    margin-top: calc((40/750)* 100vw);

    a {
      border-radius: calc((10/750)* 100vw);
      color: #fff;
      font-size: calc((30/750)* 100vw);
      line-height: 1;
      height: calc((120/750)* 100vw);
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      background: #8CB340 url("/img/kurashi/lp/pokke221031/icon-arrow.svg") no-repeat center right calc((40/750)* 100vw)/calc((18/750)* 100vw) calc((30/750)* 100vw);
    }
  }

  .c-total-cont {
    text-align: center;
  }

  .c-total {
    color: #000000;
    background: #FCFBF5;
    text-align: center;
    padding: calc((40/750)* 100vw);

    &__row {
      & + .c-total__row {
        margin-top: calc((30/750)* 100vw);
        white-space: nowrap;
      }
    }

    &__text01 {
      font-size: calc((34/750)* 100vw);
      line-height: 1;
    }

    &__text02 {
      font-size: calc((28/750)* 100vw);
      font-weight: 500;
    }

    &__text03 {
      font-size: calc((30/750)* 100vw);
      line-height: calc((52/750)* 100vw);
      margin: calc((30/750)* 100vw) 0 calc((26/750)* 100vw);
      text-align: center;
    }

    &__text04 {
      background: #F29E4A;
      height: calc((98/750)* 100vw);
      padding: 0 calc((20/750)* 100vw);
      color: #fff;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: calc((25/750)* 100vw);
      line-height: 1;
      white-space: nowrap;

      &__number {
        font-size: calc((50/750)* 100vw);

        span {
          font-size: calc((25/750)* 100vw);
          position: relative;
          top: calc((-2/750)* 100vw);
        }
      }
    }

    &__text05 {
      font-size: calc((26/750)* 100vw);
      line-height: 1;
      font-weight: 500;
      margin: calc((28/750)* 100vw) calc((20/750)* 100vw);
    }

    &__text06 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-of-type {
        border-top: 1px dashed #000000;
        margin-top: calc((20/750)* 100vw);
        padding-top: calc((20/750)* 100vw);
      }
    }

    &__label,
    &__unit {
      font-size: calc((28/750)* 100vw);
      line-height: 1;
    }

    &__price {
      font-size: calc((34/750)* 100vw);

      &.cl-green {
        color: #8CB340;
      }
    }

    &__text07 {
      border: calc((2/750)* 100vw) solid #8CB340;
      padding: calc((24/750)* 100vw);
      color: #8CB340;
      font-size: calc((34/750)* 100vw);
      line-height: calc((52/750)* 100vw);
      margin-top: calc((30/750)* 100vw);
      text-align: center;
    }

    &__text08 {
      font-size: calc((26/750)* 100vw);
      line-height: 1;
      font-weight: 500;
      text-align: right;
      margin-top: calc((20/750)* 100vw);
    }

    & + .c-text04 {
      margin-top: calc((50/750)* 100vw);
    }
  }

  .t-pink {
    color: #F70D55;
    font-size: calc((30/750)* 100vw);
  }

  .t-green {
    color: #8CB340;
    font-size: calc((30/750)* 100vw);
  }

  .c-point {
    text-align: center;

    &__text01 {
      font-size: calc((34/750)* 100vw);
      line-height: calc((52/750)* 100vw);
      margin: calc((30/750)* 100vw) 0 calc((15/750)* 100vw);
    }

    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc((34/750)* 100vw);
    }
  }

  .c-lst01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: calc((35/375)* 100vw);

    &__img {
      text-align: center;

      img {
        width: 100%;
      }
    }

    &__item {
      width: calc(100% / 3);
      padding: 0 calc((10/375)* 100vw);
      margin-bottom: calc((18/375)* 100vw);
    }

    &__text {
      font-size: calc((13/375)* 100vw);
      line-height: calc((15/375)* 100vw);
      margin-top: calc((10/375)* 100vw);
      color: #000000;
      text-align: center;
    }
  }

  &-ft {
    text-align: center;

    &__text {
      background: #8CB340;
      padding: calc((35/375)* 100vw) 0;
      font-size: calc((14/375)* 100vw);
      line-height: calc((23.8/375)* 100vw);
      font-weight: 500;
      color: #fff;

      a {
        color: #fff;
        opacity: 1;
      }

      .bold {
        display: block;
        font-weight: bold;
        font-size: calc((15/375)* 100vw);
        margin-bottom: calc((10/375)* 100vw);
      }
    }

    &__copyright {
      background: #333333;
      color: #fff;
      font-size: calc((10/375)* 100vw);
      padding: calc((19/375)* 100vw) 0 calc((18/375)* 100vw);
    }
  }

  &__01 {
    padding: calc((80/750)* 100vw) 0 calc((60/750)* 100vw);
  }

  &__02 {
    padding: calc((60/750)* 100vw) 0;

    .p-pokke221031 {
      .c-blocktext {
        & + .c-text05 {
          margin-top: calc((30/750)* 100vw);
        }
      }
    }
  }

  &__03 {
    padding: calc((60/750)* 100vw) 0;

    .c-blocktext01 {
      line-height: calc((52/750)* 100vw);
    }
  }

  &__04 {
    padding: calc((60/750)* 100vw) 0 calc((140/750)* 100vw);
  }
}

.c-point-area {
  font-size: calc((30/750)* 100vw);
  color: #fff;
  background: #000000;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  margin: 5px 33px 5px 0;
  width: -webkit-fill-available;
}

.c-point-price {
  width: 50%;
  white-space: nowrap;
}
