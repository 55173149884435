@use '../../0_base' as *;

/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート） 完了
------------------------------------------------------------*/

.p-ticketCartComplete {
	background-color: #ffffff;
	padding-top: 30px;
}

//見出し
.p-ticketCartComplete01 {
	margin: 0 0 30px;
	.c-formTitle {
		color: $ticketNaviColor02;
	}
}

//本文
.p-ticketCartComplete02 {
	margin-bottom: 80px;
	//コンポーネント　c-formComplete
	.c-formComplete {
		color: $ticketNaviColor02;
	}
}

@include sp {
	.p-ticketCartComplete {
		padding: 0 20px;
	}

	//見出し　ｓｐ
	.p-ticketCartComplete01 {
		margin: 27px 0 25px;
		.c-formTitle {
			margin-bottom: 17px;
		}
	}

	//本文　ｓｐ
	.p-ticketCartComplete02 {
		margin-bottom: 40px;
		//コンポーネント　c-formComplete
		.c-cartBtn {
			margin-top: 40px;
		}
	}
}

// フォーム
.p-ticketCartComplete{
	.c-formComplete {
		&__inquiry {
			margin: 0;

			&__content {
				width: 480px;
				margin: 54px auto 78px auto;
				@include sp {
					width: 100%;
					margin: 26px 0 20px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
