@use '../0_base' as *;

/*------------------------------------------------------------
footer07(葬祭)
------------------------------------------------------------*/
.c-foot07 {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding-top: 70px;
	background-color: $subColor01;
	border-top: solid 1px $keycolor01;
}
.c-foot07NaviArea {
	margin-bottom: 70px;
}
.c-foot07Link {
	width: 208px;
	.c-foot07Logo {
		width: 200px;
		margin-bottom: 30px;
		padding-top: 5px;
	}
	dl {
		dt {
			margin-bottom: 15px;
			padding-bottom: 10px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 1.5;
			border-bottom: solid 1px $keycolor01;
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
		dd {
			margin-bottom: 10px;
			padding-left: 35px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -15px;
				content: '';
				background-image: url("../img/common/icon_arrow-green01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:last-child{
				padding-top: 26px;
				a{
					padding-left: 4px;
				}
			}
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
	}
}
.c-foot07Navi {
	width: 912px;
	dl {
		width: 208px;
		margin-left: 20px;
		dt {
			margin-bottom: 15px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 2;
			border-bottom: solid 1px $sosaiColor01;
			&.c-foot07Navi__noBorder {
				margin-bottom: 5px;
				font-size: 1.4rem;
				border: none;
			}
			a {
				color: $gray01;
				&:hover {
					color: $sosaiColor01;
				}
			}
			span {
				color: $gray01;
			}
		}
		dd {
			margin-bottom: 10px;
			padding: 0 15px 0 35px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -15px;
				content: '';
				background-image: url("../img/common/icon_sankaku_purple.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $sosaiColor01;
				}
			}
		}
		&.mt10 {
			margin-top: 10px;
		}
	}
}

.c-sousaiFlortingBnr {
	width: 60px;
	height: 364px;
	background-color: #ffffff;
	position: fixed;
	top: calc((100% - 270px) / 2);
	right: 0;
	z-index: 9990;
	.c-sousaiFlortingBnr__1 {
		width: 100%;
		height: 166px;
		background-color: $sosaiNaviColor03;
		background-image: url("../img/common/icon_point01.png");
		-webkit-background-size: 20px;
		background-size: 20px;
		background-repeat: no-repeat;
		background-position: center 20px;
	}
	.c-sousaiFlortingBnr__2 {
		width: 100%;
		height: 240px;
		background-color: $sosaiBtnColor01;
		background-image: url("../img/common/icon_file01.png");
		-webkit-background-size: 18px;
		background-size: 18px;
		background-repeat: no-repeat;
		background-position: center 20px;
	}
	a {
		width: 100%;
		height: 100%;
		padding-top: 50px;
		font-size: 1.6rem;
		font-weight: bold;
		text-align: center;
		display: block;
		color: #ffffff;
		span {
			-webkit-writing-mode: tb-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: tb-rl;
			@include sp{
				-webkit-writing-mode: rl-tb;
				-ms-writing-mode: rl-tb;
				writing-mode: rl-tb;
			}
		}
	}
}

@include sp {
	.c-foot07 {
		min-width: 100%;
		padding-top: 0;
		padding-bottom: 50px;
		background-color: #ffffff;
		border: none;
	}

	.c-sousaiFlortingBnr {
		width: 100%;
		height: 50px;
		top: auto;
		bottom: 0;
		@include dib;
		.c-sousaiFlortingBnr__1 {
			width: 50%;
			height: 100%;
			background-image: none;
			a {
				&:before {
					width: 20px;
					height: 20px;
					background-image: url("../img/common/icon_point01.png");
				}
			}
		}
		.c-sousaiFlortingBnr__2 {
			width: 50%;
			height: 100%;
			background-image: none;
			a {
				font-size: 1.2rem;
				padding: 4px 0;
				&:before {
					width: 18px;
					height: 20px;
					background-image: url("../img/common/icon_file01.png");
				}
			}
		}
		a {
			width: 100%;
			height: 100%;
			padding: 0;
			font-size: 1.4rem;
			line-height: 3.57;
			&:before {
				margin: -3px 5px 0 0;
				content: '';
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}


// aniamtion
//-----------------------------------------------------------------
.page-sosai__index {
	.is-move {
		//transition-delay: 7s;
		transition-delay: 2s;
	}
}
.js-move {
	@media screen and (min-width: $breakpoint01+1) {
		right: -60px;
		&.is-move {
			transition-duration: 0.7s;
			right: 0;
		}
	}
}