@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　家族葬とは
------------------------------------------------------------*/
.p-sosaiKnowledgeStyleFamily {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//家族葬とは
.p-sosaiKnowledgeStyleFamily01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//家族葬とは 本文
.p-sosaiKnowledgeStyleFamily02 {
	margin-bottom: 113px;
	padding-top: 19px;
	.c-sosaiTitle02b{
		margin-bottom: 52px;
		span{
			&:after{
				margin: 2px auto 0;
			}
		}
	}
	&__content01 {
		min-height: 240px;
		margin-bottom: 60px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 8px;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
}

//家族葬のメリット
.p-sosaiKnowledgeStyleFamily03 {
	margin-bottom: 106px;
	padding: 106px 0 120px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 52px;
		padding-left: 9px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	ol {
		margin-top: 49px;
		padding-top: 53px;
		li {
			width: 353px;
			margin-right: 30px;
			padding: 32px 30px 32px;
			background-color: #ffffff;
			border: solid 2px $sosaiNaviColor02;
			&:nth-child(3n) {
				margin-right: 0;
			}
			span {
				margin-top: -83px;
				margin-bottom: 39px;
				display: block;
				text-align: center;
			}
			h3 {
				margin-bottom: 27px;
				font-size: 2.6rem;
				font-weight: 500;
				line-height: 3rem;
				color: $sosaiNaviColor02;
				text-align: center;
				display: flex;
    			align-items: center;
   				justify-content: center;
				@include mincyo;
			}
			p{
				line-height: 3rem;
				padding: 0 11px;
			}
		}
	}
}

//家族葬の注意点と事前準備
.p-sosaiKnowledgeStyleFamily04 {
	margin-bottom: 127px;
	.c-sosaiTitle02b{
		margin-bottom: 53px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		padding-left: 28px;
		margin-bottom: 40px;
	}	
	> p {
		margin-bottom: 72px;
		line-height: 3rem;
	}
	.c-sosaiTextBox01{
		> div{
			padding: 36px 40px 32px;
			.c-sosaiTitle10{
				margin-bottom: 15px;
				&:before{
					margin: -5px 10px 0 -30px;
				}
			}
			p{
				line-height: 3rem;
			}
		}
	}
}

//事前に整理・準備が必要なこと
.p-sosaiKnowledgeStyleFamily05 {
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		padding-left: 6px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	&__content01 {
		b {
			font-weight: 700;
		}
		> li {
			padding: 108px 0 73px;
			position: relative;
			&:after {
				width: 30px;
				height: 48px;
				margin: 0 auto;
				content: '';
				background-image: url("../img/component/3arrow02.png");
				-webkit-background-size: cover;
				background-size: cover;
				position: absolute;
				bottom: -24px;
				left: 0;
				right: 0;
				z-index: 10;
			}
			&:nth-child(odd) {
				background-color: $glaylight02;
			}
			&:last-child {
				padding: 106px 0 92px;
				&:after {
					content: none;
				}
			}
			.u-inner01 {
				position: relative;
			}
		}
		&__title {
			margin-bottom: 22px;
			font-size: 3.2rem;
			line-height: 1;
			font-weight: normal;
			color: $gray08;
			padding-left: 65px;
			position: relative;
			@include mincyo;
			img {
				width: 50px;
				margin: 0;
				position: absolute;
				top: -10px;
				left: 0;
			}
		}
		&__txt {
			min-height: 240px;
			padding-right: 460px;
			position: relative;
			p {
				margin-bottom: 20px;
				line-height: 3rem;
				text-align: justify;
				img {
					position: absolute;
					top: -10px;
					right: 0;
				}
			}
		}
	}
}

//関連コンテンツ
.p-sosaiKnowledgeStyleFamily06 {
	padding: 106px 0 140px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 57px;
	}
	.c-sosaiKanrenLink01{
		li{
			margin-right: 30px;
			&:last-child{
				margin-right: 0;
			}
			&:after{
				bottom: 3px;
			}
			img{
				margin-bottom: 17px;
			}
		}
	}
}


@include sp {
	.p-sosaiKnowledgeStyleFamily {
		font-size: 1.6rem;
	}
	//家族葬とは sp
	.p-sosaiKnowledgeStyleFamily01 {
		min-width: 100%;
		margin-bottom: 46px;
		padding: 32px 0 33px;
	}

	//家族葬とは 本文 sp
	.p-sosaiKnowledgeStyleFamily02 {
		margin-bottom: 45px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 30px;
			span{
				&:after{
					margin: 11px auto 0;
				}
			}
		}
		&__content01 {
			margin-bottom: 40px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 16px;
				position: static;
			}
			> p {
				margin-bottom: 10px;
				font-size: 1.4rem;
				line-height: 1.7;
			}
		}
	}
	
	//家族葬のメリット sp
	.p-sosaiKnowledgeStyleFamily03 {
		margin-bottom: 47px;
		padding: 48px 20px 11px;
		.c-sosaiTitle02b{
			margin-bottom: 26px;
			padding-left: 4px;
			span{
				&:after{
					margin: 11px auto 0;
				}
			}
		}
		.u-inner01{
			> p{
				font-size: 1.4rem;
				line-height: 1.7;
				text-align: left;
			}
		}
		ol {
			margin: 24px 0 39px 0;
			padding-top: 20px;
			li {
				width: 100%;
				height: auto;
				margin-bottom: 39px;
				padding: 10px 18px 15px;
				&:last-child {
					margin-bottom: 0;
				}
				span {
					margin-top: -42px;
					margin-bottom: 18px;
					display: block;
					text-align: center;
					img {
						width: 60px;
					}
				}
				h3 {
					margin-bottom: 23px;
					font-size: 2.1rem;
					line-height: 2.5rem;
				}
				p{
					font-size: 1.4rem;
					line-height: 1.7;
					padding: 0;
				}
			}
		}
	}

	//家族葬の注意点と事前準備 sp
	.p-sosaiKnowledgeStyleFamily04 {
		margin-bottom:75px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 27px;
			span{
				&:after{
					margin: 11px auto 0;
				}
			}
		}
		.c-sosaiTitle09{
			font-size: 1.9rem;
			line-height: 2.1rem;
			margin-left: -5px;
			padding-left: 14px;
			margin-bottom: 18px;
		}
		> p {
			margin-bottom: 33px;
			font-size: 1.4rem;
			line-height: 1.7;
			text-align: left;
		}
		.c-sosaiTextBox01{
			> div{
				padding: 15px 20px;
				.c-sosaiTitle10{
					margin-bottom: 4px;
					&:before{
						margin: -5px 5px 0 -25px
					}
				}
				p{
					font-size: 1.4rem;
					line-height: 1.7;
				}
			}
		}
	}

	//事前に整理・準備が必要なこと sp
	.p-sosaiKnowledgeStyleFamily05 {
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			padding: 0 20px;
			span{
				&:after{
					margin: 11px auto 0;
				}
			}
		}
		&__content01 {
			> li {
				padding: 47px 20px 47px;
				&:after {
					width: 30px;
					height: 48px;
				}
				&:nth-child(2){
					padding: 53px 20px 44px;
					.p-sosaiKnowledgeStyleFamily05__content01__title{
						margin-bottom: 24px;
						img{
							top: -4px;
						}
					}
				}
				&:last-child{
					padding: 53px 20px 44px;
					.p-sosaiKnowledgeStyleFamily05__content01__title{
						margin-bottom: 23px;
						img{
							top: -4px;
						}
					}
				}
			}
			&__txt {
				min-height: auto;
				padding-right: 0;
				position: static;
				p {
					margin-bottom: 0px;
					font-size: 1.4rem;
					line-height: 1.7;
					text-align: left;
					img {
						margin: 0 auto 16px;
						display: block;
						position: static;
					}
				}
			}
			&__title {
				margin-bottom: 17px;
				font-size: 2.1rem;
				line-height: 2.3rem;
				padding-left: 36px;
				position: relative;
				img {
					width: 30px;
					margin: 0;
					position: absolute;
					top: -3px;
					left: 0;
				}
			}
		}
	}

	//関連コンテンツ sp
	.p-sosaiKnowledgeStyleFamily06 {
	    padding: 48px 15px 39px;
	    margin-bottom: 59px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiKanrenLink01{
			flex-wrap: wrap;
			li{
				width: calc(50% - 5px);
				margin-bottom: 8px;
				margin-right: 10px;
				float: none;
				&:nth-child(2n){
					margin-right: 0;
				}
				img{
					margin-bottom: 8px;
				}
			}
		}
	}
	
}

/*
------------------------------------------------------------*/
