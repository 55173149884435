@use '../../0_base' as *;

/*------------------------------------------------------------
チケットご利用者アンケート 完了画面
------------------------------------------------------------*/
.p-ticketEnqueteComplete {
	padding: 41px 0 199px;

	@include sp {
		padding: 19px 20px 57px;
	}

  &1 {
		.c-accordion {
			margin-top: 11px;
		}
  }

  &2 {
		.c-step3 {
			margin-top: 43px;
			margin-bottom: 134px;

			@include sp {
				margin-top: 20px;
				margin-bottom: 59px;
			}
		}
  }

	&3 {
		margin-top: 116px;

		@include sp {
			margin-top: 36px;
		}
	}
}
