@use '../../0_base' as *;

/*------------------------------------------------------------
保険商品のご提案にあたって
------------------------------------------------------------*/
.p-hokenRecommend {
    &__title02 {
        padding: 10px 15px;
        background-color: #E4F7FF;
        border-radius: 5px;
        font-size: 2.2rem;
        @include pc {
            padding: 80px 0 0;
            border-radius: 10px;
            font-size: 4rem;
        }
    }
    &__title03 {
        border-bottom: 1.5px solid #41B4E6;
        font-size: 2.2rem;
        font-weight: 700;
        @include pc {
            border-width: 3px;
            font-size: 3.2rem;
        }
    }
	&__txt {
        font-size: 1.4rem;
        margin: 20px 0px;
        @include pc {
            font-size: 1.6rem;
            margin: 20px 0;
        }
	}
	&__list {
		li {
			font-size: 1.6rem;
			display: flex;
			span {
				display: block;
			}
			@include sp {
				font-size: 1.4rem;
			}
		}
	}
	&__list02 {
		> li {
            font-size: 1.6rem;
			@include pc {
                font-size: 1.8rem;
			}
		}
		ol {
            padding-left: 15px;
            @include pc {
                padding-left: 30px;
            }
			li {
                display: flex;
                column-gap: 2.5px;
                font-size: 1.4rem;
				@include pc {
                    column-gap: 5px;
                    font-size: 1.6rem;
				}
			}
		}
	}

    &__title04 {
        display: flex;
        align-items: baseline;
        column-gap: 2.5px;
        color: #41B4E6;
        font-size: 1.6rem;
        font-weight: 700;
        @include pc {
            column-gap: 5px;
            font-size: 1.8rem;
        }

        &::before {
            content: "■";
        }
    }

	&__table {
        width: 100%;
        border-collapse: collapse;
        line-height: 1.5;
        @include sp {
            border: 1px solid #D9D9D9;
            border-top: none;
        }
        @include pc {
            border-bottom: 1px solid #D9D9D9;
        }
        th, td {
            padding: 15px 20px;
            text-align: left;
            @include sp {
                border-top: 1px solid #D9D9D9;
            }
            @include pc {
                padding: 30px 40px;
            }
        }
        tr, th ,td {
            @include sp {
                display: block;
            }
        }
        tr {
            @include pc {
                border-top: 1px solid #D9D9D9;
            }
        }
        th {
            background-color: #E4F7FF;
            color: #41B4E6;
            font-size: 1.6rem;
            font-weight: bold;
            @include pc {
                width: 300px;
                font-size: 1.8rem;
            }
        }
        td {
            font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }
        }
	}
	&__box {
        padding: 15px;
        border: 1.5px solid #41B4E6;
        border-radius: 5px;
        @include pc {
            padding: 30px;
            border-width: 3px;
            border-radius: 10px;
        }
	}

    &__title05 {
        padding-bottom: 10px;
        border-bottom: 1px solid #41B4E6;
        font-size: 1.8rem;
        font-weight: 700;
        @include pc {
            padding-bottom: 20px;
            font-size: 2rem;
        }
    }
}
.recommend-navi {
    grid-template-columns: initial;
    margin: 80px 0px;
}
.recomend-content {
    padding: 50px 60px;
    background: white;
    @include sp {
        padding: 20px;
    }
}

.bg-E4F7FF {
    background: #E4F7FF;
}
.f {
    font-size: 1.8rem;
}

.tacenter {
    text-align: center;
}
.pb-100 {
    padding-bottom:100px;
  }

.mb10 {
    margin-bottom: 10px;
}