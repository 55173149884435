@use '../../0_base' as *;

/*------------------------------------------------------------
保険　相談予約申込みフォーム（所属生協確認）
------------------------------------------------------------*/
.p-hokenSoudan03{
    &__content01 {
        &__title {
            display: flex;
            column-gap: 5px;
            align-items: center;
            font-size: 1.9rem;
            font-weight: 700;
            @include pc {
                column-gap: 10px;
                font-size: 2.4rem;
            }

            &::before {
                content: "";
                min-width: 7.5px;
                width: 7.5px;
                height: 7.5px;
                background-color: #41B4E6;
                border-radius: 100%;
                @include pc {
                    min-width: 12px;
                    width: 12px;
                    height: 12px;
                }
            }
        }
        &__confirm {
            display: grid;
            row-gap: 10px;
            @include pc {
                grid-template-columns: 1fr 380px;
                grid-template-rows: auto 1fr;
                gap: 20px;
            }
            &__image {
                img {
                    width: 100%;
                }
                @include pc {
                    grid-column: 2/3;
                    grid-row: 1/3;
                }
            }
        }
    }
}

/*
------------------------------------------------------------*/
