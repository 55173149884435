@use 'vars' as *;

//============================================
// サイト幅でセンタリング
//============================================
@mixin center01{
  width: $baseWidth01;
  margin-left: auto;
  margin-right: auto;
}

//============================================
// clearfix
//============================================
@mixin clear {
  *zoom: 1;
  &:after{
    display: block;
    content: "";
    clear: both;
  }
}

//============================================
// フォント
//============================================
@mixin mincyo() {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@mixin gosick() {
  font-family: 'Roboto',"游ゴシック", YuGothic,"Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}

@mixin yu_gosick() {
  font-family: YuGothic,'Yu Gothic',sans-serif;
}

@mixin Noto() {
  font-family: 'Noto Sans Japanese';
}

@mixin Garamond() {
  font-family: 'Garamond Classico';
}

@mixin DIN() {
  font-family: 'DIN';
}

//============================================
// breakpoint
//============================================
@mixin sp {
  @media screen and (max-width: #{$breakpoint01}) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: #{$breakpoint01 + 1}) {
    @content;
  }
}

@mixin hover {
	@media screen and (min-width: #{$breakpoint01 + 1}) {
			&:hover {
					@content;
			}
	}
}

//============================================
// テキスト飛ばし(クリアテキスト)
//============================================
@mixin ct {
	text-indent:1000em;
	white-space:nowrap;
	overflow:hidden;
}

//============================================
// display-flex
//============================================
@mixin dfl {
    display: flex;
}

//============================================
// display-inlineblock
//============================================
@mixin dib {
    letter-spacing: -.4em;
    > * {
        display: inline-block;
        letter-spacing: normal;
    }
}

//============================================
// display-table
//============================================
@mixin dtbl {
    display: table;
    > * {
        display: table-cell;
    }
}

//============================================
// border-radius
//============================================
@mixin radius($size:5px) {
    -webkit-border-radius: $size;
    border-radius: $size;
}

//============================================
//transform プロパティを使って CSS アニメーションを実行した際に要素がガタつくのを防ぐ
//============================================
@mixin tr3d0{
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

@mixin min-w($breakpoint) {
	@media screen and (min-width: #{$breakpoint}) {
		@content;
	}
}

@mixin max-w($breakpoint) {
	@media screen and (max-width: #{$breakpoint}) {
		@content;
	}
}

@mixin step3Item($color, $imgactive, $imgactivesp, $imgactivesp2, $imgactivepc, $imgactivepc2) {
	padding: 7px 20px 5px 21px;
	height: 60px;
	width: 30%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	position: relative;

	@include pc {
		min-width: 378px;
		background: url(../img/hoken/common/step3-bg.png) no-repeat center right / contain;
	}

	@include sp {
		overflow: hidden;
		width: 29.3%;
		padding: 7px 4px 5px 20px;
		height: 50px;
		background-color: #F3F3F3;
		position: relative;
	}

	&:nth-child(1) {
		@include sp {
			background: transparent;
			z-index: 0;
		}

		&:after {
			@include sp {
				content: '';
				height: 100%;
				width: 100%;
				pointer-events: none;
				right: 0;
				top: 0;
				background: #f3f3f3;
				position: absolute;
			}
		}
	}

	&.is-active {
		z-index: 1;
		font-weight: 700;
		color: #fff;

		@include pc {
			background-image: url(../img/hoken/common/#{$imgactive}) !important;
		}

		@include sp {
			background: $color;
		}

		&:nth-child(1) {
			@include sp {
				z-index: 0;
			}

			&:after {
				@include sp {
					background-color: $color;
				}
			}

			&+.c-step3__item {
				@include sp {
					background-color: transparent;
					z-index: 1;
				}

				&:before {
					@include sp {
						content: '';
						height: 100%;
						width: 100%;
						pointer-events: none;
						left: 27px;
						top: 0;
						background-color: #F3F3F3;
						position: absolute;
						z-index: 1;
					}
				}

				&:after {
					@include sp {
						content: '';
						height: 100%;
						width: 100%;
						pointer-events: none;
						left: 0;
						top: 0;
						background: url(../img/hoken/common/#{$imgactivesp}) no-repeat center left / 27px auto;
						position: absolute;
					}
				}

				&+.c-step3__item {
					@include sp {
						background-color: transparent;
						z-index: 1;
					}

					&:before {
						@include sp {
							content: '';
							height: 100%;
							width: 100%;
							pointer-events: none;
							left: 0;
							top: 0;
							background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left / 27px auto;
							position: absolute;
							z-index: 1;
						}
					}

					&:after {
						@include sp {
							content: '';
							height: 100%;
							width: 100%;
							pointer-events: none;
							left: 27px;
							top: 0;
							background-color: #F3F3F3;
							position: absolute;
						}
					}
				}
			}
		}

		&:nth-child(2) {
			@include sp {
					background: transparent;
				}

				&:before {
					@include sp {
						content: '';
						height: 100%;
						width: 100%;
						pointer-events: none;
						left: 0;
						top: 0;
						background: url(../img/hoken/common/#{$imgactivesp2}) no-repeat center left / 27px auto;
						position: absolute;
					}
				}

				&:after {
					@include sp {
						content: '';
						height: 100%;
						width: 100%;
						pointer-events: none;
						left: 27px;
						top: 0;
						background-color: $color;
						position: absolute;
					}
				}

				&+.c-step3__item {
					@include sp {
						background: transparent;
						z-index: 3;
					}

					&:after {
						@include sp {
							content: '';
							height: 100%;
							width: 100%;
							pointer-events: none;
							left: 27px;
							top: 0;
							background-color: #F3F3F3;
							position: absolute;
						}
					}

					&:before {
						@include sp {
							content: '';
							height: 100%;
							width: 100%;
							pointer-events: none;
							left: 0;
							top: 0;
							background: url(../img/hoken/common/#{$imgactivesp}) no-repeat center left / 27px auto;
							position: absolute;
							z-index: 1;
						}
					}
				}
		}

		&:last-child {
			@include sp {
				z-index: 4;
				background-color: transparent;
			}

			&:before {
				@include sp {
					content: '';
					height: 100%;
					width: 100%;
					pointer-events: none;
					left: 0;
					top: 0;
					background: url(../img/hoken/common/#{$imgactivesp2}) no-repeat center left / 27px auto;
					position: absolute;
				}
			}

			&:after {
				@include sp {
					content: '';
					height: 100%;
					width: 100%;
					pointer-events: none;
					left: 27px;
					top: 0;
					background-color: $color;
					position: absolute;
				}
			}
		}
	}

	&:nth-child(2) {
		padding-left: 15px;

		@include pc {
			background-image: url(../img/hoken/common/step3-bg1.png);
			margin-left: -4px;
		}

		@include sp {
			width: 33.5%;
			padding-left: 31px;
			z-index: 1;
			background: transparent;
		}

		&:before {
			@include sp {
				content: '';
				height: 100%;
				width: 100%;
				pointer-events: none;
				left: 0;
				top: 0;
				background: url(../img/hoken/common/step3-bg-gray.svg) no-repeat center left / 27px auto;
				position: absolute;
			}
		}

		&:after {
			@include sp {
				content: '';
				height: 100%;
				width: 100%;
				pointer-events: none;
				left: 27px;
				top: 0;
				background: #f3f3f3;
				position: absolute;
			}
		}

		&.is-active {
			@include pc {
				background-image: url(../img/hoken/common/#{$imgactivepc}) !important;
			}
		}
	}

	&:last-child {
		padding-right: 38px;
		margin-left: -24px;
		background-position: left center;
		width: auto;
		position: relative;
		left: 18px;

		@include pc {
			background-image: url(../img/hoken/common/step3-bg2.png);
			flex: 1;
			min-width: 390px;
		}

		@include sp {
			margin-left: -1px;
			padding-right: 8px;
			left: 1px;
			width: 37.2%;
		}

		&.is-active {
			@include pc {
				background-image: url(../img/hoken/common/#{$imgactivepc2}) !important;
			}
		}
	}
}

// ===================================================================
// @mixin & @extend　定義
// ===================================================================

//フォントサイズ用ミックスイン
@mixin fz($size:12px, $important:'') {
    font-size: $size + px + $important;
    font-size: ($size / 10) * 1rem + $important;
}

// clearfixミックスイン
@mixin clearfix {
    &:after {
        content: "";
        clear: both;
        display: block;
    }
}

// 上下左右中央配置
@mixin center {
　　text-align: center;
　　position: relative;
　　top: 50%;
　　-webkit-transform: translateY(-50%);
　　-ms-transform: translateY(-50%);
　　transform: translateY(-50%);
}

//三角アイコン
@mixin arrow_icon($color, $border-width, $border-color, $left,$top){
    position:relative;
    padding: 0 0 0 15px;
    &::before{
        content:"";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $border-width;
        border-color: $border-color;
        position:absolute;
        left:$left;
        top: $top;
    }
}

