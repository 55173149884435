@use '../0_base' as *;

/*------------------------------------------------------------
footer03（チケット）
------------------------------------------------------------*/

.c-foot03 {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding-top: 72px;
	background-color: $subColor01;
	border-top: solid 1px $keycolor01;
}
.c-foot03NaviArea {
	margin-bottom: 67px;
}
.c-foot03Link {
	width: 265px;
	.c-foot03Logo {
		width: 200px;
		margin-bottom: 37px;
		padding-top: 8px;
	}
	dl {
		dt {
			margin-bottom: 10px;
			padding-bottom: 9px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 1.5;
			border-bottom: solid 1px $keycolor01;
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
		dd {
			margin-bottom: 4px;
			padding-left: 35px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -15px;
				content: '';
				background-image: url("../img/common/icon_arrow-green01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:last-child{
				padding-top: 26px;
				a{
					padding-left: 4px;
				}
			}
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
	}
}
.c-foot03Navi {
	width: 550px;
	margin-left: 20px;
	display: block;
	.c-foot03Navi__menuTitle {
		margin-bottom: 8px;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: 2;
		color: $gray01;
		border-bottom: solid 1px $ticketColor01;
		padding-bottom: 6px;
	}
	dl {
		margin-left: 10px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		min-height: 300px;
		dt {
			width: 255px;
			margin-bottom: 1px;
			font-size: 1.4rem;
			font-weight: bold;
			line-height: 2;
			a {
				color: $gray01;
				&:hover {
					color: $ticketColor02;
				}
			}
		}
		dd {
			width: 255px;
			margin-bottom: 4px;
			padding: 0 10px 0 23px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 7px 0 -13px;
				content: '';
				background-image: url("../img/common/icon_arrow-pink02.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $ticketColor02;
				}
			}
		}
	}
}

.c-foot03Navi2 {
	width: 285px;
	padding-left: 20px;
	dl {
		width: 265px;
		dt {
			margin-bottom: 10px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 2;
			color: $gray01;
			border-bottom: solid 1px $ticketColor01;
			padding-bottom: 6px;
		}
		dd {
			margin-bottom: 4px;
			padding: 0 10px 0 33px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 5px 0 -12px;
				content: '';
				background-image: url("../img/common/icon_arrow-pink02.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $ticketColor02;
				}
			}
			&.c-foot03Navi2__lineBleak {
				height: 3.1em;
				@include ct;
				&:before {
					content: none;
				}
			}
			&.c-foot03Navi2__lineBleak02 {
				height: 1.6em;
				@include ct;
				&:before {
					content: none;
				}
			}
		}
	}
}


@include sp {
	.c-foot03 {
		min-width: 100%;
		padding-top: 0;
		padding-bottom: 50px;
		background-color: #ffffff;
		border: none;
	}

	.c-ticketFlortingBnr {
		width: 100%;
		height: 55px;
		overflow: hidden;
		position: fixed;
		top: auto;
		bottom: 0;
		z-index: 9990;
		@include dib;
		a {
			width: 100%;
			height: 100%;
			padding: 10px;
			font-size: 1.2rem;
			font-weight: bold;
			letter-spacing: -0.1em;
			color: #ffffff;
			text-align: center;
			vertical-align: top;
			background-color: $ticketBtnColor01;
			border: solid 3px;
			display: inline-flex;
		    align-items: center;
		    justify-content: center;
			&:nth-child(1) {
				// color: $gray03;
				// line-height: 2.64;
				// border-color: $ticketBtnColor02;
				// &:before {
				// 	width: 18px;
				// 	height: 22px;
				// 	margin: -3px 5px 0 0;
				// 	content: '';
				// 	background-image: url("../img/common/icon_door01.png");
				// 	-webkit-background-size: cover;
				// 	background-size: cover;
				// 	display: inline-block;
				// 	vertical-align: middle;
				// }
				line-height: 1.32;
				background-color: $ticketBtnColor03;
				border-color: $ticketBtnColor04;
				span {
					width: 29px;
					height: 28px;
					background-image: url("../img/common/icon_cart01.png");
					-webkit-background-size: 28px;
					background-size: 28px;
					background-repeat: no-repeat;
					background-position: 0 2px;
					margin-right: 2px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
