@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス　特集商品一覧
------------------------------------------------------------*/
.p-kurashiFeature {
	background-color: #ffffff;
}

//ページタイトルエリア
.p-kurashiFeature01 {
	margin-bottom: 32px;
	&__content01 {
		&__img {
			margin-bottom: 32px;
			img {
				width: 100%;
			}
		}
		&__text {
			p {
				font-size: 1.6rem;
				font-weight: 500;
				line-height: 1.75;
				color: $kurashiTextColor01;
			}
		}
		.c-kurashiTitle02{
			margin-bottom: 14px;
		}
	}
}

//一覧
.p-kurashiFeature02 {
	margin-bottom: 52px;
	&__content01 {
		//コンポーネント　c-card2
		margin-bottom: 30px;
		//コンポーネント　c-kurashiPager01
		.c-kurashiPager01 {
			margin-top: 10px;
		}
	}
}

//その他のサービス
.p-kurashiFeature03 {
	margin-bottom: 100px;
	&__slider {
		padding: 0 20px;
		&__item {
			width: calc(33.3333% - 20px);
			margin: 0 10px;
			&__title {
				margin-top: 5px;
				font-size: 1.5rem;
				font-weight: bold;
				display: block;
			}
			a {
				color: $kurashiTextColor03;
				text-decoration: underline;
				display: block;
				&:hover {
					text-decoration: none;
					opacity: 1;
				}
			}
			img {
				width: 100%;
				height: 138px;
				object-fit: cover;
				font-family: 'object-fit: cover;'; /*IE対策*/
			}
		}
		.slick-arrow {
			width: 11px;
			height: 16px;
			margin-top: -23px;
			&:before {
				content: none;
			}
			&.slick-prev {
				background-image: url("../img/common/icon_arrow-orange02.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				opacity: 1 !important;
				z-index: 10;
				left: 0;
				transform: rotate(180deg);
			}
			&.slick-next {
				background-image: url("../img/common/icon_arrow-orange02.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				opacity: 1 !important;
				z-index: 10;
				right: 0;
				transform: none;
			}
		}
	}
}

@include sp {
	//ページタイトルエリア sp
	.p-kurashiFeature01 {
		margin-bottom: 25px;
		&__content01 {
			&__img {
				margin-bottom: 20px;
			}
			&__text {
				padding: 0 20px;
				p{
					font-size: 1.4rem;
					line-height: 2rem;
				}
			}
			.c-kurashiTitle02{
				letter-spacing: 2px;
				margin-bottom: 16px;
			}
		}
	}

	//一覧 sp
	.p-kurashiFeature02 {
		margin-bottom: 26px;
		&__content01 {
			//コンポーネント　c-card2
			margin-bottom: 20px;
			padding: 0 20px;
			//コンポーネント　c-kurashiPager01
			.c-kurashiPager01 {
				margin-top: 0;
			}
		}
	}

	//その他のサービス sp
	.p-kurashiFeature03 {
		padding: 0 20px;
		margin-bottom: 90px;
		&__slider {
			padding: 0;
			&__item {
				width: 100%;
				margin: 0 0 10px 0;
				padding: 0 0 10px 0;
				border-bottom: dashed 1px $gray03;
				&__title {
					width: 60%;
					margin: 0;
					padding-left: 10px;
					font-size: 1.4rem;
				}
				a {
					width: 100%;
					display:  flex;
					align-items: center;
					img {
						width: 44%;
						height: 69px;
					}
				}
			}
			.slick-arrow {
				width: 30px;
				height: 30px;
				margin: 0;
				&.slick-prev {
					background-image: url("../img/common/icon_maru-sankaku01.png") !important;
					top: auto;
					bottom: -40px;
					left: 15%;
				}
				&.slick-next {
					background-image: url("../img/common/icon_maru-sankaku01.png") !important;
					top: auto;
					bottom: -40px;
					right: 15%;
				}
			}
			.slick-dots {
				width: 60%;
				margin: 0 auto;
				bottom: -29px;
				left: 0;
				right: 0;
				li {
					width: 8px;
					height: 8px;
					margin: 0 5px;
					padding: 1px;
					background-color: $glaylight01;
					@include radius(50%);
					button {
						width: 8px;
						height: 8px;
						padding: 0;
						@include radius(50%);
						&:before {
							content: none;
						}
					}
					&.slick-active {
						background-color: $kurashiColor01 !important;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
