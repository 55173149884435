@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス　商品申込み・お問い合わせフォーム（カート）　入力・エラー
------------------------------------------------------------*/
.p-kurashiCartInput {
	padding: 37px 0 100px 0;
	background-color: #ffffff;
}

//見出し
.p-kurashiCartInput01 {
	margin-bottom: 22px;
	.c-formTitle {
		color: $ticketNaviColor02;
	}
}

//フォーム
.p-kurashiCartInput02 {
	margin-bottom: 80px;
	&__require {
		margin: 0 10px 0 0;
		display: inline-block;
		vertical-align: middle;
		font-size: 1.1rem;
		font-weight: bold;
		color: #fff;
		background: #f22727;
		padding: 0 5px;
		line-height: 1.6;
		border-radius: 3px;
		@include sp{
			margin-left: 10px;
		}
	}
	&__content01 {
		margin-bottom: 53px;
		&__txt {
			margin-bottom: 35px;
			font-size: 1.6rem;
			color: $gray01;
		}
		&__txt2 {
			margin-bottom: 15px;
			font-size: 1.6rem;
			color: $gray01;
		}
	}
	&__content02 {
		margin-bottom: 50px;
	}
	&__content03 {
		&__back {
			margin-top: 20px;
			font-size: 1.8rem;
			display: block;
			text-align: center;
			color: $linkColor01;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
			&:before {
				width: 7px;
				height: 12px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_arrow-blue06.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}
		}
	}
}


@include sp {
	.p-kurashiCartInput {
		padding: 28px 20px 0 20px;
	}
	//見出し sp
	.p-kurashiCartInput01 {
		margin-bottom: 17px;
		.c-formTitle {
			margin-bottom: 16px;
		}
	}
	//フォーム sp
	.p-kurashiCartInput02 {
		margin-bottom: 37px;
		&__content01 {
			margin-bottom: 30px;
			> p {
				margin-bottom: 17px;
				font-size: 1.3rem;
				line-height: 1.9rem;
			}
			.p-kurashiCartInput02__require {
				margin: -1px 5px 0 0;
			    line-height: 1;
			    font-size: 0.9rem;
			    padding: 2px 5px;
			}
			.p-kurashiFormTable01{
				dl{	
					dd{
						input{
							padding: 11.5px 10px;
						}
					}				
					&:nth-child(3){
						&.u-style01{
							margin-top: 13px;
						}
						dt{
							.c-table2--require{
								margin-left: 6px;
							}
						}
						dd{
							padding: 15px 14px 1px !important;							
							.c-table2--note1{
								margin: 6px 0px 10px;
							}
						}
					}
					&:nth-child(4){
						dd{
							ul{
								li{
									.c-table2--ttl1{
										margin-top: -7px;
									}
								}
							}
						}
					}
					&:nth-child(5){
						dt{
							.c-table2--require{
								margin-left: 2px;
							}
						}
						dd{
							ul{
								li{
									.c-table2--ttl1{
										margin-top: -7px;
									}
								}
							}
						}
					}
					&:nth-child(6){
						dd{
							.c-table2--note1{
								margin: 8px 0 5px;
							}
						}
					}
					&:nth-child(7),
					&:nth-child(8),
					&:nth-child(9),
					&:nth-child(10),
					&:nth-child(12),
					&:nth-child(16),
					&:nth-child(17),
					&:nth-child(18){
						dd{
							padding: 15px 14px 13px !important;	
						}
					}
					&:nth-child(11){
						dd{
							padding: 14px 14px 2px !important;	
						}
					}
					&:nth-child(14){
						dd{
							padding: 10px 14px 3px !important;
							ul{
								li{
									margin-right: 39px;
									margin-bottom: 5px;
									.c-checkbox__text{
										padding-left: 23px;
										&:before{
											width: 14px;
											height: 14px;
										}
										&:after {
											left: 5px;
											height: 9px;
											margin-top: -6px;
										}
									}
								}
							}
						}
					}
					&:nth-child(15){
						dd{
							padding: 10px 14px 4px !important;
							ul{
								li{
									margin-bottom: 5px;
									.c-checkbox__text{
										padding-left: 23px;
										&:before{
											width: 14px;
											height: 14px;
										}
										&:after {
											left: 5px;
											height: 9px;
											margin-top: -6px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&__content02 {
			margin-bottom: 30px;
			.c-kurashiTitle03{
				margin-bottom: 14px;
			}
			.c-checkbox{
				.c-checkbox__text{
					padding-left: 23px;
					&:before{
						top: 0;
						transform: translateY(0);
						width: 14px;
						height: 14px;
					}
					&:after {
						top: 7px;
						transform: translateY(0) rotate(45deg);
						left: 5px;
						height: 9px;
						margin-top: -6px;
					}
				}
			}
		}
		&__content03 {
			.c-cartBtn{
				margin-top: 35px;
			}
			&__back {
				margin-top: 5px;
				font-size: 1.4rem;
				&:before {
					width: 5px;
					height: 9px;
					margin: 0 11px 0 0;
				}
			}
		}
	}
}


//フォーム01
.p-kurashiFormTable01 {
	dl {
		color: $kurashiTextColor01;
		label {
			color: $kurashiTextColor01;
		}
		&:nth-child(3) input {
			width: 280px;
			padding: 0 15px;
		}
		&:nth-child(4), &:nth-child(5){
			li{
				float: left;
			}
			input {
				width: 240px;
				padding: 0 15px;
				margin: 0 28px 0 23px;
			}
		}
		&:nth-child(5) input {
			margin: 0 29px 0 7px;
		}
		&:nth-child(6) input, &:nth-child(7) input{
			padding: 0 15px;
			width: 240px;
		}
		&:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11),&:nth-child(12),&:nth-child(13),&:nth-child(16),&:nth-child(17),&:nth-child(18) {
			input {
				width: 600px;
				padding: 0 15px;
				@include sp{
					width: 100%;
					padding: 10px;
				}
			}
			textarea {
				width: 100%;
				padding: 15px;
				@include sp {
					padding: 10px;
				}
			}
		}
		@include sp {
			&:nth-child(3), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11), &:nth-child(12){
				input {
					width: 100%;
					padding: 10px;
				}
			}
			&:nth-child(4), &:nth-child(5) {
				li{
					float: none;
					display: inline-flex;
					align-items: center;
					width: 100%;
				}
				input {
					width: 94%;
					padding: 10px;
					margin: 0 0 10px 10px;
				}
			}
			&:nth-child(5) input{
				width : 95%;
			}
			textarea {
				line-height: 1.5;
			}
			input {
				height: 38px;
				line-height: 38px;
			}
		}
		.c-table2__radio {
			input:checked + label:before {
				background: $kurashiColor01;
				border: 1px solid $kurashiColor01 !important;
			}
			@include sp {
				li {
					margin-right: 0;
				}
			}
		}
	}
}
/*
------------------------------------------------------------*/
