@use 'vars' as *;
@use 'mixin' as *;

/*------------------------------------------------------------
common
------------------------------------------------------------*/
html {
	font-family: "Roboto", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}
// @media all and (-ms-high-contrast:none) {
// 	// html {
// 	// 	font-family: 'Roboto',"メイリオ", meiryo, sans-serif;
// 	// }
// }
body {
	min-width: 1120px;
	font-weight: 500;
	@include sp {
		min-width: 320px;
	}
}
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
img {
	max-width: 100%;
	height: auto;
}
a {
	text-decoration: none;
	transition: 0.3s ease-in-out;
}
@media (min-width: $breakpoint01 + 1) {
	a[href^="tel:"] {
		cursor: default;
		&:hover {
			opacity: 1;
		}
	}
	.sp-only {
		display: none;
	}
}
@include sp {
	.pc-only {
		display: none;
	}
}

body.serviceHoken {
    min-width: 320px;
    font-weight: normal;

    @include pc {
        min-width: 1200px;
    }
}
