@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　内装リフォームをお考えの方（商品一覧）
------------------------------------------------------------*/
.p-reformInterior {
	background-color: #ffffff;
	.c-breadcrumb1 {
	    padding: 11px 0 48px 0;
	}
}

//内装リフォームをお考えの方
.p-reformInterior01 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior.jpg");
		}
	}
}

//内装リフォームのポイント
.p-reformInterior02 {
	margin-bottom: 109px;
	&__content01 {
		.c-refomeTitle03 {
		    margin-bottom: 32px;
		}
		&__point {
			li {
				min-width: 180px;
				margin-bottom: 64px;
				padding-right: 280px;
				position: relative;
				&:last-child {
					margin: 0;
				}
				h3 {
					margin-bottom: 12px;
					font-size: 2.0rem;
					font-weight: bold;
					color: $reformTextColor01;
					span {
						margin-right: 13px;
						font-size: 2.6rem;
						color: $reformBtnColor08;
						display: inline-block;
					}
				}
				img {
					width: 253px;
					position: absolute;
					top: 7px;
					right: 0;
				}
				p {
					font-size: 1.5rem;
					font-weight: 500;
					line-height: 1.75;
				}
			}
		}
	}
}

//内装メニュー
.p-reformInterior03 {
	margin-bottom: 41px;
	.c-refomeTitle03 {
	    margin-bottom: 32px;
	}
	.p-reformInterior03__content01 {

	}
}


@include sp {

	//内装リフォームをお考えの方 sp
	.p-reformInterior01 {
		margin-bottom: 25px;
		&__content01 {
			.c-refomeTitle02 {
				background-image: url("../img/reform/title_obi_interior_sp.jpg");
			}
		}
	}
	//内装リフォームのポイント sp
	.p-reformInterior02 {
		margin-bottom: 28px;
		&__content01 {
			padding: 0 20px;
			.c-refomeTitle03 {
			    margin-bottom: 35px;
			}
			&__point {
				li {
					min-width: auto;
					margin-bottom: 10px;
					padding: 0;
					position: static;
					h3 {
						margin-bottom: 9px;
						font-size: 1.6rem;
						span {
							margin: 0 0 3px 0;
							font-size: 1.6rem;
							display: block;
						}
					}
					img {
						margin: 0 auto 11px auto;
						display: block;
						position: static;
					}
					p{
						font-size: 1.4rem;
						line-height: 2.1rem;
					}
				}
			}
		}
	}
	//内装メニュー sp
	.p-reformInterior03 {
		margin-bottom: 13px;
		.p-reformInterior03__content01 {
			padding: 0 20px;
			.c-refomeTitle03 {
			    margin-bottom: 36px;
			}
			.c-reformList01{
				li{
					width: 46%;
					margin: 0 8% 17px 0 !important;
					&:nth-child(2n + 2){
						margin: 0 0 17px 0 !important;
					}
					img{
						margin: 0 0 7px 0;
					}
					h3{
						a{
							&:before{
								margin: -3px 4px 0 -10px;
							}
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
