@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　無料見積もり・お問い合わせフォーム（カート） 入力・エラー
------------------------------------------------------------*/
.p-reformContactInput {
	padding: 49px 0 100px 0;
	background-color: #ffffff;
}

//見出し
.p-reformContactInput01 {
	margin-bottom: 24px;
	.c-formTitle {
		color: $reformTextColor02;
	}
}

//フォーム
.p-reformContactInput02 {
	margin-bottom: 80px;
	&__require {
		margin: 0 10px 0 0;
		display: inline-block;
		vertical-align: middle;
		font-size: 1.1rem;
		font-weight: bold;
		color: #fff;
		background: #f22727;
		padding: 0 5px;
		line-height: 1.6;
		border-radius: 3px;
	}
	&__content01 {
		margin-bottom: 50px;
		.c-formTelInquiry {
			margin-bottom: 28px;
		}
		&__txt {
			margin-bottom: 36px;
			font-size: 1.6rem;
			color: $gray01;
			@include sp{
				font-size: 1.3rem;
				line-height: 1.5;
			}
		}
		&__txt2 {
			margin-bottom: 15px;
			font-size: 1.6rem;
			color: $gray01;
		}
	}
	&__content02 {
		margin-bottom: 50px;
	}
	&__content03 {
		&__back {
			margin-top: 20px;
			font-size: 1.8rem;
			display: block;
			text-align: center;
			color: $reformNaviColor02;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
			&:before {
				width: 7px;
				height: 12px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_arrow-blue06.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}
		}
	}
}


@include sp {
	.p-reformContactInput {
		padding: 30px 20px 0 20px;
	}

	//見出し sp
	.p-reformContactInput01 {
		margin-bottom: 20px;
		.c-formTitle {
			margin-bottom: 27px;
		}
	}

	//フォーム sp
	.p-reformContactInput02 {
		margin-bottom: 35px;
		&__content01 {
			margin-bottom: 25px;
			.c-formTelInquiry {
				margin-bottom: 30px;
			}
			> p {
				margin-bottom: 16px;
				font-size: 1.3rem;
			}
			.p-reformContactInput02__require {
				margin: -3px 6px 0 0;
				font-size: 1rem;
				padding: 0 4px;
			}
			.c-checkbox input[type=checkbox] + label {
				padding-left: 23px;
				&:before {
					width: 14px;
					height: 14px;
				}
				&:after {
					left: 5px;
					margin-top: -6px;
					height: 9px;
				}
			}
			.c-table2__checkbox01 li {
				margin: 0 40px 5px 0;
			}
		}
		&__content02 {
			margin-bottom: 30px;
			.c-refomeTitle05 {
				font-size: 1.6rem;
				margin-bottom: 13px;
				padding-bottom: 9px;
			}
			.c-checkbox input[type=checkbox] + label {
				padding-left: 23px;
				&:before {
					top: 2px;
					transform: translateY(0);
					width: 14px;
					height: 14px;
				}
				&:after {
					top: 10px;
					left: 4px;
					transform: translateY(0) rotate(45deg);
					height: 9px;
				}
			}

		}
		&__content03 {
			margin-bottom: 35px;
			&__back {
				margin-top: 2px;
				font-size: 1.4rem;
				&:before {
					width: 5px;
					height: 9px;
					margin: 0 11px 0 0;
				}
			}
			.c-cartBtn{
				margin: 36px auto 0;
			}
		}
	}
}


//フォーム01
.p-reformFormTable01 {
	dl {
		&:nth-child(2) input {
			width: 280px;
			padding: 0 15px;
		}
		&:nth-child(3), &:nth-child(4){
			li{
				float: left;
			}
			input {
				width: 240px;
				padding: 0 15px;
				margin: 0 28px 0 23px;
			}
		}
		&:nth-child(4) input {
			margin: 0 29px 0 7px;
		}
		&:nth-child(5) input, &:nth-child(6) input{
			padding: 0 15px;
			width: 240px;
		}
		&:nth-child(14) dd{
			.c-table2__checkbox02 {
				display: flex;
				flex-wrap: wrap;
				li {
					width: 28%;
					margin-right: 0;
				}
			}

		}
		&:nth-child(18) {
			input {
				width: 80px;
				margin: 0 10px;
				padding: 0 15px;
			}
		}
		&:nth-child(7),	&:nth-child(8),	&:nth-child(9),	&:nth-child(10),&:nth-child(11),&:nth-child(12),&:nth-child(15),,&:nth-child(16),&:nth-child(19) {
			input {
				width: 600px;
				padding: 0 15px;
			}
			textarea {
				width: 100%;
				padding: 15px;
			}
		}
		@include sp {
			&:nth-child(2), &:nth-child(5), &:nth-child(6),	&:nth-child(7),
			&:nth-child(8),	&:nth-child(9),	&:nth-child(10), &:nth-child(11),
			&:nth-child(12) ,&:nth-child(15) ,&:nth-child(16),&:nth-child(19){
				input, textarea {
					width: 100%;
					padding: 11px 15px;
				}
			}
			&:nth-child(18) {
				input {
					padding: 10px;
				}
			}
			&:nth-child(3),  &:nth-child(4) {
				li{
					float: none;
					display: inline-flex;
					align-items: center;
					width: 100%;
					margin-bottom: 12px;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
				input {
					width: 94%;
					padding: 12px;
					margin: 0 0 0 7px;
				}
			}
			&:nth-child(4) input{
				width : 95%;
			}
			&:nth-child(6) dd, &:nth-child(7) dd, &:nth-child(8) dd, &:nth-child(9) dd,
			&:nth-child(11) dd, &:nth-child(15) dd, &:nth-child(16) dd, &:nth-child(18) dd, &:nth-child(19) dd{
				padding: 13px 14px 14px !important;
			}
			&:nth-child(13),  &:nth-child(14) {
				dd {
					padding: 10px 14px 4px !important;
				}
			}
			&:nth-child(14) dd{
				.c-table2__checkbox02 {
					display: block;
					li{
						width: 100%;
						margin-bottom: 5px;
					}

				}
			}
			.c-table2__checkbox01 li{
				margin-bottom: 5px;
			}
			textarea {
				line-height: 1.5;
			}
			input {
				height: 38px;
				line-height: 38px;
			}
		}
	}
	.c-table2__radio {
		input:checked + label:before {
			background: $reformColor01;
			border: 1px solid $reformColor01 !important;
		}
		@include sp {
			li {
				margin-right: 0;
			}
		}
	}
}

/*
------------------------------------------------------------*/
