@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービスTOP
------------------------------------------------------------*/

.p-kurashiTop {
	background-color: #ffffff;
	.l-contentServiceSide{
		.c-side06{
			.u-hover01{
				&:hover{
					opacity: 1;
				}
			}
		}
	}
}

//くらしTOPスライダー
.p-kurashiTop01 {
	min-width: $baseWidth01;
	margin-bottom: 60px;
	overflow: hidden;
	position: relative;
	.p-kurashiTop01__sliderBord {
		.slick-dotBox {
			.slick-dots {
				position: static;
				li {
					margin: 0;
					button {
						&:before {
							font-size: 10px;
							color: $glaylight01;
							opacity: 1 !important;
						}
					}
					&.slick-active {
						button {
							&:before {
								color: $kurashiColor01;
							}
						}
					}
				}
			}
		}
		.slick-arrow2 {
			width: 40px;
			height: 40px;
			margin: 0 10px;
			display: inline-block;
			vertical-align: middle;
			background-image: url("../img/common/icon_maru-sankaku01.png") !important;
			-webkit-background-size: cover !important;
			background-size: cover !important;
			&.slick-prev2 {
				transform: rotate(180deg);
			}
		}
	}

	&__PrevNext{
		position: absolute;
		top: 240px;
		left: calc(50% - 560px);
		width: 1120px;
		@include sp{
			display: none;
		}
		.slick-arrow3{
			width: 60px;
			height: 60px;
			position: absolute;
			top: calc(50% - 30px);
			cursor: pointer;
			&.slick-prev3{
				background-image: url("../img/common/icon_maru-sankaku01.png");
				-webkit-background-size: cover;
				background-size: cover;
				opacity: 1;
				left: -49px;
				z-index: 10;
				transform: rotate(180deg);
			}
			&.slick-next3{
				background-image: url("../img/common/icon_maru-sankaku01.png");
				-webkit-background-size: cover;
				background-size: cover;
				opacity: 1;
				right: -49px;
				z-index: 10;
				transform: none;
			}
		}
	}
}
.p-kurashiSlider {
	display: none;
	&.slick-initialized {
		display: block;
	}
	.slick-slide {
		width: 1120px;
		height: 480px;
		margin: 0 20px;
		position: relative;
		overflow: hidden;
		border: solid 1px $glaylight02;
		@include radius(20px);
		&:before {
			width: 100%;
			height: 100%;
			content: '';
			background-color: rgba($subColor06,0.5);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
		}
		a {
			width: 100%;
			height: 100%;
			display: table;
			-webkit-background-size: cover;
			background-size: cover;
			background-position: center center;
		}
	}
	.slick-center {
		&:before {
			content: none;
		}
		.p-kurashiSlider__text {
			opacity: 1;
		}
	}
	.slick-arrow {
		width: 60px;
		height: 60px;
		display: none !important;
		&:before {
			content: none;
		}
		&.slick-prev {
			background-image: url("../img/common/icon_maru-sankaku01.png") !important;
			-webkit-background-size: cover !important;
			background-size: cover !important;
			opacity: 1 !important;
			top: calc(50% - 30px);
			left: 18%;
			z-index: 10;
			transform: rotate(180deg);
		}
		&.slick-next {
			background-image: url("../img/common/icon_maru-sankaku01.png") !important;
			-webkit-background-size: cover !important;
			background-size: cover !important;
			opacity: 1 !important;
			top: calc(50% - 30px);
			right: 18%;
			z-index: 10;
			transform: none;
		}
	}
	&__text {
		padding: 50px;
		color: $kurashiTextColor04;
		font-weight: bold;
		display: table-cell;
		vertical-align: middle;
		background-image: url("../img/kurashi/index_mv_mask.png");
		background-repeat: no-repeat;
		transition: opacity 0.3s ease-in-out;
		opacity: 0;
		&__title {
			width: 300px;
			margin-bottom: 25px;
			padding-left: 25px;
			display: block;
			font-size: 4.4rem;
			line-height: 1.2;
			letter-spacing: 0.1em;
			position: relative;
			&:before {
				width: 5px;
				height: 100%;
				content: '';
				background-color: $kurashiColor01;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		&__excerpt {
			width: 300px;
			display: block;
			font-size: 1.6rem;
			letter-spacing:0.05em;
			line-height: 1.75;
		}
	}
}

//人気サービス
.p-kurashiTop02 {
	margin-bottom: 42px;
	.p-kurashiTop02__content01 {
		//コンポーネント　c-card1
		.c-card1{
			.u-hover01{
				&:hover{
					opacity: 1;
				}
			}
		}
	}
}

//サービスメニュー
.p-kurashiTop03 {
	margin-bottom: 36px;
	.p-kurashiTop03__content01 {
		.p-kurashiTop03__content01__select {
			margin-bottom: 30px;
			padding: 20px;
			background-color: $glaylight09;
			p {
				font-size: 1.6rem;
				font-weight: bold;
				color: $subColor05;
				&:before {
					width: 30px;
					height: 30px;
					margin: -5px 10px 0 0;
					content: '';
					background-image: url("../img/common/icon_search05.png");
					-webkit-background-size: 66%;
					background-size: 66%;
					background-repeat: no-repeat;
					background-position: center center;
					display: inline-block;
					vertical-align: middle;
					@include radius(50%);
				}
			}
			.p-kurashiTop03__content01__select__wrap {
				position: relative;
				&:before {
					width: 7px;
					height: 10px;
					content: '';
					background-image: url("../img/common/icon_arrow-gray05.png");
					-webkit-background-size: cover;
					background-size: cover;
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
					pointer-events: none;
					position: absolute;
					top: calc((100% - 7px) / 2);
					right: 10px;
				}
				select {
					width: 465px;
					padding: 5px 10px;
					color: $gray02;
					background-color: #ffffff;
					-webkit-appearance: button;
					-moz-appearance: button;
					appearance: button;
					border: solid 1px $subColor05;
					@include radius(4px);
					&::-ms-expand {
						display: none;
					}
					option {
						font-size: 1.6rem;
						line-height: 2;
						color: $gray01;
						display: block;
					}
				}
			}
		}
		.p-kurashiTop03__sliderBord {
			.slick-dotBox2 {
				.slick-dots {
					position: static;
					li {
						margin: 0;
						button {
							&:before {
								font-size: 10px;
								color: $glaylight01;
								opacity: 1 !important;
							}
						}
						&.slick-active {
							button {
								&:before {
									color: $kurashiColor01;
								}
							}
						}
					}
				}
			}
			.slick-arrow3 {
				width: 40px;
				height: 40px;
				margin: 0 10px;
				display: inline-block;
				vertical-align: middle;
				background-image: url("../img/common/icon_maru-sankaku01.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				&.slick-prev3 {
					transform: rotate(180deg);
				}
			}
		}
		//コンポーネント　c-card2
	}
}

//お掃除カレンダー
.p-kurashiTop04 {
	margin-bottom: 40px;
	.p-kurashiTop04__content01 {
		width: 100%;
		height: 200px;
		overflow: hidden;
		background-image: url("../img/kurashi/carender_bg.jpg");
		-webkit-background-size: cover;
		background-size: cover;
		border: solid 2px $kurashiBtnColor03;
		position: relative;
		transition: 0.3s ease-in-out;
		@include radius(6px);
		h2 {
			margin-bottom: 6px;
			font-size: 2.4rem;
			font-weight: bold;
			color: $kurashiTextColor04;
			&:before {
				width: 30px;
				height: 30px;
				margin: -5px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_carender01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		p {
			width: 370px;
			font-size: 1.6rem;
			line-height: 1.5;
			color: $kurashiTextColor04;
		}
		.c-kurashiBtn01 {
			position: absolute;
			bottom: 20px;
			right: 20px;
			background: #FFF;
			span{
				transition: 0.3s ease-in-out;
				background: #fa9932;
				border-radius: 4px;
			}
		}
		a {
			padding: 24px 30px 30px 28px;
			display: block;
			&:hover{
				opacity: 1;
				h2,p{
					opacity: 0.8;
				}
				.c-kurashiBtn01{
					span{
						opacity: 0.7;
					}
				}
			}
		}
	}
}

//くらしのサービスのお知らせ
.p-kurashiTop05 {
	margin-bottom: 40px;
	padding: 29px 30px 11px 28px;
	background-color: $subColor07;
	.p-kurashiTop05__content01 {
		position: relative;
		h2 {
			margin-bottom: 10px;
			padding-bottom: 25px;
			font-size: 2.0rem;
			font-weight: bold;
			color: $kurashiTextColor04;
			background-image: url("../img/kurashi/title01_line.png");
			background-repeat: repeat-x;
			background-position: 0 bottom;
			span {
				margin: 10px 0 0 10px;
				display: inline-block;
				vertical-align: middle;
			}
		}
		.p-kurashiTop05__content01__newsList {
			li {
				padding: 19px 0 17px;
				border-bottom: dashed 1px rgba($kurashiTextColor03,0.3);
				&:last-child {
					border: none;
				}
				p {
					width: 550px;
					color: $kurashiTextColor01;
					font-size: 1.6rem;
					font-weight: 500;
					line-height: 1.5;
				}
				a {
					color: $kurashiTextColor01;
					font-size: 1.6rem;
					font-weight: 500;
					line-height: 1.5;
					text-decoration: underline;
					&:hover {
						opacity: 1;
						text-decoration: none;
					}
				}
			}
			.p-kurashiTop05__content01__newsList_data {
				width: 112px;
				font-size: 1.6rem;
				padding-left: 5px;
				color: $kurashiTextColor03;
			}
			.c-newsCate02 {
				width: 100px;
				margin-right: 20px;
				font-weight: 500;
			}
		}
		.c-kurashiBtn02 {
			width: 116px;
			position: absolute;
			top: 0;
			right: -2px;
		}
	}
}

@include sp {
	//くらしTOPスライダー sp
	.p-kurashiTop01 {
		min-width: 100%;
		margin-bottom: 32px;
		overflow: visible;
		.p-kurashiTop01__sliderBord {
			.slick-arrow2 {
				width: 30px;
				height: 30px;
			}
		}
	}
	.p-kurashiSlider {
		.slick-slide {
			width: 100%;
			height: auto;
			margin: 0;
			border: none;
			border-bottom: solid 1px $glaylight02;
			@include radius(0);
			&:before {
				content: none;
			}
			a {
				background: none !important;
				display: block;
			}
		}
		.p-kurashiSlider__img {
			display: block;
			position: relative;
			&:after {
				width: 100%;
				height: 100%;
				content: '';
				background-image: url("../img/kurashi/index_mv_mask_sp.png");
				background-repeat: no-repeat;
				background-position: 0 bottom;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		&__text {
			padding: 2px 20px 10px 20px;
			display: block;
			background: none;
			background-color: #ffffff;
			opacity: 1;
			&__title {
				width: 100%;
				margin-bottom: 8px;
				display: block;
				font-size: 2rem;
				padding-left: 14px;
			}
			&__excerpt {
				width: 100%;
				font-size: 1.3rem;
				line-height: 1.6;
			}
		}
	}
	.slick-slider {
	    margin-bottom: 15px !important;
	}

	//人気サービス
	.p-kurashiTop02 {
		margin-bottom: 32px;
		.p-kurashiTop02__content01 {
			padding: 0 20px;
			//コンポーネント　c-card1
		}
	}

	//サービスメニュー sp
	.p-kurashiTop03 {
		margin-bottom: 21px;
		.p-search{
			margin-bottom: 15px;
		}
		.p-kurashiTop03__content01 {
			padding: 0 20px;
			.p-kurashiTop03__content01__select {
				margin-bottom: 20px;
				padding: 15px;
				.p-kurashiTop03__content01__select__wrap {
					width: 100%;
					select {
						width: 100%;
					}
				}
			}
			.p-kurashiTop03__sliderBord {
				.slick-arrow3 {
					width: 30px;
					height: 30px;
				}
			}
			//コンポーネント　c-card2
		}
	}
	//お掃除カレンダー
	.p-kurashiTop04 {
		margin-bottom: 30px;
		.p-kurashiTop04__content01 {
			padding: 0 20px;
			height: auto;
			overflow: visible;
			background: none;
			border: none;
			position: static;
			@include radius(0);
			h2 {
				margin-bottom: 3px;
				font-size: 2.0rem;
				&:before {
					width: 20px;
					height: 20px;
					margin: -5px 2px 0 0;
				}
			}
			p {
				width: 100%;
				margin-bottom: 10px;
				font-size: 1.4rem;
    			line-height: 1.6;
			}
			.c-kurashiBtn01 {
				width: 100%;
				height: 60px;
				text-align: left;
				font-size: 1.5rem;
				line-height: 2.66;
				background-image: url("../img/kurashi/carender_bg_sp.jpg");
				-webkit-background-size: cover;
				background-size: cover;
				position: static;
				border: solid 1px $kurashiBtnColor03;
				a, span {
					padding: 10px 10px 10px 14px;
					color: $kurashiBtnColor03;
					font-size: 1.4rem;
					font-weight: bold;
					background: none;
					&:before {
						width: 20px;
						height: 20px;
						margin: 0 9px 0 0;
						content: '';
						background-image: url("../img/common/icon_maru-sankaku02.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
					&:after {
						content: none;
					}
				}
			}
			a {
				padding: 0;
			}
		}
	}
	//くらしのサービスのお知らせ sp
	.p-kurashiTop05 {
		margin: 0 15px 25px;
		padding: 20px 15px;
		.p-kurashiTop05__content01 {
			h2 {
				margin-bottom: 0;
				font-size: 1.7rem;
				line-height: 1;
				text-align: center;
				padding-bottom: 7px;
				span {
					margin: 3px auto 0 auto;
					display: block;
				}
			}
			.p-kurashiTop05__content01__newsList {
				margin-bottom: 10px;
				padding: 0 10px 0;
				li {
					padding: 15px 0 11px;
					a {
						width: 100%;
					    font-size: 1.4rem;
					    line-height: 2.1rem;
					    padding-top: 5px;
					}
				}
				.p-kurashiTop05__content01__newsList_data {
					width: auto;
					font-size: 1.2rem;
					color: $kurashiTextColor03;
				}
				.p-reformTop02__contents01__newsList_data {
					width: auto;
					margin: 0 1em 10px 0;
				}
				.c-newsCate02 {
					width: auto;
				    height: auto;
				    font-size: 1rem;
				    line-height: 1;
				    padding: 3px 15px;
				    margin: 0 0 0 9px;
				}
			}
			.c-kurashiBtn02 {
				margin: 0 auto;
				position: static;
			}
		}
	}

}

/*
------------------------------------------------------------*/
