@use '../../0_base' as *;

/*------------------------------------------------------------
404
------------------------------------------------------------*/
.p-service404 {
	padding: 102px 0 98px;
	@include sp {
		padding: 85px 0 70px;
	}
	&__bg {
		background: url("../img/404/bg02.png") repeat;
		padding: 74px 0 0;
		@include sp {
			padding: 30px 0;
			background-size: 40px;
		}
		.u-inner01 {
			background: url("../img/404/bg01.png") no-repeat bottom right -8px;
			padding-bottom: 50px;
			@include sp {
				background: none;
				padding: 0 20px;
			}
		}
	}
	&__title {
		margin-bottom: 35px;
		font-size: 10rem;
		color: $keycolor02;
		line-height: 1;
		letter-spacing: 4px;
		@include yu_gosick();
		@include sp {
			margin-bottom: 30px;
			text-align: center;
			font-size: 8rem;
		}
		span {
			display: block;
			font-size: 3.6rem;
			font-weight: normal;
			letter-spacing: 0;
			@include sp {
				font-size: 3.4rem;
			}
		}
	}
	&__title02 {
		margin-bottom: 9px;
		font-size: 3.2rem;
		color: $gray01;
		line-height: 1.4;
		@include sp {
			font-size: 2rem;
		}
	}
	&__txt {
		margin-bottom: 30px;
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}
	&__btn {
		a {
			width: 430px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 1.8rem;
			font-weight: bold;
			color: #fff;
			background: $keycolor02;
			border: 2px solid $keycolor02;
			border-radius: 50px;
			@include sp {
				width: 100%;
				height: 50px;
				font-size: 1.4rem;
	    		padding: 4px 21px 0;
	    		line-height: 1.3;
			}
			&:hover {
				color: $keycolor02;
				background: #fff;
			}
		}
	}
	&__content {
		padding: 59px 0 0;
		ul {
			li {
				width: 18.57%;
				height: 16.47vw;
				max-height: 205px;
				min-height: 184px;
				margin-right: 1.7875%;
				background-image: url("../img/service/bg01.jpg");
				box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
				overflow: hidden;
				position: relative;
				@include radius(6px);
				&:after {
					width: 25px;
					height: 25px;
					content: '';
					background-image: url("../img/common/icon_btn-green01.png");
					position: absolute;
					bottom: 0;
					right: 0;
					transition: 0.3s ease-in-out;
				}
				&:before {
					content: '';
					width: 100%;
					height: 100%;
					border: solid 4px;
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
					transition: 0.3s ease-in-out;
				}
				&:hover {
					&:after {
						opacity: 0;
					}
					&:before {
						opacity: 1;
					}
				}
				&:nth-child(1) {
					&:before {
						border-color: $ticketColor01;
					}
				}
				&:nth-child(2) {
					&:before {
						border-color: $hokenColor01;
					}
				}
				&:nth-child(3) {
					&:before {
						border-color: $reformColor01;
					}
				}
				&:nth-child(4) {
					&:before {
						border-color: $kurashiColor01;
					}
				}
				&:nth-child(5) {
					margin: 0;
					&:before {
						border-color: $sosaiColor01;
					}
				}
				.c-cateIcon01 {
					width: 80px;
					height: 80px;
					margin: 0 auto;
					-webkit-background-size: 50%;
					background-size: 50%;
					position: absolute;
					top: 13%;
					left: 0;
					right: 0;
				}
				.c-cateIcon01--kurashi + a {
					margin-top: -0.5em;
				}
				a {
					width: 100%;
					display: block;
					text-align: center;
					font-size: 2.2rem;
					font-weight: bold;
					line-height: 1.2;
					position: absolute;
					top: 68%;
					left: 0;
				}
			}
		}
		@include sp {
			padding: 30px 20px 0;
			ul {
				li {
					width: 30%;
					height: 37.5vw;
					max-height: 240px;
					min-height: auto;
					margin: 0 4.5% 3% 0;
					border-radius: 3px;
					&:after {
						width: 17px;
						height: 17px;
					}
					.c-cateIcon01 {
						width: 16vw;
						height: 16vw;
						-webkit-background-size: 60%;
						background-size: 60%;
						top: 12%;
						left: 0;
						right: 0;
					}
					a {
						font-size: 1.4rem;
						top: 69%;
					}
				}
			}
		}
	}
}