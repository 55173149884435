@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　資料請求・お問い合わせフォーム（神奈川/山梨/静岡）　確認
------------------------------------------------------------*/
.p-sosaiContactConfirm {
	padding: 50px 0 40px 0;
	background-color: #ffffff;
}

//見出し
.p-sosaiContactConfirm01 {
	margin-bottom: 30px;
	.c-formTitle {
		color: $sosaiTextColor01;
	}
}

//フォーム
.p-sosaiContactConfirm02 {
	margin-bottom: 30px;
	&__content01 {
		margin-bottom: 50px;
		color: $gray01;
		.c-cartBtn {
			margin-bottom: 45px;
		}
	}
	&__content02 {
		margin-bottom: 80px;
		//コンポーネント　c-cartBtn
		&__back {
			margin-top: 20px;
			font-size: 1.8rem;
			display: block;
			text-align: center;
			color: $gray01;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
			&:before {
				width: 7px;
				height: 14px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_arrow-blue04.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}
		}
	}
}



@include sp {

	.p-sosaiContactConfirm {
		padding: 30px 0 40px 0;
		background-color: #ffffff;
	}

	//見出し sp
	.p-sosaiContactConfirm01 {
		margin-bottom: 30px;
		padding: 0 20px;
		.c-formTitle {
			margin-bottom: 20px;
		}
	}

	//フォーム sp
	.p-sosaiContactConfirm02 {
		margin-bottom: 20px;
		padding: 0 20px;
		&__content01 {
			margin-bottom: 30px;
			.c-cartBtn {
				margin-bottom: 20px;
			}
		}
		&__content02 {
			margin-bottom: 40px;
			&__back {
				font-size: 1.4rem;
			}
		}
	}

}
/*
------------------------------------------------------------*/
