@use '../../0_base' as *;

/*------------------------------------------------------------
チケット　お申込みからお届けまでの流れ
------------------------------------------------------------*/
.p-ticketFlow {
	background-color: #ffffff;
}

//お申し込み方法・お届けまでの流れ
.p-ticketFlow01 {
	margin-bottom: 80px;
	font-size: 1.6rem;
	line-height: 1.75;
	color: $ticketNaviColor02;
	&__content01 {
		margin-bottom: 50px;
		dl {
			dt {
				margin-bottom: 7px;
				font-weight: bold;
				&:before {
					width: 12px;
					height: 12px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $ticketColor01;
					display: inline-block;
					vertical-align: middle;
					@include radius(4px);
				}
			}
			dd {
				margin-bottom: 18px;
			}
			a {
				color: $linkColor01;
				text-decoration: underline;
				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
		}
	}
	&__content02 {
		> p {
			margin-bottom: 20px;
			b {
				font-weight: bold;
			}
		}
		ol {
			li {
				margin-bottom: 30px;
				position: relative;
				border-bottom: dashed 1px rgba(0,0,0,0.3);
				img {
					position: absolute;
				}
				&:nth-child(1) {
					min-height: 210px;
					padding: 0 300px 30px 0;
					img {
						top: 0;
						right: 0;
					}
				}
				&:nth-child(2) {
					padding: 0 300px 30px 0;
					img {
						top: 11px;
						right: 0;
					}
				}
				&:nth-child(3) {
					margin: 0;
					padding-bottom: 20px;
					p {
						margin-bottom: 20px;
					}
					a {
						color: $linkColor01;
						text-decoration: underline;
						&:hover {
							opacity: 1;
							text-decoration: none;
						}
						&:before {
							width: 6px;
							height: 10px;
							margin: -3px 10px 0 0;
							content: '';
							background-image: url("../img/common/icon_arrow-pink01.png");
							-webkit-background-size: cover;
							background-size: cover;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
				h3 {
					margin-bottom: 5px;
					font-size: 1.8rem;
					font-weight: bold;
					&:before {
						width: 25px;
						height: 25px;
						margin: -5px 10px 0 0;
						content: counter(count01);
						line-height: 1.38;
						color: #ffffff;
						background-color: $ticketColor01;
						text-align: center;
						display: inline-block;
						vertical-align: middle;
						@include radius(50%);
					}
				}
			}
		}
	}
}

//お届けまでの流れ
.p-ticketFlow02 {
	margin-bottom: 100px;
	&__content01 {
		&__case {
			margin-bottom: 30px;
		}
		&__annotation {
			font-size: 1.3rem;
			line-height: 1.5;
			color: #232934;
		}

		//コンポーネント　c-step

	}
}



@include sp {
	.p-ticketFlow {
		padding: 30px 20px;
	}

	//お申し込み方法・お届けまでの流れ sp
	.p-ticketFlow01 {
		margin-bottom: 40px;
		font-size: 1.4rem;
		&__content01 {
			margin-bottom: 30px;
			dl {
				dd {
					margin-bottom: 15px;
				}
			}
		}
		&__content02 {
			> p {
				margin-bottom: 15px;
			}
			ol {
				li {
					min-height: auto !important;
					margin-bottom: 15px;
					padding: 0 0 15px 0 !important;
					position: static;
					img {
						margin: 0 auto 10px auto;
						display: block;
						position: static;
					}
					&:nth-child(3) {
						margin: 0 !important;
						p {
							margin-bottom: 15px;
						}
					}
					h3 {
						font-size: 1.6rem;
						&:before {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}

	}

	//お届けまでの流れ sp
	.p-ticketFlow02 {
		margin-bottom: 40px;
		&__content01 {
			&__case {
				margin: 0;
				padding: 0;
				background-color: $glaylight10;
				&__accordionBtn {
					margin: 0 !important;
					span {
						width: 40px;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						display: block;
						&:before {
							width: 20px;
							height: 2px;
							content: '';
							background-color: $ticketColor01;
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							transition: transform 0.25s ease-out;
						}
						&:after {
							width: 2px;
							height: 20px;
							content: '';
							background-color: $ticketColor01;
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							transition: transform 0.25s ease-out;
						}
					}
				}

				//コンポーネント　c-step

				.c-step {
					width: calc(100% - 40px);
					margin: 0 20px;
					padding-bottom: 20px;
					display: none;
					.c-step__head {
						.c-step__icon {
							@include tr3d0;
						}
						.c-step__head-inner {
							@include tr3d0;
						}
					}

				}
				&.is-open {
					.p-ticketFlow02__content01__case__accordionBtn {
						span {
							&:after {
								transform: translate(-50%, -50%) rotate(-90deg);
							}
						}
					}
					.c-step {
						display: block;
					}
				}
			}
			&__annotation {
				margin-top: 20px;
			}
		}
		.c-ticketTitle01 {
			line-height: 1.4;
			margin-bottom: 15px;
		}
	}
}


/*
------------------------------------------------------------*/
