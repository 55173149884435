@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス　商品申込み・お問い合わせフォーム（カート）
------------------------------------------------------------*/
.p-kurashiCart {
	padding: 37px 0 140px 0;
	background-color: #ffffff;
}

//見出し
.p-kurashiCart01 {
	margin-bottom: 21px;
	.c-formTitle {
		color: $kurashiTextColor04;
	}
}

//カートの中
.p-kurashiCart02 {
	margin-bottom: 53px;
	&__txt {
		margin-bottom: 17px;
		font-size: 1.6rem;
		line-height: 1.7;
		color: $kurashiTextColor01;
		&__annotation {
			font-size: 1.4rem;
		}
	}
	//コンポーネント　c-tbl01
	.c-tbl01--kurashiCart{
		margin-top: 23px;
	}
}


//お申し込み前のご確認事項
.p-kurashiCart03 {
	.p-kurashiCart03__content01 {
		dl {
			dt {
				margin-bottom: 13px;
				font-size: 2.0rem;
				line-height: 1;
				font-weight: bold;
				color: $kurashiTextColor01;
				&:before {
					width: 10px;
					height: 10px;
					margin: -3px 10px 0 0;
					content: '';
					background-color: $kurashiColor01;
					display: inline-block;
					vertical-align: middle;
					@include radius(50%);
				}
			}
			dd {
				margin-bottom: 32px;
				font-size: 1.6rem;
				line-height: 1.75;
				color: $kurashiTextColor01;
				a {
					color: $linkColor01;
					text-decoration: underline;
					&:hover {
						opacity: 1;
						text-decoration: none;
					}
				}
				&:first-of-type {
					p {
						width: 800px;
						@include sp{
							width: 100%;
						}
					}
				}
			}
		}
		&__img{
			margin-top: 5px;
		}
	}
	.p-kurashiCart03__content02 {
		//コンポーネント　c-cartBtn
	}
}


@include sp {
	.p-kurashiCart {
		padding: 30px 20px 29px 20px;
	}

	//見出し sp
	.p-kurashiCart01 {
		margin-bottom: 16px;
		.c-formTitle {
			margin-bottom: 14px;
		}
	}

	//カートの中 sp
	.p-kurashiCart02 {
		margin-bottom: 30px;
		&__txt {
			margin-bottom: 7px;
			font-size: 1.4rem;
			line-height: 1.4;
			&__annotation {
				font-size: 1.1rem;
				font-weight: bold;
				padding-top: 10px;
				display: block;
			}
		}
		//コンポーネント　c-tbl01
		.c-tbl01--kurashiCart{
			margin-top: 12px;
		}
	}

	//お申し込み前のご確認事項 sp
	.p-kurashiCart03 {
		.p-kurashiCart03__content01 {
			dl {
				dt {
					margin-bottom: 5px;
					font-size: 1.4rem;
					&:before {
						width: 8px;
						height: 8px;
						margin: -3px 4px 0 0;
					}
				}
				dd {
					margin-bottom: 21px;
					font-size: 1.4rem;
					line-height: 1.4;
					p {
						width: 100%;
						margin-bottom: 10px;
					}
					img {
						margin: 0 auto;
						display: block;
					}
				}
			}
			.c-kurashiTitle03 {
				margin-bottom: 16px;
			}
		}
		.p-kurashiCart03__content02 {
			//コンポーネント　c-cartBtn
			.c-cartBtn {
				margin-top: 34px;
			}
		}
	}
}
/*
------------------------------------------------------------*/
