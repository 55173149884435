@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　お葬式勉強会フォーム（神奈川・山梨）　完了
------------------------------------------------------------*/
.p-sosaiContactSeminarComplete {
	background-color: #ffffff;
}

//見出し
.p-sosaiContactSeminarComplete01 {
	margin: 30px 0;
	.c-formTitle {
		color: $sosaiTextColor01;
	}
}

//本文
.p-sosaiContactSeminarComplete02 {
	margin-bottom: 80px;
	//コンポーネント　c-formComplete
	.c-formComplete {
		color: $gray08;
		&__inquiry {
			&__tel {
				color: #333745;
				span {
					color: #333745;
					&:before {
						background-image: url("../img/common/icon_tel04.png");
					}
				}
				p {
					font-size: 1.6rem;
					color: #111111;

				}
			}
		}
	}
}

@include sp {
	.p-sosaiContactSeminarComplete {
		padding: 0 20px;
	}
	
	//見出し　ｓｐ
	.p-sosaiContactSeminarComplete01 {
		margin: 30px 0;
		.c-formTitle {
			margin-bottom: 20px;
		}
	}
	
	//本文　ｓｐ
	.p-sosaiContactSeminarComplete02 {
		margin-bottom: 40px;
		//コンポーネント　c-formComplete
	}
}
/*
------------------------------------------------------------*/
