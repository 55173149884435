@use '../../0_base' as *;

/*------------------------------------------------------------
保険販売・勧誘方針
------------------------------------------------------------*/
.p-hokenPolicy {
	padding: 0 0 100px;
	@include sp {
		padding: 85px 20px 70px;
	}
	&__title {
		margin-bottom: 54px;
		text-align: center;
		font-size: 3.2rem;
		color: $gray01;
		line-height: 1.35;
		@include sp {
			margin-bottom: 30px;
			font-size: 2.1rem;
		}
		span {
			display: block;
			font-size: 1.4rem;
			font-weight: normal;
			opacity: 0.5;
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
	&__title02 {
        padding: 10px 15px;
        background-color: #E4F7FF;
        border-radius: 5px;
        @include pc {
            padding: 20px 30px;
            border-radius: 10px;
            font-size: 4rem;
        }
	}
	&__title03 {
        border-bottom: 1.5px solid #41B4E6;
		font-size: 2.2rem;
        font-weight: 700;
		@include pc {
            border-width: 3px;
            font-size: 3.2rem;
		}
	}
	&__title04 {
        font-size: 1.9rem;
        font-weight: 700;
        @include pc {
            font-size: 2.4rem;
        }
    }
	&__title05 {
        padding-bottom: 10px;
        border-bottom: 1px solid #41B4E6;
        font-size: 1.8rem;
        font-weight: 700;
        @include pc {
            padding-bottom: 20px;
            font-size: 2rem;
        }
	}
	&__txt {
        font-size: 1.4rem;
        @include pc {
            font-size: 1.6rem;
        }
	}
	&__list {
		li {
            padding-left: 11px;
            position: relative;
            font-size: 1.4rem;
            line-height: 1.6;
            @include pc {
                padding-left: 18px;
                font-size: 1.6rem;
            }

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background: #41B4E6;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: 0;
                @include pc {
                    width: 8px;
                    height: 8px;
                    top: 8px;
                }
            }
		}
	}
	&__box {
        padding: 15px;
        border: 1.5px solid #41B4E6;
        border-radius: 5px;
		@include pc {
            padding: 30px;
            border-width: 3px;
            border-radius: 10px;
		}
	}
}
