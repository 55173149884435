@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　お葬式勉強会フォーム（神奈川・山梨）　入力・エラー
------------------------------------------------------------*/
.p-sosaiContactSeminarInput {
	padding: 50px 0 100px 0;
	background-color: #ffffff;
}

//見出し
.p-sosaiContactSeminarInput01 {
	margin-bottom: 30px;
	.c-formTitle {
		color: $sosaiTextColor01;
	}
}

//フォーム
.p-sosaiContactSeminarInput02 {
	margin-bottom: 80px;
	&__require {
		margin: 0 10px 0 0;
		display: inline-block;
		vertical-align: middle;
		font-size: 1.1rem;
		font-weight: bold;
		color: #fff;
		background: #f22727;
		padding: 0 5px;
		line-height: 1.6;
		border-radius: 3px;
		@include sp{
			margin-left: 10px;
		}
	}
	&__title {
    font-weight: bold;
		color: $gray08;
		margin-top: 15px;
		&:first-child {
			margin-top: 0px;
		}
		.c-table2--note1 {
			font-weight: normal;
			@include sp {
				font-weight: bold;
			}
		}
	}
	&__content01 {
		margin-bottom: 50px;
		&__txt {
			margin-bottom: 40px;
			font-size: 1.6rem;
			color: $gray01;
		}
		&__txt2 {
			margin-bottom: 15px;
			font-size: 1.6rem;
			color: $gray01;
		}
	}
	&__content02 {
		margin-bottom: 50px;
	}
	&__content03 {
		//コンポーネント　c-cartBtn
		&__back {
			margin-top: 20px;
			font-size: 1.8rem;
			display: block;
			text-align: center;
			color: $gray01;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
			&:before {
				width: 7px;
				height: 14px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_arrow-blue04.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}
		}
	}
	.p-sosaiFormTable04 {
		dt {
		width: 330px;
		}
		dd {
			width: calc(100% - 330px);
		}
	}
}


@include sp {
	.p-sosaiContactSeminarInput {
		padding: 30px 20px 80px 20px;
	}

	//見出し sp
	.p-sosaiContactSeminarInput01 {
		margin-bottom: 20px;
		.c-formTitle {
			margin-bottom: 20px;
		}
	}

	//フォーム sp
	.p-sosaiContactSeminarInput02 {
		margin-bottom: 40px;
		&__title {
			margin-bottom: 10px;
		}
		&__content01 {
			margin-bottom: 30px;
			> p {
				margin-bottom: 20px;
				font-size: 1.3rem;
			}
			.p-sosaiContactSeminarInput02__require {
				margin: 0 5px;
			}
		}
		&__content02 {
			margin-bottom: 30px;
			.c-sosaiTitle05 {
				font-size: 1.6rem;
			}
		}
		&__content03 {
			margin-bottom: 40px;
			&__back {
				font-size: 1.4rem;
			}
		}
	}
}


//フォーム04
.p-sosaiFormTable04 {
	dl {
		.float {
			li{
				float: left;
			}
			input {
				width: 240px;
				padding: 0 15px;
				margin: 0 28px 0 23px;
			}
		}
		.kana input {
			margin: 0 29px 0 7px;
		}
		.small input{
			padding: 0 15px;
			width: 240px;
		}
		.select {
			select {
				width: 60px;
				height: 38px;
				margin: 0 10px;
				padding: 5px 10px;
				border: solid 1px #cccccc;
				@include radius(4px);
				option {
					font-size: 1.6rem;
					line-height: 2;
				}
			}
		}
		.wide {
			input {
					width: 600px;
					padding: 0 15px;
			}
			textarea {
				width: 100%;
				padding: 15px;
			}
		}
		@include sp {
			.wide,
			.small{
				input, textarea {
					width: 100%;
					padding: 10px;
				}
			}
			.float {
				li{
					float: none;
					display: inline-flex;
					align-items: center;
					width: 100%;
					margin-bottom: 12px;
				}
				input {
					width: 94%;
					padding: 10px;
					margin: 0 0 0 10px;
				}
			}
			input {
				height: 38px;
				line-height: 38px;
			}
		}
	}
	.c-table2__radio {
		input:checked + label:before {
			background: $sosaiColor01;
			border: 1px solid $sosaiColor01 !important;
		}
		@include sp {
			li {
				margin-right: 0;
			}
		}
	}
}
/*
------------------------------------------------------------*/
