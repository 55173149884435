@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス 組合員の声
------------------------------------------------------------*/
.p-kurashiVoice {
	background-color: #ffffff;
	.l-contentServiceMain {
		width: 850px;
		@include sp {
			width: 100%;
		}
	}
}

//組合員の声
.p-kurashiVoice01 {
	font-size: 1.6rem;
	line-height: 1.75;
	color: $kurashiTextColor01;
	.c-kurashiTitle02{
		margin-bottom: 35px;
		@include sp{
			margin-bottom: 12px;
		}
	}
	&__content01 {
		margin-bottom: 23px;
	}
	&__content02 {
		margin-bottom: 92px;
		ul {
			margin-bottom: -20px;
			.kurashiVoice-box{
				width: 420px;
				margin-bottom: 13px;
				padding: 41px 50px 48px 39px;
				background-image: url('/img/kurashi/voice_bg01.png');
				background-size: 100% 100%;
				background-repeat: no-repeat;
				position: relative;
				&:before {
					content: '';
					width: 88px;
					height: 38px;
					background-image: url('/img/kurashi/voice_icon01.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					position: absolute;
					top : -10px;
					left: 49%;
					transform: translateX(-50%);
				}
				&:nth-child(1), &:nth-child(4), &:nth-child(7) {
					background-image: url('/img/kurashi/voice_bg02.png');
				}
				&:nth-child(2), &:nth-child(5), &:nth-child(10) {
					background-image: url('/img/kurashi/voice_bg03.png');
				}
				&:nth-child(2), &:nth-child(5), &:nth-child(8) {
					&:before {
						background-image: url('/img/kurashi/voice_icon02.png');
					}
				}
				&:nth-child(3), &:nth-child(6), &:nth-child(9) {
					&:before {
						background-image: url('/img/kurashi/voice_icon03.png');
					}
				}
				&__headding {
					padding-right: 7px;
					margin-bottom: 13px;
					&--silhouette {
						width: 80px;
						height: 80px;
						text-align: center;
						@include radius(50%);
					}
					&--txt {
						width: 225px;
						display: table;
						border-bottom: 6px solid transparent;
						-webkit-border-image: url('/img/kurashi/voiceBox-line.png') 22 round; /* Safari 3.1-5 */
						-o-border-image: url('/img/kurashi/voiceBox-line.png') 22 round; /* Opera 11-12.1 */
						border-image: url('/img/kurashi/voiceBox-line.png') 22 round;
						.heading-txt01{
							color: #faad5a;
							font-size: 1.8rem;
							font-weight: bold;
						}
						.heading-txt02{
							color:#733e01;
							font-size: 2rem;
							line-height: 1.5;
							font-weight: bold;
							padding: 2px 0 6px;
						}
					}
				}
				&__content{
					font-size: 1.6rem;
					color: #594242;
					span{
						color: #f26b51;
						font-weight: bold;
					}

				}
			}
		}
	}
}


@include sp {
	.p-kurashiVoice {
		padding: 30px 20px;
	}
	//組合員の声 sp
	.p-kurashiVoice01 {
		font-size: 1.4rem;
		&__content01 {
			margin-bottom: 16px;
			p{
				line-height: 1.5;
			}
		}
		&__content02 {
			margin-bottom: 20px;
			ul {
				margin-bottom: 0;
				.kurashiVoice-box{
					width: 100%;
					margin-bottom: 15px;
					padding: 29px 20px 47px;
					background-size: 100% 100%;
					&:before {
						width: 60px;
						height: 25px;
						top: 0;
						left: 50%;
					}
					&__headding {
						padding-right: 0;
						margin-bottom: 10px;
						&--silhouette {
							width: 50px;
							height: 50px;
							margin: 5px 0 0 0;
						}
						&--txt {
							width: calc(100% - 64px);
							.heading-txt01{
								font-size: 1.4rem;
							}
							.heading-txt02{
								font-size: 1.5rem;
								padding: 2px 0 3px;
							}
						}
					}
					&__content{
						font-size: 1.4rem;
						line-height: 1.6;
						span{
							color: #594242;
							font-weight: normal;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
