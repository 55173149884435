@use '../0_base' as *;

/*------------------------------------------------------------
table
------------------------------------------------------------*/

/*
------------------------------------------------------------*/
.c-tbl01{
	width: 1126px;
	margin: 0 auto;
	font-size: 1.4rem;
	color: $gray01;
	border-collapse: collapse;
	border-bottom: 1px solid #b2b2b2;
	@include sp {
		width: 100% !important;
		display: block;
		border: none;
		tbody {
			display: block;
		}
		tr {
			margin-bottom: 10px;
			display: block;
			border: none !important;
		}
	}
	th,td{
		text-align: left;
		font-size: 1.6rem;
		@include sp {
			font-size: 1.4rem;
			line-height: 1.5;
		}
	}
	th{
		padding: 10px 0 9px 16px;
		border-right: 1px solid #fff;
		@include sp {
			display: none;
		}
	}
	td{
		padding: 17px 15px 15px;
		@include sp {
			width: 100% !important;
			text-align: left !important;
			display: table;
			border-left: 1px solid #b2b2b2;
			border-right: 1px solid #b2b2b2;
			border-bottom: 1px solid #b2b2b2;
			padding: 0 !important;
			&:first-child {
				border-top: 1px solid #b2b2b2;
			}
			.col-title {
				display: table-cell;
				vertical-align: middle;
				width: 65px;
				height: 100%;
				font-weight: bold;
				background-color: #f3f3f3;
				padding: 11px 2px 10px;
				line-height: 1.4;
				text-align: center;
			}
			.col-txt {
				width: calc(100% - 65px);
				display: table-cell;
				vertical-align: middle;
				padding: 6px 15px;
			}
		}
	}
	&__title{
		background: #f3f3f3;
		font-weight: bold;

	}
	&__item{
		line-height: 21px;
		border-bottom: 1px solid #b2b2b2;
	}
	&__total{
		@include sp {
			margin-top: 0;
		}
		td{
			text-align: right !important;
			padding: 8px 0 4px !important;
			font-size: 1.4rem;
			@include clear;
			@include sp {
				padding: 0 !important;
				font-size: 1.2rem;
				border: none !important;
				border-bottom: 1px solid #b2b2b2 !important;
			}
		}
		table{
			float: right;
			border: none;
			margin-bottom: 8px;
			@include sp {
				margin-bottom: 6px;
				width: auto;
				display: block;
				@include clear;
				tbody {
					display: block;
				}
				tr {
					display: block;
					border: none;
					margin-bottom: 0;
				}
				td {
					width: 50% !important;
					margin: 0;
					display: block;
					border: none;
					float: left;
					white-space: nowrap;
					border: none !important;
				}
			}
		}
		span{
			font-size: 1.8rem;
			font-weight: bold;
			padding-left: 8px;
			@include sp {
				font-size: 1.4rem;
				padding-left: 4px;
			}
		}
		.total-number{
			span{
				color: #f22727;

			}
		}
		.total-price{
			span{
				color: #f22727;
			}
		}
	}
	&--ticketCart {
		color: $ticketNaviColor02;
		th {
			padding: 10px 10px 9px;
			white-space: nowrap;
		}
		td {
			padding: 17px 10px 15px;
		}
		.c-tbl01__item{
			.col-name{
				min-width: 200px;
			}
			.col-date{
				min-width: 140px;
			}
			.col-time{
				min-width: 100px;
			}
			.col-locate{
				min-width: 100px;
			}
			.col-type{
				min-width: 80px;
			}
			.col-note{
				min-width: 90px;
			}
			.col-price{
				min-width: 90px;
			}
			.col-subtotal {
				white-space: nowrap;
			}
			.col-num{
				min-width: 100px;
				white-space: nowrap;
				select{
					width: 58px;
					height: 30px;
					border-radius: 5px;
					padding: 0 7px;
					@include sp {
						font-size: 1.2rem;
						width: 138px;
						height: 28px;
					}
				}
				span{
					color: #308bbb;
					font-size: 1.3rem;
					cursor: pointer;
					border-bottom: 1px solid #308bbb;
					margin-left: 15px;
					@include sp {
						margin-left: 17px;
						font-size: 1.1rem;
					}
				}
			}
		}
	}
	&--hokenRequest {
		.c-tbl01__item{
			.col-name {
				width: 416px;
			}
			.col-hosyo {
				width: 220px;
			}
			.col-kaisya {
				width: 220px;
			}
			.col-kikan {
				width: 160px;
			}
			.col-del {
				width: 104px;
				a {
					width: 60px;
					height: 26px;
					color: #ffffff;
					background-color: $gray03;
					cursor: pointer;
					text-align: center;
					display: inline-block;
					font-size: 1.3rem;
					line-height: 2;
					@include radius(4px);
				}
			}
		}
	}
	&--reformContact {
		.c-tbl01__item{
			.col-name {
				width: 416px;
			}
			.col-kind {
				width: 220px;
			}
			.col-size {
				width: 160px;
			}
			.col-maker {
				width: 220px;
			}
			.col-note {
				width: 160px;
			}
			.col-del {
				width: 104px;
				span {
					width: 60px;
					height: 26px;
					color: #ffffff;
					background-color: $gray03;
					cursor: pointer;
					text-align: center;
					display: inline-block;
					font-size: 1.3rem;
					line-height: 2;
					@include radius(4px);
				}
			}
		}
	}
	&--kurashiCart {
		color: $kurashiTextColor01;
		.c-tbl01__item{
			.col-name{
				width: 439px;
			}
			.col-note{
				width: 179px;
			}
			.col-price{
				width: 179px;
				span {
					display: block;
					font-size: 1.3rem;
					@include sp {
						font-size: 1.2rem;
					}
				}
			}
			.col-subtotal {
				span {
					display: block;
					font-size: 1.3rem;
					@include sp {
						font-size: 1.2rem;
					}
				}
			}
			.col-num{
				width: 159px;
				select{
					width: 58px;
					height: 30px;
					border-radius: 5px;
					padding: 0 7px;
					@include sp {
						font-size: 1.2rem;
						width: 138px;
						height: 28px;
					}
				}
				span{
					margin-left: 1em;
					color: #308bbb;
					font-size: 1.3rem;
					cursor: pointer;
					border-bottom: 1px solid #308bbb;
					@include sp {
						margin-left: 17px;
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}

.c-tbl02{
    background-color: #fff;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.5;
    th, td {
        text-align: left;
        font-weight: normal;
    }
    @include sp {
        &__title {
            display: none !important;
        }
        thead, tbody, tfoot, tr, th, td {
            display: block;
        }
        tbody {
            tr {
                margin-top: 15px;
                background-color: #888;
                border: 1px solid #888;
                display: grid;
                row-gap: 1px;
            }
            td {
                background-color: #fff;
                display: grid;
                grid-template-columns: 115px 1fr;
            }
            .col-title {
                white-space: nowrap;
            }
            .col-title, .col-txt {
                padding: 8px 15px;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
        .col-title {
            background-color: #EFEFEF;
            font-size: 1.6rem;
            font-weight: 700;
        }

        .js-remove-item {
            width: 100px;
            line-height: 31px;
            display: block;
            background-color: #D9D9D9;
            border-radius: 5px;
            color: #333;
            font-size: 1.4rem;
            font-weight: 700;
            text-align: center;
        }

        &__total {
            border-bottom: 1px solid #D9D9D9;
            td {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
    @include pc {
        border-collapse: collapse;
        th, td {
            padding: 10px 30px;
        }
        &__title {
            tr {
                >:not(:last-child) {
                    border-right: 1px solid #fff;
                }
            }
            th {
                background-color: #EFEFEF;
                font-size: 1.8rem;
                font-weight: 700;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #D9D9D9;
            }
        }

        .col-del {
            width: 115px;
        }

        .js-remove-item {
            width: 55px;
            line-height: 28px;
            display: block;
            background-color: #D9D9D9;
            border-radius: 10px;
            color: #333;
            font-size: 1.2rem;
            font-weight: 700;
            text-align: center;
        }

        &__total {
            border-bottom: 1px solid #D9D9D9;
        }
	}
}




/*
------------------------------------------------------------*/
.c-table1 {
	width: 1120px;
	margin: 0 auto;
	font-size: 1.6rem;
	border-top: 1px solid #efefef;
	@include sp{
		width : calc(100% - 30px);
		margin: 0 15px;
		border: 1px solid #b2b2b2;
		font-size: 1.3rem;
	}
	dl {
		display: flex;
		&:nth-child(3) input {
			width: 280px;
			padding: 0 15px;
		}
		&:nth-child(4), &:nth-child(5){
			li{
				float: left;
			}
			input {
				width: 240px;
				padding: 0 15px;
				margin: 0 28px 0 23px;
			}
		}
		&:nth-child(5) input {
			margin: 0 29px 0 7px;
		}
		&:nth-child(6),
		&:nth-child(7){
			input{
				padding: 0 15px;
				width: 240px;
			}
		}
		&:nth-child(8),	&:nth-child(9),	&:nth-child(10),
		&:nth-child(11),&:nth-child(12),&:nth-child(13) {
			input {
					width: 600px;
					padding: 0 15px;
			}
		}
		@include sp {
			display: block;
			border-bottom: 1px solid #b2b2b2;
			&:nth-child(3), &:nth-child(6),	&:nth-child(7),
			&:nth-child(8),	&:nth-child(9),	&:nth-child(10),
			&:nth-child(11),&:nth-child(12),&:nth-child(13){
				 input {
					width: 100%;
					padding: 10px;
				}
			}
			&:nth-child(4),  &:nth-child(5) {
				li{
					float: none;
					display: inline-flex;
					align-items: center;
					width: 100%;
				}
				input {
					width: 94%;
					padding: 10px;
					margin: 0 0 10px 10px;
				}
			}
			&:nth-child(5) input{
				width : 95%;
			}
		}
	}
	dt {
		display: flex;
		align-items: center;
		width: 280px;
		background: #f3f3f3;
		position: relative;
		padding: 20px 0 15px 31px;
		margin-bottom: 1px;
		label {
			font-weight: bold;
			color: #232934;
			@include sp{
				display: flex;
				align-items: center;
			}
		}
		@include sp{
			width : 100%;
			padding: 15px;
		}
	}
	dd {
		overflow: hidden;
		width: calc(100% - 280px);
		padding: 20px 0 15px 31px;
		border-bottom: 1px solid #efefef;
		@include sp{
			width : 100%;
			padding: 10px 15px;
		}
	}
	input {
		border: 1px solid #cccccc;
		border-radius: 3px;
		height: 38px;
		color: #232934;
		&::placeholder {
			color: #999999;
		}
		@include sp{
			height: auto;
		}
	}
	textarea {
		border: 1px solid #cccccc;
		border-radius: 3px;
		height: 120px;
		color: #232934;
		&::placeholder {
			color: #999999;
		}
	}
	&--require {
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 29px;
		transform: translateY(-50%);
		font-size: 1.1rem;
		font-weight: bold;
		color: #fff;
		background: #f22727;
		padding: 0 5px;
		line-height: 1.6;
		border-radius: 3px;
		@include sp{
			position: unset;
			transform: none;
			margin-left: 10px;
		}
	}
	&--note1 {
		margin-top: 6px;
		font-size: 1.4rem;
		color: #999999;
		line-height: 1.4;
		display: block;
		@include sp{
			font-size: 1.1rem;
		}
	}
	&--ttl1 {
		color: #232934;
		@include sp{
			width: 12%;

		}
	}
	&__radio {
		margin: 7px 0 5px;
		color: #232934;
		li {
			display: inline-block;
			margin-right: 26px;
			&:last-of-type {
				margin-right: 0;
			}
			@include sp{
				display: block;
			}
		}
		input {
			position: absolute;
			display: none;
		}

		input + label {
			position: relative;
			display: block;
			padding-left: 23px;
			cursor: pointer;
			vertical-align: middle;
		}
		input + label:hover:before {
			animation-duration: 0.4s;
			animation-fill-mode: both;
			animation-name: hover-color;
		}
		input + label:before {
			position: absolute;
			top: 4px;
			left: 0;
			display: inline-block;
			width: 14px;
			height: 14px;
			content: '';
			border: 1px solid #58595B;
		}
		input + label:after {
			position: absolute;
			display: none;
			content: '';
		}
		input[disabled] + label:hover:before {
			border: 1px solid #e4e4e4;
			animation-name: none;
		}
		input[disabled] + label:before {
			border-color: #e4e4e4;
		}
		input:checked + label:before {
			animation-name: none;
			background: #9ac985;
			border: 1px solid #9ac985 !important;
		}
		input:checked + label:after {
			display: block;
		}
		input + label:before {
			border-radius: 50%;
		}
		input + label:after {
			top: 8px;
			left: 4px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: #fff;
		}
		//input:checked + label:before {
			//border: 1px solid #58595B;
		//}
		input:checked[disabled] + label:before {
			border: 1px solid #c9e2f9;
		}
		input:checked[disabled] + label:after {
			background: #c9e2f9;
		}
	}
	&__checkbox01 li{
		float: left;
		margin-right: 25px;
	}
	&__checkbox02{
		overflow: hidden;
		padding-bottom: 9px;
		li{
			width : 150px;
			float: left;
		}
		@include sp{
			padding-bottom: 0;
			li{
				width: 100%;
				float: none;
			}

		}
	}
	.u-style01 {
		margin-top: 25px;
		border-top: 1px solid #efefef;
	}
	.u-style02 {
		.c-table1--note2 {
			margin-top: 6px;
			font-size: 1.4rem;
			font-weight: bold;
			color: #f22727;
			@include sp{
				font-size: 1.1rem;
			}
		}
		dt {
			background: rgba(242, 39, 39, 0.2);
		}
		input {
			background: rgba(242, 39, 39, 0.2);
		}
	}
}

/*
------------------------------------------------------------*/


.c-table2 {
	width: 1120px;
	margin: 0 auto;
	font-size: 1.6rem;
	border-top: 1px solid #efefef;
	@include sp{
		width : 100% !important;
		border-top: 1px solid #b2b2b2;
		font-size: 1.4rem;
	}
	dl {
		display: flex;
		@include sp {
			display: block;
			border: 1px solid #b2b2b2;
			border-top: none;
		}
	}
	dt {
		display: flex;
		align-items: center;
		width: 300px;
		background: #f3f3f3;
		position: relative;
		padding: 13px 0 11px 22px;
		margin-bottom: 1px;
		label,span {
			font-weight: bold;
			color: #111111;
			@include sp{
				display: flex;
				align-items: center;
			}
		}
		@include sp {
			width : 100% !important;
			padding: 11px 14px !important;
		}
	}
	dd {
		overflow: hidden;
		width: calc(100% - 280px);
		padding: 12px 0 12px 21px;
		border-bottom: 1px solid #efefef;
		ul.l-flex {
			display: flex;
		}
		@include sp{
			width : 100% !important;
			padding: 13px 14px !important;
			border-bottom: none;
			ul.l-flex {
				display: block;
			}
		}
	}
	input {
    	padding: 0 10px;
		border: 1px solid #cccccc;
		border-radius: 3px;
		height: 38px;
		color: #111111;
		&::placeholder {
			color: #999999;
		}
		@include sp{
			height: auto;
			padding: 10px;
		}
	}
	textarea {
		width: 600px;
		padding: 10px;
		border: 1px solid #cccccc;
		border-radius: 3px;
		height: 120px;
		color: #232934;
		&::placeholder {
			color: #999999;
		}
		@include sp{
			width: 100%;
		}
	}
	&--require {
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		font-size: 1.1rem;
		font-weight: bold;
		color: #fff !important;
		background: #f22727;
		padding: 0 5px;
		line-height: 1.6;
		border-radius: 3px;
		@include sp{
			font-size: 0.9rem;
			position: static;
			transform: none;
			margin-left: 10px;
		}
	}
	&__icon {
		display: inline-block;
	}
	&--note1 {
		margin-top: 6px;
		font-size: 1.4rem;
		color: #999999;
		line-height: 1.4;
		display: block;
		@include sp{
			margin: 8px 0 0;
			font-size: 1.1rem;
			font-weight: bold;
			line-height: 1.6;
		}
	}
	&--ttl1 {
		color: #232934;
		@include sp{
			width: 13%;

		}
	}
	&__radio, &__radio2, &__radio3 {
		margin: 7px 0 5px;
		color: #111111;
		@include sp {
			margin: -2px 0 -1px;
		}
		li {
			display: inline-block;
			margin-right: 21px;
			&:last-of-type {
				margin-right: 0;
			}
			@include sp{
				margin-bottom: 7px;
				display: block;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		&--2 {
			> li {
				margin-bottom: 10px;
				display: list-item;
			}
			input[type="text"] {
				display: inline-block;
				position: static;
			}
		}
		input {
			position: absolute;
			display: none;
		}
		input + label {
			position: relative;
			display: block;
			padding-left: 23px;
			cursor: pointer;
			vertical-align: middle;
		}
		input + label:hover:before {
			animation-duration: 0.4s;
			animation-fill-mode: both;
			animation-name: hover-color;
		}
		input + label:before {
			position: absolute;
			top: 48%;
			left: 0;
			transform: translateY(-50%);
			display: inline-block;
			width: 14px;
			height: 14px;
			content: '';
			border: 1px solid #58595B;

			@include sp {
				top: 11px;
			}
		}
		input + label:after {
			position: absolute;
			display: none;
			content: '';
		}
		input[disabled] + label:hover:before {
			border: 1px solid #e4e4e4;
			animation-name: none;
		}
		input[disabled] + label:before {
			border-color: #e4e4e4;
		}
		input:checked + label:before {
			animation-name: none;
			background: #9ac985;
			border: 1px solid #9ac985 !important;
		}
		input:checked + label:after {
			display: block;
		}
		input + label:before {
			border-radius: 50%;
		}
		input + label:after {
			top: 48%;
			left: 4px;
			transform: translateY(-50%);
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: #fff;

			@include sp {
				top: 11px;
			}
		}
		input:checked[disabled] + label:before {
			border: 1px solid #c9e2f9;
		}
		input:checked[disabled] + label:after {
			background: #c9e2f9;
		}
	}
	&__radio3 {
		li {
			display: block;
		}
		input + label:before {
			top: 13px;
			@include sp {
				top: 11px;
			}
		}
		input:checked + label:before {
			background: #af8ebe;
			border: 1px solid #af8ebe !important;
		}
		input + label:after {
			top: 13px;
			@include sp {
				top: 11px;
			}
		}
	}
	&__checkbox01 li{
		float: left;
		margin-right: 25px;
	}
	&__checkbox02{
		overflow: hidden;
		li{
			font-weight: normal;
			margin-right: 40px;
			float: left;
		}
		@include sp{
			padding-bottom: 0;
			li{
				width: 100%;
				float: none;
			}
		}
	}
	.u-style01 {
		margin-top: 18px;
		border-top: 1px solid #efefef;
		@include sp {
			margin-top: 15px;
			border-top: 1px solid #b2b2b2;
		}
	}
	.u-style02 {
		.c-table2--note2 {
			margin-top: 6px;
			font-size: 1.4rem;
			font-weight: bold;
			color: #f22727;
			@include sp{
				font-size: 1.1rem;
			}
		}
		dt {
			background: rgba(242, 39, 39, 0.2);
		}
		input {
			background: rgba(242, 39, 39, 0.2);
		}
	}
}

.c-table3 {
	dl {
		@include sp {
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #D9D9D9;
            font-size: 1.4rem;
        }
		@include pc {
            padding-top: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid #D9D9D9;
            display: grid;
            grid-template-columns: 400px 1fr;
            font-size: 1.8rem;
		}
	}
	dt {
        font-weight: 700;
        @include sp {
            padding: 10px 0;
        }
        @include pc {
            padding: 25px 30px;
        }
        label {
            display: flex;
            column-gap: 10px;
            align-items: center;
            @include pc {
                column-gap: 20px;
            }
        }
	}
	dd {
		@include sp {
            padding-top: 15px;
		}
		@include pc {
		}
	}
	&--require,
	&--any {
        padding-left: 2.5px;
        padding-right: 2.5px;
        border-radius: 1px;
        display: inline-block;
        font-size: 1rem;
        font-weight: 700;
        line-height: 15px;
        @include pc {
            padding-left: 6px;
            padding-right: 6px;
            border-radius: 2px;
            font-size: 1.2rem;
            line-height: 20px;
        }
	}
    &--require {
        background-color: #F43;
        color: #fff;
    }
    &--any {
        background-color: #888;
        color: #fff;
    }
	&--question {
        min-width: 21px;
        width: 21px;
        height: 21px;
        @include pc {
            min-width: 32px;
            width: 32px;
            height: 32px;
        }
        svg {
            width: 100%;
            height: 100%;
        }
	}
	&--note1 {
        margin-top: 5px;
        display: flex;
        column-gap: 2.5px;
        align-items: baseline;
        font-size: 1.2rem;
        &::before {
            content: "※";
        }
        @include pc {
            margin-top: 10px;
            font-size: 1.4rem;
        }
	}
    &--errorMessage {
        margin-top: 15px;
        color: #FF4433;
        font-size: 1.2rem;
        font-weight: 700;
        @include pc {
            margin-top: 20px;
            font-size: 1.8rem;
        }
    }
}

.c-table4 {
    dl {
        font-size: 1.4rem;
        &:last-of-type {
            border-bottom: 1px solid #D9D9D9;
        }
        @include pc {
            border: 1px solid #D9D9D9;
            border-bottom: none;
            display: grid;
            grid-template-columns: 400px 1fr;
            font-size: 1.8rem;
        }
    }
    dt, dd {
        padding: 15px 20px;
        @include pc {
            padding: 20px 30px;
        }
    }
    dt {
        background-color: #EFEFEF;
        font-size: 1.6rem;
        font-weight: 700;
        @include sp {
            border: 1px solid #D9D9D9;
        }
        @include pc {
            font-size: 1.8rem;
        }
    }
    dd {
        @include sp {
            border-left: 1px solid #D9D9D9;
            border-right: 1px solid #D9D9D9;
        }
        @include pc {
        }
    }
}
