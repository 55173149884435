@use '../../0_base' as *;

/*------------------------------------------------------------
お電話でのお問い合わせ
------------------------------------------------------------*/
.p-serviceContact {
	padding: 52px 0 60px;
	@include sp {
		padding: 85px 20px 70px;
	}
	.c-breadcrumb1{
		padding-bottom: 32px;
		a {
			color: $linkColor01;
		}
	}
	&__title {
		margin-bottom: 45px;
		text-align: center;
		font-size: 3.2rem;
		color: $gray01;
		line-height: 1.35;
		@include sp {
			margin-bottom: 30px;
			font-size: 2.1rem;
		}
		span {
			display: block;
			font-size: 1.4rem;
			font-weight: normal;
			opacity: 0.5;
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
	&__title02 {
		margin-bottom: 30px;
		font-size: 2rem;
		color: #fff;
		padding: 16px 50px 12px 29px;
		cursor: pointer;
		position: relative;
		@include sp {
			font-size: 1.6rem;
			padding: 12px 32px 11px 10px;
			line-height: 1.6;
			margin-bottom: 20px;
		}
		&:after, &:before {
			content: '';
			background: #fff;
			position: absolute;
			top: 50%;
			transition: transform 0.25s ease-out;
			transform: translateY(-50%);
		}
		&:after {
			width: 24px;
			height: 3px;
			right: 20px;
			@include sp {
				width: 20px;
				height: 2px;
				right: 10px;
			}
		}
		&:before {
			width: 3px;
			height: 24px;
			right: 30px;
			@include sp {
				width: 2px;
				height: 20px;
				right: 19px;
			}
		}
		&.color1 {
			background: $ticketColor01;
		}
		&.color2 {
			background: $hokenColor01;
		}
		&.color3 {
			background: $reformColor01;
		}
		&.color4 {
			background: $kurashiColor01;
		}
		&.color5 {
			background: $sosaiColor01;
		}
		&.is-close {
			&:before {
				transform: translateY(-50%) rotate(-90deg);
			}
		}
	}
	&__title03 {
		font-size: 2rem;
		color: #669900;
		border-bottom: 2px solid #a4c661;
		padding-bottom: 11px;
		margin-bottom: 18px;
		@include sp {
			font-size: 1.8rem;
			padding-bottom: 5px;
			margin-bottom: 10px;
		}
	}
	&__txt {
		margin-bottom: 51px;
		@include sp {
			margin-bottom: 30px;
		}
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}
	&__txt01{
		margin-top: 6px;
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
		}
		@include sp {
			margin-top: 0;
			p{
				color: #fff;
				font-size: 1rem;
				line-height: 1.3;
			}
		}
	}
	&__txt02 {
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				margin-top: 15px;
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}
	&__tel {
		font-size: 3.4rem;
		font-weight: bold;
		color: $gray01;
		line-height: 1.6;
		span {
			font-size: 2.2rem;
			font-weight: normal;
			display: inline-block;
			margin-right: 40px;
			position: relative;
			&:after {
				content: '';
				width: 28px;
				height: 26px;
				background: url("../img/common/icon_tel05.png") no-repeat;
				position: absolute;
				top: -1px;
				right: -30px;
			}
		}
		p{
			display: inline-block;
		}
	}
	&__list {
		margin-bottom: 10px;
		padding-left: 21px;
		@include sp {
			margin: 10px 0 5px;
			padding-left: 15px;
		}
		li {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 1.6;
			padding-left: 18px;
			position: relative;
			margin-top: 8px;
			@include sp {
				font-size: 1.4rem;
				margin-top: 5px;
			}
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				background: #669900;
				border-radius: 100px;
				position: absolute;
				top: 8px;
				left: 0;
				@include sp {
					width: 6px;
					height: 6px;
				}
			}
		}
		a {
			color: $linkColor01;
			text-decoration: underline;
		}
	}
	&__content {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-bottom: 20px;
		@include sp {
			display: block;
			padding-bottom: 15px;
		}
	}
	&__card {
		margin-bottom: 20px;
		width: 550px;
		background: $glaylight03;
		padding: 34px 39px 30px;
		@include sp {
			width: 100%;
			padding: 15px 15px 17px;
			a{
				width: 100%;
				display: block;
				margin-top: 10px;
				background: #4b5870;
			    border: 2px solid #939ba9;
			    padding: 12px 10px 8px;
				.c-contactBtn__txt03{
					display: table;
					margin: 0 auto;
					border: none;
					padding: 0;
					text-align: center;
					.txt-sp{
						padding: 0 0 5px 0;;
					}
				}
			}
		}
	}
	&__btn{
		display: table;
		margin: 30px auto;
		a {
			color: $gray01;
			padding: 10px 60px;
			font-size: 2.4rem;
			font-weight: bold;
			line-height: 1.3;
			text-align: center;
			background-color: #ffffff;
			background-image: url("../img/common/icon_arrow-green04.png");
			-webkit-background-size: 12px;
			background-size: 12px;
			background-repeat: no-repeat;
			background-position: 95% center;
			border: solid 2px $keycolor02;
			border-radius: 50px;
			transition: 0.3s ease-in-out;
			&:before {
				margin: -6px 9px 0 -38px;
				content: '';
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		@include sp{
			margin: 0 auto;
			a{
				font-size: 1.6rem;
			}
		}
	}
}