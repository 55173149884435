@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　斎場情報詳細
------------------------------------------------------------*/
.p-sosaiHallDetail {
	background-color: #ffffff;
}

//葬儀場を探す
.p-sosaiHallDetail01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	margin-bottom: 7px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
		img{
   			margin: 8px auto 0;
		}
	}
}

//詳細
.p-sosaiHallDetail02 {
	margin-bottom: 162px;
	&__content01 {
		margin-bottom: 40px;
		&__slideArea {
			&__slide {
				width: 790px;
				.slick-slider {
					margin: 0;
				}
				.slick-slide {
					position: relative;
					span {
						width: 100%;
						padding: 15px 20px;
						font-size: 1.4rem;
						color: #ffffff;
						background-color: rgba(0,0,0,0.8);
						position: absolute;
						bottom: 0;
						left: 0;
					}
					img {
						width: 100%;
						max-height: 380px;
						min-height: 380px;
						height: 100%;
						object-fit: cover;
						font-family: 'object-fit: cover;'; /*IE対策*/
					}
				}
				.slick-prev {
					width: 20px;
					height: 40px;
					left: 20px;
					z-index: 10;
					&:before {
						width: 20px;
						height: 40px;
						content: '';
						display: block;
						background-image: url("../img/common/icon_arrow-white04.png");
						-webkit-background-size: cover;
						background-size: cover;
						opacity: 1 !important;
						transform: rotate(180deg);
					}
				}
				.slick-next {
					width: 20px;
					height: 40px;
					right: 20px;
					z-index: 10;
					&:before {
						width: 20px;
						height: 40px;
						content: '';
						display: block;
						background-image: url("../img/common/icon_arrow-white04.png");
						-webkit-background-size: cover;
						background-size: cover;
						opacity: 1 !important;
					}
				}
			}
			&__thumbnail {
				width: 300px;
				padding: 20px 20px 0 20px;
				background-color: $subColor17;
				li {
					margin-bottom: 20px;
					margin-right: 20px;
					max-height: 100px;
					overflow: hidden;
					float: left;
					&:nth-child(odd) {
						margin-right: 20px;
						@include sp {
							margin-right: 0;
						}
					}
					@include sp {
						&:not(:nth-child(3n-1)) {
							margin-right: 5%;
						}

					}
					img {
						width: 120px;
						height: 100px;
						object-fit: cover;
						font-family: 'object-fit: cover;'; /*IE対策*/
					}
					&.is-active{
						display: none;
					}
					.slick-track{
						width: auto !important;
						left: 0 !important;
					}
					.slick-list{
						display: flex !important;
					}
				}
			}
		}
	}
	&__content02 {
		margin-bottom: 40px;
		table {
			width: 100%;
			margin-bottom: 30px;
			border-collapse: collapse;
			border-top: dotted 1px $gray08;
			font-size: 1.7rem;
			line-height: 1.5;
			color: $gray08;
			tr {
				&:nth-child(odd) {
					background-color: $glaylight03;
				}
				&:nth-child(2) {
					td{
						padding: 27px 0 25px;
					}
				}
			}
			th {
				width: 218px;
				padding: 23px 10px 22px 28px;
				text-align: left;
				vertical-align: middle;
				border-bottom: dotted 1px $gray08;
				&:before {
					width: 8px;
					height: 8px;
					margin: -4px 10px 0 0;
					content: '';
					background-color: $sosaiBtnColor01;
					display: inline-block;
					vertical-align: middle;
				}
			}
			td {
				padding: 23px 30px 22px 0;
				vertical-align: middle;
				border-bottom: dotted 1px $gray08;
				font-weight: 500;
				line-height: 1.45;
				.c-sosaiBtn04 {
					margin-left: 20px;
				}
			}
		}
	}
	&__content03 {
		&__box{
			width: 854px;
			margin: 80px auto;
		}
		&__youtube {
			width:100%;
			padding-bottom: 56.25%;
			height:0px;
			position: relative;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		&__btn {
			margin: 0 auto;
			display: table;
			.c-sosaiBtn05 {
				width: 500px;
				margin-right: 20px;
				text-align: center;
				float: left;
			}
			.c-sosaiBtn03 {
				width: 500px;
				text-align: center;
				float: right;
			}
		}
	}
}

@include sp {
	.p-sosaiHallDetail {
	}

	//葬儀場を探す sp
	.p-sosaiHallDetail01 {
		min-width: 100%;
		margin-bottom: 36px;
		padding: 32px 0 33px;;
	}

	//詳細
	.p-sosaiHallDetail02 {
		margin-bottom: 52px;
		padding: 0 15px;
		&__content01 {
			margin-bottom: 35px;
			&__slideArea {
				&__slide {
					width: 100%;
					margin-bottom: 15px;
					.slick-slide {
						span {
							padding: 5px 11px;
							font-size: 1.2rem;
						}
						img {
							min-height: auto;
							height: 216px;
							width: 100%;
						}
					}
				}
				&__thumbnail {
					width: 100%;
					padding: 10px 10px 0 10px;
					display: flex;
					flex-wrap: wrap;
					li {
						width: 30%;
						margin-bottom: 10px;
						float: none;
						img {
							height: 68px;
						}
					}
				}
			}
		}

		&__content02 {
			margin-bottom: 20px;
			table {
				font-size: 1.4rem;
				margin-bottom: 26px;
				th {
					width: 37%;
					padding: 11px;
				}
				td {
					padding: 11px 0 15px 0;
					line-height: 1.3;
					.c-sosaiBtn04 {
						border: solid 1px #2e95a3;
						margin-left: 10px;
					}
				}
				tr{
					&:nth-child(2) {
						td{
							padding: 14px 0 15px 0;
						}
					}
				}
			}
		}
		&__content03 {
			&__box{
				width: 100%;
				margin: 35px 0;
			}
			&__youtube {
				width:100%;
				padding-bottom: 56.25%;
				height:0px;
				position: relative;
			}
			&__btn {
				display: block;
				.c-sosaiBtn05 {
					float: none;
					width: 100%;
					padding: 18px;
					margin: 0 0 10px 0;
					font-size: 1.5rem;
					a:before{
						margin: -2px 14px 0 0;
					}
				}
				.c-sosaiBtn03 {
					width: 100%;
					float: none;
					font-size: 1.5rem;
					padding: 18px;
					a{
						padding: 0;
						&:before{
							margin: -2px 17px 0 6px;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.p-sosaiHallDetail02__content01__slideArea__thumbnail{
	overflow: hidden;
	.slick-track{
		width: calc(100% + 20px) !important;
		left: inherit !important;
		li{
			margin-right: 0 !important;
			width: calc(50% - 20px);
			margin-right: 20px !important;
			@include sp{
				float: left !important;
				width: calc(33.333333% - 10px);
				margin-right: 10px !important;
			}
			img{
				width: 100% !important;
			}
		}

	}
}
