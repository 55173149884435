@use '../../0_base' as *;

/*------------------------------------------------------------
お客様本位の業務運営に関する方針
------------------------------------------------------------*/
.p-hokenCustomer {
    &__title02 {
        padding: 10px 15px;
        background-color: #E4F7FF;
        border-radius: 5px;
        @include pc {
            padding: 20px 30px;
            border-radius: 10px;
            font-size: 4rem;
        }
    }
    &__title03 {
        border-bottom: 1.5px solid #41B4E6;
        font-size: 2.2rem;
        font-weight: 700;
        @include pc {
            border-width: 3px;
            font-size: 3.2rem;
        }
    }
    &__title04 {
        display: flex;
        align-items: baseline;
        column-gap: 2.5px;
        color: #41B4E6;
        font-size: 1.6rem;
        font-weight: 700;
        @include pc {
            column-gap: 5px;
            font-size: 1.8rem;
        }

        &::before {
            content: "■";
        }
    }
	&__title05 {
        font-size: 1.9rem;
        color: #41B4E6;
        font-weight: 700;
        @include pc {
            font-size: 2.4rem;
        }
	}
	&__txt {
        font-size: 1.4rem;
        @include pc {
            font-size: 1.6rem;
        }
	}
	&__note {
		margin-top: 20px;
		p {
			font-size: 1.4rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				font-size: 1.2rem;
				line-height: 1.6;
			}
		}
	}
	&__list {
        @include pc {
            padding-left: 30px;
        }
        > li {
            display: flex;
            align-items: baseline;
        }
	}
	&__list02 {
        font-size: 1.6rem;
        @include pc {
            font-size: 1.8rem;
        }
		li {
            display: flex;
            align-items: baseline;
            column-gap: 2.5px;
			&:before {
                column-gap: 5px;
                content: counter(count01) '.';
                color: #41B4E6;
                font-weight: bold;
                @include pc {
                }
			}
		}
	}

	&__list03 {
        li {
            padding-left: 11px;
            position: relative;
            font-size: 1.4rem;
            line-height: 1.6;
            @include pc {
                padding-left: 18px;
                font-size: 1.6rem;
            }
            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background: #41B4E6;
                border-radius: 50%;
                position: absolute;
                top: 7px;
                left: 0;
                @include pc {
                    width: 8px;
                    height: 8px;
                    top: 8px;
                }
            }
        }
        a {
            color: #41B4E6;
            text-decoration: underline;
        }
	}

	&__box {
        padding: 15px;
		border: 1.5px solid #D9D9D9;
        border-radius: 5px;
        @include pc {
            padding: 30px;
            border-width: 3px;
            border-radius: 10px;
        }
	}
}
