@use '../../0_base' as *;

/*------------------------------------------------------------
チケットご利用者アンケート エラー画面
------------------------------------------------------------*/
.p-ticketEnqueteError {
  padding: 41px 0 133px;

  @include sp {
    padding: 19px 20px 35px;
  }

  &__formPrivacy {
		margin: 70px 0 50px;

    @include sp {
      margin: 40px 0 30px;
    }
  }

  &1 {

    .c-formText01 {
      margin-top: 40px;

      @include sp {
        margin-top: 28px;
      }
    }

		.c-accordion {
			margin-top: 11px;
		}
  }

  &2 {

    .c-step3 {
			margin-top: 50px;
			margin-bottom: 80px;

			@include sp {
				margin-top: 25px;
				margin-bottom: 40px;
			}
		}

    .c-form1 {
      &__row {
        .c-form1__title {
          &.mt22 {
            margin-top: 22px;
          }
        }

        &:last-child {
          margin-bottom: 70px;

          @include sp {
            margin-bottom: 41px;
          }
        }
      }

      &__title {
        &.is-block {
          display: block;
        }
      }

      &__select {
        max-width: 280px;
        font-size: 1.6rem;

        @include sp {
          max-width: 100%;
          font-size: 1.4rem;
        }
      }

      &__input__note {
        color: $gray02;
        font-weight: 500;
      }

      .c-ticketText01 {
        font-size: 1.6rem;
        font-weight: 500;
        margin-top: 5px;
        display: inline-block;

        @include sp {
          padding-left: 23.08px;
          font-size: 1.4rem;
        }
      }
    }
  }
}
