@use '../../0_base' as *;

/*------------------------------------------------------------
個人情報保護方針
------------------------------------------------------------*/
.p-servicePrivacy {
	padding: 52px 0 90px;
	@include sp {
		padding: 85px 20px 100px;
	}
	.c-breadcrumb1{
		padding-bottom: 32px;
		a {
			color: $linkColor01;
		}
	}
	&__title {
		margin-bottom: 53px;
		text-align: center;
		font-size: 3.2rem;
		color: $gray01;
		line-height: 1.35;
		@include sp {
			margin-bottom: 30px;
			font-size: 2.1rem;
		}
		span {
			display: block;
			font-size: 1.4rem;
			font-weight: normal;
			opacity: 0.5;
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
	&__title02 {
		font-size: 3.2rem;
		color: $gray01;
		padding: 35px 40px 29px;
		background: url("../img/service/bg03.jpg");
		position: relative;
		margin-bottom: 23px;
		@include sp {
			font-size: 2.1rem;
			width: calc(100% + 40px);
			margin-left: -20px;
			padding: 35px 20px 29px;
			line-height: 1.6;
			margin-bottom: 20px;
		}
		&:before {
			content: '';
			width: 100%;
			height: 4px;
			background: url("../img/service/line_colorful01_pc.jpg");
			position: absolute;
			top: 0;
			left: 0;
			@include sp {
				background-size: 120px;
			}
		}
	}
	&__title03 {
		background: #a4c661;
		font-size: 2.4rem;
		color: #fff;
		padding: 16px 28px 15px;
		margin-top: 50px;
		@include sp {
			width: calc(100% + 40px);
			margin-left: -20px;
			font-size: 1.9rem;
			line-height: 1.6;
			padding: 13px 20px 12px;
			margin-top: 40px;
		}
	}
	&__title04 {
		font-size: 2rem;
		color: #669900;
		border-bottom: 2px solid #a4c661;
		padding-bottom: 14px;
		margin-bottom: 20px;
		@include sp {
			font-size: 1.8rem;
			padding-bottom: 5px;
			margin-bottom: 15px;
		}
	}
	&__txt {
		margin-top: 22px;
		@include sp {
			margin-top: 20px;
		}
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
		p + p {
			margin-top: 33px;
			@include sp {
				margin-top: 20px;
			}
		}
		.u-right {
			text-align: right;
			margin-top: 21px;
		}
	}
	&__list {
		margin: 6px 0 0;
		list-style-type: decimal;
		padding-left: 37px;
		@include sp {
			padding-left: 25px;
			margin: 8px 0;
		}
		li {
			font-size: 1.6rem;
			color: $gray01;
			margin-bottom: 7px;
			padding-left: 14px;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
				padding-left: 0;
			}
		}
		&--02{
			list-style-type: lower-alpha;
			padding-left: 89px;
			@include sp{
				padding-left: 62px;
			}
		}
	}
	&__list02 {
		margin: 5px 0 0;
		padding-left: 20px;
		@include sp {
			padding-left: 10px;
		}
		li {
			margin-bottom: 10px;
			padding-left: 18px;
			position: relative;
			@include sp {
				padding-left: 15px;
				margin-bottom: 8px;
			}
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				background: #669900;
				border-radius: 100px;
				position: absolute;
				top: 8px;
				left: 0;
				@include sp {
					width: 6px;
					height: 6px;
				}
			}
		}
		a {
			text-decoration: underline;
			font-size: 1.6rem;
			color: $linkColor01;
			@include sp {
				font-size: 1.4rem;
			}
		}
	}
	&__list03{
		padding-left: 10px;
		@include sp {
			padding-left: 0;
		}
		li {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			display: flex;
			span {
				display: block;
				font-weight: bold;
			}
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}
	&__box {
		background: $glaylight03;
		padding: 34px 40px 32px;
		margin: 21px 0 60px;
		@include sp {
			padding: 14px 20px 13px;
			margin: 30px 0 0;
		}
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
		a {
			text-decoration: underline;
			color: $linkColor01;
		}
	}
	&__list04 {
		margin: 5px 0 0;
		padding-left: 20px;
		@include sp {
			padding-left: 10px;
		}
		li {
			margin-bottom: 10px;
			padding-left: 18px;
			position: relative;
			list-style: none;
			font-size: 1.4rem;
			color: #46483c;
			line-height: 2;
			@include sp {
				padding-left: 15px;
				margin-bottom: 8px;
				font-size: 1.4rem;
    			line-height: 1.6;
			}
			&:before {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 0 6px 8px;
				border-color: transparent transparent transparent #669900;
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -6px;
				@include sp {
					width: 6px;
					height: 6px;
				}
			}
		}
		a {
			text-decoration: underline;
			font-size: 1.4rem;
			color: $linkColor01;
			@include sp {
				font-size: 1.4rem;
			}
		}
		.font16 {
			font-size: 1.6rem;
		}
	}
}
