@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　商品一覧ページ
------------------------------------------------------------*/
.p-reformSample {
	background-color: #ffffff;
}

// 商品一覧ページ
.p-reformSample01 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior05.jpg");
		}
	}
}


//商品一覧
.p-reformSample02 {
	margin-bottom: 100px;
	&__content01 {
		.c-refomeTitle03{
			margin-bottom: 30px;
		}
	}
	.c-reformServiceItemList01__items {
		li {
			margin-bottom: 25px;
		}
	}
}

@include sp {

	// 商品一覧ページ　sp
	.p-reformSample01 {
		margin-bottom: 28px;
		&__content01 {
			.c-refomeTitle02 {
				background-image: url("../img/reform/title_obi_interior05_sp.jpg");
			}
		}
	}

	//商品一覧 sp
	.p-reformSample02 {
		margin-bottom: 40px;
		padding: 0 20px;
		&__content01 {
			.c-reformServiceItemList01__imgs{
				margin-bottom: 11px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
