@use '../../0_base' as *;

/*------------------------------------------------------------
チケット 商品一覧
------------------------------------------------------------*/
.p-ticketService {
	background-color: #ffffff;
}

//チケットを探す
.p-ticketService01 {
	&__content01 {
		margin-bottom: 30px;
		padding-top: 35px;
		background-image: url("../img/ticket/line01.png");
		background-repeat: repeat-x;
		background-position: 0 0;
		p {
			//width: 240px;
			font-size: 2.0rem;
			font-weight: bold;
			color: $ticketNaviColor02;
			&:before {
				width: 18px;
				height: 18px;
				margin: -2px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_search01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		&__search {
			width: 570px;
			margin: -1px 42px 0;
			position: relative;
			input[type="text"] {
				width: 100%;
				height: 40px;
				padding: 0 130px 0 14px;
				font-size: 1.4rem;
				line-height: 2.57;
				color: $gray01;
				border: solid 1px rgba($ticketBtnColor05,0.5);
			}
			input[type="submit"] {
				width: 117px;
				height: 32px;
				display: block;
				border: none;
				background-image: url("../img/common/icon_search07.png");
				-webkit-background-size: cover;
				background-size: cover;
				position: absolute;
				top: 4px;
				right: 4px;
				@include ct;
			}
		}
	}
}

//商品一覧
.p-ticketService02 {
	margin-bottom: 130px;
	&__content01 {
		//コンポーネント　c-tiketItems02
		.c-ticketTitle04{
			margin-bottom: 30px;
		}
		.c-tiketItems02{
			&__item{
				margin-bottom: 40px;
				margin-right: 30px;
				&:nth-of-type(3n) {
					margin-right: 0;
				}
				a{
					box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.1);
				}
				&__img{
					//z-index: 2;
				}
				&__tags{
					top: 5px;
				}
				&__subCate {
					padding: 0 11px;
					margin-bottom: 5px;
				}
			}
		}
		.c-ticketPager01 {
			margin-top: -8px;
		}
	}
	&__content02 {
		//ページャー
	}
}



@include sp {
	.p-ticketService {
		padding: 30px 20px 0 20px;
	}

	//チケットを探す
	.p-ticketService01 {
		&__content01 {
			padding-top: 26px;
			margin-bottom: 25px;
			background-image: url("../img/ticket/line01_sp.png");
			-webkit-background-size: 15px;
			background-size: 15px;
			p {
				width: 100%;
				margin-bottom: 9px;
				font-size: 1.6rem;
				&:before {
					margin-right: 5px;
				}
			}
			&__search {
				width: 100%;
				margin: 0;
				input[type="text"] {
					padding: 4px 40px 4px 10px;
					font-size: 1.2rem;
					border-radius: 0;
				}
				input[type="submit"] {
					width: 32px;
					height: 32px;
					background-image: url("../img/common/icon_search06.png");
					border-radius: 0;
				}
			}
		}
	}

	//商品一覧
	.p-ticketService02 {
		margin-bottom: 35px;
		&__content01 {
			//コンポーネント　c-tiketItems02
			.c-ticketTitle04 {
				margin-bottom: 25px;
				padding-top: 31px;
			}
			.c-tiketItems02{
				&__item{
					margin-bottom: 20px;
					margin-right: 10px;
					&:nth-of-type(3n) {
						margin-right: 10px;
					}
					&:nth-of-type(2n) {
						margin-right: 0;
					}

					p{
						letter-spacing: 1px;
						margin-bottom: 5px;
					}
					&__subCate {
						padding: 0 10px;
						margin-bottom: 8px;
					}
				}
			}
			.c-ticketPager01 {
				margin-top: 5px;
			}
		}
		&__content02 {
			//ページャー
		}
	}
}

/*
------------------------------------------------------------*/
