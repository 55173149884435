@use '../../0_base' as *;

/*------------------------------------------------------------
保険　資料請求フォーム（カート）
------------------------------------------------------------*/
.p-hokenRequestStep {
    display: flex;
    column-gap: 4px;
    @include pc {
        column-gap: 12px;
    }
    li {
        padding-left: 8px;
        flex: 1;
        height: 50px;
        color: #888;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: relative;
        font-size: 1.2rem;

        @include pc {
            height: 80px;
            font-size: 1.8rem;
        }

        > span {
            position: relative;
            z-index: 1;
        }

        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 50%;
            background-color: #EFEFEF;
            position: absolute;
            left: 5px;
            pointer-events: none;
        }

        &::before {
            top: 0;
            transform: skewX(8deg);
            @include pc {
                transform: skewX(12deg);
            }
        }

        &::after {
            bottom: 0;
            transform: skewX(-8deg);
            @include pc {
                transform: skewX(-12deg);
            }
        }

        &:first-child {
            padding-left: 0;
            background-color: #EFEFEF;
            &.is-active {
                background-color: #41B4E6;
            }
        }

        &:last-child {
            padding-left: 4px;
            background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, #EFEFEF 50%);
            &::before,
            &::after {
                width: 50%;
            }
        }

        &.is-active {
            color: #fff;
            font-weight: 700;
            &::before,
            &::after {
                background-color: #41B4E6;
            }
            &:last-child {
                background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, #41B4E6 50%);
            }
        }
    }
}

.p-hokenRequestCart {
    &__title {
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: #41B4E6;
        font-size: 2.2rem;
        font-weight: 700;
        @include pc {
            column-gap: 10px;
            font-size: 3.2rem;
        }

        svg {
            min-width: 30px;
            width: 30px;
            height: 30px;
        }
    }
}

.p-hokenRequest {
	.c-hokenBlock01{
		&__title{
            color: #333;
            font-size: 1.9rem;
            font-weight: 700;
            @include pc {
                font-size: 2.4rem;
            }
		}

		&__txt{
            color: #333;
            font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }
		}

		&__text{
            padding: 15px;
            background-color: #FFEDE3;
            border-radius: 5px;
            display: flex;
            align-items: baseline;
            column-gap: 2.5px;
            font-size: 1.4rem;
            @include pc {
                padding: 30px;
                border-radius: 10px;
                column-gap: 5px;
                font-size: 1.6rem;
            }

            &::before {
                content: "※";
            }
		}
	}

	.c-hokenList01{
		&__title{
            padding: 15px;
            background-color: #41B4E6;
            border-radius: 5px;
            color: #fff;
            font-size: 1.9rem;
            font-weight: 700;
            position: relative;
            cursor: pointer;

            @include pc {
                padding: 30px;
                border-radius: 10px;
                font-size: 2.4rem;
            }

			&.is-active{
				.c-hokenList01__icon{
					&::before{
						transform: translate(-50%,-50%) rotate(90deg);
					}
				}
			}

			.c-hokenList01__txt{
				position: relative;
				z-index: 2;
			}
		}

		&__icon{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			width: 15px;
			height: 15px;

			@include pc{
                width: 30px;
                height: 30px;
                right: 30px;
			}

			&::before,
			&::after{
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background: #fff;
				transition: 0.3s ease-in-out;
                border-radius: 9999px;
			}

			&::before{
				height: 13px;
				width: 2px;
                @include pc{
                    height: 28px;
    			}
			}

			&::after{
				width: 13px;
				height: 2px;
                @include pc{
                    width: 28px;
                }
			}
		}

		&__content{
			overflow: hidden;
			display: none;
		}
	}

	.c-hokenList02{
        display: grid;
        row-gap: 15px;
        @include pc {
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

		&__item{
		}

		&__tooltip{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 280px;
			font-size: 1.6rem;
			font-weight: bold;
			color: #ffffff;
			background: #333333;
			text-align: center;
			border-radius: 4px;
			padding: 12px 0;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			transition: 0.3s ease-in-out;

			@include sp{
				font-size: 1rem;
				line-height: 14px;
				width: calc(100% - 35px);
				padding: 11px 0;
			}

			&--pink {
				background: #FF5B4C;
				width: 330px;

				@include sp{
					width: calc(100% - 35px);
				}
			}

			&.is-active{
				opacity: 1;
				visibility: visible;
			}
		}

		&__link{
            padding: 15px;
			position: relative;
			display: grid;
            grid-template-columns: 140px 1fr;
            gap: 15px;
			border: 1.5px solid #D9D9D9;
            border-radius: 5px;
			cursor: pointer;

            @include pc {
                padding: 30px;
                grid-template-columns: 211px 1fr;
                column-gap: 30px;
                row-gap: 10px;
                border-width: 3px;
                border-radius: 10px;
            }
		}

		&__img{
            @include pc {
                grid-column: 1/2;
                grid-row: 1/3;
            }
		}

		&__title{
            font-size: 1.6rem;
            font-weight: 700;
            @include pc {
                font-size: 2.4rem;
            }
		}

		&__btn{
            @include sp {
                padding-top: 9px;
                padding-bottom: 9px;
                grid-column: 1/3;
                grid-row: 2/3;
                font-size: 1.6rem;
            }
		}
	}

	.c-hoken__popup{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($color: #000000, $alpha: 0.5);
        z-index: 999;
		overflow: auto;
        padding: 40px 25px;
		display: none;

		@include pc{
            padding: 140px 0;
            z-index: 99;
		}

		&Close{
			width: 25px;
			height: 25px;
            position: absolute;
            top: 15px;
            right: 12.5px;
			cursor: pointer;
            @include pc {
                width: 50px;
                height: 50px;
                top: 30px;
                right: 40px;
            }
            svg {
                width: 100%;
                height: 100%;
            }
		}

		.p-hokenRequest03{
            margin: 0 auto;
            padding: 40px 25px;
			background: #ffffff;
            position: relative;

			@include pc {
                padding: 80px 100px;
                max-width: 1200px;
			}

            &__content01 {
                &__title {
                    display: flex;
                    column-gap: 5px;
                    align-items: center;
                    font-size: 1.9rem;
                    font-weight: 700;
                    @include pc {
                        column-gap: 10px;
                        font-size: 2.4rem;
                    }

                    &::before {
                        content: "";
                        min-width: 7.5px;
                        width: 7.5px;
                        height: 7.5px;
                        background-color: #41B4E6;
                        border-radius: 100%;
                        @include pc {
                            min-width: 12px;
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
                &__confirm {
                    display: grid;
                    row-gap: 10px;
                    @include pc {
                        grid-template-columns: 1fr 380px;
                        grid-template-rows: auto 1fr;
                        gap: 20px;
                    }
                    &__image {
                        img {
                            width: 100%;
                        }
                        @include pc {
                            grid-column: 2/3;
                            grid-row: 1/3;
                        }
                    }
                }
            }
            &__content02 {
                padding: 15px;
                background-color: #FFEDE3;
                border-radius: 5px;
                @include pc {
                    padding: 30px;
                }
                &__title {
                    margin-bottom: 5px;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #333;
                    font-size: 1.8rem;
                    font-weight: 700;
                    @include pc {
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                        font-size: 2rem;
                    }
                }

                li {
                    display: flex;
                    column-gap: 2.5px;
                    align-items: baseline;
                    font-size: 1.4rem;
                    &::before {
                        content: "※";
                    }

                    @include pc {
                        font-size: 1.6rem;
                    }
                }

                &__note {
                    p {
                        display: flex;
                        column-gap: 2.5px;
                        align-items: baseline;
                        font-size: 1rem;
                        @include pc {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
		}
	}
}

/*
------------------------------------------------------------*/
