@use '../../0_base' as *;

.p-anniversaryEnqueteMainVisual {
	margin-inline: auto;
	padding-inline: 20px;
	padding-top: 30px;
	padding-bottom: 40px;
	max-width: 640px;
	background: 
    url(../img/anniversary-enquete/mainVisual_deco01.svg) no-repeat left top / 111.5px auto,
    url(../img/anniversary-enquete/mainVisual_deco02.svg) no-repeat right top / 109.5px auto,
    url(../img/anniversary-enquete/mainVisual_deco03.svg) no-repeat calc(50% + 15px) 43px / 411px auto,
    url(../img/anniversary-enquete/mainVisual_bg01.png) no-repeat center center / cover;
	font-size: 1.6rem;

	@include sp {
		font-size: 1.3rem;
	}

	&__logo {
		text-align: center;
	}

	&__title {
		margin-top: 10px;
		margin-bottom: 10px;
		text-align: center;
	}

	&__present {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 10px;
	}

	&__text {
		margin-top: 10px;
	}

	&__text__link {
		display: flex;
		align-items: center;
		gap: 5px;
		color: inherit;
		text-decoration: underline;
	}

	&__newWindow {
		width: 13px;
		height: 13px;
		background: url(../img/anniversary-enquete/icon_newWindow_white.svg) no-repeat center center / contain;
        display: inline-block;
	}
}