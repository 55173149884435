@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　墓石
------------------------------------------------------------*/
.p-sosaiTombstone {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//墓石
.p-sosaiTombstone01 {
	min-width: $baseWidth01;
	padding: 43px 0;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		margin: 0 0 5px 0;
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//墓石 本文
.p-sosaiTombstone02 {
	margin-bottom: 127px;
	padding-top: 25px;
	&__content01 {
		min-height: 240px;
		padding-right: 445px;
		position: relative;
		> img {
			position: absolute;
			top: 8px;
			right: 0;
		}
		> p {
			margin-bottom: 30px;
			line-height: 3rem;
			a {
				color: $gray08;
				text-decoration: underline;
			}
		}
	}
}

//墓石選びのポイント
.p-sosaiTombstone03 {
	margin-bottom: 106px;
	padding: 107px 0 50px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	ol {
		padding-top: 53px;
		li {
			width: 353px;
			margin: 0 30px 70px 0;
			padding: 32px 40px;
			background-color: #ffffff;
			border: solid 2px $sosaiNaviColor02;
			&:nth-child(3) {
				margin: 0 0 70px 0;
				padding: 32px 20px;
				> img, h3{
					padding-left: 17px;
				}
				p{
					padding: 0 22px;
				}
			}
			span {
				margin-top: -83px;
				margin-bottom: 32px;
				display: block;
				text-align: center;
			}
			> img {
				margin: 0 auto 29px auto;
				display: block;
			}
			h3 {
				margin-bottom: 20px;
				font-size: 2.6rem;
				font-weight: 500;
				line-height: 1.15;
				color: $sosaiNaviColor02;
				text-align: center;
				@include mincyo;
			}
			p {
				text-align: left;
				line-height: 3rem;
				b {
					font-weight: bold;
				}
			}
		}
	}
}

//お問い合わせ
.p-sosaiTombstone04 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiAfterServiceContact >div a span{
		margin-left: -50px
	}
}

//葬儀後のサービス
.p-sosaiTombstone05 {
	padding: 107px 0 118px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
}



@include sp {
	.p-sosaiTombstone {
		font-size: 1.6rem;
	}
	//墓石 sp
	.p-sosaiTombstone01 {
		min-width: 100%;
		margin-bottom: 49px;
		padding: 15px 0;
		> span {
			font-size: 1.2rem;
		}
		.c-sosaiPagetitleCate{
			span{
				font-size: 1rem;
				line-height: 1.6rem;
				margin-top: 9px;
				margin-right: 0;
				height: 16px;
				width: 36px;
			}
		}
	}
	
	//墓石 本文 sp
	.p-sosaiTombstone02 {
		margin-bottom: 48px;
		padding: 0 20px;
		&__content01 {
			padding-right: 0;
			position:static;
			> img {
				margin: 0 auto 16px;
				display: block;
				position: static;
			}
			> p{
				font-size: 1.4rem;
				line-height: 1.7;
				margin-bottom: 0;
			}
		}
	}
	
	//墓石選びのポイント sp
	.p-sosaiTombstone03 {
		margin-bottom: 47px;
		padding: 48px 20px 49px;
		.c-sosaiTitle02b{
			margin-bottom: 39px;
		}
		ol {
			margin: 20px 0 0;
			padding-top: 20px;
			li {
				width: 100%;
				height: auto;
				margin-bottom: 39px;
				padding: 18px 18px 15px;
				font-size: 1.3rem;
				&:nth-child(3){
					margin-bottom: 0;
					padding: 18px 18px 15px;
					> img, h3{
						padding-left: 0;
					}
					p{
						padding: 0;
					}
				}
				span {
					margin-top: -50px;
					margin-bottom: 21px;
					display: block;
					text-align: center;
					img {
						width: 60px;
					}
				}
				> img {
					margin-bottom: 18px;
				}
				h3 {
					margin-bottom: 14px;
					font-size: 2.0rem;
				}
				p{
					font-size: 1.4rem;
					line-height: 1.7;
				}
			}
		}
		
		
	}
	
	//お問い合わせ sp
	.p-sosaiTombstone04 {
		margin-bottom: 50px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 29px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiAfterServiceContact{
			a{
		 		> div{
		 			padding: 8px 0 9px 81px;
		 			&:before{
		 				top: 9px;
		 			}
		 		}
			}
		}
	}
	
	//葬儀後のサービス sp
	.p-sosaiTombstone05 {
		padding: 47px 20px 55px;
		margin-bottom: 60px;
		.c-sosaiTitle02b{
			margin-bottom: 34px;
			span{
				&:after{
					margin: 11px auto 0;
				}
			}
		}
	}
	
}

/*
------------------------------------------------------------*/
