@use '../../0_base' as *;

/*------------------------------------------------------------
保険加入のメリット
------------------------------------------------------------*/
.p-hokenMerit01{
	&__list{
        &__title {
            margin-bottom: 20px;
            font-size: 1.9rem;

            @include pc {
                font-size: 2.4rem;
            }
        }
	}
}

.p-hokenMerit03{
    &__item {
        &__avatar {
            img {
                width: 53px;
                height: 53px;

                @include pc {
                    width: 68px;
                    height: 68px;
                }
            }
        }
    }
}

/*
------------------------------------------------------------*/
