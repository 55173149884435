@use '../../0_base' as *;

/*------------------------------------------------------------
保険TOP
------------------------------------------------------------*/
.p-hokenTopFirstView {
    padding-top: 13px;
    margin-bottom: 15px;
    background: url("/img/hoken/top/first_view_bg01@sp.png") no-repeat center top;
    background-size: 375px auto;

    @include pc {
        margin-bottom: 60px;
        padding-top: 38px;
        background: url("/img/hoken/top/first_view_bg01@pc.jpg") no-repeat center bottom;
    }

    &__message {
        margin-bottom: 10px;
        color: #333;
        font-size: 0.95rem;
        font-weight: bold;
        text-align: center;

        @include pc {
            margin-bottom: 40px;
            font-size: 1.6rem;
        }
    }

    &__content {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;

        @include pc {
            margin-top: 42px;
            max-width: none;
            display: block;
        }
    }

    &__title {
        margin-bottom: 15px;
        width: 100%;
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        column-gap: 11px;

        @include pc {
            margin-bottom: 40px;
            column-gap: 20px;
            font-size: 6rem;
        }

        &::before {
            content: "";
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #82cdf3 url("../img/common/icon_hoken01.png") no-repeat center;
            background-size: 30px 30px;

            @include pc {
                width: 90px;
                height: 90px;
                background-size: 50px 50px;
            }
        }
    }

    &__benefits {
        width: 165px;

        @include pc {
            width: 425px;
        }

        &__title {
            margin-left: auto;
            margin-right: auto;
            padding: 5px 15px;
            width: fit-content;
            background-color: #08336E;
            border-radius: 5px;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            position: relative;
            z-index: 1;

            @include pc {
                padding: 15px;
                border-radius: 10px;
                font-size: 2.4rem;
            }
        }

        &__list {
            margin-top: -1.4rem;
            padding-top: 18px;
            padding-bottom: 10px;
            background-color: #fff;
            border: 1.5px solid #08336E;
            border-radius: 5px;

            @include pc {
                margin-top: -3.3rem;
                padding: 24px 30px 10px;
                border-width: 3px;
                border-radius: 10px;
            }
        }

        &__item {
            text-align: center;

            @include pc {
                padding-top: 32px;
                padding-bottom: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: left;
                &:not(:first-child) {
                    border-top: 1px solid #D9D9D9;
                }
            }

            &:not(:first-child)::before {
                margin-top: 2.5px;
                margin-bottom: 2.5px;
                content: "";
                margin-left: 15px;
                margin-right: 15px;
                width: calc(100% - 30px);
                height: 1px;
                background-color: #D9D9D9;
                display: block;

                @include pc {
                    display: none;
                }
            }

            &__1 {
                color: #333;
                font-size: 1.2rem;

                @include pc {
                    font-size: 1.6rem;
                }
            }

            &__2 {
                color: #41B4E6;
                font-size: 1.6rem;
                font-weight: bold;

                @include pc {
                    margin-right: -.4em;
                    font-size: 3.4rem;
                }
            }
        }
    }

    &__image {
        flex: 1;
        display: flex;
        align-items: flex-end;
        @include pc {
            display: none;
        }
    }
}

.p-hokenTopPickup {
    margin-top: 15px;

    @include pc {
        margin-top: 80px;
    }
}

.p-hokenTopSearch {
    margin-top: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #E4F7FF;

    @include pc {
        margin-top: 40px;
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.p-hokenSearch {
    &__tabs {
        display: flex;
        column-gap: 1.5px;

        li {
            flex: 1;
        }

        @include pc {
            column-gap: 3px;
        }
    }

    &__tab {
        padding: 14px 0 9px;
        width: 100%;
        background-color: #41B4E6;
        border: none;
        color: #fff;
        font-weight: bold;
        border-radius: 5px 5px 0 0;
        border-top: 2.5px solid #41B4E6;
        cursor: pointer;

        @include pc {
            &:hover {
                transition: .3s ease-in-out;
                opacity: .8;
            }
        }

        &[data-active="true"] {
            background-color: #fff;
            color: #41B4E6;
        }

        &__1 {
            font-size: 1.4rem;
            line-height: 1.2;
        }
        &__2 {
            font-size: 1rem;
            line-height: 1.5;
        }

        @include pc {
            padding-top: 19px;
            padding-bottom: 19px;
            border-radius: 10px 10px 0 0;
            border-top-width: 5px;
            display: flex;
            justify-content: center;
            align-items: baseline;
            column-gap: 10px;

            &__1 {
                font-size: 1.8rem;
            }
            &__2 {
                font-size: 1.6rem;
            }
        }
    }

    &__content {
        padding: 25px 12.5px;
        background-color: #fff;

        @include pc {
            padding: 40px;
        }

        &[data-active="true"] {
            display: block;
        }

        &[data-active="false"] {
            display: none;
        }
    }

    &__index {
        display: grid;
        row-gap: 5px;

        @include pc {
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }
    }

    &__companyIndex {
        display: grid;
        row-gap: 5px;
        @include pc {
            row-gap: 40px;
        }
    }

    &__index__categoryName {
        margin-bottom: 5px;
        font-size: 1.8rem;
        font-weight: bold;

        @include pc {
            margin-bottom: 20px;
            font-size: 2rem;
        }
    }

    &__index__categoryButton,
    &__index__purposeButton,
    &__index__companyButton {
        background: #FFF;
        border-radius: 9999px;
        border: 1.5px solid #D9D9D9;
        color: #333;
        @include pc {
            border-width: 3px;
        }

        &:hover {
            border-color: #41B4E6;
        }

        &[data-selected="true"] {
            border-color: #41B4E6;
        }
    }

    &__index__categoryButton {
        padding: 10px 20px;
        background: #FFF;
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;

        @include pc {
            padding: 0 40px;
            height: 80px;
            column-gap: 20px;
            font-size: 1.6rem;
        }

        &__label {
            flex: 1
        }

        &__icon1 {
            min-width: 20px;
            width: 20px;
            height: 20px;
            color: #41B4E6;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 100%;
                height: 100%;
                fill: currentColor;
            }

            @include pc {
                min-width: 40px;
                width: 40px;
                height: 40px;
            }
        }

        &__icon2 {
            min-width: 12px;
            width: 12px;
            height: 14px;
            color: #41B4E6;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        @include pc {
            &__icon1 {
                min-width: 40px;
                width: 40px;
                height: 40px;
            }
            &__icon2 {
                min-width: 24px;
                width: 24px;
                height: 24px;
            }
        }
    }

    &__index__purposeButton {
        padding: 10px 20px;
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;

        @include pc {
            padding: 0 40px;
            height: 112px;
            column-gap: 20px;
            font-size: 1.6rem;
        }

        &__label {
            flex: 1
        }

        &__icon1 {
            min-width: 20px;
            width: 20px;
            height: 20px;
            color: #41B4E6;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 100%;
                height: 100%;
                fill: currentColor;
            }

            @include pc {
                min-width: 40px;
                width: 40px;
                height: 40px;
            }
        }

        &__icon2 {
            min-width: 12px;
            width: 12px;
            height: 14px;
            color: #41B4E6;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        @include pc {
            &__icon1 {
                min-width: 40px;
                width: 40px;
                height: 40px;
            }
            &__icon2 {
                min-width: 24px;
                width: 24px;
                height: 24px;
            }
        }
    }

    &__index__companyButton {
        padding-right: 20px;
        padding-left: 20px;
        height: 80px;
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;

        @include pc {
            padding-right: 20px;
            padding-left: 40px;
            font-size: 1.6rem;
            height: 140px;
            column-gap: 20px;
        }

        &__image {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                object-fit: contain;

                @include sp {
                    height: 40px;
                }

                @include pc {
                    height: 60px;
                }
            }

            &--4 img {
                height: 62px;
                @include pc {
                    height: 94px;
                }
            }

            &--8 img {
                height: 30px;
                @include pc {
                    height: 45px;
                }
            }

            &--15 img {
                @include pc {
                    height: 65px;
                }
            }
        }

        &__icon2 {
            min-width: 12px;
            width: 12px;
            height: 14px;
            color: #41B4E6;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        @include pc {
            &__icon1 {
                min-width: 40px;
                width: 40px;
                height: 40px;
            }
            &__icon2 {
                min-width: 24px;
                width: 24px;
                height: 24px;
            }
        }
    }
}

//バナーセクション1
.p-hokenTopBannerSection01 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;

    @include pc {
        column-gap: 20px;
    }
}

.p-hokenTopBanner01 {
    //padding-left: 15px;
    //padding-right: 15px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    border-radius: 9999px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;

    @include pc {
        padding: 30px;
        height: auto;
        column-gap: 20px;
        justify-content: center;
        font-size: 2.4rem;
    }

    &--soudan {
        background-color: #FA8246;
    }

    &--request {
        background-color: #41B4E6;
    }

    &__icon {
        min-width: 25px;
        width: 25px;
        height: 25px;
        fill: #fff;

        @include pc {
            min-width: 30px;
            width: 30px;
            height: 30px;
        }
    }

    &__label {
        @include sp {
            //flex: 1;
            text-align: center;
        }
    }
}

//バナーセクション2
.p-hokenTopBannerSection02 {
    margin-top: 40px;
    display: grid;
    row-gap: 40px;

    @include pc {
        margin-top: 80px;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
    }
}

.p-hokenTopBanner02 {
    padding: 15px;
    border-radius: 5px;
    border: 1.5px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    @include pc {
        padding: 40px;
        border-radius: 10px;
        border-width: 3px;
        row-gap: 20px;
    }

    &__title {
        font-size: 2.2rem;
        font-weight: bold;

        @include pc {
            font-size: 3.2rem;
        }
    }

    &__img01 {
        @include pc {
            order: 3;
        }
    }

    &__description {
        color: #222;
        font-size: 1.4rem;

        @include pc {
            font-size: 1.6rem;
            order: 2;
        }
    }

    &__link {
        @include pc {
            order: 4;
            flex: 1;
            display: flex;
            align-items: flex-end;
        }
    }
}

.p-hokenTopBanner03 {
    padding: 15px;
    border-radius: 5px;
    border: 1.5px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    @include pc {
        padding: 40px;
        border-radius: 10px;
        border-width: 3px;
        row-gap: 20px;
    }

    &__title {
        font-size: 2.2rem;
        font-weight: bold;
        @include pc {
            font-size: 3.2rem;
        }
    }

    &__img01 {
        padding: 15px;
        background-color: #E4F7FF;
        @include pc {
            padding: 20px;
        }

        &__title {
            margin-bottom: 10px;
            font-size: 1.8rem;
            font-weight: bold;
            @include pc {
                margin-bottom: 20px;
                font-size: 2rem;
            }
        }

        img {
            cursor: pointer;
        }
    }
}

//よくあるご質問
.p-hokenTopFaq {
    margin-top: 40px;
    @include pc {
        margin-top: 80px;
    }

    .p-hokenFaq__box + .p-hokenFaq__box {
        margin-top: 10px;

        @include pc {
            margin-top: 20px;
        }
    }

    &__more {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;

        @include pc {
            max-width: 590px;
            margin-top: 40px;
        }
    }
}

//おすすめコンテンツ
.p-hokenTopRecommend {
    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 30px;

    @include pc {
        margin-top: 80px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        @include pc {
            grid-template-columns: repeat(3, 1fr);
            gap: 40px;
        }

        &__item {
            a {
                color: inherit;
            }
        }

        &__item img {
            width: 100%;
        }

        &__item__caption {
            margin-top: 10px;
            font-size: 1.6rem;
            @include pc {
                font-size: 1.8rem;
            }
        }
    }
}

//保険サービスのお知らせ
.p-hokenTopInformation {
    margin-top: 40px;

    @include pc {
        margin-top: 80px;
    }

    &__list {
        &__link {
            margin-top: -1px;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-right: 15px;
            border-top: 1px solid #D9D9D9;
            border-bottom: 1px solid #D9D9D9;
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            column-gap: 10px;
            row-gap: 5px;

            @include pc {
                margin-top: -3px;
                padding: 20px 60px 20px 20px;
                column-gap: 20px;
                border-top-width: 3px;
                border-bottom-width: 3px;
                position: relative;

                &:hover {
                    .p-hokenTopInformation__list__icon {
                        transform: translateY(-50%) translateX(10px);
                    }
                }
            }
        }

        &__date {
            width: 100%;
            color: #41B4E6;
            font-size: 1.2rem;
            @include pc {
                width: auto;
                font-size: 1.6rem;
            }
        }

        &__message {
            color: #333;
            font-size: 1.4rem;
            flex: 1;
        }

        &__icon {
            min-width: 12px;
            width: 12px;
            height: auto;
            color: #D9D9D9;

            svg {
                width: 12px;
                height: 12px;
            }

            @include pc {
                width: 24px;
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                transition: .3s ease-in-out;
                &:hover {
                    color: #41B4E6;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &__notFound {
        font-size: 1.4rem;
        @include pc {
            font-size: 1.6rem;
        }
    }

    &__more {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;

        @include pc {
            max-width: 590px;
            margin-top: 40px;
        }
    }
}

//募文申請番号
.p-hokenCode {
    margin-top: 40px;
    color: #333;
    font-size: 1rem;

    > * + * {
        margin-top: 1.5em;
    }

    @include pc {
        font-size: 1.2rem;
    }
}
