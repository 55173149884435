@use '../../0_base' as *;

/*------------------------------------------------------------
個人情報保護方針
------------------------------------------------------------*/
.p-searchresult {
	padding: 102px 0 98px;
	@include sp {
		padding: 85px 0 70px;
	}
}