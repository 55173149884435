@use '../0_base' as *;

/*------------------------------------------------------------
navi
------------------------------------------------------------*/

@include sp {
	/*スマホメニュー共通パーツ*/
	.c-spMenu {
		width: 100%;
		min-width: 320px;
		height: calc(100% - 50px);
		// background-color: rgba(255,255,255,0.3);
		background-color: #fff;
		display: none;
		opacity: 0;
		overflow: auto;
		position: fixed;
		top: 50px;
		left: 0;
		z-index: 9998;
		&.is-open {
			display: block;
		}
	}
	.c-spMenuCloseBtn {
		width: 100%;
		height: 40px;
		padding: 15px;
		background-color: $keycolor01;
		text-align: center;
		position: relative;
		z-index: 100;
		&.c-spMenuCloseBtn--ticket {
			background-color: $ticketColor01;
		}
		&.c-spMenuCloseBtn--hoken {
			background-color: $hokenColor01;
		}
		&.c-spMenuCloseBtn--reform {
			background-color: $reformColor01;
		}
		&.c-spMenuCloseBtn--kurashi {
			background-color: $kurashiColor01;
		}
		&.c-spMenuCloseBtn--sosai {
			background-color: $sosaiNaviColor02;
		}
		.c-spMenuCloseBtn__1 {
			width: 15px;
			height: 2px;
			margin: 0 auto 8px auto;
			display: inline-block;
			background-color: #ffffff;
			transform: rotate(45deg) translate(3px, 2px);
		}
		.c-spMenuCloseBtn__2 {
			width: 15px;
			height: 2px;
			margin: 0 auto 4px auto;
			display: inline-block;
			background-color: #ffffff;
			transform: rotate(-45deg) translate(-12px,-13px);
		}
		.c-spMenuCloseBtn__3 {
			color: #ffffff;
			font-size: 1.3rem;
			letter-spacing: 1px;
			display: inline-block;
			margin-left: -14px;
			margin-top: -10px;
		}
	}
    .c-hokenSearch {
        padding-top: 6px;
        height: 50px;
        &__icon {
            height: 23px;
            color: #222;
            text-align: center;

            svg {
                width: 23px;
                height: 23px;
            }
        }
        &__label {
            margin-top: 4px;
            color: #000;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1;
        }
    }
	.c-spMenuBtn {
		width: 50px;
		height: 50px;
		padding-top: 13px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 0;
		span {
			transition: (transform 0.3s);
		}
		.c-spMenuBtn__1 {
			width: 15px;
			height: 2px;
			margin: 0 auto 4px auto;
			background-color: $keycolor01;
			display: block;
		}
		.c-spMenuBtn__2 {
			width: 15px;
			height: 2px;
			margin: 0 auto 4px auto;
			background-color: $keycolor01;
			display: block;
		}
		.c-spMenuBtn__3 {
			width: 15px;
			height: 2px;
			margin: 0 auto 3px auto;
			background-color: $keycolor01;
			display: block;
		}
		.c-spMenuBtn__4 {
			color: $keycolor01;
			font-size: 0.8rem;
			display: block;
		}
		.c-spMenuBtn__5 {
			color: #ffffff;
			font-size: 0.8rem;
			display: none;
		}
		&.is-open {
			background-color: $keycolor01;
			.c-spMenuBtn__1 {
				background-color: #ffffff;
				transform: rotate(225deg) translate(-5px,-3px);
			}
			.c-spMenuBtn__3 {
				background-color: #ffffff;
				transform: rotate(-225deg) translate(-5px,3px);
			}
			.c-spMenuBtn__4 {
				display: none;
			}
			.c-spMenuBtn__5 {
				display: block;
			}
		}
		&.c-spMenuBtn--2 {
			.c-spMenuBtn__5 {
				color: $gray02;
			}
			&.is-open {
				background-color: #ffffff;
				.c-spMenuBtn__1 {
					background-color: $gray02;
				}
				.c-spMenuBtn__2 {
					background-color: #ffffff;
				}
				.c-spMenuBtn__3 {
					background-color: $gray02;
				}
			}
		}
		&.c-spMenuBtn--2 {
			.c-spMenuBtn__5 {
				color: $gray02;
			}
			&.is-open {
				background-color: #ffffff;
				.c-spMenuBtn__1 {
					background-color: $gray02;
				}
				.c-spMenuBtn__2 {
					background-color: #ffffff;
				}
				.c-spMenuBtn__3 {
					background-color: $gray02;
				}
			}
		}
	}
	.c-spMenuAccordion {
		.c-spMenuAccordionBtn {
			width: 45px;
			height: 100%;
			-webkit-background-size: 20px;
			background-size: 20px;
			background-position: center center;
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			right: 0;
			&.c-spMenuAccordionBtn--ticket {
				background: none;
				&:before {
					width: 20px;
					height: 1px;
					margin: auto;
					content: '';
					background-color: $ticketColor01;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
				&:after {
					width: 1px;
					height: 20px;
					margin: auto;
					content: '';
					background-color: $ticketColor01;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
			}
			&.c-spMenuAccordionBtn--hoken {
				background: none;
				&:before {
					width: 15px;
					height: 2px;
					margin: auto;
					content: '';
					background-color: $hokenNaviColor02;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
				&:after {
					width: 2px;
					height: 15px;
					margin: auto;
					content: '';
					background-color: $hokenNaviColor02;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
			}
			&.c-spMenuAccordionBtn--reform {
				background: none;
				&:before {
					width: 15px;
					height: 1px;
					margin: auto;
					content: '';
					background-color: #ffffff;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
				&:after {
					width: 1px;
					height: 15px;
					margin: auto;
					content: '';
					background-color: #ffffff;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
			}
			&.c-spMenuAccordionBtn--kurashi {
				background-size: 17px;
				background-image: url("../img/common/icon_puls02.png");
				&:before,&:after {
					transition: transform 0.3s;
				}
			}
			&.c-spMenuAccordionBtn--sosai {
				background: none;
				&:before {
					width: 20px;
					height: 1px;
					margin: auto;
					content: '';
					background-color: $sosaiNaviColor02;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
				&:after {
					width: 1px;
					height: 20px;
					margin: auto;
					content: '';
					background-color: $sosaiNaviColor02;
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: transform 0.3s;
					@include tr3d0;
				}
			}
		}
		.c-spMenuAccordionHide {
			display: none;
			transition: 0.3s ease-in-out;
		}
		&.is-open {
			.c-spMenuAccordionBtn--ticket {
				&:before {
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
				&:after {
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}
			.c-spMenuAccordionBtn--hoken {
				&:before {
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
				&:after {
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}
			.c-spMenuAccordionBtn--reform {
				&:before {
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
				&:after {
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}
			.c-spMenuAccordionBtn--kurashi {
				background-image: url("../img/common/icon_minus02.png");
			}
			.c-spMenuAccordionBtn--sosai {
				&:before {
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
				&:after {
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}
			.c-spMenuAccordionHide {
				display: block;
				transition: 0.3s ease-in-out;
			}
		}
	}

    .c-spMenuBtn2 {
        width: 50px;
        height: 50px;
        text-align: center;
        padding-top: 6px;
        span {
            transition: (transform 0.3s);
        }
        &__icon {
            margin-left: auto;
            margin-right: auto;
            padding-top: 5px;
            width: 23px;
            height: 23px;
        }
        .c-spMenuBtn2__1 {
            width: 23px;
            height: 2px;
            border-radius: 9999px;
            background-color: $gray04;
            display: block;
        }
        .c-spMenuBtn2__2 {
            margin-top: 5px;
            width: 17px;
            height: 2px;
            border-radius: 9999px;
            background-color: $gray04;
            display: block;
        }
        .c-spMenuBtn2__3 {
            margin-top: 5px;
            width: 11.5px;
            height: 2px;
            border-radius: 9999px;
            background-color: $gray04;
            display: block;
        }
        .c-spMenuBtn2__4 {
            margin-top: 4px;
            color: #000;
            font-size: 1rem;
            line-height: 1;
            font-weight: bold;
            display: block;
        }
        .c-spMenuBtn2__5 {
            margin-top: 4px;
            color: #000;
            font-size: 1rem;
            line-height: 1;
            font-weight: bold;
            display: none;
        }
        &.is-open {
            .c-spMenuBtn2__1 {
                transform: rotate(225deg) translate(-5px, -6px);
            }
            .c-spMenuBtn2__2 {
                opacity: 0;
            }
            .c-spMenuBtn2__3 {
                width: 23px;
                transform: rotate(-225deg) translate(-4px, 5px);
            }
            .c-spMenuBtn2__4 {
                display: none;
            }
            .c-spMenuBtn2__5 {
                display: block;
            }
        }
    }
    .c-spMenuCloseBtn2 {
        margin-top: 40px;
        padding: 10px 15px;
        width: 100%;
        background-color: #d9d9d9;
        border-radius: 5px;
        border: none;
        font-size: 1.6rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;

        &__icon {
            width: 15px;
            height: 15px;
            color: #333;
        }
    }

    .c-spMenu2 {
        padding: 17px 25px 30px;
        width: 100%;
        min-width: 320px;
        height: calc(100% - 50px);
        background-color: #fff;
        display: none;
        opacity: 0;
        overflow: auto;
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 9998;
        &.is-open {
            display: block;
        }
    }
    .c-spMenu2Form{
        form.gsc-search-box {
            margin-bottom: 0;
            //padding-right: 15px;
            background: #fff;
            border: 1px solid #d9d9d9;
            display: flex;
            align-items: center;
        }

        table.gsc-search-box {
            margin-bottom: 0;
        }

        .gsc-input-box {
            padding: 0;
            border-radius: 0;
            border: none;

            input {
                padding: 0 15px !important;;
                height: 45px !important;
                background-position: 15px center !important;
            }
        }

        .gsib_a {
            padding: 0;
        }

        .gsc-search-button {
            margin-left: 0;
        }

        .gsc-search-button-v2 {
            padding: 0 15px;
            border-radius: 0;
            border: none;
            background: url("/img/common/icon_search10.svg") no-repeat center;
            background-size: 25px 25px;
            width: 55px;
            height: 45px;

            svg {
                display: none;
            }

            &:hover {
                border: none;
                background: url("/img/common/icon_search10.svg") no-repeat center;
                background-size: 25px 25px;
            }
        }
    }
}


/*
------------------------------------------------------------*/
