@use '../../0_base' as *;

/*------------------------------------------------------------
component
------------------------------------------------------------*/
.c-hokenColumnArticle01,
.c-hokenColumnArticle02,
.c-hokenColumnArticle03 {
    &__link {
        padding: 15px;
        height: 100%;
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-rows: auto 1fr;
        gap: 10px;
        border: 1.5px solid #D9D9D9;
        border-radius: 5px;
        align-items: center;
        color: #000;
        @include pc {
            padding: 40px;
            border-width: 3px;
            border-radius: 10px;
            grid-template-columns: 180px 1fr;
            grid-template-rows: auto 1fr;
            gap: 20px;
            align-items: start;
        }
    }
    &__image{
        aspect-ratio: 1/1;
        @include pc {
            grid-column: 1/2;
            grid-row: 1/3;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
            @include pc {
                border-radius: 10px;
            }
        }
    }
    &__title{
        font-size: 1.6rem;
        font-weight: 700;
        @include pc {
            font-size: 1.8rem;
        }
    }
}

.c-hokenColumnArticle01,
.c-hokenColumnArticle02 {
    &__description {
        font-size: 1.4rem;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        @include sp {
            grid-column: 1/3;
            grid-row: 2/3;
        }
    }
}

.c-hokenColumnArticle02 {
    &__link {
        grid-template-rows: 60% 1fr;
        align-items: center;
        row-gap: 0;
        @include pc {
            grid-template-rows: 1fr 1fr;
            row-gap: 20px;
        }
    }
    &__image {
        grid-column: 1/2;
        grid-row: 1/3;
    }
    &__title {
        margin-top: auto;
        margin-bottom: auto;
        @include pc {
            margin-bottom: 0;
        }
    }
    &__more {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        column-gap: 5px;
        @include pc {
            margin-top: 0;
            font-size: 1.6rem;
        }

        &__icon {
            min-width: 30px;
            width: 30px;
            height: 30px;
            color: #41B4E6;
            @include pc {
                min-width: 40px;
                width: 40px;
                height: 40px;
            }
        }
    }
}

.c-hokenColumnArticle03 {
    &__body {
        @include sp {
            grid-column: 1/3;
            grid-row: 2/3;
        }
    }
    &__description {
        font-size: 1.4rem;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
    }
}

.c-tag01 {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    @include pc {
        gap: 10px;
    }

    li {
        padding: 5px;
        background-color: #E4F7FF;
        color: #41B4E6;
        font-weight: 700;
        border-radius: 5px;
        font-size: 1.4rem;

        @include pc {
            padding: 5px 10px;
            border-radius: 10px;
            font-size: 1.2rem;
        }
    }
}

.p-hokenFFColumnTaxonomy {
    .wp-pagenavi {
        margin-left: auto;
        margin-right: auto;
        padding-left: 50px;
        padding-right: 50px;
        max-width: 285px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        position: relative;

        @include pc {
            max-width: 380px;
            padding-left: 70px;
            padding-right: 70px;
        }

        .page,
        .current {
            width: 50px;
            height: 50px;
            border: 1.5px solid #41B4E6;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #41B4E6;
            font-size: 1.6rem;
            font-weight: 700;

            @include pc {
                width: 60px;
                height: 60px;
                border-width: 3px;
                border-radius: 10px;
                font-size: 1.8rem;
            }
        }

        .current {
            background-color: #41B4E6;
            color: #fff;
        }

        .pages {
            display: none;
        }

        .extend {
            display: none;
        }

        .nextpostslink,
        .previouspostslink {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-indent: -9999px;
            position: absolute;

            @include pc {
                width: 70px;
                height: 70px;
            }

            &::before {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                background: no-repeat center;
                background-size: contain;
                @include pc {
                    width: 70px;
                    height: 70px;
                }
            }
        }

        .previouspostslink {
            margin-right: auto;
            left: 0;

            &::before {
                background-image: url("/img/common/icon_arrow_left_circle03.svg");
                @include pc {
                    background-image: url("/img/common/icon_arrow_left_circle04.svg");
                }
            }
        }

        .nextpostslink {
            margin-left: auto;
            right: 0;

            &::before {
                background-image: url("/img/common/icon_arrow_right_circle03.svg");
                @include pc {
                    background-image: url("/img/common/icon_arrow_right_circle04.svg");
                }
            }
        }
    }
}

// c-date01
.c-date01 {
    color: #41B4E6;
    font-size: 1.4rem;
    font-weight: 700;
    @include pc {
        font-size: 1.2rem;
    }
	&__publish {
		&+.c-date01__last {
			border-left: 1px solid #DDDDDD;
			padding-left: 10px;
			margin-left: 10px;
		}
	}
}

// c-pageHeaderPost01
.c-pageHeaderPost01 {
	&__title {
        font-size: 2.4rem;
        font-weight: 700;
		@include pc {
            font-size: 4rem;
		}
	}

	.c-tag01 {

        @include pc {
        }
	}
}

// c-contentPost01
.u-fw700 {
	font-weight: 700;
}

.u-w600 {
	width: 600px;
}

.u-blue {
	color: #2579BE;
}

.u-scroll {
	overflow: auto
}

.c-contentPost01 {
    font-size: 1.4rem;

    @include pc {
        font-size: 1.6rem;
    }

	.c-wrap01 {
        padding: 25px;
		background: #E4F7FF;

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }

		@include pc {
            padding: 80px;
		}
	}

	.c-tbStyle1 {
		@include sp {
			table {
				min-width: 478px;
				border: 1px solid #DDDDDD;
			}

			tr {
				text-align: center;
			}

			tr {
				display: flex;

				&:not(:last-child) {
					border-bottom: 1px solid #DDDDDD;
				}
			}

			th,
			td {
				width: calc(100% / 3);
				padding: 18px 15px 16px;
				border: none;

				&:not(:last-child) {
					border-right: 1px solid #DDDDDD;
				}
			}
		}
	}

    p,ul,ol {
        margin-bottom: 1.5em;
    }

	h2 {
        margin-top: 40px;
        margin-bottom: 10px;
        font-size: 2.2rem;
        font-weight: 700;
        @include pc {
            margin-top: 80px;
            margin-bottom: 20px;
            font-size: 3.2rem;
        }
    }

	h3 {
        margin-top: 40px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        column-gap: 7px;
        font-size: 1.6rem;
        font-weight: 700;

        @include pc {
            margin-top: 80px;
            margin-bottom: 20px;
            column-gap: 14px;
            font-size: 2.4rem;
        }

        &::before {
            content: '';
            width: 3px;
            height: 17px;
            background: linear-gradient(0deg, #41B4E6 0%, #41B4E6 50%, #FA8246 50%, #FA8246 100%);
            @include pc {
                width: 6px;
                height: 20px;
            }
        }
	}

	h4 {
        margin-top: 10px;
        margin-bottom: 5px;
        color: #41B4E6;
        font-size: 1.6rem;
        font-weight: 700;
        @include pc {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 1.8rem;
        }
	}

    h2,h3,h4 {
        > * {
            margin-top: 0;
        }
    }

	img {
		margin-bottom: 40px;
		display: block;
		border-radius: 5px;
		overflow: hidden;

		@include pc {
            margin: 0 auto 80px;
            max-width: 700px;
            border-radius: 5px;
		}
	}

	ul {
        padding-left: 20px;
        @include pc {
            padding-left: 40px;
        }
		li {
            margin-top: 2.5px;
			position: relative;
			padding-left: 11px;

            @include pc {
                margin-top: 5px;
                padding-left: 18px;
            }

			&:before {
				content: "";
				background: #82CDF3;
				width: 6px;
				height: 6px;
				border-radius: 100%;
				position: absolute;
                top: 7px;
				left: 0;
				z-index: 2;

				@include pc {
                    width: 8px;
                    height: 8px;
                    top: 8px;
				}
			}
		}

		&+ol {
			margin-top: 40px;
		}
	}

	ol {
		padding-left: 15px;
		margin-bottom: 27px;
		counter-reset: counter;

		li {
			position: relative;
			/* padding-left: 14px; */
			margin-bottom: 4px;
			counter-increment: counter;

			@include sp {
				letter-spacing: 0.2px;
				/* padding-left: 15px; */
			}

			&:before {
				content: counter(counter) ".";
				font-size: 1.6rem;
				font-weight: 700;
				color: #2579BE;
				/* position: absolute;
				top: -3px;
				left: 0;
				z-index: 2; */
				padding-right: 0.4em;
				text-align: right;
				display: inline-block;
				width: 2em;

				@include sp {
					top: -1px;
				}
			}
		}

		&+a {
			margin-top: 60px;
		}
	}

	a {
        color: #41B4E6;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #DDDDDD;

		@include sp {
			border-left: none;
			border-right: none;
		}

		tr {
			@include sp {
				&:not(:last-child) {
					border-bottom: 1px solid #DDDDDD;
				}
			}
		}

		td {
			line-height: 24px;
			padding: 19px 19px 15px;
			border: 1px solid #DDDDDD;
			background-color: #fff;
			text-align: center;

			&:first-child {
				background-color: #F3F3F3;
				font-weight: bold;
			}

			@include sp {
				width: 100% !important;
				padding: 13px 15px 12px;
				display: block;
				border: none;
				text-align: left;
			}
		}

		&.c-tbStyle1 {
			border: 1px solid #DDDDDD !important;

			@include sp {
				min-width: 478px;
			}

			td {
				text-align: center;

				&:first-child {
					background: #fff !important;
				}
			}

			tr {
				&:first-child {
					td {
						background: #F3F3F3 !important;
						font-weight: bold;
					}
				}

				@include sp {
					display: flex;
					justify-content: flex-start;
				}
			}
		}
	}

	.table-scroll {
		overflow: auto;

		@include sp {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: calc(50%);
				transform: translateY(-50%);
				right: 0;
				width: 60px;
				height: 60px;
				background: url('../img/common/icon-scroll.svg') no-repeat center;
				background-size: 60px 60px;
				opacity: 1;
				transition: 300ms;
			}

			&.is-hide {
				&::before {
					opacity: 0;
				}
			}

			&.hide {
				&::before {
					display: none !important;
				}
			}
		}

		table {
			margin-top: 0;
		}
	}

	.wp-caption {
		img {
			margin-bottom: 0;
		}

		.wp-caption-text {
			text-align: right;
		}
	}

	.aligncenter {
		margin: 0 auto;

		@include sp {
			width: 100% !important;
		}
	}
}
