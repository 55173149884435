@use 'mixin' as *;
@use 'vars' as *;

///*------------------------------------------------------------
//Anchor position
//------------------------------------------------------------*/
.u-anchor:before{
	content:"";
	margin-top: -100px;
	padding-top: 100px;
}

/*------------------------------------------------------------
//float & clear
//------------------------------------------------------------*/
.u-flt, .u-flt--pc {
	float: left;
}
.u-flr, .u-flr--pc {
	float: right;
}
.u-clearfix {
	@include clear;
}
.u-clear {
	clear: both;
}
@include sp {
	.u-flt--pc, .u-flr--pc {
		float: none;
	}
}

.u-tel,
.u-email {
	color: inherit !important;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-decoration: none;
}

.u-tel {
	@include pc {
		pointer-events: none;
	}
}

/*------------------------------------------------------------
//flex
//------------------------------------------------------------*/
.u-flex {
	@include dfl;
	&.u-flex--wrap {
		flex-wrap: wrap;
	}
	&.u-flex--wrap_r {
		flex-wrap: wrap-reverse;
	}
	&.u-flex--jc_end {
		justify-content: flex-end;
	}
	&.u-flex--jc_center {
		justify-content: center;
	}
	&.u-flex--jc_between {
		justify-content: space-between;
	}
	&.u-flex--jc_around {
		justify-content: space-around;
	}
	&.u-flex--ai_start {
		align-items: flex-start;
	}
	&.u-flex--ai_end {
		align-items: flex-end;
	}
	&.u-flex--ai_center {
		align-items: center;
	}
	&.u-flex--ac_start {
		align-content: flex-start;
	}
	&.u-flex--ac_end {
		align-content: flex-end;
	}
	&.u-flex--ac_center {
		align-content: center;
	}
	&.u-flex--ac_between {
		align-content: space-between;
	}
	&.u-flex--ac_around {
		align-content: space-around;
	}
}
@include sp {
	.u-sp_noflex {
		display: inherit;
	}
}

/*------------------------------------------------------------
//inner
//------------------------------------------------------------*/
.u-inner01 {
	@include center01;
}
@include sp {
	.u-inner01 {
		width: 100%;
	}
}

/*------------------------------------------------------------
//テキスト揃え
//------------------------------------------------------------*/
.u-txtCenter {
	text-align: center;
}
.u-txtLeft {
	text-align: left;
}
.u-txtRight {
	text-align: right;
}

/*------------------------------------------------------------
//カウンター
//------------------------------------------------------------*/
.u-count01 {
	counter-reset: count01;
	> * {
		counter-increment: count01;
	}
}

/*------------------------------------------------------------
//非表示
//------------------------------------------------------------*/
.u-dispNone {
	display: none !important;
}

/*------------------------------------------------------------
//ホバー
//------------------------------------------------------------*/
@media (min-width: $breakpoint01 + 1) {
	.u-hover01 {
		transition: opacity 0.3s ease-in-out;
		&:hover {
			opacity: 0.70;
		}
	}
}

/*------------------------------------------------------------
//テキストカラー
//------------------------------------------------------------*/
.u-red {
	color: $strongColor02;
}

/*------------------------------------------------------------
//その他
//------------------------------------------------------------*/
.u-fadeIn {
/*遅延表示（js連動）
 * サイドナビのないページのみ有効（サービスTOP、葬祭）
 * プラグイン　scrollrevea*/
}
.u-biggerLink {
/*ブロック全体をリンク（js連動）
 プラグイン　jquery.biggerlink*/
}
.u-matchHeight {
/*高さ揃え（js連動）
 プラグイン　jquery.matchHeight*/
}
.u-headPcScroll {
/*フィックスメニュー　横スクロール対応（js連動）*/
}
.u-noSmoothScroll {
/*スムーススクロールを無効化（js連動）
 * タブ切り替えなどの際に使用*/
}
.u-datepicker {
/*カレンダーで日付入力（js連動）
 フォームページのみ有効
 プラグイン　jquery-ui（CSSとdatepicker-ja.jsも読み込み必須）*/
}

.u-yuGothicWeight {
	font-weight: 400;
}

.u-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
@for $i from 0 through 20 {
    $space: $i * 5;

    .u-space-y-#{$space} > * + * {
        margin-top: #{$space}px !important;
    }

    .u-my-#{$space} {
        margin-top: #{$space}px !important;
        margin-bottom: #{$space}px !important;
    }
    .u-my-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .u-mt-#{$space} {
        margin-top: #{$space}px !important;
    }

    .u-mr-#{$space} {
        margin-right: #{$space}px !important;
    }

    .u-mb-#{$space} {
        margin-bottom: #{$space}px !important;
    }

    .u-ml-#{$space} {
        margin-left: #{$space}px !important;
    }

    .u-p-#{$space} {
        padding: #{$space}px !important;
    }

    .u-py-#{$space} {
        padding-top: #{$space}px !important;
        padding-bottom: #{$space}px !important;
    }
    .u-px-#{$space} {
        padding-left: #{$space}px !important;
        padding-right: #{$space}px !important;
    }
    .u-pt-#{$space} {
        padding-top: #{$space}px !important;
    }

    .u-pr-#{$space} {
        padding-right: #{$space}px !important;
    }

    .u-pb-#{$space} {
        padding-bottom: #{$space}px !important;
    }

    .u-pl-#{$space} {
        padding-left: #{$space}px !important;
    }
}
@for $i from 0 through 20 {
    $space: $i * 5;

    @include pc {
        .pc\:u-space-y-#{$space} > * + * {
            margin-top: #{$space}px !important;
        }

        .pc\:u-my-#{$space} {
            margin-top: #{$space}px !important;
            margin-bottom: #{$space}px !important;
        }
        .pc\:u-my-auto {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }

        .pc\:u-mt-#{$space} {
            margin-top: #{$space}px !important;
        }

        .pc\:u-mr-#{$space} {
            margin-right: #{$space}px !important;
        }

        .pc\:u-mb-#{$space} {
            margin-bottom: #{$space}px !important;
        }

        .pc\:u-ml-#{$space} {
            margin-left: #{$space}px !important;
        }

        .pc\:u-p-#{$space} {
            padding: #{$space}px !important;
        }

        .pc\:u-py-#{$space} {
            padding-top: #{$space}px !important;
            padding-bottom: #{$space}px !important;
        }

        .pc\:u-px-#{$space} {
            padding-left: #{$space}px !important;
            padding-right: #{$space}px !important;
        }

        .pc\:u-pt-#{$space} {
            padding-top: #{$space}px !important;
        }

        .pc\:u-pr-#{$space} {
            padding-right: #{$space}px !important;
        }

        .pc\:u-pb-#{$space} {
            padding-bottom: #{$space}px !important;
        }

        .pc\:u-pl-#{$space} {
            padding-left: #{$space}px !important;
        }
    }
    .pc\:u-mx-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@for $i from 0 through 100 {
    $value: $i * 5;
    .u-w-#{$value} {
        width: #{$value}px !important;
    }
    .u-h-#{$value} {
        height: #{$value}px !important;
    }
}
@for $i from 0 through 100 {
    $value: $i * 5;
    @include pc {
        .pc\:u-w-#{$value} {
            width: #{$value}px !important;
        }
        .pc\:u-h-#{$value} {
            height: #{$value}px !important;
        }
    }
}

.u-w-fit {
    width: fit-content !important;
}
@include pc {
    .pc\:u-w-fit {
        width: fit-content !important;
    }
}
.u-max-w-none {
    max-width: none;
}
@include pc {
    .pc\:u-max-w-none {
        max-width: none;
    }
}

.u-text-center {
    text-align: center !important;
}
@include pc {
    .pc\:u-text-center {
        text-align: center !important;
    }
}
.u-text-right {
    text-align: right !important;
}
@include pc {
    .pc\:u-text-right {
        text-align: right !important;
    }
}

.u-w-full {
    width: 100% !important;
}
@include pc {
    .pc\:u-w-full {
        width: 100% !important;
    }
}

.u-flex-1 {
    flex: 1 !important;
}
@include pc {
    .pc\:u-flex-1 {
        flex: 1 !important;
    }
}

.u-flex-none {
    flex: none !important;
}
@include pc {
    .pc\:flex-none {
        flex: none !important;
    }
}
@for $i from 0 through 6 {
    .u-order-#{$i} {
        order: #{$i} !important;
    }
}
@for $i from 0 through 6 {
    @include pc {
        .pc\:u-order-#{$i} {
            order: #{$i} !important;
        }
    }
}

.u-text-xs {
    font-size: 1.2rem !important;
}
.u-text-sm {
    font-size: 1.4rem !important;
}
.u-text-base {
    font-size: 1.6rem !important;
}
.u-text-lg {
    font-size: 1.8rem !important;
}
.u-text-xl {
    font-size: 2rem !important;
}
.u-text-19 {
    font-size: 1.9rem !important;
}
.u-text-24 {
    font-size: 2.4rem !important;
}
@include pc {
    .pc\:u-text-xs {
        font-size: 1.2rem !important;
    }
    .pc\:u-text-sm {
        font-size: 1.4rem !important;
    }
    .pc\:u-text-base {
        font-size: 1.6rem !important;
    }
    .pc\:u-text-lg {
        font-size: 1.8rem !important;
    }
    .pc\:u-text-xl {
        font-size: 2rem !important;
    }
    .pc\:u-text-19 {
        font-size: 1.9rem !important;
    }
    .pc\:u-text-24 {
        font-size: 2.4rem !important;
    }
}

.u-font-normal {
    font-weight: 400 !important;
}
@include pc {
    .pc\:u-font-normal {
        font-weight: 400 !important;
    }
}
.u-font-bold {
    font-weight: 700 !important;
}
@include pc {
    .pc\:u-font-bold {
        font-weight: 700 !important;
    }
}

.u-underline {
    text-decoration: underline !important;
}
@include pc {
    .pc\:u-underline {
        text-decoration: underline !important;
    }
}

.u-bg-none {
    background-color: transparent !important;
}
.u-bg-white {
    background-color: #fff !important;
}
.u-bg-gray-300 {
    background-color: #EFEFEF !important;
}
.u-bg-blue-200 {
    background-color: #E4F7FF !important;
}
.u-bg-blue-500 {
    background-color: #41B4E6 !important;
}
.u-bg-green-200 {
    background-color: #DEFAEE !important;
}
.u-bg-green-200 {
    background-color: #DEFAEE !important;
}
.u-bg-yellowGreen-200 {
    background-color: #ECFADF !important;
}
.u-bg-yellow-200 {
    background-color: #FFF8DA !important;
}
.u-bg-orange-200 {
    background-color: #FFEDE3 !important;
}
.u-bg-red-200 {
    background-color: #FFEDE3 !important;
}
@include pc {
    .pc\:u-bg-none {
        background-color: transparent !important;
    }
    .pc\:u-bg-white {
        background-color: #fff !important;
    }
    .pc\:u-bg-gray-300 {
        background-color: #EFEFEF !important;
    }
    .pc\:u-bg-blue-200 {
        background-color: #E4F7FF !important;
    }
    .pc\:u-bg-blue-500 {
        background-color: #41B4E6 !important;
    }
    .pc\:u-bg-green-200 {
        background-color: #DEFAEE !important;
    }
    .pc\:u-bg-yellowGreen-200 {
        background-color: #ECFADF !important;
    }
    .pc\:u-bg-yellow-200 {
        background-color: #FFF8DA !important;
    }
    .pc\:u-bg-red-200 {
        background-color: #FFEDE3 !important;
    }
}

.u-border-none {
    border-color: transparent !important;
}
.u-border-white {
    border-color: #fff !important;
}
.u-border-gray-200 {
    border-color: #D9D9D9 !important;
}
.u-border-blue-500 {
    border-color: #41B4E6 !important;
}
@include pc {
    .pc\:u-border-none {
        border-color: transparent !important;
    }
    .pc\:u-border-white {
        border-color: #fff !important;
    }
    .pc\:u-border-gray-200 {
        border-color: #D9D9D9 !important;
    }
    .pc\:u-border-blue-500 {
        border-color: #41B4E6 !important;
    }
}

.u-text-white {
    color: #fff !important;
}
.u-text-blue-500 {
    color: #41B4E6 !important;
}
.u-text-orange-500 {
    color: #FA8246 !important;
}
.u-text-gray-1000 {
    color: #222 !important;
}
.u-text-gray-900 {
    color: #333 !important;
}
@include pc {
    .pc\:u-text-white {
        color: #fff !important;
    }
    .pc\:u-text-blue-500 {
        color: #41B4E6 !important;
    }
    .pc\:u-text-gray-1000 {
        color: #222 !important;
    }
    .pc\:u-text-gray-900 {
        color: #333 !important;
    }
}

.u-rounded-5 {
    border-radius: 5px !important;
}

@include pc {
    .pc\:u-rounded-10 {
        border-radius: 10px !important;
    }
}

.u-rounded-full {
    border-radius: 9999px !important;
}
@include pc {
    .pc\:u-rounded-full {
        border-radius: 9999px !important;
    }
}

.u-flex {
    display: flex !important;
}
@include pc {
    .pc\:u-flex {
        display: flex !important;
    }
}

.u-flex-col {
    flex-direction: column !important;
}
@include pc {
    .pc\:u-flex-col {
        flex-direction: column !important;
    }
}

.u-justify-center {
    justify-content: center !important;
}
@include pc {
    .pc\:u-justify-center {
        justify-content: center !important;
    }
}

.u-justify-end {
    justify-content: end !important;
}
@include pc {
    .pc\:u-justify-end {
        justify-content: end !important;
    }
}

.u-justify-between {
    justify-content: space-between !important;
}
@include pc {
    .pc\:u-justify-between {
        justify-content: space-between !important;
    }
}

.u-items-center {
    align-items: center !important;
}
@include pc {
    .pc\:u-items-center {
        align-items: center !important;
    }
}

.u-items-end {
    align-items: flex-end !important;
}
@include pc {
    .pc\:u-items-end {
        align-items: flex-end !important;
    }
}

.u-column-gap-2\.5 {
    column-gap: 2.5px !important;
}
@for $i from 0 through 20 {
    $space: $i * 5;

    .u-column-gap-#{$space} {
        column-gap: #{$space}px !important;
    }
    .u-row-gap-#{$space} {
        row-gap: #{$space}px !important;
    }
}
@include pc {
    .pc\:u-column-gap-2\.5 {
        column-gap: 2.5px !important;
    }
}
@for $i from 0 through 20 {
    $space: $i * 5;

    @include pc {
        .pc\:u-column-gap-#{$space} {
            column-gap: #{$space}px !important;
        }
        .pc\:u-row-gap-#{$space} {
            row-gap: #{$space}px !important;
        }
    }
}

.u-grid {
    display: grid !important;
}
@include pc {
    .pc\:u-grid {
        display: grid !important;
    }
}

.u-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
.u-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.u-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
.u-grid-cols-auto\/1fr {
    grid-template-columns: auto 1fr !important;
}
@include pc {
    .pc\:u-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
    .pc\:u-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .pc\:u-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
    .pc\:u-grid-cols-auto\/1fr {
        grid-template-columns: auto 1fr !important;
    }
}

.u-border-1 {
    border-width: 1px !important;
    border-style: solid !important;
}
.u-border-1\.5 {
    border-width: 1.5px !important;
    border-style: solid !important;
}
.u-border-2 {
    border-width: 2px !important;
    border-style: solid !important;
}
.u-border-3 {
    border-width: 3px !important;
    border-style: solid !important;
}
@include pc {
    .pc\:u-border-1 {
        border-width: 1px !important;
        border-style: solid !important;
    }
    .pc\:u-border-1\.5 {
        border-width: 1.5px !important;
        border-style: solid !important;
    }
    .pc\:u-border-2 {
        border-width: 2px !important;
        border-style: solid !important;
    }
    .pc\:u-border-3 {
        border-width: 3px !important;
        border-style: solid !important;
    }
}
