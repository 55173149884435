@use '../../0_base' as *;

/*------------------------------------------------------------
/lp/pokke_column/ ポッケアプリ
------------------------------------------------------------*/

.p-lpPokkeCl {
    margin: 0 auto;
    overflow-x: hidden;
    .l-lpcontainer {
        padding: 0 calc((20/375)*100vw);
    }
    .c-lpheader {
        background: #F0F8E9;
        &__head {
            background: #6FBA2C;
            width: calc((335/375)*100vw);
            margin: 0 auto;
            border-radius: 0 0 calc((10/375)*100vw) calc((10/375)*100vw);
            text-align: center;
            padding: calc((20/375)*100vw);
        }
        &__logo {
            width: calc((167/375)*100vw);
            display: block;
            margin: 0 auto;
        }
        &__title {
            font-size: calc((14/375)*100vw);
            line-height: calc((21/375)*100vw);
            font-weight: bold;
            color: #fff;
            margin-top: calc((10/375)*100vw);
        }
    }
    .bg-blue {
        background: #1971BD !important;
    }
    .c-lptitle01 {
        font-size: calc((24/375)*100vw);
        line-height: calc((36/375)*100vw);
        font-weight: bold;

        &.small {
            font-size: calc((20/375)*100vw);
        }
    }
    .c-lplisttag {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: calc((13/375)*100vw);
    }
    .c-lptag {
        display: block;
        @include Noto();
        color: #333333;
        line-height: calc((28/375)*100vw);
        font-weight: 400;
        border: calc((2/375)*100vw) solid #6FBA2C;
        border-radius: calc((15/375)*100vw);
        font-size: calc((12/375)*100vw);
        line-height: calc((17.5/375)*100vw);
        padding: calc((4/375)*100vw) calc((8/375)*100vw);
        text-align: center;
        margin-top: calc((5/375)*100vw);
        &:not(:last-child) {
            margin-right: calc((5/375)*100vw);
        }
    }
    .c-lpimgtext01 {
        &__img {
            img {
                width: 100%;
            }
        }
        p {
            padding: calc((24/375)*100vw) calc((20/375)*100vw) calc((26/375)*100vw);
            font-size: calc((16/375)*100vw);
            line-height: calc((32/375)*100vw);
            @include Noto();
            color: #333333;
            line-height: calc((28/375)*100vw);
            font-weight: 400;
        }
    }
    .c-lpimgtext02 {
        &__img {
            width: 50%;
            margin: auto;
        }
    }
    .c-lpimgtext03 {
        &__img {
            padding: 0 20px;
        }
    }

    .c-lplogo02 {
        display: flex;
        background: #fff;
        padding: 10px;
    }
    .c-logotext {
        width: 25%;
        align-self: center;
        text-align: right;
    }
    .c-logo {
        width: 75%;
        text-align: center;
    }
    .c-logo2-txt {
        font-size: 1.2rem;
        margin-top: 0;
    }
    .c-radius_none {
        border-radius: 0;
    }
    &__section01 {
        background: #F0F8E9;
        padding: calc((30/375)*100vw) 0;
        .c-lpimgtext01 {
            margin: 0 calc((-20/375)*100vw) calc((-30/375)*100vw);
            img {
                margin-top: calc((30/375)*100vw);
            }
        }
    }
    .sec-blue {
        background: #f5faff;
    }
    &__section02 {
        background: #fff;
        padding: 0 calc((20/375)*100vw);
    }
    &__column {
        margin-top: calc((40/375)*100vw);
        word-break: break-all;
        a.c-color-style1:link,
        a.c-color-style1:visited,
        a.c-color-style1:active,
        a.c-color-style1:hover {
            color: #333;
        }
        h2 {
            font-size: calc((20/375)*100vw);
            line-height: calc((30/375)*100vw);
            font-weight: bold;
            color: #497A1D;
            margin-top: calc((40/375)*100vw);
        }
        h3 {
            font-size: calc((18/375)*100vw);
            line-height: calc((27/375)*100vw);
            font-weight: bold;
            border-bottom: calc((2/375)*100vw) solid #6FBA2C;
            padding-bottom: calc((10/375)*100vw);
            margin-top: calc((29/375)*100vw);
        }
        img {
            margin-top: calc((30/375)*100vw);
            overflow: hidden;
            border-radius: calc((10/375)*100vw);
            width: 100%;
        }
        p {
            @include Noto();
            color: #333333;
            font-size: calc((16/375)*100vw);
            line-height: calc((28/375)*100vw);
            font-weight: 400;
            margin-top: calc((27/375)*100vw);
        }
        .ps {
            font-size: 1.5rem;
            margin: 0;
            text-align: end;
        }
        .c-lpimg__caption{
            font-size: calc((12/375)*100vw);
            line-height: calc((19/375)*100vw);
            margin-top: calc((5/375)*100vw);
        }
        ul,
        ol {
            @include Noto();
            color: #333333;
            font-size: calc((16/375)*100vw);
            line-height: calc((28/375)*100vw);
            font-weight: 400;
            margin-top: calc((27/375)*100vw);
        }
        ul {
            li {
                position: relative;
                padding-left: calc((16/375)*100vw);
                &:before {
                    content: "";
                    width: calc((7/375)*100vw);
                    height: calc((7/375)*100vw);
                    background: #497A1D;
                    border-radius: 50%;
                    position: absolute;
                    top: calc((12/375)*100vw);
                    left: 5px;
                }
            }
        }
        ol {
            display: table;
            li {
                list-style-type: decimal;
                counter-increment: table-ol;
                display: table-row;
                // margin-left: calc((16/375)*100vw);
                &::before {
                    content: counter(table-ol) ".";
                    padding-right: 0.4em;
                    text-align: right;
                    display: table-cell;
                    width: 2em;
                }
            }
        }
    }
    .c-lpbox01 {
        @include Noto();
        color: #333333;
        font-size: calc((16/375)*100vw);
        line-height: calc((28/375)*100vw);
        font-weight: 400;
        background: #F0F8E9;
        border: calc((2/375)*100vw) solid #6FBA2C;
        border-radius: calc((10/375)*100vw);
        padding: calc((16/375)*100vw) calc((17/375)*100vw);
        margin-top: calc((35/375)*100vw);
        &--noborder {
            border: none;
        }
        p:first-child,
        h2:first-child {
            margin-top: 0;
        }
    }
    .c-lpbox02 {
        border-top: 2px solid #333;
        border-bottom: 2px solid #333;
        margin-top: calc((27/375)*100vw);
        padding-bottom: calc((27/375)*100vw);
        display: flex;
        &__img01 {
            width: 105px !important;
            height: 105px;
            display: block;
        }
        &__wrapper {
            width: calc(100% - 105px);
            margin-left: calc((16/375)*100vw);
        }
        &__title {
            font-size: calc((18/375)*100vw) !important;
            font-weight: bold !important;
        }
        &__text {
            font-size: calc((14/375)*100vw);
            margin-top: calc((14/375)*100vw);
        }
    }
    .c-lptexttotal {
        @include Noto();
        color: #333333;
        font-size: calc((16/375)*100vw);
        line-height: calc((28/375)*100vw);
        font-weight: 400;
        line-height: calc((32/375)*100vw);
        text-align: right;
        margin-top: calc((5/375)*100vw);
    }
    .c-lpfooter {
        background: #6FBA2C;
        margin-top: calc((35/375)*100vw);
        padding: calc((30/375)*100vw) 0;
        &__title {
            font-size: calc((20/375)*100vw);
            line-height: calc((30/375)*100vw);
            color: #fff;
            text-align: center;
            margin-bottom: calc((20/375)*100vw);
        }
        &__logo {
            display: flex;
            align-items: center;
        }
        &__item {
            width: calc(50% - ((8/375)*100vw));
            &:not(:last-child) {
                margin-right: calc((16/375)*100vw);
            }
            img {
                width: 100%;
            }
        }
    }
    .border_blue {
        border-bottom: calc((2/375)*100vw) solid #1971BD;
    }
}
