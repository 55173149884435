@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　葬儀スタイルによる違い
------------------------------------------------------------*/
.p-sosaiKnowledgeStyle {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//葬儀スタイルによる違い
.p-sosaiKnowledgeStyle01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//宗教による違い
.p-sosaiKnowledgeStyle02 {
	padding-top: 20px;
	margin-bottom: 127px;
	> p {
		margin-bottom: 112px;
		line-height: 3rem;
	}
	.c-sosaiTitle02b{
		margin-bottom: 52px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		margin-bottom: 22px;
		padding-left: 28px;
	}
	&__content01 {
		min-height: 240px;
		margin-bottom: 93px;
		padding-right: 445px;
		position: relative;		
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
	&__content02 {
		min-height: 240px;
		margin-bottom: 93px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
	&__content03 {
		min-height: 240px;
		margin-bottom: 92px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
	&__content04 {
		min-height: 240px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 32px;
			line-height: 3rem;
		}
		.c-sosaiBtn07 {
			width: 295px;
			height: 56px;
			margin: 0;
			a{
				font-size: 1.6rem;
				line-height: 2.4rem;
				font-weight: bold;
				padding: 15px 30px 13px;
				&:after{
					margin: -3px 0 0 16px;
				}
			}
		}
	}
}

//葬儀形式の種類と特徴
.p-sosaiKnowledgeStyle03 {
	margin-bottom: 120px;
	> p {
		margin-bottom: 113px;
		line-height: 3rem;
	}
	.c-sosaiTitle02b{
		margin-bottom: 52px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	.c-sosaiTitle09{
		line-height: 1;
		margin-bottom: 22px;
		padding-left: 28px;
	}
	&__content01 {
		min-height: 240px;
		margin-bottom: 100px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
	&__content02 {
		min-height: 240px;
		margin-bottom: 99px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 32px;
			line-height: 3rem;
		}
		.c-sosaiBtn07 {
			width: 282px;
			height: 56px;
			margin: 0;
			a{
				font-size: 1.6rem;
				line-height: 2.4rem;
				font-weight: bold;
				padding: 15px 30px 13px 27px;
				&:after{
					margin: -3px 0 0 16px;
				}
			}
		}
	}
	&__content03 {
		min-height: 240px;
		margin-bottom: 100px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
	&__content04 {
		min-height: 240px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 0;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
}




//関連コンテンツ
.p-sosaiKnowledgeStyle04 {
	padding: 108px 0 141px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
		span{
			&:after{
				margin: 5px auto 0 auto;
			}
		}
	}
	.c-sosaiKanrenLink01{
		li{
			margin-right: 30px;
			&:last-child{
				margin-right: 0;
			}
			&:after{
				bottom: 2px;
				right: 2px;
			}
		}
	}
}

@include sp {
	.p-sosaiKnowledgeStyle {
		font-size: 1.4rem;
	}
	//葬儀スタイルによる違い sp
	.p-sosaiKnowledgeStyle01 {
		min-width: 100%;
		margin-bottom: 47px;
		padding: 32px 0 33px;
	}
	
	//宗教による違い sp
	.p-sosaiKnowledgeStyle02 {
		margin-bottom: 76px;
		padding: 0 15px;
		> p {
			margin-bottom: 44px;
			line-height: 1.7;
			text-align: left;
			padding: 0 7px;
		}
		.c-sosaiTitle02b{
			margin-bottom: 24px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTitle09 {
		    margin-bottom: 19px;
		    padding-left: 14px;
		}
		&__content01 {
			margin-bottom: 33px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 10px;
				line-height: 1.7;
				padding: 0 8px;
			}
		}
		&__content02 {
			margin-bottom: 33px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 10px;
				line-height: 1.7;
				padding: 0 8px;
			}
		}
		&__content03 {
			margin-bottom: 33px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 10px;
				line-height: 1.7;
				padding: 0 8px;
			}
		}
		&__content04 {
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 19px;
				line-height: 1.7;
				padding: 0 8px;
			}
			.c-sosaiBtn07 {
				width: 85%;
				height: 46px;
				margin: 0 auto;
				a{
					font-size: 1.3rem;
					line-height: 2rem;
					padding: 12px 10px;
					&:after{
						margin: -3px 0 0 8px;
					}
				}
			}
		}
	}
	
	//葬儀形式の種類と特徴 sp
	.p-sosaiKnowledgeStyle03 {
		margin-bottom: 45px;
		padding: 0 15px;
		> p {
			margin-bottom: 45px;
			line-height: 1.7;
			text-align: left;
			padding: 0 8px;
		}
		.c-sosaiTitle02b{
			margin-bottom: 24px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiTitle09 {
		    margin-bottom: 19px;
		    padding-left: 14px;
		}
		&__content01 {
			margin-bottom: 35px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 10px;
				line-height: 1.7;
				padding: 0 7px 0 8px;
			}
		}
		&__content02 {
			margin-bottom: 50px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 19px;
				line-height: 1.7;
				padding: 0 7px 0 8px;
			}
			.c-sosaiBtn07 {
				width: 81%;
				height: 46px;
				margin: 0 auto;
				a{
					font-size: 1.3rem;
					line-height: 2rem;
					padding: 12px 10px;
					&:after{
						margin: -3px 0 0 8px;
					}
				}
			}
		}
		&__content03 {
			margin-bottom: 34px;
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 19px;
				line-height: 1.7;
				padding: 0 7px 0 8px;
			}
		}
		&__content04 {
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 14px;
				position: static;
			}
			> p {
				margin-bottom: 19px;
				line-height: 1.7;
				padding: 0 7px 0 8px;
			}
		}
	}
	
	//関連コンテンツ sp
	.p-sosaiKnowledgeStyle04 {
		padding: 46px 15px 35px;
    	margin-bottom: 60px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
				&:after{
					margin: 13px auto 0;
				}
			}
		}
		.c-sosaiKanrenLink01{
			li{
				width: calc(50% - 5px);
				margin-right: 10px;
				padding-bottom: 5px;
				&:last-child{
					margin-right: 0;
				}
				&:after{
					bottom: 2px;
					right: 2px;
				}
				img{
					margin-bottom: 8px;
				}
				a{
					font-size: 1.2rem;
					line-height: 3rem;
				}
			}
		}
	}
	
}

/*
------------------------------------------------------------*/
