@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム リフォーム事例
------------------------------------------------------------*/
.p-reformVoice {
	background-color: #ffffff;
	.c-breadcrumb1{
		padding: 11px 0 43px;
	}
}

//リフォーム事例
.p-reformVoice01 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior05.jpg");
		}
	}
}

//アンカーリンク
.p-reformVoice02{
	margin-bottom: 41px;
	font-size: 1.5rem;
	line-height: 1.73;
	color: $gray01;
	&__content01 {
		p {
			margin-bottom: 34px;
			line-height: 2.6rem;
		}
		ul {
			li {
				width: 50%;
				height: 60px;
				background-color: #ffffff;
				border: solid 2px $reformBtnColor08;
				transition: 0.3s ease-in-out;
				&:nth-child(1) {
					border-right-width: 1px;
				}
				&:nth-child(2) {
					border-left-width: 1px;
				}
				&:hover {
					background-color: $reformBtnColor08;
					a {
						color: #ffffff;
						&:after {
							background-image: url("../img/common/icon_arrow-white01.png");
						}
					}
				}
				a {
					width: 100%;
					height: 100%;
					padding-top: 10px;
					font-weight: bold;
					color: $reformBtnColor08;
					text-align: center;
					display: block;
					&:hover {
						opacity: 1;
					}
					&:after {
						width: 6px;
						height: 10px;
						margin: 0 auto;
						content: '';
						background-image: url("../img/common/icon_arrow-green03.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: block;
						transform: rotate(90deg);
					}
				}
			}
		}
	}
}

//外装リフォーム
.p-reformVoice03 {
	margin-bottom: 80px;
	.c-refomeTitle03b{
		font-size: 2.8rem;
		line-height: 1;
		margin-bottom: 38px;
    	padding: 0 22px;
	}
	.c-refomeTitle04{
		padding: 9px 0 6px;
		margin-bottom: 27px;
	}
	&__content01 {
		.p-reformVoiceBox {
			margin-bottom: 59px;
		}
	}
}

//内装リフォーム
.p-reformVoice04 {
	.c-refomeTitle03b{
		font-size: 2.8rem;
		line-height: 1;
		margin-bottom: 38px;
    	padding: 0 22px;
	}
	.c-refomeTitle04{
		padding: 9px 0 6px;
		margin-bottom: 27px;
	}
	&__content01 {
		.p-reformVoiceBox {
			margin-bottom: 59px;
		}
	}
}

//事例ボックス
.p-reformVoiceBox {
	font-size: 1.5rem;
	line-height: 1.73;
	color: $gray01;
	&__before_after {
		margin: 0;
	}
	&__before {
		width: 400px;
		position: relative;
		&:after {
			width: 0;
			height: 0;
			content: '';
			display: block;
			border-style: solid;
			border-color: transparent transparent transparent $reformBtnColor01;
			position: absolute;
			right: -32px;
			top: 175px;
			border-width: 13px 0 13px 20px;
		}
		span {
			line-height: 2.6rem;
			letter-spacing: 1px;
			margin-bottom: 12px;
			font-size: 1.8rem;
			font-weight: bold;
			color: rgba($gray01,0.5);
			display: block;
		}
	}
	&__after {
		width: 400px;
		span {
			line-height: 2.6rem;
			letter-spacing: 1px;
			margin-bottom: 12px;
			font-size: 1.8rem;
			font-weight: bold;
			color: $reformBtnColor01;
			display: block;
		}
	}
	&__img01{
		position: relative;
		&:before{
			content: "";
			width: 100%;
			height: 100%;
			border: solid 8px $glaylight15;
			position: absolute;
			top: 0;
			left: 0;
		}
		img{
			margin-bottom: 17px;
			display: block;
		}
	}
	&__img02{
		position: relative;
		&:before{
			content: "";
			width: 100%;
			height: 100%;
			border: solid 8px $reformBtnColor01;
			position: absolute;
			top: 0;
			left: 0;
		}
		img{
			margin-bottom: 17px;
			display: block;
		}
	}
	&__text{
		font-size: 1.5rem;
		line-height: 2.6rem;
		margin: 8px 0 23px;
		text-align: justify;
	}
	&__voice {
		padding: 38px 40px 37px 140px;
		background-color: $subColor15;
		position: relative;
		h3 {
			color: $reformTextColor02;
			font-weight: bold;
			font-size: 1.5rem;
			line-height: 1.8rem;
			margin-bottom: 8px;
		}
		p {
			font-size: 1.2rem;
			line-height: 2rem;
			text-align: justify;
		}
		img {
			position: absolute;
			top: 50%;
			left: 40px;
			transform: translateY(-50%);
		}
	}
}

//関連ページ
.p-reformVoice05 {
	margin-bottom: 104px;
	&__content01 {
		.c-refomeTitle04 {
			margin-bottom: 30px;
		}
	}
}

@include sp {
	//リフォーム事例 sp
	.p-reformVoice01 {
		margin-bottom: 30px;
		&__content01 {
			.c-refomeTitle02 {
				background-image: url("../img/reform/title_obi_interior05_sp.jpg");
			}
		}
	}
	//アンカーリンク sp
	.p-reformVoice02{
		margin-bottom: 30px;
		padding: 0 20px;
		&__content01 {
			p {
				margin-bottom: 20px;
			}
			ul {
				li {
					height: auto;
					a {
						padding: 10px;
					}
				}
			}
		}
	}

	//外装リフォーム sp
	.p-reformVoice03 {
		padding: 0 20px;
		&__content01 {
			.p-reformVoiceBox {
				margin-bottom: 30px;
			}
		}
	}

	//内装リフォーム sp
	.p-reformVoice04 {
		padding: 0 20px;
		&__content01 {
			.p-reformVoiceBox {
				margin-bottom: 30px;
			}
		}
	}

	//事例ボックス sp
	.p-reformVoiceBox {
		&__before_after {
			margin: 0 0 15px;
		}
		&__before {
			width: 100%;
			margin-bottom: 40px;
			&:after {
				margin-right: -35px;
				border-width: 20px 35px 0 35px;
				border-color: $reformBtnColor01 transparent transparent transparent;
				top: calc(100% + 10px);
				right: 50%;
			}
			span {
				font-size: 1.6rem;
			}
			img {
				margin: 0 auto 10px auto;
				display: block;
			}
		}
		&__after {
			width: 100%;
			span {
				font-size: 1.6rem;
			}
			img {
				margin: 0 auto 10px auto;
				display: block;
			}
		}
		&__voice {
			padding: 15px;
			p {
				@include clear;
			}
			img {
				transform: translateY(0);
				margin: 0 10px 10px 0;
				float: left;
				position: static;
			}
		}
	}
	
	//関連ページ sp
	.p-reformVoice05 {
		margin-bottom: 40px;
		padding: 0 20px;
	}
}

/*
------------------------------------------------------------*/
