@use '../0_base' as *;

/*------------------------------------------------------------
footer06（くらしのサービス）
------------------------------------------------------------*/

.c-foot06 {
	width: 100%;
	min-width: $baseWidth01;
	margin: 0 auto;
	padding-top: 70px;
	background-color: $subColor01;
	border-top: solid 1px $keycolor01;
}
.c-foot06NaviArea {
	margin-bottom: 70px;
}
.c-foot06Link {
	width: 208px;
	.c-foot06Logo {
		width: 200px;
		margin-bottom: 30px;
		padding-top: 5px;
	}
	dl {
		dt {
			margin-bottom: 15px;
			padding-bottom: 10px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 1.5;
			border-bottom: solid 1px $keycolor01;
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
		dd {
			margin-bottom: 10px;
			padding-left: 35px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 10px 0 -15px;
				content: '';
				background-image: url("../img/common/icon_arrow-green01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:last-child {
				padding-top: 26px;
			}
			a {
				color: $gray01;
				&:hover {
					color: $keycolor02;
				}
			}
		}
	}
}
.c-foot06Navi01{
	width: 654px;
	margin-left: 30px;
	.c-foot06Navi01__content {
		margin-left: 10px;
		display: block;
		dl {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
		}
		dt {
			width: 218px;
			margin-bottom: 5px;
			font-size: 1.4rem;
			font-weight: bold;
			line-height: 2;
			a {
				color: $gray01;
				&:hover {
					color: $kurashiTextColor02;
				}
			}
		}
		dd{
			width: 218px;
			margin-bottom: 10px;
			padding: 0 10px 0 25px;
			font-size: 1.4rem;
			&:before {
				width: 5px;
				height: 12px;
				margin: -2px 5px 0 -12px;
				content: '';
				background-image: url("../img/common/icon_arrow-orange01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			a {
				color: $gray01;
				&:hover {
					color: $kurashiTextColor02;
				}
			}
		}
	}
}

.c-foot06Navi02{
	width: 228px;
	padding-left: 20px;
	dd {
		margin-bottom: 10px;
		padding: 0 10px 0 25px;
		font-size: 1.4rem;
		&:before {
			width: 5px;
			height: 12px;
			margin: -2px 5px 0 -12px;
			content: '';
			background-image: url("../img/common/icon_arrow-orange01.png");
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
		a {
			color: $gray01;
			&:hover {
				color: $kurashiTextColor02;
			}
		}
		&.c-foot06Navi2__lineBleak {
			height: 1em;
			@include ct;
			&:before {
				content: none;
			}
		}
	}
}

.c-foot06Navi__menuTitle {
	margin-bottom: 15px;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 2;
	color: $gray01;
	border-bottom: solid 1px $kurashiColor01;
}


@include sp {
	.c-foot06 {
		min-width: 100%;
		padding-top: 0;
		padding-bottom: 50px;
		background-color: #ffffff;
		border: none;
	}
	.c-kurashiFlortingBnr {
		width: 100%;
		height: 50px;
		overflow: hidden;
		position: fixed;
		top: auto;
		bottom: 0;
		z-index: 9990;
		a {
			width: 100%;
			height: 47px;
			padding: 0;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 2.93;
			color: #ffffff;
			display: block;
			text-align: center;
			background-color: $kurashiBtnColor01;
			box-shadow: 0 3px 0 $kurashiBtnColor02;
			position: relative;
			&:before {
				width: 15px;
				height: 15px;
				margin: -2px 5px 0 0;
				content: '';
				background-image: url("../img/common/icon_maru-sankaku03.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:after {
				width: 110%;
				height: 41px;
				content: '';
				position: absolute;
				top: 3px;
				left: -5%;
				border: dashed 1px #ffffff;
			}
		}
	}
}

/*
------------------------------------------------------------*/
