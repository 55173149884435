@use '../../0_base' as *;

/*------------------------------------------------------------
ゴルファー保険一覧
------------------------------------------------------------*/
.p-hokenService15 {
	background-color: #ffffff;
}

//自動車やバイクの事故に備えたい
.p-hokenService15-01 {
	margin-bottom: 40px;
	.p-hokenService15-01__content01 {
		.c-hokenTitle03 {
			margin-bottom: 33px;
			padding: 29px 0 29px 33px;
    		letter-spacing: 1.4px;
			background-image: url("../img/hoken/title_obi_service15.jpg");
		}
		.p-hokenService15-01__content01__txt {
			color: $gray01;
			font-size: 1.6rem;
			font-weight: 500;
			line-height: 1.75;
			img {
				width: 317px;
				margin: 5px 0 0 20px;
			}
		}
	}
}

//おすすめの保険商品
.p-hokenService15-02 {
	margin-bottom: 50px;
	.p-hokenService15-02__content01 {

	}
}

//目的から保険を探す
.p-hokenService15-03 {
	margin-bottom: 40px;
	.p-hokenService15-03__content01 {
	}
	.c-hokenTitle01 {
		margin-bottom: 14px;
	}
}

//資料請求・保険相談のお申込み
.p-hokenService15-04 {
	margin-bottom: 100px;
	.p-hokenService15-04__content01 {
	}
}

@include sp {

	//自動車やバイクの事故に備えたい sp
	.p-hokenService15-01 {
		margin-bottom: 17px;
		.p-hokenService15-01__content01 {
			.c-hokenTitle03 {
				margin-bottom: 30px;
				padding: 17px 18px 18px;
    			line-height: 28px;
				background-image: url("../img/hoken/title_obi_service15_sp.jpg");
			}
			.p-hokenService15-01__content01__txt {
				padding: 0 20px;
				font-size: 1.4rem;
				line-height: normal;
				img {
					width: 100%;
					margin: 0 0 10px 0;
				}
			}
		}
	}

	//おすすめの保険商品 sp
	.p-hokenService15-02 {
		margin-bottom: 26px;
		.p-hokenService15-02__content01 {
			padding: 0 20px;
			.c-hokenTitle02 {
				font-size: 1.8rem;
				margin-bottom: 12px;
			}
			.c-hokenItemSerect .c-hokenItemCheckAll input[type="checkbox"] + span:before {
				width: 14px;
				height: 14px;
				margin: -3px 4px 0 0;
			}
		}
	}

	//目的から保険を探す sp
	.p-hokenService15-03 {
		margin-bottom: 25px;
		.p-hokenService15-03__content01 {
			padding: 0 20px;
		}
		.c-hokenTitle01 {
			margin-bottom: 22px;
		}
	}

	//資料請求・保険相談のお申込み sp
	.p-hokenService15-04 {
		margin-bottom: 51px;
		.p-hokenService15-04__content01 {
			padding: 0 20px;
			.c-hokenBtn03, .c-hokenBtn04 {
				a {
					padding-left: 60px;
					justify-content: left;
					font-weight: bold;
					&:before {
						left: 20px;
						top: 5px;
					}
				}
			}
			.c-hokenMoushikomi p a:before {
				margin-right: 4px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
