@use '../0_base' as *;

/*------------------------------------------------------------
layout
------------------------------------------------------------*/

.l-contentCorporate {
	width: 100%;
	padding-top: 70px;
}
.l-contentService {
	width: 100%;
}
.l-contentService2 {
	width: 100%;
	padding-top: 50px;
}
.l-contentService3 {
	width: 100%;
	position: relative;
}
.l-contentServiceIn {
	@include center01;
	justify-content: space-between;
	@include dfl;
}
.l-contentServiceMain {
	width: 840px;
}
.l-contentServiceSide {
	width: 240px;
	padding-bottom: 100px;
}

@include sp {
	.l-contentCorporate {
		padding-top: 50px;
	}
	.l-contentService {
		padding-top: 50px;
	}
	.l-contentServiceIn {
		width: 100%;
		display: inherit;
	}
	.l-contentServiceMain {
		width: 100%;
	}
	.l-contentServiceSide {
		display: none;
	}
}

/*コンポーネント
------------------------------------------------------------*/

.l-container {
    width: 1120px;
    margin: 0 auto;
    @include sp {
        width: 100%;
    }
}

.l-left {
    width: 840px;
    @include sp {
        width: 100%;
    }
}

.l-right {
    width: 240px;
    @include sp {
        width: 100%;
    }
}

.l-contentService02 {
    padding-top: 50px;

    @include pc {
        padding-top: 60px;
    }
}

.l-contentService03 {
    padding-top: 90px;

    @include pc {
        padding-top: 180px;
    }
}

.l-contentService04 {
    padding-top: 70px;

    @include pc {
        padding-top: 100px;
    }
}

.l-contentService05 {
    padding-top: 30px;

    @include pc {
        padding-top: 80px;
    }
}

.l-container02 {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;

    @include sp {
        padding-left: 25px;
        padding-right: 25px;
    }
}
//max-width: 1000px のcontainerを新設（保険 フムフムコラム）
.l-container03 {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;

    @include sp {
        padding-left: 25px;
        padding-right: 25px;
    }
}
