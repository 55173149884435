@use '../0_base' as *;

/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.c-footer-lp{
	&__nav{
		background: #fff;
		display: flex;
		justify-content: center;
		border-top: 1px dotted #ddd;
		border-bottom: 1px dotted #ddd;
		padding: 22px 0 20px;
		@include sp{
			flex-wrap: wrap;
			border: 0;
			padding: 0;
		}
		li{
			position: relative;
			padding: 0 11px;
			@include sp{
				width: 50%;
				text-align: center;
				padding: 7px 0 6px;
				border-bottom: 1px solid #ddd;
			}
			&:before{
				content: '';
				width: 2px;
				height: 78%;
				background: #c7c7c5; 
				position: absolute;
				top: 0;
				left: 0; 
				@include sp{
					content: none;
				}     
			}
			&:first-child{
				@include sp{
					width: 100%;
					border-top: 1px solid #ddd;
				}
				&:before{
					content: none;
				}
			}
			&:last-child{
				@include sp{
					border-left: 1px solid #ddd;	
				}
			}
			a{
				font-size: 1.4rem;
				line-height: 1;
				color: #595950;
				@include sp{
					font-size: 1.1rem;
				}
			}
		}
	}
	&__text{
		font-size: 1.2rem;
		line-height: 1.8rem;
		color: #999;
		text-align: center;
		padding: 12px 0;
		@include sp{
			font-size: 1rem;
			padding: 2px 0 6px;
		}
	}
}
.c-footer-lp2{
	background: #f8f8f8;
	&__logo{
		display: none;
		@include sp{
			display: block;
			text-align: center;
			padding: 10px 0 0;
		}
		img{
			@include sp{
				max-width: 180px;
			}
		}
	}
}