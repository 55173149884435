@use '../0_base' as *;

/*------------------------------------------------------------
header02(サービスTOP)
------------------------------------------------------------*/
.c-head02Pc {
	width: 95%;
	min-width: $baseWidth01;
	height: 50px;
	margin: 0 auto;
	padding: 10px 1% 0 2%;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	@include radius(0 0 25px 25px);
	@include clear;
	.c-head02Logo {
		width: 189px;
	}
}
.c-head02GnaviPc {
	ul {
		@include dib;
		li {
			margin-right: 19px;

			a {
				color: $gray01;
				font-size: 1.4rem;
				font-weight: 600;
				line-height: 2.3;
				&:before {
					margin: -3px 5px 0 0;
					content: '';
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
				&:hover {
					color: $keycolor01;
				}
			}
			&:nth-child(1) {
				a {
					&:before {
						margin: -3px 7px 0 0;
						width: 14px;
						height: 19px;
						background-image: url("../img/common/icon_biginer01.png");
					}
				}
			}
			&:nth-child(2) {
				margin-left: 11px;
				a {
					&:before {
						width: 17px;
						height: 18px;
						background-image: url("../img/common/icon_reaf01.png");
					}
				}
			}
		}
	}
}


@include sp {
	.c-head02Sp {
		width: 100%;
		height: 60px;
		padding: 15px 20px 0 20px;
		background-color: #ffffff;
		border-bottom: solid 1px $glaylight04;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		@include clear;
		.c-head02Logo {
			width: 200px;
			max-width: 63%;
			margin: 0 auto;
		}
		.c-spMenuBtn{
			padding-top: 16px;
			right: 0;
		}
	}
	.c-head02LinkSp {
		dl {
			width: 100%;
			dt {
				width: 100%;
				padding: 0 20px;
				background-color: #ffffff;
				border-bottom: solid 2px $glaylight01;
				a {
					padding: 10px 0 9px 15px;
					font-size: 1.4rem;
					font-weight: bold;
					line-height: 1.5;
					display: block;
					color: $gray01;
					background-image: url("../img/common/icon_arrow-green04.png");
					background-repeat: no-repeat;
					-webkit-background-size: 7px;
					background-size: 7px;
					background-position: 0 center;
				}
			}
			dd {
				padding: 9px 20px 3px;
				background-color: rgba(255,255,255,0.9);
				border-bottom: solid 1px $keycolor01;
				ul {
					li {
						width: 49%;
						height: 45px;
						margin: 0 2% 2% 0;
						border: solid 1px $keycolor01;
						background-color: #ffffff;
						@include radius(3px);
						a {
							width: 100%;
							height: 100%;
							padding: 3px 0 3px 52px;
							font-size: 1.4rem;
							font-weight: bold;
							line-height: 2.928;
							display: block;
							position: relative;
							&:before {
								width: 30px;
								height: 30px;
								content: '';
								background-repeat: no-repeat;
								-webkit-background-size: 80%;
								background-size: 80%;
								background-position: center center;
								position: absolute;
								top: 7px;
								left: 14px;
								@include radius(50%);
							}
						}
						&:nth-child(1) {
							a {
								color: $ticketColor02;
								&:before {
									background-color: $ticketColor01;
									background-image: url("../img/common/icon_ticket01.png");
								}
							}
						}
						&:nth-child(2) {
							margin-right: 0;
							a {
								color: $hokenColor02;
								&:before {
									background-color: $hokenColor01;
									background-image: url("../img/common/icon_hoken01.png");
								}
							}
						}
						&:nth-child(3) {
							a {
								color: $reformColor02;
								&:before {
									background-color: $reformColor01;
									background-image: url("../img/common/icon_reform01.png");
								}
							}
						}
						&:nth-child(4) {
							margin-right: 0;
							a {
								line-height: 1.2;
								color: $kurashiColor02;
								padding-top: 7px;
								&:before {
									background-color: $kurashiColor01;
									background-image: url("../img/common/icon_kurashi01.png");
								}
							}
						}
						&:nth-child(5) {
							a {
								color: $sosaiColor02;
								&:before {
									background-color: $sosaiColor01;
									background-image: url("../img/common/icon_sosai01.png");
								}
							}
						}
					}
				}
			}
		}
	}
	.c-head02GnaviSp {
		background-color: #ffffff;
		ul {
			li {
				padding: 0 38px;
				border-bottom: dashed 1px $glaylight01;
				&:last-child {
					border: none;
				}
				a {
					padding-left: 15px;
					font-size: 1.4rem;
					line-height: 2.9;
					display: block;
					color: $gray01;
					background-image: url("../img/common/icon_arrow-green04.png");
					background-repeat: no-repeat;
					-webkit-background-size: 6px;
					background-size: 6px;
					background-position: 0 center;
				}
			}
		}
		div {
			width: 100%;
			padding: 0 20px;
			background-color: #ffffff;
			border-top: solid 2px $glaylight01;
			a {
				padding-left: 14px;
				font-size: 1.4rem;
				font-weight: bold;
				line-height: 2.9;
				display: block;
				color: $gray01;
				background-image: url("../img/common/icon_arrow-green04.png");
				background-repeat: no-repeat;
				-webkit-background-size: 7px;
				background-size: 7px;
				background-position: 0 center;
			}
		}
	}


}


/*
------------------------------------------------------------*/
