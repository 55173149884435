@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　無宗教葬とは
------------------------------------------------------------*/
.p-sosaiKnowledgeStyleReligion {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//無宗教葬とは
.p-sosaiKnowledgeStyleReligion01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//無宗教葬とは 本文
.p-sosaiKnowledgeStyleReligion02 {
	margin-bottom: 100px;
	padding-top: 19px;
	.c-sosaiTitle02b{
		margin-bottom: 52px;
		span{
			&:after{
				margin: 2px auto 0;
			}
		}
	}
	&__content01 {
		min-height: 240px;
		margin-bottom: 60px;
		padding-right: 445px;
		position: relative;
		img {
			position: absolute;
			top: 8px;
			right: 0;
		}
		> p {
			margin-bottom: 40px;
			line-height: 3rem;
		}
	}
}

//無宗教葬の式の流れ
.p-sosaiKnowledgeStyleReligion03 {
	margin-bottom: 127px;
	.c-sosaiTitle02b{
		span{
			&:after{
				margin: 4px auto 0;
			}
		}
	}
	> p {
		margin-bottom: 72px;
	}
	&__content01 {
		> li{
			&:nth-child(2){
				h3{
					padding-left: 125px;
				}
				p{
					padding: 60px 0;
				}
			}
			&:nth-child(3){
				p{
					padding: 56px 0 64px;
				}
			}
			&:nth-child(4){
				h3{
					padding-top: 42px;
					img{
						top: 42px;
					}
				}
				p{
					padding: 42px 20px 43px 0;
				}
			}
			&:nth-child(6){
				p{
					padding: 45px 20px 45px 0;
				}
			}
			&:nth-child(7){
				p{
					padding: 60px 0;
				}
			}
			p{
				padding: 59px 0 61px;
			}
		}
	}
}

//無宗教葬の注意点
.p-sosaiKnowledgeStyleReligion04 {
	margin-bottom: 113px;
	.c-sosaiTitle02b{
		margin-bottom: 53px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	&__content01 {
		p{
			line-height: 3rem;
		}
		.c-sosaiTextBox01 {
			margin: 72px 0 52px;
			background-color: $glaylight03;
			> div{
				padding: 36px 30px 24px 40px;
			}
			ul {
				li {
					font-size: 1.6rem;
					line-height: 2.4rem;
					margin-bottom: 10px;
					padding-left: 18px;
					font-weight: 700;					
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 10px 0 -18px;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

//関連コンテンツ
.p-sosaiKnowledgeStyleReligion05 {
	padding: 106px 0 139px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 58px;
	}
	.c-sosaiKanrenLink01{
		li{
			margin-right: 30px;
			&:last-child{
				margin-right: 0;
			}
			&:after{
				bottom: 3px;
			}
			img{
				margin-bottom: 17px;
			}
		}
	}
}

@include sp {
	.p-sosaiKnowledgeStyleReligion {
		font-size: 1.6rem;
	}
	//無宗教葬とは sp
	.p-sosaiKnowledgeStyleReligion01 {
		min-width: 100%;
		margin-bottom: 46px;
		padding: 32px 0 33px;
	}
	
	//無宗教葬とは 本文 sp
	.p-sosaiKnowledgeStyleReligion02 {
		margin-bottom: 72px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 29px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		&__content01 {
			padding-right: 0;
			position: static;
			img {
				display: block;
				margin: 0 auto 16px;
				position: static;
			}
			> p {
				margin-bottom: 10px;
				font-size: 1.4rem;
				line-height: 1.7;
			}
		}
	}
	
	//無宗教葬の式の流れ sp
	.p-sosaiKnowledgeStyleReligion03 {
		margin-bottom: 74px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 25px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		> p {
		    margin-bottom: 24px;
		    font-size: 1.4rem;
		    line-height: 1.7;
		    text-align: left;
		}
		&__content01 {
			> li{
				min-height: auto;
				&:nth-child(odd) {
				    min-height: auto;
				}
				&:nth-child(1) {
					padding: 21px 15px 10px 56px;
					h3{
						padding: 0 0 8px;
					}
				}
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7){
					p{
						padding: 0;
					}
				}
				&:nth-child(2) {
					padding: 21px 15px 11px 56px;
					h3{
						padding: 0 0 9px;
					}
				}
				&:nth-child(3) {
					padding: 21px 15px 11px 56px;
					h3{
						padding: 0 0 8px;
					}
				}
				&:nth-child(4) {
					padding: 21px 15px 10px 56px;
					h3{
						padding: 0 0 8px;
						img{
							top: -5px;
						}
					}
				}
				&:nth-child(6) {
					padding: 21px 15px 9px 56px;
					h3{
						padding: 0 0 8px;
					}
				}
				&:nth-child(7) {
					padding: 21px 15px 17px 56px;
				}
				p{
					padding: 0;
				}
			}
		}
	}
	
	//無宗教葬の注意点
	.p-sosaiKnowledgeStyleReligion04 {
		margin-bottom: 43px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 26px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		&__content01 {
			> p{
				font-size: 1.4rem;
				line-height: 1.7;
				text-align: left;
			}
			.c-sosaiTextBox01 {
				margin: 25px 0 26px;
				> div{
					padding: 15px 20px 1px 20px;
				}
				ul {
					li {
						margin-bottom: 12px;
						padding-left: 13px;
						font-size: 1.4rem;
						line-height: 1.7;
						&:before {
							margin: -3px 7px 0 -14px;
						}
					}
				}
			}			
		}
	}
	
	//関連コンテンツ sp
	.p-sosaiKnowledgeStyleReligion05 {
		padding: 49px 15px 40px;
    	margin-bottom: 58px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiKanrenLink01{
			flex-wrap: wrap;
			li{
				width: calc(50% - 5px);
				margin-bottom: 8px;
				margin-right: 10px;
				float: none;
				&:nth-child(2n){
					margin-right: 0;
				}
				img{
					margin-bottom: 8px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
