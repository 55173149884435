@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　無料見積もり・お問い合わせフォーム（カート） 完了
------------------------------------------------------------*/
.p-reformContactComplete {
	padding: 50px 0 135px 0;
	background-color: #ffffff;
}

//見出し
.p-reformContactComplete01 {
	margin: 30px 0;
	.c-formTitle {
		color: $reformTextColor02;
	}
}

//本文
.p-reformContactComplete02 {
	margin-bottom: 80px;
	//コンポーネント　c-formComplete
	.c-formComplete {
		&__inquiry {
			width: 530px;
		}
	}
}

@include sp {
	.p-reformContactComplete {
		padding: 0 20px;
	}

	//見出し　ｓｐ
	.p-reformContactComplete01 {
		margin: 30px 0;
		.c-formTitle {
			margin-bottom: 27px;
		}
	}

	//本文　ｓｐ
	.p-reformContactComplete02 {
		margin-bottom: 40px;
		//コンポーネント　c-formComplete
		.c-formComplete {
			&__inquiry {
				width: 100%;
			}
		}
	}
}
/*
------------------------------------------------------------*/
