@use '../0_base' as *;

/*------------------------------------------------------------
header03（チケット）
------------------------------------------------------------*/

.c-head03Pc {
  width: 95%;
  min-width: 1120px;
  height: 50px;
  margin: 0 auto 70px;
  padding: 10px 1% 0 2%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  border-radius: 0 0 25px 25px;
  *zoom: 1;

  &:after {
    display: block;
    content: "";
    clear: both;
  }

  .c-head03Logo {
    width: 189px;
  }
}

.c-head03GnaviPc ul {
  letter-spacing: -.4em;

  > * {
    display: inline-block;
    letter-spacing: normal;
  }

  li {
    margin-right: 15px;

    a {
      color: #46483c;
      font-size: 1.4rem;
      line-height: 2.14;
      font-weight: 600;

      &:before {
        margin: -3px 5px 0 0;
        content: '';
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
      }

      &:hover {
        color: #649333;
      }
    }

    &:nth-child(1) a:before {
      width: 14px;
      height: 19px;
      background-image: url("../img/common/icon_biginer01.png");
    }

    &:nth-child(2) a:before {
      width: 17px;
      height: 18px;
      background-image: url("../img/common/icon_reaf01.png");
    }
  }
}

.c-head03CateTitle {
  height: 70px;
  padding-top: 15px;
  font-size: 2.4rem;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;
}

.c-head03BtnPc {
  height: 0;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 9998;

  .c-head03BtnPc__btn {
    width: 110px;
    height: 70px;
    position: absolute;
    top: 0;

    &.c-head03BtnPc__btn--1 {
      background-color: #ffff9a;
      right: 78px;

      a {
        width: 100%;
        height: 100%;
        padding-top: 40px;
        display: block;
        color: #666666;
        background-image: url("../img/common/icon_man01.png");
        background-repeat: no-repeat;
        background-position: center 15px;
        -webkit-transition: opacity 0s ease-in-out;
        transition: opacity 0s ease-in-out;
      }
    }

    &.c-head03BtnPc__btn--2 {
      background-color: #ff6e03;
      right: 0;

      a {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        color: #ffffff;

        span {
          width: 48%;
          height: 60px;
          padding: 16px 0 0 1px;
          font-size: 1.1rem;
          background-image: url("../img/common/icon_cart01.png");
          background-size: 26px;
          background-position: 49% 17px;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .c-head03Sp {
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeeee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    *zoom: 1;

    &:after {
      display: block;
      content: "";
      clear: both;
    }

    .c-head03Logo {
      width: 200px;
      max-width: 35%;
      height: 50px;
      position: absolute;
      top: 0;
      left: 14px;

      img {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }

    .c-spMenu {
      background-color: #ffffff;
    }

    .c-headCateTitleSp {
      padding: 14px 10px 0 13px !important;
      background-color: #fc819b;
    }
  }

  .c-head03LinkTextSp {
    padding: 0 20px;

    .c-head03__LinkText01 {
      margin-bottom: 13px;
      font-size: 1.5rem;
      font-weight: 500;

      &:before {
        width: 6px;
        height: 10px;
        margin: -3px 10px 0 0;
        content: '';
        background-image: url("../img/common/icon_arrow-pink01.png");
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
      }

      a {
        color: #308bbb;
        text-decoration: none;
      }
    }
  }

  .c-head03LinkSp {
    dl {
      width: 100%;

      dt {
        width: 100%;
        background-color: #8cb340;

        a {
          padding: 8px 35px 7px 20px;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -2px;
          display: block;
          color: #ffffff;
          background-image: url("../img/common/icon_arrow-white02.png");
          background-repeat: no-repeat;
          background-size: 7px;
          background-position: 95% center;
        }
      }

      dd {
        padding: 15px 20px 8px;
        background-color: #ffffff;

        ul li {
          width: 49%;
          height: 45px;
          margin: 0 2% 2% 0;
          border: solid 1px #dddddd;
          background-color: #ffffff;
          border-radius: 8px;

          a {
            width: 100%;
            height: 100%;
            padding: 2px 0 2px 55px;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 2.928;
            display: block;
            position: relative;

            &:before {
              width: 30px;
              height: 30px;
              content: '';
              background-repeat: no-repeat;
              background-size: 80%;
              background-position: center center;
              position: absolute;
              top: 6px;
              left: 16px;
              border-radius: 50%;
            }
          }

          &:nth-child(1) a {
            color: #253b73;

            &:before {
              background-color: #82cdf3;
              background-image: url("../img/common/icon_hoken01.png");
            }
          }

          &:nth-child(2) {
            margin-right: 0;

            a {
              color: #154827;

              &:before {
                background-color: #68ba93;
                background-image: url("../img/common/icon_reform01.png");
              }
            }
          }

          &:nth-child(3) a {
            line-height: 1.2;
            color: #7c4b12;
            padding-top: 7px;

            &:before {
              background-color: #faad5a;
              background-image: url("../img/common/icon_kurashi01.png");
            }
          }

          &:nth-child(4) {
            margin-right: 0;

            a {
              color: #503f69;

              &:before {
                background-color: #af8ebe;
                background-image: url("../img/common/icon_sosai01.png");
              }
            }
          }
        }
      }
    }

    div {
      width: 100%;
      padding: 0 20px 26px;
      background-color: #ffffff;

      a {
        padding: 0 0 0 9px;
        font-size: 1.4rem;
        line-height: 1.2;
        display: block;
        color: #649333;
        background-image: url("../img/common/icon_arrow-green06.png");
        background-repeat: no-repeat;
        background-size: 8px;
        background-position: 0 2px;
      }
    }
  }

  .c-head03GnaviSp {
    margin-bottom: 29px;

    .c-head03GnaviSp__navi {
      width: 100%;

      a {
        padding: 15px 35px 14px 20px;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.5;
        display: block;
      }

      &.c-head03GnaviSp__navi--1 {
        background-color: #fc819b;

        a {
          color: #ffffff;
          background-image: url("../img/common/icon_arrow-white02.png");
          background-repeat: no-repeat;
          background-size: 8px;
          background-position: 95% center;
        }
      }

      &.c-head03GnaviSp__navi--2 {
        background-color: #fef2f5;
        border-bottom: solid 1px #ffffff;

        a {
          color: #783146;
          background-image: url("../img/common/icon_arrow-pink01.png");
          background-repeat: no-repeat;
          background-size: 10px;
          background-position: 95% center;
          font-size: 1.6rem;
          padding: 15px 35px 11px 19px;
        }
      }

      &.c-head03GnaviSp__navi--3 {
        border-bottom: solid 1px #ffffff;

        &:last-of-type {
          border: none;
        }

        dt {
          background-color: #fef2f5;
          position: relative;

          a {
            width: calc(100% - 60px);
            color: #333745;
            background: none;
            color: #783146;
            padding: 15px 35px 14px 19px;
          }
        }

        dd {
          border-bottom: solid 1px #dddddd;

          a {
            color: #232934;
            background-image: url("../img/common/icon_arrow-gray04.png");
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: 95% center;
            font-weight: normal;
            color: #232934;
            padding: 11px 30px 7px 30px;
          }
        }
      }
    }

    .c-head03GnaviSp__navi__menuTitle {
      padding: 19px 20px 17px 21px;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.5;
      color: #232934;
      background-image: none;
      background-color: #ffffff;
      border-bottom: solid 2px #fc819b;

      &:before {
        width: 18px;
        height: 18px;
        content: '';
        margin: -2px 7px 0 0;
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
      }

      &.c-head03GnaviSp__navi__menuTitle--search {
        padding: 14px 20px 6px;
        background-color: #fafafa;
        border: none;

        &:before {
          background-image: url("../img/common/icon_search01.png");
        }
      }

      &.c-head03GnaviSp__navi__menuTitle--cate:before {
        margin: -2px 11px 0 0;
        background-image: url("../img/common/icon_light01.png");
      }

      &.c-head03GnaviSp__navi__menuTitle--teiban {
        padding: 17px 20px 17px 21px;

        &:before {
          margin: -2px 9px 0 0;
          background-image: url("../img/common/icon_shine01.png");
        }
      }

      &.c-head03GnaviSp__navi__menuTitle--riyou {
        padding: 16px 20px 13px 19px;

        &:before {
          content: none;
        }
      }
    }

    .c-head03GnaviSp__search {
      padding: 0 20px 20px;
      background-color: #fafafa;
      background-image: none;
      position: relative;

      input {
        &[type="text"] {
          width: 100%;
          height: 40px;
          padding: 0 30px 0 10px;
          font-size: 1rem;
          line-height: 2;
          border: solid 1px #a5abb7;
          color: green;
          border-radius: 0;
        }

        &[type="submit"] {
          width: 34px;
          height: 34px;
          display: block;
          border: none;
          background: none;
          background-image: url("../img/common/icon_search06.png");
          background-size: cover;
          position: absolute;
          top: 3px;
          right: 23px;
          border-radius: 0;
          text-indent: 1000em;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .c-head03BtnSp {
    padding: 0 20px;
    background-color: #ffffff;
    letter-spacing: -.4em;

    > * {
      display: inline-block;
      letter-spacing: normal;
    }

    a {
      width: 49%;
      padding: 3px 0;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      border: solid 3px;

      &:nth-child(1) {
        float: left;
        margin: 0 2% 10px 0;
        padding: 10px 0 7px 9px;
        line-height: 1.2;
        color: #666666;
        background-color: #ffff9a;
        background-image: url("../img/common/icon_door01.png");
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: 6px center;
        border-color: #ffea61;
      }

      &:nth-child(2) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 0 10px 0;
        padding: 10px 0 7px;
        line-height: 1.2;
        letter-spacing: -0.1em;
        background-color: #ff6e03;
        border-color: #ffa257;
      }

      &:nth-child(3) {
        width: 100%;
        padding: 6px 0;
        background-color: #4b5870;
        border-color: #939ba9;

        &:before {
          width: 20px;
          height: 16px;
          margin: 0px 5px 0 0px;
          content: '';
          background-image: url("../img/common/icon_tel01.png");
          background-size: cover;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

.c-head03BtnSp--ticket {
  padding: 0 20px;
  background-color: #ffffff;
  letter-spacing: -.4em;

  > * {
    display: inline-block;
    letter-spacing: normal;
  }

  a {
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    border: solid 3px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 10px 0;
    padding: 7px 0 7px;
    line-height: 1.2;
    letter-spacing: -0.1em;
    background-color: #ff6e03;
    border-color: #ffa257;

    span {
      width: 29px;
      height: 28px;
      background-image: url("../img/common/icon_cart01.png");
      background-size: 28px;
      background-repeat: no-repeat;
      background-position: 0 2px;
      margin-right: 2px;
    }
  }
}

.c-head03BtnSp--blue {
  margin-bottom: 30px;
  padding: 0 20px;
  background-color: #ffffff;
  letter-spacing: -.4em;

  > * {
    display: inline-block;
    letter-spacing: normal;
  }

  a {
    width: 49%;
    padding: 3px 0;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    border: solid 3px;
  }

  .c-head03BtnSp__telBtn {
    width: 100%;
    padding: 11px 0;
    background-color: #4b5870;
    border-color: #939ba9;

    &:before {
      width: 20px;
      height: 16px;
      margin: 0px 5px 0 0px;
      content: '';
      background-image: url("../img/common/icon_tel01.png");
      background-size: cover;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

/*
------------------------------------------------------------*/
