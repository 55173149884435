@use '../../0_base' as *;

/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.page-hoken_lp_kega_hoken,
.page-hoken_lp_kega_hoken_202205{
	position: relative;
	font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif ;
	background: #ffffff;
	min-width: 1200px;
	@include sp{
		min-width: 320px;
	}
	.l-contentServiceIn{
		display: block;
	}
	.l-contentService{
		@include sp{
			padding-top: 0;
		}
	}
	.pc-only{
		display: block;
		@include sp{
			display: none;
		}
	}
	.sp-only{
		display: none;
		@include sp{
			display: block;
		}
	}

	.c-header-lp__logo {
    max-width: 266px;

		@include sp{
			width: 132px;
		}
	}
}
.p-hokenLpKegaMv{
	background: url('/img/hoken/lp/kega_hoken/mv.png') no-repeat center;
	background-size: cover;
	padding: 97px 0 50px;
	position: relative;
	overflow: hidden;
	@include sp{
		padding: 48px 13px 0 20px;
		background: url('/img/hoken/lp/kega_hoken/mv-sp.png') no-repeat top center;
		background-size: 100% auto;
	}
	.l-contentServiceIn{
		@include sp{
			padding: 0 !important;
		}
	}
	&__txt{
		border-radius: 10px;
		background: rgba($color: #3A64AD, $alpha: 0.85);
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		position: relative;
		margin-top: 27px;
		padding: 40px 40px;
		@include sp{
			flex-wrap: wrap;
			margin-top: 25px;
			padding: 23px 20px 20px;
			margin-right: 6px;
		}

		&.no-img {
			justify-content: center;

			.p-hokenLpKega__info {
				@include sp {
					width: auto;
				}
			}
		}
	}
	&__subttl {
		background: #3A64AD;
		border-radius: 4px;
		padding: 3px;
		display: flex;
		align-items: center;
		max-width: 540px;
		margin-top: 15px;

		@include sp{
			margin-top: 102px;
			max-width: 270px;
			margin-left: auto;
			margin-right: auto;
			padding: 5px;
			position: relative;
			left: -2px;
		}

		&__text,
		&__item{
			font-size: 1.4rem;
			font-weight: bold;
			line-height: 20px;
			letter-spacing: -0.03em;


			@include sp{
				font-size: 1.2rem;
			}
		}

		&__text{
			color: #fff;
			padding-left: 12px;
			padding-right: 17px;
			white-space: nowrap;

			@include sp{
				padding-left: 6px;
				padding-right: 14px;
				line-height: 14px;
			}
		}

		&__item{
			color: #3A64AD;
			position: relative;
			text-align: left;
			padding-left: 19px;
			margin-left: 15px;
			white-space: nowrap;
			&::before{
				content: "";
				position: absolute;
				top: 7px;
				left: 0;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #3A64AD;
			}

			@include sp{
				padding-left: 9px;
				margin-left: 0;

				&:nth-child(2n){
					margin-left: 16px;
				}

				&:nth-child(1),
				&:nth-child(3){
					width: 68px;
				}
			}

			&:first-child{
				padding-left: 0;
				margin-left: 0;
				@include sp{
					padding-left: 9px;
					margin-left: 0;
				}
				&::before{
					display: none;

					@include sp{
						display: block;
					}
				}

			}
		}

		&__lst{
			background: #fff;
			display: flex;
			align-items: center;
			width: 452px;
			padding: 5px 15px 3px;

			@include sp{
				flex-wrap: wrap;
				width: 100%;
				padding: 3px 15px;
			}
		}

	}
	&__img{
		position: absolute;
    bottom: -53px;
    right: 20px;
    width: 300px;

		img{
			width: 100%;
		}

		@include sp{
			bottom: auto;
			top: -5px;
			right: 16px;
			max-width: 187px;
			order: 2;
			margin: -10px -10px 0 0;
			width: 60%;
			z-index: 11;
		}
	}
	&__text1{
		max-width: 535px;

		@include sp{
			max-width: 320px;
			margin: 0 auto;
			position: relative;
			left: -4px;
		}
	}
	&__text2{
		padding: 0 48px;
		@include sp{
			padding: 0;
			max-width: 335px;
			margin: 4px 0 0;
		}
	}
	&__text3{
		margin-top: 10px;

	}
}
.p-hokenLpKega{

	&__lst1{
		.p-hokenLpKega__box1{
			&:nth-child(2),
			&:nth-child(3){
				padding-bottom: 40px;
			}

			&:nth-child(4){
				.p-hokenLpKega__box1__ttl{
					margin-bottom: 20px;
				}
			}
		}
	}

	&__box1{
		display: flex;
		background: #fff;
		padding: 40px 40px 32px;
		border-radius: 10px;

		@include sp{
			display: block;
			padding: 30px 20px;
		}

		& + .p-hokenLpKega__box1{
			margin-top: 20px;

			@include sp{
				margin-top: 10px;
			}
		}

		&__ttl{
			font-size: 3.2rem;
			line-height: 1.2;
			font-weight: bold;
			margin: 10px 0 28px;
			/* white-space: nowrap; */

			@include sp{
				font-size: 2.3rem;
				line-height: 30px;
				white-space: inherit;
				margin: 0 0 17px;
			}

			.cl-red{
				color: #E55552;
			}
		}

		&__text{
			font-size: 1.8rem;
			line-height: 32px;
			font-weight: 500;

			.style-1{
				position: relative;
				font-weight: bold;
				background: rgba($color: #E55552, $alpha: 0.15);
			}
		}

		&__txt{
			font-size: 1.2rem;
			font-weight: 500;
			line-height: 22px;
			position: relative;
			padding-left: 17px;
			margin-top: 26px;

			@include sp{
				margin-top: 5px;
			}

			&::before{
				content: "※";
				position: absolute;
				top: -3px;
				left: 0;
				font-size: 1.2rem;
				font-weight: 500;
				line-height: 28px;
			}
		}

		&__content{
			width: 500px;
			margin-right: 40px;

			@include sp{
				width: 100%;
			}
		}

		&__img{
			width: 500px;

			@include sp{
				width: 100%;
				margin-top: 30px;
			}

			img{
				width: 100%;
			}

			.p-hokenLpKega__box1__txt{
				text-align: right;
				padding-left: 0;
				margin-top: 18px;
				&::before{
					display: none;
				}
			}
		}

		&__btn{
			border-radius: 10px;
			background: #fff;
			border: 5px solid #009DE4;
			font-size: 2rem;
			line-height: 28px;
			color: #009DE4;
			font-weight: bold;
			text-align: center;
			padding: 7px 10px 4px;
			margin-bottom: 20px;

			@include sp{
				font-size: 2.1rem;
				border-width: 4px;
			}
		}

		.p-hokenLpKega__noteList {
			margin-top: 26px;

			@include sp {
				margin-top: 15px;
			}
		}
	}

	.l-contentServiceIn{
		@include sp{
			padding: 0 20px;
		}
	}
	&__info{
		display: flex;
		align-items: center;
		@include sp{
			display: block;
			order: 1;
			width: 50%;
			max-width: 148px;
		}
	}
	&__txt2{
		margin-left: 10px;

		@include sp{
			margin-left: 8px;
		}
	}
	&__txt3{
		position: relative;
		margin-left: 68px;

		&::before{
			content: "";
			position: absolute;
			top: -40px;
			left: -44px;
			width: 158px;
			height: 138px;
			background: url('/img/hoken/lp/kega_hoken/notify.png') no-repeat;
			z-index: 1;

			@include sp{
				top: -23px;
				left: 0;
				width: 108px;
				height: 92px;
				background-size: 100% 100%;
			}
		}

		@include sp{
			margin: 39px 0 0;
			order: 3;
			width: 100%;
		}
	}
	&__txt4{
		border-radius: 10px;
		border: 5px solid #e6e4e2;
		padding: 30px 34px 28px;
		margin-top: 44px;
		background: #ffffff;
		@include sp{
			padding: 20px;
			margin-top: 30px;
		}
		.p-hokenLpKega3__txt1{
			margin-top: 2px;
		}
	}
	&__note {
		font-size: 1.4rem;
		position: relative;
		padding-left: 20px;
		margin: 15px 0;

		@include sp {
			font-size: 1.2rem;
		}

		&:before {
			content: "＊";
		    position: absolute;
			font-size: 1.4rem;
		    top: 6px;
		    left: 3px;
		    line-height: 1;
		}
	}
	&__noteList {
		font-size: 1.4rem;
		position: relative;

		@include sp {
			font-size: 1.2rem;
		}

		&__item {
			margin-top: 5px;
		}
	}
	&__btn1{
		font-size: 1.6rem;
		line-height: 1.25;
		color: #3A64AD;
		font-weight: bold;
		border-radius: 4px;
		background: rgb(255, 255, 255);
		box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
		width: 80px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include sp{
			font-size: 1.2rem;
			height: 25px;
			width: 120px;
		}
	}
	&__btn2{
		font-size: 2.4rem;
		line-height: 32px;
		color: #fff;
		font-weight: bold;
		width: 460px;
		height: 80px;
		border: 5px solid #fff;
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		background: #E55552;
		display: inline-block;
		text-align: center;
		padding: 18px 0;
		border-radius: 50px;
		position: relative;
		@include sp{
			font-size: 1.4rem;
			width: 100%;
			height: 60px;
			border: 4px solid #fff;
			padding: 15px 0 15px 60px;
		}
		&:after{
			content: '';
			border-style: solid;
			border-width: 6px 0 6px 7px;
			border-color: transparent transparent transparent #fff;
			position: absolute;
			top: 50%;
			right: 25px;
			transform: translateY(-50%);
			transition: .3s all;
			@include sp{
				right: 18px;
			}
		}
		span{
			position: relative;
			padding-left: 50px;
			top: 3px;
			@include sp{
				padding-left: 10px;
				top: -4px;
			}
			&:before{
				content: '';
				width: 30px;
				height: 30px;
				background: url('/img/hoken/lp/kega_hoken/icon1.png') no-repeat center;
				background-size: cover;
				position: absolute;
				top: 53%;
				left: 0;
				margin-top: -2px;
				transform: translateY(-50%);
				transition: .3s all;
				@include sp{
					width: 20px;
					height: 20px;
					left: -14px;
				}
			}
		}

		@include hover {
			color: #ff5b4c;
			border: 5px solid #ff5b4c;
			background: #fff;
			opacity: 1;

			&:after {
				border-color: transparent transparent transparent #ff5b4c;
				transition: .3s all;
			}

			span {
				&:before {
					background: url('/img/hoken/lp/kega_hoken/icon1_over.png') no-repeat center;
					background-size: cover;
					transition: .3s all;
				}
			}
		}
	}
	&__text1{
		font-size: 1.6rem;
		line-height: 28px;
		color: #333;
		font-weight: 500;
		span{
			color: #ff5b4c;
			font-weight: bold;
		}
	}
	&__text3{
		font-size: 1.6rem;
		line-height: 1;
		color: #fff;
		font-weight: bold;
		text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
		@include sp{
			font-size: 1.5rem;
			padding: 8px 12px 0;
			white-space: nowrap;
		}
		span{
			font-size: 2.4rem;
			@include sp{
				font-size: 2.3rem;
			}
		}
	}
	&__text4{
		font-size: 2.4rem;
		line-height: 1;
		font-weight: bold;
		color: #fff;
		text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
		@include sp{
			font-size: 2.2rem;
			margin: -2px 0 4px -8px;
			letter-spacing: -1px;
			white-space: nowrap;
		}
		span{
			font-size: 4.4rem;
			@include sp{
				font-size: 4rem;
				position: relative;
				top: 3px;
			}
		}
	}
	&__text5{
		font-size: 1.2rem;
		line-height: 1;
		color: #fff;
		font-weight: bold;
		text-shadow: 0px 5px 10px rgba(4, 0, 0, 0.2);
		@include sp{
			font-size: 1rem;
			margin: 0 0 0 3px;
		}
	}
	&__label{
		font-size: 1.6rem;
		line-height: 1.5;
		color: #59493f;
		background: #fcf9e8;
		box-shadow: 0px 5px 10px 0px rgba(4, 0, 0, 0.2);
		border-radius: 6px;
		font-weight: bold;
		z-index: 10;
		position: absolute;
		top: -55px;
		left: 50%;
		transform: translateX(-50%);
		@include sp{
			font-size: 1.2rem;
			margin-left: 0;
			top: -40px;
			left: 0;
			transform: translateX(0);
		}
		span{
			padding: 10px 0;
			display: inline-block;
			width: 240px;
			height: 40px;
			text-align: center;
			position: relative;
			@include sp{
				padding: 6px 0;
				display: inline-block;
				width: 170px;
				height: 30px;
			}
			&:after{
				content: '';
				border-style: solid;
				border-width: 10px 5px 0 5px;
				border-color: #fcf9e8 transparent transparent transparent;
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				@include sp{
					border-width: 8px 4px 0 4px;
					bottom: -8px;
				}
			}
		}
	}
	&__ttl1{
		font-size: 4rem;
		line-height: 50px;
		color: #59493f;
		font-weight: bold;
		position: relative;
		padding: 77px 0 31px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		letter-spacing: 1px;
		@include sp{
			display: block;
			text-align: center;
			font-size: 3rem;
			line-height: 1.2;
			padding: 45px 0 27px !important;
		}

		&--wid {
			width: 100%;
			text-align: center;
			position: relative;
			top: -9px;
		}

		&:before{
			content: '';
			background: url('/img/hoken/lp/kega_hoken/icon2.png') no-repeat center;
			background-size: 39px 40px;
			width: 39px;
			height: 40px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			@include sp{
				width: 30px;
				height: 29px;
				background-size: 30px 29px;
			}
		}
		&:after{
			content: '';
			background: url('/img/hoken/lp/kega_hoken/line1.png') no-repeat center;
			background-size: 120px 16px;
			width: 120px;
			height: 16px;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			@include sp{
				width: 88px;
				height: 11px;
				background-size: 88px 11px;
			}
		}
		&--red {
			// font-size: 6.8rem;
			color: #ff5b4c;
			// font-family: 'Roboto', sans-serif;
			@include sp{
				// font-size: 5.1rem;
				// vertical-align: sub;
			}
		}

		.number{
			font-family: 'Roboto', sans-serif;
		}

		img{
			vertical-align: sub;
			@include sp{
				max-width: 220px;
			}
		}
		i{
			font-style: normal;
		}
	}
	&__ttl2{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 26px;
	}
	&__ttl3{
		font-size: 2rem;
		line-height: 38px;
		color: #333333;
		position: relative;
		padding-left: 40px;
		margin-bottom: 10px;
		@include sp{
			font-size: 1.8rem;
			line-height: 1.5;
			margin-bottom: 14px;
		}
		&:before{
			content: '';
			width: 30px;
			height: 30px;
			background: url('/img/hoken/lp/kega_hoken/icon3.png') no-repeat center;
			background-size: cover;
			position: absolute;
			top: -2px;
			left: 0;
			@include sp{
				top: 0;
			}
		}
	}
	&__point{
		font-size: 1.2rem;
		font-weight: bold;
		line-height: 1;
		color: #fff;
		border-radius: 4px;
		width: 70px;
		height: 70px;
		min-width: 70px;
		background: #009DE4;
		text-align: center;
		padding: 12px 0;
		@include sp{
			font-size: 1rem;
			width: 60px;
			height: 60px;
			min-width: 60px;
		}
		span{
			font-size: 4rem;
			line-height: 1;
			font-family: 'Roboto', sans-serif;
			display: block;
			@include sp{
				font-size: 3.4rem;
			}
		}
	}
	&__ttl2Main{
		font-size: 2.4rem;
		line-height: 38px;
		font-weight: bold;
		color: #333;
		padding-left: 16px;
		@include sp{
			width: calc(100% - 60px);
			font-size: 1.8rem;
			line-height: 23px;
		}
		span{
			text-decoration: underline;
		}
	}
	
	&__list1{
		margin-top: 37px;
		@include sp{
			margin-top: 17px;
		}
		li{
			font-size: 1.2rem;
			line-height: 2.1rem;
			color: #595950;
			position: relative;
			padding-left: 14px;
			@include sp{
				line-height: 1.8rem;
			}
			&:before{
				content: '※';
				position: absolute;
				top: 0;
				left: 0;
			}
			a{
				color: #308bbb;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	&__list2{
		li{
			font-size: 1.2rem;
			line-height: 2.1rem;
			color: #595950;
			position: relative;
			padding-left: 12px;
			@include sp{
				line-height: 1.8rem;
			}
			&:before{
				content: '';
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #009DE4;
				position: absolute;
				top: 8px;
				left: 0;
			}
			a{
				color: #308bbb;
				text-decoration: underline;
				position: relative;
				margin-right: 15px;
				&:after{
					content: '';
					width: 10px;
					height: 10px;
					background: url('/img/hoken/lp/kega_hoken/icon5.png') no-repeat center;
					background-size: cover;
					position: absolute;
					top: 3px;
					right: -11px;
				}
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}

.p-hokenLpKega1{
	margin-top: -58px;
	padding: 136px 0 83px;
	background: #F8F7F6;

	@include sp{
		padding: 40px 0;
		margin-top: 0;
	}

	.p-hokenLpKega__ttl1{
		margin-bottom: 60px;
	}

	.p-hokenLpKega {
		&__box1 {
			&__content {
				.p-hokenLpKega__txt4 {
					margin-top: 28px;

					@include sp {
						margin-top: 25px;
					}
				}
			}
		}
	}
}

.p-hokenLpKega2{
	background: url('/img/hoken/lp/kega_hoken/bg1.png') no-repeat center;
	background-size: cover;
	padding: 70px 0 80px;
	@include sp{
		background: url('/img/hoken/lp/kega_hoken/bg1-sp.png') no-repeat center;
		background-size: cover;
		padding: 38px 0 40px;
	}
	.p-hokenLpKega__ttl1{
		font-size: 3.2rem;
		padding: 0;
		letter-spacing: 0px;
		@include sp{
			font-size: 2.4rem;
			line-height: 1.5;
			margin-top: -19px;
			padding: 0 !important;
		}
		&:before, &:after{
			content: none;
		}
		img{
			vertical-align: middle;
			position: relative;
			top: -11px;
			@include sp{
				vertical-align: bottom;
				top: 11px;
			}
		}
		&--wid{
			@include sp{
				top: 10px;
			}
		}
		p{
			&:last-child{
				margin-top: -4px;
			}
		}
	}
	&__cont{
		border-radius: 10px;
		background: rgba($color: #3A64AD, $alpha: 0.85);
		opacity: 0.902;
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		padding: 40px 40px 40px;
		margin-top: 19px;
		@include sp{
			margin-top: 28px;
			padding: 0;
			background: none;
			box-shadow: none;
		}
	}
	&__info1{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		@include sp{
			flex-wrap: wrap;
			border-radius: 10px;
			background: rgba($color: #3A64AD, $alpha: 0.85);
			opacity: 0.949;
			box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
			padding: 23px 20px 25px;
		}
		.p-hokenLpKega__btn1{
			height: 80px;
			@include sp{
				height: 25px;
			}
		}
		.p-hokenLpKega__btn2{
			height: 80px;
			padding: 20px 0;
			@include sp{
				width: 100%;
				height: 60px;
				padding: 15px 0 15px 60px;
			}
			span{
				padding-left: 43px;
				@include sp{
					padding-left: 13px;
				}
				&:before{
					left: 3px;
					@include sp{
						left: -12px;
					}
				}
			}
		}
		.p-hokenLpKega__label{
			top: -53px;
			@include sp{
				top: -40px;
			}
		}

		.p-hokenLpKega__txt3{
			top: 5px;
			@include sp{
				margin-top: 34px;
			}
		}

		&.no-img {
			justify-content: center;

			.p-hokenLpKega__info {
				@include sp {
					width: auto;
				}
			}
		}
	}
	&__info2{
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		border-radius: 10px;
		background: #fff;
		padding: 10px 10px 17px;
		margin-top: 40px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		@include sp{
			margin-top: 10px;
			padding: 20px 20px 21px;
		}
	}
	&__img{
		position: absolute;
		top: -116px;
    right: -70px;
    width: 300px;

		img{
			width: 100%;
		}

		@include sp{
			bottom: auto;
			top: -5px;
			right: 12px;
			max-width: 190px;
			order: 2;
			margin: -10px -10px 0 0;
			width: 60%;
			z-index: 11;
		}
	}
	&__ttl{
		font-size: 2rem;
		line-height: 1.5;
		font-weight: bold;
		color: #fff;
		background: #009DE4;
		border-radius: 4px;
		width: 100%;
		text-align: center;
		padding: 5px 0;
		margin-bottom: 16px;
		@include sp{
			font-size: 1.4rem;
			padding: 5px 0;
			margin-bottom: 9px;
		}
	}
	&__text1{
		font-size: 1.6rem;
		line-height: 1.75;
		color: #333;
		width: 43%;
		margin-right: 40px;
		@include sp{
			width: 100%;
			font-size: 1.2rem;
			line-height: 2.1rem;
			margin-right: 0;
		}
	}
	&__btn1{
		font-size: 1.6rem;
		line-height: 1;
		font-weight: bold;
		color: #009DE4;
		border: 2px solid #009DE4;
		border-radius: 50px;
		display: block;
		width: 320px;
		height: 50px;
		text-align: center;
		padding: 16px 0 10px;
		position: relative;
		@include sp{
			margin-top: 10px;
			width: 100%;
			max-width: 320px;
			height: 48px;
			font-size: 1.2rem;
			padding: 16px 0;
		}
		&:after{
			content: '';
			border-style: solid;
			border-width: 5px 0 5px 5px;
			border-color: transparent transparent transparent #009DE4;
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translateY(-50%);
			transition: .3s all;
		}
		span{
			position: relative;
			padding-left: 40px;
			@include sp{
				padding-left: 27px;
			}
			&:before{
				content: '';
				width: 28px;
				height: 24px;
				background: url('/img/hoken/lp/kega_hoken/icon4.png') no-repeat center;
				background-size: cover;
				position: absolute;
				top: 44%;
				left: 4px;
				transform: translateY(-50%);
				transition: .3s all;

				@include sp{
					width: 20px;
					height: 17px;
					left: 1px;
				}
			}
		}

		@include hover {
			color: #fff;
			background: #009DE4;
			opacity: 1;

			&:after {
				border-color: transparent transparent transparent #fff;
				transition: .3s all;
			}

			span {
				&:before {
					background: url('/img/hoken/lp/kega_hoken/icon4_over.png') no-repeat center;
					background-size: cover;
					transition: .3s all;
				}
			}
		}
	}
	.mw100 {
		max-width: 100%;
	}
}

.p-hokenLpKega3{
	padding: 78px 0 73px;
	overflow-x: hidden;
	@include sp{
		padding: 30px 0 40px;
	}

	.p-hokenLpKega__ttl1{
		padding: 73px 0 57px;

		@include sp{
			padding: 59px 0 21px !important;
		}

		&--wid{
			font-size: 3.2rem;
			font-weight: bold;
			margin-bottom: -9px;

			@include sp{
				font-size: 2.3rem;
				line-height: 26px;
				display: block;
				width: calc(100% + 20px);
				margin-left: -10px;
				margin-right: -10px;
			}
		}

		img{
			position: relative;
			left: -3px;
			top: 14px;

			@include sp{
				top: 16px;
				max-width: 240px;
			}
		}

		&--red{
			font-size: 6.8rem;

			@include sp{
				font-size: 5.1rem;
				position: relative;
				top: 9px;
			}
		}
	}

	&__ttl2{
		font-size: 2.8rem;
		text-align: center;
		line-height: 42px;
		font-weight: bold;
		margin: 60px 0 62px;

		@include sp{
			font-size: 2rem;
			line-height: 30px;
			text-align: left;
			margin: 30px 0;
		}
	}

	&__lst3{
		display: flex;
		flex-wrap: wrap;

		@include sp{
			display: block;
		}

		li{
			width: calc(50% - 20px);
			font-size: 1.6rem;
			line-height: 24px;
			font-weight: 500;
			position: relative;
			padding-left: 14px;
			margin-bottom: 7px;

			@include sp{
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&:nth-child(2n){
				margin-left: 20px;
				width: 50%;

				@include sp{
					width: 100%;
					margin-left: 0;
				}
			}

			&::before{
				content: "";
				position: absolute;
				top: 7px;
				left: 0;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #009DE4;
			}
		}

		&.no-col {
			li {
				width: 100%;

				&:nth-child(2n){
					margin-left: 0;
					width: 100%;
				}
			}
		}
	}

	&__txt1{
		font-size: 1.2rem;
		line-height: 21px;
		font-weight: 500;
		color: #333333;
		display: flex;
		span{
			margin: 0 2px;
			line-height: 1;
			display: block;
			position: relative;
			top: 4px;
		}
	}
	&__text1{
		font-size: 2.8rem;
		font-weight: bold;
		color: #59493f;
		line-height: 1.5;
		@include sp{
			font-size: 2rem;
			letter-spacing: -0.3px;
		}
		span{
			color: #22ac38;
		}
	}
	&__point{
		font-size: 1.2rem;
		line-height: 1.5;
		color: #fff;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		span{
			font-size: 3.2rem;
			font-family: 'Roboto', sans-serif;
			margin-left: 2px;
		}
	}
	&__imgtxt2{
		display: flex;
		flex-wrap: wrap;
		background: #F8F7F6;
		padding: 36px 40px 40px;
		margin: -15px 0 35px;
		border-radius: 10px;
		@include sp{
			flex-wrap: wrap;
			margin: 0 0 18px;
			padding: 21px 20px;
			position: relative;
		}
		&.imgtxt1{
			.p-hokenLpKega__txt4{
				padding-bottom: 21px;
			}
		}
		&.imgtxt2{
			padding: 37px 40px 40px;
			@include sp{
				padding: 19px 20px 23px;
			}
			.p-hokenLpKega3__img2{
				padding: 0px 0px 0 20px;
				width: 310px;
				@include sp{
					width: 100%;
					img{
						width: 100%;
					}
					padding: 12px 0px 0 0;
				}
			}
			.p-hokenLpKega3__txt2{
				padding: 0 20px 0 0;
				width: calc(100% - 310px);
				@include sp{
					padding: 0;
					width: 100%;
				}
			}
			.p-hokenLpKega__ttl2{
				margin-bottom: 24px;
				@include sp{
					margin-bottom: 13px;
				}
			}

			.p-hokenLpKega3__lst3{
				li{
					width: calc((100% - 60px)/3);
					margin-bottom: 0;

					@include sp{
						width: 100%;
						margin-bottom: 7px;
					}

					&:nth-child(2n){
						margin-left: 0;
					}

					&:not(:last-child){
						margin-right: 30px;

						@include sp{
							margin-right: 0;
						}
					}

					&:last-child {
						@include sp{
							margin-bottom: 0;
						}
					}
				}
			}
		}
		&.imgtxt3{
			padding: 42px 40px 40px;
			margin-bottom: 30px;
			@include sp{
				padding: 19px 20px 23px;
				margin-bottom: 0;
			}
			.p-hokenLpKega3__img2{
				width: 310px;
				@include sp{
					width: 100%;
				}
			}
			.p-hokenLpKega3__txt2{
				width: calc(100% - 310px);

				@include sp{
					width: 100%;
				}
			}
			.p-hokenLpKega__ttl2{
				margin-bottom: 28px;
				@include sp{
					margin-bottom: 13px;
				}
			}
		}

		.p-hokenLpKega__txt4 {
			width: 100%;
		}
	}
	&__txt1{
		width: calc(50% - 40px);
		@include sp{
			width: 100%;
		}
	}
	&__txt2{
		width: 50%;
		padding: 0 20px 0 0;
		@include sp{
			width: 100%;
			padding: 0;
		}
	}
	&__img1{
		width: 50%;
		margin-top: 6px;
		text-align: right;
		position: relative;
		@include sp{
			width: 100%;
			margin-top: 30px;
		}
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			background: url('/img/hoken/lp/kega_hoken/img1-1.png') no-repeat right bottom;
			background-size: cover;
			position: absolute;
			bottom: -65px;
			right: -66px;
			z-index: 0;
			@include sp{
				width: 110%;
				height: 110%;
				bottom: -15%;
				right: -15%;
			}
		}
		img{
			position: relative;
			z-index: 9;
		}
	}
	&__img2{
		width: 50%;
		text-align: center;
		padding: 0 0 0 20px;
		@include sp{
			width: 100%;
			padding: 11px 0 0;

			img{
				width: 100%;
			}
		}
	}
}
.p-hokenLpKega4{
	background: #F7F6F4;
	padding: 81px 0 67px;

	@include sp{
		padding: 40px 0;
	}

	.p-hokenLpKega__ttl1{
		padding: 57px 0 31px;

		@include sp{
			padding: 35px 0 15px !important;
		}
		img{
			position: relative;
			top: 14px;

			@include sp{
				max-width: 240px;
			}
		}

		&--wid{
			margin-top: 31px;

			@include sp{
				display: block;
				margin-top: 35px;
			}
		}
	}

	&__bl1{
		margin-top: 61px;

		@include sp{
			margin-top: 30px;
		}
	}

	&__ttl1{
		font-size: 2.4rem;
		line-height: 3.6rem;
		font-weight: bold;
		color: #3A64AD;
		border-radius: 10px;
		border: 5px solid #3A64AD;
		text-align: center;
		padding: 2px 0;

		&.style-1{
			border-color: #4CB5B9;
			color: #4CB5B9;
		}
	}

	&__text2{
		font-size: 2rem;
		line-height: 28px;
		font-weight: bold;
		color: #fff;
		border-radius: 4px;
		text-align: center;
		padding: 19px 0 12px;
		background: #3A64AD;
		max-width: 460px;
		margin: 30px auto;

		@include sp{
			font-size: 1.4rem;
			line-height: 1;
		}

		span{
			font-size: 2.4rem;
			position: relative;
			top: 1px;

			@include sp{
				font-size: 1.6rem;
				line-height: 28px;
			}

			&::before{
				content: "";
				position: absolute;
				top: -5px;
				left: 11px;
				width: 123px;
				height: 4px;
				background: url('/img/hoken/lp/kega_hoken/img-line1.png') no-repeat;
				background-size: 123px 4px;

				@include sp{
					left: 6px;
					width: 82px;
					height: 3px;
					background-size: 82px 3px;
				}
			}
		}
	}

	&__text3{
		font-size: 1.8rem;
		line-height: 24px;
		font-weight: bold;
		position: relative;
		color: #333333;
		padding-left: 12px;

		@include sp{
			font-size: 1.6rem;
			line-height: 23px;
		}

		&::before{
			content: "";
			position: absolute;
			top: 8px;
			left: 0;
			width: 6px;
			height: 6px;
			background: #3A64AD;
			border-radius: 50%;

			@include sp{
				top: 8px;
			}
		}

		& + .p-hokenLpKega4__text3{
			margin-top: 8px;
		}

		span{
			color: #E55552;
		}
	}

	&__img{
		margin-top: 25px;
	}

	&__text4{
		font-size: 2rem;
		font-weight: 500;
		position: relative;
		color: #333333;
		padding-left: 25px;
		margin-top: -4px;

		@include sp{
			font-size: 1.6rem;
			line-height: 22px;
			margin-top: 15px;
		}

		&::before{
			content: "※";
			position: absolute;
			top: 9px;
			left: 3px;
			font-size: 1.6rem;
			line-height: 1;

			@include sp{
				top: 4px;
			}
		}

		span{
			color: #E55552;
			font-weight: bold;
		}
	}

	&__text5{
		font-size: 2rem;
		line-height: 28px;
		font-weight: bold;
		color: #fff;
		border-radius: 4px;
		text-align: center;
		padding: 18px 20px 8px;
		background: #4CB5B9;
		max-width: 460px;
		margin: 30px auto;

		@include sp{
			font-size: 1.4rem;
			line-height: 1;
			padding: 11px 0;
		}

		&-1{
			font-size: 2.4rem;
			position: relative;
			top: 1px;

			@include sp{
				font-size: 1.6rem;
				line-height: 28px;
			}

			&::before{
				content: "";
				position: absolute;
				top: -5px;
				left: 12px;
				width: 78px;
				height: 4px;
				background: url('/img/hoken/lp/kega_hoken/img-line2.png') no-repeat;
				background-size: 78px 4px;

				@include sp{
					left: 8px;
					width: 50px;
					height: 3px;
					background-size: 50px 3px;
				}
			}
		}
		&-2{
			display: block;
			text-align: center;
			font-size: 1.4rem;
			font-weight: 500;
			margin-top: -1px;

			@include sp{
				font-size: 1.2rem;
			}
		}
	}

	&__bl2{
		margin-top: 69px;

		@include sp{
			margin-top: 40px;
		}

		.p-hokenLpKega__text1 {
			@include sp {
				padding-left: 10px;
				margin-top: 10px;
			}
		}
	}

	&__text6{
		font-size: 1.2rem;
		line-height: 24px;
		font-weight: 500;
		position: relative;
		color: #333333;
		padding-left: 11px;

		&::before{
			content: "";
			position: absolute;
			top: 8px;
			left: 0;
			width: 6px;
			height: 6px;
			background: #009DE4;
			border-radius: 50%;
		}
	}

	&__lst1{
		margin-top: 49px;
		padding: 36px 0 0 40px;
		border-top: 1px solid #D8D8D8;

		@include sp{
			padding: 20px 0 0;
			margin-top: 20px;
		}
	}
}

.p-hokenLpKega6{
	background: #fff;
	padding: 113px 0 60px;
	@include sp{
		padding: 36px 0 42px;
	}

	.p-hokenLpKega__ttl1{
		padding: 66px 0 40px;
		margin-bottom: 60px;
		@include sp{
			padding: 43px 0 22px;
			margin-bottom: 30px;
		}
	}
	&__q{
		font-size: 2rem;
		line-height: 3rem;
		font-weight: bold;
		color: #333333;
		position: relative;
		padding-left: 30px;
		cursor: pointer;
		@include sp{
			font-size: 1.8rem;
			line-height: 2.7rem;
			padding-right: 30px;
		}
		&:before{
			content: 'Q.';
			font-family: 'Roboto', sans-serif;
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: #009DE4;
			position: absolute;
			top: -3px;
			left: 0;
		}

		&::after{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				width: 20px;
				height: 20px;
				background: url('/img/hoken/lp/kega_hoken/faq-open.svg') no-repeat;
				background-size: 20px 20px;
				transition: all 0.3s;
		}

		&.is-active{
			&::after{
				background: url('/img/hoken/lp/kega_hoken/faq-close.svg') no-repeat;
				background-size: 20px 2px;
				height: 2px;
			}
		}
	}
	&__a{
		font-size: 1.6rem;
		line-height: 2.8rem;
		color: #333333;
		position: relative;
		padding: 16px 0 0 30px;
		margin-top: 15px;
		border-top: 1px solid #d8d8d8;
		display: none;
		@include sp{
			padding: 14px 0 0 30px;
			margin-top: 14px;
		}
		&:before{
			content: 'A.';
			font-family: 'Roboto', sans-serif;
			font-size: 2.4rem;
			line-height: 3.6rem;
			color: #E55552;
			font-weight: bold;
			position: absolute;
			top: 12px;
			left: 0;
		}
		span{
			font-weight: bold;
		}
		a{
			color: #308bbb;
			text-decoration: underline;
			position: relative;
			margin-right: 37px;
			&:hover{
				text-decoration: none;
			}
		}
	}
	&__txt1{
		border-radius: 10px;
		border: 5px solid #e6e4e2;
		padding: 30px 35px 28px;
		background: #fff;
		margin-bottom: 20px;
		@include sp{
			padding: 14px 20px 12px;
		}
	}
	&__txt2{
		border-radius: 10px;
		border: 5px solid #e6e4e2;
		padding: 30px 35px 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 60px;
		@include sp{
			display: block;
			padding: 0;
			margin-top: 30px;
		}
	}
	&__link1, &__link2{
		font-size: 1.6rem;
		line-height: 2.8rem;
		font-weight: bold;
		color: #595950;
		position: relative;
		padding: 0 20px 0 27px;
		margin: 0 38px;
		@include sp{
			width: 100%;
			display: block;
			margin: 0;
			padding: 13px 52px;
		}
		&:before{
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			@include sp{
				left: 22px;
			}
		}
		&:after{
			content: '';
			border-style: solid;
			border-width: 6px 0 6px 6px;
			border-color: transparent transparent transparent #8cb340;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include sp{
				right: 18px;
			}
		}
	}
	&__link1{
		@include sp{
			border-bottom: 1px solid #d8d8d8;
		}
		&:before{
			width: 18px;
			height: 22px;
			background: url('/img/hoken/lp/kega_hoken/icon7.png') no-repeat center;
			background-size: cover;
		}
	}
	&__link2{
		padding: 0 20px 0 32px;
		@include sp{
			padding: 13px 52px;
		}
		&:before{
			width: 22px;
			height: 22px;
			background: url('/img/hoken/lp/kega_hoken/icon8.png') no-repeat center;
			background-size: cover;
		}
	}
}
.p-hokenLpKega7{

	background: #3A64AD;
	padding: 27px 0 21px;

	@include sp{
		padding: 20px 0 17px;
	}
	.l-contentServiceIn{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		@include sp{
			flex-wrap: wrap;
			padding: 0 10px;
		}
		&.no-img {
			justify-content: center;
		}
	}
	&__img1{
		margin: 14px 10px 0 40px;
		@include sp{
			order: 3;
			margin: 13px 0 0;
		}
		img{
			margin: 0 auto;
		}
	}
	&__img2{
		position: absolute;
		right: -21px;
		top: -55px;

		@include sp{
			bottom: auto;
			top: -43px;
			right: 16px;
			max-width: 196px;
			order: 2;
			margin: -10px -10px 0 0;
			width: 63%;
			z-index: 11;
		}
	}
	.p-hokenLpKega__info{
		display: block;
		justify-content: center;
		position: relative;
		top: -2px;
		@include sp{
			width: 45%;
			margin-left: 10px;
		}

		*{
			text-align: center;
		}

		.p-hokenLpKega__btn1{
			width: 148px;
			height: 30px;
		}

		.p-hokenLpKega__txt2{
			margin-left: 0;
			margin-top: 11px;

			@include sp{
				margin-top: 0;
			}
		}
		.p-hokenLpKega__text4{
			margin: 1px 0 5px;
		}
	}


}
.p-hokenLpKega8{
	background: #F2FAFE;
	padding: 74px 0 76px;
	@include sp{
		padding: 35px 0 39px;
	}
	&__ttl1{
		font-size: 2rem;
		line-height: 3rem;
		color: #333333;
		position: relative;
		padding-left: 30px;
		margin-bottom: 20px;
		@include sp{
			margin-bottom: 11px;
		}
		&:before{
			content: '';
			background: url('/img/hoken/lp/kega_hoken/icon9.png') no-repeat center;
			background-size: cover;
			width: 23px;
			height: 20px;
			position: absolute;
			top: 6px;
			left: 0;
		}
	}
	&__text1{
		font-size: 1.2rem;
		line-height: 21px;
		margin-top: 20px;
		color: #333333;
		padding: 0 40px;

		@include sp{
			padding: 0;
			margin-top: 15px;
		}
	}
	&__text2{
		font-size: 1.2rem;
		line-height: 21px;
		margin-top: 20px;
		color: #707070;
		padding: 0 40px;

		@include sp{
			padding: 0;
			margin-top: 15px;
		}
	}
	.p-hokenLpKega__list2{
		padding: 0 9px;
		@include sp{
			padding: 0 3px;
		}
		li{
			font-size: 1.4rem;
			line-height: 2.45rem;
		}
	}
	&__imgtxt1{
		display: flex;
		align-items: center;
		@include sp{
			display: block;
		}
	}
	&__txt1{
		width: 50%;
		padding: 0 40px;
		@include sp{
			width: 100%;
			padding: 0;
		}
	}
	&__img1{
		width: 50%;
		text-align: center;
		padding-top: 5px;
		@include sp{
			width: 100%;
			padding: 22px 7px 0;
		}
	}
	.kantan_web{
		border-radius: 10px;
		box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
		background: #fff;
		padding: 55px 60px;
		margin-top: 60px;
		@include sp{
			padding: 14px 20px 20px;
			margin-top: 30px;
		}
		&__ttl1{
			font-weight:bold;
			font-size: 2rem;
			line-height: 3rem;
			color: #009DE4;
			border-bottom: #009DE4 solid 2px;
			padding-bottom: 4px;
			margin: 0 0 23px;
			@include sp{
				padding-bottom: 8px;
				margin: 0 0 14px;
			}
		}
		&__text1{
			font-size: 1.6rem;
			line-height: 2.8rem;
			color: #333333;
			margin-bottom: 8px;
		}
		&__text2{
			font-size: 1.6rem;
			line-height: 2.8rem;
			font-weight: bold;
			color: #333333;
			margin-bottom: 12px;
			span{
				color: #ff5b4c;
			}

			@include sp{
				line-height: 22px;
			}
		}
		.link01 {
			font-size: 1.6rem;
			line-height: 2.8rem;
			position: relative;
			padding-left: 20px;
			display: inline-block;
			margin-top: -2px;
			@include sp{
				padding-left: 16px;
			}

			a {
				color: #308bbb;
				text-decoration: underline;
			}

			&:before{
				content: '';
				border-style: solid;
				border-width: 7px 0 7px 7px;
				border-color: transparent transparent transparent #009DE4;
				position: absolute;
				top: 6px;
				left: 8px;
				@include sp{
					left: 3px;
				}
			}

			@include hover {
				a {
					text-decoration: none;
				}
			}
		}
		.txtbld {
			font-weight: bold;

			.checkboxBg{
				font-size: 1.6rem;
				line-height: 2.4rem;
				color: #333;
				background:  #ffffff url('/img/hoken/lp/kega_hoken/checkbox__off.png') no-repeat left 65px center;
				border-radius: 4px;
				border: 2px solid #e6e4e2;
				width: 480px;
				height: 44px;
				padding: 9px 11px;
				display: block;
				cursor: pointer;
				margin: 0 auto;
				font-weight: 500;

				@include sp{
					width: 100%;
					height: auto;
					padding: 10px 20px 10px 45px;
					text-align: left;
					background: #ffffff url('/img/hoken/lp/kega_hoken/checkbox__off.png') no-repeat left 20px top 13px;
				}
			}

			input[type="checkbox"]:checked+label {
				background: #ffffff url('/img/hoken/lp/kega_hoken/checkbox__on.png') no-repeat left 65px center;

				@include sp{
					background: #ffffff url('/img/hoken/lp/kega_hoken/checkbox__on.png') no-repeat left 20px top 13px;
				}
			}

			input[type="checkbox"]{
				display: none;
			}
		}
		.seikyo_select {
			margin: 24px 0 47px 0;
			display: flex;
			justify-content: space-between;
			@include sp{
				display: block;
				margin: 17px 0 18px 0;
			}
			li {
				margin: 0;
				font-size: 1.6rem;
				line-height: 2.4rem;
				color: #595950;
				@include sp{
					margin: 0 0 5px;
				}
			}
		}
		&__btn{
			font-size: 1.6rem;
			line-height: 60px;
			font-weight: bold;
			color: #fff;
			text-align: center;
			width: 288px;
			height: 60px;
			border-radius: 50px;
			background: #ff5b4c;
			border: 0;
			display: block;
			margin: 40px auto 25px;
			position: relative;
			@include sp{
				width: 100%;
				max-width: 292px;
				margin: 31px auto 15px;
			}

			a {
				line-height: 2.4rem;
			}

			&:after{
				content: '';
				border-style: solid;
				border-width: 7px 0 7px 7px;
				border-color: transparent transparent transparent #fff;
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
			}
		}

		.btn_default {
			.kantan_web__btn {
				background: #ccc;
			}
		}

		.alert02 {
			color: #ff5b4c;
			font-size: 1.6rem;
			line-height: 2.8rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 40px 0 20px;

			@include sp {
				text-align: center;
				font-size: 1.4rem;
			}

			img {
				margin-right: 10px;
				height: 20px;
			}
		}

		.radioBg {
			width: 235px;
			display: inline-block;
			overflow: hidden;
			border: 2px solid #e6e4e2;
			border-radius: 5px;
			box-sizing: border-box;
			padding: 8px 0 8px 37px;
			cursor: pointer;
			background: url('/img/hoken/lp/kega_hoken/radio_off.png') no-repeat left 13px center;
			@include sp{
				width: 100%;
			}
		}
		input[type="radio"]:checked+label {
			background: url('/img/hoken/lp/kega_hoken/radio_on.png') no-repeat left 13px center;
		}
		input[type="radio"]{
			display: none;
		}
		.column03 {
			border-radius: 4px;
			background: #f8f8f8;
			border: 1px solid #e6e4e2;
			padding: 25px 15px 31px;
			margin-top: 23px;
			@include sp{
				margin-top: 11px;
				padding: 14px 20px 21px 16px;
			}
		}
		.p-hokenLpKega__list1{
			margin-top: 0;
		}
	}
	&__table1{
		border-collapse: collapse;
		border: 1px solid #d8d8d8;
		width: calc(100% - 80px);
		margin: 80px auto 0;
		@include sp{
			width: 100%;
			margin: 41px auto 0;
		}
		th, td{
			font-size: 1.4rem;
			line-height: 2.1rem;
			color: #333333;
			border: 1px solid #d8d8d8;
		}
		th{
			font-weight: bold;
			background: #f8f8f8;
			text-align: left;
			padding: 15px 20px;
			width: 208px;
			vertical-align: top;
			@include sp{
				padding: 5px 9px 4px;
				width: 33.5%;
			}
		}
		td{
			background: #fff;
			padding: 15px 20px;
			@include sp{
				padding: 5px 9px 4px;
			}
			p{
				margin-bottom: 10px;
				@include sp{
					margin-bottom: 5px;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
			span{
				font-weight: bold;
				display: block;
			}
		}
	}
}

/* .page-hoken_lp_kega_hoken_202205 */
.page-hoken_lp_kega_hoken_202205 {
	.p-hokenLpKegaMv{
		&__txt {
			width: 75%;
			justify-content: space-between;

			@include sp {
				width: 100%;
			}
		}
	}
	.p-hokenLpKega {
		&__info {
			@include sp {
				width: 40%;
			}
		}
		&__btn2 {
			@include sp {
				height: auto;
				line-height: 18px;
			}

			span {
				@include sp {
				    text-indent: 0;	
				    display: block;
				    width: 100%;
				    top: 0;
				}

				&:before {
					left: 21px;
				}
			}
		}
		&__txt3 {
			@include sp {
				width: 58%;
			}

			&:before {
				@include sp {
				    position: absolute;
				    top: -53px;
				}
			}
		}
		&__text4 {
			@include sp {
				display: inline-block;
			}
		}
		&2 {
			&__info1 {
				justify-content: center;
				
				@include sp {
					justify-content: space-between;
				}
			}
		}
		&7 {
			.l-contentServiceIn {
				justify-content: center;
			}
			.p-hokenLpKega__info {
			    @include sp {
					width: 100%;
				    display: flex;
				    justify-content: center;
				    max-width: 100%;
			    }
			}

			.p-hokenLpKega__btn1 {
				@include sp {
					margin-right: 15px;
				}
			}
		}
	}
}

/* .p-hokenLpKega202406 */
.p-hokenLpKega202406 {
	.p-hokenLpKegaMv {
		background: url('/img/hoken/lp/kega_hoken/sincoop_kega202406/mv.png') no-repeat center;
		background-size: cover;
		@include sp {
			background: url('/img/hoken/lp/kega_hoken/sincoop_kega202406/mv-sp.png') no-repeat top center;
			background-size: 100% auto;
		}

		&__text2 {
			@include sp {
				width: 56%;
			}
		}

		&__subttl {
			@include sp {
				margin-top: 60px;
			}
		}
	}
}

/* .p-hokenLpKega202502 */
.p-hokenLpKega202502 {
	.p-hokenLpKegaMv {
		background: url('/img/hoken/lp/kega_hoken/sincoop_kega202502/mv.png') no-repeat center;
		background-size: cover;
		@include sp {
			background: url('/img/hoken/lp/kega_hoken/sincoop_kega202502/mv-sp.png') no-repeat top center;
			background-size: 100% auto;
		}

		&__text2 {
			@include sp {
				width: 56%;
			}
		}

		&__subttl {
			@include sp {
				margin-top: 60px;
			}
		}
	}
}