@use '../../0_base' as *;

/*------------------------------------------------------------
 葬祭　葬儀の基礎知識・マナー
------------------------------------------------------------*/
.p-sosaiKnowledge {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//葬儀の基礎知識・マナー
.p-sosaiKnowledge01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}
//葬儀の基礎知識・マナー 本文
.p-sosaiKnowledge02 {
	margin-top: -47px;
	padding: 112px 0 80px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.u-inner01{
		> p{
			line-height: 3rem;
			margin-bottom: 72px;
		}
	}
	&__content {
		margin-bottom: 41px;
		padding: 60px;
		background-color: #ffffff;
		border-top: solid 2px $sosaiNaviColor02;
		&__txt {
			padding-right: 520px;
			position: relative;
			.c-sosaiTitle12{
			    margin-bottom: 24px;
			    padding: 4px 0 1px 65px;
			}
			img {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		p {
			margin-bottom: 32px;
			line-height: 3rem;
		}
		.c-sosaiBtn07 {
			width: 157px;
			margin: 0;
			a{
				font-size: 1.4rem;
				font-weight: bold;
				&:after{
					margin: -3px 0 0 16px;
				}
			}
		}
	}
}

@include sp {
	.p-sosaiKnowledge {
		font-size: 1.6rem;
	}
	//葬儀の基礎知識・マナー sp
	.p-sosaiKnowledge01 {
		min-width: 100%;
		// margin-bottom: 50px;
		padding: 32px 0 33px;
		.c-sosaiTitle03{
			img{
				margin: 8px auto 0;
    			width: 91px;
			}
		}
	}

	//葬儀の基礎知識・マナー 本文
	.p-sosaiKnowledge02 {
	    margin: 0 0 60px;
	    padding: 46px 20px 30px;
		.u-inner01{
			> p{
				text-align: left;
				font-size: 1.4rem;
				line-height: 1.7;
				margin-bottom: 35px;
			}
		}
		&__content {
			margin: 20px -5px 20px;
			padding: 20px;
			border-top: solid 1px #2e95a3;
			&__txt {
				padding: 0;
				position: static;
				.c-sosaiTitle12{
					font-size: 1.8rem;
				    line-height: 2.1rem;
				    padding: 2px 0 1px 29px;
				    min-height: 21px;
				    margin-bottom: 17px;
				    &:before{
				    	width: 21px;
				    	height: 21px;
				    }
				}
				img {
					margin: 0 auto 16px auto;
					display: block;
					position: static;
				}
			}
			p {
				margin-bottom: 15px;
				font-size: 1.4rem;
				line-height: 1.7;
			}
			.c-sosaiBtn07 {
				width: 94%;
				height: 45px;
				margin: 0 auto;
				line-height: 3.2;
				a{
					font-size: 1.3rem;
					&:after{
						margin: -3px 0 0 6px;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
