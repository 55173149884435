@use '../0_base' as *;

/*------------------------------------------------------------
footer09
------------------------------------------------------------*/
.c-footer09 {
    margin-top: 40px;
    padding-top: 10px;
    padding-bottom: 8px;
    background-color: #EFEFEF;
    text-align: center;
    @include pc {
        margin-top: 80px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &__logo {
        img {
            width: 140px;
            height: auto;
            @include pc {
                width: 272px;
            }
        }
    }
    &__copyright{
        margin-top: 1px;
        color: #999;
        font-size: 0.9rem;
        @include pc {
            margin-top: 10px;
            color: #000;
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
}
