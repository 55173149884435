@use '../../0_base' as *;

/*------------------------------------------------------------
保険　資料請求フォーム（カート） 入力・エラー
------------------------------------------------------------*/
//フォーム
.p-hokenRequestInput02 {
	&__content01 {
		&__txt {
            color: #333;
			font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }
		}
		&__txt2 {
			margin-bottom: 15px;
			font-size: 1.6rem;
			color: $gray01;
		}
	}
	&__content02 {
        padding-top: 20px;
        @include pc {
            padding-top: 40px;
        }
	}
	&__content03 {
        .c-cartBtn {
            margin: 0;
            input.c-cartBtn__btn05 {
                margin: 0 !important;
                padding: 15px 20px !important;
                max-width: 590px !important;;
                height: auto !important;
                display: block !important;
                border-radius: 9999px !important;
                background: #FA8246 url("../img/common/icon_arrow_right01_white.svg") no-repeat center right 20px !important;
                background-size: 12px auto !important;
                border: 1.5px solid transparent !important;
                color: #fff !important;
                font-size: 1.6rem !important;
                font-weight: bold !important;
                cursor: pointer !important;
                float: none !important;
                @include pc {
                    width: 100% !important;
                    padding: 33px 50px !important;
                    background-position: center right 50px !important;
                    background-size: 24px auto !important;
                    border-width: 3px;
                    column-gap: 10px !important;
                    border-width: 3px !important;
                    font-size: 2.4rem !important;
                    transition: .3s ease-in-out;
                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }
	}
}

// フォーム01
.c-hokenFormInput01 {
    padding: 5px 20px;
    width: 100%;
    border: 1px solid #888;
    border-radius: 5px;
    height: 50px;
    font-size: 1.4rem;
    &::placeholder {
        color: #888;
    }
    @include pc {
        padding: 10px 40px;
        height: 80px;
        border-radius: 10px;
        font-size: 1.6rem;
    }
}
.c-hokenFormTextArea01 {
    padding: 15px 20px;
    width: 100%;
    height: 206px;
    border: 1px solid #888;
    border-radius: 5px;
    font-size: 1.4rem;
    &::placeholder {
        color: #888;
    }
    @include pc {
        padding: 30px 40px;
        height: 300px;
        border-radius: 10px;
        font-size: 1.6rem;
    }
}
.c-hokenFormSelect01{
    width: 100%;
    > select {
        padding: 5px 20px;
        width: 100%;
        border: 1px solid #888;
        border-radius: 5px;
        height: 50px;
        font-size: 1.4rem;
        &::placeholder {
            color: #888;
        }
        @include pc {
            padding: 10px 40px;
            height: 80px;
            border-radius: 10px;
            font-size: 1.6rem;
        }
    }
}
.c-hokenFormRadioGroup01 {
    display: grid;
    row-gap: 15px;
    @include pc {
        row-gap: 25px;
    }
}
.c-hokenFormRadio01 {
    width: fit-content;
    input {
        display: none;
    }
    label {
        cursor: pointer;
        display: flex;
        column-gap: 8.5px;
        @include pc {
            column-gap: 17px;
        }
        &::before {
            content: "";
            min-width: 21px;
            width: 21px;
            height: 21px;
            background-color: #fff;
            border: 1px solid #888;
            border-radius: 50%;
            display: block;
            @include pc {
                min-width: 27px;
                width: 27px;
                height: 27px;
            }
        }
    }
    input:checked + label::before {
        border-color: #41B4E6;
        border-width: 7px;
        @include pc {
            border-width: 10px;
        }
    }
}
.c-hokenFormCheckboxGroup01 {
    display: grid;
    row-gap: 15px;
    @include pc {
        row-gap: 25px;
    }
}
.c-hokenFormCheckbox01 {
    width: fit-content;
    input {
        display: none;
    }
    label {
        position: relative;
        cursor: pointer;
        display: flex;
        column-gap: 8.5px;
        font-size: 1.4rem !important;
        @include pc {
            column-gap: 17px;
            font-size: 1.8rem !important;
        }
        &::before {
            content: "";
            min-width: 21px;
            width: 21px;
            height: 21px;
            background-color: #fff;
            border: 1.5px solid #888;
            border-radius: 2.5px;
            display: block;
            position: relative;
            top: -1px;
            @include pc {
                min-width: 27px;
                width: 27px;
                height: 27px;
                border-radius: 5px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: 1px;
            left: 6px;
            width: 9px;
            height: 14px;
            transform: rotate(45deg);
            border-width: 3px;
            border-style: solid;
            border-color: #fff;
            border-top: 0;
            border-left: 0;
            @include pc {
                top: 2px;
                left: 8px;
                width: 11px;
                height: 17px;
            }
        }
    }
    input:checked + label::before {
        background-color: #41B4E6;
        border-color: #41B4E6;
    }
}

.c-table3--error {
    .c-hokenFormInput01,
    .c-hokenFormSelect01 > select,
    .c-hokenFormTextArea01 {
        border-color: #F43;
        background-color: #FFEDE3;
    }
}

/*
------------------------------------------------------------*/


