@use '../../0_base' as *;

/*------------------------------------------------------------
料金プラン　ゆきげ葬（やまなし）
------------------------------------------------------------*/
.p-sosaiPrice-yamanashi {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
	.c-breadcrumb1{
		padding: 9px 0 76px;
	}
}
//ゆきげ神奈川の料金プラン
.p-sosaiPrice-yamanashi01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
		img {
			margin-top: 6px;
		}
	}
}

//ゆきげ葬のお葬式プラン
.p-sosaiPrice-yamanashi02 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		padding-top: 25px;
		margin-bottom: 61px;
		font-weight: normal;
		line-height: 0.9;
		span {
			font-size: 2rem;
			padding-top: 14px;
			&:after {
				margin-top: 21px;
			}
		}
	}
	&__content01 {
		margin-bottom: 47px;
		.c-sosaiTitle09{
			margin-bottom: 22px;
			padding-left: 28px;
			line-height: 1;
		}
		p{
			font-size: 1.7rem;
			line-height: 3rem;
			@include sp{
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}
	&__content02 {
		.c-sosaiTitle10{
			margin-bottom: 21px;
			&:before{
				margin: -5px 10px 0 -30px;
			}
		}
		&__plus {
			align-self: center;
		}
		&__box {
			border: solid 1px $glaylight13;
			&--01 {
				width: 220px;
				dd{
					padding: 94px 17px 74px;
				}
			}
			&--02 {
				width: 812px;
				dd{
					padding: 30px 30px 29px;
				}
			}
			&__title1 {
				width: 100%;
				display: table;
				border-bottom: dotted 1px $gray07;
				margin: 17px 0 18px;
				padding-bottom: 12px;
				h5 {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 1.4;
					vertical-align: middle;
					display: table-cell;
					@include mincyo;
				}
			}
			&__title2 {
				width: 100%;
				height: 83px;
				display: table;
				border-bottom: dotted 1px $gray07;
				padding-top: 3px;
				h5 {
					font-size: 2.0rem;
					font-weight: 500;
					line-height: 1.2;
					vertical-align: middle;
					display: table-cell;
					@include mincyo;
				}
				span {
					font-size: 1.2rem;
					display: block;
				}
			}
			&__border {
				border: 1px solid #c8c8c8;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				> p {
					width: 100%;
					font-size: 1.5rem !important;
					font-weight: bold;
					margin-bottom: 16px;
				}
				> div {
					width: 190px;
					margin: 0 auto;
				}
				&:nth-child(1) {
					width: 452px;
					padding: 27px 12px 24px;
				}
				&:nth-child(2) {
					width: 268px;
					padding: 27px 0 44px;
				}
			}
			dt {
				width: 100%;
				padding: 14px 5px 9px;
				font-size: 1.6rem;
				font-weight: 700;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: center;
			}
			dd {
				> div {
					text-align: center;
					p {
						font-size: 1.4rem;
						line-height: 2rem;
					}
					img {
						width: 160px;
						height: auto;
					}
				}
			}
		}
	}
}

//お葬式プラン
.p-sosaiPrice-yamanashi03 {
	margin-bottom: 120px;
	padding: 107px 0 120px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.u-inner01 {
		> p {
			margin-bottom: 73px;
		}
	}
	h4.c-sosaiTitle09 {
		font-size: 1.8rem;
		@include sp {
			font-size: 1.4rem;
		}
	}
	&__content01 {
		margin-bottom: 60px;
	}
	&__content02{
		.p-sosaiPrice-yamanashiPlanArea__box01{
			margin-bottom: 47px;
		}
		.p-sosaiPrice-yamanashiPlanArea__box02{
			padding: 33px 30px 6px 37px;
		}
		.p-sosaiPrice-yamanashiPlanArea__flow01{
			margin: 0px auto 68px auto;
			padding-top: 2px;
		}
		.p-sosaiPrice-yamanashiPlanArea__service{
			padding-top: 10px;
			margin-bottom: 0;
			li{
				padding: 0 20px 58px;
				&:nth-of-type(6){
					img{
						margin: -11px auto 15px;
					}
					p{
						padding-right: 17px;
					}
				}
				&:nth-of-type(7){
					img {
						margin: -8px 0 12px -3px;
					}
					p{
						padding-right: 10px;
					}
				}
				&:nth-of-type(8){
					img{
						margin: 0 auto 4px -4px;
					}
				}
				&:nth-of-type(9){
					img{
						margin: -8px auto 12px 6px;
					}
					p{
						padding-left: 10px;
					}
				}
				&:nth-of-type(10){
					img{
						margin: -8px auto 12px 9px;
					}
					p{
						padding-left: 16px;
					}
				}
			}
		}
	}
}


//お葬式プランエリア
.p-sosaiPrice-yamanashiPlanArea {
	padding: 50px 50px 43px;
	background-color: #ffffff;
	font-size: 1.4rem;
	line-height: 1.8;
	border: solid 1px $sosaiNaviColor01;
	.c-sosaiTitle11 {
		width: calc(100% + 100px);
		margin-top: -50px;
		margin-right: -50px;
		margin-left: -50px;
		padding: 22px 15px 14px 84px;
	}
	.c-sosaiTitle10{
		&:before{
			margin: -5px 9px 0 -30px;
		}
	}
	&__box01 {
		margin-bottom: 35px;
		padding: 32px 35px 36px 44px;
		font-size: 2.0rem;
		font-weight: 500;
		color: $sosaiNaviColor01;
		position: relative;
		z-index: 10;
		@include mincyo;
		em {
			color: $sosaiNaviColor03;
		}
		&:before, &:after {
			width: calc(100% - 10px);
			height: calc(100% - 14px);
			content: '';
			border: solid 1px rgba($sosaiNaviColor03,0.4);
			position: absolute;
			z-index: -1;
		}
		&:before {
			top: 0;
			left: 1px;
		}
		&:after {
			top: 10px;
			left: 11px;
		}
	}
	&__box02 {
		margin: 25px auto 17px;
		padding: 33px 30px 9px 37px;
		background-color: $glaylight02;
		border: solid 1px $glaylight13;
		flex-wrap: wrap;
		p{
			line-height: 3.2rem;
			width: 100%;
		}
	}
	&__annotation {
		font-size: 1.4rem;
		padding-right: 4px;
    	margin-top: -4px;
	}
	&__annotation2 {
		font-size: 1.2rem;
	}
	&__price01 {
		margin-bottom: 10px;
		li {
			width: 320px;
			text-align: center;
			@include mincyo;
		}
		&__name {
			font-size: 2.4rem;
			font-weight: 500;
			line-height: 1;
			color: $sosaiNaviColor01;
		}
		&__price {
			color: $sosaiNaviColor01;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 1.2;
			margin-bottom: 15px;
			em {
				color: $sosaiNaviColor03;
				font-size: 6.6rem;
				margin-right: 5px;
				line-height: 1;
			}
			span {
				margin: 0 5px;
				font-size: 3rem;
				line-height: 1;
				font-weight: 500;
				display: inline-block;
			}
			&--sub {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 3.3rem;
				.small {
					font-size: 3rem;
				}
			}
		}
		&__img {
			margin-top: 5px;
			img {
				width: 100%;
				margin: 0;
			}
		}
		&__text {
			margin: 10px 0 20px 0;
			p{
				font-size: 1.6rem;
				font-weight: 700;
				line-height: 2rem;
				color: #333745;
				@include gosick;
			}
		}
	}
	&__price02 {
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 1.2;
		display: flex;
		align-items: baseline;
		em {
			font-size: 6.6rem;
			margin-right: 5px;
		}
		span {
			margin: 0 5px;
			font-size: 3rem;
			font-weight: 500;
			display: inline-block;
		}
		&--sub {
			display: flex;
			align-items: center;
			font-size: 3.3rem;
			.small {
				font-size: 3rem;
			}
		}
	}
	&__flow01{
		margin: -8px auto 66px auto;
		.c-sosaiPlanFlow__item{
			&:nth-of-type(1){
				.c-sosaiPlanFlow__txt{
					padding: 18px 0 8px;
				}
			}
			&:nth-of-type(3){
				.c-sosaiPlanFlow__txt{
					padding: 19px 0 7px 4px;
				}
			}
			&:nth-of-type(4){
				.c-sosaiPlanFlow__txt{
					padding: 18px 0 8px 3px;
				}
				.c-sosaiPlanFlow__icon{
					padding-bottom: 35px;
					padding-left: 5px;
				}
			}
			&:nth-of-type(5){
				.c-sosaiPlanFlow__txt{
					padding: 20px 0 6px 8px;
				}
				.c-sosaiPlanFlow__icon{
					padding-bottom: 33px;
					padding-left: 5px;
				}

			}
			&:nth-of-type(6){
				.c-sosaiPlanFlow__txt{
					padding: 12px 0 14px 8px;
				}
				.c-sosaiPlanFlow__icon{
					padding-bottom: 35px;
					padding-left: 5px;
				}
			}
		}
	}
	&__service {
		margin-bottom: 10px;
		li {
			width: 20%;
			padding: 0 20px 44px;
			text-align: center;
			img {
				margin: -2px auto 5px auto;
				display: block;
			}
			p {
				font-size: 1.6rem;
				font-weight: 700;
				line-height: 2rem;
				color: $sosaiNaviColor01;
				span {
					font-size: 1.2rem;
				}
			}
			&:nth-of-type(1){
				p{
					padding-right: 14px;
				}
			}
			&:nth-of-type(2){
				p{
					padding-right: 4px;
				}
			}
			&:nth-of-type(3){
				img{
					margin-bottom: 17px;
				}
				p{
					padding-left: 3px;
				}
			}
			&:nth-of-type(4){
				img{
					margin-bottom: 17px;
				}
			}
			&:nth-of-type(5){
				img{
				    margin-bottom: 17px;
				    margin-left: 12px;
				}
				p{
					padding-left: 21px;
				}
			}
			&:nth-of-type(6){
				img{
				    margin-left: -3px;
				}
				p{
					padding-right: 13px;
				}
			}
			&:nth-of-type(7){
				img{
					margin-bottom: 7px;
				}
				p{
					padding-right: 3px;
				}
			}
			&:nth-of-type(8){
				img{
					margin-bottom: 7px;
				}
			}
			&:nth-of-type(9){
				img{
				    margin-bottom: 2px;
				    margin-left: 8px;
				    margin-top: -7px;
				}
				p{
					padding-left: 10px;
				}
			}
			&:nth-of-type(10){
				img{
					margin-bottom: 21px;
					margin-left: 12px;
					margin-top: -2px;
				}
				p{
					padding-left: 20px;
				}
			}
			&:nth-of-type(11){
				img{
				    margin-bottom: -1px;
				    margin-left: -7px;
				    margin-top: -14px;
				}
				p{
					padding-right: 14px;
				}
			}
			&:nth-of-type(12){
				img{
				    margin-bottom: 10px;
				}
				p{
					padding-right: 6px;
				}
			}
			&:nth-of-type(13){
				img{
					margin-bottom: -2px;
					margin-top: -4px;
				}
			}
		}
	}
	&__service2 {
		margin-bottom: 15px;
		border: 2px solid rgba($sosaiNaviColor03,0.4);
		padding: 35px 20px 34px 38px;
		ul {
			li{
				&:before{
					margin: -3px 15px 0 -16px;
				}
			}
			&.mb30 {
				margin-bottom: 30px;
			}
		}
		p {
			margin-bottom: 10px;
		}
		&:nth-of-type(2n) {
			margin-bottom: 30px;
		}
		&.mb30 {
			margin-bottom: 30px;
		}
	}
	&__note{
		font-size: 1.4rem;
		margin-bottom: 47px;
		&.mb45 {
			margin-bottom: 45px;
			@include sp {
				margin-bottom: 24px;
			}
		}
	}
	&__marker {
		background: #f8f8f8;
		display: inline-block;
		padding: 10px 20px;
	}
	&__text{
		line-height: 2rem;
		margin-bottom: 10px;
	}
	&__List01 {
		li {
			margin-right: 2em;
			padding-left: 16px;
			font-weight: 700;
			&:before {
				width: 6px;
				height: 6px;
				margin: -3px 10px 0 -16px;
				content: '';
				background-color: $sosaiNaviColor02;
				display: inline-block;
				vertical-align: middle;
				@include radius(50%);
			}
		}
	}

	&__List02 {
		width: 25%;
		margin-top: 19px;
		margin-bottom: 14px;
		&:nth-of-type(2){
			li{
				&:first-child{
					margin-bottom: 30px;
				}
				&:last-child{
					margin-right: -70px;
				}
			}
		}
		&:nth-of-type(3),
		&:nth-of-type(4){
			li{
				&:before {
					margin: -3px 13px 0 -22px;
				}
			}
		}
		li {
			margin-bottom: 15px;
			padding: 0 5px 0 21px;
			font-weight: 700;
			font-size: 1.4rem;
			line-height: 1.8rem;
			&:before {
				width: 6px;
				height: 6px;
				margin: -3px 14px 0 -19px;
				content: '';
				background-color: $sosaiNaviColor02;
				display: inline-block;
				vertical-align: middle;
				@include radius(50%);
			}
		}
	}
}




//各種プランのセット内容一覧
.p-sosaiPrice-yamanashi04 {
	margin-bottom: 128px;
	.c-sosaiTitle02b{
		padding-top: 5px;
		margin-bottom: 59px;
	}
	&__table {
		width: 100%;
		margin-bottom: 60px;
		border-collapse: collapse;
		position: relative;
		thead {
			tr {
				position: relative;F
				&:after {
					width: 100%;
					height: 0;
					content: '';
					border-bottom: dotted 1px $gray07;
					position: absolute;
					bottom: 0;
					left: 0;
				}
				th {
					padding: 19px 20px;
				}
			}
		}
		tbody {
			tr {
				position: relative;
				&:after {
					width: 100%;
					height: 0;
					content: '';
					border-bottom: dotted 1px $gray07;
					position: absolute;
					//bottom: 0;
					left: 0;
				}
				th {
					padding: 29px 20px 28px 48px;
					font-weight: 500;
					text-align: left;
					background-color: #ffffff;
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 10px 0 -18px;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}

				}
				td {
					padding: 20px;
					text-align: center;
					background-color: $glaylight03;
				}
				&:nth-child(odd) {
					th {
						background-color: $glaylight03;
					}
					td {
						background-color: $glaylight18;
					}
				}
			}
		}
		&__title {
			width: 36%;
			font-size: 2.0rem;
			font-weight: 700;
			color: #ffffff;
			background-color: $sosaiNaviColor01;
			@include mincyo;
			&--01 {
				position: relative;
				&:before {
					width: 10px;
					height: 100%;
					content: '';
					background-color: #ffffff;
					position: absolute;
					top: 0;
					right: -5px;
				}
			}
		}
		&__content {
			width: 35%;
			&--01 {
				position: relative;
				&:before {
					width: 10px;
					height: 100%;
					content: '';
					background-color: #ffffff;
					position: absolute;
					top: 0;
					right: -5px;
				}
			}
		}
	}
	.c-sosaiBtn03 {
		width: 498px;
		margin: 0 auto;
		text-align: center;
		a{
			padding: 23px;
			&:before{
				margin: -5px 20px 0 0;
			}
		}
	}
}

//関連コンテンツ
.p-sosaiPrice-yamanashi05 {
	padding: 106px 0 121px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 56px;
	}
	.c-sosaiKanrenLink01{
		li{
			margin-right: 30px;
			&:last-child{
				margin-right: 0;
			}
		}
	}
}

@include sp {
	.p-sosaiPrice-yamanashi {
		font-size: 1.6rem;
	}
	//ゆきげ神奈川の料金プラン　sp
	.p-sosaiPrice-yamanashi01 {
		min-width: 100%;
		margin-bottom: 25px;
		padding: 16px 0 10px;
		.c-sosaiTitle03{
			line-height: 2.4rem;
			span {
				padding-top: 2px;
			}
		}
	}

	//ゆきげ葬のお葬式プラン sp
	.p-sosaiPrice-yamanashi02 {
		margin-bottom: 50px;
		padding: 0 20px;
		.c-sosaiTitle02b {
		    margin-bottom: 36px;
		    span {
				font-size: 1.5rem;
				padding-top: 9px;
				&:after {
					height: 2px;
					margin-top: 16px;
				}
			}
		}
		&__content01 {
			margin-bottom: 40px;
			.c-sosaiTitle09{
				margin-left: -5px;
				padding-left: 14px;
				margin-bottom: 18px;
			}
		}
		&__content02 {
			.c-sosaiTitle10{
				margin-bottom: 19px;
				&:before{
					margin: -5px 5px 0 -30px;
				}
			}
			&__plus {
				margin: 20px auto;
				display: block;
				width: 22px;
			}
			&__box {
				&--01 {
					width: 100%;
					dd {
						padding: 20px 20px 15px;
					}
				}
				&--02 {
					width: 100%;
					dd {
						padding: 20px 20px 21px;
					}
				}
				&__title1 {
				    padding-bottom: 5px;
				    display: block;
				    margin: 19px 0 8px;
				    letter-spacing: 1px;
					h5 {
						font-size: 1.8rem;
						display: inherit;
						line-height: 1.2;
					}
				}
				&__title2 {
					height: auto;
					padding-bottom: 10px;
					display: block;
					h5 {
						font-size: 1.8rem;
						display: inherit;
					}
				}
				&__border {
					> p {
						font-size: 1.7rem !important;
						margin-bottom: 29px;
					}
					&:nth-child(1) {
						width: 100%;
						padding: 22px 15px 9px;
						margin-bottom: 20px;
						> div {
							&:last-of-type {
								margin-top: 14px;
							}
						}
					}
					&:nth-child(2) {
						width: 100%;
						padding: 22px 15px 10px;
					}
				}
				dt{
					padding: 6px 5px 3px;
				    letter-spacing: 1px;
				    font-size: 1.4rem;
				    letter-spacing: 0;
				}
				dd {
					> div {
						width: 100%;
						text-align: center;
						img {
							width: 104px;
						}
						> div {
							width: 100%;
						}
					}
				}
			}
		}
	}

	//お葬式プラン sp
	.p-sosaiPrice-yamanashi03 {
		margin-bottom: 52px;
		padding: 47px 20px;
		.c-sosaiTitle02b{
			margin-bottom: 26px;
		}
		.u-inner01 {
			> p {
				margin-bottom: 23px;
				text-align: left;
				font-size: 1.4rem;
				line-height: 1.7;
			}
		}
		&__content01 {
			margin-bottom: 49px;
		}
		&__content02 {
			.p-sosaiPrice-yamanashiPlanArea__box01 {
			    margin-bottom: 20px;
			}
			.p-sosaiPrice-yamanashiPlanArea__flow01{
				margin: 0px auto 19px auto;
				.c-sosaiPlanFlow__item{
					&:nth-of-type(1){
						padding: 15px 21px 13px 18px;
						.c-sosaiPlanFlow__txt{
							padding-top: 5px;
						}
					}
					&:nth-of-type(3){
						padding: 24px 21px 13px 18px;
						.c-sosaiPlanFlow__txt{
							img{
								width: 32px;
							}
						}
						.c-sosaiPlanFlow__icon{
							img{
								width: 28px;
							}
						}
					}
				}
			}
			.p-sosaiPrice-yamanashiPlanArea__service{
				margin-bottom: 0;
				padding-top: 4px;
				li{
					padding: 0;
					&:nth-of-type(4){
						width: 33.3333333%;
						padding: 0;
						margin-bottom: 17px;
					}
					&:nth-of-type(6){
						img{
							margin: 0 auto 10px auto;
						}
						p{
							padding-right: 0;
						}
					}
					&:nth-of-type(7){
						p{
							padding-right: 0;
						}
					}
					&:nth-of-type(8){
						img{
							margin: 0 auto 10px auto;
						}
					}
					&:nth-of-type(9){
						img{
							margin: 0 auto 10px auto;
						}
						p{
							padding-left: 0;
						}
					}
					&:nth-of-type(10){
						img{
							margin: 0 auto 10px auto;
						}
						p{
							padding-left: 0;
						}
					}
				}
			}
			.p-sosaiPrice-yamanashiPlanArea__box02{
				margin: 10px 5px 14px;
				padding: 15px 15px 9px;
			}
		}
	}

	//お葬式プランエリア
	.p-sosaiPrice-yamanashiPlanArea {
		padding: 15px;
		.c-sosaiTitle11 {
			width: calc(100% + 30px);
			margin-top: -15px;
			margin-right: -15px;
			margin-left: -15px;
			padding: 20px 0 19px 62px;
		    font-size: 1.9rem;
		    line-height: 2.4rem;
		    margin-bottom: 24px;
		    &:before {
			    width: 40px;
			    height: 40px;
			    margin: -8px 9px -6px -50px;
			}
		}
		.c-sosaiTitle10{
			line-height: 2rem;
			margin: 0 auto 10px;
			&:before{
				margin: -5px 6px 0 -20px;
			}
		}
		&__box01 {
			margin-bottom: 52px;
			padding: 22px 30px 22px;
			font-size: 1.8rem;
			line-height: 2.5rem;
			&:before{
				width: calc(100% - 15px);
				height: calc(100% - 11px);
				top: 5px;
				left: 5px;
			}
			&:after {
				width: calc(100% - 15px);
				height: calc(100% - 11px);
				top: 10px;
				left: 10px;
			}
		}
		&__box02 {
			margin: 10px 5px 14px;
			padding: 15px 15px 1px;
			p {
				line-height: 1.5;
			}
		}
		&__annotation {
			font-size: 1.4rem;
		    line-height: 1.7;
		    margin: 10px -7px 0;
    		padding-right: 0;
		}
		&__annotation2 {
			font-size: 1.1rem;
			line-height: 1.5;
		}
		&__price01 {
			margin-bottom: 14px;
			li {
				width: 100%;
				margin-bottom: 28px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&__name {
				font-size: 1.7rem;
			}
			&__price {
				font-size: 1.7rem;
				margin-bottom: 7px;
				em {
					font-size: 5rem;
					line-height: 1;
				}
				span {
					font-size: 2.3rem;
				}
				&--sub {
						font-size: 2.6rem;
					.small {
						font-size: 2.3rem;
					}
				}
			}
			&__img{
				padding: 0 5px;
				margin-top: 3px;
			}
		}
		&__price02 {
			font-size: 2.0rem;
			flex-wrap: wrap;
			justify-content: center;
			em {
				font-size: 3.6rem;
			}
			span {
				font-size: 1.9rem;
			}
			&--sub {
				font-size: 2.2rem;
				.small {
					font-size: 1.9rem;
				}
			}
		}
		&__flow01{
			margin: 0 auto 18px auto;
			padding: 0 5px;
			.c-sosaiPlanFlow__item{
				&:nth-of-type(1){
					padding: 13px 21px 12px 18px;
					.c-sosaiPlanFlow__txt{
						padding: 0;
						img{
							width: 47px;
						}
					}
					.c-sosaiPlanFlow__icon{
						width: 16%;
					}
				}
				&:nth-of-type(2){
					padding: 23px 21px 11px 19px;
					.c-sosaiPlanFlow__txt{
						padding: 0;
						img{
							width: 45px;
						}
					}
					.c-sosaiPlanFlow__icon{
						img{
							width: 17px;
							margin-top: -8px;
						}
					}
				}
				&:nth-of-type(3){
					padding: 25px 21px 11px 19px;
					.c-sosaiPlanFlow__txt{
						padding: 0;
						img{
							width: 46px;
						}
					}
					.c-sosaiPlanFlow__icon{
						img{
							width: 15px;
							margin-top: -10px;
						}
					}
				}
				&:nth-of-type(4){
					padding: 15px 21px 13px 21px;
					.c-sosaiPlanFlow__txt{
						padding: 0;
						img{
							width: 46px;
						}
					}
					.c-sosaiPlanFlow__icon{
						padding: 0;
						img{
							width: 28px;
						}
					}
				}
				&:nth-of-type(5){
					padding: 15px 21px 14px 20px;
					.c-sosaiPlanFlow__txt{
						padding: 0;
						img{
							width: 63px;
						}
					}
					.c-sosaiPlanFlow__icon{
						padding: 0;
						img{
							width: 28px;
						}
					}
				}
				&:nth-of-type(6){
					padding: 22px 21px 9px 20px;
					.c-sosaiPlanFlow__txt{
						padding: 0;
						img{
							width: 41px;
						}
					}
					.c-sosaiPlanFlow__icon{
						padding: 0 10px 0 0;
						img{
							width: 25px;
						}
					}
				}
			}
		}
		&__service {
			li {
				width: 33.3333333%;
				padding: 0;
				margin-bottom: 17px;
				&:nth-of-type(1),
				&:nth-of-type(2),
				&:nth-of-type(3),
				&:nth-of-type(4),
				&:nth-of-type(5),
				&:nth-of-type(6),
				&:nth-of-type(7),
				&:nth-of-type(8),
				&:nth-of-type(9),
				&:nth-of-type(10),
				&:nth-of-type(11),
				&:nth-of-type(12),
				&:nth-of-type(13){
					img{
						padding: 0;
						margin: 0 auto 10px;
					}
					p{
						padding: 0;
						margin: auto;

					}
				}
				&:nth-of-type(7){
					img{
						margin-bottom: 3px;
					}
				}
				&:nth-of-type(8){
					img{
						margin-bottom: 3px;
					}
				}
				&:nth-of-type(9){
					margin-bottom: 11px;
					img{
						margin-bottom: 3px;
					}
				}
				&:nth-of-type(11){
					margin-bottom: 3px;
					img{
						margin-left: 8px;
						margin-bottom: 4px;
						margin-top: -5px;
					}
				}
				&:nth-of-type(10),
				&:nth-of-type(12){
					img{
						margin-bottom: 3px;
					}
				}
				img {
					margin: 0 auto 10px auto;
				}
				p {
					font-size: 1.1rem;
					line-height: 1.5rem;
					span{
						font-size: 0.9rem;
						line-height: 1.2rem;
					}
				}
			}
		}
		&__service2 {
			margin-bottom: 10px;
			padding: 20px 20px 10px;
			p{
				font-size: 1.4rem;
				line-height: 1.5;
				margin-bottom: 13px;
			}
			ul {
				display: block;
				li{
					font-size: 1.1rem;
					width: 100%;
					margin-bottom: 6px;
					&:before{
						margin: -3px 7px 0 -6px;
						width: 5px;
						height: 5px;
					}
				}
			}
		}
		&__text{
			font-size: 1.4rem;
			line-height: 1.7;
		}
		&__note {
			font-size: 1.1rem;
			line-height: 1.5;
			margin-bottom: 24px;
		}
		&__List01 {
			li {
				padding-left: 11px;
				font-size: 1.2rem;
				width: 100%;
				margin-bottom: 6px;
				&:before{
					margin: -3px 7px 0 -6px;
					width: 5px;
					height: 5px;
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		&__List02 {
			width: 100%;
			margin-top: 12px;
			margin-bottom: 0;
			&:nth-of-type(2){
				li{
					&:first-child {
					    margin-bottom: 10px;
					}
					&:last-child{
						margin-right: 0;
					}
				}
			}
			&:nth-of-type(3),
			&:nth-of-type(4){
				li{
					&:before{
						margin: -3px 7px 0 -6px;
						width: 5px;
						height: 5px;
					}
				}
			}
			li {
				padding: 0 5px 0 11px;
				margin-bottom: 10px;
				font-size: 1.1rem;
				line-height: 1.5rem;
				&:before{
					margin: -3px 7px 0 -6px;
					width: 5px;
					height: 5px;
				}
			}
		}
	}



	//各種プランのセット内容一覧 sp
	.p-sosaiPrice-yamanashi04 {
		margin-bottom: 50px;
		padding: 0 15px;
		&__table {
			margin-bottom: 30px;
			thead {
				tr {
					th {
						padding: 16px 5px;
					}
				}
			}
			tbody {
				tr {
					position: relative;
					&:nth-child(odd){
						th,td {
					    	background-color: #f6f6f6;
						}
					}
					&:last-child{
						.p-sosaiPrice-yamanashi04__table__content--01{
							&:after{
								width: calc(100% - 1px);
								height: 0;
								content: '';
								border-bottom: dotted 1px #4c4c4c;
								position: absolute;
								bottom: 0;
								left: 0;
							}
						}
					}
					th {
						width: calc(100%/3);
						padding: 10px 5px 10px 24px;
						font-size: 1.3rem;
						line-height: 2rem;
						height: 62px;
						&:before {
							margin: -3px 7px 0 -13px;
						}

					}
					td {
						width: calc(100%/3);
						padding: 10px;
						background-color: transparent;
					}
				}
			}
			&__title {
				width: calc(100%/3);
				font-size: 1.4rem;
				line-height: 1.5rem;
				@include mincyo;
				&--01 {
					&:before {
						width: 1px;
						right: -1px;
					}
				}
			}
			&__content {
				font-size: 1.3rem;
				line-height: 2rem;
				&--01 {
					&:before {
						display: none;
					}
				}
			}
		}
		.c-sosaiBtn03{
			width: 100%;
			a{
				padding: 13px 5px 12px;
				font-size: 1.5rem;
				&:before{
					margin: -4px 6px 0 0;
				}
			}
		}
	}

	//関連コンテンツ sp
	.p-sosaiPrice-yamanashi05 {
		padding: 48px 15px 39px;
	    margin-bottom: 59px;
		.c-sosaiTitle02b{
			margin-bottom: 42px;
		}
		.c-sosaiKanrenLink01{
			flex-wrap: wrap;
			li{
				width: calc(50% - 5px);
				margin-bottom: 8px;
				margin-right: 10px;
				float: none;
				&:nth-child(2n){
					margin-right: 0;
				}
				img{
					margin-bottom: 8px;
				}
			}
		}
	}

}

/*
------------------------------------------------------------*/
