@use '../../0_base' as *;

/*------------------------------------------------------------
チケット 特集商品一覧
------------------------------------------------------------*/
.p-ticketFeature {
}
//特集ピックアップ
.p-ticketFeature01 {
	margin-bottom: 30px;
	&__img {
		margin-bottom: 39px;
		img {
			margin: 0 auto;
			display: block;
		}
	}
	&__text {
		padding-top: 20px;
		color: $ticketNaviColor02;
		background-image: url("../img/ticket/line01.png");
		background-position: 0 0;
		background-repeat: repeat-x;
		h1 {
			margin-bottom: 13px;
			font-size: 2.8rem;
			font-weight: bold;
			a {
				color: $ticketNaviColor02;
			}
		}
		p {
			font-size: 1.6rem;
			font-weight: 500;
			line-height: 1.75;
		}
	}
}

//特集一覧
.p-ticketFeature02 {
	margin-bottom: 54px;
	//コンポーネント　c-tiketItems01
	.c-tiketItems01 {
		&__item dl {
			margin-bottom: 23px;
			dt {
				font-size: 1.4rem;
				line-height: 2;
			}
			dd {
				box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
				a {
					color: #ef004f;
				}
			}
		}
	}
	&__list {
		.c-tiketItems01 {
			&__item {
				width: 260px;
				margin-bottom: 40px;
				margin-right: 30px;
				margin-left: 0;
				&:nth-of-type(3n) {
					margin-right: 0;
					@include sp {
						margin-right: 6%;
					}
				}
				&__img {
					height: 195px;
				}
				&__subCate {
					margin-bottom: 5px;
				}
				dl {
					padding-bottom: 7px;
					dd ul {
						padding-bottom: 18px;
					}
				}
			}
		}
	}

	//コンポーネント　c-ticketPager01
}

//特集その他
.p-ticketFeature03 {
	margin-bottom: 95px;
	&__slider {
		padding: 0 20px;
		&__item {
			width: calc(33.3333% - 20px);
			margin: 0 10px;
			img {
				width: 100%;
				height: 138px;
				object-fit: cover;
				font-family: 'object-fit: cover;'; /*IE対策*/
			}
			&:hover{
				opacity: 0.8;
				transition: 0.3s ease-in-out;
			}
			&__title {
				margin-top: 5px;
				font-size: 1.5rem;
				font-weight: bold;
				display: block;
			}
			a {
				color: $strongColor01;
			}
		}
		.slick-arrow {
			width: 11px;
			height: 19px;
			margin-top: -19px;
			&:before {
				content: none;
			}
			&.slick-prev {
				background-image: url("../img/common/icon_arrow-green02.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				opacity: 1 !important;
				z-index: 10;
				left: 0;
				transform: rotate(180deg);
			}
			&.slick-next {
				background-image: url("../img/common/icon_arrow-green02.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				opacity: 1 !important;
				z-index: 10;
				right: 0;
				transform: none;
			}
		}
	}
}

@include sp {
	//特集ピックアップ sp
	.p-ticketFeature01 {
		margin-bottom: 26px;
		&__img {
			margin-bottom: 20px;
			img {
				width: 100%;
			}
		}
		&__text {
			padding-top: 21px;
			margin: 0 20px;
			background-image: url("../img/ticket/line01_sp.png");
			background-size: 15px;
			h1 {
				margin-bottom: 10px;
				font-size: 1.8rem;
				line-height: 1.4;
			}
			p {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}

	//特集一覧 sp
	.p-ticketFeature02 {
		margin-bottom: 30px;
		padding: 0 20px;
		//コンポーネント　c-tiketItems01
		.c-tiketItems01 {
			&__item {
				width: 47%;
				margin: 0 6% 0 0;
				&:nth-child(2n) {
					margin: 0;
				}
				&__img {
					height: 97px;
				}
				&__subCate {
					margin-bottom: 8px;
				}
				dl dt {
					font-size: 1.2rem;
					line-height: 1.8;
				}
				dl {
					padding-bottom: 0;
					dd ul {
						padding-bottom: 10px;
					}
				}
			}
		}

		//コンポーネント　c-ticketPager01
	}
	//特集その他 sp
	.p-ticketFeature03 {
		padding: 0 20px;
		margin-bottom: 69px;
		&__slider {
			padding: 0;
			&__item {
				width: 100%;
				margin: 0 0 10px 0;
				padding: 0 0 10px 0;
				border-bottom: dashed 1px $gray03;
				img {
					width: 100%;
					height: 69px;
				}
				&__title {
					width: 60%;
					margin: 0;
					padding-left: 10px;
					font-size: 1.4rem;
				}
				a {
					width: 100%;
					display:  flex;
					align-items: center;
					img {
						width: 44%;
					}
				}
			}
			.slick-arrow {
				margin: 0;
				&.slick-prev {
					top: auto;
					bottom: -26px;
					left: 15%;
				}
				&.slick-next {
					top: auto;
					bottom: -26px;
					right: 15%;
				}
			}
			.slick-dots {
				width: 60%;
				margin: 0 auto;
				left: 0;
				right: 0;
				bottom: -23px;
				li {
					width: 10px;
					height: 10px;
					margin: 0 3px;
					padding: 1px;
					background-color: #ffffff;
					border: solid 1px $glaylight01;
					@include radius(50%);
					button {
						width: 6px;
						height: 6px;
						padding: 0;
						background-color: #ffffff !important;
						@include radius(50%);
						&:before {
							content: none;
						}
					}
					&.slick-active {
						button {
							background-color: $subColor08 !important;
						}
					}
				}
			}
		}
		.c-ticketTitle03 {
			margin-bottom: 20px;
		}
	}
}

/*
------------------------------------------------------------*/
