@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　提携会社について
------------------------------------------------------------*/
.p-reformPartner {
	background-color: #ffffff;
}

//提携会社について
.p-reformPartner01 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior05.jpg");
		}
	}
}

//コープのリフォームサービス
.p-reformPartner02 {
	color: $gray01;
	font-size: 1.5rem;
	line-height: 1.74;
	&__content01 {
		> p {
			margin-bottom: 32px;
		}
	}
	&__content02 {
		margin-bottom: 15px;
		padding: 26px 29px 25px;
		background-color: $subColor15;
		h3 {
			margin-bottom: 6px;
			font-size: 1.8rem;
			font-weight: bold;
		}
		ul {
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
			li {
				padding-left: 10px;
				font-feature-settings : "palt";
				&:before {
					width: 5px;
					height: 5px;
					margin: -3px 5px 0 -10px;
					content: '';
					background-color: $reformBtnColor08;
					display: inline-block;
					vertical-align: middle;
					@include radius(50%);
				}
			}
		}
	}
	&__annotation {
		font-size: 1.3rem;
		text-align: right;
	}
}



@include sp {

	//提携会社について sp
	.p-reformPartner01 {
		margin-bottom: 30px;
		&__content01 {
			.c-refomeTitle02 {
				background-image: url("../img/reform/title_obi_interior05_sp.jpg");
			}
		}
	}

	//コープのリフォームサービス sp
	.p-reformPartner02 {
		margin-bottom: 40px;
		padding: 0 20px;
		&__content01 {
			> p {
				margin-bottom: 20px;
				font-size: 1.4rem;
			}
		}
		&__content02 {
			padding: 15px;
			li {
				font-size: 1.4rem;
			}
		}
	}


}

/*
------------------------------------------------------------*/
