@use '../../0_base' as *;

/*------------------------------------------------------------
サービスサイト　お知らせ一覧
------------------------------------------------------------*/
.p-serviceInfoIndex {
}

//お知らせ一覧 ページタイトル
.p-serviceInfoIndex01 {
}

.p-serviceInfoIndex02 {
	&__tab {
		display: flex;
		justify-content: center;
		align-items: center;
		a {
			display: table;
			height: 70px;
			width: calc(100% / 7);
			background: url('../img/service/service_info_tab_bg.png');
			border-bottom: 3px solid transparent;
			border-left: 1px solid #fff;
			&:nth-of-type(1) {
				background-color: #a4c661;
				border-bottom-color: #a4c661;
				border-left: none;
				&.current, &:hover {
					background-color: #a4c661;
				}
			}
			&:nth-of-type(2) {
				background-color: #8cb340;
				border-bottom-color: #8cb340;
				&.current, &:hover {
					background-color: #8cb340;
				}
			}
			&:nth-of-type(3) {
				background-color: #fc819b;
				border-bottom-color: #fc819b;
				&.current, &:hover {
					background-color: #fc819b;
				}
			}
			&:nth-of-type(4) {
				background-color: #82cdf3;
				border-bottom-color: #82cdf3;
				&.current, &:hover {
					background-color: #82cdf3;
				}
			}
			&:nth-of-type(5) {
				background-color: #68ba93;
				border-bottom-color: #68ba93;
				&.current, &:hover {
					background-color: #68ba93;
				}
			}
			&:nth-of-type(6) {
				background-color: #faad5a;
				border-bottom-color: #faad5a;
				&.current, &:hover {
					background-color: #faad5a;
				}
			}
			&:nth-of-type(7) {
				background-color: #af8ebe;
				border-bottom-color: #af8ebe;
				&.current, &:hover {
					background-color: #af8ebe;
				}
			}
			&.current, &:hover {
				background: transparent;
				opacity: 1;
				span {
					font-weight: bold;
					color: #fff;
				}
			}
			span {
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				font-size: 1.6rem;
				color: #46483c;
				line-height: 1.3;
				padding-top: 5px;
			}
		}
	}
}

.p-serviceInfoIndex03 {
	margin-top: 19px;
	&__card {
		a {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 20px 0;
			border-bottom: 1px dashed #dddddd;
			&:hover {
				opacity: 1;
				.p-serviceInfoIndex03__card__img {
					opacity: 0.80;
				}
				.p-serviceInfoIndex03__card__title--txt {
					text-decoration: none;
				}
			}
		}
		&__img {
			flex-shrink: 0;
			width: 200px;
			transition: 0.3s ease-in-out;
			img {
				width: 100%;
			}
		}
		&__content {
			padding-left: 20px;
			.u-flex {
				margin-top: 13px;
				.c-newsCate01 {
					min-width: 100px;
					width: auto;
					padding: 0 10px;
					&.c-newsCate01--area {
						background-color: #a4c661;
					}
					&.c-newsCate01--cls {
						background-color: #a4c661;
					}
				}
			}
		}
		&__date {
			font-size: 1.6rem;
			font-weight: normal;
			color: #999999;
			margin-top: -4px;
			@include yu_gosick();
		}
		&__title {
			margin-top: 14px;
			font-size: 1.6rem;
			color: #46483c;
			&--txt {
				text-decoration: underline;
				transition: 0.3s ease-in-out;
			}
		}
		.u-flex {
			@include sp {
				-webkit-flex-wrap: wrap;
				    -ms-flex-wrap: wrap;
				        flex-wrap: wrap;
			    -webkit-align-content: space-between;
			            align-content: space-between;
			    margin-top: 0;
			}
		}
	}
}

//お知らせ一覧 ページャー
.p-serviceInfoIndex04 {
	margin: 40px 0 100px;
	* {
		margin-left: 10px;
		&:first-child {
			margin-left: 0;
		}
	}
	.wp-pagenavi {
		display: flex;
		span {
			&.current {
				background-color: $keycolor02;
				border: solid 3px $keycolor02;
				color: #fff;
				display: block;
				width: 60px;
				height: 60px;
				font-size: 1.8rem;
				line-height: 3;
				text-align: center;
				@include radius(30px);
				&:hover {
					border-color: $keycolor02;
				}
			}
		}
		.previouspostslink {
			display: block;
			width: 120px;
			height: 60px;
			font-size: 1.8rem;
			line-height: 3;
			color: $gray01;
			text-align: center;
			border: solid 3px $glaylight01;
			@include radius(30px);
			&:before {
				width: 6px;
				height: 10px;
				margin: -2px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_arrow-green04.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}
			&:hover {
				border-color: $keycolor02;
			}
		}
		.nextpostslink {
			display: block;
			width: 120px;
			height: 60px;
			font-size: 1.8rem;
			line-height: 3;
			color: $gray01;
			text-align: center;
			border: solid 3px $glaylight01;
			@include radius(30px);
			&:after {
				width: 6px;
				height: 10px;
				margin: -2px 0 0 10px;
				content: '';
				background-image: url("../img/common/icon_arrow-green04.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:hover {
				border-color: $keycolor02;
			}
		}
		.page {
			display: block;
			width: 60px;
			height: 60px;
			font-size: 1.8rem;
			line-height: 3;
			color: $gray01;
			text-align: center;
			border: solid 3px $glaylight01;
			@include radius(30px);
			&:hover {
				border-color: $keycolor02;
			}
		}
		.pages,.extend,.last {
			display: none;
		}
	}

}


@include sp {
	.p-serviceInfoIndex {
		padding-top: 29px;
	}
	.p-serviceInfoIndex01 {
		.c-serviceTitle01 {
			margin-bottom: 25px;
			font-size: 2.1rem;
			&:before {
				margin-bottom: 3px;
			}
			span {
				font-size: 1.4rem;
			}
		}
	}
	.p-serviceInfoIndex02 {
		&__tab {
			flex-wrap: wrap;
			justify-content: flex-start;
			&.active {
				border-bottom: 2px solid #a4c661;
			}
			a {
				width: 25%;
				height: 41px;
				border-bottom: none;
				box-sizing: border-box;
				&:not(:nth-of-type(3n+1)) {
					border-left: 2px solid #fff;
				}
				&:not(:nth-of-type(-n+3)) {
					border-top: 2px solid #fff;
				}
				span {
					font-size: 1.2rem;
					line-height: 1.2;
					padding-top: 0;
				}
			}
			div {
				width: 25%;
				height: 40px;
			}
		}
	}

	.p-serviceInfoIndex03 {
		margin-top: 11px;
		padding: 0 20px;
		&__card {
			display: block;
			line-height: 1.8;
			margin: -4px 0 -8px;
			&__img {
				display: none;
			}
			&__content {
				padding-left: 0;
				width: 100%;
			}
			&__date {
				font-size: 1.2rem;
				letter-spacing: 1.1px;
				margin-top: 0;
				@include gosick();
			}
			&__title {
				width: 100%;
				margin-top: 15px;
				font-size: 1.4rem;
				display: flex;
				align-items: flex-start;
				&--img {
					min-width: 36%;
					max-width: 36%;
					margin-right: 15px;
					img {
						min-width: 36%;
						width: 100%;
					}
				}
				&--txt {
					width: calc(100% - 36%);
				}
			}
		}
		.u-flex {
			.c-newsCate01 {
				margin: 13px 5px 0 0;
				font-size: 1.2rem;
				min-width: 90px;
				height: 20px;
				padding: 1px 5px;
			}
		}
	}

	//お知らせ一覧 ページャー　SP
	.p-serviceInfoIndex04 {
		margin-bottom: 91px;
		position: relative;
		* {
			margin-left: 0;
		}
		.wp-pagenavi {
			.pages,.extend,.last {
				display: block !important;
				font-size: 1.4rem;
				color: #46483c;
				line-height: 3;
			}
			.current,.page {
				display: none !important;
			}
			.previouspostslink {
				position: absolute;
				overflow: hidden;
				text-indent: 100%;
				white-space: nowrap;
				left: 25px;
				width: 40px;
				height: 40px;
				padding: 10px 0 0 12px;
				border: solid 1px $glaylight01;
				@include radius(20px);
				&:before {
					width: 10px;
					height: 17px;
					margin: 0;
					display: block;
				}
			}
			.nextpostslink {
				position: absolute;
				right: 25px;
				width: 40px;
				height: 40px;
				padding: 8px 0 0 16px;
				overflow: hidden;
				text-indent: 100%;
				white-space: nowrap;
				border: solid 1px $glaylight01;
				@include radius(20px);
				&:after {
					position: absolute;
					top: 0;
					bottom: 0;
					width: 10px;
					height: 17px;
					background-image: url("../img/common/icon_arrow-green04.png");
					margin: auto;
					display: block;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
