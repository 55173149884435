@use '../0_base' as *;

/*------------------------------------------------------------
list
------------------------------------------------------------*/

/* チケット
------------------------------------------------------------*/
.c-tiketItems01 {
	&__item {
		width: 233px;
		margin: 0 15px;
		color: $ticketNaviColor02;
		dl {
			padding-bottom: 5px;
			dt {
				width: 100%;
				height: 26px;
				margin-bottom: 10px;
				font-size: 1.6rem;
				font-weight: bold;
				line-height: 1.625;
				text-align: center;
				color: $ticketColor02;
				background-color: rgba($ticketColor01, 0.2);
				@include radius(13px);
				@media screen and (-ms-high-contrast: none) {
					padding-top: 3px;
				}
			}
			dd {
				width: 100%;
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
				position: relative;
				z-index: 1;
				&:before {
					width: 100%;
					height: 100%;
					content: '';
					//background-color: rgba(255,255,255,0.2);
					border: solid 3px $ticketColor01;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 10;
					opacity: 0;
					transition: 0.3s ease-in-out;
				}
				p {
					margin-bottom: 4px;
					padding: 0 20px;
					font-size: 1.5rem;
					font-weight: 600;
					line-height: 1.5;
					color: $strongColor01;
					transition: 0.3s ease-in-out;
				}
				ul {
					width: 100%;
					padding: 0 20px 10px 20px;
					li {
						line-height: 1.5;
						font-size: 1.3rem;
						font-weight: 500;
						color: $ticketNaviColor02;
					}
				}
			}
		}
		&__img {
			width: 100%;
			height: 130px;
			margin-bottom: 10px;
			-webkit-background-size: contain;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			overflow: hidden;
			span {
				width: 100%;
				height: 100%;
				-webkit-background-size: cover;
				background-size: cover;
				background-position: center center;
				opacity: 0.6;
				-ms-filter: blur(5px);
				filter: blur(5px);
				transform: scale(1.1);
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
			}
		}
		&__tags {
			width: 92px;
			height: 28px;
			padding-bottom: 8px;
			font-size: 1.2em;
			font-weight: 500;
			line-height: 1.9;
			font-style: normal;
			text-align: center;
			color: #ffffff;
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			top: 5px;
			left: -15px;
			z-index: 15;
			&--osusume {
				&:after {
					content: '';
					width: 82px;
					height: 23px;
					position: absolute;
					top: 0;
					right: 0px;
					background: url(../img/ticket/tag_ribon_orange1.png) no-repeat;
					background-size: 82px 23px;
					z-index: 15;
					@include sp {
						width: 54px;
						height: 15px;
						background-size: 53px 15px;
					}
				}
				&:before {
					content: '';
					width: 15px;
					height: 25px;
					position: absolute;
					left: 0px;
					top: 2px;
					background: url(../img/ticket/tag_ribon_orange2.png) no-repeat;
					background-size: 22px 25px;
					z-index: -1;
					@include sp {
						top: 1px;
						width: 9px;
						height: 17px;
						background-size: 15px 17px;
					}
				}
			}
			&--ninki {
				&:after {
					content: '';
					width: 82px;
					height: 23px;
					position: absolute;
					top: 0;
					right: 0px;
					background: url(../img/ticket/tag_ribon_red1.png) no-repeat;
					background-size: 82px 23px;
					z-index: 15;
					@include sp {
						width: 54px;
						height: 15px;
						background-size: 53px 15px;
					}
				}
				&:before {
					content: '';
					width: 15px;
					height: 25px;
					position: absolute;
					left: 0px;
					top: 2px;
					background: url(../img/ticket/tag_ribon_red2.png) no-repeat;
					background-size: 22px 25px;
					z-index: -1;
					@include sp {
						top: 1px;
						width: 9px;
						height: 17px;
						background-size: 15px 17px;
					}
				}
			}
			span {
				position: relative;
				z-index: 16;
			}
		}
		&__subCate {
			height: 20px;
			margin: 0 0 2px 20px;
			padding: 0 0.5em;
			font-size: 1.3rem;
			font-weight: 500;
			color: #232934;
			background-color: rgba($ticketBtnColor05, 0.1);
			display: inline-block;
			@media screen and (-ms-high-contrast: none) {
				padding-top: 3px;
			}
		}
		&__soldout {
			position: absolute;
			bottom: 10px;
			width: 85%;
			left: 0;
			right: 0;
			margin: 0 auto;
			color: #fff;
			font-size: 1.3rem;
			background-color: #ff819a;
			display: block;
			padding: 0.25em 0.5em;
			text-align: center;
		}
		&__count {
			position: absolute;
			bottom: 10px;
			width: 85%;
			left: 0;
			right: 0;
			margin: 0 auto;
			color: #783146;
			font-size: 1.3rem;
			background-color: #ffe6eb;
			display: block;
			padding: 0.25em 0.5em;
			text-align: center;
		}
		&__many {
			position: absolute;
			bottom: 10px;
			width: 85%;
			left: 0;
			right: 0;
			margin: 0 auto;
			color: #232934;
			font-size: 1.3rem;
			background: #ebf4e7;
			display: block;
			padding: 0.25em 0.5em;
			text-align: center;
		}
		&:hover {
			opacity: 1;
			dl {
				dd {
					&:before {
						opacity: 1;
					}
					p {
						color: $subColor08;
					}
				}
			}
		}
	}
}

@include sp {
	.c-tiketItems01 {
		&__item {
			width: 48%;
			margin: 0 1%;
			dl {
				padding-bottom: 0;
				dt {
					height: auto;
					font-size: 1.2rem;
					margin-bottom: 9px;
				}
				dd {
					p {
						padding: 0 10px;
						font-size: 1.2rem;
						line-height: 1.4;
						margin-bottom: 4px;
					}
					ul {
						padding: 0 10px 10px 10px;
						li {
							font-size: 1rem;
							line-height: 1.3;
						}
					}
				}
			}
			&__img {
				height: 74px;
			}
			&__subCate {
				height: auto;
				margin: 0 0 4px 10px;
				font-size: 0.8rem;
			}
			&__soldout {
				margin: 0 10px;
				font-size: 0.8rem;
			}
			&__count {
				margin: 0 10px;
				font-size: 0.8rem;
			}
			&__tags {
				top: 5px;
				left: -9px;
				font-size: 0.9rem;
				width: 60px;
				height: 17px;
				line-height: 1.9;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-tiketItems02 {
	&__item {
		width: 260px;
		margin-bottom: 30px;
		color: $ticketNaviColor02;
		a {
			width: 100%;
			display: block;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			position: relative;
			z-index: 1;
			&:before {
				width: 100%;
				height: 100%;
				content: '';
				//background-color: rgba(255,255,255,0.2);
				border: solid 3px $ticketColor01;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 10;
				opacity: 0;
				transition: 0.3s ease-in-out;
			}
			p {
				margin-bottom: 3px;
				padding: 0 20px 0 24px;
				font-size: 1.5rem;
				font-weight: 600;
				line-height: 1.5;
				color: $strongColor01;
				transition: 0.3s ease-in-out;
			}
			ul {
				width: 100%;
				padding: 0 20px 12px 22px;
				li {
					line-height: 1.5;
					font-size: 1.3rem;
					font-weight: 500;
					color: $ticketNaviColor02;
				}
			}
		}
		&__img {
			width: 100%;
			height: 195px;
			margin-bottom: 10px;
			-webkit-background-size: contain;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			overflow: hidden;
			span {
				width: 100%;
				height: 100%;
				-webkit-background-size: cover;
				background-size: cover;
				background-position: center center;
				opacity: 0.6;
				-ms-filter: blur(5px);
				filter: blur(5px);
				transform: scale(1.1);
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
			}
		}
		&__tags {
			width: 92px;
			height: 28px;
			padding-bottom: 8px;
			font-size: 1.2em;
			font-weight: 500;
			line-height: 1.9;
			font-style: normal;
			text-align: center;
			color: #ffffff;
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			top: 10px;
			left: -15px;
			z-index: 15;
			&--osusume {
				&:after {
					content: '';
					width: 82px;
					height: 23px;
					position: absolute;
					top: 0;
					right: 0px;
					background: url(../img/ticket/tag_ribon_orange1.png) no-repeat;
					background-size: 82px 23px;
					z-index: 15;
					@include sp {
						width: 54px;
						height: 15px;
						background-size: 53px 15px;
					}
				}
				&:before {
					content: '';
					width: 15px;
					height: 25px;
					position: absolute;
					left: 0px;
					top: 2px;
					background: url(../img/ticket/tag_ribon_orange2.png) no-repeat;
					background-size: 22px 25px;
					z-index: -1;
					@include sp {
						top: 1px;
						width: 9px;
						height: 17px;
						background-size: 15px 17px;
					}
				}
			}
			&--ninki {
				&:after {
					content: '';
					width: 82px;
					height: 23px;
					position: absolute;
					top: 0;
					right: 0px;
					background: url(../img/ticket/tag_ribon_red1.png) no-repeat;
					background-size: 82px 23px;
					z-index: 15;
					@include sp {
						width: 54px;
						height: 15px;
						background-size: 53px 15px;
					}
				}
				&:before {
					content: '';
					width: 15px;
					height: 25px;
					position: absolute;
					left: 0px;
					top: 2px;
					background: url(../img/ticket/tag_ribon_red2.png) no-repeat;
					background-size: 22px 25px;
					z-index: -1;
					@include sp {
						top: 1px;
						width: 9px;
						height: 17px;
						background-size: 15px 17px;
					}
				}
			}
			span {
				position: relative;
				z-index: 16;
			}
		}
		&__subCate {
			margin: 0 0 10px 20px;
			padding: 0.25em 0.5em;
			font-size: 1.3rem;
			font-weight: 500;
			color: #232934;
			background-color: rgba($ticketBtnColor05, 0.1);
			display: inline-block;
		}
		&:hover {
			a {
				opacity: 1;
				&:before {
					opacity: 1;
				}
				p {
					color: $subColor08;
				}
			}
		}
	}
}

@include sp {
	.c-tiketItems02 {
		&__item {
			width: calc(50% - 5px);
			margin: 0 10px 15px 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
			a {
				p {
					padding: 0 10px;
					font-size: 1.2rem;
					line-height: 1.3;
					margin-bottom: 4px;
				}
				ul {
					padding: 0 10px 10px 10px;
					li {
						font-size: 1rem;
						line-height: 1.2;
					}
				}
			}
			&__img {
				height: 98px;
			}
			&__subCate {
				margin: 0 0 4px 10px;
				font-size: 0.8rem;
			}
			&__tags {
				top: 6px;
				left: -9px;
				font-size: 0.9rem;
				width: 60px;
				height: 17px;
				line-height: 1.8;
				padding-bottom: 0;
			}
		}
	}
}

/* リフォーム
------------------------------------------------------------*/

.c-reformList01 {
	li {
		width: 250px;
		margin: 0 45px 52px 0;
		&:nth-child(3n) {
			margin: 0 0 52px 0;
		}
		img {
			width: 100%;
			height: 178px;
			margin: 0 0 14px 0;
			display: block;
			object-fit: cover;
		}
		h3 {
			margin-bottom: 8px;
			padding-left: 16px;
			font-size: 1.4rem;
			font-weight: 600;
			&:before {
				width: 6px;
				height: 10px;
				margin: -3px 10px 0 -16px;
				content: '';
				background-image: url('../img/common/icon_arrow-green05.png');
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		a {
			color: $reformNaviColor02;
			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}
		p {
			color: $gray01;
			font-size: 1.5rem;
			font-weight: 500;
			line-height: 1.75;
		}
	}
}
@include sp {
	.c-reformList01 {
		li {
			width: 48%;
			margin: 0 4% 20px 0 !important;
			&:nth-child(2n) {
				margin: 0 0 17px 0 !important;
			}
			img {
				margin: 0 0 6px 0;
				height: 91px;
			}
			h3 {
				font-size: 1.4rem;
				margin-bottom: 3px;
			}
			p {
				font-size: 1.4rem;
				line-height: 1.5;
			}
		}
	}
}

//商品一覧
.c-reformServiceItemList01 {
	&__link {
		margin-bottom: 60px;
		li {
			width: calc(20% + 2px);
			margin-right: -2px;
			font-size: 1.5rem;
			font-weight: bold;
			text-align: center;
			border: solid 2px $reformBtnColor08;
			transition: 0.3s ease-in-out;
			&:nth-child(5n) {
				width: 20%;
				margin-right: 0;
				@include sp {
					margin-right: -2px;
				}
			}
			&:nth-child(n + 6) {
				border-top: none;
				@include sp {
					border-top: solid 2px $reformBtnColor08;
				}
			}
			a {
				width: 100%;
				height: 100%;
				color: $reformBtnColor08;
				transition: 0.3s ease-in-out;
				padding: 11px 5px 7px;
				min-height: 52px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				position: relative;
				&:hover {
					opacity: 1;
				}
				&:after {
					width: 6px;
					height: 10px;
					content: '';
					display: block;
					background-image: url('../img/common/icon_arrow-green05.png');
					-webkit-background-size: cover;
					background-size: cover;
					transform: rotate(90deg) translateX(-50%);
					position: absolute;
					bottom: 7px;
					left: 50%;
				}
				span {
					display: block;
					margin-bottom: 10px;
					width: 100%;
					line-height: 1;
				}
			}
			&:hover {
				background-color: $reformBtnColor08;
				a {
					color: #ffffff;
					&:after {
						background-image: url('../img/common/icon_arrow-white01.png');
					}
				}
			}
		}
	}
	&__items {
		margin-bottom: 50px;
		ul {
			li {
				width: 273px;
				min-height: 211px;
				margin: 0 10px 10px 0;
				padding: 10px;
				text-align: center;
				background-color: $glaylight14;
				border: solid 1px $glaylight01;
				&:nth-child(3n) {
					margin: 0 0 10px 0;
				}
				img {
					margin-bottom: 25px;
					width: 100%;
					height: 126px;
					object-fit: cover;
				}
				a {
					padding: 0 10px;
					display: block;
					font-size: 1.4rem;
					font-weight: bold;
					text-align: left;
					padding-left: 1.8em;
					text-indent: -1.1em;
					color: $reformTextColor02;
					&:before {
						width: 6px;
						height: 10px;
						margin: -3px 10px 0 0;
						content: '';
						background-image: url('../img/common/icon_arrow_red.png');
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
				span {
					display: block;
					text-align: left;
					padding-left: 28px;
					font-size: 1rem;
					font-weight: 400;
					@include sp {
						padding-left: 16px;
					}
				}
				&:hover {
					opacity: 1;
					a {
						opacity: 1;
					}
					// .c-reformServiceItemList01__imgs{
					//	img{
					//		transform: scale(1.1);
					//	}
					// }
				}
			}
		}
	}
	&__imgs {
		overflow: hidden;
		margin-bottom: 25px;
		img {
			transition: 0.3s ease-in-out;
			width: 100%;
			margin-bottom: 0 !important;
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
}

@include sp {
	.c-reformServiceItemList01 {
		&__link {
			margin-bottom: 41px;
			li {
				width: calc(50% + 2px) !important;
				margin: 0 -2px -2px 0;
				font-size: 1.4rem;
				&:nth-child(2n) {
					width: 50% !important;
					margin-right: 0 !important;
				}
				a {
					padding: 7px 5px 7px;
				}
			}
		}
		&__items {
			margin-bottom: 29px;
			ul {
				li {
					width: 100%;
					min-height: auto;
					height: auto;
					margin: 0 0 9px 0 !important;
					img {
						margin-bottom: 11px;
					}
					a {
						font-size: 1.2rem;
						padding-right: 0;
						padding-left: 1.2em;
						text-indent: -1.3em;
					}
				}
			}
		}
	}
}

/* 葬祭
------------------------------------------------------------*/
.c-sosaiAreaList01 {
	img {
		margin: 17px 0 59px 187px;
		display: block;
	}
	ul {
		li {
			margin: 0 10px 10px 0;
			font-size: 1.6rem;
			font-weight: 500;
			@include mincyo;
			a {
				padding: 12px 30px 12px 30px;
				color: #ffffff;
				display: block;
				background-color: $sosaiNaviColor02;
				@include radius(3px);
			}
		}
	}
}
@include sp {
	.c-sosaiAreaList01 {
		img {
			margin: 0 auto 30px auto;
		}
		ul {
			li {
				margin: 0 0 5px 0;
				font-size: 1.3rem;
				text-align: center;
				display: block;
				width: 100%;
				a {
					width: 100%;
					min-height: 50px;
					height: 100%;
					padding: 9px 12px;
					line-height: 1.5;
					display: flex;
					-webkit-align-items: center;
					align-items: center;
					-webkit-justify-content: center;
					justify-content: center;
					vertical-align: middle;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-sosaiHallList {
	dl {
		margin-bottom: 30px;
		background-color: #ffffff;
		border: solid 1px $sosaiNaviColor01;
		&:last-child {
			margin: 0;
		}
		dt {
			padding: 10px 10px 10px 60px;
			font-size: 2.4rem;
			font-weight: 500;
			color: #ffffff;
			background-color: $sosaiNaviColor01;
			@include mincyo;
			&:before {
				width: 30px;
				height: 30px;
				margin: -8px 15px 0 -45px;
				content: '';
				background-image: url('../img/sosai/icon_sosai01.png');
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		dd {
			padding: 50px;
			font-size: 1.6rem;
			color: $gray08;
			table {
				width: 100%;
				margin-bottom: 30px;
				border-collapse: collapse;
				border-top: dotted 1px $gray08;
				line-height: 1.5;
				th {
					width: 160px;
					padding: 24px 10px;
					text-align: left;
					vertical-align: top;
					border-bottom: dotted 1px $gray08;
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 5px 0 0;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}
				}
				td {
					padding: 24px 30px 20px 0;
					vertical-align: top;
					border-bottom: dotted 1px $gray08;
					font-weight: 500;
				}
			}
			.c-sosaiBtn01 {
				width: 100%;
			}
		}
	}
	&__img {
		width: 435px;
		img {
			width: 435px;
			height: 326px;
			object-fit: cover;
		}
	}
	&__detail {
		width: 530px;
	}
}
@include sp {
	.c-sosaiHallList {
		dl {
			margin-bottom: 20px;
			dt {
				font-size: 1.9rem;
				padding: 18px 10px 15px 57px;
				&:before {
					width: 40px;
					height: 40px;
					margin-right: 11px;
				}
			}
			dd {
				padding: 15px 15px 20px;
				table {
					font-size: 1.4rem;
					margin-bottom: 15px;
					th {
						width: 35%;
						padding: 13px 0 0 5px;
						vertical-align: middle;
					}
					td {
						line-height: 1.3;
						padding: 10px 0 10px 0;
						vertical-align: middle;
					}
				}
				.c-sosaiBtn01 {
					width: 56%;
					height: auto;
					font-size: 1.3rem;
					line-height: 2.4;
					a {
						padding: 5px;
					}
				}
			}
		}
		&__img {
			width: 100%;
			margin-bottom: 15px;
			img {
				width: 100%;
				height: auto;
			}
		}
		&__detail {
			width: 100%;
		}
	}
}

/*
------------------------------------------------------------*/
.c-sosaiPlanFlow {
	text-align: center;
	> div {
		display: inline-block;
		border: 2px solid #29806c;
		overflow: hidden;
		@include clear;
	}
	&__item {
		width: 169px;
		padding: 19px 0 19px 0;
		float: left;
		text-align: center;
		position: relative;
		&:nth-child(2n) {
			background: #f6f6f6;
		}
		&:after {
			width: 40px;
			height: 21px;
			content: '';
			background: url(../img/component/3arrow.png) no-repeat;
			position: absolute;
			top: 50%;
			right: -20px;
			z-index: 10;
			transform: translate(0, -60%);
		}
		&:last-child {
			&:after {
				content: none;
			}
		}
	}
	&__txt {
		height: 120px;
		margin: 0 auto;
		padding: 13px 0;
	}
	&__icon {
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 30px;
	}
}
@include sp {
	.c-sosaiPlanFlow {
		&__item {
			width: 100%;
			display: table;
			text-align: left;
			padding: 13px 21px 12px 16px;
			&:after {
				width: 15px;
				height: 24px;
				background: url(../img/component/3arrow02.png) no-repeat;
				background-size: 100%;
				top: calc(100% - 9px);
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
		&__txt {
			width: 83%;
			height: auto;
			padding: 0;
			display: table-cell;
			vertical-align: middle;
		}
		&__icon {
			width: 15%;
			height: auto;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			padding-bottom: 0;
			img {
				vertical-align: middle;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-sosaiFlow01 {
	border: solid 2px $sosaiNaviColor02;
	> li {
		position: relative;
		min-height: 150px;
		padding: 0 40px 0 0;
		background-color: #f8f8f8;
		display: flex;
		&:after {
			width: 21px;
			height: 40px;
			margin: 0 auto;
			content: '';
			background: url('../img/component/3arrow02.png') no-repeat;
			-webkit-background-size: 100%;
			background-size: 100%;
			position: absolute;
			bottom: -18px;
			left: -1px;
			right: 0;
			z-index: 10;
		}
		&:nth-child(odd) {
			background-color: #fff;
			min-height: 148px;
		}
		&:nth-child(2),
		&:nth-child(4) {
			p {
				padding: 59px 0 0;
			}
		}
		&:nth-child(3) {
			p {
				padding: 46px 0 0;
			}
		}
		&:last-child {
			&:after {
				content: none;
			}
			p {
				padding: 45px 0 0;
			}
		}
		h3 {
			width: 387px;
			padding: 62px 45px 0 119px;
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 1.3;
			color: $gray08;
			//display: flex;
			display: block;
			// vertical-align: middle;
			position: relative;
			@include mincyo;
			img {
				width: 50px;
				position: absolute;
				top: 50px;
				left: 50px;
			}
		}
		p {
			width: calc(100% - 387px);
			line-height: 3rem;
			padding: 42px 0;
		}
	}
}
@include sp {
	.c-sosaiFlow01 {
		border: 2px solid #277861;
		> li {
			min-height: 97px;
			padding: 20px 15px 10px 55px;
			display: block;
			&:nth-child(odd) {
				min-height: 137px;
			}
			&:after {
				display: none;
			}
			&:nth-child(1) {
				padding: 21px 15px 21px 55px;
			}
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				p {
					padding: 0;
				}
			}
			&:last-child {
				padding: 22px 15px 16px 55px;
			}
			h3 {
				font-size: 1.6rem;
				display: block;
				width: 100%;
				padding: 0 0 9px;
				line-height: 1.8rem;
				img {
					position: absolute;
					top: -6px;
					left: -41px;
					width: 30px;
				}
			}
			p {
				width: 100%;
				padding: 0;
				font-size: 1.4rem;
				line-height: 1.7;
				span {
					font-size: 1.1rem;
					line-height: 1.5rem;
					display: block;
					margin-top: 3px;
				}
			}
		}
	}
}

/* コンポーネント
------------------------------------------------------------*/
.c-block01 {
	width: 100%;
	min-width: 1110px;
	@include sp {
		min-width: 100%;
	}
	&__item {
		width: 100%;
		display: table;
		@include sp {
			margin-bottom: 20px;
		}
		&:hover {
			opacity: 1;
			.c-block01__img {
				img {
					transform: scale(1.1);
				}
				p:before {
					border: solid 1px #fff !important;
				}
				p:after {
					opacity: 0.3;
				}
			}
			.service-btn {
				color: #8cb340;
				background: #fff;
				&:after {
					background: url('../img/component/arrow-green.png');
				}
			}
		}
	}
	&__txt {
		width: 50%;
		display: table-cell;
		vertical-align: middle;
		padding: 0 25px;
		@include sp {
			width: 100%;
			padding: 0 0 20px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
		.service-title {
			@include sp {
				text-align: center;
			}
			.wrapper {
				display: flex;
				align-items: center;
				@include sp {
					display: block;
				}
			}
			span {
				font-size: 3.2rem;
				font-weight: bold;
				padding: 0 0 0 20px;
				letter-spacing: 0.05em;
				@include sp {
					display: block;
					font-size: 2rem;
					padding: 8px 0 0 0;
				}
			}
		}
		.service-cont {
			font-size: 1.6rem;
			font-weight: 500;
			line-height: 2.8rem;
			padding: 2.5% 0px 4.3% 1px;
			color: #595950;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.7;
				padding: 1px 0 10px;
			}
		}
		.service-btn {
			width: 68%;
			height: 49px;
			margin: 0 auto;
			padding: 10px 7.2% 8px 5px;
			line-height: 20px;
			display: table;
			font-size: 1.6rem;
			line-height: 20px;
			text-align: center;
			text-decoration: none;
			font-weight: 500;
			border-radius: 50px;
			box-sizing: border-box;
			border: 2px solid #8cb340;
			color: #fff;
			background: #8cb340;
			position: relative;
			transition: 0.3s ease-in-out;
			@include Noto();
			@include sp {
				width: 100%;
				height: auto;
				font-size: 1.4rem;
				font-weight: normal;
				line-height: 1.3;
				padding: 8px 0;
			}
			&:after {
				content: '';
				position: absolute;
				background: url(../img/component/arrow-white.png);
				width: 7px;
				height: 12px;
				right: 7.5%;
				top: 50%;
				transform: translateY(-50%);
				@include sp {
					right: 14px;
				}
			}
			&:hover {
				color: #8cb340;
				background: #fff;
				&:after {
					background: url(../img/component/arrow-green.png);
				}
			}
		}
	}
	&__img {
		overflow: hidden;
		a,
		p {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			&:before {
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				margin: 10px;
				z-index: 2;
				transition: 0.3s ease-in-out;
			}
			&:after {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				z-index: 1;
				transition: 0.3s ease-in-out;
			}
		}
		img {
			width: 100%;
			object-fit: cover;
			height: fit-content;
			min-height: 340px;
			transition: 0.5s ease-in-out;
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
			a:before {
				border: solid 1px #fff !important;
			}
			a:after {
				opacity: 0.3;
			}
		}
	}
	&__imgsp {
		@include sp {
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.item01 {
		.c-block01__txt {
			background: url(../img/component/service_bg01.png);
			background-size: 100%;
			.service-title span {
				color: #8e5867;
			}
		}
		.c-block01__img {
			a:before,
			p:before {
				border: solid 1px #fc819b;
			}
			a:after,
			p:after {
				background: url(../img/component/service_bg06.png) repeat;
			}
		}
		.service-wrapper {
			max-width: 560px;
			width: 100%;
			margin: 0 auto;
		}
	}
	.item02 {
		.c-block01__txt {
			background: url(../img/component/service_bg02.png);
			background-size: 100%;
			.service-title span {
				color: #3d4e7e;
			}
		}
		.c-block01__img {
			a:before,
			p:before {
				border: solid 1px #82cdf3;
			}
			a:after,
			p:after {
				background: url(../img/component/service_bg07.png) repeat;
			}
		}
		.service-wrapper {
			max-width: 560px;
			width: 100%;
			margin: 0 auto;
		}
	}
	.item03 {
		.c-block01__txt {
			background: url(../img/component/service_bg03.png);
			background-size: 100%;
			.service-title span {
				padding: 0 0 0 11px;
				color: #043b18;
			}
		}
		.c-block01__img {
			a:before,
			p:before {
				border: solid 1px #68ba93;
			}
			a:after,
			p:after {
				background: url(../img/component/service_bg08.png) repeat;
			}
		}
		.service-wrapper {
			max-width: 561px;
			width: 100%;
			margin: 0 auto;
		}
	}
	.item04 {
		.c-block01__txt {
			background: url(../img/component/service_bg04.png);
			background-size: 100%;
			.service-title span {
				padding: 0 0 0 10px;
				color: #7f5125;
			}
		}
		.c-block01__img {
			a:before,
			p:before {
				border: solid 1px #faad5a;
			}
			a:after,
			p:after {
				background: url(../img/component/service_bg09.png) repeat;
			}
		}
		.service-wrapper {
			max-width: 575px;
			width: 100%;
			margin: 0 auto;
		}
	}
	.item05 {
		.c-block01__txt {
			background: url(../img/component/service_bg05.png);
			background-size: 100%;
			.service-title span {
				color: #503f69;
			}
		}
		.c-block01__img {
			a:before,
			p:before {
				border: solid 1px #af8ebe;
			}
			a:after,
			p:after {
				background: url(../img/component/service_bg10.png) repeat;
			}
		}
		.service-wrapper {
			max-width: 561px;
			width: 100%;
			margin: 0 auto;
		}
	}
	.item01,
	.item02,
	.item03,
	.item04,
	.item05 {
		.service-wrapper {
			@include sp {
				width: 100%;
				padding: 0 20px;
				margin: -35px 0 0;
				max-width: 100%;
			}
		}
	}
}
/*
------------------------------------------------------------*/
.c-block02 {
	width: 100%;
	min-width: 1110px;
	display: table;
	@include sp {
		display: block;
		min-width: 100%;
	}
	&__left {
		width: 50%;
		display: table-cell;
		img {
			width: 100%;
			object-fit: cover;
			height: fit-content;
		}
		@include sp {
			width: 100%;
			float: none;
			display: block;
			img {
				height: auto;
			}
		}
	}
	&__right {
		width: 50%;
		background: #faf9f6;
		display: table-cell;
		vertical-align: middle;
		p {
			padding-bottom: 31px;
			font-weight: 500;
		}
		@include sp {
			display: block;
			width: calc(100% - 30px);
			padding: 28px 31px 30px 30px;
			margin: -19px 15px 0;
			position: relative;
			z-index: 10;
			p {
				padding-bottom: 20px;
			}
		}
	}
	&__inner {
		width: 75%;
		padding: 0 0 0 80px;
		@include sp {
			width: 100%;
			padding: 0;
		}
	}
	&__title {
		display: inline-flex;
		align-items: center;
		line-height: 1;
		.title-txt {
			padding: 1px 0 0 16px;
			@include mincyo();
			@include sp {
				padding: 1px 0 0 10px;
			}
		}
		.title-txt01 {
			font-size: 1.7rem;
			@include sp {
				font-size: 1.2rem;
			}
		}
		.title-txt02 {
			font-size: 3.8rem;
			padding-top: 10px;
			font-weight: normal;
			@include sp {
				font-size: 2.5rem;
				padding-top: 7px;
			}
		}
	}
	&__txt {
		padding-top: 27px;
		font-size: 1.7rem;
		line-height: 30px;
		@include sp {
			width: 100%;
			font-size: 1.4rem;
			padding-top: 18px;
			line-height: 20px;
		}
	}
	&__btn {
		width: 240px;
		padding: 15px 0 13px 30px;
		font-weight: 700;
		@include sp {
			padding: 10px 0 11px 0;
			&:after {
				right: 30px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list01 {
	background: url(../img/component/bg01.png);
	background-size: cover;
	padding: 60px 0 40px;
	@include sp {
		padding: 50px 15px 35px;
	}
	&__wrapper {
		width: 1122px;
		margin: 0 auto;
		overflow: hidden;
		display: flex;
		@include sp {
			width: 100%;
		}
	}
	&__item {
		width: 373px;
		height: 400px;
		margin: 0 1px 20px 0;
		position: relative;
		&:nth-child(3n) {
			margin-right: 0;
		}
		a:hover {
			opacity: 0.7;
		}
		@include sp {
			width: 33%;
			height: auto;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	&--txt {
		position: absolute;
		top: 29px;
		right: 17px;
		writing-mode: vertical-lr;
		@include sp {
			top: 21px;
			right: 8px;
		}
		span {
			color: #111;
			display: block;
			@include mincyo;
			line-height: 1.5;
			font-weight: normal;
			&:nth-child(1) {
				font-size: 2rem;
				@include sp {
					font-size: 1.2rem;
				}
			}
			&:nth-child(2) {
				font-size: 3.2rem;
				@include sp {
					font-size: 1.9rem;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list02 {
	width: 1120px;
	margin: 0 auto;
	overflow: hidden;
	display: flex;
	@include sp {
		width: 100%;
		padding: 0 15px;
	}
	&__item {
		width: 354px;
		float: left;
		margin-right: 30px;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
		a:hover {
			opacity: 0.7;
		}
		@include sp {
			width: 33.33%;
			margin-right: 15px;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	&--txt {
		position: absolute;
		background: #fff;
		top: 50%;
		left: 50%;
		right: auto;
		transform: translate(-50%, -50%);
		writing-mode: unset;
		padding: 10px 27px;
		border: 3px solid #fff;
		overflow: hidden;
		@include sp {
			writing-mode: vertical-lr;
			padding: 20px 10px;
		}
		span {
			font-size: 1.6rem;
			line-height: 4.8rem;
			color: #2f2933;
			font-weight: bold;
			@include sp {
				font-size: 1.2rem;
				line-height: 1.5rem;
			}
		}
		&:after {
			content: '';
			position: absolute;
			width: 15px;
			height: 30px;
			background: #2e95a3;
			right: -6px;
			bottom: -18px;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	&--txt02 {
		position: absolute;
		top: 3px;
		right: 20px;
		writing-mode: vertical-lr;
		@include sp {
			top: 4px;
			right: 4px;
		}
		span {
			color: #111;
			display: block;
			@include mincyo;
			line-height: 1.5;
			font-weight: normal;
			font-size: 3.2rem;
			@include sp {
				font-size: 1.9rem;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list03 {
	width: 1120px;
	margin: 0 auto;
	overflow: hidden;
	@include sp {
		width: 100%;
		padding: 0 15px;
	}
	&__item {
		width: 545px;
		height: 228px;
		float: left;
		margin: 0 30px 30px 0;
		position: relative;
		a {
			display: block;
			position: relative;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
		//a {
		//	.c-list03__img {
		//		transition: 0.3s ease-in-out;
		//	}
		//}
		//a:hover{
		//	opacity: 1;
		//	.c-list03__img {
		//		opacity: 0.7;
		//	}
		//}
		@include sp {
			width: calc(50% - 8px);
			height: auto;
			margin: 0 15px 15px 0;
		}
	}
	&__img {
		// transition: 0.3s ease-in-out;
		// &:hover {
		// 	opacity: 0.7;
		// }
		img {
			width: 100%;
		}
	}
	&__lable {
		overflow: hidden;
		font-size: 1.6rem;
		font-weight: bold;
		background: #fff;
		color: #000;
		border: 3px solid #fff;
		padding: 22px 27px;
		position: absolute;

		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);

		&:after {
			content: '';
			position: absolute;
			width: 15px;
			height: 30px;
			background: #2e95a3;
			right: -6px;
			bottom: -18px;
			transform: rotate(45deg);
		}
		@include sp {
			width: 85%;
			left: 50%;
			font-size: 1.2rem;
			text-align: center;
			padding: 4px 15px;
			line-height: 16px;
		}
	}
	&__list {
		&Item {
			display: block;
			width: 262px;
			float: left;
			position: relative;
			@include sp {
				width: calc(50% - 5px);
				padding-top: 5px;
			}
			&:last-child {
				margin-left: 21px;
				@include sp {
					margin-left: 10px;
				}
				.c-list03__lable {
					width: 156px;
					@include sp {
						width: auto;
						min-width: 100px;
					}
				}
			}
			.c-list03__lable {
				padding: 21px 22px 21px 28px;
				width: 140px;
				@include sp {
					padding: 12px 10px !important;
					width: auto;
					min-width: 90px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list04 {
	width: 1122px;
	margin: 0 auto;
	overflow: hidden;
	border: 2px solid #29806c;
	@include sp {
		width: calc(100% - 30px);
		margin: 0 15px;
	}
	&__item {
		width: 12.5%;
		float: left;
		text-align: center;
		padding: 28px 0 44px 0;
		&:nth-child(2n) {
			background: #f6f6f6;
		}
		&:last-child {
			.c-list04__number:after {
				background: none;
			}
		}
		@include sp {
			width: 100%;
			height: 60px;
			display: table;
			text-align: left;
			padding: 14px 22px 13px 14px;
		}
	}
	&__number {
		position: relative;
		&:after {
			content: '';
			background: url(../img/component/3arrow.png) no-repeat;
			position: absolute;
			height: 21px;
			width: 40px;
			top: 18px;
			right: -20px;
			z-index: 1;
		}
		@include sp {
			width: 30px;
			height: 30px;
			display: table-cell;
			vertical-align: middle;
			&:after {
				background: url(../img/component/3arrow02.png) no-repeat;
				background-size: contain;
				width: 15px;
				height: 24px;
				top: 34px;
				left: 8px;
			}
		}
	}
	&__txt {
		height: 195px;
		font-size: 2.4rem;
		line-height: 28px;
		text-align: left;
		margin: 0 auto;
		display: inline-block;
		padding: 15px 0 0;
		writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		font-family: 'Hiragino Kaku Gothic ProN';
		@include sp {
			font-size: 1.6rem;
			line-height: 18px;
			height: auto;
			writing-mode: unset;
			-ms-writing-mode: rl-tb;
			display: table-cell;
			vertical-align: middle;
			padding: 0 0 0 14px;
		}
	}
	&__icon {
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include sp {
			width: 10%;
			height: auto;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
		}
	}
	a {
		> * {
			transition: 0.3s ease-in-out;
		}
		&:hover {
			opacity: 1;
			> * {
				opacity: 0.8;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list05 {
	width: 840px;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	$root: &;
	@include sp {
		width: 100%;
	}

	&__item {
		width: calc((100% - 20px) / 2);
		height: 80px;
		background: url('../img/hoken/bgbtn1.png') no-repeat;
		background-size: cover;
		border-radius: 3px;
		border: 2px solid #ffcf8a;
		margin-right: 20px;
		@include sp {
			width: 100%;
			height: auto;
			margin: 0 0 10px;
			img {
				width: 100%;
			}
		}

		&:nth-child(2n) {
			background: url('../img/hoken/bgbtn2.png') no-repeat;
			background-size: cover;
			border: 2px solid #85d3b8;
			margin-right: 0;

			a {
				color: #007a50;
				&:before {
					background: url('../img/hoken/pc.png') no-repeat;
					background-size: cover;
					margin: -2px 14px 0 0;
					@include sp {
						margin: -2px 3px 0 0;
					}
				}
				&:after {
					background: url('../img/hoken/arrow2.png') no-repeat;
					background-size: cover;
				}
			}
		}

		a {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			text-align: center;
			font-size: 2rem;
			line-height: 3.8rem;
			color: #e57700;
			font-weight: bold;
			padding: 21px 40px 21px 28px;
			letter-spacing: 0.5px;
			position: relative;
			@include sp {
				font-size: 1.6rem;
				line-height: 1.9rem;
				padding: 14px 18px 14px 10px;
			}
			&:before {
				content: '';
				width: 40px;
				height: 40px;
				background: url('../img/hoken/heart01.png') no-repeat;
				background-size: cover;
				border-radius: 50%;
				display: inline-block;
				margin: -2px 16px 0 0;
				@include sp {
					width: 30px;
					height: 30px;
					margin: -2px 7px 0 0;
				}
			}
			&:after {
				content: '';
				width: 8px;
				height: 14px;
				background: url('../img/hoken/arrow1.png') no-repeat;
				background-size: cover;
				position: absolute;
				right: 29px;
				top: calc(50% - 8px);
				@include sp {
					width: 6px;
					height: 10px;
					top: calc(50% - 5px);
					right: 9px;
				}
			}
		}
	}

	&--style2 {
		@include sp {
			max-width: 235px;
		}

		#{$root}__item {
			a {
				@include sp {
					font-size: 1.2rem;
					padding: 12px 18px 8px 10px;

					&:before {
						@include sp {
							margin: -4px 10px 0 0;
						}
					}

					&:after {
						@include sp {
							right: 13px;
						}
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list06 {
	width: 840px;
	overflow: hidden;
	@include sp {
		width: 100%;
	}
	&__item {
		width: 266px;
		height: 100px;
		float: left;
		overflow: hidden;
		margin: 0 20px 20px 0;
		border-radius: 5px;
		&:nth-child(3n) {
			margin-right: 0;
		}
		@include sp {
			width: 100%;
			height: auto;
			margin: 0 0 5px 0;
		}
		a {
			display: block;
			position: relative;
			&:before {
				width: 100%;
				height: 100%;
				content: '';
				//background-color: rgba(255,255,255,0.2);
				border: solid 2px #82cdf3;
				border-radius: 5px;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				transition: 0.3s ease-in-out;
			}
			&:hover {
				opacity: 1;
				&:before {
					opacity: 1;
				}
			}
		}
		&--txt {
			color: #46483c;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 22px;
			position: absolute;
			z-index: 2;
			top: 50%;
			transform: translateY(-50%);
			padding-left: 20px;
			@include sp {
				font-size: 1.3rem;
				line-height: 18px;
				padding-left: 15px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list07 {
	width: 840px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	@include sp {
		width: 100%;
	}
	&__item {
		width: 202px;
		height: 80px;
		display: block;
		margin: 0 10px 10px 0;
		position: relative;
		z-index: 1;
		a {
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			position: relative;
			border-radius: 5px;
			@include sp {
				position: unset;
			}
			span {
				display: block;
				width: 100%;
				text-align: center;
				@include sp {
					height: 100%;
				}
			}
			&:before {
				width: 100%;
				height: 100%;
				content: '';
				//background-color: rgba(255,255,255,0.2);
				border: 1px solid #ccc;
				border-radius: 5px;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 1;
				transition: 0.3s ease-in-out;
			}
			&:after {
				width: 100%;
				height: 100%;
				content: '';
				//background-color: rgba(255,255,255,0.2);
				border: solid 2px #82cdf3;
				border-radius: 5px;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				transition: 0.3s ease-in-out;
			}
			&:hover {
				opacity: 1;
				&:before {
					opacity: 0;
				}
				&:after {
					opacity: 1;
				}
			}
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
		@include sp {
			width: calc(33.33% - 4px);
			height: 40px;
			padding: 5px;
			margin: 0 5px 5px 0;
			img {
				width: 100%;
				max-height: 100%;
				height: 100%;
				object-fit: contain;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(4n) {
				margin: 0 5px 5px 0;
			}
			&:nth-child(1) {
				padding: 7px 18px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-list08 {
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: calc((100% - 60px) / 4);
		margin: 0 0 20px 20px;
		@include sp {
			width: 100%;
			margin: 0 0 10px;
		}

		&:nth-child(4n + 1) {
			margin: 0 0 20px;
			@include sp {
				margin: 0 0 10px;
			}
		}

		&:last-child {
			@include sp {
				margin: 0;
			}
		}
	}

	&__txt {
		display: block;
		background: #fff;
		width: 100%;
		height: 100%;
		text-align: center;
		border: 1px solid #dddddd;
		border-radius: 4px;
		box-sizing: border-box;
		font-size: 1.6rem;
		line-height: 1.5;
		color: #253b73;
		font-weight: bold;
		font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic';
		font-feature-settings: 'palt';
		padding: 17px 10px;
		@include sp {
			border-radius: 2px;
			font-size: 1.3rem;
			padding: 14.5px 10px;
		}

		@include hover {
			border-color: #82cdf3;
			color: #82cdf3;
			opacity: 1;
		}
	}
}

/*
------------------------------------------------------------*/
.c-list09 {
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: calc((100% - 40px) / 3);
		margin: 0 0 20px 20px;
		background: #fff;
		border: 1px solid #dddddd;
		border-radius: 4px;
		box-sizing: border-box;
		padding: 34px 39px 24px;
		@include sp {
			width: 100%;
			margin: 0 0 10px;
			padding: 23px 22px 15px;
		}

		&:nth-child(3n + 1) {
			margin: 0 0 20px;
			@include sp {
				margin: 0 0 10px;
			}
		}

		&:last-child {
			@include sp {
				margin: 0;
			}
		}
	}

	&__ttl {
		font-size: 2rem;
		line-height: 1.5;
		color: #46483c;
		font-weight: bold;
		font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic';
		position: relative;
		padding: 0 0 0 24px;
		@include sp {
			font-size: 1.6rem;
			line-height: 1.4;
			padding: 0 0 0 17px;
		}

		&::before {
			content: '';
			width: 14px;
			height: 14px;
			background: url('../img/common/icon-square.png') no-repeat center;
			background-size: cover;
			position: absolute;
			top: 8px;
			left: 0;
			@include sp {
				width: 10px;
				height: 10px;
				top: 5px;
			}
		}
	}

	&__sublist {
		margin: 20px 0 0 5px;
		@include sp {
			margin: 14px 0 0 2px;
		}
	}

	&__subitem {
		&:not(:last-child) {
			margin: 0 0 6px;
			@include sp {
				margin: 0 0 11px;
			}
		}
	}

	&__txt {
		font-size: 1.6rem;
		line-height: 1.75;
		color: #46483c;
		font-weight: 500;
		font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic';
		position: relative;
		padding: 0 0 0 12px;
		@include sp {
			font-size: 1.3rem;
			line-height: 1.2;
			padding: 0 0 0 11px;
		}

		&::before {
			content: '';
			width: 6px;
			height: 6px;
			background: url('../img/common/icon-circle.png') no-repeat center;
			background-size: cover;
			position: absolute;
			top: 5px;
			left: 0;
			@include sp {
				top: 3px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-card03 {
	width: 840px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	@include sp {
		width: 100%;
	}
	&__item {
		width: 266px;
		float: left;
		margin: 0 20px 25px 0;
		&:nth-child(3n) {
			margin-right: 0;
		}
		@include sp {
			width: calc(50% - 4px);
			margin: 0 8px 12px 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
			&:nth-child(2n + 1) {
				margin: 0 8px 12px 0;
			}
		}
		a {
			&:hover {
				opacity: 1;
				.c-card03__img {
					&:before {
						opacity: 1;
					}
				}
				.c-card03__title {
					color: #ff957d;
					text-decoration: underline;
				}
			}
		}
	}
	&__img {
		width: 266px;
		height: 150px;
		overflow: hidden;
		border-radius: 5px;
		position: relative;
		&:before {
			width: 100%;
			height: 100%;
			content: '';
			//background-color: rgba(255,255,255,0.2);
			border: solid 2px #82cdf3;
			border-radius: 5px;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: 0.3s ease-in-out;
		}
		@include sp {
			width: 100%;
			height: auto;
			img {
				width: 100%;
			}
		}
	}
	&__title {
		font-size: 1.8rem;
		font-weight: bold;
		color: #253b73;
		padding-top: 14px;
		transition: 0.3s ease-in-out;
		@include sp {
			font-size: 1.4rem;
			padding-top: 5px;
			line-height: 19px;
		}
	}
}
