@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス 商品一覧
------------------------------------------------------------*/
.p-kurashiService {
	background-color: #ffffff;
}

//商品一覧
.p-kurashiService01 {
	&__content01 {

	}
	&__content02 {
		.c-kurashiPager01 {
			margin-top: 10px;
		}
	}
}

@include sp {
	.p-kurashiService {
		padding: 20px 20px 49px;
	}

	//商品一覧 sp
	.p-kurashiService01 {
		&__content01 {

		}
		&__content02 {
			.c-kurashiPager01 {
				margin-top: 0;
			}
		}
	}
}

/*
------------------------------------------------------------*/
