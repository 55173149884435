@use '../../0_base' as *;

/*------------------------------------------------------------
保険　資料請求フォーム（カート） 確認
------------------------------------------------------------*/
.p-hokenRequestConfirm02 {
    &__content02 {
        @include pc {
            text-align: center;
        }
        &__txt {
            margin-bottom: 10px;
            color: #333;
            font-size: 1.4rem;
            @include pc {
                margin-bottom: 20px;
                font-size: 1.6rem;
            }
        }
    }
}

/*
------------------------------------------------------------*/
