@use '../../0_base' as *;

.page-syukatsu{
	min-width: 1320px;

	@include sp{
		min-width: 320px;
	}

	&.overflow{
		overflow: hidden;
	}
}
.p-syukatsu{
	font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
	overflow-x: hidden;

	@include sp{
		// padding-bottom: 116px;
	}

	.l-container {
		width: 100%;
		max-width: 1320px;
		padding: 0 20px;
		margin: 0 auto;

		@include sp {
			width: 100%;
		}
	}

	.img-shadow{
		box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.1);
	}

	.c-popup{
		background: rgba($color: #324961, $alpha: 0.8);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transition: 0.5s ease-in-out;
		z-index: 999;
		overflow: auto;
		padding: 100px 0 20px;

		@include sp{
			padding: 60px 0;
		}

		&.is-visible{
			opacity: 1;
			visibility: visible;
		}

		&__close{
			position: absolute;
			top: -52px;
			right: 0;
			width: 32px;
			height: 32px;
			cursor: pointer;

			@include sp{
				width: 23px;
				height: 23px;
				top: -33px;
			}

			@include hover{
				.on{
					display: none;
				}

				.off{
					display: block;
				}
			}

			.on{

			}

			.off{
				display: none;
			}
		}

		.l-container{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		&__main{
			position: relative;
			width: 100%;
			max-width: 1000px;
			height: 100%;
		}

		&__wrapper{
			background: #fff;
			padding: 60px 100px;
			width: 1000px;
			margin: 0 auto;
			position: relative;
			height: 100%;
			overflow: auto;
			box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);

			@include sp{
				width: 100%;
				padding: 20px;
			}

			@include max-w(374px){
				padding: 10px;
			}
		}

		&__text01{
			font-size: 1.8rem;
			line-height: 24px;
			letter-spacing: 0.1em;
			font-weight: bold;
			color: #fff;
			background: #4C93A1;
			text-align: center;
			padding: 8px 0;

			@include sp{
				font-size: 1.4rem;
				line-height: 20px;
				padding: 7px 0;
			}

			@include max-w(374px){
				font-size: calc((14/375)*100vw);
				line-height: calc((20/375)*100vw);
			}
		}

		&__inner{
			display: flex;
			align-items: center;
			margin-top: 15px;
			.c-popup__text02{
				display: flex;
				@include sp{
					display: none;
				}
			}
		}

		&__content{
			width: calc(100% - 107px);
			padding-left: 15px;

			@include sp{
				width: 100%;
				padding-left: 0;
			}
		}

		&__img{
			text-align: center;
		}

		&__text02{
			width: 107px;
			min-width: 107px;
			height: 107px;
			border-radius: 50%;
			border: 1px solid #4C93A1;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2.2rem;
			line-height: 30px;
			font-weight: 900;
			@include mincyo();
			color: #4C93A1;
			display: none;

			@include sp{
				width: 100%;
				height: auto;
				font-size: 1.2rem;
				line-height: 1;
				padding: 4px 0 2px;
				border-radius: 29px;
				margin-top: 15px;
				display: flex;
			}
		}

		&__text03{
			font-size: 3.8rem;
			line-height: 42px;
			letter-spacing: 0.075em;
			font-weight: 900;
			@include mincyo();
			color: #324961;
			position: relative;

			@include sp{
				font-size: 1.9rem;
				line-height: 1;
				text-align: center;
				max-width: 295px;
				margin: 0 auto;
			}

			@include max-w(374px){
				font-size: calc((16/375)*100vw);
			}

			&dot{
				position: relative;
				&::before{
					content: "";
					position: absolute;
					top: -10px;
					left: 50%;
					transform: translateX(-50%);
					width: 6px;
					height: 6px;
					background: #E04A5E;
					border-radius: 50%;

					@include sp{
						width: 3px;
						height: 3px;
						top: -5px;
					}
				}
			}
		}

		&__text04{
			color: #4C93A1;
			@include mincyo();
			font-size: 5.8rem;
			font-weight: 500;
			line-height: 1;
			display: flex;
			align-items: flex-end;

			@include sp{
				text-align: center;
				justify-content: center;
				font-size: 3.5rem;
			}

			@include max-w(374px){
				font-size: calc((33/375)*100vw);
			}

			&-1{
				font-size: 4.6rem;
				position: relative;
				white-space: nowrap;

				span{
					font-size: 4.2rem;

					@include sp{
						font-size: 2.3rem;
					}

					@include max-w(374px){
						font-size: calc((21/375)*100vw);
					}
				}

				@include sp{
					font-size: 2.7rem;
				}

				@include max-w(374px){
					font-size: calc((25/375)*100vw);
				}

				&::after{
					content: "※";
					color: #4C93A1;
					font-size: 1.4rem;
					line-height: 1;
					font-weight: 900;
					@include mincyo;
					position: absolute;
					top: 0;
					right: 0;
	
					@include sp{
						font-size: 1rem;
						right: -5px;
						top: -10px;
					}

					@include max-w(374px){
						font-size: calc((10/375)*100vw);
					}
				}
			}
		}

		&__text05{
			font-size: 1.4rem;
			line-height: 20px;
			font-weight: 500;
			text-align: right;
			margin-top: 15px;
			color: #2F2933;

			@include sp{
				font-size: 1.2rem;
				line-height: 18px;
				margin-top: 8px;
			}

			span{
				display: block;
				@include sp{
					&:first-child{
						color: #324961;
					}

					&:nth-child(2){
						margin: 8px 0 6px;
					}
				}
			}
		}

		&__btn{
			margin-top: 25px;

			@include sp{
				margin-top: 8px;
			}

			a{
				display: flex;
				align-items: center;
				justify-content: space-around;
				border: 3px solid #3C76D2;
				border-radius: 50px;
				font-size: 2.4rem;
				line-height: 36px;
				color: #3C76D2;
				text-align: center;
				@include mincyo;
				position: relative;
				padding: 19px 40px;
				font-weight: 600;
				max-width: 480px;
				margin: 0 auto;

				@include sp{
					border-width: 2px;
					font-size: 1.6rem;
					line-height: 24px;
					letter-spacing: 0.05em;
					padding: 9px 20px;
				}

				@include max-w(374px){
					font-size: calc((16/375)*100vw);
					line-height: calc((24/375)*100vw);
					padding: 10px 20px;
				}

				span{
					margin: 0 15px;

					@include sp{
						flex: 1;
						margin: 0 10px;
					}
				}

				@include hover{
					opacity: 1;
					background: #3C76D2;
					color: #fff;

					&::before{
						background: url(/img/sosai/lp/2022_syukatsu/icon_tab_W.svg) no-repeat center;
					}

					&::after{
						background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
					}
				}

				&::before{
					content: "";
					position: relative;
					display: inline-block;
					width: 20px;
					height: 26px;
					background: url(/img/sosai/lp/2022_syukatsu/icon_tab.svg) no-repeat center;
					background-size: 20px 26px !important;
					transition: 0.3s ease-in-out;
				}

				&::after{
					content: "";
					position: relative;
					display: inline-block;
					width: 8px;
					height: 18px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					background-size: 8px 18px !important;
					transition: 0.3s ease-in-out;
				}
			}
		}

		&__list{
			background: #F9F9F9;
			padding: 30px;
			margin-top: 20px;

			@include sp{
				margin-top: 10px;
				padding: 15px;
			}

			&cnt{
				display: flex;
				justify-content: center;
			}

			&item{
				width: 190px;
				text-align: center;
				&:not(:last-child){
					margin-right: 30px;

					@include sp{
						margin-right: 10px;
					}
				}
			}

			&title{
				font-size: 1.4rem;
				text-align: center;
				@include mincyo;
				font-weight: 900;
				color: #4C93A1;
				border: 1px solid #4C93A1;
				padding: 3px 0;
				margin-top: 20px;

				@include sp{
					font-size: 1.2rem;
					line-height: 15px;
					min-height: 42px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				@include max-w(374px){
					font-size: calc((12/375)*100vw);
					line-height: calc((15/375)*100vw);
					min-height: calc((42/375)*100vw);
				}
			}
		}

	
	}

	.c-logo{
		padding: 30px 40px 20px;
		position: absolute;
		top: 0;
		left: 0;

		@include sp{
			padding: 10px 20px;
			text-align: center;
			width: 100%;
			img{
				height: 17px;
			}
		}
	}

	.c-mv{
		&__wrapper{
				background: url(/img/sosai/lp/2022_syukatsu/mv.jpg) no-repeat top right 45%;
				padding: 80px 0 124px;
				background-size: cover;

				@include sp{
					padding: 34px 0 0;
					position: relative;
					background: none;

					&::after{
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 50%;
						background: url(/img/sosai/lp/2022_syukatsu/mv-sp.jpg) no-repeat center top;
						background-size: cover;
						z-index: -1;
					}
				}
		}

		&__content{
			width: 50%;

			@include sp{
				width: 100%;
			}
		}

		&__text01{
			font-size: 2rem;
			line-height: 28px;
			letter-spacing: 0.15em;
			font-weight: 900;
			@include mincyo();
			background: rgba($color: #FFFFFF, $alpha: 0.6);
			color: #4C93A1;
			border: 1px solid #4C93A1;
			padding: 5px 0;
			text-align: center;
			margin-bottom: 20px;

			@include sp{
				line-height: 0;
				margin-bottom: 56%;
				img{
					height: 10px;
				}
			}
		}

		&__text02{
			font-size: 2.8rem;
			font-weight: 600;
			letter-spacing: 0.25em;
			line-height: 41px;
			@include mincyo();
			color: #2F2933;
			position: relative;
			z-index: 1;
		}

		&__img{
			margin: -160px -100% -120px 0;

			@include sp{
				margin: 10px 0 30px;
			}
		}

		&__circle{
			position: absolute;
			top: -3px;
			left: 20px;
			display: flex;
			align-items: center;

			@include sp{
				left: 10px;
			}

			span{
				display: block;
				width: 3px;
				height: 3px;
				background: #E04A5E;
				border-radius: 50%;
				&:not(:last-child){
					margin-right: 19px;

					@include max-w(1320px){
						margin-right: 11px;
					}

					@include max-w(374px){
						margin-right: 7px;
					}
				}
			}
		}

		&__text03{
			width: 224px;
			height: 96px;
			text-align: center;
			position: relative;
			margin-left: 210px;
			margin-bottom: 30px;

			@include sp{
				width: 135px;
				height: 65px;
				margin-left: 95px;
				margin-bottom: 10px
			}

			@include max-w(374px){
				width: 100px;
				height: 60px;
				margin-left: 80px;
			}

			&::after{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 42px;
				background: url(/img/sosai/lp/2022_syukatsu/mv_line.svg) no-repeat center;

				@include sp{
					height: 25px;
					background-size: 100% 25px;
				}
			}

			&-1{
				font-size: 1.6rem;
				line-height: 24px;
				letter-spacing: 0.1em;
				font-weight: bold;
				color: #324961;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				margin-bottom: 6px;

				@include sp{
					font-size: 0.95rem;
					line-height: 15px;
				}

				&::before,
				&::after{
					content: "";
					position: relative;
					display: block;
					top: 0;
					width: 19px;
					height: 17px;
					background-size: 19px 17px;

					@include sp{
						width: 14px;
						height: 12px;
						background-size: 14px 12px;
					}
				}

				&::before{
					background: url(/img/sosai/lp/2022_syukatsu/icon03.svg) no-repeat center;
					margin-right: 20px;

					@include sp{
						margin-right: 8px;
					}
				}

				&::after{
					background: url(/img/sosai/lp/2022_syukatsu/icon04.svg) no-repeat center;
					margin-left: 20px;

					@include sp{
						margin-left: 8px;
					}
				}
			}

			&-2{
				color: #E04A5E;
				font-size: 2.2rem;
				line-height: 28px;
				letter-spacing: 0.025em;
				position: relative;
				font-weight: 900;
				@include mincyo();

				@include sp{
					font-size: 1.4rem;
					line-height: 16px;
				}

				@include max-w(374px){
					font-size: 1rem;
				}
			}

			&-3{
				color: #324961;
			}
		}

		&__text04{
			font-size: 1.4rem;
			line-height: 19px;
			letter-spacing: 0.05em;
			font-weight: 500;
			color: #324961;
			text-align: right;
			margin-top: 20px;
			padding: 0 40px;
			text-shadow:
            2px 2px 2px #fff, -2px -2px 2px #fff,
           -2px 2px 2px #fff,  2px -2px 2px #fff,
            2px 0px 2px #fff, -2px -0px 2px #fff,
            0px 2px 2px #fff,  0px -2px 2px #fff;

			@include sp{
				font-size: 1.1rem;
				line-height: 16px;
				margin-top: 10px;
				padding: 0;
				text-shadow: none;
			}
		}

		&__box01{
			border: 3px solid #3C76D2;
			border-radius: 90px;
			background: #fff;
			display: flex;
			align-items: center;
			position: relative;
			padding: 25px 117px 25px 253px;
			cursor: pointer;
			transition: 0.3s ease-in-out;

			@include sp{
				padding: 15px;
				display: block;
				border-radius: 12px;
			}

			@include hover{
				background: #3C76D2;
				opacity: 1;

				.c-mv__box01Txt01,
				.c-mv__box01Txt02,
				.c-mv__box01Txt04,
				.c-mv__box01Txt05,
				.c-mv__box01Txt06,
				.c-mv__box01Txt07{
					color: #fff;

					&::after{
						color: #fff;
					}
				}

				.c-mv__box01Txt03{
					background: #fff;
				}

				&::after{
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
				}
			}

			&::after{
					content: "";
					position: absolute;
					width: 11px;
					height: 24px;
					background-size: 11px 24px !important;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					top: 50%;
					transform: translateY(-50%);
					right: 60px;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 5px;
						height: 12px;
						background-size: 5px 12px !important;
						right: 20px;
						top: 23%;
					}

					@include max-w(374px){
						top: 32px;
						right: 12px;
						transform: none;
					}
				}

			&Img01{
				width: 193px;
				position: absolute;
				bottom: 10px;
				left: -5px;

				@include sp{
					width: 78px;
					bottom: inherit;
					top: -5%;
					transform: rotate(-10deg);
					left: -90px;
				}
			}

			&Txt01{
				font-size: 2.4rem;
				line-height: 32px;
				letter-spacing: 0.03em;
				font-weight: 600;
				@include mincyo();
				color: #2F2933;
				margin-bottom: 5px;
				transition: 0.3s ease-in-out;

				@include sp{
					font-size: 1.4rem;
					line-height: 23px;
					text-align: center;
					margin-bottom: 0;
				}

				@include max-w(374px){
					font-size: 1.2rem;
				}
			}

			&Txt02{
				font-size: 4rem;
				letter-spacing: 0.1em;
				line-height: 53px;
				font-weight: 900;
				@include mincyo();
				color: #3C76D2;
				transition: 0.3s ease-in-out;

				@include sp{
					font-size: 2.3rem;
					line-height: 31px;
					text-align: center;
				}

				@include max-w(374px){
					font-size: 2rem;
				}
			}

			&Content02{
				display: flex;
				align-items: center;
				margin-left: 45px;

				@include sp{
					margin-left: 0;
					margin-top: 8px;
					justify-content: center;
				}
			}

			&Txt03{
				font-size: 1.4rem;
				line-height: 20px;
				font-weight: bold;
				color: #4C93A1;
				text-align: center;
				width: 110px;
				height: 110px;
				border-radius: 50%;
				border: 1px solid #4C93A1;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 15px;
				transition: 0.3s ease-in-out;

				@include sp{
					width: 70px;
					height: 70px;
					min-width: 70px;
					font-size: 1rem;
					line-height: 14px;
					margin-right: 8px;
				}

				@include max-w(374px){
					font-size: 0.6rem;
					line-height: normal;
					width: 50px;
					height: 50px;
					min-width: 50px;
				}
			}

			&Txt04{
				font-size: 1.6rem;
				line-height: 20px;
				letter-spacing: 0.02em;
				font-weight: 500;
				color: #2F2933;
				transition: 0.3s ease-in-out;

				@include sp{
					font-size: 1.2rem;
					line-height: 16px;
					margin-bottom: -8px;
				}
			}

			&Content04{
				display: flex;
				align-items: center;
			}

			&Img02{
				width: 126px;
				margin-left: 15px;

				@include sp{
					width: 96px;
					margin-left: 8px;
					position: relative;
					top: 6px;
				}
				@include max-w(374px){
					width: 78px;
				}
			}

			&Txt05{
				font-size: 2.4rem;
				@include mincyo();
				letter-spacing: 0.05em;
				line-height: 31px;
				color: #4C93A1;
				position: relative;
				transition: 0.3s ease-in-out;

				@include sp{
					font-size: 1.6rem;
					line-height: 22px;
				}

				@include max-w(374px){
					font-size: 1.4rem;
				}

				&::after{
					content: "※";
					position: absolute;
					bottom: 15px;
					right: -5px;
					font-size: 1rem;
					@include mincyo();
					font-weight: 600;
					color: #2F2933;
					transition: 0.3s ease-in-out;

					@include sp{
						bottom: 10px;
						font-size: 0.7rem;
					}
				}
			}

			&Txt06{
				font-size: 3.8rem;
				letter-spacing: 0.05em;
				color: #4C93A1;
				font-weight: 600;
				@include mincyo();
				position: relative;
				top: 3px;
				transition: 0.3s ease-in-out;

				@include sp{
					font-size: 3rem;
					line-height: 40px;
				}

				@include max-w(374px){
					font-size: 2.5rem;
				}
			}

			&Txt07{
				font-size: 1.8rem;
				line-height: 31px;
				@include mincyo();
				font-weight: 600;
				color: #2F2933;
				position: relative;
				top: 2px;
				transition: 0.3s ease-in-out;

				@include sp{
					font-size: 1.2rem;
					line-height: 22px;
					top: 1px;
				}

				@include max-w(374px){
					font-size: 1rem;
				}
			}
		}

		&__bottom{
			background: #fff url(/img/sosai/lp/2022_syukatsu/bg05.png) no-repeat center;
			background-size: cover !important;
			padding: 80px 0;

			@include sp{
				margin-top: 30px;
				padding: 30px 0;
				background: url(/img/sosai/lp/2022_syukatsu/bg05-sp.png) no-repeat center;
			}
		}

		&__box02{
			border: 1px solid #4C93A1;
			display: flex;
			margin-bottom: 60px;

			@include sp{
				margin-top: 0;
				display: block;
				margin-bottom: 20px;
			}

			&Title{
				width: 266px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 2.8rem;
				line-height: 37px;
				font-weight: 600;
				@include mincyo();
				text-align: center;
				background: url(/img/sosai/lp/2022_syukatsu/bg06.png) no-repeat center;
				background-size: cover;
				color: #fff;

				@include sp{
					width: 100%;
					font-size: 1.9rem;
					line-height: 24px;
					padding: 8px 0;
				}
			}

			&Content{
				width: calc(100% - 266px);
				padding: 40px;
				font-size: 1.8rem;
				font-weight: 500;
				line-height: 36px;
				color: #2F2933;
				background: #fff;

				@include sp{
					width: 100%;
					padding: 20px;
					font-size: 1.3rem;
					line-height: 26px;
					letter-spacing: 0.05em;
				}
			}

			&Cl{
				font-weight: bold;
				color: #4C93A1;
			}
		}

		&__list{
			display: flex;

			@include sp{
				display: block;
			}

			&.js-scroll{
				position: fixed;
				left: 0;
				top: -121px;
				transition: 0.5s ease-in-out;
				width: 100%;
				background: #fff;
				z-index: 99;
				padding: 20px 0;
				justify-content: center;
				box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.1);

				@include sp{
					display: none;
				}

				.c-mv__listItem{
						border: none;
						box-shadow: none;
						font-size: 1.4rem;
						width: calc((100% - 250px) / 3);
						max-width: 353px;
						line-height: normal;
						padding: 10px 20px;
						border-radius: 0;
						position: relative;
						br{
							display: none;
						}

						&::before{
							content: "";
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							height: 50px;
							width: 1px;
							border-left: 1px solid #D4D4D4;
						}
					}
					.c-mv__listItem1{
							width: 260px;
							.c-mv__listLink{
								padding: 10px 15px;

								&::after{
									right: 10px;
								}
							}

								.c-mv__listText01{
									display: none;
								}

								.c-mv__listText02{
									.scroll-only {
										display: none;
									    transition: 0.5s ease-in-out;
									}

									&-2{
										font-size: 2.8rem;
									}
								}
						}

				&.fixed{
					position: fixed;
					top: 0;

					.c-mv__listText02{
						.scroll-only {
						    transition: 0.5s ease-in-out;
							display: inline-block;
						}
					}
				}
			}



			a{
				width: calc(25% - 10px);

				@include sp{
					width: 100%;
				}

				&:not(:last-child){
					margin-right: 10px;

					@include sp{
						margin-right: 0;
						margin-bottom: 5px;
					}
				}
			}
			&Item{
				border: 2px solid #D4D4D4;
				border-radius: 8px;
				box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);
				padding: 20px 20px 32px;
				font-size: 2.2rem;
				line-height: 32px;
				@include mincyo();
				font-weight: 600;
				text-align: center;
				color: #324961;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #fff;

				@include sp{
					font-size: 1.4rem;
					line-height: 16px;
					border-width: 1px;
					padding: 16px 40px;
					border-radius: 4px;
					br{
						display: none;
					}
				}

				&:hover{
					opacity: 1;
					border-color: #4C93A1;
					color: #4C93A1;
				}

				&::after{
					content: "";
					position: absolute;
					width: 15px;
					height: 8px;
					background-size: 15px 8px !important;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow02.svg) no-repeat center;
					left: 50%;
					transform: translateX(-50%);
					bottom: 15px;
					transition: 0.3s ease-in-out;

					@include sp{
						left: inherit;
						bottom: inherit;
						top: 50%;
						right: 15px;
						transform: translateY(-50%);
					}
				}
			}

			&Item1{
				width: calc(25% - 10px);

				@include sp{
					position: fixed;
					width: 100%;
					bottom: -116px;
					left: 0;
					z-index: 99;
					padding: 20px 40px;
					background: rgba($color: #324961, $alpha: 0.5);
					transition: 0.5s ease-in-out;;
				}

				&.fixed{
					@include sp{
						bottom: 0;
					}
				}

				@include max-w(374px){
					padding: 10px;
				}

				.c-mv__listLink{
					width: 100%;
					border: 3px solid #3C76D2;
					border-radius: 8px;
					box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);
					padding: 22px 20px;
					display: flex;
					align-items: center;
					position: relative;
					background: #fff;
					cursor: pointer;
					transition: 0.3s ease-in-out;

				@include sp{
					padding: 15px 10px;
					justify-content: center;
				}

				@include max-w(374px){
					padding: 10px;
				}

				@include hover{
					opacity: 1;
					background: #3C76D2;

					.c-mv__listText01{
						border-color: #fff;
						color: #fff;
					}

					.c-mv__listText02{
						&-1{
							color: #fff;
						}
						&-2{
							color: #fff;
						}
					}

					&::after{
						background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
					}
				}

				&::after{
					content: "";
					position: absolute;
					width: 11px;
					height: 24px;
					background-size: 11px 24px !important;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					top: 50%;
					transform: translateY(-50%);
					right: 20px;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 5px;
						height: 12px;
						background-size: 5px 12px !important;
						right: 10px;
					}
				}
			}
				}

			&Text01{
				font-size: 1.6rem;
				line-height: 21px;
				font-weight: bold;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #3C76D2;
				border-radius: 50%;
				width: 70px;
				height: 70px;
				margin-right: 15px;
				letter-spacing: 0.1em;
				color: #3C76D2;
				transition: 0.3s ease-in-out;

				@include sp{
					width: 40px;
					height: 40px;
					min-width: 40px;
					font-size: 1.2rem;
					margin-right: 5px;
				}
			}

			&Text02{
				text-align: center;

				@include sp{
					display: flex;
					align-items: center;
				}

				&-1{
					font-size: 1.4rem;
					line-height: 19px;
					font-weight: bold;
					letter-spacing: 0.05em;
					color: #3C76D2;
					transition: 0.3s ease-in-out;

					@include sp{
						font-size: 1.2rem;
						line-height: 15px;
					}
				}

				&-2{
					font-size: 3.4rem;
					line-height: 36px;
					font-weight: 600;
					letter-spacing: 0.2em;
					@include mincyo();
					color: #3C76D2;
					transition: 0.3s ease-in-out;

					@include sp{
						font-size: 2.6rem;
						margin-left: 5px;
					}
				}
			}
		}
	}


	.c-title01 {
		font-size: 4.6rem;
		line-height: 52px;
		letter-spacing: 0.1em;
		@include mincyo();
		text-align: center;
		position: relative;
		color: #324961;
		padding-bottom: 34px;
		font-weight: 600;
		margin-bottom: 60px;

		@include sp {
			font-size: 2.7rem;
			line-height: 38px;
			padding-bottom: 20px;
			margin-bottom: 30px;
		}

		.cl-01 {
			color: #4C93A1;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 38px;
			height: 10px;
			background: url(/img/sosai/lp/2022_syukatsu/icon01.svg) no-repeat center;
			background-size: 38px 10px;

			@include sp {
				width: 34px;
				height: 8px;
				background-size: 34px 8px;
			}
		}
	}

	.c-title02{
		text-align: center;
			&__text01{
				font-size: 2.4rem;
				letter-spacing: 0.05em;
				line-height: 32px;
				font-weight: bold;
				background: #4C93A1;
				color: #fff;
				width: 470px;
				text-align: center;
				padding: 10px 0 8px;
				margin: 0 auto;
				display: block;

				@include sp{
					width: calc(100% - 20px);
					font-size: 1.6rem;
					line-height: 21px;
					padding: 4px 0 5px;
				}
			}

			&__img{
				margin: 40px 0 55px;
				display: block;
				@include sp{
					max-width: 202px;
					margin: 20px auto 16px;
				}
				img{
					margin: 0 auto;
				}
			}
			&__text03{
				margin: 30px 0;
				display: block;

				@include sp{
					margin: 12px 0;
				}

				img{
					margin: 0 auto;
				}
			}

			&__text02{
				display: block;
				font-size: 2.8rem;
				letter-spacing: 0.15em;
				line-height: 37px;
				font-weight: 600;
				color: #2F2933;
				@include mincyo();

				@include sp{
					font-size: 2rem;
					line-height: 30px;
				}
			}
	}

	.c-text001{
		font-size: 1.4rem;
		line-height: 20px;
		font-weight: 500;
		margin-top: 28px;
		text-align: right;

		@include sp{
			font-size: 1.1rem;
			line-height: 17px;
			margin-top: 15px;
		}
	}

	.c-text002{
		font-size: 2rem;
		line-height: 38px;
		letter-spacing: 0.15em;
		font-weight: 600;
		@include mincyo();
		color: #2F2933;
		text-align: center;

		@include sp{
			font-size: 1.5rem;
			line-height: 26px;
			text-align: left;
		}
	}

	.c-imgtext01 {
		display: flex;
		align-items: center;

		@include sp {
			flex-wrap: wrap;
		}

		&__content {
			width: 50%;
			max-width: 640px;
			padding: 60px;
			background: #fff;
			color: #2F2933;
			position: relative;
			z-index: 1;
			font-size: 2rem;
			line-height: 40px;
			letter-spacing: 0.1em;
			font-weight: 400;
			box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);

			@include sp {
				width: calc(100% - 40px);
				order: 2;
				padding: 20px;
				font-size: 1.5rem;
				line-height: 26px;
			}
		}

		&__img {
			margin: 0 -260px 0 -80px;
			width: calc(50% + 340px);

			img {
				width: 100%;
			}

			@include sp {
				width: calc(100% + 10px);
				margin: 0 -10px -50px 20px;
				order: 1;
			}
		}

		&.is-style01 {
			.c-imgtext01 {
				&__img {
					order: 1;
					margin: 0 -100px 0 -240px;

					@include sp{
						margin: 0 20px -50px -10px;
					}
				}

				&__content {
					order: 2;
					margin-left: auto;
				}
			}
		}
	}

	.c-imgtext02{
		display: flex;

		@include sp{
			display: block;
		}

		&__img{
			@include sp{
				img{
					width: 100%;
				}
			}
		}

		&__content{
			width: calc(100% - 380px);
			padding-right: 40px;
			font-size: 1.8rem;
			line-height: 36px;
			letter-spacing: 0.05em;
			font-weight: 500;
			color: #2F2933;

			@include sp{
				width: 100%;
				padding-right: 0;
				font-size: 1.3rem;
				line-height: 22px;
				letter-spacing: 0.1em;
				margin-bottom: 20px;
			}
		}
	}

	.c-block001{
		display: flex;

		@include sp{
			display: block;
		}

		&.pc-only {
			display: block;

			@include sp {
				display: none;
			}
		}

		&.sp-only {
			display: none;

			@include sp {
				display: block;
			}
		}

		&__item{
			width: calc(50% - 30px);

			@include sp{
				width: 100%;
			}

			&:not(:last-child){
				margin-right: 60px;

				@include sp{
					margin-right: 0;
					margin-bottom: 30px;
				}
			}
		}

		&__title{
			border: 3px solid #4C93A1;
			font-size: 2.4rem;
			line-height: 32px;
			letter-spacing: 0.1em;
			color: #4C93A1;
			font-weight: 900;
			text-align: center;
			padding: 13px 0 9px;
			margin-bottom: 20px;

			@include sp{
				font-size: 1.6rem;
				line-height: 23px;
				letter-spacing: 0.05em;
				padding: 3px 12px;
				margin-bottom: 10px;
			}
		}
	}

	.c-box01 {
		background: #fff;
		padding: 20px;

		@include sp{
			padding: 10px;
		}

		& + .c-box01{
			margin-top: 40px;
			@include sp{
				margin-top: 20px;
			}
		}

		&__wrapper {
			padding: 40px 60px 60px;

			@include sp{
				padding: 20px 15px 20px;
			}
		}

		&__title {
			background: #4C93A1;
			font-size: 3.2rem;
			line-height: 43px;
			padding: 20px 0 17px;
			color: #fff;
			letter-spacing: 0.05em;
			font-weight: 600;
			@include mincyo();
			text-align: center;

			@include sp{
				font-size: 2rem;
				line-height: 28px;
				padding: 12px 20px;
			}
		}

		&__text {
			font-size: 1.8rem;
			line-height: 36px;
			letter-spacing: 0.05em;
			color: #2F2933;
			font-weight: 500;
			margin: 40px 0 34px;
			padding: 0 60px;

			@include sp{
				padding: 0 15px;
				font-size: 1.3rem;
				line-height: 22px;
				letter-spacing: 0.1em;
				margin: 15px 0;
			}

			& + .c-box01__wrapper{
				padding-top: 0;
			}
		}
	}

	.c-box02{
		box-shadow: 10px 10px 10px rgba($color: #000000, $alpha: 0.1);

		&__top{
			padding: 57px 50px 24px;
			background: url(/img/sosai/lp/2022_syukatsu/bg02.jpg) no-repeat center;
			color: #fff;
			display: flex;
			justify-content: space-between;
			background-size: cover;

			@include sp{
				padding: 71px 20px 12px;
				justify-content: flex-start;
			}
		}

		&__bottom{
			padding: 20px 50px 40px;
			background: #fff;

			@include sp{
				padding: 12px 20px 20px;
			}
		}

		&__content{
			display: flex;
			justify-content: space-between;
		}

		&__img{
			margin: -87px 0 -250px -10px;
			width: 282px;

			@include sp{
				margin: 0;
				width: 67px;
			}

		}

		&__bltext{
			display: flex;
			justify-content: flex-end;
			margin-left: 26.7%;

			@include sp{
				margin-left: 0;
				position: relative;
				padding-top: 65px;
				justify-content: center;
			}

			&Img{
				padding: 14px 0 0;
				margin-left: 10px;

				@include sp{
					width: 140px;
					margin-left: 0;
					padding: 0;
				}

					@include max-w(374px){
						width: 100px;
				}
			}

			&Content{
				min-width: 542px;

				@include sp{
					min-width: inherit;
					width: calc(100% - 140px);
					padding-right: 9px;
					max-width: 155px;
				}

				@include max-w(374px){
						width: calc(100% - 100px);
				}
			}
		}

		&__title{
			position: relative;
			display: flex;
			align-items: center;
			margin-left: 13px;

			@include sp{
				width: calc(100% - 67px);
				padding-left: 10px;
				margin-left: 0;
			}

			&Content{
				width: calc(100% - 120px);
				padding-left: 20px;

				@include sp{
					width: 100%;
					padding-left: 0;
				}
			}
		}

		&__text01{
			position: absolute;
			top: -87px;
			left: 120px;
			background: #4C93A1;
			border-radius: 7px;
			color: #fff;
			padding: 15px 0;
			text-align: center;
			font-size: 2.4rem;
			line-height: 32px;
			letter-spacing: 0.05em;
			font-weight: 600;
			@include mincyo();
			width: 620px;

			@include sp{
				top: -96px;
				width: calc(100% + 67px);
				left: -67px;
				font-size: 1.3rem;
				line-height: 20px;
				font-weight: bold;
				padding: 6px 20px;
				border-radius: 3.5px;
			}

			&::after{
					content: "";
					position: absolute;
					bottom: -15px;
					left: 50%;
					transform: translateX(-50%);
					width: 0;
					height: 0;
					border-left: 12px solid transparent;
					border-right: 12px solid transparent;
					border-top: 20px solid #4C93A1;

					@include sp{
						bottom: -10px;
						border-left: 7.5px solid transparent;
						border-right: 7.5px solid transparent;
						border-top: 12.5px solid #4C93A1;
					}
				}
		}

		&__text02{
			width: 120px;
			height: 120px;
			border: 1px solid #fff;
			border-radius: 50%;
			display: flex;
			align-items: center;
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 28px;
			text-align: center;
			justify-content: center;

			@include sp{
				position: absolute;
				top: -27px;
				left: -67px;
				width: calc(100% + 67px);
				height: auto;
				border-radius: 29px;
				font-size: 1.2rem ;
				letter-spacing: 0;
				line-height: 14px;
				height: 20px;
			}

			&-2{
				font-size: 3rem;
				letter-spacing: 0.1em;
				display: block;

				@include sp{
					font-size: 1.2rem;
					line-height: 14px;
					display: inline;
					letter-spacing: 0;
				}
			}
		}

		&__text03{
			font-size: 2.4rem;
			line-height: 38px;
			letter-spacing: 0.05em;
			font-weight: 600;
			@include mincyo();
			margin-bottom: 15px;

			@include sp{
				font-size: 1.4rem;
				line-height: 20px;
			}
		}

		&__text04{
			font-size: 4.6rem;
			line-height: 51px;
			letter-spacing: 0.05em;
			font-weight: 600;
			@include mincyo();
			position: relative;

			@include sp{
				font-size: 2.2rem;
				line-height: 24px;
			}
		}

		&__text05{
			font-size: 1.6rem;
			line-height: 24px;
			letter-spacing: 0.1em;
			font-weight: bold;
			color: #324961;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin-bottom: 10px;

			@include sp{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				font-size: 1.2rem;
				line-height: 16px;
				margin-bottom: 0;
			}

			&::before,
			&::after{
				content: "";
				position: relative;
				display: block;
				top: 0;
				width: 19px;
				height: 17px;
				background-size: 19px 17px;

				@include sp{
					width: 14px;
					height: 12px;
					background-size: 14px 12px;
				}
			}

			&::before{
				background: url(/img/sosai/lp/2022_syukatsu/icon03.svg) no-repeat center;
				margin-right: 20px;

				@include sp{
					margin-right: 12px;
				}
			}

			&::after{
				background: url(/img/sosai/lp/2022_syukatsu/icon04.svg) no-repeat center;
				margin-left: 20px;

				@include sp{
					margin-left: 12px;
				}
			}
		}

		&__text06{
			font-size: 1.8rem;
			line-height: 24px;
			letter-spacing: 0.2em;
			font-weight: bold;
			color: #fff;
			background: #3C76D2;
			padding: 5px 20px 3px;
			text-align: center;

			@include sp{
				position: absolute;
				top: 24px;
				left: 0;
				width: 100%;
				font-size: 1.3rem;
				line-height: 18px;
				padding: 9px 10px 8px;
				letter-spacing: 0.05em;
			}

				@include max-w(374px){
						font-size: 1.2rem;
						letter-spacing: 0;
				}
		}

		&__text07{
			font-size: 3rem;
			line-height: 40px;
			@include mincyo();
			font-weight: 600;
			color: #324961;
			text-align: center;

			@include sp{
				text-align: left;
				font-size: 2.3rem;
				line-height: 31px;
			}

			@include max-w(374px){
				font-size: 2rem;
			}

			&-1,
			&-2{
				color: #4C93A1;
				display: inline-block;

				@include sp{
					display: inline;
				}
			}

			&-1{
				font-size: 5.4rem;
				line-height: 72px;
				position: relative;
				top: 5px;

				@include sp{
					font-size: 3.9rem;
					letter-spacing: 0.04em;
					line-height: 1;
					top: 3px;
				}

				span {
					@include sp{
						font-size: 2.3rem;
					}
				}

				@include max-w(374px){
					font-size: 3.2rem;
				}
			}

			&-3{
				display: inline-block;

				@include sp{
					display: inline;
				}
			}
		}

		&__text08{
			font-size: 1.5rem;
			line-height: 20px;
			letter-spacing: 0.05em;
			font-weight: 500;
			color: #2F2933;

			@include sp{
				font-size: 1.2rem;
				line-height: 18px;
				width: calc(100% + 140px);
				text-align: center;
				margin-top: 10px;
			}

				@include max-w(374px){
						width: calc(100% + 100px);
						br{
							display: none;
						}
				}
		}

		&__circle{
			position: absolute;
			top: -13px;
			left: 20px;
			display: flex;
			align-items: center;

			@include sp{
				display: none;
			}

			span{
				display: block;
				width: 6px;
				height: 6px;
				background: #fff;
				border-radius: 50%;
				&:not(:last-child){
					margin-right: 41px;
				}
			}
		}

		.c-btn001{
			margin-top: 40px;

			@include sp{
				margin-top: 10px;
			}

			&__link{
				display: block;
				max-width: 840px;
				margin: 0 auto;
				border: 3px solid #3C76D2;
				color: #3C76D2;
				border-radius: 70px;
				padding: 30px 0 17px;
				text-align: center;
				line-height: 56px;
				font-weight: 500;
				@include mincyo();
				position: relative;
				transition: 0.3s ease-in-out;
				cursor: pointer;

				@include sp{
					line-height: normal;
					padding: 15px 0;
					border-width: 1.5px;
				}

				&::after{
					content: "";
					position: absolute;
					width: 11px;
					height: 21px;
					background-size: 11px 21px !important;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					top: 50%;
					transform: translateY(-50%);
					right: 40px;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 5px;
						height: 12px;
						background-size: 5px 12px !important;
						right: 20px;
					}
				}

				@include hover{
					background: #3C76D2;
					color: #fff;

					&::after{
						background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
					}

					.c-btn001__text01{
						&::before{
							background: url(/img/sosai/lp/2022_syukatsu/icon05_W.svg) no-repeat center;
						}

						&::after{
							background: url(/img/sosai/lp/2022_syukatsu/icon06_W.svg) no-repeat center;
						}
					}
				}
			}

			&__text01{
				font-size: 4.3rem;
				position: relative;
				padding:  0 20px;
				margin-right: 20px;

				@include sp{
					font-size: 2rem;
					line-height: 27px;
					margin-right: 10px;
					padding: 0 8px;
				}

				@include max-w(374px){
					font-size: 1.6rem;
				}

				&::before,
				&::after{
					content: "";
					position: absolute;
					width: 13px;
					height: 34px;
					background-size: 13px 34px !important;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 6px;
						height: 16px;
						background-size: 6px 16px !important;
					}
				}

				&::before{
					background: url(/img/sosai/lp/2022_syukatsu/icon05.svg) no-repeat center;
					top: 0;
					left: 0;
				}

				&::after{
					background: url(/img/sosai/lp/2022_syukatsu/icon06.svg) no-repeat center;
					bottom: 0;
					right: 0;
				}
			}

				&__text02{
				font-size: 4.3rem;

					@include sp{
					font-size: 2rem;
					line-height: 27px;
				}

					@include max-w(374px){
					font-size: 1.6rem;
				}
			}

				&__text03{
				font-size: 3.4rem;
				position: relative;
				top: 4px;
			}
		}
	}

	.c-box03{
		border: 10px solid rgba($color: #4C93A1, $alpha: 0.3);
		background: #fff;
		padding: 75px;
		position: relative;
		box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);

		@include sp{
			padding: 30px 20px 20px;
			border-width: 5px;
		}

		&__inner01{
			display: flex;
			justify-content: space-between;

			@include sp{
				display: block;
			}
		}

		&__inner02{
			display: flex;
			align-items: center;
			margin-top: 40px;

			@include sp{
				display: block;
				margin-top: 20px;
			}
		}

		&__img{
			@include sp{
				img{
					width: 100%;
				}
			}
		}

		&__content{
			margin-right: 22px;

			@include sp{
				margin-right: 0;
				margin-bottom: 20px;
			}
		}

		&__bltext{
			display: flex;
			align-items: center;
			margin-top: 20px;

			@include sp{
				margin-top: 10px;
			}
		}

		&__info{
			padding-left: 30px;
			border-left: 1px solid #D4D4D4;

			@include sp{
				padding-left: 0;
				border-left: 0;
			}
		}

		&__phone{
			display: flex;
			align-items: center;

			@include sp{
				border: 1.5px solid #D4D4D4;
				border-radius: 50px;
				position: relative;
				flex-wrap: wrap;
				justify-content: center;
				height: 70px;
				margin-top: 10px;

				.c-box03__text05{
					display: flex;
				}

				&::after{
					content: "";
					position: absolute;
					width: 11px;
					height: 21px;
					background-size: 11px 21px !important;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					top: 50%;
					transform: translateY(-50%);
					right: 40px;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 5px;
						height: 12px;
						background-size: 5px 12px !important;
						right: 20px;
					}
				}
			}

			.c-box03__text05{
				display: none;
				@include sp{
					display: flex;
				}
			}
		}

		&__text01{
			position: absolute;
			top: -30px;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			font-size: 2.4rem;
			line-height: 32px;
			letter-spacing: 0.1em;
			font-weight: bold;
			background: #4C93A1;
			padding: 10px 0 8px;
			width: 380px;
			color: #fff;

			@include sp{
				top: -20px;
				width: calc(100% - 80px);
				font-size: 1.6rem;
				line-height: 21px;
				padding: 4px 0 5px;
			}

			@include max-w(374px){
				width: calc(100% - 40px);
			}
		}

		&__text02{
			font-size: 2.6rem;
			letter-spacing: 0.15em;
			line-height: 46px;
			font-weight: 500;
			color: #324961;
			@include mincyo();

			@include sp{
				font-size: 1.3rem;
				line-height: 19px;
				text-align: center;
				font-weight: bold;
				font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
			}
		}

		&__text03{
			width: 120px;
			height: 120px;
			border: 1px solid #4C93A1;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #4C93A1;

			@include sp{
				width: 70px;
				height: 70px;
			}

			&Content{
				*{
					display: block;
					line-height: 1;
					text-align: center;
				}

				@include sp{
					width: 37px;
				}
			}
			&-1{
				font-size: 1.8rem;
				font-weight: bold;
				letter-spacing: 0.15em;
			}
			&-2{
				font-size: 2.8rem;
				font-weight: bold;
				letter-spacing: 0.2em;
				@include mincyo();
				margin: 5px 0;
			}
			&-3{
				font-size: 1.2rem;
				font-weight: bold;
				letter-spacing: 0.01em;
			}
		}

		&__text04{
			width: calc(100% - 120px);
			padding-left: 20px;
			font-size: 3.8rem;
			line-height: 60px;
			font-weight: 600;
			@include mincyo();
			color: #4C93A1;

			@include sp{
				width: calc(100% - 70px);
				padding-left: 10px;
				font-size: 1.8rem;
				line-height: 27px;
				letter-spacing: 0.1em !important;

				br{
					display: none;
				}
			}

			&-1{
				letter-spacing: 0.12em;
			}
			&-2{
				letter-spacing: 0.14em;
			}
		}

		&__text05{
			font-size: 2rem;
			font-weight: bold;
			letter-spacing: 0.05em;
			line-height: 27px;
			color: #3C76D2;
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;

			@include sp{
				font-size: 1.3rem;
				line-height: 16px;
				justify-content: center;
				display: none;
				position: relative;
				top: 5px;
			}

			&::before{
					content: "";
					position: relative;
					display: block;
					width: 22px;
					height: 22px;
					background-size: 22px 22px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
					margin-right: 8px;

					@include sp{
						width: 12px;
						height: 12px;
						background-size: 12px 12px;
						margin-right: 4px;
					}
				}
		}

		&__text06{
			font-size: 1.2rem;
			line-height: 15px;
			letter-spacing: 0.05em;
			color: #fff;
			background: #3C76D2;
			width: 54px;
			padding: 3px 0 1px;
			text-align: center;
			font-weight: bold;

			@include sp{
				width: 62px;
				font-size: 1rem;
				line-height: 14px;
				padding: 3px 0 1px;
				br{
					display: none;
				}
			}
		}

		&__number{
			font-size: 4rem;
			line-height: 1;
			@include mincyo();
			color: #3C76D2;
			letter-spacing: 0.035em;
			font-weight: 600;
			margin-left: 6px;
			white-space: nowrap;

			@include sp{
				font-size: 2.2rem;
				line-height: 29px;
				letter-spacing: 0.025em;
				margin-left: 4px;
			}
		}

		&__text07{
			color: #666666;
			text-align: center;
			width: 135px;
			white-space: nowrap;
			font-weight: 500;

			@include sp{
				width: 100%;
				position: relative;
				top: -4px;
				br{
					display: none;
				}
			}
			&-1{
				font-size: 1.4rem;
				@include sp{
					font-size: 1rem;
					line-height: 16px;
				}
			}
				&-2{
				font-size: 1.2rem;
				@include sp{
					font-size: 1rem;
					line-height: 16px;
				}
			}
		}

		&__text08{
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 32px;
			letter-spacing: 0.05em;
			color: #3C76D2;
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;

			@include sp{
				font-size: 1.3rem;
				line-height: 16px;
				text-align: center;
				justify-content: center;
				margin-top: 10px;
			}

			&::before,
			&::after{
				content: "";
				position: relative;
				display: block;
				width: 91px;
				height: 3px;
				background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat-x;
				background-size: 8px 3px;

				@include sp{
					width: 55px;
					height: 2px;
					background-size: 4px 2px;
				}
			}

			&::before{
				margin-right: 15px;

				@include sp{
					margin-right: 5px;
				}
			}

			&::after{
				margin-left: 15px;

				@include sp{
					margin-left: 5px;
				}
			}
		}

		.c-btn002{
			margin-right: 30px;

			@include sp{
				margin-right: 0;
			}

			&__text{
					@include sp{
						text-align: left;
					}
			}

			a{
				display: block;
				border: 3px solid #3C76D2;
				border-radius: 50px;
				color: #3C76D2;
				width: 610px;
				text-align: center;
				font-size: 2.8rem;
				line-height: 36px;
				font-weight: 600;
				@include mincyo();
				padding: 29px 0;
				position: relative;

				@include sp{
					width: 100%;
					font-size: 1.8rem;
					line-height: 24px;
					letter-spacing: 0.05em;
					border-width: 1.5px;
					padding: 10px 30px 10px 55px;
					display: flex;
					justify-content: center;
				}

				@include hover{
					background: #3C76D2;
					color: #fff;

					&::after{
						background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
					}

					&::before{
						background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
					}
				}

				&::before{
					content: "";
					position: absolute;
					width: 24px;
					height: 24px;
					background-size: 24px 24px !important;
					background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
					top: 50%;
					transform: translateY(-50%);
					left: 40px;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 23px;
						height: 23px;
						background-size: 23px 23px  !important;
						left: 20px;
					}
				}

				&::after{
					content: "";
					position: absolute;
					width: 11px;
					height: 21px;
					background-size: 11px 21px !important;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					top: 50%;
					transform: translateY(-50%);
					right: 40px;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 5px;
						height: 12px;
						background-size: 5px 12px !important;
						right: 20px;
					}
				}
			}
		}
	}

	.c-box04{
		padding: 60px 60px 90px;
		background: #fff;
		box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);

		@include sp{
			padding: 20px;
		}

		.c-imgtext02{
			&__content{
				width: calc(100% - 400px);

				@include sp{
					width: 100%;
				}
			}
		}
	}

	.c-list001{
		display: flex;
		flex-wrap: wrap;

		@include sp{
			display: block;
		}

		&__item {
			border: 5px solid rgba($color: #4C93A1, $alpha: 0.3);
			padding: 40px 40px 60px;
			width: calc(50% - 15px);
			margin-bottom: 30px;

			@include sp{
				width: 100%;
				border-width: 2.5px;
				padding: 20px 20px 30px;
				margin-bottom: 20px;
			}

			&:nth-child(2n){
				margin-left: 30px;
				@include sp{
					margin-left: 0;
				}
			}
		}

		&__title {
			position: relative;
			@include mincyo();
			font-size: 2.2rem;
			line-height: 36px;
			color: #4C93A1;
			font-weight: 900;
			width: calc(100% - 34px);
			border-bottom: 1px solid #4C93A1;
			padding-bottom: 13px;
			margin-bottom: 13px;

			@include sp{
				font-size: 1.6rem;
				line-height: normal;
				width: calc(100% - 20px);
				padding-bottom: 5px;
				margin-bottom: 15px;
			}

			&::after{
				content: "";
				position: absolute;
				top: 0;
				right: -34px;
				width: 34px;
				height: 34px;
				background: url(/img/sosai/lp/2022_syukatsu/icon02.svg) no-repeat center;
				background-size: 34px 34px;

				@include sp{
					top: inherit;
					bottom: 5px;
					right: -20px;
					width: 20px;
					height: 20px;
					background-size: 20px 20px;
				}
			}
		}

		&__text{
			font-size: 1.6rem;
			line-height: 28px;
			font-weight: 500;

			@include sp{
				font-size: 1.3rem;
				line-height: 22px;
			}
		}
	}

	.c-list002{
		&__item{
			display: flex;
			align-items: center;
			padding: 40px;
			border: 1px solid #4C93A1;
			position: relative;

			@include sp{
				display: block;
				padding: 20px;
			}

			&:not(:last-child){
				margin-bottom: 30px;

				@include sp{
					margin-bottom: 25px;
				}

				&::after{
					content: "";
					position: absolute;
					bottom: -26px;
					left: 50%;
					transform: translateX(-50%);
					width: 0;
					height: 0;
					border-left: 30px solid transparent;
					border-right: 30px solid transparent;
					border-top: 20px solid #4C93A1;

					@include sp{
						bottom: -21px;
						border-left: 22.5px solid transparent;
						border-right: 22.5px solid transparent;
						border-top: 15px solid #4C93A1;
					}
				}
			}
		}

		&__stt{
			display: flex;
			align-items: center;
			border-right: 1px solid #DDDDDD;
			padding-right: 40px;

			@include sp{
				border-right: 0;
				padding-right: 0;
				margin-bottom: 15px;
			}
		}

		&__title{
			font-size: 2rem;
			line-height: 28px;
			font-weight: 900;
			@include mincyo();
			color: #4C93A1;
			margin-left: 20px;
			width: 240px;

			@include sp{
				font-size: 1.6rem;
				line-height: normal;
				margin-left: 10px;
				width: 100%;
			}
		}

		&__number{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 60px;
			height: 60px;
			min-width: 60px;
			background: url(/img/sosai/lp/2022_syukatsu/bg01.jpg) no-repeat center;
			color: #fff;
			@include mincyo();
			font-size: 2rem;
			line-height: 36px;
			letter-spacing: 0.1em;
			font-weight: 900;

			@include sp{
				width: 30px;
				height: 30px;
				min-width: 30px;
				font-size: 1.4rem;
				line-height: 18px;
			}
		}

		&__text{
			padding-left: 40px;
			font-size: 1.6rem;
			line-height: 28px;
			font-weight: 500;
			color: #2F2933;

			@include sp{
				padding-left: 0;
				font-size: 1.3rem;
				line-height: 22px;
			}
		}
	}

	.c-list003{
		display: flex;

		@include sp{
			display: block;
		}

		&__item{
			position: relative;
			width: calc((100% - 30px) / 3);
			background: #fff;
			border: 5px solid rgba($color: #4C93A1, $alpha: 0.3);
			padding: 35px;
			text-align: center;

			@include sp{
				width: 100%;
				padding: 20px;
				border-width: 2.5px;
			}

			&:not(:last-child){
				margin-right: 20px;

				@include sp{
					margin-right: 0;
					margin-bottom: 15px;
				}
			}
		}

		&__point{
			font-size: 1.8rem;
			letter-spacing: 0.1em;
			line-height: 24px;
			color: #4C93A1;
			@include mincyo();
			font-weight: 900;

			@include sp{
				position: absolute;
				top: 20px;
				right: 20px;
				width: calc(100% - 120px);
				font-size: 1.2rem;
				line-height: 18px;
				text-align: left;
			}
		}

		&__img{
			margin: 30px 0;

			@include sp{
				width: 70px;
				height: 70px;
				margin: 0;
			}
		}

		&__title{
			font-size: 2.4rem;
			letter-spacing: 0.1em;
			line-height: 36px;
			color: #4C93A1;
			@include mincyo();
			font-weight: 600;
			margin-bottom: 20px;

			@include sp{
				width: calc(100% - 80px);
				margin-left: auto;
				margin-top: -50px;
				font-size: 2.1rem;
				line-height: 28px;
				margin-bottom: 0;
				text-align: left;
			}
		}

		&__text{
			text-align: left;
			font-size: 1.6rem;
			line-height: 32px;
			font-weight: 500;
			color: #2F2933;

			@include sp{
				font-size: 1.3rem;
				line-height: 21px;
				letter-spacing: 0.05em;
				margin-top: 12px;
			}
		}
	}

	.c-copyright{
		font-size: 1.3rem;
		line-height: 17px;
		letter-spacing: 0.1em;
		font-weight: 500;
		color: rgba($color: #FFFFFF, $alpha: 0.7);
		background: #324961;
		text-align: center;
		padding: 21px 0 22px;

		@include sp{
			font-size: 1rem;
			line-height: 14px;
			padding: 8px 0;
		}
	}

	.c-box05{
		background: #fff;
		border: 10px solid rgba($color: #4C93A1, $alpha: 0.3);
		box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);
		position: relative;
		padding: 60px;

		@include sp{
			border-width: 5px;
			padding: 30px 15px 10px;
		}

		@include max-w(374px){
			padding: 30px 10px 10px;
		}

		&__label{
			background: #4C93A1;
			border-radius: 7px;
			color: #fff;
			font-size: 2.4rem;
			text-align: center;
			letter-spacing: 0.05em;
			line-height: 32px;
			width: 484px;
			padding: 12px 0;
			position: absolute;
			top: -33px;
			left: 50%;
			transform: translateX(-50%);
			@include mincyo();

			@include sp{
				width: calc(100% - 40px);
				font-size: 1.3rem;
				line-height: 20px;
				padding: 8px 0 7px;
				top: -20px;
				border-radius: 4px;
			}

			@include max-w(374px){
				font-size: calc((13/375)*100vw)
			}

			&::after{
				content: "";
				position: absolute;
				bottom: -14px;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-left: 12px solid transparent;
				border-right: 12px solid transparent;
				border-top: 20px solid #4C93A1;

				@include sp{
					bottom: -7px;
					border-left: 7.5px solid transparent;
					border-right: 7.5px solid transparent;
					border-top: 12.5px solid #4C93A1;
				}
			}
		}

		&__text{
			font-size: 2.4rem;
			line-height: 30px;
			letter-spacing: -0.01em;
			font-weight: bold;
			color: #324961;
			white-space: nowrap;
			margin-bottom: 20px;

			@include sp{
				font-size: 1.1rem;
				line-height: 18px;
				text-align: center;
				letter-spacing: 0;
				white-space: normal;
				margin-bottom: 5px;
			}
		}

		&__circle{
			width: 130px;
			height: 130px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #4C93A1;
			border-radius: 50%;

			img{
				width: 70px;

				@include sp{
					width: 35px;
				}
			}

			@include sp{
				display: none;
				width: 70px;
				height: 70px;
			}
		}

		&__content{
			display: flex;
			align-items: center;

			@include sp{
				display: block;
			}
		}

		&__cnt{
			margin: 0 15px;

			@include sp{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin: 0;
			}

			.c-box05__circle{
				display: none;
				@include sp{
					display: flex;
				}
			}

			&title{
				font-size: 4rem;
				line-height: 60px;
				@include mincyo;
				font-weight: 600;
				letter-spacing: 0.1em;
				color: #4C93A1;
				white-space: nowrap;
				margin-bottom: 6px;
				span{
					font-size: 3.8rem;

					@include sp{
						font-size: 1.6rem;
					}
				}

				@include sp{
					font-size: 1.8rem;
					line-height: 27px;
					letter-spacing: 0;
					width: calc(100% - 70px);
					white-space: normal;
					padding-left: 10px;
					margin-bottom: 0;
				}

				@include max-w(374px){
					font-size: calc((18/375)*100vw);
					line-height: calc((27/375)*100vw);
				}
			}

			&stage{
				display: flex;
				align-items: center;

				@include sp{
					width: 100%;
					margin-top: 5px;
					justify-content: center;
				}
			}
		}

		&__img{
			@include sp{
				display: none;
			}
		}

		&__stage{
			&label{
				width: 78px;
				font-size: 1.4rem;
				letter-spacing: 0.05em;
				line-height: 20px;
				font-weight: bold;
				color: #fff;
				background: #4C93A1;
				padding: 6px 0;
				text-align: center;
				display: inline-block;
				margin-right: 10px;
				flex-shrink: 0;

				@include sp{
					width: 26px;
					font-size: 0.8rem;
					line-height: 11px;
					padding: 4px 0 3px;
				}

				@include max-w(374px){
					width: calc((26/375)*100vw);
					font-size: calc((8/375)*100vw);
					line-height: calc((11/375)*100vw);
					padding: calc((4/375)*100vw) 0 calc((3/375)*100vw);
					margin-right: calc((5/375)*100vw);
				}
			}

			&date{
				font-size: 3.6rem;
				@include mincyo;
				letter-spacing: 0.04em;
				font-weight: 600;
				color: #324961;
				line-height: 1;
				display: flex;
				align-items: center;
				white-space: nowrap;

				@include sp{
					font-size: 2rem;
				}

				@include max-w(374px){
					font-size: calc((20/375)*100vw);
				}

				& + .c-box05__stagedate{
					margin-left: 10px;

					@include sp{
						margin-left: 5px;
					}
				}

				span{
					font-size: 2.8rem;

					@include sp{
						font-size: 1.6rem;
					}

					@include max-w(374px){
						font-size: calc((16/375)*100vw);
					}

					&.c-box05__stageyear{
						font-size: 3.6rem;
						white-space: nowrap;
						span{
							font-size: 2.8rem;
						}
						@include sp{
							display: none;
						}
					}
				}

				&::after{
					content: "金";
					width: 24px;
					height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #324961;
					color: #fff;
					font-size: 1.6rem;
					line-height: 1;
					font-weight: bold;
					margin-left: 10px;

					@include sp{
						font-size: 1rem;
						width: 16px;
						height: 17px;
						margin-left: 5px;
					}

					@include max-w(374px){
						font-size: calc((10/375)*100vw);
						width: calc((16/375)*100vw);
						height: calc((19/375)*100vw);
						margin-left: calc((5/375)*100vw);
					}
				}

				&:last-of-type{
					&::before{
						content: "〜";
						font-size: 2.4rem;
						line-height: 1;
						letter-spacing: 0.035em;
						margin-right: 10px;

						@include sp{
							font-size: 1.5rem;
							margin-right: 5px;
						}

						@include max-w(374px){
							font-size: calc((15/375)*100vw);
							margin-right: calc((5/375)*100vw);
						}
					}
				}
			}
		}

		&__bottom{
			display: flex;
			align-items: center;
			margin: 30px -30px 0;

			@include sp{
				display: block;
				margin: 10px 0 0;
			}
		}

		&__btn{
			width: 700px;
			padding: 0 30px;

			@include sp{
				width: 100%;
				padding: 0;
			}
			&text{
				font-size: 1.6rem;
				line-height: 20px;
				letter-spacing: 0.05em;
				font-weight: bold;
				text-align: center;
				color: #324961;
				position: relative;
				padding: 0 15px;
				max-width: 402px;
				margin: 0 auto 6px;

				@include sp{
					font-size: 1.1rem;
					line-height: 1;
					max-width: 270px;
					letter-spacing: 0;
				}

				@include max-w(374px){
					max-width: calc((270/375)*100vw);
					font-size: calc((11/375)*100vw);
				}

				&::before,
				&::after{
					content: "";
					width: 1px;
					height: 20px;
					border-left: 1px solid #324961;
					position: absolute;
					bottom: 0;

					@include sp{
						height: 12.5px;
					}
				}

				&::before{
					transform: rotate(-30deg);
					left: 0;
				}
				&::after{
					transform: rotate(-150deg);
					right: 0;
				}
			}

			&link{
				display: flex;
				align-items: center;
				justify-content: center;
				border: 3px solid #3C76D2;
				border-radius: 50px;
				font-size: 2.8rem;
				line-height: 36px;
				color: #3C76D2;
				text-align: center;
				@include mincyo;
				position: relative;
				padding: 29px 0;
				font-weight: 600;
				transition: 0.3s ease-in-out;
				cursor: pointer;

				@include sp{
					border-width: 2px;
					font-size: 1.8rem;
					line-height: 24px;
					letter-spacing: 0.05em;
					padding: 9px 25px;
				}

				@include max-w(374px){
					font-size: calc((16/375)*100vw);
					line-height: calc((24/375)*100vw);
					padding: 9px 20px;
				}

				span{
					margin: 0 15px;

					@include sp{
						text-align: left;
						flex: 1;
					}
				}

				@include hover{
					opacity: 1;
					background: #3C76D2;
					color: #fff;

					&::before{
						background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
					}

					&::after{
						background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
					}
				}

				&::before{
					content: "";
					position: relative;
					display: inline-block;
					width: 24px;
					height: 24px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
					background-size: 24px 24px !important;
					transition: 0.3s ease-in-out;
				}

				&::after{
					content: "";
					position: relative;
					display: inline-block;
					width: 8px;
					height: 18px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					background-size: 8px 18px !important;
					transition: 0.3s ease-in-out;
				}
			}
		}

		&__info{
			width: 50%;
			max-width: 510px;
			padding: 8px 30px;
			border-left: 1px solid #D4D4D4;
			white-space: nowrap;

			@include sp{
				width: 100%;
				max-width: 100%;
				border-left: none;
				padding: 0;
			}

			&title{
				font-size: 2.4rem;
				font-weight: bold;
				line-height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #3C76D2;

				@include sp{
					font-size: 1.3rem;
					line-height: 16px;
					display: none;
				}

				&::before{
					content: "";
					position: relative;
					display: inline-block;
					width: 22px;
					height: 22px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
					background-size: 22px 22px !important;
					margin-right: 10px;

					@include sp{
						height: 12px;
						width: 12px;
						background-size: 12px 12px !important;
						margin-right: 5px;
					}
				}
			}

			&cnt{
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin: 5px 0;
				pointer-events: none;
				opacity: 1 !important;

				@include sp{
					justify-content: center;
					flex-wrap: wrap;
					border: 2px solid #D4D4D4;
					padding: 5px 0;
					border-radius: 70px;
					margin: 10px 0 8px;
					pointer-events: initial;
				}

				.c-box05__infotitle{
					display: none;
					@include sp{
						display: flex !important;
						width: 100%;
					}
				}
			}

			&label{
				width: 54px;
				background: #3C76D2;
				font-size: 1.2rem;
				line-height: 15px;
				letter-spacing: 0.05em;
				text-align: center;
				padding: 5px 0 2px;
				flex-shrink: 0;
				color: #fff;
				font-weight: bold;

				@include sp{
					width: 62px;
					font-size: 1rem;
					line-height: normal;
					display: inline-block;
					padding: 2px 0 0;
				}
			}

			&phone{
				font-size: 4rem;
				line-height: 1;
				letter-spacing: 0.035em;
				@include mincyo;
				font-weight: 600;
				color: #3C76D2;
				margin: 0 10px;

				@include sp{
					display: inline-block;
					font-size: 2.2rem;
					letter-spacing: 0.025em;
					margin: 4px 0 4px 8px;
				}
			}

			&txt{
				font-size: 1.4rem;
				color: #666666;
				font-weight: 500;
				line-height: 1;

				@include sp{
					width: 100%;
					font-size: 1rem;
					display: flex;
					justify-content: center;
					text-align: center;
				}

				span{
					font-size: 1.2rem;
					margin-top: 4px;
					display: block;

					@include sp{
						font-size: 1rem;
						margin-top: 0;
					}
				}
			}

			&text{
				font-size: 1.8rem;
				letter-spacing: 0.05em;
				font-weight: bold;
				color: #3C76D2;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				@include sp{
					font-size: 1.3rem;
				}

				span{
					margin: 0 15px;
					display: block;

					@include sp{
						margin: 0 10px;
					}
				}


				&::before,
				&::after{
					content: "";
					position: relative;
					display: inline-block;
					height: 3px;
					width: 100%;
					background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat center;
					background-size: 9px 3px;
				}
			}
		}
	}

	.c-box06{
		margin-top: 40px;

		@include sp{
			margin-top: 20px;
		}

		&__link{
			border-radius: 90px;
			border: 3px solid #3C76D2;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 17px 0 16px;
			position: relative;
			background: #fff;
			transition: 0.3s ease-in-out;
			cursor: pointer;
			box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.1);

			@include sp{
				border-radius: 12px;
				border-width: 2px;
				padding: 15px;
				display: block;
			}

			@include hover{
				background: #3C76D2;
				opacity: 1;

				&::after{
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
				}

				.c-box06{
					&__title,
					&__subtitle,
					&__cnttext{
						color: #fff !important;
					}

					&__cntlabel{
						background: #fff;
						color: #3C76D2;
					}
				}

				.cl-1{
					color: #fff;
				}
			}

			&::after{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 60px;
				width: 10px;
				height: 24px;
				background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
				background-size: 10px 24px !important;
				transition: 0.3s ease-in-out;

				@include sp{
					display: none;
				}
			}
		}
		
		&__subtitle{
			font-size: 2.4rem;
			letter-spacing: -0.03em;
			color: #2F2933;
			display: block;
			margin-bottom: 12px;
			transition: 0.3s ease-in-out;

			@include sp{
				font-size: 1.4rem;
				letter-spacing: 0;
			}

			@include max-w(374px){
				font-size: calc((14/375)*100vw)
			}
		}

		&__title{
			@include mincyo;
			font-weight: 600;
			font-size: 4rem;
			letter-spacing: 0.1em;
			color: #3C76D2;
			line-height: 1;
			transition: 0.3s ease-in-out;

			@include sp{
				position: relative;
				font-size: 2.3rem;
				text-align: center;

				&::after{
					content: "";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					width: 10px;
					height: 24px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					background-size: 10px 24px !important;
					transition: 0.3s ease-in-out;
				}
			}

			@include max-w(374px){
				text-align: left;
				font-size: calc((20/375)*100vw);
			}
		}

		&__txt{
			display: inline-block;
			position: relative;
			padding: 0 15px;
			margin-right: 20px;
			&::before,
			&::after{
				content: "";
				position: absolute;
				height: 34px;
				width: 13px;
				background-size: 13px 34px !important;

				@include sp{
					height: 17px;
					width: 7px;
					background-size: 7px 17px !important;
				}
			}

			&::before{
				top: 0;
				left: 0;
				background: url(/img/sosai/lp/2022_syukatsu/icon05.svg) repeat center;
			}

			&::after{
				bottom: 0;
				right: 0;
				background: url(/img/sosai/lp/2022_syukatsu/icon06.svg) repeat center;
			}
		}

		&__cnt{
			border-left: 1px solid #D4D4D4;
			padding: 0 20px 0 30px;
			margin: 0 0 0 30px;

			@include sp{
				border-left: none;
				padding: 0;
				margin: 15px 0 0;
				position: relative;
			}

			&label{
				font-size: 1.8rem;
				line-height: 20px;
				font-weight: bold;
				color: #fff;
				text-align: center;
				letter-spacing: 0.15em;
				background: #4C93A1;
				padding: 9px 0 7px;
				width: 374px;
				transition: 0.3s ease-in-out;

				@include sp{
					width: 100%;
					font-size: 1.2rem;
					line-height: 1;
					padding: 5px 0 4px;
					margin-bottom: 10px;
				}
			}

			&text{
				font-size: 2.6rem;
				@include mincyo;
				color: #2F2933;
				letter-spacing: -0.06em;
				margin-top: 5px;
				transition: 0.3s ease-in-out;

				@include sp{
					font-size: 1.8rem;
					margin-top: 0;
				}

				@include max-w(374px){
					font-size: calc((18/375)*100vw)
				}

				.cl-1{
					color: #4C93A1;
					transition: 0.3s ease-in-out;
				}

				.fz-20{
					font-size: 2rem;

					@include sp{
						font-size: 1.5rem;
					}

					@include max-w(374px){
						font-size: calc((15/375)*100vw)
					}
				}
			}
		}

		&__img{
			@include sp{
				position: absolute;
				bottom: 15px;
				right: 15px;
			}
			img{
				height: 121px;
				width: auto;

				@include sp{
					height: 58px;
				}

				@include max-w(374px){
					height: calc((58/375)*100vw);
				}
			}
		}
	}

	.c-box07{
		box-shadow: 10px 10px 20px rgba($color: #000000, $alpha: 0.1);
		position: relative;

		&__label{
			background: #4C93A1;
			border-radius: 7px;
			color: #fff;
			font-size: 2.4rem;
			text-align: center;
			letter-spacing: 0.05em;
			line-height: 32px;
			width: 484px;
			padding: 12px 0;
			position: absolute;
			top: -33px;
			left: 50%;
			transform: translateX(-50%);
			@include mincyo();

			@include sp{
				width: calc(100% - 40px);
				font-size: 1.3rem;
				line-height: 20px;
				padding: 8px 0 7px;
				top: -20px;
				border-radius: 4px;
			}

			@include max-w(374px){
				font-size: calc((13/375)*100vw)
			}

			&::after{
				content: "";
				position: absolute;
				bottom: -14px;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-left: 12px solid transparent;
				border-right: 12px solid transparent;
				border-top: 20px solid #4C93A1;

				@include sp{
					bottom: -7px;
					border-left: 7.5px solid transparent;
					border-right: 7.5px solid transparent;
					border-top: 12.5px solid #4C93A1;
				}
			}
		}

		&__top{
			background: url(/img/sosai/lp/2022_syukatsu/bg07.png) no-repeat center;
			background-size: cover;
			padding: 50px 40px 35px;

			@include sp{
				padding: 30px 20px 10px;
			}

			@include max-w(374px){
				padding: 30px 15px 10px;
			}
		}

		&__text{
			font-size: 2rem;
			line-height: 30px;
			letter-spacing: -0.01em;
			font-weight: bold;
			color: #fff;
			white-space: nowrap;
			margin-bottom: 20px;
			text-align: center;

			@include sp{
				font-size: 1.1rem;
				line-height: 18px;
				letter-spacing: 0;
				white-space: normal;
				margin-bottom: 5px;
			}
		}

		&__circle{
			width: 130px;
			height: 130px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #fff;
			border-radius: 50%;

			img{
				width: 70px;

				@include sp{
					width: 35px;
				}
			}

			@include sp{
				display: none;
				width: 70px;
				height: 70px;
			}
		}

		&__content{
			display: flex;
			align-items: center;

			@include sp{
				display: block;
			}
		}

		&__cnt{
			margin: 0 35px 0 15px;

			@include sp{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin: 0;
			}

			.c-box07__circle{
				display: none;
				@include sp{
					display: flex;
				}
			}

			&title{
				font-size: 4rem;
				line-height: 60px;
				@include mincyo;
				font-weight: 600;
				letter-spacing: 0.1em;
				color: #fff;
				white-space: nowrap;
				margin-bottom: 6px;
				span{
					font-size: 3.8rem;

					@include sp{
						font-size: 1.6rem;
					}
				}

				@include sp{
					font-size: 1.8rem;
					line-height: 27px;
					letter-spacing: 0;
					width: calc(100% - 70px);
					white-space: normal;
					padding-left: 10px;
					margin-bottom: 0;
				}

				@include max-w(374px){
					font-size: calc((18/375)*100vw);
					line-height: calc((27/375)*100vw);
				}
			}

			&stage{
				display: flex;
				align-items: center;

				@include sp{
					width: 100%;
					margin-top: 5px;
					justify-content: center;
				}
			}
		}

		&__img{
			img{
				width: 362px;

				@include sp{
					width: auto;
				}
			}
			@include sp{
				margin: 15px 0 -44px;
			}
		}

		&__stage{
			&label{
				width: 78px;
				font-size: 1.4rem;
				letter-spacing: 0.05em;
				line-height: 20px;
				font-weight: bold;
				color: #fff;
				background: #4C93A1;
				padding: 6px 0;
				text-align: center;
				display: inline-block;
				margin-right: 10px;
				flex-shrink: 0;

				@include sp{
					width: 26px;
					font-size: 0.8rem;
					line-height: 11px;
					padding: 4px 0 3px;
				}

				@include max-w(374px){
					width: calc((26/375)*100vw);
					font-size: calc((8/375)*100vw);
					line-height: calc((11/375)*100vw);
					padding: calc((4/375)*100vw) 0 calc((3/375)*100vw);
					margin-right: calc((5/375)*100vw);
				}
			}

			&date{
				font-size: 3.6rem;
				@include mincyo;
				letter-spacing: 0.04em;
				font-weight: 600;
				color: #fff;
				line-height: 1;
				display: flex;
				align-items: center;

				@include sp{
					font-size: 2rem;
				}

				@include max-w(374px){
					font-size: calc((20/375)*100vw);
				}

				& + .c-box07__stagedate{
					margin-left: 10px;

					@include sp{
						margin-left: 5px;
					}
				}

				span{
					font-size: 2.8rem;

					@include sp{
						font-size: 1.6rem;
					}

					@include max-w(374px){
						font-size: calc((16/375)*100vw);
					}

					&.c-box07__stageyear{
						font-size: 3.6rem;
						white-space: nowrap;
						span{
							font-size: 2.8rem;
						}
						@include sp{
							display: none;
						}
					}
				}

				&::after{
					content: "金";
					width: 24px;
					height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #fff;
					color: #324961;
					font-size: 1.6rem;
					line-height: 1;
					font-weight: bold;
					margin-left: 10px;

					@include sp{
						font-size: 1rem;
						width: 16px;
						height: 17px;
						margin-left: 5px;
					}

					@include max-w(374px){
						font-size: calc((10/375)*100vw);
						width: calc((16/375)*100vw);
						height: calc((19/375)*100vw);
						margin-left: calc((5/375)*100vw);
					}
				}

				&:last-of-type{
					&::before{
						content: "〜";
						font-size: 2.4rem;
						line-height: 1;
						letter-spacing: 0.035em;
						margin-right: 10px;

						@include sp{
							font-size: 1.5rem;
							margin-right: 5px;
						}

						@include max-w(374px){
							font-size: calc((15/375)*100vw);
							margin-right: calc((5/375)*100vw);
						}
					}
				}
			}
		}

		&__bottom{
			display: flex;
			align-items: center;
			padding: 30px 40px;
			background: #fff;

			@include sp{
				display: block;
				padding: 56px 20px 20px;
			}

			@include max-w(374px){
				padding: 56px 15px 15px;
			}
		}

		&__btn{
			width: 700px;
			padding: 0 30px 0 0;

			@include sp{
				width: 100%;
				padding: 0;
			}
			&text{
				font-size: 1.6rem;
				line-height: 20px;
				letter-spacing: 0.05em;
				font-weight: bold;
				text-align: center;
				color: #324961;
				position: relative;
				padding: 0 15px;
				max-width: 402px;
				margin: 0 auto 6px;

				@include sp{
					font-size: 1.1rem;
					line-height: 1;
					max-width: 270px;
					letter-spacing: 0;
				}

				@include max-w(374px){
					max-width: calc((270/375)*100vw);
					font-size: calc((11/375)*100vw);
				}

				&::before,
				&::after{
					content: "";
					width: 1px;
					height: 20px;
					border-left: 1px solid #324961;
					position: absolute;
					bottom: 0;

					@include sp{
						height: 12.5px;
					}
				}

				&::before{
					transform: rotate(-30deg);
					left: 0;
				}
				&::after{
					transform: rotate(-150deg);
					right: 0;
				}
			}

			&link{
				display: flex;
				align-items: center;
				justify-content: center;
				border: 3px solid #3C76D2;
				border-radius: 50px;
				font-size: 2.8rem;
				line-height: 36px;
				color: #3C76D2;
				text-align: center;
				@include mincyo;
				position: relative;
				padding: 29px 0;
				font-weight: 600;
				transition: 0.3s ease-in-out;
				cursor: pointer;

				@include sp{
					border-width: 2px;
					font-size: 1.8rem;
					line-height: 24px;
					letter-spacing: 0.05em;
					padding: 9px 25px;
				}

				@include max-w(374px){
					font-size: calc((16/375)*100vw);
					line-height: calc((24/375)*100vw);
					padding: 9px 20px;
				}

				span{
					margin: 0 15px;

					@include sp{
						text-align: left;
						flex: 1;
					}
				}

				@include hover{
					opacity: 1;
					background: #3C76D2;
					color: #fff;

					&::before{
						background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
					}

					&::after{
						background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
					}
				}

				&::before{
					content: "";
					position: relative;
					display: inline-block;
					width: 24px;
					height: 24px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
					background-size: 24px 24px !important;
					transition: 0.3s ease-in-out;
				}

				&::after{
					content: "";
					position: relative;
					display: inline-block;
					width: 8px;
					height: 18px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					background-size: 8px 18px !important;
					transition: 0.3s ease-in-out;
				}
			}
		}

		&__info{
			width: 50%;
			max-width: 510px;
			padding: 8px 0 8px 30px;
			border-left: 1px solid #D4D4D4;
			white-space: nowrap;

			@include sp{
				width: 100%;
				max-width: 100%;
				border-left: none;
				padding: 0;
			}

			&title{
				font-size: 2.4rem;
				font-weight: bold;
				line-height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #3C76D2;

				@include sp{
					font-size: 1.3rem;
					line-height: 16px;
					display: none;
				}

				&::before{
					content: "";
					position: relative;
					display: inline-block;
					width: 22px;
					height: 22px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
					background-size: 22px 22px !important;
					margin-right: 10px;

					@include sp{
						height: 12px;
						width: 12px;
						background-size: 12px 12px !important;
						margin-right: 5px;
					}
				}
			}

			&cnt{
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin: 5px 0;
				pointer-events: none;
				opacity: 1 !important;

				@include sp{
					justify-content: center;
					flex-wrap: wrap;
					border: 2px solid #D4D4D4;
					padding: 5px 0;
					border-radius: 70px;
					margin: 10px 0 8px;
					pointer-events: initial;
				}

				.c-box07__infotitle{
					display: none;
					@include sp{
						display: flex !important;
						width: 100%;
					}
				}
			}

			&label{
				width: 54px;
				background: #3C76D2;
				font-size: 1.2rem;
				line-height: 15px;
				letter-spacing: 0.05em;
				text-align: center;
				padding: 5px 0 2px;
				flex-shrink: 0;
				color: #fff;
				font-weight: bold;

				@include sp{
					width: 62px;
					font-size: 1rem;
					line-height: normal;
					display: inline-block;
					padding: 2px 0 0;
				}
			}

			&phone{
				font-size: 4rem;
				line-height: 1;
				letter-spacing: 0.035em;
				@include mincyo;
				font-weight: 600;
				color: #3C76D2;
				margin: 0 10px;

				@include sp{
					display: inline-block;
					font-size: 2.2rem;
					letter-spacing: 0.025em;
					margin: 4px 0 4px 8px;
				}
			}

			&txt{
				font-size: 1.4rem;
				color: #666666;
				font-weight: 500;
				line-height: 1;

				@include sp{
					width: 100%;
					font-size: 1rem;
					display: flex;
					justify-content: center;
					text-align: center;
				}

				span{
					font-size: 1.2rem;
					margin-top: 4px;
					display: block;

					@include sp{
						font-size: 1rem;
						margin-top: 0;
					}
				}
			}

			&text{
				font-size: 1.8rem;
				letter-spacing: 0.05em;
				font-weight: bold;
				color: #3C76D2;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				@include sp{
					font-size: 1.3rem;
				}

				span{
					margin: 0 15px;
					display: block;

					@include sp{
						margin: 0 10px;
					}
				}


				&::before,
				&::after{
					content: "";
					position: relative;
					display: inline-block;
					height: 3px;
					width: 100%;
					background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat center;
					background-size: 9px 3px;
				}
			}
		}
	}

	.c-box08{
		max-width: 640px;
		margin: 0 auto;
		&__title{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__circle{
			width: 120px;
			height: 120px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #4C93A1;
			border-radius: 50%;

			img{
				width: 62px;

				@include sp{
					width: 35px;
				}
			}

			@include sp{
				width: 70px;
				height: 70px;
			}
		}

		&__txt{
			font-size: 3.2rem;
			line-height: 48px;
			@include mincyo;
			font-weight: 600;
			letter-spacing: 0.075em;
			color: #4C93A1;
			white-space: nowrap;
			margin-left: 16px;
			span{
				font-size: 3rem;

				@include sp{
					font-size: 1.6rem;
				}
			}

			@include sp{
				font-size: 1.8rem;
				line-height: 27px;
				letter-spacing: 0;
				width: calc(100% - 70px);
				white-space: normal;
				margin-left: 8px;
				margin-bottom: 0;
			}

			@include max-w(374px){
				font-size: calc((18/375)*100vw);
				line-height: calc((27/375)*100vw);
			}
		}

		&__img{
			margin: 20px 0 40px;

			@include sp{
				margin: 10px 0;
			}
		}

		&__list{
			li{
				border-bottom: 1px solid #4C93A1;
				padding: 25px 0;
				display: flex;
				align-items: center;
				font-size: 1.8rem;
				line-height: 28px;
				color: #2F2933;

				@include sp{
					padding: 15px 0;
					display: block;
					font-size: 1.3rem;
					line-height: 22px;
				}

				&:first-child{
					border-top: 1px solid #4C93A1;
				}
			}

			&text{
				font-weight: bold;
				width: 165px;
				padding-right: 30px;

				@include sp{
					width: 100%;
					padding-right: 0;
				}
			}

			&title{
				flex: 1;
			}
		}

		&__btn{
			width: 580px;
			margin: 30px auto 40px;

			@include sp{
				width: 100%;
				margin: 15px 0 10px;
			}

			&link{
				display: flex;
				align-items: center;
				justify-content: space-around;
				border: 3px solid #3C76D2;
				border-radius: 50px;
				font-size: 2.4rem;
				line-height: 36px;
				color: #3C76D2;
				text-align: center;
				@include mincyo;
				position: relative;
				padding: 19px 40px;
				font-weight: 600;
				transition: 0.3s ease-in-out;
				cursor: pointer;

				@include sp{
					border-width: 2px;
					font-size: 1.6rem;
					line-height: 22px;
					letter-spacing: 0.05em;
					padding: 9px 25px;
				}

				@include max-w(374px){
					font-size: calc((16/375)*100vw);
					line-height: calc((24/375)*100vw);
					padding: 9px 20px;
				}

				span{
					margin: 0 15px;

					@include sp{
						text-align: left;
						flex: 1;
					}
				}

				@include hover{
					opacity: 1;
					background: #3C76D2;
					color: #fff;

					&::before{
						background: url(/img/sosai/lp/2022_syukatsu/icon-computer_W.svg) no-repeat center;
					}

					&::after{
						background: url(/img/sosai/lp/2022_syukatsu/icon-arrow_W.svg) no-repeat center;
					}
				}

				&::before{
					content: "";
					position: relative;
					display: inline-block;
					width: 24px;
					height: 24px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-computer.svg) no-repeat center;
					background-size: 24px 24px !important;
					transition: 0.3s ease-in-out;
				}

				&::after{
					content: "";
					position: relative;
					display: inline-block;
					width: 8px;
					height: 18px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-arrow.svg) no-repeat center;
					background-size: 8px 18px !important;
					transition: 0.3s ease-in-out;
				}
			}
		}

		&__text{
			display: none !important;

			@include sp{
				display: flex !important;
			}
		}

		&__info{
			white-space: nowrap;
			background: #F9F9F9;
			padding: 25px 0 20px;

			@include sp{
				width: 100%;
				max-width: 100%;
				border-left: none;
				padding: 0;
				background: none;
			}

			&title,
			&cnt,
			&text{
				width: 450px;
				margin: 0 auto;

				@include sp{
					width: 100%;
				}
			}

			&title{
				font-size: 2.4rem;
				font-weight: bold;
				line-height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #3C76D2;

				@include sp{
					font-size: 1.3rem;
					line-height: 16px;
					display: none;
				}

				&::before{
					content: "";
					position: relative;
					display: inline-block;
					width: 22px;
					height: 22px;
					background: url(/img/sosai/lp/2022_syukatsu/icon-phone.svg) no-repeat center;
					background-size: 22px 22px !important;
					margin-right: 10px;

					@include sp{
						height: 12px;
						width: 12px;
						background-size: 12px 12px !important;
						margin-right: 5px;
					}
				}
			}

			&cnt{
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin: 5px auto;
				pointer-events: none;
				opacity: 1 !important;

				@include sp{
					justify-content: center;
					flex-wrap: wrap;
					border: 2px solid #D4D4D4;
					padding: 5px 0;
					border-radius: 70px;
					margin: 10px 0 12px;
					pointer-events: initial;
				}

				.c-box08__infotitle{
					display: none;
					@include sp{
						display: flex !important;
						width: 100%;
					}
				}
			}

			&label{
				width: 54px;
				background: #3C76D2;
				font-size: 1.2rem;
				line-height: 15px;
				letter-spacing: 0.05em;
				text-align: center;
				padding: 5px 0 2px;
				flex-shrink: 0;
				color: #fff;
				font-weight: bold;

				@include sp{
					width: 62px;
					font-size: 1rem;
					line-height: normal;
					display: inline-block;
					padding: 2px 0 0;
				}
			}

			&phone{
				font-size: 4rem;
				line-height: 1;
				letter-spacing: 0.035em;
				@include mincyo;
				font-weight: 600;
				color: #3C76D2;
				margin: 0 10px;

				@include sp{
					display: inline-block;
					font-size: 2.2rem;
					letter-spacing: 0.025em;
					margin: 4px 0 4px 8px;
				}
			}

			&txt{
				font-size: 1.4rem;
				color: #666666;
				font-weight: 500;
				line-height: 1;


				@include sp{
					width: 100%;
					font-size: 1rem;
					display: flex;
					justify-content: center;
					text-align: center;
				}

				span{
					font-size: 1.2rem;
					margin-top: 4px;
					display: block;

					@include sp{
						font-size: 1rem;
						margin-top: 0;
					}
				}
			}

			&text{
				font-size: 1.8rem;
				letter-spacing: 0.05em;
				font-weight: bold;
				color: #3C76D2;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				@include sp{
					font-size: 1.3rem;
				}

				span{
					margin: 0 15px;
					display: block;

					@include sp{
						margin: 0 10px;
					}
				}


				&::before,
				&::after{
					content: "";
					position: relative;
					display: inline-block;
					height: 3px;
					width: 100%;
					background: url(/img/sosai/lp/2022_syukatsu/icon-dot.svg) repeat center;
					background-size: 9px 3px;
				}
			}
		}
	}

	&__01 {
		padding: 120px 0;

		@include sp{
			padding: 60px 0;
		}

		.c-block001{
			margin-top: 40px;
		}
	}

	&__02 {
		padding: 120px 0;
		background: #F9F9F9;

		@include sp{
			padding: 60px 0;
		}

		.c-imgtext01 {
			@include sp {
				margin-bottom: 30px;
			}
		}
	}

	&__03{
		padding: 120px 0;
		background: url(/img/sosai/lp/2022_syukatsu/bg03.jpg) no-repeat center;
		background-size: cover;

		@include sp{
			padding: 60px 0;
		}
	}

	&__04{
		padding: 120px 0 60px;

			@include sp{
			padding: 60px 0 30px;
		}
	}

	&__05{
		padding: 80px 0 120px;
		background: url(/img/sosai/lp/2022_syukatsu/bg04.png) no-repeat center;
		background-size: cover;

			@include sp{
			padding: 40px 0 60px;
		}

		.c-list003{
			margin-top: 56px;

				@include sp{
			margin-top: 28px;
		}
		}
	}

	&__06{
		padding: 120px 0;
		background: url(/img/sosai/lp/2022_syukatsu/bg03.jpg) no-repeat center;
		background-size: cover;

			@include sp{
			padding: 60px 0;
		}
	}

	&__07{
		padding: 140px 0 120px;
		background: #FBFCF8;

			@include sp{
			padding: 70px 0 60px;
		}

		.c-box03{
			margin-bottom: 120px;

				@include sp{
			margin-bottom: 60px;
		}
		}

		.c-title01{
			.cl-01{
				position: relative;
				padding: 0 10px;
				margin-left: 40px;

				@include sp{
					margin-left: 0;
					padding: 0 10px;
				}

				&::before,
				&::after{
					content: "";
					position: absolute;
					width: 14px;
					height: 36px;
					background-size: 14px 36px !important;
					transition: 0.3s ease-in-out;

					@include sp{
						width: 8px;
						height: 21px;
						background-size: 8px 21px !important;
					}
				}

				&::before{
					background: url(/img/sosai/lp/2022_syukatsu/icon07.svg) no-repeat center;
					top: 0;
					left: 0;
				}

				&::after{
					background: url(/img/sosai/lp/2022_syukatsu/icon08.svg) no-repeat center;
					bottom: 0;
					right: 0;
				}
			}
		}

		.c-box04{
			margin: 50px 0;

				@include sp{
			margin: 20px 0;
		}
		}
	}
}
