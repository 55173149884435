@use '../../0_base' as *;

/*------------------------------------------------------------
/service/hoken/intention/confirm.php
------------------------------------------------------------*/
.p-hokenIntentionConfirm {
	padding: 41px 0 133px;

	@include sp {
		padding: 19px 20px 35px;
	}

	&1 {
		// .p-hokenIntentionConfirm1 .c-step3 .
		.c-step3 {
			margin-top: 43px;
			margin-bottom: 80px;

			@include sp {
				margin-top: 20px;
				margin-bottom: 40px;
			}
		}
	}
}
