@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭 静岡県にお住まいの方
------------------------------------------------------------*/
.p-sosaiServiceShizuoka {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.77;
	color: $gray08;
}
//静岡県にお住まいの方
.p-sosaiServiceShizuoka01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//コープ静岡のお葬式「ラビュー」
.p-sosaiServiceShizuoka02 {
	margin: 16px 0 125px;
	&__txt01 {
		margin-bottom: 51px;
		font-size: 2.2rem;
		font-weight: bold;
		line-height: 1.45;
		text-align: center;
	}
	&__txt02 {
		&__img {
			width: 400px;
			transform: translateY(5px);
		}
		&__txt {
			width: 680px;
			h3 {
				margin-bottom: 23px;
				font-size: 3.2rem;
				font-weight: 500;
				line-height: 1.25;
				color: $sosaiSeminarColor01;
				@include mincyo;
			}
			p{
				font-size: 1.7rem;
			}
		}
	}
}

//ラビューの安心ポイント
.p-sosaiServiceShizuoka03 {
	margin-bottom: 104px;
	padding: 107px 0 78px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	&__listBox {
		padding-top: 55px;
		li {
			width: 353px;
			margin: 0 30px 70px 0;
			padding: 34px 40px;
			background-color: #ffffff;
			border: solid 2px $sosaiNaviColor02;
			&:nth-child(3n) {
				margin: 0 0 70px 0;
			}
			span {
				margin-top: -83px;
				margin-bottom: 28px;
				display: block;
				text-align: center;
			}
			.listBox-txt01{
				color: #197e7e;
				font-size: 2.6rem;
				text-align: center;
				line-height: 1.3;
				margin-bottom: 27px;
				padding:0 30px;
				@include mincyo();
			}
			.listBox-txt02 {
				text-align: left;
				img {
					width: 100%;
					max-width: 470px;
					margin: 0 auto;
					display: block;
				}
				b {
					font-weight: bold;
				}
			}
		}
	}
	&__txt {
		margin-bottom: 30px;
		&:last-of-type {
			margin-bottom: 60px;
		}
		@include sp {
			margin-bottom: 15px;
			padding: 0 15px;
		}
	}
	.c-list03 {
		margin: 10px auto;
		&__lable {
			white-space: nowrap;
		}
	}
}

//コープ静岡からのお知らせ
.p-sosaiServiceShizuoka04 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		margin-bottom: 46px;
	}
	&__newsList {
		li {
			padding: 24px 0 16px;
			border-bottom: solid 1px $glaylight01;
			&:last-child {
				border: none;
			}
			a {
				width: 830px;
				color: $gray01;
				font-size: 1.7rem;
				font-weight: 500;
				line-height: 1.5;
				text-decoration: underline;
				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
		}
		&_data {
			width: 120px;
			font-size: 1.7rem;
			color: $gray03;
			padding-left: 5px;
			@include mincyo;
		}
		.c-newsCate02 {
			width: 60px;
			margin: 4px 5px 0 0;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.c-newsCate03 {
			width: 70px;
			margin: 4px 34px 0 0;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.c-sosaiBtn01 {
		width: 175px;
		margin-top: 27px;
		font-weight: bold;
	}
}

//コープの終活応援！お葬式勉強会
.p-sosaiServiceShizuoka05 {
	padding: 103px 0 140px;
	background-color: $subColor10;
	&__txt01 {
		margin-bottom: 92px;
		text-align: center;
		line-height: 1.9;
		b {
			font-weight: bold;
		}
	}
	&__infomation {
		margin-bottom: 40px;
		&__schedule {
			width: 545px;
			background-color: #ffffff;
			h2 {
				padding: 7px;
				font-size: 2.0rem;
				font-weight: bold;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: center;
				@include mincyo;
			}
		}
		&__report {
			width: 545px;
			background-color: #ffffff;
			h2 {
				padding: 7px;
				font-size: 2.0rem;
				font-weight: bold;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: center;
				@include mincyo;
			}
		}
		&__data {
			width: 103px;
			height: 20px;
			font-size: 1.7rem;
			font-weight: bold;
			line-height: 1.17;
			color: $gray03;
			@include mincyo;
		}
		.c-newsCate02, .c-newsCate03 {
			width: auto;
			margin-right: 3px;
			line-height: 20px;
			&--sosaiShizuoka{
				padding: 0 19px;
			}
			&--sosaiSeminar, &--sosaiReport{
				padding: 0 11px;
			}
			@include sp{
				height: 15px;
			    font-size: 1rem;
			    line-height: 15px;
			    &--sosaiShizuoka{
					padding: 0 10px;
				}
				&--sosaiSeminar, &--sosaiReport{
					padding: 0 4px;
				}
			}
		}
		a {
			font-size: 1.7rem;
			color: $gray03;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
				opacity: 1;
			}
		}
		ul {
			li {
				padding: 30px 32px 23px;
				border-bottom: dotted 1px $gray07;
				> div {
					margin-bottom: 16px;
					@include sp{
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&__btn {
		width: 100%;
		text-align: center;
		background-color: #ffffff;
		border: solid 1px $glaylight13;
		> div {
			width: 50%;
			padding: 23px 33px;
			&:first-child {
				border-right: solid 1px $glaylight13;
			}
		}
		p {
			font-size: 2.0rem;
			font-weight: bold;
			@include mincyo;
		}
		&__tel {
			font-size: 2.0rem;
			font-weight: bold;
			@include mincyo;
			em {
				font-size: 3.6rem;
				font-weight: 500;
				line-height: 1;
				font-style: normal;
				letter-spacing: 0.8px;
				color: $sosaiNaviColor02;
			}
			span {
				font-size: 1.6rem;
				display: block;
				@include mincyo;
			}
		}
	}
}

@include sp {
	.p-sosaiServiceShizuoka {
		font-size: 1.3rem;
	}

	//静岡県にお住まいの方 sp
	.p-sosaiServiceShizuoka01 {
		min-width: 100%;
		margin-bottom: 34px;
		padding: 32px 0 33px;
	}
	//コープ静岡のお葬式「ラビュー」 sp
	.p-sosaiServiceShizuoka02 {
		margin-bottom: 47px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 39px;
		}
		&__txt01 {
			margin-bottom: 19px;
			font-size: 1.4rem;
		}
		&__txt02 {
			&__img {
				width: 100%;
				margin-bottom: 29px;
			}
			&__txt {
				width: 100%;
				h3 {
					margin-bottom: 18px;
					font-size: 2.3rem;
					line-height: 1.1;
					text-align: center;
				}
				p{
					font-size: 1.4rem;
					line-height: 1.6;
				}
			}
		}
	}
	//ラビューの安心ポイント sp
	.p-sosaiServiceShizuoka03 {
		margin-bottom: 20px;
		padding: 48px 0 50px;
		.c-sosaiTitle02b{
			margin-bottom: 48px;
			padding: 0 20px;
		}
		&__listBox{
			padding: 20px 20px 50px;
			li {
				width: 100%;
				height: auto;
				margin-bottom: 39px;
				padding: 15px 18px;
    			line-height: 1.6;
				&:last-child {
					margin-bottom: 0;
				}
				span {
					margin-top: -45px;
					margin-bottom: 15px;
					display: block;
					text-align: center;
					img {
						width: 60px;
					}
				}
				.listBox-txt01{
					font-size:2.1rem;
					margin-bottom: 14px;
				}
				.listBox-txt02{
					font-size: 1.4rem;
				}
			}
		}
		.c-list03 {
			padding: 0 15px;
			&__lable {
				white-space: normal;
			}
		}
	}

	//コープ静岡からのお知らせ sp
	.p-sosaiServiceShizuoka04 {
		margin-bottom: 49px;
		padding: 0 20px;
		.c-sosaiTitle02b {
		    margin-bottom: 23px;
		}
		&__newsList {
			li {
				padding: 15px 0;
				a {
					width: 100%;
					font-size: 1.4rem;
					padding: 4px 0 0;
				}
				p {
					font-size: 1.4rem;
					padding: 4px 0 0;
				}
			}
			&_data {
				width: auto;
				padding: 0;
				margin: 0 13px 0 0;
				font-size: 1.3rem;
			}
			.c-newsCate02 {
				width: 40px;
				height: 15px;
				margin: 2px 3px 2px 0;
				font-size: 1rem;
			}
			.c-newsCate03 {
				width: 50px;
				height: 15px;
				margin: 2px 0;
				font-size: 1rem;
			}
		}
		.c-sosaiBtn01 {
			width: 50%;
			margin-top: 13px;
			line-height: 3;
    		font-size: 1.3rem;
		}
	}

	//コープの終活応援！お葬式勉強会 sp
	.p-sosaiServiceShizuoka05 {
		padding: 46px 15px 50px;
		&__txt01 {
			margin-bottom: 34px;
			font-size: 1.4rem;
			line-height: 1.6;
			padding: 0 5px;
		}
		&__infomation {
			margin-bottom: 35px;
			&__schedule {
				width: 100%;
				margin-bottom: 31px;
				h2 {
					font-size: 1.6rem;
					padding: 6px;
				}
			}
			&__report {
				width: 100%;
				h2 {
					font-size: 1.6rem;
					padding: 6px;
				}
			}
			&__data {
				width: auto;
				margin-right: 14px;
				font-size: 1.3rem;
			}
			a {
				font-size: 1.4rem;
				line-height: 1.5;
			}
			p {
				font-size: 1.4rem;
				line-height: 1.5;
			}
			ul {
				li {
					padding: 17px 19px 12px;
					line-height: 1;
				}
			}
		}
		&__btn {
			> div {
				width: 100%;
				padding: 15px;
				&:first-child {
					border: none;
					border-bottom: solid 1px $glaylight13;
				}
			}
			p {
				font-size: 1.8rem;
				margin-bottom: 7px;
			}
			&__tel_sp {
				font-weight: bold;
				background-color: $sosaiNaviColor02;
				text-align: left;
				box-shadow: 0 3px 0 #20656e;
				transition: 0.3s ease-in-out;
				@include radius(3px);
				@include tr3d0;
				a {
					width: 100%;
					height: 100%;
					padding: 10px 0 15px 30px;
					color: #ffffff;
					img {
						width: 26px;
						margin-right: 15px;
						vertical-align: middle;
					}
					em {
						font-size: 1.4rem;
						font-style: normal;
						line-height: 1.2;
					}
					span {
						font-size: 1.1rem;
						line-height: 1;
						display: block;
					}
				}
				&:hover {
					transform: translate(0,3px);
					box-shadow: none;
				}
			}
			.c-sosaiBtn03{
				display: none;
			}
			&__mail_sp {
				font-weight: bold;
				background-color: $sosaiBtnColor01;
				text-align: left;
				box-shadow: 0 3px 0 #004e91;
				transition: 0.3s ease-in-out;
				@include radius(3px);
				@include tr3d0;
				a {
					width: 100%;
					height: 100%;
					padding: 19px 19px 10px 26px;
					color: #ffffff;
					font-size: 1.4rem;
					line-height: 1.2;
					img {
						width: 26px;
						margin-right: 20px;
						vertical-align: middle;
					}
				}
				&:hover {
					transform: translate(0,3px);
					box-shadow: none;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
