@use '../0_base' as *;

/*------------------------------------------------------------
保険 LP
------------------------------------------------------------*/
.c-header-lp{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	padding: 40px 0;
	z-index: 999;
	@include sp{
		padding: 20px;
	}
	&__logo{
		max-width: 271px;
		@include sp{
			max-width: 160px;
		}
	}
}
