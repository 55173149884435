@use '../../0_base' as *;

/*------------------------------------------------------------
よくあるご質問
------------------------------------------------------------*/
.p-reformFaq {
	padding: 52px 0 40px;
	@include sp {
		padding: 85px 20px 70px;
	}
	.c-breadcrumb1{
		padding-bottom: 32px;
		a {
			color: $linkColor01;
		}
	}
	&__title {
		margin-bottom: 53px;
		text-align: center;
		font-size: 3.2rem;
		color: $gray01;
		line-height: 1.35;
		@include sp {
			margin-bottom: 30px;
			font-size: 2.1rem;
		}
		span {
			display: block;
			font-size: 1.4rem;
			font-weight: normal;
			opacity: 0.5;
		}
	}
	&__title02 {
		font-size: 2.4rem;
		color: #fff;
		background: #68ba93;
		padding: 16px 29px 15px;
		margin-bottom: 30px;
		@include sp {
			width: calc(100% + 40px);
			margin-left: -20px;
			font-size: 1.6rem;
			padding: 13px 15px 12px;
			margin-bottom: 20px;
			line-height: 1.6;
		}
	}
	&__box {
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 60px;
			@include sp {
				margin-bottom: 30px;
			}
		}
		&__q {
			font-size: 2rem;
			color: $gray01;
			line-height: 1.6;
			background: #f2f2f2;
			padding: 15px 29px 13px;
			cursor: pointer;
			position: relative;
			display: table;
			width: 100%;
			@include sp {
				font-size: 1.4rem;
				padding: 13px 35px 11px 15px;
			}
			span {
				display: table-cell;
				width: 27px;
				margin-right: 5px;
				@include sp {
					width: 20px;
				}
			}
			&:after, &:before {
				content: '';
				background: $gray01;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				transition: transform 0.25s ease-out;
			}
			&:after {
				width: 24px;
				height: 3px;
				right: 20px;
				@include sp {
					width: 10px;
					height: 2px;
					right: 10px;
				}
			}
			&:before {
				width: 3px;
				height: 24px;
				right: 30px;
				@include sp {
					width: 2px;
					height: 10px;
					right: 14px;
				}
			}
		}
		&__a {
			display: none;
			background: #fff;
			border: 1px solid $glaylight01;
			border-top: none;
			padding: 24px 40px 29px 37px;
			@include sp {
				padding: 15px;
			}
			p {
				font-size: 1.6rem;
				color: $gray01;
				line-height: 2;
				display: table;
				@include sp {
					font-size: 1.3rem;
					line-height: 1.6;
				}
			}
			span {
				display: table-cell;
				width: 32px;
				font-size: 2rem;
				font-weight: bold;
				color: #68ba93;
				@include sp {
					width: 20px;
					font-size: 1.8rem;
				}
			}
		}
		&.is-open {
			.p-reformFaq__box__q::before {
				transform: translateY(-50%) rotate(-90deg);
			}
		}
	}
	&__btn {
		margin-bottom: 40px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include sp {
			display: block;
			margin-bottom: 25px;
		}
		li {
			width: 550px;
			height: 80px;
			@include sp {
				width: 100%;
				height: 40px;
				margin-bottom: 10px;
			}
		}
		a {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 2rem;
			font-weight: bold;
			color: #68ba93;
			border: 3px solid $glaylight01;
			border-radius: 50px;
			position: relative;
			@include sp {
				font-size: 1.5rem;
				padding: 0 30px 0 10px;
				line-height: 1.6;
				border: 2px solid $glaylight01;
			}
			&:after {
				content: '';
				width: 12px;
				height: 7px;
				background: url("../img/common/icon_down03.png");
				background-size: contain;
				position: absolute;
				top: 34px;
				right: 27px;
				transition: 0.3s ease-in-out;
				@include sp {
					top: 50%;
					transform: translateY(-50%);
					right: 15px;
				}
			}
		}
	}
}