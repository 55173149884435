@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭 コープの終活応援
------------------------------------------------------------*/
.p-sosaiSeminar {
	background-color: #ffffff;
	font-size: 1.8rem;
	line-height: 1.77;
	color: $gray08;
}

//コープの終活応援
.p-sosaiSeminar01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
		img {
			margin-top: 6px;
		}
	}
}

//コープの終活応援とは
.p-sosaiSeminar02 {
	margin: 18px 0 112px;
	.c-sosaiTitle02b {
		margin-bottom: 50px;
		span {
			&:after {
				margin-top: 5px;
			}
		}
	}
	> p {
		text-align: center;
		font-size: 1.7rem;
	}
}

//コープの終活応援セミナー
.p-sosaiSeminar03 {
	margin-bottom: 140px;
	.c-supplement {
		text-align: center;
	}
	&__list {
		&__btn {
			width: 372px;
			margin-right: 1px;
			padding: 17px 15px 15px;
			color: $gray10;
			background-color: $glaylight17;
			font-size: 1.6rem;
			font-weight: 700;
			text-align: center;
			cursor: pointer;
			transition: opacity 0.3s ease-in-out;
			&:last-child {
				margin-right: 0;
			}
			&--yamanashi {
				border-top: solid 2px $sosaiYamanashiColor01;
			}
			&--kanagawa {
				border-top: solid 2px $sosaiKanagawaColor01;
			}
			&--shizuoka {
				border-top: solid 2px $sosaiSizuokaColor01;
			}
			&.is-open, {
				color: $gray08;
				background-color: #ffffff;
				opacity: 1 !important;
				cursor: default;
			}
			&:hover {
				opacity: 0.70;
			}
		}
	}
	&__bg {
		padding: 107px 0 120px;
		background-image: url(../img/sosai/index_bg.jpg);
		background-size: cover;
	}
	&__content {
		display: none;
		&.is-open {
			display: block;
		}
		.c-sosaiTitle02b {
			margin-bottom: 50px;
			span {
				&:after {
					margin-top: 5px;
				}
			}
		}
	}
	&__txt {
		text-align: center;
		margin-bottom: 112px;
		b {
			font-size: 2rem;
		}
		p {
			font-size: 1.7rem;
			+ p {
				margin-top: 13px;
			}
		}
	}
	&__ttl {
		font-size: 2.4rem;
		font-weight: bold;
		color: #fff;
		@include mincyo;
		background-color: $reformBtnColor05;
		position: relative;
		padding: 10px 10px 7px 63px;
		&:before {
			content: '';
			width: 30px;
			height: 30px;
			background-image: url(../img/sosai/icon_sosai01.png);
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			top: 50%;
			left: 19px;
			transform: translateY(-50%);
		}
	}
	&__box {
		border: 1px solid $reformBtnColor05;
		padding: 69px 61px 74px 59px;
		background-color: #fff;
		&__ttl {
			font-size: 2.8rem;
			font-weight: 500;
			padding-left: 24px;
			border-left: 4px solid $sosaiNaviColor02;
			line-height: 1;
			margin-bottom: 24px;
		}
		&__ttl02 {
			font-size: 2rem;
			color: $sosaiSeminarColor01;
			margin-bottom: 3px;
		}
		&__txt {
			p {
				font-size: 1.7rem;
				margin-bottom: 24px;
			}
		}
		&__note {
			font-size: 1.2rem !important;
			font-weight: bold;
			color: $gray03;
			margin: 30px 0 0 !important;
		}
		&__col {
			display: flex;
			&__txt {
				width: calc(100% - 460px);
			}
			&__img {
				width: 460px;
				text-align: right;
				padding-top: 1px;
			}
			&:not(:first-of-type) {
				margin-top: 99px;
				.p-sosaiSeminar03__box__ttl {
					margin-bottom: 34px;
				}
			}

		}
		.c-sosaiBtn02 {
			margin-top: -2px;
			display: block;
			a {
				padding: 0 28px 0 0;
				text-align: center;
				background-position: 87% center;
			}
		}
	}
	&__bnr01 {
		margin: 112px 0 81px;
		&__txt {
			margin-bottom: 20px;
			p {
				text-align: center;
				font-size: 2.2rem;
				@include mincyo;
			}
		}
		&__tel {
			display: flex;
			border: 1px solid $glaylight13;
			p {
				width: 50%;
				background: $subColor10;
				font-size: 1.6rem;
				font-weight: bold;
				color: $sosaiTextColor01;
				border-right: 1px solid $glaylight13;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 5px;
			}
			a {
				width: 50%;
				font-size: 1.6rem;
				color: $sosaiTextColor01;
				text-align: center;
				padding: 28px 0 22px;
				@media screen and (min-width: $breakpoint01+1) {
					@include mincyo;
				}
				span {
					display: block;
					font-size: 3.6rem;
					color: $gray08;
					line-height: 1;
					letter-spacing: 1px;
					margin-bottom: 3px;
					&:before {
						content: "";
						width: 25px;
						height: 25px;
						background: url(../img/common/icon_tel06.png) no-repeat;
						background-size: cover;
						display: inline-block;
						vertical-align: baseline;
						margin: 0 10px 0 1px;
					}
				}
			}
		}
	}
	&__bnr02 {
		margin-bottom: 0 0 0;
		&__txt {
			margin-bottom: 19px;
			p {
				text-align: center;
				font-size: 2.2rem;
				@include mincyo;
			}
		}
		&__box {
			display: flex;
			border: 1px solid $glaylight13;
		}
		&__tel {
			width: 50%;
			border-right: 1px solid $glaylight13;
			padding: 21px 0 23px;
			@media screen and (min-width: $breakpoint01+1) {
				@include mincyo;
			}
			p {
				font-size: 2rem;
				text-align: center;
				margin-bottom: 3px;
			}
			a {
				font-size: 1.6rem;
				color: $sosaiTextColor01;
				text-align: center;
				padding: 28px 0 22px;

				span {
					display: block;
					font-size: 3.6rem;
					color: $gray08;
					line-height: 1;
					letter-spacing: 1px;
					margin-bottom: 3px;
					&:before {
						content: "";
						width: 25px;
						height: 25px;
						background: url(../img/common/icon_tel06.png) no-repeat;
						background-size: cover;
						display: inline-block;
						vertical-align: baseline;
						margin: 0 10px 0 1px;
					}
				}
			}
		}
		&__btn {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			.c-sosaiBtn03 {
				a {
					padding: 23px 2px 24px 50px;
					&:before {
						margin-right: 20px;
						margin-top: 0;
					}
				}
			}
		}
	}
	&__infomation {
		margin-bottom: 73px;
		&__schedule {
			width: 720px;
			background-color: #ffffff;
			h2 {
				padding: 7px;
				font-size: 2.0rem;
				font-weight: bold;
				color: #ffffff;
				background-color: $sosaiSubColor01;
				text-align: center;
				@include mincyo;
			}
		}
		&__report {
			width: 370px;
			background-color: #ffffff;
			h2 {
				padding: 7px;
				font-size: 2.0rem;
				font-weight: bold;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: center;
				@include mincyo;
			}
		}
		&__data {
			width: 104px;
			height: 20px;
			font-size: 1.7rem;
			font-weight: bold;
			line-height: 1.17;
			color: $gray03;
			@include mincyo;
		}
		.c-newsCate02, .c-newsCate03 {
			width: auto;
			margin-right: 5px;
		}
		.c-newsCate02 {
			padding: 0 18px;
		}
		.c-newsCate03 {
			padding: 0 11px;
		}
		a {
			font-size: 1.7rem;
			color: $gray03;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
		ul {
			li {
				padding: 30px 30px 23px;
				border-bottom: dotted 1px $gray07;
				&:last-child {
					border: none;
				}
				> div {
					margin-bottom: 15px;
				}
			}
		}
	}
}





@include sp {
	.p-sosaiSeminar {
		font-size: 1.6rem;
	}
	//コープの終活応援 sp
	.p-sosaiSeminar01 {
		min-width: 100%;
		margin-bottom: 50px;
		padding: 32px 0 33px;
	}
	//コープの終活応援とは sp
	.p-sosaiSeminar02 {
		margin-bottom: 40px;
		padding: 0 20px;
		.c-sosaiTitle02b {
			margin-bottom: 50px;
			span {
				&:after {
					margin-top: 13px;
				}
			}
		}
		> p {
			font-size: 1.4rem;
			text-align: left;
		}
		.c-sosaiTitle02b {
			margin-bottom: 23px;
		}
	}
	//コープの終活応援セミナー sp
	.p-sosaiSeminar03 {
		margin-bottom: 60px;
		&__list {
			padding: 0 15px;
			&__btn {
				width: 33.3333%;
				font-size: 1.3rem;
				padding: 10px 5px 8px;
			}
		}
		&__bg {
			padding: 47px 15px 49px;
		}
		&__content {
			.c-sosaiTitle02b {
				margin-bottom: 28px;
				span {
					&:after {
						margin-top: 12px;
					}
				}
			}
		}
		&__txt {
			margin-bottom: 44px;
			text-align: left;
			padding: 0 5px;
			p, b {
				font-size: 1.4rem;
				line-height: 1.6;
			}
			p + p {
				margin-top: 9px;
			}
		}
		&__ttl {
			font-size: 1.9rem;
			padding: 16px 5px 15px 63px;
			&:before {
				width: 40px;
				height: 40px;
				left: 14px;
			}
		}
		&__box {
			padding: 19px 20px 28px;
			&__col {
				flex-wrap: wrap;
				flex-direction: column-reverse;
				&__txt {
					width: 100%;
				}
				&__img {
					margin-bottom: 19px;
					width: 100%;
					img {
						display: block;
						margin: 0 aoto;
					}
				}
				&:not(:first-of-type) {
					margin-top: 39px;
					.p-sosaiSeminar03__box__ttl {
						margin-bottom: 13px;
					}
				}
			}
			&__ttl {
				font-size: 1.9rem;
				border-left: 2px solid $sosaiNaviColor02;
				padding-left: 12px;
				line-height: 1.1;
				margin-bottom: 15px;
			}
			&__ttl02 {
				font-size: 1.5rem;
				margin-bottom: 5px;
			}
			&__txt {
				p {
					font-size: 1.4rem;
					line-height: 1.49;
					margin-bottom: 20px;
				}
			}
			&__note {
				font-size: 1rem !important;
				margin: -3px 0 0 !important;
			}
			.c-sosaiBtn02 {
				margin-top: -5px;
				font-size: 1.3rem;
				line-height: 1.3;
				a {
					padding: 12px 28px 11px;
					background-position: 94% center;
				}
			}
		}
		&__bnr01 {
			margin: 44px 0 38px;
			padding: 0 15px;
			&__txt {
				margin-bottom: 21px;
				padding: 0 8px;
				p {
					text-align: left;
					font-size: 1.8rem;
					line-height: 1.6;
				}
			}
			&__tel {
				display: block;
				padding-bottom: 17px;
				p {
					width: 100%;
					text-align: center;
					font-size: 1.4rem;
					border-right: none;
					padding: 15px 5px 13px;
					margin-bottom: 17px;
				}
				div {
					position: relative;
					width: 165px;
					margin: 0 auto;
					transform: translateX(20px);
					&:before {
						content: '';
						width: 25px;
						height: 25px;
						background: url(../img/common/icon_tel07.png) no-repeat;
						background-size: cover;
						position: absolute;
						top: 10%;
						left: -38px;
					}
				}
				a {
					width: calc(100% - 30px);
					color: #fff;
					background-color: $sosaiSeminarColor01;
					display: block;
					margin: 0 auto;
					font-size: 1rem;
					text-align: left;
					padding: 7px 0;
					line-height: 1.4;
					border-radius: 5px;
					box-shadow: 0px 3px 0px #20656e;
					span {
						font-size: 1.8rem;
						color: #fff;
						margin-bottom: 0;
						&:before {
							content: none;
						}
					}
				}
			}
		}
		&__bnr02 {
			padding: 0 15px;
			&__txt {
				margin-bottom: 11px;
				p {
					font-size: 1.8rem;
				}
			}
			&__box {
				display: block;
			}
			&__tel {
				width: 100%;
				border-right: none;
				padding: 14px 15px 17px;
				border-bottom: 1px solid $glaylight13;
				p {
					font-size: 1.6rem;
					@include mincyo;
					margin-bottom: 8px;
				}
				div {
					position: relative;
					width: 165px;
					margin: 0 auto;
					transform: translateX(20px);
					&:before {
						content: '';
						width: 25px;
						height: 25px;
						background: url(../img/common/icon_tel07.png) no-repeat;
						background-size: cover;
						position: absolute;
						top: 10px;
						left: -38px;
					}
				}
				a {
					display: block;
					margin: 0 auto;
					font-size: 1rem;
					color: #fff;
					background-color: $sosaiSeminarColor01;
					box-shadow: 0px 3px 0px #20656e;
					border-radius: 5px;
					text-align: left;
					padding: 7px 0;
					line-height: 1.4;
					span {
						font-size: 1.8rem;
						color: #fff;
						margin-bottom: 0;
						&:before {
							content: none;
						}
					}
				}
			}
			&__btn {
				width: 100%;
				padding: 15px 15px 17px;
				display: block;
				.c-sosaiBtn03 {
					font-size: 1.4rem;
					line-height: 1.2;
					max-width: 100%;
					a {
						display: block;
						padding: 15px 0 13px 0;
						&:before {
							content: none;
						}
						span {
							display: block;
							width: 170px;
							margin: 0 auto;
							position: relative;
							transform: translateX(20px);
							&:before {
								content: '';
								width: 24px;
								height: 19px;
								background-image: url(../img/common/icon_mail02.png);
								background-size: contain;
								position: absolute;
								top: 50%;
								left: -38px;
								transform: translateY(-50%);
							}
						}
					}
				}
			}
		}
		&__infomation {
			margin-bottom: 45px;
			padding: 0 15px;
			&__schedule {
				width: 100%;
				margin-bottom: 29px;
				h2 {
					padding: 6px 5px;
					font-size: 1.7rem;
				}
			}
			&__report {
				width: 100%;
				h2 {
					padding: 7px 5px 5px;
					font-size: 1.7rem;
				}
			}
			&__data {
				width: 80px;
				font-size: 1.4rem;
				margin-right: 20px;
			}
			.c-newsCate02, .c-newsCate03 {
				font-size: 1rem;
				height: 16px;
				line-height: 16px;
			}
			.c-newsCate02 {
				padding: 0 9px;
			}
			.c-newsCate03 {
				padding: 0 4px;
			}
			a {
				font-size: 1.4rem;
				line-height: 1.2;
			}
			p {
				font-size: 1.4rem;
				line-height: 1.2;
			}
			ul {
				li {
					padding: 14px 18px 13px;
					line-height: 1.2;
					> div {
						margin-bottom: 2px;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
