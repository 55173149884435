@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　海洋散骨
------------------------------------------------------------*/
.p-sosaiSea {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//海洋散骨
.p-sosaiSea01 {
	min-width: $baseWidth01;
	padding: 43px 0;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		margin: 0 0 5px 0;
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//海洋散骨 本文
.p-sosaiSea02 {
	margin-bottom: 129px;
	padding-top: 25px;
	&__content01 {
		min-height: 240px;
		padding-right: 444px;
		position: relative;
		> img {
			position: absolute;
			top: 8px;
			right: 0;
		}
		> p {
			margin-bottom: 30px;
			line-height: 3rem;
		}
	}
}

//海洋散骨のサービス内容
.p-sosaiSea03 {
	margin-bottom: 107px;
	padding: 107px 0 120px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 53px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	&__content01 {
		margin: 72px 0 40px 0;
		&__plan {
			dl {
				width: 540px;
				background-color: #ffffff;
				border: solid 1px $sosaiNaviColor01;
				dt {
					padding: 15px 10px 13px 64px;
					font-size: 2.4rem;
					font-weight: 500;
					line-height: 1.3;
					color: #ffffff;
					background-color: $sosaiNaviColor01;
					position: relative;
					@include mincyo;
					&:before {
						width: 30px;
						height: 30px;
						content: '';
						background-image: url("../img/sosai/icon_sosai01.png");
						-webkit-background-size: cover;
						background-size: cover;
						position: absolute;
						top: 14px;
						left: 20px;
					}
				}
				dd {
					padding: 38px 50px 50px;
					> * {
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			&__price {
				margin-bottom: 26px;
				font-size: 2.4rem;
				font-weight: 700;
				line-height: 1.2;
				display: flex;
				align-items: center;
				justify-content: space-around;

				@include mincyo;
				p {
					font-size: 2.2rem;
					display: inline-block;
					vertical-align: 15px;
				}
				em {
					font-size: 6.6rem;
					margin-right: 5px;
					line-height: 1;
					padding-top: 7px;
					display: inline-block;
					color: $sosaiNaviColor03;
					@media all and (-ms-high-contrast:none){
						transform: translateY(3px);
					}
				}
				span {
					margin: 0 2px;
					font-size: 2.7rem;
					font-weight: 500;
					display: inline-block;
				}
				&--sub {
					display: flex !important;
					align-items: center !important;
					font-size: 3.5rem !important;
					transform: translateX(-10px);
					.small {
						font-size: 2.7rem;
					}
				}
			}
			&__txt {
				margin-bottom: 23px;
				padding: 26px 10px 25px;
				font-size: 1.6rem;
				line-height: 2.4rem;
				border-top: dotted 1px $sosaiNaviColor01;
				border-bottom: dotted 1px $sosaiNaviColor01;
			}
			&__list {
				margin-bottom: 21px;
				li {
					margin-bottom: 8px;
					padding: 0 0 0 28px;
					font-size: 1.5rem;
					font-weight: 700;
					&:last-child {
						margin-bottom: 0;
					}
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 10px 0 -18px;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
			&__option {
				padding: 25px 31px 23px;
				background-color: $glaylight02;
				border: solid 1px $glaylight13;
				p {
					margin-bottom: 8px;
					font-size: 1.6rem;
					font-weight: 700;
				}
				ul {
					font-size: 1.4rem;
					font-weight: 700;
					li {
						width: 50%;
						&:before {
							width: 6px;
							height: 6px;
							margin: -4px 13px 0 0;
							content: '';
							background-color: $sosaiBtnColor01;
							display: inline-block;
							vertical-align: middle;
							@include radius(50%);
						}
					}
				}
			}
		}
	}
	&__content02 {
		padding: 27px 30px 25px;
		font-size: 1.2rem;
		background-color: $glaylight02;
		border: solid 1px $glaylight13;
		h3 {
			font-size: 1.2rem;
			margin-bottom: 11px;
			font-weight: 500;
		}
		ol {
			li {
				padding-left: 18px;
				font-weight: 700;
				margin-bottom: -1px;
				&:before {
					width: 19px;
					margin-left: -18px;
					content: counter(count01)'.';
					display: inline-block;
				}
			}
		}
	}
}

//海洋散骨の流れ
.p-sosaiSea04 {
	margin-bottom: 127px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiFlow01{
		li{
			padding: 0 55px 0 0;
			align-items: flex-start;
			&:nth-child(odd) {
			    min-height: 150px;
			}
			&:nth-child(2){
				p{
					padding: 44px 0 2px 0;
				}
			}
			&:nth-child(3){
				p{
					padding: 56px 0 8px 0;
				}
			}
			&:nth-child(4){
				p{
					padding: 45px 0 0 0;
				}
			}
			&:nth-child(5){
				p{
					padding: 44px 0 56px 0;
				}
			}
			&:nth-child(6){
				h3{
					display: block;
					padding: 51px 0 0 119px;
				}
				p{
					padding: 45px 0 0 0;
				}
			}
			&:after{
				bottom: -23px;
			}
			h3{
				padding: 62px 0 0 119px;
				span{
					display: block;
					font-size: 1.6rem;
				}
			}
			p{
				padding: 42px 0 48px;
			}
		}
	}
}

//お問い合わせ
.p-sosaiSea05 {
	margin-bottom: 130px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
	.c-sosaiAfterServiceContact{
		> div{
			padding: 42px 30px 39px;
			a{
				span{
					margin-left: -60px;
				}
			}
		}
	}
}

//葬儀後のサービス
.p-sosaiSea06 {
	padding: 107px 0 118px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 55px;
		span{
			&:after{
				margin: 5px auto 0;
			}
		}
	}
}


@include sp {
	.p-sosaiSea {
		font-size: 1.6rem;
	}
	//海洋散骨sp
	.p-sosaiSea01 {
		min-width: 100%;
		margin-bottom: 49px;
		padding: 15px 0;
		> span {
			font-size: 1.2rem;
		}
		.c-sosaiPagetitleCate{
			span{
				font-size: 1rem;
				line-height: 1.6rem;
				margin-top: 9px;
				margin-right: 0;
				height: 16px;
				width: 36px;
			}
		}
	}

	//海洋散骨 本文 sp
	.p-sosaiSea02 {
		margin-bottom: 0;
		padding: 0 20px;
		&__content01 {
			padding-right: 0;
			position:static;
			> img {
				margin: 0 auto 16px;
				display: block;
				position: static;
			}
			> p{
				font-size: 1.4rem;
				line-height: 1.7;
				margin-bottom: 0;
			}
		}
	}

	//海洋散骨のサービス内容 sp
	.p-sosaiSea03 {
		margin-bottom: 69px;
		padding: 48px 20px;
		margin-top: 40px;
		.c-sosaiTitle02b{
			margin-bottom: 25px;
			span{
				&:after{
					margin: 10px auto 0;
				}
			}
		}
		.u-txtCenter{
			font-size: 1.4rem;
			line-height: 1.7;
			text-align: left;
		}
		&__content01 {
			margin: 18px 0 20px 0;
			&__plan {
				dl {
					width: 100%;
					margin-bottom: 15px;
					dt {
						padding: 0px 0px 0 7px;
						font-size: 1.2rem;
						text-align: center;
						height: 49px;
						display: flex;
						align-items: center;
						justify-content: center;
						&:before {
						    width: 15px;
						    height: 15px;
						    display: inline-block;
						    position: static;
						    margin-right: 7px;
						    //margin-bottom: -3px;
						}
					}
					dd {
						padding: 15px;
					}
				}
				&__price {
					margin-bottom: 14px;
					font-size: 1.2rem;
					text-align: center;
					padding-right: 25px;
					p {
						font-size: 1.1rem;
						vertical-align: 6px;
					}
					em {
						font-size: 3.3rem;
						margin-right: 2px;
						padding-top: 0;
					}
					span {
					    font-size: 2rem;
					}
					&--sub {
						font-size: 2.4rem !important;
						transform: translateX(0);

						.small {
							font-size: 2rem;
						}
					}
				}
				&__txt {
					margin-bottom: 10px;
					padding: 11px 5px 10px;
					font-size: 1.4rem;
					line-height: 1.7;
				}
				&__list {
					margin-bottom: 13px;
					li {
						margin-bottom: 4px;
						padding: 0 0 0 22px;
						font-size: 1.4rem;
						&:before {
							margin: -3px 7px 0 -13px;
						}
					}
				}
				&__option {
					padding: 17px 19px 9px;
					margin: 0 4px;
					p {
						margin-bottom: 10px;
						font-size: 1.4rem;
						margin-bottom: 6px;
					}
					ul {
						font-size: 1.4rem;
						line-height: 2.2rem;
						font-weight: 700;
						li {
							float: none;
							width: 100%;
							margin-bottom: 4px;
							&:before {
								margin: -4px 5px 0 0;
							}
						}
					}
				}
			}
		}
		&__content02 {
			padding: 15px 20px 14px;
			background-color: $glaylight02;
			border: solid 1px $glaylight13;
			h3 {
				font-size: 1.4rem;
				line-height: 1.7;
				margin-bottom: 12px;
				font-weight: 500;
			}
			ol {
				li {
					padding-left: 20px;
					font-weight: 700;
					font-size: 1.4rem;
					line-height: 1.7;
					margin-bottom: 0px;
					&:before {
						width: 20px;
						margin-left: -20px;
						content: counter(count01)'.';
						display: inline-block;
					}
				}
			}
		}
	}


	//海洋散骨の流れ sp
	.p-sosaiSea04 {
		margin-bottom: 60px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiFlow01{
			li{
				padding: 22px 12px 10px 56px;
				&:nth-child(odd) {
				    min-height: auto;
				}
				&:nth-child(2){
					padding: 22px 12px 10px 51px;
					h3{
						img{
							left: -36px;
						}
					}
					p{
						padding: 0 0 0 5px;
					}
				}
				&:nth-child(3){
					p{
						padding: 0;
					}
				}
				&:nth-child(4){
					p{
						padding: 0;
					}
				}
				&:nth-child(5){
					padding: 25px 12px 10px 56px;
					h3{
						padding: 0 0 8px;
					}
					p{
						padding: 0;
					}
				}
				&:nth-child(6){
					padding: 18px 12px 17px 56px;
					h3{
						padding: 0 0 8px;
						span{
							font-size: 1rem;
						}
					}
					p{
						padding: 0;
					}
				}
				h3{
					padding: 0 0 8px;
				}
				p{
					padding: 0;
				}
			}
		}
	}

	//お問い合わせ sp
	.p-sosaiSea05 {
		margin-bottom: 50px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 29px;
			span{
				&:after{
					margin: 12px auto 0;
				}
			}
		}
		.c-sosaiAfterServiceContact{
			> div{
				padding: 20px 19px 19px;
			}
		}
	}

	//葬儀後のサービス sp
	.p-sosaiSea06 {
		padding: 47px 20px 55px;
	    margin-bottom: 128px;
		.c-sosaiTitle02b{
			margin-bottom: 35px;
			span{
				&:after{
					margin: 10px auto 0;
				}
			}
		}
	}

}

/*
------------------------------------------------------------*/
