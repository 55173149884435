@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　改葬
------------------------------------------------------------*/
.p-sosaiReburial {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
}

//改葬
.p-sosaiReburial01 {
	min-width: $baseWidth01;
	padding: 43px 0 47px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		margin: 0 0 1px 0;
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//改葬 本文
.p-sosaiReburial02 {
	margin: 25px 0 125px;
	&__content01 {
		min-height: 240px;
		padding-right: 445px;
		position: relative;
		> img {
			position: absolute;
			top: 7px;
			right: 0;
		}
		> p {
			line-height: 1.8;
			margin-bottom: 30px;
		}
	}
}

//改葬を検討すべきタイミングは？
.p-sosaiReburial03 {
	margin-bottom: 106px;
	padding: 107px 0 113px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	&__content01 {
		padding-top: 6px;
		ul {
			margin-bottom: 34px;
			li {
				width: 33.333333%;
				height: 124px;
				position: relative;
				font-size: 1.6rem;
				font-weight: 700;
				background-color: #ffffff;
				display: table;
				&:after {
					width: 10px;
					height: 10px;
					content: '';
					background-image: url("../img/common/icon_sankaku_left_top_brown01.png");
					-webkit-background-size: cover;
					background-size: cover;
					position: absolute;
					top: 2px;
					left: 2px;
				}
				&:nth-child(1) {
					height: 96px;
					border: solid 1px $glaylight13;
				}
				&:nth-child(2), &:nth-child(3) {
					height: 96px;
					border-top: solid 1px $glaylight13;
					border-bottom: solid 1px $glaylight13;
					border-right: solid 1px $glaylight13;
				}
				&:nth-child(4) {
					border-bottom: solid 1px $glaylight13;
					border-left: solid 1px $glaylight13;
					border-right: solid 1px $glaylight13;
				}
				&:nth-child(5), &:nth-child(6) {
					border-bottom: solid 1px $glaylight13;
					border-right: solid 1px $glaylight13;
				}
				p {
					padding: 0 40px;
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
	}
	&__txt{
		text-align: center;
	}
}

//改葬の流れ
.p-sosaiReburial04 {
	margin-bottom: 116px;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
	.c-sosaiFlow01 {
		margin-bottom: 52px;
		>li{
			&:after{
				bottom: -23px;
			}
			p{
				padding: 60px 0 61px;
			}
			&:nth-child(3) p{
				padding: 56px 0 0;
			}
			&:nth-child(4) p{
				padding: 41px 0 48px;
			}
			&:nth-child(5) p,{
				padding: 44px 0 41px;
			}
			&:nth-child(6) p,{
				padding: 44px 0 41px;
			}
			&:nth-child(7), &:nth-child(8){
				p{
					padding: 56px 0 0;
				}
			}
			&:nth-child(4){
				h3{
					padding-top: 107px;
				}
				img{
					top: 95px;
				}
			}
			&:nth-child(5), &:nth-child(6), &:nth-child(8){
				h3{
					padding-top: 48px;
				}
			}
			&:nth-child(7){
				h3{
					padding-top: 59px;
				}
			}
			&:nth-child(6) h3 span{
				font-size: 1.6rem;
			}
		}
	}
	&__txt{
		line-height: 1.8;
		text-align: center;
	}
}

//お問い合わせ
.p-sosaiReburial05 {
	margin-bottom: 130px;
	.c-sosaiTitle02b{
		margin-bottom: 60px;
	}
}

//葬儀後のサービス
.p-sosaiReburial06 {
	padding: 105px 0 118px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
}


@include sp {
	.p-sosaiReburial {
		font-size: 1.4rem;
	}
	//改葬sp
	.p-sosaiReburial01 {
		min-width: 100%;
		margin-bottom: 48px;
		padding: 15px 0;
		> span {
			font-size: 1.2rem;
			margin: 0 0 6px 0;
		}
		.c-sosaiPagetitleCate{
			padding-top: 4px;
			span{
				font-size: 1rem;
				line-height: 15px;
    			padding: 1px 3px 0;
			}
			&__Kanagawa{
				width: auto;
				height: auto;
			}
		}
	}

	//改葬 本文 sp
	.p-sosaiReburial02 {
		margin-bottom: 42px;
		padding: 0 20px;
		&__content01 {
			padding-right: 0;
			position:static;
			> img {
				margin: 0 auto 17px;
				display: block;
				position: static;
			}
			p{
				line-height: 1.6;
			}
		}
	}
	//改葬を検討すべきタイミングは？ sp
	.p-sosaiReburial03 {
		margin-bottom: 47px;
		padding: 50px 20px 45px;
		&__content01 {
			ul {
				margin-bottom: 27px;
				li {
					width: 100% !important;
					height: auto !important;
					font-size: 1.3rem;
					line-height: 1.5;
					display: inherit;
					border: solid 1px $glaylight13;
					border-bottom: none !important;
					&:last-child{
						border-bottom: solid 1px $glaylight13 !important;
					}
					p {
						padding: 15px 20px;
						display: inherit;
					}
				}
			}
		}
		&__txt{
			text-align: left;
			line-height: 1.6;
		}
	}

	//改葬の流れ sp
	.p-sosaiReburial04 {
		margin-bottom: 70px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
		}
		.c-sosaiFlow01 {
			margin-bottom: 36px;
			>li{
				min-height: auto;
				padding: 20px 15px 11px 55px;
				p{
				    font-size: 1.3rem;
				    line-height: 1.6;
				    padding: 8px 0 0;
				}
				h3{
					padding: 0;
				}
				&:nth-child(1) {
	    			padding: 21px 15px 12px 55px;
				}
				&:nth-child(4) {
	    			padding: 21px 15px 15px 55px;
				}
				&:nth-child(2), &:nth-child(3),&:nth-child(4),&:nth-child(5),
				&:nth-child(6), &:nth-child(7), &:nth-child(8){
	    			p{
	    				padding: 9px 0 0;
	    			}
	    			h3{
						padding: 0;
					}
				}
				&:nth-child(8) {
	    			padding: 21px 15px 16px 55px;
				}
				&:nth-child(4) h3 img{
	    			top: -8px;
				}
			}
		}
		&__txt{
			text-align: left;
		    line-height: 1.6;
		    padding: 0 5px;
		}
	}

	//お問い合わせ sp
	.p-sosaiReburial05 {
		margin-bottom: 48px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 30px;
		}
	}

	//葬儀後のサービス sp
	.p-sosaiReburial06 {
		padding: 50px 20px 58px;
		margin-bottom: 60px;
		.c-sosaiAfterServiceLink li{
			padding: 20px 5px 5px;
			div{
				padding: 6px 0 6px 0;
			}
		}
	}
}

/*
------------------------------------------------------------*/
