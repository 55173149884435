@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　霊園
------------------------------------------------------------*/
.p-sosaiCemetery {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.75;
	color: $gray08;
}

//霊園
.p-sosaiCemetery01 {
	min-width: $baseWidth01;
	padding: 42px 0 45px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		margin: 0 0 2px 0;
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//霊園 本文
.p-sosaiCemetery02 {
	margin: 25px 0 130px;
	&__content01 {
		min-height: 240px;
		padding-right: 445px;
		position: relative;
		> img {
			position: absolute;
			top: 7px;
			right: 0;
		}
		> p {
			margin-bottom: 30px;
			line-height: 1.8;
		}
	}
}

//霊園・墓地の形態
.p-sosaiCemetery03 {
	margin-bottom: 106px;
	padding: 103px 0 120px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle09 {
		margin-bottom: 19px;
	}
	&__txt{
		text-align: center;
	}
	&__content01 {
		margin: 69px 0 70px;
		padding-right: 440px;
		position: relative;
		> img {
			position: absolute;
			top: 3px;
			right: 0;
		}
	}
	&__content02 {
		padding-right: 445px;
		position: relative;
		> img {
			position: absolute;
			top: 90px;
			right: 0;
		}
		> p {
			margin-bottom: 34px;
		}
		.c-sosaiTextBox01 {
			width: 660px;
			background-color: #ffffff;
			>div{
				padding: 34px 42px 25px;
			}
			h4 {
				margin-bottom: 18px;
				font-size: 1.8rem;
				font-weight: 700;
			}
			ul {
				li {
					margin-bottom: 7px;
					padding-left: 18px;
					font-size: 1.6rem;
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 10px 0 -18px;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

//霊園選びからお墓ができるまでの流れ
.p-sosaiCemetery04 {
	margin-bottom: 127px;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
	.c-sosaiFlow01 li{
		&:nth-child(5) p{
			padding: 59px 0 0 ;
		}
		&:after{
			bottom: -24px;
		}
	}
}

//お問い合わせ
.p-sosaiCemetery05 {
	margin-bottom: 130px;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
	.c-sosaiAfterServiceContact{
		>div{
			a span{
				margin-left: -50px;
			}
		}
	}
}

//葬儀後のサービス
.p-sosaiCemetery06 {
	padding: 105px 0 118px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
}



@include sp {
	.p-sosaiCemetery {
		font-size: 1.4rem;
	}
	//霊園 sp
	.p-sosaiCemetery01 {
		min-width: 100%;
		margin-bottom: 50px;
		padding: 15px 0;
		> span {
			font-size: 1.2rem;
			margin: 0 0 7px 0;
		}
		.c-sosaiPagetitleCate {
			margin-top: 3px;
			span{
				width: 35px;
				height: auto;
				font-size: 1rem;
				line-height:  15px;
				padding: 1px 0 0;
			}
		}
	}

	//霊園 本文 sp
	.p-sosaiCemetery02 {
		margin-bottom: 42px;
		padding: 0 20px;
		&__content01 {
			padding-right: 0;
			position:static;
			> img {
				margin: 10px auto 17px;
				display: block;
				position: static;
			}
			p{
				line-height: 1.6;
			}
		}
	}

	//霊園・墓地の形態 sp
	.p-sosaiCemetery03 {
		margin-bottom: 46px;
		padding: 50px 20px;
		&__txt{
			text-align: left;
			line-height: 1.6;
		}
		.c-sosaiTitle09{
			margin-bottom: 16px;
		}
		&__content01 {
			margin: 32px 0 31px 0;
			padding-right: 0;
			position:static;
			> img {
				margin: 10px auto 17px;
				display: block;
				position: static;
			}
			p{
				line-height: 1.6;
			}
		}
		&__content02 {
			padding-right: 0;
			position:static;
			> img {
				margin: 10px auto 17px;
				display: block;
				position: static;
			}
			> p {
				margin-bottom: 25px;
				line-height: 1.6;
			}
			.c-sosaiTextBox01 {
				width: 100%;
				background-color: #ffffff;
				>div{
					padding: 15px 20px 5px;
				}
				h4 {
					margin-bottom: 11px;
					font-size: 1.3rem;
				}
				ul {
					li {
						padding-left: 13px;
						font-size: 1.3rem;
						line-height: 1.6;
						margin-bottom: 10px;
						&:before {
							margin: -3px 8px 0 -13px;
						}
					}
				}
			}
		}
	}

	//霊園選びからお墓ができるまでの流れ sp
	.p-sosaiCemetery04 {
		margin-bottom: 78px;
		padding: 0 15px;
		.c-sosaiTitle02b {
		    margin-bottom: 39px;
		}
		.c-sosaiFlow01 li{
			&:nth-child(1){
				padding: 21px 15px 7px 55px;
			}
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6){
				padding: 20px 15px 10px 55px;
				p{
					padding: 0;
				}
			}
		}
	}

	//お問い合わせ sp
	.p-sosaiCemetery05 {
		margin-bottom: 48px;
		padding: 0 15px;
		.c-sosaiTitle02b {
		    margin-bottom: 30px;
		}
	}

	//葬儀後のサービス sp
	.p-sosaiCemetery06 {
	    padding: 50px 20px 59px;
	    margin-bottom: 59px;
		.c-sosaiAfterServiceLink li {
		    margin-bottom: 11px;
		    padding: 22px 5px 5px;
		}
	}
}

/*
------------------------------------------------------------*/
