@use '../0_base' as *;

.c-header08 {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    @include pc {
        padding-top: 30px;
        padding-bottom: 30px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }
    &__logo {
        display: flex;
        justify-content: center;
        img {
            width: auto;
            height: 21.5px;
            @include pc {
                height: 41px;
            }
        }
    }
}
