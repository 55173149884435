@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　喪主・遺族側の基礎知識
------------------------------------------------------------*/
.p-sosaiKnowledgeHost {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.8;
	color: $gray08;
}

//喪主・遺族側の基礎知識
.p-sosaiKnowledgeHost01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//喪主・遺族側の基礎知識 本文
.p-sosaiKnowledgeHost02 {
	margin: 23px 0 107px;
	&__txt{
		text-align: center;
	}
	.c-list03 {
		margin-top: 71px;
	}
}

@include sp {
	.p-sosaiKnowledgeHost {
		font-size: 1.3rem;
	}
	//喪主・遺族側の基礎知識 sp
	.p-sosaiKnowledgeHost01 {
		min-width: 100%;
		margin-bottom: 37px;
		padding: 32px 0 33px;
	}

	//喪主・遺族側の基礎知識 本文 sp
	.p-sosaiKnowledgeHost02 {
		margin-bottom: 49px;
		padding: 0 15px;
		&__txt{
			font-size: 1.3rem;
			line-height: 1.6;
			text-align: left;
			padding: 0 5px;
		}
		.c-list03 {
			margin-top: 35px;
			padding: 0;
			&__item{
				width: 100%;
				margin: 0 0 10px 0;
				&:nth-child(1) ,&:nth-child(2){
					.c-list03__lable{
					    width: 41%;
    					padding: 12px 0;
    				}
				}
				&:nth-child(3) ,&:nth-child(4){
					.c-list03__lable{
					    width: 59%;
    					padding: 12px 0;
    				}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
