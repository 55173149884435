@use '../0_base' as *;

/*------------------------------------------------------------
icon
------------------------------------------------------------*/
.c-cateIcon01 {
	width: 60px;
	height: 60px;
	display: block;
	background-repeat: no-repeat;
	-webkit-background-size: 66%;
	background-size: 66%;
	background-position: center center;
	@include radius(50%);
	transition: 0.3s ease-in-out;
	&.c-cateIcon01--ticket {
		background-color: $ticketColor01;
		background-image: url("../img/common/icon_ticket01.png");
		+ a, +strong {
			color: $ticketColor02;
		}
	}
	&.c-cateIcon01--hoken {
		background-color: $hokenColor01;
		background-image: url("../img/common/icon_hoken01.png");
		+ a, +strong {
			color: $hokenColor02;
		}
	}
	&.c-cateIcon01--reform {
		background-color: $reformColor01;
		background-image: url("../img/common/icon_reform01.png");
		+ a, +strong {
			color: $reformColor02;
		}
	}
	&.c-cateIcon01--kurashi {
		background-color: $kurashiColor01;
		background-image: url("../img/common/icon_kurashi01.png");
		+ a, +strong {
			color: $kurashiColor02;
		}
	}
	&.c-cateIcon01--sosai {
		background-color: $sosaiColor01;
		background-image: url("../img/common/icon_sosai01.png");
		+ a, +strong {
			color: $sosaiColor02;
		}
	}
	&.c-cateIcon01--kurashi-enquete {
		background-color: $kurashiColor01;
		background-image: url("../img/common/icon_kurashi01.png");
		+ a, +strong {
			color: $kurashiColor02;
		}
	}
}


@include sp {
	.c-cateIcon01 {
		width: 40px;
		height: 40px;
		-webkit-background-size: 80%;
		background-size: 80%;
	}
	
}


/*
------------------------------------------------------------*/
