@use '../../0_base' as *;

/*------------------------------------------------------------
/service/hoken/enquete/complete.php
------------------------------------------------------------*/
.p-hokenEnqueteComplete {
	padding: 41px 0 199px;

	@include sp {
		padding: 19px 20px 106px;
	}

	&1 {
		// .p-hokenEnqueteComplete1 .c-step3 .
		.c-step3 {
			margin-top: 43px;
			margin-bottom: 134px;

			@include sp {
				margin-top: 20px;
				margin-bottom: 59px;
			}
		}
	}
}

.page-hoken_enquete_complete {
	// display: flex;
	// flex-direction: column;
	// min-height: 100vh;

	// footer {
	// 	margin-top: auto;
	// }
}
