@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　組合員の声
------------------------------------------------------------*/
.p-sosaiVoice {
	background-color: #ffffff;
	font-size: 1.8rem;
	line-height: 1.77;
	color: $gray08;
}
//組合員の声
.p-sosaiVoice01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//組合員の声 本文
.p-sosaiVoice02 {
	margin-bottom: 120px;
	padding-top: 22px;
	&__txt{
		text-align: center;
	}
	&__content01 {
		margin-top: 70px;
		&__box {
			min-height: 265px;
			margin-bottom: 40px;
			padding: 40px 480px 42px 40px;
			border: solid 1px $glaylight07;
			position: relative;
			&__title {
				margin-bottom: 24px;
				color: $sosaiNaviColor02;
				&__icon {
					width: 80px;
					margin-right: 21px;
				}
				&__txt {
					width: calc(100% - 95px);
					padding-top: 7px;
					p {
						font-size: 1.5rem;
						font-weight: 700;
						span {
							font-size: 1.2rem;
							color: #ffffff;
							background: #2e95a3;
						    margin-left: 15px;
						    padding: 4px 12px 2px;
						}
					}
					h2 {
						font-size: 2.6rem;
						font-weight: 500;
						line-height: 1.4;
						margin-top: 8px;
						@include mincyo;
					}
				}
			}
			&__txt {
				font-size: 1.7rem;
				img {
					position: absolute;
					top: 40px;
					right: 40px;
				}
			}
		}
	}
}

//関連コンテンツ
.p-sosaiVoice03 {
	padding: 106px 0 140px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b {
	    margin-bottom: 60px;
	}
}


@include sp {
	.p-sosaiVoice {
		font-size: 1.4rem;
	}
	//組合員の声　sp
	.p-sosaiVoice01 {
		min-width: 100%;
		margin-bottom: 46px;
		padding: 32px 0 33px;
	}
	//組合員の声 本文
	.p-sosaiVoice02 {
		margin-bottom: 50px;
		padding: 0 15px;
		&__txt{
			text-align: left;
			padding: 0 5px;
		}
		&__content01 {
			margin-top: 20px;
			&__box {
				margin-bottom: 20px;
				padding: 19px 19px 15px;
				position: static;
				&__title {
					margin-bottom: 16px;
					position: relative;
					&__icon {
						width: 48px;
						position: absolute;
						top: 0;
						left: 0;
					}
					&__txt {
						width: 100%;
						padding-top: 0;
						p {
							font-size: 1.3rem;
							padding-left: 60px;
							span {
								font-size: 1rem;
								padding: 2px 11px 1px;
								margin-left: 0;
							}
						}
						h2 {
							font-size: 1.8rem;
							line-height: 1.2;
							margin-top: 13px;
						}
					}
				}
				&__txt {
					font-size: 1.3rem;
					line-height: 1.6;
					img {
						margin: 0 auto 15px auto;
						display: block;
						position: static;
					}
				}
			}
		}
	}
	//関連コンテンツ sp
	.p-sosaiVoice03 {
		padding: 50px 20px;
		margin-bottom: 55px;
		overflow: hidden;
		.c-sosaiTitle02b {
		    margin-bottom: 40px;
		}
	}
}

/*
------------------------------------------------------------*/
