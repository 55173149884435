@use '../../0_base' as *;

/*------------------------------------------------------------
はじめてご利用される方へ
------------------------------------------------------------*/
.p-serviceGuide {
	padding: 52px 0 87px;
	@include sp {
		padding: 85px 20px 100px;
	}
	.c-breadcrumb1{
		padding-bottom: 32px;
		a {
			color: $linkColor01;
		}
	}
	&__title {
		margin-bottom: 47px;
		text-align: center;
		font-size: 3.2rem;
		color: $gray01;
		line-height: 1.35;
		@include sp {
			margin-bottom: 30px;
			font-size: 2.1rem;
		}
		span {
			display: block;
			font-size: 1.4rem;
			font-weight: normal;
			opacity: 0.5;
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
	&__title02 {
		font-size: 2.4rem;
		color: #fff;
		background: #a4c661;
		padding: 16px 29px 15px;
		margin-bottom: 21px;
		@include sp {
			width: calc(100% + 40px);
			margin-left: -20px;
			font-size: 1.9rem;
			line-height: 1.6;
			padding: 13px 20px 12px;
			margin-bottom: 20px;
		}
	}
	&__title03 {
		font-size: 2rem;
		color: #669900;
		border-bottom: 2px solid #a4c661;
		padding-bottom: 14px;
		margin-bottom: 25px;
		@include sp {
			font-size: 1.8rem;
			padding-bottom: 5px;
			margin-bottom: 15px;
		}
	}
	&__title04 {
		font-size: 2rem;
		color: #669900;
		border-bottom: 2px solid #a4c661;
		position: relative;
		padding: 0 0 0 15px;
		margin-bottom: 23px;
		line-height: 2.2;
		@include sp {
			font-size: 1.8rem;
			line-height: 1.6;
			padding: 0 0 0 10px;
			margin-bottom: 20px;
		}
		&:before {
			content: '';
			width: 6px;
			height: 30px;
			background: #a4c661;
			position: absolute;
			top: 7px;
			left: 0;
			@include sp {
				top: 4px;
				width: 4px;
				height: 20px;
			}
		}
	}
	&__txtimg {
		margin-bottom: 60px;

    @include clear;

		@include sp {
			margin-bottom: 40px;
		}
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			margin-bottom: 30px;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
				margin-bottom: 20px;
			}
		}
		span {
			color: $strongColor01;
		}
		img {
			float: right;
			margin: 9px 0 0 25px;
			@include sp {
				float: none;
				display: block;
				margin: 0 auto 20px;
			}
		}
	}
	&__txt {
		margin-bottom: 20px;
		p {
			font-size: 1.6rem;
			color: $gray01;
			line-height: 2;
			@include sp {
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}
	&__txt02 {
		font-size: 1.6rem;
		font-weight: bold;
		color: $gray01;
		margin-bottom: 8px;
		@include sp {
			font-size: 1.4rem;
		}
	}
	&__list {
		li {
			font-size: 1.6rem;
			color: $gray01;
			padding-left: 19px;
			position: relative;
			margin-bottom: 7px;
			@include sp {
				font-size: 1.4rem;
				padding-left: 12px;
			}
			&:before {
				content: '';
				width: 8px;
				height: 8px;
				background: #669900;
				border-radius: 100px;
				position: absolute;
				top: 7px;
				left: 0;
				@include sp {
					width: 6px;
					height: 6px;
				}
			}
		}
		a {
			color: $linkColor01;
			text-decoration: underline;
		}
	}
	&__box {
		background: $glaylight03;
		padding: 34px 40px 26px;
		margin-bottom: 60px;
		@include sp {
			padding: 14px 20px 13px;
			margin-bottom: 30px;
		}
	}
	&__frame {
		display: flex;
		> div {
			width: 50%;
			padding: 14px 0 1px 20px;
			border-bottom: 1px solid #fff;
			@include sp {
				padding: 10px 10px 0;
			}
			&:nth-child(1) {
				border-right: 1px solid $glaylight05;
			}
		}
	}
	&__table {
		margin-bottom: 30px;
		dl {
			display: flex;
			@include sp {
				display: block;
				border: 1px solid $glaylight05;
				margin-bottom: 20px;
			}
			&:nth-child(1) {
				dd {
					padding: 0;
				}
			}
			&:last-of-type {
				dd {
					border-bottom: 1px solid $glaylight05;
					@include sp {
						border-bottom: none;
					}
				}
			}
		}
		dt {
			width: 422px;
			height: auto;
			background: #f5efd9;
			border-bottom: 1px solid #fff;
			padding-left: 20px;
			@include sp {
				width: 100%;
				padding: 8px 0 9px;
			}
			span {
				height: 100%;
				display: flex;
				align-items: center;
				font-size: 1.6rem;
				font-weight: bold;
				color: $gray01;
				@include sp {
					font-size: 1.4rem;
					justify-content: center;
				}
			}
		}
		dd {
			width: calc(100% - 422px);
			border-top: 1px solid $glaylight05;
			border-right: 1px solid $glaylight05;
			padding: 17px 0 3px 20px;
			@include sp {
				width: 100%;
				border: none;
			}
		}
		.p-serviceGuide__list li {
			margin-bottom: 7px;
			min-height: 26px;
			@include sp {
				min-height: 22px;
				margin-bottom: 5px;
			}
		}
	}
	&__btn {
		margin-bottom: 30px;
		a {
			padding-right: 10px;
			text-align: center;
			width: 500px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.6rem;
			font-weight: bold;
			color: $gray01;
			border-radius: 50px;
			border: 1px solid $keycolor02;
			background: #fff;
			position: relative;
			@include sp {
				width: 100%;
				height: auto;
				font-size: 1.4rem;
				padding: 10px 40px 10px 20px;
				line-height: 1.5;
			}
			&:after {
				content: '';
				width: 7px;
				height: 12px;
				background: url("../img/common/icon_arrow-green04.png");
				background-size: contain;
				position: absolute;
				top: 18px;
				right: 21px;
				transition: 0.3s ease-in-out;
				@include sp {
					top: auto;
				}
			}
			&:hover {
				color: #fff;
				background: $keycolor02;
				&:after {
					background: url("../img/common/icon_arrow-white02.png");
					background-size: contain;
				}
			}
		}
	}
	&__listBtn {
		margin: -10px 0 31px;
		@include sp {
			margin: 0 0 30px;
			display: flex;
			flex-wrap: wrap;
		}
		li {
			display: inline-block;
			margin: 0 8px 8px 0;
			@include sp {
				margin: 0 4px 15px 0;
				width: 100%;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		a {
			width: 240px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.6rem;
			font-weight: bold;
			color: #669900;
			border-radius: 50px;
			border: 3px solid $glaylight01;
			position: relative;
			@include sp {
				padding-right: 20px;
				width: 100%;
				font-size: 1.4rem;
			}
			&:after {
				content: '';
				width: 7px;
				height: 12px;
				background: url("../img/common/icon_arrow-green04.png");
				background-size: contain;
				position: absolute;
				top: 15px;
				right: 17px;
				transition: 0.3s ease-in-out;
				@include sp {
					top: auto;
				}
			}
		}
	}
}