@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭 神奈川県にお住まいの方
------------------------------------------------------------*/
.p-sosaiServiceKanagawa {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.77;
	color: $gray08;
}

//コープの神奈川県にお住まいの方
.p-sosaiServiceKanagawa01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//コープ神奈川のお葬式「ゆきげ葬」
.p-sosaiServiceKanagawa02 {
	margin: 18px 0 120px;
	&__txt01 {
		margin-bottom: 57px;
		font-size: 2.2rem;
		font-weight: bold;
		line-height: 1.45;
		text-align: center;
	}
	&__txt02 {
		&__img {
			width: 540px;
			margin: 9px 0 0;
		}
		&__txt {
			width: 540px;
			font-size: 1.7rem;
		}
	}
}

//ゆきげ葬が提案する「安心の葬祭サービス」
.p-sosaiServiceKanagawa03 {
	margin-bottom: 105px;
    padding: 106px 0 90px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	&__listBox{
		padding-top: 53px;
		li {
			width: 353px;
			margin: 0 30px 70px 0;
			padding: 34px 40px;
			background-color: #ffffff;
			border: solid 2px $sosaiNaviColor02;
			&:nth-child(3n) {
				margin: 0 0 70px 0;
			}
			span {
				margin-top: -83px;
				margin-bottom: 28px;
				display: block;
				text-align: center;
			}
			.listBox-txt01{
				color: #197e7e;
				font-size: 2.6rem;
				text-align: center;
				line-height: 1.3;
				margin-bottom: 27px;
				padding:0 30px;
				@include mincyo();
			}
			.listBox-txt02 {
				text-align: left;
				line-height: 1.7;
				img {
					width: 100%;
					max-width: 470px;
					margin: 0 auto 10px;
					display: block;
				}
				b {
					font-weight: bold;
				}
			}
		}
	}
	.c-list03 {
		margin: 16px auto 0;
		&__lable {
			white-space: nowrap;
		}
	}
}

//ゆきげ神奈川からのお知らせ
.p-sosaiServiceKanagawa04 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		margin-bottom: 48px;
	}
	&__newsList {
		li {
			padding: 22px 0 18px;
			border-bottom: solid 1px $glaylight01;
			&:last-child {
				border: none;
			}
			a {
				width: 835px;
				color: $gray01;
				font-size: 1.7rem;
				font-weight: 500;
				line-height: 1.5;
				text-decoration: underline;
				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
		}
		&_data {
			width: 115px;
			font-size: 1.7rem;
			color: $gray03;
			@include mincyo;
		}
		.c-newsCate02 {
			width: 60px;
			margin: 4px 5px 0 0;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.c-newsCate03 {
			width: 70px;
			margin: 4px 30px 0 0;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.c-sosaiBtn01 {
		width: 175px;
		margin-top: 25px;
		font-weight: bold;
	}
}

//コープの終活応援！お葬式勉強会
.p-sosaiServiceKanagawa05 {
	padding: 104px 0 140px;
	background-color: $subColor10;
	&__txt01 {
		line-height: 1.9;
		margin-bottom: 91px;
		text-align: center;
		b {
			font-weight: bold;
		}
		a {
      color: #46483c;
      text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	&__infomation {
		margin-bottom: 30px;
		&__schedule {
			width: 545px;
			background-color: #ffffff;
			h2 {
				padding: 7px;
				font-size: 2.0rem;
				font-weight: bold;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: center;
				@include mincyo;
			}
		}
		&__report {
			width: 545px;
			background-color: #ffffff;
			h2 {
				padding: 7px;
				font-size: 2.0rem;
				font-weight: bold;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: center;
				@include mincyo;
			}
		}
		&__data {
			width: 105px;
			height: 20px;
			font-size: 1.7rem;
			font-weight: bold;
			line-height: 1.17;
			color: $gray03;
			@include mincyo;
		}
		.c-newsCate02, .c-newsCate03 {
			width: auto;
			padding: 0 11px;
			line-height: 20px;
			margin-right: 5px;
			@include sp{
				height: 15px;
			    font-size: 1rem;
			    line-height: 15px;
			    padding: 0 5px;
			}
		}
		a {
			font-size: 1.7rem;
			color: $gray03;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
		ul {
			li {
				padding: 30px 30px 23px;
				border-bottom: dotted 1px $gray07;
				> div {
					margin-bottom: 16px;
					@include sp{
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&__btn {
		margin: 0 auto;
		display: table;
		&__grbtn01{
			margin-bottom: 50px;
			display: flex;
    		justify-content: center;
		}
		&__fb {
			margin-right: 19px;
			a{
				width: 355px;
				color: #4267b2;
				font-size: 1.6rem;
				font-weight: bold;
				padding: 10px 10px 12px 29px;
				display: inline-flex;
    			align-items: center;
    			border-radius: 3px;
				border: 2px solid #4267b2;
				background: #fff;
				img{
					float: left;
					margin-right: 16px;
				}
				p{
					line-height: 1.4;
					@include yu_gosick();
				}
				span{
					font-size: 1.2rem;
					font-weight: normal;
				}
			}
		}
		&__btn02{
			a{
				width: 355px;
				height: 70px;
				padding: 19px 0;
				display: inline-flex;
    			align-items: center;
    			justify-content: center;
				color:#faad5a;
				font-size: 1.9rem;
				font-weight: bold;
				background: #fff;
				border-radius: 3px;
				border: 2px solid #faad5a;
				span{
					font-size: 1.2rem;
				}
			}
		}
		.c-sosaiBtn05 {
			margin-right: 20px;
			text-align: center;
			float: left;
			padding: 25px 18px;
		}
		.c-sosaiBtn03 {
			text-align: center;
			float: right;
		}
	}
}

@include sp {
	// .p-sosaiServiceKanagawa {
	// 	font-size: 1.6rem;
	// }

	//コープの神奈川県にお住まいの方 sp
	.p-sosaiServiceKanagawa01 {
		min-width: 100%;
		margin-bottom: 33px;
		padding: 32px 0 33px;
	}

	//コープ神奈川のお葬式「ゆきげ葬」
	.p-sosaiServiceKanagawa02 {
		margin-bottom: 45px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 50px;
		}
		&__txt01 {
			margin-bottom: 27px;
			font-size: 1.4rem;
			text-align: left;
		}
		&__txt02 {
			&__img {
				width: 100%;
				margin-bottom: 27px;
			}
			&__txt {
				width: 100%;
				font-size: 1.4rem;
				line-height: 1.6;
			}
		}
	}

	//ゆきげ葬が提案する「安心の葬祭サービス」 sp
	.p-sosaiServiceKanagawa03 {
		margin-bottom: 32px;
		padding: 46px 0 50px;
		&__listBox {
			margin-bottom: 39px;
			padding: 20px 20px 0;
			li {
				width: 100%;
				height: auto;
				margin-bottom: 39px;
				padding: 13px 20px;
				font-size: 1.3rem;
				&:last-child {
					margin-bottom: 0;
				}
				span {
					margin-top: -45px;
					margin-bottom: 15px;
					display: block;
					text-align: center;
					img {
						width: 60px;
					}
				}
				.listBox-txt01{
					font-size:2.1rem;
					margin-bottom: 14px;
				}
				.listBox-txt02{
					line-height: 1.6;
					font-size: 1.4rem;
					img{
						margin: 0 auto;
					}
				}
			}
		}
		.c-list03 {
			padding: 0 15px;
			&__lable {
				white-space: normal;
			}
		}
	}

	//ゆきげ神奈川からのお知らせ sp
	.p-sosaiServiceKanagawa04 {
		margin-bottom: 50px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 22px;
		}
		&__newsList {
			li {
				padding: 15px 0;
				a {
					width: 100%;
					font-size: 1.4rem;
					padding: 4px 0 0;
				}
				p {
					font-size: 1.4rem;
					padding: 4px 0 0;
				}
			}
			&_data {
				width: auto;
				margin: 0 7px 0 0;
				font-size: 1.3rem;
			}
			.c-newsCate02 {
				width: 40px;
				height: 15px;
				margin: 2px 5px 2px 0;
				font-size: 1rem;
			}
			.c-newsCate03 {
				width: 50px;
				height: 15px;
				margin: 2px 0;
				font-size: 1rem;
			}
		}
		.c-sosaiBtn01 {
			width: 50%;
			margin-top: 13px;
			line-height: 3;
			font-size: 1.3rem;
		}
	}
	//コープの終活応援！お葬式勉強会 sp
	.p-sosaiServiceKanagawa05 {
		padding: 48px 15px 50px;
		&__txt01 {
			margin-bottom: 34px;
			font-size: 1.4rem;
			line-height: 1.6;
			padding: 0 5px;
		}
		&__infomation {
			margin-bottom: 17px;
			&__schedule {
				width: 100%;
				margin-bottom: 27px;
				h2 {
					font-size: 1.6rem;
					padding: 6px;
				}
			}
			&__report {
				width: 100%;
				h2 {
					font-size: 1.6rem;
					padding: 6px;
				}
			}
			&__data {
				width: auto;
				margin-right: 10px;
				font-size: 1.3rem;
			}
			a {
				font-size: 1.4rem;
				line-height: 1.5;
			}
			p {
				font-size: 1.4rem;
				line-height: 1.5;
			}
			ul {
				li {
					padding: 15px;
					line-height: 1;
				}
			}
		}
		&__btn {
			display: block;
			&__grbtn01{
				margin-bottom: 30px;
				display: block;
			}
			&__fb {
				width: 100%;
				margin: 0 auto 10px auto;
				a{
					width: 100%;
					font-size: 1.3rem;
					justify-content: center;
					padding: 6px 10px 5px 19px;
					img{
						width: 25px;
					}
					span{
						font-size: 1rem;
					}
				}
			}
			&__btn02{
				a{
					width: 100%;
					height: auto;
					color:#faad5a;
					font-size: 1.3rem;
					padding: 12px 5px;
					span{
						font-size: 1rem;
					}
				}
			}
			.c-sosaiBtn05 {
				font-size: 1.4rem;
				margin: 0 0 15px 0;
				float: none;
				max-width: 100%;
			}
			.c-sosaiBtn03 {
				float: none;
				font-size: 1.4rem;
				max-width: 100%;
				a{
					padding: 20px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
