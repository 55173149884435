@use '../0_base' as *;

/*------------------------------------------------------------
title
------------------------------------------------------------*/

/*共通*/
.c-commonTitle01 {
	margin-bottom: 15px;
	padding-bottom: 5px;
	font-size: 1.8rem;
	font-weight: bold;
	color: $gray01;
	border-bottom: solid 1px $gray01;
}

/*サービスサイト*/
.c-serviceTitle01 {
	margin-bottom: 40px;
	font-size: 3.2rem;
	font-weight: bold;
	color: $gray01;
	text-align: center;
	letter-spacing: 0.05em;
	line-height: 1.3;
	&:before {
		margin: 0 auto 13px auto;
		content: "";
		-webkit-background-size: cover;
		background-size: cover;
		display: block;
	}
	span {
		margin-top: 3px;
		font-size: 1.4rem;
		font-weight: normal;
		color: $gray02;
		display: block;
		letter-spacing: 0.01em;
		@include sp {
			margin-top: 0;
		}
	}
	&.c-serviceTitle01--star {
		&:before {
			width: 30px;
			height: 29px;
			background-image: url("../img/common/icon_star01.png");
		}
	}
	&.c-serviceTitle01--news {
		&:before {
			width: 31px;
			height: 26px;
			background-image: url("../img/common/icon_news01.png");
		}
	}
}

/*チケット*/
.c-ticketTitle01 {
	margin-bottom: 21px;
	font-size: 2.8rem;
	font-weight: bold;
	color: $ticketNaviColor02;
	text-align: center;
	line-height: 1.5;
	img {
		margin: 0 auto 5px;
		display: block;
	}
}
.c-ticketTitle02,
.c-ticketTitle02b {
	margin-bottom: 30px;
	font-size: 2rem;
	font-weight: bold;
	color: $ticketNaviColor02;
	background-image: url("../img/ticket/line02.png");
	background-repeat: repeat-x;
	background-position: 0 bottom;
	line-height: 1.5;
	em {
		font-style: normal;
		padding-bottom: 8px;
		display: inline-block;
		position: relative;
		&:after {
			width: 100%;
			height: 3px;
			content: "";
			background-color: $ticketColor01;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
	&--2 {
		em {
			&:after {
				background-color: $keycolor02;
			}
		}
	}
}
.c-ticketTitle03 {
	margin-bottom: 30px;
	padding-bottom: 10px;
	font-size: 2.4rem;
	font-weight: bold;
	color: $ticketNaviColor02;
	border-bottom: solid 2px $ticketColor01;
}
.c-ticketTitle04 {
	margin-bottom: 41px;
	padding-top: 35px;
	font-size: 2.8rem;
	font-weight: bold;
	color: $ticketNaviColor02;
	background-image: url("../img/ticket/line01.png");
	background-repeat: repeat-x;
	background-position: 0 0;
}
.c-ticketTitle05 {
	text-align: center;
	position: relative;
	padding: 0 0 35px;
	@include sp {
		padding: 0 0 21px;
	}

	&::after {
		content: "";
		width: 69px;
		height: 6px;
		background: url("../img/ticket/enquete/dots1.png") repeat-x center;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 0);
	}

	&__icon {
		display: block;
		width: 50px;
		height: 50px;
		margin: 0 auto 12px;
		@include sp {
			width: 40px;
			height: 40px;
			margin: 0 auto 5px;
		}
	}

	&__txt {
		display: block;
		font-size: 3.2rem;
		line-height: 1.5;
		color: #46483c;
		font-weight: bold;
		@include sp {
			font-size: 2rem;
			line-height: 1.5;
		}
	}
}

/*保険*/
.c-hokenTitle01 {
    margin-bottom: 15px;
    font-size: 2.2rem;
    font-weight: bold;

    @include pc {
        margin-bottom: 40px;
        font-size: 3.2rem;
    }

    &--mt {
        margin-top: 40px;

        @include pc {
            margin-top: 80px;
        }
    }
}
.c-hokenTitle02 {
	margin-bottom: 31px;
	padding: 10px 10px 10px 24px;
	font-size: 2.4rem;
	letter-spacing: 1px;
	font-weight: bold;
	color: $gray01;
	background-color: rgba($hokenColor01, 0.1);
	position: relative;
	&:before {
		width: 5px;
		height: 50%;
		content: "";
		background-image: url("../img/hoken/bg04.jpg");
		-webkit-background-size: cover;
		background-size: cover;
		position: absolute;
		top: 25%;
		left: 0;
		@include radius(3px);
	}
	&--orange {
		background-color: #fef6f0;
		&:before {
			width: 5px;
			height: 50%;
			content: "";
			background-image: url("../img/hoken/bg07.png");
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			top: 25%;
			left: 0;
			@include radius(3px);
		}
	}
}
.c-hokenTitle03 {
	margin-bottom: 24px;
	padding: 29px 0 26px 46px;
	font-size: 3.2rem;
	font-weight: bold;
	letter-spacing: 1px;
	color: $gray01;
	background-image: url("../img/hoken/title_obi.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: right bottom;
	border-top: solid 4px $hokenColor01;
}
.c-hokenTitle04 {
	margin-bottom: 40px;
	padding-bottom: 10px;
	font-size: 2.4rem;
	font-weight: bold;
	color: $gray01;
	border-bottom: solid 2px $hokenColor01;
}
.c-hokenTitle05 {
	margin-bottom: 14px;
	font-size: 2rem;
	letter-spacing: 1px;
	font-weight: bold;
	color: $gray01;
	&:before {
		width: 10px;
		height: 10px;
		margin: -3px 10px 0 0;
		content: "";
		background-image: url("../img/hoken/bg04.jpg");
		-webkit-background-size: cover;
		background-size: cover;
		display: inline-block;
		vertical-align: middle;
		@include radius(50%);
	}
}
.c-hokenTitle06 {
	margin-bottom: 40px;
	padding: 20px 40px;
	font-size: 2rem;
	font-weight: bold;
	color: $gray01;
	background-image: url("../img/hoken/title_obi.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: right bottom;
	border-top: solid 4px $hokenColor01;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 2.8rem;
	}
}
.c-hokenTitle07 {
	text-align: center;
	font-size: 3.4rem;
	line-height: 1.2;
	color: #253b73;
	font-weight: bold;
	font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
	@include sp {
		font-size: 1.6rem;
		line-height: 1.32;
	}
}

.c-hokenTitle08 {
	text-align: center;
	font-size: 3.2rem;
	line-height: 1.4;
	color: #46483c;
	font-weight: bold;
	font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
	@include sp {
		font-size: 2.1rem;
		line-height: 1.1;
	}
}

.c-hokenTitle09 {
	text-align: center;
	position: relative;
	padding: 0 0 35px;
	@include sp {
		padding: 0 0 21px;
	}

	&::after {
		content: "";
		width: 69px;
		height: 6px;
		background: url("../img/hoken/common/dots2.png") repeat-x center;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 0);
	}

	&__icon {
		display: block;
		width: 50px;
		height: 50px;
		margin: 0 auto 12px;
		@include sp {
			width: 40px;
			height: 40px;
			margin: 0 auto 5px;
		}
	}

	&__txt {
		display: block;
		font-size: 3.2rem;
		line-height: 1.5;
		color: #46483c;
		font-weight: bold;
		@include sp {
			font-size: 2rem;
			line-height: 1.5;
		}
	}
}
.c-hokenTitle10 {
    margin-bottom: 10px;
    padding-bottom: 3px;
    position: relative;

    @include pc {
        padding-bottom: 10px;
    }

    &::before,
    &::after {
        content: "";
        height: 3px;
        position: absolute;
        bottom: 0;

        @include pc {
            height: 6px;
        }
    }

    &::before {
        width: 57.5px;
        background-color: #FA8246;
        left: 0;
        z-index: 1;

        @include pc {
            width: 320px;
        }
    }

    &::after {
        width: 100%;
        background-color: #41B4E6;
        right: 0;
    }

    &__text {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;

        @include sp {
            padding-left: 25px;
            padding-right: 25px;
        }

        @include pc {
            font-size: 40px;
        }
    }

    &__subText {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        color: #333;
        font-size: 1rem;

        @include sp {
            margin-top: -.5em;
            padding-left: 25px;
            padding-right: 25px;
        }

        @include pc {
            margin-top: -.5em;
            font-size: 1.6rem;
        }
    }
    &__subText2 {
        margin-top: 10px;
        color: #333;
        font-size: 1.8rem;
        font-weight: 700;

        @include pc {
            font-size: 2.4rem;
        }
    }

    &__tags {
        margin-bottom: 5px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 5px;
        @include pc {
            margin-bottom: 0;
            column-gap: 10px;
        }
    }
    &__tag {
        padding: 5px;
        background-color: #fff;
        border: 1.5px solid #41B4E6;
        border-radius: 5px;
        font-size: 1.4rem;
        @include pc {
            font-size: 1.2rem;
        }
        &--corp {
            color: $keycolor01;
            border-color: $keycolor01;
        }
        &--request {
            color: #ffffff;
            background-color: $hokenTextColor02;
            border-color: $hokenTextColor02;
        }
        &--internet {
            color: #ffffff;
            background-color: $hokenBtnColor02;
            border-color: $hokenBtnColor02;
        }
        &--campain {
            color: $hokenBtnColor03;
            border-color: $hokenBtnColor03;
        }
        &--attention {
            color: $hokenBtnColor04;
            border-color: $hokenBtnColor04;
        }
        &--popular {
            color: $hokenBtnColor05;
            border-color: $hokenBtnColor05;
        }
        &--new {
            color: $hokenBtnColor06;
            border-color: $hokenBtnColor06;
        }
        &--interview {
            color: #41B4E6;
            border-color: #41B4E6;
        }
    }
}
.c-hokenTitle11 {
    margin-top: 40px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    font-size: 1.6rem;
    font-weight: 700;

    @include pc {
        margin-top: 80px;
        margin-bottom: 40px;
        column-gap: 14px;
        font-size: 2.4rem;
    }

    &::before {
        content: '';
        width: 3px;
        height: 17px;
        background: linear-gradient(0deg, #41B4E6 0%, #41B4E6 50%, #FA8246 50%, #FA8246 100%);
        @include pc {
            width: 6px;
            height: 20px;
        }
    }
}
.c-hokenTitle12 {
    display: flex;
    align-items: center;
    column-gap: 7px;
    font-size: 1.9rem;
    font-weight: 700;

    @include pc {
        column-gap: 14px;
        font-size: 2.4rem;
    }

    &::before {
        content: '';
        width: 3px;
        height: 17px;
        background: linear-gradient(0deg, #41B4E6 0%, #41B4E6 50%, #FA8246 50%, #FA8246 100%);
        @include pc {
            width: 6px;
            height: 20px;
        }
    }
}
.c-hokenTitle13 {
    padding-bottom: 5px;
    font-size: 2.2rem;
    font-weight: 700;
    border-bottom: 1.5px solid #41B4E6;
    @include pc {
        border-width: 3px;
        font-size: 3.2rem;
    }
}
.c-hokenTitle14 {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #41B4E6;
    font-size: 2.2rem;
    font-weight: 700;
    @include pc {
        column-gap: 10px;
        font-size: 3.2rem;
    }

    svg {
        min-width: 30px;
        width: 30px;
        height: 30px;
    }
}
.c-sb_none {
    justify-content: center;
}

/*リフォーム*/
.c-refomeTitle01 {
	margin-bottom: 30px;
	font-size: 2.8rem;
	font-weight: bold;
	color: $reformTextColor01;
	text-align: center;
	span {
		padding: 0 60px;
		display: inline-block;
		background: url("../img/reform/title_ornament_left.png") no-repeat left bottom 12px,
			url("../img/reform/title_ornament_right.png") no-repeat right bottom 12px;
		-webkit-background-size: 25px;
		background-size: 25px;
	}
	img {
		margin: 0 auto 3px auto;
		display: block;
	}
}
.c-refomeTitle02 {
	width: 100%;
	min-width: $baseWidth01;
	height: 200px;
	background-image: url("../img/reform/title_obi.jpg");
	background-position: center bottom;
	position: relative;
	overflow-x: hidden;
	&__inner {
		width: $baseWidth01;
		margin: auto;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__ttl1 {
		display: block;
		font-size: 2.8rem;
		line-height: 1.3;
		font-weight: bold;
		color: $reformTextColor01;
		padding-bottom: 11px;
	}
	&__ttl2 {
		display: block;
		font-size: 1.8rem;
		font-weight: normal;
		color: $reformBtnColor08;
		@include Garamond();
	}
}
.c-refomeTitle03 {
	margin-bottom: 22px;
	padding: 0 115px 0 20px;
	font-size: 2.8rem;
	font-weight: bold;
	color: $reformTextColor02;
	position: relative;
	&:before {
		width: 5px;
		height: 28px;
		content: "";
		background-color: $reformBtnColor08;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	&:after {
		width: 105px;
		height: 12px;
		content: "";
		background: url("../img/reform/title_img01.png") no-repeat;
		position: absolute;
		top: 17px;
		right: 8px;
	}
}
.c-refomeTitle03b {
	margin-bottom: 32px;
	padding: 0 25px;
	font-size: 3.2rem;
	font-weight: bold;
	color: $reformTextColor02;
	position: relative;
	line-height: 1.2;
	&:before {
		width: 5px;
		height: 100%;
		content: "";
		background-color: $reformBtnColor08;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	span {
		font-size: 1.4rem;
		color: $gray03;
		display: block;
	}
}
.c-refomeTitle03c {
	margin-bottom: 24px;
	padding: 0 25px;
	font-size: 2.8rem;
	font-weight: bold;
	color: $reformTextColor02;
	position: relative;
	&:before {
		width: 5px;
		height: 100%;
		content: "";
		background-color: $reformBtnColor08;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	span {
		font-size: 1.4rem;
		font-weight: 500;
		color: $gray03;
		display: block;
		letter-spacing: 0px;
	}
}
.c-refomeTitle04 {
	margin-bottom: 30px;
	padding: 9px 0 8px;
	font-size: 1.8rem;
	font-weight: bold;
	color: #000000;
	border-top: dotted 1px $gray07;
	border-bottom: dotted 1px $gray07;
}
.c-refomeTitle05 {
	margin-bottom: 25px;
	padding-bottom: 10px;
	font-size: 2.4rem;
	font-weight: bold;
	color: $gray01;
	border-bottom: solid 2px $reformColor01;
}
.c-refomeTitle06 {
	margin-bottom: 26px;
	padding: 10px 20px;
	font-size: 1.4rem;
	font-weight: bold;
	color: #ffffff;
	background-color: $reformColor01;
}
.c-reformeTitle07 {
	margin-bottom: 30px;
	font-size: 2rem;
	font-weight: bold;
	color: $ticketNaviColor02;
	background-image: url("../img/ticket/line02.png");
	background-repeat: repeat-x;
	background-position: 0 bottom;
	line-height: 1.5;
	em {
		font-style: normal;
		padding-bottom: 8px;
		display: inline-block;
		position: relative;
		&:after {
			width: 100%;
			height: 3px;
			content: "";
			background-color: $reformColor01;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}

/*くらしのサービス*/
.c-kurashiTitle01 {
	margin-bottom: 30px;
	font-size: 2.4rem;
	line-height: 1;
	font-weight: bold;
	text-align: center;
	color: $kurashiTextColor04;
	&:after {
		width: 340px;
		height: 5px;
		margin: 15px auto 0 auto;
		content: "";
		display: block;
		background-image: url("../img/kurashi/title01_line.png");
	}
	img {
		display: block;
		margin: 8px auto;
	}
}
.c-kurashiTitle02 {
	margin-bottom: 33px;
	padding: 0 120px 0 24px;
	font-size: 2.8rem;
	line-height: 1;
	font-weight: bold;
	color: #483825;
	position: relative;
	&:before {
		width: 5px;
		height: 28px;
		content: "";
		background-color: $kurashiColor01;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	&:after {
		width: 100px;
		height: 5px;
		content: "";
		background-image: url("../img/kurashi/title_img01.jpg");
		position: absolute;
		top: calc((100% - 5px) / 2);
		right: 0;
	}
}
.c-kurashiTitle03 {
	margin-bottom: 30px;
	padding-bottom: 10px;
	font-size: 2.4rem;
	font-weight: bold;
	color: $kurashiTextColor01;
	border-bottom: solid 2px $kurashiColor01;
}
.c-kurashiTitle04 {
	margin-bottom: 25px;
	font-size: 2rem;
	font-weight: bold;
	color: $kurashiTextColor01;
	background-image: url("../img/kurashi/line02.png");
	background-repeat: repeat-x;
	background-position: 0 bottom;
	span {
		padding-bottom: 10px;
		display: inline-block;
		border-bottom: solid 1px $kurashiColor01;
	}
}
.c-kurashiTitle05 {
	margin-bottom: 30px;
	font-size: 2rem;
	font-weight: bold;
	color: $ticketNaviColor02;
	background-image: url("../img/ticket/line02.png");
	background-repeat: repeat-x;
	background-position: 0 bottom;
	line-height: 1.5;
	em {
		font-style: normal;
		padding-bottom: 8px;
		display: inline-block;
		position: relative;
		&:after {
			width: 100%;
			height: 3px;
			content: "";
			background-color: $kurashiColor01;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}

/*葬祭*/
.c-sosaiTitle01 {
	margin-bottom: 51px;
	color: $sosaiTextColor01;
	font-size: 3.6rem;
	font-weight: normal;
	text-align: center;
	@include mincyo;
	span {
		display: inline-block;
		&:after {
			width: 100%;
			height: 3px;
			margin: 8px auto 0 auto;
			content: "";
			background-color: $sosaiNaviColor02;
			display: block;
		}
	}
}
.c-sosaiTitle02 {
	margin-bottom: 60px;
	color: $sosaiTextColor01;
	font-size: 3rem;
	text-align: center;
	@include mincyo;
	span {
		display: inline-block;
		&:after {
			width: 100%;
			height: 3px;
			margin: 20px auto 0 auto;
			content: "";
			background-color: $sosaiNaviColor02;
			display: block;
		}
	}
}
.c-sosaiTitle02b {
	margin-bottom: 54px;
	color: $sosaiTextColor01;
	font-size: 3.6rem;
	font-weight: 400;
	text-align: center;
	@include mincyo;
	span {
		display: inline-block;
		&:after {
			width: 100%;
			height: 3px;
			margin: 7px auto 0 auto;
			content: "";
			background-color: $sosaiNaviColor02;
			display: block;
		}
	}
}
.c-sosaiTitle03 {
	margin-bottom: 30px;
	font-size: 3rem;
	font-weight: 500;
	color: $sosaiTextColor01;
	text-align: center;
	line-height: 1.5;
	@include mincyo;
	span {
		display: block;
		font-size: 1.1rem;
		font-style: italic;
		color: $sosaiNaviColor02;
		padding-top: 7px;
		@include Garamond();
	}
}
.c-sosaiTitle03b {
	margin-bottom: 30px;
	font-size: 3rem;
	color: $sosaiNaviColor02;
	text-align: center;
	@include mincyo;
	img {
		margin: 10px auto 0;
		display: block;
	}
}
.c-sosaiTitle04 {
	margin-bottom: 40px;
	font-size: 3.8em;
	font-weight: normal;
	color: $sosaiTextColor01;
	text-align: center;
	@include mincyo;
	img {
		margin: 3px 4px 4px 0;
		display: block;
		&.sp-only {
			display: none;
		}
	}
}
.c-sosaiTitle05 {
	margin-bottom: 40px;
	padding-bottom: 10px;
	font-size: 2.4rem;
	font-weight: bold;
	color: $sosaiTextColor01;
	border-bottom: solid 2px $sosaiNaviColor02;
}
.c-sosaiTitle06 {
	margin-bottom: 32px;
	font-size: 3.2rem;
	font-weight: bold;
	color: $sosaiNaviColor02;
	@include mincyo;
	&:after {
		width: 40px;
		height: 1px;
		margin: 3px 0 0 0;
		content: "";
		background-color: $sosaiNaviColor02;
		display: block;
	}
}
.c-sosaiTitle07 {
	margin-bottom: 12px;
	padding-left: 20px;
	font-size: 2.4rem;
	font-weight: bold;
	color: $sosaiTextColor01;
	@include mincyo;
	&:before {
		width: 8px;
		height: 8px;
		margin: -5px 12px 0 -20px;
		content: "";
		background-color: $sosaiBtnColor01;
		display: inline-block;
		vertical-align: middle;
	}
}
.c-sosaiTitle08 {
	margin-bottom: 30px;
	padding: 15px 30px 11px;
	font-size: 2.8rem;
	font-weight: bold;
	color: #ffffff;
	background-color: $sosaiNaviColor01;
	@include mincyo;
	span {
		margin: -5px 20px 0 0;
		padding: 5px 9px;
		font-size: 1.3rem;
		background-color: $sosaiSubColor02;
		display: inline-block;
		vertical-align: middle;
		@include gosick;
	}
}
.c-sosaiTitle09 {
	margin-bottom: 25px;
	padding-left: 30px;
	font-size: 2.8rem;
	font-weight: 500;
	line-height: 1.28;
	color: $sosaiTextColor01;
	position: relative;
	&:before {
		width: 4px;
		height: 100%;
		content: "";
		background-color: $sosaiNaviColor02;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.c-sosaiTitle10 {
	margin-bottom: 20px;
	padding-left: 30px;
	font-size: 1.8rem;
	font-weight: 700;
	color: $gray08;
	&:before {
		width: 18px;
		height: 18px;
		margin: -5px 12px 0 -30px;
		content: "";
		background-image: url("../img/common/icon_maru_blue01.png");
		-webkit-background-size: cover;
		background-size: cover;
		display: inline-block;
		vertical-align: middle;
	}
}
.c-sosaiTitle11 {
	margin-bottom: 30px;
	padding: 20px 15px 15px 84px;
	font-size: 3rem;
	font-weight: 500;
	line-height: 1.3;
	color: #ffffff;
	background-color: $sosaiNaviColor01;
	@include mincyo;
	&:before {
		width: 50px;
		height: 50px;
		margin: -8px 19px 0 -69px;
		content: "";
		background-image: url("../img/sosai/icon_sosai02.png");
		-webkit-background-size: cover;
		background-size: cover;
		display: inline-block;
		vertical-align: middle;
	}
}
.c-sosaiTitle12 {
	min-height: 46px;
	margin-bottom: 14px;
	padding: 2px 0 2px 65px;
	font-size: 3.2rem;
	font-weight: 500;
	line-height: 1.3;
	color: $gray08;
	position: relative;
	@include mincyo;
	&:before {
		width: 46px;
		height: 46px;
		content: "";
		background-image: url("../img/sosai/icon_sosai01.png");
		-webkit-background-size: cover;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
	span {
		margin: -7px 0 4px;
		font-size: 1.8rem;
		line-height: 1;
		display: block;
	}
}
.c-sosaiTitle13 {
	margin-bottom: 30px;
	color: $sosaiNaviColor02;
	font-size: 2rem;
	font-weight: 700;
	&:before {
		width: 18px;
		height: 18px;
		margin: -5px 10px 0 0;
		content: "";
		background-image: url("../img/common/icon_check_blue01.png");
		-webkit-background-size: cover;
		background-size: cover;
		display: inline-block;
		vertical-align: middle;
	}
}

/*フォームタイトル*/
.c-formTitle {
	padding-left: 40px;
	font-size: 2.8rem;
	font-weight: bold;
	color: $gray01;
	&__icon {
		margin: -6px 10px 0 -40px;
		-webkit-background-size: cover;
		background-size: cover;
		display: inline-block;
		vertical-align: middle;
		&--ticket01 {
			width: 30px;
			height: 26px;
			background-image: url("../img/common/icon_cart04.png");
		}
		&--hoken01 {
			width: 27px;
			height: 30px;
			margin-right: 13px;
			background-image: url("../img/common/icon_file02.png");
		}
		&--hoken02 {
			width: 30px;
			height: 23px;
			background-image: url("../img/common/icon_mail01.png");
		}
		&--reform01 {
			width: 27px;
			height: 30px;
			margin-right: 13px;
			background-image: url("../img/common/icon_file03.png");
		}
		&--kurashi01 {
			width: 30px;
			height: 33px;
			background-image: url("../img/common/icon_cart05.png");
		}
		&--sosai01 {
			width: 27px;
			height: 30px;
			margin-right: 13px;
			background-image: url("../img/common/icon_file04.png");
		}
		&--sosai02 {
			width: 30px;
			height: 30px;
			background-image: url("../img/common/icon_document01.png");
		}
	}
}

.c-formTitle02 {
    color: #333;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    @include pc {
        font-size: 3rem;
    }

    &::after {
        content: "";
        margin-top: 10px;
        width: 100%;
        height: 6px;
        background: url("../img/hoken/hoken_line02.svg") no-repeat center;
        background-size: contain;
        display: block;

        @include pc {
            margin-top: 10px;
            height: 12px;
        }
    }
}

@include sp {
	/*共通 sp*/
	.c-commonTitle01 {
		font-size: 1.6rem;
	}

	/*サービスサイト SP*/
	.c-serviceTitle01 {
		margin-bottom: 20px;
		font-size: 2.2rem;
		line-height: 1.5;
		&.c-serviceTitle01--star {
			&:before {
				width: 20px;
				height: 20px;
				margin-bottom: 4px;
			}
		}
		span {
			font-size: 1.4rem;
			margin-top: -2px;
		}
	}

	/*チケット SP*/
	.c-ticketTitle01 {
		margin-bottom: 20px;
		font-size: 2.1rem;
		img {
			width: 82px;
			height: auto;
			margin-bottom: 4px;
		}
	}
	.c-ticketTitle02 {
		margin-bottom: 20px;
		padding: 9px 24px 8px 14px;
		font-size: 1.4rem;
		background: none;
		background-color: #ffffff;
		position: relative;
		border-top: solid 1px $glaylight10;
		border-bottom: solid 1px $glaylight10;
		&:before {
			width: 3px;
			height: 100%;
			content: "";
			background-color: $ticketColor01;
			position: absolute;
			top: 0;
			left: 0;
		}
		em {
			padding: 0;
			&:after {
				content: none;
			}
		}
	}
	.c-ticketTitle02b {
		margin-bottom: 20px;
		font-size: 1.7rem;
	}
	.c-ticketTitle03 {
		margin-bottom: 14px;
		padding-bottom: 9px;
		font-size: 1.6rem;
		border-bottom: solid 1px #fc819b;
	}
	.c-ticketTitle04 {
		margin-bottom: 30px;
		padding-top: 20px;
		font-size: 1.7rem;
		background-image: url("../img/ticket/line01_sp.png");
		-webkit-background-size: 15px;
		background-size: 15px;
	}

	/*保険 SP*/
	.c-hokenTitle02 {
		margin-bottom: 10px;
		padding: 5px 5px 5px 17px;
		font-size: 2rem;
		&:before {
			width: 3px;
		}
	}
	.c-hokenTitle03 {
		margin-bottom: 30px;
		padding: 30px 20px;
		font-size: 2.1rem;
		background-image: url("../img/hoken/title_obi_sp.jpg");
	}
	.c-hokenTitle04 {
		margin-bottom: 30px;
		padding-bottom: 5px;
		font-size: 2rem;
	}
	.c-hokenTitle05 {
		margin-bottom: 15px;
		font-size: 1.8rem;
	}
	.c-hokenTitle06 {
		margin-bottom: 30px;
		padding: 30px 20px;
		font-size: 1.9rem;
		background-image: url("../img/hoken/title_obi_sp.jpg");
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: 2.5rem;
		}
	}

	/*リフォーム SP*/
	.c-refomeTitle01 {
		margin-bottom: 18px;
		font-size: 1.9rem;
		line-height: 1.5;
		span {
			padding: 0 35px;
			-webkit-background-size: 25px;
			background-size: 25px;
		}
		img {
			margin: 0 auto 10px auto;
		}
	}
	.c-refomeTitle02 {
		min-width: 100%;
		height: auto;
		padding: 30px 20px;
		font-size: 2rem;
		background-image: url("../img/reform/title_obi_sp.jpg");
		background-position: right bottom;
		background-repeat: no-repeat;
		background-size: cover;
		&__inner {
			width: 100%;
			position: static;
			transform: none;
		}
		&__ttl1 {
			font-size: 1.7rem;
			padding-bottom: 4px;
		}
		&__ttl2 {
			font-size: 1.3rem;
		}
	}
	.c-refomeTitle03 {
		margin-bottom: 33px;
		padding: 0 0 0 11px;
		font-size: 1.9rem;
		&:before {
			width: 3px;
			height: 18px;
		}
		&:after {
			top: auto;
			bottom: -18px;
			left: 0;
			background-size: 66px;
		}
	}
	.c-refomeTitle03b {
		margin-bottom: 30px;
		padding: 0 0 0 11px;
		font-size: 2.2rem;
		&:before {
			width: 3px;
		}
		span {
			font-size: 1.2rem;
		}
	}
	.c-refomeTitle03c {
		margin-bottom: 15px;
		padding: 0 0 0 11px;
		font-size: 1.9rem;
		&:before {
			width: 3px;
		}
		span {
			font-size: 1.2rem;
		}
	}
	.c-refomeTitle04 {
		font-size: 1.5rem;
		margin-bottom: 12px;
		padding: 6px 0 4px;
	}
	.c-refomeTitle05 {
		margin-bottom: 30px;
		padding-bottom: 5px;
		font-size: 2rem;
	}
	.c-refomeTitle06 {
		margin-bottom: 18px;
		padding: 4px 10px;
		font-size: 1.1rem;
	}

	/*くらしのサービス sp*/
	.c-kurashiTitle01 {
		margin-bottom: 20px;
		font-size: 2.4rem;
		img {
			margin: 7px auto;
		}
		&:after {
			width: 100%;
			margin: 14px auto 0 auto;
		}
	}
	.c-kurashiTitle02 {
		margin-bottom: 20px;
		padding: 0 60px 0 12px;
		font-size: 1.8rem;
		&:before {
			width: 3px;
			height: 18px;
		}
		&:after {
			width: 52px;
		}
	}
	.c-kurashiTitle03 {
		margin-bottom: 14px;
		padding-bottom: 14px;
		font-size: 1.6rem;
		line-height: 1;
		border-bottom: solid 1px $kurashiColor01;
	}
	.c-kurashiTitle04 {
		margin-bottom: 12px;
		font-size: 1.4rem;
		span {
			padding-bottom: 9px;
		}
	}

	/*葬祭 sp*/
	.c-sosaiTitle01 {
		margin-bottom: 19px !important;
		font-size: 2.3rem;
		span {
			&:after {
				margin: 3px auto 0 auto;
			}
		}
	}
	.c-sosaiTitle02,
	.c-sosaiTitle02b {
		margin-bottom: 35px;
		font-size: 2.3rem;
		line-height: 1.3;
		span {
			&:after {
				margin: 10px auto 0 auto;
			}
		}
	}
	.c-sosaiTitle03 {
		margin-bottom: 15px;
		font-size: 2rem;
		line-height: 1;
		span {
			padding-top: 5px;
			font-size: 0.7rem;
		}
	}
	.c-sosaiTitle03b {
		margin-bottom: 15px;
		font-size: 2rem;
		line-height: 1;
		span {
			&:after {
				margin: 3px auto 0 auto;
			}
		}
	}
	.c-sosaiTitle04 {
		margin-bottom: 20px;
		font-size: 2.3rem;
		line-height: 1;
		img {
			margin: 8px auto 0;
			&.pc-only {
				display: none;
			}
			&.sp-only {
				display: block;
			}
		}
	}
	.c-sosaiTitle05 {
		margin-bottom: 20px;
		font-size: 2rem;
	}
	.c-sosaiTitle06 {
		margin-bottom: 20px;
		font-size: 2.2rem;
	}
	.c-sosaiTitle07 {
		margin-bottom: 15px;
		font-size: 2rem;
	}
	.c-sosaiTitle08 {
		margin-bottom: 15px;
		padding: 14px 15px;
		font-size: 1.9rem;
		span {
			margin: 0 13px 0 0;
			padding: 6px 7px;
			font-size: 1rem;
			line-height: 1.3;
		}
	}
	.c-sosaiTitle09 {
		margin-bottom: 20px;
		padding-left: 13px;
		font-size: 1.9rem;
		&:before {
			width: 2px;
		}
	}
	.c-sosaiTitle10 {
		margin-bottom: 15px;
		padding-left: 15px;
		text-indent: 10px;
		font-size: 1.4rem;
		&:before {
			width: 10px;
			height: 10px;
			margin: -5px 5px 0 -25px;
			content: "";
		}
	}
	.c-sosaiTitle11 {
		margin-bottom: 15px;
		padding: 10px 10px 10px 55px;
		font-size: 2.4rem;
		&:before {
			width: 30px;
			height: 30px;
			margin: -5px 15px 0 -45px;
		}
	}
	.c-sosaiTitle12 {
		min-height: 36px;
		margin-bottom: 15px;
		padding: 2px 0 2px 50px;
		font-size: 2.24rem;
		&:before {
			width: 33px;
			height: 33px;
		}
		span {
			font-size: 1.26rem;
		}
	}
	.c-sosaiTitle13 {
		margin-bottom: 20px;
		font-size: 1.4rem;
		&:before {
			width: 14px;
			height: 14px;
			margin: -3px 5px 0 0;
		}
	}

	/*フォームタイトル sp*/
	.c-formTitle {
		font-size: 1.8rem;
		// padding-left: 34px;
		&__icon {
			&--ticket01 {
				width: 25px;
				height: 22px;
				margin: -4px 7px 0 -40px;
			}
			&--hoken01 {
				width: 24px;
				height: 27px;
				margin-top: -3px;
			}
		}
	}
}

.c-kurashi-enqueteTitle1{
	position: relative;
	font-size: 3.2rem;
	line-height: 48px;
	font-weight: bold;
	text-align: center;
	color: #232934;

	@include sp{
		font-size: 2rem;
		line-height: 30px;
	}

	&::before{
		content: "";
		position: relative;
		display: block;
		background: url('../img/kurashi/enquete/icon_pencil.svg') no-repeat center;
		width: 50px;
		height: 50px;
		background-size: 50px 50px;
		margin: 0 auto 12px;

		@include sp{
			width: 40px;
			height: 40px;
			background-size: 40px 40px;
			margin: 0 auto 4px;
		}
	}

	&::after{
		content: "";
		position: relative;
		display: block;
		background: url('../img/kurashi/enquete/icon_line.svg') repeat-x;
		width: 69px;
		height: 6px;
		background-size: 69px 6px;
		margin: 32px auto 0;

		@include sp{
			margin: 16px auto 0;
		}
	}
}
