@use '../0_base' as *;

/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*サービスサイト*/
.c-serviceBtn01 {
	width: 236px;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.7;
	text-align: center;
	background-color: $keycolor02;
	background-image: url('../img/common/icon_arrow-white02.png');
	-webkit-background-size: 7px;
	background-size: 7px;
	background-repeat: no-repeat;
	background-position: 95% center;
	border: solid 2px $keycolor02;
	@include radius(30px);
	transition: 0.3s ease-in-out;
	a {
		width: 100%;
		height: 100%;
		padding: 3px 20px;
		color: #ffffff;
		display: block;
	}
	&:hover {
		background-color: #ffffff;
		background-image: url('../img/common/icon_arrow-green04.png');
		a {
			color: $keycolor02;
		}
	}
}

.c-serviceBtn02 {
	width: 380px;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	background-color: $keycolor02;
	background-image: url('../img/common/icon_arrow-white02.png');
	-webkit-background-size: 7px;
	background-size: 7px;
	background-repeat: no-repeat;
	background-position: 95% center;
	border: solid 2px $keycolor02;
	@include radius(100px);
	a {
		width: 100%;
		height: 100%;
		padding: 13px 50px;
		color: #ffffff;
		display: block;
	}
	&:hover {
		background-color: #ffffff;
		background-image: url('../img/common/icon_arrow-green04.png');
		a {
			color: $keycolor02;
		}
	}
}

.c-serviceBtn03 {
	width: 550px;
	margin: 0 auto;
	padding: 22px 50px;
	font-size: 2.4rem;
	font-weight: 500;
	line-height: 1.3;
	text-align: center;
	background-color: #ffffff;
	background-image: url('../img/common/icon_arrow-green04.png');
	-webkit-background-size: 12px;
	background-size: 12px;
	background-repeat: no-repeat;
	background-position: 95% center;
	border: solid 3px $glaylight01;
	@include radius(160px);
	transition: 0.3s ease-in-out;
	a {
		width: 100%;
		height: 100%;
		display: block;
		color: $gray01;
		&:before {
			margin: -6px 9px 0 -38px;
			content: '';
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
	&:hover {
		border-color: $keycolor02;
	}
	&.c-serviceBtn03--fukidashi {
		a {
			font-weight: bold;
			&:before {
				width: 29px;
				height: 24px;
				background-image: url('../img/common/icon_fukidashi01.png');
			}
		}
	}
}

/*チケット*/
.c-ticketBtn01 {
	width: 115px;
	height: 30px;
	background-color: $ticketBtnColor05;
	a {
		width: 100%;
		height: 100%;
		font-size: 1.1rem;
		font-weight: 500;
		line-height: 2.7;
		color: #ffffff;
		display: block;
		text-align: center;
		&:after {
			width: 6px;
			height: 10px;
			margin: -2px 0 0 10px;
			content: '';
			background-image: url('../img/common/icon_arrow-white01.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
.c-ticketBtn02 {
	width: 300px;
	margin: 0 auto;
	font-size: 1.8rem;
	font-weight: bold;
	line-height: 1;
	text-align: center;
	background-color: $ticketBtnColor05;
	border: solid 2px $ticketBtnColor06;
	transition: 0.3s ease-in-out;
	a {
		padding: 15px 0;
		color: #ffffff;
		display: block;
		&:hover {
			opacity: 1;
		}
	}
	&:hover {
		background-color: #fff;
		a {
			color: $ticketBtnColor05;
		}
	}
	&--gaibu {
		position: relative;
		&:before {
			width: 14px;
			height: 14px;
			content: '';
			background-image: url('../img/common/icon_link02.png');
			position: absolute;
			top: 10px;
			right: 10px;
		}
		&:hover {
			&:before {
				background-image: url('../img/common/icon_link03.png');
			}
		}
		a {
			padding: 20px 0;
		}
	}
}

.c-ticketBtn03 {
	width: 300px;
	margin: 0 auto;
	font-size: 1.8rem;
	font-weight: bold;
	line-height: 1;
	text-align: center;
	background-color: $ticketColor01;
	border: solid 2px $ticketSubColor02;
	transition: 0.3s ease-in-out;
	a {
		padding: 15px 0;
		color: #ffffff;
		display: block;
		&:hover {
			opacity: 1;
		}
	}
	&:hover {
		background-color: #fff;
		a {
			color: $ticketColor01;
		}
	}
}

/*保険*/
.c-hokenBtn01 {
	width: 116px;
	height: 30px;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 2.35;
	text-align: center;
	background-image: url('../img/hoken/bg03.jpg');
	-webkit-background-size: cover;
	background-size: cover;
	@include radius(4px);
	a {
		width: 100%;
		height: 100%;
		display: block;
		color: #ffffff;
		&:after {
			width: 7px;
			height: 10px;
			margin: -3px 0 0 10px;
			content: '';
			background-image: url('../img/common/icon_arrow-white05.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.c-hokenBtn02 {
	width: 270px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	background-color: #fff;
	background-image: url('../img/common/icon_hoken03.png');
	-webkit-background-size: 6px;
	background-size: 6px;
	background-repeat: no-repeat;
	background-position: 89% center;
	border: 2px solid #5fb3eb;
	@include radius(100px);
	a {
		width: 100%;
		height: 100%;
		padding: 11px 17px 9px 0;
		font-size: 1.4rem;
		color: #253b73;
		display: block;
	}
	@include sp {
		width: auto;
		a {
			font-size: 1.3rem;
		}
	}
}

.c-hokenBtn03 {
	width: 100%;
	max-width: 365px;
	height: 60px;
	background: url('../img/hoken/bg02.jpg') repeat;
	border-radius: 50px;
	@include sp {
		height: 40px;
	}
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: 2rem;
		font-weight: 500;
		color: #253b73;
		padding-left: 40px;
		position: relative;
		@include sp {
			font-size: 1.4rem;
		}
		&:before {
			content: '';
			width: 30px;
			height: 30px;
			border-radius: 50px;
			background: #fff url('../img/common/icon_mail01.png') no-repeat center;
			background-size: 14px;
			position: absolute;
			left: 63px;
			top: 16px;
			@include sp {
				left: 40px;
				top: 6px;
			}
		}
	}
}

.c-hokenBtn04 {
	width: 100%;
	max-width: 365px;
	height: 60px;
	background: url('../img/hoken/bg01.jpg') repeat;
	border-radius: 50px;
	@include sp {
		height: 40px;
	}
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: 2rem;
		font-weight: 500;
		color: #fff;
		padding-left: 40px;
		position: relative;
		@include sp {
			font-size: 1.4rem;
		}
		&:before {
			content: '';
			width: 30px;
			height: 30px;
			border-radius: 50px;
			background: #fff url('../img/common/icon_file02.png') no-repeat center;
			background-size: 14px;
			position: absolute;
			left: 84px;
			top: 16px;
			@include sp {
				left: 40px;
				top: 6px;
			}
		}
	}
}
.c-hokenBtn05 {
	width: 290px;
	margin: 0 auto;
	padding: 2px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	background-image: url('../img/hoken/bg04.jpg');
	-webkit-background-size: cover;
	background-size: cover;
	overflow: hidden;
	@include radius(100px);
	a {
		width: 100%;
		height: 100%;
		padding: 11px 17px 9px 0;
		font-size: 1.6rem;
		color: #253b73;
		background-color: #fff;
		background-image: url('../img/common/icon_hoken03.png');
		-webkit-background-size: 6px;
		background-size: 6px;
		background-repeat: no-repeat;
		background-position: 89% center;
		display: block;
		@include radius(100px);
	}
}
.c-hokenBtn06 {
	height: 50px;
	margin: 0 auto;
	border: solid 2px $hokenTextColor02;
	border-radius: 50px;
	display: table;
	@include sp {
		height: 40px;
	}
	a {
		padding: 0 20px 0 60px;
		font-size: 1.6rem;
		font-weight: bold;
		color: $hokenTextColor02;
		display: table-cell;
		vertical-align: middle;
		position: relative;
		@include sp {
			font-size: 1.4rem;
		}
		&:before {
			content: '';
			width: 30px;
			height: 30px;
			border-radius: 50px;
			background: $hokenTextColor02 url('../img/common/icon_file01.png') no-repeat center;
			background-size: 14px;
			position: absolute;
			left: 20px;
			top: calc((100% - 30px) / 2);
		}
	}
}

.c-hokenBtn07 {
	text-align: center;

	&__txt {
		max-width: 840px;
		width: 100%;
		display: inline-block;
		background: #fff58a;
		border-radius: 60px;
		text-align: center;
		font-size: 2rem;
		line-height: 1.2;
		color: #253b73;
		font-weight: bold;
		padding: 22px 40px;
		font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic';
		@include sp {
			max-width: 235px;
			font-size: 1.3rem;
			padding: 15px;
		}

		span {
			display: inline-block;
			position: relative;
			padding: 11px 123px 11px 59px;
			@include sp {
				padding: 0 12px 0 35px;
			}

			&::before {
				content: '';
				width: 46px;
				height: 46px;
				background: #fff url('../img/common/icon_mail01.png') no-repeat center;
				background-size: 20px 15px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				@include sp {
					width: 30px;
					height: 30px;
					background-size: 13px 10px;
				}
			}

			&::after {
				content: '';
				width: 8px;
				height: 14px;
				background: url('../img/common/icon_hoken04.png') no-repeat center;
				background-size: 8px 14px;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				@include sp {
					width: 6px;
					height: 10px;
					background-size: 6px 10px;
				}
			}
		}
	}
}

.c-hokenBtn08 {
	text-align: center;
	$root: &;

	&__txt {
		min-width: 322px;
		background: #fff;
		display: inline-block;
		border: 2px solid #253b73;
		border-radius: 40px;
		box-sizing: border-box;
		text-align: center;
		font-size: 1.6rem;
		line-height: 1.5;
		color: #253b73;
		font-weight: bold;
		font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic';
		padding: 15px 10px 13px;
		@include sp {
			min-width: 100%;
			font-size: 1rem;
			border-width: 1px;
			padding: 8px 10px;
		}

		span {
			display: inline-block;
			position: relative;
			padding: 0 34px 0 0;
			@include sp {
				padding: 0 14px 0 0;
			}

			&::after {
				content: '';
				width: 6px;
				height: 11px;
				background: url('../img/common/icon_hoken04.png') no-repeat center;
				background-size: 6px 11px;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				@include sp {
					width: 4px;
					height: 7px;
					background-size: 4px 7px;
				}
			}
		}
	}

	&.noicon {
		#{$root}__txt {
			min-width: 282px;
			border-width: 1px;
			padding: 16px 10px 14px;
			@include sp {
				min-width: 168px;
				font-size: 1rem;
				padding: 9px 10px 7px;
			}
			span {
				&::after {
					display: none;
				}
			}
		}
	}
}

.c-hokenBtn09 {
    padding: 12px 20px;
    max-width: 590px;
    width: 100%;
    border-radius: 9999px;
    border: 1px solid #41B4E6;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    color: #41B4E6;
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;

    @include pc {
        padding: 27px 50px;
        column-gap: 10px;
        border-width: 3px;
        font-size: 2.4rem;
        transition: .3s ease-in-out;

        &:hover {
            color: #fff;
            background-color: #41B4E6;
        }
    }

    &__label {
        flex: 1;
        text-align: center;
        display: flex;
        column-gap: 5px;
        align-items: center;
        justify-content: center;
        @include pc {
            column-gap: 10px;
        }
    }

    &__beforeIcon {
        min-width: 25px;
        width: 25px;
        height: 25px;

        svg {
            width: 100%;
            height: 100%;
        }

        @include pc {
            width: 30px;
            height: 30px;
        }
    }

    &__icon {
        min-width: 12px;
        width: 12px;
        height: 14px;

        svg {
            width: 100%;
            height: 100%;
        }

        @include pc {
            width: 24px;
            height: 24px;
        }
    }

    &--orange {
        background: #FA8246;
        color: #fff;
        border: none;
        @include pc {
            &:hover {
                background-color: #FA8246;
                opacity: .8;
            }
        }
    }

    &--blue {
        background: #41B4E6;
        color: #fff;
        border: none;
    }

    &--navy {
        background: #08336E;
        color: #fff;
        border: none;
    }

    &--gray {
        background: #D9D9D9;
        color: #fff;
        border: none;
    }

    &--outline {
        background-color: #fff !important;
        border: 1.5px solid #FA8246;

        @include pc {
            border-width: 3px;
        }

        &.c-hokenBtn09--gray {
            border-color: #D9D9D9;
            color: #333;
            @include pc {
                &:hover {
                    background-color: #EFEFEF !important;
                }
            }
        }
    }
}

.c-hokenBtn10 {
    padding: 9px 20px;
    border-radius: 5px;
    border: 1.5px solid #D9D9D9;
    background: #FFF;
    display: flex;
    column-gap: 10px;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;

    @include pc {
        padding: 0 40px;
        height: 80px;
        border-radius: 10px;
        column-gap: 20px;
        font-size: 1.6rem;
        border-width: 3px;
    }

    &__label {
        flex: 1
    }

    &__icon1 {
        min-width: 20px;
        width: 20px;
        height: 20px;
        color: #41B4E6;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 100%;
            height: 100%;
            fill: currentColor;
        }

        @include pc {
            min-width: 40px;
            width: 40px;
            height: 40px;
        }
    }

    &__icon2 {
        min-width: 12px;
        width: 12px;
        height: 14px;
        color: #41B4E6;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    @include pc {
        &__icon1 {
            min-width: 40px;
            width: 40px;
            height: 40px;
        }
        &__icon2 {
            min-width: 24px;
            width: 24px;
            height: 24px;
        }
    }
}

.c-hokenBtn11 {
    padding: 14.5px 10px;
    max-width: 590px;
    width: 100%;
    border-radius: 9999px;
    background: #FA8246;
    border: 1.5px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;

    @include pc {
        padding: 19px 20px 18px 20px;
        border-width: 3px;
        column-gap: 10px;
        font-size: 1.8rem;
        transition: .3s ease-in-out;
    }

    &--outline {
        background-color: #fff !important;
        border: 1.5px solid #FA8246;
        color: #FA8246;

        @include pc {
            border-width: 3px;
        }

        &.c-hokenBtn11--outline {
            border-color: #41B4E6;
            color: #41B4E6;
            &:hover {
                background-color: #41B4E6 !important;
                color: #fff;
            }
        }
    }

    &--blue {
        background-color: #41B4E6;
    }

    &--navy {
        background-color: #08336E;
    }

    &__beforeIcon,
    &__afterIcon {
        min-width: 15px;
        width: 15px;
        height: 15px;

        svg {
            width: 100%;
            height: 100%;
        }

        @include pc {
            min-width: 25px;
            width: 25px;
            height: 25px;
        }
    }
}

/*リフォーム*/
.c-reformBtn01 {
	width: 115px;
	height: 30px;
	background-color: $reformBtnColor07;
	a {
		width: 100%;
		height: 100%;
		font-size: 1.1rem;
		font-weight: 500;
		line-height: 2.9;
		color: #ffffff;
		display: block;
		text-align: center;
		&:after {
			width: 6px;
			height: 10px;
			margin: -2px 0 0 10px;
			content: '';
			background-image: url('../img/common/icon_arrow-white01.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
.c-reformBtn02 {
	width: 277px;
	height: 56px;
	margin: 0 auto;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 3.7;
	border: solid 2px $reformBtnColor08;
	a {
		width: 100%;
		height: 100%;
		display: block;
		text-align: center;
		color: $reformBtnColor08;
		background-color: #ffffff;
		font-weight: 600;
		&:hover {
			opacity: 1;
		}
		&:after {
			width: 6px;
			height: 10px;
			margin: -2px 0 0 10px;
			content: '';
			background-image: url('../img/common/icon_arrow-green03.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
			transition: 0.3s ease-in-out;
		}
	}
	&:hover {
		a {
			background-color: $reformBtnColor08;
			color: #ffffff;
			&:after {
				background-image: url('../img/common/icon_arrow-white01.png');
			}
		}
	}
}
.c-reformBtn03 {
	width: 365px;
	height: 80px;
	margin: 0 auto;
	font-size: 1.6rem;
	font-weight: bold;
	text-align: center;
	background-color: $reformBtnColor01;
	a {
		width: 100%;
		height: 100%;
		display: block;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before {
			width: 18px;
			height: 20px;
			margin: -3px 10px 0 0;
			content: '';
			background-image: url('../img/common/icon_file01.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
input {
	&.c-reformBtn03 {
		width: 100%;
		height: 100%;
		display: block;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 365px;
		height: 80px;
		margin: 0 auto;
		font-size: 1.6rem;
		font-weight: bold;
		text-align: center;
		background: $reformBtnColor01 url(../img/common/icon_file01.png) no-repeat center left 40px;
		background-size: 18px 20px;
		border: none !important;
		@include sp {
			max-width: 365px;
			width: 100%;
			height: 60px;
			font-size: 1.5rem;
			background-size: 12px 14px;
		}
	}
}

/*くらしのサービス*/
.c-kurashiBtn01 {
	width: 190px;
	height: 30px;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 2.3;
	text-align: center;
	background-color: $kurashiBtnColor03;
	@include radius(4px);
	a,
	span {
		width: 100%;
		height: 100%;
		display: block;
		color: #ffffff;
		&:after {
			width: 7px;
			height: 10px;
			margin: -3px -5px 0 5px;
			content: '';
			background-image: url('../img/common/icon_arrow-white05.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
.c-kurashiBtn02 {
	width: 190px;
	height: 30px;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 2.35;
	text-align: center;
	background-color: $kurashiBtnColor04;
	@include radius(4px);
	a {
		width: 100%;
		height: 100%;
		display: block;
		color: #ffffff;
		&:after {
			width: 7px;
			height: 10px;
			margin: -3px -9px 0 10px;
			content: '';
			background-image: url('../img/common/icon_arrow-white05.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
.c-kurashiBtn03 {
	width: 300px;
	height: 56px;
	margin: 0 auto;
	font-size: 1.8rem;
	font-weight: bold;
	line-height: 3.11;
	text-align: center;
	border: solid 2px $kurashiColor01;
	transition: 0.3s ease-in-out;
	@include radius(3px);
	a {
		width: 100%;
		height: 100%;
		display: block;
		color: $kurashiColor01;
		transition: 0.3s ease-in-out;
		&:before {
			width: 14px;
			height: 14px;
			margin: -3px 10px 0 0;
			content: '';
			background-image: url('../img/common/icon_link04.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
	&:hover {
		background-color: $kurashiColor01;
		a {
			color: #ffffff;
			&:before {
				background-image: url('../img/common/icon_link02.png');
			}
		}
	}
}

/*葬祭*/
.c-sosaiBtn01 {
	height: 56px;
	margin: 0 auto;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 3.4;
	text-align: center;
	border: solid 2px $sosaiNaviColor02;
	@include radius(3px);
	a {
		width: 100%;
		height: 100%;
		display: block;
		color: $sosaiNaviColor02;
		&:hover {
			opacity: 1;
		}
		&:after {
			width: 7px;
			height: 14px;
			margin: -3px 0 0 10px;
			content: '';
			background-image: url('../img/common/icon_arrow-blue04.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
			transition: 0.3s ease-in-out;
		}
	}
	&:hover {
		a {
			opacity: 1;
			background-color: $sosaiNaviColor02;
			color: #ffffff;
			&:after {
				background-image: url('../img/common/icon_arrow-white03.png');
			}
		}
	}
}
.c-sosaiBtn02 {
	height: 56px;
	font-size: 1.7rem;
	font-weight: bold;
	line-height: 3.05;
	text-align: center;
	display: inline-block;
	border: solid 2px $sosaiNaviColor02;
	@include radius(3px);
	a {
		width: 100%;
		height: 100%;
		padding: 0 80px;
		display: block;
		color: $sosaiNaviColor02;
		background-image: url('../img/common/icon_arrow-blue04.png');
		-webkit-background-size: 7px;
		background-size: 7px;
		background-repeat: no-repeat;
		background-position: 95% center;
		transition: 0.3s ease-in-out;
		&:hover {
			opacity: 1;
		}
	}
	&:hover {
		a {
			background-color: $sosaiNaviColor02;
			background-image: url('../img/common/icon_arrow-white03.png');
			color: #ffffff;
		}
	}
}
.c-sosaiBtn03 {
	display: block;
	width: 490px;
	font-size: 1.9rem;
	font-weight: bold;
	background-color: $sosaiBtnColor01;
	box-shadow: 0 3px 0 #004e91;
	@include radius(3px);
	transition: 0.3s ease-in-out;
	a {
		width: 100%;
		height: 100%;
		padding: 25px;
		color: #ffffff;
		display: block;
		box-sizing: border-box;
		&:hover {
			opacity: 1;
		}
		&:before {
			width: 27px;
			height: 21px;
			margin: -5px 10px 0 0;
			content: '';
			background-image: url('../img/common/icon_mail02.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
	&:hover {
		box-shadow: none;
		transform: translate(0, 3px);
	}
}

.c-sosaiBtn04 {
	height: 30px;
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 2.14;
	text-align: center;
	display: inline-block;
	border: solid 2px $sosaiNaviColor02;
	@include radius(3px);
	@include tr3d0;
	a {
		width: 100%;
		height: 100%;
		padding: 0 15px;
		display: block;
		color: $sosaiNaviColor02;
		background-color: #ffffff;
		transition: 0.3s ease-in-out;
	}
	&:hover {
		a {
			background-color: $sosaiNaviColor02;
			color: #ffffff;
		}
	}
}
.c-sosaiBtn05 {
	width: 490px;
	padding: 25px;
	font-size: 1.9rem;
	font-weight: bold;
	background-color: $sosaiNaviColor02;
	box-shadow: 0 3px 0 #20656e;
	transition: 0.3s ease-in-out;
	@include radius(3px);
	@include tr3d0;
	a {
		width: 100%;
		height: 100%;
		color: #ffffff;
		display: block;
		&:hover {
			opacity: 1;
		}
		&:before {
			width: 24px;
			height: 27px;
			margin: -5px 10px 0 0;
			content: '';
			background-image: url('../img/common/icon_file01.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
	}
	&:hover {
		transform: translate(0, 3px);
		box-shadow: none;
	}
	&--price {
		a {
			&:before {
				width: 25px;
				height: 29px;
				background-image: url('../img/common/icon_dentaku01.png');
			}
		}
	}
}
.c-sosaiBtn06 {
	display: block;
	width: 290px;
	margin: 0 auto;
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 1.28;
	background-color: $sosaiBtnColor01;
	box-shadow: 0 3px 0 #004e91;
	@include radius(3px);
	transition: 0.3s ease-in-out;
	a {
		width: 100%;
		height: 100%;
		padding: 8px 30px;
		color: #ffffff;
		display: flex;
		text-align: left;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		@include tr3d0;
		&:before {
			width: 27px;
			height: 21px;
			margin-right: 10px;
			content: '';
			background-image: url('../img/common/icon_mail02.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: block;
		}
		&:hover {
			opacity: 1;
		}
	}
	&--search {
		a {
			padding: 15px 30px;
			&:before {
				width: 23px;
				height: 23px;
				background-image: url('../img/common/icon_search08.png');
			}
		}
	}
	&:hover {
		box-shadow: none;
		transform: translate(0, 3px);
	}
}
.c-sosaiBtn07 {
	height: 46px;
	margin: 0 auto;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 3.28;
	text-align: center;
	border: solid 2px $sosaiNaviColor02;
	@include radius(3px);
	a {
		width: 100%;
		height: 100%;
		padding: 0 30px;
		display: block;
		color: $sosaiNaviColor02;
		&:hover {
			opacity: 1;
		}
		&:after {
			width: 7px;
			height: 14px;
			margin: -3px 0 0 10px;
			content: '';
			background-image: url('../img/common/icon_arrow-blue04.png');
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
			transition: 0.3s ease-in-out;
		}
	}
	&:hover {
		a {
			opacity: 1;
			background-color: $sosaiNaviColor02;
			color: #ffffff;
			&:after {
				background-image: url('../img/common/icon_arrow-white03.png');
			}
		}
	}
}
.c-sosaiBtn08 {
	padding: 20px;
	font-size: 1.8rem;
	font-weight: bold;
	background-color: $sosaiNaviColor02;
	box-shadow: 0 3px 0 #20656e;
	transition: 0.3s ease-in-out;
	@include radius(3px);
	@include tr3d0;
	a {
		width: 100%;
		height: 100%;
		color: #ffffff;
		display: block;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			opacity: 1;
		}
		&:after {
			width: 8px;
			height: 15px;
			margin: -3px 0 0 20px;
			content: '';
			background-image: url('../img/common/icon_arrow-white03.png');
			-webkit-background-size: cover;
			background-size: cover;
			transform: rotate(90deg);
		}
	}
	&:hover {
		transform: translate(0, 3px);
		box-shadow: none;
	}
}

@include sp {
	/*サービスサイト SP*/
	.c-serviceBtn01 {
		width: 100%;
		min-width: 200px;
		margin: 0 auto;
	}

	.c-serviceBtn02 {
		width: 100%;
		min-width: 200px;
		margin: 0 auto;
		font-size: 1.4rem;
		line-height: 1.7;
		a {
			padding: 3px 20px;
		}
	}

	.c-serviceBtn03 {
		width: 100%;
		min-width: 200px;
		padding: 12px 25px 10px 56px;
		font-size: 1.6rem;
		line-height: 1.5;
		letter-spacing: -2px;
		font-weight: bold;
		-webkit-background-size: 7px;
		background-size: 7px;
		border-width: 2px;
		&.c-serviceBtn03--fukidashi {
			a {
				&:before {
					width: 20px;
					height: 16px;
				}
			}
		}
	}

	/*チケット sp*/
	.c-ticketBtn01 {
		width: 50%;
		min-width: 115px;
		height: 32px;
		a {
			font-size: 1.2rem;
			line-height: 2.7;
		}
	}
	.c-ticketBtn02 {
		width: 100%;
		font-size: 1.4rem;
	}
	.c-ticketBtn03 {
		width: 100%;
		font-size: 1.4rem;
	}

	/*保険 sp*/
	.c-hokenBtn01 {
		width: 50%;
		min-width: 116px;
		a {
			&:after {
				margin: -3px 0 0 5px;
			}
		}
	}
	.c-hokenBtn05 {
		width: 100%;
		max-width: 290px;
	}

	/*リフォーム SP*/
	.c-reformBtn01 {
		width: 50%;
		min-width: 115px;
		height: 32px;
		a {
			font-size: 1.2rem;
			line-height: 2.7;
		}
	}
	.c-reformBtn02 {
		width: 75%;
		min-width: 230px;
		height: auto;
		font-size: 1.3rem;
		line-height: 2.73;
		padding: 4px 0;
		border: solid 1px $reformBtnColor08;
	}
	.c-reformBtn03 {
		max-width: 365px;
		width: 100%;
		height: 60px;
		font-size: 1.5rem;
	}

	/*くらしのサービス SP*/
	.c-kurashiBtn01 {
		width: 50%;
		min-width: 190px;
		a {
			&:after {
				margin: -3px 0 0 5px;
			}
		}
	}
	.c-kurashiBtn02 {
		width: 50%;
		min-width: 190px;
		a {
			&:after {
				margin: -3px 0 0 5px;
			}
		}
	}

	/*葬祭 SP*/
	.c-sosaiBtn01 {
		height: 45px;
		line-height: 3.4;
		a {
			&:after {
				margin: -3px 0 0 5px;
			}
		}
	}
	.c-sosaiBtn02 {
		width: 100%;
		height: auto;
		font-size: 1.5rem;
		line-height: 1.5;
		a {
			padding: 10px 40px;
		}
	}
	.c-sosaiBtn03 {
		width: 100%;
		max-width: 490px;
		font-size: 1.6rem;
		a {
			padding: 15px;
			&:before {
				width: 20px;
				height: 16px;
				margin: -3px 5px 0 0;
			}
		}
	}
	.c-sosaiBtn04 {
		height: auto;
		font-size: 1.2rem;
		line-height: 2;
	}
	.c-sosaiBtn05 {
		width: 100%;
		max-width: 490px;
		padding: 15px;
		font-size: 1.6rem;
		a {
			&:before {
				width: 20px;
				height: 22px;
				margin: -3px 5px 0 0;
			}
		}
		&--price {
			a {
				&:before {
					width: 20px;
					height: 23px;
				}
			}
		}
	}
	.c-sosaiBtn06 {
		max-width: 80%;
		a {
			padding: 8px 15px;
			&:before {
				width: 20px;
				height: 16px;
			}
		}
		&--search {
			a {
				&:before {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	.c-sosaiBtn07 {
		height: 40px;
		line-height: 2.85;
		a {
			padding: 0 15px;
			&:after {
				margin: -3px 0 0 5px;
			}
		}
	}
	.c-sosaiBtn08 {
		padding: 15px;
		font-size: 1.6rem;
		a {
			&:after {
				margin: -3px 0 0 15px;
			}
		}
	}
}

/*
------------------------------------------------------------*/

/*コンポーネント
------------------------------------------------------------*/
.c-btn01 {
	display: block;
	color: #2e95a3;
	background: #fff;
	font-size: 1.6rem;
	font-weight: 500;
	border-radius: 2px;
	border: 2px solid #2e95a3;
	position: relative;
	transition: 0.3s ease-in-out;
	&:after {
		content: '';
		position: absolute;
		background: url(../img/component/arrow01_blue.png);
		width: 7px;
		height: 14px;
		right: 25px;
		top: 50%;
		transform: translateY(-50%);
		transition: 0.3s ease-in-out;
	}
	&:hover {
		opacity: 1;
		color: #fff;
		background: #2e95a3;
		&:after {
			background: url(../img/component/arrow01_white.png);
		}
	}
	@include sp {
		display: table;
		margin: 0 auto;
		width: 100% !important;
		font-size: 1.3rem;
		text-align: center;
	}
}
/*
------------------------------------------------------------*/

/*フォームボタン
------------------------------------------------------------*/
.c-cartBtn {
	display: table;
	margin: 50px auto 0;
	@include sp {
		width: 100%;
	}
	input,
	a,
	span.c-cartBtn__btn05 {
		@include yu_gosick();
		width: 300px;
		height: 60px;
		font-size: 1.8rem;
		line-height: 1;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: relative;
		transition: 0.3s ease-in-out;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		&:after,
		&:before {
			content: '';
			width: 15px;
			height: 15px;
			position: absolute;
			transform: rotate(45deg);
		}
		&:hover {
			opacity: 1;
		}
		@include sp {
			width: 100%;
			height: 40px;
			float: none;
			font-size: 1.4rem;
			display: inline-flex;
			&:after,
			&:before {
				width: 12px;
				height: 12px;
			}
		}
	}
	input.c-cartBtn__btn01,
	input.c-cartBtn__btn07 {
		@include yu_gosick();
		background: #fff url('../img/component/icon_101.png') no-repeat center left 20px;
		@include sp {
			background-size: 10px;
		}
		&:hover {
			background: #b2b2b2 url('../img/component/icon_102.png') no-repeat center left 20px;
			@include sp {
				background-size: 10px;
			}
		}
	}
	input.c-cartBtn__btn02,
	input.c-cartBtn__btn08 {
		@include yu_gosick();
		background: #ff6e03 url('../img/component/icon_103.png') no-repeat center right 20px;
		@include sp {
			background-size: 10px;
		}
		&:hover {
			background: #fff url('../img/component/icon_104.png') no-repeat center right 20px;
			@include sp {
				background-size: 10px;
			}
		}
	}
	input.c-cartBtn__btn10 {
		@include yu_gosick();
		background: #fff url('../img/common/icon_maru-sankaku05.png') no-repeat center left 20px;
		background-size: 20px;
	}
	input.c-cartBtn__btn11 {
		@include yu_gosick();
		background: $kurashiBtnColor01 url('../img/common/icon_maru-sankaku03.png') no-repeat center right 20px;
		background-size: 20px;
		padding-right: 40px;
		@include sp {
			background-size: 15px;
			justify-content: center;
			padding-left: 0;
		}
	}
	&__fixed {
		max-width: 280px;
		margin: 0 auto 0;
		position: fixed;
		bottom: 0;
		right: 40px;
		z-index: 80;
		padding-bottom: 20px;
		opacity: 0;
		visibility: hidden;
		transition: 0.2s ease-in-out;

		&.is-active{
			opacity: 1;
			visibility: visible;
		}

		@include sp{
			position: fixed !important;
			right: 0 !important;
			bottom: 0;
			z-index: 100;
			max-width: 100%;
			background: rgba($color: #000000, $alpha: 0.3);
			padding: 10px;
		}
	}
	&__btn01 {
		float: left;
		margin-right: 20px;
		color: #232934;
		border: 2px solid #b2b2b2;
		background: #fff;
		&:before {
			left: 20px;
			border-bottom: 2px solid #b2b2b2;
			border-left: 2px solid #b2b2b2;
		}
		&:hover {
			color: #fff;
			background: #b2b2b2;
			&:before {
				border-bottom: 2px solid #fff;
				border-left: 2px solid #fff;
			}
		}
		@include sp {
			float: none;
		}
	}
	&__btn02 {
		float: right;
		color: #fff;
		background: #ff6e03;
		border: 2px solid #ffa257;
		&:after {
			right: 20px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
		}
		&:hover {
			color: #ff6e03;
			background: #fff;
			&:after {
				border-top: 2px solid #ff6e03;
				border-right: 2px solid #ff6e03;
			}
		}
		@include sp {
			margin-bottom: 10px;
			&:after {
				right: 15px;
			}
		}
	}
	&__btn02b {
		float: right;
		color: #fff;
		background: #ff6e03;
		border: 2px solid #ffa257;
		border-radius: 0;
		&:after {
			content: none;
		}
		&:hover {
			color: #ff6e03;
			background: #fff;
			&:after {
				border-top: 2px solid #ff6e03;
				border-right: 2px solid #ff6e03;
			}
		}
		@include sp {
			margin-bottom: 10px;
			&:after {
				right: 15px;
			}
		}
	}
	&__btn03 {
		color: #232934;
		border: 2px solid #b2b2b2;
		&:hover {
			color: #fff;
			background: #b2b2b2;
		}
	}
	input.c-cartBtn__btn04 {
		@include yu_gosick();
		background: #fff url('../img/common/icon_arrow-gray02.png') no-repeat center right 20px;
		background-size: 6px;
		&:hover {
			background: #b2b2b2 url('../img/common/icon_arrow-white02.png') no-repeat center right 20px;
			background-size: 6px;
		}
	}

	&__btn04 {
		width: 300px !important;
		float: left;
		margin-right: 20px;
		color: #46483c;
		background: #fff;
		border: 2px solid #b2b2b2;
		@include radius(100px);
		&:before {
			width: 6px !important;
			height: 10px !important;
			background-image: url('../img/common/icon_arrow-gray02.png');
			-webkit-background-size: cover;
			background-size: cover;
			border: none;
			transform: rotate(180deg) !important;
			left: 20px;
		}
		&:hover {
			color: #fff;
			background: #b2b2b2;
			&:before {
				background-image: url('../img/common/icon_arrow-white02.png');
				border: none;
			}
		}
		@include sp {
			width: 100% !important;
			margin-bottom: 10px;
			float: none;
			&:before {
				left: 15px;
			}
		}
		&--2 {
			width: 300px !important;
			height: 46px !important;
			font-size: 1.4rem !important;
			@include sp {
				width: 100% !important;
				height: 40px !important;
			}
		}
		&--back {
			width: 300px !important;
			float: left;
			margin-right: 20px;
			color: #46483c;
			background: #fff;
			border: 2px solid #b2b2b2;
			@include radius(100px);
			&:before {
				width: 6px !important;
				height: 10px !important;
				background-image: url('../img/common/icon_arrow-gray02.png');
				-webkit-background-size: cover;
				background-size: cover;
				border: none;
				transform: rotate(0deg) !important;
				right: 20px;
			}
			&:hover {
				color: #fff;
				background: #b2b2b2;
				&:before {
					background-image: url('../img/common/icon_arrow-white02.png');
					border: none;
				}
			}
		}
	}
	input.c-cartBtn__btn05 {
		@include yu_gosick();
		background: #ff5b4c url('../img/common/icon_arrow-white02.png') no-repeat center right 20px;
		background-size: 6px;
		&:hover {
			background: #fff url('../img/common/icon_arrow-orange06.png') no-repeat center right 20px;
			background-size: 6px;
		}
	}
	&__btn05 {
		width: 300px !important;
		float: right;
		color: #fff;
		background: #ff5b4c;
		border: 2px solid #ff5b4c;
		@include radius(100px);
		&:after {
			width: 6px !important;
			height: 10px !important;
			background-image: url('../img/common/icon_arrow-white02.png');
			-webkit-background-size: cover;
			background-size: cover;
			border: none;
			transform: none !important;
			right: 20px;
		}
		&:hover {
			color: #ff5b4c;
			background: #fff;
			&:after {
				background-image: url('../img/common/icon_arrow-orange06.png');
				border: none;
			}
		}
		@include sp {
			width: 100% !important;
			margin-bottom: 10px;
			&:after {
				right: 15px;
			}
		}
		&--gray {
			background: $gray03;
			border-color: $gray03;

			@include hover{
				background: #ffffff;
				color: $gray03;
			}

			&::before,
			&::after{
				display: none;
			}
		}
	}
	&__btn06 {
		color: #46483c;
		border: 2px solid #b2b2b2;
		@include radius(100px);
		&:hover {
			color: #fff;
			background: #b2b2b2;
		}
	}
	&__btn07 {
		float: left;
		margin-right: 20px;
		color: $gray01;
		border: 2px solid #b2b2b2;
		background: #fff;
		&:before {
			width: 10px !important;
			height: 10px !important;
			left: 20px;
			border-bottom: 2px solid #b2b2b2;
			border-left: 2px solid #b2b2b2;
		}
		&:hover {
			color: #fff;
			background: #b2b2b2;
			&:before {
				border-bottom: 2px solid #fff;
				border-left: 2px solid #fff;
			}
		}
		@include sp {
			margin-bottom: 10px;
			float: none;
			&:before {
				left: 15px;
			}
		}
	}
	&__btn08 {
		float: right;
		color: #fff;
		background: $reformBtnColor01;
		border: 2px solid $reformBtnColor01;
		&:after {
			width: 10px !important;
			height: 10px !important;
			right: 20px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
		}
		&:hover {
			color: $reformBtnColor01;
			background: #fff;
			&:after {
				border-top: 2px solid $reformBtnColor01;
				border-right: 2px solid $reformBtnColor01;
			}
		}
		@include sp {
			margin-bottom: 10px;
			&:after {
				right: 15px;
			}
		}
	}
	&__btn09 {
		color: $gray01;
		border: 2px solid #b2b2b2;
		&:hover {
			color: #fff;
			background: #b2b2b2;
		}
	}
	&__btn10 {
		margin-right: 20px;
		float: left;
		color: $kurashiTextColor01;
		border: 2px solid #b2b2b2;
		box-shadow: 0 3px 0 rgba($kurashiTextColor03, 0.2);
		background: #fff;
		@include radius(4px);
		@include tr3d0;
		&:before {
			width: 20px !important;
			height: 20px !important;
			background-image: url('../img/common/icon_maru-sankaku05.png');
			-webkit-background-size: cover;
			background-size: cover;
			border: none;
			transform: rotate(180deg) !important;
			left: 20px;
		}
		&:hover {
			box-shadow: none;
			transform: translate(0, 3px);
		}
		@include sp {
			width: 100% !important;
			margin-bottom: 10px;
			float: none;
			&:before {
				left: 15px;
			}
		}
	}
	&__btn11 {
		float: right;
		color: #ffffff;
		background-color: $kurashiBtnColor01;
		border: 2px solid $kurashiBtnColor01;
		box-shadow: 0 3px 0 rgba($kurashiBtnColor01, 0.75);
		@include radius(4px);
		@include tr3d0;
		&:after {
			width: 20px !important;
			height: 20px !important;
			background-image: url('../img/common/icon_maru-sankaku03.png');
			-webkit-background-size: cover;
			background-size: cover;
			border: none;
			right: 20px;
			transform: none !important;
		}
		&:hover {
			box-shadow: none;
			transform: translate(0, 3px);
		}
		@include sp {
			width: 100% !important;
			margin-bottom: 10px;
			float: none;
			&:after {
				right: 15px;
			}
		}
	}
	&__btn12 {
		color: $kurashiTextColor01;
		border: 2px solid #b2b2b2;
		box-shadow: 0 3px 0 rgba($kurashiTextColor03, 0.2);
		@include radius(4px);
		@include tr3d0;
		&:hover {
			box-shadow: none;
			transform: translate(0, 3px);
		}
		@include sp {
			width: 100% !important;
			margin-bottom: 10px;
			float: none;
		}
		&--2 {
			width: 370px !important;
			@include sp {
				width: 100% !important;
			}
		}
	}
	input.c-cartBtn__btn13 {
		@include yu_gosick();
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	&__btn13 {
		color: #ffffff;
		background-color: $sosaiNaviColor02;
		border: 2px solid $sosaiNaviColor02;
		@include radius(3px);
		&:after {
			width: 7px !important;
			height: 14px !important;
			background-image: url('../img/common/icon_arrow-white03.png');
			-webkit-background-size: cover;
			background-size: cover;
			border: none;
			right: 20px;
			transform: none !important;
		}
		&:hover {
			color: $sosaiNaviColor02;
			background-color: #ffffff;
			&:after {
				background-image: url('../img/common/icon_arrow-blue04.png');
			}
		}
	}
	&__btn14 {
		color: $sosaiNaviColor02;
		background-color: #ffffff;
		border: 2px solid $sosaiNaviColor02;
		@include radius(3px);
		&:hover {
			color: #ffffff;
			background-color: $sosaiNaviColor02;
		}
	}
	&__btn15 {
		float: right;
		color: #ffffff;
		background-color: $kurashiBtnColor01;
		border: 2px solid $kurashiBtnColor01;
		box-shadow: 0 3px 0 rgba($kurashiBtnColor01, 0.75);
		@include radius(4px);
		@include tr3d0;
		&:hover {
			box-shadow: none;
			transform: translate(0, 3px);
		}
		@include sp {
			width: 100% !important;
			margin-bottom: 10px;
			float: none;
		}
	}
	&__btn16 {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        background-color: #FA8246;
        border-radius: 9999px;
        color: #fff;
        font-size: 1.6rem;
        font-weight: 700;
        white-space: nowrap;
        cursor: pointer;
        @include pc {
            padding: 20px 40px;
            font-size: 1.8rem;
            transition: .3s ease-in-out;
            &:hover {
                opacity: .8;
            }
        }
        &__label {
            flex: 1;
            display: flex;
            justify-content: center;
        }
        &__icon {
            min-width: 12px;
            width: 12px;
            height: 12px;
            @include pc {
                min-width: 16px;
                width: 16px;
                height: 16px;
            }
            svg {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
	}
}

.c-checkbox {
	&__text {
		cursor: pointer;
	}
	input[type='checkbox'] {
		position: absolute;
		display: none;
	}
	input[type='checkbox'][disabled] {
		cursor: not-allowed;
	}
	input[type='checkbox'] + label {
		position: relative;
		display: block;
		padding-left: 26px;
		cursor: pointer;
		vertical-align: middle;
		font-size: 1.6rem;
		color: #111111;
		line-height: 1.8;
		display: inline-block;
		@include sp {
			font-size: 1.3rem;
		}
	}
	input[type='checkbox'] + label:before {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		display: inline-block;
		width: 16px;
		height: 16px;
		content: '';
		border: 1px solid #c7c7c7;
		border-radius: 3px;
	}
	input[type='checkbox'] + label:after {
		position: absolute;
		display: none;
		content: '';
	}
	input[type='checkbox'][disabled] + label:hover,
	input[type='checkbox'][disabled] + label:before,
	input[type='checkbox'][disabled] + label:after {
		cursor: not-allowed;
	}
	input[type='checkbox']:checked + label:before {
		animation-name: none;
	}
	input[type='checkbox']:checked + label:after {
		display: block;
	}
	input[type='checkbox'] + label:after {
		top: 50%;
		left: 6px;
		transform: translateY(-50%);
		box-sizing: border-box;
		width: 5px;
		height: 11px;
		transform: rotate(45deg);
		border-width: 2px;
		border-style: solid;
		border-color: #fff;
		border-top: 0;
		border-left: 0;
		margin-top: -7px;
	}
	input[type='checkbox']:checked + label:before {
		border: #9ac985;
		background: #9ac985;
	}
	&--hoken {
		input[type='checkbox']:checked + label:before {
			border: $hokenColor01;
			background: $hokenColor01;
		}
	}
	&--reform {
		input[type='checkbox']:checked + label:before {
			border: $reformColor01;
			background: $reformColor01;
		}
	}
	&--kurashi {
		input[type='checkbox']:checked + label:before {
			border: $kurashiColor01;
			background: $kurashiColor01;
		}
	}
	&--sosai {
		input[type='checkbox']:checked + label:before {
			border: $sosaiColor01;
			background: $sosaiColor01;
		}
	}
}
/*
------------------------------------------------------------*/
.c-contactBtn {
	width: 480px;
	margin: 0 auto;
	padding: 32px 0 31px;
	text-align: center;
	border: 2px solid #669900;
	&__txt01 {
		font-size: 1.6rem;
		font-weight: bold;
		color: #232934;
	}
	&__txt02 {
		padding: 14px 0 6px;
	}
	&__txt03 {
		font-size: 1.6rem;
		color: #232934;
	}
	@include sp {
		width: calc(100% - 30px);
		margin: 0 auto 15px;
		padding: 14px 20px 17px 20px;
		&__txt01 {
			font-size: 1.5rem;
			padding: 0 5px 2px;
		}
		&__txt03 {
			font-size: 1.1rem;
			color: #fff;
			background: #4b5870;
			border: 3px solid #939ba9;
			padding: 12px 10px 7px;
			.txt-sp {
				font-size: 1.4rem;
				font-weight: bold;
				line-height: 1;
				padding: 0 0 8px 0;
				img {
					margin-top: -2px;
					display: inline-block;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-contactBtn02 {
	@include sp {
		max-width: 260px;
		margin: 0 auto;
		color: #fff;
		background: #2e95a3;
		display: flex;
		align-items: center;
		text-align: left;
		padding: 7px 10px 6px 68px;
		border-radius: 5px;
		box-shadow: 0 3px #20656e;
		position: relative;
		&__cont {
			display: block;
		}
		&:before {
			content: '';
			position: absolute;
			background: url('../img/common/icon_tel07.png');
			background-size: 100%;
			width: 26px;
			height: 26px;
			left: 29px;
			top: 50%;
			transform: translate(0, -50%);
		}
	}
}

.c-contactBtn03 {
	display: flex;
	align-items: center;
	justify-content: center;

	@include sp {
		width: 100%;
		flex-direction: column-reverse;
	}

	&__btn {
		width: 300px;
		color: #ffffff;
		background-color: #FF6E03;
		border: 2px solid #FFA257;
		height: 60px;
		font-size: 1.8rem;
    line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		transition: 0.3s ease-in-out;

		@include sp {
			width: 100%;
		}

		&:after {
			content: '';
			width: 15px;
			height: 15px;
			position: absolute;
			transform: rotate(45deg);
			right: 20px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
		}

		&:hover {
			color: #ff6e03;
			background: #fff;
			cursor: pointer;

			&::after {
				border-top-color:#ff6e03;
				border-right-color: #ff6e03;
			}

		}

		&.is-rounded {
			border-radius: 30px;

			input {
				border-radius: 30px;
			}
		}

		&.is-style1 {
			color: #232934;
			background-color: #ffffff;
			border-color: #B1B1B1;

			&::before {
				content: '';
				width: 15px;
				height: 15px;
				position: absolute;
				transform: rotate(45deg);
				left: 20px;
				border-bottom: 2px solid #b2b2b2;
				border-left: 2px solid #b2b2b2;
			}

			&::after {
				content: none;
			}

			&:hover {
				color: #fff;
				background: #b2b2b2;

				&::before {
					border-bottom-color: #fff;
					border-left-color: #fff;
				}

				// input {
				// 	color: #fff;
				// 	background: #b2b2b2;
				// }
			}

			// input {
			// 	color: #fff!important;
			// 	background: #b2b2b2!important;
			// }
		}

		& + .c-contactBtn03__btn {
			margin-left: 20px;

			@include sp {
				margin-left: 0;
				margin-bottom: 10px;
			}
		}

		input {
			background: inherit!important;
			color: inherit!important;
			font-weight: bold;
			cursor: inherit;
			width: 100%;
			height: 100%!important;
			border: none;
		}
	}
	&__submit {
		cursor: pointer!important;
		pointer-events: unset!important;
	}
}

.c-btn02 {
	display: block;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
	border-radius: 999px;
	overflow: hidden;

	@include sp {
		max-width: 335px;
		width: 100%;
	}

	// .c-btn02:after .
	&:after {
		width: 7px;
		height: 100%;
		right: 30px;
		top: 0;
		background: url(../img/hoken/common/icon-btn02-white.svg) no-repeat center / 7px auto;
		content: '';
		position: absolute;
		transition: background-image .3s ease-in-out;
		pointer-events: none;

		@include sp {
			right: 17px;
			width: 6px;
			background-size: 6px auto;
		}
	}

	&+.c-btn02 {
		margin-top: 20px;

		@include sp {
			margin-top: 10px;
		}
	}

	@include hover {
		.c-btn02 {
			&__text {
				background-color: #fff !important;
				color: #FF5B4C !important;
				opacity: 1;
			}
		}

		&:after {
			background-image: url(../img/hoken/common/icon-btn02-orange.svg);
		}
	}

	// .c-btn02__text .
	&__text {
		padding: 20px 35px 19px 38px !important;
		width: 100% !important;
		display: block;
		box-shadow: none;
		outline: none;
		cursor: pointer;
		border-radius: 999px;
		overflow: hidden;
		font-size: 1.8rem !important;
		line-height: 1 !important;
		color: #fff !important;
		font-weight: 700;
		@include yu_gosick;
		background-color: #FF5B4C !important;
		border: 2px solid #FF5B4C !important;
		transition: .3s ease-in-out;
		text-align: center;
		-webkit-appearance: none;
		border-radius: 999px !important;

		@include sp {
			padding: 12px 35px 10px 38px !important;
			font-size: 1.4rem !important;
		}

		&:focus,
		&:hover {
			box-shadow: none;
			outline: none;
		}
	}

	&.is-style1 {
		// .c-btn02.is-style1:after .
		&:after {
			left: 30px;
			right: auto !important;
			background-image: url(../img/hoken/common/icon-btn02-gray.svg);

			@include sp {
				left: 17px;
			}
		}

		@include hover {
			&:after {
				background-image: url(../img/hoken/common/icon-btn02-white-left.svg);
			}

			.c-btn02 {
				&__text {
					color: #fff !important;
					background-color: #B2B2B2 !important;
				}
			}
		}

		.c-btn02 {
			// .c-btn02.is-style1 .c-btn02__text .
			&__text {
				padding-left: 45px !important;
				padding-right: 44px !important;
				color: #46483C !important;
				border-color: #B2B2B2 !important;
				background-color: #fff !important;

				@include sp {
					padding-left: 35px !important;
					padding-right: 34px !important;
				}
			}
		}
	}
}
