@use '../../0_base' as *;

.p-pokke{
    font-weight: bold;
    font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

    &__top{
        display: none;
        position: fixed;
        bottom: calc((60/375)* 100vw);
        right: calc((20/375)* 100vw);
        width: calc((44/375)* 100vw);
        height: calc((44/375)* 100vw);
        z-index: 99;
    }

    @media screen and (min-width:600px){
        .p-pokke__top{
            width: 60px;
            height: 60px;
            bottom: 60px;
            right: 60px;

            img{
                width: 100%;
            }
        }
    }

    .c-mainvisual{
        background: url('/img/kurashi/lp/pokke/pokke_img02.jpg') no-repeat bottom center;
        height: calc((428/375)* 100vw);
        background-size: cover;

        &__inner{
            padding: calc((27/375)* 100vw) 0 100px;
        }

        &__title{
            text-align: center;
            width: calc((160/375)* 100vw);
            height: calc((125/375)* 100vw);
            font-size: calc((24/375)* 100vw);
            line-height: calc((32/375)* 100vw);
            background: url('/img/kurashi/lp/pokke/pokke_img01.png') no-repeat center;
            margin: 0 auto calc((28/375)* 100vw);
            padding: calc((54/375)* 100vw) 0 10px;
            background-size: cover;
        }

        &__text{
            font-size: calc((12/375)* 100vw);
            line-height: calc((21/375)* 100vw);
            text-align: center;
        }
    }

    .c-title01{
        background: #000000;
        color: #fff;
        text-align: center;
        font-size: calc((14/375)* 100vw);
        padding: 1px 0 1px;
    }

    .c-title02{
        text-align: center;
        font-size: calc((26/375)* 100vw);
        line-height: calc((38/375)* 100vw);
        padding: 1px 0 1px;
    }

    .white-space{
        white-space: nowrap;
        position: relative;
    }

    .c-title03{
        position: relative;
        font-size: calc((16/375)* 100vw);
        line-height: calc((22/375)* 100vw);
        text-align: center;
        width: fit-content;
        margin: 0 auto;
        padding: 0 calc((26/375)* 100vw);
        white-space: nowrap;
        
        &::before,
        &::after{
            content: "";
            position: absolute;
            top: calc((-2/375)* 100vw);
            width: calc((25/375)* 100vw);
            height: calc((25/375)* 100vw);
            background-size: calc((25/375)* 100vw) calc((25/375)* 100vw) !important;
        }

        &::before{
            left: 0;
            background: url('/img/kurashi/lp/pokke/pokke_lineL.png') no-repeat center;
        }

        &::after{
            right: 0;
            background: url('/img/kurashi/lp/pokke/pokke_lineR.png') no-repeat center;
        }
    }

    .c-title04{
        position: relative;
        text-align: center;
        background: #FFDD66;
        padding: calc((18/375)* 100vw) 0 calc((8/375)* 100vw);
        background-size: calc((294/375)* 100vw) calc((110/375)* 100vw);
        margin: 0 calc((10/375)* 100vw);

        &::before,
        &::after{
            content: "";
            position: absolute;
            width: calc((40/375)* 100vw);
            height: calc((40/375)* 100vw);
        }

        &::before{
            top: calc((8/375)* 100vw);
            left: calc((-10/375)* 100vw);
            border-top: 1px solid #000000;
            border-left: 1px solid #000000;
        }

        &::after{
            bottom: calc((8/375)* 100vw);
            right: calc((-10/375)* 100vw);
            border-bottom: 1px solid #000000;
            border-right: 1px solid #000000;
        }
        
        span{
            display: block;
        }

        &__1{
            font-size: calc((14/375)* 100vw);
            line-height: calc((21/375)* 100vw);
            margin-bottom: calc((6/375)* 100vw);
        }

        &__2{
            font-size: calc((24/375)* 100vw);
            line-height: calc((36/375)* 100vw);
        }
    }

    .c-title05{
        font-size: calc((24/375)* 100vw);
        line-height: calc((36/375)* 100vw);
    }

    .c-text01{
        position: relative;
        font-size: calc((14/375)* 100vw);
        line-height: calc((23/375)* 100vw);
        padding-left: calc((40/375)* 100vw) !important;

        &::before{
            content: "";
            position: absolute;
            top: 47%;
            transform: translateY(-50%);
            left: 0;
            width: calc((27/375)* 100vw);
            height: calc((26/375)* 100vw);
            background: url('/img/kurashi/lp/pokke/pokke_img03.svg') no-repeat center;
            background-size: calc((27/375)* 100vw) calc((26/375)* 100vw) !important;
        }
    }

    .c-text02{
        font-size: calc((14/375)* 100vw);
        line-height: calc((23/375)* 100vw);
        font-weight: 500;
    }

    .c-listtext{
        .c-text01{
            padding: calc((10/375)* 100vw) 0 calc((12/375)* 100vw);
            border-bottom: 1px dashed #000000;
            &:first-child{
                border-top: 1px dashed #000000;
            }
        }
    }

    .c-blcktext{
        background: #FFF6E1;
        padding: calc((20/375)* 100vw) calc((20/375)* 100vw) calc((17/375)* 100vw);
        text-align: center;

        &__icon{
            img{
                width: calc((65/375)* 100vw);
            }
        }

        &__title{
            font-size: calc((16/375)* 100vw);
            line-height: calc((36/375)* 100vw);
            margin: calc((8/375)* 100vw) 0 calc((5/375)* 100vw);
        }

        .c-text02{
            text-align: left;
        }
    }

    .c-button01{
        position: relative;
        a{
            display: block;
            position: relative;
            border: 1px solid #000000;
            font-size: calc((16/375)* 100vw);
            line-height: 1;
            padding: calc((15/375)* 100vw) 0 calc((14/375)* 100vw);
            text-align: center;
            border-radius: calc((24/375)* 100vw);
            color: #000000;
            z-index: 1;
            margin-left: 4px;
            top: 4px;

            &::after{
                content: "";
                position: absolute;
                top: 48%;
                transform: translateY(-50%);
                right: calc((26/375)* 100vw);
                width: calc((37/375)* 100vw);
                height: calc((9/375)* 100vw);
                background: url('/img/kurashi/lp/pokke/pokke_img08.svg') no-repeat center;
                background-size: calc((37/375)* 100vw) calc((9/375)* 100vw);
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFDD66;
            border-radius: calc((24/375)* 100vw);
        }
    }

    .c-blck01{
        background: #fff;
        border: 1px solid #000000;
        padding: calc((30/375)* 100vw) 0 calc((33/375)* 100vw);

        & + .c-blck01{
            margin-top: calc((30/375)* 100vw);
        }

        &__01{
            padding: 0 calc((42/375)* 100vw);
        }

        &__02{
            padding: 0 calc((20/375)* 100vw);
        }

        .c-title01{
            margin-bottom: calc((16/375)* 100vw);
        }

        .c-title02{
            margin-bottom: calc((29/375)* 100vw);
        }

        .c-title03{
            margin-bottom: calc((30/375)* 100vw);
        }

        .c-listtext{
            position: relative;

            &::after{
                content: "";
                position: absolute;
                bottom: calc((-40/375)* 100vw);
                left: 50%;
                transform: translateX(-50%);
                width: calc((32/375)* 100vw);
                height: calc((32/375)* 100vw);
                background: url('/img/kurashi/lp/pokke/pokke_arrow.png') no-repeat center;
                background-size: calc((32/375)* 100vw) calc((32/375)* 100vw);
            }
        }

        .c-title04{
            position: relative;
            margin-top: calc((60/375)* 100vw);
            margin-bottom: calc((28/375)* 100vw);
        }

        .c-listimg{
            margin: calc((27/375)* 100vw) 0 calc((30/375)* 100vw);
            text-align: center;

           
        }

        &__img{
            & + .c-blck01__img{
                margin-top: calc((20/375)* 100vw);
            }

            img{
                width: 100%;
            }
        }

        .c-button01{
            margin-top: calc((30/375)* 100vw);
        }

        @media screen and (min-width:600px){
            .c-blck01__img{
                width: 50%;
                padding: 0 20px;

                & + .c-blck01__img{
                    margin-top: 0;
                }
            }

            .c-listimg{
                display: flex;
                margin: calc((27/375)* 100vw) -20px calc((30/375)* 100vw);
            }
        }
    }

    .c-lst01{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: calc((-18/375)* 100vw);

        &__item{
            width: calc(100% / 3);
            padding: 0 calc((10/375)* 100vw);
            margin-bottom: calc((18/375)* 100vw);
        }

        &__text{
            font-size: calc((14/375)* 100vw);
            line-height: calc((20/375)* 100vw);
            margin-top: calc((10/375)* 100vw);
            color: #000000;
        }
    }

    &01{
        background: #FDF0D5;
        padding: calc((30/375)* 100vw) calc((20/375)* 100vw);

        &__inner{

        }

        .c-blck01{

            &:nth-child(1){
                .c-listtext{
                    .c-text01{
                        &:nth-child(2){
                            padding: calc((10/375)* 100vw) 0 calc((6/375)* 100vw);
                        }
                        
                        &:nth-child(3){
                            padding: calc((8/375)* 100vw) 0 calc((8/375)* 100vw);
                        }
                    }
                }
            }

            &:nth-child(2){
                .c-listtext{
                    .c-text01{
                        &:first-child{
                            padding: calc((9/375)* 100vw) 0 calc((6/375)* 100vw);
                        }
            
                        &:nth-child(2){
                            padding: calc((8/375)* 100vw) 0 calc((6/375)* 100vw);
                        }
                        
                        &:nth-child(3){
                            padding: calc((9/375)* 100vw) 0 calc((6/375)* 100vw);
                        }
                    }
                }

                .c-title02{
                    .white-space{
                        left: calc((-14/375)* 100vw);
                    }
                }
                
                .c-title04{
                    padding: calc((18/375)* 100vw) 0;
                }
            }

            &:nth-child(3){
                .c-title01{
                    margin-bottom: calc((13/375)* 100vw);
                }
                .c-listtext{
                    .c-text01{
                        &:first-child{
                            padding: calc((8/375)* 100vw) 0 calc((5/375)* 100vw);
                        }
            
                        &:nth-child(2){
                            padding: calc((9/375)* 100vw) 0 calc((7/375)* 100vw);
                        }
                        
                        &:nth-child(3){
                            padding: calc((13/375)* 100vw) 0 calc((9/375)* 100vw);
                        }
                    }
                }

                .c-blcktext{
                    &__title{
                        margin: calc((8/375)* 100vw) 0 calc((6/375)* 100vw);
                    }
                }
            
                .c-title04{
                    margin-top: calc((70/375)* 100vw);
                    margin-bottom: calc((17/375)* 100vw);
                }
            }
        }
    }

    &02{
        padding: calc((28/375)* 100vw) calc((20/375)* 100vw) calc((55/375)* 100vw);
        text-align: center;

        .c-title05{
            margin-bottom: calc((15/375)* 100vw);
        }

        .c-text02{
            line-height: calc((23.8/375)* 100vw);
        }

        .c-lst01{
            margin-top: calc((16/375)* 100vw);
        }

        .c-button01{
            max-width: calc((293/375)* 100vw);
            margin: calc((10/375)* 100vw) auto 0;
        }
    }

    .bold{
        font-weight: bold;
    }

    &-ft{
        text-align: center;
        &__text{
            background: #E2DFD7;
            padding: calc((25/375)* 100vw) 0 calc((18/375)* 100vw);
            font-size: calc((14/375)* 100vw);
            line-height: calc((23.8/375)* 100vw);
            font-weight: 500;
            a{
                color: #000000;
                opacity: 1;
            }
        }

        &__copyright{
            background: #000000;
            color: #fff;
            font-size: calc((10/375)* 100vw);
            padding: calc((19/375)* 100vw) 0 calc((18/375)* 100vw);
        }
    }
}