@use '../../0_base' as *;

/*------------------------------------------------------------
保険 ライフステージ別おすすめ保険
------------------------------------------------------------*/
.p-hokenLifeevent01{
    margin-left: auto;
    margin-right: auto;
    max-width: 325px;
    position: relative;

    @include pc {
        max-width: none;
    }

    &__bg {
        display: grid;
        row-gap: 5px;

        @include pc {
            grid-template-columns: repeat(5, 1fr);
            column-gap: 10px;
        }

        > div {
            background-color: #eee;
            height: 65px;
            @include pc {
                height: 322px;
            }
        }
    }
	&__content01 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        ul {
            padding-left: 10px;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include pc {
                padding-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
                flex-direction: row;
            }
        }
        a {
            padding-left: 15px;
            padding-right: 15px;
            width: 96px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            border: 1.5px solid #41B4E6;
            border-radius: 9999px;
            color: #41B4E6;
            font-size: 18px;
            font-weight: 700;
            @include pc {
                padding-left: 30px;
                padding-right: 30px;
                width: 192px;
                height: 70px;
                border-width: 3px;
                font-size: 20px;
                &:hover {
                    background-color: #41B4E6;
                    color: #fff;
                }
            }
            svg {
                min-width: 15px;
                width: 15px;
                height: 15px;
                @include pc {
                    min-width: 17px;
                    width: 17px;
                    height: 17px;
                }
            }
        }
	}
	&__content02 {
        position: absolute;
        @include sp {
            top: 30px;
            left: 115px;
            ul {
                display: grid;
                row-gap: 3px;
                li {
                    padding-top: 12px;
                    width: 119px;
                    height: 43px;
                    display: flex;
                    flex-direction: column;
                    color: #fff;
                    font-weight: 700;
                    text-align: center;
                    position: relative;
                    line-height: 1.4;

                    &::before,
                    &::after {
                        content: "";
                        width: 50%;
                        height: 100%;
                        background-color: #41B4E6;
                        position: absolute;
                        top: 5px;
                        pointer-events: none;
                    }

                    &::before {
                        left: 0;
                        transform: skewY(8deg);
                    }

                    &::after {
                        right: 0;
                        transform: skewY(-8deg);
                    }

                    > span {
                        position: relative;
                        z-index: 1;
                    }

                    > :nth-child(1) {
                        font-size: 14px;
                    }

                    > :nth-child(2) {
                        color: #E4F7FF;
                        font-size: 10px;
                    }
                }
                >:nth-child(1){
                    padding-top: 6px;
                    height: 37px;
                    background-color: #41B4E6;
                }
                >:nth-child(5){
                    margin-top: 35px;
                }
            }
        }
        @include pc {
            padding-left: 20px;
            padding-right: 28px;
            width: 100%;
            top: 120px;
            left: 0;
            ul {
                display: flex;
                column-gap: 10px;
                li {
                    width: 150px;
                    height: 80px;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-weight: 700;
                    text-align: center;
                    position: relative;
                    //line-height: 1.4;

                    &::before,
                    &::after {
                        content: "";
                        width: 100%;
                        height: 50%;
                        background-color: #41B4E6;
                        position: absolute;
                        left: 5px;
                        pointer-events: none;
                    }

                    &::before {
                        top: 0;
                        transform: skewX(12deg);
                    }

                    &::after {
                        bottom: 0;
                        transform: skewX(-12deg);
                    }

                    > span {
                        position: relative;
                        z-index: 1;
                    }

                    > :nth-child(1) {
                        font-size: 18px;
                    }

                    > :nth-child(2) {
                        color: #E4F7FF;
                        font-size: 14px;
                    }
                }
                >:nth-child(1){
                    width: 142px;
                    background-color: #41B4E6;
                }
                >:nth-child(4){
                    width: 285px;
                }
                >:nth-child(5){
                    margin-left: auto;
                    width: 280px;
                }
            }
        }
	}
	&__content03{
        position: absolute;
        li {
            background-color: #FA8246;
            color: #fff;
            font-weight: 700;
        }
        @include sp {
            top: 90px;
            right: 8px;
            ul {
                display: grid;
                row-gap: 5px;
            }
            li {
                padding: 5px 10px;
                width: 75px;
                font-size: 10px;
                svg {
                    min-width: 15px;
                    width: 15px;
                    height: 15px;
                }
            }
    	}
        @include pc {
            top: 215px;
            left: 174px;
            ul {
                display: flex;
                li {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 10px;
                    font-size: 14px;
                    svg {
                        min-width: 20px;
                        width: 20px;
                        height: 20px;
                    }
                }

                > :nth-child(1){
                    width: 112px;
                }
                > :nth-child(2){
                    margin-left: 24px;
                    width: 168px;
                }
                > :nth-child(3){
                    margin-left: 216px;
                    width: 140px;
                }
                > :nth-child(4){
                    margin-left: 24px;
                    width: 168px;
                }
            }
    	}
	}
}

/*
------------------------------------------------------------*/
