@use '../../0_base' as *;

/*------------------------------------------------------------
保険を探す
------------------------------------------------------------*/
.page-hoken__search {
    .c-breadcrumb2 {
        margin-bottom: 10px;

        @include pc {
            margin-bottom: 20px;
        }
    }
}

.p-hokenSearchContainer {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #E4F7FF;

    @include pc {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.p-hokenSearchResult__list {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    @include pc {
        row-gap: 30px;
    }
}

.p-hokenSearchResult__item {
    padding: 15px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: #333;

    @include pc {
        padding: 40px;
        border-width: 3px;
        border-radius: 10px;
    }

    &[data-recommendation="true"] {
        border-width: 2.5px;
        border-color: #FA8246;
        @include pc {
            border-width: 5px;
        }
    }

    &__recommendation {
        margin: -15px -15px 15px;
        padding: 5px 15px;
        background-color: #FA8246;
        color: #fff;
        font-size: 1.8rem;
        font-weight: 700;
        @include pc {
            margin: -40px -40px 20px;
            padding: 5px 40px 10px;
            font-size: 20px;
        }
    }

    &__outline {
        @include pc {
            display: grid;
            grid-template-columns: 1fr 2fr;
            column-gap: 40px;
            align-items: center;
        }
    }

    &__action {
        @include pc {
            margin-top: 20px;
            display: grid;
            grid-template-columns: 2fr 1fr;
            column-gap: 20px;
        }
    }

    &__image {
        margin-bottom: 15px;

        @include pc {
            margin-bottom: 0;
        }

        a {
            display: block;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        @include pc {
            gap: 10px;
        }
    }

    &__subTitle {
        margin-top: 15px;
        font-size: 1.4rem;

        @include pc {
            margin-top: 12px;
            font-size: 1.6rem;
        }
    }

    &__title {
        font-size: 1.8rem;
        font-weight: 700;

        @include pc {
            font-size: 2.4rem;
        }
    }

    &__description {
        margin-top: 15px;
        font-size: 1.4rem;

        @include pc {
            margin-top: 12px;
            font-size: 1.2rem;
        }
    }

    &__details {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        column-gap: 10px;

        @include pc {
            margin-top: 0;
            column-gap: 20px;
        }
    }

    &__apply {
        margin-top: 10px;
        @include pc {
            margin-top: 0;
        }
    }

    &__button {
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        border-radius: 9999px;
        font-size: 1.4rem;
        font-weight: bold;
        color: #fff;

        @include pc {
            padding: 20px;
            height: auto;
            column-gap: 10px;
            justify-content: center;
            font-size: 1.8rem;
        }

        &--view {
            background-color: #fff;
            border: 1px solid #41B4E6;
            color: #41B4E6;
        }

        &--document {
            background-color: #41B4E6;
        }

        &--apply {
            background-color: #08336E;
        }

        &__icon {
            min-width: 15px;
            width: 15px;
            height: 15px;
            fill: #fff;

            @include pc {
                min-width: 30px;
                width: 30px;
                height: 30px;
            }
        }

        &__label {
            @include sp {
                text-align: center;
            }
        }
    }

    &__no {
        margin-top: 7.5px;
        color: #888;
        font-size: 1.4rem;
        @include pc {
            margin-top: 10px;
            font-size: 1.4rem;
            text-align: right;
        }
    }
}

