@use '../../0_base' as *;

.p-anniversaryEnquetePrivacyPolicy {
	margin-bottom: 35px;
    padding: 26px 31px 33px;
    border: solid 1px #efefef;
	font-size: 1.6rem;

	@include sp {
		padding: 17px 8px 20px 19px;
		font-size: 1.3rem;
	}
	
	&__scroller {
		height: 240px;
		padding-right: 20px;
		overflow: auto;
		color: #46483c;

		@include sp {
			height: 261px;
			padding-right: 20px;
		}
	}

	h3 {
		margin-bottom: 10px;
		font-size: 1.8rem;
		@include sp {
			font-size: 1.5rem;
		}

		span {
			font-size: 1.6rem;
			font-weight: normal;
			@include sp {
				font-size: 1.3rem;
			}
		}
	}

	section + section {
		margin-top: 10px;
	}
}