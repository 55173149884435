@use '../0_base' as *;

/*------------------------------------------------------------
side05（リフォーム）
------------------------------------------------------------*/

.c-side05 {

	.c-side05__btn01 {
		width: 240px;
		height: 90px;
		margin-bottom: 20px;
		font-size: 1.6rem;
		font-weight: bold;
		background-color: $reformBtnColor01;
		position: relative;
		&:after {
			width: 26px;
			height: 28px;
			content: '';
			background-image: url("../img/common/icon_file01.png");
			-webkit-background-size: cover;
			background-size: cover;
			position: absolute;
			top: 31px;
			right: 20px;
		}
		a {
			width: 100%;
			height: 100%;
			padding: 20px 0 0 20px;
			color: #ffffff;
			display: block;
			position: relative;
			z-index: 10;
			border: solid 6px $reformBtnColor02;
			line-height: 1.3;
		}
		span {
			width: 22px;
			height: 22px;
			color: #ffffff;
			background-color: $reformBtnColor04;
			font-size: 1.4rem;
			line-height: 1.57;
			text-align: center;
			position: absolute;
			top: 22px;
			right: 8px;
			z-index: 5;
			@include radius(50%);
		}
	}

	.c-side05Navi {
		margin-bottom: 20px;
		.c-side05Navi__title {
			padding: 14px 0 12px;
			font-size: 1.5rem;
			font-weight: bold;
			color: #ffffff;
			background-color: $reformNaviColor01;
			text-align: center;
			&:before {
				width: 22px;
				height: 21px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_kougu01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		.c-side05__box {
			border: solid 1px rgba($reformNaviColor04,0.4);
			dt {
				padding: 7px 0;
				font-size: 1.3rem;
				font-weight: bold;
				color: #ffffff;
				background-color: $reformNaviColor04;
				text-align: center;
			}
			dd {
				padding: 15px 19px;
				background-color: #ffffff;
				a {
					margin-bottom: 6px;
					font-size: 1.4rem;
					font-weight: 500;
					color: $reformNaviColor02;
					text-decoration: underline;
					display: block;
					&:hover {
						opacity: 1;
						text-decoration: none;
					}
					&:last-child {
						margin: 0;
					}
					&:before {
						width: 6px;
						height: 10px;
						margin: -2px 10px 0 0;
						content: '';
						background-image: url("../img/common/icon_arrow_green03_21_35.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
		.l-right {
			margin-bottom: 20px;
		}
	}
}

/*
------------------------------------------------------------*/
