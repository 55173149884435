@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　内装ドア・建具 
------------------------------------------------------------*/
.p-reformService03 {
	background-color: #ffffff;
}

// 内装ドア・建具
.p-reformService0301 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior02.jpg");
		}
	}
}

//内装ドア・建具リフォームのポイント
.p-reformService0302 {
	margin-bottom: 80px;
	&__content01 {

	}
}

//内装ドア・建具商品一覧
.p-reformService0303 {
	margin-bottom: 100px;
	&__content01 {
		.c-refomeTitle03{
			margin-bottom: 30px;
		}
		.c-refomeTitle04 {
    		margin-bottom: 30px;
		}
	}
}


@include sp {

	// 内装ドア・建具  sp
	.p-reformService0301 {
		margin-bottom: 28px;
		&__content01 {
			.c-refomeTitle02 {
				background-image: url("../img/reform/title_obi_interior02_sp.jpg");
			}
		}
	}

	//内装ドア・建具リフォームのポイント sp
	.p-reformService0302 {
		margin-bottom: 29px;
		padding: 0 20px;
		&__content01 {
			.c-refomeTitle03{
				margin-bottom: 37px;
				line-height: 1.4;
				&:after{
					bottom: -21px;
				}
				&:before{
					top: 24%;
				}
			}
			.c-reformServiceTxet01--txt{
				p{
					line-height: 2.1rem;
				}
			}
		}
	}
	//内装ドア・建具商品一覧 sp
	.p-reformService0303 {
		margin-bottom: 40px;
		padding: 0 20px;
		&__content01 {
			.c-refomeTitle04 {
			    margin-bottom: 20px;
			}
			.c-refomeTitle03{
				margin-bottom: 35px;
			}
			.c-reformServiceItemList01__imgs{
				margin-bottom: 11px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
