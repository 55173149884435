@use '../0_base' as *;

/*------------------------------------------------------------
header06（くらしのサービス）
------------------------------------------------------------*/
.c-head06Pc {
	width: 95%;
	min-width: $baseWidth01;
	height: 50px;
	margin: 0 auto 70px;
	padding: 10px 1% 0 2%;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	@include radius(0 0 25px 25px);
	@include clear;
	.c-head06Logo {
		width: 189px;
	}
}
.c-head06GnaviPc {
	ul {
		@include dib;
		li {
			margin-right: 15px;
			a {
				color: $gray01;
				font-size: 1.4rem;
				line-height: 2.14;
				font-weight: 600;
				&:before {
					margin: -3px 5px 0 0;
					content: '';
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
				&:hover {
					color: $keycolor01;
				}
			}
			&:nth-child(1) {
				a {
					&:before {
						width: 14px;
						height: 19px;
						background-image: url("../img/common/icon_biginer01.png");
					}
				}
			}
			&:nth-child(2) {
				a {
					&:before {
						width: 17px;
						height: 18px;
						background-image: url("../img/common/icon_reaf01.png");
					}
				}
			}
		}
	}
}

.c-head06CateTitle {
	height: 70px;
	padding-top: 20px;
	font-size: 2.4rem;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	z-index: 9998;
}

.c-head06BtnPc {
	height: 0;
	font-size: 1.6rem;
	line-height: 1.2;
	font-weight: bold;
	text-align: center;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	z-index: 9998;
	.c-head06BtnPc__btn {
		width: 175px;
		height: 105px;
		background-color: $kurashiBtnColor01;
		position: absolute;
		top: 0;
		right: 0;
		@include radius(0 0 3px 3px);
		a {
			width: 100%;
			height: 100%;
			padding-top: 50px;
			display: block;
			color: #ffffff;
			background-image: url("../img/common/icon_cart03.png");
			background-repeat: no-repeat;
			background-position: center 15px;
			transition: 0.1s ease-in-out;
			&:before {
				width: 95%;
				height: 95%;
				content: '';
				position: absolute;
				top: 0;
				left: 5px;
				border-left: dashed 1px #ffffff;
				border-right: dashed 1px #ffffff;
				border-bottom: dashed 1px #ffffff;
				@include radius(0 0 3px 3px);
			}
		}
	}
}

@include sp {
	.c-head06Sp {
		width: 100%;
		height: 50px;
		padding:0;
		background-color: #ffffff;
		border-bottom: solid 1px $glaylight04;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		@include clear;
		.c-head06Logo {
			width: 200px;
			max-width: 34%;
			height: 50px;
			position: absolute;
			top: 0;
			left: 15px;
			img {
				width: 100%;
				margin: auto;
				position: absolute;
				top: 0;
				bottom: 0;
			}
		}
		.c-spMenu {
			background-color: #ffffff;
		}
		.c-headCateTitleSp {
			padding: 0;
			font-size: 1.3rem;
			line-height: 1.5;
			background-color: $kurashiColor01;
			a {
				display: block;
				padding: 8px 18px 0 11px;
				height: 100%;
			}
			strong {
				display: inline-block;
			}
			.c-cateIcon01 {
				margin: 0 12px 5px 0;
				vertical-align: text-bottom;
			}
		}
	}
	.c-head06LinkSp {
		dl {
			width: 100%;
			dt {
				width: 100%;
				background-color: $keycolor02;
				a {
					padding: 10px 35px 7px 20px;
					font-size: 1.4rem;
					font-weight: bold;
					line-height: 1.5;
					display: block;
					color: #ffffff;
					background-image: url("../img/common/icon_arrow-white02.png");
					background-repeat: no-repeat;
					-webkit-background-size: 7px;
					background-size: 7px;
					background-position: 95% center;
				}
			}
			dd {
				padding: 15px 20px 8px;
				background-color: #ffffff;
				ul {
					li {
						width: 49%;
						height: 45px;
						margin: 0 2% 2% 0;
						border: solid 1px $glaylight01;
						background-color: #ffffff;
						@include radius(8px);
						a {
							width: 100%;
							height: 100%;
							padding: 2px 0 2px 55px;
							font-size: 1.4rem;
							font-weight: bold;
							line-height: 2.928;
							display: block;
							position: relative;
							&:before {
								width: 30px;
								height: 30px;
								content: '';
								background-repeat: no-repeat;
								-webkit-background-size: 80%;
								background-size: 80%;
								background-position: center center;
								position: absolute;
								top: 8px;
								left: 15px;
								@include radius(50%);
							}
						}
						&:nth-child(1) {
							a {
								color: $ticketColor02;
								&:before {
									background-color: $ticketColor01;
									background-image: url("../img/common/icon_ticket01.png");
								}
							}
						}
						&:nth-child(2) {
							margin-right: 0;
							a {
								color: $hokenColor02;
								&:before {
									background-color: $hokenColor01;
									background-image: url("../img/common/icon_hoken01.png");
								}
							}
						}
						&:nth-child(3) {
							a {
								color: $reformColor02;
								&:before {
									background-color: $reformColor01;
									background-image: url("../img/common/icon_reform01.png");
								}
							}
						}
						&:nth-child(4) {
							margin-right: 0;
							a {
								color: $sosaiColor02;
								&:before {
									background-color: $sosaiColor01;
									background-image: url("../img/common/icon_sosai01.png");
								}
							}
						}
					}
				}
			}
		}
		div {
			width: 100%;
			padding: 0 20px 25px;
			background-color: #ffffff;
			a {
				padding: 0 0 0 10px;
				font-size: 1.4rem;
				line-height: 1.2;
				display: block;
				color: $keycolor01;
				background-image: url("../img/common/icon_arrow-green06.png");
				background-repeat: no-repeat;
				-webkit-background-size: 8px;
				background-size: 8px;
				background-position: 0 3px;
			}
		}
	}
	.c-head06GnaviSp {
		margin-bottom: 20px;
		.c-head06GnaviSp__navi {
			width: 100%;
			a {
				padding: 10px 30px;
				font-size: 1.3rem;
				font-weight: bold;
				line-height: 1.5;
				color: $kurashiTextColor01;
				display: block;
			}
			&.c-head06GnaviSp__navi--1 {
				background-color: $kurashiColor01;
				background-image: none;
				a {
					padding: 15px 35px 15px 20px;
					color: #ffffff;
					background-image: url("../img/common/icon_arrow-white02.png");
					background-repeat: no-repeat;
					-webkit-background-size: 7px;
					background-size: 7px;
					background-position: 96% center;
				}
			}
			&.c-head06GnaviSp__navi--2 {
				position: relative;
				a {
					position: relative;
					z-index: 10;
				}
				&:after {
					width: 40px;
					height: 100%;
					content: '';
					background-image: url("../img/common/icon_maru-sankaku02.png");
					background-repeat: no-repeat;
					-webkit-background-size: 20px;
					background-size: 15px;
					background-position: center center;
					position: absolute;
					top: 0;
					right: 2px;
				}
			}
			&.c-head06GnaviSp__navi--3 {
				padding-bottom: 1px;
				dt {
					position: relative;
					a {
						width: calc(100% - 60px);
						background: none;
					}
				}
				dd {
					padding: 0 40px;
					background-color: rgba(255,255,255,0.95);
					&:first-of-type {
						padding-top: 10px;
						background-color: rgba(255,255,255,0.95);
						background-image: url("../img/kurashi/line_dotted01_gray.png");
						background-repeat: repeat-x;
						background-position: 0 0;
						background-size: 5px 1px;
					}
					&:last-of-type {
						padding-bottom: 7px;
					}
					a {
						padding: 5px 17px;
						text-decoration: underline;
						&:before {
							width: 7px;
							height: 10px;
							margin: -6px 6px 0 -13px;
							content: '';
							background-image: url("../img/common/icon_arrow-orange02.png");
							-webkit-background-size: cover;
							background-size: cover;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}
			&.c-head06GnaviSp__navi--4 {
				margin-top: -2px;
				background-image: none;
				border-bottom: solid 2px $kurashiColor01;
				position: relative;
				z-index: 10;
				dt {
					em {
						padding: 15px 20px 13px;
						font-size: 1.4rem;
						font-weight: bold;
						line-height: 1.5;
						display: block;
						font-style: normal;
					}
					color: $kurashiTextColor01;
					background-image: none;
					background-color: $kurashiNaviColor01;
					position: relative;
					.c-spMenuAccordionBtn {
						-webkit-background-size: 20px;
						background-size: 20px;
					}
				}
				dd {
					padding: 0 40px;
					background-color: rgba(255,255,255,0.95);
					&:first-of-type {
						padding-top: 13px;
						border-top: solid 2px $glaylight01;
					}
					&:last-of-type {
						padding-bottom: 3px;
					}
					a {
						padding: 0 17px 10px;
						text-decoration: underline;
						&:before {
							width: 7px;
							height: 10px;
							margin: -4px 6px 0 -14px;
							content: '';
							background-image: url("../img/common/icon_arrow-orange02.png");
							-webkit-background-size: cover;
							background-size: cover;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}
		}
		> * {
			background-image: url("../img/kurashi/line_dotted01_brown.png");
			background-repeat: repeat-x;
			background-position: 0 bottom;
			background-size: 5px 1px;
			&:nth-child(even) {
				&.c-head06GnaviSp__navi--2{
					background-color: $kurashiNaviColor02;
					background-image: url("../img/kurashi/line_dotted01_brown.png");
					background-repeat: repeat-x;
					background-position: 0 bottom;
				}
				&.c-head06GnaviSp__navi--3{
					dt {
						background-color: $kurashiNaviColor02;
					}
				}
			}
		}
		.c-head06GnaviSp__navi__menuTitle {
			padding: 14px 20px 11px;
			font-size: 1.4rem;
			font-weight: bold;
			line-height: 1.5;
			letter-spacing: 1px;
			color: $kurashiTextColor01;
			background-image: none;
			background-color: $kurashiNaviColor01;
			border-bottom: solid 2px $kurashiColor01;
		}
		.c-head06GnaviSp__search {
			padding: 10px 20px;
			background-color: $subColor05;
			background-image: none;
			position: relative;
			input[type="text"] {
				width: 100%;
				height: 30px;
				padding: 0 30px 0 10px;
				font-size: 1.4rem;
				line-height: 2;
				@include radius(4px);
				border: solid 1px $glaylight01;
			}
			input[type="submit"] {
				width: 18px;
				height: 18px;
				display: block;
				border: none;
				background: none;
				background-image: url("../img/common/icon_search05.png");
				-webkit-background-size: cover;
				background-size: cover;
				position: absolute;
				top: 15px;
				right: 30px;
				@include ct;
			}
		}
	}
	.c-head06BtnSp {
		margin: 29px 0 35px;
		padding: 0 20px;
		background-color: #ffffff;
		a {
			width: 100%;
			margin: 0 auto;
			padding: 9px 0 7px;
			font-size: 1.6rem;
			font-weight: bold;
			text-align: center;
			display: block;
			color: #ffffff;
			background-color: $kurashiBtnColor01;
			box-shadow: 0 5px 0 $kurashiBtnColor02;
			position: relative;
			@include radius(3px);
			&:before {
				width: 18px;
				height: 18px;
				margin: -3px 7px 0 0;
				content: '';
				background-image: url("../img/common/icon_maru-sankaku03.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
			&:after {
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				content: '';
				border: dashed 1px #ffffff;
				position: absolute;
				top: 2px;
				left: 2px;
				@include radius(3px);
			}
		}
	}
}
/*
------------------------------------------------------------*/
