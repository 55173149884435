@use '../0_base' as *;

/*------------------------------------------------------------
text
------------------------------------------------------------*/

/*------------------------------------------------------------
お知らせ　カテゴリータグ
------------------------------------------------------------*/
.c-cateTxt01 {
	padding: 0.25em 1em;
	font-size: 1.4rem;
	color: #ffffff;
	@include radius(1.9rem);
	&.c-cateTxt01--ticket {
		background-color: $ticketColor01;
	}
	&.c-cateTxt01--hoken {
		background-color: $hokenColor01;
	}
	&.c-cateTxt01--reform {
		background-color: $reformColor01;
	}
	&.c-cateTxt01--kurashi {
		background-color: $kurashiColor01;
	}
	&.c-cateTxt01--sosai {
		background-color: $sosaiColor01;
	}
}

.c-newsCate01 {
	width: 100px;
	height: 20px;
	margin-right: 10px;
	color: #ffffff;
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.65;
	text-align: center;
	background-color: $subColor03;
	@include radius(10px);
	@media screen and (-ms-high-contrast: none) {
		padding-top: 2px;
	}
	@include sp {
		font-size: 1rem;
		width: 80px;
		height: 16px;
		line-height: 1.6;
		margin-top: 15px;
	}
	&.c-newsCate01--cls {
		background-color: #a4c661;
		min-width: 100px;
		width: auto;
		padding: 0 10px;
		@media screen and (-ms-high-contrast: none) {
			padding-top: 2px;
		}
	}
	&.c-newsCate01--area {
		background-color: #a4c661;
	}
	&.c-newsCate01--ticket {
		background-color: $ticketColor01;
	}
	&.c-newsCate01--hoken {
		background-color: $hokenColor01;
		&Oshirase {
			background-color: $hokenBtnColor01;
		}
	}
	&.c-newsCate01--reform {
		background-color: $reformColor01;
	}
	&.c-newsCate01--kurashi {
		background-color: $kurashiColor01;
		min-width: 100px;
		width: auto;
		padding: 0 10px;
		@media screen and (-ms-high-contrast: none) {
			padding-top: 2px;
		}
	}
	&.c-newsCate01--sosai {
		background-color: $sosaiColor01;
	}
}

.c-newsCate02 {
	width: 100px;
	height: 20px;
	margin-right: 10px;
	color: #ffffff;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 22px;
	text-align: center;
	background-color: $subColor03;
	&.c-newsCate02--ticket {
		background-color: $ticketColor01;
		&Events {
			background-color: $ticketBtnColor07;
		}
		&Oshirase {
			background-color: $ticketBtnColor07;
		}
	}
	&.c-newsCate02--hoken {
		background-color: $hokenColor01;
	}
	&.c-newsCate02--reform {
		background-color: $reformColor01;
		&Oshirase {
			background-color: $reformBtnColor05;
		}
		&Canpain {
			background-color: $reformBtnColor06;
		}
	}
	&.c-newsCate02--kurashi {
		background-color: $kurashiColor01;
		&Osusume {
			background-color: $kurashiBtnColor05;
		}
		&Oshirase {
			background-color: $kurashiBtnColor06;
		}
	}
	&.c-newsCate02--sosai {
		background-color: $sosaiColor01;
		&Kanagawa {
			background-color: $sosaiKanagawaColor01;
		}
		&Shizuoka {
			background-color: $sosaiSizuokaColor01;
		}
		&Yamanashi {
			background-color: $sosaiYamanashiColor01;
		}
		&Seminar {
		}
		&Report {
		}
	}
}

.c-newsCate03 {
	width: 100px;
	height: 20px;
	margin-right: 10px;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 22px;
	text-align: center;
	border: solid 1px;
	&.c-newsCate03--sosai {
		&Seminar {
			color: $sosaiSeminarColor01;
			border-color: $sosaiSeminarColor01;
		}
		&Report {
			color: $sosaiReportColor01;
			border-color: $sosaiReportColor01;
		}
		&oshirase {
			color: $sosaiReportColor01;
			border-color: $sosaiReportColor01;
		}
	}
}

@include sp {
	.c-cateTxt01 {
		font-size: 1.2rem;
		@include radius(1.7rem);
	}
	.c-cateTxt02 {
		font-size: 1.2rem;
	}
}

.c-error {
	margin-top: 6px;
	font-size: 1.4rem;
	font-weight: bold;
	color: #f22727;
	display: block;
	&__privacy {
		text-align: center;
		@include sp {
			text-align: left;
		}
	}
	@include sp {
		font-size: 1.1rem;
	}
}

/*
------------------------------------------------------------*/

/*------------------------------------------------------------
保険　承認番号テキスト
------------------------------------------------------------*/
.c-hokenNote {
	font-size: 1.2rem;
	color: #999999;
}

/*------------------------------------------------------------
保険　商品詳細タグ
------------------------------------------------------------*/

.c-hokenTags {
	margin-bottom: 10px;
	@include dib;
	&__tag {
		height: 20px;
		margin: 0 5px 5px 0;
		padding: 0 0.5em;
		font-size: 1.2rem;
		font-weight: 500;
		line-height: 1.65;
		background-color: #ffffff;
		border: solid 1px;
		display: inline-block;
		letter-spacing: normal;
		@include radius(2px);
		&--corp {
			color: $keycolor01;
			border-color: $keycolor01;
		}
		&--internet {
			color: #ffffff;
			background-color: $hokenBtnColor02;
			border-color: $hokenBtnColor02;
		}
		&--campain {
			color: $hokenBtnColor03;
			border-color: $hokenBtnColor03;
		}
		&--attention {
			color: $hokenBtnColor04;
			border-color: $hokenBtnColor04;
		}
		&--popular {
			color: $hokenBtnColor05;
			border-color: $hokenBtnColor05;
		}
		&--new {
			color: $hokenBtnColor06;
			border-color: $hokenBtnColor06;
		}
		&--interview {
			color: $hokenBtnColor07;
			border-color: $hokenBtnColor07;
		}
	}
}

/*
------------------------------------------------------------*/

/*------------------------------------------------------------
リフォーム　商品一覧　序文
------------------------------------------------------------*/

.c-reformServiceTxet01 {
	min-height: 180px;
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	flex-direction: row-reverse;
	&--img {
		width: 280px;

		img {
			width: 280px;
			height: 200px;
		}
	}
	&--txt {
		width: calc(100% - 310px);
		h3 {
			margin-bottom: 11px;
			font-size: 2rem;
			font-weight: bold;
			color: $strongColor03;
		}
		p {
			font-size: 1.5rem;
			line-height: 1.75;
			color: $gray01;
		}
	}
}
@include sp {
	.c-reformServiceTxet01 {
		min-height: auto;
		padding-right: 0;
		display: block;
		&--img {
			width: 100%;
			height: auto;
			padding-left: 0px;
		}
		&--txt {
			width: 100%;
			h3 {
				margin-bottom: 11px;
				font-size: 2rem;
				font-weight: bold;
				color: $strongColor03;
			}
			p {
				font-size: 1.5rem;
				line-height: 1.75;
				color: $gray01;
			}
		}
		img {
			width: auto;
			margin: 0 auto 14px auto;
			display: block;
			position: static;
		}
		h3 {
			margin-bottom: 4px;
			font-size: 1.5rem;
		}
		p {
			font-size: 1.4rem;
		}
	}
}

/*
------------------------------------------------------------*/

/*------------------------------------------------------------
葬祭　テキストボックス01
------------------------------------------------------------*/
.c-sosaiTextBox01 {
	+ .c-sosaiTextBox01 {
		margin-top: -1px;
	}
	> div {
		width: 100%;
		margin-top: -1px;
		padding: 35px 35px 40px;
		border: solid 1px $glaylight13;
		&:first-child {
			margin-top: 0;
		}
		@include sp {
			padding: 14px 18px 19px;
		}
	}
	&--harf {
		display: flex;
		flex-wrap: wrap;
		@include sp {
			display: inherit;
		}
		> div {
			width: calc(50% + 1px);
			margin-right: -1px;
			&:nth-child(2) {
				margin-top: 0;
			}
			&:nth-child(even) {
				width: 50%;
				margin-right: 0;
			}
			@include sp {
				width: 100% !important;
				margin-right: 0;
				&:nth-child(2) {
					margin-top: -1px;
				}
			}
		}
	}
	&--one_third {
		display: flex;
		flex-wrap: wrap;
		@include sp {
			display: inherit;
		}
		> div {
			margin-right: -1px;
			width: calc(33.33333333% + 1px);
			&:nth-child(2),
			&:nth-child(3) {
				margin-top: 0;
			}
			&:nth-child(3n) {
				width: 33.33333333%;
				margin-right: 0;
			}
			@include sp {
				width: 100% !important;
				margin-right: 0;
				&:nth-child(2),
				&:nth-child(3) {
					margin-top: -1px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/

/*------------------------------------------------------------
葬祭　ページタイトルカテゴリー(地域)
------------------------------------------------------------*/
.c-sosaiPagetitleCate {
	text-align: center;
	@include dib;
	span {
		width: 60px;
		height: 20px;
		margin-right: 2px;
		color: #ffffff;
		font-size: 1.2rem;
		font-weight: 500;
		line-height: 22px;
		text-align: center;
	}
	&__Kanagawa {
		background-color: $sosaiKanagawaColor01;
	}
	&__Shizuoka {
		background-color: $sosaiSizuokaColor01;
	}
	&__Yamanashi {
		background-color: $sosaiYamanashiColor01;
	}
}

/*------------------------------------------------------------
検索結果の商品ないときのクラス
------------------------------------------------------------*/
.c-noitem__txt {
	font-size: 1.6rem;
	@include sp {
		font-size: 1.4rem;
	}
}

/*------------------------------------------------------------
補足クラス
------------------------------------------------------------*/
.c-supplement {
	font-size: 1.6rem;
	@include sp {
		font-size: 1.4rem;
	}
}

.c-hokenText01 {
	text-align: center;
	font-size: 1.6rem;
	line-height: 1.75;
	color: #46483c;
	font-weight: 500;
	@include sp {
		text-align: left;
		font-size: 1.4rem;
	}
}

.c-hokenText02 {
	text-align: center;
	font-size: 1.6rem;
	line-height: 1.75;
	color: #ff4433;
	font-weight: bold;
	@include sp {
		text-align: left;
		font-size: 1.4rem;
	}
}

.c-hokenText03 {
	text-align: center;
	font-size: 2.8rem;
	line-height: 1.5;
	color: #46483c;
	font-weight: bold;
	@include sp {
		font-size: 1.6rem;
		line-height: 1.3;
	}
}


.c-formText01 {
	text-align: center;
	font-size: 1.6rem;
	line-height: 1.75;
	color: #46483c;
	font-weight: 500;
	@include sp {
		text-align: left;
		font-size: 1.4rem;
	}
}

.c-formText02 {
	text-align: center;
	font-size: 1.6rem;
	line-height: 1.75;
	color: #ff4433;
	font-weight: bold;
	@include sp {
		text-align: left;
		font-size: 1.4rem;
	}
}

.c-formText03 {
	text-align: center;
	font-size: 2.8rem;
	line-height: 1.5;
	color: #46483c;
	font-weight: bold;
	@include sp {
		font-size: 1.6rem;
		line-height: 1.3;
	}
}
