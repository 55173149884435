@use '../../0_base' as *;

/*------------------------------------------------------------
サービスサイト お知らせ詳細
------------------------------------------------------------*/
.p-serviceInfoDetail {
	@include sp {
		.u-flex {
			-webkit-flex-wrap: wrap;
			    -ms-flex-wrap: wrap;
			        flex-wrap: wrap;
		}
		.c-newsCate01 {
			margin-top: 10px;
		}
	}
}

//お知らせ詳細 ページタイトル
.p-serviceInfoDetail01 {
}

//お知らせ詳細 記事タイトル
.p-serviceInfoDetail02 {
	margin-bottom: 40px;
	padding: 43px 40px 28px;
	background-image: url("../img/service/bg03.jpg");
	position: relative;
	&:before {
		width: 100%;
		height: 4px;
		content: '';
		background-image: url("../img/service/line_colorful01_pc.jpg");
		background-repeat: repeat-x;
		background-position: center 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	.p-serviceInfoDetail02__data {
		margin-bottom: 10px;
		font-size: 1.6rem;
		color: $gray02;
		@include sp {
			margin-bottom: 0;
			font-size: 1.2rem;
		}
	}
	h2 {
		margin-top: 9px;
		font-size: 3.2rem;
		font-weight: bold;
		color: $gray01;
	}
}

//お知らせ詳細 記事本文
.p-serviceInfoDetail03 {
	margin-bottom: 40px;
	color: $gray01;
	h3 {
		margin-bottom: 20px;
		padding: 20px 30px;
		font-size: 2.4rem;
		line-height: 1.3;
		color: #ffffff;
		background-color: $keycolor02;
	}
	h4 {
		margin-bottom: 20px;
		padding: 0 0 10px 15px;
		font-size: 2.0rem;
		line-height: 1.4;
		font-weight: bold;
		color: #669900;
		border-bottom: solid 2px #669900;
		position: relative;
		&:before {
			width: 6px;
			height: calc(100% - 10px);
			content: '';
			background-color: #669900;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	h5 {
		margin-bottom: 7px;
		padding-left: 24px;
		font-size: 1.8rem;
		font-weight: normal;
		color: #669900;
		&:before {
			width: 14px;
			height: 14px;
			margin: -4px 10px 0 -24px;
			content: '';
			background-color: #669900;
			display: inline-block;
			vertical-align: middle;
		}
	}
	strong {
		font-weight: normal;
		color: $strongColor01;
	}
	a {
		color: $linkColor01;
		text-decoration: underline;
		&:hover {
			opacity: 1;
			text-decoration: none;
		}
	}

	p {
		margin-bottom: 2px;
		font-size: 1.6rem;
		line-height: 2;
		@include clear;
		+ h4 {
			margin-top: 41px;
		}
		+ h5 {
			margin-top: 37px;
		}
	}
	ol {
		margin: 0 0 13px 1em;
		font-size: 1.6rem;
		line-height: 2.1;
		counter-reset: count01;
		> li {
			padding-left: 32px;
			counter-increment: count01;
			&:before {
				width: 32px;
				margin-left: -32px;
				content: counter(count01)'.';
				font-weight: bold;
				display: inline-block;
			}
		}
	}
	ul {
		margin: 0 0 14px 1em;
		font-size: 1.6rem;
		line-height: 2.1;
		> li {
			padding-left: 24px;
			&:before {
				width: 8px;
				height: 8px;
				margin: 0 16px 0 -24px;
				content:'';
				display: inline-block;
				background-color: $keycolor02;
				@include radius(50%);
			}
		}
	}
	img {
		max-width: 560px;
		&.alignleft {
			margin: 0 20px 20px 0;
			float: left;
		}
		&.alignright {
			margin: 9px 0 20px 20px;
			float: right;
		}
		&.aligncenter {
			margin: 0 auto 20px auto;
			display: block;
		}
	}
	table {
		width: 100% !important;
		margin: 28px 0 53px;
		font-size: 1.6rem;
		line-height: 2;
		border-collapse: collapse;
		tr {
			td {
				padding: 10px 20px;
				background-color: #ffffff;
				border: solid 1px $glaylight05;
				&:first-of-type {
					padding: 10px 20px;
					font-weight: bold;
					text-align: left;
					color: #ffffff;
					background-color: #a4c661;
					border: solid 1px $glaylight05;
					width: 350px;
				}
			}
		}
	}
	.p-serviceInfoDetail03__textBox01 {
		margin-bottom: 40px;
		padding: 31px 36px 27px;
		border: solid 4px $glaylight04;
		h4 {
			padding: 0 0 16px;
			&:before {
				display: none;
			}
		}
		*:last-child {
			margin-bottom: 0;
		}
	}
	.p-serviceInfoDetail03__textBox02 {
		margin-bottom: 30px;
		padding: 36px;
		background-color: $glaylight03;
		h4 {
			padding-left: 0;
			&:before {
				display: none;
			}
		}
		*:last-child {
			margin-bottom: 0;
		}
	}
}

.p-serviceInfoDetail03 {
  .youtube-ar {
    @include sp {
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    iframe {
      margin: 60px 0px;

      @include sp {
        margin: 30px 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

//お知らせ詳細 ページャー
.p-serviceInfoDetail04 {
	margin-bottom: 100px;
	.p-serviceInfoDetail04__prev {
		width: 120px;
		height: 60px;
		font-size: 1.8rem;
		line-height: 3;
		color: $gray01;
		text-align: center;
		border: solid 3px $glaylight01;
		@include radius(30px);
		@media screen and (-ms-high-contrast: none) {
			padding-top: 3px;
		}
		&:before {
			width: 6px;
			height: 10px;
			margin: -2px 10px 0 0;
			content: '';
			background-image: url("../img/common/icon_arrow-green04.png");
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
			transform: rotate(180deg);
		}
		&:hover {
			border-color: $keycolor02;
		}
	}
	.p-serviceInfoDetail04__next {
		width: 120px;
		height: 60px;
		font-size: 1.8rem;
		line-height: 3;
		color: $gray01;
		text-align: center;
		border: solid 3px $glaylight01;
		@include radius(30px);
		@media screen and (-ms-high-contrast: none) {
			padding-top: 3px;
		}
		&:after {
			width: 6px;
			height: 10px;
			margin: -2px 0 0 10px;
			content: '';
			background-image: url("../img/common/icon_arrow-green04.png");
			-webkit-background-size: cover;
			background-size: cover;
			display: inline-block;
			vertical-align: middle;
		}
		&:hover {
			border-color: $keycolor02;
		}
	}
	.p-serviceInfoDetail04__back {
		height: 60px;
		padding: 0 40px;
		margin: 0 10px;
		font-size: 1.8rem;
		font-weight: bold;
		color: #ffffff;
		line-height: 3;
		text-align: center;
		background-color: $keycolor02;
		border: solid 3px $keycolor02;
		@include radius(30px);
		@media screen and (-ms-high-contrast: none) {
			padding-top: 3px;
		}
		&:hover {
			color: $keycolor02;
			background-color: #ffffff;
		}
	}
}


@include sp {
	.p-serviceInfoDetail {
		padding-top: 29px;
	}

	//お知らせ詳細 ページタイトル　SP
	.p-serviceInfoDetail01 {
		.c-serviceTitle01 {
			margin-bottom: 24px;
			font-size: 2.1rem;
			&:before {
				margin-bottom: 3px;
			}
			span {
				font-size: 1.4rem;
			}
		}
	}

	//お知らせ詳細 記事タイトル　SP
	.p-serviceInfoDetail02 {
		margin: 24px 20px 30px;
		padding: 27px 20px 26px;
		&:before {
			height: 4px;
			background-image: url("../img/service/line_colorful01_sp.jpg");
			background-size: 119px;
		}
		h2 {
			margin-top: 14px;
			font-size: 2.1rem;
			line-height: 1.3;
		}
		.c-newsCate01 {
			height: 20px;
			margin-top: 9px;
			font-size: 1.2rem;
			padding: 2px 5px;
		}
	}
	//お知らせ詳細 記事本文
	.p-serviceInfoDetail03 {
		margin-bottom: 30px;
		padding: 0 20px;
		h3 {
			margin-bottom: 20px;
			padding: 17px 20px 16px;
			font-size: 1.9rem;
			width: calc(100% + 40px);
			margin-left: -20px;
		}
		h4 {
			margin-bottom: 11px;
			padding: 0 0 5px 15px;
			font-size: 1.7rem;
			line-height: 1.5;
		}
		h5 {
			margin-bottom: 20px;
			padding-left: 20px;
			&:before {
				width: 15px;
				height: 15px;
				margin: -4px 10px 0 -20px;
			}
		}

		p {
			font-size: 1.4rem;
			line-height: 1.8;
			+ h4 {
				margin-top: 40px;
			}
			+ h5 {
				margin-top: 20px;
			}
		}
		ol {
			margin: 0 0 17px 1em;
			font-size: 1.4rem;
			> li {
				padding-left: 15px;
				&:before {
					width: 15px;
					margin-left: -16px;
				}
			}
		}
		ul {
			margin: 0 0 20px 1em;
			font-size: 1.4rem;
			> li {
				padding-left: 10px;
				&:before {
					width: 8px;
					height: 8px;
					margin: 0 10px 0 -15px;
				}
			}
		}
		img {
			max-width: 100%;
			&.alignleft {
				margin: 0 auto 10px auto;
				display: block;
				float: none;
			}
			&.alignright {
				margin: 0 auto 10px auto;
				display: block;
				float: none;
			}
			&.aligncenter {
				margin: 0 auto 10px auto;
			}
		}
		table {
			margin-bottom: 41px;
			font-size: 1.4rem;
			display: block;
			border-bottom: solid 1px $glaylight05;
			tbody, thead {
				display: block;
			}
			tr {
				display: block;
				td {
					padding: 8px 20px 6px;
					display: block;
					border: none;
					border-bottom: solid 1px $glaylight05;
					&:first-of-type {
						padding: 11px 20px 12px;
						display: block;
						border: none;
						width: 100%;
					}
				}
				*:last-child {
					border: none;
				}
			}
		}
		.p-serviceInfoDetail03__textBox01 {
			margin-bottom: 11px 16px 15px;
			padding: 15px;
			h4 {
				padding: 0 0 11px;
			}
		}
		.p-serviceInfoDetail03__textBox02 {
			margin-bottom: 20px;
			padding: 15px;
		}
	}


	//お知らせ詳細 ページャー　SP
	.p-serviceInfoDetail04 {
		margin-bottom: 80px;
		.p-serviceInfoDetail04__prev {
			width: 40px;
			height: 40px;
			padding: 10px 0 0 12px;
			border: solid 1px $glaylight01;
			@include radius(20px);
			&:before {
				width: 10px;
				height: 17px;
				margin: 0;
				display: block;
			}
		}
		.p-serviceInfoDetail04__next {
			width: 40px;
			height: 40px;
			padding: 10px 0 0 16px;
			@include radius(20px);
			border: solid 1px $glaylight01;
			&:after {
				width: 10px;
				height: 17px;
				margin: 0;
				display: block;
			}
		}
		.p-serviceInfoDetail04__back {
			font-size: 1.4rem;
			height: 40px;
			line-height: 2.5;
			padding: 0 23px;
			@include radius(20px);
		}
	}
}

/*
------------------------------------------------------------*/
