@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　ペットの火葬・霊園
------------------------------------------------------------*/
.p-sosaiPet {
	background-color: #ffffff;
	font-size: 1.8rem;
	line-height: 1.77;
	color: $gray08;
}

//ペットの火葬・霊園
.p-sosaiPet01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//ペットの火葬・霊園2
.p-sosaiPet02 {
	margin-bottom: 115px;
	padding-top: 15px;
	&__content01 {
		margin-bottom: 116px;
		&--txt01 , &--txt02{
			font-size: 1.8rem;
			line-height: 1.8;
			b {
				font-weight: 700;
			}
		}
		&--img{
			margin: 70px auto 52px;
			display: block;
		}
	}
	&__content02 {
		overflow: hidden;
		.c-sosaiTitle02b {
    		margin-bottom: 56px;
    	}
		&__txt {
			width: 680px;
			float: left;
			.c-sosaiTitle09 {
    			margin-bottom: 18px;
    		}

			p {
				margin-bottom: 69px;
				font-size: 1.7rem;
				&:last-child {
					margin: 0;
				}
			}
		}
		&__img {
			width: 400px;
			float: right;
		}
	}
}

//提携葬儀センター・霊園
.p-sosaiPet03 {
	padding: 104px 0 120px 0;
	background-color: $glaylight16;
	&__content01 {
		margin-bottom: 60px;
		padding-top: 15px;
		dl {
			margin-bottom: 60px;
			background-color: #ffffff;
			border: solid 1px $sosaiNaviColor01;
			&:last-child {
				margin: 0;
			}
			dt {
				padding: 9px 10px 8px 66px;
				font-size: 2.4rem;
				font-weight: 500;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				position: relative;
				@include mincyo;
				&:before {
					width: 30px;
					height: 30px;
					content: '';
					position: absolute;
					background: url("../img/sosai/icon_sosai01.png") no-repeat;
					-webkit-background-size: 100%;
					background-size: 100%;
					top: 50%;
					left: 20px;
					transform: translate(0, -50%);
				}
			}
			dd {
				padding: 50px;
				font-size: 1.6rem;
				color: $gray08;
				.p-sosaiPet03__table{
					width: 530px;
					margin-bottom: 40px;
					&--row{
						width: 100%;
						display: table;
						padding: 22px 10px 22px 0;
						border-top: dotted 1px $gray08;
						&:last-child{
							border-bottom: dotted 1px $gray08;
						}
						.col-left{
							display: table-cell;
							width: 170px;
							text-align: left;
							vertical-align: top;
							padding-left: 10px;
							&:before {
								width: 8px;
								height: 8px;
								margin: -4px 10px 0 0;
								content: '';
								background-color: $sosaiBtnColor01;
								display: inline-block;
							}
						}
						.col-right{
							display: table-cell;
							vertical-align: top;
							font-weight: 500;
						}
					}
				}
				.c-sosaiBtn01 {
					width: 100%;
				}
			}
		}
		&__box01 {
			margin-bottom: 40px;
			padding: 25px 20px;
			text-align: center;
			background-color: $glaylight02;
			border: solid 1px $glaylight13;
			.c-sosaiTitle10 {
				margin-bottom: 7px;
			}
			p {
				margin-bottom: 9px;
				font-size: 1.5rem;
				font-weight: 700;
			}
			span {
				display: block;
				font-size: 1.2rem;
			}
		}
		&__box02 {
			width: 500px;
			padding: 36px 30px 34px 41px;
			border: solid 1px $glaylight13;
			.c-sosaiTitle10{
				margin-bottom: 11px;
			}
			ul {
				li {
					margin-bottom: 11px;
					padding-left: 18px;
					font-size: 1.5rem;
					line-height: 1.6;
					&:last-child {
						margin-bottom: 0;
					}
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 10px 0 -18px;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
		&__img {
			width: 435px;
			img {
				width: 100%;
			}
		}
		&__detail {
			width: 530px;
			&__tel {
				.tel-num {
					padding: 5px 0 11px;
					display: block;
					font-size: 4.0rem;
					line-height: 1;
					letter-spacing: 3px;
					color: $gray08;
					@include mincyo;
					&:before {
						width: 25px;
					    height: 28px;
					    margin: -13px 11px 0 7px;
						content: '';
						background-image: url("../img/common/icon_tel06.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
				span {
					padding: 0 0 3px 17px;
					font-size: 1.4rem;
					font-weight: 700;
					color: $gray04;
					text-indent: -1em;
					display: block;
				}
			}
		}
	}
	&__content02 {
		&__tel {
			width: 560px;
			margin: 0 auto;
			padding: 23px 30px;
			color: $sosaiTextColor01;
			background-color: #ffffff;
			text-align: center;
			border: solid 1px $glaylight13;
			@include mincyo;
			h3 {
				font-size: 2.0rem;
				font-weight: 700;
			}
			.tel-number {
				margin: 3px 0;
				display: block;
				font-size: 3.6rem;
				line-height: 1;
				letter-spacing: 1px;
				color: $gray08;
				@include mincyo;
				&:before {
					width: 24px;
					height: 24px;
					margin: -13px 13px 0 0;
					content: '';
					background-image: url("../img/common/icon_tel06.png");
					-webkit-background-size: cover;
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
				}
			}
			p {
				color: $gray08;
				font-size: 1.6rem;
				line-height: 1.5;
			}
		}
	}
}



@include sp {
	.p-sosaiPet {
		font-size: 1.6rem;
	}
	//ペットの火葬・霊園 sp
	.p-sosaiPet01 {
		min-width: 100%;
		margin-bottom: 50px;
		padding: 32px 0 33px;
	}
	//ペットの火葬・霊園2 sp
	.p-sosaiPet02 {
		margin-bottom: 45px;
		padding: 0 15px;
		&__content01 {
			margin-bottom: 73px;
			.c-sosaiTitle02b {
    			margin-bottom: 26px;
    		}
			&--txt01, &--txt02{
				font-size: 1.4rem;
				line-height: 1.6;
			}
			&--txt02{
			 	text-align: left;
			}
			&--img {
				margin: 25px auto 26px;
			}
		}
		&__content02 {
			.c-sosaiTitle02b {
    			margin-bottom: 37px;
    		}
			&__txt {
				width: 100%;
				padding: 0 0 0 10px;
				p {
					font-size: 1.4rem;
					line-height: 1.6;
					margin-bottom: 34px;
				}
				.c-sosaiTitle09{
					margin-bottom: 12px;
				}
			}
			&__img {
				width: 100%;
				margin-bottom: 50px !important;
			}
		}
	}

	//提携葬儀センター・霊園 sp
	.p-sosaiPet03 {
		padding: 48px 15px;
		margin-bottom: 62px;
		.c-sosaiTitle02b {
		    margin-bottom: 25px;
		}
		&__content01 {
			margin-bottom: 30px;
			dl {
				margin-bottom: 30px;
				dt {
					font-size: 1.9rem;
					line-height: 1;
					padding: 14px 20px 12px 61px;
					display: flex;
    				align-items: center;
					&:before {
						width: 40px;
						height: 40px;
						left: 10px;
					}
				}
				dd {
					padding: 20px 15px 15px;
					.p-sosaiPet03__table{
						width: 100%;
						font-size: 1.2rem;
						margin-bottom: 15px;
						&--row{
							width: 100%;
							display: table;
							padding: 14px 10px 13px 0;
							border-top: dotted 1px $gray08;
							&:last-child{
								border-bottom: dotted 1px $gray08;
							}
							&:nth-child(3){
								display: block;
								padding: 15px 0 18px;
								.col-left, .col-right{
									width: 100%;
									display: block;
								}
								.col-right{
									.c-contactBtn02{
										width: 100%;
										height: 59px;
										max-width: 260px;
										margin: 12px auto 18px;
										padding: 4px 10px 6px 71px;
										&--btn02{
											padding: 4px 10px 6px 90px;
											&:before{
												left: 50px;
											}
										}
									}
								}
							}
							.col-left{
								width: 29%;
								padding: 0 0 0 2px;
								&:before{
									width: 6px;
									height: 6px;
									margin: -4px 6px 0 0;
								}
							}
							.col-right{
								display: table-cell;
								font-weight: 500;
								line-height: 1.5;
							}
						}
					}
					.c-sosaiBtn01 {
						width: 56%;
						height: auto;
						font-size: 1.3rem;
						a{
							padding: 5px;
						}
					}
				}
			}
			&__box01 {
				margin-bottom: 15px;
				padding: 20px 20px 15px;
				text-align: left;
				.c-sosaiTitle10 {
					margin-bottom: 7px;
					line-height: 1;
					text-align: center;
				}
				p {
					margin-bottom: 2px;
					font-size: 1.1rem;
					line-height: 1.5;
				}
				span {
					font-size: 1.0rem;
				}
			}
			&__box02 {
				width: 100%;
				margin-bottom: 15px;
				padding: 14px 20px 16px 20px;
				.c-sosaiTitle10 {
					font-size: 1.5rem;
				    margin-bottom: 6px;
				}
				&:last-child {
					margin-bottom: 0;
				}
				ul {
					li {
						font-size: 1.1rem;
						line-height: 1.5;
						margin-bottom: 4px;
						&:before{
							width: 6px;
							height: 6px;
							margin: -2px 8px 0 -18px;
						}
					}
				}
			}
			&__img {
				width: 100%;
				margin-bottom: 17px;
			}
			&__detail {
				width: 100%;
				&__tel {
					.tel-num{
						color: #fff;
						font-size: 1.8rem;
						line-height: 1;
						letter-spacing: 1.1px;
						padding: 5px 0 0;
						&:before {
							display: none;
						}
					}
					.contact-time{
						font-size: 1rem;
						line-height: 1.3;
   						margin: -1px 0 0 0;
					}
					span {
						font-size: 1rem;
						line-height: 1.3;
						padding: 0 0 3px 12px;
					}
				}
			}
		}
		&__content02 {
			&__tel {
				width: 100%;
				margin: 0 auto;
				padding: 15px 15px 17px;
				h3 {
					font-size: 1.6rem;
					margin-bottom: 7px;
				}
				.tel-number{
					color: #fff;
					font-size: 1.9rem;
					margin: 0;
					&:before{
						display: none;
					}
				}
				p {
					color: #fff;
					font-size: 1rem;
					line-height: 1.4;
				}
			}
		}
	}
}