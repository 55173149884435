@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　資料請求・お問い合わせ
------------------------------------------------------------*/
.p-sosaiContactIndex {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
	.c-breadcrumb1{
		padding: 9px 0 43px;
	}
}
//資料請求・お問い合わせ
.p-sosaiContactIndex01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
	}
}
//各県ごとの~~
.p-sosaiContactIndex02 {
	margin-top: -30px;
	padding: 91px 0 135px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	h2 {
		margin-bottom: 70px;
		font-size: 2.0rem;
		font-weight: 700;
		color: $gray08;
		text-align: center;
	}
	&__txt {
		font-size: 1.3rem;
	}
	&__contact {
		margin-bottom: 16px;
		&__box {
			width: 33%;
			margin-right: 1px;
			// height: 600px;
			border-top: solid 2px;
			background-color: #ffffff;
			text-align: center;
			&--yamanashi {
				border-top-color: $sosaiYamanashiColor01;
			}
			&--kanagawa {
				border-top-color: $sosaiKanagawaColor01;
			}
			&--shizuoka {
				border-top-color: $sosaiSizuokaColor01;
			}
			&:nth-child(2) {
				.p-sosaiContactIndex02__contact__box__txt {
					margin-bottom: 20px;
					p:not(.l-company) {
						margin-bottom: 10px;
					}
				}
			}
			&:last-child {
				margin: 0;
			}
			h3 {
				margin-top: 10px;
				font-size: 2.0rem;
				font-weight: 500;
				margin-bottom: 25px;
				@include mincyo;
				span {
					font-size: 3.2rem;
					line-height: 1;
					display: block;
				}
				img {
					margin: 0 auto;
					display: block;
				}
			}
			&__txt {
				margin: 0 40px;
				p {
					font-weight: 700;
					font-size: 1.6rem;
					&.l-company {
						font-size: 1.3rem;
						letter-spacing: -0.2px;
						line-height: 1.3;
						margin-bottom: 0;
					}
				}
				a {
					display: block;
					line-height: 1;
					color: $gray08;
					span {
						display: block;
						&:nth-child(1) {
							font-size: 3.4rem;
							letter-spacing: 2.5px;
							padding: 4px 0 6px 8px;
							@include mincyo;
							&:before {
								width: 24px;
								height: 24px;
								margin: -9px 8px 0 -4px;
								content: '';
								background-image: url("../img/common/icon_tel06.png");
								-webkit-background-size: cover;
								background-size: cover;
								display: inline-block;
								vertical-align: middle;
							}
						}
						&:nth-child(2) {
							font-size: 1.3rem;
							letter-spacing: -0.2px;
						}
					}
				}
				&-note {
					display: block;
					font-size: 1.3rem;
					letter-spacing: -0.2px;
					line-height: 1.3;
				}
			}
			.c-sosaiBtn06{
				padding: 4px 0;
				margin-bottom: 20px;
				a{
					&:before{
						margin-right: 20px;
					}
				}
			}
		}
	}
	&__note {
		font-size: 1.3rem;
		a {
			margin-left: 9px;
			font-weight: bold;
			color: $gray08;
		}
		span {
			margin-left: 25px;
			position: relative;
			&:after {
				content: '';
				width: 10px;
				height: 10px;
				background-image: url(../img/common/icon_tel08.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 2px;
				left: -16px;
			}
		}
	}
}

@include sp {
	.p-sosaiContactIndex {
		font-size: 1.6rem;
	}
	//資料請求・お問い合わせ　sp
	.p-sosaiContactIndex01 {
		min-width: 100%;
		padding: 32px 0 33px;
	}

	//各県ごとの~~ sp
	.p-sosaiContactIndex02 {
		margin: 0 0 58px;
		padding: 46px 20px 34px;
		h2 {
			margin-bottom: 23px;
			font-size: 1.4rem;
			line-height: 1.5;
		}
		&__contact {
			margin-bottom: 14px;
			&__box {
				width: 100%;
				height: auto;
				margin-bottom: 19px;
				padding-bottom: 29px;
				&:nth-child(2) {
					.p-sosaiContactIndex02__contact__box__txt {
						margin-bottom: 10px;
						p:not(.l-company) {
							margin-bottom: 10px;
						}
						a {
							padding: 15px 0 15px 43px;
							margin-bottom: 10px;
							div {
							    span {
							    	padding: 5px 0 0 0;
							    }
								&:before {
									top: 0;
								}
							}
						}
					}
				}
				h3 {
					font-size: 1.8rem;
					margin-bottom: 10px;
					span {
						font-size: 2.8rem;
					}
				}
				&__txt {
					margin: 10px 20px 7px;
					padding-top: 15px;

					p{
						font-size: 1.4rem;
					}
					a {
						text-align: left;
						color: #fff;
						margin-bottom: 0;
						margin-top: 3px;
						background: $sosaiNaviColor02;
						box-shadow: 0px 3px 0px #1f6267;
						border-radius: 5px;
						padding: 8px 0 8px 43px;
						span {
							&:nth-child(1) {
								font-size: 1.8rem;
								letter-spacing: 1px;
								padding: 0 0 2px;
								&:before {
									content: none;
								}
							}
							&:nth-child(2) {
								font-size: 1rem;
								letter-spacing: 0;
								line-height: 1.2;
							}
						}
						div {
							width: 162px;
							margin: 0 auto;
							position: relative;
							&:before {
								content: '';
								width: 25px;
								height: 25px;
								background-image: url(../img/common/icon_tel07.png);
								background-size: contain;
								background-repeat: no-repeat;
								position: absolute;
								top: 10px;
								left: -40px;
							}
						}
					}
				}

				.c-sosaiBtn06 {
					max-width: 100%;
					width: calc(100% - 40px);
					padding: 0;
					margin-bottom: 0;
					a {
						padding: 10px 15px;
						line-height: 1.1;
						&:before {
							margin: 0 14px 0 12px;
						}
					}
				}
			}
		}
		&__note {
			font-size: 1.4rem;
			line-height: 1.5;
			a {
				margin: 25px auto 0;
				padding: 10px 0;
				width: calc(100% - 40px);
				display: block;
				font-size: 1.5rem;
				color: #fff;
				background: $sosaiNaviColor02;
				box-shadow: 0px 2px 0px #1f6267;
				border-radius: 3px;
				text-align: center;
			}
			span {
				margin-left: 29px;
				&:after {
					width: 12px;
					height: 12px;
					background-image: url(../img/common/icon_tel09.png);
					position: absolute;
					left: -21px;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
