@use '../../0_base' as *;

/*------------------------------------------------------------
/service/hoken/intention/input.php
------------------------------------------------------------*/
.p-hokenIntentionInput {
	padding: 41px 0 133px;

	@include sp {
		padding: 19px 20px 35px;
	}

	&1 {
		// .p-hokenIntentionInput1 .c-hokenText01 .
		.c-hokenText01 {
			margin-top: 40px;

			@include sp {
				margin-top: 28px;
			}
		}

		.c-accordion {
			margin-top: 11px;
		}

		// .p-hokenIntentionInput1 .c-hokenText02 .
		.c-hokenText02 {
			margin-top: 25px;

			@include sp {
				margin-top: 18px;
			}
		}

		// .p-hokenIntentionInput1 .c-step3 .
		.c-step3 {
			margin-top: 60px;
			margin-bottom: 97px;

			@include sp {
				margin-top: 30px;
				margin-bottom: 37px;
			}
		}

		// .p-hokenIntentionInput1 .c-formPrivacyText .
		.c-formPrivacyText {
			margin-bottom: 32px;

			@include sp {
				margin-bottom: 30px;
			}
		}
	}
}

