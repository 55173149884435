@use '../../0_base' as *;

/*------------------------------------------------------------
保険 コープライフサービスの無料保険相談
------------------------------------------------------------*/
// こんなお悩みありませんか？
.p-hokenConsultation01 {
    &__list {
        padding: 5px 20px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;

        @include pc {
            padding: 30px;
            border-radius: 10px;
            border: 3px solid #888;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        &__icon {
            min-width: 25px;
            width: 25px;
            height: 25px;
            color: #888;

            @include pc {
                min-width: 50px;
                width: 50px;
                height: 50px;
            }
        }

        &__item {
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            align-items: center;
            column-gap: 15px;
            color: #333;
            font-size: 1.4rem;
            font-weight: 700;

            @include pc {
                padding: 0;
                flex-direction: column;
                text-align: center;
                row-gap: 20px;
            }
        }

        :not(:first-child) {
            @include sp {
                border-top: 1px solid #D9D9D9;
            }

            @include pc {
                border-left: 1px solid #D9D9D9;
            }
        }
    }
    &__text01 {
        margin-top: 14px;
        color: #41B4E6;
        font-size: 1.4rem;
        font-weight: 700;
        text-align: center;
        @include pc {
            margin-top: 40px;
            font-size: 1.6rem;
        }
    }
    &__text02 {
        margin-top: 2px;
        color: #222;
        font-size: 1.6rem;
        font-weight: 700;
        text-align: center;
        @include pc {
            margin-top: 10px;
            font-size: 1.8rem;
        }
    }
    &__text03 {
        margin-top: 14px;
        color: #333;
        font-size: 1.4rem;
        @include pc {
            font-size: 1.6rem;
            text-align: center;
        }
    }
}

//保険相談の3つのお約束
.p-hokenConsultation02 {
    &__list {
        padding: 5px 20px;
        border-radius: 5px;
        border: 1px solid #41B4E6;

        @include pc {
            padding: 30px 10px;
            border-radius: 10px;
            border: 3px solid #41B4E6;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        &__icon {
            min-width: 25px;
            width: 25px;
            height: 25px;
            color: #41B4E6;

            @include pc {
                min-width: 50px;
                width: 50px;
                height: 50px;
            }
        }

        &__item {
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            column-gap: 15px;
            color: #333;
            font-size: 1.4rem;

            @include pc {
                padding: 0 20px;
                flex-direction: column;
                align-items: center;
                row-gap: 10px;
                font-size: 1.6rem;
            }

            &:not(:first-of-type) {
                @include sp {
                    border-top: 1px solid #D9D9D9;
                }

                @include pc {
                    border-left: 1px solid #D9D9D9;
                }
            }

            h3 {
                margin-bottom: 5px;
                font-weight: 700;
                text-align: center;
            }
        }
    }
}

// ご相談までの流れ
.p-hokenConsultation03 {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #E4F7FF;

    @include pc {
        margin-top: 80px;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &__tabs {
        margin-top: 20px;
        display: flex;
        column-gap: 1.5px;

        li {
            flex: 1;
        }

        @include pc {
            column-gap: 3px;
        }
    }

    &__tab {
        padding-top: 7.5px;
        width: 100%;
        background-color: #41B4E6;
        border: none;
        color: #fff;
        font-weight: bold;
        border-radius: 5px 5px 0 0;
        border-top: 2.5px solid #41B4E6;
        cursor: pointer;

        @include pc {
            &:hover {
                transition: 0.3s ease-in-out;
                opacity: 0.8;
            }
        }

        &[data-active="true"] {
            background-color: #fff;
            color: #41B4E6;

            .p-hokenConsultation03__tab__1 svg {
                color: #41B4E6;
            }
        }

        &__1 {
            svg {
                width: 15px;
                height: 15px;
                color: #fff;
            }
        }
        &__2 {
            font-size: 1.4rem;
            line-height: 1.5;

            @include pc {
                margin-top: 0;
            }
        }

        @include pc {
            padding-top: 15px;
            padding-bottom: 17px;
            border-radius: 10px 10px 0 0;
            border-top-width: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;

            &__1 {
                svg {
                    @include pc {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            &__2 {
                font-size: 1.8rem;
            }
        }
    }

    &__content {
        padding: 25px 20px;
        background-color: #fff;
        border-radius: 0 0 5px 5px;
        font-size: 1.4rem;

        @include pc {
            padding: 50px 40px;
            border-radius: 0 0 10px 10px;
            font-size: 1.6rem;
        }

        &[data-active="true"] {
            display: block;
        }

        &[data-active="false"] {
            display: none;
        }
    }
}

.p-hokenConsultation__flow {
    &__merit {
        @include pc {
            display: grid;
            grid-template-columns: 545px 1fr;
            align-items: center;
            column-gap: 30px;
        }
    }
    &__images {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        @include pc {
            row-gap: 20px;
        }
        &__note {
            @include pc {
                order: 2;
            }
        }
        &__image {
            img {
                width: 100%;
            }
            @include pc {
                order: 1;
            }
        }
    }

    &__list {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        @include pc {
            margin-top: 0;
            row-gap: 10px;
        }

        li {
            display: flex;
            column-gap: 5px;
            @include pc {
                column-gap: 10px;
            }

            &::before {
                content: "";
                min-width: 6px;
                width: 6px;
                height: 6px;
                background-color: #41B4E6;
                border-radius: 9999px;
                position: relative;
                top: .5em;

                @include pc {
                    min-width: 8px;
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }

    &__title {
        margin-top: 40px;
        margin-bottom: 15px;
        font-size: 1.8rem;
        font-weight: 700;
        @include pc {
            margin-top: 80px;
            margin-bottom: 10px;
            font-size: 2rem;
        }
    }

    &__steps {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        @include pc {
            row-gap: 10px;
        }
    }

    &__step {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #D9D9D9;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        @include pc {
            padding: 17px;
            border-width: 3px;
            border-radius: 10px;
            flex-direction: row;
            column-gap: 20px;
            align-items: baseline;
        }

        &__no {
            padding: 5px;
            width: fit-content;
            background-color: #E4F7FF;
            border-radius: 5px;
            color: #41B4E6;
            font-size: 1.2rem;
            font-weight: 700;

            @include pc {
                padding: 5px 10px;
                border-radius: 10px;
                font-size: 1.4rem;
            }
        }

        &__online05 {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            @include pc {
                row-gap: 20px;
            }

            img {
                @include pc {
                    width: 360px;
                }
            }
        }

        &__note {
            @include sp {
                font-size: 1.2rem;
            }
        }
    }

    &__stepArrow {
        display: flex;
        justify-content: center;
        svg {
            width: 15px;
            height: 15px;
            color: #D9D9D9;
            @include pc {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__notes {

        &__read {
            font-size: 1.2rem;
            @include pc {
                font-size: 1.4rem;
            }
        }

        &__list {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            @include pc {
                margin-top: 10px;
                row-gap: 10px;
            }
            > li {
                display: flex;
                column-gap: 5px;
                @include pc {
                    column-gap: 10px;
                }

                &::before {
                    content: "";
                    min-width: 6px;
                    width: 6px;
                    height: 6px;
                    background-color: #41B4E6;
                    border-radius: 9999px;
                    position: relative;
                    top: .5em;

                    @include pc {
                        min-width: 8px;
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }

        &__online {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            @include pc {
                row-gap: 10px;
            }

            ul {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                @include pc {
                    row-gap: 3px;
                }
                > li {
                    display: flex;
                    align-items: baseline;

                    &::before {
                        content: "・";
                    }
                }
            }

            p {
                margin-bottom: -5px;

                @include pc {
                    margin-bottom: -10px;
                }
            }
        }
    }
}

/*
------------------------------------------------------------*/
