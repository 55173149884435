@use '../../0_base' as *;

/*------------------------------------------------------------
ご契約者の方へ（問い合わせ一覧）
------------------------------------------------------------*/
.p-hokenContact02 {
	.c-hokenTitle02 {
		padding-left: 26px;
		margin-bottom: 30px;
		@include sp {
			padding-left: 15px;
			margin-bottom: 20px;
		}
	}
	&__list {
        display: grid;
        row-gap: 20px;
        @include pc {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
	}
	&__list2 {
        display: grid;
        row-gap: 20px;
        @include pc {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
	}

	&__card {
        padding: 15px;
        border: 1.5px solid #FA8246;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        @include pc {
            padding: 30px;
            height: 100%;
            border-width: 3px;
            border-radius: 10px;
            row-gap: 10px;
        }
		&__ttl {
            padding-bottom: 5px;
            border-bottom: 1px solid #FA8246;
            font-size: 1.9rem;
            font-weight: 700;
            @include pc {
                font-size: 2.4rem;
            }
		}
		&__ttl02 {
            display: flex;
            column-gap: 5px;
            align-items: center;
            font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }

            &::before {
                content: "";
                min-width: 6px;
                width: 6px;
                height: 6px;
                background-color: #41B4E6;
                border-radius: 50%;
                @include pc {
                    min-width: 8px;
                    width: 8px;
                    height: 8px;
                    font-size: 1.6rem;
                }
            }
		}
		&__ttl03 {
            display: flex;
            column-gap: 5px;
            align-items: center;
            font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }

            &::before {
                content: "";
                min-width: 6px;
                width: 6px;
                height: 6px;
                background-color: #FA8246;
                border-radius: 50%;
                @include pc {
                    min-width: 8px;
                    width: 8px;
                    height: 8px;
                    font-size: 1.6rem;
                }
            }
		}
		&__txt {
            color: #222;
            font-size: 1.4rem;
            font-weight: 700;
            @include pc {
                font-size: 1.6rem;
            }
		}
		&__tel {
            a {
                @include sp {
                    padding-left: 15px;
                    padding-right: 15px;
                    width: fit-content;
                    min-height: 47.5px;
                    display: flex;
                    column-gap: 7.5px;
                    align-items: center;
                    background-color: #FA8246;
                    border-radius: 9999px;
                    color: #fff;
                    font-size: 2.5rem;
                    font-weight: 700;

                    svg {
                        min-width: 25px;
                        width: 25px;
                        height: 25px;
                    }
                }
                @include pc {
                    width: fit-content;
                    display: flex;
                    column-gap: 15px;
                    align-items: center;
                    color: #FA8246;
                    font-size: 3.2rem;
                    font-weight: 700;

                    svg {
                        min-width: 35px;
                        width: 35px;
                        height: 35px;
                    }
                }
            }
		}
		&__telTime {
            color: #222;
            font-size: 1.4rem;
		}
		&__web {
            color: #222;
            font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }
			a {
                width: fit-content;
                display: flex;
                align-items: baseline;
                color: #41B4E6;
                >span{
                    text-decoration: underline;
                }
				&:before {
					content: '▶';
				}
			}
		}
	}
	#contact01 {
		.p-hokenContact02__card__tel {
			p {
				&:nth-child(1) {
					font-size: 3.2rem;
					letter-spacing: 0;
					span {
						font-size: 1.4rem;
						margin-right: 38px;
						&:after {
							width: 24px;
							height: 24px;
							background-size: contain;
							top: -9px;
							right: -29px;
						}
					}
				}
			}
		}
	}
}

