@use '../../0_base' as *;

/*------------------------------------------------------------
くらしのサービス 商品詳細
------------------------------------------------------------*/

.p-kurashiServiceDetail {
	background-color: #ffffff;
}
.p-kurashiServiceDetai__txtarea {
	p {
		margin-bottom: 30px;
	}
}

//商品詳細
.p-kurashiServiceDetail01 {
	margin-bottom: 50px;
	font-size: 1.6rem;
	line-height: 1.75;
	color: $kurashiTextColor01;
	.c-kurashiTitle02 {
		margin-bottom: 40px;
		line-height: 1.3;
	}
	&__content01 {
		margin-bottom: 33px;
		&__slide {
			width: 710px;
			margin: 0 auto 8px auto !important;

			img {
				width: auto;
				height: 100%;
				max-height: 406px;
				margin: 0 auto;
				@include sp {
					height: 183px;
				}
			}
		}
		&__sliderBord {
			.slick-dotBox {
				.slick-dots {
					position: static;
					li {
						margin: 0;
						button {
							&:before {
								font-size: 10px;
								color: $glaylight01;
								opacity: 1 !important;
							}
						}
						&.slick-active {
							button {
								&:before {
									color: $kurashiColor01;
								}
							}
						}
					}
				}
			}
			.slick-arrow2 {
				width: 30px;
				height: 30px;
				margin: 0 10px;
				display: none;
				vertical-align: middle;
				background-image: url("../img/common/icon_maru-sankaku01.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				&.slick-prev2 {
					transform: rotate(180deg);
				}
			}
		}
		.slick-arrow {
			width: 21px;
			height: 34px;
			&:before {
				content: none;
			}
			&.slick-prev {
				background-image: url("../img/common/icon_arrow-orange03.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				opacity: 1 !important;
				top: calc(50% - 17px);
				left: -60px;
				z-index: 10;
				transform: rotate(180deg);
			}
			&.slick-next {
				background-image: url("../img/common/icon_arrow-orange03.png") !important;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				opacity: 1 !important;
				top: calc(50% - 17px);
				right: -60px;
				z-index: 10;
				transform: none;
			}
		}
	}
	&__content02 {
		margin-bottom: 31px;
		&__movie {
			width: 710px;
			margin: 0 auto;
			iframe {
				width: 100%;
				height: 330px;
			}
			+ .p-kurashiServiceDetail01__content02__movie {
				margin-top: 30px;
			}
		}
	}
	&__content03 {
		margin-bottom: 52px;
	}
	&__content04 {
		margin-bottom: 35px;
		.c-table2 {
			width: 100%;
			margin-bottom: 17px;
			dl {
				color: $kurashiTextColor01;
				label {
					color: $kurashiTextColor01;
				}
				dt {
					width: 160px;
					background-color: rgba($kurashiColor01,0.15);
					padding: 14px 0 12px 30px;
				}
				dd {
					width: calc(100% - 160px);
					padding: 14px 0 12px 31px;
					strong {
						color: $strongColor04;
						font-weight: bold;
					}
				}
			}
		}
		&__annotation {
			font-size: 1.3rem;
		}
		&__annotation2 {
			font-size: 1.3rem;
			margin-bottom: 5px;
		}
	}
	&__content05 {
		margin-bottom: 62px;
		form {
			margin-bottom: 17px;
		}
		.c-tbl01--kurashiCart {
			width: 100%;
			th{
				padding: 10px 0 10px 28px;
			}
			td{
				padding: 19px 17px 21px 31px !important;

			}
			.c-tbl01__title {
				background-color: rgba($kurashiColor01,0.15);
			}
			.c-tbl01__item{
				.col-name{
					width: auto;
				}
				.col-note{
					width: 180px;
				}
				.col-price{
					width: 180px;
				}
				.col-num{
					width: 180px;
					select{
						width: 58px;
						height: 30px;
						border-radius: 5px;
						padding: 0 7px;
						margin-right: 10px;
						display: inline-block;
					}
				}
			}
		}
		.l-errorBox {
			text-align: right;
			@include sp {
				text-align: left;
			}
		}
		.c-error {
			margin-top: 20px;
			@include sp {
				margin-top: 10px;
			}
		}
		.c-cartBtn {
			margin-top: 10px;
			display: block;
			@include clear;
			&__btn15 {
				width: 140px;
				height: 34px;
				font-size: 1.6rem;
			}
		}
	}
	&__content06 {
		margin-bottom: 40px;
		display: flex;
		justify-content: center;
		@include sp {
			display: block;
		}
		.c-kurashiBtn03 {
			margin: 0;
			&:nth-of-type(2) {
				margin-left: 20px;
				@include sp {
					margin-left: 0;
					margin-top: 10px;
				}
			}
		}

	}
	&__content07 {
		margin-bottom: 30px;
	}
	&__content08 {
		&__box {
			padding: 27px 22px;
			border: solid 5px rgba($kurashiColor01,0.15);
			&__tel {
				margin-top: -5px;
				font-size: 1.4rem;
				line-height: 1.5;
				span {
					font-size: 2.2rem !important;
					letter-spacing: 1px !important;
					&:before {
						width:21px;
						height: 18px;
						margin: -3px 11px 0 3px;
						content: '';
						background-image: url("../img/common/icon_tel03.png");
						-webkit-background-size: cover;
						background-size: cover;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
			table {
				width: 100%;
				th {
					width: 140px;
					padding: 5px 0 9px;
					font-weight: bold;
					text-align: left;
					vertical-align: top;
				}
				td {
					padding: 5px 0 9px;
					vertical-align: top;
					color: $kurashiTextColor06;
					a {
						color: $linkColor01;
						text-decoration: underline;
						&:hover {
							opacity: 1;
							text-decoration: none;
						}
					}
					span {
						font-size: 1.4rem;
						line-height: 1.5;
					}
				}
			}
		}
	}
}

//関連商品
.p-kurashiServiceDetail02 {
	margin-bottom: 100px;
	&__content01 {
		.c-kurashiPager01 {
			margin-top: 13px;
		}
	}
}

@include sp {
	.p-kurashiServiceDetail {
		padding: 30px 0;
	}
	.p-kurashiServiceDetai__txtarea {
		p {
			margin-bottom: 15px;
			font-size: 1.3rem;
			line-height: 1.6;
		}
	}

	//商品詳細 sp
	.p-kurashiServiceDetail01 {
		margin-bottom: 30px;
		.c-kurashiTitle02{
			margin: -3px 20px 33px;
			padding: 0 0 0 13px;
			line-height: 1.5;
			letter-spacing: 2.2px;
			border-left: 3px solid $kurashiColor01;
			&:before{
				display: none;
			}
			&:after{
				left: 0;
				top: unset;
				bottom: -13px;
			}
		}
		p {
			font-size: 1.4rem;
		}
		&__content01 {
			margin-bottom: 20px;
			&__slide {
				width: 100%;
				margin: 0 auto 10px !important;
				max-height: 100%;
			}
			&__sliderBord {
				.slick-arrow2 {
					display: inline-block;
				}
			}
		}
		&__content02 {
			margin-bottom: 21px;
			&__movie {
				width: 100%;
				margin: 0 auto;
				iframe {
					max-width: 100%;
					height: 100%;
				}
				+ .p-kurashiServiceDetail01__content02__movie {
					margin-top: 15px;
				}
			}
		}
		&__content03 {
			margin-bottom: 20px;
			padding: 0 20px;
		}
		&__content04 {
			margin-bottom: 13px;
			padding: 6px 20px 0;
			.c-table2{
				margin-bottom: 6px;
				dt{
					padding: 10px 15px 11px !important;
				}
				dd {
					padding: 10px 15px !important;
				}
			}
			&__annotation{
				font-size: 1.1rem;
			}
		}
		&__content05 {
			margin-bottom: 21px;
			padding: 0 20px;
			.c-tbl01--kurashiCart {
				.c-tbl01__item{
					td {
						padding: 0 !important;
						.col-title {
							width: 24%;
							line-height: 1.4;
							margin: 0 10px 0 0;
							background-color: rgba($kurashiColor01,0.15);
							display: table-cell;
							vertical-align: middle;
						}
					}
					.col-num{
						select{
							width: 140px;
							margin: 3px 13px 0 0;
						}
					}
				}
			}
			.c-cartBtn {
				margin-top: 16px;
				&__btn15 {
					max-width: 150px;
					height: 40px;
					margin: 0 auto;
					display: block;
				}
			}
		}
		&__content06 {
			margin-bottom: 20px;
			padding: 0 20px;
			.c-kurashiBtn03{
				width: 100%;
				height: auto;
				padding: 10px;
				line-height: 1;
				a:before{
					margin: -3px 5px 0 20px;
				}
			}
		}
		&__content07 {
			margin-bottom: 20px;
			padding: 0 20px;
		}
		&__content08 {
			padding: 0 20px;
			&__box {
				padding: 10px 13px 2px;
				&__tel_sp {
					text-align: center;
					margin-bottom: 14px;
					a {
						width: 100%;
						padding: 15px 0;
						font-size: 1.1rem;
						color: #ffffff !important;
						text-decoration: none !important;
						background-color: $subColor12;
						display: block;
						position: relative;
						box-shadow: 0 3px 0 #7798b1;
						transition: 0.3s ease-in-out;
						@include radius(4px);
						&:before {
							width: calc(100% - 4px);
							height: calc(100% - 4px);
							content: '';
							border: dashed 1px #ffffff;
							position: absolute;
							top: 2px;
							left: 2px;
							@include radius(4px);
						}
						span {
							font-size: 1.4rem !important;
							font-weight: bold;
							margin-bottom: 8px;
							display: block;
							&:before {
								width: 21px;
								height: 16px;
								margin: -3px 5px 0 0;
								content: '';
								background-image: url("../img/common/icon_tel01.png");
								-webkit-background-size: cover;
								background-size: cover;
								display: inline-block;
								vertical-align: middle;
							}
						}
						&:hover {
							transform: translate(0,3px);
							box-shadow: none;
						}
					}
				}
				table {
					width: 100%;
					font-size: 1.3rem;
					th {
						width: 100%;
						margin-bottom: 9px;
						padding: 0;
						display: block;
					}
					td {
						width: 100%;
						margin-bottom: 10px;
						padding: 0;
						display: block;
						span {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}
	//関連商品 sp
	.p-kurashiServiceDetail02 {
		margin-bottom: 20px;
		padding: 0 20px;
		&__content01 {
			.c-kurashiPager01 {
				margin-top: 0;
			}
		}
	}
}

/*
------------------------------------------------------------*/
