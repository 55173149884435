@use '../../0_base' as *;

/*------------------------------------------------------------
/hoken/movie
------------------------------------------------------------*/
.p-hokenMovieSection {
    padding: 15px;
    border: 1.5px solid #D9D9D9;
    border-radius: 5px;
    display: grid;
    row-gap: 15px;

    @include pc {
        padding: 30px;
        border-width: 3px;
        border-radius: 10px;
        grid-template-columns: 450px 1fr;
        column-gap: 30px;
    }

    &__image {
        margin: 0 !important;
        img {
            width: 100%;
            height: auto;
        }
    }

    &__body {
        @include pc {

        }
    }

    &__title {
        font-size: 1.8rem;
        @include pc {
            font-size: 2.4rem;
        }
    }

    &__action {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        @include sp {
        }

        @include pc {
            margin-top: 30px;
            gap: 20px;
        }

        &__details {
            @include pc {
                .c-hokenBtn11 {
                    padding-left: 50px;
                    padding-right: 50px;
                }
                .c-hokenBtn11__label,
                .c-hokenBtn11__afterIcon {
                    margin-left: auto;
                }
            }
        }
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: grey;
    opacity: .6;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }

.modal {
    height: 70%;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: none;
z-index: 10001;
    width: 100%;
    max-width: 640px;
    max-height: 370px;

    @include sp {
        max-width: 320px;
        max-height: 220px;
    }
}

.modal p {
position: absolute;
top: 50%;
left: 50%;
text-align: center;
transform: translate(-50%, -50%);
z-index: 10;
font-size: 14px;
}

.modal-close__wrap {
position: absolute;
right: 40px;
top: 20px;
}

.modal-close {
background: transparent;
border-color: transparent;
padding: 0;
margin: 0;
cursor: pointer;
width: 28px;
display: block;
height: 28px;
}

.modal-close span {
position: relative;
width: 100%;
height: 1px;
background: black;
display: block;
}

.modal-close span:nth-child(1) {
transform: rotate(45deg);
}

.modal-close span:nth-child(2) {
transform: rotate(-45deg);
}

.modal-open {
cursor: pointer;
background: orange;
border: orange;
color: #fff;
padding: 10px 12px;
}

.overlay.open {
display: block;
}

.modal.open {
display: block;
}
