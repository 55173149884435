@use '../0_base' as *;

/*------------------------------------------------------------
header04（保険）
------------------------------------------------------------*/

.c-head04Pc {
    width: 95%;
    min-width: $baseWidth01;
    height: 50px;
    margin: 0 auto;
    padding: 10px 1% 0 2%;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    @include radius(0 0 25px 25px);
    @include clear;
    .c-head04Logo {
        width: 189px;
    }
}

.c-head04GnaviPc {
    ul {
        @include dib;
        li {
            margin-right: 15px;
            a {
                color: $gray01;
                font-size: 1.4rem;
                line-height: 2.14;
                font-weight: 600;
                &:before {
                    margin: -3px 5px 0 0;
                    content: "";
                    -webkit-background-size: cover;
                    background-size: cover;
                    display: inline-block;
                    vertical-align: middle;
                }
                &:hover {
                    color: $keycolor01;
                }
            }
            &:nth-child(1) {
                a {
                    &:before {
                        width: 14px;
                        height: 19px;
                        background-image: url("../img/common/icon_biginer01.png");
                    }
                }
            }
            &:nth-child(2) {
                a {
                    &:before {
                        width: 17px;
                        height: 18px;
                        background-image: url("../img/common/icon_reaf01.png");
                    }
                }
            }
        }
    }
}

.c-head04CateTitle {
    height: 70px;
    padding-top: 20px;
    font-size: 2.4rem;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 9998;
}

.c-head04BtnPc {
    width: 100%;
    min-width: max-content;
    height: 60px;
    background: #fff;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 9998;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    white-space: nowrap;

    &__navi {
        ul {
            display: flex;
            column-gap: 40px;
        }
    }

    &__subNavi {
        ul {
            display: flex;
            column-gap: 20px;
        }
    }

    &__textLink {
        display: block;
        color: #333;
        font-size: 1.8rem;
        font-weight: bold;
    }

    &__btn {
        padding-left: 20px;
        padding-right: 20px;
        height: 45px;
        display: flex;
        align-items: center;
        border-radius: 9999px;
        column-gap: 10px;
        font-size: 1.8rem;
        font-weight: bold;
        color: #fff;

        &__icon {
            width: 20px;
            height: 20px;
            fill: currentColor;
        }
    }

    &__btn--soudan {
        background-color: #FA8246;
    }

    &__btn--request {
        background-color: #41B4E6;
    }
}

@include sp {
    .c-head04Sp {
        width: 100%;
        height: 50px;
        padding-left: 25px;
        padding-right: 25px;
        background-color: #ffffff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: flex;
        align-items: center;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.20);

        .c-head04Logo {
            width: 127px;
        }
        .c-spMenu {
            background-color: #ffffff;
        }
        .c-headCateTitleSp {
            padding: 14px 14px 0 12px;
            background-color: $hokenColor01;
        }
        &__navi {
            margin-left: auto;
            display: flex;
            column-gap: 15px;
        }
    }
    .c-head04LinkSp {
        dl {
            width: 100%;
            dt {
                width: 100%;
                background-color: $keycolor02;
                a {
                    padding: 7px 35px 7px 20px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    line-height: 1.5;
                    display: block;
                    color: #ffffff;
                    background-image: url("../img/common/icon_arrow-white02.png");
                    background-repeat: no-repeat;
                    -webkit-background-size: 7px;
                    background-size: 7px;
                    background-position: 95% center;
                }
            }
            dd {
                padding: 15px 20px 5px;
                background-color: #ffffff;
                ul {
                    li {
                        width: 49%;
                        height: 45px;
                        margin: 0 2% 2% 0;
                        border: solid 1px $glaylight01;
                        background-color: #ffffff;
                        @include radius(8px);
                        a {
                            width: 100%;
                            height: 100%;
                            padding: 2px 0 2px 45px;
                            font-size: 1.4rem;
                            font-weight: bold;
                            line-height: 2.928;
                            display: block;
                            position: relative;
                            &:before {
                                width: 30px;
                                height: 30px;
                                content: "";
                                background-repeat: no-repeat;
                                -webkit-background-size: 80%;
                                background-size: 80%;
                                background-position: center center;
                                position: absolute;
                                top: 6px;
                                left: 8px;
                                @include radius(50%);
                            }
                        }
                        &:nth-child(1) {
                            a {
                                color: $ticketColor02;
                                &:before {
                                    background-color: $ticketColor01;
                                    background-image: url("../img/common/icon_ticket01.png");
                                }
                            }
                        }
                        &:nth-child(2) {
                            margin-right: 0;
                            a {
                                color: $reformColor02;
                                &:before {
                                    background-color: $reformColor01;
                                    background-image: url("../img/common/icon_reform01.png");
                                }
                            }
                        }
                        &:nth-child(3) {
                            a {
                                line-height: 1.2;
                                color: $kurashiColor02;
                                padding-top: 7px;
                                &:before {
                                    background-color: $kurashiColor01;
                                    background-image: url("../img/common/icon_kurashi01.png");
                                }
                            }
                        }
                        &:nth-child(4) {
                            margin-right: 0;
                            a {
                                color: $sosaiColor02;
                                &:before {
                                    background-color: $sosaiColor01;
                                    background-image: url("../img/common/icon_sosai01.png");
                                }
                            }
                        }
                    }
                }
            }
        }
        div {
            width: 100%;
            padding: 0 20px 20px;
            background-color: #ffffff;
            a {
                padding: 0 0 0 15px;
                font-size: 1.4rem;
                line-height: 1.5;
                display: block;
                color: $keycolor01;
                background-image: url("../img/common/icon_arrow-green06.png");
                background-repeat: no-repeat;
                -webkit-background-size: 8px;
                background-size: 8px;
                background-position: 0 5px;
            }
        }
    }
    .c-head04GnaviSp {
        margin-bottom: 20px;
        .c-head04GnaviSp__navi {
            width: 100%;
            a,
            em {
                padding: 11px 35px 11px 20px;
                font-size: 1.3rem;
                font-weight: bold;
                font-style: normal;
                line-height: 1.5;
                color: $gray01;
                display: block;
            }
            &.c-head04GnaviSp__navi--1 {
                color: #ffffff;
                background-color: $hokenColor01;
                background-image: url("../img/common/icon_arrow-white01.png");
                background-repeat: no-repeat;
                -webkit-background-size: 10px;
                background-size: 10px;
                background-position: 94% center;
                a {
                    color: #ffffff;
                }
            }
            &.c-head04GnaviSp__navi--2 {
                background-color: rgba(255, 255, 255, 0.9);
                border-bottom: dashed 1px $glaylight01;
                a {
                    position: relative;
                    &:after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 15px;
                        margin: auto 0;
                        display: block;
                        content: "";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 6px 0 6px 6px;
                        border-color: transparent transparent transparent #82cdf3;
                    }
                }
            }
            &.c-head04GnaviSp__navi--3 {
                border-bottom: solid 1px $hokenColor01;
                dt {
                    background-color: #ffffff;
                    position: relative;
                    a,
                    em {
                        width: calc(100% - 60px);
                        font-size: 1.4rem;
                        background: none;
                    }
                }
                dd {
                    padding: 0 0 0 10px;
                    background-color: rgba(255, 255, 255, 0.9);
                    border-bottom: dashed 1px $glaylight01;
                    &:first-of-type {
                        border-top: solid 1px $hokenColor01;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                    a {
                        position: relative;
                        &:after {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 15px;
                            margin: auto 0;
                            display: block;
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 6px 0 6px 6px;
                            border-color: transparent transparent transparent #82cdf3;
                        }
                    }
                    &.c-head04GnaviSp__navi__sub {
                        padding: 0;
                        background: none;
                        dl {
                            dt {
                                background-color: rgba(255, 255, 255, 0.9);
                                background-image: none;
                                border-bottom: dashed 1px $glaylight01;
                                padding-left: 10px;
                                .c-spMenuAccordionBtn2 {
                                    width: 50px;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    z-index: 100;
                                    &:before {
                                        width: 10px;
                                        height: 2px;
                                        content: "";
                                        margin-top: auto;
                                        margin-bottom: auto;
                                        background-color: $hokenNaviColor02;
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        right: 15px;
                                        transition: transform 0.3s;
                                        @include tr3d0;
                                    }
                                    &:after {
                                        width: 2px;
                                        height: 10px;
                                        margin-top: auto;
                                        margin-bottom: auto;
                                        content: "";
                                        background-color: $hokenNaviColor02;
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        right: 19px;
                                        transition: transform 0.3s;
                                        @include tr3d0;
                                    }
                                }
                            }
                            dd {
                                border: none;
                                background-color: rgba($hokenColor02, 0.03);
                                display: none;
                                padding-left: 40px;
                                &:first-of-type {
                                    padding-top: 5px;
                                }
                                &:last-child {
                                    padding-bottom: 5px;
                                }
                                a {
                                    padding: 5px 9px;
                                    line-height: 1;
                                    text-decoration: underline;
                                    background-image: none;
                                    position: relative;
                                    &:before {
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0px;
                                        margin: auto 0;
                                        display: block;
                                        content: "";
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 4.5px 0 4.5px 4px;
                                        border-color: transparent transparent transparent #82cdf3;
                                    }
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                            &.is-open {
                                .c-spMenuAccordionBtn2 {
                                    &:before {
                                        -webkit-transform: rotate(180deg);
                                        transform: rotate(180deg);
                                    }
                                    &:after {
                                        -webkit-transform: rotate(90deg);
                                        transform: rotate(90deg);
                                    }
                                }
                                dd {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
        .c-head04GnaviSp__navi__menuTitle {
            padding: 0 20px;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 1.5;
            color: $gray01;
            background-image: none;
            background-color: $hokenNaviColor01;
            border-bottom: solid 2px $glaylight01;
            span {
                padding: 12px 0;
                display: inline-block;
                position: relative;
                &:before {
                    width: 24px;
                    height: 24px;
                    content: "";
                    margin: -3px 10px 0 0;
                    background-color: $hokenNaviColor02;
                    -webkit-background-size: 60%;
                    background-size: 60%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    display: inline-block;
                    vertical-align: middle;
                    @include radius(50%);
                }
                &:after {
                    width: 100%;
                    height: 0;
                    content: "";
                    position: absolute;
                    border-bottom: solid 2px $hokenColor01;
                    top: 100%;
                    left: 0;
                }
            }
            &.c-head04GnaviSp__navi__menuTitle--sagasu {
                span {
                    &:before {
                        background-image: url("../img/common/icon_search02.png");
                    }
                }
            }
            &.c-head04GnaviSp__navi__menuTitle--chishiki {
                span {
                    &:before {
                        background-image: url("../img/common/icon_book01.png");
                    }
                }
            }
            &.c-head04GnaviSp__navi__menuTitle--service {
                span {
                    &:before {
                        background-image: url("../img/common/icon_hoken01.png");
                    }
                }
						}
						&.c-head04GnaviSp__navi__menuTitle--policy {
							span {
									&:before {
											background-image: url("../img/common/icon_policy.png");
									}
							}
					}
        }
    }
    .c-head04BtnSp {
        margin-bottom: 40px;
        padding: 0 20px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        a {
            width: 48%;
            height: 50px;
            font-size: 1.2rem;
            font-weight: bold;
            -webkit-background-size: cover;
            background-size: cover;
            vertical-align: top;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            text-align: center;
            @include radius(30px);
            &:before {
                width: 28px;
                height: 28px;
                content: "";
                background-color: #ffffff;
                -webkit-background-size: 60%;
                background-size: 60%;
                background-position: center center;
                background-repeat: no-repeat;
                display: inline-block;
                vertical-align: middle;
                @include radius(50%);
            }
            &:nth-child(1) {
                color: $hokenColor02;
                background-image: url("../img/hoken/bg02.jpg");
                &:before {
                    margin: -3px 6px 0 -6px;
                    background-image: url("../img/common/icon_mail01.png");
                }
                span {
                    line-height: 1.425;
                    text-align: left;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            &:nth-child(2) {
                color: #ffffff;
                line-height: 2.85;
                background-image: url("../img/hoken/bg01.jpg");
                &:before {
                    margin: -3px 11px 0 -26px;
                    background-image: url("../img/common/icon_file02.png");
                    -webkit-background-size: 55%;
                    background-size: 55%;
                }
            }
        }
    }
    .c-head04GnaviSp__note {
        display: block;
        font-size: 1.1rem;
        font-weight: normal;
    }

    .c-head04GnaviSp2 {
        margin-top: 5px;

        ul {
            ul {
                padding-left: 10px;
                padding-right: 10px;

                ul {
                    margin-left: -10px;
                    margin-right: -10px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        ul[data-open="false"] {
            display: none;
        }
    }

    .c-head04GnaviSp2__item1 {
        padding: 5px 15px 10px;
        width: 100%;
        background: none;
        text-align: left;
        border: none;
        border-bottom: 1px solid #D9D9D9;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        column-gap: 20px;
        color: #41B4E6;
        font-size: 2.2rem;
        font-weight: bold;

        &__icon {
            margin-left: auto;
            width: 15px;
            height: 15px;
            svg {
                width: 100%;
                height: 100%;
                display: block;
                fill: currentColor;
            }
        }

        &[data-open="true"] {
            .c-head04GnaviSp2__item1__icon__plus {
                display: none;
            }
        }

        &[data-open="false"] {
            .c-head04GnaviSp2__item1__icon__minus {
                display: none;
            }
        }

        &__note {
            font-size: 1rem;
            color: #000;
            font-weight: normal;
        }
    }

    .c-head04GnaviSp2__item2 {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: 1.6rem;
        font-weight: bold;
        border: none;
        border-bottom: 1px solid #D9D9D9;
        padding: 5px 0 10px 15px;
        background: none;
        color: #333;

        &__icon {
            width: 12px;
            height: 12px;
            color: #41B4E6;
        }

        &__toggle {
            margin-left: auto;
            padding-left: 10px;
            border: none;
            border-left: 1px solid #d9d9d9;

            svg {
                width: 10px;
                height: 10px;
                color: #41B4E6;
            }
        }
        &__toggleButton {
            padding: 2px 5px;
            display: block;
            border: none;
            background: none;

            svg {
                fill: currentColor;
            }
        }

        &[data-open="true"] {
            .c-head04GnaviSp2__item2__toggleButton__plus {
                display: none;
            }
        }

        &[data-open="false"] {
            .c-head04GnaviSp2__item2__toggleButton__minus {
                display: none;
            }
        }
    }

    .c-head04GnaviSp2__item3 {
        padding: 10px 0 10px 50px;
        border-bottom: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: #333;
        font-size: 1.4rem;

        &__icon {
            width: 10px;
            height: 10px;
        }
    }

    .c-head04SubNaviSp {
        margin-top: 40px;

        li:not(:first-child) {
            margin-top: 10px;
        }

        a {
            color: #222;
            font-size: 1.4rem;
            text-decoration: underline;
        }
    }

    .c-head04ServiceNaviSp {
        margin-top: 40px;

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .c-head04ServiceNaviSp__top {
                width: 100%;
                a {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-size: 1.4rem;
                    font-weight: bold;
                }
            }

            .c-head04ServiceNaviSp__low {
                width: calc(50% - 5px);
            }
        }
    }

    .c-head04ServiceNaviSp__link1 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 1.4rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        border-radius: 9999px;
        border: 1.5px solid #D9D9D9;
        color: #000;

        &__icon {
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 100%;
            &--ticket {
                background: #fc819b url("/img/common/icon_ticket01.png") no-repeat center;
                background-size: 18px 18px;
            }

            &--reform {
                background: #68ba93 url("/img/common/icon_reform01.png") no-repeat center;
                background-size: 18px 18px;
            }

            &--kurashi {
                background: #faad5a url("/img/common/icon_kurashi01.png") no-repeat center;
                background-size: 18px 18px;
            }

            &--sosai {
                background: #af8ebe url("/img/common/icon_sosai01.png") no-repeat center;
                background-size: 18px 18px;
            }
        }

        &__label {
            padding-left: 7px;
            flex: 1;
        }

        &__arrow {
            width: 12px;
            height: 12px;
            color: #d9d9d9;
        }
    }

    .c-head04ServiceNaviSp__link2 {
        padding-left: 15px;
        padding-right: 15px;
        height: 65px;
        font-size: 1.4rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        border-radius: 9999px;
        border: 1.5px solid #D9D9D9;
        color: #000;

        &__icon {
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 100%;
            &--ticket {
                background: #fc819b url("/img/common/icon_ticket01.png") no-repeat center;
                background-size: 18px 18px;
            }

            &--reform {
                background: #68ba93 url("/img/common/icon_reform01.png") no-repeat center;
                background-size: 18px 18px;
            }

            &--kurashi {
                background: #faad5a url("/img/common/icon_kurashi01.png") no-repeat center;
                background-size: 18px 18px;
            }

            &--sosai {
                background: #af8ebe url("/img/common/icon_sosai01.png") no-repeat center;
                background-size: 18px 18px;
            }
        }

        &__label {
            padding-left: 7px;
            flex: 1;
        }

        &__arrow {
            width: 12px;
            height: 12px;
            color: #d9d9d9;
        }
    }

    .c-head04Corporate {
        margin-top: 20px;
        text-align: center;

        &__link {
            display: inline-flex;
            column-gap: 5px;

            color: #222;
            font-size: 1.4rem;
            text-decoration: underline;
            &__icon {
                width: 10px;
                height: 10px;
                display: inline-block;
            }
        }
    }
}


/*
------------------------------------------------------------*/
