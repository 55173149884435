@use '../../0_base' as *;

/*------------------------------------------------------------
チケットTOP
------------------------------------------------------------*/
.p-ticketTop {
	background-color: #ffffff;
	&__cateList {
		margin: 0 0 80px;
		&--txt {
			font-size: 1.5rem;
			text-align: center;
			margin-bottom: 30px;
			@include sp {
				font-size: 1.4rem;
				margin-bottom: 10px;
			}
		}
		&--txt2 {
			font-size: 1.5rem;
			text-align: center;
			margin: 20px 0 30px;
			@include sp {
				font-size: 1.4rem;
				margin-bottom: 10px;
			}
		}
		.c-ticketTitle01 {
			margin-bottom: 0px;
			@include sp {
				margin-bottom: 10px;
			}
		}
		.c-tiketItems01__item {
			dl {
				dd {
					ul {
						padding: 0 20px 40px 20px;
						@include sp {
							padding: 0 10px 30px 10px;
						}
					}
				}
			}

		}
	}
}

//チケットTOPスライダー
.p-ticketTop01 {
	margin-bottom: 83px;
	&__slide {
		height: 380px;
		display: none;
		&.slick-initialized {
			display: block;
		}
		// .slick-track {
		// 	left: 0!important;
		// }
		.slick-slide {
			height: 380px;
			background-color: #ffffff;
			position: relative;
			a {
				width: 100%;
				height: 100%;
				color: #ffffff;
				opacity: 1;
				display: block;
				position: relative;
				transition: opacity 0.3s ease-in-out;
				&:hover {
					opacity: 0.8;
				}
			}
			.p-ticketTop01__slide__bg {
				width: 100%;
				height: 100%;
				opacity: 0.5;
				-ms-filter: blur(5px);
				filter: blur(5px);
				overflow: hidden;
				-webkit-background-size: cover;
				background-size: cover;
				background-position: center center;
				position: absolute;
				top: 0;
				left: 0;
			}
			.p-ticketTop01__slide__img {
				width: 100%;
				height: 100%;
				display: block;
				transition: opacity 0.5s ease-in-out;
				position: relative;
				z-index: 10;
				img {
					// width: 100%;
					width: auto;
					//height: auto;
					height: 100%;
					margin: 0 auto;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
				}
			}
			.p-ticketTop01__slide__text {
				width: 100%;
				transition: opacity 0.5s ease-in-out;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 15;
				&__title {
					font-size: 3.4rem;
					font-weight: bold;
					line-height: 1.2;
					padding: 30px 40px 8px;
					background: -moz-linear-gradient(bottom, rgba(0,0,0,0.7), transparent);
					background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.7), transparent);
					background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
					background-size: 100%;
				}
				&__excerpt {
					font-size: 1.4rem;
					font-weight: 500;
					line-height: 1.5;
					padding: 7px 40px 30px;
					background: rgba(0,0,0,0.7);
				}
			}
			&:not(.slick-center) {
				&:before {
					width: 100%;
					height: 100%;
					content: '';
					position: absolute;
					background-color: transparent;
					z-index: 100;
				}
				a {
					opacity: 0.4;
				}
				.p-ticketTop01__slide__text {
					opacity: 0;
				}
			}
		}
		.slick-arrow {
			width: 30px;
			height: 60px;
			&:before {
				content: none;
			}
			&.slick-prev {
				background-color: rgba(255,255,255,0.8) !important;
				background-image: url("/img/common/icon_arrow-gray01.png") !important;
				-webkit-background-size: 8px !important;
				background-size: 8px !important;
				background-position: center center !important;
				background-repeat: no-repeat !important;
				opacity: 1 !important;
				top: calc(50% - 30px);
				left: calc(20% - 30px);
				z-index: 10;
				transform: rotate(180deg);
			}
			&.slick-next {
				background-color: rgba(255,255,255,0.8) !important;
				background-image: url("/img/common/icon_arrow-gray01.png") !important;
				-webkit-background-size: 8px !important;
				background-size: 8px !important;
				background-position: center center !important;
				background-repeat: no-repeat !important;
				opacity: 1 !important;
				top: calc(50% - 30px);
				right: calc(20% - 30px);
				z-index: 10;
				transform: none;
			}
		}
		.slick-dots {
			bottom: -33px;
			li {
				width: 60px;
				height: 14px;
				margin: 0 2px;
				button {
					width: 60px;
					height: 14px;
					&:before {
						width: 60px;
						height: 4px;
						content: '';
						background-color: $glaylight01;
						opacity: 1 !important;
					}
				}
				&.slick-active {
					button {
						&:before {
							content: '';
							background-color: $ticketColor01;
						}
					}
				}
			}
		}
	}
}

//チケットを探す
.p-ticketTop02 {
	margin-bottom: 40px;
	padding: 45px 0 43px;
	background: url("/img/ticket/line01.png") repeat-x 0 0, url("/img/ticket/line01.png") repeat-x 0 bottom;
	&__content01 {
		margin-bottom: 21px;
		padding: 0 21px 20px;
		border-bottom: dashed 1px $gray02;
		p {
			width: 225px;
			font-size: 2.0rem;
			font-weight: bold;
			color: $ticketNaviColor02;
			&:before {
				width: 18px;
				height: 18px;
				margin: 0 10px 0 0;
				content: '';
				background-image: url("/img/common/icon_search01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		&__search {
			width: 570px;
			position: relative;
			input[type="text"] {
				width: 100%;
				height: 40px;
				padding: 0 130px 0 10px;
				font-size: 1.4rem;
				line-height: 2.57;
				color: $gray01;
				border: solid 1px rgba($ticketBtnColor05,0.5);
				border-radius: 0;
			}
			input[type="submit"] {
				width: 117px;
				height: 32px;
				display: block;
				border: none;
				background-image: url("/img/common/icon_search07.png");
				-webkit-background-size: cover;
				background-size: cover;
				position: absolute;
				top: 4px;
				right: 4px;
				appearance: none;
				border-radius: 0;
				@include ct;
			}
		}
	}
	&__content02 {
		margin-bottom: 6px;
		padding: 0 15px 0 21px;
		p {
			width: 225px;
			font-size: 1.6rem;
			font-weight: bold;
			color: $ticketNaviColor02;
			&:before {
				width: 18px;
				height: 18px;
				margin: -2px 10px 0 0;
				content: '';
				background-image: url("/img/common/icon_light01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		&__catelist {
			width: 575px;
			li {
				margin: 0 5px 5px 0;
				font-size: 1.4rem;
				font-weight: 500;
				cursor: pointer;
				background-color: rgba($ticketBtnColor05,0.1);
				@include tr3d0;
				a {
					padding: 5px 13px 1px;
					line-height: 1;
					width: 100%;
					height: 100%;
					display: block;
					color: $ticketNaviColor02;
					@media all and (-ms-high-contrast:none){
						padding: 5px 13px 1px;
					}
				}
			}
		}
	}
	&__content03 {
		padding: 0 15px 0 21px;
		p {
			width: 225px;
			font-size: 1.6rem;
			font-weight: bold;
			color: $ticketNaviColor02;
			&:before {
				width: 18px;
				height: 18px;
				margin: -2px 10px 0 0;
				content: '';
				background-image: url("/img/common/icon_shine01.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
			}
		}
		&__catelist {
			width: 575px;
			li {
				margin: 0 5px 5px 0;
				font-size: 1.4rem;
				font-weight: 500;
				background-color: rgba($ticketBtnColor05,0.1);
				@include tr3d0;
				a {
					padding: 3px 12px 1px;
					width: 100%;
					height: 100%;
					display: block;
					color: $ticketNaviColor02;
					@media all and (-ms-high-contrast:none){
						padding: 5px 13px 0px;
					}
				}
			}
		}
	}
}

//カテゴリ選択
.p-ticketTop03 {
	margin-bottom: 60px;
	padding-bottom: 52px;
	background: url("/img/ticket/line01.png") repeat-x 0 bottom;
	&__content01 {
		&__cateBox {
			&__cateList {
				width: 100%;
				margin-bottom: 30px;
				border-collapse: collapse;
				@include dtbl;
				li {
					padding: 0.5em;
					font-size: 1.6rem;
					color: $ticketNaviColor02;
					text-align: center;
					border: solid 1px $glaylight01;
					transition: 0.3s ease-in-out;
					cursor: pointer;
					&:hover, &.is-open {
						color: #ffffff;
						background-color: $ticketColor01;
					}
				}
			}
			&__items {
				padding: 0 28px;
				display: none;
				&.is-open {
					display: block;
				}
				&__slider {
					display: none;
					&.slick-initialized {
						display: block;
					}
					.slick-arrow {
						width: 11px;
						height: 19px;
						&:before {
							content: none;
						}
						&.slick-prev {
							background-image: url("/img/common/icon_arrow-green02.png") !important;
							-webkit-background-size: cover !important;
							background-size: cover !important;
							opacity: 1 !important;
							transform: rotate(180deg);
						}
						&.slick-next {
							background-image: url("/img/common/icon_arrow-green02.png") !important;
							-webkit-background-size: cover !important;
							background-size: cover !important;
							opacity: 1 !important;
							transform: none;
						}
					}
					.slick-dots {
						bottom: -37px;
						li {
							width: 12px;
							height: 12px;
							margin: 0 5px;
							padding: 1px;
							background-color: #ffffff;
							border: solid 1px $glaylight01;
							@include radius(50%);
							button {
								width: 8px;
								height: 8px;
								padding: 0;
								background-color: #ffffff !important;
								@include radius(50%);
								&:before {
									content: none;
								}
							}
							@include sp{
								width: 10px;
								height: 10px;
								margin: 0 3px;
								button{
									width: 6px;
									height: 6px;
								}
							}
							&.slick-active {
								button {
									background-color: $subColor08 !important;
								}
							}
						}
					}
					//コンポーネント　c-tiketItems01
					.c-tiketItems01__item {
						margin: 0 15px !important;
						.c-tiketItems01__item__img {
							height: 130px;
						}
						dl dt {
							line-height: 1.7;
						}
					}
				}
			}
		}
	}
}

//定番おでかけチケット
.p-ticketTop04 {
	margin-bottom: 40px;
	&__content01 {

		//コンポーネント　c-tiketItems01
		.c-ticketTitle01 {
			margin-bottom: 29px;
		}
	}
}

//チケットサービスのお知らせ
.p-ticketTop05 {
	margin-bottom: 62px;
	&__content01 {
		padding: 22px 32px 30px;
		background: url(/img/ticket/line01.png) repeat-x 0 0, url(/img/ticket/line01.png) repeat-x 0 bottom, url(/img/ticket/wakusen_tate01.png) repeat-y 0 0;
		position: relative;
		h2 {
			margin-bottom: 10px;
			padding-bottom: 18px;
			font-size: 2.0rem;
			font-weight: bold;
			color: $ticketNaviColor02;
			border-bottom: solid 1px $ticketColor01;
		}
		&__newsList {
			li {
				padding: 19px 0 20px;
				font-size: 1.4rem;
				font-weight: 500;
				line-height: 1.5;
				position: relative;
				&:after {
					content: '';
					height: 1px;
					width: 100%;
					display: block;
					background-image: url(/img/ticket/line03.png);
					background-repeat: repeat-x;
					position: absolute;
					left: 0;
					bottom: 0;
				}
				&:last-child {
					padding-bottom: 0;
					&:after {
						content: none;
					}
				}
				a {
					display: flex;
					//color: $linkColor01;
					//text-decoration: underline;
					&:hover{
						.p-ticketTop05__content01__newsList_title{
							text-decoration: none;
						}
					}
				}
			}
			&__thmbs {
				width: 60px;
				height: 45px;
				@include tr3d0;
				img {
					width: 100%;
				}
			}
			&__txt {
    			padding-left: 20px;
    			a {
    				&:hover{
						opacity: 1;
						text-decoration: none;
					}
    			}
			}
			&_data {
				width: 88px;
				margin-bottom: 5px;
				color: $gray03;
				font-weight: 500;
				display: block;
			}
			&_title{
				color: $linkColor01;
				text-decoration: underline;
			}
			.c-newsCate02 {
				width: auto;
				padding: 0 8px;
				margin-bottom: 5px;
				display: block;
			}
			.l-flex {
				display: flex;
			}
		}
		.c-ticketBtn01 {
			position: absolute;
			top: 23px;
			right: 30px;
			a {
				font-size: 1.2rem;
				line-height: 2.8;
			}
		}
	}
}

//お申込みからお届けまでの流れ
.p-ticketTop06 {
	margin-bottom: 96px;
	&__content01 {
		&__case {
			margin-bottom: 34px;
		}
		&__annotation {
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.5;
			color: #232934;
		}
	}
}

@include sp {

	.p-ticketTop {
		&__cateList {
			margin: 70px 0;
			padding: 0 20px;
		}
	}

	//チケットTOPスライダー sp
	.p-ticketTop01 {
		margin-bottom: 57px;
		&__slide {
			height: auto;

			.slick-slide {
				height: auto;
				background: none;
				a {
					height: 100%;
					min-height: 280px;
					transition: none;
					overflow: hidden;
				}
				.p-ticketTop01__slide__img {
					width: 100%;
					height: 70%;
					max-height: 279px;
					display: flex;
					-webkit-align-items: center;
					        align-items: center;
					img {
						position: static;
					}
					&:after {
						display: none;
					}
					&.portrait{
						img {
							width: 100%;
							height: auto;
						}
					}
					&.landscape{
						img {
							width: auto;
							height: 100%;
						}
					}
				}
				.p-ticketTop01__slide__bg {
					max-height: 279px;
				}
				.p-ticketTop01__slide__text {
					width: 100%;
					&__title {
						font-size: 1.7rem;
						padding: 15px 20px;
						background: -moz-linear-gradient(bottom, rgba(0,0,0,0.9), transparent);
						background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9), transparent);
						background: linear-gradient(to top, rgba(0,0,0,0.9), transparent);

					}
					&__excerpt {
						font-size: 1.2rem;
						line-height: 1.4;
						padding: 0 20px 18px 20px;
						background: #000;
					}
				}
				&:not(.slick-center) {
					&:before {
						content: none;
					}
					a {
						opacity: 1;
					}
					.p-ticketTop01__slide__text {
						opacity: 1;
					}
				}
			}
			.slick-arrow {
				width: 10px;
				height: 14px;
				&.slick-prev {
					-webkit-background-size: cover !important;
					background-size: cover !important;
					top: auto;
					bottom: -32px;
					left:13%;
				}
				&.slick-next {
					-webkit-background-size: cover !important;
					background-size: cover !important;
					top: auto;
					bottom: -32px;
					right: 13%;
				}
			}
			.slick-dots {
				bottom: -37px;
				width: 60%;
				margin: 0 auto;
				left: 0;
				right: 0;
				li {
					width: 30px;
					button {
						width: 30px;
						&:before {
							width: 30px;
						}
					}
				}
			}
		}
	}

	//チケットを探す sp
	.p-ticketTop02 {
		margin-bottom: 22px;
		padding: 0 20px;
		background: none;
		&__content01 {
			padding-top: 26px;
			padding: 26px 0 20px;
			background: url("/img/ticket/line01_sp.png") repeat-x 0 0;
			background-size: 15px;
			margin-bottom: 14px;
			p {
				width: 100%;
				margin-bottom: 9px;
				font-size: 1.6rem;
				&:before {
					margin: -2px 5px 0 0;
				}
			}
			&__search {
				width: 100%;
				input[type="text"] {
					padding: 0 40px 0 10px;
					font-size: 1.2rem;
				}
				input[type="submit"] {
					width: 32px;
					height: 32px;
					background-image: url("/img/common/icon_search06.png");
				}
			}
		}
		&__content02 {
			margin-bottom: 13px;
			padding: 0;
			p {
				width: 100%;
				margin-bottom: 15px;
				font-size: 1.4rem;
				&:before {
					margin: -2px 5px 0 0;
				}
			}
			&__catelist {
				width: 100%;
				li {
					margin: 0 6px 5px 0;
					font-size: 1.2rem;
					a {
						padding: 3px 9px 1px;
					}
				}
			}
		}
		&__content03 {
			background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
			background-size: 15px;
			padding: 0 0 24px;
			p {
				width: 100%;
				margin-bottom: 15px;
				font-size: 1.4rem;
				&:before {
					margin: -2px 5px 0 0;
				}
			}
			&__catelist {
				width: 100%;
				li {
					margin: 0 6px 5px 0;
					font-size: 1.2rem;
					a {
						padding: 3px 9px 1px;
					}
				}
			}
		}
	}

	//カテゴリ選択 SP
	.p-ticketTop03 {
		margin-bottom: 41px;
		padding: 0 20px;
		background: none;
		&__content01 {
			padding-bottom: 44px;
			background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
			background-size: 15px;
			> p {
				margin-bottom: 5px;
				font-size: 1.4rem;
				font-weight: bold;
				color: $ticketNaviColor02;
			}
			&__cateBox {
				&__cateList {
					margin-bottom: 16px;
					@include dib;
					li {
						margin: 0 4px 5px 0;
						font-size: 1.2rem;
						padding: 1px 9px;
						letter-spacing: -0.5px;
					}
				}
				&__items {
					padding: 0;
					&__slider {
						width: calc(100% + 20px);
						margin-left: -10px;
						.slick-arrow {
							height: 16px;
							&.slick-prev {
								top: auto;
								bottom: -31px;
								left: 15%;
							}
							&.slick-next {
								top: auto;
								bottom: -31px;
								right: 15%;
							}
						}
						.slick-dots {
							bottom: -30px;
							width: 60%;
							margin: 0 auto;
							left: 0;
							right: 0;
						}

						//コンポーネント　c-tiketItems01
						.c-tiketItems01__item {
							margin: 0 10px !important;
							padding-bottom: 2px;
							&__subCate{
								padding: 0 10px;
								margin: 0 0 10px 9px;
							}
							dd{
								p{
									line-height: 1.3;
								}
								li{
									line-height: 1.2;
								}
							}
							.c-tiketItems01__item__img {
								height: 74px;
							}
						}
					}
				}
			}
		}
	}

	//定番おでかけチケット sp
	.p-ticketTop04 {
		margin-bottom: 21px;
		padding: 0 20px;
		&__content01 {
			padding-bottom: 36px;
			background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
			background-size: 15px;
			//コンポーネント　c-tiketItems01

			.c-ticketTitle01 {
				margin-bottom: 10px;
			}
		}
	}

	//チケットサービスのお知らせ sp
	.p-ticketTop05 {
		margin-bottom: 39px;
		padding: 0 20px;
		&__content01 {
			padding: 0 0 30px 0;
			background: url("/img/ticket/line01_sp.png") repeat-x 0 bottom;
			background-size: 15px;
			h2 {
				margin-bottom: 0;
				padding-bottom: 14px;
				font-size: 1.6rem;
			}
			&__newsList {
				margin-bottom: 20px;
				li {
					padding: 20px 0 21px;
					&:after {
						background-image: url(/img/ticket/line04.png);
						background-repeat: repeat-x;
						background-size: 4px 1px;
					}
					a {
						font-size: 1.3rem;
						width: 100%;
					}
					&:last-child {
						padding-bottom: 22px;
						&:after {
							content: '';
						}
					}
				}
				&__thmbs {
					min-width: 32%;
					height: auto;
					img {
						width: 100%;
					}
				}
				&__txt {
					width: 61%;
					margin-left: 7%;
					padding: 0;
				}
				&_data {
					width: 80px;
				}
				.c-newsCate02 {
					//width: 60px;
					padding: 0 4px;
				}
			}
			.c-ticketBtn01 {
				margin: 0 auto;
				position: static;
				width: 72%;
				height: 40px;
				a {
					font-size: 1.4rem;
					line-height: 3.1;
					&:after {
						margin: -3px -16px 0 20px;
					}
				}
			}
		}
	}

	//お申込みからお届けまでの流れ sp
	.p-ticketTop06 {
		margin-bottom: 56px;
		&__content01 {
			&__case {
				margin: 0;
				padding: 0;
				background-color: $glaylight10;
				&__accordionBtn {
					margin: 0 !important;
					span {
						width: 40px;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						display: block;
						&:before {
							width: 20px;
							height: 2px;
							content: '';
							background-color: $ticketColor01;
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							transition: transform 0.25s ease-out;
						}
						&:after {
							width: 2px;
							height: 20px;
							content: '';
							background-color: $ticketColor01;
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							transition: transform 0.25s ease-out;
						}
					}
				}

				//コンポーネント　c-step

				.c-step {
					width: calc(100% - 40px);
					margin: 0 20px;
					padding-bottom: 20px;
					display: none;
					.c-step__head {
						.c-step__icon {
							@include tr3d0;
						}
						.c-step__head-inner {
							@include tr3d0;
						}
					}

				}
				&.is-open {
					.p-ticketTop06__content01__case__accordionBtn {
						span {
							&:after {
								transform: translate(-50%, -50%) rotate(-90deg);
							}
						}
					}
					.c-step {
						display: block;
					}
				}
			}
			&__annotation {
				margin-top: 20px;
				padding: 0 20px;
			}
		}
		.c-ticketTitle01 {
			line-height: 1.4;
			margin-bottom: 15px;
			padding: 0 25px;
		}
	}
}

/*
------------------------------------------------------------*/
