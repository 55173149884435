@use '../0_base' as *;

/*------------------------------------------------------------
footer04（保険）
------------------------------------------------------------*/

.c-foot04 {
    margin: 0 auto;
	width: 100%;
	min-width: $baseWidth01;
}
.c-foot04NaviArea {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    @include sp {
        display: none;
    }
}
.c-foot04BottomArea {
    padding-top: 40px;

    @include pc {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.c-foot04Link {
    padding-top: 5px;
    padding-right: 30px;
    padding-bottom: 10px;
	width: 227px;
    border-right: 3px solid #D9D9D9;

	dl {
        margin-top: 30px;
		dt {
            &:not(:first-of-type) {
                margin-top: 35px;
            }
			font-size: 1.6rem;
			font-weight: bold;
			a {
                color: #68AA23;
			}
		}
		dd {
            margin-top: 15px;
            padding-left: 10px;
			font-size: 1.6rem;
			a {
				color: #222;
                @include pc {
                    &:hover {
                        text-decoration: underline;
                    }
                }
			}
            ul {
                li {
                    margin-top: 10px;
                }
            }
		}
	}
}
.c-foot04Navi {
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 10px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    dl {
        dt {
            color: #41B4E6;
            &:not(:first-of-type) {
                margin-top: 30px;
            }
            font-size: 1.6rem;
            font-weight: bold;
            a {
                color: inherit;
            }
        }
        dd {
            margin-top: 15px;
            padding-left: 10px;
            font-size: 1.6rem;
            a {
                color: #222;
                &:hover {
                    text-decoration: underline;
                }
            }
            ul {
                li {
                    margin-top: 10px;
                }
            }
        }
    }

    &__memo {
        font-size: 1.2rem;
        color: #222;
        font-weight: normal;
    }
}


@include sp {
	.c-foot04 {
		min-width: 100%;
		padding-top: 0;
		padding-bottom: 40px;
		background-color: #ffffff;
		border: none;
	}
	.c-hokenFlortingBnr {
		width: calc(100% - 120px);
        background: #fff;
		overflow: hidden;
		position: fixed;
		top: auto;
		bottom: 0;
		z-index: 9990;
        display: flex;
        column-gap: 1px;

		a {
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.5;
		}

        &__reserve {
            background: #FA8246;
            color: #fff;
        }
        &__document {
            background: #41B4E6;
            color: #fff;
        }
        &__chat {
            background: #E4F7FF;
            color: #41B4E6;
        }
	}
    .c-hokenFlortingBnrGhost {
        height: 20px;
    }
}

/*
------------------------------------------------------------*/
