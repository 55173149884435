@use '../../0_base' as *;

/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート） 入力・エラー
------------------------------------------------------------*/
.p-ticketCartInput {
	padding: 50px 0 135px 0;
	background-color: #ffffff;
}

//見出し
.p-ticketCartInput01 {
	margin-bottom: 30px;
	.c-formTitle {
		color: $ticketNaviColor02;
	}
}

//フォーム
.p-ticketCartInput02 {
	margin-bottom: 80px;
	&__require {
		margin: 0 10px 0 0;
		display: inline-block;
		vertical-align: middle;
		font-size: 1.1rem;
		font-weight: bold;
		color: #fff;
		background: #f22727;
		padding: 0 5px;
		line-height: 1.6;
		border-radius: 3px;
		@include sp{
			margin-left: 10px;
		}
	}
	&__content01 {
		margin-bottom: 50px;
		&__txt {
			margin-bottom: 40px;
			font-size: 1.6rem;
			color: $gray01;
		}
		&__txt2 {
			margin-bottom: 15px;
			font-size: 1.6rem;
			color: $gray01;
		}
	}
	&__content02 {
		margin-bottom: 50px;
	}
	&__content03 {
		&__back {
			margin-top: 20px;
			font-size: 1.8rem;
			display: block;
			text-align: center;
			color: $linkColor02;
			text-decoration: underline;
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
			&:before {
				width: 7px;
				height: 12px;
				margin: -3px 10px 0 0;
				content: '';
				background-image: url("../img/common/icon_arrow-blue06.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(180deg);
			}
		}
	}
}


@include sp {
	.p-ticketCartInput {
		padding: 29px 20px 0 20px;
	}

	//見出し sp
	.p-ticketCartInput01 {
		margin-bottom: 18px;
		.c-formTitle {
			margin-bottom: 16px;
		}
	}

	//フォーム sp
	.p-ticketCartInput02 {
		margin-bottom: 37px;
		&__content01 {
			margin-bottom: 25px;
			> p {
				margin-bottom: 15px;
				font-size: 1.3rem;
				line-height: 1.5;
			}
			.p-ticketCartInput02__require {
				margin: -2px 5px 0 0;
				font-size: 0.9rem;
				line-height: 1.7;
			}
		}
		&__content02 {
			margin-bottom: 36px;
			.c-checkbox input[type=checkbox] + label {
				padding-left: 23px;
				&:before {
					top: 2px;
					transform: translateY(0);
					width: 14px;
					height: 14px;
				}
				&:after {
					top: 11px;
					left: 4px;
					transform: translateY(0) rotate(45deg);
					height: 9px;
				}
			}
		}
		&__content03 {
			&__back {
				margin-top: 1px;
				font-size: 1.4rem;
				&:before {
					width: 5px;
					height: 9px;
					margin: 0 11px 0 0;
				}
			}
			.c-cartBtn {
				margin-top: 0;
			}
		}
	}
}


//フォーム01
.p-ticketFormTable01 {
	dl {
		&:nth-child(3) input {
			width: 280px;
			padding: 0 15px;
		}
		&:nth-child(4), &:nth-child(5){
			li{
				float: left;
			}
			input {
				width: 240px;
				padding: 0 15px;
				margin: 0 28px 0 23px;
			}
		}
		&:nth-child(5) input {
			margin: 0 29px 0 7px;
		}
		&:nth-child(6) input, &:nth-child(7) input{
			padding: 0 15px;
			width: 240px;
		}
		&:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11),&:nth-child(12),&:nth-child(13), &:nth-child(14) {
			input {
				width: 600px;
				padding: 0 15px;
			}
		}
		@include sp {
			&:nth-child(3), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11), &:nth-child(12), &:nth-child(13), &:nth-child(14) {
				input {
					width: 100%;
					padding: 11px 15px;
					font-size: 1.3rem;
				}
			}
			// &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(12), &:nth-child(13), &:nth-child(14) {
			// 	padding-bottom: 13px;
			// }
			&:nth-child(4), &:nth-child(5) {
				li{
					float: none;
					display: inline-flex;
					align-items: center;
					width: 100%;
					margin-bottom: 12px;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
				input {
					width: 96%;
					padding: 11px 15px;
					margin: 0 0 0 7px;
				}
			}
			&:nth-child(5) input{
				width : 95%;
			}
			input {
				height: 38px;
				line-height: 38px;
			}
		}
	}
	.c-table2__radio {
		@include sp {
			li {
				margin-right: 0;
			}
		}
	}
}


/*
------------------------------------------------------------*/
