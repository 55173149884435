@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　ラビュー（しずおか）料金プラン
------------------------------------------------------------*/
.p-sosaiPrice-shizuoka {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.76;
	color: $gray08;
	.c-breadcrumb1{
		padding: 11px 0 76px;
	}
}
//ラビュー（しずおか）の料金プラン
.p-sosaiPrice-shizuoka01 {
	min-width: $baseWidth01;
	padding: 63px 0 69px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	.c-sosaiTitle03 {
		margin: 0;
		img{
			margin: 6px auto 0;
		}
	}
}

//ビューのお葬式プラン
.p-sosaiPrice-shizuoka02 {
	margin-bottom: 120px;
	.c-sosaiTitle02b{
		margin-bottom: 57px;
	}
	&__content01 {
		margin-bottom: 47px;
		.c-sosaiTitle09{
			margin-bottom: 18px;
		}
		p{
			font-size: 1.7rem;
			line-height: 3rem;
			@include sp{
				font-size: 1.4rem;
				line-height: 1.7;
			}
		}
	}
	&__content02 {
		.c-sosaiTitle10{
			margin-bottom: 21px;
			&:before{
				margin: -5px 10px 0 -30px;
			}
		}
		&__plus {
			align-self: center;
		}
		&__box {
			border: solid 1px $glaylight13;
			&--01 {
				width: 220px;
				dd{
					padding: 94px 17px 74px;
				}
			}
			&--02 {
				width: 812px;
				dd{
					padding: 30px 30px 29px;
				}
			}
			&__title1 {
				width: 100%;
				display: table;
				border-bottom: dotted 1px $gray07;
				margin: 17px 0 18px;
				padding-bottom: 12px;
				h5 {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 1.4;
					vertical-align: middle;
					display: table-cell;
					@include mincyo;
				}
			}
			&__title2 {
				width: 100%;
				height: 83px;
				display: table;
				border-bottom: dotted 1px $gray07;
				padding-top: 3px;
				h5 {
					font-size: 2.0rem;
					font-weight: 500;
					line-height: 1.2;
					vertical-align: middle;
					display: table-cell;
					@include mincyo;
				}
				span {
					font-size: 1.2rem;
					display: block;
				}
			}
			&__border {
				border: 1px solid #c8c8c8;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				> p {
					width: 100%;
					font-size: 1.5rem !important;
					font-weight: bold;
					margin-bottom: 16px;
				}
				> div {
					width: 190px;
					margin: 0 auto;
				}
				&:nth-child(1) {
					width: 452px;
					padding: 27px 12px 24px;
				}
				&:nth-child(2) {
					width: 268px;
					padding: 27px 0 44px;
				}
			}
			dt {
				width: 100%;
				padding: 14px 5px 9px;
				font-size: 1.6rem;
				font-weight: 700;
				color: #ffffff;
				background-color: $sosaiNaviColor01;
				text-align: center;
			}
			dd {
				> div {
					text-align: center;
					p {
						font-size: 1.4rem;
						line-height: 2rem;
					}
					img {
						width: 160px;
						height: auto;
					}
				}
			}
		}
	}
}

//お葬式プラン
.p-sosaiPrice-shizuoka03 {
	margin-bottom: 100px;
	padding: 107px 0 120px;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	&__txt{
		text-align: center;
		margin-bottom: 73px;
	}
	&__txt02 {
		font-size: 1.2rem;
		margin-bottom: 34px;
	}
	&__txt03 {
		font-size: 1.2rem;
		margin-top: -10px;
	}
	&__txt04 {
		font-size: 1.2rem !important;
		@include gosick;
		margin-top: 10px !important;
	}
	.c-sosaiTitle11{
		padding: 21px 15px 15px 84px;
		margin-bottom: 39px;
	}
	&__content01 {
		margin-bottom: 60px;
		.p-sosaiPrice-shizuokaPlanArea__flow01{
			margin-bottom: 6px;
		}
		.p-sosaiPrice-shizuokaPlanArea__service{
			margin: 20px 0 4px;
			li{
				p{
					padding-top: 15px;
				}
				&:nth-of-type(1){
					img{
						transform: translate(0, -7px);
					}
				}
				&:nth-of-type(2), &:nth-of-type(11){
					img{
						transform: translate(0, -4px);
					}
				}
				&:nth-of-type(5){
					img{
						transform: translate(0, -10px);
					}
				}
				&:nth-of-type(6){
					img{
						transform: translate(-6px, 5px);
					}
				}
				&:nth-of-type(7), &:nth-of-type(8){
					img{
						transform: translate(0, 5px);
					}
				}
				&:nth-of-type(9){
					img{
						transform: translate(0, -5px);
					}
				}
				&:nth-of-type(12){
					img{
						transform: translate(5px, -7px);
					}
				}
				&:nth-of-type(13){
					img{
						transform: translate(-2px, -8px);
					}
				}
				&:nth-of-type(14){
					img{
						transform: translate(1px, -9px);
					}
				}
				&:nth-of-type(18){
					img{
						transform: translate(0, -10px);
					}
					p{
						padding-top: 0;
					}
				}
			}
		}
	}
	&__content02 {
		margin-bottom: 60px;
		.p-sosaiPrice-shizuokaPlanArea__flow01{
			margin-bottom: 10px;
		}
		.p-sosaiPrice-shizuokaPlanArea__annotation2{
			margin-bottom: 32px;

		}
		.p-sosaiPrice-shizuokaPlanArea__service{
			margin: 27px 0 -5px;
			li{
				p{
					padding-top: 13px;
				}
				&:nth-of-type(1){
					img{
						transform: translate(0, -7px);
					}
				}
				&:nth-of-type(2){
					img{
						transform: translate(0, -4px);
					}
				}
				&:nth-of-type(5){
					img{
						transform: translate(0, -11px);
					}
					p{
						margin: -1px 0 0 4px;
					}
				}
				&:nth-of-type(6){
					img{
						transform: translate(-9px, 0);
					}
				}
				&:nth-of-type(7), &:nth-of-type(8){
					img{
						transform: translate(0, 5px);
					}
				}
				&:nth-of-type(9){
					img{
						transform: translate(6px, -19px);
					}
					p{
						margin: -10px 0 0 4px;
					}
				}
				&:nth-of-type(11){
					img{
						transform: translate(1px, -9px);
					}
				}
				&:nth-of-type(12){
					img{
						transform: translate(0, -9px);
					}
					p{
						margin-top: -13px;
					}
				}
				&:nth-of-type(13){
					img{
						transform: translate(5px, -7px);
					}
					p{
						margin: 0 0 0 4px;
					}
				}
			}
		}
	}
	&__content03 {
		.p-sosaiPrice-shizuokaPlanArea__flow01{
			margin-bottom: 10px;
		}
		.p-sosaiPrice-shizuokaPlanArea__service{
			margin: 29px 0 -11px;
			li{
				p{
					padding-top: 14px;
				}
				&:nth-of-type(1){
					img{
						transform: translate(0, -4px);
					}
				}
				&:nth-of-type(4){
					img{
						transform: translate(0, 14px);
					}
				}
				&:nth-of-type(5){
					img{
						transform: translate(-6px, -1px);
					}
				}
				&:nth-of-type(6){
					img{
						transform: translate(-6px, 5px);
					}
				}
				&:nth-of-type(7){
					img{
						transform: translate(4px, -17px);
					}
					p{
						margin-top: -10px;
					}
				}
				&:nth-of-type(8){
					img{
						transform: translate(2px, -8px);
					}
				}
				&:nth-of-type(9){
					img{
						transform: translate(0, -7px);
					}
					p{
						margin: -12px 0 0 6px;
					}
				}
			}
		}
		&__note {
			width: 510px;
			margin: 0 auto 30px;
			p {
				font-size: 1.2rem;
			}
		}
	}
}

//お葬式プランエリア
.p-sosaiPrice-shizuokaPlanArea {
	padding: 50px 50px 31px;
	background-color: #ffffff;
	font-size: 1.4rem;
	line-height: 1.8;
	border: solid 1px $sosaiNaviColor01;
	.c-sosaiTitle11 {
		width: calc(100% + 100px);
		margin-top: -50px;
		margin-right: -50px;
		margin-left: -50px;
	}
	&__box01 {
		margin-bottom: 38px;
		padding: 41px;
		font-size: 2.0rem;
		font-weight: 500;
		color: $sosaiNaviColor01;
		position: relative;
		z-index: 10;
		@include mincyo;
		em {
			color: $sosaiNaviColor03;
		}
		&:before, &:after {
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			content: '';
			border: solid 1px rgba($sosaiNaviColor03,0.4);
			position: absolute;
			z-index: -1;
		}
		&:before {
			top: 0;
			left: 0;
		}
		&:after {
			top: 10px;
			left: 10px;
		}
	}
	&__box02 {
		margin: 17px auto 19px;
		padding: 30px 30px 31px;
		background-color: $glaylight02;
		border: solid 1px $glaylight13;
	}
	&__annotation {
		font-size: 1.4rem;
	}
	&__annotation2 {
		font-size: 1.2rem;
	}
	&__priceImg {
		margin-right: 40px;
	}
	&__price {
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 1.2;
		p {
			font-size: 2.0rem;
			margin: 0 0 6px -6px;
		}
		i {
			width: 170px;
			margin: 0 25px 0 0;
			padding: 5px 9px;
			font-size: 1.2rem;
			font-style: normal;
			display: inline-block;
			text-align: center;
			color: #ffffff;
			background-color: $sosaiNaviColor03;
			@include gosick;
		}
		em {
			font-size: 7.0rem;
			line-height: 1;
			margin-right: 4px;
		}
		span {
			margin: 0 5px;
			font-size: 3rem;
			font-weight: 500;
			display: inline-block;
		}
		&__box {
			display: flex;
			align-items: center;
		}
		&__item {
			display: flex;
			flex-wrap: wrap;

			&--sub {
				font-size: 3.5rem !important;
				margin: 0 !important;
				display: flex;
				align-items: center;
			}

			.small {
				font-size: 3rem;
				border: none;
				padding: 0;
				margin: 0;
			}
		}
	}
	&__osusume {
		margin: -2px 0 51px;
		li {
			width: 50%;
			height: 96px;
			position: relative;
			font-size: 1.6rem;
			font-weight: 700;
			display: table-cell;
			&:after {
				width: 10px;
				height: 10px;
				content: '';
				background-image: url("../img/common/icon_sankaku_left_top_brown01.png");
				-webkit-background-size: cover;
				background-size: cover;
				position: absolute;
				top: 2px;
				left: 2px;
			}
			&:nth-child(1) {
				border: solid 1px $glaylight13;
			}
			&:nth-child(2) {
				border-top: solid 1px $glaylight13;
				border-bottom: solid 1px $glaylight13;
				border-right: solid 1px $glaylight13;
			}
			p {
				padding: 35px 30px 31px 41px;
				display: table-cell;
				vertical-align: middle;

				&.two-row {
					padding: 20px 30px 17px 41px;
				}
			}
		}
	}
	&__detail {
		dt {
			padding: 20px 0 18px;
			font-size: 1.6rem;
			font-weight: bold;
			line-height: 1.28;
			color: #ffffff;
			background-color: $sosaiNaviColor02;
			display: flex;
			text-align: center;
			align-items: center;
			justify-content: center;
			box-shadow: 0 3px 0 #20656e;
			transition: 0.3s ease-in-out;
			@include radius(3px);
			@include tr3d0;
			&:before {
				width: 10px;
				height: 21px;
				margin: 0 26px 0 7px;
				content: '';
				background-image: url("../img/common/icon_arrow-white03.png");
				-webkit-background-size: cover;
				background-size: cover;
				display: block;
				transform: rotate(90deg);
			}
			&:hover {
				box-shadow: none;
				transform: translate(0, 3px);
			}
		}
		dd {
			padding-top: 41px;
			display: none;
			h4{
				margin: 0;
			}
			.p-sosaiPrice-shizuokaPlanArea__service{
				li{
					width: 190px;
					height: 210px;
					padding: 0;
					margin-right: 17px;
					&:nth-child(5n){
						margin-right: 0;
					}
					.shizuokaPlanArea__service-img{
						height: 118px;
					}
					p{
						line-height: 1.3;
					}
				}
			}
		}
		&.is-open {
			dt {
				&:before {
					transform: rotate(-90deg);
				}
			}
		}
	}
	&__service {
		margin-bottom: 45px;
		li {
			width: 20%;
			padding: 0 20px 41px;
			text-align: center;
			img {
				margin: 0 auto 4px auto;
				display: block;
			}
			p {
				padding-top: 0.5em;
				font-size: 1.6rem;
				font-weight: 700;
				line-height: 2rem;
				color: $sosaiNaviColor01;
				span {
					font-size: 1.2rem;
				}
			}
		}
		&__2line{
			padding-top: 0 !important;
		}
	}
	&__List {
		width: 49%;
		li {
			margin-bottom: 5px;
			padding: 10px 5px 0 27px;
			font-weight: 700;
			font-size: 1.4rem;
			line-height: 1.8rem;
			&:before {
				width: 6px;
				height: 6px;
				margin: -3px 10px 0 -17px;
				content: '';
				background-color: $sosaiNaviColor02;
				display: inline-block;
				vertical-align: middle;
				@include radius(50%);
			}
		}
	}
	h4 {
		margin: 0 0 17px;
		font-size: 2.8rem;
		font-weight: 500;
		color: $sosaiNaviColor01;
		text-align: center;
		@include mincyo;
	}
}


//各種プランのセット内容一覧
.p-sosaiPrice-shizuoka04 {
	margin-bottom: 123px;
	.c-sosaiTitle02b{
		padding-top: 5px;
		margin-bottom: 59px;
	}
	&__table {
		width: 100%;
		margin-bottom: 50px;
		border-collapse: collapse;
		position: relative;
		thead {
			tr {
				th {
					padding: 24px 20px 18px;
				}
			}
		}
		tbody {
			tr {
				position: relative;
				&:last-child{
					border-bottom: dotted 1px $gray07;
				}
				&:after {
					width: 100%;
					height: 0;
					content: '';
					border-bottom: dotted 1px $gray07;
					position: absolute;
					//bottom: 0;
					left: 0;
				}
				th {
					width: 26.7%;
					padding: 22px 20px 18px 47px;
					font-size: 1.5rem;
					font-weight: 700;
					text-align: left;
					background-color: #ffffff;
					&:before {
						width: 8px;
						height: 8px;
						margin: -4px 10px 0 -18px;
						content: '';
						background-color: $sosaiBtnColor01;
						display: inline-block;
						vertical-align: middle;
					}

				}
				td {
					padding: 22px 20px 18px;
					font-size: 1.5rem;
					text-align: center;
					background-color: $glaylight03;
				}
				&:nth-child(odd) {
					th {
						background-color: $glaylight03;
					}
					td {
						background-color: $glaylight18;
					}
				}
			}
		}
		&__title {
			font-size: 1.6rem;
			font-weight: 700;
			color: #ffffff;
			background-color: $sosaiNaviColor01;
			@include mincyo;
			border-right: 1px solid #FFF;
			&:last-child{
				border-right: 0;
			}
			img {
				width: 23px;
				margin: -4px 9px 0 4px;;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
	.c-sosaiBtn03 {
		width: 500px;
		margin: 0 auto;
		text-align: center;
		a:before{
			margin: -4px 20px 0 0;
		}
	}
}

//関連コンテンツ
.p-sosaiPrice-shizuoka05 {
	padding: 106px 0 122px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
}



@include sp {
	.p-sosaiPrice-shizuoka {
		font-size: 1.4rem;
	}
	//ラビュー（しずおか）の料金プラン　sp
	.p-sosaiPrice-shizuoka01 {
		min-width: 100%;
		margin-bottom: 46px;
		padding: 19px 0 8px;
		.c-sosaiTitle03{
			line-height: 2.4rem;
			span {
				padding-top: 2px;
			}
		}
	}

	//ラビューのお葬式プラン sp
	.p-sosaiPrice-shizuoka02 {
		margin-bottom: 50px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 28px;
		}
		&__content01 {
			margin-bottom: 44px;
			.c-sosaiTitle09{
				margin-bottom: 16px;
			}
			p{
				padding: 0 6px;
				line-height: 1.45;
			}
		}
		&__content02 {
			padding: 0 5px;
			.c-sosaiTitle10{
				margin-bottom: 21px;
				&:before{
					margin: -5px 5px 0 -30px;
				}
			}
			&__plus {
				margin: 20px auto;
				display: block;
				width: 22px;
			}
			&__box {
				&--01 {
					width: 100%;
					dd {
						padding: 20px 20px 15px;
					}
				}
				&--02 {
					width: 100%;
					dd {
						padding: 20px 20px 21px;
					}
				}
				&__title1 {
					padding-bottom: 5px;
					display: block;
					margin: 19px 0 8px;
					letter-spacing: 1px;
					h5 {
						font-size: 1.8rem;
						display: inherit;
						line-height: 1.2;
					}
				}
				&__title2 {
					height: auto;
					padding-bottom: 10px;
					display: block;
					h5 {
						font-size: 1.8rem;
						display: inherit;
					}
				}
				&__border {
					> p {
						font-size: 1.7rem !important;
						margin-bottom: 29px;
					}
					&:nth-child(1) {
						width: 100%;
						padding: 22px 15px 9px;
						margin-bottom: 20px;
						> div {
							&:last-of-type {
								margin-top: 14px;
							}
						}
					}
					&:nth-child(2) {
						width: 100%;
						padding: 22px 15px 10px;
					}
				}
				dt{
					padding: 6px 5px 3px;
					letter-spacing: 1px;
					font-size: 1.4rem;
					letter-spacing: 0;
				}
				dd {
					> div {
						width: 100%;
						text-align: center;
						img {
							width: 104px;
						}
						> div {
							width: 100%;
						}
					}
				}
			}
		}
	}

	//お葬式プラン sp
	.p-sosaiPrice-shizuoka03 {
		margin-bottom: 41px;
		padding: 50px 20px 0;
		overflow: hidden;
		.c-sosaiTitle02b{
			margin-bottom: 25px;
		}
		&__txt{
			text-align: left;
			line-height: 1.5;
			margin-bottom: 24px;
		}
		&__txt02 {
			font-size: 1.0rem;
			margin-bottom: 25px;
			line-height: 1.4;
		}
		&__txt03 {
			font-size: 1rem;
			margin-top: 0;
		}
		&__txt04 {
			font-size: 1rem !important;
		}
		&__content01 {
			margin-bottom: 50px;
			.p-sosaiPrice-shizuokaPlanArea__flow01{
				margin-bottom: 12px;
				.c-sosaiPlanFlow__item{
					&:nth-child(1), &:nth-child(4){
						padding: 15px 21px 14px 17px;
					}
					&:nth-child(2),&:nth-child(3),&:nth-child(5), &:nth-child(6){
						padding: 22px 21px 12px 17px;
					}
					&:nth-child(2), &:nth-child(5){
						.c-sosaiPlanFlow__icon{
							transform: translateY(-4px);
						}
					}
					&:nth-child(3){
						.c-sosaiPlanFlow__icon{
							transform: translateY(-2px);
						}
					}
					&:nth-child(6){
						.c-sosaiPlanFlow__icon{
							transform: translate(-4px, -4px);
						}
					}
				}
			}
			.p-sosaiPrice-shizuokaPlanArea__service{
				margin: 19px 0 6px;
				li{
					p{
						padding-top: 7px;
					}
					&:nth-of-type(1), &:nth-of-type(7), &:nth-of-type(13){
						img{
							transform: translate(3px, -1px);
						}
					}
					&:nth-of-type(2), &:nth-of-type(14){
						img{
							transform: translate(1px, 3px);
						}
					}
					&:nth-of-type(4){
						img{
							transform: translate(2px, 1px);
						}
					}
					&:nth-of-type(5){
						img{
							transform: translate(5px, 1px);
						}
					}
					&:nth-of-type(6), &:nth-of-type(11), &:nth-of-type(14){
						img{
							transform: translate(0);
						}
						p{
							margin: 10px 0 0 2px;
						}
					}
					&:nth-of-type(8), &:nth-of-type(9), &:nth-of-type(10),
					&:nth-of-type(15){
						img{
							transform: translate(0);
						}
					}

					&:nth-of-type(16){
						img{
							transform: translate(1px, -15px);
						}
						p{
							margin: -8px 0 0 2px;
						}
					}
					&:nth-of-type(17){
						img{
							transform: translate(5px, -16px);
						}
						p{
							margin: -16px 0 0 2px;
						}
					}
					&:nth-of-type(18){
						img{
							transform: translate(0, -15px);
						}
						p{
							margin: -8px 0 0 2px;
						}
					}
				}
			}
		}
		&__content02 {
			margin-bottom: 50px;
			.p-sosaiPrice-shizuokaPlanArea__flow01{
				margin-bottom: 4px;
				.c-sosaiPlanFlow__item{
					&:nth-child(1), &:nth-child(4){
						padding: 13px 21px 15px 18px;
					}
					&:nth-child(2),&:nth-child(3),&:nth-child(5), &:nth-child(6){
						padding: 22px 21px 12px 18px;
					}
					&:nth-child(2), &:nth-child(3), &:nth-child(5){
						.c-sosaiPlanFlow__icon{
							transform: translateY(-4px);
						}
					}
					&:nth-child(6){
						.c-sosaiPlanFlow__icon{
							transform: translate(-4px, -4px);
						}
					}
				}
			}
			.p-sosaiPrice-shizuokaPlanArea__annotation2 {
				margin-bottom: 23px;
			}
			.p-sosaiPrice-shizuokaPlanArea__service{
				margin: 27px 0 -9px;
				li{
					p{
						padding-top: 0;
					}
					&:nth-of-type(1){
						img{
							transform: translate(3px, -9px);
						}
					}
					&:nth-of-type(2), &:nth-of-type(14){
						img{
							transform: translate(0, -4px);
						}
					}
					&:nth-of-type(3){
						img{
							transform: translate(1px, -8px);
						}
					}
					&:nth-of-type(4), &:nth-of-type(6), &:nth-of-type(7), {
						img{
							transform: translate(0);
						}
						p{
							padding-top: 7px;
						}
					}
					&:nth-of-type(5), &:nth-of-type(12){
						img{
							transform: translate(0);
						}
						p{
							margin: 10px 0 0 -4px;
						}
					}
					&:nth-of-type(8), &:nth-of-type(9){
						img{
							transform: translate(0, -5px);
						}
						p{
							margin: 8px 0 0 2px;
						}
					}
					&:nth-of-type(10){
						img{
							transform: translate(0);
						}
						p{
							margin: 10px 0 0 4px;
						}
					}
					&:nth-of-type(11){
						p{
							margin: 10px 0 0 4px;
						}
					}
					&:nth-of-type(13){
						img{
							transform: translate(5px, -1px);
						}
						p{
							margin: 10px 0 0 4px;
						}
					}
					&:nth-of-type(14){
						img{
							transform: translate(0);
						}
						p{
							margin: 10px 0 0 0;
						}
					}

					&:nth-of-type(15){
						img{
							transform: translate(0, -2px);
						}
						p{
							margin: 10px 0 0 4px;
						}
					}
				}
			}
		}
		&__content03 {
			margin-bottom: 50px;
			.p-sosaiPrice-shizuokaPlanArea__flow01{
				margin-bottom: 4px;
				.c-sosaiPlanFlow__item{
					padding: 22px 21px 13px 18px;
					&:after{
						top: calc(100% - 11px);
					}
					.c-sosaiPlanFlow__icon{
						transform: translateY(-4px);
					}
					&:nth-child(3){
						.c-sosaiPlanFlow__icon{
							transform: translate(-4px, -4px);
						}
					}
				}
			}
			.p-sosaiPrice-shizuokaPlanArea__service{
				margin: 19px 0 8px;
				li{
					p{
						padding-top: 8px;
					}
					&:nth-of-type(1){
						img{
							transform: translate(2px, 3px)w;
						}
					}
					&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(8){
						img{
							transform: translate(0);
						}
					}
					&:nth-of-type(5), &:nth-of-type(6){
						img{
							transform: translate(-2px, 0);
						}
					}
					&:nth-of-type(7){
						img{
							transform: translate(0, -2px);
						}
						p{
							margin-top: 10px;
						}
					}
					&:nth-of-type(9){
						img{
							transform: translate(3px, 0);
						}
					}
					&:nth-of-type(10){
						img{
							transform: translate(0);
						}
						p{
							margin: -3px 0 0 6px;
						}
					}
				}
			}
			&__note {
				width: 100%;
				margin-bottom: 25px;
				p {
					font-size: 1rem;
				}
			}
		}
	}

	//お葬式プランエリア sp
	.p-sosaiPrice-shizuokaPlanArea {
		padding: 0 20px 30px;
		.c-sosaiTitle11 {
			width: calc(100% + 40px);
			margin: 0 -20px 30px -20px;
			font-size: 1.9rem;
			line-height: 1.2;
			padding: 10px 15px 10px 61px;
			position: relative;
			span {
				display: block;
				font-size:1.9rem;
			}
			&:before{
				width: 42px;
				height: 42px;
				position: absolute;
				margin: 0;
				top: 50%;
				left: 10px;
				transform: translate(0, -50%);
			}
		}
		&__box01 {
			margin-bottom: 27px;
			padding: 20px 20px 18px;
			font-size: 1.7rem;
			&:before, &:after {
				width: calc(100% - 5px);
				height: calc(100% - 5px);
			}
			&:after {
				top: 5px;
				left: 5px;
			}
		}
		&__box02 {
			margin: 10px 0;
			padding: 17px 20px 4px;
		}
		&__annotation {
			font-size: 1.2rem;
		}
		&__annotation2 {
			font-size: 1.0rem;
		}
		&__priceImg {
			margin: 0 auto 11px auto;
			display: block;
		}
		&__price {
			font-size: 1.5rem;
			text-align: center;
			p {
				padding : 0 5px 3px;
				font-size: 1.2rem;
				line-height: 1.7;
			}
			i {
				font-size: 1rem;
				padding: 2px 9px;
				margin: 0 0 8px 0;
			}
			em {
				font-size: 4.5rem;
			}
			span {
				font-size: 2rem;
			}
			&__box {
				flex-wrap: wrap;
				justify-content: center;
			}
			&__item {
				justify-content: center;
				&--sub {
					font-size: 2.4rem !important;
				}
				.small {
					font-size: 2rem;
				}
			}
		}

		&__osusume {
			margin-bottom: 15px;
			li {
				width: 100% !important;
				height: auto;
				margin-bottom: 13px;
				font-size: 1.4rem;
				display: inherit;
				border: solid 1px $glaylight13;
				&:nth-child(5) {
					margin-bottom: 0;
				}
				p {
					padding: 17px 0 14px 18px;
					display: inherit;
					line-height: 1.5;

					&.two-row {
						padding: 17px 18px 14px;
					}
				}
			}
		}
		&__detail {
			dt {
				font-size: 1.5rem;
				padding: 16px 15px 13px;
				&:before{
					margin: 0 12px 0 7px;
				}
			}
			dd {
				padding-top: 29px;
			}
		}
		&__service {
			li {
				width: 31% !important;
				height: auto !important;
				padding: 8px;
				margin: 0  8px 22px 0 !important;
				&:nth-child(3n){
					margin-right: 0 !important;
				}
				.shizuokaPlanArea__service-img{
					height: auto !important;
				}
				img {
					margin: 0 auto 2px auto;
				}
				p {
					padding-top: 0;
					font-size: 1.1rem;
					span {
						font-size: 1.0rem;
					}
				}
			}
		}
		&__List {
			width: 100%;
			li {
				font-size: 1.1rem;
				line-height: 1.5;
				padding: 0 0 2px 11px;
				&:before {
					margin: -3px 5px 0 -11px;
				}
			}
		}
		h4 {
			margin: 10px 0;
			font-size: 1.9rem;
			line-height: 1.3;
		}
	}

	//各種プランのセット内容一覧 sp
	.p-sosaiPrice-shizuoka04 {
		margin-bottom: 50px;
		padding: 0 15px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
		}
		&__table {
			margin-bottom: 30px;
			thead {
				tr {
					th {
						line-height: 1.3;
						padding: 7px 4px 5px;
					}
				}
			}
			tbody {
				tr {
					th {
						width: 34%;
						padding: 10px 5px 12px 24px;
						font-size: 1.3rem;
						line-height: 1.6;
						&:before {
							margin: -3px 5px 0 -12px;
						}
					}
					td {
						padding: 5px 2px ;
						font-size: 1rem;
					}
					&:nth-child(1), &:nth-child(8) {
						th{
							padding: 21px 5px 20px 24px;
						}
					}
					&:nth-child(2) {
						th{
							padding: 23px 5px 22px 24px;
						}
					}
					&:nth-child(11) {
						th{
							padding: 12px 5px 10px 24px;
						}
					}
				}
			}
			&__title {
				font-size: 1.3rem;
				@include mincyo;
				img {
					width: 15px;
					margin: 0 auto 2px;
					display: block;
				}
			}
		}
		.c-sosaiBtn03{
			width: 100%;
			font-size: 1.5rem;
			a{
				padding: 11px;
				&:before{
					margin: -4px 6px 0 0;
				}
			}
		}
	}

	//関連コンテンツ sp
	.p-sosaiPrice-shizuoka05 {
		margin-bottom: 58px;
		padding: 50px 15px 41px;
		overflow: hidden;
		.c-sosaiTitle02b {
			margin-bottom: 40px;
		}
	}
}

/*
------------------------------------------------------------*/
