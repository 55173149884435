@use '../../0_base' as *;

.p-anniversaryEnqueteSummary {
	padding: 20px;
	border: 1px solid #D5D5D5;
    border-radius: 10px;
    font-size: 1.6rem;

    @include sp {
        font-size: 1.4rem;
	}

    &__title {
        margin-bottom: 20px;
        padding-bottom: 10px;
        font-size: 2.4rem;
        font-weight: bold;
        border-bottom: 2px solid #8CB340;
        line-height: 1.19444444;

        @include sp {
            font-size: 1.8rem;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        dt {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.5;
            color: #8CB340;

            @include sp {
                font-size: 1.5rem;
            }
        }

        ul {
            li {
                margin-top: 5px;
                padding-left: 15px;
                position: relative;

                &::before {
                    position: absolute;
                    top: .5em;
                    left: 0;
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: #8CB340;
                    border-radius: 50%;

                    @include sp {
                        font-size: .4em;
                    }
                }
            }
        }
    }

    &__linkText {
        color: inherit;
        text-decoration: underline;
    }

    &__newWindow {
		width: 13px;
		height: 13px;
		background: url(../img/anniversary-enquete/icon_newWindow_coopGreen.svg) no-repeat center center / contain;
        display: inline-block;
	}
}