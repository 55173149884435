@use '../../0_base' as *;

/*------------------------------------------------------------
葬祭　葬儀の事前準備
------------------------------------------------------------*/
.p-sosaiKnowledgeHostPreparation {
	background-color: #ffffff;
	font-size: 1.7rem;
	line-height: 1.8;
	color: $gray08;
	//注釈
	&__annotation01 {
		margin: 1em 0;
		font-size: 1.2rem;
	}
}

//葬儀の事前準備
.p-sosaiKnowledgeHostPreparation01 {
	min-width: $baseWidth01;
	padding: 51px 0 53px;
	background-image: url("../img/sosai/contents_pagetitle_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center center;
	> span {
		font-size: 1.6rem;
		display: block;
		@include mincyo;
	}
	.c-sosaiTitle03 {
		margin: 0;
	}
}

//事前の確認ポイント
.p-sosaiKnowledgeHostPreparation02 {
	margin: 24px 0 112px;
	.c-sosaiTitle02b {
	    margin-bottom: 59px;
	}
	&__txt01, &__txt02{
		font-size: 1.7rem;
		line-height: 1.8;
		text-align: center;
	}
	&__content01 {
		margin: 114px 0 50px;
		dl {
			padding: 0 5px;
			dt {
				margin-bottom: 24px;
				padding-bottom: 20px;
				border-bottom: dotted 1px $glaylight13;
			}
			dd {
				p {
					margin-bottom: 33px;
					font-size: 1.6rem;
					line-height: 1.75;
				}
				img {
					margin: 0 auto;
					display: block;
				}
			}
		}
	}
}

//コープの就活応援！
.p-sosaiKnowledgeHostPreparation03 {
	padding: 120px 0 0 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	&__content01 {
		padding: 60px 60px 85px;
		background-color: #ffffff;
		border-top: solid 2px $sosaiNaviColor02;
		&__txt {
			padding-right: 520px;
			position: relative;
			img {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		p {
			margin-bottom: 33px;
		}
		.c-sosaiBtn07 {
			width: 157px;
			margin: 0;
		}
	}
}


//関連コンテンツ
.p-sosaiKnowledgeHostPreparation04 {
	padding: 106px 0 139px 0;
	background-image: url("../img/sosai/index_bg.jpg");
	-webkit-background-size: cover;
	background-size: cover;
	.c-sosaiTitle02b{
		margin-bottom: 59px;
	}
}


@include sp {
	.p-sosaiKnowledgeHostPreparation {
		font-size: 1.3rem;
		//注釈
		&__annotation01 {
			font-size: 1.0rem;
		}
	}
	//葬儀の事前準備 sp
	.p-sosaiKnowledgeHostPreparation01 {
		min-width: 100%;
		margin-bottom: 47px;
		padding: 20px 0 21px;
		> span {
			font-size: 1rem;
			margin-bottom: 6px;
		}
	}
	//事前の確認ポイント
	.p-sosaiKnowledgeHostPreparation02 {
		margin-bottom: 45px;
		padding: 0 20px;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
		}
		&__txt01, &__txt02{
			//font-size: 1.3rem;
			font-size: 1.4rem;
			line-height: 1.6;
			text-align: left;
		}
		&__content01 {
			margin: 73px 0 35px;
			dl {
				padding: 0;
				dt {
					margin-bottom: 10px;
					padding-bottom: 7px;
				}
				dd {
					p {
						margin-bottom: 10px;
						font-size: 1.3rem;
						line-height: 1.6;
					}
				}
			}
		}
	}

	//コープの就活応援！
	.p-sosaiKnowledgeHostPreparation03 {
		margin: 0;
		padding: 50px 15px 0;
		&__content01 {
			padding: 32px 19px 20px;
			.c-sosaiTitle12{
				margin-bottom: 25px;
				&:before{
					left: 4px;
				}
				span{
					margin: -4px 0 1px;
				}
			}
			&__txt {
				padding: 0;
				position: static;
				img {
					margin: 0 auto 15px;
					display: block;
					position: static;
				}
			}
			p {
				margin-bottom: 16px;
				font-size: 1.3rem;
    			line-height: 1.6;
			}
			.c-sosaiBtn07 {
				width: 93%;
				height: auto;
				line-height: 2.8;
				margin: 0 auto;
			}
		}
	}
	//関連コンテンツ sp
	.p-sosaiKnowledgeHostPreparation04 {
		margin-bottom: 63px;
		padding: 51px 15px 20px;
		overflow: hidden;
		.c-sosaiTitle02b{
			margin-bottom: 40px;
		}
		.c-sosaiKanrenLink01{
			margin: 0 auto;
			display: flex;
			li{
				width: calc(50% -  5px);
				margin-right: 10px;
				float: left;
				&:nth-child(2n), &:last-child{
					margin-right: 0;
				}
				a{
					font-size: 1.2rem;
					line-height: 1.3;
					padding: 0 15px;
					display: block;
				}
			}
		}
	}

}

/*
------------------------------------------------------------*/
