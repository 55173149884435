@use '../../0_base' as *;

/*------------------------------------------------------------
リフォーム　商品詳細
------------------------------------------------------------*/

.p-reformService00 {
	background-color: #ffffff;
}

//ページタイトル
.p-reformService0001 {
	&__content01 {
		.c-refomeTitle02 {
			background-image: url("../img/reform/title_obi_interior.jpg");
		}
	}
}

// 商品・サービス名
.p-reformService0002 {
	margin-bottom: 60px;
	&__content01 {
		margin-bottom: 60px;
		&__detail {
			margin-bottom: 45px;
			display: flex;
			flex-direction: row-reverse;
			&__img {
				width: 340px;
				margin-left: 25px;
				&__img {
					margin: 3px 0 20px;
				}
				&__movie {
					position: relative;
					padding-bottom: 56.25%;
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
			&__txt {
				width: calc(100% - 365px);
				h3 {
					margin-bottom: 12px;
					font-size: 2.0rem;
					font-weight: bold;
					color: $strongColor03;
				}
				p {
					margin-bottom: 28px;
					font-size: 1.5rem;
					line-height: 1.75;
					color: $gray01;
				}
				table {
					width: 100%;
					font-size: 1.3rem;
					border-collapse: collapse;
					tr {
						border-bottom: dotted 1px $gray07;
						&:last-child {
							border: none;
						}
					}
					th {
						width: 160px;
						padding: 16px 0 12px;
						font-weight: bold;
						text-align: left;
						color: $reformTextColor02;
					}
					td {
						padding: 16px 0 12px;
						color: $gray04;
					}
					strong {
						color: $reformBtnColor06;
						font-weight: bold;
					}
				}
			}
		}
		&__priceArea {
			margin-bottom: 60px;
			@include sp {
				margin-bottom: 30px;
			}
			dt {
				padding: 8px 0;
				font-size: 1.3rem;
				color: #ffffff;
				text-align: center;
			}
			dd {
				table {
					width: 100%;
					margin-bottom: 11px;
					font-size: 1.6rem;
					th {
						vertical-align: top;
						font-weight: bold;
						text-align: left;
						padding: 4px 0 0;
					}
					td {
						vertical-align: top;
						font-weight: bold;
						text-align: right;
						em {
							font-size: 2.4rem;
							font-style: normal;
						}
						span {
							display: block;
							font-size: 1.3rem;
							font-weight: 500;
							padding-top: 6px;
						}
					}
				}
				p {
					font-size: 1.1rem;
					color: $gray03;
					line-height: 1.5;
				}
				.c-lineDotted01{
					margin: 26px 0 22px;
				}
			}
			&__price01 {
				width: 450px;
				flex: 1;
				dt {
					background-color: $gray01;
				}
				dd {
					padding: 23px 0;
				}
				&__discount {
					color: $reformBtnColor06;
					th{
						padding: 7px 0 0 !important;
					}
					td {
						em {
							font-size: 4.6rem !important;
						}
						span{
							padding-top: 1px !important;
						}
					}
				}
			}
			&__price02 {
				width: 340px;
				margin-left: 50px;
				@include sp {
					margin-left: 0;
				}
				dt {
					background-color: $reformNaviColor04;
				}
				dd {
					padding: 23px 28px 26px;
					border: solid 3px $glaylight13;
					border-top: none;
					span{
						padding-top: 2px !important;
						line-height: 1.5;
					}
				}
				table {
					&:not(:first-of-type) {
						th {
							display: block;
							margin-bottom: 5px;
						}
						td {
							display: block;
							text-align: left;
						}
					}
				}
			}
		}
	}
	&__content02 {
		margin-bottom: 58px;
		span {
			display: block;
			font-size: 1.3rem;
			line-height: 1.53;
			color: $gray01;
			@include sp {
				font-size: 1.2rem;
				line-height: 1.4;
			}
		}
		&__txtArea {
			margin-bottom: 26px;
			display: flex;
			flex-direction: row-reverse;
			&__img {
				width: 253px;
				padding-top: 5px;
				margin-left: 25px;
			}
			&__txt {
				width: 560px;
				flex: 1;
				h3 {
					margin-bottom: 15px;
					font-size: 1.8rem;
					font-weight: bold;
					color: $reformTextColor01;
				}
				p {
					font-size: 1.5rem;
					line-height: 1.73;
					color: $gray01;
				}
			}
		}
	}
	&__content03 {
		.c-refomeTitle06 {
		    margin-bottom: 15px;
		}
		ul {
			margin-bottom: 47px;
			font-size: 1.3rem;
			line-height: 1.53;
			color: $gray01;
			li {
				margin-bottom: 9px;
				padding-left: 20px;
				&:before {
					width: 20px;
					margin: 0 0 0 -20px;
					content: '※';
					display: inline-block;
				}
			}
		}
	}
}

//関連商品
.p-reformService0003 {
	margin-bottom: 100px;
}

@include sp {

	//ページタイトル sp
	.p-reformService0001 {
		margin-bottom: 30px;
		&__content01 {
			.c-refomeTitle02 {
				background-image: url("../img/reform/title_obi_interior_sp.jpg");
			}
		}
	}

	// 商品・サービス名 sp
	.p-reformService0002 {
		margin-bottom: 40px;
		padding: 0 20px;
		&__content01 {
			margin-bottom: 29px;
			&__detail {
				min-height: auto;
				margin-bottom: 23px;
				padding-right: 0;
				display: block;
				.c-refomeTitle03c{
					&:before{
						top: 2px;
						height: 35px;
						transform: translate(0,0);
					}
					span{
						padding-left: 1px;
						letter-spacing: 1px;
					}
				}
				&__img {
					width: 100%;
					margin: 0 0 12px 0;
					&__img {
						margin: 3px 0 16px;
					}
				}
				&__txt {
					width: 100%;
					h3 {
						font-size: 1.5rem;
						margin-bottom: 10px;
					}
					p {
						margin-bottom: 25px;
						font-size: 1.4rem;
					}
					table {
						font-size: 1.4rem;
						line-height: 1.7rem;
						tr{
							border-bottom: 1px solid transparent;
							background: url("../img/reform/borderSP.png") repeat-x top left;
							&:nth-of-type(2){
								td{
									padding: 2px 0 16px;
								}
							}
							&:nth-of-type(3){
								th{
									padding: 13px 0 12px;
								}
								td{
									padding: 14px 0 12px;
								}
							}
							&:nth-of-type(5){
								th{
									padding: 11px 0 9px;
								}
								td{
									padding: 14px 0 11px;
								}
							}
						}
						th {
							width: 39%;
							padding: 11px 0;
						}
						td{
							padding: 11px 0;
							vertical-align: middle;
						}
					}
				}
			}
			&__priceArea {
				dd {
					table {
						font-size: 1.4rem;
						th{
							font-size: 1.3rem;
							line-height: 1.7rem;
							padding: 0;
							transform: translateX(-2px);
						}
						td {
							font-size: 1.1rem;
							em {
								font-size: 2.5rem;
							}
							span{
								font-size: 1.2rem;
								padding-top: 3px;
							}
						}
					}
					p{
						font-size: 1.1rem;
						line-height: 1.2rem;
					}
					.c-lineDotted01 {
    					margin: 18px 0 10px;
    				}
				}
				&__price01 {
					width: 100%;
					dt{
						padding: 2px 0;
					}
					dd {
						padding: 15px 0;
					}
					&__discount {
						td {
							padding-top: 8px;
							em {
								font-size: 3rem !important;
							}
						}
					}
				}
				&__price02 {
					width: 100%;
					padding-top: 2px;
					dt{
						padding: 1px 0;
					}
					dd {
						padding: 15px 18px 19px;
						border: solid 1px $glaylight13;
						table{
							margin-bottom: 8px;
							&:not(:first-of-type) {
								td{
									span{
										font-size: 1.3rem;
										line-height: 1.6rem;
										transform: translate(2px,-2px);
									}
								}
							}
						}
						.c-lineDotted01{
							margin: 18px 0 16px;
						}
					}
				}
			}
		}
		&__content02 {
			margin-bottom: 23px;
			&__txtArea {
				display: block;
				margin-bottom: 18px;
				&__img {
					width: 100%;
					margin-bottom: 12px;
					margin-left: 0;
				}
				&__txt {
					width: 100%;
					h3 {
						margin-bottom: 6px;
						font-size: 1.5rem;
					}
					p {
						font-size: 1.4rem;
						line-height: 1.75
					}
				}
			}
			.c-refomeTitle06{
				margin-bottom: 12px;
			}
		}
		&__content03 {
			.c-refomeTitle06 {
		    	margin-bottom: 14px;
			}
			ul {
				margin-bottom: 28px;
				font-size: 1.2rem;
				li{
					padding-left: 15px;
					line-height: 1.4rem;
					margin-bottom: 11px;
				}
			}
			.c-reformBtn03{
				a{
					letter-spacing: -1px;
    				padding-top: 4px;
    				&:before{
    					width: 12px;
    					height: 14px;
    					margin: 2px 12px 0 0;
    				}
				}
			}
		}
	}

	//関連商品 sp
	.p-reformService0003 {
		margin-bottom: 30px;
		padding: 0 20px;
		.c-refomeTitle04{
			margin-bottom: 20px;
		}
		.c-reformKanrenLink{
			li{
				margin: 0 4% 11px 0 !important;
				&:nth-of-type(2){
					margin: 0 4% 10px 0 !important;
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
