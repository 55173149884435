@use '../../0_base' as *;

/*------------------------------------------------------------
チケット　チケット申込みフォーム（カート） 確認
------------------------------------------------------------*/
.p-ticketCartConfirm {
	padding: 50px 0 135px 0;
	background-color: #ffffff;
}

//見出し
.p-ticketCartConfirm01 {
	margin-bottom: 30px;
	.c-formTitle {
		color: $ticketNaviColor02;
	}
}

//フォーム
.p-ticketCartConfirm02 {
	margin-bottom: 80px;
	&__content01 {
		margin-bottom: 40px;
		color: $ticketNaviColor02;
	}
	&__content02 {
		margin-bottom: 60px;
		color: $ticketNaviColor02;
		&__txt {
			margin-top: 40px;
			font-size: 1.4rem;
		}
		.c-error {
			margin-bottom: 20px;
			font-weight: normal;
			@include sp {
				margin-bottom: 10px;
			}
			span {
				display: block;
				font-weight: bold;
				font-size: 1.6rem;
				@include sp {
					font-size: 1.3rem;
				}
			}
		}
		.c-tbl01{
			.col-name{
				width: 270px;
			}
		}
	}
	&__content03 {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		.c-cartBtn {
			margin: 0;
		}
		&__back {
			margin-right: 20px;
			color: #232934;
			border: 2px solid #b2b2b2;
			background: #FFF;
			position: relative;
			&:before{
				left: 20px;
				border-bottom: 2px solid #b2b2b2;
				border-left: 2px solid #b2b2b2;
			}
			&:hover{
				color: #fff;
				background: #b2b2b2;
				&:before{
					border-bottom: 2px solid #fff;
					border-left: 2px solid #fff;
				}
			}
		}

	}
}


@include sp {
	.p-ticketCartConfirm {
		padding: 30px 20px 38px 20px;
	}

	//見出し sp
	.p-ticketCartConfirm01 {
		margin-bottom: 20px;
		.c-formTitle {
			margin-bottom: 20px;
		}
	}

	//フォーム sp
	.p-ticketCartConfirm02 {
		margin-bottom: 0;
		&__content01 {
			margin-bottom: 30px;
			.c-table2 {
				dl {
					padding-bottom: 10px;
				}
			}
		}
		&__content02 {
			margin-bottom: 30px;
			&__txt {
				margin-top: 20px;
				font-size: 1.2rem;
			}
		}
		&__content03 {
			display: block;
			.c-cartBtn {
				margin-top: 0;
			}
			&__back {
				margin-right: 0;
				&:before{
					left: 20px;
					border-bottom: 2px solid #b2b2b2;
					border-left: 2px solid #b2b2b2;
				}
				&:hover{
					color: #fff;
					background: #b2b2b2;
					&:before{
						border-bottom: 2px solid #fff;
						border-left: 2px solid #fff;
					}
				}
			}
		}
	}
}

/*
------------------------------------------------------------*/
